import IframeMessageModel, { IFRAME_MESSAGE_ACTIONS } from 'common/interface/IFrame.message.model';
import { IWebappViewState, IWebappIframeService, IWebappIframeServicePlacement } from 'common/interface/services';
import { isLocalhost } from 'common/utils/RuntimeEnvironment';
import { getAngularHostName, setRefreshPathnameForAngular } from 'common/utils/http';

const getZIndexByPlacement = (placement?: IWebappIframeServicePlacement) => {
    switch (placement) {
        case IWebappIframeServicePlacement.DRAWER:
            return '501';
        case IWebappIframeServicePlacement.MODAL:
            return '1001';
        default:
            return '0';
    }
};

export class WebappIframeService implements IWebappIframeService {
    public IFrameElement: HTMLIFrameElement;
    public isLoaded = false;
    private iframeOrigin: string | undefined = undefined;
    private missingMessages: IframeMessageModel[] = [];

    private currentPlacement: IWebappIframeServicePlacement | undefined;
    private defaultObserver: ResizeObserver | undefined;
    private overlayObserver: ResizeObserver | undefined;
    private checkPlacementInterval: NodeJS.Timer | undefined;
    private checkMainPlacementInterval: NodeJS.Timer | undefined;

    private currentViewState: IWebappViewState | undefined;
    private currentViewStateAcknowledged = false;
    private checkViewStateAcknowledgedInterval: NodeJS.Timer | undefined;

    constructor() {
        this.IFrameElement = document.createElement('iframe');
        this.IFrameElement.id = 'webapp';
        this.IFrameElement.className = 'webapp-iframe';
        this.IFrameElement.allow = 'clipboard-read; clipboard-write';
        this.IFrameElement.title = 'Cloud Guard';
        this.IFrameElement.setAttribute('data-aid', 'webapp-iframe');
        this.setVisibility(false);

        const iframeSrc = setRefreshPathnameForAngular();
        if (iframeSrc) {
            this.setSrc(iframeSrc);
        }

        this.IFrameElement.addEventListener('load', (event: any) => {
            if (event?.target?.src === '') return;
            this.isLoaded = true;
            if (this.missingMessages.length) {
                this.missingMessages.forEach((iframeMessage) => this.emitMessage(iframeMessage));
                this.missingMessages = [];
            }
        });
    }

    setSrc = (src: string) => {
        if (!src) {
            throw new Error('src is required');
        }
        const webappIframeRootElement = document.getElementById('webapp-iframe-root');
        if (!webappIframeRootElement) {
            throw new Error('webapp-iframe-root element not found');
        }

        webappIframeRootElement.appendChild(this.IFrameElement);

        this.IFrameElement.src = src;
        const angularHostName = getAngularHostName(document.location.hostname, document.location.port);
        const angularOrigin = `${document.location.protocol}//${angularHostName}`;
        this.iframeOrigin = angularOrigin;
        this.isLoaded = false;
    };

    private setSizeAndLocation = (sizeLocationObj: { width: number; height: number; left: number; top: number }) => {
        this.IFrameElement.style.width = `${sizeLocationObj.width}px`;
        this.IFrameElement.style.height = `${sizeLocationObj.height}px`;
        this.IFrameElement.style.left = `${sizeLocationObj.left}px`;
        this.IFrameElement.style.top = `${sizeLocationObj.top}px`;
    };

    private setZIndex = (index: string) => {
        this.IFrameElement.style.zIndex = index;
    };

    private updateIframePositionByRef = (ref: HTMLDivElement) => {
        const { width, height, top, left } = ref.getBoundingClientRect();
        this.setSizeAndLocation({ width, height, top, left });
    };

    setVisibility = (isVisible: boolean) => {
        this.IFrameElement.style.visibility = isVisible ? 'visible' : 'hidden';
    };

    setDefaultWrapperRef = (ref: HTMLDivElement) => {
        this.defaultObserver = new ResizeObserver(() => {
            if (this.currentPlacement) return;
            this.updateIframePositionByRef(ref);
            this.setZIndex(getZIndexByPlacement(this.currentPlacement));
        });
        this.defaultObserver.observe(ref);
        clearInterval(this.checkMainPlacementInterval);
        this.checkMainPlacementInterval = setInterval(() => {
            if (this.currentPlacement) return;
            this.updateIframePositionByRef(ref);
        }, 500);
    };

    clearDefaultWrapperRef = () => {
        if (!this.defaultObserver) return;
        this.defaultObserver.disconnect();
        clearInterval(this.checkMainPlacementInterval);
    };

    setOverlayWrapperRef = (ref: HTMLDivElement, placement: IWebappIframeServicePlacement) => {
        this.currentPlacement = placement;
        this.overlayObserver = new ResizeObserver(() => {
            if (!this.currentPlacement) return;
            this.updateIframePositionByRef(ref);
            this.setZIndex(getZIndexByPlacement(this.currentPlacement));
        });
        this.overlayObserver.observe(ref);
        clearInterval(this.checkPlacementInterval);
        this.checkPlacementInterval = setInterval(() => {
            if (!this.currentPlacement) return;
            this.updateIframePositionByRef(ref);
        }, 500);
    };

    clearOverlayWrapperRef = () => {
        this.currentPlacement = undefined;
        if (!this.overlayObserver) return;
        this.overlayObserver.disconnect();
        clearInterval(this.checkPlacementInterval);
    };

    emitMessage = (iframeMessage: IframeMessageModel) => {
        if (!this.isLoaded || !this.iframeOrigin) {
            if (isLocalhost) {
                console.error('failed to emit message to webapp - iframe is not ready', { iframeMessage });
            }
            this.missingMessages.push(iframeMessage);
            return false;
        }
        this.IFrameElement.contentWindow?.postMessage(iframeMessage, this.iframeOrigin);
    };

    navigate = (relativePath: string) => {
        const iframeMessage = new IframeMessageModel({
            action: IFRAME_MESSAGE_ACTIONS.URL_UPDATE,
            data: { relativePath },
        });
        this.emitMessage(iframeMessage);
    };

    setViewState = (viewState: IWebappViewState) => {
        console.log('setViewState', viewState);
        this.currentViewState = viewState;
        this.currentViewStateAcknowledged = false;
        const iframeMessage = new IframeMessageModel({
            action: IFRAME_MESSAGE_ACTIONS.SET_VIEWSTATE,
            data: viewState,
        });
        this.emitMessage(iframeMessage);
        clearInterval(this.checkViewStateAcknowledgedInterval);
        this.checkViewStateAcknowledgedInterval = setInterval(() => {
            if (this.isLoaded && !this.currentViewStateAcknowledged) {
                this.emitMessage(iframeMessage);
            }
        }, 2000);
    };

    removeViewState = () => {
        this.currentViewState = undefined;
        this.currentViewStateAcknowledged = false;
        const iframeMessage = new IframeMessageModel({
            action: IFRAME_MESSAGE_ACTIONS.REMOVE_VIEWSTATE,
        });
        this.emitMessage(iframeMessage);
        clearInterval(this.checkViewStateAcknowledgedInterval);
    };

    acknowledgeViewState = (viewState: IWebappViewState) => {
        if (!this.currentViewState) return;
        const getIdType = (_viewState: IWebappViewState) => `${_viewState.id}_${_viewState.context}_${_viewState.type}`;
        if (getIdType(viewState) === getIdType(this.currentViewState)) {
            this.currentViewStateAcknowledged = true;
            clearInterval(this.checkViewStateAcknowledgedInterval);
        }
    };
}
