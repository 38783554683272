import { IEvent, IEventOverviewPanelProvider } from 'common/module_interface/events/Events';
import { ExploreLogsButton } from '../Components/Panels/RightPanel/ExploreLogsButton';
import { MitrePanel } from '../Components/Panels/MitrePanel';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { DescriptionPanel } from '../Components/Panels/DescriptionPanel';
import { isEmptyString } from 'common/utils/helpFunctions';
import { VisualizationGraphPanel } from '../Components/Panels/VisualizationGraphPanel';
import { isPanelRelevant } from '../Services/Utils';
import { BundleNamePanel } from '../Components/Panels/BundleNamePanel';
import { GslPanel } from '../Components/Panels/GslPanel';
import { AlertIdPanel } from '../Components/Panels/AlertIdPanel';
import FindingComments from '../Components/Panels/FindingComments';
import { isEmpty } from 'lodash';
import React from 'react';
import { FindingAssetPanel } from 'common/components/Findings/FindingAssetPanel/FindingAssetPanel';
import { FindingOverviewPanelsIds } from '../../Findings.const';

export const EventOverviewPanelsTypes: { [key: string]: IEventOverviewPanelProvider } = {
    EXPLORE_LOGS_PANEL: {
        panelId: FindingOverviewPanelsIds.EXPLORE_LOGS_PANEL,
        panel: ExploreLogsButton,
        isRelevant: () => true,
    },
    MITRE_PANEL: {
        panelId: FindingOverviewPanelsIds.MITRE_PANEL,
        panel: MitrePanel,
        isRelevant: (finding: IKustoEvent) => finding.mitreDict !== undefined,
    },
    DESCRIPTION_PANEL: {
        panelId: FindingOverviewPanelsIds.DESCRIPTION_PANEL,
        panel: DescriptionPanel,
        isRelevant: (finding: IKustoEvent) => !isEmptyString(finding.description),
    },
    VISUALIZATION_PANEL: {
        panelId: FindingOverviewPanelsIds.VISUALIZATION_PANEL,
        panel: VisualizationGraphPanel,
        isRelevant: (finding: IKustoEvent) => isPanelRelevant(finding, FindingOverviewPanelsIds.VISUALIZATION_PANEL),
    },
    BUNDLE_NAME_PANEL: {
        panelId: FindingOverviewPanelsIds.BUNDLE_NAME_PANEL,
        panel: BundleNamePanel,
        isRelevant: (finding: IKustoEvent) => !isEmptyString(finding.bundleName),
    },
    GSL_PANEL: {
        panelId: FindingOverviewPanelsIds.GSL_PANEL,
        panel: GslPanel,
        isRelevant: (finding: IKustoEvent) =>
            isPanelRelevant(finding, FindingOverviewPanelsIds.GSL_PANEL) &&
            !(finding.additionalFieldsMap?.showQuery === '0'),
    },
    ALERT_ID_PANEL: {
        panelId: FindingOverviewPanelsIds.ALERT_ID_PANEL,
        panel: AlertIdPanel,
        isRelevant: (finding: IKustoEvent) => !isEmptyString(finding.findingKey),
    },
    COMMENTS_PANEL: {
        panelId: FindingOverviewPanelsIds.COMMENTS_PANEL,
        panel: FindingComments,
        isRelevant: (finding: IKustoEvent) => !isEmpty(finding.comments),
    },
    ASSET_PANEL: {
        panelId: FindingOverviewPanelsIds.ASSET_PANEL,
        panel: ({ event }) => {
            const FindingAssetPanelComponent: React.FC<{ event: IEvent }> = ({ event }) => (
                <FindingAssetPanel
                    cloudAccountId={event.cloudAccountId}
                    cloudAccountType={event.cloudAccountType}
                    entityDome9Id={event.entityDome9Id}
                    entityExternalId={event.entityExternalId}
                    entityType={event.entityType}
                    entityObject={event.entityObject}
                    entityName={event.entityName}
                />
            );
            return <FindingAssetPanelComponent event={event} />;
        },
        isRelevant: () => true,
    },
};
