import { ColDef, ICellRendererParams } from 'ag-grid-community';
import IconLinkCellRenderer from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { Chip /*CountBySeverity*/ } from 'common/design-system/components-v2';
import { SeverityLevelIconPropsLevel } from 'common/design-system/components-v2/LevelIcon/LevelIcon.types';
import { Context } from 'common/design-system/theme/colors/colors.types';
// import { ColorsWithState } from 'common/design-system/theme/theme';
import { capitalizeWords } from 'common/utils/helpFunctions';
import { TAssetData } from '../HostConfiguration.types';
import { openRuleDrawer } from '../HostConfiguration.utils';
// import SeverityCellRenderer from 'modules/workloads/CellRenders/SeverityCellRenderer/SeverityCellRenderer';

export const rulesTableColDefs = (host: TAssetData): Array<ColDef> => {
    const columnDefs: Array<ColDef> = [
        {
            headerName: 'Rule ID',
            field: 'ruleId',
            enableRowGroup: true,
            valueGetter: (params) => {
                if (!params.data) return;
                return `${params.data.ruleId}`;
            },
            cellRenderer: ({ data, value }: ICellRendererParams) => {
                if (!data && !value) return null;
                if (!data && value) return value;

                const openDrawer = openRuleDrawer({
                    assetId: host.assetId,
                    ruleId: data.ruleId,
                    provider: host.provider,
                    cloudAccountId: host.cloudAccountId,
                });

                return (
                    <IconLinkCellRenderer
                        value={value}
                        isLink={true}
                        onClick={openDrawer}
                        levelIconProps={{
                            iconProps: { name: 'rule' },
                            level: data.severity as SeverityLevelIconPropsLevel,
                            size: 'md',
                            category: 'severity',
                        }}
                    />
                );
            },
            width: 300,
            sortable: true,
        },
        {
            headerName: 'Rule Name',
            field: 'title',
            enableRowGroup: true,
            width: 420,
            sortable: true,
        },
        // Re-enable this column when the status field is available in the API
        // {
        //     headerName: 'Severity',
        //     field: 'severity',
        //     maxWidth: 120,
        //     enableRowGroup: true,
        //     sortable: true,

        //     cellRenderer: (params: any) => {
        //         if(params.node.group) {
        //             const allLeafChildData = params.node.allLeafChildren.map(child => child.data);
        //             const aggCountSeverity = countBySeverity(allLeafChildData) as Array<{ severity: Context, count: number }>;
        //             return <CountBySeverity severityCounts={addSeverityIfNotExists(aggCountSeverity)}/>;
        //         }

        //         return <SeverityCellRenderer {...params} />
        //     },
        // },
        {
            headerName: 'Status',
            field: 'status',
            enableRowGroup: true,
            valueGetter: (params) => {
                console.log({ params });
                if (!params.data) return;
                return capitalizeWords(params.data.status) || '';
            },
            cellRenderer: ({ data, value }: ICellRendererParams) => {
                if (!data && !value) return null;
                if (!data && value) return value;

                let statusContext: Context | undefined;

                switch (value.toLowerCase()) {
                    case 'pass':
                        statusContext = 'lowRisk';
                        break;
                    case 'fail':
                        statusContext = 'high';
                        break;
                    default:
                        statusContext = 'info';
                }

                return <Chip label={value} context={statusContext} />;
            },
            maxWidth: 100,
            sortable: true,
        },
        {
            headerName: 'Description',
            tooltipValueGetter: (params) => params.data?.description,
            field: 'description',
            enableRowGroup: false,
            hide: true,
            sortable: false,
        },
    ];

    return columnDefs;
};
