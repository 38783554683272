import React from 'react';
import { TEmptyStateWrapperProps } from './EmptyStateWrapper.types';
import EmptyState from 'common/components/EmptyState';

const EmptyStateWrapper: React.FC<TEmptyStateWrapperProps> = ({
    iconSize = 96,
    fontVariant = '2xl',
    iconName = 'containerImage',
    description = 'No image found for this workload',
    label = 'Error to fetch images',
}) => {
    return (
        <EmptyState
            iconSize={iconSize}
            fontVariant={fontVariant}
            iconName={iconName}
            description={description}
            label={label}
        />
    );
};

export default EmptyStateWrapper;
