type OrderDirection = 'asc' | 'desc';
interface BuildOptions {
    stringify?: boolean;
}

class GslQuery {
    private queryParts: {
        source: string;
        conditions: string;
        selections: string[];
        order: string;
        groupBy: string;
        summarize: string[];
    };

    constructor() {
        this.queryParts = {
            source: '',
            conditions: '',
            groupBy: '',
            selections: [],
            order: '',
            summarize: [],
        };
    }

    from(source: string): GslQuery {
        this.queryParts.source = source;
        return this;
    }

    where(condition: string): GslQuery {
        this.queryParts.conditions += this.queryParts.conditions ? ` and ${condition}` : condition;
        return this;
    }

    bulkOr(conditions: string[]): GslQuery {
        this.queryParts.conditions += this.queryParts.conditions
            ? ` and (${conditions.join(' or ')})`
            : conditions.join(' or ');
        return this;
    }

    or(condition: string): GslQuery {
        this.queryParts.conditions += ` or ${condition}`;
        return this;
    }

    select(...fields: string[]): GslQuery {
        this.queryParts.selections = fields;
        return this;
    }

    orderBy(field: string, direction: OrderDirection = 'asc'): GslQuery {
        this.queryParts.order = `${field} ${direction}`;
        return this;
    }

    groupBy(field: string): GslQuery {
        this.queryParts.groupBy = `summarize count() as Count by ${field} order by Count select ${field} , Count`;
        return this;
    }

    summarize(...summarizeFields: string[]): GslQuery {
        this.queryParts.summarize = summarizeFields;
        return this;
    }

    build(buildOptions?: BuildOptions): string {
        const sourcePart = `${this.queryParts.source}`;
        const wherePart = this.queryParts.conditions ? `where ${this.queryParts.conditions} ` : '';
        const summarizePart =
            this.queryParts.summarize.length > 0 ? `summarize ${this.queryParts.summarize.join(', ')} ` : '';
        const groupByPart = this.queryParts.groupBy ? this.queryParts.groupBy : '';
        const selectPart =
            this.queryParts.selections.length > 0 && !groupByPart
                ? `select ${this.queryParts.selections.join(', ')} `
                : '';
        const orderPart = this.queryParts.order ? `order by ${this.queryParts.order}` : '';

        const query = `${sourcePart} ${wherePart}${summarizePart}${groupByPart}${selectPart}${orderPart}`.trim();
        // stringify the query if buildOptions.stringify is true to escape backslashes when sending to the API
        return buildOptions?.stringify ? JSON.stringify(query) : query;
    }
}

export default GslQuery;
