import React, { useEffect, useState } from 'react';
import Stack from 'common/design-system/components-v2/Stack';
import ScheduleReportTimeSelection from './ScheduleReportTimeSelection';
import { Typography } from 'common/design-system/components-v2';
import DropDown from '../../DropDown/DropDown';
import { useTranslation } from 'react-i18next';
import { I18nNotifications } from '../../../NotificationsPage.consts';
import { IListItemProps } from 'common/design-system/components-v2/List/List.types';
import { IConfiguration } from 'common/module_interface/settings/integrations/Integrations';
import { ADD_NEW_CONFIGURATION_ID } from '../../utils';

interface IScheduleReportProps {
    configurationsList: IConfiguration[];
    scheduleReportSelections?: IScheduleReportObject;
    onAddEmailConfigurationClicked: () => void;
    onScheduleReportChanged: (scheduleReportSelections: IScheduleReportObject) => void;
    isReadOnly?: boolean;
}

export interface IScheduleReportObject {
    cronExpression: string;
    configurationId: string;
    outputType: string;
}
const ScheduleReport: React.FC<IScheduleReportProps> = ({
    configurationsList,
    onAddEmailConfigurationClicked,
    onScheduleReportChanged,
    scheduleReportSelections,
    isReadOnly = false,
}) => {
    const [scheduleReportObject, setScheduleReportObject] = useState(
        scheduleReportSelections || ({} as IScheduleReportObject),
    );
    const { t } = useTranslation(I18nNotifications);
    const onTimeChanged = (cronExpression: string) => {
        setScheduleReportObject({ ...scheduleReportObject, cronExpression });
    };

    const onReportTypeSelected = (selectedReportType: IListItemProps) => {
        setScheduleReportObject({ ...scheduleReportObject, outputType: selectedReportType.value });
    };

    useEffect(() => {
        onScheduleReportChanged(scheduleReportObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduleReportObject]);

    const reportTypeList: IListItemProps[] = [
        { value: 'Summary', label: t('MODALS.ADD_EDIT_MODAL.TYPES.SUMMARY') },
        { value: 'Detailed', label: t('MODALS.ADD_EDIT_MODAL.TYPES.DETAILED') },
        { value: 'FullCsv', label: t('MODALS.ADD_EDIT_MODAL.TYPES.FULL_CSV') },
        { value: 'FullCsvZip', label: t('MODALS.ADD_EDIT_MODAL.TYPES.FULL_CSV_ZIPPED') },
        { value: 'ExecutivePlatform', label: t('MODALS.ADD_EDIT_MODAL.TYPES.EXECUTIVE_SUMMARY') },
    ];

    const getConfigurationsList = (): IListItemProps[] => {
        const configurations = [
            {
                label: t('MODALS.ADD_EDIT_MODAL.ADD_NEW_CONFIGURATION'),
                value: ADD_NEW_CONFIGURATION_ID,
                onClick: () => onAddEmailConfigurationClicked(),
            },
        ];
        configurationsList?.forEach((configuration) => {
            configurations.push({
                label: configuration.name,
                value: configuration.id!,
                onClick: () => {
                    setScheduleReportObject({ ...scheduleReportObject, configurationId: configuration.id! });
                },
            });
        });
        return configurations;
    };

    return (
        <Stack flexWrap={true} spacing={2}>
            <ScheduleReportTimeSelection
                onTimeSelectionChangedCallBack={onTimeChanged}
                selectedCronExpression={scheduleReportObject.cronExpression}
                isReadOnly={isReadOnly}
            ></ScheduleReportTimeSelection>
            <Typography>{t('MODALS.ADD_EDIT_MODAL.SELECT_CONFIGURATION', { selectedIntegration: 'Email' })}</Typography>
            <DropDown
                initialSelectedOption={scheduleReportObject.configurationId || ''}
                placeHolder={t('MODALS.ADD_EDIT_MODAL.SELECT_CONFIGURATION_GENERAL')}
                options={getConfigurationsList()}
                buttonProps={{ color: 'normal', variant: 'outlined', size: 'medium', style: { width: '60%' } }}
                placement='bottom-start'
            />
            <Typography>{t('MODALS.ADD_EDIT_MODAL.SELECT_TYPE')}</Typography>
            <DropDown
                initialSelectedOption={scheduleReportObject.outputType}
                placeHolder={t('MODALS.ADD_EDIT_MODAL.SELECT_ITEM')}
                options={reportTypeList}
                buttonProps={{ color: 'normal', variant: 'outlined', size: 'medium', style: { width: '60%' } }}
                placement='bottom-start'
                onSelectionChangedCallBack={onReportTypeSelected}
            />
        </Stack>
    );
};

export default ScheduleReport;
