import { IColumnUsageDef } from 'common/interface/general';
import { LogsColumnTypes as ColumnTypes } from '../Types/LogsColumnTypes';

export const getAccountActivityBaseColumns = (): IColumnUsageDef[] => [
    ColumnTypes.EVENT_TIME,
    ColumnTypes.EVENT_NAME,
    ColumnTypes.IDENTITY_NAME,
    ColumnTypes.ISSUER_NAME,
    ColumnTypes.TARGET_NAME,
];
