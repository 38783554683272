import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const StepNumber = styled(Stack)`
    width: 40px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.surface.secondary};
`;

const PrerequisitesStyled = styled(Stack)`
    cursor: pointer;
`;

export { StepNumber, PrerequisitesStyled };
