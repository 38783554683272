export const allVendorsLogicTypes = {
    flowlogs: {
        action: 'string',
        bytes: 'int',
        direction: 'string',
        dst: {
            address: 'string',
            asset: {
                assetid: 'string',
                availabilityzone: 'string',
                description: 'string',
                image: 'string',
                ispublic: 'bool',
                name: 'string',
                nics: {
                    id: 'string',
                    privateipaddress: 'string',
                    publicdnsname: 'string',
                    publicipaddress: 'string',
                    sgs: [
                        {
                            securityGroupId: 'string',
                            securityGroupName: 'string',
                        },
                    ],
                    subnet: {
                        subnetid: 'string',
                    },
                },
                region: 'string',
                subtype: 'string',
                tags: [
                    {
                        key: 'string',
                        value: 'string',
                    },
                ],
                type: 'string',
                vpc: 'string',
            },
            geolocation: {
                countrycode: 'string',
                countryname: 'string',
            },
            ismalicious: 'bool',
            maliciousinfo: {
                class: 'string',
                confidence: 'string',
                malwarefamily: 'string',
                owner: 'string',
            },
            port: 'int',
        },
        eni: 'string',
        packets: 'int',
        protocol: 'int',
        src: {
            address: 'string',
            asset: {
                assetid: 'string',
                availabilityzone: 'string',
                description: 'string',
                image: 'string',
                ispublic: 'bool',
                name: 'string',
                nics: {
                    id: 'string',
                    privateipaddress: 'string',
                    publicdnsname: 'string',
                    publicipaddress: 'string',
                    sgs: [
                        {
                            securityGroupId: 'string',
                            securityGroupName: 'string',
                        },
                    ],
                    subnet: {
                        subnetid: 'string',
                    },
                },
                region: 'string',
                subtype: 'string',
                tags: [
                    {
                        key: 'string',
                        value: 'string',
                    },
                ],
                type: 'string',
                vpc: 'string',
            },
            geolocation: {
                countrycode: 'string',
                countryname: 'string',
            },
            ismalicious: 'bool',
            maliciousinfo: {
                class: 'string',
                confidence: 'string',
                malwarefamily: 'string',
                owner: 'string',
            },
            port: 'int',
        },
        starttime: 'time',
        vpc: 'string',
    },
    cloudtrail: {
        event: {
            d9readonly: 'bool',
            error: {
                message: 'string',
            },
            name: 'string',
            status: 'string',
            time: 'time',
            type: 'string',
            aws: 'bool',
        },
        identity: {
            account: {
                id: 'string',
            },
            assetid: 'string',
            id: 'string',
            image: 'string',
            name: 'string',
            region: 'string',
            tags: [
                {
                    key: 'string',
                    value: 'string',
                },
            ],
            type: 'string',
            useragent: 'string',
            vpc: 'string',
            awsservice: 'bool',
        },
        issuer: {
            id: 'string',
            mfa: 'bool',
            name: 'string',
            region: 'string',
            sts: {
                token: 'string',
            },
            token: 'string',
            type: 'string',
        },
        request: {
            parameters: 'string',
        },
        response: {
            parameters: 'string',
        },
        src: {
            address: {
                geolocation: {
                    countrycode: 'string',
                    countryname: 'string',
                },
                ip: 'string',
                maliciousinfo: {
                    class: 'string',
                    confidence: 'string',
                    malwarefamily: 'string',
                    owner: 'string',
                },
            },
            assetid: 'string',
            image: 'string',
            region: 'string',
            tags: [
                {
                    key: 'string',
                    value: 'string',
                },
            ],
            type: 'string',
            vpc: 'string',
        },
        target: {
            account: {
                id: 'string',
            },
            arn: 'string',
            assetid: 'string',
            id: 'string',
            image: 'string',
            name: 'string',
            region: 'string',
            tags: [
                {
                    key: 'string',
                    value: 'string',
                },
            ],
            type: 'string',
            vpc: 'string',
            newthirdparties: 'string',
            hasnewthirdparties: 'bool',
        },
    },
    azureflowlogs: {
        action: 'string',
        bytes: 'int',
        direction: 'string',
        dst: {
            address: 'string',
            asset: {
                assetid: 'string',
                ispublic: 'bool',
                name: 'string',
                nics: {
                    id: 'string',
                    privateipaddress: 'string',
                    publicipaddress: 'string',
                },
                region: 'string',
                subtype: 'string',
                type: 'string',
                dome9: {
                    id: 'string',
                },
            },
            geolocation: {
                countrycode: 'string',
                countryname: 'string',
            },
            ismalicious: 'bool',
            maliciousinfo: {
                class: 'string',
                confidence: 'string',
                malwarefamily: 'string',
                owner: 'string',
            },
            port: 'int',
        },
        protocol: 'int',
        src: {
            address: 'string',
            asset: {
                assetid: 'string',
                ispublic: 'bool',
                name: 'string',
                nics: {
                    id: 'string',
                    privateipaddress: 'string',
                    publicipaddress: 'string',
                },
                region: 'string',
                subtype: 'string',
                type: 'string',
                dome9: {
                    id: 'string',
                },
            },
            geolocation: {
                countrycode: 'string',
                countryname: 'string',
            },
            ismalicious: 'bool',
            maliciousinfo: {
                class: 'string',
                confidence: 'string',
                malwarefamily: 'string',
                owner: 'string',
            },
            port: 'int',
        },
        starttime: 'time',
        category: 'string',
        event: {
            name: 'string',
        },
        macaddress: 'string',
        stream: {
            owner: 'string',
        },
        systemid: 'string',
        account: 'string',
    },
    flowlogskubernets: {
        action: 'string',
        bytes: 'int',
        direction: 'string',
        dst: {
            address: 'string',
            asset: {
                assetid: 'string',
                image: 'string',
                ispublic: 'bool',
                name: 'string',
                nics: {
                    id: 'string',
                    privateipaddress: 'string',
                    publicdnsname: 'string',
                    publicipaddress: 'string',
                },
                region: 'string',
                subtype: 'string',
                type: 'string',
                clusterid: 'string',
                labels: [
                    {
                        key: 'string',
                        value: 'string',
                    },
                ],
                namespace: 'string',
                nodeName: 'string',
                k8sServices: 'string',
                controllergroups: {
                    name: 'string',
                    type: 'string',
                },
            },
            geolocation: {
                countrycode: 'string',
                countryname: 'string',
            },
            ismalicious: 'bool',
            maliciousinfo: {
                class: 'string',
                confidence: 'string',
                malwarefamily: 'string',
                owner: 'string',
            },
            port: 'int',
        },
        eni: 'string',
        packets: 'int',
        protocol: 'int',
        src: {
            address: 'string',
            asset: {
                assetid: 'string',
                image: 'string',
                ispublic: 'bool',
                name: 'string',
                nics: {
                    id: 'string',
                    privateipaddress: 'string',
                    publicdnsname: 'string',
                    publicipaddress: 'string',
                },
                region: 'string',
                subtype: 'string',
                type: 'string',
                controllergroups: {
                    name: 'string',
                    type: 'string',
                },
                clusterid: 'string',
                nodeName: 'string',
                k8sServices: 'string',
                labels: [
                    {
                        key: 'string',
                        value: 'string',
                    },
                ],
                namespace: 'string',
            },
            geolocation: {
                countrycode: 'string',
                countryname: 'string',
            },
            ismalicious: 'bool',
            maliciousinfo: {
                class: 'string',
                confidence: 'string',
                malwarefamily: 'string',
                owner: 'string',
            },
            port: 'int',
        },
        starttime: 'time',
        vpc: 'string',
    },
    azurecloudtrail: {
        event: {
            d9readonly: 'bool',
            error: {
                message: 'string',
            },
            name: 'string',
            status: 'string',
            time: 'time',
            type: 'string',
        },
        identity: {
            account: {
                id: 'string',
            },
            assetid: 'string',
            id: 'string',
            image: 'string',
            name: 'string',
            region: 'string',
            tags: [
                {
                    key: 'string',
                    value: 'string',
                },
            ],
            type: 'string',
            useragent: 'string',
            vpc: 'string',
        },
        issuer: {
            id: 'string',
            mfa: 'bool',
            name: 'string',
            region: 'string',
            sts: {
                token: 'string',
            },
            token: 'string',
            type: 'string',
        },
        request: {
            parameters: 'string',
        },
        response: {
            parameters: 'string',
        },
        src: {
            address: {
                geolocation: {
                    countrycode: 'string',
                    countryname: 'string',
                },
                ip: 'string',
                maliciousinfo: {
                    class: 'string',
                    confidence: 'string',
                    malwarefamily: 'string',
                    owner: 'string',
                },
                ismalicious: 'bool',
            },
            assetid: 'string',
            image: 'string',
            region: 'string',
            tags: [
                {
                    key: 'string',
                    value: 'string',
                },
            ],
            type: 'string',
            vpc: 'string',
        },
        target: {
            account: {
                id: 'string',
            },
            arn: 'string',
            assetid: 'string',
            id: 'string',
            image: 'string',
            name: 'string',
            region: 'string',
            tags: [
                {
                    key: 'string',
                    value: 'string',
                },
            ],
            type: 'string',
            vpc: 'string',
        },
    },
    alibabaactiontrail: {
        event: {
            d9readonly: 'bool',
            error: {
                message: 'string',
            },
            name: 'string',
            status: 'string',
            time: 'time',
            type: 'string',
        },
        identity: {
            account: {
                id: 'string',
            },
            assetid: 'string',
            id: 'string',
            image: 'string',
            name: 'string',
            region: 'string',
            tags: 'json',
            type: 'string',
            useragent: 'string',
            vpc: 'string',
        },
        issuer: {
            id: 'string',
            mfa: 'bool',
            name: 'string',
            region: 'string',
            sts: {
                token: 'string',
            },
            token: 'string',
            type: 'string',
        },
        request: {
            parameters: 'string',
        },
        response: {
            parameters: 'string',
        },
        src: {
            address: {
                geolocation: {
                    countrycode: 'string',
                    countryname: 'string',
                },
                ip: 'string',
                maliciousinfo: {
                    class: 'string',
                    confidence: 'string',
                    malwarefamily: 'string',
                    owner: 'string',
                },
            },
            assetid: 'string',
            image: 'string',
            region: 'string',
            tags: [
                {
                    key: 'string',
                    value: 'string',
                },
            ],
            type: 'string',
            vpc: 'string',
        },
        target: {
            account: {
                id: 'string',
            },
            arn: 'string',
            assetid: 'string',
            id: 'string',
            image: 'string',
            name: 'string',
            region: 'string',
            tags: 'json',
            type: 'string',
            vpc: 'string',
        },
    },
    gcpactivity: {
        event: {
            d9readonly: 'bool',
            error: {
                message: 'string',
            },
            name: 'string',
            status: 'string',
            time: 'time',
            type: 'string',
        },
        identity: {
            account: {
                id: 'string',
            },
            assetid: 'string',
            id: 'string',
            image: 'string',
            name: 'string',
            region: 'string',
            tags: [
                {
                    key: 'string',
                    value: 'string',
                },
            ],
            type: 'string',
            useragent: 'string',
            vpc: 'string',
            awsservice: 'bool',
        },
        issuer: {
            id: 'string',
            mfa: 'bool',
            name: 'string',
            region: 'string',
            sts: {
                token: 'string',
            },
            token: 'string',
            type: 'string',
        },
        request: {
            parameters: 'string',
        },
        response: {
            parameters: 'string',
        },
        src: {
            address: {
                geolocation: {
                    countrycode: 'string',
                    countryname: 'string',
                },
                ip: 'string',
                maliciousinfo: {
                    class: 'string',
                    confidence: 'string',
                    malwarefamily: 'string',
                    owner: 'string',
                },
            },
            assetid: 'string',
            image: 'string',
            region: 'string',
            tags: [
                {
                    key: 'string',
                    value: 'string',
                },
            ],
            type: 'string',
            vpc: 'string',
        },
        target: {
            account: {
                id: 'string',
            },
            arn: 'string',
            assetid: 'string',
            id: 'string',
            image: 'string',
            name: 'string',
            region: 'string',
            tags: [
                {
                    key: 'string',
                    value: 'string',
                },
            ],
            type: 'string',
            vpc: 'string',
        },
    },
    gcpflowlogs: {
        action: 'string',
        bytes: 'int',
        direction: 'string',
        dst: {
            address: 'string',
            asset: {
                assetid: 'string',
                image: 'string',
                ispublic: 'bool',
                name: 'string',
                nics: {
                    id: 'string',
                    privateipaddress: 'string',
                    publicipaddress: 'string',
                },
                region: 'string',
                subtype: 'string',
                tags: [
                    {
                        key: 'string',
                        value: 'string',
                    },
                ],
                type: 'string',
                vpc: 'string',
                clusterid: 'string',
                dome9: {
                    id: 'string',
                },
                k8sServices: 'string',
                namespace: 'string',
                nodeName: 'string',
            },
            geolocation: {
                countrycode: 'string',
                countryname: 'string',
            },
            ismalicious: 'bool',
            maliciousinfo: {
                class: 'string',
                malwarefamily: 'string',
                owner: 'string',
            },
            port: 'int',
        },
        packets: 'int',
        protocol: 'int',
        src: {
            address: 'string',
            asset: {
                assetid: 'string',
                image: 'string',
                ispublic: 'bool',
                name: 'string',
                nics: {
                    id: 'string',
                    privateipaddress: 'string',
                    publicipaddress: 'string',
                },
                region: 'string',
                subtype: 'string',
                tags: [
                    {
                        key: 'string',
                        value: 'string',
                    },
                ],
                type: 'string',
                vpc: 'string',
                k8sServices: 'string',
                namespace: 'string',
                nodeName: 'string',
                clusterid: 'string',
                dome9: {
                    id: 'string',
                },
            },
            geolocation: {
                countrycode: 'string',
                countryname: 'string',
            },
            ismalicious: 'bool',
            maliciousinfo: {
                class: 'string',
                malwarefamily: 'string',
                owner: 'string',
            },
            port: 'int',
        },
        starttime: 'time',
        endtime: 'time',
        event: {
            date: 'string',
        },
    },
    alerts: {
        id: 'string',
        findingKey: 'string',
        accountId: 'string',
        bundle: {
            id: 'string',
            name: 'string',
            description: 'string',
        },
        env: {
            cloudAccountName: 'string',
            cloudAccountId: {
                name: 'string',
            },
            organizationalUnitId: 'string',
            organizationalUnitPath: 'string',
            externalAccountId: 'string',
            cloudAccountType: 'string',
        },
        assessmentRunTime: 'string',
        assessmentHistoryId: 'string',
        rule: {
            name: 'string',
            ruleId: 'string',
            logic: 'string',
            logicHash: 'string',
            description: 'string',
            remediation: 'string',
            complianceTags: 'string',
            severity: {
                name: 'string',
            },
        },
        entity: {
            type: 'string',
            name: 'string',
            region: 'string',
            network: 'string',
            dome9Id: 'string',
            tags: 'string',
            id: 'string',
        },
        origin: {
            resolved: 'string',
        },
        status: {
            name: 'string',
        },
        statusReason: {
            name: 'string',
        },
        starttime: 'string',
        min: {
            starttime: 'string',
        },
        action: 'string',
        category: 'string',
        isExcluded: 'bool',
        magellan: {
            activity: {
                identity: {
                    id: 'string',
                    name: 'string',
                    type: 'string',
                },
                target: {
                    id: 'string',
                    name: 'string',
                    type: 'string',
                },
            },
            network: {
                src: {
                    id: 'string',
                    name: 'string',
                    type: 'string',
                    address: 'string',
                    port: 'string',
                },
                dst: {
                    id: 'string',
                    name: 'string',
                    type: 'string',
                    address: 'string',
                    port: 'string',
                },
            },
        },
        labels: 'string',
    },
    auditsystem: {
        description: 'string',
        time: 'time',
        event: {
            type: 'string',
            name: 'string',
        },
        cloud: {
            account: {
                id: 'string',
            },
        },
        user: {
            name: 'string',
        },
    },
    auditapi: {
        user: {
            name: 'string',
        },
        request: {
            url: 'string',
            body: 'string',
            parameters: 'string',
        },
        http: {
            method: 'string',
            status: 'string',
        },
        client: {
            ip: 'string',
        },
        time: 'time',
        event: {
            type: 'string',
            name: 'string',
        },
    },
};
