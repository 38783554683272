import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../../../../consts';
import { EditorStyled } from '../../AutoActionEditor.styled';
import { AutoActionTriggerEnum } from 'common/module_interface/RiskManagement/autoActions/AutoActions';
import { IWhenProps } from '../../AutoActionEditor.interface';
import { getTriggersErrorMsg } from '../../AutoActionEditor.utils';
import { errorPropsHasMessages, getAllTriggerSelectOptions } from '../../../AutoActions.utils';
import { SelectV2 } from 'common/design-system/components-v2';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { useIsReadOnlyAutoActions } from '../../../useIsAutoActionReadonly';

interface IWhenErrors {
    triggersErrorMsg?: string;
}

export const AutoActionEditorWhen: React.FC<{
    exposeErrors: boolean;
    whenProps: IWhenProps;
    setWhenProps: (props: IWhenProps) => void;
}> = ({ exposeErrors, whenProps, setWhenProps }) => {
    const isReadOnly = useIsReadOnlyAutoActions();
    const errorsRef = useRef<IWhenErrors>({});
    const triggersRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation(I18nRiskNamespace);
    const triggerOptions: SelectOption[] = getAllTriggerSelectOptions();
    const updateErrors = useCallback(
        (theWhenProps: IWhenProps) => {
            errorsRef.current.triggersErrorMsg = getTriggersErrorMsg(theWhenProps.triggers);
            whenProps.errorElementRefs =
                errorsRef.current.triggersErrorMsg && triggersRef.current ? [triggersRef.current] : [];
            theWhenProps.hasErrors = errorPropsHasMessages(errorsRef.current);
        },
        [whenProps],
    );

    const onTriggersChange = useCallback(
        (triggers: AutoActionTriggerEnum[]) => {
            const newProps = {
                ...whenProps,
                triggers,
            };
            updateErrors(newProps);
            setWhenProps(newProps);
        },
        [whenProps, updateErrors, setWhenProps],
    );

    const isError: boolean = useMemo(() => !!(exposeErrors && whenProps.hasErrors), [exposeErrors, whenProps]);
    const helperText: string | undefined = useMemo(
        () => (isError ? errorsRef.current.triggersErrorMsg : undefined),
        [isError],
    );

    useEffect(() => {
        updateErrors(whenProps);
    }, [updateErrors, whenProps]);

    return (
        <EditorStyled.PanelTopDiv>
            <EditorStyled.PanelLeftDiv>
                <EditorStyled.CategoryElementWrapper>
                    <EditorStyled.CategoryChipDiv label={t('AUTO_ACTIONS.EDITOR.WHEN.WHEN')} />
                </EditorStyled.CategoryElementWrapper>
            </EditorStyled.PanelLeftDiv>
            <EditorStyled.PanelRightDiv>
                <EditorStyled.CategoryHeaderDiv>
                    <EditorStyled.CategoryElementWrapper>
                        <EditorStyled.LabelDiv required text={t('AUTO_ACTIONS.EDITOR.WHEN.WHEN_PREFIX')} />
                    </EditorStyled.CategoryElementWrapper>
                    <EditorStyled.SelectWrapperDiv>
                        <SelectV2
                            options={triggerOptions}
                            onChange={(values: string[]) => onTriggersChange(values as AutoActionTriggerEnum[])}
                            isMulti={true}
                            value={whenProps.triggers}
                            clearable
                            placeholder={
                                isReadOnly ? undefined : t('AUTO_ACTIONS.EDITOR.WHEN.TRIGGER_SELECTOR_PLACEHOLDER')
                            }
                            isError={isError}
                            helperText={isError ? helperText : undefined}
                            ref={triggersRef}
                            fullWidth={true}
                            readOnly={isReadOnly}
                        />
                    </EditorStyled.SelectWrapperDiv>
                </EditorStyled.CategoryHeaderDiv>
            </EditorStyled.PanelRightDiv>
        </EditorStyled.PanelTopDiv>
    );
};
