import { IColumnUsageDef } from 'common/interface/general';
import { LogFields } from 'common/module_interface/intelligence/Logs/Logs.interface';

export const LogsColumnTypes: { [key: string]: IColumnUsageDef } = {
    EVENT_TIME: {
        id: LogFields.eventTime,
        colDefOverride: {
            flex: 3,
        },
    },
    EVENT_NAME: {
        id: LogFields.eventName,
        colDefOverride: {
            flex: 4,
        },
    },
    IDENTITY_NAME: {
        id: LogFields.identityName,
        colDefOverride: {
            flex: 3,
        },
    },
    IDENTITY_USERAGENT: {
        id: LogFields.identityUserAgent,
        colDefOverride: {
            flex: 3,
        },
    },
    ISSUER_NAME: {
        id: LogFields.issuerName,
        colDefOverride: {
            flex: 3,
        },
    },
    TARGET_NAME: {
        id: LogFields.targetName,
        colDefOverride: {
            flex: 3,
        },
    },
    TARGET_NAMESPACE: {
        id: LogFields.targetNamespace,
        colDefOverride: {
            flex: 3,
        },
    },
    EVENT_STATUS: {
        id: LogFields.eventStatus,
        colDefOverride: {
            flex: 2,
        },
    },
    SOURCE_IP: {
        id: LogFields.sourceIp,
        colDefOverride: {
            flex: 3,
        },
    },
    SOURCE_COUNTRY: {
        id: LogFields.sourceCountry,
        colDefOverride: {
            flex: 2,
        },
    },
};
