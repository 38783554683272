import { IIssueExclusionProps } from '../IssueExclusions.interface';
import { convertSeverityKeysToLevels, convertSeverityLevelsToKeys } from '../../Issues/IssueSeverity';
import {
    convertValuesToTags,
    getTrimmedMultiValue,
    getSafeTrimmedMultiValue,
    getSafeTrimmedSingleValue,
    convertValueToDate,
    getTrimmedSingleValue,
} from 'common/erm-components/custom/CustomForm/CustomForm.values';
import {
    IItemChanges,
    IItemsMap,
    IOrgUnitsItem,
    IOrgUnitsState,
    ISingleDateItem,
    ISingleDateState,
} from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import { IssueExclusionItemNames } from './IssueExclusionEditor.consts';
import { isPassedDate } from 'common/erm-components/utils/dates';
import { ermTrans } from '../../../RiskManagement.utils';
import i18n from 'i18next';
import { isRootOrgUnitSelected } from './IssueExclusionEditor.validations';
import { IOrganizationalUnit } from 'common/interface/data_services';

export const severityKeysToLevels = (values?: string[]): number[] => {
    if (values) {
        return convertSeverityKeysToLevels(values);
    }
    return [];
};

export const severityLevelsToKeys = (severityLevels?: number[]): string[] | undefined => {
    if (severityLevels) {
        return convertSeverityLevelsToKeys(severityLevels);
    }
};

export const getSafeName = (itemsMap: IItemsMap): string => {
    return getSafeTrimmedSingleValue(itemsMap[IssueExclusionItemNames.name]);
};

export const getExclusionPropsFromValues = (itemsMap: IItemsMap): IIssueExclusionProps => {
    return {
        name: getSafeTrimmedSingleValue(itemsMap[IssueExclusionItemNames.name]),
        description: getSafeTrimmedSingleValue(itemsMap[IssueExclusionItemNames.description]),
        ruleIds: getSafeTrimmedMultiValue(itemsMap[IssueExclusionItemNames.rules]),
        severities: severityKeysToLevels(getTrimmedMultiValue(itemsMap[IssueExclusionItemNames.severities])),
        entityTypes: getSafeTrimmedMultiValue(itemsMap[IssueExclusionItemNames.entityTypes]),
        envIds: getSafeTrimmedMultiValue(itemsMap[IssueExclusionItemNames.environments]),
        orgUnitIds: getSafeTrimmedMultiValue(itemsMap[IssueExclusionItemNames.orgUnits]),
        entityNames: getSafeTrimmedMultiValue(itemsMap[IssueExclusionItemNames.entityNames]),
        entityId: getSafeTrimmedSingleValue(itemsMap[IssueExclusionItemNames.entityId]),
        expirationDate: convertValueToDate(getTrimmedSingleValue(itemsMap[IssueExclusionItemNames.expirationDate])),
        tags: convertValuesToTags(getTrimmedMultiValue(itemsMap[IssueExclusionItemNames.tags])),
    };
};

export const getIssueExpirationDateLabel = (expired: boolean): string => {
    return ermTrans('ISSUE_EXCLUSIONS.EDITOR.EXPIRATION_DATE', {
        suffix: expired ? i18n.t('COMMON.EXPIRED_SUFFIX') : '',
    });
};

export const getNewIssueExpirationDateState = (
    item: ISingleDateItem,
    changeDetails: IItemChanges,
    itemsMap: IItemsMap,
): Promise<ISingleDateState | undefined> => {
    if (changeDetails.newItem.name !== IssueExclusionItemNames.expirationDate) {
        return Promise.resolve(undefined);
    }

    const date: Date | undefined = convertValueToDate(
        getTrimmedSingleValue(itemsMap[IssueExclusionItemNames.expirationDate]),
    );
    const expired = isPassedDate(date);

    return Promise.resolve({
        ...item.state,
        label: getIssueExpirationDateLabel(expired),
        labelProps: { color: expired ? 'alert' : undefined },
    });
};

export const getNewOrgIdsState = (
    item: IOrgUnitsItem,
    changeDetails: IItemChanges,
    itemsMap: IItemsMap,
    orgUnitsRoot: IOrganizationalUnit,
): Promise<IOrgUnitsState | undefined> => {
    if (changeDetails.newItem.name !== IssueExclusionItemNames.orgUnits) {
        return Promise.resolve(undefined);
    }

    return Promise.resolve({
        ...item.state,
        helpText: isRootOrgUnitSelected(itemsMap, orgUnitsRoot)
            ? ermTrans('ISSUE_EXCLUSIONS.EDITOR.ORGANIZATIONAL_UNIT.HELPER_TEXT')
            : '',
    });
};
