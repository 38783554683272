import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'common/services/store/store';

interface BreadcrumbsState {
    breadcrumbsLastChildren: Array<string> | undefined;
}

const initialState: BreadcrumbsState = {
    breadcrumbsLastChildren: undefined,
};

export const breadcrumbsSlice = createSlice({
    name: 'breadcrumbsState',
    initialState,
    reducers: {
        setBreadcrumbsLastChildren: (state: BreadcrumbsState, action: PayloadAction<Array<string>>) => {
            state.breadcrumbsLastChildren = action.payload;
        },
    },
});

export const { setBreadcrumbsLastChildren } = breadcrumbsSlice.actions;

export const getBreadcrumbsLastChildren = (state: RootState): Array<string> | undefined =>
    state.breadcrumbsState.breadcrumbsLastChildren;

export default breadcrumbsSlice.reducer;
