import React from 'react';
import IconLinkCellRenderer from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { changeUrl } from 'common/utils/http';

interface ImageShaLinkProps {
    field: string;
    imageEntityId: string;
}

const ImageShaLink: React.FC<ImageShaLinkProps> = ({ field, imageEntityId }) => {
    const linkToImage = `/workload/images?filterPanel={"additionalFields|ImageId":"${imageEntityId}"}`;
    return (
        <IconLinkCellRenderer
            isLink={!!imageEntityId}
            onClick={() => changeUrl(linkToImage)}
            value={field}
            tooltip={field}
        />
    );
};

export default ImageShaLink;
