import React from 'react';
import WorkloadService from '../workload.service';
import { ImageAssuraceScanImage } from '../workload.interface';

export interface UseWorkloadImageScanReq {
    onError?: (error: { message: string }) => void;
    onSuccess?: () => void;
}

export interface UseWorkloadImageScanPayload {
    isLoading: boolean;
    isRequestSuccess: boolean;
    isError?: { message: string };
    scanImage: (request: ImageAssuraceScanImage.Request) => Promise<void>;
    reset?: () => void;
}

export type UseWorkloadImageScan = (req?: UseWorkloadImageScanReq) => UseWorkloadImageScanPayload;

export const useWorkloadImageScan: UseWorkloadImageScan = ({ onSuccess, onError } = {}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = React.useState(false);
    const [isError, setIsError] = React.useState<{ message: string }>();

    const scanImage = React.useCallback(
        async (request: ImageAssuraceScanImage.Request) => {
            setIsLoading(true);
            try {
                await WorkloadService.imageAssuraceScanImage({ request });
                setIsRequestSuccess(true);
                onSuccess?.();
            } catch (error: any) {
                setIsError({ message: error.message });
                onError?.({ message: error.response.data.message });
            }
            setIsLoading(false);
        },
        [onSuccess, onError],
    );

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setIsRequestSuccess(false);
        setIsError(undefined);
    }, []);

    return {
        isLoading,
        isRequestSuccess,
        isError,
        scanImage,
        reset,
    };
};
