import React, { useMemo } from 'react';
import { WorkloadTypes } from 'modules/workloads/services/workload/workload.interface';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import Vulnerability from './Vulnerability';
import VulnerabilityWorkloads from './VulnerabilityWorkloads/VulnerabilityWorkloads';
import { AssetTypes } from 'common/assets/assets.const';

const VulnerabilityWrapper: React.FC<{ entity: ICloudEntityData }> = (props) => {
    const {
        entity: {
            cloudAccountId,
            protectedAsset: { type, id: dome9Id },
            typeByPlatform,
            entityId,
            cloudEntity: { id: cloudEntityId },
        },
    } = props;

    const isWorkloadType = useMemo(() => Object.values(WorkloadTypes).includes(typeByPlatform), [typeByPlatform]);

    const id = typeByPlatform === AssetTypes.AWS_LAMBDA ? cloudEntityId : entityId;

    return isWorkloadType ? (
        <VulnerabilityWorkloads environmentId={cloudAccountId} dome9Id={dome9Id} />
    ) : (
        <Vulnerability type={type} id={id} environmentId={cloudAccountId} />
    );
};

export default VulnerabilityWrapper;
