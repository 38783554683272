import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { I18nExclusion, modalPrefixAnalytics } from 'common/components/exclusions/helpers/exclusions.consts';
import { Trans, useTranslation } from 'react-i18next';
import { Stack, Message, GroupSelection, Typography, Command } from 'common/design-system/components-v2';
import {
    ExceptionPayloadJson,
    IExclusionModalProps,
    RuntimeFormValue,
} from 'common/components/exclusions/helpers/exclusions.interfaces';
import {
    parseExceptionPayloads,
    saveExclusionRuntimeServerless,
} from 'common/components/exclusions/helpers/exclusions.workload.utils';
import { getNotificationsService } from 'common/interface/services';
import { ExclusionModuleType, IRuntimeRequestPayload } from 'common/interface/exclusion';

const ExclusionsModalRuntimeProtection: FC<IExclusionModalProps> = ({ isOpen, onClose, onSave, exclusion }) => {
    const { t } = useTranslation(I18nExclusion);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const { finding, platform, id: exclusionId } = exclusion || {};

    const { handleSubmit, control } = useForm<RuntimeFormValue>({
        defaultValues: {
            applyTo: 'thisPodGroupOnly',
        },
    });

    const exceptionPayloadJson = React.useMemo<ExceptionPayloadJson | undefined>(() => {
        try {
            if (!finding) return undefined;
            const result = parseExceptionPayloads(finding);
            return result.exceptionPayloads;
        } catch (error) {
            return undefined;
        }
    }, [finding]);

    const saveExclusion = async (data: RuntimeFormValue) => {
        setIsSaveLoading(true);
        const { applyTo } = data;
        try {
            if (!finding || !exceptionPayloadJson) return;
            const requestPayload = {
                payloadsPatternType: exceptionPayloadJson.PayloadsType,
                name: exceptionPayloadJson.Name,
                payloads: exceptionPayloadJson.Payloads.map((pattern) => ({ pattern: pattern, callStackInfo: '' })),
                target: exceptionPayloadJson.Target,
                workloadExternalIds: applyTo === 'allPodsInCluster' ? [] : [finding.entityExternalId],
            };
            const data = {
                cloudAccountId: finding.cloudAccountExternalId,
                vendor: platform,
                requestPayload,
            };
            const saveExclusionResponse = await saveExclusionRuntimeServerless(data as IRuntimeRequestPayload);
            !!saveExclusionResponse && onSave && onSave();
            setIsSaveLoading(false);
            getNotificationsService().info(
                exclusionId
                    ? `${t('MODAL.TOAST.EXCLUSION_SAVE_SUCCESS.EDIT')} ${t('MODAL.TOAST.EXCLUSION_SAVE_SUCCESS.EDIT_DISCLAIMER')}`
                    : `${t('MODAL.TOAST.EXCLUSION_SAVE_SUCCESS.CREATE')} ${t('MODAL.TOAST.EXCLUSION_SAVE_SUCCESS.CREATE_DISCLAIMER')}`,
                '',
            );
        } catch (error: any) {
            getNotificationsService().error(t('MODAL.GENERAL.SAVE_ERROR'), error);
            setIsSaveLoading(false);
        }
    };

    return (
        <Message
            id={`${modalPrefixAnalytics}-${ExclusionModuleType.ContainersRuntimeProtection}`}
            width='lg'
            onClose={onClose}
            isOpen={isOpen}
            title={t('MODAL.TOPICS.EXCLUDE_RUNTIME_PROTECTION.TITLE')}
            cancelBtnText={t('MODAL.FOOTER.CANCEL')}
            onCancel={onClose}
            submitBtnText={t('MODAL.FOOTER.SAVE')}
            onConfirm={handleSubmit((data) => saveExclusion(data))}
            isLoading={isSaveLoading}
        >
            <Stack spacing={3} fullWidth>
                <Typography>
                    <Trans
                        components={{
                            boldText: (
                                <Typography elementType='span' type='key'>
                                    .
                                </Typography>
                            ),
                        }}
                        i18nKey={'MODAL.TOPICS.EXCLUDE_RUNTIME_PROTECTION.TEXT'}
                        ns={I18nExclusion}
                    />
                </Typography>
                <Command
                    text={exceptionPayloadJson?.Payloads?.length ? exceptionPayloadJson.Payloads[0].toString() : ''}
                />
                <Controller
                    name='applyTo'
                    control={control}
                    render={({ field }) => (
                        <GroupSelection
                            label={t('MODAL.TOPICS.EXCLUDE_RUNTIME_PROTECTION.INPUTS.APPLY_TO.LABEL')}
                            options={[
                                {
                                    label: t(
                                        'MODAL.TOPICS.EXCLUDE_RUNTIME_PROTECTION.INPUTS.APPLY_TO.OPTIONS.THIS_POD_GROUP_ONLY',
                                    ),
                                    value: 'thisPodGroupOnly',
                                },
                                {
                                    label: t(
                                        'MODAL.TOPICS.EXCLUDE_RUNTIME_PROTECTION.INPUTS.APPLY_TO.OPTIONS.ALL_PODS_IN_THIS_CLUSTER',
                                    ),
                                    value: 'allPodsInCluster',
                                },
                            ]}
                            {...field}
                        />
                    )}
                />
            </Stack>
        </Message>
    );
};

export default ExclusionsModalRuntimeProtection;
