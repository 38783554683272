import React from 'react';
import { WidgetColumnDashyLine, WidgetColumnTitle, WidgetColumnWrapper } from './WidgetColumn.styled';

const WidgetColumn: React.FC<{ title: string }> = ({ title }) => {
    return (
        <WidgetColumnWrapper>
            <WidgetColumnDashyLine></WidgetColumnDashyLine>
            <WidgetColumnTitle>{title}</WidgetColumnTitle>
            <WidgetColumnDashyLine></WidgetColumnDashyLine>
        </WidgetColumnWrapper>
    );
};

export default WidgetColumn;
