import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import EmptyState from 'common/components/EmptyState';
import { Stack } from 'common/design-system/components-v2';
import { PropsWithChildren } from 'react';
import { AwpHostConfigRuleDrawerProps, EResult } from '../../AwpHostConfigRuleDrawer.types';
import useRule from './useRule.hook';
import { RuleContext } from './RuleContext';

export const RuleProvider: React.FC<PropsWithChildren<AwpHostConfigRuleDrawerProps>> = ({ children, ...props }) => {
    const results = useRule(props);
    if (results.status === EResult.LOADING) {
        return (
            <Stack fullHeight fullWidth>
                <CloudAnimationLoader size='medium' />
            </Stack>
        );
    }
    if (results.status === EResult.ERROR) {
        return (
            <Stack fullHeight fullWidth>
                <EmptyState label='Failed fetching data' iconName='error' description={results.error} />
            </Stack>
        );
    }
    return <RuleContext.Provider value={{ data: results.data }}>{children}</RuleContext.Provider>;
};
