/* eslint-disable-next-line react-hooks/exhaustive-deps */
import { FC, useState } from 'react';
import { PropsValue } from 'react-select';
import { useTranslation } from 'react-i18next';
import { Vendors } from 'common/consts/vendors';
import Modal from 'common/design-system/components-v2/Modal';
import validateWithYup from 'common/helpers/validateWithYup';
import { OptionType } from 'common/design-system/components-v2/Select';
import { IconProps } from 'common/design-system/components-v2/Icon/Icon.types';
import { Button, Label, Select, Spinner, Stack, TextArea, Typography } from 'common/design-system/components-v2';
import { IRemediationModalProps, RuleOption, RulesetOption } from '../helpers/remediation.interfaces';
import { RemediationModuleType, IRemediation, IRemediationCreation } from 'common/interface/remediation';
import { I18nRemediation } from '../helpers/remediation.consts';
import { remediationCiemValidation } from '../helpers/remediation.utils';
import RemediationApi from 'common/services/apis/Remediation/remediationApi.services';
import RemediateByEntityOption from '../Components/RemidiationInputs/RemediateByEntity';
import RemediateBySeverityOption from '../Components/RemidiationInputs/RemediateBySeverity';
import RemediateByEnvironmentOption from '../Components/RemidiationInputs/RemediateByEnvironment';
import CloudBotsSelect from '../Components/CloudBotsSelect/CloudBotsSelect';

const selectCiemRuleSet = (): RulesetOption | null => {
    return {
        label: 'Entitlement Management',
        value: '802',
        platform: Vendors.AWS,
        iconProps: { name: 'remedy' },
    };
};
const RemediationModalCiem: FC<IRemediationModalProps> = ({ isOpen, onClose, onSave, remediation }) => {
    const { t } = useTranslation(I18nRemediation);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaveClicked, setIsSaveClicked] = useState(false);
    const [comment, setComment] = useState(remediation?.comment || '');
    const [isEditMode, setIsEditMode] = useState<boolean>(!!remediation?.id);
    const [selectedRuleSet, setSelectedRuleSet] = useState<RulesetOption | null>(() => selectCiemRuleSet());
    const [rulesetListSelect] = useState<{ label: string; value: string; iconProps?: IconProps }[]>([]);
    const [selectedRule, setSelectedRule] = useState<RuleOption | null>(null);
    const [selectedEnvironment, setSelectedEnvironment] = useState<OptionType | null>(null);
    const [selectedSeverities, setSelectedSeverities] = useState<string[] | null>(remediation?.severities || null);
    const [selectedCloudBots, setSelectedCloudBots] = useState<string[] | null>(null);
    const [selectedEntity, setSelectedEntity] = useState<string[]>();
    const configuredRemediationObject = (): IRemediationCreation => {
        return {
            cloudAccountIds: selectedEnvironment?.value ? [selectedEnvironment?.value] : null,
            cloudAccountType: selectedRuleSet?.platform,
            cloudBots: selectedCloudBots || [],
            comment: comment,
            logicExpressions: selectedEntity || undefined,
            platform: selectedRuleSet?.platform,
            ruleLogicHash: selectedRule?.value,
            ruleName: selectedRule?.label,
            severities: selectedSeverities || undefined,
            rulesetId: parseInt(selectedRuleSet?.value || ''),
        };
    };
    const requestPayload = configuredRemediationObject();
    const formValidations = validateWithYup({
        yupValidationObject: remediationCiemValidation(t),
        payload: { ...requestPayload },
    });

    const onSelectedRuleSet = (newValue: PropsValue<OptionType>) => {
        setIsEditMode(false);
        setSelectedRuleSet(newValue as RulesetOption);
        setSelectedRule(null);
    };
    const onEntityOptionChange = (value: string) => {
        setSelectedEntity([value]);
    };
    const onEnvOptionChange = (value: OptionType) => {
        setSelectedEnvironment(value);
    };
    const onSeverityOptionChange = (value: string[] | null) => {
        setSelectedSeverities(value);
    };
    const onAddCloudBots = (value: string[]) => {
        setSelectedCloudBots(value);
    };

    const saveRemediation = async () => {
        setIsLoading(true);
        try {
            setIsSaveClicked(true);
            if (
                (!selectedRule && !selectedEntity) ||
                !selectedRuleSet?.value ||
                !selectedCloudBots?.length ||
                !!formValidations.errors
            ) {
                setIsLoading(false);
                return;
            }
            if (remediation?.id) {
                const updatedRemediation: IRemediation = { ...remediation, ...requestPayload };
                await RemediationApi.update(remediation?.id, updatedRemediation, RemediationModuleType.CIEM);
            } else await RemediationApi.create({ ...requestPayload }, RemediationModuleType.CIEM);
            onSave && onSave();
        } catch (error) {
            console.error('Error saving remediation: ', error);
            setIsLoading(false);
        }
    };

    return (
        <Modal.ModalDialog isOpen={isOpen} width='lg' shouldCloseOnOverlayClick onRequestClose={onClose}>
            <Modal.ModalHeader
                hasSeparator={true}
                title={
                    remediation?.id
                        ? t('REMEDIATION.MODALS.ADD_EDIT_MODAL.EDIT_TITLE')
                        : t('REMEDIATION.MODALS.ADD_EDIT_MODAL.CREATE_TITLE')
                }
                onClose={onClose}
            />
            <Modal.ModalContent>
                <Stack flexWrap={true} spacing={3}>
                    <Stack spacing={1}>
                        <Label text={t('REMEDIATION.MODALS.ADD_EDIT_MODAL.SELECT_RULESET')} required />
                        <Select
                            options={rulesetListSelect}
                            isMulti={false}
                            value={selectedRuleSet}
                            onChange={onSelectedRuleSet}
                            isReadonly
                        />
                    </Stack>
                    <RemediateByEnvironmentOption
                        formValidations={isSaveClicked ? formValidations.errors?.cloudAccountIds : undefined}
                        isEnabled={!!selectedRuleSet}
                        selectedRuleSet={selectedRuleSet}
                        selectedOption={remediation?.cloudAccountIds?.[0]}
                        onChange={(value) => onEnvOptionChange(value as OptionType)}
                    />
                    <RemediateBySeverityOption
                        formValidations={isSaveClicked ? formValidations.errors?.severities : undefined}
                        isEnabled={!!selectedRuleSet}
                        selectedRuleSet={selectedRuleSet}
                        selectedOption={remediation?.severities}
                        onChange={(value) => onSeverityOptionChange(value)}
                    />
                    <Stack spacing={1}>
                        <RemediateByEntityOption
                            formValidations={isSaveClicked ? formValidations.errors?.logicExpressions : undefined}
                            isEnabled={!!selectedRuleSet}
                            selectedRuleSet={selectedRuleSet}
                            selectedOption={remediation?.logicExpressions?.[0]}
                            onChange={(value) => onEntityOptionChange(value as string)}
                        />
                        {isSaveClicked && !selectedRule && !selectedEntity && (
                            <Typography color='alert' variant='bodyXs'>
                                {t('REMEDIATION.MODALS.ADD_EDIT_MODAL.VALIDATIONS.REMEDIATION_REQUIREMENTS')}
                            </Typography>
                        )}
                    </Stack>
                    <Stack spacing={1}>
                        <CloudBotsSelect
                            formValidations={isSaveClicked ? formValidations.errors?.cloudBots : undefined}
                            isEnabled
                            selectedRuleSet={selectedRuleSet}
                            selectedOption={remediation?.cloudBots}
                            onChange={(value) => onAddCloudBots(value)}
                            isEditMode={isEditMode}
                            isCiem
                        />
                    </Stack>
                    <Stack spacing={1}>
                        <TextArea
                            data-aid='remediation-name-input'
                            label={t('REMEDIATION.MODALS.ADD_EDIT_MODAL.COMMENT')}
                            required
                            value={comment}
                            onChange={(ev) => setComment(ev.target.value)}
                            fullWidth
                            maxLength={200}
                            clearable
                            rows={4}
                            isError={isSaveClicked && !!formValidations.errors?.comment}
                            helperText={
                                isSaveClicked && formValidations.errors?.comment
                                    ? formValidations.errors?.comment?.[0]?.message
                                    : undefined
                            }
                        />
                    </Stack>
                </Stack>
            </Modal.ModalContent>
            <Modal.ModalFooter hasSeparator={true}>
                <Stack direction='row' justifyContent='flex-end' fullWidth spacing={2}>
                    <Button variant='text' onClick={onClose}>
                        {t('REMEDIATION.MODALS.ADD_EDIT_MODAL.BUTTONS.CANCEL')}
                    </Button>
                    <Button color='brandPrimary' onClick={() => saveRemediation()}>
                        {t('REMEDIATION.MODALS.ADD_EDIT_MODAL.BUTTONS.SAVE')}
                        {isLoading && <Spinner size={12} />}
                    </Button>
                </Stack>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    );
};

export default RemediationModalCiem;
