import { ScanStatus, ScanStatusChipData } from './ScanStatusChip.types';

export const chipTypeByStatus: ScanStatusChipData = {
    [ScanStatus.Failed]: {
        color: 'alert',
        icon: 'error',
    },
    [ScanStatus.Partial]: {
        color: 'alert',
        icon: 'error',
    },
    [ScanStatus.Not_Scanned]: {
        color: 'warning',
        icon: 'warning',
    },
    [ScanStatus.Skipped]: {
        color: 'warning',
        icon: 'warning',
    },
};
