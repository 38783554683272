import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MENU_ITEMS } from 'common/extensibility/WellKnownPaths';
import {
    IAlternateItem,
    IExternalMenuItem,
    IMainMenuItem,
    IMenuSection,
    IMenuSectionItem,
} from 'common/interface/menu';
import { getStoreService, getUserService, getWebAppIframeService } from 'common/interface/services';
import { RootState } from 'common/services/store/store';
import i18n, { ILanguageName } from 'common/services/translations/translations';
import { setAppLanguage } from 'Globalpersist.reducer';
import globalAddinContainer, { buildPath } from '../../../common/extensibility/AddinContainer';
import IframeMessageModel, { IFRAME_MESSAGE_ACTIONS } from '../../../common/interface/IFrame.message.model';

interface ChipState {
    isVisible: boolean;
    currentPage: IMenuSectionItem;
}

interface MenuState {
    mainMenu?: IMainMenuItem[];
    subMenuChip: ChipState;
    isSubMenuHidden: boolean;
    loading: boolean;
    topMenuOpen: boolean;
    externalMenuItems: IExternalMenuItem[];
}

const initialState: MenuState = {
    mainMenu: [],
    subMenuChip: {
        isVisible: false,
        currentPage: {
            id: '',
            position: 0,
            label: '',
            state: '',
        },
    },
    isSubMenuHidden: false,
    loading: true,
    topMenuOpen: false,
    externalMenuItems: [],
};

export const menuSlice = createSlice({
    name: 'menuState',
    initialState,
    reducers: {
        setSubMenuItemChip: (state: MenuState, action: PayloadAction<ChipState>) => {
            state.subMenuChip = action.payload;
        },
        setIsLoading: (state: MenuState, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setTopMenuOpen: (state: MenuState, action: PayloadAction<boolean>) => {
            state.topMenuOpen = action.payload;
        },
        setExternalMenuItems: (state: MenuState, action: PayloadAction<IExternalMenuItem>) => {
            const index = state.externalMenuItems.findIndex((externalMenuItem) => {
                return (
                    action.payload.mainMenuId === externalMenuItem.mainMenuId &&
                    action.payload.sectionId === externalMenuItem.sectionId
                );
            });
            if (index >= 0) {
                state.externalMenuItems[index] = action.payload;
            } else {
                state.externalMenuItems.push(action.payload);
            }
        },
        clearMenu: () => initialState,
    },
});

export const { setSubMenuItemChip, setTopMenuOpen, clearMenu, setExternalMenuItems } = menuSlice.actions;
export const getExternalMenuItems = (): IExternalMenuItem[] => {
    const { state } = getStoreService().getReduxTools();
    return state.menuState.externalMenuItems;
};

export const getMainMenu = (): IMainMenuItem[] => {
    return rebuildMenu();
};

export const getTopMenuOpen = (state: RootState): boolean => state.menuState.topMenuOpen;

function getMenuItemsFromContainer(): IMainMenuItem[] {
    function getSections(itemId: string): IMenuSection[] {
        const sections = globalAddinContainer.get<IMenuSection>(buildPath(MENU_ITEMS, itemId));
        const result: IMenuSection[] = [];
        for (const section of sections) {
            const tempSection = Object.assign({}, section);
            tempSection.items = globalAddinContainer.get<IMenuSectionItem>(buildPath(MENU_ITEMS, itemId, section.id));
            result.push(tempSection);
        }
        return result;
    }

    const menuItems = globalAddinContainer.get<IMainMenuItem>(MENU_ITEMS);
    const result: IMainMenuItem[] = [];
    for (const subMenuItem of menuItems) {
        const tempLeftMenuItem = Object.assign({}, subMenuItem);
        tempLeftMenuItem.sections = getSections(subMenuItem.id);
        result.push(tempLeftMenuItem);
    }
    return result;
}

export const filterByPermissions = (): IMainMenuItem[] => {
    const menuItems = getMenuItemsFromContainer();
    const filteredMenu = [] as IMainMenuItem[];
    menuItems.forEach((mainMenuItem: IMainMenuItem) => {
        let hasPermission = false;
        if (typeof mainMenuItem.permission === 'function') {
            hasPermission = mainMenuItem.permission();
        } else {
            hasPermission = getUserService().hasPermission(mainMenuItem.permission);
        }
        if (hasPermission) {
            const filteredItem = Object.assign({}, mainMenuItem);
            filteredItem.sections = [];
            mainMenuItem.sections?.forEach((subMenuSection: IMenuSection) => {
                const filteredSection = Object.assign({}, subMenuSection);
                filteredSection.items = subMenuSection.items?.filter((sectionItem) => {
                    if (typeof sectionItem.permission === 'function') {
                        return sectionItem.permission();
                    }
                    return sectionItem.permission ? getUserService().hasPermission(sectionItem.permission) : false;
                });
                filteredItem.sections?.push(filteredSection);
            });
            filteredMenu.push(filteredItem);
        }
    });

    return filteredMenu;
};

const getItemByPermissions = <T>(menuItem: IAlternateItem<T>[], defaultValue: T) => {
    let value = defaultValue;
    menuItem.every((option) => {
        if (getUserService().hasPermission(option.permission)) {
            value = option.value;
            return false;
        }
        return true;
    });
    return value;
};

const mutateMenuItemsByPermissions = (menuItems: IMainMenuItem[]): IMainMenuItem[] => {
    menuItems.forEach((menuItem) => {
        if (menuItem.labelByPermission) {
            menuItem.label = getItemByPermissions(menuItem.labelByPermission, '');
        }
        if (menuItem.iconByPermission) {
            menuItem.icon = getItemByPermissions(menuItem.iconByPermission, '');
        }
        if (menuItem.isPreviewByPermission) {
            menuItem.isPreview = getItemByPermissions(menuItem.isPreviewByPermission, false);
        }
    });

    return menuItems;
};

const addExternalMenuItems = (menuItems: IMainMenuItem[]) => {
    const { state } = getStoreService().getReduxTools();
    const menuState: MenuState = state.menuState;
    menuItems.forEach((menuItem) => {
        menuItem &&
            menuItem.sections?.forEach((sectionItem) => {
                const itemsToAdd = menuState.externalMenuItems.find((externalMenuItem) => {
                    return menuItem.id === externalMenuItem.mainMenuId && sectionItem.id === externalMenuItem.sectionId;
                });
                if (itemsToAdd) {
                    sectionItem.items?.push(...itemsToAdd.items);
                }
            });
    });
};

export const rebuildMenu = () => {
    const filteredMenuByPermissions = filterByPermissions();
    const updatedMenuItemsByPermissions = mutateMenuItemsByPermissions(filteredMenuByPermissions);
    addExternalMenuItems(updatedMenuItemsByPermissions);
    return updatedMenuItemsByPermissions;
};

export const changeLanguage = async (language: ILanguageName) => {
    const { dispatch } = getStoreService().getReduxTools();

    await i18n.changeLanguage(language);
    getWebAppIframeService().emitMessage(
        new IframeMessageModel({
            action: IFRAME_MESSAGE_ACTIONS.CHANGE_LANGUAGE,
            data: { language },
        }),
    );
    dispatch(setAppLanguage(language));
};

export default menuSlice.reducer;
