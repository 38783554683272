import { IProtectedAssetDetailPanelProvider } from 'common/module_interface/assets/ProtectedAssets';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { isNil } from 'common/utils/helpFunctions';
import i18next from 'i18next';
import { ProtectedAssetsPageRegistry } from 'common/module_interface/assets/ProtectedAssetsPageRegistry';
import { ENTITIES_WITH_PERMISSIONS_TAB } from 'common/module_interface/identity/IdentityConsts';

const permissionsPanel: IProtectedAssetDetailPanelProvider = {
    isRelevant: (cloudEntityObj: ICloudEntityData) => {
        const asset = cloudEntityObj?.protectedAsset;
        const assetType = asset?.type;
        if (isNil(assetType)) return false;
        return ENTITIES_WITH_PERMISSIONS_TAB.includes(assetType);
    },
    getValue() {
        return {
            name: 'permissions',
            title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.PANELS.PERMISSIONS'),
            isReact: false,
        };
    },
};

export default function initializePermissionsPanel() {
    ProtectedAssetsPageRegistry.addPanelAddins([
        {
            id: 'permissions',
            position: 21,
            content: permissionsPanel,
        },
    ]);
}
