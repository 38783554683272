import React from 'react';
import { SelectV2, Stack, Chip } from 'common/design-system/components-v2';

interface BaseImageListProps {
    onChange: (value: string) => void;
    options: Array<{ value: string; label: string }>;
    selectedOption: string;
}
const BaseImageList: React.FC<BaseImageListProps> = ({ options, onChange, selectedOption }) => {
    return (
        <Stack spacing={2} direction={'row'} alignItems={'center'}>
            <div style={{ width: '250px' }}>
                <SelectV2 fullWidth options={options} onChange={onChange} value={selectedOption} isMulti={false} />
            </div>
            <Chip disableInteraction color={'normal'} size={'sm'} label={`item 1 out of ${options.length}`} />
        </Stack>
    );
};

export default BaseImageList;
