import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { CVE_IGNORE_FORM_DRAWER_KEY, CVE_IGNORE_LIST_SERVICE_ID } from './CveIgnoreList.consts';
import { CveIgnoreListService } from './CveIgnoreListService';
import { initializeCustomFormDrawer } from 'common/erm-components/custom/CustomForm/CustomForm.initialize';
import { getCveIgnoreFormProps } from './CveIgnoreList.items';

const initializeCveIgnoreListService = () => {
    globalAddinContainer.addService(CVE_IGNORE_LIST_SERVICE_ID, new CveIgnoreListService());
};

const initializeCveIgnoreDrawer = () => {
    initializeCustomFormDrawer(CVE_IGNORE_FORM_DRAWER_KEY, getCveIgnoreFormProps);
};

export default function initializeCveIgnoreList() {
    initializeCveIgnoreDrawer();
    initializeCveIgnoreListService();
}
