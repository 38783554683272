import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { IDENTITY_SERVICE_ID, IIdentityService } from 'common/module_interface/identity/Identity.service';
import { sendHttpRequest } from 'common/erm-components/utils/ermComponents.http';
import {
    IIdenticalIdentity,
    IIdenticalIdentityModel,
    IIdenticalRolesInfo,
    IIdenticalIdentitiesInfoModel,
    IIdenticalIdentitiesTreeNode,
    IIdentityInfo,
} from 'common/module_interface/identity/IdenticalRoles.interfaces';
import { SIMILAR_IDENTITIES_URL } from 'common/module_interface/identity/IdentityConsts';
import React, { FunctionComponentElement } from 'react';
import { IModalsProps } from 'common/module_interface/assets/Environments';
import IdenticalIdentitiesModal from './IdenticalRoles/IdenticalIdentitiesModal';
import { identityTrans } from './initialize.i18n';

class IdentityService implements IIdentityService {
    fetchIdenticalRoles = async (cloudAccountId: string): Promise<IIdenticalRolesInfo | undefined> => {
        const roleInfos: IIdenticalRolesInfo[] | undefined = await this.fetchIdenticalRolesForMultipleCloudAccounts([
            cloudAccountId,
        ]);
        return roleInfos?.find((roleInfo: IIdenticalRolesInfo) => roleInfo.cloudAccountId === cloudAccountId);
    };

    fetchIdenticalRolesForMultipleCloudAccounts = async (
        cloudAccountIds: string[],
    ): Promise<IIdenticalRolesInfo[] | undefined> => {
        const similarAccounts: IIdenticalIdentitiesInfoModel[] | undefined = await sendHttpRequest<
            IIdenticalIdentitiesInfoModel[] | undefined
        >(SIMILAR_IDENTITIES_URL, {
            method: 'POST',
            data: { cloudAccounts: cloudAccountIds },
        });
        return similarAccounts?.map((currSimilarAccounts: IIdenticalIdentitiesInfoModel) => {
            return {
                cloudAccountId: currSimilarAccounts.id,
                platform: currSimilarAccounts.platform,
                identicalIdentities: currSimilarAccounts.similarIdentities.map((identity: IIdenticalIdentityModel) => ({
                    identities: identity.identities,
                    permissions: JSON.parse(identity.actions),
                })),
            };
        });
    };

    fetchIdenticalRolesTreeData = async (cloudAccountId: string): Promise<IIdenticalIdentitiesTreeNode[]> => {
        const identicalRolesInfo: IIdenticalRolesInfo | undefined = await this.fetchIdenticalRoles(cloudAccountId);
        const identicalRolesTreeNodes: IIdenticalIdentitiesTreeNode[] = [];
        identicalRolesInfo?.identicalIdentities.forEach((identity: IIdenticalIdentity, sequenceNumber: number) => {
            const identityGroupName: string = identityTrans('IDENTICAL_IDENTITIES_MODAL.IDENTITIES_GROUP_NAME', {
                sequenceNumber: sequenceNumber + 1,
            });
            const identityEntries: [string, IIdentityInfo[]][] = Object.entries(identity.identities);
            let duplicatesCount = 0;
            identityEntries.forEach(([entityType, value]: [string, IIdentityInfo[]]) => {
                duplicatesCount += value.length;
                const typeByPlatform = `${identicalRolesInfo.platform}|${entityType}`;

                // second level nodes
                const currIdenticalData: IIdenticalIdentitiesTreeNode = {
                    path: [identityGroupName, typeByPlatform],
                    duplicates: value.length,
                };
                identicalRolesTreeNodes.push(currIdenticalData);

                // third level nodes
                value.forEach((identityInfo: IIdentityInfo) => {
                    const currIdenticalData: IIdenticalIdentitiesTreeNode = {
                        path: [identityGroupName, typeByPlatform, identityInfo.externalId],
                        id: identityInfo.externalId,
                        entityInfo: {
                            dome9Id: identityInfo.id,
                            entityType,
                            cloudAccountId: identicalRolesInfo.cloudAccountId,
                        },
                    };
                    identicalRolesTreeNodes.push(currIdenticalData);
                });
            });

            // first level nodes
            const currentIdenticalData: IIdenticalIdentitiesTreeNode = {
                path: [identityGroupName],
                duplicates: duplicatesCount,
                permissions: identity.permissions,
            };
            identicalRolesTreeNodes.push(currentIdenticalData);
        });
        return identicalRolesTreeNodes;
    };

    getIdenticalRolesModalElement = (props: IModalsProps): FunctionComponentElement<IModalsProps> | null => {
        return React.createElement(IdenticalIdentitiesModal, { ...props });
    };
}

export default function initializeIdentityService() {
    globalAddinContainer.addService(IDENTITY_SERVICE_ID, new IdentityService());
}
