import { EventsTableRegistry } from 'common/module_interface/events/EventsTableRegistry';
import { EventsTableTab } from 'common/interface/events';
import { EventsElasticDatasource } from '../DataSource/EventsElasticDatasource';
import {
    EventLabel,
    FindingsDataSource,
    IFindingModelAlertTypeCode,
    IFindingModelOriginCode,
} from 'common/components/Findings/Findings.interface';
import i18n from 'common/services/translations/translations';
import { getEventsTableNamespace } from '../initialize.i18n';
import { Addin } from 'common/extensibility/AddinRegistry';
import { EventType } from 'common/module_interface/events/EventsConsts';

const tabsDefs: () => Addin<EventsTableTab>[] = () => {
    const elasticBase: EventsTableTab = {
        id: '',
        label: '',
        type: 'datasourceTab',
        actions: [
            'exclude',
            'acknowledge',
            'remediate',
            'comment',
            'close',
            'archive',
            'reportIssue',
            'changeSeverity',
            'assign',
            'BotStatusCheck',
            'FixIt',
        ],
        archiveActions: ['close', 'unarchive'],
        columns: [
            { id: 'checkbox' },
            { id: 'ruleName' },
            { id: 'severity' },
            { id: 'entityName' },
            { id: 'createdTime' },
            { id: 'cloudAccountId' },
            { id: 'cloudAccountType' },
            { id: 'origin' },
            { id: 'remediation' },
            { id: 'bundleName' },
            { id: 'description' },
            { id: 'entityTypeByEnvironmentType' },
            { id: 'ownerUserName' },
            { id: 'category' },
            { id: 'action' },
            { id: 'labels' },
        ],
        filters: [
            'organizational unit',
            'severity',
            'cloudAccountType',
            'cloudAccountId',
            'showExcluded',
            'showAcknowledged',
            'entityTypeByEnvironmentType',
            'origin',
            'action',
            'entityExternalId',
            'region',
            'category',
            'ownerUserName',
            'bundleName',
            'alertType',
            'ruleName',
            'date picker',
            'labels',
        ],
        exportOptions: ['export-download', 'export-email'],
        createDataSource: (archiveView, additionalFilters) => {
            // const relevantFilterFields = Object.values(IFindingModelOriginString).reduce<FilterField[]>((acc, value) => {
            //     const notRelevantOrigins: IFindingModelOriginString[] = [IFindingModelOriginString.Intelligence, IFindingModelOriginString.Magellan];
            //     if (!notRelevantOrigins.includes(value)) {
            //         acc.push({ name: 'origin', value: findingModelOriginStringToCode(value) });
            //     }
            //     return acc;
            // }, []);
            return new EventsElasticDatasource({
                id: 'all',
                findingsDataSource: archiveView ? FindingsDataSource.ARCHIVE : FindingsDataSource.FINDING,
                defaultFilters: {
                    // fields: [...relevantFilterFields, ...additionalFilters?.fields || []],
                    ...(additionalFilters || {}),
                },
            });
        },
    };

    const tabsArr: Addin<EventsTableTab>[] = [
        {
            id: EventType.ALL,
            position: 0,
            content: {
                ...elasticBase,
                id: EventType.ALL,
                label: i18n.t('TABLE_TABS.ALL', { ns: getEventsTableNamespace('table') }),
            },
        },
        {
            id: EventType.POSTURE_FINDINGS,
            position: 10,
            content: {
                ...elasticBase,
                id: EventType.POSTURE_FINDINGS,
                label: i18n.t('TABLE_TABS.POSTURE_FINDINGS', { ns: getEventsTableNamespace('table') }),
                createDataSource: (archiveView, additionalFilters) =>
                    new EventsElasticDatasource({
                        id: EventType.POSTURE_FINDINGS,
                        findingsDataSource: archiveView ? FindingsDataSource.ARCHIVE : FindingsDataSource.FINDING,
                        defaultFilters: {
                            ...(additionalFilters || {}),
                            fields: [
                                ...(additionalFilters?.fields || []),
                                { name: 'origin', value: IFindingModelOriginCode.ComplianceEngine },
                                { name: 'origin', value: IFindingModelOriginCode.Serverless },
                                { name: 'alertType', value: IFindingModelAlertTypeCode.Task },
                            ],
                        },
                    }),
            },
        },
        {
            id: EventType.CIEM,
            position: 10,
            content: {
                ...elasticBase,
                id: EventType.CIEM,
                label: i18n.t('TABLE_TABS.CIEM', { ns: getEventsTableNamespace('table') }),
                createDataSource: (archiveView, additionalFilters) =>
                    new EventsElasticDatasource({
                        id: EventType.CIEM,
                        findingsDataSource: archiveView ? FindingsDataSource.ARCHIVE : FindingsDataSource.FINDING,
                        defaultFilters: {
                            ...(additionalFilters || {}),
                            fields: [...(additionalFilters?.fields || []), { name: 'labels', value: EventLabel.CIEM }],
                        },
                    }),
            },
        },
        // CDR here with position 20 (Pushed from Intelligence module)
        {
            id: EventType.THREAT_AND_SECURITY,
            position: 30,
            content: {
                ...elasticBase,
                actions: [...elasticBase.actions, 'addDenyRule'],
                id: EventType.THREAT_AND_SECURITY,
                label: i18n.t('TABLE_TABS.THREAT_AND_SECURITY', { ns: getEventsTableNamespace('table') }),
                createDataSource: (archiveView, additionalFilters) =>
                    new EventsElasticDatasource({
                        id: EventType.THREAT_AND_SECURITY,
                        findingsDataSource: archiveView ? FindingsDataSource.ARCHIVE : FindingsDataSource.FINDING,
                        defaultFilters: {
                            ...(additionalFilters || {}),
                            fields: [
                                ...(additionalFilters?.fields || []),
                                { name: 'origin', value: IFindingModelOriginCode.ServerlessSecurityAnalyzer },
                                { name: 'origin', value: IFindingModelOriginCode.KubernetesRuntimeAssurance },
                                { name: 'origin', value: IFindingModelOriginCode.ContainersRuntimeProtection },
                                { name: 'origin', value: IFindingModelOriginCode.Serverless },
                                { name: 'origin', value: IFindingModelOriginCode.Agentless },
                                { name: 'alertType', value: IFindingModelAlertTypeCode.SecurityEvent },
                            ],
                        },
                    }),
            },
        },
        {
            id: EventType.VULNERABILITIES,
            position: 40,
            content: {
                ...elasticBase,
                filters: [...elasticBase.filters, 'showFixable'],
                id: EventType.VULNERABILITIES,
                label: i18n.t('TABLE_TABS.VULNERABILITIES', { ns: getEventsTableNamespace('table') }),
                createDataSource: (archiveView, additionalFilters) =>
                    new EventsElasticDatasource({
                        id: EventType.VULNERABILITIES,
                        findingsDataSource: archiveView ? FindingsDataSource.ARCHIVE : FindingsDataSource.FINDING,
                        defaultFilters: {
                            ...(additionalFilters || {}),
                            fields: [
                                ...(additionalFilters?.fields || []),
                                { name: 'origin', value: IFindingModelOriginCode.KubernetesImageScanning },
                                { name: 'origin', value: IFindingModelOriginCode.ImageAssurance },
                            ],
                        },
                    }),
            },
        },
    ];

    return tabsArr;
};

export default function initializeTabsDefs() {
    EventsTableRegistry.addTabs(tabsDefs());
}
