import React, { useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Chip, ComponentOverflow } from 'common/design-system/components-v2';

export const LabelsCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const labels: string[] | undefined = useMemo(() => {
        if (!params.data || !params.value || params.value.length === 0) {
            return undefined;
        } else if (params.data?.isGrouped) {
            return [params.value];
        }
        return params.value;
    }, [params]);

    if (!labels || labels.length === 0) {
        return null;
    }

    return (
        <ComponentOverflow
            components={labels.map((item: string) => (
                <Chip label={item} disableInteraction key={item} />
            ))}
        />
    );
};
