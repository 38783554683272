import history, { URL_PREFIX } from './../../common/utils/history';
import axios, { AxiosError } from 'axios';
import { navigateToNewError } from 'platform/Error/ErrorPage.reducer';
import { setIsAWSLegacyView } from '../../App.reducer';
import { LoginDetails, LoginResponse } from 'platform/account/Login/LoginForm.interface';
import { clearErrors, clearLoginForm, setMfaRequired } from 'platform/account/Login/LoginForm.reducer';
import { clearMenu } from 'platform/main/Menus/Menus.reducer';
import {
    clearSwitchRoleState,
    getUserRolesFromServer,
    setUserRoles,
} from 'platform/main/SwitchRole/Roles/Roles.reducer';
import { assumeRole, backToRootRole, getRolesFromServer, getUserAccountsAndRoles } from 'platform/roles/Roles.service';
import dayjs from 'dayjs';
import { Cookies } from 'react-cookie';
import { ALIASES, EXPIRED, MESSAGES } from 'platform/auth/auth.const';
import { LoginServerResponse } from 'platform/auth/Auth.interface';
import i18n from 'common/services/translations/translations';
import { clearAuthReducer, setIsAuthenticated, setTokenLocalExpirationTime } from 'platform/auth/Auth.reducer';
import { changeUrl, getCookieOptions, removeCsrf, setCsrf, toQueryString } from 'common/utils/http';
import { clearSwitchRoleComponentState } from './SwitchRoleComponent.reducer';
import { requestQueue } from '../services/RequestQueue';

import {
    clearUser,
    setAccount,
    setCloudAccount,
    setHasRole,
    setIsRelatedToMsp,
    setPermissionRequest,
    setRoleName,
    setRoles,
    setRootUser,
    setUser,
    setUsername,
} from './User.reducer';

import {
    getAccountService,
    getHttpService,
    getStoreService,
    getUserService,
    getWebAppIframeService,
} from 'common/interface/services';
import { getUserFromServer, getUserPermissionsFromServer } from './User.service';
import { getAccountFromServer, getCloudAccount } from '../account/Account.service';
import { dispatchUserPermissions } from './User.permissions';
import { Pages } from 'common/enum/Pages';
import { getCloudAccountsService } from 'common/interface/data_services';
import { Vendors } from 'common/consts/vendors';
import { getIsCloudInfra } from 'common/utils/RuntimeEnvironment';
import { IUserRole } from 'common/interface/user';
import { clearDashboards } from 'modules/overview/Overview.reducer';
import { getGlobalPersistor } from 'common/services/store/store';
import { UserRolesTypes } from 'common/enum/UserRoles';
import { checkUserAccountExists } from '../auth/Auth.actions';

const ERROR_CODES = {
    NO_MATCHING_D9_ACCOUNT: 'NoMatchingD9Account',
};

const SUPPORTED_PLATFORM = [
    Vendors.AWS,
    Vendors.AZURE,
    Vendors.GOOGLE,
    Vendors.KUBERNETES,
    Vendors.SHIFT_LEFT,
    Vendors.ALIBABA,
];

const AUTH_HEADERS = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
};

async function flushStateAndReload() {
    const persistor = getGlobalPersistor();
    await persistor.flush();
    // Setting a timeout before page reload clears the app's memory, reverting it to its initial state as needed.
    const timeout = setTimeout(() => {
        clearTimeout(timeout);
        window.location.reload();
    }, 500);
}

export const saveReturnUrlToLocalStorage = (returnUrl: string) => {
    localStorage.setItem('returnUrl', returnUrl);
};
export const getReturnUrlFromLocalStorage = () => {
    return localStorage.getItem('returnUrl');
};
export const removeReturnUrlFromLocalStorage = () => {
    return localStorage.removeItem('returnUrl');
};
export const navigateToApp = async () => {
    const urlParams = new URLSearchParams(document.location.search);
    let returnUrl = urlParams.get('returnUrl') ? urlParams.get('returnUrl') : null;
    const localStorageReturnUrl = getReturnUrlFromLocalStorage();
    if (!returnUrl && localStorageReturnUrl) returnUrl = localStorageReturnUrl;
    if (returnUrl) {
        let isFullUrl = false;
        try {
            if (new URL(returnUrl).hostname) {
                isFullUrl = true;
            }
        } catch (err) {
            isFullUrl = false;
        }

        if (getIsCloudInfra()) {
            if (!isFullUrl) {
                changeUrl(decodeURIComponent(returnUrl));
                return;
            }
        } else {
            if (isFullUrl) {
                window.location.assign(decodeURIComponent(returnUrl));
                return;
            }

            changeUrl(decodeURIComponent(returnUrl));
            return;
        }
    }

    if (isUserAllowedToOnboard()) {
        const userHasEnvs = await isUserHadOnboardedAnEnvironment();
        if (!userHasEnvs) {
            changeUrl(Pages.CloudAccountEnv);
            return;
        }
    }
    changeUrl(decodeURIComponent(''));
};

export const isUserAllowedToOnboard = () => {
    return getUserService().hasAnyPermission([UserRolesTypes.SUPER_USER, UserRolesTypes.ONBOARDING_PERMITTED]);
};

export const isUserHadOnboardedAnEnvironment = async () => {
    const envs = await getCloudAccountsService().getAllCloudAccounts();
    return envs.some((env) => {
        return isPlatformSupported(env.platform);
    });
};

const isPlatformSupported = (platform: string) => {
    return SUPPORTED_PLATFORM.includes(platform as Vendors);
};

export const clearState = async () => {
    const dispatch = getStoreService().dispatch;
    dispatch(clearUser());
    dispatch(clearDashboards());
    dispatch(clearMenu());
    dispatch(clearSwitchRoleState());
    dispatch(clearSwitchRoleComponentState());
    dispatch(clearAuthReducer());
    dispatch(clearLoginForm());
    const persistor = getGlobalPersistor();
    await persistor.flush();
};

export const parseLoginResponse = (response?: LoginServerResponse, clientId?: string): LoginResponse => {
    if (response) {
        return {
            csrf: response['as:csrf'],
            zuz: response['expires_in'],
            aud: clientId,
            username: response['username'],
        };
    } else {
        return {};
    }
};

export const saveTokenValues = (response: LoginResponse) => {
    const dispatch = getStoreService().dispatch;
    const cookies = new Cookies();
    const cookieOptions = getCookieOptions();

    // Set current client id cookie
    cookies.set(ALIASES.CURRENT_CLIENT_ID, response.aud, cookieOptions);

    // Set token expiration
    const expirationString = dayjs()
        .add(response.zuz || 0, 'seconds')
        .toISOString();
    cookies.set(ALIASES.LOCAL_EXPIRATION_TIME, expirationString, cookieOptions);
    dispatch(setTokenLocalExpirationTime(expirationString));

    // Set csrf and csrfm
    cookies.set(ALIASES.CSRFM, response.csrf, cookieOptions);
    setCsrf(response.csrf as string, cookieOptions);

    // Set user name
    cookies.set(ALIASES.USER_NAME, response.username, cookieOptions);

    return;
};

export const handleDeprecatedCookies = () => {
    /**
     * Handles ticket PLAT-2215 where old LOGIN_ID cookie with the value "expired" might be stuck in the cookies
     * and we have to remove it manually. This code can be removed after 1/1/2023 because the cookie will be expired
     * by then.
     */
    const cookies = new Cookies();
    const cookieOptions = getCookieOptions();
    const loginIdCookie = cookies.get(ALIASES.LOGIN_ID);
    if (loginIdCookie === EXPIRED) {
        cookies.remove(ALIASES.LOGIN_ID, cookieOptions);
    }
};

export const logoutAndRedirectToLogin = () => {
    deleteAllCookies();
    clearState().then(() => {
        if (!window.location.pathname.startsWith(`${URL_PREFIX}/${Pages.Login}`)) {
            changeUrl(`/${Pages.Login}`);
            history.go(0);
        }
    });
};

export const deleteAllCookies = () => {
    try {
        const cookieOptions = getCookieOptions();
        const cookies = new Cookies();
        const { dispatch } = getStoreService();
        cookies.remove(ALIASES.CSRFM, cookieOptions);
        cookies.remove(ALIASES.CURRENT_CLIENT_ID, cookieOptions);
        cookies.remove(ALIASES.LOCAL_EXPIRATION_TIME, cookieOptions);
        cookies.remove(ALIASES.USER_NAME, cookieOptions);
        removeCsrf();
        dispatch(setTokenLocalExpirationTime());
        dispatch(setUsername(''));
    } catch (err: any) {
        console.error(`Could not clear state ${err.message}`);
    }
};
export const makeTokenRequest = async (params: LoginDetails) => {
    return requestQueue.add(async () => {
        try {
            const { state, dispatch } = getStoreService().getReduxTools();
            const response = await axios.post<LoginServerResponse>(
                `${state.app.authServerUrl}/token`,
                toQueryString({ query: params }),
                {
                    headers: AUTH_HEADERS,
                    withCredentials: true,
                },
            );
            const responseData = response?.data?.toString() ?? '';
            const responseStatus = response?.status;
            if (responseData === MESSAGES.MFA_REQUIRED || responseStatus === 202) {
                dispatch(setMfaRequired(true));
                dispatch(clearErrors());
                throw new Error('MFA required');
            } else {
                await saveTokenValues(parseLoginResponse(response.data, params.client_id));
            }
        } catch (error) {
            console.error('Error while making token request: ', error);
            throw error; // propagate the error to the caller 'requestQueue'
        }
    });
};

export const makeCloudInfraAuthenticationRequest = async (url: string, firstTimeToken: string) => {
    return await axios.post<LoginServerResponse>(url, { firstTimeToken }, { withCredentials: true });
};

export const logout = async (returnUrl?: string, holdNavigation?: boolean) => {
    const { dispatch, state } = getStoreService().getReduxTools();
    try {
        await axios.post(`${state.app.authServerUrl}/logout`, {}, { headers: AUTH_HEADERS, withCredentials: true });
    } finally {
        dispatch(setIsAWSLegacyView(false));
        if (!holdNavigation) {
            navigateToLoginAndClearState(returnUrl);
        }
    }
};

export const initLogin = async () => {
    console.log('==initLogin');
    const sessionAlive = await getHttpService().getIsSessionAlive();
    if (sessionAlive) {
        await navigateToApp();
    } else {
        deleteAllCookies();
    }
};
export const navigateToLoginAndClearState = (returnUrl?: string, error?: AxiosError<any>) => {
    function handleCloudInfraNavigation() {
        let errorText;
        if (error?.response?.data?.errorCode === ERROR_CODES.NO_MATCHING_D9_ACCOUNT) {
            errorText = i18n.t('GENERAL.USER_ACTIONS.CLOUDINFRA_ERROR.NOT_IMPORTED_USER');
        } else {
            const errorMessage = error?.response?.data?.errorMessage;
            errorText = errorMessage
                ? i18n.t('HTTP.SERVER_ERROR_MESSAGE', { errorMessage })
                : i18n.t('GENERAL.USER_ACTIONS.CLOUDINFRA_ERROR.TEXT');
        }
        navigateToNewError({
            title: i18n.t('GENERAL.USER_ACTIONS.CLOUDINFRA_ERROR.TITLE'),
            text: errorText,
        });
    }

    function handleStandaloneNavigation() {
        if (decodeURIComponent(returnUrl!) === `/${Pages.Login}`) {
            returnUrl = '';
        } else if (returnUrl) {
            saveReturnUrlToLocalStorage(returnUrl);
        }
        logoutAndRedirectToLogin();
    }

    getWebAppIframeService().setVisibility(false);
    deleteAllCookies();
    clearState().then(() => {
        if (getIsCloudInfra()) {
            handleCloudInfraNavigation();
        } else {
            handleStandaloneNavigation();
        }
    });
};
const setUsernameCookie = (userEmail: string) => {
    const cookies = new Cookies();
    const cookieVal = cookies.get('username');
    if (cookieVal === null || cookieVal === undefined || cookieVal === 'undefined') {
        cookies.set('username', userEmail, getCookieOptions());
    }
};

export const getUserAndSaveToState = async (): Promise<boolean> => {
    const dispatch = getStoreService().dispatch;
    const isUserAccountExists = await checkUserAccountExists();
    if (!isUserAccountExists) return false;

    await Promise.all([
        getUserFromServer().then((user) => {
            dispatch(setUser(user));
            setUsernameCookie(user.email);
        }),
        getAccountFromServer().then((account) => dispatch(setAccount(account))),
        getCloudAccount().then((cloudAccount) => dispatch(setCloudAccount(cloudAccount))),
        getUserPermissionsFromServer().then((permissions) => dispatch(setPermissionRequest(permissions))),
        getAccountService()
            .hasMspActivated()
            .then((hasMsp) => dispatch(setIsRelatedToMsp(hasMsp))),
        getRolesFromServer().then((roles) => dispatch(setRoles(roles))),
        getUserAccountsAndRoles().then((roles) => dispatch(setUserRoles(roles))),
    ]);
    dispatch(setHasRole());
    await dispatchUserPermissions();
    return true;
};

export const switchRole = async (role: IUserRole) => {
    const { dispatch, state } = getStoreService().getReduxTools();
    dispatch(setIsAuthenticated(false));
    const fromSwitchRole = await assumeRole({
        accountId: role.accountId,
        roleName: role.role,
    });

    saveTokenValues({
        csrf: fromSwitchRole.csrf,
        zuz: 7150,
        aud: role.accountId.toString(),
        username: role.accountName,
    });

    dispatch(setRootUser(state.user.user?.name || state.user.username));
    const result = await getUserAndSaveToState();
    if (result) {
        dispatch(setUsername(role.accountName));
        dispatch(setRoleName(role.role));
        dispatch(setHasRole());
        dispatch(setIsAuthenticated(true));
        await getUserRolesFromServer();
        await flushStateAndReload();
    }
};

export const revertToRoot = async () => {
    const { dispatch, state } = getStoreService().getReduxTools();
    dispatch(setIsAuthenticated(false));
    const fromBackTo = await backToRootRole();

    saveTokenValues({
        csrf: fromBackTo.csrf,
        zuz: 599,
        username: state.user.rootUser,
    });

    dispatch(setUsername(fromBackTo.userName));
    const result = await getUserAndSaveToState();
    if (result) {
        dispatch(setIsAuthenticated(true));
        await flushStateAndReload();
    }
};
