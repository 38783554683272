import { ColDef } from 'ag-grid-community';
import { Layers, Malwareflatten } from 'modules/workloads/services/vulnerability/vulnerability.interface';
import { ColToHideFF, severityInfo, SortDirection } from '../../utils';
import { severityComparator } from '../../../../../../utils/versionsCompare';
import { ICellRendererParams, ValueGetterParams } from 'ag-grid-enterprise';
import DataVolume from 'common/components/DataVolume/DataVolume';
import { Stack, Tooltip } from 'common/design-system/components-v2';
import IconLinkCellRenderer from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { changeUrl } from 'common/utils/http';
import i18n from 'common/services/translations/translations';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { ThreatColumnsNames } from './Threats.types';
import { hideColumn } from '../../../../../../utils/aggrid';

const getColumnDefs = (isThreats: boolean, layers?: Layers): ColDef<Malwareflatten>[] => {
    const colDef = [
        {
            field: ThreatColumnsNames.LayerCommand,
            headerName: 'Layer Command',
            enableRowGroup: true,
            rowGroup: true,
            valueGetter: (params: ValueGetterParams<Malwareflatten>) => {
                if (!layers || !params.data || !params.data['layerId']) return 'N/A';

                return layers[params.data['layerId']].created_by;
            },
            cellRenderer: (params: ICellRendererParams) => {
                if (params.value === 'N/A') return 'N/A';

                return (
                    <IconLinkCellRenderer
                        style={{ textOverflow: 'ellipsis' }} // TODO: check if this is working
                        value={params.value}
                        isLink={false}
                        levelIconProps={{
                            iconProps: { name: 'command' },
                            category: 'severity',
                            level: 'high', // TODO: what is the level?
                            size: 'md',
                        }}
                        href={''}
                    />
                );
            },
        },
        {
            field: ThreatColumnsNames.LayerId,
            headerName: 'Layer Id',
            hide: true,
            enableRowGroup: false,
            valueGetter: (params: ValueGetterParams<Malwareflatten>) => {
                if (!layers || !params.data || !params.data['layerId']) return null;
                return params.data['layerId'];
            },
        },
        {
            field: ThreatColumnsNames.Severity,
            headerName: 'Severity',
            initialSort: SortDirection.ASC,
            comparator: severityComparator,
            maxWidth: 120,
            enableRowGroup: true,
            cellRenderer: (params: ICellRendererParams) => {
                if (!params.data) {
                    return params.value;
                }
                return <DataVolume dataInfo={severityInfo(params.value)} />;
            },
        },
        {
            field: ThreatColumnsNames.IsExclude,
            headerName: 'Is Excluded',
        },
        {
            field: ThreatColumnsNames.Finding,
            headerName: 'Finding',
        },
        {
            field: ThreatColumnsNames.Type,
            enableRowGroup: true,
            maxWidth: 150,
            headerName: 'Type',
            valueGetter: (params: ValueGetterParams<Malwareflatten>) => params.data?.category || 'N/A',
            hide: !isThreats,
        },
        {
            field: ThreatColumnsNames.Classification,
            maxWidth: 120,
            headerName: 'Classification',
            hide: !isThreats,
        },
        {
            field: ThreatColumnsNames.Description,
            headerName: 'Description',
        },
        {
            field: ThreatColumnsNames.FilePath,
            enableRowGroup: true,
            headerName: 'File Path',
            cellRenderer: (params: ICellRendererParams) => {
                if (!params.data) {
                    return params.value;
                }
                return (
                    <Stack>
                        <Tooltip content={params.value}>
                            <span>{params.value}</span>
                        </Tooltip>
                    </Stack>
                );
            },
        },
        {
            field: ThreatColumnsNames.Lines,
            headerName: 'Lines',
        },
        {
            field: ThreatColumnsNames.BaseImages,
            valueGetter: (params: ValueGetterParams<Malwareflatten>) =>
                params.data && params.data.baseImages && params.data.baseImages?.length > 0
                    ? params.data.baseImages[0].name
                    : '',
            cellRenderer: (params: ICellRendererParams<{ baseImages: Malwareflatten['baseImages'] }>) => {
                if (!params.data) {
                    return params.value;
                }
                const externalId =
                    params.data?.baseImages && params.data?.baseImages.length > 0
                        ? params.data.baseImages[0].externalId
                        : '';
                return (
                    <IconLinkCellRenderer
                        value={params.value || ''}
                        isLink={!!externalId}
                        onClick={(event) => {
                            event.preventDefault();
                            changeUrl(`workload/images?filterPanel={"additionalFields|ImageId":"${externalId}"}`);
                        }}
                    />
                );
            },
            headerName: i18n.t('VulnerabilityTable.cves.baseImage', { ns: getK8sNamespace('vulnerability') }),
            enableRowGroup: true,
            sortable: false,
        },
    ];
    return hideColumn(colDef, hideCols(layers, isThreats));
};

export const hideCols = (layers?: Layers, isThreats?: boolean): ColToHideFF => [
    {
        field: ThreatColumnsNames.LayerCommand,
        hide: !isThreats,
    },
    {
        field: ThreatColumnsNames.LayerId,
        hide: !layers || !isThreats,
    },
];

export { getColumnDefs };
