import { globalModelUtils } from 'common/components/GlobalModals/GlobalModals';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import i18n from 'common/services/translations/translations';

const localStorageKey = 'RP_policies-changes-effect-after-few-mins';

export const showRPPolicyUpdatedNotification = () => {
    const isAcknowledged = localStorage.getItem(localStorageKey) === 'true';
    if (isAcknowledged) return;

    let dontShowAgain = false;
    globalModelUtils.showConfirmationModal(
        {
            title: i18n.t('MODALS.RP_POLICY_UPDATED.TITLE', { ns: getK8sNamespace('events') }),
            text: i18n.t('MODALS.RP_POLICY_UPDATED.TEXT', { ns: getK8sNamespace('events') }),
            width: 'lg',
            checkboxProps: {
                label: i18n.t('MODALS.RP_POLICY_UPDATED.DONT_SHOW_AGAIN', { ns: getK8sNamespace('events') }),
                onClick: (event) => (dontShowAgain = event.target.checked),
            },
            onConfirm: () => {
                if (dontShowAgain) {
                    localStorage.setItem(localStorageKey, 'true');
                }
            },
            submitBtnText: i18n.t('MODALS.RP_POLICY_UPDATED.BUTTONS.SUBMIT', { ns: getK8sNamespace('events') }),
        },
        true,
    );
};
