import { GroupSelection, Input, Stack, SelectV2 } from 'common/design-system/components-v2';
import { categoryOptions, typeGroupSelection } from '../VulnerabilityExclusionForm.const';
import { Controller, useFormContext } from 'react-hook-form';
import { FormValues } from '../VulnerabilityExclusionForm.types';
import { VulnerabilityExclusionType } from 'modules/workloads/services/vulnerability/vulnerability.interface';

const TypeController = () => {
    const {
        control,
        formState: { errors },
        watch,
        resetField,
    } = useFormContext<FormValues>();
    const {
        toggles: { vulnerabilityType },
    } = watch();

    return (
        <Stack spacing={4}>
            <Controller
                name={'toggles.vulnerabilityType'}
                control={control}
                render={({ field }) => (
                    <GroupSelection
                        defaultValue={'cve'}
                        options={typeGroupSelection}
                        onChange={(value) => {
                            resetField('cveId');
                            resetField('packageName');
                            resetField('packageVersion');
                            resetField('maliciousCategory');
                            resetField('filePath');
                            field.onChange(value);
                        }}
                        value={field.value}
                        direction='row'
                    />
                )}
            />
            <Stack spacing={2} direction={'row'} margin={[0, 0, 0, 4]}>
                {vulnerabilityType === VulnerabilityExclusionType.CVE && (
                    <>
                        <Controller
                            name={'cveId'}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    fullWidth
                                    isError={!!errors.cveId}
                                    onChange={field.onChange}
                                    placeholder={'Enter CVE Id'}
                                    required
                                    helperText={errors.cveId?.message ?? undefined}
                                    label={'CVE Id'}
                                />
                            )}
                        />
                        <Controller
                            name={'packageName'}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    fullWidth
                                    placeholder={'Enter Package Name'}
                                    isError={!!errors.packageName}
                                    onChange={field.onChange}
                                    label={'Package Name'}
                                />
                            )}
                        />
                        <Controller
                            name={'packageVersion'}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    fullWidth
                                    placeholder={'Enter Package Version'}
                                    isError={!!errors.packageVersion}
                                    helperText={errors.packageVersion?.message ?? undefined}
                                    onChange={field.onChange}
                                    label={'Package Version'}
                                />
                            )}
                        />
                    </>
                )}
                {vulnerabilityType === VulnerabilityExclusionType.THREAT && (
                    <>
                        <Controller
                            name={'maliciousCategory'}
                            control={control}
                            render={({ field }) => (
                                <SelectV2
                                    {...field}
                                    isMulti={false}
                                    fullWidth
                                    placeholder={'Select category'}
                                    options={categoryOptions}
                                    isError={!!errors.maliciousCategory}
                                    onChange={field.onChange}
                                    label={'Category'}
                                />
                            )}
                        />
                        <Controller
                            name={'filePath'}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    fullWidth
                                    required
                                    placeholder={'Enter Path'}
                                    helperText={errors.filePath?.message ?? undefined}
                                    isError={!!errors.filePath}
                                    onChange={field.onChange}
                                    label={'Path'}
                                />
                            )}
                        />
                    </>
                )}
                {vulnerabilityType === VulnerabilityExclusionType.SECRET && (
                    <Controller
                        name={'filePath'}
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                fullWidth
                                required
                                placeholder={'Enter Path'}
                                isError={!!errors.filePath}
                                onChange={field.onChange}
                                label={'Path'}
                            />
                        )}
                    />
                )}
            </Stack>
        </Stack>
    );
};

export default TypeController;
