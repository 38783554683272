import { ButtonGroup, InputLabel, Stack, Switch } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { I18nSettingsTranslationKey } from '../../../initialize';
import { ScopeAndControls } from '../ScopeAndControls/ScopeAndControls';
import React, { FC, useEffect, useState } from 'react';
import { NetworkSecurity } from '../NetworkSecurity/NetworkSecurity';
import { CodeSecurity } from '../CodeSecurity/CodeSecurity';
import { SwitchWrapper, TabsWrapper } from './DirectPermissionsComponent.styled';
import { IDirectPermissionsComponentProps } from './interfaces';
import { IPermissionItem, IPermissionModel, PermissionCategoryType, PermissionViewMode } from '../interfaces';
import { AccountAccessControls } from '../NetworkSecurity/AccountAccessControls';

let tabsSelectionsState: IPermissionModel = {
    scopeControls: [] as IPermissionItem[],
    networkSecurity: [] as IPermissionItem[],
    codeSecurity: [] as IPermissionItem[],
    accountAccess: [] as IPermissionItem[],
};

export const DirectPermissionsComponent: FC<IDirectPermissionsComponentProps> = ({
    agentsEnabled,
    accountAccessStatus,
    onPermissionsUpdated,
    predefinedPermissions,
    viewMode = 'edit',
}) => {
    const { t } = useTranslation(I18nSettingsTranslationKey);

    const _permissionTypes = [
        {
            label: t('LABELS.SCOPE_CONTROLS'),
            value: PermissionCategoryType.SCOPE_CONTROLS,
        },
        {
            label: t('LABELS.NETWORK_SECURITY'),
            value: PermissionCategoryType.NETWORK_SECURITY,
        },
        {
            label: t('LABELS.CODE_SECURITY'),
            value: PermissionCategoryType.CODE_SECURITY,
        },
    ];
    const [permissions, setPermissions] = useState<IPermissionModel>();
    const [selectedPermissionType, setSelectedPermissionType] = useState(PermissionCategoryType.SCOPE_CONTROLS);
    const [showSelected, setShowSelected] = useState<boolean | undefined>();
    const [permissionTypes, setPermissionTypes] = useState<any[]>(_permissionTypes);
    useEffect(() => {
        tabsSelectionsState.scopeControls = [];
        tabsSelectionsState.networkSecurity = [];
        tabsSelectionsState.codeSecurity = [];
        tabsSelectionsState.accountAccess = [];
    }, []);

    useEffect(() => {
        if (
            (viewMode === PermissionViewMode.REVIEW && !accountAccessStatus?.hidden) ||
            (viewMode === PermissionViewMode.EDIT && !accountAccessStatus?.showOnlyInReviewMode)
        ) {
            setPermissionTypes([
                ...permissionTypes,
                {
                    label: t('LABELS.MSP'),
                    value: PermissionCategoryType.ACCOUNT_ACCESS,
                },
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountAccessStatus]);

    useEffect(() => {
        setPermissions(predefinedPermissions);
        if (predefinedPermissions) {
            tabsSelectionsState = predefinedPermissions;
        }
    }, [predefinedPermissions]);

    useEffect(() => {
        switch (viewMode) {
            case PermissionViewMode.REVIEW:
                setShowSelected(true);
                break;
            case PermissionViewMode.EDIT:
                setShowSelected(false);
                break;
        }
    }, [viewMode]);

    function onPermissionTypeChange(value: PermissionCategoryType) {
        setSelectedPermissionType(value);
        setPermissions((_permissions) => {
            return {
                ..._permissions,
                [value]: tabsSelectionsState[value],
            };
        });
    }

    function onPermissionsChanged(data: IPermissionItem[], permissionType: PermissionCategoryType) {
        const _permissions = { ...permissions };
        _permissions[permissionType] = data;
        tabsSelectionsState[permissionType] = data;
        onPermissionsUpdated && onPermissionsUpdated(tabsSelectionsState);
    }

    const onShowSelectedChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _showSelected = event.target.checked;
        setShowSelected(_showSelected);
    };

    return (
        <Stack direction={'column'} margin={[6, 0]} spacing={3} data-aid={'direct-permissions'}>
            <InputLabel text={t('LABELS.DIRECT_PERMISSIONS')} subText={t('LABELS.DIRECT_PERMISSIONS_EXPLAINED')} />
            <Stack direction={'column'} spacing={3} padding={3}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <TabsWrapper>
                        {viewMode === PermissionViewMode.EDIT ? (
                            <ButtonGroup
                                options={permissionTypes}
                                value={selectedPermissionType}
                                onChange={(value) => onPermissionTypeChange(value as PermissionCategoryType)}
                                dataAid='permissionTypesButtonGroup'
                            />
                        ) : (
                            ''
                        )}
                    </TabsWrapper>
                    <SwitchWrapper>
                        <Switch
                            label={t('LABELS.SHOW_SELECTED')}
                            checked={showSelected}
                            onChange={onShowSelectedChanged}
                        />
                    </SwitchWrapper>
                </Stack>

                {viewMode === PermissionViewMode.EDIT && (
                    <>
                        {selectedPermissionType === PermissionCategoryType.SCOPE_CONTROLS ? (
                            <ScopeAndControls
                                showOnlySelectedItems={showSelected}
                                agentsPermissionsEnabled={agentsEnabled}
                                permissions={permissions?.scopeControls}
                                permissionsChanged={(data: IPermissionItem[]) =>
                                    onPermissionsChanged(data, PermissionCategoryType.SCOPE_CONTROLS)
                                }
                            />
                        ) : (
                            ''
                        )}
                        {selectedPermissionType === PermissionCategoryType.NETWORK_SECURITY ? (
                            <NetworkSecurity
                                accountAccessStatus={accountAccessStatus}
                                showOnlySelectedItems={showSelected}
                                agentsPermissionsEnabled={agentsEnabled}
                                permissions={permissions?.networkSecurity}
                                permissionsChanged={(data: IPermissionItem[]) =>
                                    onPermissionsChanged(data, PermissionCategoryType.NETWORK_SECURITY)
                                }
                            />
                        ) : (
                            ''
                        )}
                        {selectedPermissionType === PermissionCategoryType.CODE_SECURITY ? (
                            <CodeSecurity
                                showOnlySelectedItems={showSelected}
                                agentsPermissionsEnabled={agentsEnabled}
                                permissions={permissions?.codeSecurity}
                                permissionsChanged={(data: IPermissionItem[]) =>
                                    onPermissionsChanged(data, PermissionCategoryType.CODE_SECURITY)
                                }
                            />
                        ) : (
                            ''
                        )}
                        {!accountAccessStatus?.hidden &&
                        !accountAccessStatus?.readonly &&
                        selectedPermissionType === PermissionCategoryType.ACCOUNT_ACCESS ? (
                            <AccountAccessControls
                                accountAccessStatus={accountAccessStatus}
                                showOnlySelectedItems={showSelected}
                                permissionsChanged={(data: IPermissionItem[]) =>
                                    onPermissionsChanged(data, PermissionCategoryType.ACCOUNT_ACCESS)
                                }
                                permissions={permissions?.accountAccess}
                            />
                        ) : (
                            ''
                        )}
                    </>
                )}
                {viewMode === PermissionViewMode.REVIEW && (
                    <>
                        <ScopeAndControls
                            agentsPermissionsEnabled={agentsEnabled}
                            permissions={permissions?.scopeControls}
                            viewMode={viewMode}
                            showOnlySelectedItems={showSelected}
                        />
                        <NetworkSecurity
                            accountAccessStatus={accountAccessStatus}
                            agentsPermissionsEnabled={agentsEnabled}
                            permissions={permissions?.networkSecurity}
                            viewMode={viewMode}
                            showOnlySelectedItems={showSelected}
                        />
                        <CodeSecurity
                            agentsPermissionsEnabled={agentsEnabled}
                            permissions={permissions?.codeSecurity}
                            viewMode={viewMode}
                            showOnlySelectedItems={showSelected}
                        />
                        {!accountAccessStatus?.hidden ? (
                            <AccountAccessControls
                                accountAccessStatus={accountAccessStatus}
                                showOnlySelectedItems={showSelected}
                                viewMode={viewMode}
                                permissions={permissions?.accountAccess}
                            />
                        ) : (
                            ''
                        )}
                    </>
                )}
            </Stack>
        </Stack>
    );
};
