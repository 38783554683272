import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

const TopDiv = styled(Stack)`
    width: 100%;
    height: 100%;
    margin-bottom: 16px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
`;

const IconDiv = styled(Stack)`
    padding: 4px;
    background-color: ${({ theme }) => theme.palette.surface.secondary};
    border-radius: ${({ theme }) => `${theme.border.radius(1.5)}${theme.units}`};
`;

const LinkWrapper = styled(Stack)`
    font-weight: 400;
    font-size: 14px;
`;
export const IAMSensTableStyled = {
    IconDiv,
    TopDiv,
    LinkWrapper,
};
