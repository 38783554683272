import React from 'react';
import { Typography } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { I18nNotifications } from '../../NotificationsPage.consts';

export const AssociationsCellRenderer: React.FC<any> = (params) => {
    const { t } = useTranslation(I18nNotifications);
    const associations =
        params.data?.associations && params.data?.associations.length && params.data?.associations.join(', ');
    if (!associations) return;
    return (
        <>
            {params?.data?.associations ? (
                <Typography>{associations}</Typography>
            ) : (
                <Typography>{t('GENERAL.LOADING')}</Typography>
            )}
        </>
    );
};
