import { IRuleset } from 'common/interface/ruleset';
import { getHttpService, getStoreService, IRulesetService } from 'common/interface/services';
import { getAppLanguage } from '../../Globalpersist.reducer';
import { AxiosResponse } from 'axios';
import { generalApiError } from 'common/utils/http';

const BASE_URL = '/Compliance/Ruleset';
export class RulesetService implements IRulesetService {
    async getAllRulesets(useCache = true) {
        try {
            const rulesets = await getHttpService().get<IRuleset[]>({
                path: `${BASE_URL}/view`,
                cachingConfig: { useCache },
            });
            //if the selected language isn't japanese, remove the japanese rulesets

            const filterRulesets = rulesets.filter((ruleset: IRuleset) => {
                const { state } = getStoreService().getReduxTools();
                const appLanguage = getAppLanguage(state);
                return (appLanguage === ruleset.language || ruleset.language === 'en') && ruleset.showBundle;
            });

            //remove duplicate rulesets
            const uniqueRulesets = filterRulesets.reduce((unique: IRuleset[], ruleset: IRuleset) => {
                if (!unique.some((obj) => obj.id === ruleset.id)) {
                    unique.push(ruleset);
                }
                return unique;
            }, []);

            return uniqueRulesets;
        } catch {
            return [];
        }
    }
    async deleteRuleset(id: string) {
        return getHttpService().request<AxiosResponse<string>>(
            `/Compliance/Ruleset/${id}`,
            { method: 'DELETE' },
            { returnAsAxiosResponse: true },
            generalApiError,
        );
    }
}
