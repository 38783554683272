import {
    DashboardWidgetTypes,
    IAddinWidgetsDataSource,
    IDashboardWidget,
    IWidgetDataConfiguration,
} from 'common/module_interface/overview/Interface';
import {
    alertsDataGetter,
    alertsStackedColumnDataGetter,
    getAlertSummaryData,
    eventsStackedDataGetter,
    getLatestAlertsData,
} from './WidgetsData/AlertsDataGetter';
import initializeEventsWidgetsFilters from './filters';
import WidgetLatestList from 'common/components/Widgets/Latest/WidgetLatestList';
import { aggregationTypesOptions, widgetsTypesOptions } from './EventsWidgetsSettings';
import AlertsWidgetsSettings from './Components/AlertsWidgetsSettings';
import { DASHBOARD_DYNAMIC_WIDGETS } from 'common/module_interface/overview/Consts';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import StackedColumnWidget from 'common/components/Widgets/StackedColumn/StackedColumnWidget';
import LineSetWidget from 'common/components/Widgets/Line/LineSetWidget';
import AlertsTrendWidgetSettings from './Components/AlertsTrendWidgetSettings';
import { alertsTrendDataGetter } from './WidgetsData/AlertsTrendDataGetter';
import initializeEventsTrendWidgetsFilters from './FiltersAlertsTrend';
import AlertsIconAndNumberWidget from './Components/AlertsIconAndNumberWidget';
import initializeRuleAggregationFilters from './FiltersRuleAggregation';

export const initializeEventsWidgets = () => {
    const widgetsTypes = ['pie', 'column', 'top'];
    const aggregationsTypes = [
        'action',
        'alertType',
        'ownerUserName',
        'category',
        'entity',
        'entityType',
        'cloudAccountId_calc',
        'organizationalUnitId',
        'labels',
        'cloudAccountType',
        'region',
        'ruleName',
        'bundleName',
        'severity',
        'origin',
    ];
    const getAllWidgets = (): IWidgetDataConfiguration[] => {
        const widgets = [] as IWidgetDataConfiguration[];
        aggregationsTypes.forEach((type) => {
            widgetsTypes.forEach((widgetType) => {
                widgets.push({
                    dataId: aggregationTypesOptions[type].dataId,
                    dataIdAliases: aggregationTypesOptions[type].dataIdAliases,
                    displayName: aggregationTypesOptions[type].displayName,
                    type: widgetType as DashboardWidgetTypes,
                    getData: (widget: IDashboardWidget) =>
                        alertsDataGetter(
                            widget,
                            aggregationTypesOptions[type].dataGetter,
                            type === 'entityType' ? aggregationTypesOptions[type].dataId : type,
                        ),
                    widgetComponent: widgetsTypesOptions[widgetType].component,
                    settingsComponent: AlertsWidgetsSettings,
                });
            });
        });
        return widgets;
    };

    const getAllLatestAlertsWidgets = (): IWidgetDataConfiguration[] => {
        return aggregationsTypes.map((type) => {
            return {
                dataId: aggregationTypesOptions[type].dataId,
                displayName: 'Latest',
                type: DashboardWidgetTypes.Latest,
                getData: (widget: IDashboardWidget) => alertsDataGetter(widget, getLatestAlertsData, type, true),
                widgetComponent: WidgetLatestList,
                settingsComponent: AlertsWidgetsSettings,
            };
        });
    };

    const getAllStackedColumnWidgets = (): IWidgetDataConfiguration[] => {
        const stackedAggregationTypes = [
            'action',
            'alertType',
            'entity',
            'ownerUserName',
            'category',
            'entityTypeByEnvironmentType',
            'cloudAccountId_calc',
            'labels',
            'cloudAccountType',
            'region',
            'ruleName',
            'bundleName',
            'severity',
            'origin',
        ];
        return stackedAggregationTypes.reduce((result: IWidgetDataConfiguration[], aggregationName) => {
            result.push({
                dataId: `${aggregationName}`,
                displayName: `Top ${aggregationTypesOptions[aggregationName].displayName} stacked by severity`,
                type: DashboardWidgetTypes.StackedColumn,
                getData: (widget: IDashboardWidget) =>
                    alertsStackedColumnDataGetter(
                        aggregationTypesOptions[aggregationName].stackColumnName || aggregationName,
                        widget,
                        eventsStackedDataGetter,
                    ),
                widgetComponent: StackedColumnWidget,
                settingsComponent: AlertsWidgetsSettings,
            });

            return result;
        }, []);
    };

    const eventsWidgets: IAddinWidgetsDataSource = {
        dataSourceName: 'Alerts',
        dataField: { displayName: 'Aggregation', path: 'aggregation' },
        widgets: [
            ...getAllWidgets(),
            ...getAllLatestAlertsWidgets(),
            ...getAllStackedColumnWidgets(),
            {
                dataId: 'summary',
                displayName: 'Summary',
                type: DashboardWidgetTypes.Summary,
                getData: (widget: IDashboardWidget) => alertsDataGetter(widget, getAlertSummaryData),
                widgetComponent: AlertsIconAndNumberWidget,
                settingsComponent: AlertsWidgetsSettings,
            },
            {
                dataId: 'trend',
                displayName: 'Trend',
                type: DashboardWidgetTypes.Trend,
                getData: (widget: IDashboardWidget) => alertsTrendDataGetter(widget),
                widgetComponent: LineSetWidget,
                settingsComponent: AlertsTrendWidgetSettings,
            },
            // {
            //     dataId: 'openXClosed',
            //     displayName: 'Open vs Closed',
            //     type: DashboardWidgetTypes.Trend,
            //     getData: () => OpenClosedDataGetter(),
            //     widgetComponent: ActivityGauge,
            // }
        ],
    };

    globalAddinContainer.addMap(DASHBOARD_DYNAMIC_WIDGETS, [eventsWidgets], 'dataSourceName');
    initializeEventsWidgetsFilters();
    initializeRuleAggregationFilters();
    initializeEventsTrendWidgetsFilters();
};
