import {
    renderAddFilter,
    renderClearAll,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter,
    renderSavedFilters,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { RemediationTableRegistry } from '../services/RemediationTableRegestries';
import { IRemediationFilterParams } from '../helpers/remediation.interfaces';
import i18n from 'common/services/translations/translations';
import { RULESETS, ENVIRONMENTS, I18nRemediation, PLATFORM } from '../helpers/remediation.consts';
import { IDisplayMappingObject } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { getVendor, getVendorDisplayName } from 'common/consts/vendors';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { mapEnvironmentItem, mapRulesetItem } from '../helpers/remediation.utils';

const filterDefsAddins: () => Addin<IFilterProps>[] = () => [
    {
        id: FILTERS_KEYS.ADD_FILTER,
        content: {
            filterProps: { key: FILTERS_KEYS.ADD_FILTER },
            renderFunction: renderAddFilter,
        },
    },
    {
        id: FILTERS_KEYS.FREE_TEXT,
        content: {
            filterProps: {
                key: FILTERS_KEYS.FREE_TEXT, //This needs to be the same as in stored filter/query params
                title: i18n.t('FILTERS.FREE_TEXT', { ns: I18nRemediation }),
            },
            renderFunction: renderDefaultFreeTextFilter,
        },
    },
    {
        id: PLATFORM,
        content: function ({ aggregations }: IRemediationFilterParams) {
            const displayMapping: IDisplayMappingObject = {};
            aggregations[PLATFORM]?.forEach((platform: { value: string }) => {
                displayMapping[platform.value] = {
                    displayText: getVendorDisplayName(platform.value),
                    icon: getVendor(platform.value)?.icon,
                };
            });
            return {
                filterProps: {
                    initialData: aggregations['platform'],
                    key: 'platform',
                    title: i18n.t('FILTERS.PLATFORM', { ns: I18nRemediation }),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: ENVIRONMENTS,
        content: function (params: IRemediationFilterParams) {
            const aggregations: Aggregations = params?.aggregations ?? {};
            return {
                filterProps: {
                    initialData: aggregations[ENVIRONMENTS],
                    key: ENVIRONMENTS,
                    title: i18n.t('FILTERS.ENVIRONMENT', { ns: I18nRemediation }),
                    displayMapping: mapEnvironmentItem(),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: RULESETS,
        content: function ({ aggregations }: IRemediationFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations[RULESETS],
                    key: RULESETS,
                    title: i18n.t('FILTERS.RULESETS', { ns: I18nRemediation }),
                    displayMapping: mapRulesetItem(),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: FILTERS_KEYS.SAVED_FILTERS,
        content: function ({ filtersInitialData, savedFiltersComponentName }) {
            return {
                filterProps: {
                    savedFilters: filtersInitialData?.savedFilters || [],
                    savedFiltersComponentName,
                    selectedFilterID: '',
                    key: FILTERS_KEYS.SAVED_FILTERS,
                },
                renderFunction: renderSavedFilters,
            };
        },
    },
    {
        id: FILTERS_KEYS.CLEAR_BUTTON,
        content: {
            filterProps: {
                key: FILTERS_KEYS.CLEAR_BUTTON,
            },
            renderFunction: renderClearAll,
        },
    },
];

export default function initializeFilters() {
    RemediationTableRegistry.addFilterAddins(filterDefsAddins());
}
