export const filterNameDataForFreeText = (item: { name: string }, text: string) => {
    return item?.name?.toLowerCase().includes(text.toLowerCase());
};

type FilterOptions = string[] | boolean[] | '';
export const filterDataForMultiSelect = (options: FilterOptions, fieldInItem: string | boolean) => {
    if (options === '' || (Array.isArray(options) && options.length === 0)) {
        return true;
    }
    for (const option of options) {
        if (Array.isArray(fieldInItem)) {
            if (fieldInItem.includes(option)) {
                return true;
            }
        } else {
            if (fieldInItem === option) {
                return true;
            }
        }
    }
    return false;
};
