import { EnvironmentsTableRegistry } from 'common/module_interface/assets/EnvironmentsTableRegistry';
import WorkloadService from '../services/workload/workload.service';
import { IFRAME_TAGS } from 'common/interface/IFrame.message.model';
import {
    GetWorkloadEnvironment,
    ManagedKubernetesAccountDetails,
    ManagedRegistriesAccountDetails,
} from '../services/workload/workload.interface';
import { IEnvironment } from 'common/interface/environmentsTable';
import { StatusCellRenderer_Status, StatusCellRenderer_StatusType } from 'common/module_interface/assets/Environments';
import { DEFAULT_ORGANIZATIONAL_UNIT_ID } from 'common/consts/DataConsts';
import { getOrganizationalUnitService } from 'common/interface/data_services';
import { Vendors } from 'common/consts/vendors';

export const initializeEnvironmentsRows = () => {
    EnvironmentsTableRegistry.addCustomRows([
        {
            id: 'add-env-table-workloads-unmanaged',
            content: {
                isRelevant: (selectedTab) =>
                    selectedTab === Vendors.KUBERNETES || selectedTab === Vendors.CONTAINER_REGISTRY,
                getRows: async () => {
                    const workloadEnvironment = await WorkloadService.getWorkloadEnvironment({
                        cachingConfig: { useCache: true, tags: [IFRAME_TAGS.CLEAR_CACHE.ENVIRONMENTS] },
                    }).catch(() => {
                        const emptyData: GetWorkloadEnvironment.Response = {
                            containerRegistryAccounts: [],
                            kubernetesAccounts: [],
                        };
                        return { data: emptyData };
                    });

                    const allOUs = await getOrganizationalUnitService().getAllOrganizationalUnitsFlatWithPath(true);
                    const allOUsIndexed = allOUs.reduce<{ [ouId: string]: string }>((acc, ou) => {
                        acc[ou.id] = ou.path;
                        return acc;
                    }, {});

                    const unsecuredAccounts: IEnvironment[] = [];

                    const createUnSecuredAccount = (
                        account: ManagedKubernetesAccountDetails | ManagedRegistriesAccountDetails,
                    ) => {
                        return {
                            id: account.cloudAccountId,
                            credentialId: account.dome9EntityId,
                            externalId: account.providerAccountId,
                            organizationalUnitId: account.organizationalUnitId || DEFAULT_ORGANIZATIONAL_UNIT_ID,
                            name: account.name,
                            creationDate: new Date(0).toISOString(),
                            platform: account.platform,
                            subplatform: account.type,
                            'customData|status': StatusCellRenderer_Status.unsecured,
                            'customData|statusType': StatusCellRenderer_StatusType.workloadStatus,
                            'customData|workloadPlatformType': account.type,
                            'customData|organizationalUnitPath':
                                account.organizationalUnitPath ||
                                allOUsIndexed[account.organizationalUnitId || DEFAULT_ORGANIZATIONAL_UNIT_ID],
                            'customData|uniqueIdForTable': `${account.cloudAccountId}_${account.dome9EntityId}_${account.providerAccountId}`,
                            'customData|dome9EntityId': account.dome9EntityId,
                            'customData|providerAccountId': account.providerAccountId,
                            'customData|unselectable': true,
                        };
                    };

                    workloadEnvironment.data.kubernetesAccounts.forEach((account) => {
                        if (account.managedKubernetesAccountDetails && !account.onBoardedKubernetesAccountDetails) {
                            unsecuredAccounts.push(createUnSecuredAccount(account.managedKubernetesAccountDetails));
                        }
                    });

                    workloadEnvironment.data.containerRegistryAccounts.forEach((account) => {
                        if (account.managedRegistriesAccountDetails && !account.onBoardedRegistriesAccountDetails) {
                            unsecuredAccounts.push(createUnSecuredAccount(account.managedRegistriesAccountDetails));
                        }
                    });

                    return unsecuredAccounts;
                },
            },
        },
    ]);
};
