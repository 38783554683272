import i18n, { validLanguages } from 'common/services/translations/translations';

export const OusPageNamespacePrefix = 'ous-page';
export type OuPageTermTypes = 'ous';
const termsFolders: Array<OuPageTermTypes> = ['ous'];

export const getOusPageNamespace = (term: OuPageTermTypes) => `${OusPageNamespacePrefix}_${term}`;

const jsonFilesPaths = validLanguages.reduce<Array<{ path: string; language: string; term: OuPageTermTypes }>>(
    (acc, language) => {
        const filesPAth = termsFolders.map((term) => ({ path: `${language}/${term}`, language, term }));
        return [...acc, ...filesPAth];
    },
    [],
);

export const initializeOusPageI18n = async () => {
    await Promise.all(
        jsonFilesPaths.map(async (file) => {
            try {
                const contents = await require(`./languages/${file.path}.json`);
                i18n.addResourceBundle(file.language, getOusPageNamespace(file.term), contents);
                // eslint-disable-next-line
            } catch (error) {}
        }),
    );
};
