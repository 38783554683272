import React, { FC, useEffect, useState } from 'react';
import { Button, Input, SelectV2, Spinner, Stack, Typography } from 'common/design-system/components-v2';
import {
    getAccountService,
    getNotificationsService,
    getRolesService,
    getSecurityGroupsService,
    IRole,
} from 'common/interface/services';
import { DirectPermissionsComponent } from '../../Components/DirectPermissions/DirectPermissionsComponent/DirectPermissionsComponent';
import { I18nTranslationKey } from './initRolesPage';
import { useTranslation } from 'react-i18next';
import { basicRole } from './consts';
import { dataService } from './RolesFiltersDefenitions';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { IUser } from 'common/interface/user';
import { IAccountAccessComponentStatus } from '../../Components/DirectPermissions/DirectPermissionsComponent/interfaces';
import {
    IPermissionItem,
    IPermissionModel,
    PERMISSION_TYPE,
    PermissionViewMode,
} from '../../Components/DirectPermissions/interfaces';
import { getPermissionConverter, IPermissionConverter } from '../../Components/DirectPermissions/permissionsConverters';
import { NotificationType } from 'common/interface/notifications';
import useAllCloudAccounts from 'common/hooks/useAllCloudAccounts';
import { useOrganizationalUnits } from 'common/hooks/useOrganizationalUnit';
import { useAgents } from 'common/hooks/useAgents';

interface IAddRoleProps {
    onRoleChangeCallBack: () => void;
    roleId: number;
}
let permissionsConverter: IPermissionConverter;
let scopeControls: IPermissionItem[] = [];
let networkSecurity: IPermissionItem[] = [];
let codeSecurity: IPermissionItem[] = [];
let accountAccess: IPermissionItem[] = [];

export const AddRoleDrawer: FC<IAddRoleProps> = ({ roleId, onRoleChangeCallBack }) => {
    const { t } = useTranslation(I18nTranslationKey);
    const [roleInputs, setRoleInputs] = useState({ roleName: '', roleDescription: '' });
    const [allUsersList, setAllUsersList] = useState<SelectOption[]>([]);
    const [allUsers, setAllUsers] = useState<IUser[]>([]);
    const [selectedUsersForRole, setSelectedUsersForRole] = useState<string[]>([]);
    const [, setSelectedUsersForRoleFull] = useState<IUser[]>();
    const [predefinedRole, setPredefinedRole] = useState<IRole>();
    const { isLoading: isCloudAccountLoading, allCloudAccounts } = useAllCloudAccounts();
    const { isLoading: isOULoading, organizationalUnits } = useOrganizationalUnits();
    const { agents, isLoading: isAgentsLoading } = useAgents();
    const [isPermissionsLoading, setIsPermissionsLoading] = useState(true);
    const [agentsEnabled, setAgentsEnabled] = useState<boolean>(false);
    const [accountAccessStatus, setAccountAccessStatus] = useState<IAccountAccessComponentStatus | undefined>();
    const [allPermissions, setAllPermissions] = useState<IPermissionModel>({
        scopeControls: [],
        networkSecurity: [],
        codeSecurity: [],
        accountAccess: [],
    });
    const [viewMode, setViewMode] = useState<PermissionViewMode>(PermissionViewMode.EDIT);

    const handleRoleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRoleInputs((prevState) => ({ ...prevState, roleName: event.target.value }));
        if (predefinedRole) {
            setPredefinedRole({ ...predefinedRole, name: event.target.value });
        }
    };

    const handleRoleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRoleInputs((prevState) => ({ ...prevState, roleDescription: event.target.value }));
        if (predefinedRole) {
            setPredefinedRole({ ...predefinedRole, description: event.target.value });
        }
    };

    const fetchAndSetUsersList = async (useCache = false) => {
        const users = await dataService.getUsers(useCache);
        const filterOutOwner = users.filter((user) => !user.isOwner);
        setAllUsers(() => filterOutOwner);
        const usersList = filterOutOwner.map((user) => ({ label: user.name, value: user?.id.toString() }));
        setAllUsersList(() => usersList);
    };

    const handleUpdateUserRole = async (value: string[]) => {
        setSelectedUsersForRole(value);
        const usersDetails = value.map((userList) => allUsers.filter((user) => user?.id.toString() === userList)[0]);
        setSelectedUsersForRoleFull(usersDetails);
    };

    const checkAgentsPermissionsEnabled = async () => {
        const license = await getAccountService().getLicense();
        setAgentsEnabled(license?.agentsEnabled);
        const hideAccountAccess = !(await getAccountService().hasMspActivated());
        setAccountAccessStatus({ hidden: hideAccountAccess, readonly: false });
    };

    const switchToReviewMode = () => {
        setViewMode(PermissionViewMode.REVIEW);
    };

    const switchToEditMode = () => {
        setViewMode(PermissionViewMode.EDIT);
    };

    const getSelectedPermissions = (permissions: IPermissionModel) => {
        const _selectedPermissions: IPermissionModel = {};
        const permissionModelKeys = Object.keys(permissions) as Array<keyof IPermissionModel>;
        permissionModelKeys.forEach((key) => {
            const _permissionCategory = permissions[key]?.filter(
                (permission: IPermissionItem) => permission.type !== PERMISSION_TYPE.PLACEHOLDER,
            );
            // @ts-ignore
            const _permissions = _permissionCategory?.filter(
                (permission: IPermissionItem) =>
                    permission.admin || permission.enable || permission.member || permission.manage || permission.view,
            );
            if (_permissions && _permissions.length > 0) {
                // @ts-ignore
                _selectedPermissions[key] = _permissions;
            }
        });
        return _selectedPermissions;
    };

    const onPermissionsUpdated = (permissions: IPermissionModel) => {
        const _relevantPermission = getSelectedPermissions(permissions);
        scopeControls = _relevantPermission.scopeControls || [];
        networkSecurity = _relevantPermission.networkSecurity || [];
        codeSecurity = _relevantPermission.codeSecurity || [];
        accountAccess = _relevantPermission.accountAccess || [];
    };

    const updateSelectedUsersWithRole = async (createdRoleId: number) => {
        const updateUserRoles = async (user: IUser) => {
            const isUserSelected = selectedUsersForRole.includes(user.id.toString());
            const hasExistingRole = user.roleIds.includes(createdRoleId);
            if (!isUserSelected && !hasExistingRole) return;
            const newRoleIds = isUserSelected
                ? [...new Set([...user.roleIds, createdRoleId])]
                : user.roleIds.filter((roleId) => roleId !== createdRoleId);
            const updatedUser = { ...user, roleIds: newRoleIds };
            await dataService.updateUser(updatedUser);
        };
        await Promise.all(allUsers.map(updateUserRoles));
    };

    const save = async () => {
        const _allPermissions = {
            scopeControls,
            networkSecurity,
            codeSecurity,
            accountAccess,
        };
        const permissionList = permissionsConverter.convertPermissionModelToPermissionList(_allPermissions);
        try {
            if (predefinedRole) {
                await getRolesService().updateRole({
                    ...predefinedRole,
                    permissions: permissionList as unknown as { [key: string]: string[] },
                });
                await updateSelectedUsersWithRole(predefinedRole.id);
            } else {
                const initRole = await dataService.addRole({
                    ...basicRole,
                    name: roleInputs.roleName,
                    description: roleInputs.roleDescription,
                });
                const payload: IRole = {
                    ...initRole,
                    permissions: permissionList as unknown as { [key: string]: string[] },
                };
                const updatedRole = await getRolesService().updateRole(payload);
                await updateSelectedUsersWithRole(updatedRole.id);
            }
            getNotificationsService().addNotification({
                type: NotificationType.SUCCESS,
                title: '',
                text: t('DRAWER.TOAST.SUCCESS'),
            });
            onRoleChangeCallBack();
        } catch (error) {
            getNotificationsService().addNotification({
                type: NotificationType.ERROR,
                title: '',
                text: t('DRAWER.TOAST.ERROR'),
            });
        }
    };

    const init = async () => {
        setIsPermissionsLoading(true);
        await fetchAndSetUsersList();
        const securityGroups = await getSecurityGroupsService().getAllSecurityGroups();
        const userAccountsRoles = await getAccountService().getAccountAndRoles();
        const allRoles = await getRolesService().getRoles();
        permissionsConverter = getPermissionConverter(
            organizationalUnits,
            allCloudAccounts,
            securityGroups,
            agents,
            userAccountsRoles,
        );
        if (roleId) {
            const role = allRoles.find((role) => role.id === roleId);
            setPredefinedRole(role);
            if (role?.id) {
                setRoleInputs({ roleName: role?.name, roleDescription: role?.description });
                const permissionModel = await permissionsConverter.convertPermissionListToPermissionModel(
                    role?.permissions as unknown as { [key: string]: string[] },
                );
                setAllPermissions(permissionModel);
                scopeControls = permissionModel.scopeControls || [];
                networkSecurity = permissionModel.networkSecurity || [];
                codeSecurity = permissionModel.codeSecurity || [];
                accountAccess = permissionModel.accountAccess || [];
            }
        }
        setIsPermissionsLoading(false);
    };

    useEffect(() => {
        if (roleId) {
            const updatedUserList = allUsers
                .filter((user) => user.roleIds.includes(roleId))
                .map((user) => user.id.toString());
            setSelectedUsersForRole(updatedUserList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allUsers]);

    useEffect(() => {
        void checkAgentsPermissionsEnabled();
    }, [roleId]);

    useEffect(() => {
        if (!isCloudAccountLoading && !isOULoading && !isAgentsLoading) {
            scopeControls = [];
            networkSecurity = [];
            codeSecurity = [];
            accountAccess = [];
            setAllPermissions({
                scopeControls,
                networkSecurity,
                codeSecurity,
                accountAccess,
            });
            void init();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleId, isCloudAccountLoading, isOULoading, isAgentsLoading]);

    useEffect(() => {
        setAllPermissions({
            scopeControls,
            networkSecurity,
            codeSecurity,
            accountAccess,
        });
    }, [viewMode]);

    return (
        <Stack direction={'column'} spacing={3}>
            <Stack>
                <Input
                    type='text'
                    label={t('DRAWER.ROLE_NAME')}
                    placeholder={t('DRAWER.ROLE_NAME_PLACEHOLDER')}
                    value={roleInputs.roleName}
                    onChange={(e) => handleRoleNameChange(e)}
                    dataAid={'role-name'}
                    fullWidth
                />
            </Stack>
            <Stack>
                <Input
                    type='text'
                    label={t('DRAWER.ROLE_DESCRIPTION')}
                    placeholder={t('DRAWER.ROLE_DESCRIPTION_PLACEHOLDER')}
                    value={roleInputs.roleDescription}
                    onChange={(e) => handleRoleDescriptionChange(e)}
                    dataAid={'role-description'}
                    fullWidth
                />
            </Stack>
            <Stack fullWidth>
                <SelectV2
                    label={t('DRAWER.ASSIGN_MEMBERS')}
                    placeholder={t('DRAWER.ASSIGN_MEMBERS_PLACEHOLDER')}
                    options={allUsersList}
                    onChange={(value) => handleUpdateUserRole(value)}
                    value={selectedUsersForRole}
                    isMulti={true}
                    fullWidth
                    data-aid='add-role-user-select'
                />
            </Stack>
            {isPermissionsLoading ? (
                <Stack fullWidth={true} alignItems={'center'} justifyContent={'center'} direction={'row'} spacing={3}>
                    <Spinner />
                    <Typography variant={'bodyLg'}> {t('DRAWER.LABELS.LOADING_PERMISSIONS')}</Typography>
                </Stack>
            ) : (
                <DirectPermissionsComponent
                    agentsEnabled={agentsEnabled}
                    accountAccessStatus={accountAccessStatus}
                    predefinedPermissions={allPermissions}
                    onPermissionsUpdated={onPermissionsUpdated}
                    viewMode={viewMode}
                />
            )}
            <Stack justifyContent={'flex-end'} direction={'row'} spacing={4}>
                {viewMode === PermissionViewMode.EDIT ? (
                    <Button
                        color={'brandPrimary'}
                        onClick={switchToReviewMode}
                        disabled={!roleInputs.roleName}
                        dataAid={'review-mode'}
                    >
                        {t('DRAWER.BUTTONS.REVIEW_SAVE')}
                    </Button>
                ) : (
                    <>
                        <Button color={'normal'} onClick={switchToEditMode} dataAid={'back-to-edit-button'}>
                            {t('DRAWER.BUTTONS.BACK_TO_EDIT')}
                        </Button>
                        <Button color={'brandPrimary'} onClick={save} dataAid={'save-button'}>
                            {t('DRAWER.BUTTONS.SAVE')}
                        </Button>
                    </>
                )}
            </Stack>
        </Stack>
    );
};
