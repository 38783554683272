import { IRemediationService } from 'common/module_interface/intelligence/Remediation/Remediation.service';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { REMEDIATION_SERVICE_ID } from 'common/module_interface/intelligence/Remediation/Remediation.const';
import {
    ICreateRemediationRequest,
    ICreateRemediationResponse,
} from 'common/module_interface/intelligence/Remediation/Remediation.interface';
import { getHttpService } from 'common/interface/services';

class RemediationService implements IRemediationService {
    createRemediation(data: ICreateRemediationRequest): Promise<ICreateRemediationResponse | undefined> {
        return getHttpService().post<ICreateRemediationResponse>({
            path: '/ComplianceRemediation',
            requestObject: {
                data: data,
            },
        });
    }
}

export default function initializeRemediationService() {
    globalAddinContainer.addService(REMEDIATION_SERVICE_ID, new RemediationService());
}
