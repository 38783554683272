import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { Addin } from 'common/extensibility/AddinRegistry';
import { EventsDrawerAddingBase } from 'common/module_interface/events/EventsDrawerRegistry';
import i18n from 'common/services/translations/translations';
import { formatDate } from 'common/utils/helpFunctions';
import { additionalFieldsIsRelevant, isEventLogIDS } from './utils';

export const awpDetailsPanelItems: Addin<EventsDrawerAddingBase<BlockInfoProps>>[] = [
    {
        id: 'workloads-awp-scan-time',
        content: {
            id: 'workloads-awp-scan-time',
            isRelevant: (event) => isEventLogIDS(event) && additionalFieldsIsRelevant('Scan Time', event),
            getValue: (event) => {
                const scanTime = event.additionalFields.find(({ name }) => name === 'Scan Time')!.value;
                return {
                    title: i18n.t('SCAN_TIME', { ns: 'k8s_events' }),
                    info: formatDate(scanTime),
                    dataAid: 'scanTime',
                };
            },
        },
    },
    {
        id: 'workloads-awp-os',
        content: {
            id: 'workloads-awp-os',
            isRelevant: (event) => isEventLogIDS(event) && additionalFieldsIsRelevant('OS', event),
            getValue: (event) => {
                const osValue: string = event.additionalFields.find(({ name }) => name === 'OS')!.value;
                const os = osValue[0].toUpperCase() + osValue.slice(1);
                return {
                    title: i18n.t('OS', { ns: 'k8s_events' }),
                    info: os,
                    dataAid: 'OS',
                };
            },
        },
    },
    {
        id: 'workloads-awp-dist',
        content: {
            id: 'workloads-awp-dist',
            isRelevant: (event) => isEventLogIDS(event) && additionalFieldsIsRelevant('OS Distribution', event),
            getValue: (event) => {
                const distribution: string = event.additionalFields.find(
                    ({ name }) => name === 'OS Distribution',
                )!.value;
                return {
                    title: i18n.t('DISTRIBUTION', { ns: 'k8s_events' }),
                    info: distribution,
                    dataAid: 'ruleId',
                };
            },
        },
    },
    {
        id: 'workloads-awp-ruleid',
        content: {
            id: 'workloads-awp-ruleid',
            isRelevant: (event) => isEventLogIDS(event),
            getValue: (event) => {
                return {
                    title: i18n.t('RULE_ID', { ns: 'k8s_events' }),
                    info: event.ruleId,
                    dataAid: 'ruleId',
                };
            },
        },
    },
];
