import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import {
    CreateKubernetesAccount,
    KubernetesAccountFeatureStatusChange,
    UpdateKubernetesAccountName,
    UpdateKubernetesAccountDescription,
    UpdateKubernetesAccountOU,
    DeleteKubernetesAccount,
    KubernetesFeatureType,
    KubernetesFeatureStatus,
    GetKubernetesAccountSummary,
    GetKubernetesAccount,
    GetKubernetesAgentSummary,
    RequestAgentStatusReportCSV,
} from './kubernetesAccount.interface';
import MagellanService from '../magellan/magellan.service';

const createAccount: CreateKubernetesAccount.Function = async (data) => {
    return await getHttpService().request<AxiosResponse<CreateKubernetesAccount.Response>>(
        'kubernetes/account',
        { method: 'POST', data },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const updateName: UpdateKubernetesAccountName.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<UpdateKubernetesAccountName.Response>>(
        `kubernetes/account/${request.id}/accountName?name=${request.name}`,
        { method: 'PUT' },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const updateDescription: UpdateKubernetesAccountDescription.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<UpdateKubernetesAccountDescription.Response>>(
        `kubernetes/account/${request.id}/accountDescription?description=${request.description}`,
        { method: 'PUT' },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const updateOU: UpdateKubernetesAccountOU.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<UpdateKubernetesAccountOU.Response>>(
        `kubernetes/account/${request.id}/organizationalUnit`,
        { method: 'PUT', data: request },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const deleteAccount: DeleteKubernetesAccount.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<DeleteKubernetesAccount.Response>>(
        `kubernetes/account/${request.id}`,
        { method: 'DELETE' },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const updateFeatureStatus: KubernetesAccountFeatureStatusChange.Function = async (request) => {
    let res: AxiosResponse;
    if (
        request.featureType === KubernetesFeatureType.threatIntelligence &&
        request.status === KubernetesFeatureStatus.ENABLE
    ) {
        if (!request.magellanUrl || !request.accountId) {
            throw new Error('Magellan URL and account ID are required to enable threat intelligence.');
        }
        res = await MagellanService.magellanEnableMagellanKubernetes([request.id]);

        await getHttpService().request<AxiosResponse<KubernetesAccountFeatureStatusChange.Response>>(
            `${request.magellanUrl}/onboarding/add-kubernetes-flowlogs-account`,
            { method: 'POST', data: { accountId: request.accountId, k8sAccountIds: [request.id] } },
            { returnAsAxiosResponse: true },
            (err) => {
                throw err;
            },
        );
    } else {
        res = await getHttpService().request<AxiosResponse<KubernetesAccountFeatureStatusChange.Response>>(
            `kubernetes/account/${request.id}/${request.featureType}/${request.status}`,
            { method: 'POST', data: request },
            { returnAsAxiosResponse: true },
            (err) => {
                throw err;
            },
        );
    }
    return res;
};

const getAccount: GetKubernetesAccount.Function = async (request, useCache = false) => {
    return await getHttpService().request<AxiosResponse<GetKubernetesAccount.Response>>(
        `kubernetes/account/${request.id}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true, cachingConfig: { useCache } },
        (err) => {
            throw err;
        },
    );
};

const getAccountSummary: GetKubernetesAccountSummary.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<GetKubernetesAccountSummary.Response>>(
        `kubernetes/account/${request.id}/accountSummary`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const getAgentSummary: GetKubernetesAgentSummary.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<GetKubernetesAgentSummary.Response>>(
        `kubernetes/account/${request.id}/agentSummary`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const requestAgentStatusReportCSV: RequestAgentStatusReportCSV.Function = async () => {
    return await getHttpService().request(
        'kubernetes/account/agentStatusReportCSV',
        { method: 'POST', transformResponse: (data) => data.toString(), data: {} },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const KubernetesAccountService = {
    createAccount,
    updateName,
    updateDescription,
    updateOU,
    deleteAccount,
    updateFeatureStatus,
    getAccount,
    getAccountSummary,
    getAgentSummary,
    requestAgentStatusReportCSV,
};

export default KubernetesAccountService;
