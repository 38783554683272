import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { get, isEmpty, map } from 'lodash';
import { getIntelligenceService, getKustoEventService } from 'common/module_interface/intelligence/intelligence';
import { Column, ColumnApi, GridApi } from 'ag-grid-community';
import { CounterStatus } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { dateTimeFilter, getFilterFacetFields } from './Components/FilterPanel/FindingsFilterPanel.utils';
import {
    IFilterDetails,
    IGslRunFacetResponse,
    IGslRunRequest,
    ITableApis,
} from 'common/module_interface/intelligence/Intelligence.interface';
import { ALERTS } from '../Findings.const';
import { EMPTY_STRING, OPERATORS } from 'common/consts/GeneralConsts';
import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { GenericObject, IFieldInfo } from 'common/interface/general';
import { IAdditionalFilterFieldInfo, IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { isNil, isString } from 'common/utils/helpFunctions';
import { convertFacetsToAggregations } from 'common/components/gsl/GslServiceQueries';
import { GSL_CLAUSES } from 'common/module_interface/intelligence/Gsl/GslService.const';
import { ITimeRange } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { SortModelItem } from 'ag-grid-community/dist/lib/sortController';
import {
    computeFilterString,
    filterValuesToGslRequestQueryFilters,
    freeTextToGslFormat,
} from 'common/components/KustoEvents/KustoEventService.utils';
import { KustoEventFields } from 'common/components/KustoEvents/KustoEvent.const';
import {
    convertAggregationsModelToUi,
    convertFieldInfoListUiToModel,
    convertFieldNameListUiToModel,
    convertFieldNameUiToModel,
} from 'common/erm-components/utils/Convertors/convertors';
import {
    kustoEventMappersByModelName,
    kustoEventMappersByUiName,
} from 'common/components/KustoEvents/KustoEventService';
import { CommonEventFields } from 'common/module_interface/events/EventsConsts';

export interface IDataSourceConfig {
    pageSize?: number;
    onRowCountUpdate?: Function;
    filters?: IProtectedAssetFilter[];
    mitreInfo?: GenericObject<any>;
    additionalFilterFieldInfo?: IAdditionalFilterFieldInfo[];
}

export class FindingsTableDatasource implements IServerSideDatasource {
    public groupColumns: any;
    private readonly pageSize: number;
    public onRowCountUpdate: Function | undefined;
    public totalCount: number;
    public currentCount: number;
    private filterValues: IFilterDetails | undefined;
    private apis: ITableApis | undefined;
    private isFilterChanged: boolean | undefined;
    private readonly filters?: IProtectedAssetFilter[];
    private readonly mitreInfo?: GenericObject<any>;
    private additionalFilterFieldInfo?: IAdditionalFilterFieldInfo[] = [];

    constructor(config?: IDataSourceConfig) {
        this.onRowCountUpdate = config?.onRowCountUpdate;
        this.pageSize = config?.pageSize ?? 100;
        this.isFilterChanged = false;
        this.totalCount = CounterStatus.Pending;
        this.currentCount = CounterStatus.Pending;
        this.filters = config?.filters;
        this.mitreInfo = config?.mitreInfo;
        this.onRowCountUpdate?.({ currentCount: this.currentCount, totalCount: this.totalCount });
        this.additionalFilterFieldInfo = config?.additionalFilterFieldInfo;
    }

    public setApis(gridApi: GridApi, gridColApi: ColumnApi) {
        this.apis = {
            gridApi: gridApi,
            columnApi: gridColApi,
        };
    }

    public getApis = () => {
        return this.apis;
    };

    public setAdditionalFilterFieldInfo(additionalFilterFieldInfo: IAdditionalFilterFieldInfo[]) {
        this.additionalFilterFieldInfo = additionalFilterFieldInfo;
    }

    public initiateTableRefresh() {
        this.apis?.gridApi?.refreshServerSide({ purge: true });
    }

    async setFilterFields(filterValues: IFiltersValues | undefined) {
        this.filterValues = filterValues;
        this.isFilterChanged = true;
        this.initiateTableRefresh();
    }

    public async getRows(params: IServerSideGetRowsParams) {
        try {
            this.apis?.gridApi.hideOverlay();
            const selectedGroups = map(params.columnApi.getRowGroupColumns(), (column) => column.getColId());
            const isGroupActive = selectedGroups.length > 0;
            if (this.isFilterChanged || (params.request.startRow === 0 && !isGroupActive)) {
                this.currentCount = CounterStatus.Pending;
                this.totalCount = CounterStatus.Pending;
                this.onRowCountUpdate?.({ currentCount: this.currentCount, totalCount: this.totalCount });
                this.totalCount = await this.getGSLRunDataCount(params, selectedGroups);
                this.isFilterChanged = false;
            }
            const request: IGslRunRequest = this.buildGSLRunRequest(params, selectedGroups);
            const findings: IKustoEvent[] = await getKustoEventService().getKustoEventsByRequest(request);
            this.currentCount = params.request.startRow !== undefined ? params.request.startRow + findings.length : 0;
            this.onRowCountUpdate?.({ currentCount: this.currentCount, totalCount: this.totalCount });
            params.success({ rowData: findings, groupLevelInfo: { groupColumns: this.groupColumns } });
            if (isEmpty(findings)) {
                this.apis?.gridApi.showNoRowsOverlay();
            }
        } catch (error) {
            this.currentCount = CounterStatus.Error;
            this.totalCount = CounterStatus.Error;
            this.onRowCountUpdate?.({ currentCount: this.currentCount, totalCount: this.totalCount });
            params.fail();
        }
    }

    public buildGSLRunRequest(params: IServerSideGetRowsParams, selectedGroups: string[]): IGslRunRequest {
        const isSelectedGroups = selectedGroups.length > 0;
        const groupKeys = get(params, 'request.groupKeys');
        const shouldRenderGroupRow = isSelectedGroups && groupKeys.length < selectedGroups.length;

        const { mitreFacetFields, isMitreColumnPresent } = this.mitreInfo ?? {
            mitreFacetFields: [],
            isMitreColumnPresent: false,
        };
        const organizationalUnits: string[] = [];
        this.filterValues?.fields?.forEach((field: IFieldInfo) => {
            if (field.name === CommonEventFields.organizationalUnitId) {
                organizationalUnits.push(field.value);
            }
        });
        const filteredFields = this.filterValues?.fields?.filter(
            (field) => field.name !== CommonEventFields.organizationalUnitId,
        );
        const { queryFilter, mitreLikeFilter } = filterValuesToGslRequestQueryFilters(filteredFields, mitreFacetFields);

        const freeTextFilter: string | undefined = freeTextToGslFormat(
            this.apis?.columnApi,
            this.filterValues?.freeTextPhrase,
        );
        const dateRange: ITimeRange | undefined = dateTimeFilter(this.filterValues);
        const computedFilterString: string = computeFilterString({
            queryFilter,
            freeTextFilter,
            additionalFilterFieldInfo: this.additionalFilterFieldInfo,
        });
        let mitreQueryString = EMPTY_STRING;
        if (!isEmpty(mitreFacetFields) || isMitreColumnPresent) {
            const mitreWhereClause = mitreLikeFilter ? `${GSL_CLAUSES.WHERE} ${mitreLikeFilter}` : EMPTY_STRING;
            mitreQueryString = `${GSL_CLAUSES.JOIN_MITRE} ${mitreWhereClause}`;
        }

        const request: IGslRunRequest = {
            gsl: `${ALERTS} ${computedFilterString} ${mitreQueryString}`.trim(),
            options: {
                source: ALERTS,
                limit: this.pageSize,
                pagination: {
                    page: params.request.startRow !== undefined ? params.request.startRow / this.pageSize + 1 : 1,
                    pageSize: this.pageSize,
                },
                start: dateRange !== undefined ? Number(dateRange.start) : undefined,
                end: dateRange !== undefined ? Number(dateRange.end) : undefined,
                organizationalUnitIds: organizationalUnits.length > 0 ? organizationalUnits : undefined,
            },
        };

        const groupFilters: IFieldInfo[] = [];
        if (shouldRenderGroupRow) {
            this.handleGrouping(params, selectedGroups, groupFilters, request, mitreQueryString);
        } else {
            this.handleSort(params, selectedGroups, groupFilters, request);
        }
        if (request.options)
            request.options.extraFilter = convertFieldInfoListUiToModel(kustoEventMappersByUiName, groupFilters);
        return request;
    }

    public handleGrouping(
        params: IServerSideGetRowsParams,
        selectedGroups: string[],
        groupFilters: IFieldInfo[],
        request: IGslRunRequest,
        mitreQueryString: string,
    ) {
        const groupLevel = params.parentNode.level + 1;
        const groupKeys: string[] = params.request.groupKeys;
        const groupBy: string = selectedGroups[groupLevel];

        const column: Column | undefined = params.columnApi
            .getRowGroupColumns()
            .find((col: Column) => col.getColId() === groupBy);
        const columnDef: CGColDef | undefined = column?.getColDef() as CGColDef;

        if (columnDef && !isNil(columnDef.groupOrder) && !isNil(columnDef.field)) {
            request.options = request.options || {};
            request.options.order = columnDef.groupOrder;
            request.options.orderBy = convertFieldNameUiToModel(kustoEventMappersByUiName, columnDef.field);
        }

        // add all columns configured in additionalColumnsInGroupBy property on column definition
        const additionalColumnsInGroupBy: string[] | undefined = columnDef.additionalColumnsInGroupBy;
        let modelTransformedGroupBy: string = convertFieldNameUiToModel(kustoEventMappersByUiName, groupBy);
        if (additionalColumnsInGroupBy) {
            const additionalModelFields: string[] = convertFieldNameListUiToModel(
                kustoEventMappersByUiName,
                additionalColumnsInGroupBy,
            );
            const groups = [modelTransformedGroupBy, ...additionalModelFields];
            modelTransformedGroupBy = groups.join(',');
        }

        // build summarize clause
        const countType = mitreQueryString ? 'dcount' : 'count';
        const summariesClause =
            ` ${GSL_CLAUSES.SUMMARIZE} ${countType}(findingKey) as numberOfRows by ` +
            `${modelTransformedGroupBy} ${GSL_CLAUSES.ORDER_BY} numberOfRows desc`;
        request.gsl += summariesClause;

        // build extra filter
        for (let index = 0; index < groupLevel; index++) {
            const currentGroupValue = groupKeys[index];
            if (isString(currentGroupValue)) currentGroupValue.replaceAll("'", "\\\\'");
            groupFilters.push({
                name: selectedGroups[index],
                value: currentGroupValue,
            });
        }
    }

    public handleSort(
        params: IServerSideGetRowsParams,
        selectedGroups: string[],
        groupFilters: any,
        request: IGslRunRequest,
    ) {
        if (this.apis?.columnApi) {
            const sortRequest: SortModelItem | undefined =
                params.request?.sortModel && params.request.sortModel.length > 0
                    ? params.request.sortModel[0]
                    : undefined;
            if (sortRequest) {
                const column: Column | undefined = this.apis.columnApi.getColumn(sortRequest.colId) || undefined;
                const field: string | undefined = column ? column.getColDef().field : undefined;
                if (field) {
                    request.options = request.options || {};
                    request.options.orderBy = convertFieldNameUiToModel(kustoEventMappersByUiName, field);
                    if (sortRequest.sort) {
                        request.options.order = sortRequest.sort.toString();
                    }
                }
            }
        }
        // build extra filter
        const groupKeys: string[] = params.request.groupKeys;
        for (let index = 0; index < selectedGroups.length; index++) {
            const currentGroupValue: string = groupKeys[index];
            if (isString(currentGroupValue)) currentGroupValue.replaceAll("'", "\\\\'");
            groupFilters.push({
                name: selectedGroups[index],
                value: currentGroupValue,
            });
        }
    }

    public async getGSLRunDataCount(params: IServerSideGetRowsParams, selectedGroups: string[]): Promise<number> {
        const request: IGslRunRequest = this.buildGSLRunRequest(params, selectedGroups);
        if (request.gsl.includes(GSL_CLAUSES.SUMMARIZE)) {
            const summarizeClause = request.gsl.substring(request.gsl.indexOf(GSL_CLAUSES.SUMMARIZE));
            request.gsl = request.gsl.replace(summarizeClause, EMPTY_STRING);
        }
        request.gsl += ` ${GSL_CLAUSES.SUMMARIZE} dcount(findingKey) as cnt`;

        if (request.options) {
            // disable pagination and sorting while getting records count
            request.options.orderBy = undefined;
            request.options.order = undefined;
            request.options.pagination = undefined;
        }
        try {
            return await getIntelligenceService().getGSLRunDataCount(request);
        } catch (error) {
            this.currentCount = CounterStatus.Error;
            this.totalCount = CounterStatus.Error;
            this.onRowCountUpdate?.({ currentCount: this.currentCount, totalCount: this.totalCount });
            return 0;
        }
    }

    public async fetchIntelligenceFindingsAggregations(filterValues?: IFilterDetails): Promise<Aggregations> {
        const facetFields: string[] = getFilterFacetFields(this.filters);
        const modelFacetFields: string[] = convertFieldNameListUiToModel(kustoEventMappersByUiName, facetFields);
        const { mitreFacetFields, isMitreColumnPresent } = this.mitreInfo ?? {
            mitreFacetFields: [],
            isMitreColumnPresent: false,
        };

        const organizationalUnits: string[] = [];
        filterValues?.fields?.forEach((field: IFieldInfo) => {
            if (field.name === CommonEventFields.organizationalUnitId) {
                organizationalUnits.push(field.value);
            }
        });

        const filteredFields = filterValues?.fields?.filter(
            (field) => field.name !== CommonEventFields.organizationalUnitId,
        );

        const { queryFilter, mitreFilter, mitreLikeFilter } = filterValuesToGslRequestQueryFilters(
            filteredFields,
            mitreFacetFields,
        );

        const freeTextFilter: string | undefined = freeTextToGslFormat(
            this.apis?.columnApi,
            filterValues?.freeTextPhrase,
        );
        const dateRange: ITimeRange | undefined = dateTimeFilter(filterValues);
        const facetClause: string = !isEmpty(modelFacetFields)
            ? `${GSL_CLAUSES.FACET_BY} ${modelFacetFields.join(',')}`
            : EMPTY_STRING;
        const computedFilterString: string = computeFilterString({
            queryFilter,
            freeTextFilter,
            additionalFilterFieldInfo: this.additionalFilterFieldInfo,
        });

        let facetsQuery = `${ALERTS} ${computedFilterString} ${facetClause}`;
        let modelMitreFacetFields: string[] = [];
        if (!isEmpty(mitreFacetFields) || isMitreColumnPresent) {
            // build mitre facets query
            modelMitreFacetFields = convertFieldNameListUiToModel(kustoEventMappersByUiName, mitreFacetFields);
            const defaultMitreTacticFilter = `${convertFieldNameUiToModel(kustoEventMappersByUiName, KustoEventFields.mitreTactic)} like '%%'`;
            const whereClause = `${GSL_CLAUSES.WHERE} ${mitreFilter ? mitreFilter : defaultMitreTacticFilter}`;
            const joinMitreQuery = `${GSL_CLAUSES.JOIN_MITRE} ${whereClause}`;
            const mitreFacetsQueryString = `${ALERTS} ${computedFilterString} ${joinMitreQuery} ${GSL_CLAUSES.FACET_BY} ${modelMitreFacetFields?.join(',')}`;
            // build main facets query
            const mitreLikeFilterQuery = mitreLikeFilter
                ? `${GSL_CLAUSES.JOIN_MITRE} ${GSL_CLAUSES.WHERE} ${mitreLikeFilter}`
                : EMPTY_STRING;
            const mainFacetsQueryString = `${ALERTS} ${computedFilterString} ${mitreLikeFilterQuery} ${facetClause}`;
            // combine both facets queries together
            facetsQuery = `${mainFacetsQueryString} ${OPERATORS.AND} ${mitreFacetsQueryString}`;
        }

        const request: IGslRunRequest = {
            gsl: `${facetsQuery}`,
            options: {
                source: ALERTS,
                start: Number(dateRange?.start),
                end: Number(dateRange?.end),
                multiStatementStrategy: 'union',
                organizationalUnitIds: organizationalUnits.length > 0 ? organizationalUnits : undefined,
            },
        };
        const facetResponse: IGslRunFacetResponse = await getIntelligenceService().getIntelligenceFacets(request);
        // convert facets to aggregation
        const modelAggregations: Aggregations = convertFacetsToAggregations(
            [...modelFacetFields, ...modelMitreFacetFields],
            facetResponse.data,
        );
        return convertAggregationsModelToUi(kustoEventMappersByModelName, modelAggregations);
    }
}
