import { getHttpService } from 'common/interface/services';
import {
    IOrganizationalUnit,
    IOrganizationalUnitAllChildrens,
    IOrganizationalUnitData,
    IOrganizationalUnitService,
    IOrganizationalUnitTreeNode,
} from 'common/interface/data_services';
import { DEFAULT_ORGANIZATIONAL_UNIT_ID } from 'common/consts/DataConsts';

class OrganizationalUnit implements IOrganizationalUnit {
    public id: string;
    public name: string;
    public path: string;
    public children?: IOrganizationalUnit[];

    constructor(tree: IOrganizationalUnit) {
        this.id = tree.id;
        this.name = tree.name;
        this.path = tree.path;
        this.children = tree.children?.map((child) => new OrganizationalUnit(child));
    }
}

const cachingConfig = { useCache: true, tags: ['OrganizationalUnit'] };

const updateFullHierarchy = (
    selectedIdsSet: Set<string>,
    resultItems: IOrganizationalUnitData[],
    node: IOrganizationalUnitTreeNode,
    isParentSelected: boolean,
) => {
    const isSelected = isParentSelected || selectedIdsSet.has(node.item.id);
    if (isSelected && node.item.id !== DEFAULT_ORGANIZATIONAL_UNIT_ID) {
        resultItems.push(node.item);
    }
    if (node.children) {
        node.children.forEach((childNode: IOrganizationalUnitTreeNode) =>
            updateFullHierarchy(selectedIdsSet, resultItems, childNode, isSelected),
        );
    }
};

export class OrganizationalUnitService implements IOrganizationalUnitService {
    async getOrganizationalUnitPathById(id: string): Promise<IOrganizationalUnitTreeNode[] | null> {
        const allOrganizationalUnits = await this.getAllOrganizationalUnits();

        function findRecursive(
            organizationalUnits: IOrganizationalUnitTreeNode[],
            currentPath: IOrganizationalUnitTreeNode[],
        ): IOrganizationalUnitTreeNode[] | null {
            for (const organizationalUnit of organizationalUnits) {
                const inclusivePath = [...currentPath, organizationalUnit];
                if (organizationalUnit.item.id === id) {
                    return inclusivePath;
                }
                const foundSubPath = findRecursive(organizationalUnit.children, inclusivePath);
                if (foundSubPath) {
                    return foundSubPath;
                }
            }

            return null;
        }

        return findRecursive(allOrganizationalUnits, []);
    }

    async getAllOrganizationalUnitsFlatWithPath(includingRoot?: boolean): Promise<IOrganizationalUnit[]> {
        const rootOrganizationalUnit = await this.getOrganizationalUnitsView();

        const flatList: IOrganizationalUnit[] = [];

        function getPathRecursive(organizationalUnits: IOrganizationalUnit[]) {
            organizationalUnits.forEach((ou) => {
                flatList.push({ id: ou.id, name: ou.name, path: ou.path });
                if (ou.children?.length) {
                    getPathRecursive(ou.children);
                }
            });
        }

        if (includingRoot && rootOrganizationalUnit) {
            flatList.push({
                id: rootOrganizationalUnit.id,
                name: rootOrganizationalUnit.name,
                path: rootOrganizationalUnit.path,
            });
        }
        if (rootOrganizationalUnit?.children?.length) getPathRecursive(rootOrganizationalUnit.children);

        return flatList;
    }

    async getAllOrganizationalUnitsFlatWithAllChildrens(
        includingRoot?: boolean,
    ): Promise<IOrganizationalUnitAllChildrens[]> {
        const rootOrganizationalUnit = await this.getOrganizationalUnitsView();

        const flatten = (arr: Array<OrganizationalUnit>): Array<IOrganizationalUnitAllChildrens> => {
            return arr.reduce<Array<IOrganizationalUnitAllChildrens>>((acc, org) => {
                const { children, ...rest } = org;
                let allChildrens: IOrganizationalUnitAllChildrens[] = [];
                if (children) {
                    allChildrens = flatten(children);
                }
                acc.push({ ...rest, allChildrens });
                acc.push(...allChildrens);
                return acc;
            }, []);
        };

        const flatList = flatten(includingRoot ? [rootOrganizationalUnit] : rootOrganizationalUnit.children || []);
        return flatList;
    }

    async getAllOrganizationalUnitsFlatWithCounts(useCache = true): Promise<IOrganizationalUnitData[]> {
        const nodeTree = await this.getAllOrganizationalUnits(useCache);
        const items: IOrganizationalUnitData[] = [];
        const flatTree = (node: IOrganizationalUnitTreeNode, parentPath: string[]) => {
            const itemPath = [...parentPath, node.item.name];
            node.item.path = itemPath;
            items.push(node.item);
            if (node.children.length > 0) {
                node.children.forEach((child) => {
                    flatTree(child, itemPath);
                });
            }
        };
        nodeTree.forEach((rootNode) => {
            flatTree(rootNode, []);
        });
        return items;
    }

    async getOrganizationalUnitById(id: string): Promise<IOrganizationalUnit | null> {
        const allOrganizationalUnits = await this.getAllOrganizationalUnitsFlat();
        return allOrganizationalUnits.find((orgUnit: IOrganizationalUnit) => orgUnit.id === id) || null;
    }

    async getAllOrganizationalUnits(useCache = true): Promise<IOrganizationalUnitTreeNode[]> {
        return getHttpService().get<IOrganizationalUnitTreeNode[]>({
            path: 'organizationalunit',
            cachingConfig: useCache ? cachingConfig : { useCache: false, tags: ['OrganizationalUnit'] },
        });
    }

    async getOrganizationalUnitsView(useCache = true) {
        const response = await getHttpService().get<OrganizationalUnit>({
            path: 'organizationalUnit/view',
            cachingConfig: useCache ? cachingConfig : { useCache: false, tags: ['OrganizationalUnit'] },
        });
        return new OrganizationalUnit(response);
    }

    async getAllOrganizationalUnitsFlat() {
        return await getHttpService().get<OrganizationalUnit[]>({
            path: 'organizationalUnit/GetFlatOrganizationalUnits',
            cachingConfig,
        });
    }

    async getFlatOrgUnitsBySelection(selectedIds: string[]): Promise<IOrganizationalUnitData[]> {
        const selectedIdsSet = new Set<string>(selectedIds);
        const resultItems: IOrganizationalUnitData[] = [];
        const allOrgUnitNodes: IOrganizationalUnitTreeNode[] = (await this.getAllOrganizationalUnits()) || [];
        if (allOrgUnitNodes.length) {
            updateFullHierarchy(selectedIdsSet, resultItems, allOrgUnitNodes[0], false);
            return resultItems;
        }
        return [];
    }

    async getFlatOrgUnitIdsBySelection(selectedIds: string[]): Promise<string[]> {
        const orgUnits: IOrganizationalUnitData[] = await this.getFlatOrgUnitsBySelection(selectedIds);
        return orgUnits.map((orgUnit: IOrganizationalUnitData) => orgUnit.id);
    }

    async createOrganizationalUnit(name: string, parentId: string) {
        return await getHttpService().post<string>(
            'organizationalunit',
            {
                data: { name: name, parentId: parentId },
            },
            {},
            (error) => {
                throw error;
            },
        );
    }

    async updateOrganizationalUnit(id: string, name: string, parentId: string) {
        await getHttpService().put(
            `organizationalunit/${id}`,
            {
                data: { id, name, parentId },
            },
            {},
            (error) => {
                throw error;
            },
        );
    }

    async deleteOrganizationalUnit(id: string, force = false) {
        if (force) {
            await getHttpService().delete(`organizationalunit/${id}/DeleteForce`, {
                data: { id },
            });
        } else {
            await getHttpService().delete(
                `organizationalunit/${id}`,
                {
                    data: { id },
                },
                {},
                (error) => {
                    throw error;
                },
            );
        }
    }
}
