export interface ISelectOption {
    label: string;
    value: any;
    additionalData?: any;
}

export const COMPLIANCE_TYPES = {
    OU: 'organizational unit',
    ENV: 'environment',
};

export const widgetsTypeFieldSetsCount: { [key: string]: number } = {
    gauge: 1,
    trend: 4,
    'trend-change-summary': 1,
    'trend-line-with-change-summary': 1,
};

export enum SeverityEnum {
    critical = 'critical',
    high = 'high',
    medium = 'medium',
    low = 'low',
    informational = 'informational',
}

export enum LegendTypeEnum {
    TOTAL = 'total',
    BY_SEVERITY = 'bySeverity',
}
