import styled from 'styled-components';

export const AWSNodeGroupRoleAuthExplanationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px;
`;

export const ExplanationRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 6px;

    &:first-child {
        margin-bottom: 8px;
    }

    div:first-child {
        padding: 0 8px;
    }

    div:nth-child(2) {
        line-height: 20px;
    }
`;
