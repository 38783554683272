import React from 'react';
import { Stack, LevelIcon } from 'common/design-system/components-v2';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { IRuleset } from 'common/interface/ruleset';
import { IRemediation } from 'common/interface/remediation';
import { ICloudAccount } from 'common/interface/data_services';
import { getStoreService } from 'common/interface/services';
interface IRulesetCellRenderer extends ICellRendererParams<IRemediation> {}
export const RulesetCellRenderer: React.FC<IRulesetCellRenderer> = (params) => {
    const { state } = getStoreService().getReduxTools();
    const ruleset =
        state.remediation?.rulesetList?.find((ruleset: IRuleset) => ruleset.id === params.data?.rulesetId) || {};
    return (
        <Stack direction={'row'} alignItems={'center'} spacing={3}>
            <LevelIcon iconProps={{ name: 'remedy' }} size='md' />
            {ruleset?.name ? ruleset.name : '**Deleted Ruleset**'}
        </Stack>
    );
};

export const CloudAccountCellRenderer: React.FC<IRulesetCellRenderer> = (params) => {
    const { state } = getStoreService().getReduxTools();
    const cloudAccount =
        state.remediation?.cloudAccounts?.find(
            (cloudAccount: ICloudAccount) => cloudAccount.id === params.data?.cloudAccountId,
        ) || {};
    return (
        <Stack direction={'row'} alignItems={'center'} spacing={3}>
            {/* eslint-disable-next-line */}
            <a>{cloudAccount?.name ? cloudAccount.name : ''}</a>
        </Stack>
    );
};
