import React, { useCallback, useMemo, useState } from 'react';
import { Table, Typography } from 'common/design-system/components-v2';
import { isFilterValuesEmpty } from '../../filterUtils';
import { ColumnApi, GridApi, GridReadyEvent, IRowNode } from 'ag-grid-community';
import FilterPanelVulnerability from '../../FilterPanelVulnerability/FilterPanelVulnerability';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { getColumnDefs } from './ColumnDefs';
import { ThreatsProps, ThreatColumnsNames } from './Threats.types';
import ExpandCollapseAction from 'common/components/ag-grid/Actions/ExpandCollapseAction';
import useExpandOrCollapseAggrid from 'modules/workloads/hooks/useExpandOrCollapseAggrid';

let objectFilter: IFiltersValues = {};

const Threats: React.FC<ThreatsProps> = ({ threats, isThreats = true, exportCveAsJSONAndCSV, layers }) => {
    const [itemDisplayedRowCount, setItemDisplayedRowCount] = useState<number>(0);
    const { onColumnRowGroupChanged, onRowGroupOpened, onExpandOrCollapseAll, expandState } =
        useExpandOrCollapseAggrid();
    const gridRef = React.useRef<GridApi>();
    const columnApi = React.useRef<ColumnApi>();
    const { t } = useTranslation(getK8sNamespace('vulnerability'));

    const groupByLayers = useCallback(() => {
        if (layers) {
            columnApi.current?.setRowGroupColumns([ThreatColumnsNames.LayerCommand]);
        } else {
            columnApi.current?.setRowGroupColumns([]);
            columnApi.current?.setColumnVisible(ThreatColumnsNames.LayerId, false);
        }
    }, [layers]);

    const onGridReady = useCallback(
        (event: GridReadyEvent) => {
            gridRef.current = event.api;
            columnApi.current = event.columnApi;
            if (threats.length === 0) {
                columnApi.current?.setRowGroupColumns([]);
            } else {
                groupByLayers();
            }
        },
        [groupByLayers, threats.length],
    );

    const columnDefs = useMemo(() => getColumnDefs(isThreats, layers), [isThreats, layers]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            sortable: true,
        };
    }, []);

    const isExternalFilterPresent = useCallback(() => {
        return objectFilter && !isFilterValuesEmpty(objectFilter);
    }, []);

    const doesExternalFilterPass = useCallback((node: IRowNode) => {
        let doesExternalFilterPassValue = true;
        if (node.data) {
            Object.keys(objectFilter).forEach((key) => {
                if (!objectFilter[key] || objectFilter[key].length === 0) return;
                if (key !== 'free-text') {
                    const values: Array<string> = objectFilter[key];
                    if (typeof node.data[key] === 'boolean') {
                        const doesFilterPassValues = values.some((value) => node.data[key].toString() === value);
                        doesExternalFilterPassValue = doesExternalFilterPassValue && doesFilterPassValues;
                    } else {
                        const doesFilterPassValues = values.some((value) => node.data[key] === value);
                        doesExternalFilterPassValue = doesExternalFilterPassValue && doesFilterPassValues;
                    }
                } else {
                    const doesFilterPassFreeText = Object.values(node.data).some((item: any) => {
                        if (!item) return false;
                        return item.toLowerCase().includes(objectFilter[key].toLowerCase());
                    });
                    doesExternalFilterPassValue = doesExternalFilterPassValue && doesFilterPassFreeText;
                }
            });
        }
        return doesExternalFilterPassValue;
    }, []);

    const externalFilterChanged = useCallback((newValue: IFiltersValues) => {
        objectFilter = newValue;
        gridRef.current?.onFilterChanged();
        setItemDisplayedRowCount(gridRef.current?.getDisplayedRowCount() || 0);
    }, []);

    const actions = [
        {
            id: 'collapse-expand',
            name: 'collapse-expand-action',
            callback: () => null,
            customComponent: () => {
                return (
                    <ExpandCollapseAction
                        groupsNo={expandState.maxLevel}
                        expanded={expandState.expandedParents}
                        onClick={(isExpanded: boolean) => {
                            isExpanded ? gridRef.current?.collapseAll() : gridRef.current?.expandAll();
                        }}
                    />
                );
            },
        },
    ];

    return (
        <>
            <FilterPanelVulnerability
                isRemediations={false}
                data={threats}
                onFilterChange={externalFilterChanged}
            ></FilterPanelVulnerability>
            <Table
                tableId='threats'
                saveColumnsState
                exportButtons={exportCveAsJSONAndCSV}
                ellipsisRowGroup
                gridOptions={{
                    onColumnRowGroupChanged: onColumnRowGroupChanged,
                    onExpandOrCollapseAll: onExpandOrCollapseAll,
                    onRowGroupOpened: onRowGroupOpened,
                    rowData: threats,
                    columnDefs: columnDefs,
                    onGridReady,
                    doesExternalFilterPass,
                    isExternalFilterPresent,
                    defaultColDef,
                }}
                actions={actions}
                footer={
                    <Typography>
                        {t('VulnerabilityTable.footer', {
                            itemDisplayedRowCount: itemDisplayedRowCount,
                            totalRowCount: threats.length || 0,
                        })}
                    </Typography>
                }
            />
        </>
    );
};

export default Threats;
