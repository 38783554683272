import React, { MutableRefObject, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { t } from 'i18next';
import { Button, Icon, Input, Modal, Spinner, Stack, Table, Typography } from 'common/design-system/components-v2';
import { IdenticalRolesModalStyles } from './IdenticalIdentities.styled';
import { GridOptions, GridReadyEvent, IRowNode } from 'ag-grid-community';
import { getAutoGroupColumnDef, getColumnDefs } from './IdenticalIdentities.columns';
import { IIdenticalIdentitiesTreeNode } from 'common/module_interface/identity/IdenticalRoles.interfaces';
import { getIdentityService } from 'common/module_interface/identity/Identity.service';
import FullPageLoader from 'common/erm-components/custom/FullSize/FullPageLoader/FullPageLoader';
import { IModalsProps } from 'common/module_interface/assets/Environments';
import { identityTrans } from '../initialize.i18n';
import { getNotificationsService } from 'common/interface/services';
import { GridApi } from 'ag-grid-enterprise';
import { EMPTY_STRING } from 'common/consts/GeneralConsts';
import { getCloudAccountsService } from 'common/interface/data_services';

const IDENTICAL_IDENTITIES_TABLE_ID = 'identical-identities-table';
const IdenticalIdentitiesModal: React.FC<IModalsProps> = ({ closeModal, cloudAccountId }) => {
    const [rowData, setRowData] = useState<IIdenticalIdentitiesTreeNode[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [cloudAccountName, setCloudAccountName] = useState<string>();
    const gridApiRef: MutableRefObject<GridApi<IIdenticalIdentitiesTreeNode> | undefined> =
        useRef<GridApi<IIdenticalIdentitiesTreeNode>>();
    const searchInputRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
    const debounce: MutableRefObject<NodeJS.Timeout | null> = React.useRef<NodeJS.Timeout | null>(null);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const [cloudAccount, identicalRolesTreeNodes] = await Promise.all([
                getCloudAccountsService().getCloudAccountByAccountId(cloudAccountId),
                getIdentityService().fetchIdenticalRolesTreeData(cloudAccountId),
            ]);
            if (cloudAccount) setCloudAccountName(cloudAccount.name);
            setRowData(identicalRolesTreeNodes);
        } catch (error: unknown) {
            getNotificationsService().error(
                identityTrans('IDENTICAL_IDENTITIES_MODAL.ERRORS.FAILED_TO_FETCH'),
                error as string,
            );
        } finally {
            setIsLoading(false);
        }
    }, [cloudAccountId]);

    useEffect(() => {
        fetchData();
        return () => {
            if (debounce.current) clearTimeout(debounce.current);
        };
    }, [cloudAccountId, fetchData]);

    const onGridReady = (params: GridReadyEvent<IIdenticalIdentitiesTreeNode>) => {
        gridApiRef.current = params.api;
    };

    const getDataPath = useCallback((data: IIdenticalIdentitiesTreeNode): string[] => data.path, [rowData]);

    const doesExternalFilterPass = useCallback(
        (node: IRowNode<IIdenticalIdentitiesTreeNode>): boolean => {
            const dataPath: string[] | undefined = node.data?.path;
            if (!dataPath) return false;
            const searchValue: string = searchInputRef.current?.value.toLowerCase().trimEnd() ?? EMPTY_STRING;
            return dataPath.some((path: string) => path.toLowerCase().includes(searchValue));
        },
        [searchInputRef],
    );

    const onFilterChanged = useCallback(() => {
        const filteredRowCount: number = gridApiRef.current?.getDisplayedRowCount() ?? 0;
        if (filteredRowCount === 0) {
            gridApiRef.current?.showNoRowsOverlay();
        } else {
            gridApiRef.current?.hideOverlay();
        }
    }, [gridApiRef]);

    const onSearchInputChange = useCallback(() => {
        if (debounce.current) clearTimeout(debounce.current);
        debounce.current = setTimeout(() => {
            gridApiRef?.current?.onFilterChanged();
        }, 300);
    }, [gridApiRef]);

    const gridOptions: GridOptions<IIdenticalIdentitiesTreeNode> = useMemo(() => {
        return {
            columnDefs: getColumnDefs(),
            autoGroupColumnDef: getAutoGroupColumnDef(),
            treeData: true,
            rowData: rowData,
            getDataPath: getDataPath,
            groupDefaultExpanded: 2,
            isExternalFilterPresent: () => true,
            doesExternalFilterPass,
            onFilterChanged,
            onGridReady,
        };
    }, [rowData, getAutoGroupColumnDef, getDataPath, doesExternalFilterPass, onFilterChanged, onGridReady]);

    return (
        <Modal.ModalDialog onRequestClose={closeModal} isOpen={true} width={'xl'}>
            <Modal.ModalHeader
                title={identityTrans('IDENTICAL_IDENTITIES_MODAL.TITLE')}
                onClose={closeModal}
                subTitle={
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <Typography variant={'body500'}>
                            {identityTrans('IDENTICAL_IDENTITIES_MODAL.SUBTITLE')}
                        </Typography>
                        <Typography variant={'body'}>{isLoading ? <Spinner size={12} /> : cloudAccountName}</Typography>
                    </Stack>
                }
            />
            <Modal.ModalContent fullHeight>
                <IdenticalRolesModalStyles.TableWrapper>
                    {isLoading && <FullPageLoader />}
                    {!isLoading && (
                        <Stack fullHeight direction={'column'} spacing={2}>
                            <Input
                                ref={searchInputRef}
                                clearable={true}
                                startAdornment={<Icon name='magnify' />}
                                data-aid={'free-text-filter-identical-identities'}
                                onChange={onSearchInputChange}
                                placeholder={t('FILTER_PANEL.SEARCH')}
                            ></Input>
                            <Table
                                tableId={IDENTICAL_IDENTITIES_TABLE_ID}
                                gridOptions={gridOptions}
                                disableGrouping={true}
                                disableColumnMenu={true}
                            ></Table>
                        </Stack>
                    )}
                </IdenticalRolesModalStyles.TableWrapper>
            </Modal.ModalContent>
            <Modal.ModalFooter>
                <Stack direction='row' justifyContent='flex-end' fullWidth spacing={2}>
                    <Button variant='outlined' color={'normal'} onClick={closeModal}>
                        {t('COMMON.CLOSE_PASCAL')}
                    </Button>
                </Stack>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    );
};

export default IdenticalIdentitiesModal;
