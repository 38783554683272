import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Spinner, Stack, Tooltip, Typography } from 'common/design-system/components-v2';
import { IEnvironment } from 'common/interface/environmentsTable';
import { VersionStatus } from 'modules/workloads/services/workload/workload.interface';

export interface KubernetesClusterVersion {
    version?: string;
    status: VersionStatus;
    statusDescription?: string;
}

interface IClusterVersionCellRendererProps extends ICellRendererParams<IEnvironment> {}

const ClusterVersionCellRenderer = React.forwardRef<any, IClusterVersionCellRendererProps>((params, ref) => {
    const [version, setVersion] = React.useState<KubernetesClusterVersion | undefined>(
        params.data?.['customData|kubernetesClusterVersion'],
    );
    const [isLoading, setIsLoading] = React.useState<boolean>(params.data?.['customData|workloadEnvironmentLoading']);

    React.useImperativeHandle(ref, () => {
        return {
            refresh(params: IClusterVersionCellRendererProps) {
                setVersion(params.data?.['customData|kubernetesClusterVersion']);
                setIsLoading(params.data?.['customData|workloadEnvironmentLoading']);
                return true;
            },
        };
    });

    const versionTooltipContent = React.useMemo(() => {
        if (version?.status === VersionStatus.Error || version?.status === VersionStatus.Warning) {
            return version?.statusDescription?.split('Learn more')[0].trim();
        }
        return undefined;
    }, [version]);

    const text = React.useMemo(() => {
        if (!version) return '';
        return version.version;
    }, [version]);

    const subText = React.useMemo(() => {
        if (!version) return undefined;
        switch (version.status) {
            case VersionStatus.Error:
                return '(deprecated)';
            case VersionStatus.Warning:
                return '(deprecated soon)';
        }
    }, [version]);

    if (isLoading) {
        return (
            <Stack justifyContent='center' alignItems='flex-start' fullHeight fullWidth>
                <Spinner size={16} />
            </Stack>
        );
    }

    return (
        <Tooltip content={versionTooltipContent} delay={1000}>
            <Stack direction='row' alignItems='center' spacing={1}>
                <span>{text}</span>
                {subText && (
                    <Typography color='light' variant='bodyXs'>
                        {subText}
                    </Typography>
                )}
            </Stack>
        </Tooltip>
    );
});
ClusterVersionCellRenderer.displayName = 'ClusterVersionCellRenderer';

export default ClusterVersionCellRenderer;
