import { IEvent, IEventOverviewPanelProvider } from 'common/module_interface/events/Events';
import React from 'react';
import { ENTITIES_WITH_PERMISSIONS_TAB } from 'common/module_interface/identity/IdentityConsts';
import { generateEntityLink } from 'common/module_interface/intelligence/Intelligence.utils';
import { ALink } from 'common/components/ALink';
import PropertyBox from 'common/components/PropertyBox/PropertyBox';
import { isEmpty } from 'lodash';
import { TFunction } from 'i18next';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from '../../../initialize.i18n';
import TextPropertyBox from 'common/components/PropertyBox/TextPropertyBox';
import { Icon, Typography } from 'common/design-system/components-v2';
import { FindingOverviewUpperPanelsIds } from '../../Findings.const';
import { FindingSeverityCellComponent } from 'common/components/Findings/FindingSeverityCellComponent';
import moment from 'moment-mini';

const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);

export const EventOverviewUpperPanelsTypes: { [key: string]: IEventOverviewPanelProvider } = {
    DATE_PANEL: {
        panelId: FindingOverviewUpperPanelsIds.DATE_PANEL,
        panel: ({ event }) => {
            const formattedDate = moment(event.createdTime).format('lll');
            return <TextPropertyBox title={t('INT_FINDING_OVERVIEW.DATE')} value={formattedDate} />;
        },
        isRelevant: (finding) => !isEmpty(finding.createdTime),
    },
    SEVERITY_PANEL: {
        panelId: FindingOverviewUpperPanelsIds.SEVERITY_PANEL,
        panel: ({ event }) => {
            const severityContent = <FindingSeverityCellComponent severityNameOrLevel={event.severity} />;
            return <PropertyBox title={t('INT_FINDING_OVERVIEW.SEVERITY')} content={severityContent} />;
        },
        isRelevant: (finding) => !isEmpty(finding.severity),
    },
    ENTITY_PANEL: {
        panelId: FindingOverviewUpperPanelsIds.ENTITY_PANEL,
        panel: ({ event }) => {
            const EntityLinkContentBoxComponent: React.FC<{ event: IEvent }> = ({ event }) => {
                const entity = event.entityName || event.entityExternalId;
                const tabName = ENTITIES_WITH_PERMISSIONS_TAB.includes(event.entityType) ? 'permissions' : undefined;
                const entityLink = generateEntityLink(
                    event.entityType,
                    event.entityExternalId,
                    event.cloudAccountId,
                    event.entityDome9Id,
                    tabName,
                );
                let content = <Typography>{entity}</Typography>;
                if (entityLink) {
                    content = (
                        <ALink href={entityLink} target='_blank' rel='noreferrer'>
                            {entity}
                        </ALink>
                    );
                }
                return <PropertyBox title={t('INT_FINDING_OVERVIEW.ENTITY')} content={content} />;
            };
            return <EntityLinkContentBoxComponent event={event} />;
        },
        isRelevant: (finding) => !isEmpty(finding.entityName || finding.entityExternalId),
    },
    LOG_TYPE_PANEL: {
        panelId: FindingOverviewUpperPanelsIds.LOG_TYPE_PANEL,
        panel: ({ event }) => {
            const logType = event.additionalFieldsMap?.accountActivity
                ? t('INT_FINDING_OVERVIEW.ACCOUNT_ACTIVITY')
                : t('INT_FINDING_OVERVIEW.NETWORK_TRAFFIC');
            return <TextPropertyBox title={t('INT_FINDING_OVERVIEW.LOG_TYPE')} value={logType} />;
        },
        isRelevant: (finding) =>
            !isEmpty(finding.additionalFieldsMap?.accountActivity) ||
            !isEmpty(finding.additionalFieldsMap?.networkActivity),
    },
    ACKNOWLEDGED_PANEL: {
        panelId: FindingOverviewUpperPanelsIds.ACKNOWLEDGED_PANEL,
        panel: () => {
            return (
                <PropertyBox
                    title={t('INT_FINDING_OVERVIEW.ACK')}
                    content={<Icon name={'checkCircle'} color={'success'} />}
                />
            );
        },
        isRelevant: (finding) => finding.acknowledged,
    },
    EXCLUDED_PANEL: {
        panelId: FindingOverviewUpperPanelsIds.EXCLUDED_PANEL,
        panel: () => {
            return (
                <PropertyBox title={t('INT_FINDING_OVERVIEW.IS_EXCLUDED')} content={<Icon name={'groupExclusion'} />} />
            );
        },
        isRelevant: (finding) => finding.isExcluded,
    },
};
