import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { SIMILARITY_GROUP_ID } from '../ProtectedAssetsPage/ProtectedAssetsList';
import { AggregationLevel, Datasource } from 'common/components/ProtectedAssets/datasource';

export const fetchSimilarAssets = async (similarityGroupId: string): Promise<IProtectedAssetViewModel[]> => {
    const filters = {
        fields: [{ name: SIMILARITY_GROUP_ID, value: similarityGroupId }],
        filterEntitiesWithoutRiskScore: true,
    };

    const pageSize = 1000;
    const dataSource = new Datasource({
        pageSize,
        filters,
    });
    const response = await dataSource.getAdHokDataFromServer(AggregationLevel.NONE, pageSize);
    return response.assets.sort((asset1, asset2) => (asset2.riskScore ?? 0) - (asset1.riskScore ?? 0));
};
