import React from 'react';
import { useTranslation } from 'react-i18next';
import { isNil } from 'common/utils/helpFunctions';
import {
    I18nRiskNamespace,
    IamExposureLevelEnum,
    IamExposureServerIdEnum,
    NetworkExposureLevelEnum,
} from '../../consts';
import { ChipTooltipStyled } from './ChipTooltipStyled';
import i18n from 'i18next';
import { getRiskLevelByScore, RiskLevelsMap } from 'common/module_interface/RiskManagement/RiskLevel';
import Chip from 'common/design-system/components-v2/Chip';
import { Tooltip } from 'common/design-system/components-v2';
import { AllIconsName, IconSizes } from 'common/design-system/components-v2/Icon/Icon.types';
import { getSafeWafProtectionInfo } from '../WafProtection/WafProtection';
import { getIamSensitivityLevelInfo, IIamSensitivityInfo } from '../ProtectedAsset/AssetModifiers/IamSensitivity';
import { getDataSensitivityLevelInfo, IDataSensitivityInfo } from '../ProtectedAsset/AssetModifiers/DataSensitivity';

import { getIamExposureLevelInfo } from '../ProtectedAsset/AssetModifiers/IamExposure';
import { getBusinessPriorityLevelInfo } from '../ProtectedAsset/AssetModifiers/BusinessPriority';
import { getNetworkExposureLevelInfo } from '../ProtectedAsset/AssetModifiers/NetworkExposure';

const getTooltipContent = (text: string): JSX.Element => {
    return (
        <ChipTooltipStyled.TopDiv>
            <ChipTooltipStyled.SimpleTextDiv>{text}</ChipTooltipStyled.SimpleTextDiv>
        </ChipTooltipStyled.TopDiv>
    );
};

const ICON_SIZE: IconSizes = 16;

export const RiskScoreChipWidget: React.FC<{ riskScore?: number }> = ({ riskScore }) => {
    const { t } = useTranslation(I18nRiskNamespace);
    let title;
    if (riskScore === undefined) {
        title = t('ASSET_DETAILS.RISK_SCORE_CHIP.NOT_AVAILABLE');
    } else {
        title = `${riskScore}`;
    }
    if (!isNil(riskScore)) {
        const riskLevelInfo = getRiskLevelByScore(riskScore) || RiskLevelsMap.unknown;
        return (
            <Tooltip content={getTooltipContent(t('ASSET_DETAILS.RISK_DATA.RISK_SCORE'))}>
                <Chip
                    label={title}
                    leadingIconProps={{ name: riskLevelInfo.gaugeIconName, size: ICON_SIZE }}
                    context={riskLevelInfo.context}
                />
            </Tooltip>
        );
    }

    return <></>;
};

export const ExposureChipWidget: React.FC<{ networkExposure?: string; iamExposure?: string }> = ({
    networkExposure,
    iamExposure,
}) => {
    const { t } = useTranslation(I18nRiskNamespace);
    const networkExposureLevelInfo = getNetworkExposureLevelInfo(networkExposure);
    const iamExposureLevelInfo = getIamExposureLevelInfo(iamExposure);
    if (
        networkExposureLevelInfo.id === NetworkExposureLevelEnum.Unknown &&
        iamExposureLevelInfo.id === IamExposureLevelEnum.Unknown
    ) {
        return <></>;
    }

    const networkLine =
        networkExposureLevelInfo.id === NetworkExposureLevelEnum.Unknown ? undefined : (
            <ChipTooltipStyled.InfoLineDiv key={'network'}>
                <ChipTooltipStyled.InfoNameDiv>{`${t('ASSET_DETAILS.RISK_DATA.CONTEXT.NETWORK_EXPOSURE')}:`}</ChipTooltipStyled.InfoNameDiv>
                <ChipTooltipStyled.InfoSepDiv />
                <ChipTooltipStyled.InfoValueDiv>{networkExposureLevelInfo.title}</ChipTooltipStyled.InfoValueDiv>
            </ChipTooltipStyled.InfoLineDiv>
        );
    const iamLine =
        iamExposureLevelInfo.id === IamExposureServerIdEnum.Unknown ? undefined : (
            <ChipTooltipStyled.InfoLineDiv key={'iam'}>
                <ChipTooltipStyled.InfoNameDiv>{`${t('ASSET_DETAILS.RISK_DATA.CONTEXT.IAM_EXPOSURE')}:`}</ChipTooltipStyled.InfoNameDiv>
                <ChipTooltipStyled.InfoSepDiv />
                <ChipTooltipStyled.InfoValueDiv>{iamExposureLevelInfo.title}</ChipTooltipStyled.InfoValueDiv>
            </ChipTooltipStyled.InfoLineDiv>
        );

    const tooltipLines = [];
    let highestLevelInfo: any;
    if (networkExposureLevelInfo.id === NetworkExposureLevelEnum.Unknown) {
        highestLevelInfo = iamExposureLevelInfo;
        tooltipLines.push(iamLine);
    } else if (iamExposureLevelInfo.id === IamExposureServerIdEnum.Unknown) {
        highestLevelInfo = networkExposureLevelInfo;
        tooltipLines.push(networkLine);
    } else {
        if (networkExposureLevelInfo.level >= iamExposureLevelInfo.level) {
            highestLevelInfo = networkExposureLevelInfo;
            tooltipLines.push(networkLine);
            tooltipLines.push(iamLine);
        } else {
            highestLevelInfo = iamExposureLevelInfo;
            tooltipLines.push(iamLine);
            tooltipLines.push(networkLine);
        }
    }
    const tooltip = <ChipTooltipStyled.TopDiv>{tooltipLines}</ChipTooltipStyled.TopDiv>;

    return (
        <Tooltip content={tooltip}>
            <Chip
                label={highestLevelInfo.title}
                leadingIconProps={{ name: highestLevelInfo.iconProps.name, size: ICON_SIZE }}
                customTextColor={highestLevelInfo.iconProps.customColor}
                customColor={highestLevelInfo.bg}
            />
        </Tooltip>
    );
};

export const BusinessPriorityChipWidget: React.FC<{ businessPriority?: string }> = ({ businessPriority }) => {
    const { t } = useTranslation(I18nRiskNamespace);
    if (!businessPriority) return <></>;

    const businessPriorityExtension = getBusinessPriorityLevelInfo(businessPriority);
    if (!businessPriorityExtension) return <></>;
    return (
        <Tooltip content={getTooltipContent(t('ASSET_DETAILS.RISK_DATA.IMPACT.BUSINESS_PRIORITY'))}>
            <Chip
                label={businessPriorityExtension.title}
                leadingIconProps={{ name: businessPriorityExtension.iconName, size: ICON_SIZE }}
                customTextColor={businessPriorityExtension.fg}
                customColor={businessPriorityExtension.bg}
            />
        </Tooltip>
    );
};

export const IamSensitivityChipWidget: React.FC<{ iamSensitivity?: number }> = ({ iamSensitivity }) => {
    const iamSensitivityInfo: IIamSensitivityInfo = getIamSensitivityLevelInfo(iamSensitivity);
    const { t } = useTranslation(I18nRiskNamespace);
    const iconName: AllIconsName | undefined = iamSensitivityInfo.iconProps.name;
    return (
        <Tooltip content={getTooltipContent(t('ASSET_DETAILS.RISK_DATA.IMPACT.IAM_SENSITIVITY'))}>
            <Chip
                label={`${iamSensitivity ?? i18n.t('COMMON.N_A')}`}
                leadingIconProps={iconName && { name: iconName, size: ICON_SIZE }}
                customTextColor={iamSensitivityInfo.iconProps.customColor}
                customColor={iamSensitivityInfo.bg}
            />
        </Tooltip>
    );
};

export const DataSensitivityChipWidget: React.FC<{
    dataSensitivity?: string;
    dataClassifications?: string[];
}> = ({ dataSensitivity, dataClassifications }) => {
    const { t } = useTranslation(I18nRiskNamespace);
    const title = t('ASSET_DETAILS.DATA_SENSITIVITY_CHIP.SENSITIVE');
    const tooltip = (
        <ChipTooltipStyled.TopDiv>
            <ChipTooltipStyled.InfoLineDivLong>
                <ChipTooltipStyled.InfoNameDiv>
                    {t('ASSET_DETAILS.RISK_DATA.CONTEXT.DATA_SENSITIVITY')}
                </ChipTooltipStyled.InfoNameDiv>
                <ChipTooltipStyled.InfoSepDiv />
                <ChipTooltipStyled.InfoValueDiv>
                    {dataSensitivity ?? i18n.t('COMMON.N_A')}
                </ChipTooltipStyled.InfoValueDiv>
            </ChipTooltipStyled.InfoLineDivLong>
            {dataClassifications && (
                <ChipTooltipStyled.InfoLineDivLong>
                    <ChipTooltipStyled.InfoNameDiv>
                        {t('ASSET_DETAILS.RISK_DATA.CONTEXT.DATA_CLASSIFICATION')}
                    </ChipTooltipStyled.InfoNameDiv>
                    <ChipTooltipStyled.InfoSepDiv />
                    <ChipTooltipStyled.InfoValueDiv>{dataClassifications.join(', ')}</ChipTooltipStyled.InfoValueDiv>
                </ChipTooltipStyled.InfoLineDivLong>
            )}
        </ChipTooltipStyled.TopDiv>
    );

    const dataSensitivityInfo: IDataSensitivityInfo = getDataSensitivityLevelInfo(dataSensitivity);
    const iconName: AllIconsName | undefined = dataSensitivityInfo.iconProps.name;
    return (
        <Tooltip content={tooltip}>
            <Chip
                label={title}
                leadingIconProps={iconName && { name: iconName, size: ICON_SIZE }}
                customTextColor={dataSensitivityInfo.iconProps.customColor}
                customColor={dataSensitivityInfo.bg}
            />
        </Tooltip>
    );
};

export const WafChipWidget: React.FC<{ wafProtection?: string }> = ({ wafProtection }) => {
    const { t } = useTranslation(I18nRiskNamespace);

    const wafProtectionInfo = getSafeWafProtectionInfo(wafProtection);
    const tooltip = (
        <ChipTooltipStyled.TopDiv>
            {wafProtectionInfo && (
                <ChipTooltipStyled.InfoLineDiv>
                    <ChipTooltipStyled.InfoNameDiv>{t('ASSET_DETAILS.WAF_CHIP.TOOLTIP')}</ChipTooltipStyled.InfoNameDiv>
                    <ChipTooltipStyled.InfoSepDiv />
                    <ChipTooltipStyled.InfoValueDiv>{wafProtectionInfo.title}</ChipTooltipStyled.InfoValueDiv>
                </ChipTooltipStyled.InfoLineDiv>
            )}
        </ChipTooltipStyled.TopDiv>
    );

    return (
        <Tooltip content={tooltip}>
            <Chip
                label={t('ASSET_DETAILS.WAF_CHIP.TITLE')}
                leadingIconProps={wafProtectionInfo.iconProps}
                customColor={wafProtectionInfo.bg}
            />
        </Tooltip>
    );
};
