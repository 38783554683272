import React from 'react';
import {
    OverviewCategoryDashyLine,
    OverviewCategoryInnerWrapper,
    OverviewCategorySectionTitle,
    OverviewCategoryWrapper,
} from './OverviewCategory.styled';

const OverviewCategory: React.FC<{ title: string; children?: JSX.Element }> = ({ title, children }) => {
    return (
        <OverviewCategoryWrapper>
            <OverviewCategoryInnerWrapper>
                <OverviewCategorySectionTitle>{title}</OverviewCategorySectionTitle>
                <OverviewCategoryDashyLine></OverviewCategoryDashyLine>
            </OverviewCategoryInnerWrapper>
            {children}
        </OverviewCategoryWrapper>
    );
};

export default OverviewCategory;
