import { ColDef, ITooltipParams } from 'ag-grid-community';
import { ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';
import { FlatPackageAndCve, Layers } from 'modules/workloads/services/vulnerability/vulnerability.interface';
import i18n from 'common/services/translations/translations';
import IconLinkCellRenderer from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { VULNERABILITY_DRAWER_ID } from 'modules/workloads/services/cveDrawerRegistry/cveDrawerRegistry.consts';
import { ColToHideFF, SortDirection } from '../../utils';
import { severityComparator } from 'modules/workloads/utils/versionsCompare';
import CountBySeverity from 'common/design-system/components-v2/CountBySeverity';
import SeverityCellRenderer from 'modules/workloads/CellRenders/SeverityCellRenderer/SeverityCellRenderer';
import IsFixableCellRenderer from 'modules/workloads/CellRenders/IsFixableCellRenderer/IsFixableCellRenderer';
import DotCellRenderer from 'modules/workloads/CellRenders/DotCellRenderer/DotCellRenderer';
import { ComponentOverflow, Stack, Tooltip, Typography } from 'common/design-system/components-v2';
import {
    EpssScoreCellRenderer,
    EpssScoreTooltipGetter,
} from 'modules/workloads/CellRenders/EsppScoreCellRenderer/EpssScoreCellRenderer';
import BaseVendorImageHeaderRenderer from '../../../../../table/HeaderRenderers/BaseVendorImageHeaderRenderer/BaseVendorImageHeaderRenderer';
import { getUserService } from 'common/interface/services';
import BaseImageListTableCellRenderer from 'modules/workloads/CellRenders/BaseImageListTableCellRenderer/BaseImageListTableCellRenderer';
import { FindingSeverityEnum } from 'common/consts/FindingSeverity';
import { VulnerabilityItem } from 'common/design-system/components-v2/CountBySeverity/CountBySeverity.types';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { Context } from 'common/design-system/theme/colors/colors.types';
import { booleanStringValueGetter, hideColumn } from 'modules/workloads/utils/aggrid';
import BooleanStringCellRenderer from 'modules/workloads/CellRenders/BooleanStringCellRenderer/BooleanStringCellRenderer';
import { isNil } from 'common/utils/helpFunctions';
import { floatToPercentage } from 'modules/workloads/utils/floatToPercentage';
import PackageInUseCellRenderer from 'modules/workloads/CellRenders/PackageInUseCellRenderer/PackageInUseCellRenderer';
import { CveColumnsNames, CvesProps } from './Cves.types';

export const aggregationByProperty = (
    allLeafChildren: Array<FlatPackageAndCve>,
    property: 'isExcluded' | 'isFixedByPackageRemediation' | 'packageBaseImages',
) => {
    return allLeafChildren.reduce<number>((acc, item) => {
        if (item[property]) {
            acc++;
            return acc;
        }
        return acc;
    }, 0);
};

const allowedSeverities = new Set(['critical', 'high', 'medium', 'low']);
const countBySeverity = (data: Array<FlatPackageAndCve>) => {
    const countMap = data.reduce<Record<string, number>>((acc, item) => {
        const toLowerCase = item.severity.toLowerCase();

        if (!allowedSeverities.has(toLowerCase)) return acc;

        acc[toLowerCase] = (acc[toLowerCase] || 0) + 1;
        return acc;
    }, {});
    return Object.entries(countMap).map(([severity, count]) => ({ severity: severity as FindingSeverityEnum, count }));
};

const addSeverityIfNotExists = (severityCounts: VulnerabilityItem[]) => {
    ['critical', 'high', 'medium', 'low'].forEach((severity) => {
        if (!severityCounts.some((item) => item.severity === severity)) {
            severityCounts.push({ severity: severity as Context, count: 0 });
        }
    });
    return severityCounts;
};

export const hideCols = (type: string, layers?: Layers): ColToHideFF => [
    {
        field: CveColumnsNames.LayerCommand,
        hide: !layers,
    },
    {
        field: CveColumnsNames.LayerId,
        hide: !layers,
    },
    {
        field: CveColumnsNames.PackageInUse,
        hide: !['KubernetesImage'].includes(type) || !getUserService().hasPermission(['workloads_vlm_in_use']),
    },
];

type GetColumnDefs = (cloudEntity: CvesProps['cloudEntity'], layers?: Layers) => ColDef[];
export const getColumnDefs: GetColumnDefs = (cloudEntity, layers) => {
    const colDef = [
        {
            field: CveColumnsNames.LayerId,
            headerName: i18n.t('VulnerabilityTable.cves.layerId', { ns: getK8sNamespace('vulnerability') }),
            hide: true,
            enableRowGroup: false,
            valueGetter: (params: ValueGetterParams<FlatPackageAndCve>) => {
                if (!layers || !params.data || !params.data['packageLayerId']) return null;
                return params.data['packageLayerId'];
            },
        },
        {
            field: CveColumnsNames.LayerCommand,
            headerName: i18n.t('VulnerabilityTable.cves.layerCommand', { ns: getK8sNamespace('vulnerability') }),
            enableRowGroup: true,
            valueGetter: (params: ValueGetterParams<FlatPackageAndCve>) => {
                if (!layers || !params.data || !params.data['packageLayerId']) return null;

                return layers[params.data['packageLayerId']].created_by;
            },
            cellRenderer: (params: ICellRendererParams) => {
                if (!params.value) return null;

                return (
                    <IconLinkCellRenderer
                        value={params.value}
                        isLink={false}
                        levelIconProps={{
                            iconProps: { name: 'command' },
                            category: 'severity',
                            level: 'high', // TODO: what is the level?
                            size: 'md',
                        }}
                        href={''}
                    />
                );
            },
        },
        {
            field: CveColumnsNames.Id,
            headerName: i18n.t('VulnerabilityTable.cves.id', { ns: getK8sNamespace('vulnerability') }),
            enableRowGroup: true,
            cellRenderer: (params: any) => {
                if (!params.data) {
                    return params.value;
                }
                const {
                    data: { severity },
                } = params;
                return (
                    <IconLinkCellRenderer
                        value={params.value}
                        isLink={true}
                        levelIconProps={{
                            iconProps: { name: 'cve' },
                            category: 'severity',
                            level: severity.toLowerCase(),
                            size: 'md',
                        }}
                        href={''}
                        onClick={(event) => {
                            event.preventDefault();
                            showDrawer(VULNERABILITY_DRAWER_ID, {
                                cveId: encodeURIComponent(params.data['id']),
                                packageId: encodeURIComponent(params.data['packageId'].replace(/\\/g, '/')),
                                cveSeverity: params.data['severity'],
                                cloudEntity: cloudEntity,
                            });
                        }}
                    />
                );
            },
        },
        {
            field: CveColumnsNames.Severity,
            initialSort: SortDirection.ASC,
            headerName: i18n.t('VulnerabilityTable.commonColumns.severity', { ns: getK8sNamespace('vulnerability') }),
            minWidth: 180,
            enableRowGroup: true,
            comparator: severityComparator,
            cellRenderer: (params: ICellRendererParams) => {
                if (params.node.group) {
                    const allLeafChildData = params.node.allLeafChildren.map((child) => child.data);
                    const aggCountSeverity = countBySeverity(allLeafChildData) as Array<{
                        severity: Context;
                        count: number;
                    }>;
                    return <CountBySeverity severityCounts={addSeverityIfNotExists(aggCountSeverity)} />;
                }

                return <SeverityCellRenderer {...params} />;
            },
        },
        {
            field: CveColumnsNames.IsFixedByPackageRemediation,
            headerName: i18n.t('VulnerabilityTable.cves.fixable', { ns: getK8sNamespace('vulnerability') }),
            textAlign: 'center',
            maxWidth: 120,
            enableRowGroup: true,
            cellRendererParams: { fieldName: 'isFixedByPackageRemediation' },
            cellRenderer: (params: ICellRendererParams & { fieldName: string }) => (
                <IsFixableCellRenderer {...params} />
            ),
            valueGetter: booleanStringValueGetter('isFixedByPackageRemediation'),
        },
        {
            field: CveColumnsNames.KnownExploit,
            headerName: i18n.t('VulnerabilityTable.cves.knownExploit', { ns: getK8sNamespace('vulnerability') }),
            maxWidth: 120,
            enableRowGroup: true,
            sortable: true,
            cellRenderer: BooleanStringCellRenderer('knownExploit', 'VulnerabilityTable.cells.knownExploit'),
            tooltipValueGetter: (params: ITooltipParams) => {
                return params.value === '' ? 'N/A' : undefined;
            },
            valueGetter: booleanStringValueGetter('knownExploit'),
        },
        {
            field: CveColumnsNames.IsExcluded,
            headerName: i18n.t('VulnerabilityTable.cves.isExcluded', { ns: getK8sNamespace('vulnerability') }),
            valueGetter: (params: ValueGetterParams) => {
                if (params.data?.isExcluded === undefined || params.data?.isExcluded === null) {
                    return undefined;
                }
                return Boolean(params.data?.isExcluded);
            },
            cellRenderer: (params: ICellRendererParams<FlatPackageAndCve>) => <DotCellRenderer {...params} />,
        },
        {
            field: CveColumnsNames.Remediation,
            headerName: i18n.t('VulnerabilityTable.cves.remediation', { ns: getK8sNamespace('vulnerability') }),
            textAlign: 'center',
            maxWidth: 150,
            hide: true,
            enableRowGroup: false,
            cellRenderer: (params: ICellRendererParams<FlatPackageAndCve>) => (
                <Stack>
                    <Tooltip content={params.value}>
                        <span>{params.value}</span>
                    </Tooltip>
                </Stack>
            ),
        },
        {
            field: CveColumnsNames.PackageVersion,
            headerName: i18n.t('VulnerabilityTable.cves.packageVersion', { ns: getK8sNamespace('vulnerability') }),
        },
        {
            field: CveColumnsNames['Cvss-info.Vector-string'],
            headerName: i18n.t('VulnerabilityTable.cves.vector', { ns: getK8sNamespace('vulnerability') }),
            valueFormatter: (params: ValueFormatterParams) => (params.value ? params.value : 'N/A'),
            hide: true,
        },
        {
            field: CveColumnsNames.Source,
            headerName: i18n.t('VulnerabilityTable.cves.source', { ns: getK8sNamespace('vulnerability') }),
            hide: true,
        },
        {
            field: CveColumnsNames['Cvss-info.Source'],
            headerName: i18n.t('VulnerabilityTable.cves.CVSSSource', { ns: getK8sNamespace('vulnerability') }),
            hide: true,
            enableRowGroup: false,
        },
        {
            field: CveColumnsNames.Description,
            headerName: i18n.t('VulnerabilityTable.commonColumns.description', {
                ns: getK8sNamespace('vulnerability'),
            }),
            hide: true,
            enableRowGroup: false,
            cellRenderer: (params: ICellRendererParams) => {
                return (
                    <Stack>
                        <Tooltip content={params.value}>
                            <span>{params.value}</span>
                        </Tooltip>
                    </Stack>
                );
            },
        },
        // Package fields
        {
            field: CveColumnsNames.PackageName,
            headerName: i18n.t('VulnerabilityTable.cves.packageName', { ns: getK8sNamespace('vulnerability') }),
            enableRowGroup: true,
        },
        {
            field: CveColumnsNames.packageSeverity,
            headerName: i18n.t('VulnerabilityTable.cves.packageSeverity', { ns: getK8sNamespace('vulnerability') }),
            hide: true,
            enableRowGroup: true,
            sortable: true,
        },
        {
            field: CveColumnsNames.PackageType,
            headerName: i18n.t('VulnerabilityTable.cves.packageType', { ns: getK8sNamespace('vulnerability') }),
            hide: true,
            enableRowGroup: true,
            sortable: true,
        },
        {
            field: CveColumnsNames['PackagePackage-Manager.name'],
            headerName: i18n.t('VulnerabilityTable.cves.packageManager', { ns: getK8sNamespace('vulnerability') }),
            hide: true,
            enableRowGroup: true,
            sortable: true,
        },
        {
            field: CveColumnsNames['PackageIs-os-Package'],
            headerName: i18n.t('VulnerabilityTable.cves.isOSPackage', { ns: getK8sNamespace('vulnerability') }),
            cellRenderer: BooleanStringCellRenderer('packageIs-os-package', 'VulnerabilityTable.cells.isOsPackage'),
            valueGetter: booleanStringValueGetter('packageIs-os-package'),
            hide: true,
            enableRowGroup: true,
            sortable: true,
        },
        {
            field: CveColumnsNames['PackagePackage-manager.Path'],
            headerName: i18n.t('VulnerabilityTable.cves.packagePath', { ns: getK8sNamespace('vulnerability') }),
            enableRowGroup: true,
            sortable: true,
        },
        {
            field: CveColumnsNames.EpssScore,
            headerName: i18n.t('VulnerabilityTable.cves.epssScore', { ns: getK8sNamespace('vulnerability') }),
            cellRenderer: EpssScoreCellRenderer('epssScore'),
            tooltipValueGetter: EpssScoreTooltipGetter,
            valueGetter: (params: ValueGetterParams) => {
                if (isNil(params.data?.epssScore)) {
                    return '';
                }
                return floatToPercentage(params.data.epssScore);
            },
            hide: true,
            enableRowGroup: true,
            sortable: true,
        },
        {
            field: CveColumnsNames.RelatedIds,
            enableRowGroup: false,
            headerName: i18n.t('VulnerabilityTable.cves.relatedIDs', { ns: getK8sNamespace('vulnerability') }),
            cellRenderer: (params: ICellRendererParams<FlatPackageAndCve>) => {
                if (!params.data || !params.value) {
                    return params.value;
                }
                return (
                    <ComponentOverflow
                        commaSeperated
                        components={params.value.map((item: string) => (
                            <Typography key={item}>{item}</Typography>
                        ))}
                    />
                );
            },
        },
        {
            field: CveColumnsNames.PackageInUse,
            headerName: i18n.t('VulnerabilityTable.cves.isInUse', { ns: getK8sNamespace('vulnerability') }),
            cellRenderer: PackageInUseCellRenderer,
            enableRowGroup: true,
            sortable: true,
            hide: true,
        },
        {
            field: CveColumnsNames.PackageBaseImages,
            valueGetter: (
                params: ValueGetterParams<{
                    packageBaseImages: Array<{ externalId: string; groups: Array<any>; name: string }>;
                }>,
            ) =>
                params.data && params.data.packageBaseImages?.length > 0
                    ? params.data.packageBaseImages[0].externalId
                    : '',
            headerComponent: BaseVendorImageHeaderRenderer,
            minWidth: 150,
            cellRenderer: (params: ICellRendererParams<FlatPackageAndCve>) => {
                if (params.node.group) {
                    // TODO: check if this is working
                    return aggregationByProperty(
                        params.node.allLeafChildren.map((child) => child.data!),
                        'packageBaseImages',
                    );
                }
                if (!params.data || !params.value) {
                    return params.value;
                }
                return <BaseImageListTableCellRenderer imageId={params.value} />;
            },
            headerName: i18n.t('VulnerabilityTable.cves.baseImage', { ns: getK8sNamespace('vulnerability') }),
            sortable: true,
        },
    ];
    return hideColumn(colDef, hideCols(cloudEntity.typeByPlatform, layers));
};
