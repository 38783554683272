import {
    directPermissionName,
    IPermissionItem,
    IPermissionModel,
    PERMISSION_TYPE,
    PermissionCategorySubType,
    PermissionCategoryType,
} from './interfaces';
import { I18nSettingsTranslationKey } from '../../initialize';
import { getVendor, getVendorByNumber, SRLServiceType } from 'common/consts/vendors';
import { getSecurityGroupsService, IAgent, IRole, ISecurityGroup } from 'common/interface/services';
import { v4 as uuid } from 'uuid';
import { ICloudAccount, IOrganizationalUnit } from 'common/interface/data_services';
import { IPermissionCategories } from 'common/interface/user';
import { allSystemResources, capitalize, isPermissionItemsMatch, rootEnvironmentPath, rootOUPath } from './utils';
import i18n from 'i18next';

interface IAccount {
    roles?: string[];
    accountName?: string;
    accountId?: number;
}
const securityGroupKey = '2';
const ouKey = '12';
const rootOUId = '00000000-0000-0000-0000-000000000000';
const rootOUPermissionKey = `12|${rootOUId}`;
const agentsKey = '0';
const allCloudResource = '200';
const codeSecurityKey = '210';
const enum codeSecurityPermissionType {
    'Admin' = 'Admin',
    'Member' = 'Member',
    'ReadOnly' = 'ReadOnly',
}
export interface IPermissionConverter {
    updateRoles: (roles: IRole[]) => void;
    convertPermissionListToPermissionModel: (
        permissions: { [key: string]: string[] },
        calculatedPermissions?: { [key: string]: string[] },
    ) => Promise<IPermissionModel>;
    convertPermissionModelToPermissionList: (permissionModel?: IPermissionModel) => IPermissionCategories;
}

export const getPermissionConverter = (
    allOUs: IOrganizationalUnit[],
    allCloudAccounts: ICloudAccount[],
    _securityGroups: ISecurityGroup[],
    _agents: IAgent[],
    _userAccountsRoles: IAccount[],
    _roles: IRole[] = [],
) => {
    const t = i18n.getFixedT(null, I18nSettingsTranslationKey);

    const oraganizationalUnits = allOUs;
    const cloudAccounts = allCloudAccounts;
    const agents = _agents;
    const securityGroups = _securityGroups;
    const userAccountsRoles = _userAccountsRoles;
    let roles = _roles;

    const updateRoles = (_roles: IRole[]) => {
        roles = _roles;
    };

    const convertPermissionListToPermissionModel = async (
        permissions: { [key: string]: string[] },
        calculatedPermissions: { [key: string]: string[] } = {},
    ): Promise<IPermissionModel> => {
        let permissionModel: IPermissionModel = {
            networkSecurity: [],
            scopeControls: [],
            codeSecurity: [],
            accountAccess: [],
        };

        const addPermissionItem = (
            type: PERMISSION_TYPE,
            permissionKey: string,
            path: string[],
            infoKey: string,
            t: any,
            permissionModel: IPermissionModel,
        ) => {
            const _permissions = permissions[permissionKey];
            if (_permissions?.length === 1 && _permissions[0] === '') {
                const _inheritedRoles = roles
                    .filter(
                        (role) =>
                            role?.permissions &&
                            role?.permissions[permissionKey].some((_permission) => _permissions?.includes(_permission)),
                    )
                    .map((role) => role.name);
                const permissionItem: IPermissionItem = {
                    type: type,
                    name: type,
                    categorySubType: PermissionCategorySubType.SCOPE_CONTROLS,
                    id: uuid(),
                    path: path,
                    manage: true,
                    inheritedRoles: _inheritedRoles,
                    info: t(infoKey),
                };
                permissionModel.scopeControls?.push(permissionItem);
            }
        };
        const handleCloudAccountAndOUPermissions = async (
            permissionKey: string,
            permissionModel: IPermissionModel,
            permissionSubType: PermissionCategorySubType,
            tooltipKey: string,
            permissionType: string,
        ) => {
            const _permissions = permissions[permissionKey];
            const _permissionType = permissionType as keyof IPermissionModel;

            const addAllResourcesPermissionItem = (role?: IRole) => {
                permissionModel[_permissionType]?.push({
                    type: PERMISSION_TYPE.ALL_SYSTEM_RESOURCES,
                    categorySubType: permissionSubType,
                    id: uuid(),
                    name: allSystemResources,
                    info: t('TOOLTIPS.ALL_SYSTEM_RESOURCES'),
                    path: [allSystemResources],
                    manage: permissionKey === 'manage',
                    inheritedRoles: role?.name ? [role.name] : [],
                    view: permissionKey === 'view',
                });
            };
            const addOrganizationalUnitHeaderPermissionItem = (role?: IRole) => {
                permissionModel[_permissionType]?.push({
                    type: PERMISSION_TYPE.ORGANIZATIONAL_UNIT_HEADER,
                    categorySubType: permissionSubType,
                    id: uuid(),
                    path: [rootOUPath],
                    manage: permissionKey === 'manage',
                    view: permissionKey === 'view',
                    enable: permissionKey === 'access',
                    inheritedRoles: role?.name ? [role.name] : [],
                    info: t(tooltipKey),
                    name: rootOUPath,
                });
            };
            const addOrganizationalUnitPermissionItem = (ouId: string, role?: IRole) => {
                const ou = oraganizationalUnits.find((ou) => ou.id === ouId);
                if (ou) {
                    permissionModel[_permissionType]?.push({
                        type: PERMISSION_TYPE.ORGANIZATIONAL_UNIT,
                        categorySubType: permissionSubType,
                        id: uuid(),
                        organizationUnit: ou,
                        path: [
                            t('GENERAL.ORGANIZATIONAL_UNITS', { ns: I18nSettingsTranslationKey }),
                            ...ou.path.split('/'),
                        ],
                        manage: permissionKey === 'manage',
                        view: permissionKey === 'view',
                        enable: permissionKey === 'access',
                        inheritedRoles: role?.name ? [role.name] : [],
                        info: t(tooltipKey),
                        name: ou.name,
                    });
                }
            };
            const addPlatformPermissionItem = (vendor: string, role?: IRole) => {
                permissionModel[_permissionType]?.push({
                    type: PERMISSION_TYPE.PLATFORM,
                    categorySubType: permissionSubType,
                    id: uuid(),
                    name: vendor,
                    path: [rootEnvironmentPath, capitalize(vendor || '')],
                    manage: permissionKey === 'manage',
                    view: permissionKey === 'view',
                    enable: permissionKey === 'access',
                    inheritedRoles: role?.name ? [role.name] : [],
                    info: t(tooltipKey),
                });
            };
            const getSecurityGroupAndServices = async (
                securityGroupId: string,
                cloudAccount: ICloudAccount,
                region: string,
                serviceId: string,
            ) => {
                let _securityGroup;
                let _service;
                if (+securityGroupId === -1) {
                    console.log('permission security group has no valid id');
                } else {
                    const cloudSecurityGroups = await getSecurityGroupsService().getCloudSecurityGroups(
                        cloudAccount.id,
                        region,
                    );
                    _securityGroup = cloudSecurityGroups?.find((sg) => sg.securityGroupId === +securityGroupId);
                    if (serviceId) {
                        _service = _securityGroup?.services?.inbound?.find((item) => item.id === serviceId);
                    }
                }
                return { _securityGroup, _service };
            };
            const getRegion = (platform: string, regionNumber: string) => {
                const regions = getVendor(platform)?.regions;
                const _regionsEntries = regions && Object.entries(regions);
                const _region = _regionsEntries?.find(([, value]) => value.regionNumber === +regionNumber);
                return _region ? _region[1] : undefined;
            };
            const processInnerPermissions = async (innerPermissions: string[], role?: IRole) => {
                if (!innerPermissions?.length) return;
                for (const permission of innerPermissions) {
                    if (permission === '') {
                        addAllResourcesPermissionItem(role);
                    } else {
                        const [key, id, hasRegion, regionNumber, hasSecurityGroup, securityGroupId, serviceId] =
                            permission.split('|');
                        const vendor =
                            key === ouKey ? SRLServiceType.OrganizationalUnit.toString() : getVendorByNumber(key);
                        if (vendor) {
                            switch (vendor) {
                                case SRLServiceType.OrganizationalUnit.toString(): {
                                    if (!id || id === rootOUId) {
                                        addOrganizationalUnitHeaderPermissionItem(role);
                                    }
                                    addOrganizationalUnitPermissionItem(id, role);
                                    break;
                                }
                                default:
                                    {
                                        if (id) {
                                            const cloudAccount = cloudAccounts.find(
                                                (cloudAccount) => cloudAccount.id === id,
                                            );
                                            if (cloudAccount) {
                                                const { _securityGroup, _service } = hasSecurityGroup
                                                    ? await getSecurityGroupAndServices(
                                                          securityGroupId,
                                                          cloudAccount,
                                                          regionNumber,
                                                          serviceId,
                                                      )
                                                    : {};

                                                const _region = hasRegion
                                                    ? getRegion(cloudAccount?.platform, regionNumber)
                                                    : undefined;

                                                const getType = () => {
                                                    if (serviceId) return PERMISSION_TYPE.SECURITY_GROUP_SERVICE;
                                                    if (hasSecurityGroup) return PERMISSION_TYPE.SECURITY_GROUP;
                                                    if (hasRegion) return PERMISSION_TYPE.REGION;
                                                    return PERMISSION_TYPE.CLOUD_ACCOUNT;
                                                };
                                                const itemType = getType();
                                                permissionModel[_permissionType]?.push({
                                                    type: itemType,
                                                    categorySubType: permissionSubType,
                                                    id: uuid(),
                                                    manage: permissionKey === 'manage',
                                                    region: _region,
                                                    cloudAccount: cloudAccount,
                                                    service: _service,
                                                    securityGroup: hasSecurityGroup ? _securityGroup : undefined,
                                                    view: permissionKey === 'view',
                                                    enable: permissionKey === 'access',
                                                    inheritedRoles: role?.name ? [role.name] : [],
                                                    info: t(tooltipKey),
                                                    name: cloudAccount.name,
                                                });
                                            }
                                        } else {
                                            addPlatformPermissionItem(vendor, role);
                                        }
                                    }
                                    break;
                            }
                        }
                    }
                }
            };

            roles.forEach((role) => {
                const keyPermissions = role?.permissions && role?.permissions[permissionKey];
                keyPermissions && processInnerPermissions(keyPermissions, role);
            });
            await processInnerPermissions(_permissions);
        };

        const handleScopeControlsPermissions = async () => {
            const handleAgentPermissions = () => {
                const buildPermissionItems = (permissionsMap?: { [key: string]: string[] }, roleName?: string) => {
                    const buildItem = (permission: string, type: 'view' | 'manage') => {
                        const [key, id, policyId] = permission.split('|');
                        if (key === agentsKey) {
                            const agent = agents.find((agent) => agent.id.toString() === id);
                            const accessPolicy = agent?.accessPolicy?.find((policy) => policy.id === policyId);
                            if (agent) {
                                let permissionItem: IPermissionItem | null = null;
                                if (accessPolicy) {
                                    permissionItem = {
                                        type: PERMISSION_TYPE.AGENT_SERVICE,
                                        categorySubType: PermissionCategorySubType.SCOPE_ENVS_OUS,
                                        id: uuid(),
                                        agent: agent,
                                        agentAccessPolicy: accessPolicy,
                                        info: t('TOOLTIP.DEFAULTS.NETWORK_CONTROLS'),
                                        manage: type === 'manage' ? true : false,
                                        view: type === 'view' ? true : false,
                                        inheritedRoles: roleName ? [roleName] : [],
                                        name: agent.name,
                                    };
                                    permissionModel.scopeControls?.push(permissionItem);
                                } else {
                                    permissionItem = {
                                        type: PERMISSION_TYPE.AGENT,
                                        categorySubType: PermissionCategorySubType.SCOPE_ENVS_OUS,
                                        id: uuid(),
                                        agent: agent,
                                        info: t('TOOLTIP.DEFAULTS.NETWORK_CONTROLS'),
                                        manage: type === 'manage' ? true : false,
                                        view: type === 'view' ? true : false,
                                        inheritedRoles: roleName ? [roleName] : [],
                                        name: agent.name,
                                    };
                                }

                                permissionModel.scopeControls?.push(permissionItem);
                            } else {
                                const permissionItem: IPermissionItem = {
                                    type: PERMISSION_TYPE.AGENT_HEADER,
                                    categorySubType: PermissionCategorySubType.SCOPE_ENVS_OUS,
                                    id: uuid(),
                                    agent: agent,
                                    info: t('TOOLTIP.DEFAULTS.NETWORK_CONTROLS'),
                                    manage: type === 'manage' ? true : false,
                                    view: type === 'view' ? true : false,
                                    inheritedRoles: roleName ? [roleName] : [],
                                    name: '',
                                };
                                permissionModel.scopeControls?.push(permissionItem);
                            }
                        }
                    };
                    permissionsMap?.manage?.forEach((permission: string) => {
                        buildItem(permission, 'manage');
                    });
                    permissionsMap?.view?.forEach((permission: string) => {
                        buildItem(permission, 'view');
                    });
                };
                roles.forEach((role) => {
                    buildPermissionItems(role?.permissions, role.name);
                });
                buildPermissionItems(permissions);
            };
            const handleSecurityGroupAgentsPermissions = () => {
                const buildPermissionItems = (permissionsMap?: { [key: string]: string[] }, roleName?: string) => {
                    const buildItem = (permission: string, type: 'view' | 'manage') => {
                        const [key, id] = permission.split('|');
                        if (key === securityGroupKey && id !== '-1') {
                            const _securityGroup = securityGroups.find((sg) => sg.securityGroupId.toString() === id);
                            if (_securityGroup) {
                                const permissionItem: IPermissionItem = {
                                    type: PERMISSION_TYPE.SECURITY_GROUP_AGENT,
                                    categorySubType: PermissionCategorySubType.SCOPE_ENVS_OUS,
                                    id: uuid(),
                                    securityGroup: _securityGroup,
                                    info: t('TOOLTIP.DEFAULTS.NETWORK_CONTROLS'),
                                    manage: type === 'manage' ? true : false,
                                    view: type === 'view' ? true : false,
                                    inheritedRoles: roleName ? [roleName] : [],
                                    name: _securityGroup.securityGroupName,
                                };
                                permissionModel.scopeControls?.push(permissionItem);
                            } else {
                                const permissionItem: IPermissionItem = {
                                    type: PERMISSION_TYPE.SECURITY_GROUP_AGENT_HEADER,
                                    categorySubType: PermissionCategorySubType.SCOPE_ENVS_OUS,
                                    id: uuid(),
                                    securityGroup: _securityGroup,
                                    info: t('TOOLTIP.DEFAULTS.NETWORK_CONTROLS'),
                                    manage: type === 'manage' ? true : false,
                                    view: type === 'view' ? true : false,
                                    inheritedRoles: roleName ? [roleName] : [],
                                    name: '',
                                };
                                permissionModel.scopeControls?.push(permissionItem);
                            }
                        }
                    };
                    permissionsMap?.manage?.forEach((permission: string) => {
                        buildItem(permission, 'manage');
                    });
                    permissionsMap?.view?.forEach((permission: string) => {
                        buildItem(permission, 'view');
                    });
                };

                roles.forEach((role) => {
                    buildPermissionItems(role?.permissions, role.name);
                });
                buildPermissionItems(permissions);
            };
            const handleAllCloudAccountManageResources = () => {
                const buildPermissionItems = (permissions?: string[], roleName?: string) => {
                    permissions?.forEach((permission: string) => {
                        const [key] = permission.split('|');
                        if (key === allCloudResource) {
                            const permissionItem: IPermissionItem = {
                                type: PERMISSION_TYPE.ALL_CLOUD_ACCOUNT_RESOURCES,
                                categorySubType: PermissionCategorySubType.SCOPE_CONTROLS,
                                id: uuid(),
                                path: [t('GENERAL.ALL_CLOUDGUARD_RESOURCES')],
                                info: t('TOOLTIPS.ALL_CLOUD_ACCOUNT_RESOURCES'),
                                view: false,
                                inheritedRoles: roleName ? [roleName] : [],
                                manage: true,
                                name: 'allCloudAccountResources',
                            };
                            permissionModel.scopeControls?.push(permissionItem);
                        }
                    });
                };
                roles.forEach((role) => {
                    buildPermissionItems(role?.permissions?.manage, role.name);
                });
                buildPermissionItems(permissions.manage);
            };
            const handleAllCloudAccountViewResource = () => {
                const buildPermissionItems = (permissions?: string[], roleName?: string) => {
                    permissions?.forEach((permission: string) => {
                        const [key] = permission.split('|');
                        if (key === allCloudResource) {
                            const permissionItem: IPermissionItem = {
                                type: PERMISSION_TYPE.ALL_CLOUD_ACCOUNT_RESOURCES,
                                categorySubType: PermissionCategorySubType.SCOPE_CONTROLS,
                                id: uuid(),
                                path: [t('GENERAL.ALL_CLOUDGUARD_RESOURCES')],
                                info: t('TOOLTIPS.ALL_CLOUD_ACCOUNT_RESOURCES'),
                                view: true,
                                inheritedRoles: roleName ? [roleName] : [],
                                manage: false,
                                name: 'allCloudAccountResources',
                            };
                            permissionModel.scopeControls?.push(permissionItem);
                        }
                    });
                };
                roles.forEach((role) => {
                    buildPermissionItems(role?.permissions?.view, role.name);
                });
                buildPermissionItems(permissions.view);
            };

            addPermissionItem(
                PERMISSION_TYPE.RULES_AND_RULESETS,
                'rulesets',
                [t('GENERAL.ALL_CLOUDGUARD_RESOURCES'), t('GENERAL.RULES_RULESETS')],
                'TOOLTIPS.RULESETS_RULES',
                t,
                permissionModel,
            );
            addPermissionItem(
                PERMISSION_TYPE.NOTIFICATIONS_AND_INTEGRATIONS,
                'notifications',
                [t('GENERAL.ALL_CLOUDGUARD_RESOURCES'), t('GENERAL.NOTIFICATIONS_INTEGRATIONS')],
                'TOOLTIPS.ALERTS',
                t,
                permissionModel,
            );
            addPermissionItem(
                PERMISSION_TYPE.POLICY,
                'policies',
                [t('GENERAL.ALL_CLOUDGUARD_RESOURCES'), t('GENERAL.POLICY')],
                'TOOLTIPS.POLICIES',
                t,
                permissionModel,
            );
            addPermissionItem(
                PERMISSION_TYPE.ALERTS_EXCLUSION_REMEDIATION,
                'alertActions',
                [t('GENERAL.ALL_CLOUDGUARD_RESOURCES'), t('GENERAL.ALERT_REMEDIATION_EXCLUSIONS')],
                'TOOLTIPS.ALERTS',
                t,
                permissionModel,
            );
            addPermissionItem(
                PERMISSION_TYPE.ONBOARDING,
                'onBoarding',
                ['Onboarding'],
                'TOOLTIPS.ONBOARDING',
                t,
                permissionModel,
            );

            handleSecurityGroupAgentsPermissions();
            handleAgentPermissions();
            handleAllCloudAccountManageResources();
            handleAllCloudAccountViewResource();

            await handleCloudAccountAndOUPermissions(
                'view',
                permissionModel,
                PermissionCategorySubType.SCOPE_ENVS_OUS,
                'TOOLTIPS.MANAGE_RESOURCES',
                PermissionCategoryType.SCOPE_CONTROLS,
            );
            await handleCloudAccountAndOUPermissions(
                'manage',
                permissionModel,
                PermissionCategorySubType.SCOPE_ENVS_OUS,
                'TOOLTIPS.MANAGE_RESOURCES',
                PermissionCategoryType.SCOPE_CONTROLS,
            );
        };
        const handleNetworkControlsPermissions = async () => {
            const handleCreateSecurityGroup = (permissions?: string[], roleName?: string) => {
                if (permissions?.some((item) => item === securityGroupKey)) {
                    const permissionItem: IPermissionItem = {
                        type: PERMISSION_TYPE.CREATE_SECURITY_GROUP,
                        categorySubType: PermissionCategorySubType.NETWORK_CONTROLS,
                        id: uuid(),
                        inheritedRoles: roleName ? [roleName] : [],
                        info: t('TOOLTIP.DEFAULTS.NETWORK_CONTROLS'),
                        enable: true,
                        name: 'Create security groups',
                    };
                    permissionModel.networkSecurity?.push(permissionItem);
                }
            };
            const handleCreateAgent = (permissions?: string[], roleName?: string) => {
                if (permissions?.some((item) => item === agentsKey)) {
                    const permissionItem: IPermissionItem = {
                        type: PERMISSION_TYPE.CREATE_AGENT,
                        categorySubType: PermissionCategorySubType.NETWORK_CONTROLS,
                        id: uuid(),
                        info: t('TOOLTIP.DEFAULTS.NETWORK_CONTROLS'),
                        enable: true,
                        inheritedRoles: roleName ? [roleName] : [],
                        name: 'Create CloudGuard agents',
                    };
                    permissionModel.networkSecurity?.push(permissionItem);
                }
            };
            const handleAccessPermissions = (permissions?: string[], roleName?: string) => {
                function handleSecurityGroupAgentsPermissions(
                    accessItems: { id: string; key: string }[],
                    roleName?: string,
                ) {
                    accessItems
                        .filter((item) => item.key === securityGroupKey)
                        .forEach((item) => {
                            const _securityGroup = securityGroups.find(
                                (sg) => sg.securityGroupId.toString() === item.id,
                            );
                            if (_securityGroup) {
                                const permissionItem: IPermissionItem = {
                                    type: PERMISSION_TYPE.SECURITY_GROUP_AGENT,
                                    categorySubType: PermissionCategorySubType.NETWORK_ENVS_OUS,
                                    id: uuid(),
                                    securityGroup: _securityGroup,
                                    info: t('TOOLTIP.DEFAULTS.NETWORK_CONTROLS'),
                                    inheritedRoles: roleName ? [roleName] : [],
                                    enable: true,
                                    name: _securityGroup.securityGroupName,
                                };
                                permissionModel.networkSecurity?.push(permissionItem);
                            } else {
                                const permissionItem: IPermissionItem = {
                                    type: PERMISSION_TYPE.SECURITY_GROUP_AGENT_HEADER,
                                    categorySubType: PermissionCategorySubType.NETWORK_ENVS_OUS,
                                    id: uuid(),
                                    securityGroup: _securityGroup,
                                    info: t('TOOLTIP.DEFAULTS.NETWORK_CONTROLS'),
                                    inheritedRoles: roleName ? [roleName] : [],
                                    enable: true,
                                    name: '',
                                };
                                permissionModel.networkSecurity?.push(permissionItem);
                            }
                        });
                }

                if (permissions?.length) {
                    const accessItems = permissions?.map((item) => {
                        const [key, id, policyId] = item.split('|');
                        return { key, id, policyId };
                    });
                    accessItems
                        .filter((item) => item.key === agentsKey)
                        .forEach((item) => {
                            const agent = agents.find((agent) => agent.id.toString() === item.id);
                            const accessPolicy = agent?.accessPolicy?.find((policy) => policy.id === item.policyId);
                            if (agent) {
                                let permissionItem: IPermissionItem | null = null;
                                if (accessPolicy) {
                                    permissionItem = {
                                        type: PERMISSION_TYPE.AGENT_SERVICE,
                                        categorySubType: PermissionCategorySubType.NETWORK_ENVS_OUS,
                                        id: uuid(),
                                        agent: agent,
                                        agentAccessPolicy: accessPolicy,
                                        info: t('TOOLTIP.DEFAULTS.NETWORK_CONTROLS'),
                                        enable: true,
                                        inheritedRoles: roleName ? [roleName] : [],
                                        name: agent.name,
                                    };
                                    permissionModel.networkSecurity?.push(permissionItem);
                                } else {
                                    permissionItem = {
                                        type: PERMISSION_TYPE.AGENT,
                                        categorySubType: PermissionCategorySubType.NETWORK_ENVS_OUS,
                                        id: uuid(),
                                        agent: agent,
                                        info: t('TOOLTIP.DEFAULTS.NETWORK_CONTROLS'),
                                        enable: true,
                                        inheritedRoles: roleName ? [roleName] : [],
                                        name: agent.name,
                                    };
                                    permissionModel.networkSecurity?.push(permissionItem);
                                }
                            } else {
                                const permissionItem: IPermissionItem = {
                                    type: PERMISSION_TYPE.AGENT_HEADER,
                                    categorySubType: PermissionCategorySubType.NETWORK_ENVS_OUS,
                                    id: uuid(),
                                    agent: agent,
                                    info: t('TOOLTIP.DEFAULTS.NETWORK_CONTROLS'),
                                    enable: true,
                                    inheritedRoles: roleName ? [roleName] : [],
                                    name: '',
                                };
                                permissionModel.networkSecurity?.push(permissionItem);
                            }
                        });

                    handleSecurityGroupAgentsPermissions(accessItems, roleName);
                }
            };
            handleCreateSecurityGroup(permissions?.create);
            handleCreateAgent(permissions?.create);
            handleAccessPermissions(permissions?.access);
            roles.forEach((role) => {
                handleAccessPermissions(role?.permissions?.access, role.name);
                handleCreateAgent(role?.permissions?.create, role.name);
                handleCreateSecurityGroup(role?.permissions?.create, role.name);
            });
            await handleCloudAccountAndOUPermissions(
                'access',
                permissionModel,
                PermissionCategorySubType.NETWORK_ENVS_OUS,
                'TOOLTIPS.DYNAMIC_ACCESS',
                PermissionCategoryType.NETWORK_SECURITY,
            );
        };
        const handleSecurityCodePermissions = () => {
            const buildSecurityCodeManagePermissionItems = (permissions?: string[], roleName?: string) => {
                permissions?.forEach((permission: string) => {
                    const [key, id] = permission.split('|');
                    if (key === codeSecurityKey) {
                        const permissionItem: IPermissionItem = {
                            type: PERMISSION_TYPE.CODE_SECURITY,
                            categorySubType: PermissionCategorySubType.CODE_SECURITY,
                            id: uuid(),
                            name: 'Access level',
                            inheritedRoles: roleName ? [roleName] : [],
                            view: false,
                            member: id === codeSecurityPermissionType.Member ? true : false,
                            admin: id === codeSecurityPermissionType.Admin ? true : false,
                        };
                        permissionModel.codeSecurity?.push(permissionItem);
                    }
                });
            };
            buildSecurityCodeManagePermissionItems(permissions.manage);
            roles.forEach((role) => {
                buildSecurityCodeManagePermissionItems(role?.permissions?.manage, role.name);
            });

            const buildSecurityCodeViewPermissionItems = (permissions?: string[], roleName?: string) => {
                permissions?.forEach((permission: string) => {
                    const [key, id] = permission.split('|');
                    if (key === codeSecurityKey) {
                        const permissionItem: IPermissionItem = {
                            type: PERMISSION_TYPE.CODE_SECURITY,
                            categorySubType: PermissionCategorySubType.CODE_SECURITY,
                            id: uuid(),
                            name: 'Access level',
                            inheritedRoles: roleName ? [roleName] : [],
                            view: id === codeSecurityPermissionType.ReadOnly ? true : false,
                            member: false,
                            admin: false,
                        };
                        permissionModel.codeSecurity?.push(permissionItem);
                    }
                });
            };
            buildSecurityCodeViewPermissionItems(permissions.view);
            roles.forEach((role) => {
                buildSecurityCodeViewPermissionItems(role?.permissions?.view, role.name);
            });
        };
        const handleAccountAccessPermissions = () => {
            function handleAccountAccess(crossAccountAccess?: string[], roleName?: string) {
                crossAccountAccess?.forEach((permission: string) => {
                    const [accountId, role] = permission.split('|');
                    let _permissions = [];
                    if (accountId === '*') {
                        if (role === '*') {
                            _permissions = userAccountsRoles.map((account) => {
                                const permissionItem: IPermissionItem = {
                                    type: PERMISSION_TYPE.ACCOUNT_ACCESS,
                                    categorySubType: PermissionCategorySubType.NETWORK_ACCOUNT_ACCESS,
                                    id: uuid(),
                                    userAccount: account,
                                    inheritedRoles: roleName ? [roleName] : [],
                                    userRole: '*',
                                    enable: true,
                                };
                                return permissionItem;
                            });
                        } else {
                            _permissions = userAccountsRoles.map((account) => {
                                const permissionItem: IPermissionItem = {
                                    type: PERMISSION_TYPE.ACCOUNT_ACCESS,
                                    categorySubType: PermissionCategorySubType.NETWORK_ACCOUNT_ACCESS,
                                    id: uuid(),
                                    userAccount: account,
                                    inheritedRoles: roleName ? [roleName] : [],
                                    userRole: role,
                                    enable: true,
                                };
                                return permissionItem;
                            });
                        }
                        permissionModel.accountAccess?.push(..._permissions);
                    } else {
                        const account = userAccountsRoles.find((account) => account.accountId === +accountId);
                        if (account) {
                            const permissionItem: IPermissionItem = {
                                type: PERMISSION_TYPE.ACCOUNT_ACCESS,
                                categorySubType: PermissionCategorySubType.NETWORK_ACCOUNT_ACCESS,
                                id: uuid(),
                                userAccount: account,
                                inheritedRoles: roleName ? [roleName] : [],
                                userRole: role,
                                path: role === '*' ? [account.accountName || ''] : [account.accountName || '', role],
                                enable: true,
                            };
                            permissionModel.accountAccess?.push(permissionItem);
                        }
                    }
                });
            }
            handleAccountAccess(permissions.crossAccountAccess);
            handleAccountAccess(calculatedPermissions.crossAccountAccess);
            roles.forEach((role) => {
                handleAccountAccess(role.permissions?.crossAccountAccess, role.name);
            });
        };
        const mergePermissionInheritedRole = () => {
            const _mergedInheritancePermissionModel: { [key: string]: string[] } = {};
            Object.entries(permissionModel).forEach(([key, permissionList]) => {
                _mergedInheritancePermissionModel[key] = permissionList.reduce(
                    (acc: IPermissionItem[], permission: IPermissionItem) => {
                        const existingPermission = acc.find((item) => isPermissionItemsMatch(item, permission));
                        if (existingPermission) {
                            const inheritedRoles = existingPermission.inheritedRoles?.length
                                ? existingPermission.inheritedRoles
                                : [directPermissionName];
                            const permissionRoles = permission.inheritedRoles?.length
                                ? permission.inheritedRoles
                                : [directPermissionName];
                            existingPermission.inheritedRoles = [...inheritedRoles, ...permissionRoles];
                        } else {
                            acc.push(permission);
                        }
                        return acc;
                    },
                    [] as IPermissionItem[],
                );
            });
            return _mergedInheritancePermissionModel;
        };
        const mergePermissionsItems = (permissionModel: IPermissionModel) => {
            const mergedItems: IPermissionModel = {};
            Object.entries(permissionModel).forEach(([key, permissionList]) => {
                const _key = key as keyof IPermissionModel;
                mergedItems[_key] = [];
                permissionList.forEach((permission: IPermissionItem) => {
                    const existingPermission = mergedItems[_key]?.find((item) =>
                        isPermissionItemsMatch(item, permission),
                    );
                    if (existingPermission) {
                        existingPermission.view = existingPermission.view || permission.view;
                        existingPermission.manage = existingPermission.manage || permission.manage;
                        existingPermission.enable = existingPermission.enable || permission.enable;
                    } else {
                        mergedItems[_key]?.push(permission);
                    }
                });
            });
            return mergedItems;
        };

        await handleScopeControlsPermissions();
        await handleNetworkControlsPermissions();
        handleSecurityCodePermissions();
        handleAccountAccessPermissions();

        permissionModel = mergePermissionsItems(permissionModel);
        return mergePermissionInheritedRole();
    };

    const removeRedundantChildren = (permissions: IPermissionCategories) => {
        const pipe = '|';
        const _permissions: IPermissionCategories = {
            access: [],
            alertActions: [],
            create: [],
            crossAccountAccess: [],
            manage: [],
            notifications: [],
            onBoarding: [],
            policies: [],
            rulesets: [],
            view: [],
        };

        Object.keys(permissions).forEach((_category: string) => {
            const category = _category as keyof IPermissionCategories;
            permissions[category].forEach((permission) => {
                if (permission === rootOUPermissionKey) {
                    _permissions[category].push(permission);
                } else if (!permission.includes(pipe)) {
                    _permissions[category].push(permission);
                }
            });
            permissions[category].forEach((permission) => {
                if (permission.includes(pipe)) {
                    const [key] = permission.split(pipe);
                    if (key === ouKey) {
                        if (!_permissions[category].includes(rootOUPermissionKey)) {
                            _permissions[category].push(permission);
                        }
                    } else if (!_permissions[category].includes(key)) {
                        _permissions[category].push(permission);
                    }
                }
            });
        });
        return _permissions;
    };
    const convertPermissionModelToPermissionList = (permissionModel?: IPermissionModel): IPermissionCategories => {
        const permissions: IPermissionCategories = {
            access: [],
            alertActions: [],
            create: [],
            crossAccountAccess: [],
            manage: [],
            notifications: [],
            onBoarding: [],
            policies: [],
            rulesets: [],
            view: [],
        };
        if (!permissionModel) return permissions;
        const addPermission = (categories: (keyof IPermissionCategories)[], permission: string) => {
            categories.forEach((category) => {
                if (!permissions[category].includes(permission)) {
                    permissions[category].push(permission);
                }
            });
        };
        const processCategoryPermissions = (items: IPermissionItem[]) => {
            const getVendorNumber = (vendor?: string) => {
                const result = Object.entries(SRLServiceType).find(([, value]) => {
                    return value.toString().toLowerCase() === vendor?.toLowerCase();
                });
                return result ? result[0] : '';
            };
            const convertToCategories = (item: IPermissionItem): (keyof IPermissionCategories)[] => {
                return [item.view && 'view', item.manage && 'manage', item.enable && 'access'].filter(
                    Boolean,
                ) as (keyof IPermissionCategories)[];
            };
            const convertOUNameToId = (name: string) => {
                return oraganizationalUnits.find((ou) => ou.name === name)?.id;
            };

            items.forEach((item) => {
                const cloudAccountId = item.cloudAccount?.id;
                const regionId = item.region?.regionNumber;
                const securityGroupId = item.securityGroup?.securityGroupId;
                const key = getVendorNumber(item.cloudAccount?.platform);
                switch (item.type) {
                    case PERMISSION_TYPE.CLOUD_ACCOUNT: {
                        const id = item.cloudAccount?.id;
                        const key = getVendorNumber(item.cloudAccount?.platform);
                        const permission = `${key}|${id}`;
                        const categories = convertToCategories(item);
                        addPermission(categories, permission);
                        break;
                    }
                    case PERMISSION_TYPE.ORGANIZATIONAL_UNIT: {
                        const path = item.path || [];
                        const id = convertOUNameToId(path[path.length - 1]);
                        const key = ouKey;
                        const permission = `${key}|${id}`;
                        const categories = convertToCategories(item);
                        addPermission(categories, permission);
                        break;
                    }
                    case PERMISSION_TYPE.REGION: {
                        const permission = `${key}|${cloudAccountId}|rg|${regionId}`;
                        const categories = convertToCategories(item);
                        addPermission(categories, permission);
                        break;
                    }
                    case PERMISSION_TYPE.SECURITY_GROUP: {
                        const permission = `${key}|${cloudAccountId}|rg|${regionId}|sg|${securityGroupId}`;
                        const categories = convertToCategories(item);
                        addPermission(categories, permission);
                        break;
                    }
                    case PERMISSION_TYPE.SECURITY_GROUP_SERVICE: {
                        const permission = `${key}|${cloudAccountId}|rg|${regionId}|sg|${securityGroupId}|${item.service?.id}`;
                        const categories = convertToCategories(item);
                        addPermission(categories, permission);
                        break;
                    }
                    case PERMISSION_TYPE.ALL_SYSTEM_RESOURCES:
                        if (item.view) {
                            addPermission(['view'], '');
                        }
                        if (item.manage) {
                            addPermission(['manage'], '');
                        }
                        break;
                    case PERMISSION_TYPE.RULES_AND_RULESETS:
                        if (item.manage) {
                            addPermission(['rulesets'], '');
                        }
                        break;
                    case PERMISSION_TYPE.NOTIFICATIONS_AND_INTEGRATIONS:
                        if (item.manage) {
                            addPermission(['notifications'], '');
                        }
                        break;
                    case PERMISSION_TYPE.POLICY:
                        if (item.manage) {
                            addPermission(['policies'], '');
                        }
                        break;
                    case PERMISSION_TYPE.ALERTS_EXCLUSION_REMEDIATION:
                        if (item.manage) {
                            addPermission(['alertActions'], '');
                        }
                        break;
                    case PERMISSION_TYPE.ONBOARDING:
                        addPermission(['onBoarding'], '');
                        break;
                    case PERMISSION_TYPE.ALL_CLOUD_ACCOUNT_RESOURCES:
                        if (item.view) {
                            addPermission(['view'], allCloudResource);
                        }
                        if (item.manage) {
                            addPermission(['manage'], allCloudResource);
                        }
                        break;
                    case PERMISSION_TYPE.AGENT: {
                        const permissionStr = `${agentsKey}|${item.agent?.id}`;
                        if (item.view) {
                            addPermission(['view'], permissionStr);
                        }
                        if (item.manage) {
                            addPermission(['manage'], permissionStr);
                        }
                        if (item.enable) {
                            addPermission(['access'], permissionStr);
                        }
                        break;
                    }
                    case PERMISSION_TYPE.AGENT_SERVICE: {
                        addPermission(['access'], `0|${item.agent?.id}|${item.agentAccessPolicy?.id}`);
                        break;
                    }
                    case PERMISSION_TYPE.SECURITY_GROUP_AGENT: {
                        const permissionStr = `${securityGroupKey}|${item.securityGroup?.securityGroupId}`;
                        if (item.view) {
                            addPermission(['view'], permissionStr);
                        }
                        if (item.manage) {
                            addPermission(['manage'], permissionStr);
                        }
                        if (item.enable) {
                            addPermission(['access'], permissionStr);
                        }
                        break;
                    }
                    case PERMISSION_TYPE.PLATFORM:
                        const platformNumber =
                            getVendor(item.name || '')
                                ?.srlVendorType?.valueOf()
                                .toString() || '';
                        if (item.view) {
                            addPermission(['view'], platformNumber);
                        }
                        if (item.manage) {
                            addPermission(['manage'], platformNumber);
                        }
                        if (item.enable) {
                            addPermission(['access'], platformNumber);
                        }
                        break;
                    case PERMISSION_TYPE.SECURITY_GROUP_AGENT_HEADER:
                        if (item.view) {
                            addPermission(['view'], securityGroupKey);
                        }
                        if (item.manage) {
                            addPermission(['manage'], securityGroupKey);
                        }
                        if (item.enable) {
                            addPermission(['access'], securityGroupKey);
                        }
                        break;
                    case PERMISSION_TYPE.AGENT_HEADER:
                        if (item.view) {
                            addPermission(['view'], agentsKey);
                        }
                        if (item.manage) {
                            addPermission(['manage'], agentsKey);
                        }
                        if (item.enable) {
                            addPermission(['access'], agentsKey);
                        }
                        break;
                    case PERMISSION_TYPE.ORGANIZATIONAL_UNIT_HEADER:
                        if (item.view) {
                            addPermission(['view'], rootOUPermissionKey);
                        }
                        if (item.manage) {
                            addPermission(['manage'], rootOUPermissionKey);
                        }
                        if (item.enable) {
                            addPermission(['access'], rootOUPermissionKey);
                        }
                        break;
                    case PERMISSION_TYPE.ACCOUNT_ACCESS:
                        if (item.enable) {
                            const role = item.userRole || '*';
                            addPermission(['crossAccountAccess'], `${item.userAccount?.accountId}|${role}`);
                        }

                        break;
                    case PERMISSION_TYPE.CREATE_AGENT: {
                        addPermission(['create'], agentsKey);
                        break;
                    }
                    case PERMISSION_TYPE.CREATE_SECURITY_GROUP: {
                        addPermission(['create'], securityGroupKey);
                        break;
                    }
                }
            });
        };

        permissionModel.codeSecurity?.forEach((item) => {
            if (item.admin) {
                addPermission(['manage'], `${codeSecurityKey}|${codeSecurityPermissionType.Admin}`);
            } else if (item.member) {
                addPermission(['manage'], `${codeSecurityKey}|${codeSecurityPermissionType.Member}`);
            } else if (item.view) {
                addPermission(['view'], `${codeSecurityKey}|${codeSecurityPermissionType.ReadOnly}`);
            }
        });

        processCategoryPermissions(permissionModel.scopeControls || []);
        processCategoryPermissions(permissionModel.networkSecurity || []);
        processCategoryPermissions(permissionModel.codeSecurity || []);
        processCategoryPermissions(permissionModel.accountAccess || []);
        const _permissions = removeRedundantChildren(permissions);
        return _permissions;
    };
    return {
        convertPermissionListToPermissionModel,
        convertPermissionModelToPermissionList,
        updateRoles,
    };
};
