import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { PATH_PROTECTED_ASSETS_PAGE_TABLE_FILTERS } from 'common/module_interface/assets/ProtectedAssets.consts';

const filters: IProtectedAssetFilter[] = [
    {
        id: 'serverlessProtectionMode',
        position: 50,
    },
    {
        id: 'serverlessRuntimeProtection',
    },
    {
        id: 'serverlessEnable',
    },
    {
        id: 'serverlessAutoProtect',
    },
    {
        id: 'scanStatus',
    },
];

export default function initializeProtectedAssetPageFilters() {
    globalAddinContainer.addMap(PATH_PROTECTED_ASSETS_PAGE_TABLE_FILTERS, filters);
}
