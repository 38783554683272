import { HostConfBenchmark, Rule, ScanResults } from 'modules/workloads/services/cis/cis.interface';
import cisService from 'modules/workloads/services/cis/cis.service';
import { useState, useEffect, useMemo } from 'react';
import { Filters } from '../RulesTable/RulesTableFilterPanel/RuleTableFilterPanel';
import { getNotificationsService } from 'common/interface/services';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';

export interface Aggregations {
    [key: string]: { value: any; count: number }[];
}

interface QueryParams {
    assetId: string;
}

interface UseScanResultsProps {
    provider: string;
    cloudAccountId: string;
    queryParams: QueryParams;
}

interface UseScanResultsReturn {
    benchmarkData: HostConfBenchmark | undefined;
    rowData: Rule[];
    aggregations: Aggregations | undefined;
    loading: boolean;
    error: string | null;
    setFilters: (filters: Filters) => void;
    onExportCsv: () => void;
}

export const useScanResults = ({
    provider,
    cloudAccountId,
    queryParams,
}: UseScanResultsProps): UseScanResultsReturn => {
    const [scanResults, setScanResults] = useState<ScanResults | undefined>(undefined);
    const [filters, setFilters] = useState<Filters>({ fields: [], freeTextPhrase: '' });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { t } = useTranslation(getK8sNamespace('cis'));

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const resScanResults = await cisService.getScanResults({ provider, cloudAccountId, queryParams });
                setScanResults(resScanResults.data);
            } catch (e: any) {
                console.error(e);
                setError(e.message || 'Something went wrong');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const benchmarkData = useMemo(() => scanResults?.hostConfigurationBenchmarksList[0], [scanResults]);
    const rowData = useMemo(() => {
        if (!benchmarkData) return [];

        let filteredRules = benchmarkData.rules;

        if (filters?.fields) {
            filteredRules = filteredRules.filter((rule) =>
                filters.fields!.every((field) => (rule[field.name as keyof Rule] as string)?.includes(field.value)),
            );
        }

        if (filters?.freeTextPhrase) {
            const phrase = filters.freeTextPhrase.toLowerCase();
            filteredRules = filteredRules.filter((rule) =>
                Object.values(rule).some((value) => typeof value === 'string' && value.toLowerCase().includes(phrase)),
            );
        }

        return filteredRules;
    }, [benchmarkData, filters]);

    const aggregations: Aggregations | undefined = useMemo(() => {
        if (!scanResults) return undefined;

        const result: Aggregations = {};

        const updateAggregation = (field: string, value: string) => {
            if (!result[field]) {
                result[field] = [];
            }

            const existing = result[field].find((item) => item.value === value);
            if (existing) {
                existing.count += 1;
            } else {
                result[field].push({ value, count: 1 });
            }
        };

        scanResults.hostConfigurationBenchmarksList.forEach((benchmark) =>
            benchmark.rules.forEach((rule) => {
                updateAggregation('status', rule.status);
                // updateAggregation('severity', rule.severity); // re-enable this when the severity field is available in the API
            }),
        );

        return result;
    }, [scanResults]);

    const onExportCsv = async () => {
        getNotificationsService().info(t('export.exporting'), t('export.pleaseWait'));

        try {
            const response = await cisService.getScanResultsCSV({ provider, cloudAccountId, queryParams });
            const fileName = `CloudGuard_Host_Configuration_Benchmark_${benchmarkData?.benchmarkId}_${dayjs(new Date()).format('YYYY MM DD hh:mm A').replace(/\s+/g, '_').replace(/,/g, '')}.csv`;
            fileDownload(response.data, fileName);
        } catch (err) {
            console.error(err);
            getNotificationsService().error(t('export.label'), t('export.exportFailed'));
        }
    };

    return {
        benchmarkData,
        rowData,
        aggregations,
        loading,
        error,
        setFilters,
        onExportCsv,
    };
};
