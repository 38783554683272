import React, { ComponentType, useRef } from 'react';
import { ICiemExclusionModalProps } from './CiemExclusionModal.types';
import { ExclusionRegistry } from 'common/module_interface/exclusion/exclusionRegistry';
import { ExclusionModuleType, IExclusion } from 'common/interface/exclusion';

export const CiemExclusionModal: React.FC<ICiemExclusionModalProps> = (props: ICiemExclusionModalProps) => {
    const { event, closeModal } = props;
    const ExclusionsModalRef = useRef<ComponentType<any> | undefined>(
        ExclusionRegistry.getExcludeModals().find((modal) => modal.id === ExclusionModuleType.CIEM)?.component,
    );

    if (!ExclusionsModalRef.current) {
        closeModal();
        return null;
    }

    const configuredExclusionObject = (): IExclusion => {
        return {
            cloudAccountIds: [event.cloudAccountId],
            cloudAccountType: event.cloudAccountType,
            comment: '',
            logicExpressions: event.entityName ? [`name like '${event.entityName}'`] : [],
            platform: event.cloudAccountType,
            rules: [
                {
                    logicHash: undefined,
                    id: event.ruleId,
                    name: event.ruleName,
                    rlmId: null,
                },
            ],
            rulesetId: event.bundleId,
            finding: event,
        };
    };
    const initConfiguredExclusion = configuredExclusionObject();

    const onSave = () => {
        closeModal();
    };

    return (
        // eslint-disable-next-line react/jsx-pascal-case
        <ExclusionsModalRef.current
            exclusion={initConfiguredExclusion}
            isOpen={true}
            onClose={closeModal}
            onSave={onSave}
            sourceType={event.origin}
        />
    );
};
