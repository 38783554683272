import { Layers, Malwareflatten } from 'modules/workloads/services/vulnerability/vulnerability.interface';
import { ITableExportButton } from 'common/design-system/components-v2/Table/Table.types';

export interface ThreatsProps {
    threats: Array<Malwareflatten>;
    isThreats?: boolean;
    exportCveAsJSONAndCSV: Array<ITableExportButton>;
    layers?: Layers;
}

export enum ThreatColumnsNames {
    LayerId = 'layerId',
    LayerCommand = 'layerCommand',
    Severity = 'severity',
    Finding = 'finding',
    Type = 'type',
    Classification = 'classification',
    Description = 'description',
    FilePath = 'filePath',
    Lines = 'lines',
    BaseImages = 'baseImages',
    IsExclude = 'isExclude',
}
