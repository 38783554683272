import styled from 'styled-components';

export const WidgetColumnWrapper = styled.div`
    gap: 14px;
    align-items: center;
    display: flex;
    margin-bottom: 14px;
    min-height: 0;
    min-width: 0;
`;

export const WidgetColumnDashyLine = styled.div`
    flex: 1 1 auto;
    flex-shrink: 1;
    flex-grow: 1;
    --border-top-style: dashed;
    --border-right-style: dashed;
    --border-bottom-style: dashed;
    --border-left-style: dashed;
    --border-bottom-width: 1px;
`;

export const WidgetColumnTitle = styled.div`
    font-size: var(--text-lg);
    color: hsla(var(--color-info-h), var(--color-info-s), var(--color-info-l-darkest), var(--opacity));
    opacity: 0.75;
`;
