import { IProtectedAssetDetailPanelProvider } from 'common/module_interface/assets/ProtectedAssets';
import i18next from 'i18next';
import KubernetesAccountService from 'modules/workloads/services/kubernetesAccount/kubernetesAccount.service';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { ProtectedAssetsPageRegistry } from 'common/module_interface/assets/ProtectedAssetsPageRegistry';
import ImagesOverview from './tabs/ImageOverviewTab/ImageOverviewTab';
import AwpService from 'modules/workloads/services/awp/awp.service';
import { Vendors } from 'common/consts/vendors';
import SbomTab from './tabs/sbom/sbom';
import vulnerabilityService from 'modules/workloads/services/vulnerability/vulnerability.service';
import { getSbomEntityData, isScanEngineVersion2 } from './tabs/sbom/utils';
import { vulnerabilitiesPageTabsFactory } from './tabs/Vulnerability/vulnerabilitiesTabs.utils';
import { AssetTypes } from 'common/assets/assets.const';
import { SBOM_SUPPORTED_ASSETS_SET } from './tabs/sbom/consts';
import HostConfigurationWrapper from './tabs/HostConfiguration/HostConfigurationWrapper/HostConfigurationWrapper';
import { getUserService } from 'common/interface/services';

interface CloudEntityOwnerItem {
    kind: string;
    name: string;
    uid: string;
}

const isPodGroup = ({ cloudEntity, typeByPlatform }: ICloudEntityData): boolean => {
    switch (typeByPlatform) {
        case AssetTypes.KUBERNETES_KUBERNETESDEPLOYMENT:
        case AssetTypes.KUBERNETES_KUBERNETESDAEMONSET:
            return true;
        case AssetTypes.KUBERNETES_KUBERNETESPOD: {
            const rootOwnerKind = cloudEntity.owner?.rootOwner.kind;
            return !rootOwnerKind || !['ReplicaSet', 'Deployment', 'DaemonSet'].some((type) => type === rootOwnerKind);
        }
        case AssetTypes.KUBERNETES_KUBERNETESREPLICASET: {
            const ownerReferences: CloudEntityOwnerItem[] | undefined = cloudEntity.owner?.ownerReferences;
            return !ownerReferences || !ownerReferences.some((owner) => owner.kind === 'Deployment');
        }
    }
    return false;
};

const isRuntimeProtectionRelevant = async (cloudEntityData: ICloudEntityData, tabName: string) => {
    const workloadAssetTypes = [
        AssetTypes.KUBERNETES_KUBERNETESDAEMONSET,
        AssetTypes.KUBERNETES_KUBERNETESDEPLOYMENT,
        AssetTypes.KUBERNETES_KUBERNETESPOD,
        AssetTypes.KUBERNETES_KUBERNETESREPLICASET,
    ];

    const awsAssetTypes = [AssetTypes.AWS_LAMBDA];

    if (awsAssetTypes.some((type) => type === cloudEntityData.typeByPlatform)) {
        try {
            return !!(
                await AwpService.getAwsAzureServerlessFunctionsGet(cloudEntityData.protectedAsset.id, Vendors.AWS)
            ).data;
        } catch (error) {
            return false;
        }
    }

    if (workloadAssetTypes.some((type) => type === cloudEntityData.typeByPlatform)) {
        const k8sAccount = await KubernetesAccountService.getAccount({ id: cloudEntityData.cloudAccountId }, true);
        return (
            isPodGroup(cloudEntityData) &&
            k8sAccount.data.runtimeProtectionEnabled &&
            (tabName === 'whitelist' ? k8sAccount.data.runtimeProtectionProfiling : true)
        );
    }

    return false;
};

export function initializeAssetDetailsTabs() {
    const runtimeProtectionTab: IProtectedAssetDetailPanelProvider = {
        isRelevant: async (cloudEntityData) => await isRuntimeProtectionRelevant(cloudEntityData, 'whitelist'),
        getValue() {
            return {
                name: 'whitelist',
                title: i18next.t('k8s_protected-asset-details:tabs.runtime-protection'),
                isReact: false,
            };
        },
    };

    const runtimeProtectionRulesTab: IProtectedAssetDetailPanelProvider = {
        isRelevant: async (cloudEntityData) => await isRuntimeProtectionRelevant(cloudEntityData, 'exclusions'),
        getValue({ cloudAccount }) {
            return {
                name: 'exclusions',
                title:
                    cloudAccount.platform === 'kubernetes'
                        ? i18next.t('k8s_protected-asset-details:tabs.runtime-protection-rules')
                        : i18next.t('k8s_protected-asset-details:tabs.rules-and-exclusions'),
                isReact: false,
            };
        },
    };

    const generalTab: IProtectedAssetDetailPanelProvider = {
        isRelevant: async ({ typeByPlatform, protectedAsset }) => {
            if (typeByPlatform === AssetTypes.AWS_LAMBDA) {
                try {
                    return !!(await AwpService.getAwsAzureServerlessFunctionsGet(protectedAsset.id, Vendors.AWS)).data;
                } catch (error) {
                    return false;
                }
            }

            const workloadAssetTypes = [AssetTypes.KUBERNETES_KUBERNETESNODE];
            return workloadAssetTypes.some((type) => type === typeByPlatform);
        },
        getValue() {
            return {
                name: 'general',
                title: i18next.t('k8s_protected-asset-details:tabs.general'),
                isReact: false,
            };
        },
    };

    const podGroupTab: IProtectedAssetDetailPanelProvider = {
        isRelevant: ({ typeByPlatform }) => {
            const workloadAssetTypes = [AssetTypes.KUBERNETES_KUBERNETESNODE];
            return workloadAssetTypes.some((type) => type === typeByPlatform);
        },
        getValue() {
            return {
                name: 'pod group',
                title: i18next.t('k8s_protected-asset-details:tabs.pod-group'),
                isReact: false,
            };
        },
    };

    const imagesOverviewTab: IProtectedAssetDetailPanelProvider = {
        isRelevant: ({ typeByPlatform }) => {
            const workloadAssetTypes = [
                AssetTypes.CONTAINERREGISTRY_CONTAINERREGISTRYIMAGE,
                AssetTypes.AWS_ECSIMAGE,
                AssetTypes.KUBERNETES_KUBERNETESIMAGE,
                AssetTypes.SHIFTLEFT_SHIFTLEFTIMAGE,
            ];

            return workloadAssetTypes.some((type) => type === typeByPlatform);
        },
        getValue() {
            return {
                name: 'overview',
                title: i18next.t('k8s_protected-asset-details:tabs.overview'),
                isReact: true,
                component: ImagesOverview,
            };
        },
    };

    const sbomTab: IProtectedAssetDetailPanelProvider = {
        isRelevant: async ({
            typeByPlatform,
            protectedAsset,
            cloudAccountId,
            cloudEntity: { id: originalEntityId },
        }) => {
            if (!SBOM_SUPPORTED_ASSETS_SET.has(typeByPlatform)) {
                return false;
            }

            const entityData =
                typeByPlatform === AssetTypes.AWS_LAMBDA
                    ? { entityId: originalEntityId, entityType: protectedAsset.type }
                    : await getSbomEntityData(protectedAsset);

            if (entityData == null) {
                return false;
            }

            const { entityId, entityType } = entityData;

            try {
                const { data } = await vulnerabilityService.getVulnerabilityScanMetadata({
                    entityId,
                    entityType,
                    environmentId: cloudAccountId,
                });
                return isScanEngineVersion2(data.scanInfo);
            } catch (e) {
                return false;
            }
        },
        getValue() {
            return {
                name: 'sbom',
                title: i18next.t('k8s_sbom:tabName'),
                isReact: true,
                component: SbomTab,
            };
        },
    };

    const hostConfigurationTab: IProtectedAssetDetailPanelProvider = {
        isRelevant: async ({ protectedAsset }) => {
            const hostConfigurationSupportedAssetTypes = new Set([
                AssetTypes.AZURE_VIRTUALMACHINE,
                AssetTypes.AWS_INSTANCE,
            ]);

            const supportedType = hostConfigurationSupportedAssetTypes.has(protectedAsset.typeByPlatform);
            const isLinux =
                protectedAsset.additionalFields.find((field) => field.name === 'OperatingSystem')?.value === 'linux';
            const shouldShowHostConfigurationTab = supportedType && isLinux;

            if (shouldShowHostConfigurationTab && getUserService().hasPermission(['host-configuration-tab'])) {
                return true;
            }

            return false;
        },

        getValue() {
            return {
                name: 'Host Configuration',
                title: i18next.t('k8s_cis:tabName'),
                isReact: true,
                component: HostConfigurationWrapper,
            };
        },
    };

    ProtectedAssetsPageRegistry.addPanelAddins([
        {
            id: 'overview-images',
            position: 0,
            content: imagesOverviewTab,
        },
        ...vulnerabilitiesPageTabsFactory('vulnerabilities', 11),
        {
            id: 'host configuration',
            position: 12,
            content: hostConfigurationTab,
        },
        {
            id: 'whitelist',
            position: 13,
            content: runtimeProtectionTab,
        },
        {
            id: 'exclusions',
            position: 14,
            content: runtimeProtectionRulesTab,
        },
        {
            id: 'general',
            position: 1,
            content: generalTab,
        },
        {
            id: 'pod group',
            position: 15,
            content: podGroupTab,
        },
        {
            id: 'sbom',
            position: 12,
            content: sbomTab,
        },
    ]);
}
