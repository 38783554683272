export const floatToPercentage = (value: number): string => {
    if (value === 0) {
        return '0%';
    }
    const rounded = Math.round(value * 100);
    if (rounded === 0) {
        return '< 1%';
    }
    return `${rounded.toString()}%`;
};
