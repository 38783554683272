import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Checkbox, Stack } from 'common/design-system/components-v2';

const SelectionHeaderComponent: React.FC<ICellRendererParams> = (params) => {
    function toggleSelectAll(event: React.ChangeEvent<HTMLInputElement>) {
        params.api.forEachNode((node) => {
            if (node.selectable) {
                node.setSelected(event.target.checked || false);
            }
        });
    }

    return (
        <Stack fullWidth data-aid='DS_Table_SelectAll'>
            <Checkbox onChange={toggleSelectAll} />
        </Stack>
    );
};

export default SelectionHeaderComponent;
