import i18n from 'common/services/translations/translations';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { Vendors } from 'common/consts/vendors';
import { NotificationType } from 'common/interface/notifications';
import { getNotificationsService } from 'common/interface/services';
import {
    PatchAutoProtectModeResponse,
    PatchAutoProtectModeStatus,
} from 'modules/workloads/services/workload/workload.interface';
import { ActionMode } from '../../types/actions.types';

export const NOTIFICATION_TIMEOUT = 5000; // 5 seconds
export const BASE_TRANSLATION_KEY = 'SERVERLESS.ACTIONS';

export const showNotification = (data: PatchAutoProtectModeResponse[], actionMode: ActionMode) => {
    const successesCount = data.filter(
        (item: PatchAutoProtectModeResponse) => item.status === PatchAutoProtectModeStatus.Success,
    ).length;
    const ignoredCount = data.filter(
        (item: PatchAutoProtectModeResponse) => item.status === PatchAutoProtectModeStatus.Ignore,
    ).length;
    const errorsCount = data.filter(
        (item: PatchAutoProtectModeResponse) => item.status === PatchAutoProtectModeStatus.Error,
    ).length;

    if (successesCount > 0 && ignoredCount > 0) {
        // show partial success notification
        getNotificationsService().addNotification({
            type: NotificationType.INFO,
            title: i18n.t(`${BASE_TRANSLATION_KEY}.${actionMode}.NOTIFICATIONS.PARTIAL_SUCCESS.TITLE`),
            text: i18n.t(`${BASE_TRANSLATION_KEY}.${actionMode}.NOTIFICATIONS.PARTIAL_SUCCESS.TEXT`, {
                successesCount,
                ignoredCount: ignoredCount + errorsCount,
            }),
            autoClose: NOTIFICATION_TIMEOUT,
        });
    } else if (successesCount > 0 && ignoredCount === 0 && errorsCount === 0) {
        // show success notification
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: i18n.t(`${BASE_TRANSLATION_KEY}.${actionMode}.NOTIFICATIONS.SUCCESS.TITLE`),
            text: i18n.t(`${BASE_TRANSLATION_KEY}.${actionMode}.NOTIFICATIONS.SUCCESS.TEXT`),
            autoClose: NOTIFICATION_TIMEOUT,
        });
    } else if (successesCount === 0 && (ignoredCount > 0 || errorsCount > 0)) {
        // show error notification
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: i18n.t(`${BASE_TRANSLATION_KEY}.${actionMode}.NOTIFICATIONS.ERROR.TITLE`),
            text: i18n.t(`${BASE_TRANSLATION_KEY}.${actionMode}.NOTIFICATIONS.ERROR.TEXT`),
            autoClose: NOTIFICATION_TIMEOUT,
        });
    }
};

export const getFunctionId = (item: IProtectedAssetViewModel) => {
    return item.platform === Vendors.AWS
        ? `arn:aws:lambda:${item.region.replaceAll('_', '-')}:${item.externalCloudAccountId}:function:${item.entityId}:$LATEST`
        : item.entityId;
};
