import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { getVendor } from 'common/consts/vendors';
import { IEnvironment } from 'common/interface/environmentsTable';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { changeUrl, generateHref } from 'common/utils/http';

const EnvironmentCellRenderer: React.FC<ICellRendererParams<IEnvironment>> = (params) => {
    const { id, name, platform } = params.data || {};
    const vendor = getVendor(platform || '');

    if (!vendor || !name) return null;

    const href = generateHref(`/cloud-account/${vendor?.uri}/${id}`);
    const options: IIconLinkCellProps = {
        isLink: true,
        value: name,
        href,
        onClick: (event) => {
            event.preventDefault();
            changeUrl(href);
        },
    };

    if (vendor?.icon) {
        options.levelIconProps = {
            iconProps: {
                vendorNameOrPath: vendor.icon,
            },
            size: 'md',
            withBorder: true,
        };
    }

    if (vendor?.newIcon) {
        options.levelIconProps = {
            iconProps: {
                name: vendor.newIcon,
            },
            size: 'md',
            withBorder: true,
        };
    }

    return <IconLinkCellRenderer {...options} />;
};

export default EnvironmentCellRenderer;
