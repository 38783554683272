import { IReactPageRoute } from 'common/interface/routing';
import { DynamicProtectedAssetsTableProps } from 'common/components/ProtectedAssets/DynamicProtectedAssetsTable/DynamicProtectedAssetsTable.types';
import {
    assetsTableBaseColumns,
    imagesTableBaseColumns,
    serverlessTableColumns,
    sortTableColumns,
    mainPageTableBaseColumns,
    TABLE_SELECTION,
} from './ProtectedAssetsList/types/columns';
import {
    assetsTableBaseFilters,
    imagesTableBaseFilters,
    mainPageTableBaseFilters,
} from './ProtectedAssetsList/types/filters';
import { getAppRootRegistry, getUserService } from 'common/interface/services';
import { AssetTypes } from 'common/assets/assets.const';
import { ActionsIds } from './Definitions/actions/types/actions.types';
import { filterColumns } from 'modules/workloads/utils/aggrid';
import { lazy } from 'react';

function initializeRoutes() {
    const protectedAssetsPageUrl = '/workload/workloads';

    type ProtectedAssetsPageProps = () => DynamicProtectedAssetsTableProps;

    const protectedAssetsPageProps: ProtectedAssetsPageProps = () => ({
        tableId: 'workloads-protected-assets-page-filter-panel-id',
        columns: filterColumns(sortTableColumns([...mainPageTableBaseColumns, ...assetsTableBaseColumns]), [], 'id'),
        includedEntityTypes: [
            'EcsCluster',
            'EcsService',
            'EcsTask',
            'KubernetesPod',
            'KubernetesJob',
            'KubernetesCronJob',
            'KubernetesStatefulSet',
            'KubernetesDaemonSet',
            'KubernetesDeployment',
            'KubernetesReplicaSet',
        ],
        withTabsPanel: {
            pageId: 'workloadsProtectedAssets',
            closeAllUrl: protectedAssetsPageUrl,
        },
        withFilterPanel: {
            filters: [...assetsTableBaseFilters, ...mainPageTableBaseFilters],
        },
        tablePadding: 5,
    });

    const protectedAssetsPageRoute = (): IReactPageRoute => {
        return {
            condition: true,
            component: lazy(
                () =>
                    import('common/components/ProtectedAssets/DynamicProtectedAssetsTable/DynamicProtectedAssetsTable'),
            ),
            componentProps: protectedAssetsPageProps(),
            path: [protectedAssetsPageUrl],
            exact: true,
        };
    };

    const imagesPageUrl = '/workload/images';

    const imagesPageProps = () => ({
        tableId: 'workloads-images-page-filter-panel-id',
        columns: filterColumns(
            sortTableColumns([...TABLE_SELECTION, ...mainPageTableBaseColumns, ...imagesTableBaseColumns]),
            [],
            'id',
        ),
        includedEntityTypes: ['KubernetesImage', 'ContainerRegistryImage', 'ShiftLeftImage', 'EcsImage'],
        withTabsPanel: {
            pageId: 'workloadsImagesProtectedAssets',
            closeAllUrl: imagesPageUrl,
        },
        withFilterPanel: {
            filters: [...imagesTableBaseFilters, ...mainPageTableBaseFilters],
        },
        actions: [{ id: ActionsIds.RequestScan }, { id: ActionsIds.AddBaseImageRule }],
        tablePadding: 5,
    });

    const imagesPageRoute = (): IReactPageRoute => {
        return {
            condition: true,
            component: lazy(
                () =>
                    import('common/components/ProtectedAssets/DynamicProtectedAssetsTable/DynamicProtectedAssetsTable'),
            ),
            componentProps: imagesPageProps(),
            path: [imagesPageUrl],
            exact: true,
        };
    };

    const serverlessPageFilters: string[] = [
        'organizational unit',
        'environment',
        'region',
        'network',
        'tag',
        'serverlessRuntimeProtection',
        'serverlessAutoProtect',
        'serverlessProtectionMode',
        'serverlessEnable',
    ];

    const serverlessPageProps: ProtectedAssetsPageProps = () => ({
        tableId: 'workloads-protected-assets-page-filter-panel-id',
        columns: sortTableColumns([...serverlessTableColumns]),
        actions: [{ id: ActionsIds.AutoProtect }, { id: ActionsIds.ProtectionMode }],
        includedEntityTypes: [AssetTypes.AWS_LAMBDA],
        withFilterPanel: {
            filters: serverlessPageFilters.map((filter) => ({ id: filter })),
        },
        tablePadding: 5,
        isRowSelectable: (
            node: any, // set row selectable based on the value of ServerlessEnable
        ) =>
            !!(node?.data?.externalAdditionalFields as Record<'name', string>[])?.find(
                (f) => f.name === 'ServerlessEnable',
            ),
        deselectAllOnFilterChange: true,
    });

    const ServerlessFunctionsRoute = () => {
        return {
            condition: () => getUserService().hasAnyPermission(['workload-serverless-functions']),
            component: lazy(
                () =>
                    import('common/components/ProtectedAssets/DynamicProtectedAssetsTable/DynamicProtectedAssetsTable'),
            ),
            componentProps: serverlessPageProps(),
            path: '/workload/serverless/assets',
        };
    };

    getAppRootRegistry().addRoutes([
        { id: 'Workloads Protected Assets Page', content: protectedAssetsPageRoute },
        { id: 'Workloads Images Page', content: imagesPageRoute },
        { id: 'Workloads Serverless Functions Page', content: ServerlessFunctionsRoute },
    ]);
}

export function initializeProtectedAssetsPage() {
    initializeRoutes();
}
