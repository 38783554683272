import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { POLICY_SERVICE } from 'common/interface/policy';
import { IntelligenceService } from '../Services/IntelligenceService';
import { getAppRootRegistry } from 'common/interface/services';
import { lazy } from 'react';
const INTELLIGENCE = 'intelligence';

export default function initialize() {
    const policyPageRoute = {
        condition: true,
        component: lazy(() => import('./AddIntelligencePolicyPage')),
        path: '/intelligence/policy/add',
    };

    const policyEditPageRoute = {
        condition: true,
        component: lazy(() => import('./EditPolicyPage')),
        path: '/intelligence/policy/edit',
    };

    globalAddinContainer.add(POLICY_SERVICE, [
        {
            id: 'intelligence-policy-service',
            content: { moduleName: INTELLIGENCE, service: new IntelligenceService() },
        },
    ]);
    getAppRootRegistry().addRoutes([
        { id: 'intelligence-Policy', content: policyPageRoute },
        { id: 'intelligence-edit-Policy', content: policyEditPageRoute },
    ]);
}
