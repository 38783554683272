import React from 'react';
import { ColDef, ICellRendererParams } from 'ag-grid-enterprise';
import { Stack, Table } from 'common/design-system/components-v2';
import { useWorkloadsByImage } from 'modules/workloads/services/workload/hooks/useWorkloadsByImage';
import { GetWorkloads } from 'modules/workloads/services/workload/workload.interface';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import EnvironmentCellRender from 'common/components/ProtectedAssets/Renderers/EnvironmentCellRender';
import EntityCellRender from 'common/components/ProtectedAssets/Renderers/EntityCellRender';
import { IAsset, UrlFuncResult } from 'common/assets/common.assets';
import { toQueryString } from 'common/utils/http';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import styled from 'styled-components';

const ContainersWorkloadsWrapper = styled(Stack)`
    min-height: '200px';
`;

type WorkloadsColumn = { entityName: string; Environment: string; entityType: string; [key: string]: string };

enum WorkloadsTypes {
    Deployment = 'KubernetesDeployment',
    DaemonSet = 'KubernetesDaemonSet',
    Pod = 'KubernetesPod',
    ReplicaSet = 'KubernetesReplicaSet',
    StatefulSet = 'KubernetesStatefulSet',
    CronJob = 'KubernetesCronJob',
}

type GetHeaderName = (workloads: GetWorkloads.Response) => string;
const getHeaderName: GetHeaderName = ({ ecsImageWorkloads, kubernetesImageWorkloads }) => {
    if (ecsImageWorkloads.length && kubernetesImageWorkloads.length) {
        return 'namespaceRegion';
    } else if (ecsImageWorkloads.length && !kubernetesImageWorkloads.length) {
        return 'region';
    } else {
        return 'namespace';
    }
};

const ContainersWorkloads: React.FC<{ imageId: string; digest: string }> = ({ imageId, digest }) => {
    const { t } = useTranslation(getK8sNamespace('image-overview'));
    const { data, isLoading } = useWorkloadsByImage(imageId, digest);

    const generateWorkloadTableRowsData = (kubernetesWorkload: GetWorkloads.Response): any[] => {
        const { ecsImageWorkloads, kubernetesImageWorkloads } = kubernetesWorkload;

        const kubernetesImageWorkloadsData = kubernetesImageWorkloads.map(({ name, namespace, kind, ...rest }) => ({
            entityName: name,
            [`${getHeaderName({ ecsImageWorkloads, kubernetesImageWorkloads })}`]: namespace,
            entityType: WorkloadsTypes[kind as keyof typeof WorkloadsTypes],
            ...rest,
        }));

        const ecsImageWorkloadsData = ecsImageWorkloads.map(({ name, region, type, ...rest }) => ({
            entityName: name,
            [`${getHeaderName({ ecsImageWorkloads, kubernetesImageWorkloads })}`]: region,
            entityType: type,
            ...rest,
        }));
        return [...ecsImageWorkloadsData, ...kubernetesImageWorkloadsData];
    };

    const generateUrl = (asset: IAsset, assetUrl: UrlFuncResult) => {
        const url = `/workload/workloads/protected-asset${asset?.isGenericObject ? '-generic' : ''}`;
        const query = {
            ...((assetUrl?.query as object) || {}),
            type: asset?.typeNumber,
        };
        const queryParams = toQueryString({ query });
        return `${url}?${queryParams}`;
    };

    type GetColumnDefs = (workloads: GetWorkloads.Response) => ColDef<WorkloadsColumn>[];
    const getColumnDefs: GetColumnDefs = ({ ecsImageWorkloads, kubernetesImageWorkloads }) => [
        {
            field: 'entityName',
            headerName: t('workloadsTable.headers.entity'),
            cellRenderer: EntityCellRender,
            cellRendererParams: (param: ICellRendererParams) => {
                return {
                    ...param,
                    generateUrl,
                    data: {
                        typeByPlatform: param.data.entityType,
                        platform: param.data.platform,
                        cloudAccountId: param.data.environmentId,
                        name: param.data.entityName,
                        entityId: param.data.externalId,
                    },
                };
            },
            flex: 1,
        },
        {
            field: 'Environment',
            headerName: t('workloadsTable.headers.environment'),
            flex: 1,
            cellRenderer: EnvironmentCellRender,
        },
        {
            field: `${getHeaderName({ ecsImageWorkloads, kubernetesImageWorkloads })}`,
            headerName: t(`workloadsTable.headers.${getHeaderName({ ecsImageWorkloads, kubernetesImageWorkloads })}`),
            flex: 1,
        },
        { field: 'entityType', headerName: t('workloadsTable.headers.type'), flex: 1 },
    ];

    return (
        <ContainersWorkloadsWrapper spacing={4} fullWidth fullHeight>
            {isLoading ? (
                <CloudAnimationLoader size={'medium'} />
            ) : data ? (
                <Table
                    disableColumnMenu
                    disableGrouping
                    gridOptions={{
                        rowData: generateWorkloadTableRowsData(data),
                        columnDefs: getColumnDefs(data),
                    }}
                />
            ) : null}
        </ContainersWorkloadsWrapper>
    );
};

export default ContainersWorkloads;
