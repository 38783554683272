import { GridOptions } from 'ag-grid-community';
import { CheckboxCellRenderer } from '../CellRenderers/CheckboxCellRenderer';
import { AgGridReact } from 'ag-grid-react';
import { Stack } from 'common/design-system/components-v2';
import { FC, useEffect, useRef, useState } from 'react';
import { getBorderStyle } from '../utils';
import { InfoCellRenderer } from '../CellRenderers/InfoCellRenderer';
import {
    infoColumnWidth,
    inheritedRolesColumnWidth,
    IPermissionComponentProps,
    IPermissionItem,
    PERMISSION_TYPE,
    PermissionCategorySubType,
    PermissionViewMode,
} from '../interfaces';
import { useTranslation } from 'react-i18next';
import { I18nSettingsTranslationKey } from '../../../initialize';
import { InheritedRolesCellRenderer } from '../CellRenderers/InheritedRolesCellRenderer';

const codeControls: IPermissionItem[] = [
    {
        type: PERMISSION_TYPE.CODE_SECURITY,
        categorySubType: PermissionCategorySubType.CODE_SECURITY,
        id: PERMISSION_TYPE.CODE_SECURITY,
        name: 'Access level',
        view: false,
        member: false,
        admin: false,
    },
];

export const CodeSecurity: FC<IPermissionComponentProps> = ({ viewMode, permissions, permissionsChanged }) => {
    const reviewMode = viewMode === PermissionViewMode.REVIEW;
    const [rowData, setRowData] = useState<IPermissionItem[]>(codeControls);
    const gridRef = useRef(null);
    const { t } = useTranslation(I18nSettingsTranslationKey);

    useEffect(() => {
        permissions?.length && setRowData(permissions);
    }, [permissions]);

    const resetCodeSecurityFields = (field: string, checked: boolean) => {
        const codeItem = rowData[0];
        codeItem.admin = false;
        codeItem.member = false;
        codeItem.view = false;
        // @ts-ignore
        codeItem[field] = checked;
        permissionsChanged && permissionsChanged([codeItem]);
        setRowData([...rowData]);
    };
    const getBaseColumns = () => {
        const baseColumns = [];
        if (reviewMode) {
            baseColumns.push({
                field: 'inheritedRoles',
                cellRenderer: InheritedRolesCellRenderer,
                headerName: t('HEADERS.ROLES'),
                width: inheritedRolesColumnWidth,
            });
        }
        baseColumns.push({
            field: 'info',
            headerName: t('HEADERS.INFO'),
            width: infoColumnWidth,
            cellRenderer: InfoCellRenderer,
            cellRendererParams: { permissionSubType: PermissionCategorySubType.CODE_SECURITY },
        });
        return baseColumns;
    };
    const gridOptions: GridOptions = {
        defaultColDef: {
            suppressMenu: true,
        },
        columnDefs: [
            { field: 'name', headerName: t('HEADERS.CODE_SECURITY'), flex: 1 },
            ...getBaseColumns(),
            {
                field: 'view',
                headerName: t('HEADERS.READ_ONLY'),
                cellRenderer: CheckboxCellRenderer,
                width: 100,
                cellRendererParams: {
                    reviewMode: reviewMode,
                    onClick: (checked: boolean) => {
                        resetCodeSecurityFields('view', checked);
                    },
                },
            },
            {
                field: 'member',
                headerName: t('HEADERS.MEMBER'),
                cellRenderer: CheckboxCellRenderer,
                width: 100,
                cellRendererParams: {
                    reviewMode: reviewMode,
                    onClick: (checked: boolean) => {
                        resetCodeSecurityFields('member', checked);
                    },
                },
            },
            {
                field: 'admin',
                headerName: t('HEADERS.ADMIN'),
                cellRenderer: CheckboxCellRenderer,
                width: 100,
                cellRendererParams: {
                    reviewMode: reviewMode,
                    onClick: (checked: boolean) => {
                        resetCodeSecurityFields('admin', checked);
                    },
                },
            },
        ],
    };

    return (
        <>
            {reviewMode && !permissions?.length ? (
                ''
            ) : (
                <Stack
                    data-aid={'code-security'}
                    style={{ height: '120px', border: getBorderStyle(viewMode) }}
                    padding={3}
                    spacing={3}
                >
                    <AgGridReact
                        ref={gridRef}
                        className={'ag-theme-alpine'}
                        rowData={rowData}
                        gridOptions={gridOptions}
                        animateRows={true}
                    />
                </Stack>
            )}
        </>
    );
};
