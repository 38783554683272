import { Addin } from 'common/extensibility/AddinRegistry';
import { ColDef } from 'ag-grid-community';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { IEnvironmentsFilter } from 'common/module_interface/assets/Environments';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { IFilterParams, ITableRegistry } from './interfaces';

export const getTableRegistry = (columnsPathId: string, filtersPathId: string): ITableRegistry => {
    return {
        addColumnDefAddins: (columnDefAddins: Addin<ColDef>[]) => {
            globalAddinContainer.add<ColDef>(columnsPathId, columnDefAddins);
        },

        addColumnDefs: (columnDefs: ColDef[]) => {
            globalAddinContainer.addMap(columnsPathId, columnDefs, 'colId');
        },

        getColumnDefs: (): ColDef[] => {
            return globalAddinContainer.get<ColDef>(columnsPathId);
        },

        addFilterAddins: (filterAddins: Addin<IFilterProps>[]) => {
            globalAddinContainer.add<IFilterProps>(filtersPathId, filterAddins);
        },

        getFilter: (filterId: string, filterParams: IFilterParams[]): IFilterProps | undefined => {
            return globalAddinContainer.getById<IFilterProps>(filtersPathId, filterId, filterParams);
        },

        getFilterDefs(filters: IEnvironmentsFilter[], params: IFilterParams): IFilterProps[] {
            const result: IFilterProps[] = [];
            filters.forEach((filter) => {
                const matchedFilterDef = this.getFilter(filter.id, [params]);
                if (matchedFilterDef) {
                    const filterDef = { ...matchedFilterDef };
                    filterDef.filterProps = { ...filterDef.filterProps, ...filter.filterProps };
                    result.push(filterDef);
                }
            });
            return result;
        },
    };
};
