import { getStoreService, IUserService } from 'common/interface/services';
import { ICloudAccount, IUser, IUserAccount } from 'common/interface/user';
import { IItemPermissions } from 'common/interface/account';
import { getPermissions } from '../user/User.reducer';

export class UserService implements IUserService {
    hasPermission(permissions: IItemPermissions): boolean {
        const { state } = getStoreService().getReduxTools();
        const userPermissions = state.user.permissions;

        const permissionsCompare = permissions.reduce((acc: boolean[], permissionItem: string) => {
            const isTrueExpression = permissionItem.charAt(0) !== '!';
            const permissionKey = isTrueExpression ? permissionItem : permissionItem.substring(1);

            const isPermissionNotExistsAndShouldBeFalse = !userPermissions[permissionKey] && !isTrueExpression;
            const isPermissionValueIsAsExpected =
                userPermissions[permissionKey] === isTrueExpression ||
                localStorage.getItem(permissionKey) === String(isTrueExpression);

            acc.push(isPermissionNotExistsAndShouldBeFalse || isPermissionValueIsAsExpected);
            return acc;
        }, []);

        return permissionsCompare.includes(true) && !permissionsCompare.includes(false);
    }

    hasAnyPermission(permissions: IItemPermissions): boolean {
        return permissions.some((permissionItem: string) => {
            return this.hasPermission([permissionItem]);
        });
    }

    getIsSuperUser() {
        const userPermissions = getPermissions(getStoreService().state);
        return userPermissions.isSuperUser;
    }

    getUser() {
        const { state } = getStoreService().getReduxTools();
        return state.user.user as IUser;
    }

    getAccount(): IUserAccount {
        const { state } = getStoreService().getReduxTools();
        return state.user.account as IUserAccount;
    }
    getCloudguardAccount(): ICloudAccount {
        const { state } = getStoreService().getReduxTools();
        return state.user.cloudAccount;
    }
}
