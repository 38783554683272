import React from 'react';
import { HostnameSelectorProps } from './RegistryURISelector.interface';
import { useTranslation } from 'react-i18next';
import { Label, SelectV2 as Select, Stack } from 'common/design-system/components-v2';

const GCRRegistryURIs = ['gcr.io', 'us.gcr.io', 'eu.gcr.io', 'asia.gcr.io'];

const RegistryURISelector: React.FC<HostnameSelectorProps> = (props) => {
    const { t } = useTranslation('k8s_registry');
    const { onChange, registryUrl } = props;
    const options = GCRRegistryURIs.map((uri: string) => ({ label: uri, value: uri }));

    return (
        <Stack spacing={1}>
            <Label text={t('ON_BOARDING.registryConfigFormLabels.registryUri')} required />
            <Select
                isMulti={false}
                options={options}
                onChange={(value: any) => onChange(value.value)}
                placeholder={t('ON_BOARDING.selectRegistryUriPlaceHolder')}
                value={registryUrl.value}
            />
        </Stack>
    );
};

export default RegistryURISelector;
