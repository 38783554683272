import { Addin } from 'common/extensibility/AddinRegistry';
import {
    AzureEnvironmentAddinRegistry,
    AzureEnvironmentAddingBase,
    CustomModalContent,
} from 'common/helpers/azureEnvironment';
import { Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import { Vendors } from 'common/consts/vendors';
import { Trans } from 'react-i18next';
import { Command, Stack } from 'common/design-system/components-v2';
import { ALink } from 'common/components/ALink';
import AzureCloudAccountService from './services/azure-cloud-account/azure-cloud-account.service';
import { getK8sNamespace } from './initialize.i18n';
import AwpService from './services/awp/awp.service';
import { lazy } from 'react';

export const initialAzureEnvironmentTabs = () => {
    const tabs: Addin<AzureEnvironmentAddingBase<Tab>>[] = [
        {
            id: 'awp',
            position: 91,
            content: {
                id: 'awp',
                getValue: (awsData) => ({
                    name: 'awp',
                    label: 'AWP',
                    isReactTab: true,
                    reactTab: {
                        component: lazy(() => import('./aws/tabs/awp/AwpTab')),
                        componentProps: {
                            cloudAccountId: awsData.account.id,
                            platform: Vendors.AZURE,
                        },
                    },
                }),
            },
        },
    ];

    const agentlessProtectionData: Addin<AzureEnvironmentAddingBase<CustomModalContent>>[] = [
        {
            id: 'agentless',
            position: 92,
            content: {
                id: 'agentless',
                getValue: async (awsData) => {
                    const getCommand = async () => {
                        try {
                            const res = await AzureCloudAccountService.getAzureOffboarding(awsData.account.id);
                            return res.data.offboardingScriptCommand;
                        } catch (err) {
                            console.log(err);
                            return '';
                        }
                    };
                    const getContent = (command: string) => {
                        return (
                            <Trans
                                i18nKey={`${getK8sNamespace('awp')}:modals.removeAccount.disableAwpAzure`}
                                components={{
                                    span: <span />,
                                    link1: <ALink target={'_blank'} href='https://shell.azure.com/'></ALink>,
                                    link2: (
                                        <ALink
                                            target={'_blank'}
                                            href='https://learn.microsoft.com/en-us/cli/azure/install-azure-cli'
                                        ></ALink>
                                    ),
                                    stack: <Stack spacing={[2]}>.</Stack>,
                                    command: <Command isLoading={false} text={command} />,
                                }}
                            />
                        );
                    };
                    const command = await getCommand();
                    return {
                        name: 'remove',
                        label: 'remove',
                        isReactTab: true,
                        content: getContent(command),
                    };
                },
                isRelevant: async (awsData) => {
                    const getAccountAgentlessData = async () => {
                        try {
                            const res = await AwpService.getWorkloadAgentessAccount(awsData.account.id, Vendors.AZURE);
                            return res.data.agentlessProtectionEnabled;
                        } catch (err) {
                            return false;
                        }
                    };
                    const res = await getAccountAgentlessData();
                    return res;
                },
            },
        },
    ];
    AzureEnvironmentAddinRegistry.addTabs(tabs);
    AzureEnvironmentAddinRegistry.addModalCustomContent(agentlessProtectionData);
};
