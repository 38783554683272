import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ERM_CACHE_TAGS, I18nRiskNamespace, ORIGIN_TYPES, VULNERABILITIES_INNER_TAB_INDEX } from '../../../consts';
import { getHttpService } from 'common/interface/services';
import { Loading } from 'common/erm-components/custom/FullSize/Loading';
import { Error } from 'common/erm-components/custom/FullSize/Error';
import { ASSETS_TAB_NAMES } from 'common/module_interface/assets/ProtectedAssets';
import { changeTab } from 'common/module_interface/assets/utils';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { getCustomRulesets, getRulesetVendorName } from '../../Rulesets/Ruleset.actions';
import { AssetTypes } from 'common/assets/assets.const';
import '../../../riskManagement.scss';
import {
    FindingSeverityEnum,
    FindingSeverityServerEnum,
    getSafeFindingSeverityInfo,
} from 'common/consts/FindingSeverity';
import { Icon } from 'common/design-system/components-v2';
import { EventsTableFilters, getEventsTableWidgetService } from 'common/module_interface/events/EventsTableRegistry';
import { EventType } from 'common/module_interface/events/EventsConsts';
import { SysEventType } from 'common/module_interface/events/Events';
import { changeUrlParams } from 'common/utils/http';

enum RemediationType {
    CVES = 'CVEs',
    THREATS_OR_SECRETS = 'ThreatsOrSecrets',
    OVER_PRIVILEGED = 'OverPrivileged',
    MISCONFIGURATIONS = 'Misconfigurations',
}

interface IRemediationActionsRequestViewModel {
    id: string;
    dome9id: string;
    platform: string;
    type: string;
    cloudAccountId: string;
    region?: string;
    size?: number;
}

interface IRemediationActionViewModel {
    severity: FindingSeverityServerEnum;
    type: RemediationType;
    action: string;
    findingId?: string;
}

const RemediationsListItem: React.FC<{
    title: string;
    severity: FindingSeverityEnum;
    onRowClick?: () => void;
}> = ({ title, severity, onRowClick }) => {
    const color = getSafeFindingSeverityInfo(severity).color;
    return (
        <div
            className={`border p-6 flex items-center bg-content ${onRowClick ? 'cursor-pointer' : ''}`}
            onClick={onRowClick}
        >
            <Icon name={'remedy'} customColor={color}></Icon>
            <div className='flex-1 px-6 truncate'>{title}</div>
        </div>
    );
};

async function getTopActions(entity: ICloudEntityData) {
    const asset = entity.protectedAsset;
    const data: IRemediationActionsRequestViewModel = {
        id: entity.entityId,
        dome9id: asset.id,
        cloudAccountId: asset.cloudAccountId,
        platform: asset.platform,
        type: asset.type,
        size: 5,
    };
    return await getHttpService().request<{
        remediationActions: IRemediationActionViewModel[];
    } | null>(
        'erm/remediation/top-actions',
        {
            method: 'POST',
            data,
        },
        {
            cachingConfig: {
                dataAgeLimit: 3 * 60,
                useCache: true,
                tags: [ERM_CACHE_TAGS.TOP_REMEDIATION_ACTIONS],
            },
        },
        (error) => {
            if (error.response?.status === 404) {
                return null;
            }
            throw error;
        },
    );
}

const changeTabToFindings = (platform: string, origin: number, findingId?: string) => {
    const filterFields: EventsTableFilters['fields'] = [
        {
            name: 'origin',
            value: [origin],
        },
        {
            name: 'isExcluded',
            value: ['false'],
        },
    ];
    const assetVendor = getRulesetVendorName(platform);
    if (findingId) {
        getEventsTableWidgetService().openEventDrawer(findingId, SysEventType.elastic);
        return;
    }
    getCustomRulesets().then((customRulesets) => {
        const ruleset = customRulesets.find((ruleset) => getRulesetVendorName(ruleset.platform) === assetVendor);
        if (ruleset) {
            filterFields.push({
                name: 'bundleName',
                value: [ruleset.rulesetName],
            });
        }
        const params = getEventsTableWidgetService().getParamsStringForEventsTab(
            ASSETS_TAB_NAMES.EVENTS_TAB_NAME,
            EventType.POSTURE_FINDINGS,
            { fields: filterFields },
        );
        changeUrlParams(params.map((param) => ({ name: param.paramKey, value: param.newValue })));
    });
};

const getOnRowClick = (entity: ICloudEntityData, remediationAction: IRemediationActionViewModel) => {
    return () => {
        const isLambda = entity.typeByPlatform === AssetTypes.AWS_LAMBDA;
        switch (remediationAction.type) {
            case RemediationType.CVES:
                if (isLambda) {
                    changeTabToFindings(
                        entity.protectedAsset.platform,
                        ORIGIN_TYPES.SERVERLESS_RUNTIME_PROTECTION,
                        remediationAction.findingId,
                    );
                } else {
                    changeTab(
                        ASSETS_TAB_NAMES.VULNERABILITIES_TAB_NAME,
                        [
                            {
                                name: 'type',
                                value: 'PACKAGE',
                            },
                        ],
                        VULNERABILITIES_INNER_TAB_INDEX.REMEDIATION_SUMMARY,
                    );
                }
                break;

            case RemediationType.THREATS_OR_SECRETS:
                if (isLambda) {
                    changeTabToFindings(
                        entity.protectedAsset.platform,
                        ORIGIN_TYPES.SERVERLESS_RUNTIME_PROTECTION,
                        remediationAction.findingId,
                    );
                } else {
                    changeTab(
                        ASSETS_TAB_NAMES.VULNERABILITIES_TAB_NAME,
                        [
                            {
                                name: 'type',
                                value: 'SECRET',
                            },
                            {
                                name: 'type',
                                value: 'THREAT',
                            },
                        ],
                        VULNERABILITIES_INNER_TAB_INDEX.REMEDIATION_SUMMARY,
                    );
                }
                break;

            case RemediationType.OVER_PRIVILEGED:
                changeTabToFindings(entity.protectedAsset.platform, ORIGIN_TYPES.CIEM, remediationAction.findingId);
                break;

            case RemediationType.MISCONFIGURATIONS:
                changeTabToFindings(
                    entity.protectedAsset.platform,
                    ORIGIN_TYPES.COMPLIANCE_ENGINE,
                    remediationAction.findingId,
                );
                break;

            default:
                break;
        }
    };
};

const RemediationsList: React.FC<{ entity: ICloudEntityData }> = ({ entity }) => {
    const { t } = useTranslation(I18nRiskNamespace);
    const [isLoading, setIsLoading] = useState(false);
    const [remediations, setRemediations] = useState<IRemediationActionViewModel[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        setIsLoading(true);
        setErrorMessage(null);
        getTopActions(entity)
            .then((serverRemediations) => {
                setRemediations(serverRemediations?.remediationActions ?? []);
            })
            .catch((err: any) => {
                setErrorMessage(err.message);
                setRemediations([]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [entity]);

    const getRemediationsComponent = useCallback(() => {
        if (errorMessage !== null) {
            return <Error message={errorMessage}></Error>;
        }
        if (remediations.length === 0) {
            return <div>{t('ASSET_DETAILS.TOP_REMEDIATIONS.NO_DATA')}</div>;
        }
        return (
            <div className='space-y-6'>
                {remediations.map((remediationAction, index) => {
                    return (
                        <RemediationsListItem
                            key={index}
                            title={remediationAction.action}
                            onRowClick={getOnRowClick(entity, remediationAction)}
                            severity={getSafeFindingSeverityInfo(remediationAction.severity).key}
                        ></RemediationsListItem>
                    );
                })}
            </div>
        );
    }, [errorMessage, remediations, entity, t]);

    return (
        <div className='top-remediations'>
            <div className='flex items-center gap-5 mx-9 mb-7'>
                <div className='text-lg text-info-darkest opacity-75'>{t('ASSET_DETAILS.TOP_REMEDIATIONS.TITLE')}</div>
                <div className='flex-1 border-dashed border-b'></div>
            </div>
            {isLoading ? (
                <div className='flex items-center mx-9'>
                    <Loading />
                </div>
            ) : (
                <div className='space-y-6 mx-9 mb-9'>{getRemediationsComponent()}</div>
            )}
        </div>
    );
};

export { RemediationsList };
