import { checkConditionsValidation, getOtherExclusionNames } from './IssueExclusionEditor.validations';
import { ISSUE_EXCLUSION_EDITOR_KEY, IssueExclusionItemNames, SimpleItemName } from './IssueExclusionEditor.consts';
import {
    getIssueExclusionSubmitFailedNotification,
    getIssueExclusionSubmitSuccessNotification,
    saveIssueExclusion,
} from './IssueExclusionEditor.datasource';
import { ermTrans } from '../../../RiskManagement.utils';
import { IIssueExclusionProps } from '../IssueExclusions.interface';
import { ISSUE_EXCLUSIONS_PAGE_TABLE_ID } from '../IssueExclusions.consts';
import {
    commonDividerItemProps,
    commonInputItemProps,
    commonLabelItemProps,
    commonLazySelectFieldItemProps,
    commonMultiSelectFieldItemProps,
    commonOrgUnitsFieldItemProps,
    commonRadioItemProps,
    commonSingleDateFieldItemProps,
    commonTagsFieldItemProps,
    commonTextAreaItemProps,
    FormItemType,
} from 'common/erm-components/custom/CustomForm/CustomForm.consts';
import {
    ICustomFormProps,
    IItem,
    IItemChanges,
    IItemsMap,
    IOrgUnitsItem,
    IRadioItem,
} from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import {
    getIssueExpirationDateLabel,
    getNewIssueExpirationDateState,
    getNewOrgIdsState,
    severityLevelsToKeys,
} from './IssueExclusionEditor.values';
import { IIssue } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { convertDateToValue, convertTagsToValues } from 'common/erm-components/custom/CustomForm/CustomForm.values';
import {
    getAllEntityTypeOptions,
    getAllEnvironmentOptions,
    getAllRulesOptions,
    getIssueSeverityOptions,
    getEntityNamesOptions,
    getOrgUnitsRoot,
} from '../../../RiskManagement.options';
import { createEmptyIssueExclusionUpdateProps } from '../IssueExclusions.utils';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { isPassedDate } from 'common/erm-components/utils/dates';
import { IOrganizationalUnit } from 'common/interface/data_services';

const getOrgEnvRadio = async (
    orgUnitsRoot: IOrganizationalUnit,
    issueExclusion?: IIssueExclusionProps,
): Promise<IRadioItem> => {
    return {
        ...commonRadioItemProps,
        itemType: FormItemType.radio,
        name: IssueExclusionItemNames.orgEnvRadio,
        state: {},
        options: [
            {
                name: IssueExclusionItemNames.orgUnitsRadioOption,
                subItems: [
                    {
                        ...commonOrgUnitsFieldItemProps,
                        name: IssueExclusionItemNames.orgUnits,
                        getNewItemState: (item: IOrgUnitsItem, changeDetails: IItemChanges, itemsMap: IItemsMap) =>
                            getNewOrgIdsState(item, changeDetails, itemsMap, orgUnitsRoot),
                        state: {
                            label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.ORGANIZATIONAL_UNIT.TITLE'),
                            orgUnitsRoot,
                            value: issueExclusion?.orgUnitIds,
                            tooltip: ermTrans('ISSUE_EXCLUSIONS.EDITOR.TOOLTIP.ORG_UNITS'),
                        },
                    },
                ],
            },
            {
                name: IssueExclusionItemNames.environmentsRadioOption,
                subItems: [
                    {
                        ...commonMultiSelectFieldItemProps,
                        name: IssueExclusionItemNames.environments,
                        state: {
                            label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.ENVIRONMENT'),
                            options: await getAllEnvironmentOptions(),
                            value: issueExclusion?.envIds,
                            tooltip: ermTrans('ISSUE_EXCLUSIONS.EDITOR.TOOLTIP.ENVIRONMENTS'),
                        },
                    },
                ],
            },
        ],
    };
};

const getRuleRadio = async (issueExclusion?: IIssueExclusionProps): Promise<IRadioItem> => ({
    ...commonRadioItemProps,
    itemType: FormItemType.radio,
    name: IssueExclusionItemNames.rulesRadio,
    state: {},
    options: [
        {
            name: IssueExclusionItemNames.rulesRadioOption,
            subItems: [
                {
                    ...commonMultiSelectFieldItemProps,
                    name: IssueExclusionItemNames.rules,
                    state: {
                        label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.RULE'),
                        options: await getAllRulesOptions(),
                        value: issueExclusion?.ruleIds,
                        tooltip: ermTrans('ISSUE_EXCLUSIONS.EDITOR.TOOLTIP.RULES'),
                    },
                },
            ],
        },
        {
            name: IssueExclusionItemNames.severitiesRadioOption,
            subItems: [
                {
                    ...commonMultiSelectFieldItemProps,
                    name: IssueExclusionItemNames.severities,
                    state: {
                        label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.SEVERITY'),
                        options: getIssueSeverityOptions(),
                        value: severityLevelsToKeys(issueExclusion?.severities),
                        tooltip: ermTrans('ISSUE_EXCLUSIONS.EDITOR.TOOLTIP.SEVERITIES'),
                    },
                },
            ],
        },
    ],
});

const getEntityRadio = async (issueExclusion?: IIssueExclusionProps): Promise<IRadioItem> => ({
    ...commonRadioItemProps,
    itemType: FormItemType.radio,
    name: IssueExclusionItemNames.entityRadio,
    state: {},
    options: [
        {
            name: IssueExclusionItemNames.entityNamesRadioOption,
            subItems: [
                {
                    ...commonLazySelectFieldItemProps,
                    name: IssueExclusionItemNames.entityNames,
                    getLazyOptions: getEntityNamesOptions,
                    state: {
                        label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.ENTITY_NAME'),
                        value: issueExclusion?.entityNames,
                        tooltip: ermTrans('ISSUE_EXCLUSIONS.EDITOR.TOOLTIP.ENTITY_NAMES'),
                    },
                },
            ],
        },
        {
            name: IssueExclusionItemNames.entityIdRadioOption,
            subItems: [
                {
                    ...commonInputItemProps,
                    name: IssueExclusionItemNames.entityId,
                    state: {
                        label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.ENTITY_ID'),
                        value: issueExclusion?.entityId,
                        tooltip: ermTrans('ISSUE_EXCLUSIONS.EDITOR.TOOLTIP.ENTITY_ID'),
                    },
                },
            ],
        },
    ],
});

const getItems = async (issueExclusion?: IIssueExclusionProps): Promise<IItem[]> => {
    const expired = isPassedDate(issueExclusion?.expirationDate);
    const orgUnitsRoot: IOrganizationalUnit = await getOrgUnitsRoot();
    return [
        {
            ...commonInputItemProps,
            name: IssueExclusionItemNames.name,
            state: {
                isRequired: true,
                charsLimit: 100,
                label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.NAME'),
                forbiddenOtherValues: await getOtherExclusionNames(issueExclusion),
                value: issueExclusion?.name,
            },
        },
        {
            ...commonTextAreaItemProps,
            name: IssueExclusionItemNames.description,
            state: {
                charsLimit: 200,
                label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.DESCRIPTION'),
                value: issueExclusion?.description,
            },
        },
        {
            ...commonDividerItemProps,
            name: SimpleItemName.conditionsAreaDivider,
            state: {},
        },
        {
            ...commonLabelItemProps,
            name: SimpleItemName.conditionsAreaLabel,
            checkValidation: (_: IItem, itemsMap: IItemsMap) => checkConditionsValidation(itemsMap, orgUnitsRoot),
            state: {
                label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.CONDITIONS'),
                isRequired: true,
            },
        },
        await getRuleRadio(issueExclusion),
        await getOrgEnvRadio(orgUnitsRoot, issueExclusion),
        await getEntityRadio(issueExclusion),
        {
            ...commonMultiSelectFieldItemProps,
            name: IssueExclusionItemNames.entityTypes,
            state: {
                label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.ENTITY_TYPE'),
                options: await getAllEntityTypeOptions(),
                value: issueExclusion?.entityTypes,
                tooltip: ermTrans('ISSUE_EXCLUSIONS.EDITOR.TOOLTIP.ENTITY_TYPE'),
            },
        },
        {
            ...commonSingleDateFieldItemProps,
            name: IssueExclusionItemNames.expirationDate,
            getNewItemState: getNewIssueExpirationDateState,
            state: {
                label: getIssueExpirationDateLabel(expired),
                value: convertDateToValue(issueExclusion?.expirationDate),
                labelProps: { color: expired ? 'alert' : undefined },
                tooltip: ermTrans('ISSUE_EXCLUSIONS.EDITOR.TOOLTIP.EXPIRATION_DATE'),
            },
        },
        {
            ...commonTagsFieldItemProps,
            name: IssueExclusionItemNames.tags,
            state: {
                label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.ENTITY_TAGS'),
                value: convertTagsToValues(issueExclusion?.tags),
            },
        },
    ];
};

export const getIssueExclusionFormProps = (issueExclusion?: IIssueExclusionProps): ICustomFormProps => {
    return {
        getItems,
        submitData: saveIssueExclusion,
        getSubmitSuccessNotification: getIssueExclusionSubmitSuccessNotification,
        getSubmitFailedNotification: getIssueExclusionSubmitFailedNotification,
        title: issueExclusion?.id ? issueExclusion.name : ermTrans('ISSUE_EXCLUSIONS.EDITOR.ADD_ISSUE_EXCLUSION'),
        iconProps: { name: 'exclude' },
        minDialogHeight: 300,
        eventHandlerId: ISSUE_EXCLUSIONS_PAGE_TABLE_ID,
    };
};

export const showIssueExclusionForm = (issueExclusion?: IIssueExclusionProps) => {
    showDrawer(ISSUE_EXCLUSION_EDITOR_KEY, issueExclusion);
};

export const getExclusionUpdateProps = (issue: IIssue): IIssueExclusionProps => {
    return {
        ...createEmptyIssueExclusionUpdateProps(),
        name: '',
        ruleIds: issue.ruleId ? [issue.ruleId] : [],
        entityId: issue.entityExternalId,
        description: ermTrans('ISSUES.TABLE.ACTIONS.EXCLUDE.EXCLUSION_DESCRIPTION', { issueId: issue.id }),
        envIds: issue.environmentId ? [issue.environmentId] : [],
    };
};

export const excludeIssue = (issue: IIssue) => {
    const issueExclusionProps: IIssueExclusionProps = getExclusionUpdateProps(issue);
    showIssueExclusionForm(issueExclusionProps);
};
