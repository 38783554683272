import React, { lazy } from 'react';
import initializeFindingsTable from './FindingsTable/FindingsTable.initialize';
import { initializeFindingOverview } from './FindingOverview/FindingOverview.initialize';
import initializeCloudBots from './CloudBots/CloudBots.initialize';
import { EventsTableRegistry } from 'common/module_interface/events/EventsTableRegistry';
import CdrEventsPage from './CdrEvents/CdrEventsPage';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from '../initialize.i18n';
import { EVENTS_TABLE_ID, EventType } from 'common/module_interface/events/EventsConsts';
import { EvidencesRegistry } from 'common/module_interface/RiskManagement/issues/EvidencesRegistry';
import { IIssue, SystemLabels } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import IssueCDREvents from './IssueCDREvents/IssueCDREvents';
import CiemEventsPage from './CiemEvents/CiemEventsPage';
import { EventsModalProps, EventsModalsRegistry } from 'common/module_interface/events/EventsModalsRegistry';
import FixItAction from './FindingsTable/Actions/FixItAction/FixItAction';
import { changeUrlParam } from 'common/utils/http';
import { FixItModal } from './FindingsTable/Modals/FixItModal/FixItModal';
import { CloudBotStatusModal } from './FindingsTable/Modals/CloudBotStatusModal/CloudBotStatusModal';
import { EventsDrawerAddingBase, EventsDrawerRegistry } from 'common/module_interface/events/EventsDrawerRegistry';
import { Addin } from 'common/extensibility/AddinRegistry';
import { Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import { IFindingModelOriginString } from 'common/components/Findings/Findings.interface';
import { getKustoEventService } from 'common/module_interface/intelligence/intelligence';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { isAfEnabled } from 'common/utils/debugUtils';
import { ExclusionRegistry } from 'common/module_interface/exclusion/exclusionRegistry';
import { IEvent } from 'common/module_interface/events/Events';
import { ExclusionModuleType } from 'common/interface/exclusion';
import CdrElasticExclusion from './FindingsTable/Modals/CdrExclusion/CdrElasticExclusion';

const initializeAllFindingsTable = () => {
    const t = i18n.getFixedT(null, i18nIntelligenceNamespace);
    EventsTableRegistry.addTabs([
        {
            id: EventType.CIEM_KUSTO,
            position: 15,
            content: {
                type: 'componentTab',
                id: EventType.CIEM_KUSTO,
                label: 'CIEM Kusto',
                component: CiemEventsPage,
            },
            permission: () => isAfEnabled('ciem-kusto-table'),
        },
    ]);
    EventsTableRegistry.addTabs([
        {
            id: EventType.CDR,
            position: 20,
            content: {
                type: 'componentTab',
                id: EventType.CDR,
                label: t('NAVIGATION_MENU.THREAT.TITLE'),
                component: CdrEventsPage,
            },
        },
    ]);

    EventsTableRegistry.addActions([
        {
            id: 'FixIt',
            content: {
                id: 'FixIt',
                position: 30,
                name: '', // Not relevant when using customComponent
                callback: () => null,
                customComponent: (rows) => (
                    <FixItAction
                        openDialog={(dialogType) => changeUrlParam('modalType', dialogType)}
                        selectedRows={rows}
                        tableId={EVENTS_TABLE_ID}
                    />
                ),
            },
        },
    ]);
    // This is temporary disabled untill 'Fix It' action will support a sync between the modal and the table
    // EventsDrawerRegistry.addCustomActions([
    //     {
    //         id: 'FixIt',
    //         content: {
    //             id: 'FixIt',
    //             getValue: (event) => (
    //                 <FixItAction
    //                     openDialog={(dialogType) => changeUrlParam('modalType', dialogType)}
    //                     selectedRows={[event]}
    //                     tableId={EVENTS_DRAWER_ID}
    //                     showAsListItem
    //                 />
    //             ),
    //         }
    //     },
    // ]);

    const FixItModalWithRefresh: React.FC<EventsModalProps> = (props: EventsModalProps) => {
        return <FixItModal {...props} refreshTableData={props.requestNewData} />;
    };

    EventsModalsRegistry.addModals([
        {
            id: 'FixIt',
            content: {
                id: 'FixIt',
                modalComponent: FixItModalWithRefresh,
            },
        },
    ]);

    const CloudBotStatusModalWithRefresh: React.FC<EventsModalProps> = (props: EventsModalProps) => {
        return <CloudBotStatusModal {...props} refreshTableData={props.requestNewData} />;
    };

    EventsModalsRegistry.addModals([
        {
            id: 'BotStatusCheck',
            content: {
                id: 'BotStatusCheck',
                modalComponent: CloudBotStatusModalWithRefresh,
            },
        },
    ]);
};

const initializeIssueEvidenceCDREvents = () => {
    EvidencesRegistry.addItem(16, {
        id: 'CDREvents',
        title: i18n.t('ISSUE_CDR_EVENTS.TITLE', { ns: i18nIntelligenceNamespace }),
        isRelevant: (issue: IIssue) => issue.systemLabels.includes(SystemLabels.SECURITY_EVENTS),
        component: IssueCDREvents,
    });
};

const drawerTabs: () => Addin<EventsDrawerAddingBase<Tab>>[] = () => {
    return [
        {
            id: 'cdrEnrichment',
            position: 20,
            content: {
                id: 'cdrEnrichment',
                getValue: async (event) => {
                    const kustoEvent: IKustoEvent = await getKustoEventService().getKustoEventById(event.findingKey);
                    return {
                        name: 'cdrEnrichment',
                        label: 'CDR Enrichment ',
                        isReactTab: true,
                        reactTab: {
                            component: lazy(() => import('./AllEventsElastic/CDREnrichmentOverview')),
                            componentProps: { event: kustoEvent },
                            overflowHidden: false,
                        },
                    };
                },
                isRelevant: (event) =>
                    event.origin === IFindingModelOriginString.Intelligence ||
                    event.origin === IFindingModelOriginString.Magellan,
            },
        },
    ];
};

const initializeAllEventsExclusion = () => {
    ExclusionRegistry.addExcludeModals([
        {
            id: ExclusionModuleType.CDR,
            content: {
                id: ExclusionModuleType.CDR,
                component: CdrElasticExclusion,
            },
        },
    ]);
    ExclusionRegistry.addExcludeEventEnablers([
        {
            id: ExclusionModuleType.CDR,
            content: {
                enabler: (event: IEvent) => {
                    return event.origin === IFindingModelOriginString.Magellan;
                },
            },
        },
    ]);
};

function initializeAllEventsDrawer() {
    EventsDrawerRegistry.addTabs(drawerTabs());
}

export default function initializeFindings() {
    initializeFindingsTable();
    initializeFindingOverview();
    initializeCloudBots();
    initializeAllFindingsTable();
    initializeIssueEvidenceCDREvents();
    initializeAllEventsDrawer();
    initializeAllEventsExclusion();
}
