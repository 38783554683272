import React, { useEffect, useState } from 'react';
import { extractFilterFieldsToNewModel, initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { FILTER_PANEL_QUERY_NAMES, FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import FilterPanelPlaceholder from 'common/components/ProtectedAssets/DynamicProtectedAssetsTable/components/FilterPanelPlaceholder';
import { getHttpService, ICustomzationResponse } from 'common/interface/services';
import { filterDefs } from '../../definitions/FilterDefinitions';

interface IWebAppFilterParams {
    filter?: {
        fields?: IWebAppFilter[];
        freeTextPhrase?: string;
        tags?: IWebAppKeyValue[];
    };
}

interface IWebAppFilter {
    name: string;
    value: string;
}

interface IWebAppKeyValue {
    key: string;
    value: string;
}

export interface Filters {
    fields?: { value: string; name: string }[];
    freeTextPhrase?: string;
}

export interface RulesTableFilterPanelProps {
    onFilterChange: (filters: Filters) => void;
    aggregations: Aggregations;
}

export interface Aggregations {
    [key: string]: { value: any; count: number }[];
}

const RulesTableFilterPanel: React.FC<RulesTableFilterPanelProps> = (props) => {
    const { onFilterChange, aggregations } = props;

    const [filterElement, setFilterElement] = useState(<div></div>);
    const [isLoading, setIsLoading] = useState(true);

    const savedFiltersComponentName = 'rules_cis_index_favs';
    const recentlyUsedComponentName = 'rules_cis_index_recent';

    const FILTER_PANEL_ID = 'cis-rule-filter-panel-id';

    useEffect(() => {
        async function getAggregations(filtersValues?: Filters): Promise<Aggregations> {
            if (filtersValues) {
                onFilterChange(filtersValues || {});
            }

            return aggregations;
        }

        const extractWebAppFiltersFromQueryParams = () => {
            const queryParams = new URLSearchParams(window.location.search);
            const webAppQueryParams = queryParams.get(FILTER_PANEL_QUERY_NAMES.QUERY);

            if (webAppQueryParams) {
                const urlFilters: IWebAppFilterParams = JSON.parse(webAppQueryParams);
                const filter = urlFilters?.filter;
                const fields = filter?.fields ? extractFilterFieldsToNewModel(filter.fields) : {};
                const freeTextPhrase = filter?.freeTextPhrase
                    ? { [FILTERS_KEYS.FREE_TEXT]: filter?.freeTextPhrase }
                    : {};
                const tags = filter?.tags ? { [FILTERS_KEYS.TAGS]: filter.tags } : [];

                return { ...fields, ...freeTextPhrase, ...tags };
            }
        };

        async function renderFilterPanel() {
            const filtersInitialData = await getFiltersInitialData();
            const webAppQueryParams = extractWebAppFiltersFromQueryParams();
            const aggregations = await getAggregations();
            const filterPanelElementsList = filterDefs({
                aggregations,
                filtersInitialData,
                savedFiltersComponentName,
                recentlyUsedComponentName,
            }).map((filterDef) => filterDef());
            const filterPanel = initFilterPanel({
                filterPanelElementsList,
                getAggregations: getAggregations,
                shouldBuildObjectForAPI: true,
                webAppQueryParams: webAppQueryParams,
                filterId: FILTER_PANEL_ID,
            });
            setFilterElement(filterPanel);
        }

        async function initPage() {
            await renderFilterPanel();
            setIsLoading(false);
        }

        initPage();
    }, [aggregations, onFilterChange]);

    const getFiltersInitialData = async () => {
        const savedFiltersPromise = getHttpService().request<ICustomzationResponse<any>>(
            `customization?component=${savedFiltersComponentName}`,
            { method: 'GET' },
            { cachingConfig: { useCache: false } },
        );
        const recentlyUsedPromise = getHttpService().request<ICustomzationResponse<any>>(
            `customization?component=${recentlyUsedComponentName}`,
            { method: 'GET' },
            { cachingConfig: { useCache: false } },
        );
        return Promise.all([savedFiltersPromise, recentlyUsedPromise]).then(([savedFilters, recentlyUsed]) => {
            return {
                savedFilters,
                recentlyUsed,
            };
        });
    };

    if (isLoading) {
        return <FilterPanelPlaceholder />;
    }

    return filterElement;
};

export default RulesTableFilterPanel;
