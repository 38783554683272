import { ICellRendererParams, IRowNode } from 'ag-grid-community';
import { IIdenticalIdentitiesTreeNode } from 'common/module_interface/identity/IdenticalRoles.interfaces';
import React, { useCallback, useEffect, useState } from 'react';
import { Icon, Typography } from 'common/design-system/components-v2';
import EntityTypeCellRenderer from 'common/components/ag-grid/Renderers/EntityTypeCellRenderer';
import { IdentitiesGroupCellRendererStyled as Styled } from './IdentitiesGroupCellRenderer.styled';
import { RowNodeEvent } from 'ag-grid-community/dist/lib/interfaces/iRowNode';

export const IdentitiesGroupCellRenderer: React.FC<ICellRendererParams<IIdenticalIdentitiesTreeNode>> = (params) => {
    const { node, value } = params;
    const [expanded, setExpanded] = useState(node.expanded);

    useEffect(() => {
        const expandListener = (event: RowNodeEvent<IIdenticalIdentitiesTreeNode>) => setExpanded(event.node.expanded);
        node.addEventListener('expandedChanged', expandListener);
        return () => node.removeEventListener('expandedChanged', expandListener);
    }, [node]);

    const canExpandNode = useCallback((node: IRowNode<IIdenticalIdentitiesTreeNode>) => node.level < 2, [node]);

    const toggleNodeExpansion = useCallback(() => node.setExpanded(!node.expanded), [node]);

    return (
        <Styled.TreeNodeWrapper spacing={2} direction={'row'} alignItems={'center'} level={node.level}>
            {canExpandNode(node) && (
                <Icon
                    name={expanded ? 'chevronDown' : 'chevronRight'}
                    onClick={toggleNodeExpansion}
                    cursor={'pointer'}
                />
            )}
            {node.level === 0 && (
                <>
                    <Icon name='identityCard' />
                    <Typography>{value}</Typography>
                </>
            )}
            {node.level === 1 && <EntityTypeCellRenderer {...params} />}
        </Styled.TreeNodeWrapper>
    );
};
