import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IAutoActionIntegration } from '../../../AutoActions.interface';
import { I18nRiskNamespace } from '../../../../../consts';
import { EditorStyled } from '../../AutoActionEditor.styled';
import { INTEGRATIONS_PAGE_RELATIVE_URL, MAX_SELECTED_INTEGRATIONS } from '../../AutoActionEditor.consts';
import { SelectV2, Stack } from 'common/design-system/components-v2';
import Link from 'common/erm-components/basic/Link/Link';
import { IThenProps } from '../../AutoActionEditor.interface';
import { getIntegrationIdsErrorMsg } from '../../AutoActionEditor.utils';
import { IntegrationsDefinition } from 'common/module_interface/settings/integrations/consts';
import { errorPropsHasMessages, getSupportedIntegrationDefs } from '../../../AutoActions.utils';
import Button from 'common/design-system/components-v2/Button';
import Chip from 'common/design-system/components-v2/Chip';
import { EditorInfoCard } from '../../components/EditorInfoCard';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { useIsReadOnlyAutoActions } from '../../../useIsAutoActionReadonly';

interface IThenErrors {
    integrationIdsErrorMsg?: string;
}

export const AutoActionEditorThen: React.FC<{
    allIntegrations: IAutoActionIntegration[];
    refreshIntegrations: () => void;
    exposeErrors: boolean;
    thenProps: IThenProps;
    setThenProps: (props: IThenProps) => void;
}> = ({ allIntegrations, refreshIntegrations, exposeErrors, thenProps, setThenProps }) => {
    const isReadOnly = useIsReadOnlyAutoActions();
    const errorsRef = useRef<IThenErrors>({});
    const integrationDefs: IntegrationsDefinition[] = useMemo(() => getSupportedIntegrationDefs(), []);
    const integrationIdsRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation(I18nRiskNamespace);

    const updateErrors = useCallback(
        (theThenProps: IThenProps) => {
            errorsRef.current.integrationIdsErrorMsg = getIntegrationIdsErrorMsg(theThenProps.integrationIds);
            thenProps.errorElementRefs =
                errorsRef.current.integrationIdsErrorMsg && integrationIdsRef.current
                    ? [integrationIdsRef.current]
                    : [];
            theThenProps.hasErrors = errorPropsHasMessages(errorsRef.current);
        },
        [thenProps],
    );

    const onIntegrationIdsChange = useCallback(
        (integrationIds: string[]) => {
            const newProps = {
                ...thenProps,
                integrationIds,
            };
            updateErrors(newProps);
            setThenProps(newProps);
        },
        [setThenProps, thenProps, updateErrors],
    );

    const integrationOptions: SelectOption[] = useMemo(() => {
        return allIntegrations.map((integration) => {
            return {
                label: integration.name,
                value: integration.id,
                labelProps: {
                    leadingIconProps: integration.icon ? { vendorNameOrPath: integration.icon } : undefined,
                },
            };
        });
    }, [allIntegrations]);

    const isOptionDisabled = useCallback((option: SelectOption, selectedOptions: SelectOption[]) => {
        return (
            selectedOptions.length >= MAX_SELECTED_INTEGRATIONS &&
            !selectedOptions.some((anOption) => anOption.value === option?.value)
        );
    }, []);

    const getSelectionCountText = useCallback(() => {
        const count = thenProps.integrationIds.length;
        const max = MAX_SELECTED_INTEGRATIONS;
        if (count >= MAX_SELECTED_INTEGRATIONS) {
            return t('AUTO_ACTIONS.EDITOR.THEN.INTEGRATIONS_COUNTER_LIMIT_REACHED', { count, max });
        } else {
            return t('AUTO_ACTIONS.EDITOR.THEN.INTEGRATIONS_COUNTER', { count, max });
        }
    }, [t, thenProps.integrationIds.length]);

    const isError: boolean = useMemo(() => !!(exposeErrors && thenProps.hasErrors), [exposeErrors, thenProps]);
    const helperText: string | undefined = useMemo(
        () => (isError ? errorsRef.current.integrationIdsErrorMsg : undefined),
        [isError],
    );

    useEffect(() => {
        updateErrors(thenProps);
    }, [updateErrors, thenProps]);

    return (
        <EditorStyled.PanelTopDiv>
            <EditorStyled.PanelLeftDiv>
                <EditorStyled.CategoryElementWrapper>
                    <EditorStyled.CategoryChipDiv label={t('AUTO_ACTIONS.EDITOR.THEN.THEN')} />
                </EditorStyled.CategoryElementWrapper>
            </EditorStyled.PanelLeftDiv>
            <EditorStyled.PanelRightDiv>
                <EditorStyled.CategoryElementWrapper>
                    <EditorStyled.LabelDiv required text={t('AUTO_ACTIONS.EDITOR.THEN.THEN_PREFIX')} />
                </EditorStyled.CategoryElementWrapper>
                <Stack direction={'row'} spacing={1} alignItems={'flex-start'}>
                    <EditorStyled.SelectWrapperDiv>
                        <SelectV2
                            fullWidth
                            options={integrationOptions}
                            onChange={(values: string[]) => onIntegrationIdsChange(values)}
                            isMulti
                            value={thenProps.integrationIds}
                            clearable
                            placeholder={
                                isReadOnly ? undefined : t('AUTO_ACTIONS.EDITOR.THEN.INTEGRATION_SELECTOR_PLACEHOLDER')
                            }
                            isError={isError}
                            helperText={isError ? helperText : undefined}
                            isOptionDisabled={isOptionDisabled}
                            ref={integrationIdsRef}
                            readOnly={isReadOnly}
                        />
                    </EditorStyled.SelectWrapperDiv>
                    {!isReadOnly && (
                        <Button
                            tooltip={t('AUTO_ACTIONS.EDITOR.THEN.ACTIONS.REFRESH_TOOLTIP')}
                            variant={'text'}
                            iconProps={{ name: 'refresh' }}
                            onClick={() => refreshIntegrations()}
                        />
                    )}
                </Stack>
                {thenProps.integrationIds.length > 0 &&
                    thenProps.integrationIds.length <= MAX_SELECTED_INTEGRATIONS && (
                        <EditorStyled.LabelDiv text={getSelectionCountText()} />
                    )}
                <EditorInfoCard
                    title={t('AUTO_ACTIONS.EDITOR.THEN.NOTE_TITLE')}
                    text={t('AUTO_ACTIONS.EDITOR.THEN.NOTE_MSG')}
                >
                    <Stack direction={'column'}>
                        <Stack direction={'column'} spacing={2} fullWidth={true}>
                            <Stack direction={'row'} spacing={4} flexWrap>
                                {integrationDefs.map((def) => (
                                    <Chip
                                        label={def.title}
                                        leadingIconProps={{ vendorNameOrPath: def.icon }}
                                        key={def.title}
                                        color={'normal'}
                                        withBorder
                                        disableInteraction
                                    />
                                ))}
                            </Stack>
                            {!isReadOnly && (
                                <Stack direction={'column'} fullWidth={true} alignItems={'flex-end'}>
                                    <Link
                                        externalUrl={INTEGRATIONS_PAGE_RELATIVE_URL}
                                        textProps={{ text: t('AUTO_ACTIONS.EDITOR.THEN.CREATE_NEW_INTEGRATION') }}
                                        showExternalIcon
                                    />
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                </EditorInfoCard>
            </EditorStyled.PanelRightDiv>
        </EditorStyled.PanelTopDiv>
    );
};
