import React from 'react';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';
import { ComponentOverflow, Typography, Message } from 'common/design-system/components-v2';
import EntityHeader from 'common/design-system/components-v2/EntityHeader';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { CveOverview } from 'modules/workloads/services/cveDrawerRegistry/cveDrawerRegistry.types';
import { getLoggerService } from 'common/interface/services';
import { floatToPercentage } from 'modules/workloads/utils/floatToPercentage';

const UpperPanel: React.FC<{ overview: CveOverview }> = ({ overview }) => {
    const { t } = useTranslation(getK8sNamespace('cve-explorer'));
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const { relatedIds, lastModified, urls, sourceUrl, source, id, epssScore } = overview.cve;

    const relatedCves = relatedIds.map((relatedCve) => (
        <Typography style={{ whiteSpace: 'nowrap' }} key={`related-${relatedCve}`}>
            {relatedCve}
        </Typography>
    ));

    const infoBlocks: Array<BlockInfoProps> = [
        ...(relatedIds.length > 0
            ? [
                  {
                      title: t('cveDrawer.upperPanel.related'),
                      info: <ComponentOverflow components={relatedCves} commaSeperated />,
                  },
              ]
            : []),
        ...(lastModified
            ? [{ title: t('cveDrawer.upperPanel.updated'), info: lastModified, copyable: !!lastModified }]
            : []),
        ...(urls?.length > 0 ? [{ title: t('cveDrawer.upperPanel.references'), info: urls?.length.toString() }] : []),
        ...(sourceUrl
            ? [
                  {
                      title: t('cveDrawer.upperPanel.cveSource'),
                      info: `${source}: ${id}`,
                      externalUrl: sourceUrl,
                      copyable: true,
                  },
              ]
            : []),
        ...(epssScore
            ? [{ title: t('cveDrawer.upperPanel.epssScore'), info: floatToPercentage(epssScore), copyable: true }]
            : []),
    ];

    const onConfirm = async () => {
        await getLoggerService().warning(
            {
                title: t('cveDrawer.upperPanel.reportedFalsePositive.title'),
                cveId: id,
            },
            '',
        );
    };

    return (
        <>
            <Message
                variant={'warning'}
                isOpen={isOpen}
                width={'md'}
                onCancel={() => setIsOpen(false)}
                onClose={() => setIsOpen(false)}
                title={t('cveDrawer.upperPanel.reportedFalsePositive.messageTitle')}
                onConfirm={onConfirm}
            >
                <Typography>{t('cveDrawer.upperPanel.reportedFalsePositive.messageBody', { id: id })}</Typography>
            </Message>
            <EntityHeader title={''} blocks={infoBlocks} disableShowMore />
        </>
    );
};

export default UpperPanel;
