import { Addin } from 'common/extensibility/AddinRegistry';
import { IProtectedAssetDetailProvider } from 'common/module_interface/assets/ProtectedAssets';
import { InfoPaneFieldProps } from 'common/components/InfoPane/InfoPane';
import { AssetFieldsMap } from 'common/module_interface/assets/AssetsConsts';
import { buildPath, globalAddinContainer } from 'common/extensibility/AddinContainer';

export const PATH_DRAWER_INFO = buildPath('vulnerability', 'drawer');
const PATH_DRAWER_INFO_FIELDS_FIELDS = buildPath(PATH_DRAWER_INFO, 'fields');
export const BASE_IMAGE_INFO_FIELDS = 'base-image';

export const DrawerInfoFieldsBaseImage = {
    addFieldAddins: (fieldAddins: Addin<IProtectedAssetDetailProvider<InfoPaneFieldProps>>[], panelId: string) => {
        const fullPath = buildPath(PATH_DRAWER_INFO_FIELDS_FIELDS, panelId);
        globalAddinContainer.add<IProtectedAssetDetailProvider<InfoPaneFieldProps>>(fullPath, fieldAddins);
    },

    getFields: (panelId: string): IProtectedAssetDetailProvider<InfoPaneFieldProps>[] => {
        const fullPath = buildPath(PATH_DRAWER_INFO_FIELDS_FIELDS, panelId);
        return globalAddinContainer.get<IProtectedAssetDetailProvider<InfoPaneFieldProps>>(fullPath);
    },
};

const initializeDrawerInfoFields = () => {
    const fields: Addin<IProtectedAssetDetailProvider<InfoPaneFieldProps>>[] = [
        {
            id: 'name',
            position: 10,
            content: AssetFieldsMap.nameField,
        },
        {
            id: 'type',
            position: 20,
            content: AssetFieldsMap.typeField,
        },
        {
            id: 'id',
            position: 20,
            content: AssetFieldsMap.idField,
        },
        {
            id: 'environment',
            position: 20,
            content: AssetFieldsMap.environmentField,
        },
    ];
    DrawerInfoFieldsBaseImage.addFieldAddins(fields, BASE_IMAGE_INFO_FIELDS);
};

export { initializeDrawerInfoFields };
