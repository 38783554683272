import { changeUrl } from 'common/utils/http';
import { isEmptyString } from 'common/utils/helpFunctions';
import { FINDINGS_ORIGINS } from 'common/module_interface/events/EventsConsts';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { LogType } from 'common/module_interface/intelligence/Logs/LogSources';
import { FindingOverviewPanelsIds } from '../../Findings.const';

export function setEntityInUrl(entityId: string | undefined, entityName: string | undefined) {
    const querystring = new URLSearchParams(window.location.search);
    if (entityId && entityName) {
        querystring.set('entityId', entityId);
        querystring.set('entityName', entityName);
    } else {
        querystring.delete('entityId');
        querystring.delete('entityName');
    }
    changeUrl(window.location.pathname + `?${querystring.toString()}`);
}

const severityPriority: Record<string, number> = {
    critical: 0,
    high: 1,
    medium: 2,
    low: 3,
    informational: 4,
};

export function severityComparator(valueA: string, valueB: string) {
    if (!valueA || !valueB) {
        return 0;
    }
    return severityPriority[valueA.toLowerCase() as string] - severityPriority[valueB.toLowerCase() as string];
}
export function getIntelligenceSource(finding: IKustoEvent) {
    if (!finding?.additionalFieldsMap?.networkActivity && !finding?.additionalFieldsMap?.accountActivity)
        return undefined;
    return finding.additionalFieldsMap?.networkActivity ? LogType.NETWORK_ACTIVITY : LogType.ACCOUNT_ACTIVITY;
}

export function isPanelRelevant(finding: IKustoEvent, panel: string) {
    if (!finding || !panel || finding.origin !== FINDINGS_ORIGINS.INTELLIGENCE) return false;

    switch (panel) {
        case FindingOverviewPanelsIds.VISUALIZATION_PANEL:
            return !!finding?.additionalFieldsMap?.networkActivity || !!finding?.additionalFieldsMap?.accountActivity;

        case FindingOverviewPanelsIds.GSL_PANEL:
            return !isEmptyString(finding.ruleLogic);

        default:
            return false;
    }
}
