import React from 'react';
import style from 'styled-components';
import { VulnerabilitiesProps } from './Vulnerabilities.types';
import FindingsRow from './FindingsRow/FindingsRow';
import EmptyState from '../EmptyState/EmptyState';
import { Stack } from 'common/design-system/components-v2';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';

const VulnerabilitiesWrapper = style(Stack)`
    height: 170px;
    overflow-y: auto;
`;

const Vulnerabilities: React.FC<VulnerabilitiesProps> = ({
    VulnerabilityObject,
    onClickGoVulnerability,
    isEmptyState,
    isLoading,
}) => {
    if (isLoading) {
        return (
            <VulnerabilitiesWrapper alignItems={'center'}>
                <CloudAnimationLoader size={'medium'} />
            </VulnerabilitiesWrapper>
        );
    }
    return (
        <VulnerabilitiesWrapper fullWidth spacing={4}>
            {isEmptyState ? (
                <EmptyState />
            ) : (
                VulnerabilityObject &&
                Object.keys(VulnerabilityObject).map((vulnerability) => (
                    <FindingsRow
                        onClickGoVulnerability={onClickGoVulnerability}
                        key={vulnerability}
                        title={VulnerabilityObject[vulnerability].title}
                        type={vulnerability}
                        totalFixable={VulnerabilityObject[vulnerability].fixable}
                        severityCounts={VulnerabilityObject[vulnerability].vulnerability}
                    />
                ))
            )}
        </VulnerabilitiesWrapper>
    );
};

export default Vulnerabilities;
