import {
    ContainerRegistryUserConfigurations,
    RegistryAccount,
    RegistryAccountSummary,
    RegistryAgentSummary,
} from 'modules/workloads/services/containerRegistry/containerRegistry.interface';

export interface ExtendedRegistry {
    account: RegistryAccount;
    accountSummary: RegistryAccountSummary;
    agentSummary: Array<RegistryAgentSummary>;
}

export interface RegistriesState {
    registries: Array<RegistryAccount>;
    registriesLoading: boolean;
    registriesError: boolean;
    extendedRegistries: {
        [registryId: string]: {
            data?: ExtendedRegistry;
            loading?: boolean;
            error?: boolean;
            deleted?: boolean;
        };
    };
    registrySettings: {
        [registryId: string]: {
            data?: ContainerRegistryUserConfigurations;
            loading?: boolean;
            error?: boolean;
        };
    };
}

export const RegistriesInitialState: RegistriesState = {
    registries: [],
    registriesLoading: false,
    registriesError: false,
    extendedRegistries: {},
    registrySettings: {},
};
