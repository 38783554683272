import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ColumnApi, GridOptions, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import { Table } from 'common/design-system/components-v2';
import { CounterStatus } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import { FindingsTableDatasource, IDataSourceConfig } from './FindingsTableDatasource';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { GenericObject, IActionUsageDef, IColumnUsageDef } from 'common/interface/general';
import { FindingsTableRegistry } from 'common/components/KustoEvents/FindingsTableRegistry';
import { mergeActionDefs, mergeColumnDefs, saveColumnsState } from 'common/utils/tableUtils';
import { ModalType } from '../Findings.const';
import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import Modals from './Modals/Modals';
import { ITableAction, ITableProps } from 'common/design-system/components-v2/Table/Table.types';
import { IAdditionalFilterFieldInfo, IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import EventTitleCellRenderer from './CellRenderers/EventTitleCellRenderer';
import TableFooterCounter from 'common/erm-components/custom/TableFooterCounter/TableFooterCounter';

interface IFindingsTableProps extends ITableProps {
    tableId: string;
    filters: IProtectedAssetFilter[];
    columns: IColumnUsageDef[];
    datasource: FindingsTableDatasource | undefined;
    mitreInfo: GenericObject<any>;
    updateDatasource: Function;
    filterValues?: IFiltersValues | undefined;
    actionsCreator?: (openDialog: (dialogType: ModalType | null) => void) => IActionUsageDef[];
    additionalFilterFieldInfo?: IAdditionalFilterFieldInfo[];
}

const FindingsTable: React.FC<IFindingsTableProps> = ({
    tableId,
    filters,
    columns,
    actionsCreator,
    datasource,
    filterValues,
    mitreInfo,
    updateDatasource,
    disableColumnMenu,
    disableGrouping,
    additionalFilterFieldInfo,
}) => {
    const COLUMNS_STATE_STORAGE_KEY = useMemo(() => `${tableId}__COLUMNS_STATE`, [tableId]);
    const gridApiRef = useRef<GridApi>();
    const columnApiRef = useRef<ColumnApi>();
    const [counters, setCounters] = useState({
        totalCount: CounterStatus.Pending,
        currentCount: CounterStatus.Pending,
    });
    const [modalType, setModalType] = React.useState<ModalType | string | null>(null);
    const [selectedRows, setSelectedRows] = React.useState<IKustoEvent[]>([]);

    const onGridReady = useCallback(
        async (params: GridReadyEvent) => {
            gridApiRef.current = params.api;
            columnApiRef.current = params.columnApi;
            const defaultDatasourceConfig: IDataSourceConfig = {
                onRowCountUpdate: setCounters,
                filters: filters,
                mitreInfo: mitreInfo,
                additionalFilterFieldInfo: additionalFilterFieldInfo,
            };
            const findingsDatasource = new FindingsTableDatasource(defaultDatasourceConfig);
            findingsDatasource.setApis(params.api, params.columnApi);
            updateDatasource(findingsDatasource);
        },
        [filters, mitreInfo, additionalFilterFieldInfo, updateDatasource],
    );

    const getChildCount = useCallback((data: IKustoEvent) => {
        return data.numberOfRows ?? 0;
    }, []);

    const openDialog = useCallback((dialogType: ModalType | null) => {
        setModalType(dialogType);
    }, []);

    const onSortChanged = useCallback(() => {
        // refresh top level server side store by maintaining the current grouping
        gridApiRef?.current?.refreshServerSide({ purge: false });
    }, []);

    const onSelectionChanged = (params: SelectionChangedEvent<IKustoEvent>) => {
        const selectedItems = params.api.getSelectedRows();
        setSelectedRows(selectedItems);
    };

    const refreshTableData = () => {
        gridApiRef.current?.deselectAll();
        gridApiRef?.current?.refreshServerSide({ purge: true });
    };

    useCallback(() => {
        if (!columnApiRef.current) {
            return;
        }
        saveColumnsState(columnApiRef.current, COLUMNS_STATE_STORAGE_KEY);
    }, [COLUMNS_STATE_STORAGE_KEY]);

    const getColumnDefs = useCallback(() => {
        return mergeColumnDefs(columns, FindingsTableRegistry.getColumnDefs());
    }, [columns]);

    const getActionsDefs: ITableAction[] = useMemo<ITableAction[]>(() => {
        if (!actionsCreator) return [];
        const actions: IActionUsageDef[] = actionsCreator(openDialog);
        return mergeActionDefs(actions, FindingsTableRegistry.getActions());
    }, [actionsCreator, openDialog]);

    const gridOptions: GridOptions = useMemo(() => {
        return {
            rowModelType: 'serverSide',
            rowSelection: 'multiple',
            suppressRowDeselection: false,
            enableRangeSelection: false,
            suppressCellFocus: true,
            columnDefs: getColumnDefs(),
            autoGroupColumnDef: {
                cellRendererSelector: (params) => ({
                    component: params.node.group ? 'agGroupCellRenderer' : EventTitleCellRenderer,
                }),
                width: 450,
                minWidth: 200,
            },
            getChildCount: getChildCount,
            onGridReady,
            onSortChanged,
            onRowSelected: onSelectionChanged,
        };
    }, [getColumnDefs, getChildCount, onGridReady, onSortChanged]);

    const footerCounter = React.useMemo(() => {
        return <TableFooterCounter counters={counters} />;
    }, [counters]);

    useEffect(() => {
        if (datasource) {
            gridApiRef?.current?.setServerSideDatasource(datasource);
        }
    }, [datasource]);

    useEffect(() => {
        if (filterValues && datasource) {
            void datasource.setFilterFields(filterValues);
        }
    }, [filterValues, datasource]);

    useEffect(() => {
        if (datasource) datasource.setAdditionalFilterFieldInfo(additionalFilterFieldInfo ?? []);
    }, [additionalFilterFieldInfo, datasource]);

    return (
        <>
            <Table
                tableId={tableId}
                saveColumnsState={true}
                footer={footerCounter}
                actions={getActionsDefs}
                gridOptions={gridOptions}
                disableGrouping={disableGrouping}
                disableColumnMenu={disableColumnMenu}
                appliedFilters={filterValues}
            />
            <Modals
                tableId={tableId}
                modalType={modalType}
                closeModal={() => setModalType(null)}
                setModalType={setModalType}
                selectedRows={selectedRows}
                refreshTableData={refreshTableData}
                resetSelectedRows={() => gridApiRef.current?.deselectAll()}
            />
        </>
    );
};

export default FindingsTable;
