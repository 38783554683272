import { IconComponentProps } from 'common/design-system/components-v2/Icon/Icon.types';
import { IBaseAssetModifier } from './IBaseAssetModifier';
import { ImportanceLevelMap } from 'common/consts/ImportanceLevel';
import { IamExposureLevelEnum, IamExposureServerIdEnum } from '../../../consts';
import { ermTrans } from '../../../RiskManagement.utils';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { isNil } from 'common/utils/helpFunctions';

export interface IIamExposureLevelInfo extends IBaseAssetModifier {
    serverIds: string[];
    title: string;
    iconProps: IconComponentProps;
    bg: string;
}

export const getIamExposureInfosMap = (): { [key in IamExposureLevelEnum]: IIamExposureLevelInfo } => ({
    [IamExposureLevelEnum.Unknown]: {
        id: IamExposureLevelEnum.Unknown,
        serverIds: [IamExposureServerIdEnum.Unknown],
        title: ermTrans('PROTECTED_ASSETS.MODIFIERS.IAM_EXPOSURE.UNKNOWN'),
        iconProps: { name: 'web', customColor: ImportanceLevelMap.unknown.fg },
        bg: ImportanceLevelMap.unknown.bg,
        level: -1,
    },
    [IamExposureLevelEnum.Private]: {
        id: IamExposureLevelEnum.Private,
        serverIds: [IamExposureServerIdEnum.Private],
        title: ermTrans('PROTECTED_ASSETS.MODIFIERS.IAM_EXPOSURE.PRIVATE'),
        iconProps: { name: 'private', customColor: ImportanceLevelMap.low.fg },
        bg: ImportanceLevelMap.low.bg,
        level: 0,
    },
    [IamExposureLevelEnum.Partial]: {
        id: IamExposureLevelEnum.Partial,
        serverIds: [IamExposureServerIdEnum.PartiallyPublic, IamExposureServerIdEnum.Partial],
        title: ermTrans('PROTECTED_ASSETS.MODIFIERS.IAM_EXPOSURE.PARTIALLY_PUBLIC'),
        iconProps: { name: 'semiPublic', customColor: ImportanceLevelMap.medium.fg },
        bg: ImportanceLevelMap.medium.bg,
        level: 1,
    },
    [IamExposureLevelEnum.Public]: {
        id: IamExposureLevelEnum.Public,
        serverIds: [IamExposureServerIdEnum.Public],
        title: ermTrans('PROTECTED_ASSETS.MODIFIERS.IAM_EXPOSURE.PUBLIC'),
        iconProps: { name: 'web', customColor: ImportanceLevelMap.critical.fg },
        bg: ImportanceLevelMap.critical.bg,
        level: 2,
    },
});

export const getAllIamExposureLevelInfos = (): IIamExposureLevelInfo[] =>
    Object.values(getIamExposureInfosMap()).sort((a, b) => a.level - b.level);

export const getIamExposureLevelInfos = (): IIamExposureLevelInfo[] =>
    getAllIamExposureLevelInfos().filter((info) => info.level >= 0);

const getUnknownIamExposureLevelInfo = (): IIamExposureLevelInfo =>
    getIamExposureInfosMap()[IamExposureLevelEnum.Unknown];

export function getIamExposureLevelInfo(iamExposure?: string): IIamExposureLevelInfo {
    const levelInfo: IIamExposureLevelInfo | undefined = isNil(iamExposure)
        ? undefined
        : getIamExposureLevelInfos().find((item) => item.serverIds.includes(iamExposure));
    return levelInfo || getUnknownIamExposureLevelInfo();
}

export const getAllIamExposureOptions = (): SelectOption[] => {
    return getAllIamExposureLevelInfos().map((item) => ({
        value: item.id,
        label: item.title,
    }));
};
