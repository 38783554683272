import React, { useCallback } from 'react';
import vulnerabilityService from '../vulnerability.service';
import { GetVulnerabilityScan, UseVulnerabilityScanState } from '../vulnerability.interface';

const initialState = {
    data: undefined,
    isLoading: false,
    error: false,
};

export type UseVulnerabilityScan = (data: GetVulnerabilityScan.Request) => UseVulnerabilityScanState;
const useVulnerabilityScan: UseVulnerabilityScan = (props) => {
    const [vulState, setVulState] = React.useState<UseVulnerabilityScanState>(initialState);

    const getData = useCallback(async () => {
        setVulState((prevState) => ({ ...prevState, isLoading: true }));
        try {
            const { data } = await vulnerabilityService.getVulnerabilityScan({
                entityId: props.entityId,
                entityType: props.entityType,
                environmentId: props.environmentId,
                remediationOnly: props.remediationOnly,
            });
            setVulState({ isLoading: false, error: false, data: data });
        } catch (error) {
            setVulState((prevState) => ({ ...prevState, isLoading: true, error: true }));
        }
    }, [props.entityId, props.entityType, props.environmentId, props.remediationOnly]);

    React.useEffect(() => {
        getData();
    }, [getData]);

    return {
        ...vulState,
    };
};

export default useVulnerabilityScan;
