import { getNotificationsService, UserPageService } from 'common/interface/services';

interface IInviteUserUtil {
    invitedUserId?: number;
    t: (key: string) => string;
}

export const InviteUserUtil = async ({ invitedUserId, t }: IInviteUserUtil): Promise<void> => {
    const showErrorNotification = (error: any) => {
        const errorData = error?.response?.data || error.message || '';
        const errorHeader = t('ACTIONS.INVITE_USER.TOAST_ERROR_HEADER');
        const errorContent = errorData || t('ACTIONS.INVITE_USER.TOAST_ERROR_CONTENT');
        getNotificationsService().error(errorHeader, errorContent);
    };

    const showSuccessNotification = () => {
        const successHeader = t('ACTIONS.INVITE_USER.TOAST_SUCCESS_HEADER');
        const successContent = t('ACTIONS.INVITE_USER.TOAST_SUCCESS_CONTENT');
        getNotificationsService().success(successHeader, successContent);
    };

    if (!invitedUserId) {
        showErrorNotification(new Error('No user selected'));
        return;
    }
    const payload = { id: invitedUserId };
    try {
        await UserPageService().inviteUserIamSafety(payload);
        showSuccessNotification();
    } catch (error: any) {
        showErrorNotification(error);
    }
};
