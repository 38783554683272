import { GraphModel } from 'common/components/Graph/Models/GraphModel';
import { NetworkTrafficConfiguration } from '../../PerimeterExposure/Models/NetworkTrafficConfiguration';
import { search } from './SuggestionHttpService';
import { DisplayPrompt, ResponseElement } from '../Suggestion.interface';

export const SUPPORTED_AI_DATA_CENTERS = ['us1', 'euwe1'];

export function handleGraphSuggestions(
    graphModel: GraphModel,
    setDisplayedPrompts: (o: { displayName: string; prompts: DisplayPrompt[] }) => void,
    setSuggestions: (responseElements: ResponseElement[][]) => void,
    setShowSuggestionsPopup: (shouldShow: boolean) => void,
): void {
    graphModel.edges.forEach((edge) => {
        if (!edge.data?.securityGroupConfigurations) return;

        edge.data.displaySuggestions = async (
            securityGroupConfiguration: NetworkTrafficConfiguration,
            displayName: string,
        ) => {
            const prompts = await search(securityGroupConfiguration);
            setSuggestions([]);
            setDisplayedPrompts({ prompts: prompts, displayName });
            setShowSuggestionsPopup(true);
        };
    });
}
