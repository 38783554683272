import { validLanguages } from 'common/services/translations/translations';
import i18n from 'i18next';
import { buildPath } from 'common/extensibility/AddinContainer';
import initializeServiceAccountsColumnDefs from './ServiceAccountsColumnDefenitions';
import initializeServiceAccountsFilters from './ServiceAccountsFiltersDefenitions';
import { getTableRegistry } from '../../SimpleTableFilterPage/SimpleGenericTableRegistry';
import { getAppRootRegistry } from 'common/interface/services';
import { lazy } from 'react';

export const I18nTranslationKey = 'ServiceAccounts';
const PATH_SERVICE_ACCOUNTS_PREFIX = buildPath('service_accounts', 'service_accounts_settings');
export const PATH_SERVICE_ACCOUNTS_PAGE = buildPath(PATH_SERVICE_ACCOUNTS_PREFIX, 'page');
export const PATH_SERVICE_ACCOUNTS_PAGE_TABLE_COLUMNS = buildPath(PATH_SERVICE_ACCOUNTS_PAGE, 'columns');
export const PATH_SERVICE_ACCOUNTS_PAGE_TABLE_FILTERS = buildPath(PATH_SERVICE_ACCOUNTS_PAGE, 'filters');

export const initializeI18nServiceAccounts = async () => {
    return Promise.all(
        validLanguages.map(async (language) => {
            try {
                const contents = await require(`./languages/${language}/translation.json`);
                i18n.addResourceBundle(language, `${I18nTranslationKey}`, contents);
            } catch {
                //Nothing to do with missing translation files
            }
        }),
    );
};

export const serviceAccountsTableRegistry = getTableRegistry(
    PATH_SERVICE_ACCOUNTS_PAGE_TABLE_COLUMNS,
    PATH_SERVICE_ACCOUNTS_PAGE_TABLE_FILTERS,
);

const initGenericPage = (initializeColumnDefs: Function, initializeFilters: Function) => {
    initializeColumnDefs();
    initializeFilters();

    const ServiceAccountsPageRoute = {
        condition: () => true,
        component: lazy(() => import('./ServiceAccountsPage')),
        path: '/users-managementservice-accounts',
    };

    getAppRootRegistry().addRoutes([{ id: 'serviceAccounts', content: ServiceAccountsPageRoute }]);
};

export const initializeServiceAccounts = () => {
    initGenericPage(initializeServiceAccountsColumnDefs, initializeServiceAccountsFilters);
};
