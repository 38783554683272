import { ICdrExclusionsService, ICdrExclusionProps, ICdrExclusion } from './CdrExclusion.interface';
import { getService } from 'common/extensibility/AddinContainer';
import { CDR_EXCLUSIONS_SERVICE_ID, CDR_EXCLUSIONS_URL } from './CdrExclusion.consts';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { fetchAllRulesets, getCdrIpIdByName, getCdrIpNameById, getRulesetName } from './CdrExclusion.datasource';
import { getRulesOptionsByRuleset } from './CdrExclusion.options';
import { getHttpService, getStoreService } from 'common/interface/services';
import { generalApiError } from 'common/utils/http';
import { clearCacheDataByTag, getCacheTag } from 'common/utils/apiCaching';
import { sendHttpRequest } from 'common/erm-components/utils/ermComponents.http';
import { getSafeFindingSeverityInfo } from 'common/consts/FindingSeverity';
import { IntelligenceRuleset } from 'common/module_interface/intelligence/Intelligence.interface';

interface IRuleModel {
    id: string;
    name: string;
    checked: boolean;
}

interface IExclusionModelProps {
    cloudAccountsOrOUsDispalyName: string | null;
    cloudAccountsIcon: string | null;
    cloudAccountsPlatform: string | null;
    entityId: string | null;
    entityName: string | null;
    severity: string | null;
    severities: string[] | null;
    comment: string | null;
    checked: boolean;
    rules: IRuleModel[] | null;
    rulesString: string | null;
    srcIp: string | null;
    dstIp: string | null;
    srcIpListId: string | null;
    dstIpListId: string | null;
    srcIpListName: string | null;
    dstIpListName: string | null;
    srcPort: string | null;
    dstPort: string | null;
    cloudAccountId: string | null;
    cloudAccountIds: string | null;
    organizationalUnitIds: string | null;
    dateRangeString: string | null;
    srcIpText: string | null;
    dstIpText: string | null;
    bundleName: string;
    bundleId: string | null;
    bundleVendor: string | null;
    dateTo: string | null;
    dateFrom: string | null;
    id?: string;
}

const convertExclusionPropsToModelProps = async (
    excProps: ICdrExclusionProps,
    id?: string,
): Promise<IExclusionModelProps> => {
    const rulesetId: string = excProps.rulesetId;
    const rulesetName: string = (await getRulesetName(rulesetId)) || '';
    const entityNameList: string[] = (excProps.entityNames || []).map((name: string) => `name like '${name}'`);
    const entityName: string | null = entityNameList ? entityNameList.join(' or ') : null;
    const severityList: string[] = (excProps.severities || []).map((key: string) => key.toUpperCase());
    const severities: string[] | null = severityList.length === 0 ? null : severityList;
    const severity: string | null = severityList.length === 0 ? null : severityList.join(',');
    const ruleList: IRuleModel[] = [];
    const ruleOptions: SelectOption[] = await getRulesOptionsByRuleset(rulesetId);
    (excProps.ruleIds || []).forEach((ruleId) => {
        const option: SelectOption | undefined = ruleOptions.find((option) => option.value === ruleId);
        if (option) {
            ruleList.push({
                id: option.value,
                name: option.label,
                checked: true,
            });
        }
    });
    const rules: IRuleModel[] | null = ruleList;
    const cloudAccountIds: string | null = excProps.envIds ? excProps.envIds.join(', ') : null;
    let dateFrom;
    let dateTo;
    if (excProps.expirationDate) {
        dateFrom = new Date().toISOString();
        const dateToObj = excProps.expirationDate;
        dateToObj.setHours(23, 59, 59, 999);
        dateTo = dateToObj.toISOString();
    }
    return {
        id,
        cloudAccountsOrOUsDispalyName: null,
        cloudAccountsIcon: null,
        cloudAccountsPlatform: null,
        entityId: excProps.entityId || null,
        entityName,
        severity,
        severities,
        comment: excProps.comment || '',
        checked: false,
        rules,
        rulesString: null,
        srcIp: excProps.srcIpCidr || null,
        dstIp: excProps.destIpCidr || null,
        srcIpListId: excProps.srcSavedIp ? await getCdrIpIdByName(excProps.srcSavedIp) : null,
        dstIpListId: excProps.destSavedIp ? await getCdrIpIdByName(excProps.destSavedIp) : null,
        srcIpListName: excProps.srcSavedIp || null,
        dstIpListName: excProps.destSavedIp || null,
        srcPort: excProps.srcPort || null,
        dstPort: excProps.destPort || null,
        cloudAccountId: cloudAccountIds,
        cloudAccountIds,
        organizationalUnitIds: excProps.orgUnitIds ? excProps.orgUnitIds.join(', ') : null,
        dateRangeString: null,
        srcIpText: null,
        dstIpText: null,
        bundleName: rulesetName,
        bundleId: String(rulesetId || 0),
        bundleVendor: null,
        dateFrom: dateFrom || null,
        dateTo: dateTo || null,
    };
};

interface IExclusionRuleModel {
    exclusion_id: number;
    rule_id: string;
    rule_name: string;
}

interface IExclusionModel {
    id: number;
    account_id: string;
    cloud_account_id: string;
    bundle_name: string;
    bundle_id: string;
    entity_name: string;
    entity_id: string;
    comment: string;
    severity: string;
    date_from: string;
    date_to: string;
    src_ip_list_id: string;
    dst_ip_list_id: string;
    src_ip: string;
    dst_ip: string;
    src_port: string;
    dst_port: string;
    organizational_unit_ids: string;
    rules: IExclusionRuleModel[];
}

const getSafeSplitList = (str: string): string[] => {
    if (!str) {
        return [];
    }
    return str.split(/[ ]*,[ ]*/);
};

const getExpirationDateForCdrFromTimestamps = (timestamps: string[]): Date | undefined =>
    timestamps.length === 2 ? new Date(timestamps[1]) : undefined;

const extractEntityNamesFromModel = (modelEntityName: string): string[] => {
    if (!modelEntityName) return [];
    const parts: string[] = modelEntityName.split(/[ ]+or[ ]+/);
    const rawNames: string[] = parts.map((str) => {
        const matches: RegExpMatchArray | null = str.match(/^name like [']+([^'']+)[']+$/);
        if (matches && matches.length > 1) {
            return matches[1];
        } else {
            return '';
        }
    });
    return rawNames.filter((name) => !!name);
};

const createExclusionFromModel = async (
    model: IExclusionModel,
    allRulesets?: IntelligenceRuleset[],
): Promise<ICdrExclusion> => {
    const rulesets: IntelligenceRuleset[] = allRulesets || (await fetchAllRulesets());
    const ruleset: IntelligenceRuleset | undefined = model.bundle_id
        ? rulesets.find((rs) => rs.id === model.bundle_id)
        : undefined;
    const ruleIds: string[] = model.rules ? model.rules.map((rule) => rule.rule_id) : [];
    const upperSeverities: string[] = getSafeSplitList(model.severity);
    const severities: string[] = upperSeverities.map((upperSeverity) => getSafeFindingSeverityInfo(upperSeverity).key);
    const entityNames: string[] = extractEntityNamesFromModel(model.entity_name);
    const srcSavedIp: string = model.src_ip_list_id ? await getCdrIpNameById(model.src_ip_list_id) : '';
    const destSavedIp: string = model.dst_ip_list_id ? await getCdrIpNameById(model.dst_ip_list_id) : '';

    return {
        rulesetId: model.bundle_id,
        rulesetName: ruleset?.name || '',
        comment: model.comment,
        orgUnitIds: getSafeSplitList(model.organizational_unit_ids),
        envIds: getSafeSplitList(model.cloud_account_id),
        expirationDate: getExpirationDateForCdrFromTimestamps([model.date_from, model.date_to]),
        ruleIds,
        entityNames,
        entityId: model.entity_id,
        severities,
        srcIpCidr: model.src_ip,
        srcSavedIp,
        srcPort: model.src_port,
        destIpCidr: model.dst_ip,
        destSavedIp,
        destPort: model.dst_port,
        id: String(model.id),
    };
};

const createExclusionsFromModels = async (models: IExclusionModel[]): Promise<ICdrExclusion[]> => {
    const rulesets: IntelligenceRuleset[] = await fetchAllRulesets();
    const exclusions: ICdrExclusion[] = [];
    for (let i = 0; i < models.length; i++) {
        const model: IExclusionModel = models[i];
        exclusions.push(await createExclusionFromModel(model, rulesets));
    }
    return exclusions;
};

export function getCdrExclusionsService(): ICdrExclusionsService {
    return getService<ICdrExclusionsService>(CDR_EXCLUSIONS_SERVICE_ID);
}

const SERVICE_NAME = 'cdr-exlusions';

export class CdrExclusionsService implements ICdrExclusionsService {
    private clearMultiActionsCache() {
        clearCacheDataByTag(SERVICE_NAME);
    }
    private clearSpecificActionCache(id: string) {
        clearCacheDataByTag(SERVICE_NAME, id);
    }

    private getBaseUrl = (): string => {
        const state = getStoreService().state;
        const magellanBaseUrl = state.app.magellanUrl;
        return `${magellanBaseUrl}/${CDR_EXCLUSIONS_URL}`;
    };

    private getExclusionUrl = (id: string) => `${this.getBaseUrl()}/${id}`;

    public async getAllCdrExclusions(): Promise<ICdrExclusion[]> {
        const exclusionModels: IExclusionModel[] = await sendHttpRequest<IExclusionModel[]>(
            this.getBaseUrl(),
            { method: 'GET' },
            undefined,
            [getCacheTag(SERVICE_NAME)],
        );
        return await createExclusionsFromModels(exclusionModels);
    }

    public async updateCdrExclusion(id: string, cdrExclusionProps: ICdrExclusionProps): Promise<void> {
        const updateModel: IExclusionModelProps = await convertExclusionPropsToModelProps(cdrExclusionProps, id);
        this.clearMultiActionsCache();
        this.clearSpecificActionCache(id);
        return getHttpService().request<void>(
            this.getBaseUrl(),
            {
                method: 'PUT',
                data: updateModel,
            },
            undefined,
            generalApiError,
        );
    }

    public createCdrExclusion = async (updateProps: ICdrExclusionProps): Promise<number> => {
        this.clearMultiActionsCache();
        const updateModel: IExclusionModelProps = await convertExclusionPropsToModelProps(updateProps);
        const exclusionModel: IExclusionModel = await getHttpService().request<IExclusionModel>(
            this.getBaseUrl(),
            {
                method: 'POST',
                data: updateModel,
            },
            undefined,
            generalApiError,
        );
        return exclusionModel.id;
    };

    public async deleteCdrExclusion(id: string, multiTagAlreadyCleared?: boolean): Promise<void> {
        if (!multiTagAlreadyCleared) {
            this.clearMultiActionsCache();
        }
        this.clearSpecificActionCache(id);
        void getHttpService().request<string>(
            this.getExclusionUrl(id),
            {
                method: 'DELETE',
            },
            undefined,
            generalApiError,
        );
    }

    public async deleteCdrExclusions(ids: string[]): Promise<void> {
        this.clearMultiActionsCache();
        const promises = ids.map((cdrExclusionId) => {
            return this.deleteCdrExclusion(cdrExclusionId, true);
        });
        void Promise.all(promises).then(() => []);
    }
}
