import { Addin } from 'common/extensibility/AddinRegistry';
import {
    AwsEnvironmentAddinRegistry,
    AwsEnvironmentAddingBase,
    CustomModalContent,
} from 'common/helpers/awsEnvironment';
import {
    AwsEnvironmentToolbarActions,
    AwsEnvironmentData,
    AwsEnvironmentDataExtraData,
    CloudAccountOnboardingMode,
} from 'common/module_interface/assets/AwsEnvironment';
import { Vendors } from 'common/consts/vendors';
import EcsService from './services/ecs/ecs.service';
import { ecsImagesScanActivation, getEcsImageScanLabel } from './utils/ecsImageScan';
import { getUserService } from 'common/interface/services';
import { Trans } from 'react-i18next';
import { getK8sNamespace } from './initialize.i18n';
import i18n from 'common/services/translations/translations';
import AwpService from './services/awp/awp.service';
import { Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import { lazy } from 'react';

export const initialAwsEnvironmentTabs = () => {
    const actions: Addin<AwsEnvironmentAddingBase<AwsEnvironmentToolbarActions>>[] = [
        {
            id: 'ecs-image-scan',
            position: 7,
            content: {
                id: 'ecs-image-scan',
                isRelevant: () => getUserService().hasPermission(['allow_ecs_aws_image_tab']),
                getValue: (awsData) => {
                    return EcsService.getEcsImagesScanStatus(awsData.account.id).then(({ data: { ecsImageScan } }) => {
                        return {
                            type: 'button',
                            name: 'ecs-image-scan',
                            label: getEcsImageScanLabel(ecsImageScan),
                            callback: async (
                                awsAccount: AwsEnvironmentData,
                                extraData: AwsEnvironmentDataExtraData,
                                reloadActions?: () => void,
                            ) => {
                                ecsImagesScanActivation(
                                    ecsImageScan,
                                    awsData.account.id,
                                    reloadActions ?? (() => null),
                                );
                            },
                        };
                    });
                },
            },
        },
    ];
    const tabs: Addin<AwsEnvironmentAddingBase<Tab>>[] = [
        {
            id: 'serverless',
            position: 90,
            content: {
                id: 'serverless',
                isRelevant: (awsData, extraData) => extraData.serverlessAccount?.state.toLowerCase() === 'active',
                getValue: () => ({
                    name: 'serverless',
                    label: 'Serverless',
                    isReactTab: false,
                }),
            },
        },
        {
            id: 'awp',
            position: 91,
            content: {
                id: 'awp',
                getValue: (awsData) => ({
                    name: 'awp',
                    label: 'AWP',
                    isReactTab: true,
                    reactTab: {
                        component: lazy(() => import('./aws/tabs/awp/AwpTab')),
                        componentProps: {
                            cloudAccountId: awsData.account.id,
                            platform: Vendors.AWS,
                        },
                    },
                }),
            },
        },
    ];
    const agentlessProtectionData: Addin<AwsEnvironmentAddingBase<CustomModalContent>>[] = [
        {
            id: 'agentless',
            content: {
                id: 'agentless',
                getValue: (awsData) => {
                    const getContent = () => {
                        const isOrgOnboarding =
                            awsData.account.organizationId &&
                            awsData.account.organizationId !== 'NoOrg' &&
                            awsData.account.organizationId !== 'OrgDetached';
                        if (isOrgOnboarding) return null;
                        if (awsData?.account.onboardingMode === CloudAccountOnboardingMode.CUSTOMER_MANAGED)
                            return (
                                <Trans
                                    i18nKey={`${getK8sNamespace('awp')}:modals.removeAccount.unifiedOnboarding.text`}
                                    components={{ b: <b /> }}
                                />
                            );
                        return <>{i18n.t(`${getK8sNamespace('awp')}:modals.removeAccount.disableAwp`)}</>;
                    };

                    return {
                        name: 'remove',
                        content: getContent(),
                    };
                },
                isRelevant: async (awsData) => {
                    const getAccountAgentlessData = async () => {
                        try {
                            const res = await AwpService.getWorkloadAgentessAccount(awsData.account.id, Vendors.AWS);
                            return res.data.agentlessProtectionEnabled;
                        } catch (err) {
                            return false;
                        }
                    };
                    return await getAccountAgentlessData();
                },
            },
        },
    ];

    AwsEnvironmentAddinRegistry.addTabs(tabs);
    AwsEnvironmentAddinRegistry.addActions(actions);
    AwsEnvironmentAddinRegistry.addModalCustomContent(agentlessProtectionData);
};
