import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlibabaInitialState, AlibabaState } from './alibaba.interface';
import { AlibabaEnvironmentData } from 'common/module_interface/assets/AlibabaEnvironment';

const AlibabaSlice = createSlice({
    name: 'env_alibaba',
    initialState: AlibabaInitialState,
    reducers: {
        setLoading: (state: AlibabaState, action: PayloadAction<{ alibabaId: string; loading: boolean }>) => {
            const { alibabaId, loading } = action.payload;
            if (!state.alibabaEvnironments[alibabaId]) {
                state.alibabaEvnironments[alibabaId] = {};
            }
            state.alibabaEvnironments[alibabaId].loading = loading;
        },
        setError: (state: AlibabaState, action: PayloadAction<{ alibabaId: string; error: boolean }>) => {
            const { alibabaId, error } = action.payload;
            if (!state.alibabaEvnironments[alibabaId]) {
                state.alibabaEvnironments[alibabaId] = {};
            }
            state.alibabaEvnironments[alibabaId].error = error;
        },
        setData: (state: AlibabaState, action: PayloadAction<{ alibabaId: string; data: AlibabaEnvironmentData }>) => {
            const { alibabaId, data } = action.payload;
            if (!state.alibabaEvnironments[alibabaId]) {
                state.alibabaEvnironments[alibabaId] = {};
            }
            state.alibabaEvnironments[alibabaId].data = data;
        },
        setDeleted: (state: AlibabaState, action: PayloadAction<{ alibabaId: string }>) => {
            const { alibabaId } = action.payload;
            if (!state.alibabaEvnironments[alibabaId]) {
                state.alibabaEvnironments[alibabaId] = { deleted: true };
            }
            state.alibabaEvnironments[alibabaId].deleted = true;
        },
    },
});

export default AlibabaSlice;
