import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';
import { IConfiguration } from 'common/module_interface/settings/integrations/Integrations';
import { OutputTypes } from '../Components/OutputTypeSelector/interfaces';

export interface Integration {
    id: string;
    name: string;
    type: string;
    configuration: any;
}
export interface ISaveNotificationRequest {
    name: string;
    id?: string;
    description?: string;
    integrations?: Integration[];
    alertsConsole?: boolean;
    sendOnEachOccurrence?: boolean;
    origin?: string;
    integrationSettingsModel?: IIntegrationSettingsRequest;
}

export interface INotification extends ISaveNotificationRequest {
    associations?: string[] | null;
    types?: string[];
    statusDetails?: any;
}

export interface IIntegrationSettings {
    matchingList: string;
    integration: Integration;
    integrationSettings: ISingleNotificationIntegrationSetting;
}

export interface INotificationTableProps {
    pageSize: number;
    data: INotification[];
    allNotificationCount: number;
    onDelete: (notifications: INotification[]) => void;
    onNotificationClicked?: (notification: INotification) => void;
    onAdd: () => void;
    onInnerGridReady: (params: any) => void;
    viewMode?: boolean;
}

export interface INotificationFilterPanelProps {
    tableData: INotification[];
    rawData: INotification[];
    onFilterChange: (filterValues: IFiltersValues) => void;
    isLoading: boolean;
}

export interface INotificationsFilterParams {
    aggregations: Aggregations;
    savedFiltersComponentName?: string;
    recentlyUsedComponentName?: string;
    filtersInitialData?: any;
}

export interface Aggregations {
    [key: string]: { value: any; count: number }[];
}

export interface AggregationOption {
    value: any;
    count: number;
}

export interface IIntegrationSettingsRequest {
    reportsIntegrationSettings: IReportsIntegration[];
    singleNotificationIntegrationSettings: ISingleNotificationIntegrationSetting[];
    scheduledIntegrationSettings: any[];
}

export interface IReportsIntegration {
    integrationId: string;
    filter?: any;
}

export interface ISingleNotificationIntegrationSetting {
    integrationId: string;
    outputType?: OutputTypes | string;
    filter?: any;
    payload?: any;
    hasIntegrationPayload?: boolean;
    hasOutputTypeSelection?: boolean;
}

export interface ISelectedCategoriesList {
    [key: string]: ISelectedCategoryObject;
}

export interface ISelectedCategoryObject {
    integrationPayload?: string;
    hasIntegrationPayload?: boolean;
    hasOutputTypeSelection?: boolean;
    outputType?: OutputTypes | string;
    isChecked: boolean;
    selectedIntegration: string;
    notificationType: string;
    selectedConfiguration?: ISelectedConfigurations;
    hasError?: boolean;
}

export interface IAddEditNotificationsModalSection {
    title: string;
    icon: AllIconsName;
    categoryList: INotificationsCategoryProperties[];
    filter?: boolean;
}

export interface INotificationsCategoryProperties {
    id: string;
    title: string;
    integrationsIDs: string[];
    type: string;
    hasOutputTypeSelection?: boolean;
    description?: string;
    descriptionLink?: string;
    alert?: {
        condition?: (filter: IFiltersValues) => void;
        title: string;
        message: string;
    };
}

export interface ISelectedConfigurations extends IConfiguration {
    outputType?: OutputTypes;
    filter?: any;
    payload?: any;
}

export enum FilterSeverityEnum {
    CRITICAL = 'Critical',
    HIGH = 'High',
    MEDIUM = 'Medium',
    LOW = 'Low',
    INFO = 'Info',
}

///This enum is copied from the backend, to let us match the integration types.
///This is a temporary solution, until we will have a better way to handle this.
export enum AssessmentNotificationTarget {
    Sns,
    EmailPerFinding,
    IndexElasticSearch,
    PagerDuty,
    AwsSecurityHub,
    GcpSecurityCommandCenter,
    Webhook,
    AzureSecurityCenter,
    Slack,
    AggregatedEmail,
    Teams,
    Eventarc,
    SlackSingleMessage,
    TeamsSingleMessage,
}
