import React from 'react';
import { Stack } from 'common/design-system/components-v2';
import { AgGridReact, AgGridReactProps, AgReactUiProps } from 'ag-grid-react';
import WidgetCard from 'common/components/Dashboard/WidgetCard/WidgetCard';

interface KubernetesGeneralSectionTableProps {
    title: string;
    headers: Array<{ headerName: string; field: string }>;
    data: Array<any>;
}
const KubernetesGeneralSectionTable: React.FC<KubernetesGeneralSectionTableProps> = ({ title, headers, data }) => {
    const agGridProps: AgGridReactProps & AgReactUiProps = React.useMemo(
        () => ({
            columnDefs: headers,
            defaultColDef: {
                flex: 1,
                suppressMenu: true,
                suppressMovable: true,
                lockPosition: true,
                resizable: true,
            },
            headerHeight: 25,
            detailRowAutoHeight: true,
            domLayout: 'autoHeight',
            sideBar: false,
            lockPinned: true,
            tooltipShowDelay: 100,
            enableBrowserTooltips: true,
            rowData: data,
        }),
        [headers, data],
    );

    return (
        <WidgetCard
            classNames={{}}
            title={title}
            content={
                <Stack padding={[1, 3]}>
                    <AgGridReact {...agGridProps} className='ag-theme-alpine' />
                </Stack>
            }
        />
    );
};

export default KubernetesGeneralSectionTable;
