import React from 'react';

interface ILevelIndicatorProps {
    title: string;
    value: string;
    totalBars: number;
    currentBarIndex: number;
    isDisabled?: boolean;
    className?: string;
}

const LevelIndicator: React.FC<ILevelIndicatorProps> = ({
    title,
    value,
    totalBars,
    currentBarIndex,
    isDisabled,
    className,
}) => {
    const barWidth = Math.floor(100 / totalBars);
    const firstBarWidthDelta = 100 - barWidth * totalBars;
    return (
        <div className={className ?? ''}>
            <div className='flex items-center gap-5 pt-6 pb-2'>
                <div>{title}</div>
                <div className={`ml-auto font-semibold ${isDisabled ? 'text-weakest' : ''}`}>{value}</div>
            </div>
            <div className='h-[5px] flex flex-1 gap-[2px]'>
                {Array(totalBars)
                    .fill(0)
                    .map((number, index) => {
                        let size = barWidth;
                        if (index === 0) {
                            size += firstBarWidthDelta;
                        }
                        let className = '';
                        if (isDisabled) {
                            className = 'border';
                        } else if (index <= currentBarIndex) {
                            className = 'bg-primary-lighter';
                        } else {
                            className = 'bg-canvas';
                        }

                        return <div key={index} className={className} style={{ width: `${size}%` }}></div>;
                    })}
            </div>
        </div>
    );
};

export default LevelIndicator;
