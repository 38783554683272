import React, { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { ModalType } from '../../Findings.const';
import { IModalsProps } from './Modals.types';
import { CloudBotStatusModal } from './CloudBotStatusModal/CloudBotStatusModal';
import { FixItModal } from './FixItModal/FixItModal';
import { RemediateModal } from './RemediateModal/RemediateModal';
import { REMEDIATE_MAX_CLOUD_BOTS_ALLOWED } from './RemediateModal/RemediateModal.const';
import { ReportIssueModal } from './ReportIssueModal/ReportIssueModal';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { AcknowledgeModal } from './AcknowledgeModal/AcknowledgeModal';
import { CommentModal } from './CommentModal/CommentModal';
import { CloseModal } from './CloseModal/CloseModal';
import { ChangeSeverityModal } from './ChangeSeverityModal/ChangeSeverityModal';
import { AssignUserModal } from './AssignUserModal/AssignUserModal';
import { ArchiveModal } from './ArchiveModal/ArchiveModal';
import { CiemExclusionModal } from './CiemExclusionModal/CiemExclusionModal';
import { createCdrRemediation } from '../../CdrEvents/CdrEvents.utils';
import { IRemediationCreationByEventProps } from '../../Findings.interface';
import { createCiemRemediation } from '../../CiemEvents/CiemEvents.utils';

const Modals: React.FC<IModalsProps> = (props: IModalsProps) => {
    const { modalType, closeModal, selectedRows } = props;

    const handleCloseModal = useCallback(() => {
        closeModal();
    }, [closeModal]);

    const createRemediationModal = (
        selectedEvents: IKustoEvent[],
        createRemediation: (remediationProps: IRemediationCreationByEventProps) => Promise<void>,
    ) => {
        if (selectedEvents.length === 0) {
            return null;
        }
        return (
            <RemediateModal
                {...props}
                finding={selectedEvents[0]}
                closeModal={handleCloseModal}
                createRemediation={createRemediation}
                maxCloudBotsAllowed={REMEDIATE_MAX_CLOUD_BOTS_ALLOWED}
            />
        );
    };

    // Render the appropriate modal based on the modalType prop
    switch (modalType) {
        case ModalType.FixIt:
            return <FixItModal {...props} closeModal={handleCloseModal} />;

        case ModalType.BotStatusCheck:
            return <CloudBotStatusModal {...props} />;

        case ModalType.RemediateCdr:
            return createRemediationModal(selectedRows, createCdrRemediation);

        case ModalType.RemediateCiem:
            return createRemediationModal(selectedRows, createCiemRemediation);

        case ModalType.ExcludeCiem: {
            const event: IKustoEvent | undefined = isEmpty(selectedRows) ? undefined : selectedRows[0];
            if (!event) return null;
            return <CiemExclusionModal {...props} event={event} closeModal={handleCloseModal} />;
        }

        case ModalType.ReportIssue:
            return <ReportIssueModal {...props} closeModal={handleCloseModal} />;

        case ModalType.Acknowledge: {
            const findings: IKustoEvent[] = selectedRows;
            if (isEmpty(findings)) return null;
            return <AcknowledgeModal {...props} findings={findings} closeModal={handleCloseModal} />;
        }

        case ModalType.Comment: {
            const findings: IKustoEvent[] = selectedRows;
            if (isEmpty(findings)) return null;
            return <CommentModal {...props} findings={findings} closeModal={handleCloseModal} />;
        }

        case ModalType.Close: {
            const findings: IKustoEvent[] = selectedRows;
            if (isEmpty(findings)) return null;
            return <CloseModal {...props} findings={findings} closeModal={handleCloseModal} />;
        }

        case ModalType.ChangeSeverity: {
            const findings: IKustoEvent[] = selectedRows;
            if (isEmpty(findings)) return null;
            return <ChangeSeverityModal {...props} findings={findings} closeModal={handleCloseModal} />;
        }

        case ModalType.AssignUser: {
            const findings: IKustoEvent[] = selectedRows;
            if (isEmpty(findings)) return null;
            return <AssignUserModal {...props} findings={findings} closeModal={handleCloseModal} />;
        }

        case ModalType.Archive: {
            const findings: IKustoEvent[] = selectedRows;
            if (isEmpty(findings)) return null;
            return <ArchiveModal {...props} findings={findings} closeModal={handleCloseModal} />;
        }

        default:
            return null;
    }
};

export default Modals;
