import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Typography, Icon, Stack } from 'common/design-system/components-v2';
import { IEnvironment } from 'common/interface/environmentsTable';
import { getRiskLevelInfo, RiskLevelServerEnum } from 'common/module_interface/RiskManagement/RiskLevel';

const EnvTableRiskCellRenderer = React.forwardRef<any, ICellRendererParams<IEnvironment>>((props, ref) => {
    const [riskLevel, setRiskLevel] = React.useState<RiskLevelServerEnum>(props.data?.['customData|riskLevel']);

    React.useImperativeHandle(ref, () => {
        return {
            refresh(params: ICellRendererParams<IEnvironment>) {
                setRiskLevel(params.data?.['customData|riskLevel']);
                return true;
            },
        };
    });

    const riskLevelInfo = getRiskLevelInfo(riskLevel);
    if (!riskLevelInfo) {
        return null;
    }

    return (
        <Stack alignItems='center' spacing={2} direction='row' overflow='hidden' data-aid='risk-renderer'>
            <Icon name={riskLevelInfo.gaugeIconName || 'riskGaugeUnknown'} context={riskLevelInfo.context} />
            <Typography ellipsis>{riskLevelInfo.displayText}</Typography>
        </Stack>
    );
});

EnvTableRiskCellRenderer.displayName = 'EnvTableRiskCellRenderer';

export default EnvTableRiskCellRenderer;
