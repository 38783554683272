import { EventCategory, IFindingModelOriginString } from 'common/components/Findings/Findings.interface';
import { ICommonEvent } from 'common/module_interface/events/Events.interface';
import { CommonEventFields } from 'common/module_interface/events/EventsConsts';

export const isEventLogIDS = <T extends Pick<ICommonEvent, CommonEventFields.category | CommonEventFields.origin>>(
    event: T,
) => event.category === EventCategory.LogIDS && event.origin === IFindingModelOriginString.Agentless;

export const additionalFieldsIsRelevant = <T extends Pick<ICommonEvent, CommonEventFields.additionalFields>>(
    fieldName: string,
    event: T,
) => Boolean(event.additionalFields?.find(({ name }) => name === fieldName)?.value);
