import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

export const FindingsRowWrapper = styled(Stack)`
    height: 46px;
`;

export const Category = styled(Stack)`
    width: 120px;
`;
