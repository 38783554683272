import React, { ReactElement, useMemo } from 'react';
import { IEventOverviewPanelProvider } from 'common/module_interface/events/Events';
import { EventOverviewRegistry } from 'common/module_interface/events/EventOverviewRegistry';
import { Stack } from 'common/design-system/components-v2';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import UpperPanel from './Panels/UpperPanel';
import { EventOverviewPanels } from 'common/module_interface/events/EventsConsts';

const FindingOverview: React.FC<{ finding: IKustoEvent }> = ({ finding }) => {
    const panels: ReactElement[] = useMemo(() => {
        if (!finding) return [];

        const allProviders: IEventOverviewPanelProvider[] = EventOverviewRegistry.getPanels(
            EventOverviewPanels.CDR_CONTENT_PANEL,
        );

        const relevantProviders: IEventOverviewPanelProvider[] = allProviders.filter(
            (panelProvider: IEventOverviewPanelProvider) =>
                panelProvider.isRelevant ? panelProvider.isRelevant(finding) : false,
        );

        return relevantProviders.map((panelProvider: IEventOverviewPanelProvider) => (
            <panelProvider.panel key={panelProvider.panelId} event={finding} />
        ));
    }, [finding]);

    return (
        <Stack direction='column' spacing={5} fullWidth={true} margin={[0, 0, 0, 2]}>
            <UpperPanel event={finding} panelId={EventOverviewPanels.CDR_UPPER_PANEL} />
            {panels}
        </Stack>
    );
};

export default FindingOverview;
