import React from 'react';
import { useTranslation } from 'react-i18next';
import { SeeDocumentationWrapper } from './SeeDocumentation.styled';
import { Icon, Typography } from 'common/design-system/components-v2';

export interface SeeDocumentationProps {
    link?: string;
    showText?: boolean;
}

const SeeDocumentation: React.FunctionComponent<SeeDocumentationProps> = (props) => {
    const { link, showText = true } = props;
    const { t } = useTranslation('k8s_common');

    return (
        <SeeDocumentationWrapper externalUrl={link} removeExternalIcon rel='noreferrer'>
            <Icon name='book' size={12} color='brandPrimary' />
            <Typography context='info' saturation>
                {showText && t('seeDocumentation')}
            </Typography>
        </SeeDocumentationWrapper>
    );
};

export default SeeDocumentation;
