import { NETWORK_TRAFFIC_TABLE_FILTERS } from 'common/module_interface/intelligence/intelligence';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { IIntelligenceFilter } from 'common/module_interface/intelligence/Intelligence.interface';

/**
 * filters for network traffic table
 */
const filters: IIntelligenceFilter[] = [
    {
        id: 'gsl',
        filterProps: {
            context: 'flowlogs',
            defaultGsl: "vpcfl where action='ACCEPT'",
        },
    },
];

export default function initializeNetworkTrafficPageFilters() {
    globalAddinContainer.addMap(NETWORK_TRAFFIC_TABLE_FILTERS, filters);
}
