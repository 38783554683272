import React, { useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { IChipLabelProps } from 'common/design-system/components-v2/Chip/Chip.types';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from '../../../initialize.i18n';
import { TFunction } from 'i18next';
import { Chip } from 'common/design-system/components-v2';
import { ExecutionStatus } from 'common/module_interface/intelligence/CloudBots/CloudBots.const';
import { DefaultTheme, useTheme } from 'styled-components';

type ExecutionStatusPropMappings = { [key in ExecutionStatus]: IChipLabelProps };
const getExecutionStatusPropMappings = (theme: DefaultTheme): ExecutionStatusPropMappings => {
    const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);
    return {
        [ExecutionStatus.WAITING]: {
            label: t('CLOUD_BOTS.EXECUTION_STATUS.WAITING'),
            loading: true,
        },
        [ExecutionStatus.FAILED]: {
            color: 'alert',
            customTextColor: theme.palette.riskLevel.highFg,
            customColor: theme.palette.riskLevel.highBg,
            label: t('CLOUD_BOTS.EXECUTION_STATUS.ERROR'),
            leadingIconProps: { name: 'error', color: 'alert' },
        },
        [ExecutionStatus.SUCCESS]: {
            color: 'success',
            customTextColor: theme.palette.riskLevel.lowFg,
            customColor: theme.palette.riskLevel.lowBg,
            label: t('CLOUD_BOTS.EXECUTION_STATUS.FIXED'),
            leadingIconProps: { name: 'checkCircle', color: 'success' },
        },
        [ExecutionStatus.PASSED]: {
            color: 'success',
            customTextColor: theme.palette.riskLevel.lowFg,
            customColor: theme.palette.riskLevel.lowBg,
            label: t('CLOUD_BOTS.EXECUTION_STATUS.FIXED'),
            leadingIconProps: { name: 'checkCircle', color: 'success' },
        },
        [ExecutionStatus.NOT_INITIATED]: {
            label: t('CLOUD_BOTS.EXECUTION_STATUS.NOT_INITIATED'),
            leadingIconProps: { name: 'disable' },
        },
    };
};

const getExecutionStatusElement = (
    executionStatus: ExecutionStatus = ExecutionStatus.NOT_INITIATED,
    statusProps: ExecutionStatusPropMappings,
) => {
    const chipProps: IChipLabelProps = {
        disableInteraction: true,
        ...statusProps[executionStatus],
    };
    return <Chip {...chipProps} />;
};

const CloudBotExecutionStatusCellRenderer: React.FC<ICellRendererParams> = (params: ICellRendererParams) => {
    const { value } = params;
    const theme: DefaultTheme = useTheme();
    const statusProps: ExecutionStatusPropMappings = useMemo(() => {
        return getExecutionStatusPropMappings(theme);
    }, [theme]);
    return getExecutionStatusElement(value, statusProps);
};

export default CloudBotExecutionStatusCellRenderer;
