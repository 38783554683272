import { Stack } from 'common/design-system/components-v2';
import { IPackageDrawerProps, TPackageInfoState, EPackageInfoStateTypes } from './PackageDrawer.types';
import React from 'react';
import UpperPanel from './components/UpperPanel/UpperPanel';
import { SbomGslService } from 'modules/workloads/services/vulnerability-search';
import { mergePackageInfo } from './PackageDrawer.utils';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import ConnectedAffectedAssetsPanel from './components/AffectedAssetsPanel/ConnectedAffectedAssetsPanel';
import EmptyStateWrapper from 'modules/workloads/protectedAsset/page/tabs/EmptyStateWrapper/EmptyStateWrapper';

const PackageDrawer: React.FC<IPackageDrawerProps> = ({ id, showExplorerPackageButton }) => {
    const [packageInfo, setPackageInfo] = React.useState<TPackageInfoState>({ type: EPackageInfoStateTypes.LOADING });

    React.useEffect(() => {
        const getInitialData = async () => {
            setPackageInfo({ type: EPackageInfoStateTypes.LOADING });
            try {
                const packageData = await SbomGslService.getPackageLicenses({ id });
                setPackageInfo({ type: EPackageInfoStateTypes.LOADED, data: mergePackageInfo(packageData) });
            } catch {
                setPackageInfo({ type: EPackageInfoStateTypes.ERROR });
            }
        };
        getInitialData();
    }, [id]);

    return (
        <>
            {packageInfo.type === EPackageInfoStateTypes.LOADING ? (
                <Stack fullHeight fullWidth>
                    <CloudAnimationLoader size='medium' />
                </Stack>
            ) : null}

            {packageInfo.type === EPackageInfoStateTypes.LOADED ? (
                <Stack direction='column' spacing={5} fullWidth>
                    <UpperPanel
                        showExplorePackageButton={Boolean(showExplorerPackageButton)}
                        name={packageInfo.data.name}
                        version={packageInfo.data.version}
                        manager={packageInfo.data.manager}
                        licenses={packageInfo.data.licenses}
                        path={packageInfo.data.path}
                        isOsPackage={packageInfo.data.isOsPackage}
                    />
                    <ConnectedAffectedAssetsPanel id={id} />
                </Stack>
            ) : null}

            {packageInfo.type === EPackageInfoStateTypes.ERROR ? (
                <EmptyStateWrapper label='Failed fetching data' iconName='error' description='' />
            ) : null}
        </>
    );
};

export default PackageDrawer;
