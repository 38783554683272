import { getCyeraIntegrationService, IBaseIdModel } from 'common/module_interface/RiskManagement/Services';

export async function addOrUpdateCyeraAccount(
    name: string,
    clientId: string,
    secret: string,
    accountId?: string,
): Promise<IBaseIdModel> {
    if (accountId) {
        return await getCyeraIntegrationService().updateCyeraAccount(accountId, name);
    } else {
        return await getCyeraIntegrationService().addCyeraAccount(clientId, secret, name);
    }
}
