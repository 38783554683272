import { Chip, Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

const Header = styled(Stack)`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

const Index = styled(Chip)`
    height: 20px;
    margin-left: 8px;
    font-weight: 450;
    padding: 4px 8px;
`;

const ContentDiv = styled(Stack)`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`;

const SelectWrapper = styled(Stack)`
    width: 500px;
`;

const IndexWrapper = styled(Stack)`
    justify-content: center;
    width: 130px;
`;

const ErrorDiv = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    color: red;
`;

const LinkWrapper = styled(Stack)`
    font-weight: 400;
    font-size: 12px;
`;

export const SubAssetStyled = {
    ContentDiv,
    Header,
    Index,
    SelectWrapper,
    IndexWrapper,
    ErrorDiv,
    LinkWrapper,
};
