import { IConditionValueOption } from 'common/erm-components/custom/FilterTree/FilterTree.interface';
import {
    getCloudAccountsService,
    getOrganizationalUnitService,
    ICloudAccount,
    IOrganizationalUnit,
} from 'common/interface/data_services';
import {
    getEnvironmentDisplayNameFromCloudAccount,
    getMatchingAssetsOptions,
} from 'common/components/ProtectedAssets/AssetUtils';
import { getVendor, IVendor } from 'common/consts/vendors';
import {
    getRealIssueSeverities,
    getSafeIssueSeverityInfo,
    IIssueSeverityInfo,
    sortDecendingly,
} from './components/Issues/IssueSeverity';
import { getSupportedSecurityGraphEntityTypes, getSupportedSecurityGraphPlatforms } from './SecurityGraph.supported';
import { ISecurityRule } from './components/AutoActions/AutoActions.interface';
import { fetchAllRules } from './components/AutoActions/AutoActions.utils';
import { IAsset } from 'common/assets/common.assets';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { ISelectOptionsMap } from './RiskManagement.interface';

export const createSelectOption = (value: string, label?: string): SelectOption => {
    return {
        value,
        label: label ?? value,
    };
};
export const getSelectOptionsMap = (options: SelectOption[]): ISelectOptionsMap => {
    const optionsMap: ISelectOptionsMap = {};
    options.forEach((option) => {
        optionsMap[option.value] = option;
    });
    return optionsMap;
};

export const convertCondOptionToSelectOption = (condOption: IConditionValueOption): SelectOption => ({
    value: String(condOption.value),
    label: condOption.label,
    labelProps: condOption.labelProps,
});

export const getIssueSeverityCondOptions = (severities: (string | number)[]): Promise<IConditionValueOption[]> => {
    const infos: IConditionValueOption[] = severities
        .sort()
        .reverse()
        .map((severity) => {
            const severityInfo: IIssueSeverityInfo = getSafeIssueSeverityInfo(severity);
            return {
                label: severityInfo.displayText,
                value: severity,
                labelProps: { leadingIconProps: severityInfo.iconProps },
            };
        });
    return Promise.resolve(infos);
};

export const getAllIssueSeverityCondOptions = (): IConditionValueOption[] => {
    return sortDecendingly(getRealIssueSeverities()).map((severityInfo: IIssueSeverityInfo) => {
        return {
            label: severityInfo.displayText,
            value: severityInfo.level,
            labelProps: { leadingIconProps: severityInfo.iconProps },
        };
    });
};

export const getIssueSeverityOptions = (): SelectOption[] => {
    const condOptions: IConditionValueOption[] = getAllIssueSeverityCondOptions();
    return condOptions.map((condOption: IConditionValueOption) => {
        const severityInfo: IIssueSeverityInfo = getSafeIssueSeverityInfo(condOption.value as string | number);
        return {
            value: severityInfo.key,
            label: condOption.label,
            labelProps: condOption.labelProps,
        };
    });
};

export const getPlatformCondOptions = (platformNames: string[]): IConditionValueOption[] => {
    const options: IConditionValueOption[] = [];
    platformNames.forEach((platformName) => {
        const platform: IVendor | null = getVendor(platformName);
        if (platform) {
            options.push({
                label: platform.displayName,
                value: platformName,
                labelProps: { leadingIconProps: { vendorNameOrPath: platform.icon } },
            });
        }
    });
    return options;
};

export const getAllPlatformCondOptions = (): IConditionValueOption[] => {
    return getPlatformCondOptions(getSupportedSecurityGraphPlatforms());
};

export const getEntityTypeCondOptions = (typeByPlatforms: string[]): IConditionValueOption[] => {
    const options: IConditionValueOption[] = [];
    typeByPlatforms.forEach((typeByPlatform) => {
        const iAsset: IAsset | undefined = getProtectedAssetsService().getAssetByType(typeByPlatform) || undefined;
        if (iAsset) {
            options.push({
                label: iAsset.displayName,
                value: typeByPlatform,
                labelProps: { leadingIconProps: { vendorNameOrPath: iAsset.icon } },
            });
        }
    });
    return options;
};

export const getAllEntityTypeCondOptions = (): IConditionValueOption[] => {
    return getEntityTypeCondOptions(getSupportedSecurityGraphEntityTypes());
};

export const getAllEntityTypeOptions = async (): Promise<SelectOption[]> => {
    const condOptions: IConditionValueOption[] = getAllEntityTypeCondOptions();
    return Promise.resolve(
        condOptions.map((condOption: IConditionValueOption) => convertCondOptionToSelectOption(condOption)),
    );
};

export const getEnvironmentCondOptions = (envIds: string[]): Promise<IConditionValueOption[]> => {
    return getCloudAccountsService()
        .getAllCloudAccounts()
        .then((allCloudAccounts: ICloudAccount[]) => {
            const options: IConditionValueOption[] = [];
            envIds.forEach((envId) => {
                const env = allCloudAccounts.find((cloudAccount) => cloudAccount.id === envId);
                if (env) {
                    const envName: string = getEnvironmentDisplayNameFromCloudAccount(env, env.id);
                    const platform = getVendor(env.platform);
                    if (platform) {
                        options.push({
                            label: envName,
                            value: envId,
                            labelProps: { leadingIconProps: { vendorNameOrPath: platform.icon } },
                        });
                    }
                }
            });
            return options;
        });
};

export const getAllEnvironmentCondOptions = (): Promise<IConditionValueOption[]> => {
    return getCloudAccountsService()
        .getAllCloudAccounts()
        .then((allCloudAccounts: ICloudAccount[]) => {
            const options: IConditionValueOption[] = [];
            const supportedPlatforms: string[] = getSupportedSecurityGraphPlatforms();
            const supportedEnvs: ICloudAccount[] = allCloudAccounts.filter((env) =>
                supportedPlatforms.includes(env.platform),
            );
            supportedEnvs.forEach((env: ICloudAccount) => {
                const envName: string = getEnvironmentDisplayNameFromCloudAccount(env, env.id);
                const platform = getVendor(env.platform);
                if (platform) {
                    options.push({
                        label: envName,
                        value: env.id,
                        labelProps: { leadingIconProps: { vendorNameOrPath: platform.icon } },
                    });
                }
            });
            return options;
        });
};

export const getAllEnvironmentOptions = async (): Promise<SelectOption[]> => {
    const condOptions: IConditionValueOption[] = await getAllEnvironmentCondOptions();
    return condOptions.map((condOption: IConditionValueOption) => convertCondOptionToSelectOption(condOption));
};

export const getAllRulesCondOptions = async (): Promise<IConditionValueOption[]> => {
    const allRules: ISecurityRule[] = await fetchAllRules();
    return allRules.map((rule) => {
        return {
            label: rule.title,
            value: rule.id,
            labelProps: { leadingIconProps: { name: 'rule' } },
        };
    });
};

export const getAllRulesOptions = async (): Promise<SelectOption[]> => {
    const condOptions: IConditionValueOption[] = await getAllRulesCondOptions();
    return condOptions.map((condOption: IConditionValueOption) => convertCondOptionToSelectOption(condOption));
};

export const getAllOrgUnitOptions = async (): Promise<SelectOption[]> => {
    const rootOrgUnit: IOrganizationalUnit = await getOrgUnitsRoot();
    const childOrgUnits: IOrganizationalUnit[] = (
        await getOrganizationalUnitService().getAllOrganizationalUnitsFlat()
    ).filter((orgUnit) => orgUnit.id !== rootOrgUnit.id);
    const allOrgUnits: IOrganizationalUnit[] = [rootOrgUnit, ...childOrgUnits];
    return allOrgUnits.map((orgUnit) => ({
        value: orgUnit.id,
        label: orgUnit.name,
    }));
};

export const getEntityNamesOptions = (freeText?: string): Promise<SelectOption[]> => {
    const includedEntityTypes = getSupportedSecurityGraphEntityTypes();
    return getMatchingAssetsOptions(freeText, includedEntityTypes);
};

export const getOrgUnitsRoot = async (): Promise<IOrganizationalUnit> => {
    return getOrganizationalUnitService().getOrganizationalUnitsView();
};
