import { ICellRendererParams } from 'ag-grid-enterprise';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import i18n from 'common/services/translations/translations';
import { CloudBotsTableFields, ExecutionStatus } from 'common/module_interface/intelligence/CloudBots/CloudBots.const';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import CloudBotTriggerStatusCellRenderer from '../../CellRenderers/CloudBotTriggerStatusCellRenderer';
import CloudBotExecutionStatusCellRenderer from '../../CellRenderers/CloudBotExecutionStatusCellRenderer';
import { TFunction } from 'i18next';

export const getColumnDefs = (): ColDef[] => {
    const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);
    return [
        {
            field: CloudBotsTableFields.BOT_NAME,
            headerName: t('CLOUD_BOTS.TABLE.COLUMNS.BOT_NAME.HEADER'),
            headerTooltip: t('CLOUD_BOTS.TABLE.COLUMNS.BOT_NAME.TOOLTIP'),
            flex: 3,
        },
        {
            field: CloudBotsTableFields.ENTITY_NAME,
            headerName: t('CLOUD_BOTS.TABLE.COLUMNS.ENTITY_NAME.HEADER'),
            headerTooltip: t('CLOUD_BOTS.TABLE.COLUMNS.ENTITY_NAME.TOOLTIP'),
            flex: 3,
        },
        {
            field: CloudBotsTableFields.TRIGGERED_STATUS,
            headerName: t('CLOUD_BOTS.TABLE.COLUMNS.TRIGGER_STATUS.HEADER'),
            headerTooltip: t('CLOUD_BOTS.TABLE.COLUMNS.TRIGGER_STATUS.TOOLTIP'),
            cellRendererSelector: (params: ICellRendererParams) => {
                if (!params.value) {
                    return {
                        component: 'agGroupCellRenderer',
                        params: {
                            suppressCount: true,
                            innerRenderer: CloudBotTriggerStatusCellRenderer,
                        },
                    };
                }
                return {
                    component: CloudBotTriggerStatusCellRenderer,
                };
            },
            flex: 2,
        },
        {
            field: CloudBotsTableFields.EXECUTION_STATUS,
            headerName: t('CLOUD_BOTS.TABLE.COLUMNS.EXECUTION_STATUS.HEADER'),
            headerTooltip: t('CLOUD_BOTS.TABLE.COLUMNS.EXECUTION_STATUS.TOOLTIP'),
            flex: 2,
            cellRendererSelector: (params: ICellRendererParams) => {
                if (params.value === ExecutionStatus.FAILED) {
                    return {
                        component: 'agGroupCellRenderer',
                        params: {
                            suppressCount: true,
                            innerRenderer: CloudBotExecutionStatusCellRenderer,
                        },
                    };
                }
                return {
                    component: CloudBotExecutionStatusCellRenderer,
                };
            },
        },
    ];
};
