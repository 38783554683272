import {
    KubernetesAccount,
    GetKubernetesAccountSummary,
    GetKubernetesAgentSummary,
} from '../../services/kubernetesAccount/kubernetesAccount.interface';

export interface Cluster {
    account: KubernetesAccount;
    summary: GetKubernetesAccountSummary.Response;
    agentSummary: GetKubernetesAgentSummary.Response;
}

export interface ClusterState {
    clusters: {
        [clusterId: string]: {
            data?: Cluster;
            loading?: boolean;
            error?: boolean;
            deleted?: boolean;
        };
    };
}

export const ClusterInitialState: ClusterState = {
    clusters: {},
};
