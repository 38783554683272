import React, { useCallback, useMemo, useState } from 'react';
import { isFilterValuesEmpty } from '../../filterUtils';
import { Table, Typography } from 'common/design-system/components-v2';
import { Remediationflatten } from 'modules/workloads/services/vulnerability/vulnerability.interface';
import { GridApi, GridReadyEvent, IRowNode } from 'ag-grid-community';
import { ITableExportButton } from 'common/design-system/components-v2/Table/Table.types';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import FilterPanelVulnerability from '../../FilterPanelVulnerability/FilterPanelVulnerability';
import { getColumnDefs } from './ColumnDefs';

let objectFilter: IFiltersValues = {};
const remediationFieldsToSearchTextFree = ['severity', 'category', 'finding'];

const Remediations: React.FC<{
    remediations: Array<Remediationflatten>;
    exportCveAsJSONAndCSV: Array<ITableExportButton>;
}> = ({ remediations, exportCveAsJSONAndCSV }) => {
    const gridRef = React.useRef<GridApi>();
    const [itemDisplayedRowCount, setItemDisplayedRowCount] = useState<number>(0);
    const { t } = useTranslation(getK8sNamespace('vulnerability'));

    const columnDefs = getColumnDefs();

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            sortable: true,
        };
    }, []);

    const onGridReady = useCallback((event: GridReadyEvent) => {
        gridRef.current = event.api;
    }, []);

    const externalFilterChanged = useCallback((newValue: IFiltersValues) => {
        objectFilter = newValue;
        gridRef.current?.onFilterChanged();
        setItemDisplayedRowCount(gridRef.current?.getDisplayedRowCount() || 0);
    }, []);

    const isExternalFilterPresent = useCallback(() => {
        return objectFilter && !isFilterValuesEmpty(objectFilter);
    }, []);

    const doesExternalFilterPass = useCallback((node: IRowNode) => {
        let doesExternalFilterPassValue = true;
        if (node.data) {
            Object.keys(objectFilter).forEach((key) => {
                if (!objectFilter[key] || objectFilter[key].length === 0) return;
                if (key !== 'free-text') {
                    const values: Array<string> = objectFilter[key];
                    if (typeof node.data[key] === 'boolean') {
                        const doesFilterPassValues = values.some((value) => node.data[key].toString() === value);
                        doesExternalFilterPassValue = doesExternalFilterPassValue && doesFilterPassValues;
                    } else {
                        const doesFilterPassValues = values.some((value) => node.data[key] === value);
                        doesExternalFilterPassValue = doesExternalFilterPassValue && doesFilterPassValues;
                    }
                } else {
                    const doesFilterPassFreeText = remediationFieldsToSearchTextFree.some((field) => {
                        if (!node.data[field]) return false;
                        return node.data[field].toLowerCase().includes(objectFilter[key].toLowerCase());
                    });
                    doesExternalFilterPassValue = doesExternalFilterPassValue && doesFilterPassFreeText;
                }
            });
        }
        return doesExternalFilterPassValue;
    }, []);

    return (
        <>
            <FilterPanelVulnerability
                isRemediations
                data={remediations}
                onFilterChange={externalFilterChanged}
            ></FilterPanelVulnerability>
            <Table
                tableId='remediations'
                saveColumnsState
                exportButtons={exportCveAsJSONAndCSV}
                gridOptions={{
                    rowData: remediations,
                    columnDefs: columnDefs,
                    onGridReady,
                    doesExternalFilterPass,
                    isExternalFilterPresent,
                    defaultColDef,
                }}
                footer={
                    <Typography>
                        {t('VulnerabilityTable.footer', {
                            itemDisplayedRowCount: itemDisplayedRowCount,
                            totalRowCount: remediations.length || 0,
                        })}
                    </Typography>
                }
            />
        </>
    );
};

export default Remediations;
