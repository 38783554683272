import React, { useCallback } from 'react';
import sbomService from '../sbom.service';
import { GetSbom, UseSbomState, SbomPackagesFlat } from '../sbom.interface';

const flattenSbomPackagesManager = (data: Array<SbomPackagesFlat>): Array<SbomPackagesFlat> =>
    data.map((item) => ({
        ...item,
        packageManagerPath: item['package-manager'].path,
        packageManagerName: item['package-manager'].name,
    }));

export type UseSbom = (data: GetSbom.Request) => {
    isLoading: boolean;
    data?: GetSbom.Response | undefined;
    error: boolean;
};
export const useSbom: UseSbom = (props) => {
    const [{ isLoading, error, data }, setState] = React.useState<UseSbomState>({
        isLoading: false,
        error: false,
    });

    const getData = useCallback(async () => {
        setState((prevState) => ({ ...prevState, isLoading: true, error: false }));
        try {
            const res = await sbomService.getSbom({
                entityId: props.entityId,
                entityType: props.entityType,
                environmentId: props.environmentId,
            });
            setState({
                isLoading: false,
                error: false,
                data: { ...res.data, sbomPackages: flattenSbomPackagesManager(res.data.sbomPackages) },
            });
        } catch (error) {
            setState({ isLoading: false, error: true, data: undefined });
        }
    }, [props.entityId, props.entityType, props.environmentId]);

    React.useEffect(() => {
        if (!isLoading && !error) {
            getData();
        }
    }, [isLoading, error, getData]);

    return {
        isLoading,
        data,
        error,
    };
};
