export const VARIES_PROP_VALUE = '(varies)';

export enum DetailsTabEnum {
    basicProps = 'basicProps',
    additionalProps = 'additionalProps',
    members = 'members',
}

export const DEFAULT_QUERY_NAME = 'New Query';
export const TOXIC_QUERIES_LIST_KEY = 'TOXIC_QUERIES_LIST_KEY';
export const PIVOT_ICON_FILL = '#d2f7fd';
export const MAX_GRAPH_ITEMS = 1000;
