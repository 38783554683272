import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { ContainerImagesInfo, IIssue } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { LoadingState } from 'common/interface/general';
import { fetchContainerImages } from '../../../Issues.utils';
import { Stack, Typography } from 'common/design-system/components-v2';
import IconLinkCellRenderer from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { LevelIconProps } from 'common/design-system/components-v2/LevelIcon/LevelIcon.types';
import { changeUrl } from 'common/utils/http';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../../../../consts';
import { ImageStyled } from './IssueContainerImagesPanel.styled';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { IAssetUrlRequiredProps } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { globalModelUtils } from 'common/components/GlobalModals/GlobalModals';

const getImageURL = ({ cloudAccountId, imageId, typeByPlatform }: ContainerImagesInfo, region: string) => {
    const [platform, type] = typeByPlatform.split('|');
    const urlProps: IAssetUrlRequiredProps = {
        platform: platform,
        type: type,
        cloudAccountId: cloudAccountId,
        entityId: imageId,
        region: region,
    };
    return getProtectedAssetsService().getProtectedAssetUrlByProps(urlProps);
};

const iconProps: LevelIconProps = {
    iconProps: { name: 'containerImage' },
    size: 'md',
};

export const IssueContainerImagesPanel: React.FC<{ issue: IIssue }> = ({ issue }) => {
    const [loadingState, setLoadingState] = useState(LoadingState.IS_LOADING);
    const [images, setImages] = useState<ContainerImagesInfo[]>();

    const { t } = useTranslation(I18nRiskNamespace);

    const loadImages = useCallback(async () => {
        setLoadingState(LoadingState.IS_LOADING);
        setImages(undefined);
        try {
            const imagesList = await fetchContainerImages(issue);
            setImages(imagesList);
            setLoadingState(LoadingState.LOADING_SUCCEEDED);
        } catch (e) {
            setLoadingState(LoadingState.LOADING_FAILED);
        }
    }, [issue]);

    useEffect(() => {
        if (issue) {
            void loadImages();
        }
    }, [issue, loadImages]);

    if (loadingState === LoadingState.LOADING_SUCCEEDED && images?.length === 0) {
        return null;
    }

    return (
        <Stack fullWidth>
            {loadingState === LoadingState.LOADING_FAILED && (
                <Typography color={'alert'}>{t('ISSUES.ISSUE_VIEWER.PANELS.IMAGES.LOADING_ERROR_MESSAGE')}</Typography>
            )}
            {loadingState === LoadingState.LOADING_SUCCEEDED && images && (
                <Stack direction={'column'} fullWidth fullHeight>
                    <ImageStyled.TitleDiv>{`${t('ISSUES.ISSUE_VIEWER.PANELS.IMAGES.TITLE', { count: images.length })}`}</ImageStyled.TitleDiv>
                    <Stack direction={'column'} fullWidth fullHeight spacing={2}>
                        {images.map((imageInfo: ContainerImagesInfo) => {
                            const url = getImageURL(imageInfo, issue.region);
                            return (
                                <IconLinkCellRenderer
                                    href={url ?? undefined}
                                    onClick={(event: SyntheticEvent) => {
                                        if (url) {
                                            event.preventDefault();
                                            changeUrl(url);
                                        } else {
                                            globalModelUtils.showErrorModal({ text: t('GENERAL.FAILED_LOADING_DATA') });
                                        }
                                    }}
                                    isLink={true}
                                    key={imageInfo.imageId}
                                    value={imageInfo.imageId}
                                    levelIconProps={iconProps}
                                />
                            );
                        })}
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};
