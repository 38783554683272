import initializeColumnDefs from 'modules/postureManagement/remediation/initializers/remediation.columnDefs';
import initializeFilters from 'modules/postureManagement/remediation/initializers/remediation.filtersDefs';
import RemediationSlice from 'modules/postureManagement/remediation/reducer/remediation.slice';
import { IApplicationReducer } from 'common/interface/redux';
import { RemediationRegistry } from 'common/module_interface/remediation/RemediationRegistry';
import { IFindingModelOriginString } from 'common/components/Findings/Findings.interface';
import { IEvent } from 'common/module_interface/events/Events';
import { Vendors } from 'common/consts/vendors';
import { getAppRootRegistry, getUserService } from 'common/interface/services';

export function initializeRemediationSlice() {
    const reducers: IApplicationReducer[] = [{ name: 'remediation', reducer: RemediationSlice, isBlackList: true }];
    getAppRootRegistry().addReducers(reducers, 'name');
}

export default function initializeRemediationEvents() {
    RemediationRegistry.addRemediateEventEnablers([
        {
            id: IFindingModelOriginString.ComplianceEngine,
            content: {
                enabler: (event: IEvent) => {
                    const user = getUserService().getUser();
                    const isCompliance = event.origin === IFindingModelOriginString.ComplianceEngine;
                    return (
                        user.isSuperUser &&
                        isCompliance &&
                        event.cloudAccountType.toLowerCase() !== Vendors.OCI.toLowerCase()
                    );
                },
            },
        },
    ]);
}

export const initializeRemediationCSPMPage = () => {
    initializeColumnDefs();
    initializeFilters();
    initializeRemediationSlice();
    initializeRemediationEvents();
};
