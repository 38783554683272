import i18next from 'i18next';
import { getEnvsNamespace } from '../initialize.i18n';
import CloudAccountsApi from '../../services/cloudAccountsApi/cloudAccountsApi.service';
import { getNotificationsService } from 'common/interface/services';

export const resetAllEnvironmentsPermissions = () => {
    const getText = (textPath: string) => {
        return i18next.t(textPath, {
            ns: getEnvsNamespace('table'),
        });
    };
    CloudAccountsApi.resetAllMissingPermissions().catch((error) => {
        return error;
    });
    getNotificationsService().info(
        getText('ACTIONS.VALIDATE_ALL_PERMISSIONS.TOASTS.REQUEST_SENT_TITLE'),
        getText('ACTIONS.VALIDATE_ALL_PERMISSIONS.TOASTS.REQUEST_SENT_TEXT'),
    );
};
