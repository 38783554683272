import {
    IAutoAction,
    IAutoActionEditorParams,
    IAutoActionIntegration,
    IAutoActionPreviewInfo,
    IAutoActionPreviewRequest,
    IAutoActionProps,
    ISecurityRule,
    ISecurityRuleModel,
} from './AutoActions.interface';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { getIntegrationsService, getUserService } from 'common/interface/services';
import { IntegrationsDefinition } from 'common/module_interface/settings/integrations/consts';
import { isNil } from 'common/utils/helpFunctions';
import { ICompoundFilterNode } from 'common/erm-components/custom/FilterTree/CompoundFilter';
import { AUTO_ACTION_PREVIEW_URL, AUTO_ACTIONS_PAGE_TABLE_ID } from './AutoActions.consts';
import {
    AutoActionTriggerEnum,
    getAutoActionsService,
    GRAPH_RULES_URL,
    IAutoActionModel,
} from 'common/module_interface/RiskManagement/autoActions/AutoActions';
import { AUTO_ACTION_EDITOR_KEY } from './AutoActionEditor/AutoActionEditor.consts';
import { ermPermissionFn, ermTrans, isAfNewIntegrations } from '../../RiskManagement.utils';
import { getSupportedIntegrationTypes } from '../../SecurityGraph.supported';
import { IGetAllConfigurationsModel } from 'common/module_interface/settings/integrations/Integrations';
import { IntegrationsRegistry } from 'common/module_interface/settings/integrations/IntegrationsRegistry';
import { DEFAULT_ORGANIZATIONAL_UNIT_ID } from 'common/consts/DataConsts';
import _ from 'lodash';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { IConditionValueOption } from 'common/erm-components/custom/FilterTree/FilterTree.interface';
import { condOptionsToSelectOptions } from 'common/erm-components/custom/FilterTree/FilterTree.utils';
import { sendHttpRequest } from 'common/erm-components/utils/ermComponents.http';
import { IssueSeverityEnum } from 'common/module_interface/RiskManagement/issues/Issues.interface';

export const hasAutoActionsEditPermission = (): boolean => {
    return (
        ermPermissionFn() &&
        isAfNewIntegrations() &&
        (getUserService().getIsSuperUser() || getUserService().hasPermission(['policies']))
    );
};

export const getSupportedIntegrationDefs = (): IntegrationsDefinition[] => {
    const supportedKeys = getSupportedIntegrationTypes();
    const allIntegrationDefs: IntegrationsDefinition[] = IntegrationsRegistry.getIntegrationsDefinitions();
    return allIntegrationDefs.filter((def) => def.id && supportedKeys.includes(def.id));
};

export const fetchAllAutoActionsIntegrations = (): Promise<IAutoActionIntegration[]> => {
    try {
        const allIntegrations: IAutoActionIntegration[] = [];
        return getIntegrationsService()
            .getAllConfigurations(true)
            .then((configurationsMap: IGetAllConfigurationsModel) => {
                const integrationDefs: IntegrationsDefinition[] = getSupportedIntegrationDefs();
                Object.keys(configurationsMap).forEach((key) => {
                    const integrationDef = integrationDefs.find((def) => def.id === key);
                    if (integrationDef) {
                        const configurationsList = configurationsMap[key];
                        configurationsList.forEach((configuration) => {
                            if (configuration.id) {
                                allIntegrations.push({
                                    id: configuration.id,
                                    name: configuration.name,
                                    icon: integrationDef?.icon,
                                });
                            }
                        });
                    }
                });
                return allIntegrations;
            });
    } catch (e) {
        console.error('Failed loading integrations data. Error: ', e);
        return Promise.resolve([]);
    }
};

const createAutoActionFromModel = async (
    index: number,
    autoActionModel: IAutoActionModel,
    allIntegrations?: IAutoActionIntegration[],
): Promise<IAutoAction> => {
    const finalAllIntegrations: IAutoActionIntegration[] = allIntegrations || (await fetchAllAutoActionsIntegrations());
    return {
        ...autoActionModel,
        integrationIds: autoActionModel.integrationIds || [],
        triggers: autoActionModel.triggers || [],
        integrations: finalAllIntegrations.filter((integration) =>
            autoActionModel.integrationIds.includes(integration.id),
        ),
        orgUnitIdsFilter: autoActionModel.orgUnitIdsFilter || [],
        serverFilter: autoActionModel.filter,
        filter: autoActionModel.filter ? { root: autoActionModel.filter } : undefined,
    };
};

export const createAutoActionsListFromModel = async (autoActionModels: IAutoActionModel[]): Promise<IAutoAction[]> => {
    const autoActions: IAutoAction[] = [];
    const allIntegrations: IAutoActionIntegration[] = await fetchAllAutoActionsIntegrations();
    for (let i = 0; i < autoActionModels.length; i++) {
        autoActions.push(await createAutoActionFromModel(i, autoActionModels[i], allIntegrations));
    }
    return autoActions;
};

export const openAutoActionEditorDrawer = (autoActionProps?: IAutoActionProps) => {
    const params: IAutoActionEditorParams = {
        onAutoActionsChangedHandlerId: AUTO_ACTIONS_PAGE_TABLE_ID,
        autoActionProps,
    };
    showDrawer(AUTO_ACTION_EDITOR_KEY, params);
};

export const fetchAllAutoActions = async (): Promise<IAutoAction[]> => {
    return getAutoActionsService()
        .getAllAutoActions()
        .then((autoActionModels: IAutoActionModel[]) => {
            return createAutoActionsListFromModel(autoActionModels);
        });
};

export const fetchPreviewFacets = async (
    aggrFields: string[],
    filter?: ICompoundFilterNode,
    orgUnitIds?: string[],
): Promise<IAutoActionPreviewInfo> => {
    const request: IAutoActionPreviewRequest = {
        filter,
        orgUnitIdsFilter: _.filter(orgUnitIds, (id) => id !== DEFAULT_ORGANIZATIONAL_UNIT_ID),
    };
    return await sendHttpRequest<IAutoActionPreviewInfo>(AUTO_ACTION_PREVIEW_URL, {
        data: request,
        method: 'POST',
    });
};

export const errorPropsHasMessages = (errorProps: { [key: string]: any }) => {
    return Object.values(errorProps).filter((val) => !isNil(val)).length > 0;
};

const createRuleFromModel = (ruleModel: ISecurityRuleModel): ISecurityRule => {
    return {
        ...ruleModel,
        title: ruleModel.name,
        severity: ruleModel.severity as IssueSeverityEnum,
    };
};

export const fetchAllRules = async (): Promise<ISecurityRule[]> => {
    const ruleModels: ISecurityRuleModel[] = await sendHttpRequest<ISecurityRuleModel[]>(GRAPH_RULES_URL, {
        method: 'GET',
    });
    return ruleModels ? ruleModels.map((ruleModel) => createRuleFromModel(ruleModel)) : [];
};

export const getAllTriggerSelectOptions = (): SelectOption[] => condOptionsToSelectOptions(getAllTriggerCondOptions());

export const getAllTriggerCondOptions = (): IConditionValueOption[] => [
    {
        label: ermTrans('AUTO_ACTIONS.GENERAL.CREATED'),
        value: AutoActionTriggerEnum.CREATED,
    },
    {
        label: ermTrans('AUTO_ACTIONS.GENERAL.UPDATED'),
        value: AutoActionTriggerEnum.UPDATED,
    },
    {
        label: ermTrans('AUTO_ACTIONS.GENERAL.CLOSED'),
        value: AutoActionTriggerEnum.CLOSED,
    },
];
