import { useScanResults } from 'modules/workloads/protectedAsset/page/tabs/HostConfiguration/hooks/useScanResults';
import { AwpHostConfigRuleDrawerProps, TResult, EResult } from '../../AwpHostConfigRuleDrawer.types';

const useRule = ({ assetId, cloudAccountId, provider, ruleId }: AwpHostConfigRuleDrawerProps): TResult => {
    const { loading, error, rowData, benchmarkData } = useScanResults({
        provider,
        cloudAccountId,
        queryParams: { assetId },
    });

    if (loading) {
        return { status: EResult.LOADING };
    }
    if (error) {
        return { status: EResult.ERROR, error };
    }
    const rule = rowData?.find((row) => row.ruleId === ruleId);
    if (!benchmarkData || !rule) {
        return { status: EResult.ERROR, error: 'Rule not found' };
    }
    return { status: EResult.SUCCESS, data: { ...benchmarkData, cloudAccountId, provider, assetId, ruleId, rule } };
};

export default useRule;
