import React, { useMemo } from 'react';
import PropertyBoxesRow from 'common/components/PropertyBox/PropertyBoxesRow';
import PropertyBox from 'common/components/PropertyBox/PropertyBox';
import TextPropertyBox from 'common/components/PropertyBox/TextPropertyBox';
import { useTranslation } from 'react-i18next';
import { IDataVolumeInfo } from 'common/components/DataVolume/DataVolume.interface';
import DataVolume from 'common/components/DataVolume/DataVolume';
import { BaseStyled } from './BasicPropertiesPanel.styled';
import { ISecurityControl } from '../../SecurityControls.interface';
import { I18nRiskNamespace } from '../../../../consts';
import { getIssueSeverityInfo, IssueSeveritiesMap } from '../../../Issues/IssueSeverity';

export const BasicPropertiesPanel: React.FC<{ securityControl: ISecurityControl }> = ({ securityControl }) => {
    const { t } = useTranslation(I18nRiskNamespace);
    const severityVolumeWidget = useMemo(() => {
        const severityInfo = getIssueSeverityInfo(securityControl.severity);
        if (!severityInfo) {
            return undefined;
        }
        const dataVolumeInfo: IDataVolumeInfo = {
            text: severityInfo.displayText,
            color: severityInfo.color,
            zeroBasedIndex: severityInfo.level - IssueSeveritiesMap.low.level,
            maxLevel: 4,
        };
        return <DataVolume dataInfo={dataVolumeInfo} />;
    }, [securityControl]);

    return (
        <BaseStyled.TopDiv>
            <PropertyBoxesRow>
                {severityVolumeWidget && (
                    <PropertyBox title={t('SECURITY_CONTROLS.PROPERTIES.SEVERITY')} content={severityVolumeWidget} />
                )}
                <TextPropertyBox title={t('SECURITY_CONTROLS.PROPERTIES.ID')} value={securityControl.id} />
            </PropertyBoxesRow>
            <BaseStyled.SepDiv>
                <BaseStyled.SepLine />
            </BaseStyled.SepDiv>
        </BaseStyled.TopDiv>
    );
};
