import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RemediationInitialState, RemediationState } from './remediation.interface';
import { IRemediation, ICloudBot } from 'common/interface/remediation';
import { IRuleset } from 'common/interface/ruleset';
import { ILicense } from 'common/interface/services';
import { ICloudAccount, IOrganizationalUnit } from 'common/interface/data_services';
import { RootState } from 'common/services/store/store';

const RemediationSlice = createSlice({
    name: 'remediation',
    initialState: RemediationInitialState,
    reducers: {
        setAllRemediation: (state: RemediationState, action: PayloadAction<IRemediation[]>) => {
            state.remediationList = action.payload;
        },
        setRulesetList: (state: RemediationState, action: PayloadAction<IRuleset[]>) => {
            state.rulesetList = action.payload;
        },
        setCloudAccountsList: (state: RemediationState, action: PayloadAction<ICloudAccount[]>) => {
            state.cloudAccounts = action.payload;
        },
        setOrganizationalUnits: (state: RemediationState, action: PayloadAction<IOrganizationalUnit[]>) => {
            state.organizationalUnits = action.payload;
        },
        setLicenseList: (state: RemediationState, action: PayloadAction<ILicense[]>) => {
            state.licenseList = action.payload;
        },
        setCloudBots: (state: RemediationState, action: PayloadAction<ICloudBot[]>) => {
            state.cloudBots = action.payload;
        },
    },
});

export default RemediationSlice.reducer;

export const {
    setAllRemediation,
    setRulesetList,
    setCloudAccountsList,
    setOrganizationalUnits,
    setLicenseList,
    setCloudBots,
} = RemediationSlice.actions;
export const getRemediationList = (state: RootState): IRemediation[] => state.remediation.remediationList || [];
export const getRulesetList = (state: RootState): IRuleset[] => state.remediation.rulesetList;
export const getOrganizationalUnits = (state: RootState): IOrganizationalUnit[] =>
    state.remediation.organizationalUnits;
export const getLicenseList = (state: RootState): ILicense[] => state.remediation.licenseList;
export const getCloudBots = (state: RootState): ICloudBot[] => state.remediation.cloudBots;
export const getCloudAccounts = (state: RootState): ICloudAccount[] => state.remediation.cloudAccounts;
