import React from 'react';
import { AssetStyled } from 'common/components/AssetPropertiesPanel/AssetPropertiesPanel.styled';
import { Stack, Typography } from 'common/design-system/components-v2';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';
import { RemediationPanel } from './RemediationPanel/RemediationPanel';
import EntityHeader from 'common/design-system/components-v2/EntityHeader/EntityHeader';
import { CveOverview } from 'modules/workloads/services/cveDrawerRegistry/cveDrawerRegistry.types';
import { EntityHeaderProps } from 'common/design-system/components-v2/EntityHeader/EntityHeader.types';

const ResourcePanel: React.FC<{ overview: CveOverview }> = ({ overview }) => {
    const { t } = useTranslation(getK8sNamespace('cve-explorer'));
    const { isOsPackage, inUse, packageManager, version, remediation, name } = overview.package;

    const chips: EntityHeaderProps['chips'] = [
        ...(isOsPackage ? [{ label: t('cveDrawer.packagePanel.operatingSystem') }] : []),
        ...(inUse
            ? [{ label: t('cveDrawer.packagePanel.isInUse'), customColor: '#F1DBFF', customTextColor: '#920DF2' }]
            : []),
    ];

    return (
        <Stack spacing={2} fullWidth>
            <Typography variant='body500'>{t('cveDrawer.packagePanel.package')}</Typography>
            <AssetStyled.ContentDiv>
                <EntityHeader
                    disableShowMore
                    levelIcon={{
                        iconProps: { name: 'package' },
                    }}
                    title={name}
                    blocksMaxWidth={800}
                    blocks={[
                        ...(packageManager?.name.length > 0
                            ? [{ title: t('cveDrawer.packagePanel.manager'), info: packageManager.name }]
                            : []),
                        ...(version && version.length > 0
                            ? [{ title: t('cveDrawer.packagePanel.version'), info: version, copyable: true }]
                            : []),
                        ...(packageManager?.path.length > 0
                            ? [{ title: t('cveDrawer.packagePanel.path'), info: packageManager.path, copyable: true }]
                            : []),
                    ]}
                    chips={chips}
                />
                {remediation && (
                    <Stack padding={[2, 0, 0, 0]}>
                        <RemediationPanel
                            title={t('cveDrawer.packagePanel.remediation')}
                            remediation={remediation}
                            tooltip={t('cveDrawer.packagePanel.tooltip')}
                        ></RemediationPanel>
                    </Stack>
                )}
            </AssetStyled.ContentDiv>
        </Stack>
    );
};

export default ResourcePanel;
