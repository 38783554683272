export interface ExampleState {
    test: string;
    loading: boolean;
    loadingError?: Error;
}

export const ExmapleInitialState: ExampleState = {
    test: '',
    loading: false,
    loadingError: undefined,
};
