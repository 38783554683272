import { AssetTypes } from 'common/assets/assets.const';
import { ICachingConfig } from 'common/interface/services';
import { AggregationConfig, Datasource, IDataSourceConfig } from 'common/components/ProtectedAssets/datasource';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { SortingModel } from 'common/components/Findings/Findings.interface';
import { DEFAULT_PROTECTED_ASSETS_SORT_MODEL, FULL_ERM_AGGREGATION } from '../../consts';
import { getErmSupportedAssets, RiskModifierSupportedTypes } from 'common/module_interface/RiskManagement/DataUtils';
import { DataAssetCategoriesMap, DataAssetsCategory } from '../../DataAssetCategories';

const ALL_SUPPORTED_ASSET_TYPES: string[] = [];

export const getAllSupportedAssetTypes = () => {
    return [...ALL_SUPPORTED_ASSET_TYPES];
};

const ALL_DATA_ASSETS_TYPES: string[] = [];
export const getAllDataAssetsTypes = () => ALL_DATA_ASSETS_TYPES;

const RISK_MODIFIER_SUPPORTED_ASSET_TYPES: RiskModifierSupportedTypes = {
    networkExposure: [],
    iamExposure: [],
    iamSensitivity: [],
    dataSensitivity: [],
};
export async function setupRiskData() {
    const supportedAssetsData = await getErmSupportedAssets();

    ALL_SUPPORTED_ASSET_TYPES.splice(0, ALL_SUPPORTED_ASSET_TYPES.length, ...supportedAssetsData.allSupportedTypes);

    ALL_DATA_ASSETS_TYPES.length = 0;
    const categories = supportedAssetsData.dataAssetsCategories;
    for (const key in categories) {
        const categoryInfo = DataAssetCategoriesMap[key as DataAssetsCategory];
        categoryInfo.assetTypes = [];
        categories[key].forEach((type) => {
            categoryInfo.assetTypes.push(type);
            ALL_DATA_ASSETS_TYPES.push(type);
        });
    }

    const riskModifiers = Object.keys(RISK_MODIFIER_SUPPORTED_ASSET_TYPES).map(
        (modifier) => modifier as keyof RiskModifierSupportedTypes,
    );
    riskModifiers.forEach((riskModifier) => {
        RISK_MODIFIER_SUPPORTED_ASSET_TYPES[riskModifier] =
            supportedAssetsData.riskModifierSupportedTypes[riskModifier];
    });
}

export function isRiskSupportedForAsset(typeByPlatform: string): boolean {
    return ALL_SUPPORTED_ASSET_TYPES.includes(typeByPlatform);
}

export function isRunningStatusRelevant(typeByPlatform: string): boolean {
    const relevantAssetTypes = [AssetTypes.AWS_INSTANCE, AssetTypes.AZURE_VIRTUALMACHINE, AssetTypes.GCP_VMINSTANCE];
    return relevantAssetTypes.includes(typeByPlatform);
}

export function isDataSensitivityRelevant(typeByPlatform: string): boolean {
    return RISK_MODIFIER_SUPPORTED_ASSET_TYPES.dataSensitivity.includes(typeByPlatform);
}

export function isNetworkExposureRelevant(typeByPlatform: string): boolean {
    return RISK_MODIFIER_SUPPORTED_ASSET_TYPES.networkExposure.includes(typeByPlatform);
}

export function isIamExposureRelevant(typeByPlatform: string): boolean {
    return RISK_MODIFIER_SUPPORTED_ASSET_TYPES.iamExposure.includes(typeByPlatform);
}

export function isIamSensitivityRelevant(typeByPlatform: string): boolean {
    return RISK_MODIFIER_SUPPORTED_ASSET_TYPES.iamSensitivity.includes(typeByPlatform);
}

export const getErmAggregation = (
    datasource: Datasource,
    aggregationConfig: AggregationConfig = FULL_ERM_AGGREGATION,
): Promise<Aggregations> => {
    return datasource.getAdHokDataFromServer(aggregationConfig).then((response) => {
        return response?.aggregations || {};
    });
};

export const createErmDataSourceConfig = (
    filters: IFiltersValues,
    pageSize: number,
    sortModel?: SortingModel,
    cachingConfig?: ICachingConfig,
): IDataSourceConfig => {
    return {
        pageSize,
        filters: {
            ...filters,
            includedEntityTypes: getAllSupportedAssetTypes(),
        },
        defaultSortModel: sortModel || DEFAULT_PROTECTED_ASSETS_SORT_MODEL,
        filterEntitiesWithoutRiskScore: true,
        cachingConfig,
    };
};
