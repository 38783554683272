import { IPolicy } from 'common/interface/policy';
import { getHttpService, IPolicyService, ISetPolicyResponse } from 'common/interface/services';
export class PolicyService implements IPolicyService {
    async updatePolicy(data: IPolicy[]): Promise<ISetPolicyResponse[]> {
        return await getHttpService().put<Promise<ISetPolicyResponse[]>>('ContinuousCompliancePolicyV2', { data });
    }

    async getCompliancePolicies(useCache = true, errorCodeIgnoreList?: number[]) {
        try {
            return await getHttpService().get<IPolicy[]>({
                path: 'ContinuousCompliancePolicyV2',
                cachingConfig: { useCache },
                errorCodeIgnoreList: errorCodeIgnoreList,
            });
        } catch {
            return [];
        }
    }

    async clearCache() {
        getHttpService().clearCacheByPath('ContinuousCompliancePolicyV2');
        getHttpService().clearCacheByPath('vulnerability/policy');
        getHttpService().clearCacheByPath('serverless/policy');
    }

    async getPolicies(useCache = true, errorCodeIgnoreList?: number[]): Promise<IPolicy[]> {
        const results = await Promise.all([this.getCompliancePolicies(useCache, errorCodeIgnoreList)]);
        return results.flat();
    }

    async getPolicy(id: string, useCache?: boolean): Promise<IPolicy | undefined> {
        const allPolicies = await this.getPolicies(useCache);
        return allPolicies.find((policy: IPolicy) => policy.id === id);
    }

    async setPolicy(data: IPolicy[]): Promise<ISetPolicyResponse[]> {
        return await getHttpService().post<Promise<ISetPolicyResponse[]>>('ContinuousCompliancePolicyV2', { data });
    }

    async deletePolicy(policyId: string): Promise<ISetPolicyResponse[]> {
        return await getHttpService().delete<Promise<ISetPolicyResponse[]>>('ContinuousCompliancePolicyV2/' + policyId);
    }
}
