import { initializeI18nRemediationModals } from 'modules/remediation/initialize.i18n';
import RemediationModalCspm from 'modules/remediation/Modals/RemediationModalCspm';
import RemediationModalCiem from 'modules/remediation/Modals/RemediationModalCiem';
import { RemediationModuleType } from 'common/interface/remediation';
import { initializeEventsActions } from './initialize.events';
import { RemediationRegistry } from 'common/module_interface/remediation/RemediationRegistry';

const initializeModal = () => {
    RemediationRegistry.addRemediationModals([
        {
            id: RemediationModuleType.CSPM,
            content: {
                id: RemediationModuleType.CSPM,
                component: RemediationModalCspm,
            },
        },
        {
            id: RemediationModuleType.CIEM,
            content: {
                id: RemediationModuleType.CIEM,
                component: RemediationModalCiem,
            },
        },
    ]);
};

const initializeRemediationModals = () => {
    initializeEventsActions();
    initializeI18nRemediationModals();
    initializeModal();
};

export default initializeRemediationModals;
