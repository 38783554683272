import {
    IEntityPropertiesMap,
    IEntityTypeInfo,
    IPropertyInfo,
    IRelationToTypesInfosMap,
    IToxicGraphSchema,
} from '../ToxicGraph.interface';
import { capitalize, getConnectionPairId } from '../ToxicGraph.utils';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { IconProps } from 'common/design-system/components-v2/Icon/Icon.types';
import {
    IConditionValueOption,
    IConditionValuesComponentProps,
    IFilterTreeFieldDefinition,
} from 'common/erm-components/custom/FilterTree/FilterTree.interface';
import {
    IToxicGraphSchemaEntityTypeInfo,
    IToxicGraphSchemaEntityTypesMap,
    IToxicGraphSchemaPropInfo,
    IToxicGraphSchemaPropsMap,
    IToxicGraphSchemaRelationsMap,
} from './ToxicGraphModel.interface';
import { FilterConditionOperator } from 'common/erm-components/custom/FilterTree/FilterCondition';
import { toxicOpModelToUiMap, ToxicPropTypeEnum } from './ToxicGraphModel.consts';
import React from 'react';
import { SingleValueSelector } from 'common/erm-components/custom/FilterTree/FilterTreeDefaults/SingleValueSelector';
import { DefaultFreeTextSearcher } from 'common/erm-components/custom/FilterTree/FilterTreeDefaults/DefaultFreeTextSearcher';
import { DefaultValuesSelector } from 'common/erm-components/custom/FilterTree/FilterTreeDefaults/DefaultValuesSelector';
import { globalToxicGraphSchema } from '../ToxicGraph.initialize';

const extraIconPropsMap: { [key: string]: IconProps } = {
    Cve: { name: 'cve' },
    Malware: { name: 'malicious' },
    SecurityEvent: { name: 'securityEvent' },
};

interface ISchemaRelationInfo {
    parentToChildText: string;
    childToParentText: string;
}

const toxicGraphSchema: IToxicGraphSchema = {
    entityTypesMap: {},
    relationsMap: {},
    operatorsMap: {},
    typesMap: {},
    connectionPairsMap: {},
};

const getPropertiesMap = (modelPropsMap: IToxicGraphSchemaPropsMap): IEntityPropertiesMap => {
    const propertiesMap: IEntityPropertiesMap = {};
    Object.values(modelPropsMap).forEach((propData: IToxicGraphSchemaPropInfo) => {
        const operators = propData.availableOperators || [];
        propertiesMap[propData.name] = {
            name: propData.name,
            displayName: capitalize(propData.displayName),
            type: propData.type,
            operators,
            options: propData.enum,
        };
        operators.forEach((op: string) => {
            toxicGraphSchema.operatorsMap[op] = op;
        });
        if (propData.type) {
            toxicGraphSchema.typesMap[propData.type] = propData.type;
        }
    });
    return propertiesMap;
};

const getFilerDefs = (propsMap: IEntityPropertiesMap): IFilterTreeFieldDefinition[] => {
    const filterDefs: IFilterTreeFieldDefinition[] = [];
    Object.values(propsMap).forEach((propInfo: IPropertyInfo) => {
        const allOperators: FilterConditionOperator[] = propInfo.operators.map(
            (modelOp) => toxicOpModelToUiMap[modelOp],
        );
        if (allOperators.length > 0 && [ToxicPropTypeEnum.string, ToxicPropTypeEnum.boolean].includes(propInfo.type)) {
            let valueOptions: IConditionValueOption[] | undefined = undefined;
            let conditionValuesComponent: React.FC<IConditionValuesComponentProps> | undefined;
            let operators: FilterConditionOperator[];
            if (propInfo.type === ToxicPropTypeEnum.string) {
                if (propInfo.options && propInfo.options.length > 0) {
                    if (allOperators.includes(FilterConditionOperator.In)) {
                        operators = [FilterConditionOperator.In];
                        conditionValuesComponent = DefaultValuesSelector;
                        valueOptions = propInfo.options.map((option) => ({ value: option, label: option }));
                    } else if (allOperators.includes(FilterConditionOperator.Equals)) {
                        operators = [FilterConditionOperator.Equals];
                        conditionValuesComponent = SingleValueSelector;
                        valueOptions = propInfo.options.map((option) => ({ value: option, label: option }));
                    } else {
                        operators = allOperators;
                        conditionValuesComponent = DefaultFreeTextSearcher;
                    }
                } else {
                    operators = allOperators.filter((op) => ![FilterConditionOperator.In].includes(op));
                    conditionValuesComponent = DefaultFreeTextSearcher;
                }
            } else {
                valueOptions = [
                    { value: 'true', label: 'True' },
                    // { value: 'false', label: 'False' },
                ];
                operators = [FilterConditionOperator.Equals];
                conditionValuesComponent = SingleValueSelector;
            }
            if (operators.length > 0) {
                const filterDef: IFilterTreeFieldDefinition = {
                    name: propInfo.name,
                    header: propInfo.displayName,
                    conditionOperatorsInfo: {
                        operators,
                        defaultOperator: operators[0],
                    },
                    valueOptions,
                    conditionValuesComponent,
                };
                filterDefs.push(filterDef);
            }
        }
    });
    return filterDefs;
};

const addToConnectionsPairsMap = (info: IEntityTypeInfo) => {
    const collectData = (map: IRelationToTypesInfosMap, isParentToChildRelation: boolean) => {
        Object.keys(map).forEach((relation) => {
            const types = map[relation];
            const pairId: string = getConnectionPairId(relation, info.name);
            toxicGraphSchema.connectionPairsMap[pairId] = {
                parentType: info.name,
                relationType: relation,
                isParentToChildRelation,
                possibleChildTypes: types,
            };
        });
    };
    collectData(info.incomingRelationsMap, false);
    collectData(info.outgoingRelationsMap, true);
};

const getIconProps = (entityType: string, nativeTypes: string[]): IconProps | undefined => {
    for (const type of nativeTypes) {
        const iAsset = getProtectedAssetsService().getAssetByType(type);
        if (iAsset?.icon) {
            return { vendorNameOrPath: iAsset.icon };
        }
    }
    return extraIconPropsMap[entityType];
};

export function prepareToxicGraphSchema() {
    const schemaRelationInfosMap: { [key: string]: ISchemaRelationInfo } = {};
    const relationsSchema: IToxicGraphSchemaRelationsMap = globalToxicGraphSchema.RelationshipSchemas; //fakeSchema.RelationshipSchemas;
    Object.keys(relationsSchema).forEach((relation) => {
        const relationInfo = relationsSchema[relation];
        schemaRelationInfosMap[relation] = {
            parentToChildText: capitalize(relationInfo.displayName),
            childToParentText: capitalize(relationInfo.reverseDisplayName),
        };
    });

    const entityTypesMap: IToxicGraphSchemaEntityTypesMap = globalToxicGraphSchema.EntitySchemas;
    Object.keys(entityTypesMap).forEach((key) => {
        const entityTypeSchema: IToxicGraphSchemaEntityTypeInfo = entityTypesMap[key];
        const modelPropsMap: IToxicGraphSchemaPropsMap = entityTypeSchema.properties;
        const incomingRelationsMap: IRelationToTypesInfosMap = entityTypeSchema.incomingRelationships || {};
        const outgoingRelationsMap: IRelationToTypesInfosMap = entityTypeSchema.outgoingRelationships || {};
        const relevantRelations: string[] = [
            ...Object.keys(incomingRelationsMap),
            ...Object.keys(outgoingRelationsMap),
        ];
        const nativeTypes: string[] = modelPropsMap?.nativeType?.enum || [];
        const propertiesMap: IEntityPropertiesMap = getPropertiesMap(modelPropsMap);
        const info: IEntityTypeInfo = {
            name: key,
            isEnvironmentRelated: !entityTypeSchema.tenantLevel,
            isGroupEntity: !!entityTypeSchema.groupEntity,
            nativeTypes,
            iconProps: getIconProps(key, nativeTypes),
            incomingRelationsMap,
            outgoingRelationsMap,
            relevantRelations,
            propertiesMap,
            filterDefs: getFilerDefs(propertiesMap),
        };
        toxicGraphSchema.entityTypesMap[key] = info;
        addToConnectionsPairsMap(info);
        relevantRelations.forEach((relation) => {
            toxicGraphSchema.relationsMap[relation] = {
                name: relation,
                parentToChildText: schemaRelationInfosMap[relation]?.parentToChildText || relation,
                childToParentText: schemaRelationInfosMap[relation]?.childToParentText || relation,
            };
        });
    });
}

let isSchemaPrepared = false;
export const getToxicSchema = (): IToxicGraphSchema => {
    if (!isSchemaPrepared) {
        prepareToxicGraphSchema();
        isSchemaPrepared = true;
    }
    return toxicGraphSchema;
};
