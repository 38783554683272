import React, { useCallback, useEffect, useState } from 'react';
import { Button, Typography } from 'common/design-system/components-v2';
import { IEvidenceInfo, IIssue } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace, VULNERABILITIES_INNER_TAB_INDEX } from 'modules/riskManagement/consts';
import { VulnerabilitiesStyled } from './IssueVulnerabilities.styled';
import { IssueVulnerabilitiesTable } from './IssueVulnerabilitiesTable';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { ASSETS_TAB_NAMES, IProtectedAssetDetailPanelProvider } from 'common/module_interface/assets/ProtectedAssets';
import { gotoAssetPage } from 'modules/riskManagement/components/Issues/Issues.utils';
import useVulnerabilities from './useVulnerabilities';
import Stack from 'common/design-system/components-v2/Stack';
import { ProtectedAssetsPageRegistry } from 'common/module_interface/assets/ProtectedAssetsPageRegistry';
import { LoadingState } from 'common/interface/general';
import FullSizeSpinner from 'common/erm-components/custom/FullSize/FullSizeSpinner';
import { ermTrans } from '../../../../../../RiskManagement.utils';
import FindingSeveritySummary from '../FindingSeveritySummary/FindingSeveritySummary';

export const ExtraInfo: React.FC<{ issue: IIssue; asset: IProtectedAssetViewModel; evidenceInfo?: IEvidenceInfo }> = ({
    issue,
    asset,
    evidenceInfo,
}) => {
    const { itemsInfo } = useVulnerabilities(issue, asset);
    const { t } = useTranslation(I18nRiskNamespace);
    const [navigationEnabled, setNavigationEnabled] = useState(false);

    const onSeeAllClicked = () => {
        gotoAssetPage(issue, asset, ASSETS_TAB_NAMES.VULNERABILITIES_TAB_NAME, VULNERABILITIES_INNER_TAB_INDEX.CVES);
    };

    const isRelevant = useCallback(
        async (provider: IProtectedAssetDetailPanelProvider): Promise<boolean> => {
            if (!evidenceInfo) {
                return false;
            }
            if (typeof provider.isRelevant === 'boolean') {
                return provider.isRelevant;
            }
            if (Array.isArray(provider.isRelevant)) {
                return provider.isRelevant.includes(evidenceInfo.cloudEntity.typeByPlatform);
            }
            return provider.isRelevant(evidenceInfo.cloudEntity);
        },
        [evidenceInfo],
    );

    const setNavigationStatus = useCallback((): void => {
        if (evidenceInfo) {
            const panelsData = ProtectedAssetsPageRegistry.getPanels();
            const item = panelsData.find(
                (item) => item.getValue(evidenceInfo.cloudEntity).title === evidenceInfo.title,
            );
            if (item) {
                isRelevant(item).then((res) => setNavigationEnabled(res));
            } else {
                setNavigationEnabled(false);
            }
        } else {
            setNavigationEnabled(true);
        }
    }, [evidenceInfo, isRelevant]);

    useEffect(() => {
        void setNavigationStatus();
    }, [evidenceInfo, setNavigationStatus]);

    return (
        <Stack direction='row' justifyContent='space-between' alignItems='center' fullWidth>
            <FindingSeveritySummary criticalCount={itemsInfo.criticalCount} highCount={itemsInfo.highCount} />
            {navigationEnabled && (
                <Button onClick={onSeeAllClicked}>{t('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.SEE_ALL')}</Button>
            )}
        </Stack>
    );
};

export const IssueVulnerabilities: React.FC<{
    issue: IIssue;
    asset: IProtectedAssetViewModel;
    title: string;
    initiallyOpen?: boolean;
}> = ({ issue, asset }) => {
    const { itemsInfo } = useVulnerabilities(issue, asset);

    return (
        <VulnerabilitiesStyled.TopDiv>
            {itemsInfo.loadingState === LoadingState.IS_LOADING && (
                <VulnerabilitiesStyled.SpinnerDiv>
                    <FullSizeSpinner />
                </VulnerabilitiesStyled.SpinnerDiv>
            )}
            {itemsInfo.loadingState === LoadingState.LOADING_FAILED && (
                <VulnerabilitiesStyled.NoteDiv>
                    <Typography color={'alert'}>
                        {ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.FAILED_LOADING_CVES')}
                    </Typography>
                </VulnerabilitiesStyled.NoteDiv>
            )}
            {itemsInfo.loadingState === LoadingState.LOADING_SUCCEEDED && !itemsInfo.cveItems.length && (
                <VulnerabilitiesStyled.NoteDiv>
                    {ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.NO_CVES')}
                </VulnerabilitiesStyled.NoteDiv>
            )}
            {itemsInfo.loadingState === LoadingState.LOADING_SUCCEEDED && itemsInfo.cveItems.length > 0 && (
                <IssueVulnerabilitiesTable topItems={itemsInfo.cveItems} />
            )}
        </VulnerabilitiesStyled.TopDiv>
    );
};
