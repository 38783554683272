import { validLanguages } from 'common/services/translations/translations';
import i18n from 'i18next';
import { buildPath } from 'common/extensibility/AddinContainer';
import initializeRolesColumnDefs from './RolesColumnDefenitions';
import initializeRolesFilters from './RolesFiltersDefenitions';
import { getTableRegistry } from '../../SimpleTableFilterPage/SimpleGenericTableRegistry';
import { getAppRootRegistry } from 'common/interface/services';
import { DrawerRegistry } from 'common/components/DrawerInfra/Drawer/DrawerRegistry';
import { IDrawerContent, IDrawerHandlersIdMap } from 'common/components/DrawerInfra/Drawer/Drawer.interface';
import { DrawerBus } from 'common/components/DrawerInfra/Drawer/DrawerBus';
import { AddRoleDrawer } from './AddRoleDrawer';
import { lazy } from 'react';

export const I18nTranslationKey = 'Roles';
const PATH_ROLES_PREFIX = buildPath('roles', 'roles_settings');
export const PATH_ROLES_PAGE = buildPath(PATH_ROLES_PREFIX, 'page');
export const PATH_ROLES_PAGE_TABLE_COLUMNS = buildPath(PATH_ROLES_PAGE, 'columns');
export const PATH_ROLES_PAGE_TABLE_FILTERS = buildPath(PATH_ROLES_PAGE, 'filters');

export const initializeI18nRoles = async () => {
    return Promise.all(
        validLanguages.map(async (language) => {
            try {
                const contents = await require(`./languages/${language}/translation.json`);
                i18n.addResourceBundle(language, `${I18nTranslationKey}`, contents);
            } catch {
                //Nothing to do with missing translation files
            }
        }),
    );
};

export const rolesTableRegistry = getTableRegistry(PATH_ROLES_PAGE_TABLE_COLUMNS, PATH_ROLES_PAGE_TABLE_FILTERS);

const initGenericPage = (initializeColumnDefs: Function, initializeFilters: Function) => {
    initializeColumnDefs();
    initializeFilters();

    const RolesPageRoute = {
        condition: () => true,
        component: lazy(() => import('./RolesPage')),
        path: '/users-management/roles',
    };

    getAppRootRegistry().addRoutes([{ id: 'roles', content: RolesPageRoute }]);
};

export const ROLE_DRAWER = {
    key: 'RoleDrawer',
    eventTypes: {
        roleChanged: 'roleChanged',
    },
};
export const ROLE_CHANGED_HANDLER_ID = 'ROLE_CHANGED_HANDLER';

const initializeRoleDrawer = () => {
    DrawerRegistry.addContentProvider({
        id: ROLE_DRAWER.key,
        getDrawerContent: (
            drawerContent: IDrawerContent,
            handlersIdMap?: IDrawerHandlersIdMap,
        ): Promise<IDrawerContent> => {
            const onRoleChangeCallBack = () => {
                DrawerBus.sendEvent(handlersIdMap![ROLE_DRAWER.eventTypes.roleChanged]);
            };
            const result = {
                title: drawerContent.title,
                component: AddRoleDrawer,
                componentProps: { ...drawerContent.componentProps, onRoleChangeCallBack: onRoleChangeCallBack },
            };
            //@ts-ignore
            return Promise.resolve(result);
        },
        options: {
            hasHeaderLineSeparator: true,
            width: 'lg',
        },
    });
};

export const initializeRoles = () => {
    initializeRoleDrawer();
    initGenericPage(initializeRolesColumnDefs, initializeRolesFilters);
};
