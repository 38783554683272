import { getHttpService, IExclusionsService } from 'common/interface/services';
import { generalApiError } from 'common/utils/http';
import { IFieldFilter, IRuntimeRequestPayload } from 'common/interface/exclusion';
import { AxiosResponse } from 'axios';

export class ExclusionsService implements IExclusionsService {
    async getExclusionList(useCache = false) {
        return await getHttpService().get<any>({
            path: 'compliance/Exclusion',
            cachingConfig: { useCache },
        });
    }
    async getRuleSets(useCache = true) {
        return await getHttpService().get<any>({
            path: 'Compliance/Ruleset/view',
            cachingConfig: { useCache },
        });
    }
    async getOrganizationalUnit(useCache = true) {
        return await getHttpService().get<any>({
            path: 'organizationalUnit/GetFlatOrganizationalUnits',
            cachingConfig: { useCache },
        });
    }
    async getEnvironment(useCache = true) {
        return await getHttpService().get<any>({
            path: 'view/common/all-cloud-accounts',
            cachingConfig: { useCache },
        });
    }
    async getVendorRegions(vendor: string, useCache = true) {
        return await getHttpService().get<any>({
            path: `regions/exclusion?platform=${vendor}`,
            cachingConfig: { useCache },
        });
    }
    async getRulesForRuleset(rulesetId: number, useCache = true) {
        return await getHttpService().get<any>({
            path: `Compliance/Ruleset/${rulesetId}`,
            cachingConfig: { useCache },
        });
    }
    async getProtectedAssets(freeText: string, fields?: IFieldFilter[] | null) {
        const defaultField = [
            {
                name: 'organizationalUnitId',
                value: '00000000-0000-0000-0000-000000000000',
            },
        ];
        const reqObj = {
            pageSize: 100,
            filter: {
                fields: fields || defaultField,
                freeTextPhrase: freeText,
            },
            externalAdditionalFields: {
                source: 'ThirdPartyMs',
            },
            skipAggregations: true,
        };

        const response = await getHttpService().request<AxiosResponse<any>>(
            'protected-asset/search',
            { method: 'POST', data: reqObj },
            { returnAsAxiosResponse: true },
            (err) => {
                throw err;
            },
        );
        return response.data;
    }
    async deleteExclusion(exclusionId: string) {
        return await getHttpService().delete<void>(`compliance/Exclusion?id=${exclusionId}`);
    }
    async deleteMultiExclusions(exclusionsId: any) {
        return new Promise((resolve, reject) => {
            getHttpService()
                .request<any>(
                    'compliance/Exclusion/BulkDelete',
                    {
                        method: 'POST',
                        data: exclusionsId,
                    },
                    {},
                    (error) => {
                        reject(error);
                    },
                )
                .then((resp) => {
                    resp && resolve(resp);
                });
        });
    }

    async saveExclusionCspm(exclusionConfig: any): Promise<void> {
        return await getHttpService().request(
            'compliance/Exclusion',
            {
                method: exclusionConfig.id ? 'PUT' : 'POST',
                data: exclusionConfig,
            },
            {},
            generalApiError,
        );
    }

    async saveExclusionCiem(exclusionConfig: any): Promise<void> {
        return await getHttpService().request(
            `ciem/exclusion${exclusionConfig.id ? `?id=${exclusionConfig.id}` : ''}`,
            {
                method: exclusionConfig.id ? 'PUT' : 'POST',
                data: exclusionConfig,
            },
            {},
            generalApiError,
        );
    }

    async saveExclusionRuntimeServerless(exclusionConfig: IRuntimeRequestPayload): Promise<void> {
        const { cloudAccountId, vendor, requestPayload } = exclusionConfig;
        return await getHttpService().request(
            `workload/runtimeProtection/${vendor}/accounts/${cloudAccountId}/exclusions`,
            {
                method: 'POST',
                data: requestPayload,
            },
            {},
            generalApiError,
        );
    }
}
