import {
    renderAddFilter,
    renderClearAll,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter,
    renderSavedFilters,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';

import { Addin } from 'common/extensibility/AddinRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import i18n from 'common/services/translations/translations';
import { I18nTranslationKey, serviceAccountsTableRegistry } from './initServiceAccountsPage';
import { AggregationOption, IDataItem, IFilterParams } from '../../SimpleTableFilterPage/interfaces';
import { getRolesService, getServiceAccountsService, IServiceAccount } from 'common/interface/services';
import { filterDataForMultiSelect, filterNameDataForFreeText } from '../../SimpleTableFilterPage/commonFilters';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';

export const ROLE = 'role';

export interface IFilter {
    key: string;
    options: AggregationOption[];
    getAllOptionsFromServiceAccount: (role: IServiceAccount) => string[] | boolean[] | null | undefined;
}

export const filterIds: { id: string }[] = [
    { id: FILTERS_KEYS.ADD_FILTER },
    { id: FILTERS_KEYS.FREE_TEXT },
    { id: ROLE },
    { id: FILTERS_KEYS.SAVED_FILTERS },
    { id: FILTERS_KEYS.CLEAR_BUTTON },
];
export const savedFiltersComponentName = 'serviceAccountsSavedFilters';
export const filterId = 'serviceAccountsFilter';

export const dataService = {
    getById(id: string): Promise<IDataItem | undefined> {
        return Promise.resolve(undefined);
    },
    update(data: IDataItem): Promise<void> {
        return Promise.resolve(undefined);
    },
    getAll: async (useCache: boolean): Promise<any[]> => {
        const serviceAccounts = await getServiceAccountsService().getServiceAccounts(useCache);
        const roles = await getRolesService().getRoles(useCache);
        for (const serviceAccount of serviceAccounts) {
            serviceAccount.rolesNames = roles
                .filter((role) => serviceAccount.roleIds.includes(role.id))
                .map((role) => role.name);
        }
        return serviceAccounts;
    },
    delete: async (id: string): Promise<void> => {
        try {
            return getServiceAccountsService().deleteServiceAccounts(id);
        } catch (e) {
            throw new Error('Failed to delete service account');
        }
    },
};

export const calcFilterData = (filtersValues: any, rawData: any) =>
    rawData.filter(
        (data: any) =>
            filterNameDataForFreeText(data, filtersValues[FILTERS_KEYS.FREE_TEXT]) &&
            filterDataForMultiSelect(filtersValues[ROLE], data['rolesNames']),
    );

export const getAggregations = async (filtersValues: IFiltersValues, data: any): Promise<Aggregations> => {
    const rolesFilter: IFilter = {
        key: ROLE,
        options: [],
        getAllOptionsFromServiceAccount: (serviceAccount: IServiceAccount) => {
            return serviceAccount.rolesNames;
        },
    };

    const aggregations = [rolesFilter];

    for (const serviceAccount of data) {
        for (const aggregation of aggregations) {
            const optionsFromRole = aggregation.getAllOptionsFromServiceAccount(serviceAccount);
            optionsFromRole?.forEach((option) => {
                let isOptionExist = false;
                aggregation.options.forEach((filterOption) => {
                    if (option === filterOption.value) {
                        filterOption.count++;
                        isOptionExist = true;
                    }
                });
                !isOptionExist && aggregation.options.push({ value: option, count: 1 });
            });
        }
    }
    return { [ROLE]: rolesFilter.options };
};

const filterDefsAddins: () => Addin<IFilterProps>[] = () => [
    {
        id: FILTERS_KEYS.ADD_FILTER,
        content: {
            filterProps: { key: FILTERS_KEYS.ADD_FILTER },
            renderFunction: renderAddFilter,
        },
    },
    {
        id: FILTERS_KEYS.FREE_TEXT,
        content: {
            filterProps: {
                key: FILTERS_KEYS.FREE_TEXT, //This needs to be the same as in stored filter/query params
                title: i18n.t('FILTERS.FREE_TEXT', { ns: I18nTranslationKey }),
            },
            renderFunction: renderDefaultFreeTextFilter,
        },
    },
    {
        id: FILTERS_KEYS.SAVED_FILTERS,
        content: function ({ filtersInitialData, savedFiltersComponentName }) {
            return {
                filterProps: {
                    savedFilters: filtersInitialData?.savedFilters || [],
                    savedFiltersComponentName,
                    selectedFilterID: '',
                    key: FILTERS_KEYS.SAVED_FILTERS,
                },
                renderFunction: renderSavedFilters,
            };
        },
    },
    {
        id: FILTERS_KEYS.CLEAR_BUTTON,
        content: {
            filterProps: {
                key: FILTERS_KEYS.CLEAR_BUTTON,
            },
            renderFunction: renderClearAll,
        },
    },
    {
        id: ROLE,
        content: function ({ aggregations }: IFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations[ROLE],
                    key: ROLE,
                    title: i18n.t('FILTERS.ROLE', { ns: I18nTranslationKey }),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
];

export default function initializeRolesFilters() {
    serviceAccountsTableRegistry.addFilterAddins(filterDefsAddins());
}
