import { IApplicationReducer } from 'common/interface/redux';
import rulesManagementReducer from './RulesManagement.reducer';
import { IReactPageRoute } from 'common/interface/routing';
import { lazy } from 'react';
import { getAppRootRegistry } from 'common/interface/services';

export default function initializeRulesManagement() {
    const reducers: IApplicationReducer[] = [
        { name: 'rulesManagement', isBlackList: false, reducer: rulesManagementReducer },
    ];
    getAppRootRegistry().addReducers(reducers, 'name');

    const rulesManagementPageRoute: IReactPageRoute = {
        condition: true,
        component: lazy(() => import('./RulesManagement')),
        path: '/compliance-engine/rules',
    };

    const rulesManagementAddPageRoute: IReactPageRoute = {
        condition: true,
        component: lazy(() => import('./AddEditRule')),
        path: '/compliance-engine/rules/rule',
    };

    getAppRootRegistry().addRoutes([
        { id: 'ADD_EDIT_RULE', content: rulesManagementAddPageRoute },
        { id: 'RULES', content: rulesManagementPageRoute },
    ]);
}
