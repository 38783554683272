import React from 'react';
import { Icon, Spinner, Stack, Tooltip, Typography } from 'common/design-system/components-v2';
import { FixItActionStyles as Styles } from './FixItActionButton.styled';
import { IFixItActionButtonProps } from './FixItActionButton.types';

const FixItActionButton: React.FC<IFixItActionButtonProps> = (props: IFixItActionButtonProps) => {
    const { label, tooltip, loading, iconProps, color, bgColor = 'transparent', onClick, closeButtonInfo } = props;

    return (
        <Styles.ActionWrapper
            direction={'row'}
            alignItems={'center'}
            padding={[2, 4]}
            spacing={2}
            overflow='hidden'
            bgColor={bgColor}
        >
            <Tooltip content={tooltip}>
                <Styles.ActionContentWrapper
                    direction={'row'}
                    alignItems={'center'}
                    spacing={2}
                    overflow='hidden'
                    onClick={onClick}
                >
                    {iconProps && !loading && <Icon {...iconProps} />}
                    {loading && (
                        <Stack justifyContent='center' alignItems='center'>
                            <Spinner size={iconProps?.size || 16} />
                        </Stack>
                    )}
                    <Typography variant='body500' color={color} textDecoration='underline' ellipsis>
                        {label}
                    </Typography>
                </Styles.ActionContentWrapper>
            </Tooltip>
            {closeButtonInfo && (
                <Tooltip content={closeButtonInfo.tooltip}>
                    <Styles.CloseButtonIconWrapper onClick={closeButtonInfo.onClick}>
                        <Icon name='remove' size={12}></Icon>
                    </Styles.CloseButtonIconWrapper>
                </Tooltip>
            )}
        </Styles.ActionWrapper>
    );
};
export default FixItActionButton;
