export const ALERTS = 'alerts';
export const FINDINGS_OVERVIEW_DRAWER_ID = 'findings-overview-drawer';

export const FindingColumns = {
    ACTIONS: 'Actions',
};

export enum FindingsActions {
    FIX_IT = 'FixIt',
    REMEDIATE_CDR = 'RemediateCdr',
    REMEDIATE_CIEM = 'RemediateCiem',
    REPORT_ISSUE = 'ReportIssue',
    ACKNOWLEDGE = 'Acknowledge',
    COMMENT = 'Comment',
    CLOSE = 'Close',
    CHANGE_SEVERITY = 'ChangeSeverity',
    ASSIGN_USER = 'AssignUser',
    EXCLUDE_CDR = 'ExcludeCdr',
    EXCLUDE_CIEM = 'ExcludeCiem',
    ARCHIVE = 'Archive',
}

export enum ModalType {
    FixIt = 'FixIt',
    BotStatusCheck = 'BotStatusCheck',
    RemediateCdr = 'RemediateCdr',
    RemediateCiem = 'RemediateCiem',
    ReportIssue = 'ReportIssue',
    Acknowledge = 'Acknowledge',
    ExcludeCiem = 'ExcludeCiem',
    Comment = 'Comment',
    Close = 'Close',
    ChangeSeverity = 'ChangeSeverity',
    AssignUser = 'AssignUser',
    Archive = 'Archive',
}

export const REMEDIATION = 'remediation';

export enum RemediationType {
    Feedback = 'feedback',
}

export enum RightPanelItemType {
    ExploreLogs = 'explore-logs',
}

export enum FindingOverviewPanelsIds {
    MITRE_PANEL = 'mitre-panel',
    DESCRIPTION_PANEL = 'description-panel',
    VISUALIZATION_PANEL = 'visualization-panel',
    BUNDLE_NAME_PANEL = 'bundle-name-panel',
    GSL_PANEL = 'gsl-panel',
    ALERT_ID_PANEL = 'alert-id-panel',
    COMMENTS_PANEL = 'comments-panel',
    UPPER_PANEL = 'upper-panel',
    ASSET_PANEL = 'asset-panel',
    EXPLORE_LOGS_PANEL = 'explore-logs-panel',
}

export enum FindingOverviewUpperPanelsIds {
    DATE_PANEL = 'date-panel',
    SEVERITY_PANEL = 'severity-panel',
    ENTITY_PANEL = 'entity-panel',
    LOG_TYPE_PANEL = 'log-type-panel',
    ACKNOWLEDGED_PANEL = 'acknowledged-panel',
    EXCLUDED_PANEL = 'excluded-panel',
}
