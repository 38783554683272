import React, { useCallback, useMemo } from 'react';
import { Stack } from 'common/design-system/components-v2';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { FILTERS_API_OBJECT_KEYS, FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import {
    renderAddFilter,
    renderClearAll,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { FlatPackageAndCve } from 'modules/workloads/services/vulnerability/vulnerability.interface';
import { IRowNode } from 'ag-grid-community';
import { aggregatePackageInUseValues, aggregatePackageNameVersionValues } from '../utils';
import { EBooleanString } from 'modules/workloads/utils/aggrid';
import { getUserService } from 'common/interface/services';

export interface FilterPanelVulCveProps {
    data: Array<FlatPackageAndCve>;
    onFilterChange: (filters: IFiltersValues) => void;
    getRefElementTextFree?: (refElementTextFree: string) => void;
}

export const customTransform: Record<string, (value: IRowNode['data']) => string> = {
    knownExploit: (value) => (value['knownExploit'] === null ? 'null' : `${value['knownExploit']}`),
    packageNameVersion: (value) => `${value.packageName}:${value.packageVersion}`,
    packageInUse: (value) => (value.packageInUse ? EBooleanString.TRUE : 'N/A'),
};

const FilterPanelVulnerabilityCve: React.FC<FilterPanelVulCveProps> = ({ data, onFilterChange }) => {
    const getAggregations = useCallback(
        async (filtersValues?: IFiltersValues): Promise<Aggregations> => {
            if (filtersValues) {
                onFilterChange(filtersValues);
            }

            const dataAgg = data.reduce<{ [key: string]: Array<{ value: string; count: number }> }>((acc, cves) => {
                Object.keys(cves).forEach((key) => {
                    let value = cves[key as keyof FlatPackageAndCve];
                    if (typeof value === 'object') {
                        const transfromFunct = customTransform[key];
                        if (transfromFunct === undefined) {
                            return;
                        }
                        value = customTransform[key](cves);
                    }
                    if (typeof value === 'boolean') {
                        value = value.toString();
                    }
                    if (!acc[key]) {
                        acc[key] = [
                            {
                                value: value,
                                count: 1,
                            },
                        ];
                    } else {
                        const findItem = acc[key].find((item) => item.value === value);
                        if (findItem) {
                            findItem.count++;
                        } else {
                            acc[key].push({
                                value: value,
                                count: 1,
                            });
                        }
                    }
                });
                return acc;
            }, {});

            return {
                ...dataAgg,
                packageNameVersion: aggregatePackageNameVersionValues(data),
                packageInUse: aggregatePackageInUseValues(data),
            };
        },
        [data, onFilterChange],
    );

    const buildFilterPropsByProperty = useCallback(
        (property: keyof FlatPackageAndCve) => {
            const groupBy: Record<string, { displayText: string }> = {};
            data.forEach((item) => {
                let key = item[property];
                let displayText = key;

                if (typeof key === 'boolean') {
                    if (property === 'isFixedByPackageRemediation') {
                        displayText = key ? 'Fixable' : 'Not Fixable';
                    } else {
                        key = key.toString();
                    }
                }
                if (typeof key === 'object') {
                    const transformFunct = customTransform[property];
                    if (transformFunct === undefined) {
                        return;
                    }
                    key = transformFunct(item);
                }
                if (!groupBy[key]) {
                    groupBy[key] = { displayText: displayText };
                }
            });
            return groupBy;
        },
        [data],
    );

    const buildRelatedIdsFilter = useCallback(() => {
        const groupBy: Record<string, { displayText: string }> = {};
        data.forEach((item) => {
            item.relatedIds.forEach((relatedId) => {
                if (!groupBy[relatedId]) {
                    groupBy[relatedId] = { displayText: relatedId };
                }
            });
        });
        return groupBy;
    }, [data]);

    const buildRelatedIdsCountArray = useCallback(() => {
        const countMap: { [key: string]: { value: string; count: number } } = {};
        data.forEach((obj) => {
            obj.relatedIds.forEach((relatedId) => {
                if (!countMap[relatedId]) {
                    countMap[relatedId] = {
                        value: relatedId,
                        count: 1,
                    };
                } else {
                    countMap[relatedId].count++;
                }
            });
        });
        return Object.keys(countMap).map((value) => ({
            value,
            count: countMap[value],
        }));
    }, [data]);

    const buildValueCountArray = useCallback(
        (property: keyof FlatPackageAndCve) => {
            const countMap: { [key: string]: { value: any; count: number } } = {};

            data.forEach((obj) => {
                const transformFunct = customTransform[property];
                const value = transformFunct ? transformFunct(obj) : obj[property];
                if (!countMap[value]) {
                    countMap[value] = {
                        value,
                        count: 1,
                    };
                } else {
                    countMap[value].count++;
                }
            });

            return Object.keys(countMap).map((value) => ({
                value,
                count: countMap[value],
            }));
        },
        [data],
    );

    const filterPanel = useMemo(() => {
        return initFilterPanel({
            filterPanelElementsList: [
                {
                    filterProps: { key: FILTERS_KEYS.ADD_FILTER },
                    renderFunction: renderAddFilter,
                },
                {
                    filterProps: {
                        key: 'packageNameVersion',
                        title: 'Package Name & Version',
                        initialData: aggregatePackageNameVersionValues(data),
                        displayMapping: data.reduce<Record<string, { displayText: string }>>((acc, row) => {
                            const key = `${row.packageName}:${row.packageVersion}`;
                            acc[key] = { displayText: key };
                            return acc;
                        }, {}),
                    },
                    renderFunction: renderMultiSelectFilter,
                },
                ...(getUserService().hasPermission(['workloads_vlm_in_use'])
                    ? [
                          {
                              filterProps: {
                                  key: 'packageInUse',
                                  title: 'In Use',
                                  initialData: aggregatePackageInUseValues(data),
                                  displayMapping: data.reduce<Record<string, { displayText: string }>>((acc, row) => {
                                      const key = Boolean(row.packageInUse) ? EBooleanString.TRUE : 'N/A';
                                      acc[key] = { displayText: key };
                                      return acc;
                                  }, {}),
                              },
                              renderFunction: renderMultiSelectFilter,
                          },
                      ]
                    : []),
                {
                    filterProps: {
                        key: 'relatedIds',
                        title: 'Related IDs',
                        initialData: buildRelatedIdsCountArray(),
                        displayMapping: buildRelatedIdsFilter(),
                    },
                    renderFunction: renderMultiSelectFilter,
                },
                {
                    filterProps: {
                        key: 'severity',
                        title: 'Cves Severity',
                        initialData: buildValueCountArray('severity'),
                        displayMapping: buildFilterPropsByProperty('severity'),
                    },
                    renderFunction: renderMultiSelectFilter,
                },
                {
                    filterProps: {
                        key: 'isFixedByPackageRemediation',
                        title: 'Fixable',
                        initialData: buildValueCountArray('isFixedByPackageRemediation'),
                        displayMapping: buildFilterPropsByProperty('isFixedByPackageRemediation'),
                    },
                    renderFunction: renderMultiSelectFilter,
                },
                {
                    filterProps: {
                        key: 'packageType',
                        title: 'Package Type',
                        initialData: buildValueCountArray('packageType'),
                        displayMapping: buildFilterPropsByProperty('packageType'),
                    },
                    renderFunction: renderMultiSelectFilter,
                },
                {
                    filterProps: {
                        key: 'packageSeverity',
                        title: 'Package Severity',
                        initialData: buildValueCountArray('packageSeverity'),
                        displayMapping: buildFilterPropsByProperty('packageSeverity'),
                    },
                    renderFunction: renderMultiSelectFilter,
                },
                {
                    filterProps: {
                        key: 'packageIs-os-package',
                        title: 'Is OS Package',
                        // @ts-ignore
                        initialData: buildValueCountArray('packageIs-os-package'),
                        // @ts-ignore
                        displayMapping: buildFilterPropsByProperty('packageIs-os-package'),
                    },
                    renderFunction: renderMultiSelectFilter,
                },
                {
                    filterProps: {
                        key: 'knownExploit',
                        title: 'Known Exploit',
                        initialData: buildValueCountArray('knownExploit'),
                        displayMapping: buildFilterPropsByProperty('knownExploit'),
                    },
                    renderFunction: renderMultiSelectFilter,
                },
                {
                    filterProps: {
                        key: 'isExcluded',
                        title: 'Is Excluded',
                        initialData: buildValueCountArray('isExcluded'),
                        displayMapping: buildFilterPropsByProperty('isExcluded'),
                    },
                    renderFunction: renderMultiSelectFilter,
                },
                {
                    filterProps: {
                        key: FILTERS_KEYS.CLEAR_BUTTON,
                    },
                    renderFunction: renderClearAll,
                },
                {
                    filterProps: {
                        key: FILTERS_KEYS.FREE_TEXT,
                        title: 'Free Text',
                    },
                    keyInObjectForAPI: FILTERS_API_OBJECT_KEYS.FREE_TEXT,
                    renderFunction: renderDefaultFreeTextFilter,
                },
            ],
            getAggregations: getAggregations,
            shouldBuildObjectForAPI: false,
            filterId: 'filter-panel-vulnerability',
        });
    }, [
        data,
        buildRelatedIdsCountArray,
        buildRelatedIdsFilter,
        buildValueCountArray,
        buildFilterPropsByProperty,
        getAggregations,
    ]);

    return <Stack margin={[6, 0, 0, 0]}>{filterPanel}</Stack>;
};

export default FilterPanelVulnerabilityCve;
