/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { PropsValue } from 'react-select';
import { OptionType } from 'common/design-system/components-v2/Select';
import { Label, Select, Stack, Typography } from 'common/design-system/components-v2';
import { I18nRemediation } from '../../helpers/remediation.consts';
import { useTranslation } from 'react-i18next';
import { IconProps } from 'common/design-system/components-v2/Icon/Icon.types';
import { getCloudAccountsService, ICloudAccount } from 'common/interface/data_services';
import { RemediateByEntityOptionProps } from '../../helpers/remediation.interfaces';
const RemediateByEnvironmentOption: FC<RemediateByEntityOptionProps> = ({
    isEnabled,
    selectedRuleSet,
    onChange,
    selectedOption,
    formValidations,
}) => {
    const { t } = useTranslation(I18nRemediation);
    const [selectedEnvironment, setSelectedEnvironment] = useState<OptionType | null>(null);
    const [accountsList, setAccountsList] = useState<{ label: string; value: string; iconProps?: IconProps }[]>([]);

    const filterAccountsByVendor = (cloudAccountsList: ICloudAccount[]) => {
        return cloudAccountsList
            .filter((account: ICloudAccount) => account.platform === (selectedRuleSet?.platform || ''))
            .map((account: ICloudAccount) => ({
                label: account.name,
                value: account.id,
                iconProps: { vendorNameOrPath: account.platform },
            }));
    };

    async function fetchAccounts() {
        const cloudAccountsList = await getCloudAccountsService().getAllCloudAccounts();
        if (selectedRuleSet) {
            const accounts = filterAccountsByVendor(cloudAccountsList);
            setAccountsList(accounts);
            if (selectedOption) {
                const selectedAccount = accounts.find((acc) => acc.value === selectedOption);
                if (selectedAccount) {
                    setSelectedEnvironment(selectedAccount);
                    onChange(selectedAccount);
                }
            } else {
                setSelectedEnvironment(null);
                onChange(null);
            }
        } else {
            setSelectedEnvironment(null);
            setAccountsList([]);
            onChange(null);
        }
    }

    useEffect(() => {
        fetchAccounts();
    }, [selectedRuleSet, selectedOption]);

    useEffect(() => {
        fetchAccounts();
    }, []);

    const onSelectedEnvironment = (newValue: PropsValue<OptionType>) => {
        setSelectedEnvironment(newValue as OptionType);
        onChange(newValue as OptionType);
    };

    return (
        // TODO: refactor to SelectV2
        <Stack spacing={1}>
            <Label text={t('REMEDIATION.MODALS.ADD_EDIT_MODAL.REMEDIATE_BY_ENVIRONMENT')} />
            <Select
                isDisabled={!isEnabled}
                options={accountsList}
                isMulti={false}
                value={selectedEnvironment}
                onChange={onSelectedEnvironment}
            />
            {formValidations && (
                <Typography color='alert' variant='bodyXs'>
                    {formValidations?.[0]?.message}
                </Typography>
            )}
        </Stack>
    );
};
export default RemediateByEnvironmentOption;
