import { IAffectedAsset } from '../PackageDrawer.types';
import { DatasourceEngine } from './datasourceEngine';
import { protectedAssetHandler } from './handlers';
import { SearchGroupManager } from './searchGroupManager';
import { SearchGroup } from './types';

export const createAffectedAssetsDataSource =
    (affectedAssetMap: Map<string, IAffectedAsset>) => (searchGroup: SearchGroup) => {
        return new DatasourceEngine(protectedAssetHandler(new SearchGroupManager(searchGroup), affectedAssetMap));
    };
