import { IRole, getHttpService } from 'common/interface/services';
import { IBasicRole, IRolesService } from 'common/interface/rolesService';
import { IUser } from 'common/interface/user';

export class RolesService implements IRolesService {
    async getRoles(useCache = true): Promise<IRole[]> {
        return await getHttpService().get<IRole[]>({ path: 'role', cachingConfig: { useCache } });
    }

    async addRole(role: IBasicRole): Promise<IRole> {
        return await getHttpService().request<IRole>('role', { method: 'POST', data: role });
    }

    async getUsers(useCache = true): Promise<IUser[]> {
        return await getHttpService().get<IUser[]>({ path: 'user', cachingConfig: { useCache } });
    }

    async updateUser(user: IUser): Promise<IUser> {
        return await getHttpService().request<IUser>(`user/${user.id}`, { method: 'PUT', data: user });
    }

    async deleteRole(roleId: string): Promise<void> {
        return await getHttpService().request<void>(`role/${roleId}`, { method: 'DELETE' });
    }

    async updateRole(role: IRole): Promise<IRole> {
        return await getHttpService().request<IRole>(`role/${role.id}`, { method: 'PUT', data: role });
    }
}
