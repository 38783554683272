import React from 'react';
import { SbomGslService } from 'modules/workloads/services/vulnerability-search';
import { getFieldsFromAffectedAssetsMap, mapAssetsByDome9Id } from '../../PackageDrawer.utils';
import { getHttpService } from 'common/interface/services';
import { ProtectedAssetsResponse } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { ASSETS_SEARCH_URL } from 'common/module_interface/assets/AssetsConsts';
import { IAffectedAsset, TPackageDrawerParams } from '../../PackageDrawer.types';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { createAffectedAssetsDataSource } from '../../datasource/affectedAssetDatasource';
import AffectedAssetsPanel from './AffectedAssetsPanel';

export const ConnectedAffectedAssetsPanel: React.FC<TPackageDrawerParams> = ({ id }) => {
    const pageSize = 50;
    const [initialAggregations, setInitialAggregations] = React.useState<Aggregations>({});
    const [affectedAssetMap, setAffectedAssetMap] = React.useState(new Map<string, IAffectedAsset>());
    const initialFilterFields = React.useMemo(() => {
        return getFieldsFromAffectedAssetsMap(affectedAssetMap);
    }, [affectedAssetMap]);
    const affectedAssetDatasourceFactory = React.useMemo(
        () => createAffectedAssetsDataSource(affectedAssetMap),
        [affectedAssetMap],
    );
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        const init = async () => {
            setIsLoading(true);
            const affectedAssetsResponse = await SbomGslService.getPackageAffectedAssets({ id });
            const assetsMap = mapAssetsByDome9Id(affectedAssetsResponse);
            setAffectedAssetMap(assetsMap);
            const filterFields = getFieldsFromAffectedAssetsMap(assetsMap);
            const adHokSearchResults = await getHttpService().post<ProtectedAssetsResponse>(ASSETS_SEARCH_URL, {
                data: {
                    pageSize: 0,
                    filter: {
                        fields: filterFields,
                    },
                    skipAggregations: false,
                },
            });
            setInitialAggregations(adHokSearchResults.aggregations);
            setIsLoading(false);
        };
        init();
    }, [id]);

    return (
        <AffectedAssetsPanel
            isLoading={isLoading}
            affectedAssetDatasourceFactory={affectedAssetDatasourceFactory}
            filterFields={initialFilterFields}
            initialAggregation={initialAggregations}
            pageSize={pageSize}
        />
    );
};

export default ConnectedAffectedAssetsPanel;
