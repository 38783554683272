import { IPermissionItem, PERMISSION_TYPE, PermissionViewMode } from './interfaces';

export const getBorderStyle = (viewMode: PermissionViewMode | null = PermissionViewMode.EDIT): string => {
    return viewMode === PermissionViewMode.REVIEW ? 'none' : '1px solid #EBEEF4';
};

export const capitalize = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};

export const isPermissionItemsMatch = (permissionA: IPermissionItem, permissionB: IPermissionItem) => {
    return (
        (permissionA.cloudAccount?.id || '') === (permissionB.cloudAccount?.id || '') &&
        permissionA.type === permissionB.type &&
        permissionA.userAccount?.accountId === permissionB.userAccount?.accountId &&
        permissionA.organizationUnit?.id === permissionB.organizationUnit?.id &&
        (permissionA.userRole === permissionB.userRole ||
            (!permissionA.userRole && permissionB.userRole === '*') ||
            (!permissionB.userRole && permissionA.userRole === '*')) &&
        (permissionA.securityGroup?.securityGroupId || '') === (permissionB.securityGroup?.securityGroupId || '') &&
        (permissionA.region?.region || '') === (permissionB.region?.region || '') &&
        (permissionA.service?.id || '') === (permissionB.service?.id || '') &&
        (permissionA.agent?.id || '') === (permissionB.agent?.id || '') &&
        (permissionA.categorySubType || '') === (permissionB.categorySubType || '') &&
        (permissionA.type === PERMISSION_TYPE.PLATFORM && permissionB.type === PERMISSION_TYPE.PLATFORM
            ? permissionA.name === permissionB.name
            : true) &&
        (permissionA.type === PERMISSION_TYPE.ACCOUNT_ACCESS && permissionB.type === PERMISSION_TYPE.ACCOUNT_ACCESS
            ? permissionA.path?.join() === permissionB.path?.join()
            : true)
    );
};
export const rootEnvironmentPath = 'Environments';
export const rootAgentPath = 'Agents servers';
export const rootSecurityGroupAgentPath = 'Security groups agents';
export const rootOUPath = 'Organizational Units';
export const allSystemResources = 'All System Resources';

export const buildPathPhrase = (item: IPermissionItem) => {
    const permissionItem = item as IPermissionItem;
    let response: any = permissionItem.path || [];
    let basePath: string[] = [];
    if (permissionItem.type === PERMISSION_TYPE.PLATFORM) {
        response = permissionItem.path || [];
    }
    if (permissionItem.type === PERMISSION_TYPE.ORGANIZATIONAL_UNIT) {
        response = permissionItem.path || [];
    }
    if (permissionItem.cloudAccount) {
        basePath = [
            rootEnvironmentPath,
            capitalize(permissionItem.cloudAccount?.platform) || '',
            permissionItem.cloudAccount.name || '',
        ];
        response = basePath;
    }
    if (permissionItem.region) {
        basePath = [...basePath, permissionItem.region?.description || ''];
        response = basePath;
    }
    if (permissionItem.securityGroup) {
        basePath = [
            ...basePath,
            permissionItem.securityGroup?.vpcId + ' > ' + permissionItem.securityGroup?.securityGroupName || '',
        ];
        response = basePath;
    }
    if (permissionItem.type === PERMISSION_TYPE.SECURITY_GROUP_SERVICE) {
        basePath = [...basePath, permissionItem.service?.name || ''];
        response = basePath;
    }

    if (permissionItem.type === PERMISSION_TYPE.PLACEHOLDER) {
        response = [...basePath, null];
    }

    if (permissionItem.type === PERMISSION_TYPE.AGENT) {
        response = [rootAgentPath, permissionItem.agent?.name || ''];
    }
    if (permissionItem.type === PERMISSION_TYPE.AGENT_SERVICE) {
        response = [rootAgentPath, permissionItem.agent?.name || '', permissionItem.agentAccessPolicy?.name || ''];
    }
    if (permissionItem.type === PERMISSION_TYPE.SECURITY_GROUP_AGENT) {
        response = [rootSecurityGroupAgentPath, permissionItem.securityGroup?.securityGroupName || ''];
    }
    return response;
};

export const permissionSorter = (a: IPermissionItem, b: IPermissionItem) => {
    return buildPathPhrase(a).join().localeCompare(buildPathPhrase(b).join());
};
