import React from 'react';
import { EdgeProps } from 'reactflow';
import ExposureEdge from '../Components/ExposureEdge';

export enum ExposureEdgeType {
    ExposureEdge = 'ExposureEdge',
}

export const edgeTypes: Record<ExposureEdgeType, React.FC<EdgeProps>> = {
    [ExposureEdgeType.ExposureEdge]: ExposureEdge,
};
