import { GslRunRequest, GslRunService } from '../common/gsl-run';
import {
    GetExportCSV,
    GetFilterAggregations,
    GetPackageAffectedAssets,
    GetPackageLicenses,
    GetTopPackages,
    SearchVulnerabilitiesByPackageName,
} from './sbom-gsl.interface';
import { addFiltersToGslQuery } from '../common/utils';
import GslQuery from '../common/GslQuery';
import { Aggregations } from '../common/types';
import { GslQueryFilters } from '../common/gsl-run/gsl-run.interface';
import { paddingBackslash } from 'modules/workloads/utils';

export const searchSbomGslSummarizeFields = [
    'dcount(affectedAsset_dome9Id) as Assets',
    'make_set(licenses_values) as licenses_values',
    'arg_max(package_name,package_version,package_managerName) by package_id',
];

const getTopPackages: GetTopPackages.Function = async () => {
    const request: GslRunRequest = {
        gsl: 'sbom summarize dcount(affectedAsset_dome9Id) as Assets by package_name,package_version,package_managerName, package.id order by Assets',
        options: {
            source: 'sbom',
            limit: 20,
            decorators: ['ct-raw-data-decorator'],
        },
    };

    return await GslRunService.gslRun<GetTopPackages.Response>(request);
};

const searchGslQuery = (packageName: string, filters?: GslQueryFilters): GslQuery => {
    const gslQuery = new GslQuery()
        .from('sbom')
        .where(`package.name like '${packageName.replaceAll('*', '%')}'`)
        .summarize(...searchSbomGslSummarizeFields)
        .orderBy('Assets', 'desc');

    if (filters && filters.length > 0) {
        addFiltersToGslQuery({ gslQuery, filters });
    }
    return gslQuery;
};

const searchVulnerabilitiesByPackageName: SearchVulnerabilitiesByPackageName.Function = async (
    { packageName, filters },
    useCache = true,
) => {
    const gslQuery = searchGslQuery(packageName, filters);

    const request: GslRunRequest = {
        gsl: gslQuery.build(),
        options: {
            source: 'sbom',
            limit: 10000,
            decorators: ['ct-raw-data-decorator'],
        },
    };

    const timeout = 1000 * 60;

    return await GslRunService.gslRun<SearchVulnerabilitiesByPackageName.Response>(request, timeout, useCache);
};

const getFilterAggregations: GetFilterAggregations.Function = async (packageName, queryFilters) => {
    const aggregationFields = ['package.id', 'package.version', 'package.managerName'];
    const aggregateByField = async (aggregationField: string) => {
        const gslQuery = searchGslQuery(packageName, queryFilters);
        const withCountByAggField = `${gslQuery.build()} | summarize count() as Count by ${aggregationField}`;
        const request: GslRunRequest = {
            gsl: withCountByAggField,
            options: {
                source: 'sbom',
            },
        };

        return await GslRunService.gslRun<
            GetFilterAggregations.AssetAggregationsResponse<'package_id' | 'package_version' | 'package_managerName'>
        >(request);
    };

    try {
        const aggregationResponses = await Promise.all(aggregationFields.map((aggField) => aggregateByField(aggField)));
        const aggregations: Aggregations = aggregationResponses.reduce((accum, aggResponse) => {
            const key = Object.keys(aggResponse.data.cols)
                .find((col) => col.startsWith('package_'))
                ?.replace('_', '.');
            const aggData = aggResponse.data.data;
            if (key === undefined) {
                return accum;
            }
            return {
                ...accum,
                [key]: aggData.map(([value, count]) => ({ value, count, packageFields: { packageName } })),
            };
        }, {});

        return aggregations;
    } catch (error) {
        console.error('Failed fetching aggregation fields: ', error);
        return {};
    }
};

const getExportCsv: GetExportCSV.Function = async ({
    packageName,
    filters,
    activeColumns,
    summarizeFields,
    orderBy,
}) => {
    const gslQuery = new GslQuery().from('sbom').where(`package.name like '${packageName}'`);

    if (activeColumns && activeColumns.length > 0) {
        gslQuery.select(...activeColumns);
    }

    if (summarizeFields?.length) {
        gslQuery.summarize(...summarizeFields);
    }

    if (orderBy) {
        gslQuery.orderBy(orderBy.name, orderBy.order);
    }

    if (filters && filters.length > 0) {
        addFiltersToGslQuery({ gslQuery, filters });
    }

    const request: GslRunRequest = {
        gsl: gslQuery.build(),
        options: {
            source: 'sbom',
            limit: 10000,
            decorators: ['csv-data-decorator'],
        },
    };

    return await GslRunService.gslRun<GetExportCSV.Response>(request);
};

const getPackageLicenses: GetPackageLicenses.Function = ({ id }) => {
    const fields = [
        'package.name',
        'package.version',
        'package.managerName',
        'package.path',
        'isOsPackage',
        'licensesInfo',
    ];
    const request: GslRunRequest = {
        gsl: new GslQuery()
            .from('sbom')
            .select(...fields)
            .where(`package.id = '${paddingBackslash(id)}'`)
            .build(),
        options: {
            source: 'sbom',
            decorators: ['ct-raw-data-decorator'],
        },
    };
    return GslRunService.gslRun<GetPackageLicenses.Response>(request).then((response) => response.data);
};

const getPackageAffectedAssets: GetPackageAffectedAssets.Function = ({ id }) => {
    const fields = [
        'affectedAsset.dome9Id',
        'affectedAsset.entityType',
        'affectedAsset.entityName',
        'affectedAsset.externalId',
        'package.path',
        'licensesInfo',
    ];
    const gslRawQuery = new GslQuery()
        .from('sbom')
        .select(...fields)
        .where(`package.id = '${paddingBackslash(id)}'`)
        .build();
    const request: GslRunRequest = {
        gsl: gslRawQuery,
        options: {
            source: 'sbom',
            decorators: ['ct-raw-data-decorator'],
        },
    };
    return GslRunService.gslRun<GetPackageAffectedAssets.Response>(request);
};

const SbomGslService = {
    getTopPackages,
    searchVulnerabilitiesByPackageName,
    getExportCsv,
    getFilterAggregations,
    getPackageLicenses,
    getPackageAffectedAssets,
};

export default SbomGslService;
