import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import {
    Alert,
    Button,
    Checkbox,
    InputLabel,
    RadioButton,
    Spinner,
    Stack,
    Typography,
} from 'common/design-system/components-v2';
import { PlatformTypes } from 'common/common_types';
import { FspVersion, PatchAutoProtectModePayload } from 'modules/workloads/services/workload/workload.interface';
import { List, StyledSelect } from './ServerlessActions.styled';
import { BASE_TRANSLATION_KEY, getFunctionId, NOTIFICATION_TIMEOUT, showNotification } from './utils';
import WorkloadService from 'modules/workloads/services/workload/workload.service';
import ServerlessActionsModal from './ServerlessActionsModal';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { GridApi } from 'ag-grid-community';
import { ActionMode, ProtectionMode } from '../../types/actions.types';
import { getExternalAdditionalFieldValue } from 'common/module_interface/assets/utils';

interface AutoProtectActionProps {
    selectedRows: IProtectedAssetViewModel[];
    gridApi: GridApi;
}

const AutoProtectAction: React.FC<AutoProtectActionProps> = ({ selectedRows, gridApi }) => {
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const [selectedProtectionMode, setSelectedProtectionMode] = React.useState<ProtectionMode>(ProtectionMode.Disable);
    const [fspVersions, setFspVersions] = React.useState<FspVersion[]>([]);
    const [selectedFspVersion, setSelectedFspVersion] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isFetchingFspVersions, setIsFetchingFspVersions] = React.useState<boolean>(false);
    const [showAllVersions, setShowAllVersions] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string | null>(null);
    const { t } = useTranslation();

    const isDisabled = selectedRows.length === 0 || !selectedRows.every((item) => item.platform === PlatformTypes.aws);

    const runTimeEnvironments: string[] = useMemo(
        () =>
            selectedRows.reduce((acc: string[], item) => {
                const env = item.additionalFields?.find((field) => field.name === 'RuntimeEnvironment')?.value;
                if (env && !acc.includes(env)) {
                    acc.push(env);
                }
                return acc;
            }, []),
        [selectedRows],
    );

    const handleOnClick = () => {
        setIsModalOpen(true);
    };

    const handleOnCancel = () => {
        setIsModalOpen(false);
        resetModalState();
    };

    const resetModalState = () => {
        setSelectedProtectionMode(ProtectionMode.Disable);
        setFspVersions([]);
        setError(null);
        setIsFetchingFspVersions(false);
    };

    const handleOnConfirm = useCallback(async () => {
        const requestData: PatchAutoProtectModePayload = {
            functionsData: selectedRows.map((item) => ({
                functionId: getFunctionId(item),
                fspAutoInstrumentation: selectedProtectionMode === ProtectionMode.Enable ? 'true' : 'false',
                fspStickyVersion: selectedProtectionMode === ProtectionMode.Enable ? selectedFspVersion : undefined,
            })),
        };

        const accountId = selectedRows[0].cloudAccountId;
        setIsLoading(true);

        try {
            const response = await WorkloadService.patchAutoProtectMode(accountId, { request: requestData });
            const { data } = response;

            resetModalState();
            setIsModalOpen(false);
            showNotification(data, ActionMode.AutoProtect);

            gridApi.refreshServerSide();
            gridApi.deselectAll();
        } catch (err: unknown) {
            if (err instanceof Error) {
                getNotificationsService().addNotification({
                    type: NotificationType.ERROR,
                    title: t(`${BASE_TRANSLATION_KEY}.AUTO_PROTECT.ERROR`),
                    autoClose: NOTIFICATION_TIMEOUT,
                });
            }
        } finally {
            setIsLoading(false);
        }
    }, [selectedRows, selectedProtectionMode, selectedFspVersion, gridApi, t]);

    const fetchFspVersions = useCallback(async () => {
        setIsFetchingFspVersions(true);
        try {
            const res = await WorkloadService.getFspVersions();
            const data: FspVersion[] = res.data;
            const filteredData = data.filter(
                (item) => !item.unsupportedRuntimes?.some((runtime) => runTimeEnvironments.includes(runtime)),
            );
            const sortedData = filteredData.sort((a, b) => +a.fspVersion - +b.fspVersion);
            setFspVersions(sortedData);
            setSelectedFspVersion(sortedData[0].fspVersion);
        } catch (err: unknown) {
            if (err instanceof Error) {
                setError(err.message);
            }
        } finally {
            setIsFetchingFspVersions(false);
        }
    }, [runTimeEnvironments]);

    useEffect(() => {
        const isAllEnabled = selectedRows.every(
            (row) => getExternalAdditionalFieldValue(row, 'FSPStickyMode') === 'true',
        );
        setSelectedProtectionMode(isAllEnabled ? ProtectionMode.Disable : ProtectionMode.Enable);
    }, [selectedRows]);

    useEffect(() => {
        setError(null);
        if (selectedProtectionMode === ProtectionMode.Enable && fspVersions.length === 0) {
            fetchFspVersions();
        }
    }, [fetchFspVersions, fspVersions.length, selectedProtectionMode]);

    const fspOptions = useMemo(
        () =>
            fspVersions
                .map((item, index) => ({
                    label: item.fspVersion + (index === 0 ? ' latest' : ''),
                    value: item.fspVersion,
                }))
                .slice(0, showAllVersions ? fspVersions.length : 5),
        [fspVersions, showAllVersions],
    );

    return (
        <>
            <ServerlessActionsModal
                title={t(`${BASE_TRANSLATION_KEY}.AUTO_PROTECT.MODAL_TITLE`)}
                isModalOpen={isModalOpen}
                onClose={handleOnCancel}
                onConfirm={handleOnConfirm}
                isLoading={isFetchingFspVersions || isLoading}
                isError={error !== null}
            >
                <Stack spacing={3} direction='column'>
                    <Typography data-aid='modal-selected-items'>
                        {t(`${BASE_TRANSLATION_KEY}.AUTO_PROTECT.SELECTED_ITEMS`)}: {selectedRows.length}
                    </Typography>
                    <Stack direction='row' spacing={4} alignItems='center'>
                        <InputLabel text={t(`${BASE_TRANSLATION_KEY}.AUTO_PROTECT.MODE`)} />
                        <RadioButton
                            label={t(`${BASE_TRANSLATION_KEY}.AUTO_PROTECT.ENABLE`)}
                            onChange={() => setSelectedProtectionMode(ProtectionMode.Enable)}
                            checked={selectedProtectionMode === ProtectionMode.Enable}
                            dataAid='enable'
                        />
                        <RadioButton
                            label={t(`${BASE_TRANSLATION_KEY}.AUTO_PROTECT.DISABLE`)}
                            onChange={() => setSelectedProtectionMode(ProtectionMode.Disable)}
                            checked={selectedProtectionMode === ProtectionMode.Disable}
                            dataAid='disable'
                        />
                    </Stack>
                    {fspVersions.length > 0 &&
                    selectedProtectionMode === ProtectionMode.Enable &&
                    !isFetchingFspVersions ? (
                        <Stack direction='row' spacing={4} alignItems='center' data-aid='fsp-version-select'>
                            <InputLabel text={t(`${BASE_TRANSLATION_KEY}.AUTO_PROTECT.FSP_VERSION`)} />
                            <StyledSelect
                                options={fspOptions}
                                onChange={(value) => setSelectedFspVersion(value)}
                                isMulti={false}
                                defaultValue={selectedFspVersion}
                            />
                            <Checkbox
                                label={t(`${BASE_TRANSLATION_KEY}.AUTO_PROTECT.SHOW_ALL_VERSIONS`)}
                                checked={showAllVersions}
                                onChange={(e) => setShowAllVersions(e.target.checked)}
                            />
                        </Stack>
                    ) : null}
                    {isFetchingFspVersions ? <Spinner /> : null}
                    {error ? <Alert type='error'>{t(`${BASE_TRANSLATION_KEY}.AUTO_PROTECT.ERROR`)}</Alert> : null}
                    <Stack>
                        <List>
                            <li>{t(`${BASE_TRANSLATION_KEY}.AUTO_PROTECT.INSTRUCTIONS.1`)}</li>
                            <li>{t(`${BASE_TRANSLATION_KEY}.AUTO_PROTECT.INSTRUCTIONS.2`)}</li>
                            <li>{t(`${BASE_TRANSLATION_KEY}.AUTO_PROTECT.INSTRUCTIONS.3`)}</li>
                            <li>{t(`${BASE_TRANSLATION_KEY}.AUTO_PROTECT.INSTRUCTIONS.4`)}</li>
                        </List>
                    </Stack>
                </Stack>
            </ServerlessActionsModal>
            <Button disabled={isDisabled} onClick={handleOnClick} iconProps={{ name: 'sfp' }} variant='text'>
                {t(`${BASE_TRANSLATION_KEY}.AUTO_PROTECT.BUTTON`)}
            </Button>
        </>
    );
};

export default AutoProtectAction;
