import { DASHBOARD_DYNAMIC_WIDGETS } from 'common/module_interface/overview/Consts';
import { DashboardWidgetTypes, IAddinWidgetsDataSource } from 'common/module_interface/overview/Interface';
import { ILogicWidgetsOptions, createWidgets } from './Utils';
import { TRAFFIC_ACTIVITY_AZURE, defaultIntelligenceWidgetsTypeOptions } from './Consts';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';

export const initialize_AZURE_TrafficActivityWidgets = () => {
    const AZURE_trafficActivityOptions: ILogicWidgetsOptions = {
        account: defaultIntelligenceWidgetsTypeOptions,
        action: defaultIntelligenceWidgetsTypeOptions,
        category: defaultIntelligenceWidgetsTypeOptions,
        direction: defaultIntelligenceWidgetsTypeOptions,
        'dst.address': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.assetid': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.dome9.id': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.ispublic': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.name': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.nics.id': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.nics.privateipaddress': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.nics.publicipaddress': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.region': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.subtype': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.type': defaultIntelligenceWidgetsTypeOptions,
        'dst.geolocation.countryname': defaultIntelligenceWidgetsTypeOptions,
        'dst.ismalicious': defaultIntelligenceWidgetsTypeOptions,
        'dst.maliciousinfo.class': defaultIntelligenceWidgetsTypeOptions,
        'dst.maliciousinfo.confidence': defaultIntelligenceWidgetsTypeOptions,
        'dst.maliciousinfo.malwarefamily': defaultIntelligenceWidgetsTypeOptions,
        'dst.maliciousinfo.owner': defaultIntelligenceWidgetsTypeOptions,
        'dst.port': defaultIntelligenceWidgetsTypeOptions,
        'event.name': defaultIntelligenceWidgetsTypeOptions,
        macaddress: defaultIntelligenceWidgetsTypeOptions,
        protocol: defaultIntelligenceWidgetsTypeOptions,
        'src.address': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.assetid': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.dome9.id': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.ispublic': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.name': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.nics.id': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.nics.privateipaddress': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.nics.publicipaddress': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.region': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.subtype': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.type': defaultIntelligenceWidgetsTypeOptions,
        'src.geolocation.countryname': defaultIntelligenceWidgetsTypeOptions,
        'src.ismalicious': defaultIntelligenceWidgetsTypeOptions,
        'src.maliciousinfo.class': defaultIntelligenceWidgetsTypeOptions,
        'src.maliciousinfo.confidence': defaultIntelligenceWidgetsTypeOptions,
        'src.maliciousinfo.malwarefamily': defaultIntelligenceWidgetsTypeOptions,
        'src.maliciousinfo.owner': defaultIntelligenceWidgetsTypeOptions,
        'src.port': defaultIntelligenceWidgetsTypeOptions,
        starttime: defaultIntelligenceWidgetsTypeOptions,
        'stream.owner': defaultIntelligenceWidgetsTypeOptions,
        systemid: defaultIntelligenceWidgetsTypeOptions,
        'dst.geolocation.countrycode': [DashboardWidgetTypes.Map, DashboardWidgetTypes.MapLinks],
        'src.geolocation.countrycode': [DashboardWidgetTypes.Map, DashboardWidgetTypes.MapLinks],
    };

    const AZURE_TrafficActivityWidgets: IAddinWidgetsDataSource = {
        dataSourceName: TRAFFIC_ACTIVITY_AZURE,
        dataField: { displayName: 'Aggregation', path: 'aggregation' },
        widgets: createWidgets(AZURE_trafficActivityOptions),
    };

    globalAddinContainer.addMap(DASHBOARD_DYNAMIC_WIDGETS, [AZURE_TrafficActivityWidgets], 'dataSourceName');
};
