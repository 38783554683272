import React from 'react';
import { Table } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { getColumnDefs } from './AffectedAssetsTable.columns';
import EmptyState from 'common/components/EmptyState';
import { GridApi } from 'ag-grid-community';
import { IAffectedAssetsTableProps } from './AffectedAssetsTable.types';

const AffectedAssetsTable: React.FC<IAffectedAssetsTableProps> = ({ searchGroup, affectedAssetDatasourceFactory }) => {
    const { t } = useTranslation(getK8sNamespace('cve-explorer'));
    const [gridApi, setGridApi] = React.useState<GridApi>();

    React.useEffect(() => {
        gridApi?.setServerSideDatasource(affectedAssetDatasourceFactory(searchGroup));
    }, [affectedAssetDatasourceFactory, gridApi, searchGroup]);

    return (
        <Table
            pageSize={searchGroup.pageSize}
            gridOptions={{
                rowModelType: 'serverSide',
                rowBuffer: 0,
                maxConcurrentDatasourceRequests: 1,
                columnDefs: getColumnDefs(t),
                getChildCount: (dataItem) => dataItem?.childCount,
                noRowsOverlayComponent: () => (
                    <EmptyState iconSize={32} iconName='magnify' label={t('affectedAssetsTable.emptyState.label')} />
                ),
                onGridReady: (gridReadyEvent) => {
                    setGridApi(gridReadyEvent.api);
                },
            }}
        />
    );
};

export default AffectedAssetsTable;
