import { ICreateRemediationRequest } from 'common/module_interface/intelligence/Remediation/Remediation.interface';
import { getRemediationService } from 'common/module_interface/intelligence/Remediation/Remediation.service';
import { IRemediationCreationByEventProps } from '../Findings.interface';
import { ISearchFilterViewModel } from 'common/components/Findings/Findings.interface';
import { IAdditionalFilterFieldInfo } from 'common/components/KustoEvents/KustoEvent.interface';
import { FieldConvertorsRegistry, FieldEntityKind, IFieldConvertor } from 'common/registries/FieldConvertorsRegistry';

export const createCdrRemediation = async (remediationProps: IRemediationCreationByEventProps) => {
    // @@@ Rina: why all events have Remediation action disabled?
    const { event, cloudBots, comment, severities, platform, ruleLogic } = remediationProps;
    const request: ICreateRemediationRequest = {
        rulesetId: event.bundleId,
        platform: platform,
        cloudAccountId: event.cloudAccountId,
        cloudBots: cloudBots,
        comment: comment,
        ruleName: event.ruleName,
        logic: ruleLogic,
        ruleLogicHash: event.ruleLogicHash,
        severities: severities,
    };
    await getRemediationService().createRemediation(request);
};

export const convertElasticFiltersToKusto = (defaultFilters?: ISearchFilterViewModel): IAdditionalFilterFieldInfo[] => {
    if (!defaultFilters || !defaultFilters.fields) return [];
    return defaultFilters.fields.reduce<IAdditionalFilterFieldInfo[]>((acc, field) => {
        if (!field.value) return acc;
        const convertor: IFieldConvertor | undefined = FieldConvertorsRegistry.getFieldConvertor(
            FieldEntityKind.ELASTIC_EVENT,
            FieldEntityKind.KUSTO_EVENT_FIELD_INFO,
            field.name,
        );
        if (convertor && convertor.getTargetValue) {
            acc.push(convertor.getTargetValue(field.value));
        }
        return acc;
    }, []);
};
