import React, { useState } from 'react';
import WorkloadService from '../../../../../services/workload/workload.service';
import ModalAddRuleBaseImage from '../../../../../components/ModalAddRuleBaseImage/ModalAddRuleBaseImage';
import { Button } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';

const AddRuleBaseImage: React.FC<{ cloudAccountId: string }> = ({ cloudAccountId }) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const { t } = useTranslation('k8s_protected-asset-details');

    const onConfirm = async (name: string, environmentIds: Array<string>, repository: string, description: string) => {
        await WorkloadService.addBaseImageRules({
            name: name,
            environments: environmentIds,
            repository: repository,
            description: description,
        });
    };

    const initialValues = {
        id: '',
        ruleName: '',
        environments: cloudAccountId ? [cloudAccountId] : [],
        repository: '',
        description: '',
    };

    return (
        <>
            <Button iconProps={{ name: 'rule' }} variant='text' onClick={() => setIsOpenModal(true)}>
                {t('actions.setAsBaseImage')}
            </Button>

            {isOpenModal && (
                <ModalAddRuleBaseImage
                    isEditMode={false}
                    isModalOpen={isOpenModal}
                    onConfirm={onConfirm}
                    handleCloseModal={() => setIsOpenModal(false)}
                    initialValues={initialValues}
                />
            )}
        </>
    );
};

export default AddRuleBaseImage;
