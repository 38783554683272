import { IItemsMap } from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import { IMalwareIgnoreUpdate } from './MalwareIgnoreList.interface';
import { getCommonIgnoreItemFromValues } from '../CommonIgnoreList/CommonIgnoreList.items';
import { ICommonIgnoreItemUpdate } from '../CommonIgnoreList/CommonIgnoreList.interface';
import { getTrimmedMultiValue } from 'common/erm-components/custom/CustomForm/CustomForm.values';
import { MalwareIgnoreInnerNames } from './MalwareIgnoreList.consts';

export const getMalwareIgnoreItemFromValues = (itemsMap: IItemsMap): IMalwareIgnoreUpdate => {
    const commonIgnoreItemUpdate: ICommonIgnoreItemUpdate = getCommonIgnoreItemFromValues(itemsMap);

    return {
        ...commonIgnoreItemUpdate,
        malwareIds: getTrimmedMultiValue(itemsMap[MalwareIgnoreInnerNames.malwareIds]),
    };
};
