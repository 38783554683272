import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';
import { Context } from 'common/design-system/theme/colors/colors.types';

export const FixableBoxContext = styled(Stack)<{ context: Context; count: number; isClickable: boolean }>`
    width: 72px;
    height: 40px;
    border-radius: ${({ theme }) => `${theme.border.radius(1)}${theme.units}`};
    padding: 8px;
    background-color: ${({ theme, context }) =>
        context ? theme.palette[context].backgrounds.strength.weaker : theme.palette.severity.unknownBg};
    &:hover {
        p:nth-child(2) {
            text-decoration: ${({ count, isClickable }) =>
                count !== null && count > 0 && isClickable ? 'underline' : 'none'};
            cursor: ${({ count, isClickable }) => (count !== null && count > 0 && isClickable ? 'pointer' : 'default')};
        }
    }
`;
