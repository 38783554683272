import React, { useEffect, useRef } from 'react';
import { CloudBotCard } from '../CloudBotCard';
import { ICloudBotCardListProps } from './CloudBotCardList.types';
import { ICloudBot } from 'common/module_interface/intelligence/CloudBots/CloudBots.interface';
import { CloudBotCardListStyles as Styles } from './CloudBotCardList.styled';

const CloudBotCardList: React.FC<ICloudBotCardListProps> = (params: ICloudBotCardListProps) => {
    const { cloudBots, onDelete, onParameterInput, enableScrollIntoLastCloudBot } = params;
    const lastCloudBotContainerRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const prevLengthRef: React.MutableRefObject<number> = useRef<number>(cloudBots.length);

    const scrollToLastCloudBotContainer = () => {
        if (lastCloudBotContainerRef.current) {
            lastCloudBotContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }
    };

    useEffect(() => {
        if (enableScrollIntoLastCloudBot && cloudBots.length > prevLengthRef.current) {
            scrollToLastCloudBotContainer();
        }
        prevLengthRef.current = cloudBots.length;
    }, [cloudBots.length, enableScrollIntoLastCloudBot]);

    return (
        <Styles.CloudBotCardListWrapper spacing={2}>
            {cloudBots.map((selectedCloudBot: ICloudBot, index: number) => (
                <Styles.CloudBotCardWrapper
                    ref={index === cloudBots.length - 1 ? lastCloudBotContainerRef : null}
                    key={selectedCloudBot.id}
                >
                    <CloudBotCard cloudBot={selectedCloudBot} onDelete={onDelete} onParameterInput={onParameterInput} />
                </Styles.CloudBotCardWrapper>
            ))}
        </Styles.CloudBotCardListWrapper>
    );
};

export default CloudBotCardList;
