import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppClientIdInputProps } from './AppClientIdInput.interface';
import { AuthFieldInput } from '../index';
import { tooltipUrls } from '../components.consts';

const AppClientIdInput: React.FC<AppClientIdInputProps> = (props) => {
    const { appClientId, setAppClientId } = props;
    const { t } = useTranslation();

    return (
        <AuthFieldInput
            authFieldName={t('ON_BOARDING.REGISTRY.APP_CLIENT_ID')}
            tooltipUrl={tooltipUrls.APP_CLIENT_ID}
            authProp={appClientId}
            onChange={setAppClientId}
        />
    );
};

export default AppClientIdInput;
