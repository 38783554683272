import { Icon, Stack, Typography } from 'common/design-system/components-v2';
import { EcsTaskRoleAuthExplanationWrapper, Bullet, InfoBox } from './EcsTaskRoleAuthExplanation.styled';
import { useTranslation } from 'react-i18next';

const EcsTaskRoleAuthExplanation = () => {
    const { t } = useTranslation('k8s_registry');
    return (
        <EcsTaskRoleAuthExplanationWrapper>
            <InfoBox>
                <Icon name='info' size={12} color='brandPrimary' />
                <Stack spacing={2}>
                    <Typography>{t('ON_BOARDING.EcsTaskRoleAuthExplanation.toUseThisMethod')}</Typography>
                    <Stack spacing={1} padding={[0, 0, 0, 3]}>
                        <Bullet>
                            <Typography>{t('ON_BOARDING.EcsTaskRoleAuthExplanation.yourHostCluster')}</Typography>
                        </Bullet>
                        <Bullet>
                            <Typography>{t('ON_BOARDING.EcsTaskRoleAuthExplanation.yourEcsClusterIs')}</Typography>
                        </Bullet>
                        <Bullet>
                            <Typography>{t('ON_BOARDING.EcsTaskRoleAuthExplanation.theAmazonEcsTask')}</Typography>
                        </Bullet>
                        <Bullet>
                            <Typography>{t('ON_BOARDING.EcsTaskRoleAuthExplanation.minimumVersion')}</Typography>
                        </Bullet>
                    </Stack>
                </Stack>
            </InfoBox>
        </EcsTaskRoleAuthExplanationWrapper>
    );
};

export default EcsTaskRoleAuthExplanation;
