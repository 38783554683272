import i18n from 'i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import { ProtectedAssetsTableRegistry } from 'common/module_interface/assets/ProtectedAssetsTableRegistry';
import WorkloadRequestImageScan from '../../../../services/workload/components/WorkloadRequestImageScan';
import AddRuleBaseImage from './ActionsComponents/AddRuleBaseImage';
import { Button } from 'common/design-system/components-v2';
import { AssetTypes } from 'common/assets/assets.const';
import { ActionsIds } from './types/actions.types';
import AutoProtectAction from './ActionsComponents/Serverless/AutoProtectAction';
import ProtectionModeAction from './ActionsComponents/Serverless/ProtectionModeAction';

const actionsDefs: ITableAction[] = [
    {
        id: ActionsIds.RequestScan,
        name: 'Request Scan',
        buttonProps: {
            iconProps: { name: 'riskManagement4' },
        },
        callback: () => null,
        customComponent: (selectedRows, params) => {
            const { cloudAccountId, entityId } =
                selectedRows.length === 1 ? selectedRows[0] : { cloudAccountId: '', entityId: '' };

            return (
                <WorkloadRequestImageScan
                    disabled={!(cloudAccountId && entityId)}
                    environmentId={cloudAccountId}
                    imageIdOrDigest={entityId}
                    successCallback={() => {
                        params.gridApi.refreshServerSide();
                        params.gridApi.deselectAll();
                    }}
                />
            );
        },
    },
    {
        id: ActionsIds.AddBaseImageRule,
        name: i18n.t(`${getK8sNamespace('protected-asset-details')}:actions.setAsBaseImage`),
        buttonProps: {
            iconProps: { name: 'rule' },
        },
        callback: () => null,
        isActionDisabled: (items) => items.length > 1 || items.length === 0,
        customComponent: (selectedRows) => {
            const { cloudAccountId, typeByPlatform } =
                selectedRows.length === 1 ? selectedRows[0] : { cloudAccountId: '', typeByPlatform: '' };

            const isContainerRegistry = typeByPlatform === AssetTypes.CONTAINERREGISTRY_CONTAINERREGISTRYIMAGE;

            if (!cloudAccountId || !isContainerRegistry) {
                return (
                    <Button
                        iconProps={{ name: 'rule' }}
                        variant='text'
                        disabled
                        tooltip={'Base Image Rule work only for Container Registry Image'}
                        tooltipPlacement={'top'}
                    >
                        {i18n.t(`${getK8sNamespace('protected-asset-details')}:actions.setAsBaseImage`)}
                    </Button>
                );
            }

            return <AddRuleBaseImage cloudAccountId={cloudAccountId} />;
        },
    },
    {
        id: ActionsIds.AutoProtect,
        name: 'Auto Protect',
        customComponent: (selectedRows, { gridApi }) => (
            <AutoProtectAction selectedRows={selectedRows} gridApi={gridApi} />
        ),
        callback: () => null,
    },
    {
        id: ActionsIds.ProtectionMode,
        name: 'Protection Mode',
        customComponent: (selectedRows, { gridApi }) => (
            <ProtectionModeAction selectedRows={selectedRows} gridApi={gridApi} />
        ),
        callback: () => null,
    },
];

export default function initializeActionsDefs() {
    ProtectedAssetsTableRegistry.addActions(actionsDefs, 'id');
}
