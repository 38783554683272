import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthMethodSelectorProps } from './AuthMethodSelector.interface';
import { getRegistryOptions } from 'modules/workloads/utils/RegistryOptions/RegistryOptions';
import { AuthMethodSelectorWrapper } from './AuthMethodSelector.styled';
import { SelectV2 as Select } from 'common/design-system/components-v2';

const AuthMethodSelector: React.FC<AuthMethodSelectorProps> = (props) => {
    const { registryType, authMethod, scanningEnvironmentType, setRegistryAuthMethod } = props;
    const { t } = useTranslation('k8s_registry');
    const RegistryOptions = getRegistryOptions();
    const options = RegistryOptions[registryType].authMethods[scanningEnvironmentType].map(({ title, key }) => {
        return { label: t(title), value: key };
    });

    return (
        <AuthMethodSelectorWrapper padding={[0, 0, 3, 0]}>
            <Select
                label={t('ON_BOARDING.AUTH_METHOD')}
                isMulti={false}
                required
                options={options}
                onChange={(value) => setRegistryAuthMethod(value)}
                placeholder={t('ON_BOARDING.authMethodPlaceholder')}
                value={options.find((option) => option.value === authMethod)?.value || authMethod}
                fullWidth
            />
        </AuthMethodSelectorWrapper>
    );
};

export default AuthMethodSelector;
