import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { InfoPanelActionProps } from 'common/components/InfoPane/InfoPane';

export enum AwpScanChipProviderTypes {
    SaasEnabled = 'Saas',
    AwpNotEnabled = 'NotEnabled',
    InAccountEnabled = 'InAccountEnabled',
}

export type AwpRescanChipActionFunction = (entity: ICloudEntityData) => InfoPanelActionProps;
