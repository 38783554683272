import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import { IIssue } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { ermTrans, isAfToxicGraph } from '../../../../RiskManagement.utils';
import { colors } from 'common/design-system/theme/colors';
import { TOXIC_GROUP_FIELDS } from '../../Issues.consts';
import { getExclusionUpdateProps } from '../../../IssueExclusions/IssueExclusionEditor/IssueExclusionEditor.items';
import { IIssueExclusionProps } from '../../../IssueExclusions/IssueExclusions.interface';
import { openIssueExclusionEditor } from '../../../IssueExclusions/IssueExclusions.utils';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';

export function getIssuesTableActions(): ITableAction<IIssue>[] {
    const actions: ITableAction<IIssue>[] = [
        {
            id: 'show-toxic-combination',
            name: ermTrans('ISSUES.TABLE.ACTIONS.OVERVIEW'),
            callback: (_, params) => {
                params.columnApi.setRowGroupColumns(TOXIC_GROUP_FIELDS);
            },
            isActionDisabled: () => false,
            buttonProps: {
                iconProps: { name: 'presetRowGroup', size: 16 },
                style: { borderColor: colors.grey[60] },
                variant: 'outlined',
                size: 'medium',
            },
        },
        {
            id: 'exclude',
            name: ermTrans('ISSUES.TABLE.ACTIONS.EXCLUDE.TITLE'),

            callback: (issues) => {
                if (issues.length === 1) {
                    const issue = issues[0];
                    const issueExclusionProps: IIssueExclusionProps = getExclusionUpdateProps(issue);
                    openIssueExclusionEditor(issueExclusionProps);
                }
            },

            isActionDisabled: (issues) => issues.length !== 1,

            buttonProps: {
                iconProps: { name: 'exclude' },
            },
        },
    ];

    const openQueryPageAction: ITableAction<IIssue> = {
        id: 'openQueryPageAction',
        name: 'Explore Query',
        callback: (selectedRows: IIssue[]) => {
            getErmUrlsService().goToToxicGraphPageByIssueId(selectedRows[0].id);
        },
        isActionDisabled: (selectedRows) => selectedRows.length !== 1,
        buttonProps: {
            iconProps: { name: 'issue' },
        },
    };

    if (isAfToxicGraph()) {
        actions.push(openQueryPageAction);
    }

    return actions;
}
