import { timeDecorator } from '../LogOverview/FieldDecorators';
import { ILogFieldsSection, LogFields } from 'common/module_interface/intelligence/Logs/Logs.interface';
import { TFunction } from 'i18next';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from '../../initialize.i18n';

export function getAccountActivityLogOverviewFieldsSection(): ILogFieldsSection[] {
    const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);
    const accountActivityTransKey = 'LOGS_TABLE.OVERVIEW.ACCOUNT_ACTIVITY';
    const trans = (key: string) => t(`${accountActivityTransKey}.${key}`);
    return [
        {
            title: trans('EVENT.TITLE'),
            fields: [
                { label: trans('EVENT.LABELS.EVENT_NAME'), field: LogFields.eventName },
                { label: trans('EVENT.LABELS.EVENT_TIME'), field: LogFields.eventTime, decorator: timeDecorator },
                { label: trans('EVENT.LABELS.EVENT_STATUS'), field: LogFields.eventStatus },
                { label: trans('EVENT.LABELS.EVENT_TYPE'), field: LogFields.eventType },
                { label: trans('EVENT.LABELS.CLOUD_GUARD_EVENT'), field: LogFields.cloudGuardEvent },
            ],
        },
        {
            title: trans('IDENTITY.TITLE'),
            fields: [
                { label: trans('IDENTITY.LABELS.IDENTITY_NAME'), field: LogFields.identityName },
                { label: trans('IDENTITY.LABELS.IDENTITY_ID'), field: LogFields.identityId },
                { label: trans('IDENTITY.LABELS.IDENTITY_USER_AGENT'), field: LogFields.identityUserAgent },
                { label: trans('IDENTITY.LABELS.IDENTITY_TYPE'), field: LogFields.identityType },
                { label: trans('IDENTITY.LABELS.IDENTITY_REGION'), field: LogFields.identityRegion },
            ],
        },
        {
            title: trans('ISSUER.TITLE'),
            fields: [
                { label: trans('ISSUER.LABELS.ISSUER_NAME'), field: LogFields.issuerName },
                { label: trans('ISSUER.LABELS.ISSUER_ID'), field: LogFields.issuerId },
                { label: trans('ISSUER.LABELS.ISSUER_TYPE'), field: LogFields.issuerType },
                { label: trans('ISSUER.LABELS.ISSUER_REGION'), field: LogFields.issuerRegion },
            ],
        },
        {
            title: trans('TARGET.TITLE'),
            fields: [
                { label: trans('TARGET.LABELS.TARGET_NAME'), field: LogFields.targetName },
                { label: trans('TARGET.LABELS.TARGET_ID'), field: LogFields.targetId },
                { label: trans('TARGET.LABELS.TARGET_NAMESPACE'), field: LogFields.targetNamespace },
                { label: trans('TARGET.LABELS.TARGET_TYPE'), field: LogFields.targetType },
            ],
        },
        {
            title: trans('SOURCE.TITLE'),
            fields: [
                { label: trans('SOURCE.LABELS.SOURCE_IP'), field: LogFields.sourceIp },
                { label: trans('SOURCE.LABELS.SOURCE_COUNTRY'), field: LogFields.sourceCountry },
                { label: trans('SOURCE.LABELS.SOURCE_IS_MALICIOUS'), field: LogFields.sourceIsMalicious },
            ],
        },
    ];
}
