import React from 'react';
import { List } from 'common/design-system/components-v2';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import { BotStatus } from 'common/module_interface/intelligence/CloudBots/CloudBots.const';
import { useTranslation } from 'react-i18next';
import { IListItemProps } from 'common/design-system/components-v2/List/List.types';
import { BotStatusPropMappings } from './FixItAction';

interface FixItActionListProps {
    botStatusMappings: BotStatusPropMappings;
    cloudBotStatus: BotStatus;
    openModal: () => void;
    isActionDisabled: boolean;
}

const FixItActionList: React.FC<FixItActionListProps> = (props) => {
    const { botStatusMappings, openModal, cloudBotStatus, isActionDisabled } = props;
    const { t } = useTranslation(i18nIntelligenceNamespace);

    const listOptions: IListItemProps[] = React.useMemo(() => {
        let listOption: IListItemProps = {
            label: t('FINDINGS_TABLE.ACTIONS.FIX_IT.TITLE'),
            value: 'fix-it',
            onClick: openModal,
            disabled: isActionDisabled,
        };
        if (
            cloudBotStatus &&
            cloudBotStatus !== BotStatus.NOT_STARTED &&
            Object.keys(botStatusMappings).includes(cloudBotStatus)
        ) {
            const relevantProps = botStatusMappings[cloudBotStatus];
            listOption = {
                label: relevantProps.label,
                value: 'fix-it',
                onClick: relevantProps.onClick,
                onClear: relevantProps.closeButtonInfo?.onClick,
                tooltip: relevantProps.tooltip,
                labelProps: relevantProps.iconProps?.name && {
                    leadingIconProps: {
                        name: relevantProps.iconProps.name,
                        color: relevantProps.iconProps.color,
                    },
                },
            };
        }

        return [listOption];
    }, [botStatusMappings, cloudBotStatus, isActionDisabled, openModal, t]);

    return <List options={listOptions} />;
};

export default FixItActionList;
