import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const QRadarStyled = styled(Stack)`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const QRadarNameWrapper = styled(Stack)`
    width: 50%;
    margin: 24px;
`;

const QRadarEndpointUrlWrapper = styled(Stack)`
    display: flex;
    flex-direction: row;
    margin: 0 24px;
    align-items: flex-end;
`;

const QRadarEndpointUrlInputWrapper = styled(Stack)`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const QRadarAuthenticationWrapper = styled(Stack)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 24px;
`;

const BasicAuthCredentialsInputWrapper = styled(Stack)`
    display: flex;
    flex-direction: column;
    width: 45%;
`;

const IgnoreCertificateValidationWrapper = styled(Stack)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 24px;
`;

export {
    QRadarStyled,
    QRadarNameWrapper,
    QRadarEndpointUrlWrapper,
    QRadarEndpointUrlInputWrapper,
    QRadarAuthenticationWrapper,
    BasicAuthCredentialsInputWrapper,
    IgnoreCertificateValidationWrapper,
};
