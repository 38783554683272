import { ColDef } from 'ag-grid-community';
import { TFunction } from 'i18next';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import LicensesChipsCellRenderer from 'modules/workloads/CellRenders/LicensesChipsCellRenderer/LicensesChipsCellRenderer';

const getProtectedAssetsColumns = (t: TFunction) => {
    return getProtectedAssetsService().getColumnDefs([
        { id: 'ermRiskScore', colDefOverride: { sort: 'desc' } },
        { id: 'entity', colDefOverride: { headerName: t('affectedAssetsTable.columns.asset'), width: 200 } },
        { id: 'type', colDefOverride: { width: 120 } },
        { id: 'ermWafProtection' },
        { id: 'cves' },
        { id: 'threats' },
        { id: 'secrets' },
        { id: 'isRunning' },
        {
            id: 'organizationalUnit',
            colDefOverride: { headerName: t('affectedAssetsTable.columns.organizationalUnit'), width: 120 },
        },
        { id: 'environment', colDefOverride: { width: 180 } },
        { id: 'ermDataClassification' },
        { id: 'ermBusinessPriority' },
        { id: 'ermNetworkExposure' },
        { id: 'ermIamExposure' },
        { id: 'ermHasIssues' },
    ]);
};

export const getColumnDefs = (t: TFunction): ColDef<any>[] => [
    ...getProtectedAssetsColumns(t),
    {
        field: 'path',
        headerName: t('affectedAssetsTable.columns.path'),
        flex: 1,
        sortable: false,
        width: 120,
    },
    {
        field: 'licenses',
        headerName: t('affectedAssetsTable.columns.licenses'),
        flex: 1,
        sortable: false,
        hide: true,
        width: 120,
        cellRenderer: LicensesChipsCellRenderer,
    },
];
