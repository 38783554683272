import React, { useCallback, useEffect } from 'react';
import { Controller, useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { Stack, Label, Input, Checkbox, TextArea, DatePicker, Button } from 'common/design-system/components-v2';
import {
    FormValues,
    DEFAULT_VALUES,
    SignupSchema,
    Environment,
    VulnerabilityExclusionFormProps,
    Entity,
} from './VulnerabilityExclusionForm.types';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import TypeController from './Content/TypeController';
import EnvController from './Content/EnvController';
import EntityNameController from './Content/EntityNameController';
import { DateRange } from 'react-day-picker';
import { closeDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';

const VulnerabilityExclusionForm: React.FC<VulnerabilityExclusionFormProps> = ({
    onConfirm,
    initialValues,
    onConfirmEdit,
    isEdit,
}) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const { t } = useTranslation(['k8s_common', 'k8s_vulnerabilityExclusion']);

    const methods = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: DEFAULT_VALUES,
        shouldUnregister: true,
        resolver: yupResolver(SignupSchema) as any,
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        reset,
    } = methods;

    const formatConditions = (inputs: Array<string> | undefined): string[] | undefined => {
        if (!inputs || inputs?.length === 0) return undefined;
        return inputs.map((item) => `name like '${item}'`);
    };

    const setIsEnvOrOrg = useCallback(() => {
        if (initialValues?.filter.environmentsIds && initialValues.filter.environmentsIds?.length > 0) {
            return Environment.ENVIRONMENTS_IDS;
        }
        return Environment.ENVIRONMENTS_IDS;
    }, [initialValues?.filter.environmentsIds]);

    const setIsEntityType = useCallback(() => {
        if (initialValues?.filter.entityId) {
            return Entity.ENTITY_ID;
        }
        return Entity.ENTITY_NAME;
    }, [initialValues?.filter.entityId]);

    useEffect(() => {
        if (initialValues) {
            setValue('name', initialValues.name);
            setValue('cveId', initialValues.filter.cveId);
            setValue('runAssessment', initialValues.includeInAssessment);
            setValue('comment', initialValues.comment);
            setValue('environmentsIds', initialValues.filter.environmentsIds);
            setValue('filePath', initialValues.filter.filePath);
            setValue('maliciousCategory', initialValues.filter.maliciousCategory);
            setValue('organizationalUnitsIds', initialValues.filter.organizationalUnitsIds);
            setValue('packageName', initialValues.filter.packageName);
            setValue('toggles.vulnerabilityType', initialValues.type);
            setValue('toggles.envType', setIsEnvOrOrg());
            setValue('toggles.entity', setIsEntityType());
            setValue('packageVersion', initialValues.filter.packageVersion);
            setValue('entityNames', formatConditions(initialValues.filter.entityNames));
            setValue('entityId', initialValues.filter.entityId);
            initialValues.filter.endDate &&
                initialValues.filter.startDate &&
                setValue('date', {
                    from: new Date(initialValues.filter.endDate),
                    to: new Date(initialValues.filter.startDate),
                });
        } else {
            reset(DEFAULT_VALUES);
        }
    }, [initialValues, reset, setIsEntityType, setIsEnvOrOrg, setValue]);

    const entityNameToArray = (value: string | string[] | undefined): string[] | undefined => {
        if (!value || value.length === 0 || Array.isArray(value)) return undefined;
        const entityNameArray: Array<string> = [];
        value.split(' or ').forEach((item: string) => {
            const start = item.indexOf("'") + 1;
            const end = item.lastIndexOf("'");
            const extractedSubstring = item.substring(start, end);
            entityNameArray.push(extractedSubstring);
        });
        return entityNameArray;
    };

    const handelOnEdit = async (id: string, data: FormValues) => {
        const {
            cveId,
            runAssessment,
            comment,
            environmentsIds,
            name,
            packageName,
            filePath,
            maliciousCategory,
            organizationalUnitsIds,
            entityNames,
            entityId,
            packageVersion,
            date,
            toggles: { vulnerabilityType },
        } = data;
        try {
            await onConfirmEdit(id, name, comment, vulnerabilityType, runAssessment, {
                cveId,
                packageName,
                packageVersion,
                entityNames: entityNameToArray(entityNames),
                entityId: entityId,
                environmentsIds,
                organizationalUnitsIds,
                filePath,
                maliciousCategory,
                startDate: date.from,
                endDate: date.to,
            });
            getNotificationsService().addNotification({
                type: NotificationType.SUCCESS,
                title: t('k8s_vulnerabilityExclusion:edit.serverResponseSuccessMessage'),
                text: '',
            });
        } catch (error) {
            getNotificationsService().addNotification({
                type: NotificationType.ERROR,
                title: t('k8s_vulnerabilityExclusion:edit.serverResponseErrorMessage'),
                text: '',
            });
        }
    };

    const handelOnCreate = async (data: FormValues) => {
        const {
            cveId,
            runAssessment,
            comment,
            environmentsIds,
            name,
            packageName,
            filePath,
            maliciousCategory,
            organizationalUnitsIds,
            entityNames,
            entityId,
            packageVersion,
            date,
            toggles: { vulnerabilityType },
        } = data;

        try {
            await onConfirm(vulnerabilityType, comment, runAssessment, name, {
                cveId,
                packageName,
                packageVersion,
                entityNames: entityNameToArray(entityNames),
                entityId: entityId,
                environmentsIds,
                organizationalUnitsIds,
                filePath,
                maliciousCategory,
                startDate: date.from,
                endDate: date.to,
            });
            getNotificationsService().addNotification({
                type: NotificationType.SUCCESS,
                title: t('k8s_vulnerabilityExclusion:create.serverResponseSuccessMessage'),
                text: '',
            });
        } catch (error) {
            getNotificationsService().addNotification({
                type: NotificationType.ERROR,
                title: t('k8s_vulnerabilityExclusion:create.serverResponseErrorMessage'),
                text: '',
            });
        }
    };

    const handleConfirm: SubmitHandler<FormValues> = async (data) => {
        setIsLoading(true);

        if (!isEdit) {
            await handelOnCreate(data);
        } else {
            await handelOnEdit(initialValues?.id || '', data);
        }
        oncloseModal();
        setIsLoading(false);
    };

    const oncloseModal = () => {
        reset(DEFAULT_VALUES);
        closeDrawer();
    };

    return (
        <FormProvider {...methods}>
            <Stack spacing={4}>
                <Stack spacing={2}>
                    <Controller
                        name={'name'}
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                fullWidth
                                isError={!!errors.name}
                                onChange={field.onChange}
                                placeholder={'Enter a exclusion name'}
                                required
                                helperText={errors.name?.message ?? undefined}
                                label={'Name'}
                            />
                        )}
                    />
                </Stack>

                <Stack>
                    <Label
                        text={'Assessment'}
                        color={'strong'}
                        tooltip={t('k8s_vulnerabilityExclusion:assessmentTooltip')}
                    />
                    <Controller
                        name={'runAssessment'}
                        control={control}
                        render={({ field }) => (
                            <Checkbox
                                {...field}
                                checked={field.value}
                                label={t('k8s_vulnerabilityExclusion:table.columns.includeInAssessment')}
                            />
                        )}
                    />
                </Stack>

                <EnvController />

                <TypeController />

                <EntityNameController />

                <Stack>
                    <Controller
                        name={'comment'}
                        control={control}
                        render={({ field }) => (
                            <TextArea
                                {...field}
                                fullWidth
                                placeholder={'Enter a comment'}
                                inputStyles={{ height: '50px' }}
                                required
                                isError={!!errors.comment}
                                helperText={errors.comment?.message ?? undefined}
                                label={t('k8s_vulnerabilityExclusion:table.columns.comment')}
                            />
                        )}
                    />
                </Stack>

                <Stack>
                    <Controller
                        name={'date'}
                        control={control}
                        render={({ field }) => {
                            return (
                                <DatePicker
                                    value={field.value}
                                    type={'range'}
                                    label={'Date Range'}
                                    onChange={(date: DateRange | undefined) => field.onChange(date)}
                                />
                            );
                        }}
                    />
                </Stack>
            </Stack>
            <Stack margin={[4, 0]}>
                <hr />
            </Stack>
            <Stack style={{ marginTop: 'auto' }} direction='row' justifyContent='flex-end' fullWidth>
                <Stack direction='row' spacing={3}>
                    <Button variant='text' onClick={() => closeDrawer()} dataAid='cancel'>
                        {t('k8s_common:cancel')}
                    </Button>
                    <Button
                        loading={isLoading}
                        color='brandPrimary'
                        onClick={handleSubmit(async (data) => handleConfirm(data))}
                        dataAid='save'
                    >
                        {t('k8s_common:save')}
                    </Button>
                </Stack>
            </Stack>
        </FormProvider>
    );
};

export default VulnerabilityExclusionForm;
