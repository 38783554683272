import { EventsTableRegistry } from 'common/module_interface/events/EventsTableRegistry';
import { Addin } from 'common/extensibility/AddinRegistry';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import { EventAction, IEvent } from 'common/module_interface/events/Events';
import i18n from 'common/services/translations/translations';
import { IFindingModelOriginString, IFindingModelStatus } from 'common/components/Findings/Findings.interface';
import { i18nExclusionNamespace } from './initialize.i18n';
import { IActionEnabler } from 'common/interface/exclusion';
import { ExclusionRegistry } from 'common/module_interface/exclusion/exclusionRegistry';
import { hasPermissionToEventActions, isServerlessExclusion } from 'common/module_interface/events/utils';
import { EventsDrawerAddingBase, EventsDrawerRegistry } from 'common/module_interface/events/EventsDrawerRegistry';
import { changeUrlParam } from 'common/utils/http';

const EXCLUSIONS = 'exclusions';
const EXCLUDE = 'exclude';

const canExecuteAction = (selectedRows: IEvent[]): boolean => {
    if (!hasPermissionToEventActions()) {
        return false;
    }
    const enablers = ExclusionRegistry.getExcludeEventEnablers();
    if (selectedRows.length === 1) {
        const selectedRow = selectedRows[0];
        return (
            isServerlessExclusion(selectedRow) ||
            enablers.some((action: IActionEnabler) => action.enabler && action.enabler(selectedRow)) ||
            [
                IFindingModelOriginString.ComplianceEngine,
                IFindingModelOriginString.ContainersRuntimeProtection,
                IFindingModelOriginString.InfrastructureAsCode,
            ].includes(selectedRow.origin)
        );
    }
    return false;
};
const actionsDefs = () => {
    const actions: Addin<ITableAction<IEvent>>[] = [
        {
            id: EXCLUDE,
            content: {
                id: EXCLUDE,
                position: 0,
                name: i18n.t('ACTIONS.EXCLUDE.BUTTON', { ns: i18nExclusionNamespace }),
                callback: () => changeUrlParam('modalType', EXCLUSIONS),
                buttonProps: (selectedRows) => ({
                    iconProps: { name: EXCLUDE },
                    disabled: !canExecuteAction(selectedRows),
                    tooltip: selectedRows.length !== 1 ? 'Select only one event to enable this action' : undefined,
                }),
            },
        },
    ];
    return actions;
};

const actionsMenuDefs = () => {
    const actions: Addin<EventsDrawerAddingBase<EventAction>>[] = [
        {
            id: EXCLUDE,
            content: {
                id: EXCLUDE,
                isRelevant: (event: IEvent) =>
                    event.status !== IFindingModelStatus.Archived && canExecuteAction([event]),
                getValue: () => ({
                    label: i18n.t('ACTIONS.EXCLUDE.BUTTON', { ns: i18nExclusionNamespace }),
                    icon: EXCLUDE,
                    callback: () => changeUrlParam('modalType', EXCLUSIONS),
                }),
            },
        },
    ];
    return actions;
};

export function initializeEventsActions() {
    EventsTableRegistry.addActions(actionsDefs());
    EventsDrawerRegistry.addActions(actionsMenuDefs());
}
