import { ERM_OPTIONAL_AGGREGATION_FIELDS, I18nRiskNamespace } from './consts';
import { IFieldInfo, IValueCount } from 'common/interface/general';
import { getUserService, ICachingConfig } from 'common/interface/services';
import { IServerInputSort } from './components/Issues/Issues.interface';
import { getVendor, IVendor } from 'common/consts/vendors';
import { FieldEntityKind } from 'common/registries/FieldConvertorsRegistry';
import { convertFilterField } from 'common/utils/filterUtils';
import i18n from 'i18next';
import { AccountPlan } from 'common/enum/AccountPlan';
import { FindingSeverityServerEnum } from 'common/consts/FindingSeverity';
import { isAfEnabled } from 'common/utils/debugUtils';
import { IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { SortModelItem } from 'ag-grid-community/dist/lib/sortController';
import { getSortModelForColumn } from './components/Issues/Issues.utils';
import { GridApi } from 'ag-grid-community';
import { NEW_NOTIFICATIONS_PAGE_AF } from 'common/module_interface/settings/integrations/Integrations';
import { IFilterFieldsProps, RequestFilter } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { getAggregationsFromServer } from 'common/components/ProtectedAssets/datasource';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { CACHE_TIMOUTS, getCacheConfig } from 'common/erm-components/utils/ermComponents.http';
import {
    DATA_SECURITY_ACTIVE_FEATURE,
    SIMILAR_ASSETS_FEATURE,
    TOXIC_GRAPH_ACTIVE_FEATURE,
} from './components/ActiveFeatures/ActiveFeatures.consts';
import { IUserAccount } from 'common/interface/user';

export const isAfNewIntegrations = () => isAfEnabled(NEW_NOTIFICATIONS_PAGE_AF);
export const isAfDataSecurity = () => isAfEnabled(DATA_SECURITY_ACTIVE_FEATURE.key);
export const isAfSimilarAssets = () => isAfEnabled(SIMILAR_ASSETS_FEATURE.key);
export const isAfToxicGraph = () => isAfEnabled(TOXIC_GRAPH_ACTIVE_FEATURE.key);

export const ermTrans = (str: string, params?: { [key: string]: any }): string => {
    const finalParams = {
        ...(params || {}),
        ns: I18nRiskNamespace,
    };
    return i18n.t(str, { ...finalParams, interpolation: { escapeValue: false } });
};

export const ermPermissionFn = (featureKey?: string): boolean => {
    if (featureKey && !isAfEnabled(featureKey)) {
        return false;
    }
    const account: IUserAccount = getUserService().getAccount();
    return account?.plan !== AccountPlan.FREE;
};

export const hasToxicGraphPermissionFn = (): boolean => {
    return ermPermissionFn() && isAfToxicGraph();
};

export const mappingCriticalHigh = (riskScore: IValueCount[] | null) => {
    let criticalCount = 0;
    let highCount = 0;
    riskScore?.forEach((score: IValueCount) => {
        if (score.value === FindingSeverityServerEnum.Critical) {
            criticalCount = score.count;
        }
        if (score.value === FindingSeverityServerEnum.High) {
            highCount = score.count;
        }
    });
    return { criticalCount, highCount };
};

export const isEqualList = <T>(arr1: T[] | undefined, arr2: T[] | undefined): boolean => {
    if (!arr1) {
        return !arr2;
    }

    if (!arr2) {
        return false;
    }

    return JSON.stringify(arr1) === JSON.stringify(arr2);
};

export const DASHBOARD_CACHE_CONFIG: ICachingConfig = getCacheConfig(CACHE_TIMOUTS.LONG);

export const assetTypeToFindingType = (assetType?: string): string | undefined => {
    if (!assetType) {
        return;
    }
    const matches = assetType.match(/^(.+)[|](.+)$/);
    if (matches?.length === 3) {
        const platform = matches[1];
        const type = matches[2];
        const vendor = getVendor(platform);
        if (vendor) {
            return `${vendor.elasticVendorType}|${type}`;
        }
    }
};

export const assetFilterFieldsToFindings = (fields: IFieldInfo[] = []) => {
    const finalFields: IFieldInfo[] = [];
    fields.forEach((field: IFieldInfo) => {
        const targetField = convertFilterField(FieldEntityKind.ASSET, FieldEntityKind.FINDING, field);
        if (targetField) {
            finalFields.push(targetField);
        }
    });
    return finalFields;
};

export const gridSortToServerFormat = <T = any>(
    params: IServerSideGetRowsParams<T>,
): IServerInputSort[] | undefined => {
    if (!params.request.sortModel) {
        return;
    }
    const sortModelList: IServerInputSort[] = [];
    params.request.sortModel.forEach((sortItem: SortModelItem) => {
        const sortModel = getSortModelForColumn(params.columnApi, sortItem.colId, sortItem.sort);
        if (sortModel) {
            sortModelList.push(sortModel);
        }
    });
    return sortModelList.length ? sortModelList : undefined;
};

export const refreshTable = (gridApi: GridApi) => {
    gridApi.refreshServerSide({ purge: true });
};

export const valuesToFilterFields = (fieldName: string, values: string[]): IFilterFieldsProps[] => {
    return values.map((value) => ({ name: fieldName, value }));
};

export const removeFilterFields = (unwantedNames: string[], fields?: IFieldInfo[]): IFieldInfo[] | undefined => {
    if (!fields) {
        return;
    }
    return fields.filter((field) => !unwantedNames.includes(field.name));
};

export async function fetchErmAggregations(aggregationFields: string[], filter?: RequestFilter): Promise<Aggregations> {
    const mainAggregations: string[] = [];
    const optionalAggregations: string[] = [];
    aggregationFields.forEach((field) => {
        if (ERM_OPTIONAL_AGGREGATION_FIELDS.includes(field)) {
            optionalAggregations.push(field);
        } else {
            mainAggregations.push(field);
        }
    });
    return getAggregationsFromServer(mainAggregations, optionalAggregations, filter, getCacheConfig());
}

export const getVendorsByPlatforms = (platforms: string[]): IVendor[] => {
    const vendors: IVendor[] = [];
    platforms.forEach((platform) => {
        const vendor: IVendor | null = getVendor(platform);
        if (vendor) {
            vendors.push(vendor);
        }
    });
    return vendors;
};
