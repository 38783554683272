export const RELEVANT_ASSETS_FILTERS_IDS = [
    'add filter',
    'free text',
    'organizational unit',
    'type',
    'environment',
    'platform',
    'region',
    'network',
    'resourceGroup',
    'runState',
    'tag',
    'publicIdAssociated',
    'billableAsset',
    'serverlessRuntimeProtection',
    'serverlessAutoProtect',
    'serverlessProtectionMode',
    'serverlessEnable',
];
