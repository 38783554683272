import React, { useCallback, useEffect, useState } from 'react';
import Typography from 'common/design-system/components-v2/Typography';
import { Icon, Input, Modal, Spinner, Stack, Button } from 'common/design-system/components-v2';
import { ConfigurationWrapper } from '../CommonConfigurationsComponents.style';
import { getConfigurationDrawerHeader } from '../Common/ConfigurationDrawerHeader';
import { getIntegrationsService } from 'common/interface/services';
import { useTranslation } from 'react-i18next';
import { I18nIntegrations } from 'common/module_interface/settings/integrations/consts';
import { setShouldRefreshData } from '../../../Integrations.reducer';
import { useDispatch } from 'react-redux';
import {
    ITenableConfigurationObj,
    ITenableConfigurationProps,
} from 'common/module_interface/settings/integrations/configurations.interface';
import { integrationNotificationReadOnly } from 'common/utils/userPermissionUtils';

export const TenableConfigurationComponent: React.FC<ITenableConfigurationProps> = ({
    onConfigurationChangeCallBack,
}) => {
    const { t } = useTranslation(I18nIntegrations);
    const dispatch = useDispatch();
    const [configurationName, setConfigurationName] = useState<string>();
    const [configuration, setConfiguration] = useState<ITenableConfigurationObj>();
    const [isValidateEnabled, setIsValidateEnabled] = useState<boolean>();
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [tenableExternalAccountNumber, setTenableExternalAccountNumber] = useState('');
    const viewMode = integrationNotificationReadOnly();

    const updateComponentActionButtonsState = useCallback(() => {
        if (!!configurationName && !!configuration?.secretKey && !!configuration.accessKey) {
            setIsSaveEnabled(true);
        } else {
            setIsSaveEnabled(false);
        }
    }, [configuration?.accessKey, configuration?.secretKey, configurationName]);

    const getTenableConfiguration = useCallback(async () => {
        const tenableConfiguration = await getIntegrationsService().getTenableConfiguration();
        if (tenableConfiguration) {
            setConfiguration(tenableConfiguration.configurationObj);
            setConfigurationName(tenableConfiguration.name);
            setTenableExternalAccountNumber(tenableConfiguration.configurationObj.tenableExternalAccountNumber);
            setIsValidateEnabled(tenableConfiguration.configurationObj.status !== null);
        }
    }, []);

    useEffect(() => {
        updateComponentActionButtonsState();
    }, [updateComponentActionButtonsState]);

    useEffect(() => {
        void getTenableConfiguration();
    }, [getTenableConfiguration]);

    const saveIntegration = async () => {
        setIsSaving(true);
        if (configurationName && configuration?.accessKey && configuration?.secretKey) {
            const response = await getIntegrationsService().saveTenableConfiguration(
                configurationName,
                configuration.accessKey,
                configuration.secretKey,
            );
            setIsSaving(false);
            if (response.isError) {
                setValidationMessage(response.message);
            } else {
                setConfiguration({
                    ...configuration,
                    tenableExternalAccountNumber: response.tenableExternalAccountNumber,
                });
            }
            dispatch(setShouldRefreshData(true));
            onConfigurationChangeCallBack && onConfigurationChangeCallBack();
        }
    };

    const deleteIntegration = async () => {
        setConfiguration({} as ITenableConfigurationObj);
        setConfigurationName('');
        setIsDeleteModalOpen(false);
        await getIntegrationsService().deleteTenableConfiguration(tenableExternalAccountNumber);
        dispatch(setShouldRefreshData(true));
        onConfigurationChangeCallBack && onConfigurationChangeCallBack();
    };

    const validateIntegration = () => {
        getIntegrationsService().validateTenableConfiguration(tenableExternalAccountNumber);
        setIsValidateEnabled(false);
        onConfigurationChangeCallBack && onConfigurationChangeCallBack();
    };

    const accessKeyChanged = (newAccessKey: string) => {
        setConfiguration({ ...configuration, accessKey: newAccessKey } as ITenableConfigurationObj);
        setValidationMessage('');
    };

    const secretKeyChanged = (newSecretKey: string) => {
        setConfiguration({ ...configuration, secretKey: newSecretKey } as ITenableConfigurationObj);
        setValidationMessage('');
    };

    const validationMessageJSX = (
        <>
            {
                <Stack spacing={1} flexWrap={true} direction={'row'} margin={1} alignItems={'center'}>
                    {!!validationMessage && <Icon name={'error'} color={'alert'} size={12} />}
                    <Typography variant='body' color={'alert'}>
                        {validationMessage}
                    </Typography>
                </Stack>
            }
        </>
    );

    const addTenableConfigurationMode = (
        <>
            <div>
                <Typography variant='bodyLg'>{t('GENERAL.NAME')}</Typography>
                <Input
                    value={configurationName}
                    onChange={(event) => {
                        setConfigurationName(event.target.value);
                    }}
                />
            </div>
            <div>
                <Typography variant='bodyLg'>{t('CONFIGURATIONS.TENABLE.ACCESS_KEY')}</Typography>
                <Input
                    isError={!!validationMessage}
                    required={true}
                    value={configuration?.accessKey}
                    onChange={(event) => {
                        accessKeyChanged(event.target.value);
                    }}
                    fullWidth={true}
                />
            </div>
            <div>
                <Typography variant='bodyLg'>{t('CONFIGURATIONS.TENABLE.SECRET_KEY')}</Typography>
                <Input
                    isError={!!validationMessage}
                    required={true}
                    value={configuration?.secretKey}
                    onChange={(event) => {
                        secretKeyChanged(event.target.value);
                    }}
                    fullWidth={true}
                />
            </div>
            {validationMessageJSX}
        </>
    );

    const existTenableConfigurationMode = (
        <>
            <div>
                <Typography variant='body500'>{t('GENERAL.NAME')}</Typography>
                <Typography variant='bodyLg'>{configurationName}</Typography>
            </div>
            <div>
                <Typography variant='body500'>{t('CONFIGURATIONS.TENABLE.ACCESS_KEY')}</Typography>
                <Typography variant='bodyLg'>{configuration?.accessKey}</Typography>
            </div>
            <div>
                <Typography variant='body500'>{t('CONFIGURATIONS.TENABLE.LAST_MATCHED_ASSETS_COUNT')}</Typography>
                <Typography variant='bodyLg'>{configuration?.lastMatchedAssetsCount}</Typography>
            </div>
            <div>
                <Typography variant='body500'>
                    {t('CONFIGURATIONS.TENABLE.LAST_MATCHED_VULNERABILITIES_COUNT')}
                </Typography>
                <Typography variant='bodyLg'>{configuration?.lastMatchedVulnerabilitiesCount}</Typography>
            </div>
            <div>
                <Typography variant='bodyXs'>
                    {t('CONFIGURATIONS.TENABLE.LAST_VULNERABILITIES_SYNC_TIME')}: {configuration?.lastVulnerabilitySync}
                </Typography>
                <Typography variant='bodyXs'>
                    {t('CONFIGURATIONS.TENABLE.LAST_ASSET_SYNC_TIME')}: {configuration?.lastAssetSync}
                </Typography>
            </div>
        </>
    );

    const deleteIntegrationModal = (
        <Modal.ModalDialog width={'lg'} isOpen={isDeleteModalOpen}>
            <Modal.ModalHeader
                title={t('CONFIGURATIONS.TENABLE.DELETE_MODAL.TITLE')}
                hasSeparator={false}
                onClose={() => setIsDeleteModalOpen(false)}
            />
            <Modal.ModalContent>
                <Typography variant='bodyLg'>
                    {t('CONFIGURATIONS.TENABLE.DELETE_MODAL.CONTENT', { configurationName: configurationName })}
                </Typography>
            </Modal.ModalContent>
            <Modal.ModalFooter hasSeparator={false}>
                <Button
                    variant='outlined'
                    size='medium'
                    onClick={() => {
                        setIsDeleteModalOpen(false);
                    }}
                    dataAid='Cancel'
                >
                    {t('GENERAL.CANCEL')}
                </Button>
                <Button
                    variant='contained'
                    color='alert'
                    size='medium'
                    onClick={() => {
                        deleteIntegration();
                    }}
                    dataAid='Remove'
                >
                    {t('GENERAL.REMOVE')}
                </Button>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    );

    return (
        <Stack direction={'column'} fullWidth={true}>
            {getConfigurationDrawerHeader()}
            <ConfigurationWrapper>
                {tenableExternalAccountNumber ? existTenableConfigurationMode : addTenableConfigurationMode}
                <div>
                    <Button
                        variant='outlined'
                        disabled={!isValidateEnabled || viewMode}
                        color='normal'
                        onClick={() => validateIntegration()}
                        dataAid='Validate'
                    >
                        {t('GENERAL.VALIDATE')}
                    </Button>
                </div>
                <Stack justifyContent={'flex-end'} direction={'row'}>
                    <Button
                        iconProps={{ name: 'delete' }}
                        variant='text'
                        disabled={!configuration?.tenableExternalAccountNumber || viewMode}
                        onClick={() => setIsDeleteModalOpen(true)}
                    />
                    <Button
                        size={'medium'}
                        color='brandPrimary'
                        disabled={!isSaveEnabled || isSaving || viewMode}
                        onClick={() => saveIntegration()}
                    >
                        {isSaving ? <Spinner size={12} /> : <>{t('GENERAL.SAVE')}</>}
                    </Button>
                </Stack>
            </ConfigurationWrapper>
            {isDeleteModalOpen && deleteIntegrationModal}
        </Stack>
    );
};
