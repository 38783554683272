import { ICloudAccount, IOrganizationalUnit } from 'common/interface/data_services';
import { IVendorRegion } from 'common/consts/vendors';
import {
    AccessPolicy,
    IAccount,
    IAgent,
    InboundEntityOrOutboundEntity,
    ISecurityGroup,
} from 'common/interface/services';
import { IAccountAccessComponentStatus } from './DirectPermissionsComponent/interfaces';

export enum PERMISSION_TYPE {
    ONBOARDING = 'onboarding',
    ALL_CLOUD_ACCOUNT_RESOURCES = 'allCloudAccountResources',
    NOTIFICATIONS_AND_INTEGRATIONS = 'notificationsAndIntegrations',
    RULES_AND_RULESETS = 'rulesAndRulesets',
    POLICY = 'policy',
    ALERTS_EXCLUSION_REMEDIATION = 'alertsExclusionRemediation',
    CODE_SECURITY = 'codeSecurity',
    CLOUD_ACCOUNT = 'cloudAccount',
    AGENT = 'agent',
    SECURITY_GROUP = 'securityGroup',
    SECURITY_GROUP_SERVICE = 'securityGroupService',
    REGION = 'region',
    ORGANIZATIONAL_UNIT = 'organizationalUnit',
    ORGANIZATIONAL_UNIT_HEADER = 'organizationalUnitHeader',
    PLACEHOLDER = 'placeholder',
    SECURITY_GROUP_AGENT = 'securityGroupAgent',
    CREATE_SECURITY_GROUP = 'createSecurityGroup',
    CREATE_AGENT = 'createAgent',
    ACCOUNT_ACCESS = 'accountAccess',
    PLATFORM = 'platform',
    PLATFORM_HEADER = 'platformHeader',
    SECURITY_GROUP_AGENT_HEADER = 'securityGroupAgentHeader',
    AGENT_HEADER = 'agentHeader',
    ALL_SYSTEM_RESOURCES = 'allSystemResources',
    AGENT_SERVICE = 'agentService',
}

export enum PermissionCategorySubType {
    SCOPE_ENVS_OUS = 'scopeEnvsOUs',
    SCOPE_CONTROLS = 'scopeControls',
    NETWORK_ENVS_OUS = 'networkEnvsOUs',
    NETWORK_CONTROLS = 'networkControls',
    CODE_SECURITY = 'codeSecurity',
    NETWORK_ACCOUNT_ACCESS = 'networkAccountAccess',
}

export enum PermissionCategoryType {
    SCOPE_CONTROLS = 'scopeControls',
    NETWORK_SECURITY = 'networkSecurity',
    CODE_SECURITY = 'codeSecurity',
    ACCOUNT_ACCESS = 'accountAccess',
}

export enum PermissionViewMode {
    EDIT = 'edit',
    REVIEW = 'review',
}

export interface IPermissionItem {
    type: PERMISSION_TYPE;
    categorySubType: PermissionCategorySubType;
    id: string;
    view?: boolean;
    manage?: boolean;
    name?: string;
    enable?: boolean;
    info?: string;
    member?: boolean;
    admin?: boolean;
    path?: string[];
    cloudAccount?: ICloudAccount;
    organizationUnit?: IOrganizationalUnit;
    userAccount?: IAccount;
    userRole?: string;
    region?: IVendorRegion;
    inheritedRoles?: string[];
    securityGroup?: ISecurityGroup;
    agent?: IAgent;
    agentAccessPolicy?: AccessPolicy;
    service?: InboundEntityOrOutboundEntity;
}

export interface IPermissionModel {
    scopeControls?: IPermissionItem[];
    networkSecurity?: IPermissionItem[];
    codeSecurity?: IPermissionItem[];
    accountAccess?: IPermissionItem[];
}

export interface IPermissionComponentProps {
    permissionsChanged?: (data: IPermissionItem[]) => void;
    showOnlySelectedItems?: boolean;
    permissions?: IPermissionItem[];
    viewMode?: PermissionViewMode | null;
    permissionSubType?: PermissionCategorySubType;
    agentsPermissionsEnabled?: boolean;
    mspPermissionsEnabled?: boolean;
    accountAccessStatus?: IAccountAccessComponentStatus;
    title?: string;
}

export const directPermissionName = 'Direct';
export const inheritedRolesColumnWidth = 150;
export const infoColumnWidth = 80;
