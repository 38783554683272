import { getHttpService } from 'common/interface/services';
import { ERM_URLS } from '../consts';
import {
    IBaseIdModel,
    ICyeraIntegrationService,
    ICyeraConfigurationModel,
} from 'common/module_interface/RiskManagement/Services';
import { ICyeraConfiguration } from 'common/module_interface/RiskManagement/integrations/cyeraintegration/CyeraIntegration.consts';
import { IConfiguration } from 'common/module_interface/settings/integrations/Integrations';

export class CyeraIntegrationService implements ICyeraIntegrationService {
    async addCyeraAccount(clientId: string, secret: string, name: string): Promise<IBaseIdModel> {
        return await getHttpService().post<IBaseIdModel>(ERM_URLS.CYERA_INTEGRATION, {
            data: {
                clientId,
                secret,
                name,
            },
        });
    }

    async getCyeraAccounts(): Promise<ICyeraConfiguration[]> {
        const response = await getHttpService().get<ICyeraConfigurationModel>(ERM_URLS.CYERA_INTEGRATION);
        return response.accounts;
    }

    async updateCyeraAccount(accountId: string, name: string): Promise<IBaseIdModel> {
        return await getHttpService().put<IBaseIdModel>(`${ERM_URLS.CYERA_INTEGRATION}/${accountId}`, {
            data: {
                name,
            },
        });
    }

    getCyeraConfigurations = async (): Promise<IConfiguration[]> => {
        try {
            const response = await this.getCyeraAccounts();
            return response?.map((account) => ({
                id: account.id,
                name: account.name,
                configurationObj: account,
            }));
        } catch {
            return [];
        }
    };

    async deleteCyeraAccount(accountId: string): Promise<void> {
        await getHttpService().delete(`${ERM_URLS.CYERA_INTEGRATION}/${accountId}`);
    }
}
