import React, { useCallback, useEffect, useRef, useState } from 'react';
import { extractFilterFieldsToNewModel, initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { getCustomizationService, getStoreService } from 'common/interface/services';
import { getCloudAccountsService, getOrganizationalUnitService } from 'common/interface/data_services';
import { getProtectedAssetsService, IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import ProtectedAssetsTable, { CounterStatus } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import {
    AssetAggregationCacheConfig,
    Datasource,
    IDataSourceConfig,
} from 'common/components/ProtectedAssets/datasource';
import { FILTER_PANEL_QUERY_NAMES, FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { setIsReactPageWithAngular } from '../../../../App.reducer';
import { DEFAULT_PROTECTED_ASSETS_SORT_MODEL, FULL_ERM_AGGREGATION } from '../../consts';
import AssetTabs from 'common/components/ProtectedAssets/AssetTabs';
import { assetTabsMetadata } from './initialize';
import { getAllSupportedAssetTypes } from '../ProtectedAsset/ErmDataUtils';
import { IColumnUsageDef } from 'common/interface/general';
import {
    RECENTLY_USED_FILTER_ID,
    SAVED_FILTERS_FILTER_ID,
} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable.filters';
import { ERM_PROTECTED_ASSET_FILTER_IDS } from 'common/module_interface/RiskManagement/protectedAssets/filters.consts';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';
import { PROTECTED_ASSET_COL_IDS } from 'common/module_interface/assets/ProtectedAssets.consts';
import {
    CellRendererSelectorResult,
    ColDef,
    Column,
    ColumnApi,
    ColumnRowGroupChangedEvent,
    ICellRendererParams,
} from 'ag-grid-community';
import { calcGroupedColLeafIndent } from 'common/utils/tableUtils';
import { SimilarAssetGroupCellRender } from '../CellRenderers/SimilarAssetGroupCellRender';
import { RowClassParams } from 'ag-grid-community/dist/lib/entities/gridOptions';
import { isAfSimilarAssets } from '../../RiskManagement.utils';
import FullPageLoader from 'common/erm-components/custom/FullSize/FullPageLoader/FullPageLoader';

export const SIMILARITY_GROUP_ID = 'similarityGroupId';
const defaultDatasourceConfig: IDataSourceConfig = {
    pageSize: 50,
    filters: {},
    defaultSortModel: DEFAULT_PROTECTED_ASSETS_SORT_MODEL,
    aggregateMaxRiskScore: false,
    filterEntitiesWithoutRiskScore: true,
};

interface IWebAppFilterParams {
    filter?: {
        fields?: IWebAppFilter[];
        freeTextPhrase?: string;
        tags?: IWebAppKeyValue[];
    };
}

interface IWebAppFilter {
    name: string;
    value: string;
}

interface IWebAppKeyValue {
    key: string;
    value: string;
}

const SAVED_FILTERS_COMPONENT_NAME = 'risk_protected-assets';
const SAVED_FILTERS_RECENTLY_USED_COMPONENT_NAME = 'risk_protected-assets-recent';
const filters: IProtectedAssetFilter[] = [
    { id: 'add filter' },
    { id: 'free text' },
    { id: ERM_PROTECTED_ASSET_FILTER_IDS.riskScore },
    { id: ERM_PROTECTED_ASSET_FILTER_IDS.iamSensitivity },
    { id: ERM_PROTECTED_ASSET_FILTER_IDS.dataSensitivity },
    { id: ERM_PROTECTED_ASSET_FILTER_IDS.dataClassification },
    { id: 'organizational unit' },
    { id: 'platform' },
    { id: 'type' },
    { id: 'environment' },
    { id: 'region' },
    { id: 'network' },
    { id: 'resourceGroup' },
    { id: 'runState' },
    { id: 'tag' },
    { id: 'publicIdAssociated' },
    { id: 'billableAsset' },
    { id: 'labels' },
    { id: 'serverlessRuntimeProtection' },
    { id: 'serverlessAutoProtect' },
    { id: 'serverlessProtectionMode' },
    { id: 'serverlessEnable' },
    { id: ERM_PROTECTED_ASSET_FILTER_IDS.businessPriority },
    { id: ERM_PROTECTED_ASSET_FILTER_IDS.networkExposure },
    { id: ERM_PROTECTED_ASSET_FILTER_IDS.iamExposure },
    { id: ERM_PROTECTED_ASSET_FILTER_IDS.secrets },
    { id: ERM_PROTECTED_ASSET_FILTER_IDS.cves },
    { id: ERM_PROTECTED_ASSET_FILTER_IDS.openSecurityIssues },
    { id: ERM_PROTECTED_ASSET_FILTER_IDS.wafProtection },
    { id: ERM_PROTECTED_ASSET_FILTER_IDS.networkInspection },
    {
        id: SAVED_FILTERS_FILTER_ID,
        filterProps: {
            savedFiltersComponentName: SAVED_FILTERS_COMPONENT_NAME,
        },
    },
    {
        id: RECENTLY_USED_FILTER_ID,
        filterProps: {
            componentName: SAVED_FILTERS_RECENTLY_USED_COMPONENT_NAME,
        },
    },
    {
        id: 'clear all',
    },
];

export const getProtectedAssetsListColumns = (): IColumnUsageDef[] => {
    return [
        {
            id: PROTECTED_ASSET_COL_IDS.entity,
            colDefOverride: {
                cellRendererParams: {
                    generateUrl: getErmUrlsService().generateAssetUrl,
                },
                enableRowGroup: false,
            },
        },
        {
            id: 'ermRiskScore',
        },
        ...(isAfSimilarAssets() ? [{ id: SIMILARITY_GROUP_ID }] : []),
        { id: 'type' },
        { id: 'ermWafProtection' },
        { id: 'ermNetworkInspection' },
        { id: 'platform' },
        { id: 'environment' },
        { id: 'ermMisconfigurations' },
        { id: 'cves' },
        { id: 'threats' },
        { id: 'secrets' },
        { id: 'isRunning' },
        {
            id: 'name',
            colDefOverride: {
                hide: true,
                suppressColumnsToolPanel: true,
                enableRowGroup: true,
            },
        },
        { id: 'ermIamSensitivity' },
        { id: 'ermDataSensitivity' },
        { id: 'ermDataClassification' },
        { id: 'ermBusinessPriority' },
        { id: 'ermNetworkExposure' },
        { id: 'ermIamExposure' },
        { id: 'ermHasIssues' },
        { id: 'labels', colDefOverride: { hide: true } },
        { id: 'privateIpAddress' },
        { id: 'publicIpAddress' },
    ];
};

const ProtectedAssetsList: React.FC = () => {
    const [filterElement, setFilterElement] = useState(<div></div>);
    const [datasource, setDatasource] = useState(new Datasource(defaultDatasourceConfig));
    const [totalCount, setTotalCount] = useState(CounterStatus.Pending);
    const [currentCount, setCurrentCount] = useState(CounterStatus.Pending);
    const [isLoading, setIsLoading] = useState(true);
    const [isFilterLoading, setIsFilterLoading] = useState(false);

    const FILTER_PANEL_ID = 'erm-protected-assets-page-filter-panel-id';
    useEffect(() => {
        const dispatch = getStoreService().dispatch;
        dispatch(setIsReactPageWithAngular(false));
    });
    const columnsRef = useRef<IColumnUsageDef[]>(getProtectedAssetsListColumns());
    useEffect(() => {
        async function getAggregations(filtersValues: IFiltersValues): Promise<Aggregations> {
            const tempDatasource = new Datasource({
                ...defaultDatasourceConfig,
                ...{
                    filters: {
                        ...filtersValues,
                        includedEntityTypes: getAllSupportedAssetTypes(),
                    },
                    cachingConfig: AssetAggregationCacheConfig,
                },
            });
            setDatasource(tempDatasource);
            setIsFilterLoading(true);
            const searchResponse = await tempDatasource.getAdHokDataFromServer(FULL_ERM_AGGREGATION);
            setCurrentCount(tempDatasource.totalCount);
            setIsFilterLoading(false);
            return searchResponse.aggregations;
        }

        const extractWebAppFiltersFromQueryParams = () => {
            const queryParams = new URLSearchParams(window.location.search);
            const webAppQueryParams = queryParams.get(FILTER_PANEL_QUERY_NAMES.QUERY);

            if (webAppQueryParams) {
                const urlFilters: IWebAppFilterParams = JSON.parse(webAppQueryParams);
                const filter = urlFilters?.filter;
                const fields = filter?.fields ? extractFilterFieldsToNewModel(filter.fields) : {};
                const freeTextPhrase = filter?.freeTextPhrase
                    ? { [FILTERS_KEYS.FREE_TEXT]: filter?.freeTextPhrase }
                    : {};
                const tags = filter?.tags ? { [FILTERS_KEYS.TAGS]: filter.tags } : [];

                return { ...fields, ...freeTextPhrase, ...tags };
            }
        };

        async function renderFilterPanel(aggregations: Aggregations) {
            const filtersInitialData = await getFiltersInitialData();
            const webAppQueryParams = extractWebAppFiltersFromQueryParams();
            const filterPanelElementsList = getProtectedAssetsService().getFilterDefs(filters, {
                aggregations,
                filtersInitialData,
                allCloudAccounts: filtersInitialData.allCloudAccounts,
            });
            const filterPanel = initFilterPanel({
                filterPanelElementsList,
                getAggregations: getAggregations,
                shouldBuildObjectForAPI: true,
                webAppQueryParams: webAppQueryParams,
                filterId: FILTER_PANEL_ID,
            });
            setFilterElement(filterPanel);
        }

        async function initPage() {
            setIsLoading(true);
            const tempDatasource = new Datasource({
                ...defaultDatasourceConfig,
                ...{
                    filters: { includedEntityTypes: getAllSupportedAssetTypes() },
                    cachingConfig: AssetAggregationCacheConfig,
                },
            });
            const initialData = await tempDatasource.getAdHokDataFromServer(FULL_ERM_AGGREGATION);
            setTotalCount(initialData.totalCount);
            await renderFilterPanel(initialData.aggregations);
            setIsLoading(false);
        }

        initPage();
    }, []);

    const getFiltersInitialData = async () => {
        const savedFiltersPromise = getCustomizationService().getCustomization(SAVED_FILTERS_COMPONENT_NAME);
        const recentlyUsedPromise = getCustomizationService().getCustomization(
            SAVED_FILTERS_RECENTLY_USED_COMPONENT_NAME,
        );
        const organizationalUnitsPromise = getOrganizationalUnitService().getOrganizationalUnitsView();
        const allCloudAccountsPromise = getCloudAccountsService().getAllCloudAccounts();
        return Promise.all([
            savedFiltersPromise,
            recentlyUsedPromise,
            organizationalUnitsPromise,
            allCloudAccountsPromise,
        ]).then(([savedFilters, recentlyUsed, organizationalUnits, allCloudAccounts]) => {
            return {
                savedFilters,
                recentlyUsed,
                organizationalUnits,
                allCloudAccounts,
            };
        });
    };

    const isTableGrouped = useCallback((columnApi: ColumnApi) => {
        return columnApi.getRowGroupColumns().length > 0;
    }, []);

    const onColumnRowGroupChanged = useCallback(
        (event: ColumnRowGroupChangedEvent) => {
            const columns = event.columnApi.getAllGridColumns();
            const entityCol: Column | undefined = columns.find(
                (col) => col.getColId() === PROTECTED_ASSET_COL_IDS.entity,
            );
            if (entityCol && entityCol.isVisible() === isTableGrouped(event.columnApi)) {
                event.columnApi.setColumnVisible(PROTECTED_ASSET_COL_IDS.entity, !isTableGrouped(event.columnApi));
            }
        },
        [isTableGrouped],
    );

    const getEntityColDef = useCallback((params: ICellRendererParams): ColDef | undefined => {
        const entityCol: Column | undefined = params.columnApi
            .getAllGridColumns()
            .find((col) => col.getColId() === PROTECTED_ASSET_COL_IDS.entity);
        return entityCol ? entityCol.getColDef() : undefined;
    }, []);

    const getGroupRendererSelector = useCallback(
        (params: ICellRendererParams): CellRendererSelectorResult | undefined => {
            if (params.node.group) {
                if (params.node.field === SIMILARITY_GROUP_ID) {
                    const entityColDef: ColDef | undefined = getEntityColDef(params);
                    if (entityColDef) {
                        return {
                            component: 'agGroupCellRenderer',
                            params: {
                                innerRenderer: SimilarAssetGroupCellRender,
                            },
                        };
                    }
                }

                return {
                    component: 'agGroupCellRenderer',
                };
            }

            const entityColDef: ColDef | undefined = getEntityColDef(params);
            if (!entityColDef) {
                return undefined;
            }
            const indent: number | undefined = calcGroupedColLeafIndent(params.columnApi);
            const style: React.CSSProperties | undefined =
                indent !== undefined ? { paddingLeft: `${indent}px` } : undefined;
            return {
                component: entityColDef.cellRenderer,
                params: {
                    ...entityColDef.cellRendererParams,
                    style,
                },
            };
        },
        [getEntityColDef],
    );

    const getRowClass = (params: RowClassParams) =>
        params.node.group && params.node.field === SIMILARITY_GROUP_ID ? 'group-row-hide-overflow' : undefined;

    return (
        <div className='h-full inset-0 flex-column' data-aid='protected-assets-page'>
            <div className='flex-column grow '>
                {isLoading ? (
                    <FullPageLoader />
                ) : (
                    <>
                        <AssetTabs
                            enableTabSelection={false}
                            pageId={assetTabsMetadata.tabsId}
                            closeAllUrl={assetTabsMetadata.closeAllUrl}
                        ></AssetTabs>
                        <div className='flex flex-column flex-1 p-8'>
                            <div className='relative z-40'>{filterElement}</div>
                            <ProtectedAssetsTable
                                columns={columnsRef.current}
                                isLoading={isFilterLoading}
                                totalCount={totalCount}
                                currentCount={currentCount}
                                pageSize={defaultDatasourceConfig.pageSize}
                                datasource={datasource}
                                tableId='erm-protected-asset-table'
                                onColumnRowGroupChanged={isAfSimilarAssets() ? onColumnRowGroupChanged : undefined}
                                autoGroupColumnDef={
                                    isAfSimilarAssets()
                                        ? {
                                              cellRendererSelector: getGroupRendererSelector,
                                          }
                                        : undefined
                                }
                                getRowClass={isAfSimilarAssets() ? getRowClass : undefined}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ProtectedAssetsList;
