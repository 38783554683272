import { initializeI18EmailNotifications } from './initialize.i18n';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { EmailNotificationService } from './Services/EmailNotificationService';
import { EMAIL_NOTIFICATION_PAGE_SERVICE_ID } from 'common/interface/services';

export default function initializeEmailNotificationsPage() {
    const initializeEmailNotificationsPageServices = () => {
        globalAddinContainer.addService(EMAIL_NOTIFICATION_PAGE_SERVICE_ID, new EmailNotificationService());
    };

    initializeEmailNotificationsPageServices();
    void initializeI18EmailNotifications();
}
