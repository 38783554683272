import { Command, Stack, Typography } from 'common/design-system/components-v2';
import { EventOverviewTabProps } from 'common/module_interface/events/EventsDrawerRegistry';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';

const LogIdsSection: React.FC<EventOverviewTabProps> = ({ finding: event }) => {
    const { t } = useTranslation(getK8sNamespace('events'));

    const infoBlock: { title: string; value: JSX.Element; isRelevant: boolean }[] = [
        {
            title: t('WHAT_IS_LOGIDS.TITLE'),
            value: <Typography>{t('WHAT_IS_LOGIDS.BODY')}</Typography>,
            isRelevant: true,
        },
        {
            title: t('DESCRIPTION'),
            value: <Typography>{event.description}</Typography>,
            isRelevant: event.description !== undefined,
        },
        {
            title: t('FILENAME'),
            value: <Command text={event.additionalFields.find(({ name }) => name === 'File Name')?.value || ''} />,
            isRelevant: event.additionalFields.find(({ name }) => name === 'File Name')?.value !== undefined,
        },
        {
            title: t('MATCHED_TEXT'),
            value: <Command text={event.additionalFields.find(({ name }) => name === 'Matched Text')?.value || ''} />,
            isRelevant: event.additionalFields.find(({ name }) => name === 'Matched Text')?.value !== undefined,
        },
    ];

    return (
        <Stack spacing={4}>
            {infoBlock
                .filter(({ isRelevant }) => isRelevant)
                .map(({ title, value }) => (
                    <Stack spacing={2} key={title}>
                        <Typography variant='body500' color='strong'>
                            {title}
                        </Typography>
                        {value}
                    </Stack>
                ))}
        </Stack>
    );
};

export default LogIdsSection;
