import { EntityHeader } from 'common/design-system/components-v2';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';
import { getEntityBlock } from '../AwpHostConfigRuleDrawer.utils';
import { useRuleContext } from '../context/Rule/useRuleContext.hook';

const UpperPanel = () => {
    const { data } = useRuleContext();
    const { t } = useTranslation(getK8sNamespace('cis'), { keyPrefix: 'panel' });
    const blocks = getEntityBlock(data, t);
    return <EntityHeader title={''} blocks={blocks} disableShowMore></EntityHeader>;
};

export default UpperPanel;
