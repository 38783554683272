import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

export const IconWrapper = styled(Stack)<{ isFixable: boolean }>`
    background: ${({ theme, isFixable }) =>
        isFixable ? theme.palette.severity.informationalBg : theme.palette.severity.unknownBg};
    color: ${({ theme, isFixable }) =>
        isFixable ? theme.palette.severity.informationalFg : theme.palette.severity.unknownFg};
    border-radius: 50%;
`;

const IsFixableCellRendererStyles = {
    IconWrapper,
};

export default IsFixableCellRendererStyles;
