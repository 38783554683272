import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
    allSystemResources,
    buildPathPhrase,
    capitalize,
    isPermissionItemsMatch,
    permissionSorter,
    rootAgentPath,
    rootEnvironmentPath,
    rootOUPath,
    rootSecurityGroupAgentPath,
} from '../utils';
import { GridApi, GridOptions, GridReadyEvent, IRowNode } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { Input, Stack } from 'common/design-system/components-v2';
import {
    getCloudAccountsService,
    getOrganizationalUnitService,
    ICloudAccount,
    IOrganizationalUnit,
} from 'common/interface/data_services';
import { InfoCellRenderer } from '../CellRenderers/InfoCellRenderer';
import { I18nSettingsTranslationKey } from '../../../initialize';
import { GridWrapper } from './CGAssetsAndEnvironmentsComponent.styled';
import {
    infoColumnWidth,
    inheritedRolesColumnWidth,
    IPermissionComponentProps,
    IPermissionItem,
    PERMISSION_TYPE,
    PermissionCategorySubType,
    PermissionViewMode,
} from '../interfaces';
import { v4 as uuid } from 'uuid';
import { getAgentsService, getSecurityGroupsService, ISecurityGroup } from 'common/interface/services';
import { CellClickedEvent, RowGroupOpenedEvent } from 'ag-grid-community/dist/lib/events';
import { getVendor, IVendorRegion } from 'common/consts/vendors';
import { CheckboxCellRenderer } from '../CellRenderers/CheckboxCellRenderer';
import { InheritedRolesCellRenderer } from '../CellRenderers/InheritedRolesCellRenderer';
import { getUniqueValues } from 'common/utils/objectUtils';

const aws = 'aws';
interface CGAssetsAndEnvironmentsComponentProps extends IPermissionComponentProps {
    showAllResourcesItem?: boolean;
}
const regionsMap = new Map<string, [string, IVendorRegion][]>();
export const CGAssetsAndEnvironmentsComponent = ({
    title,
    showAllResourcesItem = false,
    agentsPermissionsEnabled,
    viewMode,
    permissionSubType = PermissionCategorySubType.SCOPE_ENVS_OUS,
    showOnlySelectedItems = undefined,
    permissionsChanged,
    permissions = [],
}: CGAssetsAndEnvironmentsComponentProps) => {
    const [rowData, setRowData] = useState<IPermissionItem[]>([]);
    const { t } = useTranslation(I18nSettingsTranslationKey);
    const [filterInput, setFilterInput] = useState<string>('');
    const reviewMode = viewMode === PermissionViewMode.REVIEW;
    const [allPermissions, setAllPermissions] = useState<IPermissionItem[]>([]);
    const [currentSecurityGroups, setCurrentSecurityGroups] = useState<ISecurityGroup[]>([]);
    const [showOnlySelectedPermissions, setShowOnlySelectedPermissions] = useState<boolean | undefined>(
        showOnlySelectedItems,
    );
    const apiRef = useRef<GridApi>();

    const setData = (data: IPermissionItem[]) => {
        apiRef?.current?.setRowData(data);
    };

    const onGridReady = (params: GridReadyEvent<any, any>) => {
        apiRef.current = params.api;
        setData(allPermissions.sort(permissionSorter));
    };

    useEffect(() => {
        if (showOnlySelectedItems) {
            setFilterInput('');
        }
        setShowOnlySelectedPermissions(showOnlySelectedItems);
    }, [showOnlySelectedItems]);

    const selectAllOUChildren = (_permissions: IPermissionItem[], permissionItem: IPermissionItem) => {
        _permissions
            .filter((item) => {
                if (permissionItem.type === PERMISSION_TYPE.ORGANIZATIONAL_UNIT_HEADER) {
                    return item.type === PERMISSION_TYPE.ORGANIZATIONAL_UNIT;
                }
            })
            .forEach((item) => {
                item.view = permissionItem.view;
                item.manage = permissionItem.manage;
                item.enable = permissionItem.enable;
            });
    };
    const selectChildren = (_permissions: IPermissionItem[]) => {
        const selectedItems = _permissions?.filter((item) => item.view || item.manage || item.enable);
        if (selectedItems?.length) {
            for (const permissionItem of selectedItems) {
                selectAllOUChildren(_permissions, permissionItem);
            }
        }
        return _permissions;
    };
    const fixTreeParentNodeSelection = (_permissions: IPermissionItem[]) => {
        const updateHeaderIfAllSelected = (
            permissions: IPermissionItem[],
            type: PERMISSION_TYPE,
            field: 'view' | 'manage' | 'enable',
        ) => {
            const items = permissions.filter((item) => item.type === type);
            if (items.every((item) => item[field])) {
                const header = permissions.find((item) => item.type === PERMISSION_TYPE.PLATFORM_HEADER);
                if (header) {
                    header[field] = true;
                }
            }
        };

        updateHeaderIfAllSelected(_permissions, PERMISSION_TYPE.PLATFORM, 'view');
        updateHeaderIfAllSelected(_permissions, PERMISSION_TYPE.PLATFORM, 'manage');
        updateHeaderIfAllSelected(_permissions, PERMISSION_TYPE.PLATFORM, 'enable');

        return _permissions;
    };
    useEffect(() => {
        if (!rowData.length) return;
        let _permissions = rowData;

        if (permissions?.length) {
            const _rowData = rowData.filter(
                (item) => !permissions.some((permission) => isPermissionItemsMatch(permission, item)),
            );
            _permissions = [...permissions, ..._rowData];
        }
        _permissions = selectChildren(_permissions);
        _permissions = fixTreeParentNodeSelection(_permissions);
        const _filteredData = _permissions.filter((item) =>
            item?.name?.toLowerCase()?.includes(filterInput.toLowerCase()),
        );
        setAllPermissions(_filteredData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissions, rowData, filterInput, permissionSubType]);

    useEffect(() => {
        if (showOnlySelectedPermissions) {
            let _filteredData: IPermissionItem[] = [];
            if (permissionSubType === PermissionCategorySubType.SCOPE_ENVS_OUS) {
                _filteredData = allPermissions.filter((item: IPermissionItem) => item.view || item.manage);
            } else if (permissionSubType === PermissionCategorySubType.NETWORK_ENVS_OUS) {
                _filteredData = allPermissions.filter((item: IPermissionItem) => item.enable);
            }
            setData(_filteredData.sort(permissionSorter));
            apiRef.current?.expandAll();
        } else {
            setShowOnlySelectedPermissions(undefined);
            setData(allPermissions.sort(permissionSorter));
            if (showOnlySelectedPermissions === false) {
                apiRef.current?.collapseAll();
            }
        }
    }, [allPermissions, permissionSubType, showOnlySelectedPermissions]);

    const init = async () => {
        let scopeAgents: IPermissionItem[] = [];
        let networkAgents: IPermissionItem[] = [];

        let scopeSecurityAgents: IPermissionItem[] = [];
        let networkSecurityAgents: IPermissionItem[] = [];
        const _allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts();
        const _organizationalUnits = await getOrganizationalUnitService().getAllOrganizationalUnitsFlatWithPath();
        if (agentsPermissionsEnabled) {
            const _securityGroups = await getSecurityGroupsService().getAllSecurityGroups();
            const _agents = await getAgentsService().getAgents();
            const mapScopeAgentsPermissions = () => {
                return (
                    _agents?.map((agent) => {
                        return {
                            type: PERMISSION_TYPE.AGENT,
                            categorySubType: PermissionCategorySubType.SCOPE_ENVS_OUS,
                            id: uuid(),
                            name: agent.name,
                            manage: false,
                            view: false,
                            info: t('TOOLTIPS.MANAGE_AGENTS'),
                            agent: agent,
                        } as IPermissionItem;
                    }) || []
                );
            };
            const processAgentServices = (agent: IPermissionItem) => {
                if (!agent.agent?.accessPolicy) return [];

                const accessPolicies = agent.agent.accessPolicy.filter((policy) => !policy.normallyOpen);
                return accessPolicies.map((policy) => ({
                    ...agent,
                    id: `\${agent.id}-\${service.id}`,
                    type: PERMISSION_TYPE.AGENT_SERVICE,
                    name: policy.name,
                    agentAccessPolicy: policy,
                }));
            };
            const mapNetworkAgentsPermissions = () => {
                return (
                    _agents
                        ?.map((agent) => {
                            const agentItem = {
                                type: PERMISSION_TYPE.AGENT,
                                categorySubType: PermissionCategorySubType.NETWORK_ENVS_OUS,
                                id: uuid(),
                                name: agent.name,
                                manage: false,
                                view: false,
                                info: t('TOOLTIPS.MANAGE_AGENTS'),
                                agent: agent,
                            } as IPermissionItem;
                            const serviceRows = processAgentServices(agentItem);
                            return [agentItem, ...serviceRows];
                        })
                        .flat() || []
                );
            };
            const rootSecurityGroupAgentItem: IPermissionItem = {
                type: PERMISSION_TYPE.SECURITY_GROUP_AGENT_HEADER,
                categorySubType: permissionSubType,
                id: uuid(),
                name: rootSecurityGroupAgentPath,
                path: [rootSecurityGroupAgentPath],
                info: t('TOOLTIPS.MANAGE_AGENTS'),
            };
            const rootAgentItem: IPermissionItem = {
                type: PERMISSION_TYPE.AGENT_HEADER,
                categorySubType: permissionSubType,
                id: uuid(),
                name: rootAgentPath,
                path: [rootAgentPath],
                info: t('TOOLTIPS.MANAGE_AGENTS'),
            };
            const mapScopeSecurityGroupAgentsPermissions = () => {
                return (
                    _securityGroups?.map((_securityGroup) => {
                        return {
                            type: PERMISSION_TYPE.SECURITY_GROUP_AGENT,
                            securityGroup: _securityGroup,
                            categorySubType: PermissionCategorySubType.SCOPE_ENVS_OUS,
                            id: uuid(),
                            name: _securityGroup.securityGroupName,
                            manage: false,
                            view: false,
                            info: t('TOOLTIPS.MANAGE_SECURITY_GROUPS'),
                        } as IPermissionItem;
                    }) || []
                );
            };
            const mapNetworkSecurityGroupAgentsPermissions = () => {
                return (
                    _securityGroups?.map((_securityGroup) => {
                        return {
                            type: PERMISSION_TYPE.SECURITY_GROUP_AGENT,
                            categorySubType: PermissionCategorySubType.NETWORK_ENVS_OUS,
                            id: uuid(),
                            securityGroup: _securityGroup,
                            name: _securityGroup.securityGroupName,
                            manage: false,
                            view: false,
                            info: t('TOOLTIPS.MANAGE_SECURITY_GROUPS'),
                        } as IPermissionItem;
                    }) || []
                );
            };
            scopeSecurityAgents = mapScopeSecurityGroupAgentsPermissions().concat(rootSecurityGroupAgentItem);
            networkSecurityAgents = mapNetworkSecurityGroupAgentsPermissions().concat(rootSecurityGroupAgentItem);
            scopeAgents = mapScopeAgentsPermissions().concat(rootAgentItem);
            networkAgents = mapNetworkAgentsPermissions().concat(rootAgentItem);
        }

        const OUHeaderItem: IPermissionItem = {
            type: PERMISSION_TYPE.ORGANIZATIONAL_UNIT_HEADER,
            categorySubType: permissionSubType,
            id: uuid(),
            name: rootOUPath,
            info: t('TOOLTIPS.ALL_CLOUD_ACCOUNT_RESOURCES'),
            path: [rootOUPath],
        };
        const EnvironmentsHeaderItem: IPermissionItem = {
            type: PERMISSION_TYPE.PLATFORM_HEADER,
            categorySubType: permissionSubType,
            id: uuid(),
            name: rootEnvironmentPath,
            info: t('TOOLTIPS.ALL_CLOUD_ACCOUNT_RESOURCES'),
            path: [rootEnvironmentPath],
        };
        const rootOUItem: IPermissionItem = {
            type: PERMISSION_TYPE.ORGANIZATIONAL_UNIT,
            categorySubType: permissionSubType,
            id: uuid(),
            name: 'root-ou',
            info: t('TOOLTIPS.MANAGE_SECURITY_GROUPS'),
            path: [rootOUPath, _organizationalUnits.length ? _organizationalUnits[0].path.split('/')[0] : ''],
        };
        const allResourcesItem: IPermissionItem = {
            type: PERMISSION_TYPE.ALL_SYSTEM_RESOURCES,
            categorySubType: permissionSubType,
            id: uuid(),
            name: allSystemResources,
            info: t('TOOLTIPS.ALL_SYSTEM_RESOURCES'),
            path: [allSystemResources],
        };

        if (permissionSubType === PermissionCategorySubType.SCOPE_ENVS_OUS) {
            const mapCloudAccount = (cloudAccount: ICloudAccount) => {
                return [
                    {
                        type: PERMISSION_TYPE.CLOUD_ACCOUNT,
                        categorySubType: permissionSubType,
                        id: uuid(),
                        cloudAccount: cloudAccount,
                        manage: false,
                        view: false,
                        info: t('TOOLTIPS.MANAGE_RESOURCES', { resource: capitalize(cloudAccount.platform) }),
                        name: `${cloudAccount.name} - ${cloudAccount.externalId}`,
                    },
                ] as IPermissionItem[];
            };

            const mapOU = (ou: IOrganizationalUnit) => {
                return {
                    type: PERMISSION_TYPE.ORGANIZATIONAL_UNIT,
                    categorySubType: permissionSubType,
                    id: uuid(),
                    organizationUnit: ou,
                    path: [rootOUPath, ...ou.path.split('/')],
                    manage: false,
                    view: false,
                    enable: false,
                    info: t('TOOLTIPS.MANAGE_RESOURCES', { resource: capitalize(ou.name) }),
                    name: ou.name,
                } as IPermissionItem;
            };
            const _cloudAccounts = _allCloudAccounts.map(mapCloudAccount).flat().flat();
            const allPlatforms = _allCloudAccounts.map((cloudAccount: ICloudAccount) => cloudAccount.platform);
            const platformsInUse = getUniqueValues(allPlatforms);
            const platformPermissionItems = platformsInUse.map((platform) => {
                return {
                    type: PERMISSION_TYPE.PLATFORM,
                    categorySubType: permissionSubType,
                    id: uuid(),
                    name: platform,
                    info: t('TOOLTIPS.MANAGE_RESOURCES', { resource: capitalize(platform) }),
                    path: [rootEnvironmentPath, capitalize(platform)],
                } as IPermissionItem;
            });
            const _placeholders = _cloudAccounts
                .filter((item) => item.cloudAccount?.platform === aws)
                .map((item) => {
                    return {
                        ...item,
                        type: PERMISSION_TYPE.PLACEHOLDER,
                        categorySubType: permissionSubType,
                        id: uuid(),
                    } as IPermissionItem;
                });
            const ouPermissionItems = _organizationalUnits.map(mapOU);
            const _rowData = [
                EnvironmentsHeaderItem,
                ...platformPermissionItems,
                ..._cloudAccounts,
                ..._placeholders,
                OUHeaderItem,
                rootOUItem,
                ...ouPermissionItems,
                ...scopeAgents,
                ...scopeSecurityAgents,
            ];
            if (showAllResourcesItem) {
                _rowData.push(allResourcesItem);
            }
            setRowData(_rowData);
        }

        if (permissionSubType === PermissionCategorySubType.NETWORK_ENVS_OUS) {
            const _awsAccounts: ICloudAccount[] = _allCloudAccounts.filter((item) => item.platform === aws);
            const mapCloudAccount = (cloudAccount: ICloudAccount) => {
                return {
                    type: PERMISSION_TYPE.CLOUD_ACCOUNT,
                    categorySubType: permissionSubType,
                    id: uuid(),
                    cloudAccount: cloudAccount,
                    manage: false,
                    info: t('TOOLTIPS.DYNAMIC_ACCESS'),
                    view: false,
                    name: `${cloudAccount.name} - ${cloudAccount.externalId}`,
                } as IPermissionItem;
            };
            const mapOU = (ou: IOrganizationalUnit) => {
                return {
                    type: PERMISSION_TYPE.ORGANIZATIONAL_UNIT,
                    categorySubType: permissionSubType,
                    id: uuid(),
                    organizationUnit: ou,
                    path: [t(rootOUPath), ...ou.path.split('/')],
                    manage: false,
                    view: false,
                    enable: false,
                    info: t('TOOLTIPS.DYNAMIC_ACCESS'),
                    name: ou.name,
                } as IPermissionItem;
            };
            const _cloudAccounts = _awsAccounts.map(mapCloudAccount).flat().flat();
            const allPlatforms = _allCloudAccounts.map((cloudAccount: ICloudAccount) => cloudAccount.platform);
            const platformsInUse = getUniqueValues(allPlatforms);
            const platformPermissionItems = platformsInUse.map((platform) => {
                return {
                    type: PERMISSION_TYPE.PLATFORM,
                    categorySubType: permissionSubType,
                    id: uuid(),
                    name: platform,
                    path: [rootEnvironmentPath, capitalize(platform)],
                } as IPermissionItem;
            });
            const _placeholders = _cloudAccounts.map((item) => {
                return {
                    ...item,
                    type: PERMISSION_TYPE.PLACEHOLDER,
                    categorySubType: permissionSubType,
                    id: uuid(),
                } as IPermissionItem;
            });
            const ouPermissionItems = _organizationalUnits.map(mapOU);

            setRowData([
                EnvironmentsHeaderItem,
                ..._cloudAccounts,
                ...platformPermissionItems,
                ..._placeholders,
                OUHeaderItem,
                rootOUItem,
                ...ouPermissionItems,
                ...networkAgents,
                ...networkSecurityAgents,
            ]);
        }
    };

    useEffect(() => {
        void init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getColumnDefs = () => {
        const getBaseColumns = () => {
            const baseColumns: any = [];
            if (reviewMode) {
                baseColumns.push({
                    field: 'inheritedRoles',
                    cellRenderer: InheritedRolesCellRenderer,
                    headerName: t('HEADERS.ROLES'),
                    width: inheritedRolesColumnWidth,
                });
            }
            baseColumns.push({
                field: 'info',
                headerName: t('HEADERS.INFO'),
                cellRenderer: InfoCellRenderer,
                cellRendererParams: { permissionSubType: permissionSubType },
                width: infoColumnWidth,
                reviewMode: reviewMode,
            });
            return baseColumns;
        };
        switch (permissionSubType) {
            case PermissionCategorySubType.SCOPE_ENVS_OUS:
                return [
                    ...getBaseColumns(),
                    {
                        field: 'view',
                        headerName: t('HEADERS.VIEW'),
                        cellRenderer: CheckboxCellRenderer,
                        width: 80,
                        cellRendererParams: { columnName: 'view', reviewMode: reviewMode },
                    },
                    {
                        field: 'manage',
                        headerName: t('HEADERS.MANAGE'),
                        cellRenderer: CheckboxCellRenderer,
                        width: 100,
                        cellRendererParams: { columnName: 'manage', reviewMode: reviewMode },
                    },
                ];
            case PermissionCategorySubType.NETWORK_ENVS_OUS:
                return [
                    ...getBaseColumns(),
                    {
                        field: 'enable',
                        headerName: t('HEADERS.ENABLE'),
                        cellRenderer: CheckboxCellRenderer,
                        width: 80,
                        cellRendererParams: { columnName: 'enable', reviewMode: reviewMode },
                    },
                ];
        }
    };

    const gridOptions: GridOptions = useMemo(() => {
        return {
            defaultColDef: {
                suppressMenu: true,
            },
            getRowHeight: (params) => {
                if (params.node?.data?.type === PERMISSION_TYPE.PLACEHOLDER) {
                    return 0;
                }
            },
            getRowClass: (params) => {
                const name = params.node.data?.name || '';
                return `data-aid-row-${name.replaceAll(' ', '-')}`;
            },
            columnDefs: getColumnDefs(),
            domLayout: 'autoHeight',
            autoGroupColumnDef: {
                flex: 1,
                headerName: title,
                minWidth: 200,
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: {
                    suppressCount: true,
                },
            },
            rowSelection: 'multiple',
            groupDefaultExpanded: 0,
            suppressAggFuncInHeader: true,
            treeData: true,
            getRowId: (params) => {
                const item = params.data as IPermissionItem;
                switch (item.type) {
                    case PERMISSION_TYPE.PLACEHOLDER:
                        return item.id;
                    default:
                        return [
                            'type',
                            item.type,
                            'categorySubType',
                            item.categorySubType,
                            'name',
                            item.name,
                            'cloudAccountId',
                            item.cloudAccount?.id,
                            'region',
                            item.region?.region,
                            'securityGroupId',
                            item.securityGroup?.securityGroupId,
                            'securityGroupService',
                            item.service?.id,
                            'agentId',
                            item.agent?.id,
                            'ouName',
                            item.organizationUnit?.name,
                            'ouId',
                            item.organizationUnit?.id,
                        ].join('-');
                }
            },
            getDataPath: (data) => {
                return buildPathPhrase(data as IPermissionItem);
            },
        };
    }, [getColumnDefs, title]);

    const onCellClicked = useCallback(
        (event: CellClickedEvent) => {
            // @ts-ignore
            if (event?.event?.target?.tagName === 'INPUT' && event.event.target.type === 'checkbox') {
                let item: IPermissionItem | undefined = event.node.data as IPermissionItem;
                if (!item.type) {
                    const parent = event?.node?.parent?.data as IPermissionItem;
                    item = allPermissions.find(
                        (item) =>
                            item.region?.region === event.node.key &&
                            item.cloudAccount?.id === parent.cloudAccount?.id &&
                            item.service?.id === parent.service?.id,
                    );
                }
                if (item) {
                    if (permissionsChanged) {
                        permissionsChanged(allPermissions?.filter((item) => item.enable || item.manage || item.view));
                    }
                }
            }
        },
        [allPermissions, permissionsChanged],
    );

    const scopeSearchChanged = (filterInput: string) => {
        setFilterInput(filterInput);
    };

    const isNodeExpandedAndNotPopulated = (node: IRowNode<any>) => {
        return node.expanded && !node.data?.populated;
    };

    const OnTreeExpand = async (event: RowGroupOpenedEvent) => {
        try {
            const node = event.node;
            if (isNodeExpandedAndNotPopulated(node)) {
                if (node.data) {
                    node.data.populated = true;
                }
                const _permissionNodeItem = node.data as IPermissionItem;
                if (!_permissionNodeItem) return;
                if (_permissionNodeItem.cloudAccount?.platform.toLowerCase() === aws) {
                    const getRegionsPermissionItems = (_regionsEntries: undefined | [string, IVendorRegion][]) => {
                        const regions: IPermissionItem[] | IPermissionItem[][] | undefined =
                            _regionsEntries?.map((regionEntry) => {
                                return {
                                    type: PERMISSION_TYPE.REGION,
                                    categorySubType: permissionSubType,
                                    id: uuid(),
                                    cloudAccount: _permissionNodeItem.cloudAccount,
                                    manage: false,
                                    region: regionEntry[1],
                                    view: false,
                                    info: t('TOOLTIPS.MANAGE_RESOURCES', {
                                        resource: capitalize(_permissionNodeItem.cloudAccount?.platform || ''),
                                    }),
                                    name: _permissionNodeItem.name,
                                } as IPermissionItem;
                            }) || [];
                        return [...regions!];
                    };

                    if (_permissionNodeItem?.type === PERMISSION_TYPE.CLOUD_ACCOUNT) {
                        const regions = getVendor(_permissionNodeItem?.cloudAccount?.platform || '')?.regions;
                        if (regions) {
                            regionsMap.set(_permissionNodeItem?.cloudAccount?.platform, Object.entries(regions));
                        }
                        let regionsPermissionItems = getRegionsPermissionItems(
                            regionsMap.get(_permissionNodeItem?.cloudAccount?.platform),
                        );
                        regionsPermissionItems = regionsPermissionItems
                            .map((item) => {
                                const existingItem = allPermissions.find(
                                    (a) =>
                                        a.securityGroup?.securityGroupId === item.securityGroup?.securityGroupId &&
                                        a.region?.region === item.region?.region &&
                                        a.cloudAccount?.id === item.cloudAccount?.id,
                                );
                                if (!existingItem) return item;
                                return null;
                            })
                            .filter((item) => item !== null) as IPermissionItem[];
                        regionsPermissionItems.forEach((item) => {
                            item.manage = _permissionNodeItem.manage;
                            item.view = _permissionNodeItem.view;
                            item.enable = _permissionNodeItem.enable;
                        });

                        const _regionsPlaceholders = regionsPermissionItems.map((item) => {
                            return {
                                ...item,
                                type: PERMISSION_TYPE.PLACEHOLDER,
                                categorySubType: permissionSubType,
                                id: uuid(),
                            } as IPermissionItem;
                        });

                        setRowData((prevPermissions) => [
                            ...prevPermissions,
                            ...regionsPermissionItems,
                            ..._regionsPlaceholders,
                        ]);
                    }

                    if (_permissionNodeItem?.type === PERMISSION_TYPE.REGION) {
                        const getSecurityGroupsPermissionItems = async (cloudAccount: string, region: string) => {
                            const rowSecurityGroups: ISecurityGroup[] =
                                await getSecurityGroupsService().getCloudSecurityGroups(cloudAccount, region);
                            setCurrentSecurityGroups(rowSecurityGroups);
                            const securityGroups: IPermissionItem[] = rowSecurityGroups.map((_securityGroup) => {
                                return {
                                    type: PERMISSION_TYPE.SECURITY_GROUP,
                                    categorySubType: permissionSubType,
                                    id: uuid(),
                                    cloudAccount: _permissionNodeItem.cloudAccount,
                                    manage: false,
                                    region: _permissionNodeItem.region,
                                    securityGroup: _securityGroup,
                                    view: false,
                                    info: t('TOOLTIPS.MANAGE_RESOURCES', {
                                        resource: capitalize(_permissionNodeItem.cloudAccount?.platform || ''),
                                    }),
                                    name: _permissionNodeItem.name,
                                } as IPermissionItem;
                            });
                            return securityGroups;
                        };
                        const securityGroupsPermissionItems: IPermissionItem[] = await getSecurityGroupsPermissionItems(
                            _permissionNodeItem.cloudAccount.id,
                            _permissionNodeItem.region?.region || '',
                        );
                        const _securityGroupsItems = securityGroupsPermissionItems.length
                            ? securityGroupsPermissionItems
                            : [];

                        const _securityGroupsPlaceHolders = securityGroupsPermissionItems.map((item) => {
                            return {
                                ...item,
                                type: PERMISSION_TYPE.PLACEHOLDER,
                                categorySubType: permissionSubType,
                                id: uuid(),
                            } as IPermissionItem;
                        });

                        setRowData((prevPermissions) => [
                            ...prevPermissions,
                            ..._securityGroupsItems,
                            ..._securityGroupsPlaceHolders,
                        ]);
                    }
                    if (
                        _permissionNodeItem?.type === PERMISSION_TYPE.SECURITY_GROUP &&
                        _permissionNodeItem.categorySubType === PermissionCategorySubType.NETWORK_ENVS_OUS
                    ) {
                        const securityGroupsServices: IPermissionItem[][] =
                            currentSecurityGroups
                                ?.filter(
                                    (item) =>
                                        item.securityGroupId === _permissionNodeItem.securityGroup?.securityGroupId,
                                )
                                .map((securityGroup) => {
                                    return (
                                        securityGroup.services?.inbound
                                            ?.filter((inbound) => {
                                                return inbound.scope?.every(
                                                    (_scope) =>
                                                        !(_scope.type === 'CIDR' && _scope.data.cidr === '0.0.0.0/0'),
                                                );
                                            })
                                            ?.map((inbound) => {
                                                return {
                                                    type: PERMISSION_TYPE.SECURITY_GROUP_SERVICE,
                                                    categorySubType: permissionSubType,
                                                    id: uuid(),
                                                    cloudAccount: _permissionNodeItem.cloudAccount,
                                                    manage: false,
                                                    service: inbound,
                                                    region: _permissionNodeItem.region,
                                                    securityGroup: securityGroup,
                                                    view: false,
                                                    info: t('TOOLTIPS.MANAGE_RESOURCES', {
                                                        resource: capitalize(
                                                            _permissionNodeItem.cloudAccount?.platform || '',
                                                        ),
                                                    }),
                                                    name: `${securityGroup.securityGroupName} - ${inbound.name}`,
                                                } as IPermissionItem;
                                            }) || []
                                    );
                                }) || [];
                        setRowData((prevPermissions) => [...prevPermissions, ...securityGroupsServices.flat()]);
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Stack data-aid={'assets-environments'} spacing={1}>
            {showOnlySelectedPermissions ? (
                ''
            ) : (
                <Input
                    placeholder={t('GENERAL.SEARCH_OU_ENV')}
                    fullWidth={true}
                    onChange={(e) => scopeSearchChanged(e.target.value)}
                />
            )}
            <GridWrapper>
                <AgGridReact
                    onGridReady={onGridReady}
                    onRowGroupOpened={OnTreeExpand}
                    groupDefaultExpanded={0}
                    className={'ag-theme-alpine'}
                    onCellClicked={onCellClicked}
                    gridOptions={gridOptions}
                    treeData={true}
                    animateRows={true}
                    rowSelection='multiple'
                />
            </GridWrapper>
        </Stack>
    );
};
