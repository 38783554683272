import i18n, { validLanguages } from 'common/services/translations/translations';

export const AlibabaEnvNamespacePrefix = 'alibaba-env';
export type AlibabaEnvTermTypes = 'alibaba';
const termsFolders: Array<AlibabaEnvTermTypes> = ['alibaba'];

export const getAlibabaEnvNamespace = (term: AlibabaEnvTermTypes) => `${AlibabaEnvNamespacePrefix}_${term}`;

const jsonFilesPaths = validLanguages.reduce<Array<{ path: string; language: string; term: AlibabaEnvTermTypes }>>(
    (acc, language) => {
        const filesPAth = termsFolders.map((term) => ({ path: `${language}/${term}`, language, term }));
        return [...acc, ...filesPAth];
    },
    [],
);

export const initializeAlibabaEnvI18n = async () => {
    await Promise.all(
        jsonFilesPaths.map(async (file) => {
            try {
                const contents = await require(`./languages/${file.path}.json`);
                i18n.addResourceBundle(file.language, getAlibabaEnvNamespace(file.term), contents);
                // eslint-disable-next-line
            } catch (error) {}
        }),
    );
};
