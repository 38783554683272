import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { AwpRescanChipActionFunction, AwpScanChipProviderTypes } from './AwpRescanChip.types';
import {
    AwpRescanChip,
    EnableAwpScanChip,
} from 'modules/workloads/services/workload/components/AwpScanChip/AwpScanChip';
import { Vendors } from 'common/consts/vendors';
import i18n from 'common/services/translations/translations';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { ScanMode } from 'modules/workloads/services/awp/awp.interface';

const inAccountEnabledActionFunction = (entity: ICloudEntityData) => {
    return {
        component: EnableAwpScanChip,
        componentProps: {
            cloudAccountId: entity.cloudAccountId,
            entityId: entity.entityId,
            platform: entity.cloudAccount.platform as Vendors,
        },
    };
};

const awpNotEnabledActionFunction = () => {
    return {
        component: AwpRescanChip,
        componentProps: {
            disabled: true,
            tooltip: i18n.t(`${getK8sNamespace('common')}:workloadImageScan.agentlessScan.notEnabled`),
        },
    };
};

const saasEnabledActionFunction = () => {
    return {
        component: AwpRescanChip,
        componentProps: {
            disabled: true,
            tooltip: i18n.t(`${getK8sNamespace('common')}:workloadImageScan.agentlessScan.saasNotSupported`),
        },
    };
};

export const awpScanChipProviders: Record<AwpScanChipProviderTypes, AwpRescanChipActionFunction> = {
    [AwpScanChipProviderTypes.InAccountEnabled]: inAccountEnabledActionFunction,
    [AwpScanChipProviderTypes.AwpNotEnabled]: awpNotEnabledActionFunction,
    [AwpScanChipProviderTypes.SaasEnabled]: saasEnabledActionFunction,
};

export const getProviderType = (isEnabled: boolean, scanMode?: ScanMode): AwpScanChipProviderTypes => {
    if (!isEnabled) {
        return AwpScanChipProviderTypes.AwpNotEnabled;
    }
    if (scanMode === ScanMode.Saas) {
        return AwpScanChipProviderTypes.SaasEnabled;
    }
    return AwpScanChipProviderTypes.InAccountEnabled;
};
