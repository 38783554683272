import React from 'react';
import { Controller, RegisterOptions, useForm } from 'react-hook-form';
import { EventsModalOpenFrom, EventsModalProps } from 'common/module_interface/events/EventsModalsRegistry';
import { useTranslation } from 'react-i18next';
import { Message, SelectV2, Stack, TextArea } from 'common/design-system/components-v2';
import { getEventsNamespace } from 'modules/events/initialize.i18n';
import { ComplianceFindingComment } from 'common/module_interface/events/complianceFindingApi.interface';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { FindingSeverityServerEnum } from 'common/consts/FindingSeverity';
import { useMutateEventSeverity } from 'modules/events/services/complianceFindingApi/hooks/useMutateEventSeverity';

interface FormValues {
    comment: string;
    severity: FindingSeverityServerEnum;
}
const commentMaxLength = 200;

const ChangeSeverityAction: React.FC<EventsModalProps> = ({
    closeModal,
    selectedRows,
    resetSelectedRows,
    requestNewData,
    openFrom,
}) => {
    const { t } = useTranslation(getEventsNamespace('actions'));

    const onSuccess = (response: ComplianceFindingComment.Response) => {
        if (response.hasErrors) {
            const failedCount = Object.keys(response.failedItems).length;
            getNotificationsService().addNotification({
                text: t('CHANGE_SEVERITY.NOTIFICATIONS.PARTIAL_ERROR', {
                    failedCount,
                    eventsCount: selectedRows.length,
                }),
                type: NotificationType.WARNING,
            });
        } else {
            getNotificationsService().addNotification({
                text: t('CHANGE_SEVERITY.NOTIFICATIONS.SUCCESS', { count: selectedRows.length }),
                type: NotificationType.SUCCESS,
            });
        }
        if (openFrom === EventsModalOpenFrom.TABLE && resetSelectedRows) {
            resetSelectedRows();
        }
        closeModal();
        requestNewData?.();
    };

    const onError = () => {
        getNotificationsService().addNotification({
            text: t('CHANGE_SEVERITY.NOTIFICATIONS.ERROR', { count: selectedRows.length }),
            type: NotificationType.ERROR,
        });
    };

    const { changeSeverity, isLoading } = useMutateEventSeverity({ onSuccess, onError });

    const inputValidations: { [input: string]: RegisterOptions } = React.useMemo(
        () => ({
            comment: {
                required: t('CHANGE_SEVERITY.INPUTS.COMMENT.ERRORS.REQUIRED'),
                maxLength: {
                    value: commentMaxLength,
                    message: t('CHANGE_SEVERITY.INPUTS.COMMENT.ERRORS.MAX_LENGTH', { value: commentMaxLength }),
                },
            },
            severity: {
                required: t('CHANGE_SEVERITY.INPUTS.SEVERITY.ERRORS.REQUIRED'),
            },
        }),
        [t],
    );

    const { handleSubmit, control } = useForm<FormValues>({
        defaultValues: {
            comment: '',
            severity: undefined,
        },
    });

    const submitUpdate = async (data: FormValues) => {
        const { comment, severity } = data;
        if (comment && severity) {
            changeSeverity(selectedRows, { comment, severity });
        }
    };

    return (
        <Message
            title={t('CHANGE_SEVERITY.TITLE')}
            text={selectedRows.length > 1 ? t('CHANGE_SEVERITY.TEXT', { count: selectedRows.length }) : undefined}
            submitBtnText={t('CHANGE_SEVERITY.BUTTONS.SUBMIT')}
            width='lg'
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={handleSubmit((data) => submitUpdate(data))}
            isLoading={isLoading}
            dataAid='events-changeSeverity-action'
        >
            <Controller
                name='severity'
                rules={inputValidations['severity']}
                control={control}
                render={({ field, fieldState }) => (
                    <Stack>
                        <SelectV2
                            autoFocus
                            label={t('CHANGE_SEVERITY.INPUTS.SEVERITY.LABEL')}
                            placeholder={t('CHANGE_SEVERITY.INPUTS.SEVERITY.PLACEHOLDER')}
                            required
                            fullWidth
                            isMulti={false}
                            options={Object.keys(FindingSeverityServerEnum).map((key) => ({
                                label: t(`CHANGE_SEVERITY.INPUTS.SEVERITY.OPTIONS.${key}`),
                                value: key,
                            }))}
                            isError={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            {...field}
                        />
                    </Stack>
                )}
            />
            <Controller
                name='comment'
                rules={inputValidations['comment']}
                control={control}
                render={({ field, fieldState }) => (
                    <Stack>
                        <TextArea
                            label={t('CHANGE_SEVERITY.INPUTS.COMMENT.LABEL')}
                            placeholder={t('CHANGE_SEVERITY.INPUTS.COMMENT.PLACEHOLDER')}
                            rows={4}
                            required
                            fullWidth
                            isError={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            maxLength={commentMaxLength}
                            {...field}
                        />
                    </Stack>
                )}
            />
        </Message>
    );
};

export default ChangeSeverityAction;
