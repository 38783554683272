import {
    IBoxItem,
    ICustomFormProps,
    IItem,
    IItemsMap,
} from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import {
    boxProps,
    getCommonIgnoreFailedNotification,
    getCommonIgnoreItemsMap,
    getCommonIgnoreSuccessNotification,
} from '../CommonIgnoreList/CommonIgnoreList.items';
import { IMalwareIgnoreItem, IMalwareIgnorePartial } from './MalwareIgnoreList.interface';
import { ICommonIgnoreItemModel, ICommonItemsMap } from '../CommonIgnoreList/CommonIgnoreList.interface';
import {
    MalwareIgnoreNames,
    MALWARE_IGNORE_LIST_PAGE_ID,
    MalwareIgnoreInnerNames,
    MALWARE_IGNORE_LIST_MODEL_FIELDS,
    MALWARE_IGNORE_FORM_DRAWER_KEY,
} from './MalwareIgnoreList.consts';
import { fetchAllMalwareIgnoreItems, saveIgnoreItem } from './MalwareIgnoreList.datasource';
import { ermTrans } from '../../../RiskManagement.utils';
import { CommonIgnoreNames } from '../CommonIgnoreList/CommonIgnoreList.consts';
import { getCommonIgnoreItemFromModel } from '../CommonIgnoreList/CommonIgnoreList.model';
import {
    commonBoxItemProps,
    commonLazySelectFieldItemProps,
} from 'common/erm-components/custom/CustomForm/CustomForm.consts';
import { isEmptyItemValue } from 'common/erm-components/custom/CustomForm/CustomForm.values';
import { getConditionsMap, getConditionValues, IConditionsMap } from '../../../RiskManagement.conditions';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { IMalware } from '../../Issues/IssueViewer/IssueViewerPanels/IssueEvidencePanel/Malware/Malware.interface';

const getItems = async (ignoreItem?: IMalwareIgnorePartial): Promise<IItem[]> => {
    const commonItemsMap: ICommonItemsMap = await getCommonIgnoreItemsMap(fetchAllMalwareIgnoreItems, ignoreItem);
    const malwareDetailsItem: IBoxItem = await getMalwareBox(ignoreItem);
    return [
        commonItemsMap[CommonIgnoreNames.name],
        commonItemsMap[CommonIgnoreNames.description],
        commonItemsMap[CommonIgnoreNames.expirationDate],
        malwareDetailsItem,
        commonItemsMap[CommonIgnoreNames.vulBox],
    ];
};

export const getMalwareIgnoreForm = (origIgnoreItem?: IMalwareIgnorePartial): ICustomFormProps => {
    return {
        getItems,
        submitData: saveIgnoreItem,
        getSubmitSuccessNotification: getCommonIgnoreSuccessNotification,
        getSubmitFailedNotification: getCommonIgnoreFailedNotification,
        title: origIgnoreItem?.id ? origIgnoreItem?.name || origIgnoreItem.id : '(New Malware Ignore Item)',
        minDialogHeight: 300,
        eventHandlerId: MALWARE_IGNORE_LIST_PAGE_ID,
    };
};

export const showMalwareIgnoreForm = (origIgnoreItem?: IMalwareIgnorePartial): void => {
    showDrawer(MALWARE_IGNORE_FORM_DRAWER_KEY, origIgnoreItem);
};

export const createIgnoreItemForMalware = (malware: IMalware) => {
    const initialIgnoreItem: IMalwareIgnorePartial = {
        name: ermTrans('IGNORE_LIST.MALWARE_IGNORE_LIST.ACTIONS.SUGGESTED_IGNORE_ITEM_NAME', {
            malwareId: malware.malwareId,
        }),
        description: ermTrans('IGNORE_LIST.MALWARE_IGNORE_LIST.ACTIONS.SUGGESTED_IGNORE_ITEM_DESCRIPTION', {
            malwareId: malware.malwareId,
        }),
        malwareIds: [malware.malwareId],
        entityId: malware.entityId,
    };

    showMalwareIgnoreForm(initialIgnoreItem);
};

export const getMalwareIgnoreItemFromModel = (model: ICommonIgnoreItemModel): IMalwareIgnoreItem => {
    const { filter } = model;
    const condMap: IConditionsMap = getConditionsMap(filter);
    const commonItems = getCommonIgnoreItemFromModel(model);
    return {
        ...commonItems,
        malwareIds: getConditionValues(MALWARE_IGNORE_LIST_MODEL_FIELDS.MALWARE_IDS, condMap),
    };
};

export const getMalwareIgnoreItemsFromModels = (models: ICommonIgnoreItemModel[]): IMalwareIgnoreItem[] => {
    return models.map((model) => getMalwareIgnoreItemFromModel(model));
};

export const checkMalwareIdFields = async (item: IItem, itemsMap: IItemsMap): Promise<string | undefined> => {
    if (!isEmptyItemValue(itemsMap[MalwareIgnoreInnerNames.malwareIds])) {
        return;
    }

    return ermTrans('IGNORE_LIST.MALWARE_IGNORE_LIST.VALIDATION.AT_LEAST_ONE_MALWARE_ID_IS_REQUIRED');
};

export const getMalwareBox = async (ignoreItem?: IMalwareIgnorePartial): Promise<IBoxItem> => {
    return {
        ...commonBoxItemProps,
        name: MalwareIgnoreNames.malwareBox,
        checkValidation: checkMalwareIdFields,
        state: {
            ...boxProps,
            label: ermTrans('IGNORE_LIST.MALWARE_IGNORE_LIST.FORM.MALWARE_LABEL'),
            tooltip: ermTrans('IGNORE_LIST.MALWARE_IGNORE_LIST.FORM.TOOLTIP.MALWARE_LABEL'),
            isRequired: false,
        },
        subItems: [
            {
                ...commonLazySelectFieldItemProps,
                name: MalwareIgnoreInnerNames.malwareIds,
                state: {
                    value: ignoreItem?.malwareIds,
                    label: ermTrans('IGNORE_LIST.MALWARE_IGNORE_LIST.TABLE.COLUMNS.MALWARE_IDS.HEADER'),
                    tooltip: ermTrans('IGNORE_LIST.MALWARE_IGNORE_LIST.FORM.TOOLTIP.MALWARE_IDS'),
                    placeholder: ermTrans('IGNORE_LIST.MALWARE_IGNORE_LIST.FORM.PLACEHOLDER.MALWARE_IDS'),
                    disableWildcards: true,
                    isRequired: true,
                },
            },
        ],
    };
};
