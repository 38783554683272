import React from 'react';
import { EdgeData } from '../../Common/Models/GraphModels';
import { TFunction } from 'i18next';
import { CommonPorts, ProtocolsAndPortsConsts } from 'common/consts/ProtocolsAndPortsConsts';
import moment from 'moment-mini';

const NetworkTrafficEdgePopoverComponent: React.FC<EdgeData> = (edgeData: EdgeData, t: TFunction) => {
    const additionalData = edgeData.additionalData;
    const protocol = additionalData?.protocol
        ? ProtocolsAndPortsConsts[parseInt(additionalData?.protocol) + 1].name
        : t('INT_GRAPH.EDGE_INFO.POPOVER.UNKNOWN_PROTOCOL');
    const port = additionalData?.port ? CommonPorts[additionalData?.port] : additionalData?.port;
    const eventTime = moment(additionalData?.time).format('lll');

    return (
        <div className='-mx-6 -mt-5 -mb-5 bg-content'>
            <div className='px-7 pt-7 break-all flex-0'>{t(`INT_GRAPH.EDGE_INFO.${additionalData?.direction}`)}</div>

            <div className={'p-7'}>
                <div className={'grid max-h-[400px] overflow-y-auto text-sm'}>
                    <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                        <table className={'table--tertiary table'}>
                            <thead>
                                <tr>
                                    <th
                                        className={'border-r px-4'}
                                    >{`${t('INT_GRAPH.EDGE_INFO.POPOVER.PROTOCOL')} : ${t('INT_GRAPH.EDGE_INFO.POPOVER.PORT')}`}</th>
                                    <th>{t('INT_GRAPH.EDGE_INFO.POPOVER.TIME')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr key={0} className={'border-t'}>
                                    <td
                                        className={'border-r px-4'}
                                    >{`${protocol || t('INT_GRAPH.EDGE_INFO.POPOVER.UNKNOWN_PROTOCOL')} : ${port || additionalData?.port || t('INT_GRAPH.EDGE_INFO.POPOVER.UNKNOWN_PORT')}`}</td>
                                    <td>{eventTime}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NetworkTrafficEdgePopoverComponent;
