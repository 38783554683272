import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import {
    renderAddFilter,
    renderAddItemFilter,
    renderMultiSelectFilter,
    renderTreeFilter,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { mapTypeItem } from 'common/module_interface/assets/utils';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IntegrationsRegistry } from 'common/module_interface/settings/integrations/IntegrationsRegistry';
import i18n from 'common/services/translations/translations';
import { I18nIntegrations, SENTINEL_FILTER_FIELDS } from 'common/module_interface/settings/integrations/consts';
import { IIntegrationsFilterParams } from 'common/module_interface/settings/integrations/Integrations';
import { IDisplayMappingObject } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { getVendor, getVendorByElasticNumber } from 'common/consts/vendors';
import { ICloudAccount } from 'common/interface/data_services';
import {
    findingModelActionCodeToString,
    findingModelOriginCodeToString,
    IFindingModelActionCode,
    IFindingModelOriginCode,
} from 'common/components/Findings/Findings.interface';

const filterDefsAddins: () => Addin<IFilterProps>[] = () => [
    {
        id: FILTERS_KEYS.ADD_FILTER,
        content: {
            filterProps: { key: FILTERS_KEYS.ADD_FILTER },
            renderFunction: renderAddFilter,
        },
    },
    {
        id: SENTINEL_FILTER_FIELDS.ORGANIZATIONAL_UNIT,
        content: function ({ filtersInitialData }: IIntegrationsFilterParams) {
            return {
                filterProps: {
                    isMultiSelect: true,
                    initialData: filtersInitialData?.organizationalUnits || {},
                    key: SENTINEL_FILTER_FIELDS.ORGANIZATIONAL_UNIT,
                    title: i18n.t('CONFIGURATIONS.SENTINEL.FILTER_TOPICS.ORGANIZATION_UNIT', { ns: I18nIntegrations }),
                },
                renderFunction: renderTreeFilter,
            };
        },
    },
    {
        id: SENTINEL_FILTER_FIELDS.SEVERITY,
        content: function ({ aggregations }: IIntegrationsFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations[SENTINEL_FILTER_FIELDS.SEVERITY],
                    key: SENTINEL_FILTER_FIELDS.SEVERITY,
                    title: i18n.t('CONFIGURATIONS.SENTINEL.FILTER_TOPICS.SEVERITY', { ns: I18nIntegrations }),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: SENTINEL_FILTER_FIELDS.CLOUD_ACCOUNT_TYPE,
        content: function ({ aggregations }: IIntegrationsFilterParams) {
            const displayMapping: IDisplayMappingObject = {};
            aggregations[SENTINEL_FILTER_FIELDS.CLOUD_ACCOUNT_TYPE]?.forEach((platform: { value: number }) => {
                const vendorName = getVendorByElasticNumber(platform.value)?.name;
                const vendor = getVendor(vendorName || '');
                if (!vendor) return;
                displayMapping[platform.value] = {
                    displayText: vendor.displayName,
                    icon: vendor.icon,
                };
            });
            return {
                filterProps: {
                    initialData: aggregations[SENTINEL_FILTER_FIELDS.CLOUD_ACCOUNT_TYPE],
                    key: SENTINEL_FILTER_FIELDS.CLOUD_ACCOUNT_TYPE,
                    title: i18n.t('CONFIGURATIONS.SENTINEL.FILTER_TOPICS.PLATFORM', { ns: I18nIntegrations }),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: SENTINEL_FILTER_FIELDS.CLOUD_ACCOUNT_ID,
        content: function ({ aggregations, filtersInitialData }: IIntegrationsFilterParams) {
            const displayMapping: IDisplayMappingObject = {};
            const indexCloudAccounts: { [id: string]: ICloudAccount } =
                filtersInitialData?.allCloudAccounts?.reduce<{ [id: string]: ICloudAccount }>((acc, curr) => {
                    acc[curr.id] = curr;
                    return acc;
                }, {}) || {};
            aggregations[SENTINEL_FILTER_FIELDS.CLOUD_ACCOUNT_ID]?.forEach((id: { value: string }) => {
                const [platformNum, accountId] = id.value.split('|');
                const vendorName = getVendorByElasticNumber(Number(platformNum))?.name;
                const vendor = getVendor(vendorName || '');
                if (!vendor) return;
                displayMapping[id.value] = {
                    displayText: indexCloudAccounts[accountId]
                        ? `${indexCloudAccounts[accountId].name}(${indexCloudAccounts[accountId].externalId})`
                        : accountId,
                    icon: vendor.icon,
                };
            });
            return {
                filterProps: {
                    initialData: aggregations[SENTINEL_FILTER_FIELDS.CLOUD_ACCOUNT_ID],
                    key: 'cloudAccountId_calc',
                    title: i18n.t('CONFIGURATIONS.SENTINEL.FILTER_TOPICS.ENVIRONMENT', { ns: I18nIntegrations }),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: SENTINEL_FILTER_FIELDS.SHOW_EXCLUDED,
        content: function ({ aggregations }: IIntegrationsFilterParams) {
            const isExcluded = 'isExcluded';
            const trueText = 'true';
            const falseText = 'false';

            const displayMapping: IDisplayMappingObject = {
                [trueText]: {
                    displayText: i18n.t('GENERAL.YES', { ns: I18nIntegrations }),
                },
                [falseText]: {
                    displayText: i18n.t('GENERAL.NO', { ns: I18nIntegrations }),
                },
            };

            const counters =
                aggregations?.[isExcluded]?.reduce<{ [key: string]: number }>((acc, curr) => {
                    acc[curr.value] = curr.count;
                    return acc;
                }, {}) || {};

            const initialData = [
                { count: counters[trueText] || 0, value: trueText },
                { count: counters[falseText] || 0, value: falseText },
            ];

            return {
                filterProps: {
                    initialData,
                    key: isExcluded,
                    title: i18n.t('CONFIGURATIONS.SENTINEL.FILTER_TOPICS.SHOW_EXCLUDED', { ns: I18nIntegrations }),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: SENTINEL_FILTER_FIELDS.SHOW_ACKNOWLEDGED,
        content: function ({ aggregations }: IIntegrationsFilterParams) {
            const acknowledged = 'acknowledged';
            const trueText = 'true';
            const falseText = 'false';

            const displayMapping: IDisplayMappingObject = {
                [trueText]: {
                    displayText: i18n.t('GENERAL.YES', { ns: I18nIntegrations }),
                },
                [falseText]: {
                    displayText: i18n.t('GENERAL.NO', { ns: I18nIntegrations }),
                },
            };

            const counters =
                aggregations?.[acknowledged]?.reduce<{ [key: string]: number }>((acc, curr) => {
                    acc[curr.value] = curr.count;
                    return acc;
                }, {}) || {};

            const initialData = [
                { count: counters[trueText] || 0, value: trueText },
                { count: counters[falseText] || 0, value: falseText },
            ];

            return {
                filterProps: {
                    initialData,
                    key: acknowledged,
                    title: i18n.t('CONFIGURATIONS.SENTINEL.FILTER_TOPICS.SHOW_ACKNOWLEDGED', { ns: I18nIntegrations }),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: SENTINEL_FILTER_FIELDS.ENTITY_TYPE,
        content: function ({ aggregations }: IIntegrationsFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations[SENTINEL_FILTER_FIELDS.ENTITY_TYPE],
                    key: SENTINEL_FILTER_FIELDS.ENTITY_TYPE,
                    title: i18n.t('CONFIGURATIONS.SENTINEL.FILTER_TOPICS.ENTITY_TYPE', { ns: I18nIntegrations }),
                    displayMapping: mapTypeItem(aggregations[SENTINEL_FILTER_FIELDS.ENTITY_TYPE]),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: SENTINEL_FILTER_FIELDS.ACTION,
        content: function ({ aggregations }: IIntegrationsFilterParams) {
            const displayMapping: IDisplayMappingObject = {};
            aggregations[SENTINEL_FILTER_FIELDS.ACTION]?.forEach((action: { value: IFindingModelActionCode }) => {
                const actionString = findingModelActionCodeToString(action.value);
                if (!actionString) return;
                displayMapping[action.value] = {
                    displayText: i18n.t(`CONFIGURATIONS.SENTINEL.FILTER_TOPICS.ACTION_OPTIONS.${actionString}`, {
                        ns: I18nIntegrations,
                    }),
                };
            });
            return {
                filterProps: {
                    initialData: aggregations[SENTINEL_FILTER_FIELDS.ACTION],
                    key: SENTINEL_FILTER_FIELDS.ACTION,
                    title: i18n.t('CONFIGURATIONS.SENTINEL.FILTER_TOPICS.ACTION_OPTIONS.TITLE', {
                        ns: I18nIntegrations,
                    }),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: SENTINEL_FILTER_FIELDS.ENTITY_ID,
        content: () => {
            return {
                filterProps: {
                    initialData: [],
                    key: SENTINEL_FILTER_FIELDS.ENTITY_ID,
                    title: i18n.t('CONFIGURATIONS.SENTINEL.FILTER_TOPICS.ENTITY_ID', { ns: I18nIntegrations }),
                    value: [],
                },
                renderFunction: renderAddItemFilter,
            };
        },
    },
    {
        id: SENTINEL_FILTER_FIELDS.REGION,
        content: function ({ aggregations }: IIntegrationsFilterParams) {
            const filteredInitialData = aggregations[SENTINEL_FILTER_FIELDS.REGION]?.filter(
                (region: { value: string }) => region.value,
            );
            return {
                filterProps: {
                    initialData: filteredInitialData,
                    key: SENTINEL_FILTER_FIELDS.REGION,
                    title: i18n.t('CONFIGURATIONS.SENTINEL.FILTER_TOPICS.REGION', { ns: I18nIntegrations }),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: SENTINEL_FILTER_FIELDS.CATEGORY,
        content: function ({ aggregations }: IIntegrationsFilterParams) {
            const filteredInitialData = aggregations[SENTINEL_FILTER_FIELDS.CATEGORY]?.filter(
                (category: { value: string }) => category.value,
            );
            return {
                filterProps: {
                    initialData: filteredInitialData,
                    key: SENTINEL_FILTER_FIELDS.CATEGORY,
                    title: i18n.t('CONFIGURATIONS.SENTINEL.FILTER_TOPICS.CATEGORY', { ns: I18nIntegrations }),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: SENTINEL_FILTER_FIELDS.ASSIGNEE,
        content: function ({ aggregations }: IIntegrationsFilterParams) {
            const displayMapping = {
                '': {
                    displayText: i18n.t('CONFIGURATIONS.SENTINEL.FILTER_TOPICS.ASSIGNEE_OPTIONS.UNASSIGNED', {
                        ns: I18nIntegrations,
                    }),
                },
            };
            return {
                filterProps: {
                    initialData: aggregations[SENTINEL_FILTER_FIELDS.ASSIGNEE],
                    key: SENTINEL_FILTER_FIELDS.ASSIGNEE,
                    title: i18n.t('CONFIGURATIONS.SENTINEL.FILTER_TOPICS.ASSIGNEE_OPTIONS.TITLE', {
                        ns: I18nIntegrations,
                    }),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: SENTINEL_FILTER_FIELDS.RULESET_NAME,
        content: function ({ aggregations }: IIntegrationsFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations[SENTINEL_FILTER_FIELDS.RULESET_NAME],
                    key: SENTINEL_FILTER_FIELDS.RULESET_NAME,
                    title: i18n.t('CONFIGURATIONS.SENTINEL.FILTER_TOPICS.RULESET_NAME', { ns: I18nIntegrations }),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: SENTINEL_FILTER_FIELDS.SOURCE,
        content: function ({ aggregations }: IIntegrationsFilterParams) {
            const displayMapping: IDisplayMappingObject = {};
            aggregations['origin']?.forEach((origin: { value: IFindingModelOriginCode }) => {
                const originString = findingModelOriginCodeToString(origin.value);
                if (!originString) return;
                displayMapping[origin.value] = {
                    displayText: i18n.t(`TABLE_FILTERS.${originString}`, { ns: I18nIntegrations }),
                };
            });
            return {
                filterProps: {
                    initialData: aggregations[SENTINEL_FILTER_FIELDS.SOURCE],
                    key: SENTINEL_FILTER_FIELDS.SOURCE,
                    title: i18n.t('CONFIGURATIONS.SENTINEL.FILTER_TOPICS.SOURCE', { ns: I18nIntegrations }),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
];

export default function initializeSentinelFilters() {
    IntegrationsRegistry.addFilterAddins(filterDefsAddins());
}
