import React from 'react';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import KubernetesGeneralSectionMoreInfo from './components/MoreInfo';
import KubernetesGeneralSectionImages from './components/KubernetesGeneralSectionImages';
import { generalFeatures, isFeatureRelevant } from './KubernetesGeneralSection.types';
import KubernetesGeneralSectionTable from './components/Table';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import KubernetesGeneralSectionStyled from './KubernetesGeneralSection.styled';

const KubernetesGeneralSection: React.FC<{ entity: ICloudEntityData }> = ({ entity }) => {
    const { t } = useTranslation(getK8sNamespace('protected-asset-details'));
    return (
        <KubernetesGeneralSectionStyled.Wrapper direction='row' spacing={2} justifyContent='space-between'>
            {isFeatureRelevant(entity, generalFeatures.moreInfo) && (
                <KubernetesGeneralSectionStyled.ComponentWrapper>
                    <KubernetesGeneralSectionMoreInfo entity={entity} />
                </KubernetesGeneralSectionStyled.ComponentWrapper>
            )}
            {isFeatureRelevant(entity, generalFeatures.images) && (
                <KubernetesGeneralSectionStyled.ComponentWrapper>
                    <KubernetesGeneralSectionImages entity={entity} />
                </KubernetesGeneralSectionStyled.ComponentWrapper>
            )}
            {/* {isFeatureRelevant(entity, generalFeatures.spec) && <div>spec feature</div>} */}
            {isFeatureRelevant(entity, generalFeatures.labels) && (
                <KubernetesGeneralSectionStyled.ComponentWrapper>
                    <KubernetesGeneralSectionTable
                        title={t('overviewGeneral.features.label.title')}
                        data={entity.cloudEntity.labels || []}
                        headers={[
                            { field: 'key', headerName: t('overviewGeneral.features.label.labels.key') },
                            { field: 'value', headerName: t('overviewGeneral.features.label.labels.value') },
                        ]}
                    />
                </KubernetesGeneralSectionStyled.ComponentWrapper>
            )}
            {isFeatureRelevant(entity, generalFeatures.tolerations) && (
                <KubernetesGeneralSectionStyled.ComponentWrapper>
                    <KubernetesGeneralSectionTable
                        title={t('overviewGeneral.features.tolerations.title')}
                        data={entity.cloudEntity.spec?.template?.spec?.tolerations || []}
                        headers={[
                            { field: 'key', headerName: t('overviewGeneral.features.tolerations.labels.key') },
                            {
                                field: 'operator',
                                headerName: t('overviewGeneral.features.tolerations.labels.operator'),
                            },
                            { field: 'effect', headerName: t('overviewGeneral.features.tolerations.labels.effect') },
                        ]}
                    />
                </KubernetesGeneralSectionStyled.ComponentWrapper>
            )}
        </KubernetesGeneralSectionStyled.Wrapper>
    );
};

export default KubernetesGeneralSection;
