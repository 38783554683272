import React, { useEffect, useState } from 'react';
import { EdgeLabelRenderer, EdgeProps, getBezierPath } from 'reactflow';
import { EdgeData } from '../../Common/Models/GraphModels';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../initialize.i18n';
import NetworkTrafficEdgePopoverComponent from './NetworkTrafficEdgePopoverComponent';
import { getArrowHead } from '../../Common/Services/EdgeTypeHandler';
import OccurrencesTableComponent from '../../Common/Components/OccurencesTableComponent';
import { getVisualizationGraphState } from '../../Common/Services/GraphState.reducer';
import { useSelector } from 'react-redux';
import { LayoutDirection } from 'common/components/Graph/Models/LayoutDirection';
import { Tooltip } from 'common/design-system/components-v2';

const NetworkTrafficEdge: React.FC<EdgeProps> = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data,
    style,
    markerEnd,
}) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const edgeData = data as EdgeData;
    const [label, setLabel] = useState<string | null>(null);
    const [reverseGraph, setReverseGraph] = useState<boolean>(false);
    const [arrowPath, setArrowPath] = useState<string>(getArrowHead(LayoutDirection.LeftToRight));
    const graphDirectionState: LayoutDirection | undefined = useSelector(getVisualizationGraphState);

    useEffect(() => {
        if (graphDirectionState === LayoutDirection.RightToLeft) {
            setReverseGraph(true);
            setArrowPath(getArrowHead(LayoutDirection.RightToLeft));
        }
    }, [graphDirectionState]);

    useEffect(() => {
        if (edgeData?.additionalData?.port) setLabel(edgeData.additionalData.port);
    }, [edgeData?.additionalData?.port, data]);

    return (
        <g style={style}>
            <defs>
                <mask id={id}>
                    <rect x='0' y='0' fill='white' height='10000' width='10000' transform='translate(-1000,-1000)' />
                    <polygon
                        className={'react-flow__edge-fat-arrow'}
                        height='10'
                        width='10'
                        transform='translate(-8,-8)'
                        points='12,14 0,14 4,8 0,2 12,2 16,8 '
                        fill='black'
                        style={{
                            offsetPath: `path('${edgePath}')`,
                        }}
                    />
                </mask>
                <linearGradient id={`gradient-${id}`} x2='1' y2='1' gradientTransform='rotate(-45)'>
                    <stop className={`edge-stop--${reverseGraph ? 'end' : 'start'}`} offset='10%'></stop>
                    <stop className={`edge-stop--${reverseGraph ? 'start' : 'end'}`} offset='60%'></stop>
                </linearGradient>
                <marker
                    className='edge-stop--arrow'
                    id={`marker-end-${id}`}
                    viewBox='0 0 40 40'
                    markerHeight={8}
                    markerWidth={reverseGraph ? '50' : '8'}
                    refX={reverseGraph ? '-10' : '11'}
                    refY={reverseGraph ? '7' : '8'}
                >
                    <path d={arrowPath} />
                </marker>
            </defs>

            <path
                id={id}
                className='react-flow__custom-edge-path'
                stroke={`url(#gradient-${id}) rgb(0,85,204)`}
                d={edgePath}
                markerEnd={markerEnd}
            />
            {label && (
                <EdgeLabelRenderer>
                    <Tooltip
                        content={NetworkTrafficEdgePopoverComponent(edgeData, t)}
                        interactive={true}
                        appendTo={() => document.body}
                        delay={200}
                        maxWidth={400}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                                background: `linear-gradient(45deg, ${reverseGraph ? edgeData.targetColor : edgeData.sourceColor} 10%, ${reverseGraph ? edgeData.sourceColor : edgeData.targetColor} 60%)`,
                                padding: '0 5px',
                                borderRadius: 5,
                                fontSize: 11,
                                color: 'white',
                                fontWeight: 600,
                                maxWidth: 150,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {label}
                        </div>
                    </Tooltip>
                </EdgeLabelRenderer>
            )}
            <OccurrencesTableComponent edgeData={edgeData} labelX={labelX} labelY={labelY} />
        </g>
    );
};
export default NetworkTrafficEdge;
