import React from 'react';
import { Vulnerability } from '../Vulnerabilities.types';
import { Category, FindingsRowWrapper } from './FindingsRow.styled';
import { CountBySeverity, Typography, Stack } from 'common/design-system/components-v2';
import FixableBox from '../FixableBox/FixableBox';

const FindingsRow: React.FC<Vulnerability> = ({
    type,
    severityCounts,
    onClickGoVulnerability,
    totalFixable,
    title,
}) => (
    <FindingsRowWrapper alignItems={'flex-end'} spacing={2} direction={'row'}>
        <Category direction={'row'} alignItems='center' padding={[0, 0, 2, 0]}>
            <Typography variant={'body500'}>{title}</Typography>
        </Category>
        <Stack direction={'row'} spacing={1}>
            {type === 'cve' && totalFixable > 0 && onClickGoVulnerability && (
                <FixableBox onClick={onClickGoVulnerability} title={'Fixable'} totalFixable={totalFixable} />
            )}
            {type === 'secret' || type === 'threat' ? (
                <FixableBox totalFixable={totalFixable} />
            ) : (
                <CountBySeverity isBigSize severityCounts={severityCounts} />
            )}
        </Stack>
    </FindingsRowWrapper>
);

export default FindingsRow;
