import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IValueCount, LoadingState } from 'common/interface/general';
import { Spinner, Stack, Typography } from 'common/design-system/components-v2';
import { formatNumberShorthand } from 'common/utils/helpFunctions';
import { IconProps } from 'common/design-system/components-v2/Icon/Icon.types';
import { IPreviewItemInfo, IPreviewMainFieldInfo } from './PreviewBox';
import i18n from 'i18next';
import { WhereStyled } from '../AutoActionEditorWhere.styled';

const MAX_COUNTER_ITEMS = 5;
export const PreviewItemsBox: React.FC<{ counters: IValueCount[]; fieldInfo: IPreviewMainFieldInfo }> = ({
    counters,
    fieldInfo,
}) => {
    const topCounters: IValueCount[] = counters.slice(0, MAX_COUNTER_ITEMS);
    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.IS_LOADING);
    const itemInfosRef = useRef<IPreviewItemInfo[]>([]);

    const loadItemInfos = useCallback(async () => {
        if (topCounters.length === 0) {
            return;
        }

        try {
            itemInfosRef.current = await fieldInfo.itemInfosGetter(topCounters);
            setLoadingState(LoadingState.LOADING_SUCCEEDED);
        } catch (e) {
            console.error('Failed loading preview items');
            setLoadingState(LoadingState.LOADING_FAILED);
        }
    }, [fieldInfo, topCounters]);

    useEffect(() => {
        void loadItemInfos();
    }, [loadItemInfos]);

    if (!topCounters) {
        return undefined;
    }

    if (loadingState === LoadingState.IS_LOADING) {
        return <Spinner size={16} />;
    }

    if (loadingState === LoadingState.LOADING_FAILED) {
        return <Typography color={'alert'}>{i18n.t('COMMON.ERROR_OCCURRED')}</Typography>;
    }

    return (
        <Stack direction={'column'} spacing={4}>
            <Typography variant={'body500'}>{fieldInfo.itemsBoxTitle}</Typography>
            <Stack direction={'column'} spacing={4}>
                {itemInfosRef.current.map((itemInfo) => {
                    const countStr = formatNumberShorthand(itemInfo.count, true);
                    const iconProps: IconProps | undefined = itemInfo.iconProps;
                    const itemText = itemInfo.text;
                    return (
                        <Stack direction={'row'} spacing={4} key={itemText} alignItems={'center'}>
                            <WhereStyled.PreviewItemLabelDiv text={itemText} leadingIconProps={iconProps} />
                            <Typography variant={'body500'}>{countStr}</Typography>
                        </Stack>
                    );
                })}
            </Stack>
        </Stack>
    );
};
