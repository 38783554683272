import { FC, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { I18nTranslationKey } from '../initUsersPage';
import { Button, Modal, Spinner, Stack, Typography } from 'common/design-system/components-v2';
import { getNotificationsService, UserPageService } from 'common/interface/services';

interface IResetPasswordModalProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    selectedEmail?: string;
}

export const ResetPasswordModal: FC<IResetPasswordModalProps> = ({ isModalOpen, toggleModal, selectedEmail }) => {
    const { t } = useTranslation(I18nTranslationKey);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        toggleModal();
    };

    const errorHandling = (error: any) => {
        const errorData = error?.response?.data || '';
        errorData
            ? getNotificationsService().error(t('ACTIONS.RESET_PASSWORD_MODAL.TOAST_ERROR_HEADER'), errorData)
            : getNotificationsService().error(
                  t('ACTIONS.RESET_PASSWORD_MODAL.TOAST_ERROR_HEADER'),
                  t('ACTIONS.RESET_PASSWORD_MODAL.TOAST_ERROR_CONTENT'),
              );
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        const email = selectedEmail || '';
        const payload = { email };
        try {
            await UserPageService().resetPasswordRequestByEmail(payload);
            getNotificationsService().success(
                t('ACTIONS.RESET_PASSWORD_MODAL.TOAST_SUCCESS_HEADER'),
                t('ACTIONS.RESET_PASSWORD_MODAL.TOAST_SUCCESS_CONTENT'),
            );
            handleClose();
        } catch (e: any) {
            errorHandling(e);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal.ModalDialog width={'lg'} isOpen={isModalOpen}>
            <Modal.ModalHeader onClose={handleClose} title={t('ACTIONS.RESET_PASSWORD_MODAL.HEADER')} />
            <Modal.ModalContent>
                <Stack padding={2}>
                    <Typography variant={'lg'}>
                        <Trans>{t('ACTIONS.RESET_PASSWORD_MODAL.CONTENT', { email: selectedEmail })}</Trans>
                    </Typography>
                </Stack>
            </Modal.ModalContent>
            <Modal.ModalFooter>
                <Stack direction={'row'} spacing={3} justifyContent={'flex-end'} fullWidth>
                    <Button
                        dataAid={'cancel-reset-password-button'}
                        variant='text'
                        onClick={handleClose}
                        disabled={isLoading}
                    >
                        {t('GENERAL.CANCEL')}
                    </Button>
                    <Button
                        dataAid={'ok-reset-password-button'}
                        color={'brandPrimary'}
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        {' '}
                        {isLoading ? <Spinner size={12} /> : t('GENERAL.OK')}
                    </Button>
                </Stack>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    );
};
