import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Chip, Typography } from 'common/design-system/components-v2';
import { getIamSensitivityLevelInfo } from '../../ProtectedAsset/AssetModifiers/IamSensitivity';

export const IAMSensitivityCellRenderer: React.FC<ICellRendererParams> = (params) => {
    if (params.value) {
        const { title, iconProps, bg } = getIamSensitivityLevelInfo(params.value);
        return (
            <Chip
                label={title}
                leadingIconProps={{ name: iconProps.name }}
                tooltip={<Typography variant={'body500'}>{params.value}</Typography>}
                customColor={bg}
            />
        );
    }
};
