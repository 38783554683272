import { EnvironmentsTableRegistry } from 'common/module_interface/assets/EnvironmentsTableRegistry';
import { IEnvironmentsExport } from 'common/module_interface/assets/Environments';
import { Addin } from 'common/extensibility/AddinRegistry';
import i18n from 'common/services/translations/translations';
import { getEnvsNamespace } from '../initialize.i18n';
import { exportTable } from '../utils/exportTable';

export const exportsDefs = () => {
    const exportOptions: Addin<IEnvironmentsExport>[] = [
        {
            id: 'export-all',
            position: 0,
            content: {
                id: 'export-all',
                name: i18n.t('EXPORT.ALL_ENVIRONMENTS', { ns: getEnvsNamespace('table') }),
                callback: (allData) => exportTable(allData),
                isActionDisabled: (allData) => allData.length === 0,
            },
        },
        {
            id: 'export-filtered',
            position: 1,
            content: {
                id: 'export-filtered',
                name: i18n.t('EXPORT.FILTERED_ENVIRONMENTS', { ns: getEnvsNamespace('table') }),
                callback: (allData, filteredData) => exportTable(filteredData),
                isActionDisabled: (allData, filteredData) => filteredData.length === 0,
            },
        },
    ];
    return exportOptions;
};

export default function initializeExportsDefs() {
    EnvironmentsTableRegistry.addExports(exportsDefs());
}
