import { buildPath } from 'common/extensibility/AddinContainer';
import initializeRulesFilters from './RulesetsPageFilters/FilterDefenitions';
import { getTableRegistry } from 'common/SimpleTableFilterPage/SimpleGenericTableRegistry';
import initializeRulesetsColumnDefs from './RulsetsPageTable/RulsetsColumnDefenitions';
import { validLanguages } from 'common/services/translations/translations';
import i18n from 'i18next';
import { getAppRootRegistry, getUserService } from 'common/interface/services';
import { NEW_GSL_AF } from '../initialize';
import { lazy } from 'react';

export const I18nTranslationKey = 'Rulesets';
export const PATH_RULESETS_PREFIX = buildPath('rulesets', 'posture_management');
export const PATH_RULESETS_PAGE = buildPath(PATH_RULESETS_PREFIX, 'page');
export const PATH_RULESETS_PAGE_TABLE_COLUMNS = buildPath(PATH_RULESETS_PAGE, 'columns');
export const PATH_RULESETS_PAGE_TABLE_FILTERS = buildPath(PATH_RULESETS_PAGE, 'filters');

export const initializeI18nRulesets = async () => {
    return Promise.all(
        validLanguages.map(async (language) => {
            try {
                const contents = await require(`./languages/${language}/translation.json`);
                i18n.addResourceBundle(language, `${I18nTranslationKey}`, contents);
            } catch {
                //Nothing to do with missing translation files
            }
        }),
    );
};

export const RulesetsTableRegistry = getTableRegistry(
    PATH_RULESETS_PAGE_TABLE_COLUMNS,
    PATH_RULESETS_PAGE_TABLE_FILTERS,
);

const initGenericPage = (initializeColumnDefs: Function, initializeFilters: Function) => {
    initializeColumnDefs();
    initializeFilters();

    const rulesetsPageRoute = () => {
        return {
            condition: getUserService().hasPermission([NEW_GSL_AF]),
            component: lazy(() => import('./PostureManagementRulsets')),
            path: '/posture-management/rulesets',
        };
    };

    getAppRootRegistry().addRoutes([{ id: 'rulesets', content: rulesetsPageRoute }]);
};

export const initializeRulesets = () => {
    initGenericPage(initializeRulesetsColumnDefs, initializeRulesFilters);
};
