import { DrawerRegistry } from 'common/components/DrawerInfra/Drawer/DrawerRegistry';
import { IDrawerContent } from 'common/components/DrawerInfra/Drawer/Drawer.interface';
import { IconWrapper } from '../../Findings/FindingOverview/FindingOverviewStyled';
import { Icon } from 'common/design-system/components-v2';
import { LOGS_OVERVIEW_DRAWER_ID } from 'common/module_interface/intelligence/Logs/Logs.consts';
import LogOverview from './LogOverview';
import { TFunction } from 'i18next';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from '../../initialize.i18n';

function initializeDrawerContent() {
    const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);

    DrawerRegistry.addContentProvider({
        id: LOGS_OVERVIEW_DRAWER_ID,
        getDrawerContent: async ({ logInfo }): Promise<IDrawerContent | undefined> => {
            const result = {
                title: t('LOGS_TABLE.OVERVIEW.TITLE'),
                icon: (
                    <IconWrapper justifyContent='center' alignItems='center'>
                        <Icon name={'log'} size={24} />
                    </IconWrapper>
                ),
                component: LogOverview,
                componentProps: { logInfo },
            };
            return Promise.resolve(result);
        },
        options: {
            width: 'lg',
        },
    });
}

export function initializeLogOverview() {
    initializeDrawerContent();
}
