import React from 'react';
import { ComponentOverflow } from 'common/design-system/components-v2';
import { LicensesProps } from './Licenses.types';
import { transformToValue } from './Licenses.utils';

const Licenses: React.FC<LicensesProps> = ({ licenses, transformer }) => {
    return <ComponentOverflow components={licenses.map(transformer ?? transformToValue)} />;
};

export default Licenses;
