import { Stack } from 'common/design-system/components-v2';
import { BenchmarkTabProps } from './BenchmarkTab.types';
import RulesTable from '../../RulesTable';
import BenchmarkHeader from '../BenchmarkHeader';

const BenchmarkTab = (props: BenchmarkTabProps) => {
    const { host, benchmarkMetadata, benchmarkData, rowData, aggregations, onExportCsv, loading, setFilters } = props;

    return (
        <Stack spacing={4}>
            <BenchmarkHeader benchmarkData={benchmarkData} benchmarkMetadata={benchmarkMetadata} />
            <RulesTable
                host={host}
                rowData={rowData}
                isLoading={loading}
                aggregations={aggregations}
                key={rowData.length}
                onFilterChange={setFilters}
                onExportCsv={onExportCsv}
            />
        </Stack>
    );
};

export default BenchmarkTab;
