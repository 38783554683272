import { SearchGroup } from './types';

const DEFAULT_KEY = '';
export class SearchGroupManager {
    private defaultSearchGroup: SearchGroup;
    private searchGroups: Map<string, SearchGroup>;
    public DEFAULT_KEY: string;

    constructor(defaultSearchGroup: SearchGroup, defaultKey = DEFAULT_KEY) {
        this.defaultSearchGroup = defaultSearchGroup;
        this.DEFAULT_KEY = defaultKey;
        this.searchGroups = new Map<string, SearchGroup>([[defaultKey, defaultSearchGroup]]);
    }

    public getDefault() {
        return this.searchGroups.get(this.DEFAULT_KEY) ?? this.defaultSearchGroup;
    }

    public get(key: string) {
        return this.searchGroups.get(key);
    }

    public add(key: string, searchGroup: SearchGroup) {
        this.searchGroups.set(key, searchGroup);
    }
}
