import { IntegrationSearch } from './IntegrationSearch.styled';
import { IntegrationsSearchBarDefinition } from 'common/module_interface/settings/integrations/consts';
import { IconButton, Input } from 'common/design-system/components-v2';

export const IntegrationSearchBar = (integrationSearchProps: IntegrationsSearchBarDefinition) => {
    const { placeholder, type, onChange } = integrationSearchProps;
    const Content = (
        <IntegrationSearch data-aid={'integration-search'}>
            <Input
                autoFocus
                type={type}
                fullWidth={true}
                placeholder={placeholder}
                startAdornment={
                    <div>
                        <IconButton color='brandPrimary' iconProps={{ name: 'magnify' }} size='small' />
                    </div>
                }
            />
        </IntegrationSearch>
    );
    return <div onChange={onChange}>{Content}</div>;
};
