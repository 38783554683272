import { ICellRendererParams } from 'ag-grid-enterprise';
import { AssetTypes } from 'common/assets/assets.const';
import { ProgressBar, Stack } from 'common/design-system/components-v2';
import { getExternalAdditionalFieldValue } from 'common/module_interface/assets/utils';
import React from 'react';

const LearningCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const isLambdaAssetType = params?.data?.typeByPlatform === AssetTypes.AWS_LAMBDA;
    const isIncludesFSP = getExternalAdditionalFieldValue(params.data, 'IncludesFSP') === 'true';
    const isShowWhiteListProgress = isLambdaAssetType && isIncludesFSP;
    const isServerlessEnabled = getExternalAdditionalFieldValue(params.data, 'ServerlessEnable') === 'true';

    if (!isShowWhiteListProgress || !isServerlessEnabled) return null;

    const whiteListProgressProtegoValue = getExternalAdditionalFieldValue(params.data, 'WhiteListProgressProtego') ?? 0;

    return (
        <Stack direction='row' fullWidth>
            <ProgressBar percentage={+whiteListProgressProtegoValue} singleAnimation showPercentage />
        </Stack>
    );
};

export default LearningCellRenderer;
