import React, { useMemo } from 'react';
import PropertyBoxesRow from 'common/components/PropertyBox/PropertyBoxesRow';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import PropertyBox from 'common/components/PropertyBox/PropertyBox';
import FindingMitreSection from '../FindingMitreSection';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';

export const MitrePanel: React.FC<{ event: IKustoEvent }> = ({ event }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);

    const mitreDict = event.mitreDict;

    const tagsView = useMemo(() => {
        return <FindingMitreSection mitreDetails={mitreDict} />;
    }, [mitreDict]);

    return (
        mitreDict && (
            <PropertyBoxesRow>
                <PropertyBox title={t('INT_FINDING_OVERVIEW.MITRE.TITLE')} content={tagsView} />
            </PropertyBoxesRow>
        )
    );
};
