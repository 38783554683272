import { GridOptions } from 'ag-grid-community';
import { CheckboxCellRenderer } from '../CellRenderers/CheckboxCellRenderer';
import { AgGridReact } from 'ag-grid-react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { I18nSettingsTranslationKey } from '../../../initialize';
import { InfoCellRenderer } from '../CellRenderers/InfoCellRenderer';
import {
    infoColumnWidth,
    inheritedRolesColumnWidth,
    IPermissionComponentProps,
    IPermissionItem,
    PERMISSION_TYPE,
    PermissionCategorySubType,
    PermissionViewMode,
} from '../interfaces';
import { GridWrapper } from './NetworkControls.styled';
import { v4 as uuid } from 'uuid';
import { isPermissionItemsMatch } from '../utils';
import { InheritedRolesCellRenderer } from '../CellRenderers/InheritedRolesCellRenderer';

export const NetworkControls: FC<IPermissionComponentProps> = ({
    title,
    viewMode,
    permissions,
    permissionsChanged,
}) => {
    const { t } = useTranslation(I18nSettingsTranslationKey);
    const networkControls: IPermissionItem[] = [
        {
            type: PERMISSION_TYPE.CREATE_SECURITY_GROUP,
            categorySubType: PermissionCategorySubType.NETWORK_CONTROLS,
            name: 'Create security groups',
            enable: false,
            info: t('TOOLTIPS.DEFAULTS.NETWORK_CONTROLS'),
            id: uuid(),
        },
        {
            type: PERMISSION_TYPE.CREATE_AGENT,
            categorySubType: PermissionCategorySubType.NETWORK_CONTROLS,
            name: 'Create CloudGuard agents',
            enable: false,
            info: t('TOOLTIPS.DEFAULTS.NETWORK_CONTROLS'),
            id: uuid(),
        },
    ];

    const reviewMode = viewMode === PermissionViewMode.REVIEW;
    const [rowData, setRowData] = useState<IPermissionItem[]>(networkControls);

    const onCellClicked = () => {
        if (permissionsChanged) {
            permissionsChanged(rowData!);
        }
    };

    useEffect(() => {
        let _permissions = rowData;
        if (permissions?.length) {
            const _rowData = rowData.filter(
                (item) => !permissions.some((permission) => isPermissionItemsMatch(permission, item)),
            );
            _permissions = [...permissions, ..._rowData];
        }
        setRowData(_permissions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissions]);

    const getBaseColumns = () => {
        const baseColumns = [];
        if (reviewMode) {
            baseColumns.push({
                field: 'inheritedRoles',
                cellRenderer: InheritedRolesCellRenderer,
                headerName: t('HEADERS.ROLES'),
                width: inheritedRolesColumnWidth,
            });
        }
        baseColumns.push({
            field: 'info',
            headerName: t('HEADERS.INFO'),
            width: infoColumnWidth,
            cellRenderer: InfoCellRenderer,
            cellRendererParams: { permissionSubType: PermissionCategorySubType.NETWORK_CONTROLS },
        });
        return baseColumns;
    };
    const gridOptions: GridOptions = {
        defaultColDef: {
            suppressMenu: true,
        },
        getRowClass: (params) => {
            const name = params.node.data.type;
            return `row-${name.replaceAll(' ', '-')}`;
        },
        columnDefs: [
            { field: 'name', headerName: title, flex: 1 },
            ...getBaseColumns(),
            {
                field: 'enable',
                headerName: t('HEADERS.ENABLE'),
                cellRenderer: CheckboxCellRenderer,
                width: 80,
                cellRendererParams: { reviewMode: reviewMode },
            },
        ],
    };

    return (
        <GridWrapper>
            <AgGridReact
                onCellClicked={onCellClicked}
                className={'ag-theme-alpine'}
                rowData={rowData}
                gridOptions={gridOptions}
                animateRows={true}
            />
        </GridWrapper>
    );
};
