import React from 'react';
import { Chip, Stack } from 'common/design-system/components-v2';
import { IUser } from 'common/interface/user';
import { getUserService } from 'common/interface/services';
import { useTheme } from 'styled-components';
import { RowNode } from 'ag-grid-community';
const owner = 'Owner';
const direct = 'Direct';
const you = 'you';
const superUser = 'Super User';
export const UserChipsCellRenderer: React.FC<{ node: RowNode }> = ({ node }) => {
    const theme = useTheme();
    const user = node.data as IUser;
    const isMe = user.id === getUserService().getUser().id;
    const hasDirectPermission = Object.entries(user.permissions).some(([, value]) =>
        value.some((permission: string) => permission.length),
    );
    function convertRoleToColor(roleName: string) {
        switch (roleName) {
            case 'Owner':
                return theme.palette.surface.success;
            default:
                return theme.palette.surface.brandLight;
        }
    }

    const sortedRoles = React.useMemo(
        () => (a: string, b: string) => {
            if (a === superUser) return -1;
            if (b === superUser) return 1;
            return a > b ? 1 : -1;
        },
        [],
    );

    if (user) {
        return (
            <Stack spacing={1} direction={'row'}>
                {user.isOwner ? (
                    <Chip
                        customColor={theme.palette.surface.success}
                        label={owner}
                        leadingIconProps={{ name: 'asterisk' }}
                    />
                ) : (
                    ''
                )}
                {isMe ? (
                    <Chip
                        customColor={theme.palette.surface.brandPrimary}
                        label={you}
                        leadingIconProps={{ name: 'user' }}
                    />
                ) : (
                    ''
                )}
                {hasDirectPermission ? <Chip customColor={theme.palette.surface.warning} label={direct} /> : ''}
                {user.rolesNames
                    ?.sort(sortedRoles)
                    .map((item) => <Chip customColor={convertRoleToColor(item)} label={item} key={item} />)}
            </Stack>
        );
    } else {
        return '';
    }
};

export default UserChipsCellRenderer;
