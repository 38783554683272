import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'common/services/store/store';
import {
    AwsOnboardingAccountType,
    AwsOnboardingType,
    BladeState,
    BladeStateTotal,
    BladeStatus,
    CftTab,
    CftTabType,
    GetMemeberAccountConfigurationsResponse,
    IntelligenceRulesetDTO,
    OnboardingState,
    OnboardingStepType,
    PostManagementStackResponse,
    ProtectionType,
    Ruleset,
    RulesetDTO,
} from './aws.interface';
import * as yup from 'yup';
import {
    organizationalOnboardingAccountValidation,
    memberAccountValidation,
    managementAccountIdValidation,
} from './aws.validations';
import { AWSCloudAccount } from 'common/module_interface/assets/AwsEnvironment';
import { getValidationErrors } from 'common/components/Forms/FormValidations';
import { isNotChina } from 'common/utils/dataCenter';

const initialState: OnboardingState = {
    awsStep: 0,
    managementAccountId: '',
    allowCGUpdateStack: false,
    allowChinaPrivacyPolicy: false,
    awsOnboardingType: AwsOnboardingType.simple,
    enableServerless: false,
    enableIntelligence: false,
    awsOnboardingAccountType: AwsOnboardingAccountType.aws,
    rulesets: [],
    awsCftPopupOpen: false,
    selectedProtection: 'monitor',
    bladeStateTotal: {
        PENDING: 0,
        ACTIVE: 0,
        ERROR: 0,
        INACTIVE: 0,
    },
    bladesStatuses: [],
    selectedRulesets: [],
    defaultRulesets: [],
    gotRulesetsFromServer: false,
    userClickedLaunchStack: false,
    awsOnboardingFinished: false,
    environmentId: '',
    intelligenceRulesets: [],
    defaultIntelligenceRulesets: [],
    selectedIntelligenceRulesets: [],
    gotIntelligenceRulesetsFromServer: false,
    isOrgOnboarding: false,
    isWelcomeStepClicked: false,
    nextButtonDisabled: false,
    orgOnboardingDone: false,
    accountAlreadyOnboarded: false,
    isManagementOnboarded: false,
    isWizardBusy: false,
    managementAccountDetails: null,
    isSuccessModalShow: false,
};

export const onboardingSlice = createSlice({
    name: 'onboarding',
    initialState,
    reducers: {
        goToNextAwsStep: (state: OnboardingState) => {
            state.awsStep += 1;
        },
        goBackInAwsStep: (state: OnboardingState) => {
            state.awsStep -= 1;
        },
        goToAwsStep: (state: OnboardingState, action: PayloadAction<number>) => {
            state.awsStep = action.payload;
        },
        setAwsOnboardingType: (state: OnboardingState, action: PayloadAction<AwsOnboardingType>) => {
            state.awsOnboardingType = action.payload;
        },
        setEnableServerless: (state: OnboardingState, action: PayloadAction<boolean>) => {
            state.enableServerless = action.payload;
        },
        setEnableIntelligence: (state: OnboardingState, action: PayloadAction<boolean>) => {
            state.enableIntelligence = action.payload;
        },
        setAwsOnboardingAccountType: (state: OnboardingState, action: PayloadAction<AwsOnboardingAccountType>) => {
            state.awsOnboardingAccountType = action.payload;
        },
        setAwsCfts: (state: OnboardingState, action: PayloadAction<CftTab[] | undefined>) => {
            state.awsCfts = action.payload;
        },
        setAwsOnboardingUrl: (state: OnboardingState, action: PayloadAction<string | undefined>) => {
            state.awsOnboardingUrl = action.payload;
        },
        setAwsCftPopupOpen: (state: OnboardingState, action: PayloadAction<boolean>) => {
            state.awsCftPopupOpen = action.payload;
        },
        setProtectionType: (state: OnboardingState, action: PayloadAction<ProtectionType>) => {
            state.selectedProtection = action.payload;
        },
        setDefaultRulesets: (state: OnboardingState, action: PayloadAction<number[]>) => {
            state.defaultRulesets = action.payload;
        },
        setDefaultIntelligenceRulesets: (state: OnboardingState, action: PayloadAction<number[]>) => {
            state.defaultIntelligenceRulesets = action.payload;
        },
        resetToDefaultRulesets: (state: OnboardingState) => {
            state.selectedRulesets = state.defaultRulesets;
        },
        resetToDefaultIntelligenceRulesets: (state: OnboardingState) => {
            state.selectedIntelligenceRulesets = state.defaultIntelligenceRulesets;
        },
        setRulesets: (state: OnboardingState, action: PayloadAction<Array<RulesetDTO>>) => {
            const paylaod = action.payload;
            state.rulesets = paylaod.map((ruleset: RulesetDTO) => {
                return {
                    name: ruleset.name,
                    description: ruleset.description,
                    icon: ruleset.icon,
                    selected: ruleset.default,
                    id: ruleset.id,
                    isTemplate: ruleset.isTemplate,
                    cloudVendor: ruleset.cloudVendor,
                    category: ruleset.common ? 'Common' : 'Additional',
                } as Ruleset;
            });
        },
        setIntelligenceRulesets: (state: OnboardingState, action: PayloadAction<Array<IntelligenceRulesetDTO>>) => {
            const paylaod = action.payload;
            state.intelligenceRulesets = paylaod.map((ruleset: IntelligenceRulesetDTO) => {
                return {
                    name: ruleset.name,
                    description: ruleset.description,
                    selected: ruleset.is_preselected,
                    id: +ruleset.id,
                    cloudVendor: ruleset.vendor,
                    icon: ruleset.is_predefined ? 'cloudGuard' : 'user',
                } as Ruleset;
            });
        },
        setBladeStatuses: (state: OnboardingState, action: PayloadAction<BladeStatus[]>) => {
            state.bladesStatuses = action.payload;
            state.bladeStateTotal.ERROR = action.payload.reduce(
                (acc: number, cur: BladeStatus) => acc + (cur.status === 'ERROR' ? 1 : 0),
                0,
            );
            state.bladeStateTotal.PENDING = action.payload.reduce(
                (acc: number, cur: BladeStatus) => acc + (cur.status === 'PENDING' ? 1 : 0),
                0,
            );
            state.bladeStateTotal.ACTIVE = action.payload.reduce(
                (acc: number, cur: BladeStatus) => acc + (cur.status === 'ACTIVE' ? 1 : 0),
                0,
            );
            state.bladeStateTotal.INACTIVE = action.payload.reduce(
                (acc: number, cur: BladeStatus) => acc + (cur.status === 'INACTIVE' ? 1 : 0),
                0,
            );
            state.awsOnboardingFinished = state.bladesStatuses.some(
                (elem) => elem.blade === 'Onboarding' && (elem.status === 'ACTIVE' || elem.status === 'ERROR'),
            );
            const successBladeStatus = action.payload.find((blade: BladeStatus) => blade.status === BladeState.ACTIVE);
            state.environmentId = successBladeStatus ? successBladeStatus.environmentId : '';
        },
        setAwsOnboardingId: (state: OnboardingState, action: PayloadAction<string | undefined>) => {
            state.awsOnboardingId = action.payload;
        },
        setSelectedRulesets: (state: OnboardingState, action: PayloadAction<number[]>) => {
            state.selectedRulesets = action.payload;
        },
        setIntelligenceSelectedRulesets: (state: OnboardingState, action: PayloadAction<number[]>) => {
            state.selectedIntelligenceRulesets = action.payload;
        },
        setGotRulesetsFromServer: (state: OnboardingState, action: PayloadAction<boolean>) => {
            state.gotRulesetsFromServer = action.payload;
        },
        setGotIntelligenceRulesetsFromServer: (state: OnboardingState, action: PayloadAction<boolean>) => {
            state.gotIntelligenceRulesetsFromServer = action.payload;
        },
        setUserClickedLaunchStack: (state: OnboardingState, action: PayloadAction<boolean>) => {
            state.userClickedLaunchStack = action.payload;
        },
        setLambdaRepoUrl: (state: OnboardingState, action: PayloadAction<string | undefined>) => {
            state.lambdaRepoUrl = action.payload;
        },
        setAllowCGUpdateStack: (state: OnboardingState, action: PayloadAction<boolean>) => {
            state.allowCGUpdateStack = action.payload;
        },
        setAllowChinaPrivacyPolicy: (state: OnboardingState, action: PayloadAction<boolean>) => {
            state.allowChinaPrivacyPolicy = action.payload;
        },
        setSelectedModuleInSummary: (state: OnboardingState, action: PayloadAction<BladeStatus>) => {
            state.selectedModuleInSummary = action.payload;
        },
        setSelectedModuleByUser: (state: OnboardingState, action: PayloadAction<boolean>) => {
            state.isSelectedModuleByUser = action.payload;
        },
        setPostureManagementFilterValue: (state: OnboardingState, action: PayloadAction<string>) => {
            state.postureManagementFilterValue = action.payload;
        },
        setIntelligenceFilterValue: (state: OnboardingState, action: PayloadAction<string>) => {
            state.intelligenceFilterValue = action.payload;
        },
        setIsTakingLongerThanExpectedAccount: (state: OnboardingState, action: PayloadAction<boolean>) => {
            state.isTakingLongerThanExpectedAccount = action.payload;
        },
        setIsTakingLongerThanExpectedSummary: (state: OnboardingState, action: PayloadAction<boolean>) => {
            state.isTakingLongerThanExpectedSummary = action.payload;
        },
        setIsOrgOnboarding: (state: OnboardingState, action: PayloadAction<boolean>) => {
            state.isOrgOnboarding = action.payload;
        },
        setOrgOnboardingArnRole: (state: OnboardingState, action: PayloadAction<string | undefined>) => {
            state.orgOnboardingArnRole = action.payload;
        },
        setOrgOnboardingManagementAccountExternalId: (
            state: OnboardingState,
            action: PayloadAction<string | undefined>,
        ) => {
            state.orgOnboardingManagementAccountExternalId = action.payload;
        },
        setOrgOnboardingOrganizationName: (state: OnboardingState, action: PayloadAction<string | undefined>) => {
            state.orgOnboardingOrganizationName = action.payload;
        },
        setOrgOnboardingPostManagementResponse: (
            state: OnboardingState,
            action: PayloadAction<PostManagementStackResponse>,
        ) => {
            state.orgOnboardingPostManagementResponse = action.payload;
        },
        setOrgOnboardingMemberAccountConfigurations: (
            state: OnboardingState,
            action: PayloadAction<GetMemeberAccountConfigurationsResponse>,
        ) => {
            state.orgOnboardingMemeberAccountConfigurations = action.payload;
        },
        setMemberAccountArn: (state: OnboardingState, action: PayloadAction<string | undefined>) => {
            state.memberAccountArn = action.payload;
        },
        setNextButtonDisabled: (state: OnboardingState, action: PayloadAction<boolean>) => {
            state.nextButtonDisabled = action.payload;
        },
        setOrgOnboardingDone: (state: OnboardingState, action: PayloadAction<boolean>) => {
            state.orgOnboardingDone = action.payload;
        },
        setWelcomeStepClicked: (state: OnboardingState) => {
            state.isWelcomeStepClicked = true;
        },
        setManagementAccountId: (state: OnboardingState, action: PayloadAction<string>) => {
            state.managementAccountId = action.payload;
        },
        setManagementAccountDetails: (state: OnboardingState, action: PayloadAction<AWSCloudAccount>) => {
            state.managementAccountDetails = action.payload;
        },
        setIsAccountAlreadyOnboarded: (state: OnboardingState, action: PayloadAction<boolean>) => {
            state.accountAlreadyOnboarded = action.payload;
        },
        setIsManagementOnboarded: (state: OnboardingState, action: PayloadAction<boolean | undefined>) => {
            state.isManagementOnboarded = action.payload;
        },
        setIsWizardBusy: (state: OnboardingState, action: PayloadAction<boolean>) => {
            state.isWizardBusy = action.payload;
        },
        setIsSuccessModalShow: (state: OnboardingState, action: PayloadAction<boolean>) => {
            state.isSuccessModalShow = action.payload;
        },
        clearOnboardingState: () => initialState,
    },
});

export const {
    goToNextAwsStep,
    goBackInAwsStep,
    goToAwsStep,
    setEnableServerless,
    setEnableIntelligence,
    setAwsOnboardingType,
    setAwsOnboardingAccountType,
    setAwsCfts,
    setAwsOnboardingUrl,
    setAwsCftPopupOpen,
    setProtectionType,
    setDefaultRulesets,
    setDefaultIntelligenceRulesets,
    resetToDefaultRulesets,
    resetToDefaultIntelligenceRulesets,
    setIntelligenceRulesets,
    setGotIntelligenceRulesetsFromServer,
    setRulesets,
    setBladeStatuses,
    setAwsOnboardingId,
    setAllowCGUpdateStack,
    setAllowChinaPrivacyPolicy,
    setSelectedRulesets,
    setIntelligenceSelectedRulesets,
    setGotRulesetsFromServer,
    clearOnboardingState,
    setLambdaRepoUrl,
    setUserClickedLaunchStack,
    setSelectedModuleInSummary,
    setSelectedModuleByUser,
    setPostureManagementFilterValue,
    setIntelligenceFilterValue,
    setIsTakingLongerThanExpectedAccount,
    setIsTakingLongerThanExpectedSummary,
    setIsOrgOnboarding,
    setWelcomeStepClicked,
    setOrgOnboardingArnRole,
    setOrgOnboardingManagementAccountExternalId,
    setOrgOnboardingOrganizationName,
    setOrgOnboardingPostManagementResponse,
    setOrgOnboardingMemberAccountConfigurations,
    setMemberAccountArn,
    setNextButtonDisabled,
    setOrgOnboardingDone,
    setManagementAccountId,
    setManagementAccountDetails,
    setIsAccountAlreadyOnboarded,
    setIsManagementOnboarded,
    setIsSuccessModalShow,
    setIsWizardBusy,
} = onboardingSlice.actions;

export const getAwsStep = (state: RootState): number => state.onboarding.awsStep;
export const isManagementAccountDetails = (state: RootState): boolean =>
    !!state.onboarding.managementAccountDetails?.id;
export const getAwsOnboardingId = (state: RootState): string | undefined => state.onboarding.awsOnboardingId;
export const getAwsStepName = (state: RootState): OnboardingStepType => {
    const onboardingType = state.onboarding.awsOnboardingType;
    const currentStep = state.onboarding.awsStep;
    switch (true) {
        case currentStep === 0:
            return OnboardingStepType.Welcome;
        case currentStep === 1 && onboardingType === AwsOnboardingType.simple:
            return OnboardingStepType.CloudAccount;
        case currentStep === 2 && onboardingType === AwsOnboardingType.simple:
            return OnboardingStepType.Summary;

        case currentStep === 1 && onboardingType === AwsOnboardingType.advanced:
            return OnboardingStepType.GroupProtection;

        case currentStep === 2 && onboardingType === AwsOnboardingType.advanced:
            return OnboardingStepType.PostureManagement;

        case currentStep === 3 &&
            onboardingType === AwsOnboardingType.advanced &&
            state.onboarding.awsOnboardingAccountType === AwsOnboardingAccountType.aws:
            return OnboardingStepType.Intelligence;
        case currentStep === 3 &&
            onboardingType === AwsOnboardingType.advanced &&
            state.onboarding.awsOnboardingAccountType !== AwsOnboardingAccountType.aws:
            return OnboardingStepType.CloudAccount;

        case currentStep === 4 &&
            onboardingType === AwsOnboardingType.advanced &&
            state.onboarding.awsOnboardingAccountType !== AwsOnboardingAccountType.aws:
            return OnboardingStepType.Summary;
        case currentStep === 4 &&
            onboardingType === AwsOnboardingType.advanced &&
            isNotChina() &&
            state.onboarding.awsOnboardingAccountType === AwsOnboardingAccountType.aws:
            return OnboardingStepType.Serverless;

        case currentStep === 5 &&
            onboardingType === AwsOnboardingType.advanced &&
            state.onboarding.awsOnboardingAccountType === AwsOnboardingAccountType.aws:
            return OnboardingStepType.CloudAccount;
        case currentStep === 6 &&
            onboardingType === AwsOnboardingType.advanced &&
            state.onboarding.awsOnboardingAccountType === AwsOnboardingAccountType.aws:
            return OnboardingStepType.Summary;
        default:
            return OnboardingStepType.Unknown;
    }
};
export const getAwsOnboadingType = (state: RootState): AwsOnboardingType => state.onboarding.awsOnboardingType;
export const getEnableServerless = (state: RootState): boolean => state.onboarding.enableServerless;
export const getEnableIntelligence = (state: RootState): boolean => state.onboarding.enableIntelligence;
export const getAwsOnboadingAccountType = (state: RootState): AwsOnboardingAccountType =>
    state.onboarding.awsOnboardingAccountType;

export const getAwsCfts = (state: RootState): CftTab[] | undefined =>
    state.onboarding.awsCfts?.map((cft: CftTab) => {
        return {
            ...cft,
            fileName: `CloudGuard_${cft.type}_CFT.yaml`,
            repoUrl: cft.type === CftTabType.Onboarding ? getLambdaRepoUrl(state) : undefined,
        };
    });
export const getAwsOnboardingUrl = (state: RootState): string | undefined => state.onboarding.awsOnboardingUrl;
export const getAwsCftPopupOpen = (state: RootState): boolean => state.onboarding.awsCftPopupOpen;
export const getSelectedProtection = (state: RootState): ProtectionType => state.onboarding.selectedProtection;
export const getBladeStateTotals = (state: RootState): BladeStateTotal => state.onboarding.bladeStateTotal;
export const getRulesets = (state: RootState): Array<Ruleset> => state.onboarding.rulesets;
export const getIntelligenceRulesets = (state: RootState): Array<Ruleset> => state.onboarding.intelligenceRulesets;
export const getBladeStatuses = (state: RootState): BladeStatus[] => state.onboarding.bladesStatuses;
export const getAwsStepsMaximumIndex = (state: RootState): number => {
    let StepsMaximumIndex = 0;
    if (state.onboarding.awsOnboardingType === AwsOnboardingType.simple) {
        StepsMaximumIndex = 2;
    } else if (
        state.onboarding.awsOnboardingType === AwsOnboardingType.advanced &&
        state.onboarding.awsOnboardingAccountType === AwsOnboardingAccountType.aws
    ) {
        StepsMaximumIndex = 6;
    } else if (state.onboarding.awsOnboardingType === AwsOnboardingType.organizational) {
        if (state.onboarding.managementAccountDetails?.id) StepsMaximumIndex = 4;
        else StepsMaximumIndex = 3;
    } else {
        StepsMaximumIndex = 4;
    }
    return StepsMaximumIndex;
};
export const getAllowCGUpdateStack = (state: RootState) => state.onboarding.allowCGUpdateStack;
export const getAllowChinaPrivacyPolicy = (state: RootState) => state.onboarding.allowChinaPrivacyPolicy;
export const canGoForward = (state: RootState): boolean => getAwsStepsMaximumIndex(state) > state.onboarding.awsStep;
export const canGoBack = (state: RootState): boolean => state.onboarding.awsStep !== 0;
export const getIsSummary = (state: RootState): boolean => getAwsStepsMaximumIndex(state) === state.onboarding.awsStep;
export const getSelectedRulsets = (state: RootState): number[] => state.onboarding.selectedRulesets;
export const getIntelligenceSelectedRulesets = (state: RootState): number[] =>
    state.onboarding.selectedIntelligenceRulesets;
export const getGotRulesetsFromServer = (state: RootState) => state.onboarding.gotRulesetsFromServer;
export const getGotIntelligenceRulesetsFromServer = (state: RootState) =>
    state.onboarding.gotIntelligenceRulesetsFromServer;
export const getUserClickedLaunchStack = (state: RootState) => state.onboarding.userClickedLaunchStack;
export const getLambdaRepoUrl = (state: RootState) => state.onboarding.lambdaRepoUrl;
export const getAwsOnboardingFinished = (state: RootState): boolean => state.onboarding.awsOnboardingFinished;
export const getWelcomeStepClicked = (state: RootState): boolean => state.onboarding.isWelcomeStepClicked;

export const getAwsOnboardingStarted = (state: RootState): boolean => {
    const statuses = getBladeStatuses(state);
    return Boolean(
        statuses &&
            statuses.length &&
            statuses.some(
                (status) => status.feature === 'General' && status.statusMessage.toLowerCase() !== 'pending start',
            ),
    );
};
export const getShowPostureLoader = (state: RootState): boolean =>
    getIsAccountPage(state) && !getAwsOnboardingStarted(state) && getUserClickedLaunchStack(state);
export const getShowSummaryLoader = (state: RootState): boolean =>
    getIsSummary(state) && !state.onboarding.awsOnboardingFinished;
export const getShowAwsLoader = (state: RootState): boolean =>
    getShowPostureLoader(state) || getShowSummaryLoader(state);
export const getIsAccountPage = (state: RootState) =>
    (state.onboarding.awsStep === 1 && state.onboarding.awsOnboardingType === AwsOnboardingType.simple) ||
    (state.onboarding.awsStep === 5 &&
        state.onboarding.awsOnboardingType === AwsOnboardingType.advanced &&
        state.onboarding.awsOnboardingAccountType === AwsOnboardingAccountType.aws) ||
    (state.onboarding.awsStep === 3 &&
        state.onboarding.awsOnboardingType === AwsOnboardingType.advanced &&
        state.onboarding.awsOnboardingAccountType !== AwsOnboardingAccountType.aws);

export const getSelectedModuleInSummary = (state: RootState): BladeStatus | undefined =>
    state.onboarding.selectedModuleInSummary;
export const getSelectedModuleByUser = (state: RootState): boolean | undefined =>
    state.onboarding.isSelectedModuleByUser;
export const getPostureManagementFilterValue = (state: RootState): string | undefined =>
    state.onboarding.postureManagementFilterValue;
export const getIntelligenceFilterValue = (state: RootState): string | undefined =>
    state.onboarding.intelligenceFilterValue;
export const getIsTakingLongerThanExpectedAccount = (state: RootState): boolean | undefined =>
    state.onboarding.isTakingLongerThanExpectedAccount;
export const getIsTakingLongerThanExpectedSummary = (state: RootState): boolean | undefined =>
    state.onboarding.isTakingLongerThanExpectedSummary;

export const getIsOnboardOrganizationStackSetPage = (state: RootState): boolean =>
    getIsOrgSelected(state) && getAwsStep(state) === 2;
export const getWelcomeOnboardOrganizationPage = (state: RootState): boolean =>
    getIsOrgSelected(state) && getAwsStep(state) === 0;

export const getAwsOnboardingNextButtonDisabled = (state: RootState) => {
    if (state.onboarding.nextButtonDisabled) {
        return true;
    }
    if (getIsOnboardOrganizationStackSetPage(state) && !getMemberAccountFormValid(state).valid) {
        return true;
    }
    if (getWelcomeOnboardOrganizationPage(state) && !getManagementAccountIdValidation(state).valid) {
        return true;
    }
    return getIsOrgSelected(state) && getAwsStep(state) === 1 && !getStacksetForPermissionsValid(state).valid;
};

export const getChinaPolicyNotValid = (state: RootState) => {
    if (
        !getIsOrgSelected(state) &&
        state.onboarding.awsOnboardingAccountType === AwsOnboardingAccountType.awschina &&
        !state.onboarding.allowChinaPrivacyPolicy
    ) {
        return true;
    }
    return false;
};

export const getFinishButtonDisabled = (state: RootState): boolean =>
    getIsOrgSelected(state) ? !getMemberAccountFormValid(state).valid : false;

// organizational onboarding getters
export const getIsOrgSelected = (state: RootState): boolean => state.onboarding.isOrgOnboarding;

export const getOrgOnboardingArnRole = (state: RootState) => state.onboarding.orgOnboardingArnRole;

export const getOrgOnboardingManagementAccountExternalId = (state: RootState) =>
    state.onboarding.orgOnboardingManagementAccountExternalId;

export const getOrgOnboardingOrganizationName = (state: RootState) => state.onboarding.orgOnboardingOrganizationName;

export const getStacksetForPermissionsValid = (state: RootState): { valid: boolean; errors?: any } => {
    try {
        yup.object().shape(organizationalOnboardingAccountValidation).validateSync(
            {
                roleArn: state.onboarding.orgOnboardingArnRole,
                externalId: state.onboarding.orgOnboardingManagementAccountExternalId,
            },
            { abortEarly: false },
        );
        return { valid: true };
    } catch (error: any) {
        return { valid: false, errors: getValidationErrors(error) };
    }
};

export const getOrgOnboardingMemberAccountConfigurations = (
    state: RootState,
): GetMemeberAccountConfigurationsResponse => state.onboarding.orgOnboardingMemeberAccountConfigurations;

export const getMemberAccountArn = (state: RootState) => state.onboarding.memberAccountArn;

export const getMemberAccountFormValid = (state: RootState): { valid: boolean; errors?: any } => {
    try {
        yup.object().shape(memberAccountValidation).validateSync(
            {
                memberRoleArn: state.onboarding.memberAccountArn,
            },
            { abortEarly: false },
        );
        return { valid: true };
    } catch (error: any) {
        return { valid: false, errors: getValidationErrors(error) };
    }
};

export const getManagementAccountIdValidation = (state: RootState): { valid: boolean; errors?: any } => {
    try {
        yup.object().shape(managementAccountIdValidation).validateSync(
            {
                managementAccountId: state.onboarding.managementAccountId,
            },
            { abortEarly: false },
        );
        return { valid: true };
    } catch (error: any) {
        return { valid: false, errors: getValidationErrors(error) };
    }
};

export const getOrgOnboardingDone = (state: RootState): boolean => state.onboarding.orgOnboardingDone;
export const getManagementAccountId = (state: RootState): string => state.onboarding.managementAccountId;
export const getManagementAccountDetails = (state: RootState): AWSCloudAccount | null =>
    state.onboarding.managementAccountDetails;
export const getIsAccountAlreadyOnboarded = (state: RootState): string => state.onboarding.accountAlreadyOnboarded;
export const getIsManagementOnboarded = (state: RootState): string => state.onboarding.isManagementOnboarded;
export const getIsSuccessModalShow = (state: RootState): boolean => state.onboarding.isSuccessModalShow;
export const getIsWizardBusy = (state: RootState): string => state.onboarding.isWizardBusy;
export default onboardingSlice.reducer;
