import { IActionUsageDef } from 'common/interface/general';
import {
    createAcknowledgeAction,
    createAssignUserAction,
    createChangeSeverityAction,
    createCloseAction,
    createCommentAction,
    createArchiveAction,
    createReportIssueAction,
    createExcludeCiemAction,
    createRemediateCiemAction,
} from '../FindingsTable/Types/FindingsActionsTypes';
import { ModalType } from '../Findings.const';

export const getCiemEventsTableActions = (
    openDialog: (dialogType: ModalType | null) => void,
    isArchiveView: boolean,
): IActionUsageDef[] => {
    if (isArchiveView) {
        return [createArchiveAction(openDialog, true), createCloseAction(openDialog)];
    }
    return [
        createRemediateCiemAction(openDialog),
        createReportIssueAction(openDialog),
        createAcknowledgeAction(openDialog),
        createCommentAction(openDialog),
        createChangeSeverityAction(openDialog),
        createArchiveAction(openDialog),
        createAssignUserAction(openDialog),
        createExcludeCiemAction(openDialog),
    ];
};
