import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Vendors, getVendor } from 'common/consts/vendors';
import { LevelIcon, Stack } from 'common/design-system/components-v2';
import { IEnvironment } from 'common/interface/environmentsTable';
import { OverflowText, StyledA } from 'common/common_styled';
import { StatusCellRenderer_Status } from 'common/module_interface/assets/Environments';
import { getKubernetesPlatformFullTypeName } from 'modules/workloads/services/workload/workload.interface';
import { getIconNameByEnvironmentCode } from 'common/utils/environments';

const WorkloadEnvironmentCellRenderer: React.FC<ICellRendererParams<IEnvironment>> = (params) => {
    const { id, name, platform } = params.data || {};
    const vendor = getVendor(platform || '');

    const isUnSecured = params.data?.['customData|status'] === StatusCellRenderer_Status.unsecured;

    const href = React.useMemo(() => {
        if (!id) return undefined;
        if (params.data?.['customData|status'] === StatusCellRenderer_Status.unsecured) {
            try {
                // logic from webapp project, could be refactored
                switch (params.data.platform) {
                    case Vendors.KUBERNETES: {
                        const dome9EntityId = params.data?.['customData|dome9EntityId'];
                        const type = params.data?.['customData|workloadPlatformType'];
                        const fullAssetTypeName = getKubernetesPlatformFullTypeName(type);

                        if (!params.data || !dome9EntityId || !type || !fullAssetTypeName) return undefined;
                        const envCode = dome9EntityId.split('|')[0];
                        const fixedPlatform =
                            type === 'AKS'
                                ? 'azure'
                                : envCode === '8'
                                  ? 'google'
                                  : getIconNameByEnvironmentCode(envCode);
                        const urlParams = {
                            platform: fixedPlatform,
                            cloudAccountId: id,
                            assetType: fullAssetTypeName,
                            assetId: dome9EntityId,
                        };
                        return `/protected-asset/generic?${new URLSearchParams(urlParams).toString()}`;
                    }
                    default:
                        return undefined;
                }
            } catch (error) {
                return undefined;
            }
        }
        return `/cloud-account/${vendor?.uri}/${id}`;
    }, [id, vendor, params.data]);

    return (
        <Stack direction='row' alignItems='center' spacing={2}>
            {vendor?.icon && (
                <LevelIcon
                    iconProps={{
                        name: vendor.newIcon,
                        filter: isUnSecured ? 'grayscale(1)' : 'unset',
                    }}
                    size='lg'
                />
            )}
            {href ? <StyledA href={href}>{name}</StyledA> : <OverflowText>{name}</OverflowText>}
        </Stack>
    );
};

export default WorkloadEnvironmentCellRenderer;
