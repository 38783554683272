import { ElementType } from 'react';
import TopicComponent from './Components/TopicComponent';
import { getAccountService, getUserService } from 'common/interface/services';

export const EMAIL_NOTIFICATIONS_AF = 'new-email-notifications';

export const I18nEmailNotifications = 'email-notifications';

export const componentsList = {
    cloudTamperDetected: 'cloudTamperDetected',
    invitationEvent: 'invitationEvent',
    leaseEvent: 'leaseEvent',
    accessNotificationsOthers: 'accessNotificationsOthers',
    policyChanges: 'policyChanges',
    cloudExternalChanges: 'cloudExternalChanges',
    awsProfileCreatedOrDeleted: 'awsProfileCreatedOrDeleted',
    serverUpdated: 'serverUpdated',
    serverCreatedOrDeleted: 'serverCreatedOrDeleted',
    serverStatusChange: 'serverStatusChange',
    awsInvalidCredentials: 'awsInvalidCredentials',
    azureInvalidCredentials: 'azureInvalidCredentials',
    userPermissionsChange: 'userPermissionsChange',
    userLogonSuccessfully: 'userLogonSuccessfully',
    userLogonFailure: 'userLogonFailure',
    userPasswordChange: 'userPasswordChange',
    userLockOut: 'userLockOut',
    usersNotificationsOthers: 'usersNotificationsOthers',
    userCreatedOrDeleted: 'userCreatedOrDeleted',
    userForgotPassword: 'userForgotPassword',
    fimChanges: 'fimChanges',
    compliance: {
        bundleAdded: 'bundleAdded',
        bundleDeleted: 'bundleDeleted',
        ruleAdded: 'ruleAdded',
        ruleChanged: 'ruleChanged',
        ruleDeleted: 'ruleDeleted',
    },
    iamSafe: {
        iamSafetyAccount: 'iamSafetyAccount',
        iamSafetyInvalidCredentials: 'iamSafetyInvalidCredentials',
        tamperDetected: 'tamperDetected',
        enrollmentEvent: 'enrollmentEvent',
        leaseEvent: 'leaseEvent',
        leaseNotificationsOthers: 'leaseNotificationsOthers',
    },
};

export enum SECTION_VALUES {
    ALL = 'All',
    ASSETS = 'Assets',
    CSPM = 'CSPM',
    NETWORK_SECURITY = 'Network Security',
    IDENTITY = 'Identity',
    AGENTS = 'Agents',
    ADMINISTRATION = 'Administration',
}

const iamSafe = 'iamSafe';
const getUserInfo = () => getUserService().getUser();

const checkAgentsPermissionsEnabled = async () => {
    const license = await getAccountService().getLicense();
    return !!license?.agentsEnabled;
};

export interface IConditionalCheckbox {
    parent: string;
    emailNotificationsKey?: string;
}

export interface EmailNotificationsSubject {
    key: string;
    label: string;
    conditionalCheckbox?: IConditionalCheckbox;
    hidden?: () => boolean | Promise<boolean>;
    disabled?: () => boolean;
}

export interface IEmailNotificationSection {
    title: string;
    Component: ElementType;
    id: SECTION_VALUES;
    subjectList: EmailNotificationsSubject[];
}

type EmailNotificationsSections = {
    [key in SECTION_VALUES]?: IEmailNotificationSection;
};

export const emailNotificationsSections: EmailNotificationsSections = {
    [SECTION_VALUES.ASSETS]: {
        title: 'ASSETS.TITLE',
        Component: TopicComponent,
        id: SECTION_VALUES.ASSETS,
        subjectList: [
            {
                key: componentsList.awsProfileCreatedOrDeleted,
                label: 'ASSETS.ENVIRONMENT_CREATION_OR_DELETION',
                disabled: () => !getUserInfo()?.isSuperUser,
            },
            { key: componentsList.serverUpdated, label: 'ASSETS.SERVERS_INSTANCES_UPDATE' },
            {
                key: componentsList.serverCreatedOrDeleted,
                label: 'ASSETS.SERVERS_CREATION_OR_DELETION',
                hidden: async () => !(await checkAgentsPermissionsEnabled()),
            },
            {
                key: componentsList.serverStatusChange,
                label: 'ASSETS.SERVERS_STATUS_CHANGE',
                hidden: async () => !(await checkAgentsPermissionsEnabled()),
            },
            { key: componentsList.awsInvalidCredentials, label: 'ASSETS.INVALID_AWS_CREDENTIALS' },
            { key: componentsList.azureInvalidCredentials, label: 'ASSETS.INVALID_AZURE_CREDENTIALS' },
        ],
    },
    [SECTION_VALUES.CSPM]: {
        title: 'POSTURE_MANAGEMENT.TITLE',
        Component: TopicComponent,
        id: SECTION_VALUES.CSPM,
        subjectList: [
            { key: componentsList.compliance.bundleAdded, label: 'POSTURE_MANAGEMENT.RULESET_CREATION' },
            { key: componentsList.compliance.bundleDeleted, label: 'POSTURE_MANAGEMENT.RULESET_DELETION' },
            { key: componentsList.compliance.ruleAdded, label: 'POSTURE_MANAGEMENT.RULE_CREATION' },
            { key: componentsList.compliance.ruleChanged, label: 'POSTURE_MANAGEMENT.RULE_CHANGE' },
            { key: componentsList.compliance.ruleDeleted, label: 'POSTURE_MANAGEMENT.RULE_DELETION' },
        ],
    },
    [SECTION_VALUES.NETWORK_SECURITY]: {
        title: 'NETWORK_SECURITY.TITLE',
        Component: TopicComponent,
        id: SECTION_VALUES.NETWORK_SECURITY,
        subjectList: [
            { key: componentsList.cloudTamperDetected, label: 'NETWORK_SECURITY.TAMPER_PROTECTION' },
            { key: componentsList.invitationEvent, label: 'NETWORK_SECURITY.DYNAMIC_ACCESS_INVITATIONS' },
            { key: componentsList.leaseEvent, label: 'NETWORK_SECURITY.DYNAMIC_ACCESS_LEASES' },
            {
                key: componentsList.accessNotificationsOthers,
                label: 'NETWORK_SECURITY.DYNAMIC_ACCESS_FOR_OTHER_USERS',
                conditionalCheckbox: { parent: componentsList.leaseEvent },
            },
            {
                key: componentsList.policyChanges,
                label: 'NETWORK_SECURITY.SECURITY_GROUP_POLICY_CHANGES_IN_CLOUDGUARD',
            },
            {
                key: componentsList.cloudExternalChanges,
                label: 'NETWORK_SECURITY.SECURITY_GROUP_POLICY_CHANGES_NOT_IN_CLOUDGUARD',
            },
        ],
    },
    [SECTION_VALUES.IDENTITY]: {
        title: 'IDENTITY.TITLE',
        Component: TopicComponent,
        id: SECTION_VALUES.IDENTITY,
        subjectList: [
            { key: componentsList.iamSafe.iamSafetyAccount, label: 'IDENTITY.ENVIRONMENT_ATTACHED_OR_REMOVED' },
            { key: componentsList.iamSafe.iamSafetyInvalidCredentials, label: 'IDENTITY.INVALID_CREDENTIALS' },
            {
                key: componentsList.iamSafe.tamperDetected,
                label: 'IDENTITY.TAMPER_PROTECTION',
                hidden: () => !getUserInfo()?.isSuperUser,
            },
            { key: componentsList.iamSafe.enrollmentEvent, label: 'IDENTITY.ENROLLMENT' },
            { key: componentsList.iamSafe.leaseEvent, label: 'IDENTITY.AUTHORIZATION_WINDOWS' },
            {
                key: componentsList.iamSafe.leaseNotificationsOthers,
                label: 'IDENTITY.AUTHORIZATION_WINDOWS_FOR_OTHER_USERS',
                conditionalCheckbox: { parent: componentsList.iamSafe.leaseEvent, emailNotificationsKey: iamSafe },
                hidden: () => !getUserInfo()?.isSuperUser,
            },
        ],
    },
    [SECTION_VALUES.AGENTS]: {
        title: 'AGENTS.TITLE',
        Component: TopicComponent,
        id: SECTION_VALUES.AGENTS,
        subjectList: [{ key: componentsList.fimChanges, label: 'AGENTS.FILE_INTEGRITY_MONITORING_DETECTED_CHANGES' }],
    },
    [SECTION_VALUES.ADMINISTRATION]: {
        title: 'ADMINISTRATION.TITLE',
        Component: TopicComponent,
        id: SECTION_VALUES.ADMINISTRATION,
        subjectList: [
            { key: componentsList.userPermissionsChange, label: 'ADMINISTRATION.USER_PERMISSIONS_CHANGE' },
            { key: componentsList.userLogonSuccessfully, label: 'ADMINISTRATION.SUCCESSFUL_LOGON' },
            { key: componentsList.userLogonFailure, label: 'ADMINISTRATION.FAILED_LOGON' },
            { key: componentsList.userPasswordChange, label: 'ADMINISTRATION.PASSWORD_CHANGE' },
            { key: componentsList.userLockOut, label: 'ADMINISTRATION.LOCKOUT' },
            {
                key: componentsList.usersNotificationsOthers,
                label: 'ADMINISTRATION.ADMINISTRATION_NOTIFICATIONS_FOR_OTHER_USERS',
                disabled: () => !getUserInfo()?.isSuperUser,
            },
            {
                key: componentsList.userCreatedOrDeleted,
                label: 'ADMINISTRATION.USER_CREATION_OR_DELETION',
                disabled: () => !getUserInfo()?.isSuperUser,
                conditionalCheckbox: { parent: componentsList.usersNotificationsOthers },
            },
            {
                key: componentsList.userForgotPassword,
                label: 'ADMINISTRATION.FORGOT_PASSWORD',
                disabled: () => !getUserInfo()?.isSuperUser,
                conditionalCheckbox: { parent: componentsList.usersNotificationsOthers },
            },
        ],
    },
};
