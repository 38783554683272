import { EnvironmentsTableRegistry } from 'common/module_interface/assets/EnvironmentsTableRegistry';
import { IEnvironmentsAction } from 'common/module_interface/assets/Environments';
import { Addin } from 'common/extensibility/AddinRegistry';
import i18n from 'common/services/translations/translations';
import AddEnvironmentAction from '../Actions/AddEnvironment';
import { getEnvsNamespace } from '../initialize.i18n';
import { Vendors } from 'common/consts/vendors';
import { validatePermissionsRelevantVendors } from '../components/Modals/ValidatePermissions/ValidatePermissions';
import { changeUrlParam } from 'common/utils/http';
import { EnvironmentTableModalTypeUrlParam, ModalType } from '../components/EnvironmentsTable/EnvironmentsTable.types';
import { MAX_ALLOWED_PERMISSIONS_CHECKS } from '../utils/exportTable';
import { resetAllEnvironmentsPermissions } from '../Actions/ValidateAllPermissions';

export const actionsDefs = () => {
    const actions: Addin<IEnvironmentsAction>[] = [
        {
            id: 'add-new-environment',
            position: 0,
            content: {
                id: 'add-new-environment',
                name: i18n.t('ADD_ENVIRONMENT.ADD', { ns: getEnvsNamespace('table') }),
                buttonProps: {
                    iconProps: { name: 'plus' },
                },
                callback: () => null,
                customComponent: () => <AddEnvironmentAction />,
            },
        },
        {
            id: 'add-new-environment-cloud-accounts',
            position: 0,
            content: {
                id: 'add-new-environment-cloud-accounts',
                name: i18n.t('ADD_ENVIRONMENT.ADD', { ns: getEnvsNamespace('table') }),
                buttonProps: {
                    iconProps: { name: 'plus' },
                },
                callback: () => null,
                customComponent: () => (
                    <AddEnvironmentAction
                        vendors={[Vendors.AWS, Vendors.AZURE, Vendors.GCP, Vendors.OCI, Vendors.ALIBABA]}
                    />
                ),
            },
        },
        {
            id: 'validatePermissions',
            position: 10,
            content: {
                id: 'validatePermissions',
                name: i18n.t('ACTIONS.VALIDATE_PERMISSIONS.BUTTON', { ns: getEnvsNamespace('table') }),
                buttonProps: {
                    iconProps: { name: 'check' },
                },
                description: i18n.t('ACTIONS.VALIDATE_PERMISSIONS.TOOLTIPS.BUTTON_DESCRIPTION', {
                    ns: getEnvsNamespace('table'),
                }),
                callback: () => changeUrlParam(EnvironmentTableModalTypeUrlParam, ModalType.ValidatePermissions),
                isActionDisabled: (selectedRows) => {
                    const validVendors = selectedRows.some((row) =>
                        validatePermissionsRelevantVendors.includes(row.platform as Vendors),
                    );
                    const maxAllowedPermissionValidation = selectedRows.length > MAX_ALLOWED_PERMISSIONS_CHECKS;
                    return !validVendors || maxAllowedPermissionValidation;
                },
            },
        },
        {
            id: 'validateAllPermissions',
            position: 20,
            content: {
                id: 'validateAllPermissions',
                name: i18n.t('ACTIONS.VALIDATE_ALL_PERMISSIONS.BUTTON', { ns: getEnvsNamespace('table') }),
                buttonProps: {
                    iconProps: { name: 'checkCircle' },
                },
                description: i18n.t('ACTIONS.VALIDATE_ALL_PERMISSIONS.BUTTON_DESCRIPTION', {
                    ns: getEnvsNamespace('table'),
                }),
                callback: () => resetAllEnvironmentsPermissions(),
                isActionDisabled: () => false,
            },
        },
        {
            id: 'associateToOU',
            position: 20,
            content: {
                id: 'associateToOU',
                name: i18n.t('ACTIONS.ASSOCIATE_TO_OU.BUTTON', { ns: getEnvsNamespace('table') }),
                buttonProps: {
                    iconProps: { name: 'assign' },
                },
                callback: () => changeUrlParam(EnvironmentTableModalTypeUrlParam, ModalType.AssociateToOU),
            },
        },
        {
            id: 'multiDelete',
            position: 30,
            content: {
                id: 'multiDelete',
                name: i18n.t('ACTIONS.MULTI_DELETE.BUTTON', { ns: getEnvsNamespace('table') }),
                buttonProps: {
                    iconProps: { name: 'delete' },
                },
                callback: () => changeUrlParam(EnvironmentTableModalTypeUrlParam, ModalType.MultiDelete),
            },
        },
    ];
    return actions;
};

export default function initializeActionsDefs() {
    EnvironmentsTableRegistry.addActions(actionsDefs());
}
