import React from 'react';
import { ICellRendererParams, ITooltipParams } from 'ag-grid-community';
import { EColumnGroupState, getColumnGroupState } from 'modules/workloads/utils/aggrid';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';

export const EpssScoreCellRenderer = (fieldName: string) => {
    const Component: React.FC<ICellRendererParams> = ({ node, value }) => {
        const { t } = useTranslation(getK8sNamespace('vulnerability'));
        const groupState = getColumnGroupState(fieldName, node);

        if (groupState === EColumnGroupState.OTHER) {
            return null;
        }
        if (groupState === EColumnGroupState.SELF && !value) {
            return t('VulnerabilityTable.cells.blank');
        }
        return value;
    };
    return Component;
};

export const EpssScoreTooltipGetter = ({ value }: ITooltipParams) => {
    return value === undefined || value === null ? 'N/A' : undefined;
};
