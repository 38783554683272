import { Vendors, getVendor } from 'common/consts/vendors';
import { isNotChina } from 'common/utils/dataCenter';
import { TFunction } from 'i18next';
import { IEnvironment } from 'common/interface/environmentsTable';
import { EnvironmentTab, Filters } from 'common/module_interface/assets/EnvironmentsTableRegistry';
import { IRowNode } from 'ag-grid-community';
import { AllIconsName, IconProps } from 'common/design-system/components-v2/Icon/Icon.types';

interface AddNewEnvLink {
    isRelevant?: () => boolean;
    label: string;
    iconProps?: IconProps;
    href: string;
    vendor: Vendors;
}

const getIconProps = (vendor: string): IconProps | undefined => {
    const iconName: AllIconsName | undefined = getVendor(vendor)?.newIcon;
    return iconName ? { name: iconName } : undefined;
};

export const environmentsTableAddNewLinks: (t: TFunction) => AddNewEnvLink[] = (t) => [
    {
        label: t('ADD_ENVIRONMENT.VENDORS.AWS'),
        iconProps: getIconProps(Vendors.AWS),
        href: '/aws',
        vendor: Vendors.AWS,
    },
    {
        label: t('ADD_ENVIRONMENT.VENDORS.AZURE'),
        iconProps: getIconProps(Vendors.AZURE),
        href: '/azure-onboarding',
        vendor: Vendors.AZURE,
    },
    {
        label: t('ADD_ENVIRONMENT.VENDORS.GCP'),
        iconProps: getIconProps(Vendors.GCP),
        href: '/gcp-onboarding',
        isRelevant: () => isNotChina(),
        vendor: Vendors.GCP,
    },
    {
        label: t('ADD_ENVIRONMENT.VENDORS.OCI'),
        iconProps: getIconProps(Vendors.OCI),
        href: '/oci-onboarding',
        isRelevant: () => isNotChina(),
        vendor: Vendors.OCI,
    },
    {
        label: t('ADD_ENVIRONMENT.VENDORS.KUBERNETES'),
        iconProps: getIconProps(Vendors.KUBERNETES),
        href: '/workload/cloud-add/kubernetes',
        vendor: Vendors.KUBERNETES,
    },
    {
        label: t('ADD_ENVIRONMENT.VENDORS.CONTAINER_REGISTRY'),
        iconProps: getIconProps(Vendors.CONTAINER_REGISTRY),
        href: '/workload/registry-onboarding',
        vendor: Vendors.CONTAINER_REGISTRY,
    },
    {
        label: t('ADD_ENVIRONMENT.VENDORS.ALIBABA'),
        iconProps: getIconProps(Vendors.ALIBABA),
        href: '/cloud-add/alibaba',
        isRelevant: () => isNotChina(),
        vendor: Vendors.ALIBABA,
    },
    {
        label: t('ADD_ENVIRONMENT.VENDORS.SHIFTLEFT'),
        iconProps: getIconProps(Vendors.SHIFT_LEFT),
        href: '/workload/cloud-add/shiftleft',
        vendor: Vendors.SHIFT_LEFT,
    },
];

export interface EnvironmentsTableProps {
    fetchingData: boolean;
    pageSize: number;
    getFilteredData: () => IEnvironment[];
    data: IEnvironment[];
    tableId: string;
    allCloudAccountsCount: number;
    tabData: EnvironmentTab;
    doesExternalFilterPass: (node: IRowNode<IEnvironment>) => boolean;
    activeFilters: Filters;
}

export const EnvironmentTableModalTypeUrlParam = 'EnvsTableModalType';

export enum ModalType {
    ValidatePermissions = 'ValidatePermissions',
    AssociateToOU = 'AssociateToOU',
    MultiDelete = 'MultiDelete',
}
