import { EMPTY_STRING, OPERATORS } from 'common/consts/GeneralConsts';
import { FINDINGS_OVERVIEW_DRAWER_ID } from './Findings.const';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { TFunction } from 'i18next';
import { IAdditionalFilterFieldInfo, IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { getVendor, Vendors } from 'common/consts/vendors';
import { IFindingKeysByCloudAccountId } from 'common/module_interface/intelligence/Intelligence.interface';
import { IFindingModelOriginString, IFindingModelStatus } from 'common/components/Findings/Findings.interface';
import { isNil } from 'common/utils/helpFunctions';
import { IFieldInfo } from 'common/interface/general';
import { CommonEventFields, EVENT_DETAILS_DRAWER_KEY } from 'common/module_interface/events/EventsConsts';
import { FindingStatusCode } from 'common/components/KustoEvents/KustoEvent.const';
import {
    convertFieldInfoUiToModelByFieldMapper,
    getKustoFieldMapperByUiName,
    isStringModelField,
} from 'common/erm-components/utils/Convertors/convertors';
import { IEvent, SysEventType } from 'common/module_interface/events/Events';
import { IModelUiMappingInfo } from 'common/erm-components/utils/Convertors/convertors.interface';
import { kustoEventMappersByUiName } from 'common/components/KustoEvents/KustoEventService';

/**
 * Function to get the status name based on the status code.
 * It uses internationalization to get the status name in the current language.
 *
 * @param {string} statusCode - The status code for which to get the status name.
 * @returns {string} The status name corresponding to the status code.
 */
export const getStatusName = (statusCode: number): string => {
    const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);
    let statusName = EMPTY_STRING;
    // Switch case to handle different status codes
    switch (statusCode) {
        // If the status code is 'OPEN', get the corresponding status name
        case FindingStatusCode.OPEN:
            statusName = t('FINDINGS_TABLE.CELL_RENDERERS.STATUS.OPEN');
            break;
        // If the status code is 'CLOSE', get the corresponding status name
        case FindingStatusCode.CLOSE:
            statusName = t('FINDINGS_TABLE.CELL_RENDERERS.STATUS.CLOSE');
            break;
    }
    // Return the status name
    return statusName;
};

export const openKustoEventDrawer = (event: IKustoEvent) => {
    if (event.origin === IFindingModelOriginString.CIEM) {
        showDrawer(EVENT_DETAILS_DRAWER_KEY.key, { eventId: event.id, sysEventType: SysEventType.kusto });
        return;
    }

    if (event.id || event.findingKey) {
        showDrawer(FINDINGS_OVERVIEW_DRAWER_ID, {
            eventId: event.id,
        });
    }
};

export const getSafeServerPlatform = (cloudAccountType: string): Vendors => {
    const vendor: Vendors = getVendor(cloudAccountType)?.name || Vendors.GENERIC;
    return ((vendor: Vendors) => {
        switch (vendor) {
            // convert gcp to google
            case Vendors.GCP:
                return Vendors.GOOGLE;
            default:
                return vendor;
        }
    })(vendor);
};

export const getFindingKeysByCloudAccountId = (findings: IEvent[]): IFindingKeysByCloudAccountId[] => {
    const cloudAccountIdToFindingKeysMap: Map<string, string[]> = findings.reduce((map, finding) => {
        const { cloudAccountId, findingKey } = finding;
        if (!map.has(cloudAccountId)) {
            map.set(cloudAccountId, []);
        }
        map.get(cloudAccountId)?.push(findingKey);
        return map;
    }, new Map<string, string[]>());

    return Array.from(cloudAccountIdToFindingKeysMap, ([cloudAccountId, findingsKeys]) => ({
        cloudGuardCloudAccountId: cloudAccountId,
        findingsKeys,
    }));
};

const convertAdditionalFieldToGslFilterString = (field: IAdditionalFilterFieldInfo): string | undefined => {
    const { operator, arrayJoinOperator } = field;
    const mapper: IModelUiMappingInfo | undefined = getKustoFieldMapperByUiName(kustoEventMappersByUiName, field.name);
    const modelFieldInfo: IFieldInfo = mapper ? convertFieldInfoUiToModelByFieldMapper(field, mapper) : field;
    const isStringValue: boolean = isStringModelField(mapper);
    const modelValue = modelFieldInfo.value;
    const modelName = modelFieldInfo.name;

    if (isNil(modelValue)) return undefined;

    const getGslValue = (val: any) => (isStringValue ? `'${val}'` : val);
    const getGslCondition = (val: any) => `(${modelName} ${operator} ${getGslValue(val)})`;
    if (Array.isArray(modelValue) && arrayJoinOperator) {
        return modelValue.map(getGslCondition).join(` ${arrayJoinOperator} `);
    }
    return getGslCondition(modelValue);
};

export const getEntityIdFilterInfo = (entityId: string): IAdditionalFilterFieldInfo => {
    return {
        name: CommonEventFields.entityExternalId,
        value: entityId,
        operator: OPERATORS.EQ,
        converterFn: convertAdditionalFieldToGslFilterString,
    };
};

export const getEntityDome9IdFilterInfo = (dome9id: string): IAdditionalFilterFieldInfo => {
    return {
        name: CommonEventFields.entityDome9Id,
        value: dome9id,
        operator: OPERATORS.EQ,
        converterFn: convertAdditionalFieldToGslFilterString,
    };
};

export const getCloudAccountIdFilterInfo = (platform: string): IAdditionalFilterFieldInfo => {
    return {
        name: CommonEventFields.cloudAccountId,
        value: platform,
        operator: OPERATORS.EQ,
        converterFn: convertAdditionalFieldToGslFilterString,
    };
};

export const getIsArchivedFilterInfo = (isArchived?: boolean): IAdditionalFilterFieldInfo => {
    return {
        name: CommonEventFields.status,
        value: isArchived ? IFindingModelStatus.Archived : IFindingModelStatus.Active,
        operator: OPERATORS.EQ,
        converterFn: convertAdditionalFieldToGslFilterString,
    };
};

export const getOriginsFilterInfo = (origins: string[] = []): IAdditionalFilterFieldInfo => {
    return {
        name: CommonEventFields.origin,
        value: origins,
        operator: OPERATORS.EQ,
        arrayJoinOperator: OPERATORS.OR,
        converterFn: convertAdditionalFieldToGslFilterString,
    };
};
