import { Controller, useFormContext } from 'react-hook-form';
import { GroupSelection, SelectV2, Stack } from 'common/design-system/components-v2';
import { Environment, FormValues } from '../VulnerabilityExclusionForm.types';
import { envGroupSelection } from '../VulnerabilityExclusionForm.const';
import useAllCloudAccounts from 'common/hooks/useAllCloudAccounts';
import { useOrganizationalUnits } from 'common/hooks/useOrganizationalUnit';

const EnvController = () => {
    const { allCloudAccounts } = useAllCloudAccounts();
    const { organizationalUnits } = useOrganizationalUnits();
    const { control, watch, resetField } = useFormContext<FormValues>();
    const envType = watch('toggles.envType');

    return (
        <Stack>
            <Controller
                name={'toggles.envType'}
                render={({ field }) => {
                    return (
                        <GroupSelection
                            defaultValue={Environment.ENVIRONMENTS_IDS}
                            options={envGroupSelection}
                            onChange={(value) => {
                                resetField('environmentsIds');
                                resetField('organizationalUnitsIds');
                                field.onChange(value);
                            }}
                            value={field.value}
                            direction='row'
                        />
                    );
                }}
            />
            {envType === Environment.ENVIRONMENTS_IDS ? (
                <Controller
                    name={'environmentsIds'}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <SelectV2
                            fullWidth
                            onChange={onChange}
                            placeholder={'Select Environment'}
                            value={value && value.length === 0 ? [] : value}
                            isMulti={true}
                            options={allCloudAccounts.map((account) => ({ label: account.name, value: account.id }))}
                        />
                    )}
                />
            ) : (
                <Controller
                    name={'organizationalUnitsIds'}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                        return (
                            <SelectV2
                                fullWidth
                                onChange={onChange}
                                placeholder={'Select Organizational Unit'}
                                value={value?.length === 0 ? [] : value}
                                isMulti={true}
                                options={organizationalUnits.map((org) => ({ label: org.name, value: org.id }))}
                            />
                        );
                    }}
                />
            )}
        </Stack>
    );
};

export default EnvController;
