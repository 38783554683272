import onboardingReducer from './aws/helpers/aws.reducer';
import ociOnboardingReducer from './oci/OciOnboarding.reducer';
import { IApplicationReducer } from 'common/interface/redux';
import { getIsAWSLegacyView } from '../../App.reducer';
import { getAppRootRegistry, getStoreService } from 'common/interface/services';
import AzureOnboardingReducer from './azure/helpers/azure.reducer';
import { lazy } from 'react';

export default function initializeOnboarding() {
    const reducers: IApplicationReducer[] = [
        { name: 'onboarding', reducer: onboardingReducer, isBlackList: false },
        { name: 'ociOnboarding', reducer: ociOnboardingReducer, isBlackList: true },
        { name: 'azureOnboarding', reducer: AzureOnboardingReducer, isBlackList: true },
    ];
    getAppRootRegistry().addReducers(reducers, 'name');

    const onboardingPageRoute = () => {
        const isAWSLegacyView = getIsAWSLegacyView(getStoreService().state);
        return {
            condition: !isAWSLegacyView,
            component: lazy(() => import('./aws/AWSOnboarding')),
            path: '/aws',
        };
    };
    const ociOnboardingPageRoute = () => {
        return {
            condition: true,
            component: lazy(() => import('./oci/OciOnboarding')),
            path: '/oci-onboarding',
        };
    };
    const azureOnboardingPageRoute = () => {
        return {
            condition: true,
            component: lazy(() => import('./azure/AzureOnboarding')),
            path: '/azure-onboarding',
        };
    };
    const gcpOnboardingPageRoute = () => {
        return {
            condition: true,
            component: lazy(() => import('./gcp/GcpOnboarding')),
            path: '/gcp-onboarding',
        };
    };
    getAppRootRegistry().addRoutes([
        { id: 'Onboarding Page', content: onboardingPageRoute },
        { id: 'OCI Onboarding Page', content: ociOnboardingPageRoute },
        { id: 'Azure Onboarding Page', content: azureOnboardingPageRoute },
        { id: 'GCP Onboarding Page', content: gcpOnboardingPageRoute },
    ]);
}
