import { Addin } from 'common/extensibility/AddinRegistry';
import { EventsDrawerAddingBase, EventsDrawerRegistry } from 'common/module_interface/events/EventsDrawerRegistry';
import { Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import { IFindingModelOriginString } from 'common/components/Findings/Findings.interface';
import { getIntelligenceService } from 'common/module_interface/intelligence/intelligence';
import { getCloudAccountsService } from 'common/interface/data_services';
import { getUserService } from 'common/interface/services';
import { lazy } from 'react';

const drawerTabs: () => Addin<EventsDrawerAddingBase<Tab>>[] = () => {
    return [
        {
            id: 'overview',
            position: 0,
            content: {
                id: 'overview',
                getValue: (event) => ({
                    name: 'overview',
                    label: 'Overview',
                    isReactTab: true,
                    reactTab: {
                        component: lazy(() => import('../Tabs/OverviewTab')),
                        componentProps: { event },
                    },
                }),
                isRelevant: (event) => event.origin !== IFindingModelOriginString.CIEM,
            },
        },
        {
            id: 'overview-ciem',
            position: 0,
            content: {
                id: 'overview-ciem',
                getValue: async (event) => {
                    const cloudAccount = await getCloudAccountsService().getCloudAccountByAccountId(
                        event.cloudAccountId,
                    );
                    return {
                        name: 'overview-ciem',
                        label: 'Overview',
                        isReactTab: false,
                        viewStateProps: {
                            id: event.id,
                            context: 'event',
                            type: 'overview-ciem',
                            metaData: {
                                event,
                                cloudAccount,
                            },
                        },
                    };
                },
                isRelevant: (event) => event.origin === IFindingModelOriginString.CIEM,
            },
        },
        {
            id: 'cve',
            position: 10,
            content: {
                id: 'cve',
                getValue: async (event) => {
                    const cloudAccount = await getCloudAccountsService().getCloudAccountByAccountId(
                        event.cloudAccountId,
                    );
                    return {
                        name: 'cve',
                        label: 'CVE',
                        isReactTab: false,
                        viewStateProps: {
                            id: event.id,
                            context: 'event',
                            type: 'cve',
                            metaData: {
                                event,
                                cloudAccount,
                            },
                        },
                    };
                },
                isRelevant: (event) => event.category === 'Package',
            },
        },
        {
            id: 'vulnerability',
            position: 20,
            content: {
                id: 'vulnerability',
                getValue: (event) => ({
                    name: 'vulnerabilities',
                    label: 'Vulnerability',
                    isReactTab: false,
                    viewStateProps: {
                        id: event.id,
                        context: 'event',
                        type: 'vulnerability',
                        metaData: event,
                    },
                }),
                isRelevant: (event) => {
                    const isVulnDetection =
                        event.origin === IFindingModelOriginString.ComplianceEngine &&
                        ['AWS Vulnerabilities Detection', 'Azure Vulnerabilities Detection'].includes(
                            event.bundleName || '',
                        );
                    const isImageAssuranceScan =
                        event.origin === IFindingModelOriginString.ImageAssurance &&
                        (event.category === 'ImageScan' || event.category === 'ScanSummary');
                    return isVulnDetection || isImageAssuranceScan;
                },
            },
        },
        {
            id: 'entityViewer',
            position: 30,
            content: {
                id: 'entityViewer',
                getValue: (event) => ({
                    name: 'entityViewer',
                    label: 'Entity Viewer',
                    isReactTab: true,
                    reactTab: {
                        component: lazy(() => import('../Tabs/EntityViewerTab')),
                        componentProps: { event },
                        overflowHidden: true,
                    },
                }),
                isRelevant: (event) => Object.keys(event.entityObject || {}).length > 0,
            },
        },
        {
            id: 'permissions',
            position: 40,
            content: {
                id: 'permissions',
                getValue: async (event) => {
                    const cloudAccount = await getCloudAccountsService().getCloudAccountByAccountId(
                        event.cloudAccountId,
                    );
                    return {
                        name: 'permissions',
                        label: 'Permissions',
                        isReactTab: false,
                        viewStateProps: {
                            id: event.id,
                            context: 'event',
                            type: 'permissions',
                            metaData: {
                                event,
                                cloudAccount,
                            },
                        },
                    };
                },
                isRelevant: (event) => event.origin === IFindingModelOriginString.CIEM,
            },
        },
        {
            id: 'occurrences',
            position: 50,
            content: {
                id: 'occurrences',
                getValue: (event) => ({
                    name: 'occurrences',
                    label: 'Occurrences',
                    isReactTab: false,
                    viewStateProps: {
                        id: event.id,
                        context: 'event',
                        type: 'occurrences',
                        metaData: event,
                    },
                }),
                isRelevant: (event) => !!(event?.occurrences && event?.occurrences?.length > 0),
            },
        },
        {
            id: 'remediation',
            position: 60,
            content: {
                id: 'remediation',
                getValue: (event) => ({
                    name: 'remediation',
                    label: 'Remediation',
                    isReactTab: false,
                    viewStateProps: {
                        id: event.id,
                        context: 'event',
                        type: 'remediation',
                        metaData: event,
                    },
                }),
                isRelevant: async (event) => {
                    if (!event.findingKey) return false;
                    const gslResponse = await getIntelligenceService().getGSLRunData({
                        gsl: `remediation where findingKey='${event.findingKey}' select event_time, remediation, status, params`,
                    });
                    return !!gslResponse.data.length;
                },
            },
        },
        {
            id: 'e2eTestTab',
            position: 999,
            content: {
                id: 'e2eTestTab',
                getValue: (event) => ({
                    name: 'e2eTestTab',
                    label: 'e2eTestTab',
                    isReactTab: false,
                    viewStateProps: {
                        id: event.id,
                        context: 'event',
                        type: 'e2eTestTab',
                        metaData: event,
                    },
                }),
                isRelevant: () => getUserService().hasPermission(['eventsE2eTesting']),
            },
        },
    ];
};

export default function initializeOverviewTabs() {
    EventsDrawerRegistry.addTabs(drawerTabs());
}
