import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const EmailStyled = styled(Stack)`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const EmailNameAndSearchWrapper = styled(Stack)`
    width: 50%;
    margin: 24px 24px 0 24px;
`;

const EmailBoxWrapper = styled(Stack)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 8px 24px 0 24px;
    border-radius: 8px;
    border: 1px solid rgb(219, 224, 233);
    min-height: 96px;
`;

const EmailAddWrapper = styled(Stack)`
    padding: 2px;
    min-width: 120px;
    max-width: none;
    width: 100%;
`;

const EmailBoxFooterWrapper = styled(Stack)`
    display: flex;
    flex-direction: row;
    margin: 0 24px;
`;

const EmailErrorWrapper = styled(Stack)`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export {
    EmailStyled,
    EmailNameAndSearchWrapper,
    EmailBoxWrapper,
    EmailBoxFooterWrapper,
    EmailErrorWrapper,
    EmailAddWrapper,
};
