import globalAddinContainer, { buildPath } from 'common/extensibility/AddinContainer';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IApplicationReducer } from 'common/interface/redux';
import { IReactPageRoute } from 'common/interface/routing';
import { IAppRootRegistry } from 'common/interface/services';

const REACT_PAGE_ROUTE = buildPath('router', 'reactPages');
const AFTER_LOGIN_EVENT = buildPath('events', 'after', 'login');
const APPLICATION_REDUCERS = buildPath('application', 'reducers');

export class AppRootRegistry implements IAppRootRegistry {
    public addRoutes(routes: Addin<IReactPageRoute>[]) {
        // Not sure why but if the content is not a function the page will crash
        const fixedRoutes = routes.map((route) => {
            if (typeof route.content !== 'function') {
                const originalContent = route.content;
                route.content = () => originalContent;
            }
            return route;
        });
        globalAddinContainer.add(REACT_PAGE_ROUTE, fixedRoutes);
    }
    public getRoutes(): IReactPageRoute[] {
        return globalAddinContainer.get(REACT_PAGE_ROUTE);
    }
    public addAfterLoginEvents(event: Addin<(...args: any[]) => void>[]) {
        globalAddinContainer.add(AFTER_LOGIN_EVENT, event);
    }
    public getAfterLoginEvents(): ((...args: any[]) => void)[] {
        return globalAddinContainer.get(AFTER_LOGIN_EVENT);
    }
    public addReducers(reducers: IApplicationReducer[], id: string) {
        globalAddinContainer.addMap(APPLICATION_REDUCERS, reducers, id);
    }
    public getReducers(): any[] {
        return globalAddinContainer.get(APPLICATION_REDUCERS);
    }
}
