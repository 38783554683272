import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { Popover, Stack, Typography } from 'common/design-system/components-v2';
import { ClientFilterPageTable } from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable';
import { getHttpService } from 'common/interface/services';
import ImageLinkRenderer from '../../protectedAsset/table/CellRenderers/ImageLinkRenderer';
import { ICellRendererParams, ValueGetterParams } from 'ag-grid-enterprise';
import {
    IProtectedAssetViewModel,
    ProtectedAssetsResponse,
} from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import EnvironmentCellRenderer from '../../protectedAsset/table/CellRenderers/EnvironmentCellRenderer';
import { createFreeTextFilter } from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable.filters';
import { removeShaFromImage } from '../../utils';
import { useTranslation } from 'react-i18next';

interface BaseImageListTable {
    imageName: string;
    Environment: string;
}

const BaseImageListTableCellRenderer: React.FC<{ imageId: string }> = ({ imageId }) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = React.useRef<any>(null);
    const { t } = useTranslation('k8s_base-image');

    const handleClick = () => setIsOpen((prevState) => !prevState);

    const fetchImagesByImageId = async (imageId: string) => {
        const reqObj = {
            filter: {
                fields: [
                    {
                        name: 'additionalFields|ImageId',
                        value: imageId,
                    },
                ],
            },
        };
        const { data } = await getHttpService().request<AxiosResponse<ProtectedAssetsResponse>>(
            'protected-asset/search',
            { method: 'POST', data: reqObj },
            { returnAsAxiosResponse: true },
            (err) => {
                throw err;
            },
        );
        return data.assets;
    };

    const getColumnDefs = [
        {
            colId: 'name',
            headerName: t('protectedAssetTable.columns.name'),
            valueGetter: (param: ValueGetterParams<IProtectedAssetViewModel>) => param.data?.name,
            cellRenderer: (params: ICellRendererParams<IProtectedAssetViewModel>) => <ImageLinkRenderer {...params} />,
        },
        {
            colId: 'cloudAccountId',
            headerName: t('protectedAssetTable.columns.environment'),
            field: 'cloudAccountId',
            cellRenderer: EnvironmentCellRenderer,
        },
    ];

    const getTableFilterDefs = () => {
        return [createFreeTextFilter<BaseImageListTable>(['cloudAccountId', 'name'])];
    };

    return (
        <Stack>
            <Typography textDecoration={'underline'} ref={ref} onClick={handleClick}>
                {imageId}
            </Typography>
            <Popover placement={'left'} maxHeight={700} open={isOpen} anchorEl={ref.current} onClose={handleClick}>
                <div style={{ height: '300px', width: '500px' }}>
                    {isOpen && (
                        <ClientFilterPageTable
                            pageTableId={'base-images'}
                            tableSettings={{ disableColumnMenu: true }}
                            getFooterItemTitle={(count: number) => `Total: ${count}`}
                            fetchAllItems={() => fetchImagesByImageId(removeShaFromImage(imageId))}
                            getTableColumnDefs={() => getColumnDefs}
                            getTableFilterDefs={getTableFilterDefs}
                        />
                    )}
                </div>
            </Popover>
        </Stack>
    );
};

export default BaseImageListTableCellRenderer;
