import { FC, useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    Input,
    InputLabel,
    SelectV2,
    Spinner,
    Stack,
    Typography,
} from 'common/design-system/components-v2';
import {
    getAccountService,
    getNotificationsService,
    getRolesService,
    getSecurityGroupsService,
    getUsersService,
    IRole,
} from 'common/interface/services';
import { useTranslation } from 'react-i18next';
import { I18nTranslationKey } from './initUsersPage';
import { useRoles } from 'common/hooks/useRoles';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { DirectPermissionsComponent } from '../../Components/DirectPermissions/DirectPermissionsComponent/DirectPermissionsComponent';
import {
    IPermissionItem,
    IPermissionModel,
    PERMISSION_TYPE,
    PermissionViewMode,
} from '../../Components/DirectPermissions/interfaces';
import { IBaseUserModel, IUser } from 'common/interface/user';
import useAllCloudAccounts from 'common/hooks/useAllCloudAccounts';
import { useOrganizationalUnits } from 'common/hooks/useOrganizationalUnit';
import { useAgents } from 'common/hooks/useAgents';
import { NotificationType } from 'common/interface/notifications';
import { IAccountAccessComponentStatus } from '../../Components/DirectPermissions/DirectPermissionsComponent/interfaces';
import { getPermissionConverter, IPermissionConverter } from '../../Components/DirectPermissions/permissionsConverters';
import { buildPathPhrase } from '../../Components/DirectPermissions/utils';

interface IAddUserProps {
    onChangeCallBack: () => void;
    userId: number;
}
let permissionsConverter: IPermissionConverter;
let scopeControls: IPermissionItem[] = [];
let networkSecurity: IPermissionItem[] = [];
let codeSecurity: IPermissionItem[] = [];
let accountAccess: IPermissionItem[] = [];
export const AddEditUserDrawer: FC<IAddUserProps> = ({ userId, onChangeCallBack }) => {
    const { t } = useTranslation(I18nTranslationKey);
    const { roles, isLoading } = useRoles();
    const [viewMode, setViewMode] = useState<PermissionViewMode>(PermissionViewMode.EDIT);
    const [allPermissions, setAllPermissions] = useState<IPermissionModel>({
        scopeControls: [],
        networkSecurity: [],
        codeSecurity: [],
        accountAccess: [],
    });
    const [availableRoles, setAvailableRoles] = useState<SelectOption[]>([]);
    const { isLoading: isCloudAccountLoading, allCloudAccounts } = useAllCloudAccounts();
    const { isLoading: isOULoading, organizationalUnits } = useOrganizationalUnits();
    const [isPermissionsLoading, setIsPermissionsLoading] = useState(true);
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const [preSelectedRoles, setPreSelectedRoles] = useState<IRole[]>([]);
    const [predefinedUser, setPredefinedUser] = useState<IUser>();
    const [userInputs, setUserInputs] = useState({ firstName: '', lastName: '', email: '', ssoEnabled: false });
    const { agents, isLoading: isAgentsLoading } = useAgents();
    const [agentsEnabled, setAgentsEnabled] = useState<boolean>(false);
    const [accountAccessStatus, setAccountAccessStatus] = useState<IAccountAccessComponentStatus | undefined>();
    const [predefinedPermissions, setPredefinedPermissions] = useState<IPermissionModel>();
    const [userAndRolesInheritedPermissions, setUserAndRolesInheritedPermissions] = useState<IPermissionModel>();

    const checkAgentsPermissionsEnabled = async () => {
        const license = await getAccountService().getLicense();
        setAgentsEnabled(license?.agentsEnabled);
        const hasAccountAccess = userId && (await getAccountService().hasMspActivated());
        setAccountAccessStatus({ hidden: !hasAccountAccess, readonly: true, showOnlyInReviewMode: true });
    };

    useEffect(() => {
        scopeControls = [];
        networkSecurity = [];
        codeSecurity = [];
        accountAccess = [];
    }, []);

    useEffect(() => {
        void checkAgentsPermissionsEnabled();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    useEffect(() => {
        if (preSelectedRoles) {
            setSelectedRoles(preSelectedRoles.map((role) => String(role.id)));
        }
    }, [preSelectedRoles]);

    useEffect(() => {
        if (userAndRolesInheritedPermissions && viewMode === PermissionViewMode.REVIEW) {
            setPredefinedPermissions(userAndRolesInheritedPermissions);
        } else {
            setPredefinedPermissions(allPermissions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allPermissions]);

    useEffect(() => {
        setAllPermissions({
            scopeControls,
            networkSecurity,
            codeSecurity,
            accountAccess,
        });
    }, [viewMode]);

    const switchToReviewMode = () => {
        setViewMode(PermissionViewMode.REVIEW);
    };

    const switchToEditMode = () => {
        setViewMode(PermissionViewMode.EDIT);
    };

    const onRoleChanged = async (roles: string[], permissionModel?: IPermissionModel) => {
        permissionModel = permissionModel || allPermissions;
        setSelectedRoles(roles);
        const _userAndRolesInheritedPermissions = {
            scopeControls: [...(permissionModel.scopeControls || [])],
            networkSecurity: [...(permissionModel.networkSecurity || [])],
            codeSecurity: [...(permissionModel.codeSecurity || [])],
            accountAccess: [...(permissionModel.accountAccess || [])],
        };
        Object.entries(permissionModel).forEach(([, permissionCategory]) => {
            permissionCategory.forEach((permission: IPermissionItem) => {
                permission.inheritedRoles = [];
            });
        });
        const allRoles = await getRolesService().getRoles();
        const selectedRoles: IRole[] = roles.map((selectedRole) =>
            allRoles.find((role) => role.id === +selectedRole),
        ) as IRole[];
        permissionsConverter.updateRoles(selectedRoles);
        for (const selectedRole of selectedRoles) {
            const role = allRoles.find((_role) => _role.id === selectedRole.id);
            const permissions = await permissionsConverter.convertPermissionListToPermissionModel(
                role?.permissions || {},
            );
            if (permissions) {
                Object.entries(permissions).forEach(([_key]) => {
                    const key = _key as keyof IPermissionModel;
                    permissions[key]?.forEach((permission: IPermissionItem) => {
                        const _permissions = _userAndRolesInheritedPermissions[key]?.find(
                            (permissionItem: IPermissionItem) =>
                                buildPathPhrase(permissionItem) === buildPathPhrase(permission),
                        );
                        if (_permissions) {
                            _permissions.inheritedRoles?.push(role?.name || '');
                            _permissions.view = _permissions.view || permission.view;
                            _permissions.enable = _permissions.enable || permission.enable;
                            _permissions.manage = _permissions.manage || permission.manage;
                            _permissions.member = _permissions.member || permission.member;
                            _permissions.admin = _permissions.admin || permission.admin;
                        } else {
                            _userAndRolesInheritedPermissions[key]?.push({
                                ...permission,
                                inheritedRoles: [role?.name || ''],
                            });
                        }
                    });
                });
            }
        }
        setUserAndRolesInheritedPermissions(_userAndRolesInheritedPermissions);
    };

    const save = async () => {
        const _allPermissions = {
            scopeControls,
            networkSecurity,
            codeSecurity,
            accountAccess,
        };
        // removed
        const permissionList = permissionsConverter.convertPermissionModelToPermissionList(_allPermissions);
        const userModel: IBaseUserModel = {
            id: null,
            email: userInputs.email,
            firstName: userInputs.firstName,
            lastName: userInputs.lastName,
            ssoEnabled: userInputs.ssoEnabled,
        };

        if (predefinedUser) {
            predefinedUser.email = userInputs.email;
            if (permissionList) {
                predefinedUser.permissions = permissionList as unknown as { [key: string]: string[] };
            }
            predefinedUser.roleIds = selectedRoles.map((role) => Number(role));
            delete predefinedUser.permissions.crossAccountAccess;
            await getUsersService().editUser(predefinedUser);
        } else {
            const newUser = await getUsersService().addUser(userModel);
            if (permissionList) {
                newUser.permissions = permissionList as unknown as { [key: string]: string[] };
            }
            newUser.roleIds = selectedRoles.map((role) => Number(role));
            await getUsersService().editUser(newUser);
        }

        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: '',
            text: 'save success',
        });
        onChangeCallBack();
    };

    const init = async () => {
        setIsPermissionsLoading(true);
        const users: IUser[] = await getUsersService().getUsers();
        const securityGroups = await getSecurityGroupsService().getAllSecurityGroups();
        const userAccountsRoles = await getAccountService().getAccountAndRoles();
        permissionsConverter = getPermissionConverter(
            organizationalUnits,
            allCloudAccounts,
            securityGroups,
            agents,
            userAccountsRoles,
        );
        if (userId) {
            const user = users.find((user) => user.id === userId);
            setPredefinedUser(user);
            if (user) {
                permissionsConverter = getPermissionConverter(
                    organizationalUnits,
                    allCloudAccounts,
                    securityGroups,
                    agents,
                    userAccountsRoles,
                    [],
                );
                setUserInputs({ firstName: '', lastName: '', email: user.email, ssoEnabled: user.ssoEnabled });
                const permissionModel = await permissionsConverter.convertPermissionListToPermissionModel(
                    user.permissions,
                    user.calculatedPermissions,
                );
                setAllPermissions(permissionModel);
                scopeControls = permissionModel.scopeControls || [];
                networkSecurity = permissionModel.networkSecurity || [];
                codeSecurity = permissionModel.codeSecurity || [];
                accountAccess = permissionModel.accountAccess || [];
                void onRoleChanged(
                    user.roleIds.map((roleId) => String(roleId)),
                    permissionModel,
                );
            }
        }
        setIsPermissionsLoading(false);
    };

    useEffect(() => {
        if (!isCloudAccountLoading && !isOULoading && !isAgentsLoading) {
            scopeControls = [];
            networkSecurity = [];
            codeSecurity = [];
            accountAccess = [];
            setAllPermissions({
                scopeControls,
                networkSecurity,
                codeSecurity,
                accountAccess,
            });
            void init();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, isCloudAccountLoading, isOULoading, isAgentsLoading]);

    async function handleRoles() {
        const _availableRoles: SelectOption[] = roles.map((role: IRole) => {
            return { labelProps: { leadingIconProps: { name: 'user' } }, label: role.name, value: String(role.id) };
        });
        setAvailableRoles(_availableRoles);
        if (userId) {
            const user = await getUsersService()
                .getUsers(false)
                .then((users) => users.find((user) => user.id === userId));
            if (user?.roleIds?.length) {
                setPreSelectedRoles(
                    user.roleIds
                        .map((roleId) => roles.find((role) => role.id === roleId))
                        .filter((role): role is IRole => role !== undefined),
                );
            }
        }
    }

    useEffect(() => {
        if (roles.length) {
            void handleRoles();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roles]);

    const getSelectedPermissions = (permissions: IPermissionModel) => {
        const _selectedPermissions: IPermissionModel = {};
        const permissionModelKeys = Object.keys(permissions) as Array<keyof IPermissionModel>;
        permissionModelKeys.forEach((key) => {
            const _permissionCategory = permissions[key]?.filter(
                (permission: IPermissionItem) => permission.type !== PERMISSION_TYPE.PLACEHOLDER,
            );
            // @ts-ignore
            const _permissions = _permissionCategory?.filter(
                (permission: IPermissionItem) =>
                    permission.admin || permission.enable || permission.member || permission.manage || permission.view,
            );
            if (_permissions && _permissions.length > 0) {
                // @ts-ignore
                _selectedPermissions[key] = _permissions;
            }
        });
        return _selectedPermissions;
    };

    const onPermissionsUpdated = (permissions: IPermissionModel) => {
        const _relevantPermission = getSelectedPermissions(permissions);
        scopeControls = _relevantPermission.scopeControls || [];
        networkSecurity = _relevantPermission.networkSecurity || [];
        codeSecurity = _relevantPermission.codeSecurity || [];
        accountAccess = _relevantPermission.accountAccess || [];

        void onRoleChanged(selectedRoles, allPermissions);
    };

    const firstNameUpdated = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserInputs({ ...userInputs, firstName: event.target.value });
    };

    const lastNameUpdated = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserInputs({ ...userInputs, lastName: event.target.value });
    };

    const emailUpdated = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserInputs({ ...userInputs, email: event.target.value });
    };

    const ssoStatusUpdated = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserInputs({ ...userInputs, ssoEnabled: event.target.checked });
    };

    return (
        <Stack fullHeight={true}>
            <Stack direction={'column'} spacing={3} style={{ flexGrow: 1, overflow: 'auto' }}>
                {userId ? (
                    ''
                ) : (
                    <>
                        <InputLabel text={t('LABELS.USER')} />
                        <Stack direction={'column'} spacing={3} padding={3}>
                            <Input
                                required={true}
                                onChange={firstNameUpdated}
                                label={t('LABELS.FIRST_NAME')}
                                labelProps={{ subText: t('LABELS.ENTER_FIRST_NAME') }}
                                type='text'
                                placeholder={t('LABELS.TYPE_HERE')}
                            />
                            <Input
                                required={true}
                                onChange={lastNameUpdated}
                                label={t('LABELS.LAST_NAME')}
                                labelProps={{ subText: t('LABELS.ENTER_LAST_NAME') }}
                                type='text'
                                placeholder={t('LABELS.TYPE_HERE')}
                            />
                            <Input
                                disabled={!!userId}
                                value={userInputs.email}
                                required={true}
                                onChange={emailUpdated}
                                label={t('LABELS.EMAIL')}
                                labelProps={{ subText: t('LABELS.ENTER_EMAIL') }}
                                type='text'
                                placeholder={t('LABELS.TYPE_HERE')}
                            />
                        </Stack>
                    </>
                )}
                <Stack direction={'column'} spacing={1}>
                    <InputLabel text={t('LABELS.SSO_ENABLED')} subText={t('LABELS.SSO_EXPLAINED')} />
                    <Checkbox checked={userInputs.ssoEnabled} onChange={ssoStatusUpdated} label={t('LABELS.ON')} />
                </Stack>
                <Stack direction={'column'} spacing={3}>
                    <InputLabel text={t('LABELS.ROLES')} subText={t('LABELS.ROLES_EXPLAINED')} />
                    <Stack direction={'column'} spacing={3} padding={3}>
                        <SelectV2
                            isMulti={true}
                            value={selectedRoles}
                            onChange={(roles) => onRoleChanged(roles)}
                            options={availableRoles}
                            placeholder={isLoading ? t('LABELS.LOADING') : t('LABELS.TYPE_HERE')}
                            fullWidth
                            clearable
                        />
                    </Stack>
                </Stack>
                {isPermissionsLoading ? (
                    <Stack
                        fullWidth={true}
                        alignItems={'center'}
                        justifyContent={'center'}
                        direction={'row'}
                        spacing={3}
                    >
                        <Spinner />
                        <Typography variant={'bodyLg'}> {t('LABELS.LOADING_PERMISSIONS')}</Typography>
                    </Stack>
                ) : (
                    <DirectPermissionsComponent
                        agentsEnabled={agentsEnabled}
                        accountAccessStatus={accountAccessStatus}
                        predefinedPermissions={predefinedPermissions}
                        onPermissionsUpdated={onPermissionsUpdated}
                        viewMode={viewMode}
                    />
                )}
            </Stack>
            <Stack
                style={{ borderTop: '1px solid #EBEEF4', marginBottom: '-20px', marginTop: '10px', paddingTop: '10px' }}
                justifyContent={'flex-end'}
                direction={'row'}
                spacing={4}
            >
                {viewMode === PermissionViewMode.EDIT ? (
                    <Button dataAid={'review-mode'} color={'brandPrimary'} onClick={switchToReviewMode}>
                        {t('BUTTONS.REVIEW_SAVE')}
                    </Button>
                ) : (
                    <>
                        <Button dataAid={'back-to-edit-mode'} color={'normal'} onClick={switchToEditMode}>
                            {t('BUTTONS.BACK_TO_EDIT')}
                        </Button>
                        <Button dataAid={'save'} color={'brandPrimary'} onClick={save}>
                            {t('BUTTONS.SAVE')}
                        </Button>
                    </>
                )}
            </Stack>
        </Stack>
    );
};
