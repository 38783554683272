import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const ActionWrapper = styled(Stack)<{ bgColor: string }>`
    min-width: 80px;
    background-color: ${({ bgColor }) => bgColor};
    border-radius: 8px;
    :active {
        background: ${({ theme }) => theme.palette.componentStates.ghostActive};
    }
`;

const ActionContentWrapper = styled(Stack)`
    cursor: pointer;
`;

const CloseButtonIconWrapper = styled(Stack)`
    cursor: pointer;
`;

export const FixItActionStyles = {
    ActionWrapper,
    ActionContentWrapper,
    CloseButtonIconWrapper,
};
