import React from 'react';
import { Stack, Table, Typography } from 'common/design-system/components-v2';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';
import AffectedAssetsTable from '../AffectedAssetsTable/AffectedAssetsTable';
import { AffectedAssetsTableWrapper } from './AffectedAssetsPanel.styled';
import AffectedAssetsTableFilterPanel from '../AffectedAssetsFilterPanel/AffectedAssetsFilterPanel';
import { SearchGroup } from '../../datasource/types';
import { AffectedAssetsPanelProps } from './AffectedAssetsPanel.types';
import { Filters } from 'modules/workloads/services/vulnerability-search/common/types';
import FilterPanelPlaceholder from 'common/components/ProtectedAssets/DynamicProtectedAssetsTable/components/FilterPanelPlaceholder';

const AffectedAssetsPanel: React.FC<AffectedAssetsPanelProps> = ({
    filterFields,
    pageSize,
    initialAggregation,
    affectedAssetDatasourceFactory,
    isLoading,
}) => {
    const { t } = useTranslation(getK8sNamespace('cve-explorer'));
    const [searchGroup, setSearchGroup] = React.useState<SearchGroup>({
        filters: {
            fields: filterFields,
            freeTextPhrase: '',
        },
        pageSize,
        current: 0,
        searchAfter: [],
    });
    const onFilterChange = React.useCallback(
        (filters: Filters) => {
            setSearchGroup({
                pageSize,
                filters: {
                    fields: [...filterFields, ...(filters?.fields ?? [])],
                    freeTextPhrase: filters.freeTextPhrase ?? '',
                },
                current: 0,
                searchAfter: [],
            });
        },
        [filterFields, pageSize],
    );

    return (
        <Stack spacing={2} fullWidth>
            <Typography variant='body500'>{t('cveDrawer.affectedAssetsPanel.title')}</Typography>
            <AffectedAssetsTableWrapper fullWidth padding={6}>
                {isLoading ? (
                    <FilterPanelPlaceholder />
                ) : (
                    <AffectedAssetsTableFilterPanel
                        onFilterChange={onFilterChange}
                        coreFilterFields={filterFields}
                        aggregations={initialAggregation}
                    />
                )}
                {isLoading ? (
                    <Table gridOptions={{ onGridReady: (gridReadyEvent) => gridReadyEvent.api.showLoadingOverlay() }} />
                ) : (
                    <AffectedAssetsTable
                        searchGroup={searchGroup}
                        affectedAssetDatasourceFactory={affectedAssetDatasourceFactory}
                    />
                )}
            </AffectedAssetsTableWrapper>
        </Stack>
    );
};

export default AffectedAssetsPanel;
