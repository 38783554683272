import { IColumnUsageDef } from 'common/interface/general';
import { FindingsColumnTypes as ColumnTypes } from '../FindingsTable/Types/FindingsColumnTypes';
import { CommonEventFields } from 'common/module_interface/events/EventsConsts';
import { KustoEventFields } from 'common/components/KustoEvents/KustoEvent.const';
import { GslCommonFields } from 'common/components/gsl/GslCommonFields';

export const getCdrEventsTableColumns = (hiddenColumns: string[] | undefined): IColumnUsageDef[] => {
    return [
        ColumnTypes.SELECTION,
        ColumnTypes.RULE_NAME,
        ColumnTypes.SEVERITY,
        ColumnTypes.CREATED_TIME,
        ColumnTypes.ENVIRONMENT,
        ColumnTypes.PLATFORM,
        ColumnTypes.DESCRIPTION,
        ColumnTypes.RULESET_NAME,
        ColumnTypes.ENTITY_NAME,
        ColumnTypes.ENTITY_TYPE,
        ColumnTypes.ACTION,
        ColumnTypes.ASSIGNEE,
        ColumnTypes.MITRE,
        ColumnTypes.ALERT_ID,
        ColumnTypes.LOG_TYPE,
    ].filter((column) => !hiddenColumns?.includes(column.id));
};

export const getIssueEvidenceCdrEventsTableColumns = (): IColumnUsageDef[] => [
    ColumnTypes.CREATED_TIME,
    ColumnTypes.SEVERITY,
    ColumnTypes.RULE_NAME,
];

export const getCdrEventsDashboardTableColumns = (): IColumnUsageDef[] => [
    {
        id: CommonEventFields.ruleName,
        colDefOverride: {
            flex: 4,
        },
    },
    {
        id: CommonEventFields.severityLevel,
        colDefOverride: {
            flex: 2,
        },
    },
    {
        id: CommonEventFields.createdTime,
        colDefOverride: {
            flex: 3,
        },
    },
    {
        id: KustoEventFields.mitreDict,
        colDefOverride: {
            flex: 3,
        },
    },
    {
        id: CommonEventFields.entityName,
        colDefOverride: {
            flex: 3,
        },
    },
    {
        id: CommonEventFields.cloudAccountId,
        colDefOverride: {
            flex: 3,
        },
    },
];

export const getCdrTopNetworkSecurityEventsColumns = (): IColumnUsageDef[] => [
    {
        id: CommonEventFields.ruleName,
        colDefOverride: {
            flex: 3,
        },
    },
    {
        id: CommonEventFields.severityLevel,
        colDefOverride: {
            flex: 2,
        },
    },
    {
        id: CommonEventFields.createdTime,
        colDefOverride: {
            flex: 2,
        },
    },
    {
        id: KustoEventFields.mitreDict,
        colDefOverride: {
            flex: 2,
        },
    },
    {
        id: CommonEventFields.entityName,
        colDefOverride: {
            flex: 2,
        },
    },
];

export const getCdrEventsByRuleColumns = (
    onClickRuleTitle: (ruleTitle: string, mitreTactic?: string) => void,
): IColumnUsageDef[] => [
    {
        id: CommonEventFields.ruleName,
        colDefOverride: {
            flex: 6,
            cellRendererParams: {
                customOnClick: onClickRuleTitle,
            },
        },
    },
    {
        id: CommonEventFields.bundleName,
        colDefOverride: {
            flex: 6,
        },
    },
    {
        id: CommonEventFields.severityLevel,
        colDefOverride: {
            flex: 2,
        },
    },
    {
        id: KustoEventFields.mitreTactic,
        colDefOverride: {
            flex: 3,
        },
    },
    {
        id: GslCommonFields.count,
        colDefOverride: {
            flex: 2,
        },
    },
];
