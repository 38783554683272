import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { CommonEventFields } from 'common/module_interface/events/EventsConsts';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { KustoEventFields } from 'common/components/KustoEvents/KustoEvent.const';
import { getSafeFindingSeverityInfo, IFindingSeverityInfo } from 'common/consts/FindingSeverity';

const RuleNameCellRenderer: React.FC<
    ICellRendererParams<IKustoEvent> & { customOnClick?: (ruleTitle: string, mitreTactic?: string) => void }
> = (params) => {
    if (!params.data) {
        return null;
    }
    const ruleTitle: string = params.data[CommonEventFields.ruleName];
    if (!ruleTitle) {
        return null;
    }
    const mitreTactic: string = params.data[KustoEventFields.mitreTactic];
    const severity: number = params.data[CommonEventFields.severityLevel];
    const severityInfo: IFindingSeverityInfo = getSafeFindingSeverityInfo(severity);
    const { customOnClick } = params;
    const options: IIconLinkCellProps = {
        isLink: true,
        value: ruleTitle,
        onClick: customOnClick ? () => customOnClick(ruleTitle, mitreTactic) : undefined,
        levelIconProps: {
            iconProps: {
                name: 'rule',
            },
            category: 'severity',
            level: severityInfo.key,
            size: 'md',
        },
    };

    return <IconLinkCellRenderer {...options} />;
};

export default RuleNameCellRenderer;
