import { IProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';

export interface CoreOwner {
    uid: string;
    kind: string;
}

export interface LinkLabel {
    label: string;
    link: string;
}

export enum AssetLabelTypes {
    Linkable = 'Linkable',
    Label = 'Label',
    NotAvailable = 'NotAvailable',
}

export type AssetLabel =
    | { type: AssetLabelTypes.Linkable; data: LinkLabel }
    | { type: AssetLabelTypes.Label; data: string }
    | { type: AssetLabelTypes.NotAvailable };

export type GetProtectedAssetUrl = (owner: CoreOwner, cloudAccountId: string) => Promise<LinkLabel | null>;

export interface KubernetesGeneralSectionPodOwnerProps {
    owner?: Partial<CoreOwner> | null;
    cloudAccountId: string;
    getUrl: GetProtectedAssetUrl;
}

export type ProtectedAssetIdToUrlConverter = Pick<
    IProtectedAssetsService,
    'getProtectedAssetById' | 'getProtectedAssetUrl'
>;
