import i18n, { validLanguages } from 'common/services/translations/translations';

export const GcpEnvNamespacePrefix = 'gcp-env';
export type GcpEnvTermTypes = 'gcp';
const termsFolders: Array<GcpEnvTermTypes> = ['gcp'];

export const getGcpEnvNamespace = (term: GcpEnvTermTypes) => `${GcpEnvNamespacePrefix}_${term}`;

const jsonFilesPaths = validLanguages.reduce<Array<{ path: string; language: string; term: GcpEnvTermTypes }>>(
    (acc, language) => {
        const filesPAth = termsFolders.map((term) => ({ path: `${language}/${term}`, language, term }));
        return [...acc, ...filesPAth];
    },
    [],
);

export const initializeGcpEnvI18n = async () => {
    await Promise.all(
        jsonFilesPaths.map(async (file) => {
            try {
                const contents = await require(`./languages/${file.path}.json`);
                i18n.addResourceBundle(file.language, getGcpEnvNamespace(file.term), contents);
                // eslint-disable-next-line
            } catch (error) {}
        }),
    );
};
