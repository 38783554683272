import { AgGridReact } from 'ag-grid-react';
import { GridOptions } from 'ag-grid-community';
import { CheckboxCellRenderer } from '../CellRenderers/CheckboxCellRenderer';
import { useEffect, useState } from 'react';
import { I18nSettingsTranslationKey } from '../../../initialize';
import { useTranslation } from 'react-i18next';
import { InfoCellRenderer } from '../CellRenderers/InfoCellRenderer';
import {
    infoColumnWidth,
    inheritedRolesColumnWidth,
    IPermissionComponentProps,
    IPermissionItem,
    PERMISSION_TYPE,
    PermissionCategorySubType,
    PermissionViewMode,
} from '../interfaces';
import { isPermissionItemsMatch, permissionSorter } from '../utils';
import { InheritedRolesCellRenderer } from '../CellRenderers/InheritedRolesCellRenderer';

export const CGResourceControls = ({
    title,
    permissions,
    viewMode = PermissionViewMode.EDIT,
    showOnlySelectedItems,
    permissionsChanged,
}: IPermissionComponentProps) => {
    const { t } = useTranslation(I18nSettingsTranslationKey);

    const reviewMode = viewMode === PermissionViewMode.REVIEW;

    const [rowData, setRowData] = useState<IPermissionItem[]>([]);
    const [filteredItems, setFilteredItems] = useState<IPermissionItem[]>([]);
    const getAllResourcesChildren = (permissions: IPermissionItem[]) => {
        return permissions.filter(
            (item) =>
                item.type === PERMISSION_TYPE.RULES_AND_RULESETS ||
                item.type === PERMISSION_TYPE.NOTIFICATIONS_AND_INTEGRATIONS ||
                item.type === PERMISSION_TYPE.ALERTS_EXCLUSION_REMEDIATION ||
                item.type === PERMISSION_TYPE.POLICY,
        );
    };
    const updateSelections = (permissions: IPermissionItem[]) => {
        const allCloudResources = permissions.find(
            (item: IPermissionItem) => item.type === PERMISSION_TYPE.ALL_CLOUD_ACCOUNT_RESOURCES,
        );
        const allResourcesChildren = getAllResourcesChildren(permissions);
        if (allCloudResources?.manage) {
            allResourcesChildren.forEach((item: IPermissionItem) => {
                item.manage = allCloudResources.manage;
            });
        }
        if (allCloudResources?.view) {
            allResourcesChildren.forEach((item: IPermissionItem) => {
                item.view = allCloudResources.view;
            });
        }

        const alert = permissions.find(
            (item: IPermissionItem) => item.type === PERMISSION_TYPE.ALERTS_EXCLUSION_REMEDIATION,
        );
        const policy = permissions.find((item: IPermissionItem) => item.type === PERMISSION_TYPE.POLICY);

        const rulesetItem = permissions.find((item) => item.type === PERMISSION_TYPE.RULES_AND_RULESETS);
        rulesetItem!.view = alert?.view || alert?.manage || policy?.view || policy?.manage;

        const notificationItem = permissions.find(
            (item) => item.type === PERMISSION_TYPE.NOTIFICATIONS_AND_INTEGRATIONS,
        );
        if (notificationItem) {
            notificationItem.view = policy?.view || policy?.manage;
        }
        return permissions;
    };
    const onCellClicked = () => {
        const _updatedPermissions = updateSelections(filteredItems);
        setRowData([..._updatedPermissions]);
        if (permissionsChanged) {
            permissionsChanged(_updatedPermissions!);
        }
    };

    useEffect(() => {
        const resourceControls: IPermissionItem[] = [
            {
                type: PERMISSION_TYPE.ALL_CLOUD_ACCOUNT_RESOURCES,
                categorySubType: PermissionCategorySubType.SCOPE_CONTROLS,
                id: PERMISSION_TYPE.ALL_CLOUD_ACCOUNT_RESOURCES,
                path: [t('GENERAL.ALL_CLOUDGUARD_RESOURCES')],
                manage: false,
                view: false,
                info: t('TOOLTIPS.ALL_CLOUD_ACCOUNT_RESOURCES'),
                name: PERMISSION_TYPE.ALL_CLOUD_ACCOUNT_RESOURCES,
            },
            {
                type: PERMISSION_TYPE.ONBOARDING,
                categorySubType: PermissionCategorySubType.SCOPE_CONTROLS,
                id: PERMISSION_TYPE.ONBOARDING,
                path: [t('GENERAL.ONBOARDING')],
                manage: false,
                view: false,
                info: t('TOOLTIPS.ONBOARDING'),
                name: PERMISSION_TYPE.ONBOARDING,
            },
            {
                type: PERMISSION_TYPE.POLICY,
                categorySubType: PermissionCategorySubType.SCOPE_CONTROLS,
                id: PERMISSION_TYPE.POLICY,
                path: [t('GENERAL.ALL_CLOUDGUARD_RESOURCES'), t('GENERAL.POLICY')],
                manage: false,
                view: false,
                info: t('TOOLTIPS.POLICIES'),
                name: PERMISSION_TYPE.POLICY,
            },
            {
                type: PERMISSION_TYPE.ALERTS_EXCLUSION_REMEDIATION,
                categorySubType: PermissionCategorySubType.SCOPE_CONTROLS,
                id: PERMISSION_TYPE.ALERTS_EXCLUSION_REMEDIATION,
                path: [t('GENERAL.ALL_CLOUDGUARD_RESOURCES'), t('GENERAL.ALERT_REMEDIATION_EXCLUSIONS')],
                manage: false,
                view: false,
                info: t('TOOLTIPS.REMEDIATION_EXCLUSION'),
                name: PERMISSION_TYPE.ALERTS_EXCLUSION_REMEDIATION,
            },
            {
                type: PERMISSION_TYPE.NOTIFICATIONS_AND_INTEGRATIONS,
                categorySubType: PermissionCategorySubType.SCOPE_CONTROLS,
                id: PERMISSION_TYPE.NOTIFICATIONS_AND_INTEGRATIONS,
                path: [t('GENERAL.ALL_CLOUDGUARD_RESOURCES'), t('GENERAL.NOTIFICATIONS_INTEGRATIONS')],
                manage: false,
                view: false,
                info: t('TOOLTIPS.INTEGRATIONS'),
                name: PERMISSION_TYPE.NOTIFICATIONS_AND_INTEGRATIONS,
            },
            {
                type: PERMISSION_TYPE.RULES_AND_RULESETS,
                categorySubType: PermissionCategorySubType.SCOPE_CONTROLS,
                id: PERMISSION_TYPE.RULES_AND_RULESETS,
                path: [t('GENERAL.ALL_CLOUDGUARD_RESOURCES'), t('GENERAL.RULES_RULESETS')],
                manage: false,
                view: false,
                info: t('TOOLTIPS.RULESETS_RULES'),
                name: PERMISSION_TYPE.RULES_AND_RULESETS,
            },
        ];
        setRowData(resourceControls);
    }, [t]);

    const handlePermissions = () => {
        if (rowData?.length === 0) return;
        let _permissions = rowData;
        if (permissions?.length) {
            const _rowData = rowData.filter(
                (item) => !permissions.some((permission) => isPermissionItemsMatch(permission, item)),
            );
            _permissions = [...permissions, ..._rowData];
        }
        const updatedPermissions = updateSelections(_permissions);
        setFilteredItems([...updatedPermissions]);
    };
    useEffect(() => {
        if (!rowData.length) return;
        handlePermissions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissions, rowData]);
    const getBaseColumns = () => {
        const baseColumns = [];
        if (reviewMode) {
            baseColumns.push({
                field: 'inheritedRoles',
                cellRenderer: InheritedRolesCellRenderer,
                headerName: t('HEADERS.ROLES'),
                width: inheritedRolesColumnWidth,
            });
        }
        baseColumns.push({
            field: 'info',
            headerName: t('HEADERS.INFO'),
            cellRenderer: InfoCellRenderer,
            cellRendererParams: { permissionSubType: PermissionCategorySubType.NETWORK_CONTROLS },
            width: infoColumnWidth,
        });
        return baseColumns;
    };
    const gridOptions: GridOptions = {
        getRowClass: (params) => {
            return 'type-' + params.node.data?.type;
        },
        defaultColDef: {
            suppressMenu: true,
        },
        columnDefs: [
            ...getBaseColumns(),
            {
                field: 'view',
                headerName: t('HEADERS.VIEW'),
                cellRenderer: CheckboxCellRenderer,
                width: 80,
                cellRendererParams: { columnName: 'view', reviewMode: reviewMode },
            },
            {
                field: 'manage',
                headerName: t('HEADERS.MANAGE'),
                cellRenderer: CheckboxCellRenderer,
                width: 100,
                cellRendererParams: { columnName: 'manage', reviewMode: reviewMode },
            },
        ],
        autoGroupColumnDef: {
            flex: 1,
            headerName: title,
            minWidth: 200,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
                suppressCount: true,
            },
        },
        domLayout: 'autoHeight',
        rowSelection: 'multiple',
        groupDefaultExpanded: 1,
        suppressAggFuncInHeader: true,
        treeData: true,
        getDataPath: (data) => data.path,
    };

    const showSelectedOnly = () => {
        const _permissions: IPermissionItem[] = permissions?.length ? permissions : rowData;
        const filteredItems = _permissions && _permissions.filter((item: IPermissionItem) => item.view || item.manage);
        setFilteredItems(filteredItems);
    };
    useEffect(() => {
        if (showOnlySelectedItems) {
            showSelectedOnly();
        } else {
            handlePermissions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewMode, showOnlySelectedItems]);

    return (
        <div className='ag-theme-alpine' data-aid={'scope-controls'}>
            <AgGridReact
                rowData={filteredItems.sort(permissionSorter)}
                gridOptions={gridOptions}
                treeData={true}
                onCellClicked={onCellClicked}
                animateRows={true}
                rowSelection='multiple'
            />
        </div>
    );
};
