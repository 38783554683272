import { IProtectedAssetDetailProvider } from 'common/module_interface/assets/ProtectedAssets';
import { emptyPaneActionInfo, InfoPanelActionProps } from 'common/components/InfoPane/InfoPane';
import { Vendors } from 'common/consts/vendors';
import KubernetesAccountService from '../../services/kubernetesAccount/kubernetesAccount.service';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import AssetDetailsAgentsVersionChipWidget from './widgets/Chips//AgentsVersionChip';
import { ProtectedAssetsPageRegistry } from 'common/module_interface/assets/ProtectedAssetsPageRegistry';
import ImageRescanChip from './widgets/Chips/ImageRescanChip';
import RiskScore from './widgets/Chips/RiskScore';
import { getFieldByNameFromAdditionalField, getScanStatus, getScanStatusDetails } from '../../utils';
import GroupImage from './widgets/Chips/GroupImage';
import MenuActions from './widgets/Chips/MenuActionsBaseImages';
import { awpScanChipAddinActionFactory } from './widgets/Chips/AwpRescanChip/AwpRescanChip';
import { AssetTypes } from 'common/assets/assets.const';
import { ScanStatusChip } from './widgets/Chips/ScanStatusChip';
import { getAdditionalFieldValue } from 'common/utils/ColumnDisplayValueGetters';
import { chipTypeByStatus } from './widgets/Chips/ScanStatusChip.consts';
import { ICloudAccount } from 'common/interface/data_services';

const isImageType = (typeByPlatform: string) =>
    [
        AssetTypes.CONTAINERREGISTRY_CONTAINERREGISTRYIMAGE,
        AssetTypes.KUBERNETES_KUBERNETESIMAGE,
        AssetTypes.AWS_ECSIMAGE,
        AssetTypes.SHIFTLEFT_SHIFTLEFTIMAGE,
    ].some((type) => type === typeByPlatform);

const isAgentsVersionRelevant = async (cloudAccount?: ICloudAccount, cloudAccountId?: string): Promise<boolean> => {
    if (!cloudAccount || !cloudAccountId || cloudAccount.platform !== Vendors.KUBERNETES) return false;

    try {
        const { data } = await KubernetesAccountService.getAgentSummary({ id: cloudAccountId });
        return data.some((agent) => agent.agentSummary.some((agent) => !agent.isAgentUpToDate));
    } catch (error) {
        return false;
    }
};

const getAgentsVersionChip = (cloudAccountId?: string): InfoPanelActionProps => {
    if (!cloudAccountId) return emptyPaneActionInfo;

    return {
        component: AssetDetailsAgentsVersionChipWidget,
        componentProps: { cloudAccountId },
    };
};

const initializeDetailsPaneItems = () => {
    const K8sAgentsVersion: IProtectedAssetDetailProvider<InfoPanelActionProps> = {
        isRelevant: async ({ cloudAccount, cloudAccountId }) => {
            return isAgentsVersionRelevant(cloudAccount, cloudAccountId);
        },
        getValue({ cloudAccountId }): InfoPanelActionProps {
            return getAgentsVersionChip(cloudAccountId);
        },
        alternativeProvider: {
            isRelevant: async ({ cloudAccount, cloudAccountId }) => {
                return isAgentsVersionRelevant(cloudAccount, cloudAccountId);
            },
            getValue({ cloudAccountId }): InfoPanelActionProps {
                return getAgentsVersionChip(cloudAccountId);
            },
        },
    };

    const ImageRescan: IProtectedAssetDetailProvider<InfoPanelActionProps> = {
        isRelevant: async ({ typeByPlatform }, isSummaryView) => {
            if (isSummaryView) return false;
            const relevantTypes = [
                AssetTypes.CONTAINERREGISTRY_CONTAINERREGISTRYIMAGE,
                AssetTypes.KUBERNETES_KUBERNETESIMAGE,
            ];
            return relevantTypes.some((type) => type === typeByPlatform);
        },
        getValue(entity: ICloudEntityData): InfoPanelActionProps {
            return {
                component: ImageRescanChip,
                componentProps: entity,
            };
        },
    };

    const scanStatusChip: IProtectedAssetDetailProvider<InfoPanelActionProps> = {
        isRelevant: async (entity: ICloudEntityData, isSummaryView) => {
            if (isSummaryView) return false;
            const scanStatus = getScanStatus(entity.protectedAsset);
            if (!scanStatus || !chipTypeByStatus[scanStatus]) return false;
            const isRelevantTypes = new Set([
                AssetTypes.KUBERNETES_KUBERNETESIMAGE,
                AssetTypes.CONTAINERREGISTRY_CONTAINERREGISTRYIMAGE,
                AssetTypes.SHIFTLEFT_SHIFTLEFTIMAGE,
                AssetTypes.AWS_ECSIMAGE,
                AssetTypes.AWS_INSTANCE,
                AssetTypes.AZURE_VIRTUALMACHINE,
                AssetTypes.AZURE_FUNCTIONAPP,
            ]);
            return isRelevantTypes.has(entity.typeByPlatform);
        },
        getValue(entity: ICloudEntityData): InfoPanelActionProps {
            const text = getScanStatus(entity.protectedAsset);
            const scanDetails = getScanStatusDetails(entity.protectedAsset);
            const tooltip = scanDetails ? `${text}: ${scanDetails}` : '';
            return {
                component: ScanStatusChip,
                componentProps: { text, tooltip },
            };
        },
    };

    const riskScore: IProtectedAssetDetailProvider<InfoPanelActionProps> = {
        isRelevant: async ({ protectedAsset: { typeByPlatform, additionalFields } }) => {
            return (
                isImageType(typeByPlatform) && !!getFieldByNameFromAdditionalField(additionalFields)('RiskScore')?.value
            );
        },
        getValue(entity: ICloudEntityData): InfoPanelActionProps {
            return {
                component: RiskScore,
                componentProps: {
                    riskScore: getFieldByNameFromAdditionalField(entity.protectedAsset.additionalFields)('RiskScore')
                        ?.value,
                },
            };
        },
    };

    const groupImage: IProtectedAssetDetailProvider<InfoPanelActionProps> = {
        isRelevant: async ({ protectedAsset }) => {
            return (
                isImageType(protectedAsset.typeByPlatform) &&
                (getAdditionalFieldValue(protectedAsset, 'VendorImage') === 'Vendor Image' ||
                    getAdditionalFieldValue(protectedAsset, 'BaseImage') === 'Base Image')
            );
        },
        getValue(entity: ICloudEntityData): InfoPanelActionProps {
            return {
                component: GroupImage,
                componentProps: {
                    isVendorImage: getAdditionalFieldValue(entity.protectedAsset, 'VendorImage') === 'Vendor Image',
                    isBaseImage: getAdditionalFieldValue(entity.protectedAsset, 'BaseImage') === 'Base Image',
                },
            };
        },
    };

    const menuActions: IProtectedAssetDetailProvider<InfoPanelActionProps> = {
        isRelevant: async ({ protectedAsset }) => {
            return isImageType(protectedAsset.typeByPlatform);
        },
        getValue(entity: ICloudEntityData): InfoPanelActionProps {
            return {
                component: MenuActions,
                componentProps: {
                    entity: entity,
                    isBaseImage: getAdditionalFieldValue(entity.protectedAsset, 'BaseImage') === 'Base Image',
                },
            };
        },
    };

    ProtectedAssetsPageRegistry.addChipProviders([
        {
            id: 'group image',
            position: 1,
            content: groupImage,
        },
        {
            id: 'risk score image',
            position: 3,
            content: riskScore,
        },
        {
            id: 'Workloads Images scan status',
            position: 2,
            content: scanStatusChip,
        },
        {
            id: 'K8s Agents version',
            position: 4,
            content: K8sAgentsVersion,
        },
        {
            id: 'Workloads Images Rescan',
            position: 5,
            content: ImageRescan,
        },
        {
            id: 'menu actions',
            position: 6,
            content: menuActions,
        },
        ...awpScanChipAddinActionFactory('rescan request chip', 1000),
    ]);
};

export { initializeDetailsPaneItems };
