import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import i18n from 'i18next';
import {
    ISimilarityGroupViewerDrawerProps,
    SIMILAR_ASSETS_DRAWER_KEY,
} from 'modules/riskManagement/components/SimilarityGroup/SimilarityGroup.initialize';
import EntityLink from 'common/components/ProtectedAssets/Renderers/EntityLink';
import { getFullEntityName } from 'common/components/ProtectedAssets/Renderers/EntityCellRender';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';

export const SimilarAssetGroupCellRender: React.FC<ICellRendererParams> = (params) => {
    const { entityId, name, platform } = params.data;
    if (!entityId && !name) {
        return null;
    }
    const entityName = getFullEntityName(name, entityId, platform);
    const widgetProps: ISimilarityGroupViewerDrawerProps = {
        similarityGroupId: params.node.data.similarityGroupId,
        memberAsset: params.node.data,
        assetEntityName: entityName,
    };
    const onClick = () => showDrawer(SIMILAR_ASSETS_DRAWER_KEY, widgetProps);

    const text = `${i18n.t('COMMON.PROTECTED_ASSETS_TABLE.SIMILARITY_PREFIX')} ${entityName}`;
    return <EntityLink entityName={text} url={''} onClick={onClick} />;
};
