import { ColDef } from 'ag-grid-community';
import { RemediationTableRegistry } from '../services/RemediationTableRegestries';
import i18n from 'common/services/translations/translations';
import { I18nRemediation } from '../helpers/remediation.consts';
import { IRemediation } from 'common/interface/remediation';
import { RulesetCellRenderer, CloudAccountCellRenderer } from '../Components/CellRenderers/CellsRenderer';

const CspmColumnDefs: () => ColDef<IRemediation>[] = () => [
    {
        colId: 'checkbox',
        headerCheckboxSelection: true,
        width: 40,
        maxWidth: 40,
        minWidth: 40,
        resizable: false,
        lockPosition: 'left',
        checkboxSelection: true,
        showDisabledCheckboxes: true,
    },
    {
        colId: 'ruleset',
        field: 'rulesetId',
        headerName: i18n.t('Ruleset', { ns: I18nRemediation }), // TABLE.HEADERS.RULESET
        width: 300,
        suppressSizeToFit: true,
        sortable: true,
        valueFormatter: (params) => `${params.data?.rulesetId}`,
        cellRenderer: RulesetCellRenderer,
    },
    {
        colId: 'ruleName',
        field: 'ruleName',
        headerName: i18n.t('Rule Name', { ns: I18nRemediation }), // TABLE.HEADERS.RULESET
        width: 200,
        suppressSizeToFit: true,
        sortable: true,
        valueFormatter: (params) => `${params.data?.ruleLogicHash ? params.data?.ruleName || '**Deleted Rule**' : ''}`,
    },
    {
        colId: 'environment',
        field: 'cloudAccountId',
        headerName: i18n.t('Environment', { ns: I18nRemediation }), // TABLE.HEADERS.RULESET
        width: 150,
        suppressSizeToFit: true,
        sortable: true,
        valueFormatter: (params) => `${params.data?.platform || ''}`,
        cellRenderer: CloudAccountCellRenderer,
    },
    {
        colId: 'entityName',
        field: 'ruleName',
        headerName: i18n.t('Entity Name', { ns: I18nRemediation }), // TABLE.HEADERS.RULESET
        width: 150,
        suppressSizeToFit: true,
        sortable: true,
        valueFormatter: (params) => `${params.data?.ruleName || ''}`,
    },
    {
        colId: 'ruleId',
        field: 'ruleId',
        headerName: i18n.t('Entity ID', { ns: I18nRemediation }), // TABLE.HEADERS.RULESET
        width: 150,
        suppressSizeToFit: true,
        sortable: true,
        valueFormatter: (params) => `${params.data?.ruleId || ''}`,
    },
    {
        colId: 'cloudBots',
        field: 'cloudBots',
        headerName: i18n.t('Cloud Bots', { ns: I18nRemediation }), // TABLE.HEADERS.RULESET
        width: 200,
        suppressSizeToFit: true,
        sortable: false,
        valueFormatter: (params) => `${params.data?.cloudBots?.map((cloudBot) => cloudBot.split(' ')[0]) || ''}`,
    },
    {
        colId: 'comment',
        field: 'comment',
        headerName: i18n.t('Comment', { ns: I18nRemediation }), // TABLE.HEADERS.RULESET
        width: 300,
        suppressSizeToFit: true,
        sortable: false,
        valueFormatter: (params) => `${params.data?.comment}`,
    },
    {
        colId: 'cloud',
        field: 'platform',
        headerName: i18n.t('cloud', { ns: I18nRemediation }), // TABLE.HEADERS.RULESET
        width: 50,
        suppressSizeToFit: true,
        sortable: true,
        sort: 'asc',
        hide: true,
        valueFormatter: (params) => `${params.data?.platform}`,
    },
];

/*
const CiemColumnDef: () => ColDef<IRemediation>[] = () => [
    {
        colId: 'checkbox',
        headerCheckboxSelection: true,
        width: 40,
        maxWidth: 40,
        minWidth: 40,
        resizable: false,
        lockPosition: 'left',
        checkboxSelection: true,
        showDisabledCheckboxes: true,
    },
    {
        colId: 'ruleset',
        field: 'rulesetId',
        headerName: i18n.t('Ruleset', { ns: I18nRemediation }), // TABLE.HEADERS.RULESET
        width: 300,
        suppressSizeToFit: true,
        sortable: true,
        valueFormatter: (params) => `${params.data?.rulesetId}`,
        cellRenderer: RulesetCellRenderer
    },
    {
        colId: 'severity',
        field: 'severities',
        headerName: i18n.t('Severity', { ns: I18nRemediation }), // TABLE.HEADERS.RULESET
        width: 200,
        suppressSizeToFit: true,
        sortable: true,
        valueFormatter: (params) => `${ params.data?.severities?.join(', ') || '' }`,
    },
    {
        colId: 'environment',
        field: 'cloudAccountId',
        headerName: i18n.t('Environment', { ns: I18nRemediation }), // TABLE.HEADERS.RULESET
        width: 150,
        suppressSizeToFit: true,
        sortable: true,
        valueFormatter: (params) => `${params.data?.platform || ''}`,
        cellRenderer: CloudAccountCellRenderer
    },
    {
        colId: 'entityName',
        field: 'ruleName',
        headerName: i18n.t('Entity Name', { ns: I18nRemediation }), // TABLE.HEADERS.RULESET
        width: 150,
        suppressSizeToFit: true,
        sortable: true,
        valueFormatter: (params) => `${params.data?.ruleName || ''}`,
    },
    {
        colId: 'cloudBots',
        field: 'cloudBots',
        headerName: i18n.t('Cloud Bots', { ns: I18nRemediation }), // TABLE.HEADERS.RULESET
        width: 200,
        suppressSizeToFit: true,
        sortable: false,
        valueFormatter: (params) => `${ (params.data?.cloudBots?.map((cloudBot) => cloudBot.split(' ')[0])) || ''}`,
    },
    {
        colId: 'comment',
        field: 'comment',
        headerName: i18n.t('Comment', { ns: I18nRemediation }), // TABLE.HEADERS.RULESET
        width: 300,
        suppressSizeToFit: true,
        sortable: false,
        valueFormatter: (params) => `${params.data?.comment}`,
    },
    {
        colId: 'cloud',
        field: 'platform',
        headerName: i18n.t('cloud', { ns: I18nRemediation }), // TABLE.HEADERS.RULESET
        width: 50,
        suppressSizeToFit: true,
        sortable: true,
        sort: 'asc',
        hide: true,
        valueFormatter: (params) => `${params.data?.platform}`,
    },
];
*/

export default function initializeColumnDefs() {
    RemediationTableRegistry.addColumnDefs(CspmColumnDefs());
}
