import React, { useMemo } from 'react';
import PropertyBoxesRow from 'common/components/PropertyBox/PropertyBoxesRow';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import PropertyBox from 'common/components/PropertyBox/PropertyBox';
import Command from 'common/design-system/components-v2/Command/Command';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
export const GslPanel: React.FC<{ event: IKustoEvent }> = ({ event }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const rule = event.ruleLogic;

    const gsl = useMemo(() => {
        return <Command text={rule} />;
    }, [rule]);

    return (
        rule && (
            <PropertyBoxesRow>
                <PropertyBox title={t('INT_FINDING_OVERVIEW.GSL')} content={gsl} />
            </PropertyBoxesRow>
        )
    );
};
