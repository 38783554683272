import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import SimpleText from 'common/components/ProtectedAssets/Renderers/SimpleText';
import { getStatusName } from '../../Findings.utils';

const StatusCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const statusId = Number(params.value);
    const statusName: string = getStatusName(statusId);
    return <SimpleText value={statusName} />;
};

export default StatusCellRenderer;
