import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const TableWrapper = styled(Stack)`
    height: 700px;
`;

export const IdenticalRolesModalStyles = {
    TableWrapper,
};
