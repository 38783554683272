import React from 'react';
import { Icon, Stack, Typography } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import Tooltip, { ITooltipProps } from 'common/design-system/components-v2/Tooltip';
import RemediationSection from 'common/components/RemediationPanel';

interface RemediationPanelProps {
    title?: string;
    remediation: string;
    tooltip?: ITooltipProps['content'];
}

export const RemediationPanel: React.FC<RemediationPanelProps> = ({ remediation, tooltip }) => {
    const { t } = useTranslation(getK8sNamespace('cve-explorer'));
    return (
        <Stack spacing={2}>
            <Stack direction='row' alignItems='center' spacing={1}>
                <Typography variant='body500'>{t('cveDrawer.remediationPanel.remediation')}</Typography>
                {tooltip && (
                    <Tooltip content={tooltip} placement='right'>
                        <Icon name='info' size={14} />
                    </Tooltip>
                )}
            </Stack>
            <RemediationSection remediation={remediation} />
        </Stack>
    );
};
