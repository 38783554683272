import { FlatPackageAndCve, Layers } from '../../../../../../services/vulnerability/vulnerability.interface';
import { ITableExportButton } from 'common/design-system/components-v2/Table/Table.types';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';

export interface CvesProps {
    cves: Array<FlatPackageAndCve>;
    exportCveAsJSONAndCSV: Array<ITableExportButton>;
    setRemediationOnly: (remediationOnly: boolean) => void;
    layers?: Layers;
    cloudEntity: Pick<IProtectedAssetViewModel, 'entityId' | 'typeByPlatform' | 'cloudAccountId'>;
}

export interface ExpandState {
    maxLevel: number;
    parents: number;
    expandedParents: number;
}

export enum CveColumnsNames {
    LayerId = 'LayerId',
    LayerCommand = 'layerCommand',
    Id = 'id',
    Severity = 'severity',
    IsFixedByPackageRemediation = 'isFixedByPackageRemediation',
    KnownExploit = 'knownExploit',
    IsExcluded = 'isExcluded',
    Remediation = 'remediation',
    PackageVersion = 'packageVersion',
    'Cvss-info.Vector-string' = 'cvss-info.vector-string',
    'Source' = 'source',
    'Cvss-info.Source' = 'cvss-info.source',
    Description = 'description',
    PackageName = 'packageName',
    packageSeverity = 'packageSeverity',
    PackageType = 'packageType',
    'PackagePackage-Manager.name' = 'packagePackage-manager.name',
    'PackageIs-os-Package' = 'packageIs-os-package',
    'PackagePackage-manager.Path' = 'packagePackage-manager.path',
    EpssScore = 'epssScore',
    RelatedIds = 'relatedIds',
    PackageBaseImages = 'packageBaseImages',
    PackageInUse = 'packageInUse',
}
