import React, { Fragment, useState } from 'react';
import { Button, Modal, Stack, Typography } from 'common/design-system/components-v2';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { useTranslation } from 'react-i18next';
import { getLoggerService, getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { getIntelligenceService } from 'common/module_interface/intelligence/intelligence';
import {
    ICloseRequest,
    IFindingKeysByCloudAccountId,
} from 'common/module_interface/intelligence/Intelligence.interface';
import { ICloseModalProps } from './CloseModal.types';
import { getFindingKeysByCloudAccountId } from '../../../Findings.utils';

export const CloseModal: React.FC<ICloseModalProps> = (props: ICloseModalProps) => {
    const { findings, closeModal } = props;
    const { t } = useTranslation(i18nIntelligenceNamespace);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onCloseModalCancel = () => {
        closeModal();
    };

    const prepareCloseRequest = (): ICloseRequest => {
        const findingsKeysByCloudAccountId: IFindingKeysByCloudAccountId[] = getFindingKeysByCloudAccountId(findings);
        return { findings: findingsKeysByCloudAccountId };
    };

    const onCloseAlertsClick = async () => {
        const request: ICloseRequest = prepareCloseRequest();
        try {
            setIsLoading(true);
            await getIntelligenceService().closeFindings(request);
            getNotificationsService().addNotification({
                type: NotificationType.SUCCESS,
                text: t('CLOSE_MODAL.CLOSE_ALERTS_SUCCESS'),
            });
            closeModal();
        } catch (message: unknown) {
            const errorTitle = t('CLOSE_MODAL.CLOSE_ALERTS_FAILED');
            getNotificationsService().addNotification({ type: NotificationType.ERROR, text: errorTitle });
            await getLoggerService().error(`${errorTitle} ${message as string}`);
        } finally {
            setIsLoading(false);
            props.refreshTableData();
        }
    };

    return (
        <Fragment>
            <Modal.ModalDialog onRequestClose={onCloseModalCancel} isOpen={true} width={'md'}>
                <Modal.ModalHeader title={t('CLOSE_MODAL.TITLE')} onClose={onCloseModalCancel} />
                <Modal.ModalContent fullHeight={true}>
                    <Typography>{t('CLOSE_MODAL.DESCRIPTION', { alertsCount: findings.length })}</Typography>
                </Modal.ModalContent>
                <Modal.ModalFooter>
                    <Stack direction='row' justifyContent='flex-end' fullWidth spacing={2}>
                        <Button key={'cancel-button-action'} variant='text' onClick={onCloseModalCancel}>
                            {i18n.t('COMMON.CANCEL')}
                        </Button>
                        <Button
                            key={'close-alerts-button-action'}
                            color='brandPrimary'
                            loading={isLoading}
                            disabled={isLoading}
                            onClick={onCloseAlertsClick}
                            dataAid='Close alert'
                        >
                            {t('CLOSE_MODAL.ACTIONS.CLOSE_ALERTS')}
                        </Button>
                    </Stack>
                </Modal.ModalFooter>
            </Modal.ModalDialog>
        </Fragment>
    );
};
