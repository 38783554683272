import { ICloudBot } from 'common/interface/remediation';
const CloudBotModel = (
    name: string,
    platform: string,
    parameters: string[] = [],
    isCustom: boolean | undefined = false,
): ICloudBot => ({
    name,
    platform,
    parameters,
    isCustom,
});

const awsCustomCloudBot = CloudBotModel('Custom', 'Aws', undefined, true);
const azureCustomCloudBot = CloudBotModel('Custom', 'Azure', undefined, true);
const googleCustomCloudBot = CloudBotModel('Custom', 'Google', undefined, true);
const kubernetesCustomCloudBot = CloudBotModel('Custom', 'Kubernetes', undefined, true);
const alibabaCustomCloudBot = CloudBotModel('Custom', 'Alibaba', undefined, true);

const cloudBots: ICloudBot[] = [
    CloudBotModel('acm_delete_certificate', 'Aws'),
    CloudBotModel('ami_set_to_private', 'Aws'),
    CloudBotModel('cloudtrail_enable', 'Aws', ['trail_name', 'bucket_name']),
    CloudBotModel('cloudtrail_enable_log_file_validation', 'Aws'),
    CloudBotModel('cloudtrail_send_to_cloudwatch', 'Aws', ['log_group_name']),
    CloudBotModel('cloudwatch_create_metric_filter', 'Aws', ['email_address', 'filter1', 'filter2', 'filter3']),
    CloudBotModel('config_enable', 'Aws', ['bucket_name', 'bucket_region']),
    CloudBotModel('ec2_attach_instance_role', 'Aws', ['role_arn']),
    CloudBotModel('ec2_attach_sg', 'Aws', ['name_of_sg_to_attach']),
    CloudBotModel('ec2_create_snapshot', 'Aws'),
    CloudBotModel('ec2_release_eips', 'Aws'),
    CloudBotModel('ec2_quarantine_instance', 'Aws'),
    CloudBotModel('ec2_stop_instance', 'Aws'),
    CloudBotModel('ec2_terminate_instance', 'Aws'),
    CloudBotModel('ec2_update_instance_role', 'Aws', ['policy_arn']),
    CloudBotModel('ecs_service_role_detach_inline_policy', 'Aws'),
    CloudBotModel('iam_detach_policy', 'Aws'),
    CloudBotModel('iam_generate_credential_report', 'Aws'),
    CloudBotModel('iam_group_delete_inline_group', 'Aws'),
    CloudBotModel('iam_role_attach_policy', 'Aws', ['policy_arn']),
    CloudBotModel('iam_user_attach_policy', 'Aws', ['policy_arn']),
    CloudBotModel('iam_user_deactivate_unused_access_key', 'Aws', ['number_of_days']),
    CloudBotModel('iam_user_delete_inline_policies', 'Aws', ['managed_policies_arn']),
    CloudBotModel('iam_user_disable_console_password', 'Aws'),
    CloudBotModel('iam_user_force_password_change', 'Aws'),
    CloudBotModel('iam_quarantine_role', 'Aws'),
    CloudBotModel('iam_quarantine_user', 'Aws'),
    CloudBotModel('iam_entity_create_and_attach_permission_boundary', 'Aws', ['policy_name']),
    CloudBotModel('iam_role_clone_with_non_enumerable_name', 'Aws'),
    CloudBotModel('iam_turn_on_password_policy', 'Aws', [
        'MinimumPasswordLength',
        'RequireSymbols',
        'RequireNumbers',
        'RequireUppercaseCharacters',
        'RequireLowercaseCharacters',
        'AllowUsersToChangePassword',
        'MaxPasswordAge',
        'PasswordReusePrevention',
        'HardExpiry',
    ]),
    CloudBotModel('igw_delete', 'Aws'),
    CloudBotModel('kms_cmk_enable_key', 'Aws'),
    CloudBotModel('kms_enable_rotation', 'Aws'),
    CloudBotModel('lambda_detach_blanket_permissions', 'Aws'),
    CloudBotModel('lambda_enable_active_tracing', 'Aws'),
    CloudBotModel('lambda_tag', 'Aws', ['Key', 'Value']),
    CloudBotModel('load_balancer_enable_access_logs', 'Aws'),
    CloudBotModel('mark_for_stop_ec2_resource', 'Aws', ['unit']),
    CloudBotModel('network_firewall_enable_logging', 'Aws', ['LoggingType', 'LogDestinationType', 'LogDestination']),
    CloudBotModel('rds_quarantine_instance', 'Aws'),
    CloudBotModel('route53domain_enable_auto_renew', 'Aws'),
    CloudBotModel('route53domain_enable_transfer_lock', 'Aws'),
    CloudBotModel('s3_block_all_public_access', 'Aws'),
    CloudBotModel('s3_delete_acls', 'Aws'),
    CloudBotModel('s3_delete_permissions', 'Aws'),
    CloudBotModel('s3_disable_static_website_hosting', 'Aws'),
    CloudBotModel('s3_enable_encryption', 'Aws', ['Encryption type (kms - RECOMMENDED!)', 'KMS key arn']),
    CloudBotModel('s3_enable_logging', 'Aws'),
    CloudBotModel('s3_enable_versioning', 'Aws'),
    CloudBotModel('s3_limit_access', 'Aws'),
    CloudBotModel('s3_only_allow_ssl', 'Aws'),
    CloudBotModel('secretsmanager_enable_encryption', 'Aws', ['kms-key-id']),
    CloudBotModel('sg_clear_rules_for_any_scope', 'Aws', ['port', 'protocol', 'direction', 'white-list (Optional)']),
    CloudBotModel('sg_delete', 'Aws'),
    CloudBotModel('sg_delete_not_matching_cidr', 'Aws', ['port', 'cidr', 'direction']),
    CloudBotModel('sg_modify_scope_by_port', 'Aws', ['port', 'change_scope_from', 'change_scope_to', 'direction']),
    CloudBotModel('sg_rules_delete', 'Aws'),
    CloudBotModel('sg_rules_delete_by_scope', 'Aws', ['scope', 'direction', 'port', 'protocol']),
    CloudBotModel('sg_single_rule_delete', 'Aws', ['split', 'protocol', 'scope', 'direction', 'port']),
    CloudBotModel('sns_enforce_sse', 'Aws', ['kmsKeyId']),
    CloudBotModel('sns_set_topic_private', 'Aws', ['Policy']),
    CloudBotModel('sns_topic_delete', 'Aws'),
    CloudBotModel('sqs_configure_dlq', 'Aws'),
    CloudBotModel('sqs_enforce_sse', 'Aws', ['kmsKeyId', 'kmsRegion']),
    CloudBotModel('tag_ec2_resource', 'Aws', ['key', 'value']),
    CloudBotModel('ssm_document_set_private', 'Aws', ['account_id']),
    CloudBotModel('vpc_delete', 'Aws'),
    CloudBotModel('vpc_isolate', 'Aws'),
    CloudBotModel('vpc_turn_on_flow_logs', 'Aws', ['traffic_type', 'destination', 's3_arn']),
    CloudBotModel('ec2_tag_instance_from_vpc', 'Aws'),
    CloudBotModel('s3_delete_bucket', 'Aws'),
    CloudBotModel('cosmos_db_account_add_tag', 'Azure', ['Key', 'Value']),
    CloudBotModel('create_delete_resource_lock_at_resource_group_level', 'Azure', ['lock-name']),
    CloudBotModel('create_readonly_resource_lock_at_resource_group_level', 'Azure', ['lock-name']),
    CloudBotModel('enable_nsg_flow_logs', 'Azure', [
        'storage-account-name',
        'storage-account-resource-group',
        'network-watcher-name',
        'log-retention-days',
        'flow-log-name',
    ]),
    CloudBotModel('modify_network_security_group_scope_by_port', 'Azure', ['port', 'direction', 'scope', 'access']),
    CloudBotModel('delete_network_security_group', 'Azure'),
    CloudBotModel('delete_network_security_group_single_rule', 'Azure', [
        'destination_port',
        'destination_scope',
        'source_port',
        'source_scope',
        'access',
    ]),
    CloudBotModel('disable_blob_public_access', 'Azure'),
    CloudBotModel('postgres_enable_connection_throttling', 'Azure'),
    CloudBotModel('postgres_enable_log_connections', 'Azure'),
    CloudBotModel('postgres_enable_log_disconnections', 'Azure'),
    CloudBotModel('postgres_enable_log_duration', 'Azure'),
    CloudBotModel('postgres_enable_log_retention_days_7', 'Azure'),
    CloudBotModel('postgres_enforce_ssl_connection', 'Azure'),
    CloudBotModel('postgres_enforce_ssl_connection_tls_12', 'Azure'),
    CloudBotModel('redis_cache_ssl_only', 'Azure'),
    CloudBotModel('sql_add_firewall_rule_by_existing_subnet', 'Azure', [
        'firewall-rule-name',
        'vnet-resource-group',
        'existing-vnet-name',
        'existing-subnet-name',
    ]),
    CloudBotModel('sql_add_firewall_rule_by_ip', 'Azure', [
        'firewall-rule-name',
        'firewall-rule-starting-ip-address',
        'firewall rule ending ip address',
    ]),
    CloudBotModel('sql_disable_public_access', 'Azure', ['min_tls_version']),
    CloudBotModel('sql_enable_access_from_all_vnets', 'Azure'),
    CloudBotModel('sql_enable_data_encryption', 'Azure'),
    CloudBotModel('sql_enable_azure_ad_authentication', 'Azure', [
        'sql_enable_azure_ad_authentication',
        'azure-ad-admin-email',
        'azure-ad-admin-sid',
        'azure-ad-tenant-id',
    ]),
    CloudBotModel('storage_account_disable_blob_public_access', 'Azure'),
    CloudBotModel('storage_account_disable_public_network_access', 'Azure', ['vnet resource group', 'vnet', 'subnet']),
    CloudBotModel('storage_account_enable_https_traffic_only', 'Azure'),
    CloudBotModel('tag_virtual_machine', 'Azure', ['key', 'value']),
    CloudBotModel('virtual_machine_deallocate', 'Azure'),
    CloudBotModel('virtual_machine_stop', 'Azure'),
    CloudBotModel('firewall_rules_delete', 'Google'),
    CloudBotModel('vm_instance_stop', 'Google'),
    CloudBotModel('gke_change_imageType_to_cos', 'Google'),
    CloudBotModel('gke_enable_master_authorized_networks', 'Google', ['cidr blocks']),
    CloudBotModel('gke_subnet_set_private_google_access_on', 'Google'),
    CloudBotModel('vm_instance_add_label', 'Google', ['key', 'value']),
    CloudBotModel('vm_instance_disable_public_ip', 'Google'),
    CloudBotModel('subnet_set_private_google_access_on', 'Google'),
    CloudBotModel('cloud_sql_delete_public_ip_ranges', 'Google'),
    CloudBotModel('storage_bucket_remove_allow_public_access_rules', 'Google'),
    CloudBotModel('flow_logs_enable', 'Google'),
];

export const getCloudBots = () => {
    return Promise.resolve([
        awsCustomCloudBot,
        azureCustomCloudBot,
        googleCustomCloudBot,
        kubernetesCustomCloudBot,
        alibabaCustomCloudBot,
        ...cloudBots,
    ]);
};
