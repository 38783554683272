import { ColDef } from 'ag-grid-community';
import i18n from 'common/services/translations/translations';
import { I18nTranslationKey, usersTableRegistry } from './initUsersPage';
import DotCellRenderer from './cellRenderers/DotCellRenderer/dotCellRenderer';
import { IUser } from 'common/interface/user';
import UserChipsCellRenderer from './cellRenderers/ChipsCellRenderer/UserChipsCellRenderer';
import IconLinkCellRenderer from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { ValueFormatterParams } from 'ag-grid-enterprise';
import { editUser } from './UsersPage';

const NA = 'N/A';
const columnDefs: () => ColDef<IUser>[] = () => [
    {
        colId: 'checkbox',
        headerCheckboxSelection: true,
        width: 40,
        maxWidth: 40,
        minWidth: 40,
        resizable: false,
        lockPosition: 'left',
        checkboxSelection: () => {
            return true;
        },
        showDisabledCheckboxes: true,
    },
    {
        colId: 'name',
        field: 'name',
        headerName: i18n.t('TABLE.HEADERS.NAME', { ns: I18nTranslationKey }),
        width: 400,
        suppressSizeToFit: true,
        sortable: true,
        cellRenderer: (params: ValueFormatterParams) =>
            IconLinkCellRenderer({ isLink: true, value: params.data?.name, onClick: () => editUser(params.data) }),
        valueFormatter: (params) => `${params.data?.name}`,
    },
    {
        colId: 'rolesNames',
        field: 'rolesNames',
        headerName: i18n.t('TABLE.HEADERS.ROLES', { ns: I18nTranslationKey }),
        width: 400,
        suppressSizeToFit: true,
        sortable: true,
        valueFormatter: (params) => `${params.data}`,
        valueGetter: (params) => params,
        cellRenderer: UserChipsCellRenderer,
    },
    {
        colId: 'sso',
        field: 'ssoEnabled',
        headerName: i18n.t('TABLE.HEADERS.SSO', { ns: I18nTranslationKey }),
        width: 100,
        suppressSizeToFit: true,
        sortable: true,
        enableRowGroup: true,
        valueGetter: (params) => params.data?.ssoEnabled,
        valueFormatter: (params) => `${params.value}` || '',
        cellRenderer: DotCellRenderer,
    },
    {
        colId: 'mfa',
        field: 'mfaEnforcement',
        headerName: i18n.t('TABLE.HEADERS.MFA', { ns: I18nTranslationKey }),
        width: 100,
        suppressSizeToFit: true,
        sortable: true,
        enableRowGroup: true,
        valueGetter: (params) => params.data?.mfaEnforcement,
        valueFormatter: (params) => `${params.value}` || '',
        cellRenderer: DotCellRenderer,
    },
    {
        colId: 'apiKeys',
        field: 'apiKeys',
        headerName: i18n.t('TABLE.HEADERS.API_KEY', { ns: I18nTranslationKey }),
        width: 100,
        suppressSizeToFit: true,
        sortable: true,
        enableRowGroup: true,
        valueGetter: (params) => params.data?.hasApiKey,
        valueFormatter: (params) => `${params.value}` || '',
        cellRenderer: DotCellRenderer,
    },
    {
        colId: 'isMobileDevicePaired',
        field: 'isMobileDevicePaired',
        headerName: i18n.t('TABLE.HEADERS.MOBILE_PAIRED', { ns: I18nTranslationKey }),
        width: 150,
        suppressSizeToFit: true,
        sortable: true,
        enableRowGroup: true,
        valueGetter: (params) => params.data?.isMobileDevicePaired,
        valueFormatter: (params) => `${params.value}` || '',
        cellRenderer: DotCellRenderer,
    },
    {
        colId: 'lastLogin',
        field: 'lastLogin',
        type: 'date',
        headerName: i18n.t('TABLE.HEADERS.LAST_LOGIN', { ns: I18nTranslationKey }),
        width: 200,
        suppressSizeToFit: true,
        sortable: true,
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
            if (valueA === NA && valueB === NA) return 0;
            if (valueA === NA) return 1;
            if (valueB === NA) return -1;
            const dateA = new Date(valueA);
            const dateB = new Date(valueB);
            return dateA.getTime() - dateB.getTime();
        },
        enableRowGroup: true,
        valueGetter: (params) => {
            const lastLogin = params.data?.lastLogin;
            if (lastLogin) {
                return new Date(lastLogin).toLocaleString();
            } else {
                return NA;
            }
        },
        valueFormatter: (params) => `${params.value}` || '',
    },
];

export default function initializeUsersColumnDefs() {
    usersTableRegistry.addColumnDefs(columnDefs());
}
