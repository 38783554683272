import {
    IProtectedAssetActionKey,
    IProtectedAssetFilter,
    IProtectedAssetFilterParams,
    IProtectedAssetsService,
} from 'common/module_interface/assets/ProtectedAssets';
import { ColDef } from 'ag-grid-enterprise';
import { AssetUrlParams, getTypeByPlatform, IAsset, UrlFuncResult } from 'common/assets/common.assets';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { getHttpService, ICachingConfig } from 'common/interface/services';
import { toQueryString } from 'common/utils/http';
import {
    IAssetUrlRequiredProps,
    IProtectedAssetViewModel,
    ProtectedAssetsResponse,
    RequestFilter,
} from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { ProtectedAssetsTableRegistry } from 'common/module_interface/assets/ProtectedAssetsTableRegistry';
import { AggregationLevel, Datasource } from 'common/components/ProtectedAssets/datasource';
import { CounterStatus } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import { ProtectedAssetsWidgetRegistry } from './Widgets/filters';
import { IColumnUsageDef } from 'common/interface/general';
import { mergeColumnDefs } from 'common/utils/tableUtils';
import { ASSETS_SEARCH_URL } from 'common/module_interface/assets/AssetsConsts';
import { getSavedFilterDefs } from 'common/utils/filterUtils';
import { getAssets } from 'common/assets/Assets.service';
import { getVendorBySrl } from 'common/consts/vendors';
import { AxiosError } from 'axios';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import { isListAssetType } from 'common/module_interface/assets/utils';

export class ProtectedAssetsService implements IProtectedAssetsService {
    getColumnDefs(columnUsages: IColumnUsageDef[]): ColDef[] {
        return mergeColumnDefs(columnUsages, ProtectedAssetsTableRegistry.getColumnDefs());
    }

    getFilterDefs(filters: IProtectedAssetFilter[], params: IProtectedAssetFilterParams): IFilterProps[] {
        return getSavedFilterDefs(filters, params, ProtectedAssetsTableRegistry.getFilter);
    }

    getFilterDefsForWidgets(filters: IProtectedAssetFilter[], params: IProtectedAssetFilterParams): IFilterProps[] {
        const result: IFilterProps[] = [];
        filters.forEach((filter) => {
            const matchedFilterDef = ProtectedAssetsWidgetRegistry.getFilter(filter.id, [params]);
            if (matchedFilterDef) {
                const filterDef = { ...matchedFilterDef };
                filterDef.filterProps = { ...filterDef.filterProps, ...filter.filterProps };
                result.push(filterDef);
            }
        });
        return result;
    }

    getActionsDefs(actions: IProtectedAssetActionKey[]): ITableAction[] {
        const actionsDefs = ProtectedAssetsTableRegistry.getActions();
        return actions.reduce((acc: ITableAction[], actionKey) => {
            const matchedActionDef = actionsDefs.find((actionDef) => actionKey.id === actionDef.id);
            if (matchedActionDef) {
                acc.push(matchedActionDef);
            }
            return acc;
        }, []);
    }

    getAssetByType(type: string): IAsset | null {
        if (type) {
            const assets = getAssets();
            const isListType = isListAssetType(type);
            let fixedAssetType = type;
            if (isListType) {
                fixedAssetType = fixedAssetType.replace(/list</i, '').replace(/>/, '');
            }
            let asset =
                assets.find(
                    (asset: IAsset) =>
                        asset.typeByPlatform.toLowerCase() === fixedAssetType.toLowerCase() ||
                        asset.type.toLowerCase() === fixedAssetType.toLowerCase(),
                ) ?? null;
            if (asset && isListType) {
                asset = { ...asset };
                asset.displayName = `List | ${asset.displayName}`;
            }
            return asset;
        }
        return null;
    }

    getAssetByTypeNumber(typeNumberAsString: string): IAsset | null {
        const assets = getAssets() as IAsset[];
        return assets.find((asset: IAsset) => asset.typeNumber?.toString() === typeNumberAsString) ?? null;
    }

    getAssetIconByDisplayName(assetDisplayName: string): string | undefined {
        const assets = getAssets() as IAsset[];
        const asset = assets.find((asset: IAsset) => asset.displayName === assetDisplayName);
        return asset?.icon;
    }

    getProtectedAssetUrlByProps(urlProps: IAssetUrlRequiredProps): string | null {
        const { entityId, platform, dome9Id, type, typeByPlatform, cloudAccountId, region, generateUrl } = urlProps;
        const typeByPlat = typeByPlatform || getTypeByPlatform(platform, type);
        const assetTypeDetails = this.getAssetByType(typeByPlat);
        if (assetTypeDetails === null) {
            return null;
        }
        const urlParams: AssetUrlParams = {
            asset: assetTypeDetails,
            entityId,
            cloudAccountId,
            dome9Id,
            region,
            platform,
            entityType: type,
            typeNumber: assetTypeDetails?.typeNumber,
        };
        const assetUrl = assetTypeDetails.getUrl(urlParams) ?? null;
        if (assetUrl === null) {
            return null;
        }

        return generateUrl
            ? generateUrl(assetTypeDetails, assetUrl)
            : `${assetUrl.url}?${toQueryString({ query: assetUrl.query })}`;
    }

    getProtectedAssetUrl(
        assetViewModel: IProtectedAssetViewModel & {
            generateUrl?: (asset: IAsset, assetUrl: UrlFuncResult) => string;
        },
    ): string | null {
        if (!assetViewModel) {
            return null;
        }
        const { entityId, platform, id, typeByPlatform, type, cloudAccountId, region, generateUrl } = assetViewModel;
        const urlProps: IAssetUrlRequiredProps = {
            entityId,
            platform,
            type,
            typeByPlatform,
            cloudAccountId,
            region,
            dome9Id: id,
            generateUrl,
        };
        return this.getProtectedAssetUrlByProps(urlProps);
    }

    getProtectedAssetById(
        id: string,
        typeByPlatform: string,
        cloudAccountId: string,
        cachingConfig?: ICachingConfig,
        customHandleError?: (error: AxiosError<ProtectedAssetsResponse>) => ProtectedAssetsResponse,
    ): Promise<IProtectedAssetViewModel | null> {
        return getHttpService()
            .post<ProtectedAssetsResponse>(
                ASSETS_SEARCH_URL,
                {
                    data: {
                        pageSize: 1,
                        filter: {
                            fields: [{ name: 'cloudAccountId', value: cloudAccountId }],
                            includedEntityTypes: [typeByPlatform],
                            freeTextForIdsPhrase: id,
                        },
                        skipAggregations: true,
                    },
                },
                { cachingConfig },
                customHandleError,
            )
            .then((assetData) => {
                if (assetData.assets.length > 0) {
                    return assetData.assets[0];
                }
                return null;
            });
    }

    getProtectedAssetsByFilter(
        filter: RequestFilter,
        cachingConfig?: ICachingConfig,
        customHandleError?: (error: AxiosError<ProtectedAssetsResponse>) => ProtectedAssetsResponse,
    ): Promise<IProtectedAssetViewModel[]> {
        return getHttpService()
            .post<ProtectedAssetsResponse>(
                ASSETS_SEARCH_URL,
                {
                    data: {
                        pageSize: 1000,
                        filter: filter,
                        skipAggregations: true,
                    },
                },
                { cachingConfig },
                customHandleError,
            )
            .then((assetData) => {
                return assetData.assets ?? [];
            });
    }

    async getProtectedAssetBySrl(assetSrl: string): Promise<IProtectedAssetViewModel | null> {
        const dataSource = new Datasource({
            pageSize: 1,
            filters: {
                fields: [
                    {
                        Name: 'srl',
                        Value: assetSrl,
                    },
                ],
            },
        });

        const response = await dataSource.getAdHokDataFromServer(AggregationLevel.NONE, 1);
        if (response.totalCount === CounterStatus.Error || response.totalCount === 0) {
            return null;
        }
        return response.assets[0];
    }

    getProtectedAssetByTypeAndDome9Id(type: string, dome9Id: string | null): IAsset | null {
        // An example for dome9Id: "1|593f0462-48b8-4f64-baa5-b70901fe56db|rg|s3Bucket|d9d9permissionsbucket6-301"
        const parts = dome9Id?.match(/^([0-9]+)[|]/);
        if (!parts || parts.length < 2) {
            return null;
        }
        const srl = parts[1];
        const vendor = getVendorBySrl(srl);
        if (!vendor) {
            return null;
        }
        const typeByPlatform = vendor.name + '|' + type;
        return this.getAssetByType(typeByPlatform);
    }
}
