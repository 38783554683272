import { Command } from 'common/design-system/components-v2';
import { PropsWithChildren } from 'react';
import { InlineCode } from './SmartCodeSnippet.styles';
import { SmartCodeSnippetProps } from './SmartCodeSnippet.types';

const SmartCodeSnippet: React.FC<PropsWithChildren<SmartCodeSnippetProps>> = ({ children, node }) => {
    const isBlocked = node?.position && node.position.start?.line !== node.position.end?.line;
    return isBlocked ? (
        <Command text={children?.toString() || ''}>{children}</Command>
    ) : (
        <InlineCode>{children}</InlineCode>
    );
};

export default SmartCodeSnippet;
