import { EnvironmentsTableRegistry } from 'common/module_interface/assets/EnvironmentsTableRegistry';
import { IEnvironmentsAction } from 'common/module_interface/assets/Environments';
import { Addin } from 'common/extensibility/AddinRegistry';
import AddEnvironmentAction from './Actions/AddEnvironment';
import i18n from 'common/services/translations/translations';
import { getK8sNamespace } from '../initialize.i18n';
import { Vendors } from 'common/consts/vendors';

export const initializeEnvironmentsActionsDefs = () => {
    const tabActions: Addin<IEnvironmentsAction>[] = [
        {
            id: 'add-new-environment-kubernetes',
            position: 0,
            content: {
                id: 'add-new-environment-kubernetes',
                name: i18n.t('ENVIRONMENTS_TABLE.TABLE_ACTIONS.ADD.TITLE', { ns: getK8sNamespace('common') }),
                buttonProps: {
                    iconProps: { name: 'plus' },
                },
                callback: () => null,
                customComponent: (params) => <AddEnvironmentAction {...params} type={Vendors.KUBERNETES} />,
            },
        },
        {
            id: 'add-new-environment-container-registries',
            position: 0,
            content: {
                id: 'add-new-environment-container-registries',
                name: i18n.t('ENVIRONMENTS_TABLE.TABLE_ACTIONS.ADD.TITLE', { ns: getK8sNamespace('common') }),
                buttonProps: {
                    iconProps: { name: 'plus' },
                },
                callback: () => null,
                customComponent: () => <AddEnvironmentAction type={Vendors.CONTAINER_REGISTRY} />,
            },
        },
        {
            id: 'add-new-environment-shiftleft',
            position: 0,
            content: {
                id: 'add-new-environment-shiftleft',
                name: i18n.t('ENVIRONMENTS_TABLE.TABLE_ACTIONS.ADD.TITLE', { ns: getK8sNamespace('common') }),
                buttonProps: {
                    iconProps: { name: 'plus' },
                },
                callback: () => null,
                customComponent: () => <AddEnvironmentAction type={Vendors.SHIFT_LEFT} />,
            },
        },
    ];
    EnvironmentsTableRegistry.addActions(tabActions);
};
