import { UserRolesTypes } from 'common/enum/UserRoles';
import { ActiveFeaturesTypes } from 'common/enum/ActiveFeatures';
import { addMainMenuItem } from 'common/helpers/menu';
import {
    getAppRootRegistry,
    getUserService,
    IRulesetService,
    RULESET_SERVICE_ID,
    SHIFTLEFT_SERVICE_ID,
} from 'common/interface/services';
import { getService, globalAddinContainer } from 'common/extensibility/AddinContainer';
import {
    ENVIRONMENT_POLICY_RULESETS,
    IShallowRuleset,
    IVendorRuleset,
    OU_POLICY_RULESETS,
    POLICY_ENVIRONMENT_VENDORS,
    POLICY_RULESETS,
    POLICY_SERVICE,
    Vendor,
} from 'common/interface/policy';
import { Vendors } from 'common/consts/vendors';
import { ShiftleftPolicyService } from './policy/ShiftleftPolicyService';
import { lazy } from 'react';

function initializeMenuItems() {
    const menuItem = {
        id: 'shiftleft',
        position: 100,
        icon: 'shiftleft',
        label: 'NAVIGATION_MENU.SHIFTLEFT.TITLE',
        isPreview: true,
        permission: [ActiveFeaturesTypes.SHIFTLEFT],
        descriptionKeys: ['NAVIGATION_MENU.SHIFTLEFT.DESCRIPTION.1'],
        sections: [
            {
                id: 'default',
                position: 10,
                items: [
                    {
                        id: 'welcome',
                        position: 10,
                        label: 'NAVIGATION_MENU.SHIFTLEFT.WELCOME',
                        state: '/shiftLeft/welcome',
                        permission: [UserRolesTypes.ALL],
                    },
                ],
            },
            {
                id: 'assets',
                position: 20,
                items: [
                    {
                        id: 'assets',
                        position: 10,
                        label: 'NAVIGATION_MENU.SHIFTLEFT.ITEMS.ASSETS',
                        state: '/shiftleft/assets',
                        permission: [UserRolesTypes.ALL],
                    },
                ],
            },
            {
                id: 'assessments results',
                position: 30,
                title: 'NAVIGATION_MENU.SHIFTLEFT.ASSESSMENTS_RESULTS',
                items: [
                    {
                        id: 'findings',
                        position: 10,
                        label: 'NAVIGATION_MENU.SHIFTLEFT.ITEMS.FINDINGS',
                        state: '/shiftleft/findings',
                        permission: [UserRolesTypes.ALL],
                    },
                    {
                        id: 'history',
                        position: 20,
                        label: 'NAVIGATION_MENU.SHIFTLEFT.ITEMS.HISTORY',
                        state: '/shiftleft/history',
                        permission: [UserRolesTypes.ALL],
                    },
                ],
            },
            {
                id: 'policies',
                position: 40,
                title: 'NAVIGATION_MENU.SHIFTLEFT.POLICIES',
                items: [
                    {
                        id: 'rulesets',
                        position: 10,
                        label: 'NAVIGATION_MENU.SHIFTLEFT.ITEMS.RULESETS',
                        state: '/shiftleft/rulesets',
                        permission: [UserRolesTypes.ALL],
                    },
                    {
                        id: 'policies',
                        position: 20,
                        label: 'NAVIGATION_MENU.SHIFTLEFT.ITEMS.POLICIES',
                        state: '/shiftleft/policies',
                        permission: [UserRolesTypes.ALL],
                    },
                    {
                        id: 'exclusions',
                        position: 30,
                        label: 'NAVIGATION_MENU.SHIFTLEFT.ITEMS.EXCLUSIONS',
                        state: '/shiftleft/exclusions',
                        permission: [UserRolesTypes.ALL],
                    },
                ],
            },
            {
                id: 'other',
                position: 50,
                items: [
                    {
                        id: 'gsl builder',
                        position: 10,
                        label: 'NAVIGATION_MENU.SHIFTLEFT.ITEMS.GSL_BUILDER',
                        state: '/shiftleft/gsl-builder',
                        permission: [UserRolesTypes.ALL],
                    },
                    {
                        id: 'cli',
                        position: 20,
                        label: 'NAVIGATION_MENU.SHIFTLEFT.ITEMS.CLI',
                        state: '/shiftleft/onboarding-shiftleft',
                        permission: [UserRolesTypes.ALL],
                    },
                ],
            },
        ],
    };

    addMainMenuItem(menuItem);
}

const initClassicShiftLeft = () => {
    const SHIFTLLEFT_DEFAULT_PATH = '/shiftleft/onboarding-shiftleft';
    const SHIFTLLEFT_PAGE_REDIRECT_PATH = '/shiftleft-redirect';
    const SHIFTLLEFT_WELCOME_PAGE_PATH = '/shiftLeft/welcome';

    const WELCOME_PAGE = () => {
        return {
            condition: true,
            component: lazy(() => import('./components/WelcomePage')),
            path: SHIFTLLEFT_WELCOME_PAGE_PATH,
        };
    };
    const shiftLeftPageRedirect = () => {
        return {
            exact: true,
            condition: getUserService().hasPermission([ActiveFeaturesTypes.SHIFTLEFT]),
            component: lazy(() => import('common/components/RedirectComponent/RedirectComponent')),
            componentProps: { to: SHIFTLLEFT_DEFAULT_PATH, ignorePreviousSearchParams: true },
            path: SHIFTLLEFT_PAGE_REDIRECT_PATH,
        };
    };

    getAppRootRegistry().addRoutes([
        { id: 'SHIFT_LEFT', content: WELCOME_PAGE },
        {
            id: 'shiftLeftPageRidirect',
            content: shiftLeftPageRedirect,
        },
    ]);
};

const vendors: Vendor[] = [
    {
        id: Vendors.SHIFT_LEFT,
        name: Vendors.SHIFT_LEFT,
        icon: 'shiftleft',
        title: 'ShiftLeft',
    },
];

const getVendorsRulesets = () => {
    const getRulesets = async (vendor?: Vendors) => {
        let rulesets = await getService<IRulesetService>(RULESET_SERVICE_ID).getAllRulesets();
        rulesets = rulesets.map((ruleset) => {
            return {
                ...ruleset,
                cloudVendor: ruleset.cloudVendor,
                availableVersions: [...ruleset.availableVersions, { version: 'Latest' }],
            };
        });
        const shallowRulesets: IShallowRuleset[] = rulesets.map((ruleset) => {
            return {
                id: ruleset.id.toString(),
                name: ruleset.name,
                icon: ruleset.cloudVendor,
                description: ruleset.description,
                availableVersions: ruleset.availableVersions,
                platform: ruleset.cloudVendor,
            };
        });
        if (vendor) {
            return shallowRulesets.filter((ruleset: IShallowRuleset) => ruleset.platform === vendor);
        } else {
            return shallowRulesets;
        }
    };

    const getCShiftLeftRulesets = async () => {
        const shallowRulesets = await getRulesets();
        return shallowRulesets.filter(
            (ruleset: IShallowRuleset) =>
                ruleset.platform === Vendors.SHIFT_LEFT || ruleset.platform === Vendors.IMAGE_ASSURANCE,
        );
    };

    const vendorsRulesetsList: IVendorRuleset[] = [];
    for (const vendor of vendors) {
        switch (vendor.id) {
            case Vendors.SHIFT_LEFT:
                vendorsRulesetsList.push({ vendorId: vendor.id, getRulesets: () => getCShiftLeftRulesets() });
                break;
            default:
                return [];
        }
    }
    return vendorsRulesetsList;
};

const vendorRulesetsList = getVendorsRulesets();

const getMappedRulesetItems = (rulesetsList: IVendorRuleset[]) => {
    return [
        ...rulesetsList.map((item: IVendorRuleset) => ({
            id: item.vendorId,
            content: {
                getRulesets: item.getRulesets,
                vendorId: item.vendorId,
            },
        })),
    ];
};
export const initializePolicy = () => {
    const SHIFTLEFT = 'ShiftLeft';
    globalAddinContainer.add(POLICY_ENVIRONMENT_VENDORS, [
        ...vendors.map((vendor) => ({ id: vendor.id, content: vendor })),
    ]);
    globalAddinContainer.add(POLICY_RULESETS, getMappedRulesetItems(vendorRulesetsList));
    globalAddinContainer.add(ENVIRONMENT_POLICY_RULESETS, getMappedRulesetItems(vendorRulesetsList));
    globalAddinContainer.add(OU_POLICY_RULESETS, getMappedRulesetItems(vendorRulesetsList));
    const policyEnvironmentPageRoute = {
        condition: true,
        component: lazy(() => import('./policy/AddEnvironmentPolicyPage')),
        path: '/shiftleft/policy/environments/add',
    };
    const policyOUPageRoute = {
        condition: true,
        component: lazy(() => import('./policy/AddOUPolicyPage')),
        path: '/shiftleft/policy/ou/add',
    };
    const policyEditPageRoute = {
        condition: true,
        component: lazy(() => import('./policy/EditShiftleftPolicyPage')),
        path: '/shiftleft/policy/edit',
    };
    getAppRootRegistry().addRoutes([
        { id: 'shiftleft-environment-policy', content: policyEnvironmentPageRoute },
        { id: 'shiftleft-environment-policy-edit', content: policyEditPageRoute },
        { id: 'shiftleft-ou-policy', content: policyOUPageRoute },
    ]);
    globalAddinContainer.addService(SHIFTLEFT_SERVICE_ID, new ShiftleftPolicyService());
    globalAddinContainer.add(POLICY_SERVICE, [
        { id: SHIFTLEFT, content: { moduleName: SHIFTLEFT, service: new ShiftleftPolicyService() } },
    ]);
};

export default function initializeShiftLeft() {
    initializeMenuItems();
    initClassicShiftLeft();
    initializePolicy();
}
