import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Stack, Typography } from 'common/design-system/components-v2';
import { I18nTranslationKey } from '../initServiceAccountsPage';

interface ISucceededToCreateServiceAccountContent {
    apiKeyId?: string;
    apiKeySecret?: string;
}

export const SucceededToCreateServiceAccountContent: FC<ISucceededToCreateServiceAccountContent> = ({
    apiKeyId,
    apiKeySecret,
}) => {
    const { t } = useTranslation(I18nTranslationKey);

    const handleCopyApiKeyId = () => {
        apiKeyId && void navigator.clipboard.writeText(apiKeyId);
    };

    const handleCopyApiKeySecret = () => {
        apiKeySecret && void navigator.clipboard.writeText(apiKeySecret);
    };

    return (
        <Stack padding={[0, 5]}>
            <Alert type={'info'}>
                <Stack spacing={3}>
                    <Typography color={'strong'} variant={'sm'}>
                        {t('MODALS.NEW_SERVICE_ACCOUNT_DETAILS.IMPORTANT')}
                    </Typography>
                    <Typography variant={'sm'}>{t('MODALS.NEW_SERVICE_ACCOUNT_DETAILS.IMPORTANT_MESSAGE')}</Typography>
                </Stack>
            </Alert>
            <Stack padding={[5]} spacing={4} direction={'row'}>
                <Stack spacing={1} justifyContent={'space-around'}>
                    <Typography color={'strong'} variant={'sm'}>
                        {t('MODALS.CREATE_SERVICE_ACCOUNT_MODAL.API_KEY_ID')}
                    </Typography>
                    <Typography color={'strong'} variant={'sm'}>
                        {t('MODALS.CREATE_SERVICE_ACCOUNT_MODAL.API_KEY_SECRET')}
                    </Typography>
                </Stack>
                <Stack spacing={1} justifyContent={'space-around'}>
                    <Typography variant={'sm'}>{apiKeyId}</Typography>
                    <Typography variant={'sm'}>{apiKeySecret}</Typography>
                </Stack>
                <Stack spacing={1} justifyContent={'space-around'}>
                    <Button
                        dataAid={'copy-api-key-id-button'}
                        color='normal'
                        variant={'text'}
                        iconProps={{ name: 'copy' }}
                        onClick={handleCopyApiKeyId}
                    />
                    <Button
                        dataAid={'copy-api-key-secret-button'}
                        color='normal'
                        variant={'text'}
                        iconProps={{ name: 'copy' }}
                        onClick={handleCopyApiKeySecret}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};
