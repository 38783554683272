import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

export const ICON_WRAPPER_BG = '#f5f6f9';
const TopDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

const TopTableDiv = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    overflow: auto;
    flex-grow: 1;
    margin-bottom: 16px;

    .ag-theme-alpine.ag-theme-alpine.ag-theme-alpine {
        .ag-row:has(.ag-details-row) {
            overflow: visible;
            padding-left: 50px;

            &.ag-row-hover {
                background-color: white;
            }

            .ag-details-row {
                padding: 20px 0 17px;
                width: 100%;
                background-color: white;
                overflow: auto;

                &:hover {
                    background-color: white;
                }

                .ag-details-grid {
                    border: 1px solid #dbe0ea;
                    padding: 5px;
                    border-radius: 12px;
                }
            }
        }

        .packages-table-header {
            background-color: white;
        }

        .ag-header-viewport {
            background-color: white;
        }
    }
`;

const SeveritiesCountersDiv = styled.div`
    display: flex;
    gap: 8px;
`;

const NoteDiv = styled(Stack)`
    padding-left: 24px;
    height: 30px;
`;

const SpinnerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 250px;
    padding-bottom: 20px;
    width: 100%;
`;

export const VulnerabilitiesStyled = {
    TopDiv,
    TopTableDiv,
    SeveritiesCountersDiv,
    NoteDiv,
    SpinnerDiv,
};
