import i18n from 'common/services/translations/translations';
import { EnvironmentTab, EnvironmentsTableRegistry } from 'common/module_interface/assets/EnvironmentsTableRegistry';
import { getEnvsNamespace } from '../initialize.i18n';
import { Vendors } from 'common/consts/vendors';

const tabsDefs: () => EnvironmentTab[] = () => [
    {
        id: 'all',
        label: i18n.t('TABLE_TABS.ALL', { ns: getEnvsNamespace('table') }),
        actions: ['add-new-environment', 'validatePermissions', 'validateAllPermissions', 'associateToOU'],
        columns: [
            { id: 'checkbox' },
            { id: 'risk-score' },
            { id: 'name' },
            { id: 'description' },
            { id: 'organizationalUnit' },
            { id: 'platform' },
            { id: 'status' },
            { id: 'onboardingTime' },
            { id: 'isCloudbotsOnboarded' },
            { id: 'isLogicGuardDutyOnboarded' },
            { id: 'isLogicEventActivityOnboarded' },
            { id: 'isLogicTrafficActivityOnboarded' },
            { id: 'iamSafety' },
            { id: 'serverlessEnabled' },
            { id: 'agentlessEnabled' },
            { id: 'agentlessScanMode' },
            { id: 'agentlessCentralizedAccountId' },
        ],
        filters: [
            'organizational unit',
            'platform',
            'customData|status',
            'customData|riskLevel',
            'customData|iamSafety',
            'customData|intelligenceEnabled',
            'customData|agentlessEnabled',
            'customData|agentlessScanMode',
            'customData|serverlessEnabled',
        ],
        exportOptions: ['export-all', 'export-filtered'],
    },
    {
        id: 'cloud-accounts',
        label: i18n.t('TABLE_TABS.CLOUD_ACCOUNTS', { ns: getEnvsNamespace('table') }),
        defaultFilters: {
            fields: [
                {
                    name: 'platform',
                    value: Vendors.AWS,
                },
                {
                    name: 'platform',
                    value: Vendors.AZURE,
                },
                {
                    name: 'platform',
                    value: Vendors.GOOGLE,
                },
                {
                    name: 'platform',
                    value: Vendors.OCI,
                },
                {
                    name: 'platform',
                    value: Vendors.ALIBABA,
                },
            ],
        },
        actions: ['add-new-environment-cloud-accounts', 'validatePermissions', 'associateToOU'],
        columns: [
            { id: 'checkbox' },
            { id: 'risk-score' },
            { id: 'name' },
            { id: 'description' },
            { id: 'organizationalUnit' },
            { id: 'platform' },
            { id: 'status' },
            { id: 'onboardingTime' },
            { id: 'isCloudbotsOnboarded' },
            { id: 'isLogicGuardDutyOnboarded' },
            { id: 'isLogicEventActivityOnboarded' },
            { id: 'isLogicTrafficActivityOnboarded' },
            { id: 'iamSafety' },
            { id: 'serverlessEnabled' },
            { id: 'agentlessEnabled' },
        ],
        filters: [
            'organizational unit',
            'platform',
            'customData|status',
            'customData|riskLevel',
            'customData|iamSafety',
            'customData|intelligenceEnabled',
            'customData|agentlessEnabled',
            'customData|serverlessEnabled',
        ],
        exportOptions: ['export-all', 'export-filtered'],
    },
];

export default function initializeTabsDefs() {
    EnvironmentsTableRegistry.addTabs(tabsDefs());
}
