import { IItemsMap } from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import {
    IMalwareIgnoreItem,
    IMalwareIgnoreListService,
    IMalwareIgnorePartial,
    IMalwareIgnoreUpdate,
} from './MalwareIgnoreList.interface';
import { getMalwareIgnoreItemFromValues } from './MalwareIgnoreList.values';
import { getService } from 'common/extensibility/AddinContainer';
import { MALWARE_IGNORE_LIST_SERVICE_ID } from './MalwareIgnoreList.consts';

export const saveIgnoreItem = async (itemsMap: IItemsMap, origIgnoreItem?: IMalwareIgnorePartial): Promise<void> => {
    const ignoreItem: IMalwareIgnoreUpdate = getMalwareIgnoreItemFromValues(itemsMap);
    if (origIgnoreItem?.id) {
        await getMalwareIgnoreListService().updateIgnoreItem(origIgnoreItem.id, ignoreItem);
    } else {
        await getMalwareIgnoreListService().createIgnoreItem(ignoreItem);
    }
};

export function getMalwareIgnoreListService(): IMalwareIgnoreListService {
    return getService<IMalwareIgnoreListService>(MALWARE_IGNORE_LIST_SERVICE_ID);
}

export const fetchAllMalwareIgnoreItems = async (): Promise<IMalwareIgnoreItem[]> => {
    return getMalwareIgnoreListService().getAllIgnoreItems();
};

export const deleteMalwareIgnoreListItems = async (items: IMalwareIgnoreItem[]): Promise<void> => {
    const ids = items.map((item) => item.id);
    await getMalwareIgnoreListService().deleteIgnoreItems(ids);
};
