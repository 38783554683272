import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Button, Stack } from 'common/design-system/components-v2';
import { ermTrans } from '../../../RiskManagement.utils';
import { IMalware } from '../IssueViewer/IssueViewerPanels/IssueEvidencePanel/Malware/Malware.interface';
import { createIgnoreItemForMalware } from '../../IgnoreList/MalwareIgnoreList/MalwareIgnoreList.items';

export const IgnoreMalwareActionRenderer: React.FC<ICellRendererParams<IMalware>> = (params) => {
    const malwareInfo: IMalware | undefined = params.data;
    if (!malwareInfo) {
        return null;
    }

    return (
        <Stack justifyContent={'center'} alignItems={'center'}>
            <Button
                variant='contained'
                tooltip={ermTrans('IGNORE_LIST.MALWARE_IGNORE_LIST.ACTIONS.IGNORE_TOOLTIP')}
                onClick={() => createIgnoreItemForMalware(malwareInfo)}
            >
                {ermTrans('IGNORE_LIST.MALWARE_IGNORE_LIST.ACTIONS.IGNORE')}
            </Button>
        </Stack>
    );
};
