import { ICellRendererParams } from 'ag-grid-community';
import {
    IIdenticalIdentitiesTreeNode,
    IPermissionInfo,
} from 'common/module_interface/identity/IdenticalRoles.interfaces';
import React, { RefObject, useState } from 'react';
import { Icon, Popover, Stack } from 'common/design-system/components-v2';
import PermissionsTable from '../PermissionsTable/PermissionsTable';

export const PermissionsCellRenderer: React.FC<ICellRendererParams<IIdenticalIdentitiesTreeNode>> = (params) => {
    const [open, setOpen] = useState<boolean>(false);
    const ref: RefObject<HTMLDivElement> = React.useRef<HTMLDivElement>(null);

    const toggleOpenState = () => {
        setOpen((current) => !current);
    };

    const permissions: IPermissionInfo[] | undefined = params.data?.permissions;
    if (!permissions) return null;

    return (
        <Stack alignItems={'flex-start'}>
            <Stack ref={ref} onClick={toggleOpenState}>
                <Icon name={'listView'} cursor={'pointer'}></Icon>
            </Stack>
            <Popover open={open} maxHeight={280} anchorEl={ref.current} placement={'top-end'} onClose={toggleOpenState}>
                <PermissionsTable permissions={permissions} />
            </Popover>
        </Stack>
    );
};
