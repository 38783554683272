import React, { useEffect, useState } from 'react';
import { initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { getHttpService, ICustomzationResponse } from 'common/interface/services';
import { useTranslation } from 'react-i18next';
import { FILTER_EVENTS } from 'common/components/FilterPanel/FilterPanel.consts';
import { IGenericFilterPanelProps } from './interfaces';

const SimpleGenericFilterPanel: React.FC<IGenericFilterPanelProps> = ({
    filterId,
    savedFiltersComponentName,
    tableRegistry,
    translationKey,
    filterIds,
    getAggregations,
    tableData,
    rawData,
    isLoading,
    onFilterChange,
}) => {
    const [filterElement, setFilterElement] = useState(<div></div>);
    const { t } = useTranslation(translationKey);

    const getFiltersInitialData = async () => {
        const savedFilters = await getHttpService().request<ICustomzationResponse<any>>(
            `customization?component=${savedFiltersComponentName}`,
            {
                method: 'GET',
            },
        );

        return { savedFilters };
    };

    useEffect(() => {
        const onFilterEvent = (action: string, filtersValues: IFiltersValues) => {
            if (action === FILTER_EVENTS.FILTER_CHANGED) {
                onFilterChange(filtersValues);
            }
        };

        async function renderFilterPanel() {
            const filtersInitialData = await getFiltersInitialData();
            const aggregations = await getAggregations({}, rawData);
            const filterPanelElementsList = tableRegistry.getFilterDefs(filterIds, {
                aggregations: aggregations || {},
                filtersInitialData: filtersInitialData || {},
                savedFiltersComponentName,
            });

            const filterPanel = initFilterPanel({
                filterPanelElementsList,
                getAggregations: () => getAggregations({}, rawData),
                shouldBuildObjectForAPI: false,
                filterId: filterId,
                onFilterChangeCallBack: onFilterEvent,
            });
            setFilterElement(filterPanel);
        }

        if (!isLoading) {
            renderFilterPanel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData, isLoading, t, rawData, onFilterChange]);

    return <>{filterElement}</>;
};

export default SimpleGenericFilterPanel;
