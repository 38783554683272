import { Dropdown } from 'common/design-system/components-v2';
import List from 'common/design-system/components-v2/List';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import WorkloadService from '../../../../services/workload/workload.service';
import ModalAddRuleBaseImage from '../../../../components/ModalAddRuleBaseImage/ModalAddRuleBaseImage';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { AssetTypes } from 'common/assets/assets.const';

const MenuActionsBaseImages: React.FC<{ isBaseImage: boolean; entity: ICloudEntityData }> = ({ entity }) => {
    const [open, setOpen] = React.useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const { t } = useTranslation('k8s_protected-asset-details');

    const options = [
        {
            label: t('actions.setAsBaseImage'),
            value: entity.protectedAsset.entityId,
            tooltip: 'Base Image Rule work only for Container Registry Image',
            disabled: entity.typeByPlatform !== AssetTypes.CONTAINERREGISTRY_CONTAINERREGISTRYIMAGE,
        },
    ];

    const onConfirm = async (name: string, environmentIds: Array<string>, repository: string, description: string) => {
        await WorkloadService.addBaseImageRules({
            name: name,
            environments: environmentIds,
            repository: repository,
            description: description,
        });
    };

    const buildRepository = () => {
        const { registry, repository } = entity.cloudEntity.imageDetails;
        if (!registry) {
            return repository;
        }
        const [, repoName] = registry?.split('/');
        return repoName ? `${repoName}/${repository}` : repository;
    };

    const initialValues = {
        id: '',
        repository: buildRepository(),
        environments: [entity.cloudAccountId],
        description: '',
        ruleName: '',
    };

    return (
        <>
            <Dropdown
                open={open}
                buttonProps={{ iconButton: true, iconProps: { name: 'more' } }}
                onStateChange={(state) => setOpen(state)}
            >
                <List
                    options={options}
                    onOptionClick={async () => {
                        setIsOpenModal(true);
                    }}
                />
            </Dropdown>
            {isOpenModal && (
                <ModalAddRuleBaseImage
                    isEditMode={false}
                    isModalOpen={isOpenModal}
                    initialValues={initialValues}
                    onConfirm={onConfirm}
                    handleCloseModal={() => setIsOpenModal(false)}
                />
            )}
        </>
    );
};

export default MenuActionsBaseImages;
