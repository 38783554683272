import { ICdrUrlsService, CDR_URLS_SERVICE_ID } from 'common/module_interface/intelligence/intelligence';
import { IFieldInfo } from 'common/interface/general';
import { changeUrl } from 'common/utils/http';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { EventType } from 'common/module_interface/events/EventsConsts';
import { TimeFilter } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';
import { getEventsTableWidgetService } from 'common/module_interface/events/EventsTableRegistry';

export class CdrUrlsService implements ICdrUrlsService {
    public goToEventsTable(filterFields: IFieldInfo[], timeFilter?: TimeFilter, freeTextPhrase?: string) {
        const eventsTableUrl = getEventsTableWidgetService().getMainTableLink(EventType.CDR, {
            fields: filterFields,
            freeText: freeTextPhrase,
            datePicker: timeFilter?.epoch,
        });
        changeUrl(eventsTableUrl);
    }

    public goToEnvironmentTable = (filterFields?: IFieldInfo[]) => {
        getErmUrlsService().gotoEnvironmentTable(filterFields);
    };
}

export default function initializeCdrUrlService() {
    globalAddinContainer.addService(CDR_URLS_SERVICE_ID, new CdrUrlsService());
}
