import React, { useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Button, Dropdown, Stack } from 'common/design-system/components-v2';
import { FindingsTableRegistry } from 'common/components/KustoEvents/FindingsTableRegistry';
import { IButtonProps } from 'common/design-system/components-v2/Button/Button.types';

// This component is used to render the actions in more column (column with 3 dots) in the findings table
const ActionsCellRenderer: React.FC<ICellRendererParams> = (params: ICellRendererParams) => {
    const actionsButtons: JSX.Element[] | undefined = useMemo(() => {
        if (params.node.group) return;
        return FindingsTableRegistry.getActions().map((action) => {
            let buttonProps: Partial<IButtonProps> = {};
            if (typeof action.buttonProps === 'function') {
                buttonProps = action.buttonProps(params.api.getSelectedRows());
            } else if (action.buttonProps) {
                buttonProps = action.buttonProps;
            }
            return (
                <Button
                    variant='text'
                    disabled={false}
                    key={action.id}
                    iconProps={buttonProps.iconProps}
                    onClick={() => {
                        // deselect all rows and select the current row as action applicable only for current row
                        params.api.deselectAll();
                        params.node.setSelected(true);
                        action.callback(params.api.getSelectedRows(), {
                            gridApi: params.api,
                            columnApi: params.columnApi,
                        });
                    }}
                >
                    {action.name}
                </Button>
            );
        });
    }, [params]);

    return (
        actionsButtons && (
            <Stack alignItems='center' fullWidth justifyContent='center' margin={[2, 0, 0, 0]}>
                <Dropdown
                    buttonProps={{
                        iconProps: { name: 'more', color: 'brandPrimary' },
                        iconButton: true,
                    }}
                >
                    <Stack>{actionsButtons}</Stack>
                </Dropdown>
            </Stack>
        )
    );
};

export default ActionsCellRenderer;
