import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Spinner, Stack } from 'common/design-system/components-v2';
import { getNotificationsService, getServiceAccountsService } from 'common/interface/services';
import { FailedToCreateServiceAccountContent } from './FailedToCreateServiceAccountContent';
import { SucceededToCreateServiceAccountContent } from './SucceededToCreateServiceAccountContent';
import { ServiceAccountModalContent } from './ServiceAccountModalContent';
import { IAccountsServiceItem } from 'common/interface/serviceAccountService';
import { I18nTranslationKey } from '../initServiceAccountsPage';

interface ICreateServiceAccountProps {
    isModalOpen: boolean;
    handleOpenAction?: (updateRequired: boolean) => void;
    handleCloseAction: (updateRequired: boolean) => void;
    selectedServiceAccount?: IAccountsServiceItem | null;
    editMode?: boolean;
}

export const ServiceAccountModal: FC<ICreateServiceAccountProps> = ({
    editMode = false,
    isModalOpen,
    handleCloseAction,
    selectedServiceAccount,
}) => {
    const { t } = useTranslation(I18nTranslationKey);
    const [nameInput, setNameInput] = useState<string>();
    const [selectedRoles, setSelectedRoles] = useState<string[]>();
    const [apiKeyId, setApiKeyId] = useState<string>();
    const [apiKeySecret, setApiKeySecret] = useState<string>();
    const [nameInputError, setNameInputError] = useState<string>();
    const [selectedRolesError, setSelectedRolesError] = useState<string>();
    const [generalCreateError, setGeneralCreateError] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>();
    const [createServiceAccountSuccess, setCreateServiceAccountSuccess] = useState<boolean>();

    const init = () => {
        setNameInput('');
        setSelectedRoles([]);
        setNameInputError('');
        setSelectedRolesError('');
        setGeneralCreateError(false);
        setCreateServiceAccountSuccess(false);
        setApiKeyId('');
        setApiKeySecret('');
    };

    const handleClose = (updateRequired = true) => {
        init();
        handleCloseAction(updateRequired);
    };

    const errorHandling = (error: any) => {
        const errorData = error?.response?.data || '';
        const accountNameError = t('MODALS.CREATE_SERVICE_ACCOUNT_MODAL.ACCOUNT_NAME_ERROR');
        const roleError = t('MODALS.CREATE_SERVICE_ACCOUNT_MODAL.ROLE_ERROR');
        switch (true) {
            case errorData.includes(accountNameError): {
                const errorMessage =
                    t('MODALS.CREATE_SERVICE_ACCOUNT_MODAL.NAME_ALREADY_EXISTS_1') +
                    nameInput +
                    t('MODALS.CREATE_SERVICE_ACCOUNT_MODAL.NAME_ALREADY_EXISTS_2');
                setNameInputError(errorMessage);
                break;
            }
            case errorData.includes(roleError): {
                setSelectedRolesError(t('MODALS.CREATE_SERVICE_ACCOUNT_MODAL.ROLE_IS_REQUIRED'));
                break;
            }
            default: {
                setGeneralCreateError(true);
                break;
            }
        }
    };

    const handleCreate = async () => {
        setNameInputError('');
        setSelectedRolesError('');
        setLoading(true);
        try {
            const formattedRoles = selectedRoles?.map((role) => Number(role));
            const resp = await getServiceAccountsService().addServiceAccount(nameInput, formattedRoles);
            setApiKeyId(resp.apiKeyId);
            setApiKeySecret(resp.apiKeySecret);
            setCreateServiceAccountSuccess(true);
            getNotificationsService().success(
                t('MODALS.NEW_SERVICE_ACCOUNT_DETAILS.SERVICE_ACCOUNT'),
                t('MODALS.NEW_SERVICE_ACCOUNT_DETAILS.CREATED_SUCCESSFULLY'),
            );
        } catch (e: any) {
            errorHandling(e);
        } finally {
            setLoading(false);
        }
    };

    const handleUpdate = async () => {
        setNameInputError('');
        setSelectedRolesError('');
        setLoading(true);
        const payload = {
            name: nameInput,
            roleIds: selectedRoles?.map((role) => Number(role)),
            dateCreated: selectedServiceAccount?.dateCreated,
            apiKeyId: selectedServiceAccount?.apiKeyId,
            lastUsed: selectedServiceAccount?.lastUsed,
            id: selectedServiceAccount?.id,
            actions: [{ text: 'Edit' }, { text: 'Delete' }],
        };
        try {
            await getServiceAccountsService().editServiceAccounts(payload);
            getNotificationsService().success(
                t('MODALS.NEW_SERVICE_ACCOUNT_DETAILS.SERVICE_ACCOUNT'),
                t('MODALS.NEW_SERVICE_ACCOUNT_DETAILS.UPDATED_SUCCESSFULLY'),
            );
            handleCloseAction(true);
        } catch (e: any) {
            errorHandling(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setNameInputError('');
    }, [nameInput]);

    useEffect(() => {
        setSelectedRolesError('');
    }, [selectedRoles]);

    useEffect(() => {
        init();
    }, [isModalOpen, selectedServiceAccount]);

    return (
        <Modal.ModalDialog width={'lg'} isOpen={isModalOpen}>
            <Modal.ModalHeader
                onClose={handleClose}
                title={
                    editMode
                        ? t('MODALS.CREATE_SERVICE_ACCOUNT_MODAL.EDIT_TITLE')
                        : t('MODALS.CREATE_SERVICE_ACCOUNT_MODAL.TITLE')
                }
            />
            <Modal.ModalContent>
                {!generalCreateError ? (
                    <ServiceAccountModalContent
                        nameInput={nameInput}
                        setNameInput={setNameInput}
                        selectedRoles={selectedRoles}
                        setSelectedRoles={setSelectedRoles}
                        nameInputError={nameInputError}
                        selectedRolesError={selectedRolesError}
                        selectedServiceAccount={selectedServiceAccount}
                    />
                ) : (
                    <FailedToCreateServiceAccountContent />
                )}
                {!generalCreateError && createServiceAccountSuccess && (
                    <SucceededToCreateServiceAccountContent apiKeyId={apiKeyId} apiKeySecret={apiKeySecret} />
                )}
            </Modal.ModalContent>
            <Modal.ModalFooter>
                <Stack direction={'row'} spacing={3} justifyContent={'flex-end'} fullWidth>
                    <Button dataAid={'cancel-service-account-button'} variant='text' onClick={() => handleClose()}>
                        {createServiceAccountSuccess || generalCreateError ? t('GENERAL.CLOSE') : t('GENERAL.CANCEL')}
                    </Button>
                    {!createServiceAccountSuccess && !generalCreateError && (
                        <Button
                            dataAid={'create-service-account-button'}
                            color={'brandPrimary'}
                            onClick={selectedServiceAccount ? handleUpdate : handleCreate}
                            disabled={!nameInput || !selectedRoles?.length}
                        >
                            {loading ? (
                                <Spinner size={12} />
                            ) : selectedServiceAccount ? (
                                t('GENERAL.SAVE')
                            ) : (
                                t('GENERAL.CREATE')
                            )}
                        </Button>
                    )}
                </Stack>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    );
};
