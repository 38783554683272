import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { TFunction } from 'i18next';
import { TResultSuccess } from './AwpHostConfigRuleDrawer.types';
import { formatDate } from 'common/utils/helpFunctions';

export const getEntityBlock = (data: TResultSuccess['data'], t: TFunction): BlockInfoProps[] => {
    return [
        {
            title: t('provider'),
            info: t(`providers.${data.provider}`),
            copyable: true,
            dataAid: 'upperPanelProvider',
        },
        {
            title: t('categories'),
            info: data.rule.categories.split('.').join(', '),
            copyable: true,
            dataAid: 'upperPanelCategories',
        },
        {
            title: t('scanDate'),
            info: formatDate(data.scanDate),
            copyable: true,
            dataAid: 'upperPanelScanDate',
        },
        {
            title: t('status'),
            info: data.rule.status,
            copyable: true,
            dataAid: 'upperPanelStatus',
        },
        {
            title: t('ruleId'),
            info: data.rule.ruleId,
            copyable: true,
            dataAid: 'upperPanelRuleId',
        },
        {
            title: t('benchmarkId'),
            info: data.benchmarkId,
            copyable: true,
            dataAid: 'upperPanelBenchmarkId',
        },
        {
            title: t('benchmarkVersion'),
            info: data.benchmarkId,
            copyable: true,
            dataAid: 'upperPanelBenchmarkVersion',
        },
        {
            title: t('benchmarkTitle'),
            info: data.benchmarkId,
            copyable: true,
            dataAid: 'upperPanelBenchmarkTitle',
        },
    ];
};
