import React, { useEffect, useState } from 'react';
import {
    GcpSecurityCommandCenterStyled,
    GcpSecurityCommandCenterNameWrapper,
    CloudAccountSelectionWrapper,
    EnableCloudWrapper,
    GcpSecurityCommandCenterSourceWrapper,
    GcpCloudSecuritySource,
} from './GcpSecurityCommandCenter.styled';
import { Stack, Typography, Input, Button, SelectV2 } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import {
    COMPONENT_TEXT_COLOR,
    I18nIntegrations,
    SUBMIT_STATUS_RESPONSE,
} from 'common/module_interface/settings/integrations/consts';
import { getIntegrationsService, getNotificationsService } from 'common/interface/services';
import { IConfigurationContainerProps } from 'common/module_interface/settings/integrations/configurations.interface';
import GenericCancelSubmitButtonWrapper from 'common/components/SubmitButtonWrapper/SubmitButtonWrapper';
import { IConfiguration } from 'common/module_interface/settings/integrations/Integrations';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';

interface IGenericConfigurationProps extends IConfigurationContainerProps {
    configuration?: IGenericConfiguration;
}

export interface IGenericConfiguration extends IConfiguration {
    configurationObj: IGcpSecurityCommandCenterConfigurationObj;
}

export interface IGcpSecurityCommandCenterConfigurationObj {
    ProjectId?: string;
    SourceId?: string;
}

interface IGcpAccount {
    name: string;
    projectId: string;
    id: string;
    clientEmail?: string;
}

interface CloudProject {
    id: string;
    name: string;
    projectId: string;
    creationDate?: string;
    organizationalUnitId?: string | null;
    organizationalUnitPath?: string;
    organizationalUnitName?: string;
    gsuite?: string | null;
    vendor?: string;
    projectNumber?: string;
}

const GcpSecurityCommandCenterComponent: React.FC<IGenericConfigurationProps> = ({
    onConfigurationChangeCallBack,
    configuration,
    onConfigurationSaved,
    viewMode,
}) => {
    const { t } = useTranslation(I18nIntegrations);

    const [nameInputConfig, setNameInputConfig] = useState<string>(configuration?.name || '');
    const [cloudAccountInputConfig, SetCloudAccountInputConfig] = useState<{ name: string; projectId: string }>({
        name: '',
        projectId: '',
    });
    const [selectedCloudAccount, setSelectedCloudAccount] = useState<IGcpAccount>();
    const [cloudSecurityApiResponse, setCloudSecurityApiResponse] = useState<CloudProject[]>([]);
    const [accountDetails, setAccountDetails] = useState<IGcpAccount>();
    const [cloudAccountDropdownList, setCloudAccountDropdownList] = useState<SelectOption[]>([]);
    const [idConfiguration, setIdConfiguration] = useState<string>(configuration?.id || '');
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [cloudSecuritySourceConfig, setCloudSecuritySourceConfig] = useState<string>(
        configuration?.configurationObj?.SourceId || '',
    );
    const [responseMessageSuccess, setResponseMessageSuccess] = useState<boolean>(true);
    const [cloudSecuritySourceTestButtonError, setCloudSecuritySourceTestButtonError] = useState<string>('');

    const viewOnly = Boolean(viewMode);
    const discardChangesButtonHidden = Boolean(idConfiguration);

    const checkMissingRequiredInput = () => {
        const variableNotChanged =
            nameInputConfig === '' ||
            cloudAccountInputConfig.name === '' ||
            cloudAccountInputConfig.projectId === '' ||
            cloudSecuritySourceConfig === '';
        setIsButtonDisabled(variableNotChanged);
    };

    const checkChangesMade = () => {
        const changesDetected =
            nameInputConfig !== configuration?.name ||
            cloudSecuritySourceConfig !== configuration?.configurationObj.SourceId ||
            cloudAccountInputConfig?.projectId !== configuration?.configurationObj.ProjectId;
        setIsButtonDisabled(!changesDetected);
        checkMissingRequiredInput();
    };

    const updateAccountDetails = async (configurationAccountId: string) => {
        const selectedAccountDetails = await getIntegrationsService().getSingleGcpCloudAccount(configurationAccountId);
        setAccountDetails(selectedAccountDetails);
    };

    const setGcpAccountInitialValue = async () => {
        if (cloudSecurityApiResponse?.length > 0 && configuration?.configurationObj.ProjectId) {
            const initialAccount = cloudSecurityApiResponse.find(
                (account: CloudProject) => account.projectId === configuration?.configurationObj.ProjectId,
            );
            if (initialAccount) {
                setSelectedCloudAccount(initialAccount);
                SetCloudAccountInputConfig({ name: initialAccount.name, projectId: initialAccount.projectId });
                await updateAccountDetails(initialAccount.id);
            }
        }
    };

    const getAllGcpCloudAccounts = async () => {
        try {
            const gcpCloudAccounts = await getIntegrationsService().getGcpCloudAccounts(false);
            setCloudSecurityApiResponse(gcpCloudAccounts);
            setCloudAccountDropdownList(
                gcpCloudAccounts &&
                    gcpCloudAccounts.map((account: IGcpAccount) => {
                        return { label: `${account.name} (${account.projectId})`, value: account.projectId };
                    }),
            );
        } catch (error: any) {
            getNotificationsService().error('', t('GENERAL.NETWORK_ERROR'));
        }
    };

    const handleNameChange = (nameInput: string) => {
        setNameInputConfig(nameInput);
    };

    const handleCloudAccountChange = (value?: string) => {
        const selectedAccount = cloudSecurityApiResponse.find((acc: CloudProject) => acc.projectId === value);
        selectedAccount &&
            SetCloudAccountInputConfig({ name: selectedAccount?.name, projectId: selectedAccount?.projectId });
        setCloudSecuritySourceConfig('');
        setSelectedCloudAccount(selectedAccount);
        selectedAccount && updateAccountDetails(selectedAccount?.id);
    };

    const handleDiscardChanges = async () => {
        setNameInputConfig(configuration?.name || '');
        const initialAccount = cloudSecurityApiResponse.find(
            (account: CloudProject) => account.projectId === configuration?.configurationObj.ProjectId,
        );
        initialAccount &&
            SetCloudAccountInputConfig({ name: initialAccount.name, projectId: initialAccount.projectId });
        initialAccount && (await updateAccountDetails(initialAccount?.id));
        setCloudSecuritySourceConfig(configuration?.configurationObj.SourceId || '');
    };

    const handleCloudSecuritySourceChange = (sourceInput: string) => {
        setCloudSecuritySourceTestButtonError('');
        setCloudSecuritySourceConfig(sourceInput);
    };

    const testSource = async () => {
        setCloudSecuritySourceTestButtonError('');
        try {
            await getIntegrationsService().testGcpCloudSecuritySource(cloudSecuritySourceConfig, accountDetails?.id);
            setCloudSecuritySourceTestButtonError(t('GENERAL.TEST_PASSED'));
            setResponseMessageSuccess(true);
            return true;
        } catch (error: any) {
            setCloudSecuritySourceTestButtonError(error || t('GENERAL.NETWORK_ERROR'));
            setResponseMessageSuccess(false);
            return false;
        }
    };

    const testConfigurationData = async () => {
        return await testSource();
    };

    const submitForm = async (): Promise<{ Status: SUBMIT_STATUS_RESPONSE; Message?: string }> => {
        const testPassed = await testConfigurationData();
        if (testPassed) {
            try {
                const payload = { ProjectId: cloudAccountInputConfig.projectId, SourceId: cloudSecuritySourceConfig };
                const resp =
                    onConfigurationSaved && (await onConfigurationSaved(nameInputConfig, payload, idConfiguration));
                resp?.id && setIdConfiguration(resp.id);
                onConfigurationChangeCallBack && onConfigurationChangeCallBack();
                setIsButtonDisabled(true);
                return { Status: SUBMIT_STATUS_RESPONSE.SUCCESS };
            } catch (error: any) {
                setResponseMessageSuccess(false);
                return { Status: SUBMIT_STATUS_RESPONSE.FAIL_SAVE, Message: error.message || error.title };
            }
        } else {
            return { Status: SUBMIT_STATUS_RESPONSE.FAIL_TEST };
        }
    };

    useEffect(() => {
        checkChangesMade();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameInputConfig, cloudAccountInputConfig, cloudSecuritySourceConfig, idConfiguration]);

    useEffect(() => {
        setGcpAccountInitialValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cloudAccountDropdownList]);

    useEffect(() => {
        getAllGcpCloudAccounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <GcpSecurityCommandCenterStyled data-aid={'gcp-security-command-center-component'}>
            <GcpSecurityCommandCenterNameWrapper>
                <Typography variant='subtitleLg'>{t('GENERAL.NAME')}</Typography>
                <Input
                    type='text'
                    data-aid='nameInput'
                    value={nameInputConfig}
                    placeholder={t('GENERAL.TYPE_HERE')}
                    onChange={(e) => handleNameChange(e.target.value)}
                    autoFocus={true}
                    disabled={viewOnly}
                />
            </GcpSecurityCommandCenterNameWrapper>
            <CloudAccountSelectionWrapper>
                <Typography variant='subtitleLg'>{t('CONFIGURATIONS.GCP_SECURITY_COMMAND_CENTER.PROJECT')}</Typography>
                <SelectV2
                    isMulti={false}
                    fullWidth
                    options={cloudAccountDropdownList}
                    disabled={viewOnly}
                    placeholder={t('GENERAL.SELECT')}
                    data-aid='gcp-cloud-account-select'
                    value={
                        cloudAccountInputConfig.name &&
                        `${cloudAccountInputConfig?.name} (${cloudAccountInputConfig?.projectId})`
                    }
                    onChange={(value) => handleCloudAccountChange(value)}
                />
            </CloudAccountSelectionWrapper>
            {selectedCloudAccount && accountDetails && (
                <Stack>
                    <EnableCloudWrapper>
                        <Typography variant='bodyLg'>
                            {t('CONFIGURATIONS.GCP_SECURITY_COMMAND_CENTER.PROJECT_STEP_1')}
                        </Typography>
                        <Typography variant='bodyLg'>
                            {t('CONFIGURATIONS.GCP_SECURITY_COMMAND_CENTER.PROJECT_STEP_2')}
                        </Typography>
                        <Typography variant='bodyLg'>
                            {t('CONFIGURATIONS.GCP_SECURITY_COMMAND_CENTER.PROJECT_STEP_3')}
                        </Typography>
                        <Typography variant='bodyLg'>
                            {t('CONFIGURATIONS.GCP_SECURITY_COMMAND_CENTER.PROJECT_STEP_4')}
                        </Typography>
                        <Typography variant='bodyLg'>
                            {t('CONFIGURATIONS.GCP_SECURITY_COMMAND_CENTER.PROJECT_STEP_5')}
                            <i>{selectedCloudAccount.name}</i>
                        </Typography>
                        <Typography variant='bodyLg'>
                            {t('CONFIGURATIONS.GCP_SECURITY_COMMAND_CENTER.PROJECT_STEP_6')}
                            <i>{accountDetails.clientEmail}</i>
                        </Typography>
                    </EnableCloudWrapper>

                    <Stack>
                        <GcpSecurityCommandCenterSourceWrapper>
                            <GcpCloudSecuritySource>
                                <Typography variant='subtitleLg'>
                                    {t('CONFIGURATIONS.GCP_SECURITY_COMMAND_CENTER.CLOUD_SECURITY_SOURCE')}
                                </Typography>
                                <Input
                                    type='text'
                                    value={cloudSecuritySourceConfig}
                                    fullWidth={true}
                                    placeholder={t('GENERAL.TYPE_HERE')}
                                    onChange={(e) => handleCloudSecuritySourceChange(e.target.value)}
                                    disabled={viewOnly}
                                />
                            </GcpCloudSecuritySource>
                            <Stack margin={[0, 0, 0, 2]}>
                                <Button
                                    size='medium'
                                    color='normal'
                                    dataAid='testButton'
                                    active={true}
                                    loading={false}
                                    disabled={viewOnly || !cloudSecuritySourceConfig}
                                    onClick={testConfigurationData}
                                >
                                    {t('GENERAL.TEST')}
                                </Button>
                            </Stack>
                        </GcpSecurityCommandCenterSourceWrapper>
                        <Stack margin={[0, 6]}>
                            <Typography
                                elementType='h5'
                                color={
                                    responseMessageSuccess ? COMPONENT_TEXT_COLOR.SUCCESS : COMPONENT_TEXT_COLOR.ALERT
                                }
                            >
                                {cloudSecuritySourceTestButtonError}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            )}
            {GenericCancelSubmitButtonWrapper({
                handleDiscardChanges,
                discardChangesButtonHidden,
                isButtonDisabled,
                submitForm,
                viewOnly,
                responseMessageSuccess,
            })}
        </GcpSecurityCommandCenterStyled>
    );
};

export default GcpSecurityCommandCenterComponent;
