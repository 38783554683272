import { GlobalConfirmModalProps, globalModelUtils } from 'common/components/GlobalModals/GlobalModals';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { Alert, Stack } from 'common/design-system/components-v2';
import React from 'react';
import i18n from 'common/services/translations/translations';
import EcsService from 'modules/workloads/services/ecs/ecs.service';
import { getNotificationsService } from 'common/interface/services';

const ModalText: React.FC<{ isActive: boolean }> = ({ isActive }) => {
    const { t } = useTranslation(getK8sNamespace('common'));
    const action = isActive ? 'deactivate' : 'activate';
    return (
        <Stack spacing={4}>
            {!isActive && (
                <Alert type='info'>
                    <Stack spacing={1} direction='row'>
                        <span>{t('ecsImagesScanActivate.note')}</span>
                        <span>{t('ecsImagesScanActivate.deactiveNote')}</span>
                    </Stack>
                </Alert>
            )}
            <div>{t(`ecsImagesScanActivate.${action}.description`)}</div>
            <div>{t('ecsImagesScanActivate.proceed')}</div>
        </Stack>
    );
};

const ecsCommonModalProps = (isActive: boolean): GlobalConfirmModalProps => {
    const action = isActive ? 'deactivate' : 'activate';
    return {
        title: i18n.t(`k8s_common:ecsImagesScanActivate.${action}.header`),
        submitBtnText: i18n.t(`k8s_common:ecsImagesScanActivate.${action}.confirm`),
        variant: !isActive ? 'inform' : 'danger',
        width: !isActive ? 'lg' : 'md',
        children: <ModalText isActive={isActive} />,
    };
};

const onConfirm = (isActive: boolean, cloudAccountId: string, reload: () => void) => {
    const action = isActive ? 'deactivate' : 'activate';
    return () => {
        globalModelUtils.showConfirmationModal({
            ...ecsCommonModalProps(isActive),
            submitBtnDisabled: true,
            isLoading: true,
        });
        EcsService.updateEcsImagesScan(cloudAccountId, !isActive)
            .then(() => {
                getNotificationsService().success(i18n.t(`k8s_common:ecsImagesScanActivate.${action}.success`), '');
                reload();
            })
            .catch((error) => {
                let title = i18n.t(`k8s_common:ecsImagesScanActivate.${action}.error`);
                if (error.response?.status === 401) {
                    title = i18n.t(`k8s_common:ecsImagesScanActivate.${action}.unauthorized`);
                }
                getNotificationsService().error(title, '');
            })
            .finally(() => {
                globalModelUtils.showConfirmationModal({
                    ...ecsCommonModalProps(isActive),
                    isOpen: false,
                });
            });
    };
};

export const getEcsImageScanLabel = (isActive: boolean) => {
    return isActive
        ? i18n.t('k8s_common:ecsImagesScanActivate.deactivate.header')
        : i18n.t('k8s_common:ecsImagesScanActivate.activate.header');
};

export const ecsImagesScanActivation = (isActive: boolean, cloudAccountId: string, reload: () => void) => {
    globalModelUtils.showConfirmationModal({
        ...ecsCommonModalProps(isActive),
        onConfirm: onConfirm(isActive, cloudAccountId, reload),
    });
};
