import {
    getBreadcrumbsService,
    getHttpService,
    getNotificationsService,
    getStoreService,
    getWebAppIframeService,
} from 'common/interface/services';
import { throttle } from 'common/utils/functions';
import { changeUrl, getAngularHostName, getReturnUrl } from 'common/utils/http';
import { emitMessageToCloudInfra } from 'common/utils/iframe.communication';
import {
    handleChangeDefaultDashboard,
    handleDeleteDashboard,
    handlePinnedDashboardChange,
    handleRenameDashboard,
} from 'modules/overview/Components/Dashboard/DashboardActionsFunctions';
import { authenticateWithCloudInfra, refreshToken } from 'platform/auth/Auth.actions';
import { changeLanguage, setSubMenuItemChip } from 'platform/main/Menus/Menus.reducer';
import { logout, navigateToLoginAndClearState } from 'platform/user/User.actions';
import {
    getIsReact,
    getIsReactPageWithAngular,
    setHideAngularUntilNavigated,
    setIsAWSLegacyView,
} from '../App.reducer';
import IframeMessageModel, {
    IFRAME_MESSAGE_ACTIONS,
    IFRAME_MESSAGE_SOURCES,
    IFRAME_TAGS,
} from '../common/interface/IFrame.message.model';
import history, { URL_PREFIX } from '../common/utils/history';
import { setUserPersonalName } from './user/User.reducer';
import AwsActions from 'modules/assets/Environments/AwsEnvironment/reducer/aws.actions';
import { getCloudAccountsService } from 'common/interface/data_services';
import { getCurrentHub } from '@sentry/react';

const reactPageIgnoredMessageActions: string[] = [
    IFRAME_MESSAGE_ACTIONS.URL_UPDATE,
    IFRAME_MESSAGE_ACTIONS.MENU.SUB_MENU.CHANGE,
    IFRAME_MESSAGE_ACTIONS.MENU.CHANGE,
];

function setIframeIncomingMessageListener() {
    const iframeMessagesHandlers = {
        [IFRAME_MESSAGE_SOURCES.WEBAPP]: handleWebappMessage,
        [IFRAME_MESSAGE_SOURCES.CLOUD_INFRA]: handleCloudInfraMessage,
    };

    async function handleCloudInfraMessage(message: IframeMessageModel) {
        switch (message.action) {
            case IFRAME_MESSAGE_ACTIONS.LOGOUT:
            case IFRAME_MESSAGE_ACTIONS.APP_SWITCH:
            case IFRAME_MESSAGE_ACTIONS.TENANT_SWITCH:
                try {
                    await logout(undefined, true);
                    emitMessageToCloudInfra(new IframeMessageModel({ action: message.action + '_ACK' }));
                } catch (error) {
                    console.error(error);
                    emitMessageToCloudInfra(new IframeMessageModel({ action: message.action + '_FAIL' }));
                }
                break;
            case IFRAME_MESSAGE_ACTIONS.IFRAME_READY: {
                let relativePath = message?.data?.relativePath ?? '';
                relativePath = relativePath.replace('#', '').replace('/home', '').replace('/v2', '');
                changeUrl(relativePath);
                const angularHostName = getAngularHostName(document.location.hostname, document.location.port);
                const url = `${document.location.protocol}//${angularHostName}${relativePath}`;
                getWebAppIframeService().setSrc(url);
                const language = message?.data?.user?.locale ?? 'en';
                const formattedLanguage = language.substring(0, 2);
                changeLanguage(formattedLanguage);

                dispatchCloudInfraUserName(message?.data?.user);
                break;
            }
            case IFRAME_MESSAGE_ACTIONS.URL_UPDATE: {
                let localURL = `${document.location.pathname}${document.location.search}`;
                if (localURL.startsWith(URL_PREFIX)) {
                    localURL = localURL.replace(URL_PREFIX, '');
                }
                let relativePath = message?.data?.pathNameWithHash;
                if (relativePath) {
                    const split = relativePath.split('#');
                    relativePath = split[1];
                    if (localURL === relativePath) {
                        return;
                    }
                    history.replace(relativePath, '');
                    const iframeMessage = new IframeMessageModel({
                        action: IFRAME_MESSAGE_ACTIONS.URL_UPDATE,
                        data: { relativePath, isCloudInfra: true },
                    });
                    getWebAppIframeService().emitMessage(iframeMessage);
                }
                break;
            }
        }
    }

    let sentryStartSpan: any = null;

    async function handleWebappMessage(message: IframeMessageModel) {
        let iframeMessage = null;
        const dispatch = getStoreService().dispatch;
        const alertsService = getNotificationsService();
        const redirectOptions = { redirectURL: getReturnUrl() };
        switch (message.action) {
            case IFRAME_MESSAGE_ACTIONS.API_STATUS: {
                sentryStartSpan?.startChild({
                    status: 'ok',
                    op: 'resource.iframe.api',
                    description: `api: '${message.data.api}`,
                    startTimestamp: message.data.start.valueOf() / 1000,
                    endTimestamp: message.data.end.valueOf() / 1000,
                });
                return;
            }
            case IFRAME_MESSAGE_ACTIONS.FIRST_PAINT_STATUS: {
                const transaction = getCurrentHub().getScope().getTransaction();
                sentryStartSpan = transaction?.startChild({
                    status: 'ok',
                    op: 'resource.iframe',
                    description: 'angular page started',
                    startTimestamp: new Date().valueOf() / 1000,
                });
                return;
            }
            case IFRAME_MESSAGE_ACTIONS.PAGE_MUTATION_STATUS: {
                if (sentryStartSpan) {
                    sentryStartSpan.startChild({
                        status: 'ok',
                        op: 'resource.iframe.mutation',
                        description: `mutate element: ${message.data.state?.targetElementClassName}`,
                        startTimestamp: new Date().valueOf() / 1000,
                        endTimestamp: new Date().valueOf() / 1000,
                    });
                }

                return;
            }
            case IFRAME_MESSAGE_ACTIONS.PAGE_LOADED: {
                if (sentryStartSpan) {
                    sentryStartSpan.endTimestamp = new Date().valueOf() / 1000;
                }
                return;
            }
            case IFRAME_MESSAGE_ACTIONS.REFRESH_TOKEN:
                return refreshToken(true);
            case IFRAME_MESSAGE_ACTIONS.QUERY_PARAMS_UPDATE: {
                const newSearchParams = new URLSearchParams(message?.data?.queryParams);
                const newUrl = window.location.pathname.replace('/v2', '') + '?' + newSearchParams.toString();
                history.replace(newUrl);
                return;
            }

            case IFRAME_MESSAGE_ACTIONS.LOGOUT:
                await logout(redirectOptions.redirectURL);
                return;
            case IFRAME_MESSAGE_ACTIONS.REDIRECT:
                return navigateToLoginAndClearState(redirectOptions.redirectURL);
            case IFRAME_MESSAGE_ACTIONS.AUTHENTICATE_WITH_CLOUD_INFRA:
                return authenticateWithCloudInfra;
            case IFRAME_MESSAGE_ACTIONS.CLOUD_INFRA_ERROR_PAGE:
                window.location.href = `${URL_PREFIX}/error`;
                return;
            case IFRAME_MESSAGE_ACTIONS.URL_UPDATE: {
                const relativePath = message?.data?.relativePath;
                const webappURL = `${URL_PREFIX}${relativePath}`.split('?')[0];
                const localURL = document.location.pathname;
                if (localURL !== webappURL) {
                    history.push(relativePath);
                    iframeMessage = new IframeMessageModel({
                        action: IFRAME_MESSAGE_ACTIONS.URL_UPDATE,
                        data: { relativePath },
                    });
                    emitMessageToCloudInfra(iframeMessage);
                    dispatch(setHideAngularUntilNavigated(false));
                }
                break;
            }

            case IFRAME_MESSAGE_ACTIONS.ONBOARDING.SET_NEW_AWS_VIEW:
                dispatch(setIsAWSLegacyView(false));
                break;
            case IFRAME_MESSAGE_ACTIONS.DASHBOARD.DEFAULT.CHANGE: {
                const dashboardId = message.data;
                handleChangeDefaultDashboard(dashboardId);
                return;
            }
            case IFRAME_MESSAGE_ACTIONS.DASHBOARD.DELETE: {
                const dashboardId = message.data;
                handleDeleteDashboard(dashboardId, false);
                return;
            }
            case IFRAME_MESSAGE_ACTIONS.DASHBOARD.RENAME: {
                const newDashboardName = message.data;
                handleRenameDashboard(newDashboardName);
                return;
            }
            case IFRAME_MESSAGE_ACTIONS.DASHBOARD.FAVORITE.CHANGE: {
                const dashboardId = message.data;
                handlePinnedDashboardChange(dashboardId);
                return;
            }
            case IFRAME_MESSAGE_ACTIONS.MENU.SUB_MENU.CHIP:
                dispatch(setSubMenuItemChip(message?.data));
                return;
            case IFRAME_MESSAGE_ACTIONS.TOAST:
                alertsService.addNotification({
                    type: message?.data?.level,
                    text: message?.data?.message,
                    id: message?.id,
                    title: message?.data?.title,
                    time: message?.data?.timeout,
                });
                return;
            case IFRAME_MESSAGE_ACTIONS.CLEAR_CACHE:
                if (message.data?.tag) {
                    switch (message.data.tag) {
                        case IFRAME_TAGS.CLEAR_CACHE.ENVIRONMENTS:
                            getCloudAccountsService().clearCache();
                            break;
                        default:
                            getHttpService().clearCacheByTag(message.data.tag, message.data.method, false);
                            break;
                    }
                } else {
                    console.error('No tag provided to clear cache [FRONTEND], data is: ', message.data);
                }
                return;
            case IFRAME_MESSAGE_ACTIONS.USER_ACTIVE:
                sendUserAliveToCI();
                break;
            case IFRAME_MESSAGE_ACTIONS.SET_BREADCRUMBS_LAST_CHILD:
                // this timeout is used to deal with update url race condition
                setTimeout(() => {
                    getBreadcrumbsService().setBreadcrumbsLastChildren(message?.data?.breadcrumbsLastChildren);
                }, 200);
                break;
            case IFRAME_MESSAGE_ACTIONS.NOTIFY_AWS_PAGE_ON_NOTIFICATIONS: {
                const { awsId, count } = message?.data || {};
                if (!count || !awsId) {
                    console.error('awsId or count is missing, data is: ', message.data);
                }
                AwsActions.setNotificationCountWarning(awsId, count);
                break;
            }
            case IFRAME_MESSAGE_ACTIONS.ACKNOWLEDGE_VIEWSTATE: {
                getWebAppIframeService().acknowledgeViewState(message?.data);
                break;
            }
            default:
                break;
        }
    }

    window.onmessage = function ({ data: message }: MessageEvent) {
        const iframeMessage = new IframeMessageModel(message);
        const messageHandle = iframeMessagesHandlers[iframeMessage.source] as Function;
        messageHandle && !shouldIgnoreMessage(iframeMessage) && messageHandle(iframeMessage);
    };
}

const shouldIgnoreMessage = (message: IframeMessageModel): boolean => {
    const isReactPage = getIsReact(getStoreService().state);
    const isReactPageWithAngular = getIsReactPageWithAngular(getStoreService().state);
    return (
        isReactPage &&
        !isReactPageWithAngular &&
        reactPageIgnoredMessageActions.includes(message.action) &&
        message.source !== IFRAME_MESSAGE_SOURCES.CLOUD_INFRA
    );
};

function dispatchCloudInfraUserName(user: { firstName: string; lastName: string }) {
    const dispatch = getStoreService().dispatch;
    dispatch(setUserPersonalName({ firstname: user.firstName, lastname: user.lastName }));
}

function sendUserAliveToCI() {
    const iframeMessage = new IframeMessageModel({
        action: IFRAME_MESSAGE_ACTIONS.USER_ACTIVE,
        data: {},
    });
    emitMessageToCloudInfra(iframeMessage);
}

document.addEventListener(
    'mousemove',
    throttle(() => {
        sendUserAliveToCI();
    }, 5 * 1000),
);

document.addEventListener(
    'keydown',
    throttle(() => {
        sendUserAliveToCI();
    }, 5 * 1000),
);

window.addEventListener(
    'popstate',
    function () {
        let relativePath = window.location.pathname + window.location.search;
        if (relativePath.startsWith(URL_PREFIX)) {
            relativePath = relativePath.replace(URL_PREFIX, '');
        }
        const iframeMessage = new IframeMessageModel({
            action: IFRAME_MESSAGE_ACTIONS.URL_UPDATE,
            data: {
                relativePath,
            },
        });
        getWebAppIframeService().emitMessage(iframeMessage);
    },
    false,
);

export { setIframeIncomingMessageListener };
