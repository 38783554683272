import React from 'react';
import { IGenericWidgetDataProps } from 'common/module_interface/overview/Interface';
import IconAndNumberWidget from 'common/components/Widgets/IconAndNumberWidget/IconAndNumberWidget';
import { changeUrl } from 'common/utils/http';
import { buildAlertsWidgetLink } from '../WidgetsData/AlertsDataGetter';

const AlertsIconAndNumberWidget: React.FC<IGenericWidgetDataProps> = (props) => {
    const onclickCallback = () => {
        changeUrl(buildAlertsWidgetLink(props?.widget, {}));
    };

    return (
        <div className='group flex flex-1 w-full'>
            <IconAndNumberWidget {...props} onClickCallback={onclickCallback} />
        </div>
    );
};

export default AlertsIconAndNumberWidget;
