import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { IClientFilterPageTableProps } from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPage.interface';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { getProtectedAssetsListColumns } from '../ProtectedAssetsPage/ProtectedAssetsList';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { ColDef } from 'ag-grid-enterprise';
import { ClientFilterPageTable } from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable';
import { Label, Stack } from 'common/design-system/components-v2';
import { fetchSimilarAssets } from './SimilarityGroup.datasource';
import FullSizeSpinner from 'common/erm-components/custom/FullSize/FullSizeSpinner';
import { useTranslation } from 'react-i18next';
import { IAsset } from 'common/assets/common.assets';
import { getEntityTypeValue } from 'common/components/ProtectedAssets/Renderers/ColumnDisplayValueGetters';

const SIMILARITY_GROUP_VIEWER_TABLE_ID = 'SIMILARITY_GROUP_VIEWER_TABLE_ID';
const getSimilarityGroupTableColumns = (): ColDef[] => {
    const ermColumns = getProtectedAssetsListColumns();
    return getProtectedAssetsService()
        .getColumnDefs(ermColumns)
        .filter((colDef) => {
            return colDef.colId !== 'type';
        })
        .map((colDef) => {
            return {
                ...colDef,
                // sortable: false,
                enableRowGroup: false,
            };
        });
};

export interface ISimilarityGroupViewerProps {
    similarityGroupId: string;
    memberAsset: IProtectedAssetViewModel;
}

export const SimilarityGroupViewer: React.FC<ISimilarityGroupViewerProps> = ({ similarityGroupId }) => {
    const [assets, setAssets] = useState<IProtectedAssetViewModel[]>();
    const { t } = useTranslation();
    const pageProps: IClientFilterPageTableProps<IProtectedAssetViewModel> = useMemo(
        () => ({
            fetchAllItems: () => Promise.resolve(assets || []),
            getTableColumnDefs: getSimilarityGroupTableColumns,
            pageTableId: SIMILARITY_GROUP_VIEWER_TABLE_ID,
        }),
        [assets],
    );

    const assetTypeComponent: ReactNode = useMemo(() => {
        if (!assets || assets.length === 0) {
            return null;
        }

        const asset: IProtectedAssetViewModel = assets[0];
        const iAsset: IAsset | undefined =
            getProtectedAssetsService().getAssetByType(asset.typeByPlatform) || undefined;
        if (!iAsset) {
            return null;
        }
        const typeName: string | undefined = getEntityTypeValue(asset.typeByPlatform) || undefined;
        if (!typeName) {
            return null;
        }
        return <Label text={typeName} leadingIconProps={iAsset.icon ? { vendorNameOrPath: iAsset.icon } : undefined} />;
    }, [assets]);

    const loadData = useCallback(async () => {
        setAssets(await fetchSimilarAssets(similarityGroupId));
    }, [similarityGroupId]);

    useEffect(() => {
        void loadData();
    }, [loadData]);

    if (!assets) {
        return <FullSizeSpinner />;
    }

    if (assets.length === 0) {
        return (
            <Stack fullWidth fullHeight alignItems={'center'} justifyContent={'center'}>
                <Label text={t('COMMON.PROTECTED_ASSETS_TABLE.NO_ITEMS')} />
            </Stack>
        );
    }

    return (
        <Stack fullWidth fullHeight padding={[4, 0, 0, 0]}>
            {assetTypeComponent && (
                <Stack direction={'row'} fullWidth spacing={4}>
                    <Label text={t('COMMON.PROTECTED_ASSETS_TABLE.COMMON_ASSET_TYPE')} />
                    {assetTypeComponent}
                </Stack>
            )}
            <ClientFilterPageTable {...pageProps} />
        </Stack>
    );
};
