import { IReportIssueMessageModal, IReportIssueModalProps } from './ReportIssueModal.types';
import ModalDialog from 'common/design-system/components-v2/Modal/ModalDialog';
import { Button, Modal, SelectV2, Stack, TextArea, Typography } from 'common/design-system/components-v2';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import { FormFields, REPORTING_ISSUE_TYPE } from './ReportIssueModal.const';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import i18n from 'common/services/translations/translations';
import { getLoggerService } from 'common/interface/services';
import { IFieldError } from 'common/module_interface/intelligence/CloudBots/CloudBots.interface';
import ConfirmationModal from 'common/components/ConfirmationModal';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { useFieldErrors } from '../../Hooks/useFieldErrors';

export const ReportIssueModal: React.FC<IReportIssueModalProps> = (props) => {
    const { selectedRows, closeModal } = props;
    const fieldErrorsProps = useFieldErrors();
    const { addFieldErrors, getFieldErrorText, clearFieldErrors } = fieldErrorsProps;
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const [selectedIssueType, setSelectedIssueType] = useState<string>('');
    const [issuesTypes, setIssuesTypes] = useState<SelectOption[]>([]);
    const [issueDetails, setIssueDetails] = useState<string>('');
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

    const onIssueTypeChange = (value: string): void => {
        setSelectedIssueType(value);
        clearFieldErrors([FormFields.ISSUE_TYPE]);
    };

    const onReportIssueModalCancel = (): void => {
        setIsConfirmationModalOpen(true);
    };

    const onConfirmationModalCancel = (): void => {
        setIsConfirmationModalOpen(false);
    };

    const onDiscardChanges = (): void => {
        closeModal();
    };

    const onIssueDetailsChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setIssueDetails(event.target.value);
        clearFieldErrors([FormFields.ISSUE_DETAILS]);
    };

    const onIssueReport = (): void => {
        const isAnyFieldError: boolean = updateFieldsErrorState();
        if (isAnyFieldError) return;

        const messages: string[] = [];
        selectedRows.forEach((finding: IKustoEvent) => {
            const message: IReportIssueMessageModal = {
                reportType: REPORTING_ISSUE_TYPE[selectedIssueType as keyof typeof REPORTING_ISSUE_TYPE],
                description: issueDetails,
                finding: finding,
            };
            const messageJson = JSON.stringify(message);
            messages.push(messageJson);
        });
        getLoggerService()
            .info(messages, undefined, ['Finding Alerts Report Tracking'])
            .catch(() => console.log('error while sending report'));
        closeModal();
    };

    const updateFieldsErrorState = () => {
        const errors: IFieldError[] = [];
        if (!selectedIssueType) {
            errors.push({
                field: FormFields.ISSUE_TYPE,
                label: t('REPORT_ISSUE_MODAL.INPUT_FIELDS.ISSUE_TYPE.ERROR'),
            });
        }
        if (!issueDetails) {
            errors.push({
                field: FormFields.ISSUE_DETAILS,
                label: t('REPORT_ISSUE_MODAL.INPUT_FIELDS.DETAILS.ERROR'),
            });
        }
        addFieldErrors(errors);
        return errors.length > 0;
    };

    const issueTypeFieldErrorText: string | undefined = useMemo(() => {
        return getFieldErrorText(FormFields.ISSUE_TYPE);
    }, [getFieldErrorText]);

    const issueDetailsFieldErrorText: string | undefined = useMemo(() => {
        return getFieldErrorText(FormFields.ISSUE_DETAILS);
    }, [getFieldErrorText]);

    useEffect(() => {
        const _issuesTypes: SelectOption[] = Object.keys(REPORTING_ISSUE_TYPE).map((issueType) => {
            return {
                label: t(`REPORT_ISSUE_MODAL.ISSUE_TYPES.${issueType}`),
                value: issueType,
            };
        });
        setIssuesTypes(_issuesTypes);
    }, [t]);

    return (
        <Fragment>
            <ModalDialog onRequestClose={onReportIssueModalCancel} isOpen={true} width={'lg'}>
                <Modal.ModalHeader title={t('REPORT_ISSUE_MODAL.TITLE')} onClose={onReportIssueModalCancel} />
                <Modal.ModalContent>
                    <Stack spacing={5}>
                        <SelectV2
                            isMulti={false}
                            required={true}
                            options={issuesTypes}
                            onChange={onIssueTypeChange}
                            isError={!!issueTypeFieldErrorText}
                            helperText={issueTypeFieldErrorText}
                            label={t('REPORT_ISSUE_MODAL.INPUT_FIELDS.ISSUE_TYPE.TITLE')}
                            placeholder={t('REPORT_ISSUE_MODAL.INPUT_FIELDS.ISSUE_TYPE.PLACEHOLDER')}
                        ></SelectV2>
                        <TextArea
                            fullWidth={true}
                            onChange={onIssueDetailsChange}
                            required={true}
                            clearable={true}
                            inputSize='large'
                            maxLength={200}
                            rows={4}
                            isError={!!issueDetailsFieldErrorText}
                            helperText={issueDetailsFieldErrorText}
                            label={t('REPORT_ISSUE_MODAL.INPUT_FIELDS.DETAILS.TITLE')}
                            placeholder={t('REPORT_ISSUE_MODAL.INPUT_FIELDS.DETAILS.PLACEHOLDER')}
                        />
                    </Stack>
                </Modal.ModalContent>
                <Modal.ModalFooter>
                    <Stack direction='row' justifyContent='flex-end' fullWidth spacing={2}>
                        <Button key={'cancel-button-action'} variant='text' onClick={closeModal}>
                            {i18n.t('COMMON.CANCEL')}
                        </Button>
                        <Button
                            key={'execute-button-action'}
                            color='brandPrimary'
                            onClick={onIssueReport}
                            dataAid='Report'
                        >
                            {t('REPORT_ISSUE_MODAL.ACTIONS.REPORT')}
                        </Button>
                    </Stack>
                </Modal.ModalFooter>
            </ModalDialog>
            {isConfirmationModalOpen && (
                <ConfirmationModal
                    width={'md'}
                    isOpen={isConfirmationModalOpen}
                    title={t('ACTIONS.CONFIRMATION_MODAL.TITLE')}
                    submitBtnText={i18n.t('COMMON.DISCARD')}
                    onConfirm={onDiscardChanges}
                    onClose={onConfirmationModalCancel}
                    onCancel={onConfirmationModalCancel}
                >
                    <Typography>{t('ACTIONS.CONFIRMATION_MODAL.DESCRIPTION')}</Typography>
                </ConfirmationModal>
            )}
        </Fragment>
    );
};
