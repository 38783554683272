import React from 'react';
import CountBox from 'common/components/CountBox/CountBox';
import { getSafeIssueSeverityInfo } from '../IssueSeverity';
import { IssueSeverityEnum } from 'common/module_interface/RiskManagement/issues/Issues.interface';

type CountBoxSize = 'tiny' | 'standard' | 'extend';

interface IssueSeverityBoxProps {
    number: number;
    size: CountBoxSize;
    severity: IssueSeverityEnum;
    isAddCommas?: boolean;
    isAddShorthand?: boolean;
    onClick?: () => void;
}

export const IssueSeverityBox: React.FC<IssueSeverityBoxProps> = ({
    number,
    size,
    severity,
    isAddCommas,
    isAddShorthand,
    onClick,
}) => {
    return (
        <CountBox
            text={getSafeIssueSeverityInfo(severity).displayText}
            onClick={onClick}
            number={number}
            size={size}
            color={getSafeIssueSeverityInfo(severity).color}
            isAddCommas={isAddCommas}
            isAddShorthand={isAddShorthand}
        ></CountBox>
    );
};

export default IssueSeverityBox;
