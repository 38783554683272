import { getHttpService } from 'common/interface/services';
import { IEvent } from 'common/module_interface/events/Events';
export class ComplianceEventsApi {
    async getFindingDetails(findingId: string, useCache = true) {
        return await getHttpService().request<IEvent>(
            `Compliance/Finding/${findingId}`,
            { method: 'GET' },
            {
                returnAsAxiosResponse: false,
                cachingConfig: { useCache, tags: ['Compliance/Finding', `Compliance/Finding/${findingId}`] },
            },
            () => {
                throw new Error('Could not find event by id: ' + findingId);
            },
        );
    }
}
