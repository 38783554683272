import { FC, useState } from 'react';
import {
    ENTITY_FILTER_BY_KEY,
    EXCLUSION_SELECTED_TYPE,
    I18nExclusion,
    modalPrefixAnalytics,
} from '../../../common/components/exclusions/helpers/exclusions.consts';
import { IDateRange } from 'common/design-system/components-v2/DatePicker/DatePicker.types';
import { useTranslation } from 'react-i18next';
import { GroupSelection, Stack, Message, Input } from 'common/design-system/components-v2';
import {
    CiemSelectOption,
    IExclusionConfig,
    IExclusionModalProps,
    IExclusionValidation,
} from 'common/components/exclusions/helpers/exclusions.interfaces';
import { exclusionCspmValidation, exclusionSaveCiem } from 'common/components/exclusions/helpers/exclusions.utils';
import ExclusionComment from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionComment';
import ExclusionByOrganizationalUnit from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByOrganizationalUnit';
import ExclusionByRegion from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByRegion';
import ExclusionByAccountNumber from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByAccountNumber';
import ExclusionByEnvironment from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByEnvironment';
import ExclusionBySeverities from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionBySeverities';
import ExclusionByEntity from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByEntity';
import ExclusionByDate from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByDate';
import ExclusionWarning from 'common/components/exclusions/helpers/exclusionWarning';
import validateWithYup from 'common/helpers/validateWithYup';
import { getNotificationsService } from 'common/interface/services';
import { selectCiemRuleSet } from 'common/consts/ExclusionConsts';
import { ExclusionModuleType } from 'common/interface/exclusion';

const ExclusionsModalCiem: FC<IExclusionModalProps> = ({ isOpen, onClose, onSave, exclusion }) => {
    const { t } = useTranslation(I18nExclusion);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [isSaveClicked, setIsSaveClicked] = useState(false);
    const [selectedRuleset] = useState<CiemSelectOption>(() => selectCiemRuleSet());
    const [selectedRulesetDetails] = useState<CiemSelectOption>(() => selectCiemRuleSet());
    const [selectedComment, setSelectedComment] = useState<string>(exclusion?.comment || '');
    const [ouEnvironmentRadioButton, setOuEnvironmentRadioButton] = useState<string>(
        exclusion?.cloudAccountIds ? EXCLUSION_SELECTED_TYPE.ENVIRONMENT : EXCLUSION_SELECTED_TYPE.ORGANIZATIONAL_UNIT,
    );
    const [selectedOrganizationalUnit, setSelectedOrganizationalUnit] = useState<string[]>([]);
    const [selectedEnvironment, setSelectedEnvironment] = useState<string[]>([]);
    const [selectedRegion, setSelectedRegion] = useState<string[]>([]);
    const [selectedDateRange, setSelectedDateRange] = useState<IDateRange | undefined>(
        exclusion?.dateRange || undefined,
    );
    const [selectedAccountNumber, setSelectedAccountNumber] = useState<string>(exclusion?.cloudAccountId || '');
    const [selectedSeverities, setSelectedSeverities] = useState<string[]>([]);
    const [entityLogic, setEntityLogic] = useState<string | null>(null);

    const exclusionId = exclusion?.id;
    const exclusionConfig = {
        selectedComment,
        ouEnvironmentRadioButton,
        selectedOrganizationalUnit,
        selectedEnvironment,
        entityLogic,
        selectedDateRange,
        selectedRegion,
        selectedAccountNumber,
        selectedSeverities,
        exclusionId,
        selectedRulesetDetails,
    };

    const configuredExclusionObject = (): IExclusionValidation => {
        return {
            ruleset: selectedRuleset?.value,
            comment: selectedComment,
            requiredAdditionalInfo:
                !!selectedRegion.length || !!selectedSeverities.length || !!entityLogic || !!selectedAccountNumber,
        };
    };
    const requestPayload = configuredExclusionObject();
    const formValidations = validateWithYup({
        yupValidationObject: exclusionCspmValidation(t),
        payload: { ...requestPayload },
    });

    const saveExclusion = async () => {
        setIsSaveLoading(true);
        setIsSaveClicked(true);
        try {
            if (!formValidations.valid) {
                setIsSaveLoading(false);
                return;
            }
            const saveExclusionResponse = await exclusionSaveCiem(exclusionConfig as IExclusionConfig);
            !!saveExclusionResponse && onSave && onSave();
            setIsSaveLoading(false);
            getNotificationsService().info(
                exclusionId
                    ? t('MODAL.TOAST.EXCLUSION_SAVE_SUCCESS.EDIT')
                    : t('MODAL.TOAST.EXCLUSION_SAVE_SUCCESS.CREATE'),
                '',
            );
        } catch (error: any) {
            getNotificationsService().error(t('MODAL.GENERAL.SAVE_ERROR'), error);
            setIsSaveLoading(false);
        }
    };

    const handleFilterFields = () => {
        switch (ouEnvironmentRadioButton) {
            case EXCLUSION_SELECTED_TYPE.ORGANIZATIONAL_UNIT:
                return selectedOrganizationalUnit.map((id) => ({
                    name: ENTITY_FILTER_BY_KEY.ORGANIZATIONAL_UNIT,
                    value: id,
                }));
            case EXCLUSION_SELECTED_TYPE.ENVIRONMENT:
                return selectedEnvironment.map((id) => ({ name: ENTITY_FILTER_BY_KEY.ENVIRONMENT, value: id }));
            default:
                return null;
        }
    };

    const onCommentChange = (value: string) => {
        setSelectedComment(value);
    };
    const handleOuEnvironmentRadioButtonChange = (value: string) => {
        setOuEnvironmentRadioButton(value);
    };
    const handleOrganizationalUnitChange = (value: string[]) => {
        setSelectedOrganizationalUnit(value);
    };
    const handleEnvironmentChange = (value: string[]) => {
        setSelectedEnvironment(value);
    };
    const onRegionChange = (value: string[]) => {
        setSelectedRegion(value);
    };
    const onDateRangeChange = (value?: IDateRange) => {
        setSelectedDateRange(value);
    };
    const onAccountNumberChange = (value: string) => {
        setSelectedAccountNumber(value);
    };
    const onSeverityChange = (value: string[]) => {
        setSelectedSeverities(value);
    };
    const onEntityLogicChange = (value: string | null) => {
        setEntityLogic(value);
    };

    return (
        <Message
            id={`${modalPrefixAnalytics}-${ExclusionModuleType.CIEM}`}
            width='lg'
            onClose={onClose}
            isOpen={isOpen}
            title={exclusion?.id ? t('MODAL.HEADER.EDIT') : t('MODAL.HEADER.CREATE')}
            cancelBtnText={t('MODAL.FOOTER.CANCEL')}
            onCancel={onClose}
            submitBtnText={t('MODAL.FOOTER.SAVE')}
            onConfirm={saveExclusion}
            isLoading={isSaveLoading}
        >
            <Stack spacing={3} fullWidth>
                {isSaveClicked && !formValidations.valid && <ExclusionWarning />}
                <Input
                    label={t('MODAL.TOPICS.RULESET.TITLE')}
                    type='text'
                    value={selectedRuleset.label}
                    readOnly={true}
                    fullWidth
                />
                <ExclusionComment
                    formValidations={isSaveClicked ? formValidations.errors?.comment : undefined}
                    isEnabled={!!selectedRuleset}
                    selectedOption={selectedComment}
                    onChange={(value) => onCommentChange(value)}
                    isSaveClicked={isSaveClicked}
                />
                <Stack spacing={1}>
                    <GroupSelection
                        direction='row'
                        value={ouEnvironmentRadioButton}
                        onChange={handleOuEnvironmentRadioButtonChange}
                        options={[
                            {
                                dataAid: 'ou-radio-button',
                                label: t('MODAL.TOPICS.ORGANIZATIONAL_UNIT.TITLE'),
                                name: EXCLUSION_SELECTED_TYPE.ORGANIZATIONAL_UNIT,
                                value: EXCLUSION_SELECTED_TYPE.ORGANIZATIONAL_UNIT,
                            },
                            {
                                dataAid: 'enviroment-radio-button',
                                label: t('MODAL.TOPICS.ENVIRONMENT.TITLE'),
                                name: EXCLUSION_SELECTED_TYPE.ENVIRONMENT,
                                value: EXCLUSION_SELECTED_TYPE.ENVIRONMENT,
                            },
                        ]}
                    />
                    {ouEnvironmentRadioButton === EXCLUSION_SELECTED_TYPE.ORGANIZATIONAL_UNIT && (
                        <ExclusionByOrganizationalUnit
                            isEnabled={!!selectedRuleset}
                            selectedRuleset={selectedRuleset?.value}
                            selectedOption={selectedOrganizationalUnit}
                            initialSelectedOption={exclusion?.organizationalUnitIds}
                            onChange={(value) => handleOrganizationalUnitChange(value)}
                            onClose={onClose}
                        />
                    )}
                    {ouEnvironmentRadioButton === EXCLUSION_SELECTED_TYPE.ENVIRONMENT && (
                        <ExclusionByEnvironment
                            isEnabled={!!selectedRuleset}
                            selectedRuleset={selectedRuleset?.value}
                            selectedOption={selectedEnvironment}
                            initialSelectedOption={exclusion?.cloudAccountIds}
                            selectedRulesetDetails={selectedRulesetDetails}
                            onChange={(value) => handleEnvironmentChange(value)}
                            onClose={onClose}
                        />
                    )}
                </Stack>
                <ExclusionByRegion
                    isEnabled={!!selectedRuleset}
                    selectedRuleset={selectedRuleset?.value}
                    selectedOption={selectedRegion}
                    initialSelectedOption={exclusion?.regions}
                    selectedRulesetDetails={selectedRulesetDetails}
                    onChange={(value) => onRegionChange(value)}
                    onClose={onClose}
                />
                <ExclusionByDate selectedOption={selectedDateRange} onChange={(value) => onDateRangeChange(value)} />
                <ExclusionByEntity
                    formValidations={isSaveClicked ? formValidations.errors?.logicExpressions : undefined}
                    filterFields={handleFilterFields()}
                    isEnabled={!!selectedRuleset}
                    selectedRuleset={selectedRuleset?.value}
                    selectedOption={exclusion?.logicExpressions}
                    onChange={onEntityLogicChange}
                    helperText={t('MODAL.TOPICS.ENTITY.CIEM_SUPPORTS_1_ENTITY')}
                    limitSelection={1}
                    isSelectionGroup
                />
                <ExclusionByAccountNumber
                    isEnabled={!!selectedRuleset}
                    selectedRuleset={selectedRuleset?.value}
                    selectedOption={exclusion?.logicExpressions}
                    onChange={(value) => onAccountNumberChange(value)}
                />
                <ExclusionBySeverities
                    isEnabled={!!selectedRuleset}
                    selectedRuleset={selectedRuleset?.value}
                    selectedOption={selectedSeverities}
                    initialSelectedOption={exclusion?.severities}
                    onChange={(value) => onSeverityChange(value)}
                />
            </Stack>
        </Message>
    );
};

export default ExclusionsModalCiem;
