import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { IssueViewerRegistry } from 'common/module_interface/RiskManagement/issues/IssueViewerRegistry';
import { ISSUE_ASSET_PANEL_KEY } from './IssueAssetPanel.initialize';
import { IssueEntityNameLink } from '../../../cellRenderers/IssueEntityNameCellRender';
import { IIssue } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { AssetPropertiesPanel } from 'common/components/AssetPropertiesPanel/AssetPropertiesPanel';
import { fetchMainEntities, getCloudEntityDataFromIssue } from '../../../Issues.utils';
import { SubAssetDetails } from './SubAssetDetails/SubAssetDetails';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../../../../consts';
import { IssueProvider } from 'common/components/IssueContext/IssueContext';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { LoadingState } from 'common/interface/general';
import FullSizeError from 'common/erm-components/custom/FullSize/FullSizeError';

export const IssueAssetPanel: React.FC<{ issue: IIssue }> = ({ issue }) => {
    const { t } = useTranslation(I18nRiskNamespace);
    const [subAssets, setSubAssets] = useState<IProtectedAssetViewModel[]>();
    const [mainAssetID, setMainAssetID] = useState<string>();
    const [loadingStateSubAssets, setLoadingStateSubAssets] = useState<LoadingState | undefined>();
    const [selectedSubAsset, setSelectedSubAsset] = useState<IProtectedAssetViewModel>();
    const EntityNameLinkComponent = useMemo(() => {
        return <IssueEntityNameLink issue={issue} showFullName={false} />;
    }, [issue]);

    const entityFields = useMemo(() => {
        return IssueViewerRegistry.getFields(ISSUE_ASSET_PANEL_KEY);
    }, []);

    const getCloudEntityFromIssue = useCallback(async () => {
        return getCloudEntityDataFromIssue(issue);
    }, [issue]);

    const getSubEntities = useCallback(
        async (issueId: string): Promise<void> => {
            setLoadingStateSubAssets(LoadingState.IS_LOADING);
            setSubAssets(undefined);
            try {
                const subEntitiesList = await fetchMainEntities(issueId);
                const subAssetsListWithoutMainAsset: IProtectedAssetViewModel[] = subEntitiesList.filter(
                    (asset) => asset.id !== mainAssetID,
                );
                setSubAssets(subAssetsListWithoutMainAsset.length > 0 ? subAssetsListWithoutMainAsset : undefined);
                setLoadingStateSubAssets(LoadingState.LOADING_SUCCEEDED);
            } catch (error) {
                setLoadingStateSubAssets(LoadingState.LOADING_FAILED);
            }
        },
        [mainAssetID],
    );

    useEffect(() => {
        if (issue && mainAssetID) {
            void getSubEntities(issue.id);
        }
    }, [getSubEntities, issue, mainAssetID]);

    useEffect(() => {
        if (subAssets && subAssets.length > 0) {
            setSelectedSubAsset(subAssets[0]);
        } else {
            setSelectedSubAsset(undefined);
        }
    }, [subAssets]);

    const loadSubEntities = (): ReactNode => {
        return (
            <>
                {loadingStateSubAssets === LoadingState.IS_LOADING && null}
                {loadingStateSubAssets === LoadingState.LOADING_FAILED && <FullSizeError />}
                {loadingStateSubAssets === LoadingState.LOADING_SUCCEEDED &&
                    selectedSubAsset &&
                    subAssets &&
                    subAssets.length && (
                        <SubAssetDetails
                            selectedSubAsset={selectedSubAsset}
                            onSelectionChange={setSelectedSubAsset}
                            subAssets={subAssets}
                        />
                    )}
            </>
        );
    };

    return (
        <IssueProvider issue={issue}>
            <AssetPropertiesPanel
                registeredEntityFields={entityFields}
                typeByPlatform={issue.entityTypeByPlatform}
                getCloudEntity={getCloudEntityFromIssue}
                headerComponent={EntityNameLinkComponent}
                title={t('PROTECTED_ASSETS.SUB_ASSETS.FOUND_IN_ASSET')}
                onAssetLoaded={setMainAssetID}
            >
                {loadSubEntities()}
            </AssetPropertiesPanel>
        </IssueProvider>
    );
};
