import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { GetSbom } from 'modules/workloads/services/sbom/sbom.interface';
import { SBOM_SUPPORTED_WORKLOAD_SET } from './consts';
import WorkloadService from 'modules/workloads/services/workload/workload.service';
import { AssetTypes } from 'common/assets/assets.const';

export const isScanEngineVersion2 = (scanInfo: GetSbom.Response['scanInfo'] | undefined) => {
    return scanInfo?.scanProducer.includes('V2') ?? false;
};

export const getSbomEntityData = async (protectedAsset: IProtectedAssetViewModel) => {
    const { typeByPlatform, entityId, type } = protectedAsset;

    if (!SBOM_SUPPORTED_WORKLOAD_SET.has(typeByPlatform)) {
        return { entityId, entityType: type };
    }

    let images = [];
    try {
        const { data } = await WorkloadService.getImages({ request: { dome9Id: protectedAsset.id } });
        images = data;
    } catch {
        return null;
    }

    if (images.length === 0) {
        return null;
    }
    return { entityId: images[0].imageId, entityType: AssetTypes.KUBERNETES_KUBERNETESIMAGE.split('|')[1] };
};
