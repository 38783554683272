import {
    GetVulnerabilityExclusions,
    GetVulnerabilityScan,
    GetVulnerabilityScanMetadata,
    CreateVulnerabilityExclusions,
    DeleteVulnerabilityExclusions,
    EditVulnerabilityExclusions,
} from './vulnerability.interface';
import { GetScanEngineVersion } from './scanEngineVersion.interface';
import { getHttpService } from 'common/interface/services';
import { AxiosResponse } from 'axios';
import { SERVICES_TAGS } from '../services.consts';

const getScanEngineVersion: GetScanEngineVersion.Function = async () => {
    return await getHttpService().request<AxiosResponse<GetScanEngineVersion.Response>>(
        'vulnerability/scan-engine-version',
        { method: 'GET' },
        { returnAsAxiosResponse: true, cachingConfig: { useCache: true } },
        (err) => {
            throw err;
        },
    );
};

const getVulnerabilityScan: GetVulnerabilityScan.Function = async ({
    entityId,
    entityType,
    environmentId,
    remediationOnly,
}) => {
    return await getHttpService().request<AxiosResponse<GetVulnerabilityScan.Response>>(
        `vulnerability/scan?entityId=${entityId}&entityType=${entityType}&environmentId=${environmentId}&hasRemediation=${remediationOnly}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true, cachingConfig: { useCache: true } },
        (err) => {
            throw err;
        },
    );
};

const getVulnerabilityScanMetadata: GetVulnerabilityScanMetadata.Function = async ({
    entityId,
    entityType,
    environmentId,
}) => {
    return await getHttpService().request<AxiosResponse<GetVulnerabilityScanMetadata.Response>>(
        `vulnerability/scan-metadata?entityId=${entityId}&entityType=${entityType}&environmentId=${environmentId}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true, cachingConfig: { useCache: true } },
        (err) => {
            throw err;
        },
    );
};

const getVulnerabilityExclusions = async () => {
    return await getHttpService().request<AxiosResponse<GetVulnerabilityExclusions.Response>>(
        'vulnerability/exclusions',
        { method: 'GET' },
        {
            returnAsAxiosResponse: true,
            cachingConfig: { useCache: true, tags: [SERVICES_TAGS.VULNERABILITY_EXCLUSIONS] },
        },
        (err) => {
            throw err;
        },
    );
};

const createVulnerabilityExclusions = async (request: CreateVulnerabilityExclusions.Request) => {
    return await getHttpService().request<AxiosResponse<CreateVulnerabilityExclusions.Response>>(
        'vulnerability/exclusions',
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true, cachingConfig: { useCache: true } },
        (err) => {
            throw err;
        },
    );
};

const editVulnerabilityExclusions = async (request: EditVulnerabilityExclusions.Request) => {
    return await getHttpService().request<AxiosResponse<EditVulnerabilityExclusions.Response>>(
        'vulnerability/exclusions',
        { method: 'PUT', data: request },
        { returnAsAxiosResponse: true, cachingConfig: { useCache: true } },
        (err) => {
            throw err;
        },
    );
};

const deleteVulnerabilityExclusions = async (request: DeleteVulnerabilityExclusions.Request) => {
    return await getHttpService().request<AxiosResponse<DeleteVulnerabilityExclusions.Response>>(
        'vulnerability/exclusions/bulk-delete',
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true, cachingConfig: { useCache: true } },
        (err) => {
            throw err;
        },
    );
};

const vulnerabilityService = {
    getScanEngineVersion,
    getVulnerabilityScanMetadata,
    getVulnerabilityScan,
    getVulnerabilityExclusions,
    createVulnerabilityExclusions,
    deleteVulnerabilityExclusions,
    editVulnerabilityExclusions,
};

export default vulnerabilityService;
