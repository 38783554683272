import { getCurrentDataCenter } from 'common/utils/RuntimeEnvironment';

export interface Cluster {
    id: string;
    admissionControlEnabled: boolean;
    runtimeProtectionEnabled: boolean;
    threatIntelligenceEnabled: boolean;
    imageAssuranceEnabled: boolean;
    imageAccessRuntimeMonitorEnabled: boolean;
}

export interface GetHelmCommandBody {
    apiKey: string;
    apiSecret: string;
    namespace: string;
    cluster: Cluster;
    isEaEnabled?: boolean;
}

export type GetHelmCommand = (body: GetHelmCommandBody) => string;

export const getHelmCommand: GetHelmCommand = (body) => {
    const { apiKey, apiSecret, namespace, cluster, isEaEnabled } = body;
    const {
        id,
        admissionControlEnabled,
        runtimeProtectionEnabled,
        threatIntelligenceEnabled,
        imageAssuranceEnabled,
        imageAccessRuntimeMonitorEnabled,
    } = cluster;
    const dataCenter = getCurrentDataCenter()?.dataCenter;

    const helmCommand = Object.values({
        baseCommand: 'helm upgrade --install asset-mgmt cloudguard',
        repoCommand: `--repo https://raw.githubusercontent.com/CheckPointSW/charts/${isEaEnabled ? 'ea' : 'master'}/repository/`,
        setCredentials: `--set credentials.user=${apiKey} --set credentials.secret=${apiSecret}`,
        setClusterId: `--set clusterID=${id}`,

        setThreatIntelligenceEnabled: threatIntelligenceEnabled ? '--set addons.flowLogs.enabled=true' : false,
        setRuntimeProtectionEnabled: runtimeProtectionEnabled ? '--set addons.runtimeProtection.enabled=true' : false,
        setImageScanEnabled: imageAssuranceEnabled ? '--set addons.imageScan.enabled=true' : false,
        setAdmissionControlEnabled: admissionControlEnabled ? '--set addons.admissionControl.enabled=true' : false,
        setImageAssuranceFileAccessMonitorEnabled: imageAccessRuntimeMonitorEnabled
            ? '--set addons.imageScan.armon.enabled=true'
            : false,

        dataCenter: dataCenter ? `--set datacenter=${dataCenter}` : false,

        setImageRegistryUser: threatIntelligenceEnabled ? '--set imageRegistry.user="checkpoint+consec_read"' : false,
        setImageRegistryPassword: threatIntelligenceEnabled
            ? '--set imageRegistry.password=V08FPKKJSHP8YJYLE571MMLAHPOSPX1ASFBI4P875L4ZNQWEXUCEU0V4ASWCZVAZ'
            : false,

        namespaceCommand: `--namespace ${namespace} --create-namespace`,
    })
        .filter(Boolean)
        .join(' ');

    return helmCommand;
};

export const getNonHelmCommand: GetHelmCommand = (body) => {
    const { apiKey, apiSecret, cluster, isEaEnabled } = body;
    const {
        id,
        admissionControlEnabled,
        runtimeProtectionEnabled,
        threatIntelligenceEnabled,
        imageAssuranceEnabled,
        imageAccessRuntimeMonitorEnabled,
    } = cluster;
    const dataCenter = getCurrentDataCenter()?.dataCenter;

    const nonHelmCommand = Object.values({
        baseCommand:
            'kubectl run cloudguard-install --rm --image alpine/helm --tty --stdin --quiet --restart=Never --command -- helm template asset-mgmt cloudguard',
        repoCommand: `--repo https://raw.githubusercontent.com/CheckPointSW/charts/${isEaEnabled ? 'ea' : 'master'}/repository/`,
        setCredentials: `--set credentials.user=${apiKey} --set credentials.secret=${apiSecret}`,
        setClusterId: `--set clusterID=${id}`,

        setImageScanEnabled: imageAssuranceEnabled ? '--set addons.imageScan.enabled=true' : false,
        setAdmissionControlEnabled: admissionControlEnabled ? '--set addons.admissionControl.enabled=true' : false,
        setRuntimeProtectionEnabled: runtimeProtectionEnabled ? '--set addons.runtimeProtection.enabled=true' : false,
        setThreatIntelligenceEnabled: threatIntelligenceEnabled ? '--set addons.flowLogs.enabled=true' : false,
        setImageAssuranceFileAccessMonitorEnabled: imageAccessRuntimeMonitorEnabled
            ? '--set addons.imageScan.armon.enabled=true'
            : false,

        dataCenter: dataCenter ? `--set datacenter=${dataCenter}` : false,

        setImageRegistryUser: threatIntelligenceEnabled ? '--set imageRegistry.user="checkpoint+consec_read"' : false,
        setImageRegistryPassword: threatIntelligenceEnabled
            ? '--set imageRegistry.password=V08FPKKJSHP8YJYLE571MMLAHPOSPX1ASFBI4P875L4ZNQWEXUCEU0V4ASWCZVAZ'
            : false,
        ending: '--set containerRuntime=containerd --kube-version 1.24 > cloudguard-install.yaml',
    })
        .filter(Boolean)
        .join(' ');

    return nonHelmCommand;
};
