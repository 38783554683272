import { UserRolesTypes } from 'common/enum/UserRoles';
import { ActiveFeaturesTypes } from 'common/enum/ActiveFeatures';
import { addMainMenuItem } from 'common/helpers/menu';
import { getIsCloudInfra } from 'common/utils/RuntimeEnvironment';
import { initializeIntegrations } from './Integrations/initialize';
import { initializeNotificationsPage } from './NotificationsPage/initialize';
import { getAppRootRegistry, getUserService } from 'common/interface/services';
import { NEW_NOTIFICATIONS_PAGE_AF } from 'common/module_interface/settings/integrations/Integrations';
import { integrationNotificationEnabled, isCredentialsEnabled } from 'common/utils/userPermissionUtils';
import { initializeRoles } from './Roles/RolesPage/initRolesPage';
import { initializeUsers } from './Users/UsersPage/initUsersPage';
import { initializeServiceAccounts } from './ServiceAccounts/ServiceAccountsPage/initServiceAccountsPage';
import { validLanguages } from 'common/services/translations/translations';
import i18n from 'i18next';
import initializeEmailNotificationsPage from './EmailNotificationsPage/initialize';
import { lazy } from 'react';

const HIDE_ADMINISTRATION_MENU = 'hide-administration-menu';
const OWNERSHIP_CONFIG = 'ownership-config';

export function initializeMenuItems() {
    const isCloudInfra = getIsCloudInfra();
    const jitUserId = -1;
    const menuItem = {
        id: 'settings',
        position: 111,
        icon: 'settings',
        label: 'NAVIGATION_MENU.SETTINGS.TITLE',
        permission: [UserRolesTypes.ALL],
        exclude: [HIDE_ADMINISTRATION_MENU],
        descriptionKeys: ['MENU.ADMINISTRATIONS.DESCRIPTION.1', 'MENU.ADMINISTRATIONS.DESCRIPTION.2'],
        sections: [
            {
                id: 'account',
                position: 10,
                title: 'NAVIGATION_MENU.SETTINGS.ACCOUNT',
                items: [
                    {
                        id: 'account info',
                        position: 10,
                        label: 'NAVIGATION_MENU.SETTINGS.ACCOUNT_INFO',
                        state: '/account',
                        permission: [UserRolesTypes.ALL],
                    },
                    {
                        id: 'cross account trust',
                        position: 20,
                        label: 'NAVIGATION_MENU.SETTINGS.CROSS_ACCOUNT_TRUST',
                        state: '/settings/trust',
                        permission: [UserRolesTypes.SUPER_USER, ActiveFeaturesTypes.ALLOW_TRUST_FEATURE],
                    },
                    {
                        id: 'credentials',
                        position: 30,
                        label: 'NAVIGATION_MENU.SETTINGS.CREDENTIALS',
                        state: '/settings/credentials',
                        exclude: ['assumed-rule'],
                        permission: () =>
                            getUserService().getUser() &&
                            getUserService().getUser().id !== jitUserId &&
                            isCredentialsEnabled(),
                    },
                    {
                        id: 'mobile devices',
                        position: 40,
                        label: 'NAVIGATION_MENU.SETTINGS.MOBILE_DEVICES',
                        state: '/settings/devices',
                        exclude: ['assumed-rule'],
                        permission: () => getUserService().getUser() && getUserService().getUser().id !== jitUserId,
                    },
                ],
            },
            {
                id: 'roles',
                position: 20,
                title: isCloudInfra ? 'NAVIGATION_MENU.SETTINGS.ROLES' : 'NAVIGATION_MENU.SETTINGS.USERS_AND_ROLES',
                items: [
                    {
                        id: 'users',
                        position: 10,
                        label: 'NAVIGATION_MENU.SETTINGS.USERS',
                        state: '/users-management/users',
                        permission: [UserRolesTypes.CLOUD_GUARD_MANAGED],
                    },
                    {
                        id: 'service accounts',
                        position: 20,
                        label: 'NAVIGATION_MENU.SETTINGS.SERVICE_ACCOUNTS',
                        state: '/users-managementservice-accounts',
                        permission: [UserRolesTypes.SUPER_USER],
                    },
                    {
                        id: 'roles',
                        position: 30,
                        label: 'NAVIGATION_MENU.SETTINGS.ROLES',
                        state: '/users-management/roles',
                        permission: [UserRolesTypes.ALL],
                    },
                ],
            },
            {
                id: 'configuration',
                position: 30,
                title: 'NAVIGATION_MENU.SETTINGS.CONFIGURATION',
                items: [
                    {
                        id: 'notifications',
                        position: 10,
                        label: 'NAVIGATION_MENU.ALERTING_AND_REPORTING.NOTIFICATIONS',
                        state: '/notifications',
                        permission: ['!new-integrations'],
                    },
                    {
                        id: 'new-notifications',
                        position: 10,
                        label: 'NAVIGATION_MENU.ALERTING_AND_REPORTING.NOTIFICATIONS',
                        state: '/notifications-list',
                        permission: () =>
                            integrationNotificationEnabled() &&
                            getUserService().hasPermission([NEW_NOTIFICATIONS_PAGE_AF]),
                    },
                    {
                        id: 'security and authentication',
                        position: 20,
                        label: 'NAVIGATION_MENU.SETTINGS.SECURITY_AND_AUTHENTICATION',
                        state: '/settings/authentication',
                        permission: [UserRolesTypes.ALL],
                    },
                    {
                        id: 'email notifications',
                        position: 30,
                        label: 'NAVIGATION_MENU.SETTINGS.EMAIL_NOTIFICATIONS',
                        state: '/settings/email',
                        exclude: ['assumed-rule'],
                        permission: [UserRolesTypes.PROVISIONED],
                    },
                    {
                        id: 'access leases',
                        position: 40,
                        label: 'NAVIGATION_MENU.SETTINGS.ACCESS_LEASES',
                        state: '/settings/access',
                        exclude: ['assumed-rule'],
                        permission: [UserRolesTypes.ALL],
                    },
                    {
                        id: 'integrations',
                        position: 50,
                        label: 'NAVIGATION_MENU.SETTINGS.INTEGRATIONS',
                        state: '/settings/integrations',
                        permission: ['!new-integrations'],
                    },
                    {
                        id: 'workload',
                        position: 60,
                        label: 'NAVIGATION_MENU.SETTINGS.WORKLOADS',
                        state: '/settings/workload',
                        permission: [UserRolesTypes.ALL],
                    },
                    {
                        id: 'ownership',
                        position: 70,
                        label: 'NAVIGATION_MENU.SETTINGS.OWNERSHIP',
                        state: '/settings/ownership',
                        permission: [OWNERSHIP_CONFIG],
                    },
                ],
            },
        ],
    };

    addMainMenuItem(menuItem);
}

export const I18nSettingsTranslationKey = 'Settings';

export const initializeI18nSettings = async () => {
    return Promise.all(
        validLanguages.map(async (language) => {
            try {
                const contents = await require(`./languages/${language}/translation.json`);
                i18n.addResourceBundle(language, `${I18nSettingsTranslationKey}`, contents);
            } catch {
                //Nothing to do with missing translation files
            }
        }),
    );
};

export default function initialize() {
    initializeMenuItems();
    initializeNotificationsPage();
    initializeIntegrations();
    initializeEmailNotificationsPage();

    const accessLeasesPageRoute = {
        condition: true,
        component: lazy(() => import('./AccessLeases/AccessLeases')),
        path: '/settings/access',
    };
    const emailPageRoute = () => {
        return {
            condition: getUserService().hasPermission([UserRolesTypes.ALL]),
            component: lazy(() => import('./EmailNotificationsPage/EmailNotificationsPage')),
            path: '/settings/email',
        };
    };

    const notificationPageRoute = () => {
        return {
            condition: () =>
                integrationNotificationEnabled() && getUserService().hasPermission([NEW_NOTIFICATIONS_PAGE_AF]),
            component: lazy(() => import('./NotificationsPage/NotificationsPage')),
            path: '/notifications-list',
        };
    };

    const integrationsPageRoute = {
        condition: true,
        component: lazy(() => import('./Integrations/IntegrationMainPage/IntegrationMainWrapper')),
        path: '/settings/integrations-list',
    };

    getAppRootRegistry().addRoutes([
        { id: 'Access Leases', content: accessLeasesPageRoute },
        { id: 'integrations', content: integrationsPageRoute },
        { id: 'Notification Page', content: notificationPageRoute },
        { id: 'Email Page', content: emailPageRoute },
    ]);

    initializeUsers();
    initializeRoles();
    initializeServiceAccounts();
}
