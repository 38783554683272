import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BaseImageList from '../BaseImageList/BaseImageList';
import { AxiosResponse } from 'axios';
import ResourcePanel from '../../../pages/cveDrawer/components/ResourcePanel';
import { AssetPropertiesPanel } from 'common/components/AssetPropertiesPanel/AssetPropertiesPanel';
import { BASE_IMAGE_INFO_FIELDS, DrawerInfoFieldsBaseImage } from '../../initialize.drawers.infoFields';
import { removeShaFromImage } from '../../../utils';
import { getCloudEntityData } from 'common/components/ProtectedAssets/AssetUtils';
import { getHttpService } from 'common/interface/services';
import {
    IProtectedAssetViewModel,
    ProtectedAssetsResponse,
} from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { getAdditionalFieldValue } from 'common/utils/ColumnDisplayValueGetters';
import { VENDORS } from 'common/consts/vendors';
import { CveOverview } from '../../../services/cveDrawerRegistry/cveDrawerRegistry.types';

const BaseImageAssetPropertiesPanel: React.FC<{ cveParams: CveOverview }> = ({ cveParams }) => {
    const [selectedOption, setSelectedOption] = useState<string>('');
    const [options, setOptions] = useState<ProtectedAssetsResponse['assets']>([]);

    const {
        package: { baseImages: baseImagesList },
    } = cveParams;

    const setEntityFieldsUseMemo = useMemo(() => {
        return DrawerInfoFieldsBaseImage.getFields(BASE_IMAGE_INFO_FIELDS);
    }, []);

    useEffect(() => {
        (async () => {
            const baseImages = await fetchImagesByImageId(removeShaFromImage(baseImagesList[0].externalId));
            const sortedBaseImages = sortBaseImages(baseImages);
            const [firstImage] = sortedBaseImages;
            setOptions(sortedBaseImages);
            setSelectedOption(getSelectNameValue(firstImage.name, firstImage.cloudAccountId));
        })();
    }, [baseImagesList]);

    const getSelectNameValue = (name: string, cloudAccountId: string) => `${name}-${cloudAccountId}`;

    const findOptionByValue = useCallback(
        (value: string) => options.find((option) => getSelectNameValue(option.name, option.cloudAccountId) === value),
        [options],
    );

    const getCloudEntityDataPromise = useCallback(async () => {
        const image = findOptionByValue(selectedOption);
        if (!image) return null;
        return await getCloudEntityData(image.id, image.type, image.platform, image.cloudAccountId);
    }, [findOptionByValue, selectedOption]);

    const fetchImagesByImageId = async (imageId: string) => {
        const reqObj = {
            filter: {
                fields: [
                    {
                        name: 'additionalFields|ImageId',
                        value: imageId,
                    },
                ],
            },
        };
        const { data } = await getHttpService().request<AxiosResponse<ProtectedAssetsResponse>>(
            'protected-asset/search',
            { method: 'POST', data: reqObj },
            { returnAsAxiosResponse: true },
            (err) => {
                throw err;
            },
        );
        return data.assets;
    };

    const buildOptions = (options: IProtectedAssetViewModel[]) => {
        return options.map((image) => ({
            value: getSelectNameValue(image.name, image.cloudAccountId),
            label: `${getAdditionalFieldValue(image, 'Repository')}:${getAdditionalFieldValue(image, 'Tag')}`,
            labelProps: {
                leadingIconProps: { name: VENDORS.find((vendor) => vendor.name === image.platform)?.newIcon },
            },
        }));
    };

    const sortBaseImages = (baseImages: IProtectedAssetViewModel[]) => {
        return baseImages.sort((a, b) => {
            const imageA = getAdditionalFieldValue(a, 'BaseImage');
            const imageB = getAdditionalFieldValue(b, 'BaseImage');
            if (imageA !== null && imageB !== null) {
                if (imageA > imageB) return 1;
                if (imageA < imageB) return -1;
            }
            return 0;
        });
    };

    const onChangeBaseImage = async (value: string) => {
        const image = findOptionByValue(value);
        if (!image) return;
        setSelectedOption(getSelectNameValue(image.name, image.cloudAccountId));
    };

    if (!options.length) return null;

    return (
        <AssetPropertiesPanel
            title={'Base Image'}
            typeByPlatform={findOptionByValue(selectedOption)?.typeByPlatform || ''}
            registeredEntityFields={setEntityFieldsUseMemo}
            getCloudEntity={getCloudEntityDataPromise}
            headerComponent={
                options.length > 0 ? (
                    <BaseImageList
                        onChange={onChangeBaseImage}
                        selectedOption={selectedOption}
                        options={buildOptions(options)}
                    />
                ) : null
            }
        >
            <ResourcePanel overview={cveParams} />
        </AssetPropertiesPanel>
    );
};

export default BaseImageAssetPropertiesPanel;
