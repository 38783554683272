import { AxiosResponse } from 'axios';
import { ICachingConfig, getHttpService } from 'common/interface/services';
import {
    ComplianceFindingComment,
    ComplianceFindingSeverity,
    ComplianceFindingAcknowledge,
    ComplianceFindingAssign,
    ComplianceFindingClose,
    ComplianceFindingArchive,
    ComplianceFindingSearch,
    ComplianceFindingSearchWithCustomAggregations,
    ComplianceFindingGroupsByProperties,
} from 'common/module_interface/events/complianceFindingApi.interface';
import { getElasticEventListFromModels } from 'common/module_interface/elasticEvents/ElasticEvent.serviceUtils';
import { IComplianceFindingService } from 'common/module_interface/events/Events';

const apiBaseUrl = 'Compliance/Finding';

export class ComplianceFindingApi implements IComplianceFindingService {
    async addComment(
        request: ComplianceFindingComment.Request,
    ): Promise<AxiosResponse<ComplianceFindingComment.Response>> {
        return await getHttpService().request<AxiosResponse<ComplianceFindingComment.Response>>(
            `${apiBaseUrl}/bulk/comment`,
            { method: 'PUT', data: request },
            { returnAsAxiosResponse: true },
            (err) => {
                throw err;
            },
        );
    }

    async changeSeverity(
        request: ComplianceFindingSeverity.Request,
    ): Promise<AxiosResponse<ComplianceFindingSeverity.Response>> {
        return await getHttpService().request<AxiosResponse<ComplianceFindingSeverity.Response>>(
            `${apiBaseUrl}/bulk/severity`,
            { method: 'PUT', data: request },
            { returnAsAxiosResponse: true },
            (err) => {
                throw err;
            },
        );
    }

    async acknowledge(
        request: ComplianceFindingAcknowledge.Request,
    ): Promise<AxiosResponse<ComplianceFindingAcknowledge.Response>> {
        return await getHttpService().request<AxiosResponse<ComplianceFindingAcknowledge.Response>>(
            `${apiBaseUrl}/bulk/acknowledge`,
            { method: 'PUT', data: request },
            { returnAsAxiosResponse: true },
            (err) => {
                throw err;
            },
        );
    }

    async assign(request: ComplianceFindingAssign.Request): Promise<AxiosResponse<ComplianceFindingAssign.Response>> {
        return await getHttpService().request<AxiosResponse<ComplianceFindingAssign.Response>>(
            `${apiBaseUrl}/bulk/assign`,
            { method: 'PUT', data: request },
            { returnAsAxiosResponse: true },
            (err) => {
                throw err;
            },
        );
    }

    async close(request: ComplianceFindingClose.Request): Promise<AxiosResponse<ComplianceFindingClose.Response>> {
        return await getHttpService().request<AxiosResponse<ComplianceFindingClose.Response>>(
            `${apiBaseUrl}/bulk/close`,
            { method: 'POST', data: request },
            { returnAsAxiosResponse: true },
            (err) => {
                throw err;
            },
        );
    }

    async archive(
        request: ComplianceFindingArchive.Request,
    ): Promise<AxiosResponse<ComplianceFindingArchive.Response>> {
        return await getHttpService().request<AxiosResponse<ComplianceFindingArchive.Response>>(
            `${apiBaseUrl}/bulk/archive`,
            { method: 'PUT', data: request },
            { returnAsAxiosResponse: true },
            (err) => {
                throw err;
            },
        );
    }

    async unarchive(
        request: ComplianceFindingArchive.Request,
    ): Promise<AxiosResponse<ComplianceFindingArchive.Response>> {
        return await getHttpService().request<AxiosResponse<ComplianceFindingArchive.Response>>(
            `${apiBaseUrl}/bulk/unarchive`,
            { method: 'PUT', data: request },
            { returnAsAxiosResponse: true },
            (err) => {
                throw err;
            },
        );
    }

    async search(
        request: ComplianceFindingSearch.Request,
        cachingConfig?: ICachingConfig,
    ): Promise<AxiosResponse<ComplianceFindingSearch.Response>> {
        return await getHttpService().request<AxiosResponse<ComplianceFindingSearch.Response>>(
            `${apiBaseUrl}/search`,
            { method: 'POST', data: request },
            { returnAsAxiosResponse: true, cachingConfig },
            (err) => {
                throw err;
            },
        );
    }

    async searchWithCustomAggregations(
        request: ComplianceFindingSearchWithCustomAggregations.Request,
        cachingConfig?: ICachingConfig,
    ): Promise<AxiosResponse<ComplianceFindingSearchWithCustomAggregations.Response>> {
        const modelResponse: AxiosResponse<ComplianceFindingSearchWithCustomAggregations.ModelResponse> =
            await getHttpService().request<AxiosResponse<ComplianceFindingSearchWithCustomAggregations.ModelResponse>>(
                `${apiBaseUrl}/searchWithCustomAggregations`,
                { method: 'POST', data: request },
                { returnAsAxiosResponse: true, cachingConfig },
                (err) => {
                    throw err;
                },
            );

        return {
            ...modelResponse,
            data: {
                ...modelResponse.data,
                findings: modelResponse.data.findings ? getElasticEventListFromModels(modelResponse.data.findings) : [],
            },
        };
    }

    async groupsByProperties(
        request: ComplianceFindingGroupsByProperties.Request,
        cachingConfig?: ICachingConfig,
    ): Promise<AxiosResponse<ComplianceFindingGroupsByProperties.Response>> {
        return await getHttpService().request<AxiosResponse<ComplianceFindingGroupsByProperties.Response>>(
            `${apiBaseUrl}/GroupsByProperties`,
            { method: 'POST', data: request },
            { returnAsAxiosResponse: true, cachingConfig },
            (err) => {
                throw err;
            },
        );
    }
}
