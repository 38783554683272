import { License } from 'modules/workloads/services/sbom/sbom.interface';
import { Package } from 'modules/workloads/services/vulnerability/vulnerability.interface';

export interface IPackageDrawerProps {
    id: string;
    showExplorerPackageButton?: boolean;
}

export type TPackageDrawerParams = IPackageDrawerProps;

export interface IAffectedAsset {
    path: string;
    licenses: License[];
    externalId: string;
    id: string;
}

export type TPackageInfo = Pick<Package, 'id' | 'name' | 'version'> & {
    manager: Package['packageManager']['name'];
    path: Package['packageManager']['path'][];
    isOsPackage: Package['is-os-package'][];
} & { licenses: License[] };

export enum EPackageInfoStateTypes {
    LOADING = 'LOADING',
    LOADED = 'LOADED',
    ERROR = 'ERROR',
}

export type TPackageInfoState =
    | { type: EPackageInfoStateTypes.LOADING }
    | { type: EPackageInfoStateTypes.LOADED; data: TPackageInfo }
    | { type: EPackageInfoStateTypes.ERROR };
