import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Button, Stack } from 'common/design-system/components-v2';
import { IIssueCve } from '../Issues.interface';
import { ermTrans } from '../../../RiskManagement.utils';
import { createIgnoreItemForCve } from '../../IgnoreList/CveIgnoreList/CveIgnoreList.items';

export const IgnoreCveActionRenderer: React.FC<ICellRendererParams<IIssueCve>> = (params) => {
    const cve: IIssueCve | undefined = params.data;
    if (!cve) {
        return null;
    }

    return (
        <Stack justifyContent={'center'} alignItems={'center'}>
            <Button
                variant='contained'
                tooltip={ermTrans('IGNORE_LIST.CVE_IGNORE_LIST.ACTIONS.IGNORE_TOOLTIP')}
                onClick={() => createIgnoreItemForCve(cve)}
            >
                {ermTrans('IGNORE_LIST.CVE_IGNORE_LIST.ACTIONS.IGNORE')}
            </Button>
        </Stack>
    );
};
