import styled from 'styled-components';

export const Dot = styled.div`
    height: 10px;
    width: 10px;
    background-color: ${({ theme }) => theme.palette.onSurface.light};
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.palette.onSurface.light};
    display: inline-block;
`;
