import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AzureInitialState, AzureState } from './azure.interface';
import { AzureEnvironmentData, UpdateCredentialsAzureResponse } from 'common/module_interface/assets/AzureEnvironment';

const AzureSlice = createSlice({
    name: 'env_azure',
    initialState: AzureInitialState,
    reducers: {
        setLoading: (state: AzureState, action: PayloadAction<{ azureId: string; loading: boolean }>) => {
            const { azureId, loading } = action.payload;
            if (!state.azureEvnironments[azureId]) {
                state.azureEvnironments[azureId] = {};
            }
            state.azureEvnironments[azureId].loading = loading;
        },
        setError: (state: AzureState, action: PayloadAction<{ azureId: string; error: boolean }>) => {
            const { azureId, error } = action.payload;
            if (!state.azureEvnironments[azureId]) {
                state.azureEvnironments[azureId] = {};
            }
            state.azureEvnironments[azureId].error = error;
        },
        setData: (state: AzureState, action: PayloadAction<{ azureId: string; data: AzureEnvironmentData }>) => {
            const { azureId, data } = action.payload;
            if (!state.azureEvnironments[azureId]) {
                state.azureEvnironments[azureId] = {};
            }
            state.azureEvnironments[azureId].data = data;
        },
        setDeleted: (state: AzureState, action: PayloadAction<{ azureId: string }>) => {
            const { azureId } = action.payload;
            if (!state.azureEvnironments[azureId]) {
                state.azureEvnironments[azureId] = { deleted: true };
            }
            state.azureEvnironments[azureId].deleted = true;
        },
        setCredentialsResponse: (
            state: AzureState,
            action: PayloadAction<{ azureId: string; response: UpdateCredentialsAzureResponse }>,
        ) => {
            const { azureId, response } = action.payload;
            if (!state.azureEvnironments[azureId]) {
                state.azureEvnironments[azureId] = {};
            }
            state.azureEvnironments[azureId].credentialsResponse = response;
        },
    },
});

export default AzureSlice;
