import { BenchmarkHeaderProps } from './BenchmarkHeader.types';
import EntityCard from 'common/design-system/components-v2/EntityCard';
import ScanStatusTag from '../../../components/ScanStatus';
import { IChipProps } from 'common/design-system/components-v2/Chip/Chip.types';
import moment from 'moment-mini';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';

const BenchmarkHeader = (props: BenchmarkHeaderProps) => {
    const { t } = useTranslation(getK8sNamespace('cis'));
    const { benchmarkMetadata, benchmarkData } = props;

    const lastSuccessfulResultTime = moment(benchmarkMetadata?.lastSuccessfulResultTime).format('lll');

    const { pass, fail } = benchmarkData?.statistics.status || { pass: 0, fail: 0 };
    const { total } = benchmarkData?.statistics || { total: 0 };
    const statitics = `(Passed: ${pass}, Failed: ${fail})`;

    const chips: IChipProps[] = [
        {
            label: `Rules Passed: ${pass}/${total}`,
            context: 'info',
        },
    ];

    const entityBlocks = [
        {
            title: t('benchmarkHeader.id'),
            info: benchmarkData?.benchmarkId,
        },
        {
            title: t('benchmarkHeader.framework'),
            info: 'CIS',
        },
        {
            title: t('benchmarkHeader.version'),
            info: benchmarkData?.benchmarkVersion,
        },
        {
            title: t('benchmarkHeader.rulesEvaluated'),
            info: `${total} ${statitics}`,
        },
        {
            title: t('benchmarkHeader.lastSuccessfulScan'),
            info: lastSuccessfulResultTime,
        },
    ];

    return (
        <EntityCard
            title={`${benchmarkData?.benchmarkTitle}`}
            levelIcon={{
                category: 'importance',
                iconProps: {
                    name: 'cis',
                },
                level: 'unknown',
            }}
            blocks={entityBlocks}
            chips={chips}
            titleRightElements={<ScanStatusTag scanDate={lastSuccessfulResultTime} scanProducer='CloudGuard' />}
        />
    );
};

export default BenchmarkHeader;
