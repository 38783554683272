import { GridOptions } from 'ag-grid-community';
import React, { useCallback, useRef } from 'react';
import { GridApi } from 'ag-grid-enterprise';
import '../../Intelligence.scss';
import withShowMoreButton from 'common/erm-components/utils/WithShowMore/WithShowMore';
import { Stack, Table } from 'common/design-system/components-v2';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { getIssueEvidenceCdrEventsTableColumns } from '../CdrEvents/CdrEventsPage.columns';
import { mergeColumnDefs } from 'common/utils/tableUtils';
import { FindingsTableRegistry } from 'common/components/KustoEvents/FindingsTableRegistry';

const IssueCDREventsTable: React.FC<{ events: IKustoEvent[] }> = ({ events }) => {
    const flexValuesFoColumns = [2, 2, 8];

    const findingsColumns = mergeColumnDefs(
        getIssueEvidenceCdrEventsTableColumns(),
        FindingsTableRegistry.getColumnDefs(),
    ).map((colDef, index) => ({ ...colDef, flex: flexValuesFoColumns[index % flexValuesFoColumns.length] }));

    const apiRef = useRef<GridApi>();

    const onGridReady = useCallback((params: any) => {
        apiRef.current = params.api;
    }, []);

    const gridOptions: GridOptions = {
        columnDefs: findingsColumns,
        defaultColDef: {
            sortable: false,
            resizable: true,
            suppressMenu: true,
            suppressMovable: true,
        },
        rowModelType: 'clientSide',
        domLayout: 'autoHeight',
        suppressDragLeaveHidesColumns: true,
        rowData: events,
        enableRangeSelection: false,
        suppressCellFocus: true,
        onGridReady,
        masterDetail: true,
        detailRowAutoHeight: true,
    };

    const TableWithButton = withShowMoreButton(Table);

    return (
        <Stack fullWidth>
            <TableWithButton gridOptions={gridOptions} disableColumnMenu={true} disableGrouping={true} />
        </Stack>
    );
};

export default IssueCDREventsTable;
