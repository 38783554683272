import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'common/services/store/store';
import IAccount from './Account.interface';

interface SwitchRoleComponentState {
    selectedAccount?: IAccount;
    selectedRole?: string;
    isUserRolesModalIsOpen: boolean;
}

const initialState: SwitchRoleComponentState = {
    isUserRolesModalIsOpen: false,
};

const SwitchRoleComponentSlice = createSlice({
    name: 'switchRoleComponentState',
    initialState,
    reducers: {
        setSelectedAccount: (state: SwitchRoleComponentState, action: PayloadAction<IAccount>) => {
            state.selectedAccount = action.payload;
        },
        setSelectedRole: (state: SwitchRoleComponentState, action: PayloadAction<string>) => {
            state.selectedRole = action.payload;
        },
        setUserRolesModalIsOpen: (state: SwitchRoleComponentState, action: PayloadAction<boolean>) => {
            state.isUserRolesModalIsOpen = action.payload;
        },
        clearSwitchRoleComponentState: () => initialState,
    },
});

export const { setSelectedAccount, setSelectedRole, setUserRolesModalIsOpen, clearSwitchRoleComponentState } =
    SwitchRoleComponentSlice.actions;

export const getSelectedAccount = (state: RootState) => state.switchRoleComponentState.selectedAccount;
export const getSelectedRole = (state: RootState) => state.switchRoleComponentState.selectedRole;
export const getUserRolesModalIsOpen = (state: RootState): boolean =>
    state.switchRoleComponentState.isUserRolesModalIsOpen;
export const getSwitchRoleButtonEnabled = (state: RootState): boolean =>
    state.switchRoleComponentState.selectedAccount !== undefined &&
    state.switchRoleComponentState.selectedRole !== undefined;

export default SwitchRoleComponentSlice.reducer;
