import { UserRolesTypes } from 'common/enum/UserRoles';
import { addMainMenuItem, addMenuSectionItem } from 'common/helpers/menu';
import { getAppRootRegistry, getUserService } from 'common/interface/services';
import initializeIntelligenceFilters from './Definitions/FilterDefinitions';
import initializeNetworkTrafficPageFilters from './NetworkTrafficPage/filters';
import initializeMagellanPanels from './Panels/MagellanPanels';
import initializeIntelligenceService from './Services/IntelligenceService';
import initializeMagellanLinkGeneratorService from './Services/MagellanLinkGeneratorService';
import initializeMagellanSchemaService from './Services/MagellanSchemaService';
import { initialize_AWS_EventActivityWidgets } from './Widgets/Initialize_AWS_EventActivityWidgets';
import { initialize_AWS_TrafficActivityWidgets } from './Widgets/Initialize_AWS_TrafficActivityWidgets';
import { initialize_AZURE_EventActivityWidgets } from './Widgets/Initialize_AZURE_EventActivityWidgets';
import { initialize_AZURE_TrafficActivityWidgets } from './Widgets/Initialize_AZURE_TrafficActivityWidgets';
import { initialize_K8_TrafficActivityWidgets } from './Widgets/Initialize_K8_TrafficActivityWidgets';
import { initialAwsEnvironmentTabs } from './initialize.awsEnv';
import { initializeAzureIntelligenceOnboardingPages } from './Onboarding/Azure/InitializeAzureOnboarding';
import { IApplicationReducer } from 'common/interface/redux';
import visualizationGraphStateReducer from './Visualizations/Common/Services/GraphState.reducer';
import { isNotChina } from 'common/utils/dataCenter';
import { InitializeEnvironmetsDefs } from './EnvironmetsDefs/initialize.envDefs';
import initializeFindings from './Findings/Findings.initialize';
import i18n from 'i18next';
import { i18nIntelligenceNamespace } from './initialize.i18n';
import initializePolicy from './Policy/initialize';
import initializeCloudBotsService from './Services/CloudBotsService';
import {
    ALL_EVENTS_TABLE_ACTIVE_FEATURE,
    CDR_EXCLUSIONS_ACTIVE_FEATURE,
    CDR_LOGS_TABLE_ACTIVE_FEATURE,
} from './ActiveFeatures/ActiveFeatures.consts';
import initializeActiveFeatures from './ActiveFeatures/ActiveFeatures.initialize';
import initializeRemediationService from './Services/RemediationService';
import initializeGslService from 'common/components/gsl/GslService';
import initializeKustoEventService from 'common/components/KustoEvents/KustoEventService';
import initializeCdrExclusion from './Findings/FindingsTable/Modals/CdrExclusion/CdrExclusion.initialize';
import {
    isExclusionsEnabled,
    isPolicyEnabled,
    isRemediationEnabled,
    isRulesetEnabled,
} from 'common/utils/userPermissionUtils';
import { initializeAccountActivityLogTable } from './Logs/AccountActivity/AccountActivityLogs.initialize';
import initializeCdrUrlService from './Services/CdrUrlsService';
import { initializeCdrDashboard } from './Dashboard/CdrDashboard.initialize';
import initializeLogsService from './Services/LogsService';
import { lazy } from 'react';

const SECURITY_ALERTS = 'security-alerts';

function initializeServices() {
    initializeIntelligenceService();
    initializeMagellanLinkGeneratorService();
    initializeMagellanSchemaService();
    initializeCloudBotsService();
    initializeRemediationService();
    initializeGslService();
    initializeKustoEventService();
    initializeCdrUrlService();
    initializeLogsService();
}

export default function initialize() {
    const welcomePageRoute = () => {
        return {
            condition: getUserService().hasPermission([UserRolesTypes.ALL]),
            component: lazy(() => import('./Welcome/Welcome')),
            path: '/magellan/intelligence/welcome-page',
        };
    };

    const cdrExclusionsPageRoute = () => {
        return {
            condition: getUserService().hasPermission([UserRolesTypes.ALL]),
            component: lazy(() => import('./Findings/CdrExclusions/CdrExclusionsPage')),
            path: '/cdr-exclusions',
        };
    };

    function initializeCrdExclusionsTable() {
        addMenuSectionItem('events', 'default', {
            id: 'cdr-exclusions-page',
            position: 26,
            label: 'CDR Exclusions',
            state: '/cdr-exclusions',
            chip: {
                label: i18n.t('GENERAL.PREVIEW'),
            },
            permission: () => isNotChina() && getUserService().hasPermission([CDR_EXCLUSIONS_ACTIVE_FEATURE.key]),
        });
    }

    const allEventsPageRoute = () => {
        return {
            condition: () => getUserService().hasPermission([UserRolesTypes.ALL]),
            component: lazy(() => import('./Findings/AllEvents/AllEventsPage')),
            path: '/all-events',
        };
    };

    const dashboardRoute = () => {
        return {
            condition: () => getUserService().hasPermission([UserRolesTypes.ALL]),
            component: lazy(() => import('./Dashboard/CdrDashboard')),
            path: '/cdr-dashboard',
        };
    };

    function initializeAllEventsTable() {
        addMenuSectionItem('events', 'default', {
            id: 'all-events-page-kusto',
            position: 28,
            label: i18n.t('NAVIGATION_MENU.EVENTS.ALL_EVENTS', { ns: i18nIntelligenceNamespace }),
            state: '/all-events',
            chip: {
                label: i18n.t('GENERAL.PREVIEW'),
            },
            permission: () => isNotChina() && getUserService().hasPermission([ALL_EVENTS_TABLE_ACTIVE_FEATURE.key]),
        });
    }

    function accountActivityLogsRoute() {
        return {
            condition: getUserService().hasPermission([CDR_LOGS_TABLE_ACTIVE_FEATURE.key]),
            component: lazy(() => import('./Logs/AccountActivity/AccountActivityLogsPage')),
            path: '/logs/accountActivity',
        };
    }

    function initializeReducers() {
        const reducers: IApplicationReducer[] = [
            { name: 'visualizationGraphState', reducer: visualizationGraphStateReducer, isBlackList: true },
        ];
        getAppRootRegistry().addReducers(reducers, 'name');
    }

    getAppRootRegistry().addRoutes([
        { id: 'intelligence welcome', content: welcomePageRoute },
        { id: 'cdr exclusions page', content: cdrExclusionsPageRoute },
        { id: 'all events page', content: allEventsPageRoute },
        { id: 'cdr dashboard', content: dashboardRoute },
        { id: 'account activity logs page', content: accountActivityLogsRoute },
    ]);

    addMainMenuItem({
        id: 'intelligence',
        position: 90,
        icon: 'microscope',
        label: i18n.t('NAVIGATION_MENU.THREAT.TITLE', { ns: i18nIntelligenceNamespace }),
        permission: [UserRolesTypes.ALL],
        sections: [
            {
                id: 'default',
                position: 10,
                items: [
                    {
                        id: 'welcome',
                        position: 10,
                        label: i18n.t('NAVIGATION_MENU.THREAT.WELCOME', { ns: i18nIntelligenceNamespace }),
                        state: '/magellan/intelligence/welcome-page',
                        permission: [UserRolesTypes.ALL],
                    },
                    {
                        id: 'cdr dashboard',
                        position: 20,
                        label: i18n.t('NAVIGATION_MENU.THREAT.CDR_DASHBOARD', { ns: i18nIntelligenceNamespace }),
                        state: '/cdr-dashboard',
                        permission: [UserRolesTypes.ALL],
                        chip: {
                            label: i18n.t('GENERAL.NEW'),
                        },
                    },
                ],
            },
            {
                id: 'logs',
                title: i18n.t('NAVIGATION_MENU.LOGS.TITLE', { ns: i18nIntelligenceNamespace }),
                position: 30,
                items: [
                    {
                        id: 'account activity',
                        position: 10,
                        label: i18n.t('NAVIGATION_MENU.LOGS.ACCOUNT_ACTIVITY', { ns: i18nIntelligenceNamespace }),
                        state: '/logs/accountActivity',
                        permission: [CDR_LOGS_TABLE_ACTIVE_FEATURE.key],
                        chip: {
                            label: i18n.t('GENERAL.PREVIEW'),
                        },
                    },
                ],
            },
            {
                id: 'threat monitoring',
                position: 30,
                title: i18n.t('NAVIGATION_MENU.THREAT.THREAT_MONITORING', { ns: i18nIntelligenceNamespace }),
                items: [
                    {
                        id: 'rulesets',
                        position: 10,
                        label: i18n.t('NAVIGATION_MENU.THREAT.RULESETS', { ns: i18nIntelligenceNamespace }),
                        state: '/magellan/rulesets',
                        urlAliases: [/^\/magellan\/ruleset\/.+/, '/magellan/ruleset'],
                        permission: () => isRulesetEnabled(),
                    },
                    {
                        id: 'policies',
                        position: 20,
                        label: i18n.t('NAVIGATION_MENU.THREAT.POLICIES', { ns: i18nIntelligenceNamespace }),
                        state: '/magellan/rulesets-assignment',
                        permission: () => isPolicyEnabled(),
                    },
                    {
                        id: 'exclusions',
                        position: 30,
                        label: i18n.t('NAVIGATION_MENU.THREAT.EXCLUSIONS', { ns: i18nIntelligenceNamespace }),
                        state: '/magellan/exclusion',
                        permission: () => isExclusionsEnabled(),
                    },
                    {
                        id: 'remediations',
                        position: 40,
                        label: i18n.t('NAVIGATION_MENU.THREAT.REMEDIATIONS', { ns: i18nIntelligenceNamespace }),
                        state: '/magellan/remediation',
                        permission: () => isNotChina() && isRemediationEnabled(),
                    },
                    {
                        id: 'securityAlerts',
                        position: 40,
                        label: i18n.t('NAVIGATION_MENU.THREAT.SECURITY_ALERTS', { ns: i18nIntelligenceNamespace }),
                        state: '/magellan/security-alerts/log-table',
                        permission: [SECURITY_ALERTS],
                    },
                ],
            },
        ],
    });

    initializeServices();
    initializeIntelligenceFilters();
    initializeNetworkTrafficPageFilters();
    initializeMagellanPanels();
    initialize_AWS_TrafficActivityWidgets();
    initialize_AWS_EventActivityWidgets();
    initialize_AZURE_EventActivityWidgets();
    initialize_AZURE_TrafficActivityWidgets();
    initialize_K8_TrafficActivityWidgets();
    initialAwsEnvironmentTabs();
    initializeAzureIntelligenceOnboardingPages();
    initializeReducers();
    initializeFindings();
    InitializeEnvironmetsDefs();
    initializeCrdExclusionsTable();
    initializeAllEventsTable();
    initializePolicy();
    initializeActiveFeatures();
    initializeCdrExclusion();
    initializeAccountActivityLogTable();
    initializeCdrDashboard();
    // Alibaba widgets are not working in angular
    // initialize_ALIBABA_EventActivityWidgets();
}
