import { useTranslation } from 'react-i18next';
import { I18nSettingsTranslationKey } from '../../../initialize';
import { getBorderStyle } from '../utils';
import { Stack } from 'common/design-system/components-v2';
import { CGAssetsAndEnvironmentsComponent } from '../CGAssetsAndEnvironments/CGAssetsAndEnvironmentsComponent';
import { NetworkControls } from './NetworkControls';
import { FC, useEffect, useState } from 'react';
import {
    IPermissionComponentProps,
    IPermissionItem,
    PermissionCategorySubType,
    PermissionViewMode,
} from '../interfaces';

export const NetworkSecurity: FC<IPermissionComponentProps> = ({
    agentsPermissionsEnabled,
    showOnlySelectedItems,
    permissions,
    permissionsChanged,
    viewMode = PermissionViewMode.EDIT,
}) => {
    const { t } = useTranslation(I18nSettingsTranslationKey);
    const [allPermissions, setAllPermissions] = useState<IPermissionItem[]>([]);
    const reviewMode = viewMode === PermissionViewMode.REVIEW;

    const assetsAndEnvironmentsChanged = (data: IPermissionItem[]) => {
        let _permissions = allPermissions.filter(
            (permission) => permission.categorySubType !== PermissionCategorySubType.NETWORK_ENVS_OUS,
        );
        _permissions = [..._permissions, ...data];
        setAllPermissions(_permissions);
        permissionsChanged && permissionsChanged(_permissions);
    };

    const networkControlsPermissionChanged = (data: IPermissionItem[]) => {
        let _permissions = allPermissions.filter(
            (permission) => permission.categorySubType !== PermissionCategorySubType.NETWORK_CONTROLS,
        );
        _permissions = [..._permissions, ...data];
        setAllPermissions(_permissions);
        permissionsChanged && permissionsChanged(_permissions);
    };

    useEffect(() => {
        if (permissions?.length) {
            setAllPermissions(permissions);
        }
    }, [permissions]);

    const scopeFilteredPermissions = allPermissions?.filter(
        (permission) => permission.categorySubType === PermissionCategorySubType.NETWORK_ENVS_OUS,
    );
    const networkFilteredPermissions = allPermissions?.filter(
        (permission) => permission.categorySubType === PermissionCategorySubType.NETWORK_CONTROLS,
    );

    return (
        <Stack direction={'column'} spacing={3}>
            {reviewMode && !scopeFilteredPermissions?.length ? (
                ''
            ) : (
                <Stack style={{ border: getBorderStyle(viewMode) }} padding={3} spacing={3}>
                    <CGAssetsAndEnvironmentsComponent
                        title={t('LABELS.DYNAMIC_ACCESS')}
                        agentsPermissionsEnabled={agentsPermissionsEnabled}
                        permissionSubType={PermissionCategorySubType.NETWORK_ENVS_OUS}
                        permissions={scopeFilteredPermissions}
                        showOnlySelectedItems={showOnlySelectedItems}
                        viewMode={viewMode}
                        permissionsChanged={assetsAndEnvironmentsChanged}
                    />
                </Stack>
            )}
            {reviewMode && !networkFilteredPermissions?.length ? (
                ''
            ) : (
                <Stack style={{ border: getBorderStyle(viewMode) }} padding={3}>
                    <NetworkControls
                        title={t('LABELS.NETWORK_CONTROLS')}
                        permissionsChanged={networkControlsPermissionChanged}
                        showOnlySelectedItems={showOnlySelectedItems}
                        viewMode={viewMode}
                        permissions={networkFilteredPermissions}
                    />
                </Stack>
            )}
        </Stack>
    );
};
