import { ColDef } from 'ag-grid-community';
import i18n from 'common/services/translations/translations';
import { I18nTranslationKey, serviceAccountsTableRegistry } from './initServiceAccountsPage';
import { IServiceAccount } from 'common/interface/services';
import { ChipsCellRenderer } from './CellRenderers/ChipsCellRenderer';
import IconLinkCellRenderer from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { ValueFormatterParams } from 'ag-grid-enterprise';
const columnDefs: () => ColDef<IServiceAccount>[] = () => [
    {
        colId: 'checkbox',
        headerCheckboxSelection: true,
        width: 40,
        maxWidth: 40,
        minWidth: 40,
        resizable: false,
        lockPosition: 'left',
        checkboxSelection: () => {
            return true;
        },
        showDisabledCheckboxes: true,
    },
    {
        colId: 'name',
        field: 'name',
        headerName: i18n.t('TABLE.HEADERS.NAME', { ns: I18nTranslationKey }),
        width: 400,
        suppressSizeToFit: true,
        cellRenderer: IconLinkCellRenderer,
        sortable: true,
        valueFormatter: (params) => `${params.data?.name}`,
    },
    {
        colId: 'rolesNames',
        field: 'rolesNames',
        headerName: i18n.t('TABLE.HEADERS.ROLES', { ns: I18nTranslationKey }),
        width: 200,
        suppressSizeToFit: true,
        sortable: true,
        valueFormatter: (params) => `${params.data?.rolesNames}`,
        valueGetter: (params) => params.data?.rolesNames || '',
        cellRenderer: ChipsCellRenderer,
    },
    {
        colId: 'apiKey',
        field: 'apiKeyId',
        headerName: i18n.t('TABLE.HEADERS.API_KEY_ID', { ns: I18nTranslationKey }),
        width: 400,
        suppressSizeToFit: true,
        sortable: true,
        enableRowGroup: true,
        valueGetter: (params) => params.data?.apiKeyId || '',
        valueFormatter: (params) => `${params.value}` || '',
        cellRenderer: (params: ValueFormatterParams) =>
            IconLinkCellRenderer({ isLink: false, value: params.data?.apiKeyId, isCopiable: true }),
    },
    {
        colId: 'lastUsed',
        field: 'lastUsed',
        headerName: i18n.t('TABLE.HEADERS.LAST_USED', { ns: I18nTranslationKey }),
        width: 200,
        type: 'date',
        suppressSizeToFit: true,
        sortable: true,
        enableRowGroup: true,
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
            const never = i18n.t('TABLE.NEVER', { ns: I18nTranslationKey });
            if (valueA === never && valueB === never) return 0;
            if (valueA === never) return 1;
            if (valueB === never) return -1;
            const dateA = new Date(valueA);
            const dateB = new Date(valueB);
            return dateA.getTime() - dateB.getTime();
        },
        valueGetter: (params) =>
            params.data?.lastUsed
                ? new Date(params.data?.lastUsed || 0).toLocaleString()
                : i18n.t('TABLE.NEVER', { ns: I18nTranslationKey }),
        valueFormatter: (params) => `${params.value}` || '',
    },
];

export default function initializeRolesColumnDefs() {
    serviceAccountsTableRegistry.addColumnDefs(columnDefs());
}
