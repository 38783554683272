import { Stack } from 'common/design-system/components-v2';
import { CGResourceControls } from '../CGResourceControls/CGResourceControls';
import { getBorderStyle } from '../utils';
import { useTranslation } from 'react-i18next';
import { I18nSettingsTranslationKey } from '../../../initialize';
import { FC, useEffect, useState } from 'react';
import {
    IPermissionComponentProps,
    IPermissionItem,
    PermissionCategorySubType,
    PermissionViewMode,
} from '../interfaces';
import { CGAssetsAndEnvironmentsComponent } from '../CGAssetsAndEnvironments/CGAssetsAndEnvironmentsComponent';

export const ScopeAndControls: FC<IPermissionComponentProps> = ({
    agentsPermissionsEnabled,
    mspPermissionsEnabled,
    permissions,
    permissionsChanged,
    viewMode = PermissionViewMode.EDIT,
    showOnlySelectedItems,
}) => {
    const reviewMode = viewMode === PermissionViewMode.REVIEW;
    const { t } = useTranslation(I18nSettingsTranslationKey);
    const [selectedPermissions, setSelectedPermissions] = useState<IPermissionItem[]>([]);
    const [predefinedPermissions, setPredefinedPermissions] = useState<IPermissionItem[]>([]);

    const assetsAndEnvironmentsChanged = (data: IPermissionItem[]) => {
        if (data.length) {
            let _permissions = selectedPermissions.filter(
                (permission) => permission.categorySubType !== PermissionCategorySubType.SCOPE_ENVS_OUS,
            );
            _permissions = [..._permissions, ...data];
            setSelectedPermissions(_permissions);
            permissionsChanged && permissionsChanged(_permissions);
        }
    };

    const resourceControlsPermissionChanged = (data: IPermissionItem[]) => {
        if (data.length) {
            let _permissions = selectedPermissions.filter(
                (permission) => permission.categorySubType !== PermissionCategorySubType.SCOPE_CONTROLS,
            );
            _permissions = [..._permissions, ...data];
            setSelectedPermissions(_permissions);
            permissionsChanged && permissionsChanged(_permissions);
        }
    };

    useEffect(() => {
        if (permissions?.length) {
            setPredefinedPermissions(permissions);
            setSelectedPermissions(permissions);
        }
    }, [permissions]);

    const scopeFilteredPermissions = predefinedPermissions?.filter(
        (permission) => permission.categorySubType === PermissionCategorySubType.SCOPE_ENVS_OUS,
    );
    const controlsFilteredPermissions = predefinedPermissions?.filter(
        (permission) => permission.categorySubType === PermissionCategorySubType.SCOPE_CONTROLS,
    );
    return (
        <Stack direction={'column'} spacing={3}>
            {reviewMode && !scopeFilteredPermissions?.length ? (
                ''
            ) : (
                <Stack style={{ border: getBorderStyle(viewMode), borderRadius: '5px' }} padding={3} spacing={3}>
                    <CGAssetsAndEnvironmentsComponent
                        title={t('LABELS.SCOPE')}
                        showAllResourcesItem={true}
                        permissionSubType={PermissionCategorySubType.SCOPE_ENVS_OUS}
                        mspPermissionsEnabled={mspPermissionsEnabled}
                        agentsPermissionsEnabled={agentsPermissionsEnabled}
                        permissions={scopeFilteredPermissions}
                        showOnlySelectedItems={showOnlySelectedItems}
                        viewMode={viewMode}
                        permissionsChanged={assetsAndEnvironmentsChanged}
                    />
                </Stack>
            )}
            {reviewMode && !controlsFilteredPermissions?.length ? (
                ''
            ) : (
                <Stack style={{ border: getBorderStyle(viewMode), borderRadius: '5px' }} padding={3}>
                    <CGResourceControls
                        title={t('LABELS.CLOUD_GUARD_CONTROLS')}
                        permissions={controlsFilteredPermissions}
                        permissionsChanged={resourceControlsPermissionChanged}
                        viewMode={viewMode}
                        showOnlySelectedItems={showOnlySelectedItems}
                    />
                </Stack>
            )}
        </Stack>
    );
};
