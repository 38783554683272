import { renderMultiSelectFilter, renderTextFilter } from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { Addin } from 'common/extensibility/AddinRegistry';
import i18n from 'i18next';
import { ProtectedAssetsTableRegistry } from 'common/module_interface/assets/ProtectedAssetsTableRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { getK8sNamespace } from '../../../initialize.i18n';
import { SetInRequestObj } from 'common/components/FilterPanel/FilterPanel.interface';

const GENERAL_BOOLEAN_VALUES = {
    TRUE: 'true',
    FALSE: 'false',
    TRUE_CAPITAL_T: 'True',
    FALSE_CAPITAL_F: 'False',
};

enum IMAGES_GROUP {
    VENDOR_IMAGE_NAME = 'Vendor Image',
    BASE_IMAGE_NAME = 'Base Image',
    NONE = 'None',
}

enum SCAN_STATUS {
    SCANNED = 'Scanned',
    NOT_SCANNED = 'Not Scanned',
    SKIPPED = 'Skipped',
    FAILED = 'Failed',
    PARTIAL = 'Partial',
    IN_PROGRESS = 'In Progress',
}

const serverlessFilterDefsAddins: Addin<IFilterProps>[] = [
    {
        id: 'serverlessProtectionMode',
        content: () => {
            const initialData = [{ value: GENERAL_BOOLEAN_VALUES.TRUE }, { value: GENERAL_BOOLEAN_VALUES.FALSE }];

            const displayMapping = {
                [GENERAL_BOOLEAN_VALUES.TRUE]: {
                    displayText: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_PROTECTION_MODE.VALUE1'),
                },
                [GENERAL_BOOLEAN_VALUES.FALSE]: {
                    displayText: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_PROTECTION_MODE.VALUE2'),
                },
            };

            return {
                filterProps: {
                    initialData: initialData,
                    key: 'externalAdditionalFields|WithFSPBlock',
                    title: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_PROTECTION_MODE.TITLE'),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'serverlessRuntimeProtection',
        content: () => {
            const initialData = [{ value: GENERAL_BOOLEAN_VALUES.TRUE }];

            const displayMapping = {
                [GENERAL_BOOLEAN_VALUES.TRUE]: {
                    displayText: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_RUNTIME_PROTECTION.VALUE1'),
                },
            };

            return {
                filterProps: {
                    initialData: initialData,
                    key: 'externalAdditionalFields|IncludesFSP',
                    title: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_RUNTIME_PROTECTION.TITLE'),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'serverlessEnable',
        content: () => {
            const initialData = [{ value: GENERAL_BOOLEAN_VALUES.TRUE }, { value: GENERAL_BOOLEAN_VALUES.FALSE }];

            const displayMapping = {
                [GENERAL_BOOLEAN_VALUES.TRUE]: {
                    displayText: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_ENABLE.VALUE1'),
                },
                [GENERAL_BOOLEAN_VALUES.FALSE]: {
                    displayText: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_ENABLE.VALUE2'),
                },
            };

            return {
                filterProps: {
                    initialData: initialData,
                    key: 'externalAdditionalFields|ServerlessEnable',
                    title: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_ENABLE.TITLE'),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'serverlessAutoProtect',
        content: () => {
            const initialData = [{ value: GENERAL_BOOLEAN_VALUES.TRUE }, { value: GENERAL_BOOLEAN_VALUES.FALSE }];

            const displayMapping = {
                [GENERAL_BOOLEAN_VALUES.TRUE]: {
                    displayText: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_AUTO_PROTECT.VALUE1'),
                },
                [GENERAL_BOOLEAN_VALUES.FALSE]: {
                    displayText: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_AUTO_PROTECT.VALUE2'),
                },
            };

            return {
                filterProps: {
                    initialData: initialData,
                    key: 'externalAdditionalFields|FSPStickyMode',
                    title: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_AUTO_PROTECT.TITLE'),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'VendorImage',
        content: () => {
            const initialData = [{ value: IMAGES_GROUP.NONE }, { value: IMAGES_GROUP.VENDOR_IMAGE_NAME }];

            const displayMapping = {
                [IMAGES_GROUP.VENDOR_IMAGE_NAME]: { displayText: 'Yes' },
                [IMAGES_GROUP.NONE]: { displayText: 'No' },
            };

            return {
                filterProps: {
                    initialData: initialData,
                    key: 'additionalFields|VendorImage',
                    title: i18n.t('chips.vendorImage', { ns: getK8sNamespace('protected-asset-details') }),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'ImageId',

        content: () => {
            return {
                filterProps: {
                    initialData: '',
                    key: 'additionalFields|ImageId',
                    title: 'Image Id',
                },
                setInRequestObj: (requestObj, value: string): SetInRequestObj => {
                    requestObj.fields = requestObj.fields || [];
                    return requestObj.fields.push({ name: 'additionalFields|ImageId', value: value });
                },
                renderFunction: renderTextFilter,
            };
        },
    },
    {
        id: 'BaseImage',

        content: () => {
            const initialData = [{ value: IMAGES_GROUP.NONE }, { value: IMAGES_GROUP.BASE_IMAGE_NAME }];

            const displayMapping = {
                [IMAGES_GROUP.BASE_IMAGE_NAME]: { displayText: 'Yes' },
                [IMAGES_GROUP.NONE]: { displayText: 'No' },
            };

            return {
                filterProps: {
                    initialData: initialData,
                    key: 'additionalFields|BaseImage',
                    title: i18n.t('chips.baseImage', { ns: getK8sNamespace('protected-asset-details') }),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'scanStatus',
        content: () => {
            const initialData = Object.values(SCAN_STATUS).map((value) => ({ value }));

            const displayMapping = Object.entries(SCAN_STATUS).reduce<Record<string, { displayText: string }>>(
                (acc, [key, value]) => {
                    acc[key] = { displayText: i18n.t(`vulnerability.scanStatus.${value}`) };
                    return acc;
                },
                {},
            );

            return {
                filterProps: {
                    initialData: initialData,
                    key: 'vulnerability|scanStatus',
                    title: i18n.t('infoPane.ScanStatus', { ns: getK8sNamespace('protected-asset-details') }),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
];

export default function initializeFilters() {
    ProtectedAssetsTableRegistry.addFilterAddins(serverlessFilterDefsAddins);
}
