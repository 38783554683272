import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { Addin } from 'common/extensibility/AddinRegistry';
import { AwsEnvironmentAddinRegistry, AwsEnvironmentAddingBase } from 'common/helpers/awsEnvironment';
import { StatusCellRenderer_Status } from 'common/module_interface/assets/Environments';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { getAwsEnvNamespace } from './initialize.i18n';
import { getCloudAccountsService } from 'common/interface/data_services';
import { Vendors } from 'common/consts/vendors';
import { changeUrl } from 'common/utils/http';
import { Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import { getEventsTableWidgetService } from 'common/module_interface/events/EventsTableRegistry';
import { IChipProps } from 'common/design-system/components-v2/Chip/Chip.types';
import { CommonEventFields } from 'common/module_interface/events/EventsConsts';
import { lazy } from 'react';

export const initialAwsEnvironmentData = () => {
    const tabs: Addin<AwsEnvironmentAddingBase<Tab>>[] = [
        {
            id: 'notifications',
            position: 10,
            content: {
                id: 'notifications',
                getValue: () => ({
                    name: 'notifications',
                    label: i18next.t('awsPage.tabs.notifications', { ns: getAwsEnvNamespace('aws') }),
                    isReactTab: false,
                }),
            },
        },
        {
            id: 'network',
            position: 20,
            content: {
                id: 'network',
                getValue: () => ({
                    name: 'network',
                    label: i18next.t('awsPage.tabs.network', { ns: getAwsEnvNamespace('aws') }),
                    isReactTab: false,
                }),
            },
        },
        {
            id: 'protected assets new',
            position: 50,
            content: {
                id: 'protected assets new',
                getValue: (awsData) => ({
                    name: 'protected assets new',
                    label: i18next.t('awsPage.tabs.protectedAssets', { ns: getAwsEnvNamespace('aws') }),
                    isReactTab: true,
                    reactTab: {
                        component: lazy(() => import('./tabs/Assets')),
                        componentProps: {
                            cloudAccountId: awsData.account.id,
                        },
                    },
                }),
            },
        },
        {
            id: 'events',
            position: 60,
            content: {
                id: 'events',
                getValue: (awsData) => {
                    const EventsTable = getEventsTableWidgetService().getMainTable();
                    return {
                        name: 'events',
                        label: i18next.t('awsPage.tabs.events', { ns: getAwsEnvNamespace('aws') }),
                        isReactTab: true,
                        reactTab: {
                            component: EventsTable,
                            componentProps: {
                                tableIdPrefix: `aws-env_${awsData.account.id}`,
                                disablePadding: true,
                                defaultFilters: {
                                    fields: [{ name: 'cloudAccountId_calc', value: awsData.account.id }],
                                },
                                hiddenFilters: [
                                    CommonEventFields.cloudAccountId,
                                    CommonEventFields.cloudAccountType,
                                    'organizational unit',
                                    CommonEventFields.organizationalUnitId,
                                    CommonEventFields.region,
                                ],
                                hiddenColumns: [CommonEventFields.cloudAccountId, CommonEventFields.cloudAccountType],
                            },
                        },
                    };
                },
            },
        },
        {
            id: 'compliance policies',
            position: 70,
            content: {
                id: 'compliance policies',
                getValue: () => ({
                    name: 'compliance policies',
                    label: i18next.t('awsPage.tabs.compliancePolicies', { ns: getAwsEnvNamespace('aws') }),
                    isReactTab: false,
                }),
            },
        },
        {
            id: 'assessment history',
            position: 80,
            content: {
                id: 'assessment history',
                getValue: () => ({
                    name: 'assessment history',
                    label: i18next.t('awsPage.tabs.ssessmentHistory', { ns: getAwsEnvNamespace('aws') }),
                    isReactTab: false,
                }),
            },
        },
    ];
    AwsEnvironmentAddinRegistry.addTabs(tabs);

    const detailsItems: Addin<AwsEnvironmentAddingBase<BlockInfoProps>>[] = [
        {
            id: 'organizationalUnit',
            position: 10,
            content: {
                id: 'organizationalUnit',
                getValue: (awsData) => ({
                    title: i18next.t('awsPage.info.organizationalUnit', { ns: getAwsEnvNamespace('aws') }),
                    info: awsData.account.organizationalUnitName,
                    copyable: true,
                }),
            },
        },
        {
            id: 'onboardingTime',
            position: 20,
            content: {
                id: 'onboardingTime',
                getValue: (awsData) => ({
                    title: i18next.t('awsPage.info.onboardingTime', { ns: getAwsEnvNamespace('aws') }),
                    info: dayjs(awsData.account.creationDate).format('MMM D, YYYY h:mm A Z'),
                }),
            },
        },
        {
            id: 'accountNumber',
            position: 40,
            content: {
                id: 'accountNumber',
                getValue: (awsData) => ({
                    title: i18next.t('awsPage.info.accountNumber', { ns: getAwsEnvNamespace('aws') }),
                    info: awsData.account.externalAccountNumber,
                    copyable: true,
                }),
            },
        },
        {
            id: 'cloudGuardId',
            position: 60,
            content: {
                id: 'cloudGuardId',
                getValue: (awsData) => ({
                    title: i18next.t('awsPage.info.cloudGuardId', { ns: getAwsEnvNamespace('aws') }),
                    info: awsData.account.id,
                    copyable: true,
                }),
            },
        },
    ];
    AwsEnvironmentAddinRegistry.addDetailsPanelItems(detailsItems);

    const chips: Addin<AwsEnvironmentAddingBase<IChipProps>>[] = [
        {
            id: 'environmentStatusChip',
            position: 20,
            content: {
                id: 'environmentStatusChip',
                customLoadingLabel: i18next.t('awsPage.chips.status.loading', { ns: getAwsEnvNamespace('aws') }),
                getValue: async (awsData) => {
                    const syncStatusList = await getCloudAccountsService().getEnvironmentSyncStatus(true, (err) => {
                        throw err;
                    });
                    const status = syncStatusList.find(
                        (s) => s.platform === Vendors.AWS && s.id === awsData.account.id,
                    );

                    const syncStatus = status?.hasIssues
                        ? StatusCellRenderer_Status.warning
                        : StatusCellRenderer_Status.success;

                    const tooltipI18Key =
                        syncStatus === StatusCellRenderer_Status.warning
                            ? 'awsPage.chips.MISSING_PERMISSIONS'
                            : 'awsPage.chips.VALID_PERMISSIONS';
                    const chipProps: IChipProps = {
                        label: i18next.t(`awsPage.chips.status.${syncStatus}`, { ns: getAwsEnvNamespace('aws') }),
                        leadingIconProps: {
                            name: syncStatus === StatusCellRenderer_Status.warning ? 'warning' : 'checkCircle',
                        },
                        context: syncStatus === StatusCellRenderer_Status.warning ? 'medium' : 'low',
                        disableInteraction: true,
                        tooltip: i18next.t(tooltipI18Key, { ns: getAwsEnvNamespace('aws') }),
                    };

                    if (syncStatus === StatusCellRenderer_Status.warning) {
                        chipProps.disableInteraction = false;
                        chipProps.onClick = () => {
                            changeUrl(`/cloud-account/aws/${awsData.account.id}?tabName=notifications&tabOnly=true`);
                        };
                    }

                    return chipProps;
                },
            },
        },
    ];
    AwsEnvironmentAddinRegistry.addChips(chips);
};
