/* eslint-disable react/prop-types */

import { IAlertTrendOptionsItem, IGenericWidgetSettingsProps } from 'common/module_interface/overview/Interface';
import { deepCloneObject } from 'common/utils/objectUtils';
import AlertsOptionLineSettings from './AlertsOptionLineSettings';
import { useEffect, useState } from 'react';
import {
    getCloudAccountsService,
    getOrganizationalUnitService,
    ICloudAccount,
    IOrganizationalUnitTreeNode,
} from 'common/interface/data_services';
import { FlatOUTree, flattenTree } from 'common/utils/helpFunctions';
import { ISelectOption } from 'common/interface/general';

const AlertsTrendWidgetSettings: React.FC<IGenericWidgetSettingsProps> = ({ widget, updateWidgetCallback }) => {
    const [allCloudAccounts, setAllCloudAccounts] = useState<ICloudAccount[]>([]);
    const [ousSelectOptions, setOusSelectOptions] = useState<ISelectOption[]>([]);
    const [envsSelectOptions, setEnvsSelectOptions] = useState<ISelectOption[]>([]);
    const [ouFlatTree, setOuFlatTree] = useState<FlatOUTree[]>([]);
    const [rootOu, setRootOu] = useState<IOrganizationalUnitTreeNode | null>(null);

    const numberOfFilterComponents = 3;
    //const widgetClone = deepCloneObject(widget);
    if (!widget.options) {
        widget.options = {};
    }
    if (!widget.options.alertTrendOptions) {
        widget.options.alertTrendOptions = [];
    }

    for (let index = 0; index < numberOfFilterComponents; index++) {
        if (!widget.options.alertTrendOptions?.[index]) {
            const defaultAlertTrendOptions = {
                selected: [],
                selectedName: '',
                title: `Line ${index + 1}`,
                type: 'None',
                widgetConfigFilterState: [],
            };

            widget.options.alertTrendOptions?.push(defaultAlertTrendOptions);
        }
    }

    useEffect(() => {
        async function setOuSelectOptions() {
            const _organizationalUnitsTree = await getOrganizationalUnitService().getAllOrganizationalUnits();
            const _flatTree = flattenTree(_organizationalUnitsTree[0]);
            const _rootOu =
                _organizationalUnitsTree && _organizationalUnitsTree.length > 0 ? _organizationalUnitsTree[0] : null;
            const _ousSelectOptions = _flatTree.map((ou) => {
                return {
                    label: ou.name,
                    value: ou.id,
                };
            });
            _rootOu && _ousSelectOptions.unshift({ label: _rootOu.item.name, value: _rootOu.item.id });

            setOusSelectOptions(_ousSelectOptions);
            setOuFlatTree(_flatTree);
            setRootOu(_rootOu);
        }

        async function setEnvSelectOptions() {
            const _allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts();
            const _envsSelectOptions = _allCloudAccounts.map((env) => {
                return {
                    label: env.name,
                    value: env.externalId,
                };
            });

            setAllCloudAccounts(_allCloudAccounts);
            setEnvsSelectOptions(_envsSelectOptions);
        }

        setOuSelectOptions();
        setEnvSelectOptions();
    }, []);

    const updateAlertOptionCallback = (alertsOption: IAlertTrendOptionsItem, index: number) => {
        const _widgetClone = deepCloneObject(widget);
        _widgetClone.options.alertTrendOptions[index] = { ...alertsOption };
        updateWidgetCallback(_widgetClone);
    };

    const updateAlertFilterCallback = (filterState: any, index: number) => {
        const _widgetClone = deepCloneObject(widget);
        _widgetClone.options.alertTrendOptions[index].widgetConfigFilterState = filterState;
        updateWidgetCallback(_widgetClone);
    };

    return (
        <div className='mt-8'>
            {widget.options?.alertTrendOptions?.map((alertsOption: IAlertTrendOptionsItem, index: number) => {
                return (
                    <AlertsOptionLineSettings
                        key={index}
                        alertsOption={alertsOption}
                        index={index}
                        widget={widget}
                        updateAlertOptionCallback={updateAlertOptionCallback}
                        updateAlertFilterCallback={updateAlertFilterCallback}
                        optionsData={{
                            allCloudAccounts: allCloudAccounts,
                            ousSelectOptions: ousSelectOptions,
                            envsSelectOptions: envsSelectOptions,
                            ouFlatTree: ouFlatTree,
                            rootOu: rootOu,
                        }}
                    />
                );
            })}
        </div>
    );
};

export default AlertsTrendWidgetSettings;
