import styled from 'styled-components';

const TeamsNameWrapper = styled.div`
    width: 50%;
    margin: ${({ theme }) =>
        `${theme.spacing(2)}${theme.units} ${theme.spacing(5)}${theme.units} ${theme.spacing(5)}${theme.units}`};
`;

const TeamsWebhookUrlWrapper = styled.div`
    margin: ${({ theme }) => `0 ${theme.spacing(5)}${theme.units} ${theme.spacing(5)}${theme.units}`};
    display: flex;
    flex-direction: column;
`;

const TeamsAlertWrapper = styled.div`
    margin: ${({ theme }) => `${theme.spacing(2)}${theme.units} ${theme.spacing(5)}${theme.units}`};
    display: flex;
    flex-direction: column;
`;

export { TeamsNameWrapper, TeamsWebhookUrlWrapper, TeamsAlertWrapper };
