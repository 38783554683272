import { IIssueExclusionProps } from '../IssueExclusions.interface';
import { getExclusionPropsFromValues, getSafeName } from './IssueExclusionEditor.values';
import { getIssueExclusionsService } from '../IssueExclusions.utils';
import { ermTrans } from '../../../RiskManagement.utils';
import { IItemsMap } from 'common/erm-components/custom/CustomForm/CustomForm.interface';

export const saveIssueExclusion = async (
    itemsMap: IItemsMap,
    issueExclusions?: IIssueExclusionProps,
): Promise<void> => {
    const exclusionProps: IIssueExclusionProps = getExclusionPropsFromValues(itemsMap);
    if (issueExclusions?.id) {
        await getIssueExclusionsService().updateIssueExclusion(issueExclusions.id, exclusionProps);
    } else {
        await getIssueExclusionsService().createIssueExclusion(exclusionProps);
    }
};

export const getIssueExclusionSubmitSuccessNotification = (
    itemsMap: IItemsMap,
    issueExclusions?: IIssueExclusionProps,
): string => {
    const name = getSafeName(itemsMap);
    if (issueExclusions?.id) {
        return ermTrans('ISSUE_EXCLUSIONS.TABLE.ACTIONS.EXCLUSION_UPDATED_SUCCESSFULLY', { name });
    } else {
        return ermTrans('ISSUE_EXCLUSIONS.TABLE.ACTIONS.EXCLUSION_ADDED_SUCCESSFULLY', { name });
    }
};

export const getIssueExclusionSubmitFailedNotification = (
    itemsMap: IItemsMap,
    error: string,
    issueExclusions?: any,
): string => {
    const name = getSafeName(itemsMap);
    if (issueExclusions?.id) {
        return ermTrans('ISSUE_EXCLUSIONS.TABLE.ACTIONS.FAILED_EDITING_EXCLUSION', { name, error });
    } else {
        return ermTrans('ISSUE_EXCLUSIONS.TABLE.ACTIONS.FAILED_ADDING_NEW_EXCLUSION', { name, error });
    }
};
