import { ICellRendererParams } from 'ag-grid-community';
import { IIdenticalIdentitiesTreeNode } from 'common/module_interface/identity/IdenticalRoles.interfaces';
import React from 'react';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { AssetUrlParams, IAsset, UrlFuncResult } from 'common/assets/common.assets';
import { generateAssetPageUrl } from 'common/module_interface/assets/utils';
import EntityLink from 'common/components/ProtectedAssets/Renderers/EntityLink';
import { PROTECTED_ASSETS_URL } from 'common/module_interface/assets/ProtectedAssets.consts';
import { Vendors } from 'common/consts/vendors';
import { CopiableLine } from 'common/design-system/components-v2';

export const IdentityIdCellRenderer: React.FC<ICellRendererParams<IIdenticalIdentitiesTreeNode>> = (params) => {
    const identityId: string | undefined = params.data?.id;
    if (!identityId) return null;
    const CopiableEntityId = <CopiableLine value={identityId}>{identityId}</CopiableLine>;
    const dome9Id: string | undefined = params.data?.entityInfo?.dome9Id;
    const entityType: string = params.data?.entityInfo?.entityType ?? '';
    const cloudAccountId: string | undefined = params.data?.entityInfo?.cloudAccountId;

    const iAsset: IAsset | null = getProtectedAssetsService().getProtectedAssetByTypeAndDome9Id(
        entityType,
        dome9Id ?? null,
    );
    if (!iAsset) {
        return CopiableEntityId;
    }
    const urlParams: AssetUrlParams = {
        asset: iAsset,
        entityId: iAsset.platform !== Vendors.AWS ? identityId : undefined,
        cloudAccountId,
        dome9Id: dome9Id,
        platform: iAsset.platform,
        entityType,
        typeNumber: iAsset?.typeNumber,
    };
    const assetUrl: UrlFuncResult | null = iAsset?.getUrl(urlParams) ?? null;
    if (assetUrl === null) {
        return CopiableEntityId;
    }
    const finalUrl = generateAssetPageUrl(iAsset, assetUrl, PROTECTED_ASSETS_URL, {}, 'permissions');

    return <EntityLink entityName={identityId} url={finalUrl} isCopiable={true} />;
};
