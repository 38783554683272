import { DashboardWidgetTypes, IDashboard } from 'common/module_interface/overview/Interface';
import { v4 } from 'uuid';
import { LOCAL_SERVERLESS_DEFAULT_DASHBOARD } from '../Consts';
import { DEFAULT_ORGANIZATIONAL_UNIT_ID } from 'common/consts/DataConsts';

export default function initializeServerlessDefaultDashboard() {
    const serverlessDefaultDashboard: IDashboard = {
        id: LOCAL_SERVERLESS_DEFAULT_DASHBOARD,
        name: LOCAL_SERVERLESS_DEFAULT_DASHBOARD,
        isLocalDashboard: true,
        sections: [
            {
                isExpanded: true,
                id: 'serverlessDefault',
                title: 'SERVERLESS DEFAULT',
                dashboardId: LOCAL_SERVERLESS_DEFAULT_DASHBOARD,
                position: 1,
                widgets: [
                    {
                        id: v4(),
                        sectionId: 'serverlessDefault',
                        dashboardId: LOCAL_SERVERLESS_DEFAULT_DASHBOARD,
                        type: DashboardWidgetTypes.Top,
                        title: 'Functions with most critical alerts',
                        limit: 50,
                        description: '',
                        hideOnNoData: false,
                        aggregation: 'entity',
                        dataSourceName: 'Alerts',
                        gslFilter: '',
                        cloudAccountId: null,
                        timeFrame: 1,
                        filterState: [
                            {
                                name: 'organizationalUnitId',
                                value: DEFAULT_ORGANIZATIONAL_UNIT_ID,
                            },
                            {
                                name: 'severity',
                                value: 'Critical',
                            },
                            {
                                name: 'origin',
                                value: 2,
                            },
                            {
                                name: 'acknowledged',
                                value: 'false',
                            },
                            {
                                name: 'isExcluded',
                                value: 'false',
                            },
                        ],
                        options: {
                            id: v4(),
                            sizes: {
                                height: 2,
                                width: 2,
                            },
                            navigateOption: 'Alerts',
                            binSize: '10m',
                        },
                    },
                    {
                        id: v4(),
                        sectionId: 'serverlessDefault',
                        dashboardId: LOCAL_SERVERLESS_DEFAULT_DASHBOARD,
                        type: DashboardWidgetTypes.Top,
                        title: 'Functions with most findings',
                        limit: 50,
                        description: '',
                        hideOnNoData: false,
                        aggregation: 'entity',
                        dataSourceName: 'Alerts',
                        gslFilter: '',
                        cloudAccountId: null,
                        timeFrame: 1,
                        filterState: [
                            {
                                name: 'organizationalUnitId',
                                value: DEFAULT_ORGANIZATIONAL_UNIT_ID,
                            },
                            {
                                name: 'origin',
                                value: 2,
                            },
                            {
                                name: 'acknowledged',
                                value: 'false',
                            },
                            {
                                name: 'isExcluded',
                                value: 'false',
                            },
                        ],
                        options: {
                            id: v4(),
                            sizes: {
                                height: 2,
                                width: 2,
                            },
                            navigateOption: 'Alerts',
                            binSize: '10m',
                        },
                    },
                    {
                        id: v4(),
                        sectionId: 'serverlessDefault',
                        dashboardId: LOCAL_SERVERLESS_DEFAULT_DASHBOARD,
                        type: DashboardWidgetTypes.Pie,
                        title: 'Findings types',
                        limit: 5,
                        description: '',
                        hideOnNoData: false,
                        aggregation: 'alertType',
                        dataSourceName: 'Alerts',
                        gslFilter: '',
                        cloudAccountId: null,
                        timeFrame: 1,
                        filterState: [
                            {
                                name: 'organizationalUnitId',
                                value: DEFAULT_ORGANIZATIONAL_UNIT_ID,
                            },
                            {
                                name: 'origin',
                                value: 2,
                            },
                            {
                                name: 'acknowledged',
                                value: 'false',
                            },
                            {
                                name: 'isExcluded',
                                value: 'false',
                            },
                        ],
                        options: {
                            id: v4(),
                            sizes: {
                                height: 2,
                                width: 2,
                            },
                            navigateOption: 'Alerts',
                            binSize: '10m',
                        },
                    },
                    {
                        id: v4(),
                        sectionId: 'serverlessDefault',
                        dashboardId: LOCAL_SERVERLESS_DEFAULT_DASHBOARD,
                        type: DashboardWidgetTypes.Top,
                        title: 'Functions with permissive roles issues',
                        limit: 50,
                        description: '',
                        hideOnNoData: false,
                        aggregation: 'entity',
                        dataSourceName: 'Alerts',
                        gslFilter: '',
                        cloudAccountId: null,
                        timeFrame: 1,
                        filterState: [
                            {
                                name: 'organizationalUnitId',
                                value: DEFAULT_ORGANIZATIONAL_UNIT_ID,
                            },
                            {
                                name: 'origin',
                                value: 2,
                            },
                            {
                                name: 'acknowledged',
                                value: 'false',
                            },
                            {
                                name: 'isExcluded',
                                value: 'false',
                            },
                            {
                                name: 'free-text',
                                value: 'PermissiveRole',
                            },
                        ],
                        options: {
                            id: v4(),
                            sizes: {
                                height: 2,
                                width: 2,
                            },
                            navigateOption: 'Alerts',
                            binSize: '10m',
                        },
                    },
                    {
                        id: v4(),
                        sectionId: 'serverlessDefault',
                        dashboardId: LOCAL_SERVERLESS_DEFAULT_DASHBOARD,
                        type: DashboardWidgetTypes.Summary,
                        title: 'Serverless - total alerts',
                        limit: 10,
                        description: '',
                        hideOnNoData: false,
                        aggregation: 'summary',
                        dataSourceName: 'Alerts',
                        gslFilter: '',
                        cloudAccountId: null,
                        timeFrame: 1,
                        filterState: [
                            {
                                name: 'organizationalUnitId',
                                value: DEFAULT_ORGANIZATIONAL_UNIT_ID,
                            },
                            {
                                name: 'origin',
                                value: 2,
                            },
                            {
                                name: 'acknowledged',
                                value: 'false',
                            },
                            {
                                name: 'isExcluded',
                                value: 'false',
                            },
                        ],
                        options: {
                            id: v4(),
                            sizes: {
                                height: 1,
                                width: 2,
                            },
                            navigateOption: 'Alerts',
                            binSize: '10m',
                            thresholds: [],
                        },
                    },
                    {
                        id: v4(),
                        sectionId: 'serverlessDefault',
                        dashboardId: LOCAL_SERVERLESS_DEFAULT_DASHBOARD,
                        type: DashboardWidgetTypes.Pie,
                        title: 'Serverless - Alerts by severity',
                        limit: 5,
                        description: '',
                        hideOnNoData: false,
                        aggregation: 'severity',
                        dataSourceName: 'Alerts',
                        gslFilter: '',
                        cloudAccountId: null,
                        timeFrame: 1,
                        filterState: [
                            {
                                name: 'organizationalUnitId',
                                value: DEFAULT_ORGANIZATIONAL_UNIT_ID,
                            },
                            {
                                name: 'origin',
                                value: 2,
                            },
                            {
                                name: 'acknowledged',
                                value: 'false',
                            },
                            {
                                name: 'isExcluded',
                                value: 'false',
                            },
                        ],
                        options: {
                            id: v4(),
                            sizes: {
                                height: 1,
                                width: 2,
                            },
                            navigateOption: 'Alerts',
                            binSize: '10m',
                        },
                    },
                ],
            },
        ],
    };

    return serverlessDefaultDashboard;
}
