import { ActiveFeaturesRegistry } from 'common/registries/ActiveFeatureManager/ActiveFeaturesRegistry';
import { ERM_ACTIVE_FEATURES, ERM_TOPIC_ID } from './ActiveFeatures.consts';

export default function initializeActiveFeatures() {
    ActiveFeaturesRegistry.addActiveFeatureTopic({
        key: ERM_TOPIC_ID,
        title: 'ERM',
        icon: 'riskManagement4',
    });
    ActiveFeaturesRegistry.addActiveFeatureInfos(ERM_ACTIVE_FEATURES);
}
