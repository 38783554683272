import React from 'react';
import { Chip } from 'common/design-system/components-v2';
import { getRiskLevelByScore } from 'common/module_interface/RiskManagement/RiskLevel';

const RiskScore: React.FC<{ riskScore: number }> = ({ riskScore }) => (
    <Chip
        context={getRiskLevelByScore(riskScore)?.context}
        leadingIconProps={{ name: 'riskManagement4' }}
        label={riskScore.toString()}
    />
);

export default RiskScore;
