import { ColDef, IRowNode, ValueGetterParams } from 'ag-grid-community';
import { isNil } from 'common/utils/helpFunctions';

export enum EColumnGroupState {
    NONE = 'NONE',
    SELF = 'SELF',
    OTHER = 'OTHER',
}

export function getColumnGroupState<TData>(
    fieldName: string,
    rowNode: IRowNode<TData>,
): keyof typeof EColumnGroupState {
    if (!rowNode.field) {
        return EColumnGroupState.NONE;
    }
    if (rowNode.field === fieldName) {
        return EColumnGroupState.SELF;
    }
    return EColumnGroupState.OTHER;
}

export const isGrouping = (fieldName: string, rowNode: IRowNode) => {
    return getColumnGroupState(fieldName, rowNode) === EColumnGroupState.SELF;
};

export enum EBooleanString {
    NIL = '',
    TRUE = 'true',
    FALSE = 'false',
}

export function booleanStringValueGetter(fieldName: string) {
    return (params: ValueGetterParams): EBooleanString[keyof EBooleanString] => {
        const value = params.data?.[fieldName];
        if (isNil(value)) {
            return EBooleanString.NIL;
        }
        return value === true ? EBooleanString.TRUE : EBooleanString.FALSE;
    };
}

export function isTrue(value: string) {
    return value === EBooleanString.TRUE;
}

type ColToHideFF = Array<{ field: string; hide: boolean }>;
export const hideColumn = (colDef: ColDef[], hideColFunction: ColToHideFF) =>
    filterColumns(colDef, hideColFunction, 'field');

export function filterColumns<T extends Record<string, any>>(
    columns: T[],
    hideCols: Array<{ field: T[keyof T]; hide: boolean }>,
    fieldName: keyof T,
): T[] {
    return columns.filter((column) => {
        return !hideCols.some((hideColumn) => hideColumn.field === column[fieldName] && hideColumn.hide);
    });
}
