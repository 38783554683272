import { Icon, Stack, Typography } from 'common/design-system/components-v2';
import {
    AWSCrossAccountRoleAuthExplanationWrapper,
    Bullet,
    InfoBox,
} from './AWSCrossAccountRoleAuthExplanationForEcsScanner.styled';
import { useTranslation, Trans } from 'react-i18next';
import { getCheckpointUrl } from 'common/components/Forms/urls';
import { ALink } from 'common/components/ALink';

const AWSCrossAccountRoleAuthExplanationForEcsScanner = () => {
    const { t } = useTranslation('k8s_registry');
    return (
        <AWSCrossAccountRoleAuthExplanationWrapper>
            <InfoBox>
                <Icon name='info' size={12} color='brandPrimary' />
                <Stack spacing={2}>
                    <Typography>
                        {t('ON_BOARDING.AWSCrossAccountRoleAuthExplanationForEcsScanner.toUseThisMethod')}
                    </Typography>
                    <Stack spacing={1} padding={[0, 0, 0, 3]}>
                        <Bullet>
                            <Typography>
                                {t('ON_BOARDING.AWSCrossAccountRoleAuthExplanationForEcsScanner.yourHostCluster')}
                            </Typography>
                        </Bullet>
                        <Bullet>
                            <Typography>
                                <Trans
                                    components={{
                                        aLink: (
                                            <ALink
                                                href={getCheckpointUrl('AWS_CUSTOM_ROLE')}
                                                target='_blank'
                                                rel='noreferrer'
                                            />
                                        ),
                                    }}
                                    i18nKey={
                                        'k8s_registry:ON_BOARDING.AWSCrossAccountRoleAuthExplanationForEcsScanner.inAddition'
                                    }
                                />
                            </Typography>
                        </Bullet>
                        <Bullet>
                            <Typography>
                                {t('ON_BOARDING.AWSCrossAccountRoleAuthExplanationForEcsScanner.needToCreate')}
                            </Typography>
                        </Bullet>
                        <Bullet>
                            <Typography>
                                {t('ON_BOARDING.AWSCrossAccountRoleAuthExplanationForEcsScanner.minimumVersion')}
                            </Typography>
                        </Bullet>
                    </Stack>
                </Stack>
            </InfoBox>
        </AWSCrossAccountRoleAuthExplanationWrapper>
    );
};

export default AWSCrossAccountRoleAuthExplanationForEcsScanner;
