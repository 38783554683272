import React from 'react';
import { Colors, Icon } from '@dome9/berries/react-components';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { INotification, NotificationStatus } from 'common/interface/notificationPage';
import { Typography } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { I18nNotifications } from '../../NotificationsPage.consts';

interface IStatusCellRendererProps extends ICellRendererParams<INotification> {}

const StatusCellRenderer: React.FC<IStatusCellRendererProps> = (params) => {
    const { t } = useTranslation(I18nNotifications);
    if (!params.data?.status) return;

    let name = null;
    switch (params.data?.status) {
        case NotificationStatus.OK:
            name = 'check';
            break;
        case NotificationStatus.ERROR:
            name = 'warning';
            break;
        case NotificationStatus.NA:
            name = 'na';
            break;
    }
    const getStatusColor = (status?: NotificationStatus): Colors => {
        switch (status) {
            case NotificationStatus.OK:
                return 'success';
            case NotificationStatus.ERROR:
                return 'warning';
            case NotificationStatus.NA:
            default:
                return 'undefined';
        }
    };
    return (
        <>
            {name ? (
                <Icon name={name} color={getStatusColor(params.data?.status)} size={16} />
            ) : (
                <Typography>{t('GENERAL.LOADING')}</Typography>
            )}
        </>
    );
};

export default StatusCellRenderer;
