import React from 'react';
import { getComplianceFindingApiService, IEvent } from 'common/module_interface/events/Events';
import { ComplianceFindingAcknowledge } from 'common/module_interface/events/complianceFindingApi.interface';

export interface UseMutateEventAcknowledgeRequest {
    onSuccess?: (response: ComplianceFindingAcknowledge.Response) => void;
    onError?: (error: string) => void;
}

export interface UseMutateEventAcknowledgePayload {
    isLoading: boolean;
    isError: boolean;
    results?: ComplianceFindingAcknowledge.Response;

    acknowledge: (events: IEvent[], details: ComplianceFindingAcknowledge.Request['details']) => Promise<void>;
    reset?: () => void;
}

export type UseMutateEventAcknowledgeReset = (
    req?: UseMutateEventAcknowledgeRequest,
) => UseMutateEventAcknowledgePayload;

export const useMutateEventAcknowledge: UseMutateEventAcknowledgeReset = (req) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isError, setIsError] = React.useState<boolean>(false);
    const [results, setResults] = React.useState<ComplianceFindingAcknowledge.Response>();

    const acknowledge = React.useCallback(
        async (events: IEvent[], details: ComplianceFindingAcknowledge.Request['details']) => {
            setIsError(false);
            setIsLoading(true);
            try {
                const response = await getComplianceFindingApiService().acknowledge({
                    ids: events.map((event) => event.id),
                    details,
                });
                setResults(response.data);
                req?.onSuccess?.(response.data);
            } catch (error: any) {
                setIsError(true);
                req?.onError?.(error?.message || 'An error occurred');
            } finally {
                setIsLoading(false);
            }
        },
        [req],
    );

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setIsError(false);
        setResults(undefined);
    }, []);

    return {
        isLoading,
        isError,
        results,

        acknowledge,
        reset,
    };
};
