class RequestQueue {
    private queue: Array<{
        requestFn: () => Promise<any>;
        resolve: (value: any) => void;
        reject: (reason?: any) => void;
    }>;
    private isProcessing: boolean;
    private currentPromise: Promise<void> | null;
    private resolveCurrentPromise: (() => void) | null;

    constructor() {
        this.queue = [];
        this.isProcessing = false;
        this.currentPromise = null;
        this.resolveCurrentPromise = null;
    }

    add(requestFn: () => Promise<any>): Promise<any> {
        return new Promise((resolve, reject) => {
            this.queue.push({ requestFn, resolve, reject });
            this.processQueue();
        });
    }

    private async processQueue() {
        if (this.isProcessing) return;

        this.isProcessing = true;

        while (this.queue.length > 0) {
            if (this.currentPromise) {
                await this.currentPromise;
            }

            const { requestFn, resolve, reject } = this.queue.shift()!;

            this.currentPromise = new Promise<void>((resolve) => {
                this.resolveCurrentPromise = resolve;
            });

            try {
                const result = await requestFn();
                resolve(result);
            } catch (error) {
                console.error('Error processing token request:', error);
                reject(error);
            } finally {
                this.resolveCurrentPromise!();
                this.currentPromise = null;
                this.resolveCurrentPromise = null;
            }
        }

        this.isProcessing = false;
    }
}

export const requestQueue = new RequestQueue();
