import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'common/design-system/components-v2';
import { I18nRiskNamespace } from '../../../consts';
import { RemStyled } from './RemediationPanel.styled';
import { useTheme } from 'styled-components';

export const RemediationPanel: React.FC<{ remediation?: string }> = ({ remediation = '' }) => {
    const { t } = useTranslation(I18nRiskNamespace);
    const theme = useTheme();
    const textList = useMemo(() => {
        return remediation ? remediation.split('\n') : [];
    }, [remediation]);

    const createInstructionsArea = useCallback(() => {
        if (textList.length === 0) {
            return null;
        }
        if (textList.length === 1) {
            return textList[0];
        }
        return (
            <ol>
                {textList.map((text: string, index: number) => (
                    <li key={index}>{text}</li>
                ))}
            </ol>
        );
    }, [textList]);

    return (
        <RemStyled.TopDiv>
            <RemStyled.HeaderDiv>
                <RemStyled.HeaderIconDiv>
                    <Icon name={'remedyFill'} customColor={theme.palette.onSurface.brandPrimary} />
                </RemStyled.HeaderIconDiv>
                <RemStyled.HeaderTextDiv>
                    {t('ISSUES.ISSUE_VIEWER.PANELS.REMEDIATION.HOW_TO_REMEDIATE_IT')}
                </RemStyled.HeaderTextDiv>
            </RemStyled.HeaderDiv>
            <RemStyled.BodyDiv>
                <RemStyled.BodyBarDiv />
                <RemStyled.BodyTextDiv>{createInstructionsArea()}</RemStyled.BodyTextDiv>
            </RemStyled.BodyDiv>
        </RemStyled.TopDiv>
    );
};
