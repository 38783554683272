import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { FindingsFilterTypes as FilterTypes } from '../FindingsTable/Types/FindingsFilterTypes';
import { CDR_EVENTS_TABLE } from './CdrEventsPage.const';
import { createFavoritesFilter, createRecentlyUsedFilter } from 'common/utils/filterUtils';
import { DEFAULT_RANGES_VALUES } from 'common/components/FilterPanel/DefaultFilters/DateFilter/DateFilter.consts';

const getDateFilter = () => {
    return {
        id: FilterTypes.DATE_PICKER.id,
        filterProps: {
            defaultValue: DEFAULT_RANGES_VALUES.THIRTY_DAYS,
            options: [
                DEFAULT_RANGES_VALUES.FOUR_HOURS,
                DEFAULT_RANGES_VALUES.TWELVE_HOURS,
                DEFAULT_RANGES_VALUES.ONE_DAY,
                DEFAULT_RANGES_VALUES.WEEK,
                DEFAULT_RANGES_VALUES.THIRTY_DAYS,
                DEFAULT_RANGES_VALUES.ONE_YEAR,
            ],
            limitations: { maxDaysSelected: 365, maxDaysBack: 365 },
        },
    };
};

export const getCdrEventsTableFilters = (hiddenFilters: string[] | undefined): IProtectedAssetFilter[] => {
    return [
        FilterTypes.ADD_FILTER,
        getDateFilter(),
        FilterTypes.FREE_TEXT,
        createRecentlyUsedFilter(CDR_EVENTS_TABLE),
        createFavoritesFilter(CDR_EVENTS_TABLE),
        FilterTypes.CLEAR_ALL,
        FilterTypes.PLATFORM,
        FilterTypes.ORGANIZATIONAL_UNITS,
        FilterTypes.ENVIRONMENT,
        FilterTypes.RULE_NAME,
        FilterTypes.SEVERITY,
        FilterTypes.ENTITY_TYPE,
        FilterTypes.RULESET_NAME,
        FilterTypes.REGION,
        FilterTypes.ACTION,
        FilterTypes.ASSIGNEE,
        FilterTypes.STATUS,
        FilterTypes.SHOW_ACKNOWLEDGED,
        FilterTypes.SHOW_EXCLUDED,
        FilterTypes.MITRE_TACTIC,
        FilterTypes.MITRE_TECHNIQUE,
        FilterTypes.LOG_TYPE,
    ].filter((filter) => !hiddenFilters?.includes(filter.id));
};
