import { IBoxItem, ICustomFormProps, IItem } from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import {
    boxProps,
    getCommonIgnoreFailedNotification,
    getCommonIgnoreItemsMap,
    getCommonIgnoreSuccessNotification,
} from '../CommonIgnoreList/CommonIgnoreList.items';
import {
    CVE_IGNORE_FORM_DRAWER_KEY,
    CVE_IGNORE_LIST_PAGE_ID,
    CVE_IGNORE_LIST_MODEL_FIELDS,
    CveIgnoreNames,
    CveIgnoreInnerNames,
} from './CveIgnoreList.consts';
import { ICveIgnoreItem, ICveIgnorePartial } from './CveIgnoreList.interface';
import { ICommonIgnoreItemModel, ICommonItemsMap } from '../CommonIgnoreList/CommonIgnoreList.interface';
import { fetchAllCveIgnoreItems, saveIgnoreItem } from './CveIgnoreList.datasource';
import { IIssueCve } from '../../Issues/Issues.interface';
import { ermTrans } from '../../../RiskManagement.utils';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { CommonIgnoreNames } from '../CommonIgnoreList/CommonIgnoreList.consts';
import { getConditionsMap, getConditionValues, IConditionsMap } from '../../../RiskManagement.conditions';
import { getCommonIgnoreItemFromModel } from '../CommonIgnoreList/CommonIgnoreList.model';
import {
    commonBoxItemProps,
    commonLazySelectFieldItemProps,
} from 'common/erm-components/custom/CustomForm/CustomForm.consts';
import { checkOneOfCveFields } from './CveIgnoreList.validations';

const getItems = async (ignoreItem?: ICveIgnorePartial): Promise<IItem[]> => {
    const commonItemsMap: ICommonItemsMap = await getCommonIgnoreItemsMap(fetchAllCveIgnoreItems, ignoreItem);
    const cveItem: IBoxItem = await getCveBox(ignoreItem);
    return [
        commonItemsMap[CommonIgnoreNames.name],
        commonItemsMap[CommonIgnoreNames.description],
        commonItemsMap[CommonIgnoreNames.expirationDate],
        cveItem,
        commonItemsMap[CommonIgnoreNames.vulBox],
    ];
};

export const getCveIgnoreFormProps = (origIgnoreItem?: ICveIgnorePartial): ICustomFormProps => {
    return {
        getItems,
        submitData: saveIgnoreItem,
        getSubmitSuccessNotification: getCommonIgnoreSuccessNotification,
        getSubmitFailedNotification: getCommonIgnoreFailedNotification,
        title: origIgnoreItem?.id ? origIgnoreItem?.name || origIgnoreItem.id : '(New CVE Ignore Item)',
        minDialogHeight: 300,
        eventHandlerId: CVE_IGNORE_LIST_PAGE_ID,
    };
};

export const showCveIgnoreForm = (origIgnoreItem?: ICveIgnorePartial): void => {
    showDrawer(CVE_IGNORE_FORM_DRAWER_KEY, origIgnoreItem);
};

export const createIgnoreItemForCve = (cve: IIssueCve) => {
    const initialIgnoreItem: ICveIgnorePartial = {
        name: ermTrans('IGNORE_LIST.CVE_IGNORE_LIST.ACTIONS.SUGGESTED_IGNORE_ITEM_NAME', { cveId: cve.id }),
        description: ermTrans('IGNORE_LIST.CVE_IGNORE_LIST.ACTIONS.SUGGESTED_IGNORE_ITEM_DESCRIPTION', {
            cveId: cve.id,
        }),
        cveIds: [cve.id],
        entityId: cve.entityId,
    };

    showCveIgnoreForm(initialIgnoreItem);
};

export const getCveIgnoreItemFromModel = (model: ICommonIgnoreItemModel): ICveIgnoreItem => {
    const { filter } = model;
    const condMap: IConditionsMap = getConditionsMap(filter);
    const commonItems = getCommonIgnoreItemFromModel(model);
    return {
        ...commonItems,
        cveIds: getConditionValues(CVE_IGNORE_LIST_MODEL_FIELDS.CVE_IDS, condMap),
        packageNames: getConditionValues(CVE_IGNORE_LIST_MODEL_FIELDS.PACKAGE_NAME, condMap),
        packagePaths: getConditionValues(CVE_IGNORE_LIST_MODEL_FIELDS.PACKAGE_PATH, condMap),
    };
};

export const getCveIgnoreItemsFromModels = (models: ICommonIgnoreItemModel[]): ICveIgnoreItem[] => {
    return models.map((model) => getCveIgnoreItemFromModel(model));
};

export const getCveBox = async (ignoreItem?: ICveIgnorePartial): Promise<IBoxItem> => ({
    ...commonBoxItemProps,
    name: CveIgnoreNames.cveBox,
    checkValidation: checkOneOfCveFields,
    state: {
        ...boxProps,
        label: ermTrans('IGNORE_LIST.CVE_IGNORE_LIST.FORM.CVE_LABEL'),
        tooltip: ermTrans('IGNORE_LIST.CVE_IGNORE_LIST.FORM.TOOLTIP.CVE_LABEL'),
    },
    subItems: [
        {
            ...commonLazySelectFieldItemProps,
            name: CveIgnoreInnerNames.cveIds,
            state: {
                value: ignoreItem?.cveIds,
                label: ermTrans('IGNORE_LIST.CVE_IGNORE_LIST.TABLE.COLUMNS.CVE_IDS.HEADER'),
                tooltip: ermTrans('IGNORE_LIST.CVE_IGNORE_LIST.FORM.TOOLTIP.CVE_IDS'),
                placeholder: ermTrans('IGNORE_LIST.CVE_IGNORE_LIST.FORM.PLACEHOLDER.CVE_IDS'),
                disableWildcards: true,
            },
        },
        {
            ...commonLazySelectFieldItemProps,
            name: CveIgnoreInnerNames.packageNames,
            getLazyOptions: () => Promise.resolve([]),
            state: {
                label: ermTrans('IGNORE_LIST.CVE_IGNORE_LIST.TABLE.COLUMNS.PACKAGE_NAMES.HEADER'),
                value: ignoreItem?.packageNames,
                tooltip: ermTrans('IGNORE_LIST.CVE_IGNORE_LIST.FORM.TOOLTIP.PACKAGE_NAMES'),
            },
        },
        {
            ...commonLazySelectFieldItemProps,
            name: CveIgnoreInnerNames.packagePaths,
            getLazyOptions: () => Promise.resolve([]),
            state: {
                label: ermTrans('IGNORE_LIST.CVE_IGNORE_LIST.TABLE.COLUMNS.PACKAGE_PATHS.HEADER'),
                value: ignoreItem?.packagePaths,
                tooltip: ermTrans('IGNORE_LIST.CVE_IGNORE_LIST.FORM.TOOLTIP.PACKAGE_PATHS'),
            },
        },
    ],
});
