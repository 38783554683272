import { Checkbox, Message, Stack } from 'common/design-system/components-v2';
import { ModalSectionTitle } from '../../../notificationPage.styled';
import { useTranslation } from 'react-i18next';
import { I18nNotifications } from '../../../NotificationsPage.consts';
import { useState } from 'react';

interface AlertConsoleSectionProps {
    isAlertConsoleSelected: boolean | undefined;
    isReadOnly: boolean;
    isDefaultNotification: boolean;
    setIsAlertConsoleSelected: any;
}

const AlertConsoleSection = (alertConsoleProps: AlertConsoleSectionProps) => {
    const { isAlertConsoleSelected, isReadOnly, setIsAlertConsoleSelected, isDefaultNotification } = alertConsoleProps;
    const { t } = useTranslation(I18nNotifications);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleAlertConsoleChanged = () => {
        isAlertConsoleSelected ? setIsModalOpen(true) : setIsAlertConsoleSelected(!isAlertConsoleSelected);
    };

    const handleConfirm = () => {
        setIsAlertConsoleSelected(false);
        setIsModalOpen(false);
    };

    return (
        <>
            <Stack spacing={2} alignItems='center' direction='row' fullWidth>
                <ModalSectionTitle variant='body500'>
                    {t('MODALS.ADD_EDIT_MODAL.SECTIONS.ALERT_CONSOLE')}
                </ModalSectionTitle>
            </Stack>
            <Checkbox
                label={t('MODALS.ADD_EDIT_MODAL.CATEGORIES.ALERT_CONSOLE')}
                checked={isAlertConsoleSelected}
                disabled={isReadOnly || isDefaultNotification}
                onChange={() => handleAlertConsoleChanged()}
            />
            <Message
                isOpen={isModalOpen}
                title={t('MODALS.ADD_EDIT_MODAL.ALERT_CONSOLE_MODAL.TITLE')}
                text={t('MODALS.ADD_EDIT_MODAL.ALERT_CONSOLE_MODAL.TEXT')}
                onClose={() => setIsModalOpen(false)}
                onCancel={() => setIsModalOpen(false)}
                onConfirm={() => handleConfirm()}
            />
        </>
    );
};

export default AlertConsoleSection;
