import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import { GetScanResultsMetadata, GetScanResults, GetScanResultsCSV } from './cis.interface';

const requestTimeout = 1000 * 60;

const getScanResultsMetadata: GetScanResultsMetadata.Function = async (request) => {
    const { provider, cloudAccountId, queryParams } = request;
    return await getHttpService().request<AxiosResponse<GetScanResultsMetadata.Response>>(
        `workload/hostconfig/${provider}/account/${cloudAccountId}/scan-results-metadata?assetId=${queryParams.assetId}`,
        { method: 'GET', timeout: requestTimeout },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const getScanResults: GetScanResults.Function = async (request) => {
    const { provider, cloudAccountId, queryParams } = request;
    return await getHttpService().request<AxiosResponse<GetScanResults.Response>>(
        `workload/hostconfig/${provider}/account/${cloudAccountId}/scan-results?assetId=${queryParams.assetId}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const getScanResultsCSV: GetScanResultsCSV.Function = async (request) => {
    const { provider, cloudAccountId, queryParams } = request;
    return await getHttpService().request<AxiosResponse<ArrayBuffer>>(
        `workload/hostconfig/${provider}/account/${cloudAccountId}/scan-results-csv?assetId=${queryParams.assetId}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const cisService = {
    getScanResultsMetadata,
    getScanResults,
    getScanResultsCSV,
};

export default cisService;
