import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Stack, JsonViewer } from 'common/design-system/components-v2';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { getEntityService } from 'common/interface/services';
import { getAssetInfoFromLocation } from 'common/components/ProtectedAssets/AssetUtils';

interface EntityViewerPanelProps {
    entity: ICloudEntityData;
}

const EntityViewerPanel: React.FC<EntityViewerPanelProps> = ({ entity }) => {
    const [data, setData] = useState<any>(null);
    const location = useLocation();
    const assetInfo = getAssetInfoFromLocation(location);

    const getEntityData = async () => {
        try {
            const payload = {
                id: entity.cloudEntity.id || entity.cloudEntity.externalId || entity.cloudEntity.dome9Id,
                type: assetInfo.type,
                platform: assetInfo.platform,
                cloudAccountId: assetInfo.cloudAccountId,
            };
            const asset = await getEntityService().getEntity(
                payload.id,
                payload.type,
                payload.platform,
                payload.cloudAccountId,
            );
            setData(asset);
        } catch (error) {
            if (entity.cloudEntity) {
                setData(entity.cloudEntity);
            }
        }
    };
    useEffect(() => {
        getEntityData();
        // TODO: fix dependencies - if decide to ignore explain why
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Stack spacing={8} data-aid='entity-viewer-panel'>
            {data && <JsonViewer showExpandAll showSearch data={data} />}
        </Stack>
    );
};

export default EntityViewerPanel;
