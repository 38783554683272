import {
    IMalwareIgnoreItem,
    IMalwareIgnoreListService,
    IMalwareIgnoreModel,
    IMalwareIgnoreModelUpdate,
    IMalwareIgnoreUpdate,
} from './MalwareIgnoreList.interface';
import { sendHttpRequest } from 'common/erm-components/utils/ermComponents.http';
import {
    getCommonIgnoreItemFilterConditions,
    getCommonIgnoreItemModelUpdateFromItem,
} from '../CommonIgnoreList/CommonIgnoreList.model';
import { clearCacheDataByTag, getCacheTag } from 'common/utils/apiCaching';
import { getHttpService } from 'common/interface/services';
import { generalApiError } from 'common/utils/http';
import { IdResponse } from 'common/erm-components/utils/ermComponents.interface';
import { MALWARE_IGNORE_LIST_MODEL_FIELDS, MALWARE_IGNORE_RESOURCE_URL } from './MalwareIgnoreList.consts';
import { getMalwareIgnoreItemsFromModels } from './MalwareIgnoreList.items';
import { ICommonIgnoreItemModelUpdate } from '../CommonIgnoreList/CommonIgnoreList.interface';
import { FilterConditionOperator, IFilterCondition } from 'common/erm-components/custom/FilterTree/FilterCondition';
import { addConditionIfExists } from '../../../RiskManagement.conditions';
import { ICompoundFilterNode } from 'common/erm-components/custom/FilterTree/CompoundFilter';
import { getCleanCompoundFilter } from 'common/erm-components/utils/filters';

const MALWARE_IGNORE_LIST_SERVICE_NAME = 'MALWARE_IGNORE_LIST_SERVICE_NAME';

const getMalwareIgnoreListUrl = (itemId: string) => `${MALWARE_IGNORE_RESOURCE_URL}/${itemId}`;

const getModelUpdateFromItemUpdate = (updateItem: IMalwareIgnoreUpdate): IMalwareIgnoreModelUpdate => {
    const commonUpdateModel: ICommonIgnoreItemModelUpdate = getCommonIgnoreItemModelUpdateFromItem(updateItem);
    const conditions: IFilterCondition[] = getCommonIgnoreItemFilterConditions(updateItem);
    addConditionIfExists(
        conditions,
        MALWARE_IGNORE_LIST_MODEL_FIELDS.MALWARE_IDS,
        updateItem.malwareIds,
        FilterConditionOperator.In,
    );

    const filter: ICompoundFilterNode | undefined = getCleanCompoundFilter(conditions);
    return {
        ...commonUpdateModel,
        filter,
    };
};

export class MalwareIgnoreListService implements IMalwareIgnoreListService {
    private clearMultiActionsCache() {
        clearCacheDataByTag(MALWARE_IGNORE_LIST_SERVICE_NAME);
    }
    private clearSpecificActionCache(issueExclusionId: string) {
        clearCacheDataByTag(MALWARE_IGNORE_LIST_SERVICE_NAME, issueExclusionId);
    }

    public async getAllIgnoreItems(): Promise<IMalwareIgnoreItem[]> {
        const models: IMalwareIgnoreModel[] = await sendHttpRequest<IMalwareIgnoreModel[]>(
            MALWARE_IGNORE_RESOURCE_URL,
            { method: 'GET' },
            undefined,
            [getCacheTag(MALWARE_IGNORE_LIST_SERVICE_NAME)],
        );
        return getMalwareIgnoreItemsFromModels(models);
    }

    public async updateIgnoreItem(itemId: string, updateItem: IMalwareIgnoreUpdate): Promise<string> {
        this.clearMultiActionsCache();
        this.clearSpecificActionCache(itemId);
        const updateModel: IMalwareIgnoreModelUpdate = getModelUpdateFromItemUpdate(updateItem);
        return getHttpService().request<string>(
            getMalwareIgnoreListUrl(itemId),
            {
                method: 'PUT',
                data: updateModel,
            },
            undefined,
            generalApiError,
        );
    }

    public async createIgnoreItem(newItem: IMalwareIgnoreUpdate): Promise<string> {
        this.clearMultiActionsCache();
        const newModel: IMalwareIgnoreModelUpdate = getModelUpdateFromItemUpdate(newItem);
        const response: IdResponse = await getHttpService().request<IdResponse>(
            MALWARE_IGNORE_RESOURCE_URL,
            {
                method: 'POST',
                data: newModel,
            },
            undefined,
            generalApiError,
        );
        return response.id;
    }

    public async deleteIgnoreItem(itemId: string, multiTagAlreadyCleared?: boolean): Promise<string> {
        if (!multiTagAlreadyCleared) {
            this.clearMultiActionsCache();
        }
        this.clearSpecificActionCache(itemId);
        return getHttpService().request<string>(
            getMalwareIgnoreListUrl(itemId),
            {
                method: 'DELETE',
            },
            undefined,
            generalApiError,
        );
    }

    public async deleteIgnoreItems(itemIds: string[], throwExceptionUponFailures?: boolean): Promise<string[]> {
        this.clearMultiActionsCache();
        const promises = itemIds.map((itemId) => {
            return this.deleteIgnoreItem(itemId, true);
        });
        if (throwExceptionUponFailures) {
            return Promise.all(promises).then(() => []);
        } else {
            const failedIds: string[] = [];
            return Promise.allSettled(promises).then((results: PromiseSettledResult<any>[]) => {
                results.forEach((result: PromiseSettledResult<any>, index) => {
                    if (result.status === 'rejected') {
                        failedIds.push(itemIds[index]);
                    }
                });
                return failedIds;
            });
        }
    }
}
