import React from 'react';
import { Stack, Typography } from 'common/design-system/components-v2';
import { ICellRendererParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { EBooleanString, EColumnGroupState, getColumnGroupState } from 'modules/workloads/utils/aggrid';
import { Dot } from '../../components/Dot/Dot';

const PackageInUseCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const { node, value: paramValue } = params;
    const { t } = useTranslation(getK8sNamespace('vulnerability'));
    const groupState = getColumnGroupState('packageInUse', node);

    if (groupState === EColumnGroupState.OTHER) {
        return null;
    }

    const value = node.groupData?.['ag-Grid-AutoColumn'] ?? paramValue;

    const renderItems: { isRelevant: () => boolean; render: JSX.Element }[] = [
        {
            isRelevant: () => Boolean(value) && groupState === EColumnGroupState.NONE,
            render: <Dot isTrue />,
        },
        {
            isRelevant: () => groupState === EColumnGroupState.SELF,
            render: <Typography>{t(`VulnerabilityTable.cells.isInUse.${value === EBooleanString.TRUE}`)}</Typography>,
        },
    ];

    return (
        <Stack direction='row' alignItems='center' justifyContent='center' fullHeight fullWidth spacing={1}>
            {renderItems.reduce<JSX.Element[]>((accum, renderItem) => {
                return renderItem.isRelevant() ? [...accum, renderItem.render] : accum;
            }, [])}
        </Stack>
    );
};

export default PackageInUseCellRenderer;
