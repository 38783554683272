import i18n, { validLanguages } from 'common/services/translations/translations';
import { I18nRemediation } from './helpers/remediation.consts';
export const initializeI18nRemediationModals = async () => {
    return Promise.all(
        validLanguages.map(async (language) => {
            try {
                const contents = await require(`./languages/${language}/translation.json`);
                i18n.addResourceBundle(language, `${I18nRemediation}`, contents);
            } catch {
                //Nothing to do with missing translation files
            }
        }),
    );
};
