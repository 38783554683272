import { IProtectedAssetDetailProvider } from 'common/module_interface/assets/ProtectedAssets';
import { InfoPaneFieldProps } from 'common/components/InfoPane/InfoPane';
import KubernetesEnvironmentLink from '../components/KubernetesEnvironmentLink';
import ImageScanInfo from '../components/ImageScanInfo';
import MoreInfoImage from '../components/ImageShaLink';
import i18n from 'common/services/translations/translations';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { ProtectedAssetsPageRegistry } from 'common/module_interface/assets/ProtectedAssetsPageRegistry';
import { removeShaFromImage, getScanStatusData } from 'modules/workloads/utils';
import { AssetTypes } from 'common/assets/assets.const';
import { getAdditionalFieldValue } from 'common/utils/ColumnDisplayValueGetters';

const isRelevantImageInfoPanel = new Set([
    AssetTypes.KUBERNETES_KUBERNETESIMAGE,
    AssetTypes.CONTAINERREGISTRY_CONTAINERREGISTRYIMAGE,
    AssetTypes.SHIFTLEFT_SHIFTLEFTIMAGE,
    AssetTypes.AWS_ECSIMAGE,
    AssetTypes.AWS_INSTANCE,
    AssetTypes.AZURE_VIRTUALMACHINE,
    AssetTypes.AZURE_FUNCTIONAPP,
]);

export function initializeInfoPaneFieldProps() {
    const kubernetesEnvironmentField: IProtectedAssetDetailProvider<InfoPaneFieldProps> = {
        isRelevant: ({ typeByPlatform }) => {
            return [AssetTypes.AWS_EKSCLUSTER, AssetTypes.AZURE_AKSCLUSTER, AssetTypes.GCP_GKECLUSTER].includes(
                typeByPlatform,
            );
        },
        getValue({ protectedAsset }): InfoPaneFieldProps {
            return {
                title: i18n.t(`${getK8sNamespace('protected-asset-details')}:infoPane.title`),
                value: KubernetesEnvironmentLink,
                componentProps: { dome9Id: protectedAsset.id },
            };
        },
    };

    const imageInfoPanelScanStatus: IProtectedAssetDetailProvider<InfoPaneFieldProps> = {
        isRelevant: ({ typeByPlatform, protectedAsset }) =>
            isRelevantImageInfoPanel.has(typeByPlatform) && !!getScanStatusData(protectedAsset).scanStatus,
        getValue({ protectedAsset }): InfoPaneFieldProps {
            const { scanStatus, tooltip, date } = getScanStatusData(protectedAsset);

            return {
                title: i18n.t(`${getK8sNamespace('protected-asset-details')}:infoPane.ScanStatus`),
                value: ImageScanInfo,
                componentProps: { text: scanStatus, tooltip, date },
            };
        },
    };

    const baseImage: IProtectedAssetDetailProvider<InfoPaneFieldProps> = {
        isRelevant: ({ typeByPlatform, protectedAsset }) =>
            isRelevantImageInfoPanel.has(typeByPlatform) && !!getAdditionalFieldValue(protectedAsset, 'BaseImageId'),
        getValue({ protectedAsset }): InfoPaneFieldProps {
            const imageIdWithoutSha = removeShaFromImage(getAdditionalFieldValue(protectedAsset, 'BaseImageId'));
            return {
                title: 'Based On',
                value: MoreInfoImage,
                componentProps: {
                    field: getAdditionalFieldValue(protectedAsset, 'BaseImageId'),
                    imageEntityId: imageIdWithoutSha,
                },
            };
        },
    };

    const awsLambdaRuntime: IProtectedAssetDetailProvider<InfoPaneFieldProps> = {
        isRelevant: ({ typeByPlatform }) => typeByPlatform === AssetTypes.AWS_LAMBDA,
        getValue({ cloudEntity: { runtimeEnvironment } }) {
            return {
                title: i18n.t(`${getK8sNamespace('protected-asset-details')}:infoPane.runtime`),
                value: runtimeEnvironment,
            };
        },
    };

    ProtectedAssetsPageRegistry.addFieldAddins([
        {
            id: 'kubernetesEnvironment',
            position: 90,
            content: kubernetesEnvironmentField,
        },
        {
            id: 'imageInfoPanelScanStatus',
            position: 90,
            content: imageInfoPanelScanStatus,
        },
        {
            id: 'base image',
            position: 91,
            content: baseImage,
        },
        {
            id: 'awsLambdaRuntime',
            position: 92,
            content: awsLambdaRuntime,
        },
    ]);
}
