import React, { ReactNode, useCallback, useMemo } from 'react';
import { Button, Modal, Table } from 'common/design-system/components-v2';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { BotStatus } from 'common/module_interface/intelligence/CloudBots/CloudBots.const';
import { GenericObject } from 'common/interface/general';
import { useCloudBotStatusChecker } from '../../Hooks/useCloudBotStatusChecker';
import { GridOptions } from 'ag-grid-community';
import { CloudBotStatusModalStyles as Styles } from './CloudBotStatusModal.styled';
import { ModalHeaderProps } from 'common/design-system/components-v2/Modal/ModalHeader/ModalHeader.types';
import { getColumnDefs } from './CloudBotTable.columns';
import CloudBotErrorDetailsCellRenderer from '../../CellRenderers/CloudBotErrorDetailsCellRenderer';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { ICloudBotStatusModalProps } from './CloudBotStatusModal.types';

const getModalHeaderPropsMappings = (): GenericObject<ModalHeaderProps> => {
    const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);
    return {
        [BotStatus.IN_PROGRESS]: {
            title: t('CLOUD_BOTS.BOT_STATUS.FIX_IN_PROGRESS'),
        },
        [BotStatus.SUCCESS]: {
            title: t('CLOUD_BOTS.BOT_STATUS.FIX_COMPLETED'),
            icon: 'success-outline',
        },
        [BotStatus.FAILED]: {
            title: t('CLOUD_BOTS.BOT_STATUS.FIX_FAILED'),
            icon: 'error-outline',
        },
    };
};

export const CloudBotStatusModal: React.FC<ICloudBotStatusModalProps> = (props: ICloudBotStatusModalProps) => {
    const { closeModal, tableId } = props;
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const { cloudBotRemediationData, cloudBotStatus, clearBotStatus } = useCloudBotStatusChecker({ tableId });
    const showModalFooter: boolean = [BotStatus.SUCCESS, BotStatus.FAILED].includes(cloudBotStatus);

    const getModalHeaderProps = useCallback((status: BotStatus) => {
        return getModalHeaderPropsMappings()[status];
    }, []);

    const modalHeaderActions: Array<JSX.Element> = useMemo(() => {
        const MinimizeButton: JSX.Element = (
            <Button
                key={'minimize-button-action'}
                iconProps={{ name: 'minus', size: 14 }}
                variant='text'
                size='small'
                tooltip={t('CLOUD_BOTS.MINIMIZE')}
                onClick={closeModal}
            />
        );
        return [MinimizeButton];
    }, [closeModal, t]);

    const clearFixItState = useCallback(async () => {
        clearBotStatus();
        closeModal();
    }, [clearBotStatus, closeModal]);

    const getModalFooterActions = useCallback((): ReactNode => {
        const modalFooterActionMappings: GenericObject<ReactNode> = {
            [BotStatus.SUCCESS]: (
                <Button key={'close-button-action'} color='brandPrimary' onClick={clearFixItState}>
                    {i18n.t('COMMON.CLOSE_PASCAL')}
                </Button>
            ),
            [BotStatus.FAILED]: (
                <Button
                    key={'acknowledge-button-action'}
                    color='brandPrimary'
                    onClick={clearFixItState}
                    dataAid='Acknoledge'
                >
                    {t('CLOUD_BOTS.MODAL_ACTIONS.ACKNOWLEDGE')}
                </Button>
            ),
        };
        return modalFooterActionMappings[cloudBotStatus];
    }, [clearFixItState, cloudBotStatus, t]);

    const gridOptions: GridOptions = useMemo(() => {
        return {
            columnDefs: getColumnDefs(),
            defaultColDef: {
                sortable: true,
            },
            rowData: cloudBotRemediationData?.workItemsTriggerStatuses,
            masterDetail: true,
            detailCellRenderer: CloudBotErrorDetailsCellRenderer,
        };
    }, [cloudBotRemediationData?.workItemsTriggerStatuses]);

    return (
        <Modal.ModalDialog onRequestClose={closeModal} isOpen={true} width={'lg'}>
            <Modal.ModalHeader actions={modalHeaderActions} {...getModalHeaderProps(cloudBotStatus)} />
            <Modal.ModalContent>
                <Styles.TableWrapper>
                    <Table
                        tableId={`${tableId}.cloud-bot-status-table`}
                        gridOptions={gridOptions}
                        disableGrouping={true}
                        disableColumnMenu={true}
                    ></Table>
                </Styles.TableWrapper>
            </Modal.ModalContent>
            {showModalFooter && (
                <Modal.ModalFooter>
                    <Styles.FooterActionsWrapper direction='row' justifyContent='flex-end' fullWidth spacing={2}>
                        {getModalFooterActions()}
                    </Styles.FooterActionsWrapper>
                </Modal.ModalFooter>
            )}
        </Modal.ModalDialog>
    );
};
