import {
    renderAddFilter,
    renderClearAll,
    renderDefaultDateFilter,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter,
    renderRecentlyUsedFilters,
    renderSavedFilters,
    renderTreeFilter,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import {
    FILTER_DISPAY_TYPES,
    FILTERS_API_OBJECT_KEYS,
    FILTERS_KEYS,
} from 'common/components/FilterPanel/FilterPanel.consts';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { IFiltersInitialData, IProtectedAssetFilterParams } from 'common/module_interface/assets/ProtectedAssets';
import { IDisplayMappingObject } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { getVendor, getVendorDisplayName } from 'common/consts/vendors';
import { buildCloudAccountList, mapEnvironmentItem, mapTypeItem } from 'common/module_interface/assets/utils';
import { getRegionDisplayName } from 'common/utils/vendorUtils';
import { isEmpty } from 'lodash';
import { DEFAULT_RANGES_VALUES } from 'common/components/FilterPanel/DefaultFilters/DateFilter/DateFilter.consts';
import { FindingsTableRegistry } from 'common/components/KustoEvents/FindingsTableRegistry';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { EMPTY_STRING } from 'common/consts/GeneralConsts';
import { getStatusName } from '../../Findings.utils';
import { TFunction } from 'i18next';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { ICloudAccount } from 'common/interface/data_services';
import { CommonEventFields } from 'common/module_interface/events/EventsConsts';
import { FindingSeverityServerEnum } from 'common/consts/FindingSeverity';
import {
    ensureDefaultBooleanAggregations,
    getFindingSeverityFilterProps,
    getYesNoDisplayMapping,
} from 'common/erm-components/utils/filters';
import {
    FindingStatusCode,
    KustoEventFields,
    KustoEventFilterFields,
} from 'common/components/KustoEvents/KustoEvent.const';
import { INumericValueCount } from 'common/interface/general';

export const ensureDefaultStatusAggregations = (aggregations: Aggregations): void => {
    const statusCounters: INumericValueCount[] | undefined = aggregations[
        KustoEventFields.statusId
    ] as INumericValueCount[];
    if (!statusCounters) {
        return;
    }

    const allStatusCodes: number[] = Object.values(FindingStatusCode)
        .filter((value: string | FindingStatusCode) => isFinite(Number(value)))
        .map(Number);

    allStatusCodes.forEach((currNumericKey: number) => {
        if (!statusCounters.find((item) => currNumericKey === item.value)) {
            statusCounters.push({ value: currNumericKey, count: 0 });
        }
    });
};

/**
 * common filter definitions for Intelligence Findings table
 */
function getFilterDefs(): Addin<IFilterProps>[] {
    const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);
    return [
        {
            id: FILTERS_KEYS.ADD_FILTER,
            content: {
                filterProps: { key: FILTERS_KEYS.ADD_FILTER },
                renderFunction: renderAddFilter,
            },
        },
        {
            id: FILTERS_KEYS.FREE_TEXT,
            content: {
                filterProps: {
                    key: FILTERS_KEYS.FREE_TEXT,
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.FREE_TEXT.TITLE'),
                },
                keyInObjectForAPI: FILTERS_API_OBJECT_KEYS.FREE_TEXT,
                renderFunction: renderDefaultFreeTextFilter,
            },
        },
        {
            id: FILTERS_KEYS.SAVED_FILTERS,
            content: function (params: IProtectedAssetFilterParams) {
                const filtersInitialData = params?.filtersInitialData ?? {};
                return {
                    filterProps: {
                        savedFilters: filtersInitialData?.savedFilters || [],
                        savedFiltersComponentName: EMPTY_STRING,
                        selectedFilterID: EMPTY_STRING,
                        key: FILTERS_KEYS.SAVED_FILTERS,
                    },
                    renderFunction: renderSavedFilters,
                };
            },
        },
        {
            id: FILTERS_KEYS.RECENTLY_USED_FILTERS,
            content: function (params: IProtectedAssetFilterParams) {
                const filtersInitialData = params?.filtersInitialData ?? {};
                return {
                    filterProps: {
                        key: FILTERS_KEYS.RECENTLY_USED_FILTERS,
                        recentlyUsedList: filtersInitialData?.recentlyUsed,
                        maxLength: 4,
                        componentName: EMPTY_STRING,
                        title: i18n.t('FILTER_PANEL.RECENTLY_USED_FILTERS'),
                    },
                    renderFunction: renderRecentlyUsedFilters,
                };
            },
        },
        {
            id: FILTERS_KEYS.DATE_PICKER,
            content: {
                filterProps: {
                    key: FILTERS_KEYS.DATE_PICKER,
                    defaultValue: DEFAULT_RANGES_VALUES.THIRTY_DAYS,
                    options: [
                        DEFAULT_RANGES_VALUES.FOUR_HOURS,
                        DEFAULT_RANGES_VALUES.TWELVE_HOURS,
                        DEFAULT_RANGES_VALUES.ONE_DAY,
                        DEFAULT_RANGES_VALUES.WEEK,
                        DEFAULT_RANGES_VALUES.THIRTY_DAYS,
                    ],
                    title: 'Date',
                    limitations: { maxDaysSelected: 30, maxDaysBack: 30 },
                },
                keyInObjectForAPI: FILTERS_API_OBJECT_KEYS.DATE_PICKER,
                displayTypes: [FILTER_DISPAY_TYPES.ROW],
                renderFunction: renderDefaultDateFilter,
            },
        },
        {
            id: FILTERS_KEYS.CLEAR_BUTTON,
            content: {
                filterProps: {
                    key: FILTERS_KEYS.CLEAR_BUTTON,
                },
                renderFunction: renderClearAll,
            },
        },
        {
            id: KustoEventFilterFields.OrganizationalUnitId,
            content: function (params: IProtectedAssetFilterParams) {
                const filtersInitialData: IFiltersInitialData = params?.filtersInitialData ?? {};
                return {
                    filterProps: {
                        isMultiSelect: true,
                        initialData: filtersInitialData?.organizationalUnits,
                        key: CommonEventFields.organizationalUnitId,
                        title: t('FINDINGS_TABLE.FILTERS.ORGANIZATIONAL_UNITS.TITLE'),
                    },
                    renderFunction: renderTreeFilter,
                };
            },
        },
        {
            id: KustoEventFilterFields.CloudAccountType,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                aggregations[CommonEventFields.cloudAccountType]?.forEach((platform: { value: string }) => {
                    displayMapping[platform.value] = {
                        displayText: getVendorDisplayName(platform.value),
                        icon: getVendor(platform.value)?.icon,
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations[CommonEventFields.cloudAccountType],
                        key: CommonEventFields.cloudAccountType,
                        title: t('FINDINGS_TABLE.FILTERS.PLATFORM.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: KustoEventFilterFields.EntityTypeByEnvironmentType,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                return {
                    filterProps: {
                        initialData: aggregations[CommonEventFields.entityTypeByEnvironmentType],
                        key: CommonEventFields.entityTypeByEnvironmentType,
                        title: t('FINDINGS_TABLE.FILTERS.ENTITY_TYPE.TITLE'),
                        displayMapping: mapTypeItem(aggregations[CommonEventFields.entityTypeByEnvironmentType]),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: KustoEventFilterFields.CloudAccountId,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const allCloudAccounts: ICloudAccount[] = params?.allCloudAccounts ?? [];
                const allCloudAccountsItems = aggregations[CommonEventFields.cloudAccountId]
                    ? buildCloudAccountList(aggregations[CommonEventFields.cloudAccountId], allCloudAccounts)
                    : [];
                return {
                    filterProps: {
                        itemCountGroupOption: {
                            enableGrouping: true,
                            countedItemsHeader: t('FINDINGS_TABLE.FILTERS.ENVIRONMENT.COUNTED_ITEMS_HEADER'),
                            nonCountedItemsHeader: t('FINDINGS_TABLE.FILTERS.ENVIRONMENT.NON_COUNTED_ITEMS_HEADER'),
                        },
                        initialData: allCloudAccountsItems,
                        key: CommonEventFields.cloudAccountId,
                        title: t('FINDINGS_TABLE.FILTERS.ENVIRONMENT.TITLE'),
                        displayMapping: mapEnvironmentItem(allCloudAccounts),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: KustoEventFilterFields.Severity,
            content: function (params: IProtectedAssetFilterParams) {
                const allowedSeverities: FindingSeverityServerEnum[] = [
                    FindingSeverityServerEnum.Critical,
                    FindingSeverityServerEnum.High,
                    FindingSeverityServerEnum.Medium,
                    FindingSeverityServerEnum.Low,
                    FindingSeverityServerEnum.Informational,
                ];
                const aggregations: Aggregations = params?.aggregations ?? {};
                for (const currSeverityServerKey of allowedSeverities) {
                    if (
                        !aggregations[CommonEventFields.severity]?.find(
                            (aggregationItem) => currSeverityServerKey === aggregationItem.value,
                        )
                    ) {
                        aggregations[CommonEventFields.severity]?.push({ value: currSeverityServerKey, count: 0 });
                    }
                }
                return {
                    filterProps: {
                        key: CommonEventFields.severity,
                        title: t('FINDINGS_TABLE.FILTERS.SEVERITY.TITLE'),
                        ...getFindingSeverityFilterProps(aggregations, CommonEventFields.severity),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: KustoEventFilterFields.EntityName,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                return {
                    filterProps: {
                        initialData: aggregations[CommonEventFields.entityName],
                        key: CommonEventFields.entityName,
                        title: t('FINDINGS_TABLE.FILTERS.ENTITY.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: KustoEventFilterFields.Region,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                aggregations[CommonEventFields.region]?.forEach((regionData: { value: string }) => {
                    displayMapping[regionData.value] = { displayText: getRegionDisplayName(regionData.value) };
                });
                return {
                    filterProps: {
                        initialData: aggregations[CommonEventFields.region],
                        key: CommonEventFields.region,
                        title: t('FINDINGS_TABLE.FILTERS.REGION.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: KustoEventFilterFields.RuleName,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                return {
                    filterProps: {
                        initialData: aggregations[CommonEventFields.ruleName],
                        key: CommonEventFields.ruleName,
                        title: t('FINDINGS_TABLE.FILTERS.RULE_NAME.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: KustoEventFilterFields.BundleName,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                return {
                    filterProps: {
                        initialData: aggregations[CommonEventFields.bundleName],
                        key: CommonEventFields.bundleName,
                        title: t('FINDINGS_TABLE.FILTERS.RULESET_NAME.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: KustoEventFilterFields.Action,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                return {
                    filterProps: {
                        initialData: aggregations[CommonEventFields.action],
                        key: CommonEventFields.action,
                        title: t('FINDINGS_TABLE.FILTERS.ACTION.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: KustoEventFilterFields.Remediation,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                aggregations[CommonEventFields.remediation]?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: isEmpty(item.value) ? 'Empty' : item.value,
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations[CommonEventFields.remediation],
                        key: CommonEventFields.remediation,
                        title: t('FINDINGS_TABLE.FILTERS.REMEDIATION.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: KustoEventFilterFields.Category,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                aggregations[CommonEventFields.category]?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: isEmpty(item.value) ? 'None' : item.value,
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations[CommonEventFields.category],
                        key: CommonEventFields.category,
                        title: t('FINDINGS_TABLE.FILTERS.CATEGORY.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: KustoEventFilterFields.IsExcluded,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                ensureDefaultBooleanAggregations(aggregations, CommonEventFields.isExcluded);
                return {
                    filterProps: {
                        initialData: aggregations[CommonEventFields.isExcluded],
                        key: CommonEventFields.isExcluded,
                        title: t('FINDINGS_TABLE.FILTERS.SHOW_EXCLUDED.TITLE'),
                        displayMapping: getYesNoDisplayMapping(),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: KustoEventFilterFields.StatusId,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                ensureDefaultStatusAggregations(aggregations);
                const displayMapping: IDisplayMappingObject = {};
                aggregations[KustoEventFields.statusId]?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: getStatusName(Number(item.value)),
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations[KustoEventFields.statusId],
                        key: KustoEventFields.statusId,
                        title: t('FINDINGS_TABLE.FILTERS.STATUS.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: KustoEventFilterFields.Origin,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                return {
                    filterProps: {
                        initialData: aggregations[CommonEventFields.origin],
                        key: CommonEventFields.origin,
                        title: t('FINDINGS_TABLE.FILTERS.ORIGIN.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: KustoEventFilterFields.MitreTactic,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                aggregations[KustoEventFields.mitreTactic]?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: !isEmpty(item.value) ? item.value : 'Empty',
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations[KustoEventFields.mitreTactic],
                        key: KustoEventFields.mitreTactic,
                        title: t('FINDINGS_TABLE.FILTERS.TACTIC.TITLE'),
                        isMitreField: true,
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: KustoEventFilterFields.MitreTechnique,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                aggregations[KustoEventFields.mitreTechnique]?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: !isEmpty(item.value) ? item.value : 'Empty',
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations[KustoEventFields.mitreTechnique],
                        key: KustoEventFields.mitreTechnique,
                        title: t('FINDINGS_TABLE.FILTERS.TECHNIQUE.TITLE'),
                        isMitreField: true,
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: KustoEventFilterFields.OwnerUserName,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                aggregations[CommonEventFields.ownerUserName]?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: isEmpty(item.value)
                            ? t('FINDINGS_TABLE.CELL_RENDERERS.ASSIGNEE.UNASSIGNED')
                            : item.value,
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations[CommonEventFields.ownerUserName],
                        key: CommonEventFields.ownerUserName,
                        title: t('FINDINGS_TABLE.FILTERS.ASSIGNEE.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: KustoEventFilterFields.Acknowledged,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                ensureDefaultBooleanAggregations(aggregations, CommonEventFields.acknowledged);
                return {
                    filterProps: {
                        initialData: aggregations[CommonEventFields.acknowledged],
                        key: CommonEventFields.acknowledged,
                        title: t('FINDINGS_TABLE.FILTERS.SHOW_ACKNOWLEDGED.TITLE'),
                        displayMapping: getYesNoDisplayMapping(),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: KustoEventFilterFields.LogType,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                aggregations[KustoEventFields.logType]?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: item.value ?? 'Empty',
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations[KustoEventFields.logType],
                        key: KustoEventFields.logType,
                        title: t('FINDINGS_TABLE.FILTERS.LOG_TYPE.TITLE'),
                        displayMapping: displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
    ];
}

export function initializeFilterDefs() {
    FindingsTableRegistry.addFilterDefs(getFilterDefs());
}
