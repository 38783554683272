import * as yup from 'yup';

export const DEFAULT_VALUES = {
    id: '',
    ruleName: '',
    environments: [],
    repository: '',
    description: '',
};

export const SignupSchema = yup.object().shape({
    ruleName: yup.string().trim().required('Rule name is required'),
    environments: yup.array().min(1, 'Registry environment is required'),
    repository: yup.string().trim().required('Repository is required'),
    description: yup.string().trim().required('Description is required'),
});
