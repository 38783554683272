import { getHttpService } from 'common/interface/services';
import { GetEcsImagesScanStatus, UpdateEcsImagesScan } from './ecs.interface';

const updateEcsImagesScan: UpdateEcsImagesScan.Function = async (cloudAccountId, activate) => {
    return await getHttpService().request(
        `cloudAccount/ecs/${cloudAccountId}`,
        { method: 'POST', data: { ecsImageScan: activate } },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const getEcsImagesScanStatus: GetEcsImagesScanStatus.Function = async (cloudAccountId: string) => {
    return await getHttpService().request(
        `cloudAccount/ecs/${cloudAccountId}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const EcsService = {
    updateEcsImagesScan,
    getEcsImagesScanStatus,
};

export default EcsService;
