import { Markdown, Stack, Typography } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { customizeComponents } from '../AwpHostConfigRuleDrawer.styles';
import { useRuleContext } from '../context/Rule/useRuleContext.hook';

const Description = () => {
    const {
        data: {
            rule: { description, rationale },
        },
    } = useRuleContext();
    const { t } = useTranslation(getK8sNamespace('cis'), { keyPrefix: 'sections' });
    const sections: Array<[title: string, body: string]> = [
        ['description', description],
        ['rationale', rationale],
    ];

    return (
        <Stack spacing={4}>
            {sections.map(([title, body]) => {
                return (
                    <Stack spacing={2} key={title}>
                        <Typography variant='bodyLg'>{t(title)}</Typography>
                        <Markdown markdown={body} customizeComponents={customizeComponents} />
                    </Stack>
                );
            })}
        </Stack>
    );
};
export default Description;
