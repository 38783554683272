import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import { GslRun } from './gsl-run.interface';

const gslRun = async <T>(request: GslRun.Request, timeout?: number, useCache = true): GslRun.Response<T> => {
    return await getHttpService().request<AxiosResponse<T>>(
        'gslws/gsl-run?action=fetch',
        { method: 'POST', data: request, timeout, timeoutErrorMessage: 'GSL query timed out' },
        { returnAsAxiosResponse: true, cachingConfig: { useCache, dataAgeLimit: 300 } },
        (err) => {
            throw err;
        },
    );
};

const GslRunService = {
    gslRun,
};

export default GslRunService;
