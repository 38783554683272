import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { Addin } from 'common/extensibility/AddinRegistry';
import { OciEnvironmentAddinRegistry, OciEnvironmentAddingBase } from 'common/helpers/ociEnvironment';
import { OciEnvironmentData } from 'common/module_interface/assets/OciEnvironment';
import { StatusCellRenderer_Status } from 'common/module_interface/assets/Environments';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { getOciEnvNamespace } from './initialize.i18n';
import { changeUrl } from 'common/utils/http';
import { getCloudAccountsService } from 'common/interface/data_services';
import { Vendors } from 'common/consts/vendors';
import { Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import { getEventsTableWidgetService } from 'common/module_interface/events/EventsTableRegistry';
import { IChipProps } from 'common/design-system/components-v2/Chip/Chip.types';
import { CommonEventFields } from 'common/module_interface/events/EventsConsts';
import { lazy } from 'react';

export const initialOciEnvironmentData = () => {
    const tabs: Addin<OciEnvironmentAddingBase<Tab>>[] = [
        {
            id: 'readiness',
            position: 10,
            content: {
                id: 'readiness',
                getValue: () => ({
                    name: 'readiness',
                    label: i18next.t('ociPage.tabs.readiness', { ns: getOciEnvNamespace('oci') }),
                    isReactTab: false,
                }),
            },
        },
        {
            id: 'protected assets new',
            position: 30,
            content: {
                id: 'protected assets new',
                getValue: (ociData: OciEnvironmentData) => ({
                    name: 'protected assets new',
                    label: i18next.t('ociPage.tabs.protectedAssets', { ns: getOciEnvNamespace('oci') }),
                    isReactTab: true,
                    reactTab: {
                        component: lazy(() => import('./tabs/Assets')),
                        componentProps: { cloudAccountId: ociData.account.id },
                    },
                }),
            },
        },
        {
            id: 'events',
            position: 40,
            content: {
                id: 'events',
                getValue: (ociData) => {
                    const EventsTable = getEventsTableWidgetService().getMainTable();
                    return {
                        name: 'events',
                        label: i18next.t('ociPage.tabs.events', { ns: getOciEnvNamespace('oci') }),
                        isReactTab: true,
                        reactTab: {
                            component: EventsTable,
                            componentProps: {
                                tableIdPrefix: `oci-env_${ociData.account.id}`,
                                disablePadding: true,
                                defaultFilters: {
                                    fields: [{ name: 'cloudAccountId_calc', value: ociData.account.id }],
                                },
                                hiddenFilters: [
                                    CommonEventFields.cloudAccountId,
                                    CommonEventFields.cloudAccountType,
                                    'organizational unit',
                                    CommonEventFields.organizationalUnitId,
                                    CommonEventFields.region,
                                ],
                                hiddenColumns: [CommonEventFields.cloudAccountId, CommonEventFields.cloudAccountType],
                            },
                        },
                    };
                },
            },
        },
        {
            id: 'compliance policies',
            position: 50,
            content: {
                id: 'compliance policies',
                getValue: () => ({
                    name: 'compliance policies',
                    label: i18next.t('ociPage.tabs.compliancePolicies', { ns: getOciEnvNamespace('oci') }),
                    isReactTab: false,
                }),
            },
        },
        {
            id: 'assessment history',
            position: 60,
            content: {
                id: 'assessment history',
                getValue: () => ({
                    name: 'assessment history',
                    label: i18next.t('ociPage.tabs.assessmentHistory', { ns: getOciEnvNamespace('oci') }),
                    isReactTab: false,
                }),
            },
        },
    ];
    OciEnvironmentAddinRegistry.addTabs(tabs);

    const detailsItems: Addin<OciEnvironmentAddingBase<BlockInfoProps>>[] = [
        {
            id: 'organizationalUnit',
            position: 10,
            content: {
                id: 'organizationalUnit',
                getValue: (ociData) => ({
                    title: i18next.t('ociPage.info.organizationalUnit', { ns: getOciEnvNamespace('oci') }),
                    info: ociData.account.organizationalUnitName,
                    copyable: true,
                }),
            },
        },
        {
            id: 'cloudGuardId',
            position: 30,
            content: {
                id: 'cloudGuardId',
                getValue: (ociData) => ({
                    title: i18next.t('ociPage.info.cloudGuardId', { ns: getOciEnvNamespace('oci') }),
                    info: ociData.account.id,
                    copyable: true,
                }),
            },
        },
        {
            id: 'onboardingTime',
            position: 40,
            content: {
                id: 'onboardingTime',
                getValue: (ociData) => ({
                    title: i18next.t('ociPage.info.onboardingTime', { ns: getOciEnvNamespace('oci') }),
                    info: dayjs(ociData.account.creationDate).format('MMM D, YYYY h:mm A Z'),
                }),
            },
        },
        {
            id: 'totalAssets',
            position: 60,
            content: {
                id: 'totalAssets',
                getValue: (ociData) => ({
                    title: i18next.t('ociPage.info.totalAssets', { ns: getOciEnvNamespace('oci') }),
                    info: i18next.t('COMMON.PRETTY_NUMBER', { value: ociData.account.totalAssets }),
                }),
            },
        },
    ];
    OciEnvironmentAddinRegistry.addDetailsPanelItems(detailsItems);

    const chips: Addin<OciEnvironmentAddingBase<IChipProps>>[] = [
        {
            id: 'environmentStatusChip',
            position: 20,
            content: {
                id: 'environmentStatusChip',
                customLoadingLabel: i18next.t('ociPage.chips.status.loading', { ns: getOciEnvNamespace('oci') }),
                getValue: async (ociData) => {
                    const syncStatusList = await getCloudAccountsService().getEnvironmentSyncStatus(true, (err) => {
                        throw err;
                    });
                    const status = syncStatusList.find(
                        (s) => s.platform === Vendors.OCI && s.id === ociData.account.id,
                    );
                    const syncStatus = status?.hasIssues
                        ? StatusCellRenderer_Status.warning
                        : StatusCellRenderer_Status.success;
                    const tooltipI18Key =
                        syncStatus === StatusCellRenderer_Status.warning
                            ? 'ociPage.chips.MISSING_PERMISSIONS'
                            : 'ociPage.chips.VALID_PERMISSIONS';
                    const chipProps: IChipProps = {
                        label: i18next.t(`ociPage.chips.status.${syncStatus}`, { ns: getOciEnvNamespace('oci') }),
                        leadingIconProps: {
                            name: syncStatus === StatusCellRenderer_Status.warning ? 'warning' : 'checkCircle',
                        },
                        context: syncStatus === StatusCellRenderer_Status.warning ? 'medium' : 'low',
                        disableInteraction: true,
                        tooltip: i18next.t(tooltipI18Key, { ns: getOciEnvNamespace('oci') }),
                    };

                    if (syncStatus === StatusCellRenderer_Status.warning) {
                        chipProps.disableInteraction = false;
                        chipProps.onClick = () => {
                            changeUrl(`/cloud-account/oci/${ociData.account.id}?tabName=readiness&tabOnly=true`);
                        };
                    }
                    return chipProps;
                },
            },
        },
    ];
    OciEnvironmentAddinRegistry.addChips(chips);
};
