import { IApplicationReducer } from 'common/interface/redux';
import azureReducer from './reducer';
import { initialAzureEnvironmentData } from './AzureEnvironment.consts';
import { getAppRootRegistry } from 'common/interface/services';

const initializeAzureEnvRedux = () => {
    const reducers: IApplicationReducer[] = [
        {
            name: 'env_azure',
            reducer: azureReducer,
            isBlackList: true,
        },
    ];
    getAppRootRegistry().addReducers(reducers, 'name');
};

export const initializeAzureEnv = () => {
    initializeAzureEnvRedux();
    initialAzureEnvironmentData();
};
