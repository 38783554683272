import { FC } from 'react';
import { SentinelInputType } from 'common/module_interface/settings/integrations/consts';
import { SentinelFilterPanel } from '../SentinelFilter';

interface IProvideFilterParametersProps {
    onFilterValueChange: (filtersValues: SentinelInputType) => void;
}

export const ProvideFilterParameters: FC<IProvideFilterParametersProps> = ({ onFilterValueChange }) => {
    return <SentinelFilterPanel onFilterValueChange={onFilterValueChange} />;
};
