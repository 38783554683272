import { GetGroupKey, GetRowsRequest, SearchGroup } from './types';
import {
    AgGridSortModel,
    CGColDef,
    GroupByRequest,
    GroupByResponse,
    SearchRequest,
} from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { getGroupByProperties } from 'common/components/ProtectedAssets/datasource';
import { SortingModel } from 'common/components/Findings/Findings.interface';

export const getSortOrder = (sortModels: AgGridSortModel[], columns: CGColDef[]): SortingModel | undefined => {
    if (sortModels.length === 0) {
        return undefined;
    }
    const columnToSortBy = columns.find(({ colId }) => colId === sortModels[0].colId);
    if (columnToSortBy === undefined) {
        return undefined;
    }
    const fieldName = columnToSortBy?.sortField || columnToSortBy.field;
    if (fieldName === undefined) {
        return undefined;
    }
    return {
        fieldName,
        direction: sortModels[0].sort === 'asc' ? 1 : -1,
    };
};

export const createGroupRows = (getRowsRequest: GetRowsRequest, groupByResponse: GroupByResponse) => {
    const nestedContext = getRowsRequest.groupKeys.reduce<[Record<string, string>, GroupByResponse]>(
        ([prevFields, prevAggData], value) => {
            const aggBucket = prevAggData.find((aggItem) => aggItem.fieldValue === value);
            if (!aggBucket) {
                return [{}, []];
            }
            return [{ ...prevFields, [aggBucket.fieldName]: aggBucket.fieldValue }, aggBucket.nestedBuckets];
        },
        [{}, groupByResponse],
    );
    const currentBucket = nestedContext[1].length ? nestedContext[1] : groupByResponse;
    return currentBucket.map((groupItem) => {
        return {
            ...nestedContext[0],
            [groupItem.fieldName]: groupItem.fieldValue,
            childCount: groupItem.numberOfDocuments,
            isGroup: true,
        };
    });
};

export const getKey: GetGroupKey = (getRowsRequest) => {
    return getRowsRequest.groupKeys.join(',');
};

export const createGroupByRequest = (getRowsRequest: GetRowsRequest, searchGroup: SearchGroup): GroupByRequest => {
    const sorting = getSortOrder(getRowsRequest.sortModel, getRowsRequest.columnsDefs);
    return {
        propertiesList: getGroupByProperties(getRowsRequest.rowGroupCols, sorting, false),
        filter: searchGroup.filters,
    };
};

export const createSearchRequest = (getRowsRequest: GetRowsRequest, searchGroup: SearchGroup): SearchRequest => {
    const sorting = getSortOrder(getRowsRequest.sortModel, getRowsRequest.columnsDefs);
    return {
        filter: searchGroup.filters,
        pageSize: searchGroup.pageSize,
        searchAfter: getRowsRequest.startRow === 0 ? [] : searchGroup.searchAfter,
        filterEntitiesWithoutRiskScore: true,
        ...(sorting ? { sorting } : {}),
    };
};

export function mutateRequest<Request, Attribute extends keyof Request>(
    request: Request,
    attribute: Attribute,
    value: Request[Attribute],
): Request {
    return { ...request, [attribute]: value };
}

export const createSearchGroup = (
    getRowsRequest: GetRowsRequest,
    pageSize: number,
    filters: SearchGroup['filters'],
): SearchGroup => {
    const newFields = getRowsRequest.groupKeys.map((value, index) => ({
        name: getRowsRequest.rowGroupCols[index].field!,
        value,
    }));
    return {
        pageSize,
        filters: {
            ...filters,
            fields: [...filters.fields, ...newFields],
        },
        current: 0,
        searchAfter: [],
    };
};
