import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Chip } from 'common/design-system/components-v2';
import { eventStatus } from 'common/module_interface/intelligence/Logs/Logs.consts';

const EventStatusCellRender: React.FC<ICellRendererParams> = (params) => {
    return (
        <Chip
            label={params.data.eventStatus}
            color={params.data.eventStatus === eventStatus.Success ? 'success' : 'alert'}
        />
    );
};

export default EventStatusCellRender;
