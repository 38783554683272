import initializeColumnDefs from './Definitions/ColumnDefinitions';
import initializeFilters from './Definitions/FilterDefinitions';
import initializeTabsDefs from './Definitions/TabsDefinitions';
import initializeActionsDefs from './Definitions/ActionsDefinitions';
import initializeExportsDefs from './Definitions/ExportsDefinitions';
import globalAddinContainer from 'common/extensibility/AddinContainer';
import {
    EVENTS_TABLE_WIDGET_ID,
    EventsTableFilters,
    EventsTableWidget,
} from 'common/module_interface/events/EventsTableRegistry';
import {
    EVENTS_URL,
    EVENT_DETAILS_DRAWER_KEY,
    EVENT_TYPE_URL_KEY,
    EventType,
} from 'common/module_interface/events/EventsConsts';
import { formatDate } from 'common/utils/dateUtils';
import { ChangeParamPayload } from 'common/hooks/useReactRouterQuery';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { lazy } from 'react';
import isArray from 'lodash/isArray';

export const eventsTypeParam = 'eventType';

export const initializeEventsTable = () => {
    initializeColumnDefs();
    initializeFilters();
    initializeTabsDefs();
    initializeActionsDefs();
    initializeExportsDefs();

    const formatFilters = (eventType: EventType, filters?: string | EventsTableFilters): ChangeParamPayload[] => {
        if (!filters) return [{ paramKey: EVENT_TYPE_URL_KEY, newValue: eventType }];
        if (typeof filters === 'string') {
            return [
                { paramKey: EVENT_TYPE_URL_KEY, newValue: eventType },
                { paramKey: 'query', newValue: filters },
            ];
        }
        const filterPanelString: string[] = [];
        if (filters.freeText) filterPanelString.push(`"free-text":"${filters.freeText}"`);
        if (filters.fields) {
            const groupedFields: Record<string, string[]> = {};
            filters.fields.forEach((field) => {
                if (!groupedFields[field.name]) groupedFields[field.name] = [];
                const value = isArray(field.value) ? field.value.join('","') : field.value;
                groupedFields[field.name].push(`${value}`);
            });
            Object.entries(groupedFields).forEach(([name, values]) => {
                const valueString = values.join('","');
                filterPanelString.push(`"${name}":["${valueString}"]`);
            });
        }
        if (filters.datePicker) {
            const displayName = `${formatDate(filters.datePicker.from)}-${formatDate(filters.datePicker.to)}`;
            filterPanelString.push(
                `"date-picker":{"displayName":"${displayName}","epoch":{"from":"${filters.datePicker.from}","to":"${filters.datePicker.to}"},"key":"Custom","isNewDateFilter":true}`,
            );
        }
        return [
            { paramKey: EVENT_TYPE_URL_KEY, newValue: eventType },
            { paramKey: 'filterPanel', newValue: `{${filterPanelString.join(',')}}` },
        ];
    };

    const eventsTableWidget: EventsTableWidget = {
        getMainTable: () => lazy(() => import('./EventsTable')),
        getMainTableLink: (eventType, filters) => {
            const filtersAsString = formatFilters(eventType, filters)
                .map((filter) => `${filter.paramKey}=${filter.newValue}`)
                .join('&');
            return `${EVENTS_URL}?${filtersAsString}`;
        },
        getParamsStringForEventsTab: (tabName, eventType, filters) => {
            const params = formatFilters(eventType, filters);
            params.push({ paramKey: 'tabName', newValue: tabName });
            return params;
        },
        openEventDrawer: (eventId, sysEventType) => {
            showDrawer(EVENT_DETAILS_DRAWER_KEY.key, { eventId, sysEventType });
        },
    };
    globalAddinContainer.addService(EVENTS_TABLE_WIDGET_ID, eventsTableWidget);
};
