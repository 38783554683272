import React, { useCallback } from 'react';
import { Command } from 'common/design-system/components-v2';
import { getSafeFindingSeverityInfo, IFindingSeverityInfo } from 'common/consts/FindingSeverity';
import { SeverityChip } from 'common/components/SeverityChip/SeverityChip';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import { RemediationBox } from '../RemediationBox/RemediationBox';
import i18n from 'i18next';
import { LevelIconProps } from 'common/design-system/components-v2/LevelIcon/LevelIcon.types';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import EntityCard from 'common/design-system/components-v2/EntityCard';

interface RuleCardProps {
    finding: IKustoEvent;
}

export const FindingRuleCard: React.FC<RuleCardProps> = ({ finding }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const NA_STRING = i18n.t('COMMON.N_A');

    const getBlocks = useCallback((): BlockInfoProps[] => {
        return [
            {
                title: t('CARD_PROPS.RULESET'),
                info: finding.bundleName,
                copyable: true,
                maxWidth: 350,
            },
            {
                title: t('CARD_PROPS.ID'),
                info: finding.ruleId,
                copyable: true,
                maxWidth: 350,
            },
            {
                title: t('CARD_PROPS.DESCRIPTION'),
                info: finding.description,
                maxWidth: 800,
            },
            {
                title: '',
                info: (
                    <RemediationBox
                        title={t('CARD_PROPS.REMEDIATION')}
                        remediation={finding.remediation || NA_STRING}
                    ></RemediationBox>
                ),
                maxWidth: 800,
            },
            {
                title: t('CARD_PROPS.GSL'),
                info: <Command text={finding.ruleLogic || NA_STRING} />,
                maxWidth: 800,
            },
        ];
    }, [NA_STRING, finding.description, finding.ruleId, finding.ruleLogic, finding.remediation, finding.bundleName, t]);

    const getLevelIcon = useCallback((): LevelIconProps => {
        const severityInfo: IFindingSeverityInfo = getSafeFindingSeverityInfo(finding.severity as string);
        return {
            category: 'severity',
            level: severityInfo.key,
            iconProps: {
                name: 'rule',
            },
        };
    }, [finding.severity]);

    const getRightTitleElements = useCallback((): JSX.Element => {
        const severityInfo: IFindingSeverityInfo = getSafeFindingSeverityInfo(finding.severity as string);
        return <SeverityChip severity={severityInfo.key} />;
    }, [finding.severity]);

    return (
        <EntityCard
            cardTitle={t('RULE_CARD.TITLE')}
            title={finding.ruleName}
            blocks={getBlocks()}
            levelIcon={getLevelIcon()}
            titleRightElements={getRightTitleElements()}
        />
    );
};
