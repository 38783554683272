import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

export const SeverityDiv = styled(Stack)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
`;

export const BusinessStyled = {
    SeverityDiv,
};
