import { IPermissionObject, IUser } from 'common/interface/user';
import { getStoreService } from 'common/interface/services';
import { getIsCloudInfra } from 'common/utils/RuntimeEnvironment';
import { setPermissions } from './User.reducer';
import { ActiveFeaturesTypes } from 'common/enum/ActiveFeatures';

export const dispatchUserPermissions = async () => {
    const staticPermissions = constructStaticPermissions();
    const userPermissions = extractUserPermissions();

    await dispatchPermissions({ ...staticPermissions, ...userPermissions });
};

const irrelevantPermissionKeys = new Set<string>(['accountId', 'userId']);

const constructStaticPermissions = () => {
    const staticPermissions: IPermissionObject = {};
    staticPermissions['all'] = true;
    return staticPermissions;
};

const extractUserPermissions = () => {
    const { state } = getStoreService().getReduxTools();
    const activeFeaturesPermissions: IPermissionObject = extractActiveFeaturesPermissions();
    const navBarPermissions: IPermissionObject = extractNavBarPermissions();
    const usersManagedPermissions: IPermissionObject = constructUsersManagementPermissions();
    const mspPermissions: IPermissionObject = {
        [ActiveFeaturesTypes.ALLOW_TRUST_FEATURE]: state.user.permissions.isRelatedToMsp,
    };
    return { ...activeFeaturesPermissions, ...navBarPermissions, ...usersManagedPermissions, ...mspPermissions };
};

export const dispatchPermissions = async (permissions: IPermissionObject) => {
    const { dispatch } = getStoreService().getReduxTools();
    dispatch(setPermissions(permissions));
};

const extractActiveFeaturesPermissions = () => {
    const { state } = getStoreService().getReduxTools();
    const permsArray: string[] = state.user.account?.activeFeatures || [];

    return permsArray.reduce((obj: any, perm: string) => {
        obj[perm] = true;
        return obj;
    }, {});
};

const extractNavBarPermissions = () => {
    const { state } = getStoreService().getReduxTools();
    const navBarPermissionsRequest = state.user.permissionsRequest;
    const perms: IPermissionObject = {};

    for (const permissionsKey in navBarPermissionsRequest) {
        if (isPermissionKeySupported(navBarPermissionsRequest, permissionsKey)) {
            perms[permissionsKey] = navBarPermissionsRequest[permissionsKey];
        }
    }

    return perms;
};

const isPermissionKeySupported = (navBarPermissionsRequest: any, permissionsKey: string) => {
    return (
        Object.prototype.hasOwnProperty.call(navBarPermissionsRequest, permissionsKey) &&
        !irrelevantPermissionKeys.has(permissionsKey)
    );
};

const constructUsersManagementPermissions = () => {
    const { state } = getStoreService().getReduxTools();
    return {
        isUsersManagedInCloudGuard: !getIsCloudInfra() || state.user.account?.usersManagedBy === 'CloudGuard',
        isUserProvisioned: state.user.user !== undefined && (state.user.user as IUser).id !== -1,
    };
};
