import { IconComponentProps } from 'common/design-system/components-v2/Icon/Icon.types';
import { IBaseAssetModifier } from './IBaseAssetModifier';
import { ImportanceLevelMap } from 'common/consts/ImportanceLevel';
import { IamSensitivityLevelEnum } from '../../../consts';
import { ermTrans } from '../../../RiskManagement.utils';
import { isNil } from 'common/utils/helpFunctions';

export interface IIamSensitivityInfo extends IBaseAssetModifier {
    low: number;
    high: number;
    title: string;
    iconProps: IconComponentProps;
    bg: string;
}

export const getIamSensitivityInfosMap = (): { [key in IamSensitivityLevelEnum]: IIamSensitivityInfo } => ({
    [IamSensitivityLevelEnum.unknown]: {
        id: IamSensitivityLevelEnum.unknown,
        low: -1,
        high: -1,
        title: ermTrans('PROTECTED_ASSETS.MODIFIERS.IAM_SENSITIVITY.UNKNOWN'),
        iconProps: { name: 'iamImpact', customColor: ImportanceLevelMap.unknown.fg },
        bg: ImportanceLevelMap.unknown.bg,
        level: -1,
    },
    [IamSensitivityLevelEnum.low]: {
        id: IamSensitivityLevelEnum.low,
        low: 0,
        high: 60,
        title: ermTrans('PROTECTED_ASSETS.MODIFIERS.IAM_SENSITIVITY.LOW'),
        iconProps: { name: 'iamImpact', customColor: ImportanceLevelMap.low.fg },
        bg: ImportanceLevelMap.low.bg,
        level: 0,
    },
    [IamSensitivityLevelEnum.medium]: {
        id: IamSensitivityLevelEnum.medium,
        low: 60,
        high: 75,
        title: ermTrans('PROTECTED_ASSETS.MODIFIERS.IAM_SENSITIVITY.MEDIUM'),
        iconProps: { name: 'iamImpact', customColor: ImportanceLevelMap.medium.fg },
        bg: ImportanceLevelMap.medium.bg,
        level: 1,
    },
    [IamSensitivityLevelEnum.high]: {
        id: IamSensitivityLevelEnum.high,
        low: 75,
        high: 90,
        title: ermTrans('PROTECTED_ASSETS.MODIFIERS.IAM_SENSITIVITY.HIGH'),
        iconProps: { name: 'iamImpact', customColor: ImportanceLevelMap.high.fg },
        bg: ImportanceLevelMap.high.bg,
        level: 2,
    },
    [IamSensitivityLevelEnum.veryHigh]: {
        id: IamSensitivityLevelEnum.veryHigh,
        low: 90,
        high: Infinity,
        title: ermTrans('PROTECTED_ASSETS.MODIFIERS.IAM_SENSITIVITY.VERY_HIGH'),
        iconProps: { name: 'iamImpact', customColor: ImportanceLevelMap.critical.fg },
        bg: ImportanceLevelMap.critical.bg,
        level: 3,
    },
});

export const getAllIamSensitivityLevelInfos = (): IIamSensitivityInfo[] =>
    Object.values(getIamSensitivityInfosMap()).sort((a, b) => a.level - b.level);

export const getIamSensitivityLevelInfos = (): IIamSensitivityInfo[] =>
    getAllIamSensitivityLevelInfos().filter((info) => info.level >= 0);

const getUnknownIamSensitivityInfo = (): IIamSensitivityInfo =>
    getIamSensitivityInfosMap()[IamSensitivityLevelEnum.unknown];

export const getIamSensitivityLevelInfo = (iamSensitivity?: number): IIamSensitivityInfo => {
    const levelInfo: IIamSensitivityInfo | undefined = isNil(iamSensitivity)
        ? undefined
        : getIamSensitivityLevelInfos().find((item) => iamSensitivity >= item.low && iamSensitivity < item.high);
    return levelInfo || getUnknownIamSensitivityInfo();
};
