import { getHttpService, IUserPageService } from 'common/interface/services';
import { IUser } from 'common/interface/user';

export class UserPageService implements IUserPageService {
    async resetPasswordRequestByEmail(payload: { email: string }) {
        return await getHttpService().request<any>('auth/reset-password-request-by-email', {
            method: 'POST',
            data: payload,
        });
    }

    async inviteUserIamSafety(payload: { id: number }) {
        return await getHttpService().request<any>(`user/${payload.id}/iam-safe/accounts`, {
            method: 'POST',
            data: payload,
        });
    }

    async connectUserToSso(user: IUser) {
        return await getHttpService().request<any>(`user/${user.id}`, { method: 'PUT', data: user });
    }

    async disableUserMfa(payload: { userId: number }) {
        return await getHttpService().request<any>(`settings/mfa?userId=${payload.userId}`, {
            method: 'DELETE',
            data: payload,
        });
    }

    async setAsAccountOwner(payload: { userId: number }) {
        return await getHttpService().request<any>(`account/owner`, { method: 'PUT', data: payload });
    }

    async revokeApiKey(payload: { userId: number }) {
        return await getHttpService().request<any>(`user/${payload.userId}/api-key`, { method: 'DELETE' });
    }
}
