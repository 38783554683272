import { IApplicationReducer } from 'common/interface/redux';
import alibabaReducer from './reducer';
import { initialAlibabaEnvironmentData } from './AlibabaEnvironment.consts';
import { getAppRootRegistry } from 'common/interface/services';

const initializeAlibabaEnvRedux = () => {
    const reducers: IApplicationReducer[] = [
        {
            name: 'env_alibaba',
            reducer: alibabaReducer,
            isBlackList: true,
        },
    ];
    getAppRootRegistry().addReducers(reducers, 'name');
};

export const initializeAlibabaEnv = () => {
    initializeAlibabaEnvRedux();
    initialAlibabaEnvironmentData();
};
