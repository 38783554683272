import { ISearcher } from 'platform/MainNavigationBar/MainNavigationBar.interface';
import { GLOBAL_SEARCH_SEARCHERS } from 'common/extensibility/WellKnownPaths';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { globalSearcher } from './globalSearcher';

export default function initializeBESearch() {
    globalAddinContainer.add<ISearcher>(GLOBAL_SEARCH_SEARCHERS, [
        {
            id: globalSearcher.id,
            position: 20,
            content: globalSearcher,
        },
    ]);
}
