import React from 'react';
import { EnableAWPProps } from './EnableAWP.consts';
import EmptyState from 'common/components/EmptyState';
import { getCheckpointUrl } from 'common/components/Forms/urls';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { Vendors } from 'common/consts/vendors';

const EnableAWP: React.FunctionComponent<EnableAWPProps> = ({ cloudAccountId, platform }) => {
    const { t } = useTranslation(getK8sNamespace('awp'));
    return (
        <EmptyState
            iconName={platform === Vendors.AWS ? 'aws' : 'azure'}
            iconSize={48}
            label={t('awpTab.enableAwp.title')}
            description={t('awpTab.enableAwp.description', { context: platform })}
            links={[
                {
                    label: t('awpTab.enableAwp.enableLinkLabel'),
                    url: `/cloud-add/agentless/${platform}/${cloudAccountId}`,
                    buttonProps: { color: 'brandPrimary', tooltip: t('awpTab.enableAwp.enableLinkTooltip') },
                },
                {
                    label: t('awpTab.enableAwp.moreInfoAboutAWP'),
                    url: getCheckpointUrl('AGENTLESS_WORKLOAD_POSTURE'),
                    outsideLink: true,
                    newTab: true,
                    buttonProps: { tooltip: t('awpTab.enableAwp.moreInfoAboutAWPTooltip') },
                },
            ]}
        />
    );
};

export default EnableAWP;
