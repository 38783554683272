import { IServerSideGetRowsParams } from 'ag-grid-enterprise';
import {
    findingModelActionStringToCode,
    findingModelAlertTypeStringToCode,
    findingModelCloudAccountTypeStringToCode,
    findingModelOriginStringToCode,
    FindingsDataSource,
    IFindingModelCloudAccountType,
    IFindingModelOriginString,
    ISearchFilterViewModel,
} from 'common/components/Findings/Findings.interface';
import {
    CommonEventFields,
    FINDINGS_AGGREGATIONS_URL,
    FINDINGS_GROUPING_URL,
} from 'common/module_interface/events/EventsConsts';
import { IEventsDatasource, IEventsElasticDatasourceParams } from 'common/interface/events';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import FindingsReportApi from 'common/services/apis/FindingsReport/findingsReport.service';
import { BaseFindingReportExportToCsvRequest } from 'common/services/apis/FindingsReport/findingsReport.interface';
import {
    ComplianceFindingGroupsByProperties,
    ComplianceFindingSearchWithCustomAggregations,
} from 'common/module_interface/events/complianceFindingApi.interface';
import { getComplianceFindingApiService } from 'common/module_interface/events/Events';

export class EventsElasticDatasource implements IEventsDatasource {
    public isFeching;
    public isLoading;
    public isError;
    public rowCount;
    public totalRowCount;
    public initialParams: IEventsElasticDatasourceParams;
    public filters: ISearchFilterViewModel | undefined;

    private searchAfter: string[];
    private subscribeList: { [key: string]: (state: IEventsDatasource) => void } = {};

    private allAggregationsPromise: Promise<Aggregations>;

    constructor(private params: IEventsElasticDatasourceParams) {
        this.isFeching = false;
        this.isLoading = true;
        this.isError = false;
        this.rowCount = 0;
        this.totalRowCount = 0;
        this.initialParams = this.params;
        this.searchAfter = [];
        this.allAggregationsPromise = this.getAggregations();
    }

    private mergeFiltersWithDefaults(
        filters?: ISearchFilterViewModel,
        additionalFilters?: ISearchFilterViewModel,
    ): ISearchFilterViewModel {
        const filtersToReturn = {
            ...this.initialParams.defaultFilters,
            ...filters,
            ...additionalFilters,
            fields: [
                ...(this.initialParams.defaultFilters?.fields || []),
                ...(filters?.fields || []),
                ...(additionalFilters?.fields || []),
            ],
            filterFields: [
                ...(this.initialParams.defaultFilters?.filterFields || []),
                ...(filters?.filterFields || []),
                ...(additionalFilters?.filterFields || []),
            ],
        };
        if (filters?.fields) {
            filters.fields.forEach(({ name }) => {
                filtersToReturn.fields = filtersToReturn.fields?.filter((field) => field.name !== name) || []; // remove values with same group name
            });
            filtersToReturn.fields.push(...filters.fields);
        }
        if (additionalFilters?.fields) {
            additionalFilters.fields.forEach(({ name }) => {
                filtersToReturn.fields = filtersToReturn.fields?.filter((field) => field.name !== name) || []; // remove values with same group name
            });
            filtersToReturn.fields.push(...additionalFilters.fields);
        }

        return filtersToReturn;
    }

    public async downloadCSV(selectedColumns?: string[]): Promise<boolean> {
        const payload: BaseFindingReportExportToCsvRequest = {
            searchRequest: {
                dataSource: this.initialParams.findingsDataSource,
                filter: this.mergeFiltersWithDefaults(this.filters),
            },
            selectedColumns,
        };

        this.fixRemediationFilter(payload.searchRequest.filter);

        try {
            const response = await FindingsReportApi.downloadCSV(payload);
            const anchor = document.createElement('a');
            const blob = new Blob([response.data], { type: 'application/octet-stream' });
            anchor.href = URL.createObjectURL(blob);
            anchor.download = 'CSV_findings_report.zip';
            anchor.click();
            return true;
        } catch (err) {
            return false;
        }
    }

    public async emailCSV(recipents: string[], selectedColumns?: string[]): Promise<boolean> {
        const payload: BaseFindingReportExportToCsvRequest = {
            searchRequest: {
                dataSource: this.initialParams.findingsDataSource,
                filter: this.mergeFiltersWithDefaults(this.filters),
            },
            recipents: recipents,
            selectedColumns,
        };

        this.fixRemediationFilter(payload.searchRequest.filter);

        try {
            FindingsReportApi.exportToCsv(payload);
            return true;
        } catch (err) {
            return false;
        }
    }

    public updateFilters(filters?: ISearchFilterViewModel) {
        this.filters = filters;
        this.rowCount = 0;
        this.isLoading = false;
        this.notifySubscribers();
    }

    public async getAggregations(filters?: ISearchFilterViewModel): Promise<Aggregations> {
        if (!filters && this.allAggregationsPromise) {
            return this.allAggregationsPromise;
        }

        const defaultAggregations = [
            'aggregationEntityTypeByEnvironmentType',
            CommonEventFields.cloudAccountId,
            CommonEventFields.severity,
            CommonEventFields.alertType,
            CommonEventFields.acknowledged,
            CommonEventFields.cloudAccountType,
            CommonEventFields.entityType,
            CommonEventFields.isExcluded,
            CommonEventFields.origin,
            CommonEventFields.labels,
            CommonEventFields.action,
            CommonEventFields.ownerUserName,
            CommonEventFields.ruleName,
            CommonEventFields.bundleName,
            CommonEventFields.entityNetwork,
            CommonEventFields.category,
            CommonEventFields.region,
        ];

        const payload: ComplianceFindingSearchWithCustomAggregations.Request = {
            dataSource: this.initialParams.findingsDataSource,
            skipAggregations: false,
            aggregations: defaultAggregations,
            filter: this.mergeFiltersWithDefaults(filters),
            lowAggregationsSize: false,
            pageSize: 0,
        };

        try {
            const promiseResults = await getComplianceFindingApiService().searchWithCustomAggregations(payload, {
                useCache: true,
            });
            if (!filters) {
                this.totalRowCount = promiseResults.data.totalFindingsCount;
            }
            return promiseResults.data.aggregations;
        } catch (err) {
            return {};
        }
    }

    public getRows(params: IServerSideGetRowsParams): void {
        if (params.request.rowGroupCols.length > 0) {
            this.handleGrouping(params);
        } else {
            this.handleSearch(params, undefined, params.request.rowGroupCols.length > 0);
        }
    }

    fixRemediationFilter(filters?: ISearchFilterViewModel): void {
        const hasRemediation = filters?.fields?.findIndex((field) => field.name === 'showFixable') || -1;
        if (hasRemediation > -1 && filters?.fields) {
            filters.fields.splice(hasRemediation, 1);
            filters.hasRemediation = true;
        }
    }

    private handleGrouping(params: IServerSideGetRowsParams) {
        const rowGroupCols = params.request.rowGroupCols;
        const groupKeys = params.request.groupKeys;

        const fixFilterValueByKey = (key: string, value: any) => {
            switch (key) {
                case CommonEventFields.origin:
                    return findingModelOriginStringToCode(value);
                case CommonEventFields.action:
                    return findingModelActionStringToCode(value);
                case CommonEventFields.alertType:
                    return findingModelAlertTypeStringToCode(value);
                default:
                    return value;
            }
        };

        const fields = params.request.groupKeys.map((groupKey, index) => ({
            name: params.request.rowGroupCols[index].id,
            value: fixFilterValueByKey(params.request.rowGroupCols[index].id, groupKey),
        }));

        if (rowGroupCols.length === groupKeys.length) {
            return this.handleSearch(
                params,
                {
                    fields,
                },
                true,
            );
        }

        if (params.parentNode?.data?.nestedBuckets !== undefined) {
            params.success({
                rowData: params.parentNode.data.nestedBuckets.map((item: any) => ({
                    isGrouped: true,
                    [item.fieldName]: item.fieldValue,
                    id: item.fieldValue,
                    childCount: item.numberOfDocuments,
                    nestedBuckets: item.nestedBuckets,
                })),
            });
            return;
        }

        let direction = -1;
        if (params.request.sortModel.length > 0) {
            direction = params.request.sortModel[0].sort === 'asc' ? 1 : -1;
        }

        fields.push({
            name: CommonEventFields.status,
            value: this.initialParams.findingsDataSource === FindingsDataSource.ARCHIVE ? '1' : '0',
        });

        const payload: ComplianceFindingGroupsByProperties.Request = {
            filter: this.mergeFiltersWithDefaults(this.filters, { fields }),
            propertiesList: rowGroupCols.map((group) => ({
                property: group.id,
                sortOption: 'BucketsCount',
                direction,
                maxSize: 10000,
            })),
        };

        this.fixRemediationFilter(payload.filter);

        this.isFeching = true;
        getComplianceFindingApiService()
            .groupsByProperties(payload)
            .then(({ data }) => {
                const rowData: any[] = [];
                let rowCount = 0;
                data.forEach((item) => {
                    rowData.push({
                        isGrouped: true,
                        [item.fieldName]: item.fieldValue,
                        id: item.fieldValue,
                        childCount: item.numberOfDocuments,
                        nestedBuckets: item.nestedBuckets,
                    });
                    rowCount += item.numberOfDocuments;
                });

                this.rowCount = rowCount;
                params.success({
                    rowData,
                    rowCount: data.length,
                });
                this.isFeching = false;
                this.notifySubscribers();
            })
            .catch((err) => {
                params.context = {
                    error: err,
                };
                params.fail();
                console.error(`Could not fetch data from server getting ${FINDINGS_GROUPING_URL} - ${err}`);
                this.isError = true;
                this.rowCount = 0;
            });
    }

    private handleSearch(
        params: IServerSideGetRowsParams,
        additionalFilters?: ISearchFilterViewModel,
        ignoreRowCountChange?: boolean,
    ) {
        if (params.request.startRow === 0) {
            this.searchAfter = [];
            if (!ignoreRowCountChange) {
                this.rowCount = 0;
            }
        }

        this.isFeching = true;
        this.notifySubscribers();

        const mergedFilters = this.mergeFiltersWithDefaults(this.filters, additionalFilters);

        this.fixRemediationFilter(mergedFilters);

        const payload: ComplianceFindingSearchWithCustomAggregations.Request = {
            dataSource: this.initialParams.findingsDataSource,
            aggregations: [],
            filter: mergedFilters,
            pageSize: 50,
            searchAfter: this.searchAfter,
            skipAggregations: true,
        };

        if (params.request.sortModel.length === 1) {
            payload.sorting = {
                fieldName: params.request.sortModel[0].colId,
                direction: params.request.sortModel[0].sort === 'asc' ? 1 : -1,
            };
        } else if (params.request.sortModel.length > 1) {
            payload.multiSorting = params.request.sortModel.map((sortModel) => ({
                fieldName: sortModel.colId,
                direction: sortModel.sort === 'asc' ? 1 : -1,
            }));
        }

        const fixFilterValueByKey = (key: string, value: any) => {
            switch (key) {
                case CommonEventFields.cloudAccountType: {
                    const isStringKey =
                        IFindingModelCloudAccountType[value as IFindingModelCloudAccountType] !== undefined;
                    if (isStringKey) return findingModelCloudAccountTypeStringToCode(value);
                    return value;
                }
                case CommonEventFields.origin: {
                    const isStringKey = IFindingModelOriginString[value as IFindingModelOriginString] !== undefined;
                    if (isStringKey) return findingModelOriginStringToCode(value);
                    return value;
                }
                default:
                    return value;
            }
        };

        payload.filter?.fields?.forEach((field) => {
            field.value = fixFilterValueByKey(field.name, field.value);
        });

        getComplianceFindingApiService()
            .searchWithCustomAggregations(payload)
            .then(async ({ data }) => {
                if (!ignoreRowCountChange) {
                    this.rowCount = data.totalFindingsCount;
                }
                this.searchAfter = data.searchAfter || [];
                params.success({
                    rowData: data.findings,
                });
            })
            .catch((err) => {
                params.context = {
                    error: err,
                };
                params.fail();
                console.error(`Could not fetch data from server getting ${FINDINGS_AGGREGATIONS_URL} - ${err}`);
                this.isError = true;
                this.rowCount = 0;
            })
            .finally(() => {
                this.isFeching = false;
                this.notifySubscribers();
            });
    }

    private notifySubscribers() {
        Object.values(this.subscribeList).forEach((callback) => callback(this));
    }

    public subscribeToStateChange(callback: (state: IEventsDatasource) => void): string {
        const subscribeKey = Math.floor(Math.random() * (10000000 - 1000000 + 1) + 1000000).toString();
        this.subscribeList[subscribeKey] = callback;
        callback(this);
        return subscribeKey;
    }

    public unsubscribeFromStateChange(subscribeKey: string): void {
        delete this.subscribeList[subscribeKey];
    }
}
