import {
    renderAddFilter,
    renderClearAll,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter,
    renderRecentlyUsedFilters,
    renderSavedFilters,
    renderTreeFilter,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { Addin } from 'common/extensibility/AddinRegistry';
import i18n from 'i18next';
import { IDisplayMappingObject } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { getVendor, getVendorDisplayName } from 'common/consts/vendors';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { EnvironmentsTableRegistry } from 'common/module_interface/assets/EnvironmentsTableRegistry';
import { IEnvironmentsFilterParams } from 'common/module_interface/assets/Environments';
import { getEnvsNamespace } from '../initialize.i18n';

const filterDefsAddins: () => Addin<IFilterProps>[] = () => [
    {
        id: 'add filter',
        content: {
            filterProps: { key: 'add-filter' },
            renderFunction: renderAddFilter,
        },
    },
    {
        id: 'free text',
        content: {
            filterProps: {
                key: FILTERS_KEYS.FREE_TEXT, //This needs to be the same as in stored filter/query params
                title: i18n.t('TABLE_FILTERS.FREE_TEXT.TITLE', { ns: getEnvsNamespace('table') }),
            },
            keyInObjectForAPI: 'freeTextPhrase',
            renderFunction: renderDefaultFreeTextFilter,
        },
    },
    {
        id: 'organizational unit',
        content: function ({ filtersInitialData }: IEnvironmentsFilterParams) {
            return {
                filterProps: {
                    isMultiSelect: true,
                    initialData: filtersInitialData?.organizationalUnits,
                    key: 'organizationalUnitId',
                    title: i18n.t('TABLE_FILTERS.ORGANIZATIONAL_UNITS.TITLE', { ns: getEnvsNamespace('table') }),
                },
                renderFunction: renderTreeFilter,
            };
        },
    },
    {
        id: 'platform',
        content: function ({ aggregations }: IEnvironmentsFilterParams) {
            const displayMapping: IDisplayMappingObject = {};
            aggregations['platform']?.forEach((platform: { value: string }) => {
                displayMapping[platform.value] = {
                    displayText: getVendorDisplayName(platform.value),
                    icon: getVendor(platform.value)?.icon,
                };
            });
            return {
                filterProps: {
                    initialData: aggregations['platform'],
                    key: 'platform',
                    title: i18n.t('TABLE_FILTERS.PLATFORM.TITLE', { ns: getEnvsNamespace('table') }),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'customData|status',
        content: function ({ aggregations }: IEnvironmentsFilterParams) {
            const displayMapping: IDisplayMappingObject = {
                success: {
                    displayText: i18n.t('TABLE_FILTERS.STATUS.VALUES.NORMAL', { ns: getEnvsNamespace('table') }),
                    newIcon: 'checkCircle',
                },
                warning: {
                    displayText: i18n.t('TABLE_FILTERS.STATUS.VALUES.WARNING', { ns: getEnvsNamespace('table') }),
                    newIcon: 'warning',
                },
                error: {
                    displayText: i18n.t('TABLE_FILTERS.STATUS.VALUES.ERROR', { ns: getEnvsNamespace('table') }),
                    newIcon: 'error',
                },
                unsecured: {
                    displayText: i18n.t('TABLE_FILTERS.STATUS.VALUES.UNSECURED', { ns: getEnvsNamespace('table') }),
                    newIcon: 'unsecured',
                },
            };

            const counters =
                aggregations?.['customData|status']?.reduce<{ [key: string]: number }>((acc, curr) => {
                    acc[curr.value] = curr.count;
                    return acc;
                }, {}) || {};

            const initialData = [
                { count: counters['success'] || 0, value: 'success' },
                { count: counters['warning'] || 0, value: 'warning' },
                { count: counters['error'] || 0, value: 'error' },
                { count: counters['unsecured'] || 0, value: 'unsecured' },
            ];

            return {
                filterProps: {
                    initialData,
                    key: 'customData|status',
                    title: i18n.t('TABLE_FILTERS.STATUS.TITLE', { ns: getEnvsNamespace('table') }),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'saved filters',
        content: function ({ filtersInitialData, savedFiltersComponentName }: IEnvironmentsFilterParams) {
            return {
                filterProps: {
                    savedFilters: filtersInitialData?.savedFilters || [],
                    savedFiltersComponentName,
                    selectedFilterID: '',
                    key: FILTERS_KEYS.SAVED_FILTERS,
                },
                renderFunction: renderSavedFilters,
            };
        },
    },
    {
        id: 'recently used',
        content: function ({ filtersInitialData, recentlyUsedComponentName }: IEnvironmentsFilterParams) {
            return {
                filterProps: {
                    key: FILTERS_KEYS.RECENTLY_USED_FILTERS,
                    recentlyUsedList: filtersInitialData?.recentlyUsed,
                    maxLength: 4,
                    componentName: recentlyUsedComponentName,
                    title: i18n.t('TABLE_FILTERS.RECENTLY_USED_FILTERS.TITLE', { ns: getEnvsNamespace('table') }),
                },
                renderFunction: renderRecentlyUsedFilters,
            };
        },
    },
    {
        id: 'clear all',
        content: {
            filterProps: {
                key: FILTERS_KEYS.CLEAR_BUTTON,
            },
            renderFunction: renderClearAll,
        },
    },
];

export default function initializeFilters() {
    EnvironmentsTableRegistry.addFilterAddins(filterDefsAddins());
}
