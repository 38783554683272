import { EditorStyled } from '../AutoActionEditor.styled';
import { Icon, Label, Stack, Typography } from 'common/design-system/components-v2';
import React, { ReactElement } from 'react';

export const EditorInfoCard: React.FC<{ title: string; text: string; children?: ReactElement }> = ({
    title,
    text,
    children,
}) => {
    return (
        <EditorStyled.EditorCardDiv background={'brandLight'}>
            <Stack direction={'row'} spacing={1}>
                <Icon name={'info'} color={'brandPrimary'} />
                <Typography variant={'body500'}>{title}</Typography>
            </Stack>
            <EditorStyled.EditorCardContentDiv>
                <Label text={text} />
                {children}
            </EditorStyled.EditorCardContentDiv>
        </EditorStyled.EditorCardDiv>
    );
};
