import { getHttpService, ISecurityGroup } from 'common/interface/services';
import { ISecurityGroupsService } from 'common/interface/securityGroupsService';

export class SecurityGroupsService implements ISecurityGroupsService {
    async getAllSecurityGroups(useCache = true): Promise<ISecurityGroup[]> {
        return await getHttpService().get<ISecurityGroup[]>({
            path: '/securitygroup',
            cachingConfig: {
                useCache,
            },
        });
    }
    async getAllCloudSecurityGroups(useCache = true): Promise<ISecurityGroup[]> {
        return await getHttpService().get<ISecurityGroup[]>({
            path: '/cloudsecuritygroup',
            cachingConfig: {
                useCache,
            },
        });
    }

    async getCloudSecurityGroups(cloudAccount: string, region: string, useCache = true): Promise<ISecurityGroup[]> {
        return await getHttpService().get<ISecurityGroup[]>({
            path: `/cloudsecuritygroup?cloudAccountId=${cloudAccount}&regionId=${region}`,
            cachingConfig: {
                useCache,
            },
        });
    }
}
