import React from 'react';
import { EventsModalOpenFrom, EventsModalProps } from 'common/module_interface/events/EventsModalsRegistry';
import { useTranslation } from 'react-i18next';
import { Message } from 'common/design-system/components-v2';
import { getEventsNamespace } from 'modules/events/initialize.i18n';
import { ComplianceFindingClose } from 'common/module_interface/events/complianceFindingApi.interface';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { useDeleteEvent } from 'modules/events/services/complianceFindingApi/hooks/useDeleteEvent';
import { closeDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';

const CloseAction: React.FC<EventsModalProps> = ({
    closeModal,
    selectedRows,
    resetSelectedRows,
    requestNewData,
    openFrom,
}) => {
    const { t } = useTranslation(getEventsNamespace('actions'));

    const onSuccess = (response: ComplianceFindingClose.Response) => {
        if (response.hasErrors) {
            const failedCount = Object.keys(response.failedItems).length;
            getNotificationsService().addNotification({
                text: t('CLOSE.NOTIFICATIONS.PARTIAL_ERROR', { failedCount, eventsCount: selectedRows.length }),
                type: NotificationType.WARNING,
            });
        } else {
            getNotificationsService().addNotification({
                text: t('CLOSE.NOTIFICATIONS.SUCCESS', { count: selectedRows.length }),
                type: NotificationType.SUCCESS,
            });
        }
        if (openFrom === EventsModalOpenFrom.TABLE && resetSelectedRows) {
            resetSelectedRows();
        }
        closeModal();
        if (openFrom === EventsModalOpenFrom.DRAWER) {
            closeDrawer();
            return;
        }
        requestNewData?.();
    };

    const onError = () => {
        getNotificationsService().addNotification({
            text: t('CLOSE.NOTIFICATIONS.ERROR', { count: selectedRows.length }),
            type: NotificationType.ERROR,
        });
    };

    const { deleteEvents, isLoading } = useDeleteEvent({ onSuccess, onError });

    return (
        <Message
            title={t('CLOSE.TITLE')}
            text={t('CLOSE.TEXT', { count: selectedRows.length })}
            submitBtnText={t('CLOSE.BUTTONS.SUBMIT', { count: selectedRows.length })}
            width='lg'
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={() => deleteEvents(selectedRows)}
            isLoading={isLoading}
            dataAid='events-close-action'
        />
    );
};

export default CloseAction;
