import { GridOptions } from 'ag-grid-community';
import React, { useCallback, useRef } from 'react';
import { GridApi } from 'ag-grid-enterprise';
import withShowMoreButton from 'common/erm-components/utils/WithShowMore/WithShowMore';
import { Stack, Table } from 'common/design-system/components-v2';
import { mergeColumnDefs } from 'common/utils/tableUtils';
import { DataSensitivityInfo } from './DataSensitivity.interface';
import { ProtectedAssetsTableRegistry } from 'common/module_interface/assets/ProtectedAssetsTableRegistry';
import { PROTECTED_ASSET_COL_IDS } from 'common/module_interface/assets/ProtectedAssets.consts';
import { IColumnUsageDef } from 'common/interface/general';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';

const DataSensitivityTable: React.FC<{ assets: DataSensitivityInfo[] }> = ({ assets }) => {
    const flexValuesFoColumns = [3, 7];

    const dataClassificationTableColumns: IColumnUsageDef[] = [
        {
            id: PROTECTED_ASSET_COL_IDS.entity,
            colDefOverride: {
                cellRendererParams: {
                    generateUrl: getErmUrlsService().generateAssetUrl,
                },
                enableRowGroup: false,
            },
        },
        { id: 'ermDataClassification' },
    ];

    const assetsColumns = mergeColumnDefs(
        dataClassificationTableColumns,
        ProtectedAssetsTableRegistry.getColumnDefs().map((colDef, index) => ({
            ...colDef,
            flex: flexValuesFoColumns[index % flexValuesFoColumns.length],
        })),
    );

    const apiRef = useRef<GridApi>();

    const onGridReady = useCallback((params: any) => {
        apiRef.current = params.api;
    }, []);

    const gridOptions: GridOptions = {
        columnDefs: assetsColumns,
        defaultColDef: {
            sortable: false,
            resizable: true,
            suppressMenu: true,
            suppressMovable: true,
        },
        rowModelType: 'clientSide',
        domLayout: 'autoHeight',
        suppressDragLeaveHidesColumns: true,
        rowData: assets,
        enableRangeSelection: false,
        suppressCellFocus: true,
        onGridReady,
        masterDetail: true,
        detailRowAutoHeight: true,
    };

    const TableWithButton = withShowMoreButton(Table);

    return (
        <Stack fullWidth>
            <TableWithButton gridOptions={gridOptions} disableColumnMenu={true} disableGrouping={true} />
        </Stack>
    );
};

export default DataSensitivityTable;
