import i18n, { validLanguages } from 'common/services/translations/translations';

export const AwsEnvNamespacePrefix = 'aws-env';
export type AwsEnvTermTypes = 'aws';
const termsFolders: Array<AwsEnvTermTypes> = ['aws'];

export const getAwsEnvNamespace = (term: AwsEnvTermTypes) => `${AwsEnvNamespacePrefix}_${term}`;

const jsonFilesPaths = validLanguages.reduce<Array<{ path: string; language: string; term: AwsEnvTermTypes }>>(
    (acc, language) => {
        const filesPAth = termsFolders.map((term) => ({ path: `${language}/${term}`, language, term }));
        return [...acc, ...filesPAth];
    },
    [],
);

export const initializeAwsEnvI18n = async () => {
    await Promise.all(
        jsonFilesPaths.map(async (file) => {
            try {
                const contents = await require(`./languages/${file.path}.json`);
                i18n.addResourceBundle(file.language, getAwsEnvNamespace(file.term), contents);
                // eslint-disable-next-line
            } catch (error) {}
        }),
    );
};
