import React from 'react';
import PropertyBoxesRow from 'common/components/PropertyBox/PropertyBoxesRow';
import TextPropertyBox from 'common/components/PropertyBox/TextPropertyBox';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
export const DescriptionPanel: React.FC<{ event: IKustoEvent }> = ({ event }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const description = event.description;

    return (
        description && (
            <PropertyBoxesRow>
                <TextPropertyBox title={t('INT_FINDING_OVERVIEW.DESCRIPTION')} value={description || ''} />
            </PropertyBoxesRow>
        )
    );
};
