import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const Info = styled(Stack)`
    padding-top: 18px;
    cursor: pointer;
`;

export const CVEStyled = {
    Info,
};
