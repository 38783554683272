import { validLanguages } from 'common/services/translations/translations';
import i18n from 'i18next';
import { buildPath, globalAddinContainer } from 'common/extensibility/AddinContainer';
import { getTableRegistry } from '../../SimpleTableFilterPage/SimpleGenericTableRegistry';
import initializeUsersColumnDefs from './UsersColumnDefenitions';
import initializeUsersFilters from './UsersFiltersDefenitions';
import { getAppRootRegistry, USER_PAGE_SERVICE_ID } from 'common/interface/services';
import { DrawerRegistry } from 'common/components/DrawerInfra/Drawer/DrawerRegistry';
import { IDrawerContent, IDrawerHandlersIdMap } from 'common/components/DrawerInfra/Drawer/Drawer.interface';
import { DrawerBus } from 'common/components/DrawerInfra/Drawer/DrawerBus';
import { AddEditUserDrawer } from './AddEditUserDrawer';
import { UserPageService } from '../Services/UserService';
import { createElement, lazy } from 'react';
import { Icon } from 'common/design-system/components-v2';

export const I18nTranslationKey = 'Users';
const PATH_USERS_PREFIX = buildPath('users', 'users_settings');
export const PATH_USERS_PAGE = buildPath(PATH_USERS_PREFIX, 'page');
export const PATH_USERS_PAGE_TABLE_COLUMNS = buildPath(PATH_USERS_PAGE, 'columns');
export const PATH_USERS_PAGE_TABLE_FILTERS = buildPath(PATH_USERS_PAGE, 'filters');

export const initializeI18nUsers = async () => {
    return Promise.all(
        validLanguages.map(async (language) => {
            try {
                const contents = await require(`./languages/${language}/translation.json`);
                i18n.addResourceBundle(language, `${I18nTranslationKey}`, contents);
            } catch {
                //Nothing to do with missing translation files
            }
        }),
    );
};

const initGenericPage = (initializeColumnDefs: Function, initializeFilters: Function) => {
    initializeColumnDefs();
    initializeFilters();

    const UsersPageRoute = {
        condition: () => true,
        component: lazy(() => import('./UsersPage')),
        path: '/users-management/users',
    };

    getAppRootRegistry().addRoutes([{ id: 'users', content: UsersPageRoute }]);
};

export const usersTableRegistry = getTableRegistry(PATH_USERS_PAGE_TABLE_COLUMNS, PATH_USERS_PAGE_TABLE_FILTERS);

export const USER_DRAWER = {
    key: 'UserDrawer',
    eventTypes: {
        userChanged: 'userChanged',
    },
};
export const USER_CHANGED_HANDLER_ID = 'USER_CHANGED_HANDLER';

const userDrawerIcon = (namePath: string) =>
    createElement(
        'div',
        {
            style: {
                backgroundColor: '#F1F4F9',
                padding: '12px',
                borderRadius: '12px',
                display: 'inline-flex',
            },
        },
        createElement(Icon, {
            size: 24,
            vendorNameOrPath: namePath,
        }),
    );
const initializeUserDrawer = () => {
    DrawerRegistry.addContentProvider({
        id: USER_DRAWER.key,
        getDrawerContent: (
            drawerContent: IDrawerContent,
            handlersIdMap?: IDrawerHandlersIdMap,
        ): Promise<IDrawerContent> => {
            const onChangeCallBack = () => {
                DrawerBus.sendEvent(handlersIdMap![USER_DRAWER.eventTypes.userChanged]);
            };
            const result = {
                title: drawerContent.title,
                component: AddEditUserDrawer,
                icon: drawerContent.icon && userDrawerIcon(drawerContent.icon as string),
                componentProps: { ...drawerContent.componentProps, onChangeCallBack: onChangeCallBack },
            };
            return Promise.resolve(result);
        },
        options: {
            hasHeaderLineSeparator: true,
            width: 'lg',
        },
    });
};

const initializeUserPageServices = () => {
    globalAddinContainer.addService(USER_PAGE_SERVICE_ID, new UserPageService());
};

export const initializeUsers = () => {
    initializeUserDrawer();
    initGenericPage(initializeUsersColumnDefs, initializeUsersFilters);
    initializeUserPageServices();
};
