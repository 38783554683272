import React, { useEffect, useState } from 'react';
import { IIssue } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import PerimeterExposureComponent from './PerimeterExposureComponent';
import { getAssetExposure } from '../Services/PerimeterExposureHttpService';

export const EvidencesPerimeterExposureGraph: React.FC<{
    issue: IIssue;
    asset: IProtectedAssetViewModel;
    title: string;
}> = ({ asset }) => {
    const [srl, setSrl] = useState<string | null>(null);
    useEffect(() => {
        setSrl(asset?.srl ?? null);
    }, [asset]);

    return srl ? (
        <div className='w-full h-[280px]'>
            <PerimeterExposureComponent assetSrl={srl} getGraphBySrl={getAssetExposure} showFullScreenButton={true} />
        </div>
    ) : null;
};

export default EvidencesPerimeterExposureGraph;
