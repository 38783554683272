import {
    integrationCategory,
    INTEGRATIONS_IDS,
    IntegrationsDefinitionWrapper,
    IntegrationDataLoader,
    HOW_TO_CONFIGURE_URLS,
} from 'common/module_interface/settings/integrations/consts';
import SENTRA from 'assets/integrations/Icons/Sentra.svg';
import SentraComponent from 'modules/riskManagement/components/Integrations/SentraIntegration/SentraComponent';
import { getSentraIntegrationService } from 'common/module_interface/RiskManagement/Services';
import { SENTRA_INTEGRATION_ID } from 'common/module_interface/RiskManagement/integrations/sentraIntegration/SentraIntegration.consts';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IntegrationsRegistry } from 'common/module_interface/settings/integrations/IntegrationsRegistry';
import { ermTrans } from '../../../RiskManagement.utils';
import { addOrUpdateSentraAccount } from './Sentra.utils';

export function initializeSentraIntegration() {
    const sentraIntegrationObject: IntegrationsDefinitionWrapper[] = [
        {
            id: SENTRA_INTEGRATION_ID,
            content: {
                id: INTEGRATIONS_IDS.SENTRA,
                title: ermTrans('SENTRA_INTEGRATION.TITLE'),
                icon: SENTRA,
                category: integrationCategory.DSPM,
                howToConfigureLink: HOW_TO_CONFIGURE_URLS.SENTRA,
                configurationComponent: SentraComponent,
                onDelete: (accountId: string) => getSentraIntegrationService().deleteSentraAccount(accountId),
                onSave: (name: string, configurationObj: any, configurationId?: string) =>
                    addOrUpdateSentraAccount(configurationObj.apiKey, name, configurationId),
            },
        },
    ];
    IntegrationsRegistry.addIntegrationsDefinition(sentraIntegrationObject);
    initializeSentraIntegrationDataFunction();
}
export function initializeSentraIntegrationDataFunction() {
    const sentraConfigurations: Addin<IntegrationDataLoader> = {
        id: SENTRA_INTEGRATION_ID,
        content: {
            getData: getSentraIntegrationService().getSentraConfigurations,
        },
    };
    IntegrationsRegistry.addConfigurationDataLoader(sentraConfigurations);
}
