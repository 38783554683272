export const CVE_IGNORE_LIST_SERVICE_ID = 'CVE_IGNORE_LIST_SERVICE_ID';
export const CVE_IGNORE_LIST_PAGE_ID = 'CVE_IGNORE_LIST_PAGE_ID';
export const CVE_IGNORE_RESOURCE_URL = 'security-graph/ignore-cves';
export const CVE_IGNORE_FORM_DRAWER_KEY = 'CVE_IGNORE_FORM_DRAWER_KEY';

export enum CVE_IGNORE_LIST_MODEL_FIELDS {
    CVE_IDS = 'targetExternalId',
    PACKAGE_NAME = 'packageName',
    PACKAGE_PATH = 'packagePath',
}

export enum CVE_IGNORE_LIST_ITEM_FIELDS {
    CVE_IDS = 'cveIds',
    PACKAGE_NAMES = 'packageNames',
    PACKAGE_PATHS = 'packagePaths',
}

export enum CveIgnoreNames {
    cveBox = 'cveBox',
}

export enum CveIgnoreInnerNames {
    cveIds = 'cveIds',
    packageNames = 'packageNames',
    packagePaths = 'packagePaths',
}

export const RequiredOneOfCveFields: string[] = [
    CveIgnoreInnerNames.cveIds,
    CveIgnoreInnerNames.packageNames,
    CveIgnoreInnerNames.packagePaths,
];
