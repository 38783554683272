import styled from 'styled-components';

const TipsWrapper = styled.span`
    margin-right: 5px;

    .severity-critical {
        path {
            stroke: ${({ theme }) => `${theme.palette.severity.criticalFg}`};
        }
    }
    .severity-high {
        path {
            stroke: ${({ theme }) => `${theme.palette.severity.highFg}`};
        }
    }
    .severity-medium {
        path {
            stroke: ${({ theme }) => `${theme.palette.severity.mediumFg}`};
        }
    }
    .severity-low {
        path {
            stroke: ${({ theme }) => `${theme.palette.severity.lowFg}`};
        }
    }
`;
export const InsightsStyled = {
    TipsWrapper,
};
