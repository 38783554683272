import { cdrTrans } from 'modules/Intelligence/Intelligence.utils';
import { ICdrExclusionProps } from './CdrExclusion.interface';
import { getCdrExclusionsService } from './CdrExclusionService';
import { IntelligenceRule, IntelligenceRuleset } from 'common/module_interface/intelligence/Intelligence.interface';
import { sendHttpRequest } from 'common/erm-components/utils/ermComponents.http';
import { getExclusionFromState } from './CdrExclusion.values';
import { IItemsMap } from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import { getIntelligenceService } from 'common/module_interface/intelligence/intelligence';

const NORMAL_IP_LIST_URL = 'ipList';
const MAGIC_IP_LIST_URL = '/ipList/allMagicIps';

export const fetchAllRulesets = async (): Promise<IntelligenceRuleset[]> => {
    return getIntelligenceService().getIntelligenceRulesets();
};

export const getRulesetName = async (rulesetId: string): Promise<string | undefined> => {
    const allRulesets: IntelligenceRuleset[] = await getIntelligenceService().getIntelligenceRulesets();
    const rulesetInfo: IntelligenceRuleset | undefined = allRulesets.find((ruleset) => ruleset.id === rulesetId);
    return rulesetInfo?.name;
};

export const fetchRulesetRules = async (rulesetId: number): Promise<IntelligenceRule[]> => {
    return getIntelligenceService().getIntelligenceRules(rulesetId);
};

interface INormalIpListItemModel {
    comment: string;
    ip: string;
}

export interface INormalIpListModel {
    name: string;
    description: string;
    id: number;
    items: INormalIpListItemModel[];
}
export interface IMagicIpListModel {
    name: string;
    source: string;
    ips: string[];
}

export const fetchNormalIpListNames = async (): Promise<INormalIpListModel[]> => {
    return sendHttpRequest<INormalIpListModel[]>(NORMAL_IP_LIST_URL, { method: 'GET' });
};

export const fetchMagicIpListNames = async (): Promise<IMagicIpListModel[]> => {
    return sendHttpRequest<IMagicIpListModel[]>(MAGIC_IP_LIST_URL, { method: 'GET' });
};

export const getCdrIpIdByName = async (ipName: string): Promise<string> => {
    const normalIps: INormalIpListModel[] = await fetchNormalIpListNames();
    const normalIpInfo: INormalIpListModel | undefined = normalIps.find((ipInfo) => ipInfo.name === ipName);
    if (normalIpInfo) {
        return String(normalIpInfo.id);
    }

    return ipName;
};

export const getCdrIpNameById = async (ipId: string): Promise<string> => {
    const normalIps: INormalIpListModel[] = await fetchNormalIpListNames();
    const normalIpInfo: INormalIpListModel | undefined = normalIps.find((ipInfo) => String(ipInfo.id) === ipId);
    if (normalIpInfo) {
        return normalIpInfo.name;
    }

    return ipId;
};

export const saveCdrExclusion = async (itemsMap: IItemsMap, cdrExclusions?: ICdrExclusionProps): Promise<void> => {
    const exclusionProps: ICdrExclusionProps = getExclusionFromState(itemsMap);
    if (cdrExclusions?.id) {
        await getCdrExclusionsService().updateCdrExclusion(cdrExclusions?.id, exclusionProps);
    } else {
        await getCdrExclusionsService().createCdrExclusion(exclusionProps);
    }
};

export const getCdrExclusionSubmitSuccessNotification = (
    itemsMap: IItemsMap,
    cdrExclusions?: ICdrExclusionProps,
): string => {
    if (cdrExclusions?.id) {
        return cdrTrans('CDR_EXCLUSIONS.NOTIFICATIONS.EXCLUSION_UPDATED_SUCCESSFULLY');
    } else {
        return cdrTrans('CDR_EXCLUSIONS.NOTIFICATIONS.EXCLUSION_ADDED_SUCCESSFULLY');
    }
};

export const getCdrExclusionSubmitFailedNotification = (
    itemsMap: IItemsMap,
    error: string,
    cdrExclusions?: ICdrExclusionProps,
): string => {
    if (cdrExclusions?.id) {
        return cdrTrans('CDR_EXCLUSIONS.NOTIFICATIONS.FAILED_EDITING_EXCLUSION', { error });
    } else {
        return cdrTrans('CDR_EXCLUSIONS.NOTIFICATIONS.FAILED_ADDING_NEW_EXCLUSION', { error });
    }
};
