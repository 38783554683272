import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EditorStyled } from './AutoActionEditor.styled';
import { IAutoAction, IAutoActionIntegration, IAutoActionProps } from '../AutoActions.interface';
import { Button, Spinner, Stack } from 'common/design-system/components-v2';
import { closeDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { fetchAllAutoActionsIntegrations } from '../AutoActions.utils';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../../consts';
import { LoadingState } from 'common/interface/general';
import { RiskStyled } from '../../../RiskManagement.styled';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { getOrganizationalUnitService, IOrganizationalUnit } from 'common/interface/data_services';
import { globalModelUtils } from 'common/components/GlobalModals/GlobalModals';
import { IAttrProps, IThenProps, IWhenProps, IWhereProps } from './AutoActionEditor.interface';
import { AUTO_ACTION_EDITOR_DRAWER_EVENT_HANDLER_ID } from './AutoActionEditor.consts';
import {
    getAutoActionsService,
    IUpdatedAutoActionProps,
} from 'common/module_interface/RiskManagement/autoActions/AutoActions';
import { AutoActionEditorThen } from './AutoActionEditorSections/AutoActionEditorThen/AutoActionEditorThen';
import { AutoActionEditorWhere } from './AutoActionEditorSections/AutoActionEditorWhere/AutoActionEditorWhere';
import { AutoActionEditorWhen } from './AutoActionEditorSections/AutoActionEditorWhen/AutoActionEditorWhen';
import { AutoActionEditorAttr } from './AutoActionEditorSections/AutoActionEditorAttr/AutoActionEditorAttr';
import { convertToServerFilter, ICompoundFilter } from 'common/erm-components/custom/FilterTree/CompoundFilter';
import { globalEventBus } from 'common/erm-components/utils/EventBus/eventBus';
import { AUTO_ACTIONS_PAGE_TABLE_ID } from '../AutoActions.consts';
import { useCloseDrawerHandler } from 'common/components/DrawerInfra/Drawer/UseCloseDrawerHandler';
import { useIsReadOnlyAutoActions } from '../useIsAutoActionReadonly';

export const AutoActionEditor: React.FC<{
    autoActionProps?: IAutoActionProps;
    allAutoActions: IAutoAction[];
    onAutoActionsChange?: () => void;
}> = ({ autoActionProps, allAutoActions, onAutoActionsChange }) => {
    const { t } = useTranslation(I18nRiskNamespace);
    const isReadOnly = useIsReadOnlyAutoActions();
    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.IS_LOADING);
    const [allIntegrations, setAllIntegrations] = useState<IAutoActionIntegration[]>([]);
    const [saveWasClicked, setSaveWasClicked] = useState<boolean>(false);
    const [orgUnitsRoot, setOrgUnitsRoot] = useState<IOrganizationalUnit | undefined>();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const initialFilterRef = useRef<ICompoundFilter | undefined>(autoActionProps?.filter);
    const otherAutoActions: IAutoAction[] = useMemo(
        () =>
            autoActionProps
                ? allAutoActions.filter((anAutoAction) => anAutoAction.id !== autoActionProps.id)
                : allAutoActions,
        [allAutoActions, autoActionProps],
    );
    const [attrProps, setAttrProps] = useState<IAttrProps>({
        name: autoActionProps?.name || '',
        description: autoActionProps?.description || '',
        errorElementRefs: [],
    });
    const [whenProps, setWhenProps] = useState<IWhenProps>({
        triggers: autoActionProps?.triggers || [],
        errorElementRefs: [],
    });

    const [whereProps, setWhereProps] = useState<IWhereProps>({
        orgUnitIds: autoActionProps?.orgUnitIdsFilter || [],
        initialFilter: initialFilterRef.current,
        filter: initialFilterRef.current,
        validServerFilter: convertToServerFilter(initialFilterRef.current),
        ignoreInvalidConditions: true,
        errorElementRefs: [],
    });

    const [thenProps, setThenProps] = useState<IThenProps>({
        integrationIds: autoActionProps?.integrationIds || [],
        errorElementRefs: [],
    });

    const hasErrors = useCallback(() => {
        return attrProps.hasErrors || whenProps.hasErrors || whereProps.hasErrors || thenProps.hasErrors;
    }, [attrProps.hasErrors, thenProps.hasErrors, whenProps.hasErrors, whereProps.hasErrors]);

    const getFirstErrorElementRef = useCallback((): HTMLInputElement | undefined => {
        const allErrorElementRefs = [
            ...attrProps.errorElementRefs,
            ...whenProps.errorElementRefs,
            ...whereProps.errorElementRefs,
            ...thenProps.errorElementRefs,
        ];
        return allErrorElementRefs.length > 0 ? allErrorElementRefs[0] : undefined;
    }, [
        attrProps.errorElementRefs,
        thenProps.errorElementRefs,
        whenProps.errorElementRefs,
        whereProps.errorElementRefs,
    ]);

    const prepareSaveAutoActionProps = useCallback((): IUpdatedAutoActionProps => {
        return {
            name: attrProps.name.trim(),
            description: attrProps.description.trim(),
            triggers: whenProps.triggers,
            orgUnitIdsFilter: whereProps.orgUnitIds,
            integrationIds: thenProps.integrationIds,
            filter: convertToServerFilter(whereProps.filter),
        };
    }, [
        attrProps.description,
        attrProps.name,
        thenProps.integrationIds,
        whenProps.triggers,
        whereProps.filter,
        whereProps.orgUnitIds,
    ]);
    const initialAutoActionPropsRef = useRef<IUpdatedAutoActionProps>(prepareSaveAutoActionProps());

    const hasAutoActionPropsChanged = useCallback(() => {
        const currAutoActionProps = prepareSaveAutoActionProps();
        return JSON.stringify(initialAutoActionPropsRef.current) !== JSON.stringify(currAutoActionProps);
    }, [prepareSaveAutoActionProps]);

    const onCancel = useCallback(
        (closeAll?: boolean, forceClose?: boolean) => {
            if (!forceClose && hasAutoActionPropsChanged()) {
                globalModelUtils.showConfirmationModal({
                    title: t('AUTO_ACTIONS.EDITOR.DISCARD_DIALOG.TITLE'),
                    text: t('AUTO_ACTIONS.EDITOR.DISCARD_DIALOG.TEXT'),
                    variant: 'warning',
                    onConfirm: () => closeDrawer(closeAll),
                    submitBtnText: t('AUTO_ACTIONS.EDITOR.DISCARD_DIALOG.SUBMIT_BUTTON_TEXT'),
                    cancelBtnText: t('AUTO_ACTIONS.EDITOR.DISCARD_DIALOG.CANCEL_BUTTON_TEXT'),
                });
            } else {
                closeDrawer(closeAll);
            }
        },
        [hasAutoActionPropsChanged, t],
    );

    useCloseDrawerHandler(AUTO_ACTION_EDITOR_DRAWER_EVENT_HANDLER_ID, (closeAll?: boolean) => {
        onCancel(closeAll, isReadOnly);
    });

    const closeOnSubmit = useCallback(() => {
        closeDrawer();
        globalEventBus.sendEvent(AUTO_ACTIONS_PAGE_TABLE_ID);
    }, []);

    const onAddAutoAction = useCallback(() => {
        setIsSaving(true);
        getAutoActionsService()
            .createAutoAction(prepareSaveAutoActionProps())
            .then(() => {
                getNotificationsService().addNotification({
                    type: NotificationType.SUCCESS,
                    text: t('AUTO_ACTIONS.TABLE.ACTIONS.AUTO_ACTION_ADDED_SUCCESSFULLY', { name: attrProps.name }),
                });
                closeOnSubmit();
                onAutoActionsChange && onAutoActionsChange();
            })
            .catch((error) => {
                setIsSaving(false);
                setSaveWasClicked(true);
                console.error('Failed adding new autoAction. Error:', error);
                getNotificationsService().addNotification({
                    type: NotificationType.ERROR,
                    text: t('AUTO_ACTIONS.TABLE.ACTIONS.FAILED_ADDING_NEW_AUTO_ACTION'),
                });
            });
    }, [attrProps.name, closeOnSubmit, onAutoActionsChange, prepareSaveAutoActionProps, t]);

    const onUpdateAutoAction = useCallback(
        (id: string) => {
            setIsSaving(true);
            getAutoActionsService()
                .updateAutoAction(id, prepareSaveAutoActionProps())
                .then(() => {
                    getNotificationsService().addNotification({
                        type: NotificationType.SUCCESS,
                        text: t('AUTO_ACTIONS.TABLE.ACTIONS.AUTO_ACTION_UPDATED_SUCCESSFULLY', {
                            name: attrProps.name,
                        }),
                    });
                    closeOnSubmit();
                    onAutoActionsChange && onAutoActionsChange();
                })
                .catch((error) => {
                    setIsSaving(false);
                    setSaveWasClicked(true);
                    console.error('Failed editing autoAction with id:', id, ' Error:', error);
                    getNotificationsService().addNotification({
                        type: NotificationType.ERROR,
                        text: t('AUTO_ACTIONS.TABLE.ACTIONS.FAILED_EDITING_AUTO_ACTION'),
                    });
                });
        },
        [attrProps.name, closeOnSubmit, onAutoActionsChange, prepareSaveAutoActionProps, t],
    );

    const onSave = useCallback(() => {
        if (hasErrors()) {
            const ref: HTMLInputElement | undefined = getFirstErrorElementRef();
            if (ref) {
                ref.scrollIntoView({
                    block: 'center',
                    inline: 'nearest',
                    behavior: 'smooth',
                });
            }
            setSaveWasClicked(true);
            return;
        }
        if (autoActionProps?.id) {
            onUpdateAutoAction(autoActionProps.id);
        } else {
            onAddAutoAction();
        }
    }, [hasErrors, autoActionProps, getFirstErrorElementRef, onUpdateAutoAction, onAddAutoAction]);

    const loadIntegrations = useCallback(async () => {
        return fetchAllAutoActionsIntegrations().then((integrations: IAutoActionIntegration[]) => {
            setAllIntegrations(integrations);
        });
    }, []);

    const loadData = useCallback(async () => {
        setLoadingState(LoadingState.IS_LOADING);
        const loadOrgUnitsRootPromise = async () => {
            return getOrganizationalUnitService()
                .getOrganizationalUnitsView()
                .then((ouRoot: IOrganizationalUnit) => {
                    setOrgUnitsRoot(ouRoot);
                });
        };
        const loadIntegrationsPromise = () => {
            return loadIntegrations();
        };

        return Promise.all([loadOrgUnitsRootPromise(), loadIntegrationsPromise()])
            .then(() => {
                setLoadingState(LoadingState.LOADING_SUCCEEDED);
            })
            .catch((error) => {
                console.error(error);
                globalModelUtils.showErrorModal({
                    text: t('AUTO_ACTIONS.EDITOR.FAILED_LOADING_DATA_MESSAGE'),
                    onClose: () => {
                        closeDrawer();
                    },
                });
            });
    }, [loadIntegrations, t]);

    useEffect(() => {
        void loadData();
    }, [loadData]);

    if (loadingState === LoadingState.IS_LOADING) {
        return (
            <EditorStyled.TopDiv>
                <RiskStyled.SpinnerDiv>
                    <Spinner />
                </RiskStyled.SpinnerDiv>
            </EditorStyled.TopDiv>
        );
    }
    return (
        <EditorStyled.TopDiv spacing={5}>
            <EditorStyled.TopAreaDiv direction={'column'} spacing={5} overflow={'auto'}>
                <AutoActionEditorAttr
                    exposeErrors={saveWasClicked}
                    onSave={onSave}
                    attrProps={attrProps}
                    setAttrProps={setAttrProps}
                    otherAutoActions={otherAutoActions}
                />
                <EditorStyled.LabelDiv text={t('AUTO_ACTIONS.EDITOR.CONFIGURATION')} />
                <EditorStyled.ConfigurationContentDiv spacing={5}>
                    <AutoActionEditorWhen
                        exposeErrors={saveWasClicked}
                        whenProps={whenProps}
                        setWhenProps={setWhenProps}
                    />
                    <AutoActionEditorWhere
                        exposeErrors={saveWasClicked}
                        orgUnitsRoot={orgUnitsRoot}
                        whereProps={whereProps}
                        setWhereProps={setWhereProps}
                    />
                    <AutoActionEditorThen
                        exposeErrors={saveWasClicked}
                        allIntegrations={allIntegrations}
                        refreshIntegrations={loadIntegrations}
                        thenProps={thenProps}
                        setThenProps={setThenProps}
                    />
                </EditorStyled.ConfigurationContentDiv>
            </EditorStyled.TopAreaDiv>
            <EditorStyled.ButtonsDiv>
                <Stack direction='row' justifyContent='flex-end' fullWidth spacing={2}>
                    {!isReadOnly && (
                        <Button variant='text' dataAid='Cancel' onClick={() => onCancel()} disabled={isSaving}>
                            {t('AUTO_ACTIONS.EDITOR.BUTTONS.CANCEL')}
                        </Button>
                    )}
                    {!isReadOnly && (
                        <Button color='brandPrimary' onClick={onSave} disabled={isSaving}>
                            {isSaving ? t('AUTO_ACTIONS.EDITOR.BUTTONS.SAVING') : t('AUTO_ACTIONS.EDITOR.BUTTONS.SAVE')}
                        </Button>
                    )}
                    {isReadOnly && (
                        <Button color='brandPrimary' onClick={() => onCancel(false, true)}>
                            {t('AUTO_ACTIONS.EDITOR.BUTTONS.CLOSE')}
                        </Button>
                    )}
                </Stack>
            </EditorStyled.ButtonsDiv>
        </EditorStyled.TopDiv>
    );
};
