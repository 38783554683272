import styled from 'styled-components';

const BaseTopDiv = styled.div<{ bg: string }>`
    display: flex;
    background-color: ${(props) => props.bg};
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: 16px;
`;

const BusinessTopDiv = styled(BaseTopDiv)<{ bg: string }>`
    width: 30px;
    height: 30px;
`;

const SecurityTopDiv = styled(BaseTopDiv)<{ bg: string }>`
    color: ${({ theme }) => theme.palette.common.white};
    font-weight: ${({ theme }) => theme.typography.body500.fontWeight};
    width: 24px;
    height: 24px;
`;

export const BadgeStyled = {
    BusinessTopDiv,
    SecurityTopDiv,
};
