import { IAssumeRoleRequest, IAssumeRoleResponse, IGetRolesRequest } from './Roles.interface';
import IAccount from '../user/Account.interface';
import { getHttpService } from 'common/interface/services';
import { IUserRole } from 'common/interface/user';
import { generalApiError } from 'common/utils/http';

export const getRolesFromServer = async (): Promise<IUserRole[]> => {
    try {
        const fromGetRoles = await getHttpService().get<IGetRolesRequest>(
            'customization?component=switch-role',
            undefined,
            undefined,
            generalApiError,
        );
        return fromGetRoles[0]?.data?.lru || [];
    } catch (error) {
        return [];
    }
};

export const assumeRole = async (role: IAssumeRoleRequest): Promise<IAssumeRoleResponse> => {
    return await getHttpService().post<IAssumeRoleResponse>('auth/assume-role/web', {
        data: role,
    });
};
export const backToRootRole = async (): Promise<{ csrf: string; userName: string }> => {
    return await getHttpService().post<IAssumeRoleResponse>('auth/assume-role/web');
};

export const getUserAccountsAndRoles = async (): Promise<IAccount[]> => {
    return await getHttpService().get<IAccount[]>('AccountTrust/assumable-roles');
};
