import React, { useState } from 'react';
import { SectionStyled } from './Section.styled';
import { isNil } from 'common/utils/helpFunctions';
import { Icon } from 'common/design-system/components-v2';

export const Section: React.FC<{
    initiallyOpen?: boolean;
    title?: string;
    onClick?: () => void;
    isLargeTitle?: boolean;
    extraTitleComponent?: React.ReactElement;
    children: React.ReactElement | Array<React.ReactElement>;
}> = ({ initiallyOpen = true, title, onClick, isLargeTitle, extraTitleComponent, children }) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(initiallyOpen);
    const toggleIsExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <SectionStyled.TopDiv>
            <SectionStyled.HeaderDiv>
                <SectionStyled.HeaderArrowTextDiv>
                    <SectionStyled.ArrowDiv isExpanded={isExpanded} onClick={toggleIsExpanded}>
                        <Icon name={'chevronRight'} size={14} />
                    </SectionStyled.ArrowDiv>
                    {title && (
                        <SectionStyled.HeaderTextDiv
                            isLargeTitle={isLargeTitle}
                            isLink={!isNil(onClick)}
                            onClick={onClick || toggleIsExpanded}
                        >
                            {title}
                        </SectionStyled.HeaderTextDiv>
                    )}
                </SectionStyled.HeaderArrowTextDiv>
                <SectionStyled.HeaderExtraComponentDiv>{extraTitleComponent}</SectionStyled.HeaderExtraComponentDiv>
            </SectionStyled.HeaderDiv>
            <SectionStyled.ContentDiv isExpanded={isExpanded}>{children}</SectionStyled.ContentDiv>
        </SectionStyled.TopDiv>
    );
};
