import { useCallback, useState } from 'react';

import { isEmpty } from 'lodash';
import { IFieldErrorsProps } from '../Modals/Modals.types';
import { IFieldError } from 'common/module_interface/intelligence/CloudBots/CloudBots.interface';
import { Typography } from 'common/design-system/components-v2';

export const useFieldErrors = (): IFieldErrorsProps => {
    const [fieldErrors, setFieldErrors] = useState<IFieldError[]>([]);

    /**
     * Adds field errors to the fieldErrors state
     * @param {IFieldError[]} fieldErrorsToAdd - The field errors to add
     * @returns {void}
     */
    const addFieldErrors = useCallback((fieldErrorsToAdd: IFieldError[]) => {
        setFieldErrors((prevErrors: IFieldError[]) => {
            const errors: IFieldError[] = prevErrors.filter((error: IFieldError) => {
                return !fieldErrorsToAdd.some((fieldError: IFieldError) => fieldError.field === error.field);
            });
            errors.push(...fieldErrorsToAdd);
            return errors;
        });
    }, []);

    /**
     * Checks if a field has an error
     * @param {string} field - The field to check
     * @returns {boolean} - Returns true if the field has an error, false otherwise
     */
    const isFieldError = useCallback(
        (field: string): boolean => {
            return fieldErrors.some((error: IFieldError) => error.field === field);
        },
        [fieldErrors],
    );

    /**
     * Returns a Typography component with the error label if the field has an error
     * @param {string} field - The field to check
     * @returns {React.ReactNode} - Returns a Typography component if the field has an error, null otherwise
     */
    const getFieldErrorElement = useCallback(
        (field: string) => {
            const errorLabel = fieldErrors.find((error: IFieldError) => error.field === field)?.label;
            return errorLabel ? (
                <Typography variant={'bodyXs'} color={'alert'}>
                    {errorLabel}
                </Typography>
            ) : null;
        },
        [fieldErrors],
    );

    /**
     * Returns the error text of the specified field
     * @param {string} field - The field to get the error text of
     * @returns {string} - The error text of the specified field
     */
    const getFieldErrorText = useCallback(
        (field: string): string | undefined => {
            return fieldErrors.find((error: IFieldError) => error.field === field)?.label;
        },
        [fieldErrors],
    );

    /**
     * Clears the errors of the specified fields
     * @param {string[]} fields - The fields to clear the errors of
     */
    const clearFieldErrors = useCallback(
        (fields: string[] = []) => {
            setFieldErrors((prevErrors: IFieldError[]) => {
                let errors: IFieldError[] = [];
                if (!isEmpty(fields)) {
                    errors = prevErrors.filter((error: IFieldError) => {
                        return !fields.some((field: string) => field === error.field);
                    });
                }
                return errors;
            });
        },
        [setFieldErrors],
    );

    return {
        fieldErrors,
        addFieldErrors,
        isFieldError,
        getFieldErrorText,
        getFieldErrorElement,
        clearFieldErrors,
    };
};
