import {
    renderAddFilter,
    renderClearAll,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { Addin } from 'common/extensibility/AddinRegistry';
import i18n from 'i18next';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { RiskManagementRegistry } from '../../../RiskManagementRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { I18nRiskNamespace } from '../../../consts';

const filterDefsAddins: Addin<IFilterProps>[] = [
    {
        id: 'add filter',
        content: {
            filterProps: { key: 'add-filter' },
            renderFunction: renderAddFilter,
        },
    },
    {
        id: 'free text',
        content: {
            filterProps: {
                key: FILTERS_KEYS.FREE_TEXT, //This needs to be the same as in stored filter/query params
                title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.FREE_TEXT.TITLE'),
            },
            keyInObjectForAPI: 'freeTextPhrase',
            renderFunction: renderDefaultFreeTextFilter,
        },
    },
    {
        id: 'type',
        content: function ({ aggregations }: { aggregations: Aggregations }) {
            return {
                filterProps: {
                    initialData: aggregations?.['type'],
                    key: 'type',
                    title: i18n.t('RISK_MANAGEMENT.RULESETS.FILTER.TYPE', { ns: I18nRiskNamespace }),
                    displayMapping: aggregations?.type,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'clear all',
        content: {
            filterProps: {
                key: FILTERS_KEYS.CLEAR_BUTTON,
            },
            renderFunction: renderClearAll,
        },
    },
];

export default function initializeRulesetsFiltersDefs() {
    RiskManagementRegistry.addRulesetsTableFilterAddins(filterDefsAddins);
}
