import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const AddCloudBotsWrapper = styled(Stack)``;
const CloudBotsSelectWrapper = styled(Stack)``;
const CloudBotCardListWrapper = styled(Stack)``;
const CloudBotsCounterWrapper = styled(Stack)``;
const AddButtonWrapper = styled(Stack)<{ isHelperTextVisible?: boolean }>`
    justify-content: ${(prop) => (prop?.isHelperTextVisible ? 'center' : 'flex-end')};
`;

export const CloudBotsStyles = {
    AddCloudBotsWrapper,
    CloudBotsSelectWrapper,
    CloudBotCardListWrapper,
    CloudBotsCounterWrapper,
    AddButtonWrapper,
};
