import React from 'react';
import { CveOverview } from 'modules/workloads/services/cveDrawerRegistry/cveDrawerRegistry.types';
import { BlockInfo, Stack, Typography } from 'common/design-system/components-v2';
import { AttackVectorPanelStyled } from './AttackVectorPanel.styled';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';

const AttackVectorPanel: React.FC<{ overview: CveOverview }> = ({ overview }) => {
    const { t } = useTranslation(getK8sNamespace('cve-explorer'));
    const {
        source,
        'base-score': baseScore,
        'impact-score': impactScore,
        'exploitability-score': exploitabilityScore,
        'vector-string': vectorString,
        version,
    } = overview.cve.cvssInfo;

    const infoBlocks: Array<BlockInfoProps> = [
        { title: t('cveDrawer.attackVectorPanel.infoBlocks.source'), info: source },
        { title: t('cveDrawer.attackVectorPanel.infoBlocks.baseScore'), info: baseScore },
        { title: t('cveDrawer.attackVectorPanel.infoBlocks.impactScore'), info: impactScore },
        { title: t('cveDrawer.attackVectorPanel.infoBlocks.exploitabilityScore'), info: exploitabilityScore },
        { title: t('cveDrawer.attackVectorPanel.infoBlocks.version'), info: version },
        { title: t('cveDrawer.attackVectorPanel.infoBlocks.vector'), info: vectorString, copyable: true },
    ];

    return (
        <Stack spacing={1}>
            <Typography variant='body500'>{t('cveDrawer.attackVectorPanel.cvssDetails')}</Typography>
            <AttackVectorPanelStyled.AttackVectorPanelWrapper padding={4} spacing={3}>
                <Stack direction='row' spacing={6}>
                    {infoBlocks.map((block, index) => (
                        <BlockInfo
                            key={`block-${index}`}
                            title={block.title}
                            info={block.info}
                            copyable={block.copyable}
                        />
                    ))}
                </Stack>
            </AttackVectorPanelStyled.AttackVectorPanelWrapper>
        </Stack>
    );
};

export default AttackVectorPanel;
