import { validLanguages } from 'common/services/translations/translations';
import i18n from 'i18next';
import { getAppRootRegistry, getUserService } from 'common/interface/services';
import { lazy } from 'react';

export const I18nTranslationKey = 'SystemComponentTesting';

export const INTERNAL_TOOLS_AF = 'InternalTools';

export const initializeI18nSystemComponentTestingPage = async () => {
    return Promise.all(
        validLanguages.map(async (language) => {
            try {
                const contents = await require(`./languages/${language}/translation.json`);
                i18n.addResourceBundle(language, `${I18nTranslationKey}`, contents);
            } catch {
                //Nothing to do with missing translation files
            }
        }),
    );
};

const initializeSystemComponentTestingPage = () => {
    const SystemComponentTestingPageRoute = {
        condition: () => getUserService().hasPermission([INTERNAL_TOOLS_AF]),
        component: lazy(() => import('./SystemComponentTesting')),
        path: '/system-testing/system-component-testing',
    };

    getAppRootRegistry().addRoutes([{ id: 'SystemComponentTesting', content: SystemComponentTestingPageRoute }]);
};

export const initializeInternalTools = () => {
    void initializeI18nSystemComponentTestingPage();
    initializeSystemComponentTestingPage();
};
