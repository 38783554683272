import styled from 'styled-components';
import { EditorStyled } from '../../AutoActionEditor.styled';
import { Label, Stack } from 'common/design-system/components-v2';
import { PreviewCardVariant } from './PreviewBox/PreviewCounterCard';
import Checkbox from 'common/design-system/components-v2/Checkbox';

const IgnoreCheckboxDiv = styled(Checkbox)``;

const PreviewItemLabelDiv = styled(Label)`
    width: 100%;
`;

const PreviewBaseDiv = styled(Stack)`
    gap: ${({ theme }) => `${theme.spacing(4)}${theme.units}`};
    height: 50%;
`;

const PreviewTopDiv = styled(Stack)`
    flex-direction: column;
    width: 100%;
    padding: 0;
    gap: ${({ theme }) => `${theme.spacing(4)}${theme.units}`};
`;

const PreviewTitleDiv = styled(Stack)`
    flex-direction: column;
    width: 100%;
`;

const PreviewBoxesContainer = styled(PreviewBaseDiv)`
    flex-direction: row;
    width: 100%;
    height: 130px;
`;

const PreviewLeftDiv = styled(PreviewBaseDiv)`
    flex-direction: column;
    width: 25%;
    height: 100%;
`;

const PreviewRightDiv = styled(PreviewBaseDiv)`
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
`;

const PreviewBaseRightRow = styled(PreviewBaseDiv)`
    flex-direction: row;
    width: 100%;
`;

const PreviewRightTopDiv = styled(PreviewBaseRightRow)``;

const PreviewRightBottomDiv = styled(PreviewBaseRightRow)``;

const PreviewCounterCardTopDiv = styled(Stack)`
    width: 100%;
    height: 100%;
`;

const PreviewCounterCardDiv = styled(EditorStyled.EditorCardDiv)<{ variant: PreviewCardVariant; isClickable: boolean }>`
    background: ${({ theme }) => theme.palette.surface.normal};
    padding: ${(props) => (props.variant === 'h1' ? '8px 10px' : '2px 6px')};
    line-height: ${(props) => (props.variant === 'h1' ? '32px' : '24px')};
    gap: 0;
    cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};

    &:hover {
        background: ${({ theme, isClickable }) =>
            isClickable ? theme.palette.surface.brandLight : theme.palette.surface.normal};
    }
`;

const FilterTreeWrapper = styled(Stack)`
    margin-left: -40px;
`;

export const WhereStyled = {
    PreviewTopDiv,
    PreviewTitleDiv,
    PreviewBoxesContainer,
    PreviewLeftDiv,
    PreviewRightDiv,
    PreviewRightTopDiv,
    PreviewRightBottomDiv,
    PreviewCounterCardTopDiv,
    PreviewCounterCardDiv,
    IgnoreCheckboxDiv,
    PreviewItemLabelDiv,
    FilterTreeWrapper,
};
