import { GetVulnerabilityScan, Package, Cve } from './vulnerability.interface';

type GetCveAndPackageById = (
    cveId: string,
    packageId: string,
    data?: GetVulnerabilityScan.Response,
) => { package: Package; cve: Cve } | undefined;
const getCveAndPackageById: GetCveAndPackageById = (cveId, packageId, data?) => {
    if (!data) return undefined;

    const vulPackage = data.Package.find((vulPackage) => vulPackage.id.replace(/\\/g, '/') === packageId);
    if (!vulPackage) return undefined;

    const cve = vulPackage.cves.find((cve) => cve.id === cveId);

    if (!cve) {
        return undefined;
    }

    return {
        package: vulPackage,
        cve,
    };
};

export { getCveAndPackageById };
