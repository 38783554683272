import { IApplicationReducer } from 'common/interface/redux';
import ociReducer from './reducer';
import { initialOciEnvironmentData } from './OciEnvironment.consts';
import { getAppRootRegistry } from 'common/interface/services';

const initializeOciEnvRedux = () => {
    const reducers: IApplicationReducer[] = [
        {
            name: 'env_oci',
            reducer: ociReducer,
            isBlackList: true,
        },
    ];
    getAppRootRegistry().addReducers(reducers, 'name');
};

export const initializeOciEnv = () => {
    initializeOciEnvRedux();
    initialOciEnvironmentData();
};
