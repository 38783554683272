import { HttpMethod } from 'common/common_types';
import { getHttpService, getStoreService } from 'common/interface/services';
import {
    IAlertTrendOptionsItem,
    IDashboardWidget,
    IGenericWidgetDataItem,
    IGenericWidgetDataItemSet,
    IGenericWidgetDataSet,
} from 'common/module_interface/overview/Interface';
import dayjs from 'dayjs';
import {
    IMagellanOvertimeRequest,
    IMagellanOvertimeResponse,
} from 'common/module_interface/intelligence/Intelligence.interface';
import { getEventsOvertimeFilterString } from 'common/module_interface/events/Events';
import { getCloudAccountsService } from 'common/interface/data_services';
import { changeUrl } from 'common/utils/http';
import { EventType } from 'common/module_interface/events/EventsConsts';
import { getEventsTableWidgetService } from 'common/module_interface/events/EventsTableRegistry';
import { getVendor } from 'common/consts/vendors';

export const alertsTrendDataGetter = async (widget: IDashboardWidget): Promise<IGenericWidgetDataSet> => {
    const state = getStoreService().state;
    const url = `${state.app?.magellanUrl}/alerts/overtime`;
    const alertTrendOptions: IAlertTrendOptionsItem[] =
        // @ts-ignore - this is a temporary fix for the case when the widget is not yet migrated to the new format
        widget.options?.alertTrendOptions || (widget.options.alertsOptions as IAlertTrendOptionsItem[]) || [];
    const startTime = dayjs().subtract(29, 'days').set('seconds', 0).set('milliseconds', 0).valueOf();
    const endTime = dayjs().set('seconds', 0).set('milliseconds', 0).valueOf();

    const selectedOptions: IAlertTrendOptionsItem[] = [];
    const requests = alertTrendOptions.reduce<IMagellanOvertimeRequest[]>((result, optionItem) => {
        if (
            optionItem.selected &&
            optionItem.selected.length !== 0 &&
            optionItem.type !== '' &&
            optionItem.type !== 'None' &&
            !optionItem.isEmpty
        ) {
            result.push({
                start: startTime,
                end: endTime,
                limit: 1000,
                cloudAccount: optionItem.selected,
                filter:
                    optionItem.widgetConfigFilterState &&
                    getEventsOvertimeFilterString(optionItem.widgetConfigFilterState),
            });
            selectedOptions.push(optionItem);
        }
        return result;
    }, []);

    const response = await Promise.all(
        requests.map((request) =>
            getHttpService().request<IMagellanOvertimeResponse>(
                url,
                {
                    method: HttpMethod.post,
                    data: request,
                },
                { cachingConfig: { useCache: true } },
            ),
        ),
    );

    const cloudAccounts = await getCloudAccountsService().getAllCloudAccounts(true);
    const getRelevantCloudAccountIds = (cloudAccountIdOrExternalId: string | string[]): string[] => {
        // this is a temporary fix for the case when the widget is not yet migrated to the new format
        const fixedCloudAccountIdOrExternalId = Array.isArray(cloudAccountIdOrExternalId)
            ? cloudAccountIdOrExternalId
            : [cloudAccountIdOrExternalId];
        return fixedCloudAccountIdOrExternalId.reduce<string[]>((acc, cloudAccountIdOrExternalId) => {
            const cloudAccount = cloudAccounts.find(
                (cloudAccount) =>
                    cloudAccount.id === cloudAccountIdOrExternalId ||
                    cloudAccount.externalId === cloudAccountIdOrExternalId,
            );
            if (cloudAccount) {
                const vendor = getVendor(cloudAccount.platform);
                acc.push(`${vendor?.elasticVendorType}|${cloudAccount.id}`);
            }
            return acc;
        }, []);
    };

    let itemsHasValues = false;
    const items = response.reduce<IGenericWidgetDataItemSet[]>((result, responseItem, index) => {
        if (!selectedOptions[index].selected || selectedOptions[index].isEmpty) {
            return result;
        }
        const cloudAccountIds = getRelevantCloudAccountIds(selectedOptions[index].selected);
        const onClick = () => {
            const eventsUrl = getEventsTableWidgetService().getMainTableLink(EventType.ALL, {
                fields: [
                    {
                        name: 'cloudAccountId_calc',
                        value: cloudAccountIds,
                    },
                ],
            });
            return changeUrl(eventsUrl);
        };
        result.push({
            key: selectedOptions[index].title,
            value: responseItem.data.reduce<IGenericWidgetDataItem[]>((acc, item) => {
                itemsHasValues = true;
                const isFoundIndex = acc.findIndex((accItem) => accItem.key === item.date_time.value);
                if (isFoundIndex > -1) {
                    acc[isFoundIndex].value = Number(acc[isFoundIndex].value) + item.sum_counts;
                    return acc;
                }
                acc.push({
                    key: item.date_time.value,
                    value: item.sum_counts,
                    onclick: onClick,
                });
                return acc;
            }, []),
        });
        return result;
    }, []);

    return { items: itemsHasValues ? items : [] };
};
