import { IActiveFeatureInfo } from 'common/registries/ActiveFeatureManager/ActiveFeatures';
import { ActiveFeaturesRegistry } from 'common/registries/ActiveFeatureManager/ActiveFeaturesRegistry';

const WORKLOADS_TOPIC_ID = 'WORKLOADS';

export function initializeActiveFeatures() {
    const sbomSearchAfInfo: IActiveFeatureInfo = {
        key: 'allow_sbom_search',
        description: 'Enable SBOM search under vulnerability search page',
        topic: WORKLOADS_TOPIC_ID,
    };

    const vulnerabilityExclusionAfInfo: IActiveFeatureInfo = {
        key: 'vulnerability-exclusion',
        description: 'Enable vulnerability exclusion feature',
        topic: WORKLOADS_TOPIC_ID,
    };

    const vulnerabilityFindingsAfInfo: IActiveFeatureInfo = {
        key: 'workload_vlm_findings',
        description: 'Open the new React vulnerability findings page',
        topic: WORKLOADS_TOPIC_ID,
    };

    const serverlessPageAfInfo: IActiveFeatureInfo = {
        key: 'workload-serverless-functions',
        description: 'Open the new React serverless page',
        topic: WORKLOADS_TOPIC_ID,
    };

    ActiveFeaturesRegistry.addActiveFeatureTopic({
        key: WORKLOADS_TOPIC_ID,
        title: 'Workloads',
        icon: 'containerImage',
    });

    ActiveFeaturesRegistry.addActiveFeatureInfos([
        sbomSearchAfInfo,
        vulnerabilityExclusionAfInfo,
        vulnerabilityFindingsAfInfo,
        serverlessPageAfInfo,
    ]);
}
