import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../../../../../consts';
import { IProtectedAssetDetailProvider } from 'common/module_interface/assets/ProtectedAssets';
import { getFullEntityName } from 'common/components/ProtectedAssets/Renderers/EntityCellRender';
import { AssetPropertiesPanel } from 'common/components/AssetPropertiesPanel/AssetPropertiesPanel';
import { IssueViewerRegistry } from 'common/module_interface/RiskManagement/issues/IssueViewerRegistry';
import { ISSUE_ASSET_PANEL_KEY } from '../IssueAssetPanel.initialize';
import { getCloudEntityData } from 'common/components/ProtectedAssets/AssetUtils';
import { SubAssetStyled } from './SubAssetStyled';
import { useTheme } from 'styled-components';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { InfoPaneFieldProps } from 'common/components/InfoPane/InfoPane';
import { ISubAssetDetailsProps } from 'common/components/AssetPropertiesPanel/AssetPropertiesPanel.interface';
import Evidences from '../../../Evidences/Evidences';
import { useIssue } from 'common/components/IssueContext/IssueContext';
import { ISelectSingleValueCellRenderer, SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { SelectV2 } from 'common/design-system/components-v2';
import { getAssetUrl } from '../../../../Issues.utils';
import EntityLink from 'common/components/ProtectedAssets/Renderers/EntityLink';

const prepareSubAssetOptions = (items: IProtectedAssetViewModel[]) =>
    items.map((subAsset: IProtectedAssetViewModel, index: number) => ({
        label: `${index + 1}. ${getFullEntityName(subAsset.name, subAsset.entityId, subAsset.platform)}`,
        value: subAsset.id,
        asset: subAsset,
    }));

export const SubAssetDetails: React.FC<ISubAssetDetailsProps> = ({
    selectedSubAsset,
    subAssets,
    onSelectionChange,
}) => {
    const [options, setOptions] = useState(prepareSubAssetOptions(subAssets));
    const { t } = useTranslation(I18nRiskNamespace);
    const theme = useTheme();
    const getItemCounter = (): number => options.findIndex((opt) => opt.asset.id === selectedSubAsset.id) + 1;
    const selectOptions: SelectOption[] = useMemo(
        () => options.map((option) => ({ value: option.value, label: option.label })),
        [options],
    );
    const selectedValue: string | undefined = useMemo(
        () => options.find((op) => op.value === selectedSubAsset?.id)?.value,
        [options, selectedSubAsset],
    );

    const onChange = (value: string) => {
        if (value) {
            const asset = options.find((op) => op.value === value)?.asset;
            if (asset) {
                onSelectionChange(asset);
                return;
            }
        }

        onSelectionChange(undefined);
    };

    const SingleWithLinkValueLink: ISelectSingleValueCellRenderer = ({
        selectedOption,
    }: {
        selectedOption: SelectOption;
    }) => (
        <SubAssetStyled.LinkWrapper>
            <EntityLink entityName={selectedOption.label} url={getAssetUrl(selectedSubAsset) || 'none'} />
        </SubAssetStyled.LinkWrapper>
    );

    const headerComponent = (
        <SubAssetStyled.Header>
            <SubAssetStyled.SelectWrapper>
                <SelectV2
                    options={selectOptions}
                    isMulti={false}
                    value={selectedValue}
                    onChange={onChange}
                    fullWidth
                    valueCellRenderer={SingleWithLinkValueLink}
                />
            </SubAssetStyled.SelectWrapper>
            <SubAssetStyled.IndexWrapper>
                <SubAssetStyled.Index
                    label={t('PROTECTED_ASSETS.SUB_ASSETS.SELECTED_ITEM_INDEX', {
                        index: getItemCounter(),
                        total: options.length,
                    })}
                    size='sm'
                    customColor={theme.palette.surface.brandLight}
                />
            </SubAssetStyled.IndexWrapper>
        </SubAssetStyled.Header>
    );

    const entityFields: IProtectedAssetDetailProvider<InfoPaneFieldProps>[] = useMemo(
        () => IssueViewerRegistry.getFields(ISSUE_ASSET_PANEL_KEY),
        [],
    );

    const getCloudEntity: () => Promise<ICloudEntityData | null> = useCallback(async () => {
        const { id, type, platform, cloudAccountId } = selectedSubAsset;
        return getCloudEntityData(id, type, platform, cloudAccountId);
    }, [selectedSubAsset]);

    useEffect(() => {
        setOptions(prepareSubAssetOptions(subAssets));
    }, [subAssets]);

    const { issue } = useIssue();

    return (
        <SubAssetStyled.ContentDiv>
            <AssetPropertiesPanel
                registeredEntityFields={entityFields}
                typeByPlatform={selectedSubAsset.typeByPlatform}
                getCloudEntity={getCloudEntity}
                headerComponent={headerComponent}
                title={t('PROTECTED_ASSETS.SUB_ASSETS.WORKLOADS')}
            >
                {issue ? (
                    <Evidences asset={selectedSubAsset} issue={issue} initiallyOpen={false} />
                ) : (
                    <SubAssetStyled.ErrorDiv>
                        {t('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.FAILED_LOADING_EVIDENCES')}
                    </SubAssetStyled.ErrorDiv>
                )}
            </AssetPropertiesPanel>
        </SubAssetStyled.ContentDiv>
    );
};
