import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { MultiChipCellContent } from 'common/erm-components/custom/MultiChipCellContent/MultiChipCellContent';

const NONE_VALUE = 'None';

const MultiChipCellRenderer: React.FC<ICellRendererParams> = (params) => {
    let labels: string[];
    if (params.data.isGrouped) {
        if (params.value === NONE_VALUE) {
            return params.value;
        }
        labels = [params.value];
    } else {
        labels = params.value;
    }

    return <MultiChipCellContent propsList={labels.map((label) => ({ label }))} />;
};

export default MultiChipCellRenderer;
