import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Icon, Stack, Tooltip, Typography } from 'common/design-system/components-v2';
import { getExternalAdditionalFieldValue } from 'common/module_interface/assets/utils';

const getInsertionStateIcon = (insertionState: string) => {
    switch (insertionState) {
        case 'Error':
            return 'statusError';
        case 'Warning':
            return 'statusWarning';
        case 'Finish':
            return 'statusSuccess';
        case 'InProgress':
            return 'inProgress';
        case 'WaitingForScanAdd':
        case 'WaitingForScanRemove':
        case 'WaitingForInsertionAdd':
        case 'WaitingForInsertionRemove':
            return 'waiting';
        default:
            return null;
    }
};

const InsertionStateCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const { t } = useTranslation();

    const insertionState = getExternalAdditionalFieldValue(params.data, 'InsertionState');
    const insertionStateIcon = insertionState && getInsertionStateIcon(insertionState);
    const insertionErrorReason = getExternalAdditionalFieldValue(params.data, 'InsertionErrorReason');

    // Convert camelCase to SNAKE_CASE
    const status = insertionState
        ? t(
              `SERVERLESS.PROTECTED_ASSETS.COLUMNS.SERVERLESS_INSERTION_STATE.STATUS.${insertionState?.replace(/([a-zA-Z])([A-Z])/g, '$1_$2').toUpperCase()}`,
          )
        : '';

    const tooltipMessage = t(
        `SERVERLESS.PROTECTED_ASSETS.COLUMNS.SERVERLESS_INSERTION_STATE.MESSAGES.${insertionErrorReason}`,
    );

    const content = (
        <Stack direction='row' alignItems='center' spacing={2}>
            {insertionStateIcon ? <Icon name={insertionStateIcon} size={14}></Icon> : null}
            <Typography>{status}</Typography>
        </Stack>
    );

    return (
        <Stack direction='row' alignItems='center' spacing={2}>
            {insertionErrorReason ? (
                <Tooltip content={tooltipMessage} placement='top-start'>
                    {content}
                </Tooltip>
            ) : (
                content
            )}
        </Stack>
    );
};

export default InsertionStateCellRenderer;
