import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'common/services/store/store';

interface SideBarState {
    isVisible: boolean;
    loadedComponentId: string;
}

const initialState: SideBarState = {
    isVisible: false,
    loadedComponentId: '',
};

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        setIsVisible: (state: SideBarState, action: PayloadAction<boolean>) => {
            state.isVisible = action.payload;
        },
        setLoadedComponentId: (state: SideBarState, action: PayloadAction<string>) => {
            state.loadedComponentId = action.payload;
        },
    },
});

export const { setIsVisible, setLoadedComponentId } = sidebarSlice.actions;

export const getSidebarIsVisible = (state: RootState) => state.sidebar.isVisible;

export const getLoadedComponentId = (state: RootState) => state.sidebar.loadedComponentId;
export default sidebarSlice.reducer;
