import {
    setApiUrl,
    setAuthServerUrl,
    setCloudInfraAuthenticateUrl,
    setCloudInfraUrl,
    setEnvironmentType,
    setHasApiConnectivityError,
    setMagellanUrl,
} from 'App.reducer';

import EnvironmentVariables from 'common/services/configuration/EnvironmentVariables';
import { getUrl } from 'common/utils/http';
import { getHttpService, getStoreService } from 'common/interface/services';
import { changeLanguage } from 'platform/main/Menus/Menus.reducer';
import { getAppLanguage } from 'Globalpersist.reducer';
import { handleDeprecatedCookies } from 'platform/user/User.actions';
import { getRuntimeEnvironmentType } from 'common/utils/RuntimeEnvironment';

//TODO:Refactor this not to be stored in state because these are literally consts.
async function StoreNonStateItemsInState(dispatch: (action: any) => void) {
    const environmentType = getRuntimeEnvironmentType();
    // eslint-disable-next-line
    // @ts-ignore
    const envVars = (await EnvironmentVariables.init()) as IAppUrl; //This needs to load in FETCH_INITIAL_SERVER_DATA path
    dispatch(setEnvironmentType(environmentType));
    dispatch(setApiUrl(getUrl(envVars.REACT_APP_BASE_HTTP_URL.split(','))));
    dispatch(setAuthServerUrl(getUrl(envVars.REACT_APP_AUTH_URL.split(','))));
    dispatch(setCloudInfraAuthenticateUrl(envVars.REACT_APP_CLOUD_INFRA_AUTHENTICATE_URL));
    dispatch(setCloudInfraUrl(envVars.REACT_APP_CLOUD_INFRA_URL));
    dispatch(setMagellanUrl(getUrl(envVars.REACT_APP_MAGELLAN_URL.split(','))));
}

export const initApp = async () => {
    handleDeprecatedCookies();

    const { state, dispatch } = getStoreService().getReduxTools();
    await StoreNonStateItemsInState(dispatch);
    const languageInStore = getAppLanguage(state);
    await changeLanguage(languageInStore);
    const apiConnectivity = await getHttpService().checkApiConnectivity();
    dispatch(setHasApiConnectivityError(!apiConnectivity));
};
