import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Stack } from 'common/design-system/components-v2';
import Flag from 'react-flagkit';
import { InternalCountryName } from 'common/module_interface/intelligence/Logs/Logs.consts';

const CountryCellRender: React.FC<ICellRendererParams> = (params) => {
    if (!params.data.sourceCountryCode || !params.data.sourceCountry) return null;

    if (params.data.sourceCountry === InternalCountryName || params.data.sourceCountryCode === InternalCountryName) {
        return params.data.sourceCountry;
    }

    return (
        <Stack direction={'row'} spacing={1}>
            <Flag country={params.data.sourceCountryCode} size={18} />
            {params.data.sourceCountry}
        </Stack>
    );
};

export default CountryCellRender;
