import { Vendors } from 'common/consts/vendors';
import AwpService from 'modules/workloads/services/awp/awp.service';
import { useEffect, useState } from 'react';

interface UseIsAwpEnabledProps {
    platform: Vendors;
    cloudAccountId: string;
}

interface UseIsAwpEnabledReturn {
    isAwpEnabled: boolean | null;
    loading: boolean;
}

export const useIsAwpEnabled = ({ platform, cloudAccountId }: UseIsAwpEnabledProps): UseIsAwpEnabledReturn => {
    const [isAwpEnabled, setIsAwpEnabled] = useState<boolean | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await AwpService.getWorkloadAgentessAccount(cloudAccountId, platform);
                setIsAwpEnabled(true);
            } catch (error: any) {
                if (error.response?.status === 404) {
                    setIsAwpEnabled(false);
                }
            }

            setLoading(false);
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isAwpEnabled,
        loading,
    };
};
