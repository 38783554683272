import i18n, { validLanguages } from 'common/services/translations/translations';

export const AzureEnvNamespacePrefix = 'azure-env';
export type AzureEnvTermTypes = 'azure';
const termsFolders: Array<AzureEnvTermTypes> = ['azure'];

export const getAzureEnvNamespace = (term: AzureEnvTermTypes) => `${AzureEnvNamespacePrefix}_${term}`;

const jsonFilesPaths = validLanguages.reduce<Array<{ path: string; language: string; term: AzureEnvTermTypes }>>(
    (acc, language) => {
        const filesPAth = termsFolders.map((term) => ({ path: `${language}/${term}`, language, term }));
        return [...acc, ...filesPAth];
    },
    [],
);

export const initializeAzureEnvI18n = async () => {
    await Promise.all(
        jsonFilesPaths.map(async (file) => {
            try {
                const contents = await require(`./languages/${file.path}.json`);
                i18n.addResourceBundle(file.language, getAzureEnvNamespace(file.term), contents);
                // eslint-disable-next-line
            } catch (error) {}
        }),
    );
};
