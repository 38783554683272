import {
    getCloudAccountsService,
    getOrganizationalUnitService,
    ICloudAccount,
    IOrganizationalUnit,
} from 'common/interface/data_services';
import { Rule } from 'common/interface/RulesManagement';
import { getService } from 'common/extensibility/AddinContainer';
import RuleManageService from '../RulesManagement/services/RuleManagement.service';
import { getHttpService, RULES_MANAGEMENT_SERVICE_ID } from 'common/interface/services';
import { IAssessmentData, IAssessmentStatistics, IAssessmentTable, IFilters } from './AssessmentInterfaces';
import i18n from 'i18next';
import dayjs from 'dayjs';

const rootOUPath = '00000000-0000-0000-0000-000000000000';
let rulesets: Rule[];
const abortControllerForRunAssessments = new AbortController();
const signalForRunAssessments = abortControllerForRunAssessments.signal;
const getAssessmentStatistics = async (rulesetIds: number[], useCache = true): Promise<IAssessmentStatistics[]> => {
    return await getHttpService().request<any>(
        'AssessmentHistoryV2/OrganizationalUnitsLastAssessmentStatistics',
        { method: 'POST', data: { organizationalUnitIds: [rootOUPath], bundleIds: rulesetIds } },
        { cachingConfig: { useCache: useCache } },
    );
};
const getAllRulesets = async () => {
    rulesets =
        rulesets ||
        (await getService<RuleManageService>(RULES_MANAGEMENT_SERVICE_ID).getRulesetsViewsFromComplianceServer());
    const enRulesets = rulesets.filter((ruleset) => ruleset.language === 'en');
    if (i18n.language !== 'en') {
        rulesets = [...enRulesets, ...rulesets.filter((ruleset) => ruleset.language === i18n.language)];
    } else {
        rulesets = enRulesets;
    }
    return rulesets;
};

export const getAssessmentStatisticsForAllRulesets = async (): Promise<IAssessmentData> => {
    const rulesets = await getAllRulesets();
    const rulesetIds = rulesets.map((ruleset) => ruleset.id);
    return getAssessmentData({ rulesetIds: rulesetIds } as IFilters);
};

export const getRunAssessmentByRuleAndCloudAccount = async (
    ruleId: number,
    cloudAccountId: string,
    cloudAccountType: number,
    description: string,
    requestId: string,
    ruleName?: string,
): Promise<any> => {
    return await getHttpService().request<any>('assessment/bundleV2', {
        method: 'POST',
        data: {
            CloudAccountId: cloudAccountId,
            CloudAccountType: cloudAccountType,
            Id: ruleId,
            description: description,
            name: ruleName,
            requestId: requestId,
            shouldMinimizeResult: true,
        },
        signal: signalForRunAssessments,
    });
};

export const abortAllRunAssessments = () => {
    return abortControllerForRunAssessments.abort();
};

export const getAssessmentTrend = async (bundleId: number, cloudAccountId: string): Promise<any> => {
    const fromDate = dayjs().subtract(3, 'month').toISOString();
    const toDate = dayjs().set('seconds', 0).set('milliseconds', 0).toISOString();
    return await getHttpService().request<any>(
        `AssessmentHistoryV2/assessmentTrendV2?bundleId=${bundleId}&cloudAccountId=${cloudAccountId}&from=${fromDate}&to=${toDate}`,
        { method: 'GET' },
    );
};

export async function getRootOU() {
    const ouTree = await getOrganizationalUnitService().getOrganizationalUnitsView();
    return {
        path: ouTree.path,
        id: ouTree.id,
        name: ouTree.name,
    };
}

export const getAssessmentData = async (filters: IFilters): Promise<IAssessmentData> => {
    function normalizeOUPath(assessmentsStatistic: IAssessmentStatistics, flatOUs: IOrganizationalUnit[]) {
        let ouPaths: any[] = [];
        if (assessmentsStatistic.organizationalUnitPaths[0] === '') {
            ouPaths = [rootOU.name];
        } else {
            ouPaths = [rootOU.name, ...assessmentsStatistic.organizationalUnitPaths[0].split('.')];
        }

        return ouPaths
            .map((ou: any) => flatOUs.find((fou) => fou.id === ou || fou.name === ou))
            .map((o: any) => (o ? o.name : ''));
    }
    const rootOU = await getRootOU();
    let flatOUs: IOrganizationalUnit[] = await getOrganizationalUnitService().getAllOrganizationalUnitsFlat();
    flatOUs.push(rootOU);

    const rulesets: Rule[] = await getAllRulesets();
    let cloudAccounts: ICloudAccount[] = await getCloudAccountsService().getAllCloudAccounts();
    if (filters.cloudAccountIds?.length) {
        cloudAccounts = cloudAccounts.filter((ca: any) => filters.cloudAccountIds?.includes(ca.id));
        const parentOUs: any[] = [];
        const filteredFlatOUs = flatOUs.filter((ou: any) =>
            cloudAccounts?.find((ca) => {
                return ou.id === ca.organizationalUnitId;
            }),
        );
        filteredFlatOUs.forEach((filteredFlatOU: any) => {
            const parentOU = flatOUs.find((fou: any) => fou.id === filteredFlatOU.parentId);
            parentOU && parentOUs.push(parentOU);
        });

        flatOUs = [...filteredFlatOUs, ...parentOUs, rootOU];
    }
    if (filters.platforms?.length) {
        cloudAccounts = cloudAccounts.filter((ca: any) => filters.platforms?.includes(ca.platform));
        filters.rulesetIds = rulesets
            .filter((ruleset: any) => filters?.platforms?.includes(ruleset.cloudVendor))
            .sort((a, b) => (a?.rulesCount! < b?.rulesCount! ? 1 : -1))
            .map((r) => r.id)
            .slice(0, 10);
    }
    if (filters.organizationalUnitIds?.length) {
        const parentOUs: any[] = [];
        const filteredFlatOUs = flatOUs.filter((ou: any) =>
            filters.organizationalUnitIds?.find((ouId) => {
                return ou.parentId === rootOU.id || ouId === ou.id || ou.path.includes(ouId);
            }),
        );
        filteredFlatOUs.forEach((filteredFlatOU: any) => {
            const parentOU = flatOUs.find((fou: any) => fou.id === filteredFlatOU.parentId);
            parentOU && parentOUs.push(parentOU);
        });

        flatOUs = [...parentOUs, ...filteredFlatOUs, rootOU];
    }
    const assessmentsStatistics: IAssessmentStatistics[] = await getAssessmentStatistics(filters.rulesetIds!);
    const assessmentTable = assessmentsStatistics?.map((assessmentsStatistic) => {
        const ruleset = rulesets.find((ruleset) => ruleset.id === assessmentsStatistic.bundleId);
        const ouPaths = normalizeOUPath(assessmentsStatistic, flatOUs);
        const currentOU = ouPaths[ouPaths.length - 1];
        const cloudAccount = cloudAccounts.find(
            (cloudAccount) => cloudAccount.id === assessmentsStatistic.cloudAccountId,
        );
        if (
            filters.organizationalUnitIds?.includes(cloudAccount?.organizationalUnitId || '') ||
            filters.cloudAccountIds?.includes(cloudAccount?.id || '') ||
            filters.platforms?.includes(cloudAccount?.platform || '')
        ) {
            ouPaths.push(cloudAccount?.name);
        }
        if (cloudAccount && !ouPaths.includes(cloudAccount?.name)) {
            ouPaths.push(cloudAccount?.name);
        }
        if (currentOU && ruleset) {
            return {
                rulesetId: assessmentsStatistic.bundleId,
                rulesetDisplayName: ruleset.name,
                ouPath: ouPaths,
                ouId: currentOU.id,
                ouDisplayName: currentOU.name,
                cloudAccountId: assessmentsStatistic.cloudAccountId,
                cloudAccountName: cloudAccount?.name,
                resultDate: assessmentsStatistic.resultDate,
                triggeredBy: assessmentsStatistic.triggeredBy,
                rulesetName: ruleset.name,
                cloudVendor: ruleset.cloudVendor,
                statistics: assessmentsStatistic.statistics,
            } as IAssessmentTable;
        } else {
            return null;
        }
    });
    return { rulesets: rulesets, assessmentsStatistics: assessmentTable.filter((item) => item) };
};
