import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ShiftLeftState, ShiftLeftInitialState, ShiftLeft } from './shiftLeft.interface';

const ShiftLeftSlice = createSlice({
    name: 'k8s_shiftLeft',
    initialState: ShiftLeftInitialState,
    reducers: {
        setLoading: (state: ShiftLeftState, action: PayloadAction<{ shiftLeftId: string; loading: boolean }>) => {
            const { shiftLeftId, loading } = action.payload;
            if (!state.shiftLeftEvnironments[shiftLeftId]) {
                state.shiftLeftEvnironments[shiftLeftId] = {};
            }
            state.shiftLeftEvnironments[shiftLeftId].loading = loading;
        },
        setError: (state: ShiftLeftState, action: PayloadAction<{ shiftLeftId: string; error: boolean }>) => {
            const { shiftLeftId, error } = action.payload;
            if (!state.shiftLeftEvnironments[shiftLeftId]) {
                state.shiftLeftEvnironments[shiftLeftId] = {};
            }
            state.shiftLeftEvnironments[shiftLeftId].error = error;
        },
        setData: (state: ShiftLeftState, action: PayloadAction<{ shiftLeftId: string; data: ShiftLeft }>) => {
            const { shiftLeftId, data } = action.payload;
            if (!state.shiftLeftEvnironments[shiftLeftId]) {
                state.shiftLeftEvnironments[shiftLeftId] = {};
            }
            state.shiftLeftEvnironments[shiftLeftId].data = data;
        },
        setDeleted: (state: ShiftLeftState, action: PayloadAction<{ shiftLeftId: string; deleted: boolean }>) => {
            const { shiftLeftId } = action.payload;
            if (!state.shiftLeftEvnironments[shiftLeftId]) {
                state.shiftLeftEvnironments[shiftLeftId] = { deleted: true };
            }
            state.shiftLeftEvnironments[shiftLeftId].deleted = true;
        },
    },
});

export default ShiftLeftSlice;
