import { EventsTableColDef } from 'common/interface/events';
import { IEvent } from 'common/module_interface/events/Events';
import { EventsTableRegistry } from 'common/module_interface/events/EventsTableRegistry';
import i18n from 'common/services/translations/translations';
import { getEventsTableNamespace } from '../initialize.i18n';
import { formatDateAndTime } from 'common/utils/dateUtils';
import RemediationCellRenderer from '../CellRenderers/RemediationCellRenderer';
import TitleCellRenderer from '../CellRenderers/TitleCellRenderer';
import { fixOriginName } from 'modules/events/helpers/fixOriginName';
import { EnvironmentName } from '../CellRenderers/EnvironmentName';
import SeverityCellRenderer from '../CellRenderers/SeverityCellRenderer';
import { Platform } from '../CellRenderers/Platform';
import { getAssetByEntityTypeByEnvironmentType } from 'common/utils/filterUtils';
import { LabelsCellRenderer } from '../CellRenderers/LabelsCellRenderer';

const columnDefs: () => EventsTableColDef<IEvent>[] = () => [
    {
        id: 'checkbox',
        colId: 'checkbox',
        headerName: '',
        headerCheckboxSelection: false,
        checkboxSelection: true,
        maxWidth: 40,
        minWidth: 40,
        resizable: false,
    },
    {
        id: 'ruleName',
        colId: 'ruleName',
        field: 'ruleName',
        headerName: i18n.t('TABLE_HEADERS.TITLE', { ns: getEventsTableNamespace('table') }),
        sortable: true,
        enableRowGroup: true,
        cellRenderer: TitleCellRenderer,
    },
    {
        id: 'severity',
        colId: 'severity',
        field: 'severity',
        headerName: i18n.t('TABLE_HEADERS.SEVERITY', { ns: getEventsTableNamespace('table') }),
        sortable: true,
        enableRowGroup: true,
        cellRenderer: SeverityCellRenderer,
    },
    {
        id: 'entityName',
        colId: 'entityName',
        field: 'entityName',
        headerName: i18n.t('TABLE_HEADERS.ENTITY', { ns: getEventsTableNamespace('table') }),
        sortable: true,
        enableRowGroup: true,
        valueFormatter: (params) => params.value || params.data?.entityExternalId,
    },
    {
        id: 'createdTime',
        colId: 'createdTime',
        field: 'createdTime',
        headerName: i18n.t('TABLE_HEADERS.CREATED_TIME', { ns: getEventsTableNamespace('table') }),
        sortable: true,
        valueFormatter: (params) => formatDateAndTime(params.value),
    },
    {
        id: 'cloudAccountId',
        colId: 'cloudAccountId',
        field: 'cloudAccountId',
        headerName: i18n.t('TABLE_HEADERS.ENVIRONMENT', { ns: getEventsTableNamespace('table') }),
        cellRenderer: EnvironmentName,
        enableRowGroup: true,
    },
    {
        id: 'cloudAccountType',
        colId: 'cloudAccountType',
        field: 'cloudAccountType',
        headerName: i18n.t('TABLE_HEADERS.PLATFORM', { ns: getEventsTableNamespace('table') }),
        enableRowGroup: true,
        sortable: true,
        cellRenderer: Platform,
    },
    {
        id: 'origin',
        colId: 'origin',
        field: 'origin',
        headerName: i18n.t('TABLE_HEADERS.SOURCE', { ns: getEventsTableNamespace('table') }),
        sortable: true,
        enableRowGroup: true,
        valueFormatter: (params) => fixOriginName(params.value),
    },
    {
        id: 'remediation',
        colId: 'remediation',
        field: 'remediation',
        headerName: i18n.t('TABLE_HEADERS.REMEDIATION', { ns: getEventsTableNamespace('table') }),
        cellRenderer: RemediationCellRenderer,
        width: 120,
        maxWidth: 120,
        minWidth: 120,
    },
    {
        id: 'bundleName',
        colId: 'bundleName',
        field: 'bundleName',
        headerName: i18n.t('TABLE_HEADERS.RULESET_NAME', { ns: getEventsTableNamespace('table') }),
        sortable: true,
        enableRowGroup: true,
    },
    {
        id: 'description',
        colId: 'description',
        field: 'description',
        headerName: i18n.t('TABLE_HEADERS.DESCRIPTION', { ns: getEventsTableNamespace('table') }),
    },
    {
        id: 'entityTypeByEnvironmentType',
        colId: 'entityTypeByEnvironmentType',
        field: 'entityTypeByEnvironmentType',
        headerName: i18n.t('TABLE_HEADERS.ENTITY_TYPE', { ns: getEventsTableNamespace('table') }),
        sortable: true,
        enableRowGroup: true,
        valueFormatter: (params) => {
            const asset = getAssetByEntityTypeByEnvironmentType(params.value);
            if (!asset) return params.value;
            return asset.displayName;
        },
    },
    {
        id: 'ownerUserName',
        colId: 'ownerUserName',
        field: 'ownerUserName',
        headerName: i18n.t('TABLE_HEADERS.ASSIGNEE', { ns: getEventsTableNamespace('table') }),
        sortable: false,
        enableRowGroup: true,
    },
    {
        id: 'category',
        colId: 'category',
        field: 'category',
        headerName: i18n.t('TABLE_HEADERS.CATEGORY', { ns: getEventsTableNamespace('table') }),
        sortable: true,
        enableRowGroup: true,
    },
    {
        id: 'action',
        colId: 'action',
        field: 'action',
        headerName: i18n.t('TABLE_HEADERS.ACTION', { ns: getEventsTableNamespace('table') }),
        sortable: true,
        enableRowGroup: true,
    },
    {
        id: 'labels',
        colId: 'labels',
        field: 'labels',
        headerName: i18n.t('TABLE_HEADERS.LABELS', { ns: getEventsTableNamespace('table') }),
        sortable: true,
        enableRowGroup: true,
        cellRenderer: LabelsCellRenderer,
    },
];

export default function initializeColumnDefs() {
    EventsTableRegistry.addColumnDefs(columnDefs());
}
