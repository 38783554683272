import { IconProps } from 'common/design-system/components-v2/Icon/Icon.types';
import { PivotTypeEnum } from './ToxicGraphPage/LeftPanel/QueryBox/PivotType';
import { ICustomTreeNode } from 'common/erm-components/custom/CustomTree/CustomTree.interface';
import { ToxicCondOpModelEnum, ToxicPropTypeEnum } from './ToxicGraphModel/ToxicGraphModel.consts';
import { IFilterTreeFieldDefinition } from 'common/erm-components/custom/FilterTree/FilterTree.interface';
import { ICompoundFilter } from 'common/erm-components/custom/FilterTree/CompoundFilter';
import { IToxicGraphNodeModel, IToxicSrlAdditionalDataModel } from './ToxicGraphModel/ToxicGraphModel.interface';

export enum IToxicGraphQueryPivotTypeEnum {
    pivot = 'GraphMainEntity',
    group = 'GraphMainEntityGroup',
}

export interface IQueryNodeData {
    id: string;
    relation?: string;
    entityType: string;
    filterText?: string;
    compoundFilter?: ICompoundFilter;
    optional?: boolean;
    errorInfo?: IToxicValidationErrorInfo;
    pivotType: PivotTypeEnum;
}

export interface IQueryTreeNodeActions {
    onPivotTypeChange: (nodeId: string, pivotType: PivotTypeEnum) => void;
    edit: (nodeId: string) => void;
    add: (parentNodeId: string) => void;
    remove: (nodeId: string) => void;
}

export interface IQueryTreeUserSettings {
    showFilters: boolean;
    actions: IQueryTreeNodeActions;
    onPivotTypeChange: (nodeId: string, pivotType: PivotTypeEnum) => void;
    onEditNode: (nodeId: string) => void;
    onToggleOptional: (nodeId: string) => void;
}

export interface IQueryNodeEditorData {
    parentNode?: ICustomTreeNode<IQueryNodeData>;
    editedNode?: ICustomTreeNode<IQueryNodeData>;
    onSubmit: (data: IQueryNodeData) => void;
}

// export interface IQueryAddingTop {
//     oldRoot?: ICustomTreeNode<IQueryNodeData>;
//     onSubmit: (oldRootEntityType: IQueryNodeData, newRootData: IQueryNodeData) => void;
// }

export type IRelationToTypesInfosMap = { [key: string]: string[] };

export interface IRelationInfo {
    name: string;
    parentToChildText: string;
    childToParentText: string;
}

export interface IPropertyInfo {
    name: string;
    displayName: string;
    type: ToxicPropTypeEnum;
    operators: ToxicCondOpModelEnum[];
    options?: string[];
}

export interface IConnectionPairInfo {
    parentType: string;
    relationType: string;
    isParentToChildRelation: boolean;
    possibleChildTypes: string[];
}
export type IEntityPropertiesMap = { [key: string]: IPropertyInfo };
export type IConnectionPairsMap = { [key: string]: IConnectionPairInfo };

export interface IEntityTypeInfo {
    name: string;
    nativeTypes: string[];
    isEnvironmentRelated: boolean;
    isGroupEntity: boolean;
    incomingRelationsMap: IRelationToTypesInfosMap;
    outgoingRelationsMap: IRelationToTypesInfosMap;
    relevantRelations: string[];
    propertiesMap: IEntityPropertiesMap;
    iconProps?: IconProps;
    filterDefs: IFilterTreeFieldDefinition[];
}

export interface IToxicGraphSchema {
    entityTypesMap: { [key: string]: IEntityTypeInfo };
    relationsMap: { [key: string]: IRelationInfo };
    operatorsMap: { [key: string]: string };
    typesMap: { [key: string]: string };
    connectionPairsMap: IConnectionPairsMap;
}

export enum ToxicErrorEnum {
    requiredRelation = 'Relation is required',
    requiredEntityType = 'Entity type is required',
    invalidRelation = 'Relation is invalid',
    invalidEntityType = 'Entity type is invalid',
}

export interface IToxicValidationErrorInfo {
    errorType: ToxicErrorEnum;
    description?: string;
}

export interface IToxicPivotItem {
    srl: string;
    displayName: string;
    entityLabel: string;
    requestId: string;
    entityIdentifier: string;
    iconProps?: IconProps;
    additionalData?: IToxicSrlAdditionalDataModel;
}

export type IToxicPropsMap = { [key: string]: IToxicGraphProp };

export interface IToxicGraphProp {
    name: string;
    displayName: string;
    displayValue: string;
    value: any;
    isVaries?: boolean;
}

export interface IToxicGraphBaseNode {
    id: string;
    entityType: string;
    name: string;
    environmentId?: string;
    props: IToxicGraphProp[];
    additionalProps?: IToxicGraphProp[];
    isContainer: boolean;
    iconProps?: IconProps;
    isPivot: boolean;
    pivot: IToxicPivotItem;
}

export interface IToxicGraphSimpleNode extends IToxicGraphBaseNode {
    isContainer: false;
    containerGroupId?: string | null;
    modelNode: IToxicGraphNodeModel;
}

export interface IToxicGraphContainerNode extends IToxicGraphBaseNode {
    isContainer: true;
    groupId: string;
    members: IToxicGraphSimpleNode[];
}

export type IToxicGraphNode<T extends IToxicGraphSimpleNode | IToxicGraphContainerNode = any> = T extends {
    isContainer: true;
}
    ? IToxicGraphContainerNode
    : IToxicGraphSimpleNode;

export interface IToxicGraphNodeContext {
    nodeData: IToxicGraphNode;
    subGraph: IToxicSubGraph;
    api: IToxicGraphApi;
}

export interface IToxicGraphRelationContext {
    relationData: IToxicGraphRelation;
    subGraph: IToxicSubGraph;
    api: IToxicGraphApi;
}

export interface IToxicGraphRelation {
    id: string;
    fromId: string;
    toId: string;
    relation: string;
    pivot: IToxicPivotItem;
    displayName: string;
}

export interface IToxicSubGraph {
    pivot: IToxicPivotItem;
    nodes: IToxicGraphNode[];
    relations: IToxicGraphRelation[];
    origNodes: IToxicGraphSimpleNode[];
    origRelations: IToxicGraphRelation[];
    expandedGroupIds: string[];
    selectedNodeId?: string;
    secondarySelectedNodesIdsSet: Set<string>;
    secondarySelectedRelationsIdsSet: Set<string>;
    allGroupIds: string[];
}

export interface IToxicGraphApi {
    expandGroup: (groupId: string) => void;
    collapseGroup: (groupId: string) => void;
}
