import { MENU_ITEMS } from 'common/extensibility/WellKnownPaths';
import { addMainMenuItem } from 'common/helpers/menu';
import { PROTECTED_ASSETS_SERVICE_ID } from 'common/module_interface/assets/ProtectedAssets';
import { ProtectedAssetsService } from './ProtectedAssetsService';
import initializeProtectedAssets from './ProtectedAssets/initialize';
import { buildPath, globalAddinContainer } from 'common/extensibility/AddinContainer';
import { IReactPageRoute } from 'common/interface/routing';
import { UserRolesTypes } from 'common/enum/UserRoles';
import { ActiveFeaturesTypes } from 'common/enum/ActiveFeatures';
import { PATH_ENVIRONMENTS_GET_STARTED } from 'common/module_interface/assets/GetStartedPage';
import { Addin } from 'common/extensibility/AddinRegistry';
import i18n from 'i18next';
import { IMenuSectionItem } from 'common/interface/menu';
import { initializeProtectedAssetsWidgets } from './Widgets/initializeProtectedAssetsWidgets';
import { initializeEnvironmentsWidgets } from './Widgets/initializeEnvironmentsWidgets';
import { getAppRootRegistry, getHttpService } from 'common/interface/services';
import { getProdEnvironmentBaseUrl } from 'common/utils/http';
import { initializeAwsEnv } from './Environments/AwsEnvironment/initialize';
import { initializeAzureEnv } from './Environments/AzureEnvironment/initialize';
import { initializeEnvironments } from './Environments/EnvironmentsTable/initialize';
import { MAIN_MENU_ITEM_ID, MAIN_SECTION_ID } from 'common/module_interface/assets/ProtectedAssets.consts';
import { Pages } from 'common/enum/Pages';
import { Asset, getTypeByPlatform, IAsset } from 'common/assets/common.assets';
import { setAssets } from 'common/assets/Assets.service';
import { assets as staticAssets, defaultVendorAssets } from 'common/assets/assets';
import { isLocalhost } from 'common/utils/RuntimeEnvironment';
import { initializeGcpEnv } from './Environments/GcpEnvironment/initialize';
import { initializeAlibabaEnv } from './Environments/AlibabaEnvironment/initialize';
import { initializeOciEnv } from './Environments/OciEnvironment/initialize';
import { lazy } from 'react';

function initializePages() {
    const getStartedPageRoute = () => {
        return {
            condition: true,
            component: lazy(() => import('./Environments/GetStarted/GetStarted')),
            path: `/${Pages.CloudOnboarding}`,
        } as IReactPageRoute;
    };

    const awsEnvironmentRoute = () => {
        return {
            condition: true,
            component: lazy(() => import('./Environments/AwsEnvironment/AwsEnvironment')),
            path: '/cloud-account/aws/:id',
        };
    };

    const azureEnvironmentRoute = () => {
        return {
            condition: true,
            component: lazy(() => import('./Environments/AzureEnvironment/AzureEnvironment')),
            path: '/cloud-account/azure/:id',
        };
    };

    const gcpEnvironmentRoute = () => {
        return {
            condition: true,
            component: lazy(() => import('./Environments/GcpEnvironment/GcpEnvironment')),
            path: '/cloud-account/gcp/:id',
        };
    };

    const alibabaEnvironmentRoute = () => {
        return {
            condition: true,
            component: lazy(() => import('./Environments/AlibabaEnvironment/AlibabaEnvironment')),
            path: '/cloud-account/alibaba/:id',
        };
    };

    const ociEnvironmentRoute = () => {
        return {
            condition: true,
            component: lazy(() => import('./Environments/OciEnvironment/OciEnvironment')),
            path: '/cloud-account/oci/:id',
        };
    };

    const environmentsRoute = () => {
        return {
            condition: true,
            component: lazy(() => import('./Environments/EnvironmentsTable/Environments')),
            path: ['/cloud-account', '/cloud-account/index'],
            exact: true,
        };
    };

    const ousRoute = () => {
        return {
            condition: true,
            component: lazy(() => import('./Ous')),
            path: ['/organizational-units'],
            exact: true,
        };
    };

    getAppRootRegistry().addRoutes([
        { id: 'Get Started Page', content: getStartedPageRoute },
        { id: 'Aws Environment Page', content: awsEnvironmentRoute },
        { id: 'Azure Environment Page', content: azureEnvironmentRoute },
        { id: 'GCP Environment Page', content: gcpEnvironmentRoute },
        { id: 'ALIBABA Environment Page', content: alibabaEnvironmentRoute },
        { id: 'OCI Environment Page', content: ociEnvironmentRoute },
        { id: 'Environments Page', content: environmentsRoute },
        { id: 'Ous Page', content: ousRoute },
    ]);
}

function initializeMenu() {
    addMainMenuItem({
        id: MAIN_MENU_ITEM_ID,
        position: 30,
        icon: 'assets',
        label: 'NAVIGATION_MENU.ASSETS.TITLE',
        permission: [UserRolesTypes.ALL],
        sections: [
            {
                id: MAIN_SECTION_ID,
                position: 10,
                items: [
                    {
                        id: 'environments',
                        position: 10,
                        label: 'NAVIGATION_MENU.ASSETS.ENVIRONMENTS',
                        state: '/cloud-account',
                        urlAliases: [
                            /^\/cloud-account\/.+/,
                            '/aws',
                            /^\/cloud-add\/.+/,
                            '/protection-mode',
                            '/configuration',
                            '/operation-mode',
                            `/${Pages.CloudOnboarding}`,
                            '/oci-onboarding',
                            '/azure-onboarding',
                            '/gcp-onboarding',
                            '/magellan/onboarding-azure',
                            /^\/cloud-update\/agentless\/.+/,
                        ],
                        permission: [UserRolesTypes.ALL],
                    },

                    {
                        id: 'organizational units',
                        position: 30,
                        label: 'NAVIGATION_MENU.SETTINGS.ORGANIZATIONAL_UNITS',
                        state: '/organizational-units',
                        permission: [UserRolesTypes.ALL],
                    },
                    {
                        id: 'resources',
                        position: 40,
                        label: 'NAVIGATION_MENU.ASSETS.CUSTOM_RESOURCES',
                        state: '/lists',
                        urlAliases: [
                            '/lists',
                            '/lists/index',
                            /^\/lists\/generic\/.+/,
                            '/lists/ip',
                            /^\/lists\/managed\/.+/,
                        ],
                        permission: [UserRolesTypes.ALL],
                    },
                    {
                        id: 'agents',
                        position: 50,
                        label: 'NAVIGATION_MENU.ASSET_MANAGEMENT.AGENTS',
                        state: '/protected-asset/agent',
                        permission: [ActiveFeaturesTypes.ALLOW_ANY_AGENT_FEATURES],
                    },
                    {
                        id: 'install agent',
                        position: 60,
                        label: 'NAVIGATION_MENU.ASSET_MANAGEMENT.INSTALL_AGENT',

                        state: '/cloud-add/agent',
                        permission: [ActiveFeaturesTypes.ALLOW_ANY_AGENT_FEATURES],
                    },
                ],
            },
        ],
    });

    globalAddinContainer.add(buildPath(MENU_ITEMS, MAIN_MENU_ITEM_ID, MAIN_SECTION_ID), [
        {
            id: 'protected assets',
            position: 20,
            content: function () {
                const menuItem: IMenuSectionItem = {
                    position: 20,
                    id: 'NAVIGATION_MENU.ASSET_MANAGEMENT.PROTECTED_ASSETS',
                    label: 'NAVIGATION_MENU.ASSET_MANAGEMENT.PROTECTED_ASSETS',
                    isReact: true,
                    permission: [UserRolesTypes.ALL],
                    state: '/protected-asset',
                    urlAliases: [
                        '/protected-asset',
                        '/protected-asset/generic',
                        '/protected-asset/index',
                        '/protected-asset/details',
                    ],
                };
                return menuItem;
            },
        },
    ]);
}

const initializeGetStarted = () => {
    const aws: Addin = {
        id: i18n.t('GET_STARTED.AWS.VENDOR_KEY'),
        position: 0,
        content: {
            vendor: i18n.t('GET_STARTED.AWS.VENDOR_KEY'),
            linkTo: i18n.t('GET_STARTED.AWS.LINK_TO'),
            isReact: false,
            title: i18n.t('GET_STARTED.AWS.TITLE'),
        },
    };
    const azure: Addin = {
        id: i18n.t('GET_STARTED.AZURE.VENDOR_KEY'),
        position: 1,
        content: {
            vendor: i18n.t('GET_STARTED.AZURE.VENDOR_KEY'),
            linkTo: i18n.t('GET_STARTED.AZURE.LINK_TO'),
            isReact: false,
            title: i18n.t('GET_STARTED.AZURE.TITLE'),
        },
    };
    const gcp: Addin = {
        id: i18n.t('GET_STARTED.GCP.VENDOR_KEY'),
        position: 2,
        content: {
            vendor: i18n.t('GET_STARTED.GCP.VENDOR_KEY'),
            linkTo: i18n.t('GET_STARTED.GCP.LINK_TO'),
            isReact: false,
            title: i18n.t('GET_STARTED.GCP.TITLE'),
        },
    };
    const alibaba: Addin = {
        id: i18n.t('GET_STARTED.ALIBABA.VENDOR_KEY'),
        position: 3,
        content: {
            vendor: i18n.t('GET_STARTED.ALIBABA.VENDOR_KEY'),
            linkTo: i18n.t('GET_STARTED.ALIBABA.LINK_TO'),
            isReact: false,
            title: i18n.t('GET_STARTED.ALIBABA.TITLE'),
        },
    };
    const oci: Addin = {
        id: i18n.t('GET_STARTED.OCI.VENDOR_KEY'),
        position: 3,
        content: {
            vendor: i18n.t('GET_STARTED.OCI.VENDOR_KEY'),
            linkTo: i18n.t('GET_STARTED.OCI.LINK_TO'),
            isReact: false,
            title: i18n.t('GET_STARTED.OCI.TITLE'),
        },
    };
    globalAddinContainer.add(PATH_ENVIRONMENTS_GET_STARTED, [aws, azure, gcp, alibaba, oci]);
};
interface IMappedAsset {
    category: string;
    path: string;
    platform: string;
    type: string;
    displayName: string;
    isPreviewEntity: boolean;
}
export default function initialize() {
    const initAssetsList = () => {
        const baseUrl = isLocalhost ? getProdEnvironmentBaseUrl() : window.location.origin + '/v2/';
        const mapUrl = baseUrl + 'app-assets/vendor-icons/map.json';
        getAppRootRegistry().addAfterLoginEvents([
            {
                id: 'assets-list',
                content: async () => {
                    try {
                        const assetsList = await getHttpService().request<
                            { ruleEntityTypeName: string; cloudType: string }[]
                        >('RulesEngine', { method: 'GET' }, { cachingConfig: { useCache: true } });
                        const mapList = await getHttpService().request<IMappedAsset[]>(
                            mapUrl,
                            { withCredentials: false, method: 'GET' },
                            { cachingConfig: { useCache: true } },
                        );
                        const assets: IAsset[] = assetsList
                            ?.map((asset) => {
                                asset.cloudType = asset.cloudType === 'google' ? 'gcp' : asset.cloudType;
                                const staticAsset = staticAssets.find(
                                    (_asset) =>
                                        _asset.type === asset.ruleEntityTypeName && _asset.platform === asset.cloudType,
                                );
                                const mappedObject = mapList.find((map) => {
                                    return map.type === asset.ruleEntityTypeName && map.platform === asset.cloudType;
                                });
                                const icon = baseUrl + 'app-assets/vendor-icons/' + mappedObject?.path || '';
                                if (staticAsset && mappedObject) {
                                    return Object.assign(staticAsset, {
                                        icon: icon,
                                        displayName: mappedObject.displayName,
                                        category: mappedObject.category,
                                        isPreviewEntity: mappedObject.isPreviewEntity,
                                    }) as IAsset;
                                } else {
                                    if (mappedObject) {
                                        const _asset = {
                                            type: asset.ruleEntityTypeName,
                                            displayName: mappedObject?.displayName || '',
                                            platform: mappedObject?.platform || '',
                                            icon: icon,
                                            isGenericObject: true,
                                            typeByPlatform: getTypeByPlatform(
                                                mappedObject?.platform || '',
                                                asset.ruleEntityTypeName,
                                            ),
                                        };
                                        return new Asset(_asset);
                                    } else {
                                        return null;
                                    }
                                }
                            })
                            .filter((asset) => asset) as IAsset[];
                        assets.push(...defaultVendorAssets);
                        setAssets(assets);
                    } catch (e) {
                        console.error(e);
                    }
                },
            },
        ]);
    };

    initializePages();
    initializeMenu();
    initializeProtectedAssets();
    initializeGetStarted();
    initializeProtectedAssetsWidgets();
    initializeEnvironmentsWidgets();
    initializeAlibabaEnv();
    initializeOciEnv();
    initializeGcpEnv();
    initializeAzureEnv();
    initializeAwsEnv();
    initializeEnvironments();

    initAssetsList();

    globalAddinContainer.addService(PROTECTED_ASSETS_SERVICE_ID, new ProtectedAssetsService());
}
