import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { IPermissionInfo } from 'common/module_interface/identity/IdenticalRoles.interfaces';
import { identityTrans } from '../../initialize.i18n';

export const getColumnDefs = (): ColDef<IPermissionInfo>[] => {
    return [
        {
            field: 'resource',
            headerName: identityTrans('PERMISSIONS_TABLE.COLUMNS.RESOURCE.HEADER'),
            headerTooltip: identityTrans('PERMISSIONS_TABLE.COLUMNS.RESOURCE.TOOLTIP'),
            flex: 1,
            sortable: true,
            minWidth: 100,
        },
        {
            field: 'action',
            headerName: identityTrans('PERMISSIONS_TABLE.COLUMNS.PERMISSION.HEADER'),
            headerTooltip: identityTrans('PERMISSIONS_TABLE.COLUMNS.PERMISSION.TOOLTIP'),
            flex: 1,
            sortable: true,
            minWidth: 100,
        },
    ];
};
