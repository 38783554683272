import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

export const ImageCardWrapper = styled(Stack)<{ isSelectedImage: boolean }>`
    border: 1px solid ${({ theme }) => `${theme.palette.border.light}`};
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
    min-height: auto;
    max-width: 400px;
    min-width: 400px;
    overflow: hidden;
    cursor: pointer;
    border-color: ${({ isSelectedImage, theme }) =>
        isSelectedImage ? theme.palette.surfaceStates.brandPrimaryActive : 'none'};
    background-color: ${({ isSelectedImage, theme }) => (isSelectedImage ? theme.palette.surface.brandLight : 'none')};
    flex: 1;

    .image-details {
        overflow: hidden;
        p {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
    &:hover {
        box-shadow: ${({ theme }) => theme.shadows[2]};
        transition: all 0.3s ease-in-out;
    }
`;
