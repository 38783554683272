import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { Icon, Tooltip, Typography } from 'common/design-system/components-v2';
import { AssetNodeProps } from 'common/components/Graph/Models/AssetNodeProps';
import IntelligenceNodePopoverComponent from './IntelligenceNodePopoverComponent';
import { IntelligenceTypeMap } from '../Consts/IntelligenceTypeMap';
import { ASSET_IN_CONTEXT, GRAPH_CLASSES, NODE_SIZE } from '../Consts/GraphProperties';
import '../../../Intelligence.scss';
import { IconMap } from '../Services/IconMap';
import { IntelligenceNodeData } from 'common/module_interface/intelligence/intelligenceNodeData';
import { InsightPopoverRegistry } from 'common/module_interface/insight/InsightPopoverRegistry';
import { LayoutDirection } from 'common/components/Graph/Models/LayoutDirection';
import { useSelector } from 'react-redux';
import { getVisualizationGraphState } from '../Services/GraphState.reducer';
import CopiableLine from 'common/design-system/components-v2/CopiableLine';
import { preventNodeSelection } from '../Utils';

const IntelligenceAssetNode: React.FC<AssetNodeProps> = (props: AssetNodeProps) => {
    const { data, isConnectable } = props;
    const intelligenceAssetNodeData = data as IntelligenceNodeData;
    const platform = intelligenceAssetNodeData.platform?.toLowerCase();
    const intelligenceType = IntelligenceTypeMap[`${platform}_${intelligenceAssetNodeData.type.toLowerCase()}`];
    const [nwBadge] = useState(InsightPopoverRegistry.getBadges().find((item) => item.position === 'nw'));
    const [neBadge] = useState(InsightPopoverRegistry.getBadges().find((item) => item.position === 'ne'));
    const [sourceHandlePosition, setSourceHandlePosition] = useState<Position>(Position.Left);
    const [targetHandlePosition, setTargetHandlePosition] = useState<Position>(Position.Right);
    const graphDirectionState: LayoutDirection | undefined = useSelector(getVisualizationGraphState);

    useEffect(() => {
        if (graphDirectionState === LayoutDirection.RightToLeft) {
            setSourceHandlePosition(Position.Right);
            setTargetHandlePosition(Position.Left);
        }
    }, [graphDirectionState]);

    const typeForIcon = intelligenceType || 'Default';
    const NodeIcon: FunctionComponent = IconMap[typeForIcon];
    const customSize = NODE_SIZE;
    const handleRef = useRef(null);

    return (
        <>
            <Handle
                type='target'
                position={sourceHandlePosition}
                style={{
                    top: '50.1%',
                    background: 'transparent',
                    width: 0,
                    height: 0,
                    border: 'none',
                }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />

            <div
                className={`flex flex-col items-center ${GRAPH_CLASSES.NODE}-${intelligenceAssetNodeData.classification}`}
            >
                {intelligenceAssetNodeData?.isInContext && (
                    <Tooltip content={ASSET_IN_CONTEXT.TOOLTIP}>
                        <div className={'absolute top-[-40px] flex flex-col items-center z-200'}>
                            <Icon name={'location'} size={32} />
                        </div>
                    </Tooltip>
                )}
                <Tooltip
                    content={IntelligenceNodePopoverComponent(intelligenceAssetNodeData)}
                    interactive={true}
                    appendTo={() => document.body}
                    delay={200}
                    maxWidth={400}
                >
                    <div className='flex flex-col items-center'>
                        <div
                            className={`flex flex-col items-center group rounded-full overflow-hidden p-2 ${intelligenceAssetNodeData.selected ? 'shadow-md shadow-strong' : ''} hover:shadow-md transition-shadow theme theme-dark`}
                            style={{
                                background: intelligenceAssetNodeData.backgroundColor,
                                fill: '#ffffff',
                                borderWidth: intelligenceAssetNodeData.selected ? '2px' : '0px',
                                borderColor: '#2E3F57',
                            }}
                        >
                            {nwBadge && (
                                <div className={'absolute -left-6'}>
                                    {<nwBadge.component data={intelligenceAssetNodeData} />}
                                </div>
                            )}

                            {neBadge && (
                                <div className={'absolute -right-3'}>
                                    {<neBadge.component data={intelligenceAssetNodeData} />}
                                </div>
                            )}

                            {intelligenceAssetNodeData.riskScore !== undefined &&
                                intelligenceAssetNodeData.riskScore !== null && (
                                    <div className='absolute text-strong bottom-2 font-bold drop-shadow-sm'>
                                        {intelligenceAssetNodeData.riskScore}
                                    </div>
                                )}
                            <div
                                className='bg-svg-element grid place-items-center '
                                style={{
                                    width: `${customSize}px`,
                                    height: `${customSize}px`,
                                }}
                            >
                                {NodeIcon && (
                                    <div
                                        className={`intelligence-visualization-node-icon-container ${platform ?? ''} ${typeForIcon}`}
                                    >
                                        <NodeIcon />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Tooltip>
                <div className='absolute top-[85px] flex flex-col items-center ' onClick={preventNodeSelection}>
                    <div className=' w-[20ch] rounded-full px-5 font-medium text-center truncate'>
                        {intelligenceAssetNodeData.name ||
                            intelligenceAssetNodeData.externalId ||
                            intelligenceAssetNodeData.type}
                    </div>
                    {intelligenceAssetNodeData.additionalData?.ip && (
                        <CopiableLine value={intelligenceAssetNodeData.additionalData?.ip}>
                            <Typography variant='body' color='light'>
                                {intelligenceAssetNodeData.additionalData?.ip}
                            </Typography>
                        </CopiableLine>
                    )}
                </div>
            </div>
            <Handle
                ref={handleRef}
                type='source'
                position={targetHandlePosition}
                isConnectable={isConnectable}
                style={{
                    top: '49.9%',
                    background: 'transparent',
                    width: 0,
                    height: 0,
                    border: 'none',
                }}
            />
        </>
    );
};

export default IntelligenceAssetNode;
