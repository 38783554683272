import { ColDef } from 'ag-grid-community';
import i18n from 'common/services/translations/translations';
import { I18nTranslationKey, RulesetsTableRegistry } from '../InitRulsetsPage';
import Icon from 'common/design-system/components-v2/Icon';
import { ICellRendererParams, ValueFormatterParams } from 'ag-grid-enterprise';
import { Stack, Typography } from 'common/design-system/components-v2';
import { IExtendRuleset } from 'common/components/rulesetsPage/DefaultRulesetsDataService';

const columnDefs: () => ColDef<IExtendRuleset>[] = () => [
    {
        colId: 'checkbox',
        headerCheckboxSelection: false,
        width: 40,
        maxWidth: 40,
        minWidth: 40,
        resizable: false,
        lockPosition: 'left',
        checkboxSelection: () => {
            return true;
        },
        showDisabledCheckboxes: true,
    },
    {
        colId: 'name',
        field: 'name',
        headerName: i18n.t('TABLE.HEADERS.NAME', { ns: I18nTranslationKey }),
        width: 400,
        suppressSizeToFit: true,
        sortable: true,
        valueFormatter: (params: ValueFormatterParams<IExtendRuleset>) => `${params.data?.name}`,
        cellRenderer: (params: ICellRendererParams<IExtendRuleset>) => {
            return (
                <Typography>
                    <Stack direction={'row'} spacing={2}>
                        <Icon name={params?.data?.id && params?.data?.id < 0 ? 'cloudGuard' : 'user'} />
                        {params.data?.name}
                    </Stack>
                </Typography>
            );
        },
    },
    {
        colId: 'description',
        field: 'description',
        headerName: i18n.t('TABLE.HEADERS.DESCRIPTION', { ns: I18nTranslationKey }),
        width: 200,
        suppressSizeToFit: true,
        sortable: true,
        enableRowGroup: true,
        valueGetter: (params) => params.data?.description || '',
        valueFormatter: (params) => `${params.value}` || '',
    },
    {
        colId: 'policies',
        field: 'numberOfPolicies',
        headerName: i18n.t('TABLE.HEADERS.POLICIES', { ns: I18nTranslationKey }),
        width: 200,
        suppressSizeToFit: true,
        sortable: true,
        enableRowGroup: true,
        valueGetter: (params) => params.data?.numberOfPolicies || '',
        valueFormatter: (params) => `${params.value}` || '',
    },
    {
        colId: 'platform',
        field: 'cloudVendor',
        headerName: i18n.t('TABLE.HEADERS.PLATFORM', { ns: I18nTranslationKey }),
        width: 200,
        suppressSizeToFit: true,
        sortable: true,
        enableRowGroup: true,
        valueGetter: (params) => params.data?.cloudVendor || '',
        valueFormatter: (params) => `${params.value}` || '',
    },
    {
        colId: 'rulesCount',
        field: 'rulesCount',
        headerName: i18n.t('TABLE.HEADERS.RULES_NUMBER', { ns: I18nTranslationKey }),
        width: 200,
        suppressSizeToFit: true,
        sortable: true,
        enableRowGroup: true,
        valueGetter: (params) => params.data?.rulesCount || '',
        valueFormatter: (params) => `${params.value}` || '',
    },
];

export default function initializeRulesetsColumnDefs() {
    RulesetsTableRegistry.addColumnDefs(columnDefs());
}
