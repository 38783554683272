import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { KustoEventFilterFields } from 'common/components/KustoEvents/KustoEvent.const';

export const FindingsFilterTypes: { [key: string]: IProtectedAssetFilter } = {
    // common filters
    ADD_FILTER: {
        id: FILTERS_KEYS.ADD_FILTER,
    },
    FREE_TEXT: {
        id: FILTERS_KEYS.FREE_TEXT,
    },
    DATE_PICKER: {
        id: FILTERS_KEYS.DATE_PICKER,
    },
    CLEAR_ALL: {
        id: FILTERS_KEYS.CLEAR_BUTTON,
    },
    // field filters
    PLATFORM: {
        id: KustoEventFilterFields.CloudAccountType,
        isField: true,
    },
    ENTITY_TYPE: {
        id: KustoEventFilterFields.EntityTypeByEnvironmentType,
        isField: true,
    },
    ENVIRONMENT: {
        id: KustoEventFilterFields.CloudAccountId,
        isField: true,
    },
    SEVERITY: {
        id: KustoEventFilterFields.Severity,
        isField: true,
    },
    ENTITY_NAME: {
        id: KustoEventFilterFields.EntityName,
        isField: true,
    },
    ORGANIZATIONAL_UNITS: {
        id: KustoEventFilterFields.OrganizationalUnitId,
        isField: true,
    },
    REGION: {
        id: KustoEventFilterFields.Region,
        isField: true,
    },
    RULE_NAME: {
        id: KustoEventFilterFields.RuleName,
        isField: true,
    },
    RULESET_NAME: {
        id: KustoEventFilterFields.BundleName,
        isField: true,
    },
    ACTION: {
        id: KustoEventFilterFields.Action,
        isField: true,
    },
    REMEDIATION: {
        id: KustoEventFilterFields.Remediation,
        isField: true,
    },
    SHOW_EXCLUDED: {
        id: KustoEventFilterFields.IsExcluded,
        isField: true,
    },
    STATUS: {
        id: KustoEventFilterFields.StatusId,
        isField: true,
    },
    ORIGIN: {
        id: KustoEventFilterFields.Origin,
        isField: true,
    },
    MITRE_TACTIC: {
        id: KustoEventFilterFields.MitreTactic,
        isField: true,
    },
    MITRE_TECHNIQUE: {
        id: KustoEventFilterFields.MitreTechnique,
        isField: true,
    },
    ASSIGNEE: {
        id: KustoEventFilterFields.OwnerUserName,
        isField: true,
    },
    SHOW_ACKNOWLEDGED: {
        id: KustoEventFilterFields.Acknowledged,
        isField: true,
    },
    LOG_TYPE: {
        id: KustoEventFilterFields.LogType,
        isField: true,
    },
};
