import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import {
    GetAllRegistryAccounts,
    GetRegistryAccount,
    GetRegistryAccountSummary,
    GetRegistryAgentSummary,
    GetRegistryAccountSettings,
    UpdateRegistryAccountName,
    DeleteRegistryAccount,
    LinkedScanningEnvironment,
    UnLinkScanningEnvironment,
    UpdateRegistryOrganizationalUnitId,
    UpdateRegistryAccountSettings,
    UpdateRegistryAccountDescription,
    GetRegistryAccountsStatus,
} from './containerRegistry.interface';

const getAllRegistryAccounts: GetAllRegistryAccounts.Function = async () => {
    return await getHttpService().request<AxiosResponse<GetAllRegistryAccounts.Response>>(
        'containerRegistry/account',
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const getRegistryAccount: GetRegistryAccount.Function = async (registryId) => {
    return await getHttpService().request<AxiosResponse<GetRegistryAccount.Response>>(
        `containerRegistry/account/${registryId}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const getRegistryAccountSummary: GetRegistryAccountSummary.Function = async (registryId) => {
    return await getHttpService().request<AxiosResponse<GetRegistryAccountSummary.Response>>(
        `containerRegistry/account/${registryId}/accountSummary`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const getRegistryAgentSummary: GetRegistryAgentSummary.Function = async (registryId) => {
    return await getHttpService().request<AxiosResponse<GetRegistryAgentSummary.Response>>(
        `containerRegistry/account/${registryId}/agentSummary`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const getRegistryAccountSettings: GetRegistryAccountSettings.Function = async (registryId) => {
    return await getHttpService().request<AxiosResponse<GetRegistryAccountSettings.Response>>(
        `containerRegistry/account/${registryId}/settings`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const updateRegistryAccountSettings: UpdateRegistryAccountSettings.Function = async (registryId, data) => {
    return await getHttpService().request<AxiosResponse<UpdateRegistryAccountSettings.Response>>(
        `containerRegistry/account/${registryId}/settings`,
        { method: 'PUT', data },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const updateName: UpdateRegistryAccountName.Function = async (registryId, name) => {
    return await getHttpService().request<AxiosResponse<UpdateRegistryAccountName.Response>>(
        `containerRegistry/account/${registryId}/accountName/${name}`,
        { method: 'PUT' },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const updateDescription: UpdateRegistryAccountDescription.Function = async (registryId, description) => {
    return await getHttpService().request<AxiosResponse<UpdateRegistryAccountDescription.Response>>(
        `containerRegistry/account/${registryId}/accountDescription?description=${description}`,
        { method: 'PUT' },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const updateOrganizationalUnitId: UpdateRegistryOrganizationalUnitId.Function = async (
    registryId,
    organizationalUnitId,
) => {
    return await getHttpService().request<AxiosResponse<UpdateRegistryOrganizationalUnitId.Response>>(
        `containerRegistry/account/${registryId}/organizationalUnit`,
        {
            method: 'PUT',
            data: {
                organizationalUnitId,
            },
        },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const deleteAccount: DeleteRegistryAccount.Function = async (registryId) => {
    return await getHttpService().request<AxiosResponse<DeleteRegistryAccount.Response>>(
        `containerRegistry/account/${registryId}`,
        { method: 'DELETE' },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const linkedScanningEnvironment: LinkedScanningEnvironment.Function = async (registryId, data) => {
    return await getHttpService().request<AxiosResponse<LinkedScanningEnvironment.Response>>(
        `containerRegistry/account/${registryId}/linkedScanningEnvironment`,
        { method: 'PUT', data },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const unLinkScanningEnvironment: UnLinkScanningEnvironment.Function = async (registryId, linkedEnvironmentId) => {
    return await getHttpService().request<AxiosResponse<UnLinkScanningEnvironment.Response>>(
        `containerRegistry/account/${registryId}/linkedScanningEnvironment/${linkedEnvironmentId}`,
        { method: 'DELETE' },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const getRegistryAccountsStatus: GetRegistryAccountsStatus.Function = async () => {
    return await getHttpService().request<AxiosResponse<GetRegistryAccountsStatus.Response>>(
        'containerRegistry/account/accountAgentStatus',
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const ContainerRegisryService = {
    getAllRegistryAccounts,
    getRegistryAccount,
    getRegistryAccountSummary,
    getRegistryAgentSummary,
    getRegistryAccountSettings,
    updateName,
    updateDescription,
    updateOrganizationalUnitId,
    deleteAccount,
    linkedScanningEnvironment,
    unLinkScanningEnvironment,
    updateRegistryAccountSettings,
    getRegistryAccountsStatus,
};

export default ContainerRegisryService;
