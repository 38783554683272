import { Addin } from 'common/extensibility/AddinRegistry';
import { PATH_ENVIRONMENTS_GET_STARTED } from 'common/module_interface/assets/GetStartedPage';
import i18n from 'i18next';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';

export const initializeK8sGetStarted = () => {
    const kubernetes: Addin = {
        id: i18n.t('GET_STARTED.K8S.VENDOR_KEY'),
        position: 4,
        content: function () {
            return {
                vendor: i18n.t('GET_STARTED.K8S.VENDOR_KEY'),
                linkTo: i18n.t('GET_STARTED.K8S.LINK_TO'),
                isReact: false,
                title: i18n.t('GET_STARTED.K8S.TITLE'),
            };
        },
    };

    // const containerregistry: Addin = {
    //     id: i18n.t('GET_STARTED.CONTAINER_REGISTRY.VENDOR_KEY'),
    //     position: 5,
    //     content: function (){
    //         return {
    //             vendor: i18n.t('GET_STARTED.CONTAINER_REGISTRY.VENDOR_KEY'),
    //             linkTo: i18n.t('GET_STARTED.CONTAINER_REGISTRY.LINK_TO'),
    //             isReact: false,
    //             title: i18n.t('GET_STARTED.CONTAINER_REGISTRY.TITLE')
    //         } ;

    //     }
    // };
    globalAddinContainer.add(PATH_ENVIRONMENTS_GET_STARTED, [kubernetes]);
};
