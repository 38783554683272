import React, { useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { IChipLabelProps } from 'common/design-system/components-v2/Chip/Chip.types';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from '../../../initialize.i18n';
import { TFunction } from 'i18next';
import { Chip } from 'common/design-system/components-v2';
import { TriggerStatus } from 'common/module_interface/intelligence/CloudBots/CloudBots.const';
import { DefaultTheme, useTheme } from 'styled-components';

type TriggerStatusPropMappings = { [key in TriggerStatus]: IChipLabelProps };
const getTriggerStatusPropMappings = (theme: DefaultTheme): TriggerStatusPropMappings => {
    const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);
    return {
        [TriggerStatus.TRUE]: {
            color: 'success',
            customTextColor: theme.palette.riskLevel.lowFg,
            customColor: theme.palette.riskLevel.lowBg,
            label: t('CLOUD_BOTS.TRIGGER_STATUS.TRIGGERED'),
            leadingIconProps: { name: 'checkCircle', color: 'success' },
        },
        [TriggerStatus.FALSE]: {
            color: 'alert',
            customTextColor: theme.palette.riskLevel.highFg,
            customColor: theme.palette.riskLevel.highBg,
            label: t('CLOUD_BOTS.TRIGGER_STATUS.NOT_TRIGGERED'),
            leadingIconProps: { name: 'error', color: 'alert' },
        },
    };
};

const getTriggerStatusElement = (
    isTriggered: TriggerStatus = TriggerStatus.FALSE,
    statusProps: TriggerStatusPropMappings,
) => {
    const chipProps: IChipLabelProps = {
        disableInteraction: true,
        ...statusProps[isTriggered],
    };
    return <Chip {...chipProps} />;
};

const CloudBotTriggerStatusCellRenderer: React.FC<ICellRendererParams> = (params: ICellRendererParams) => {
    const { value } = params;
    const theme: DefaultTheme = useTheme();
    const statusProps: TriggerStatusPropMappings = useMemo(() => {
        return getTriggerStatusPropMappings(theme);
    }, [theme]);
    return getTriggerStatusElement(value, statusProps);
};

export default CloudBotTriggerStatusCellRenderer;
