import { getHttpService } from 'common/interface/services';
import { ERM_URLS } from '../consts';
import {
    ISentraIntegrationService,
    ISentraConfigurationModel,
    IBaseIdModel,
} from 'common/module_interface/RiskManagement/Services';

export class SentraIntegrationService implements ISentraIntegrationService {
    async addSentraAccount(apiKey: string, name: string) {
        return await getHttpService().post<IBaseIdModel>(ERM_URLS.SENTRA_INTEGRATION, {
            data: {
                ApiKey: apiKey,
                Name: name,
            },
        });
    }
    async getSentraAccounts() {
        const response = await getHttpService().get<ISentraConfigurationModel>(ERM_URLS.SENTRA_INTEGRATION);
        return response.accounts;
    }

    async updateSentraAccount(accountId: string, apiKey: string, name: string) {
        return await getHttpService().put<IBaseIdModel>(ERM_URLS.SENTRA_INTEGRATION + `/${accountId}`, {
            data: {
                ApiKey: apiKey,
                Name: name,
            },
        });
    }

    getSentraConfigurations = async () => {
        try {
            const response = await this.getSentraAccounts();
            return response?.map((account) => ({
                id: account.id,
                name: account.name,
                configurationObj: account,
            }));
        } catch {
            return [];
        }
    };

    async deleteSentraAccount(accountId: string) {
        await getHttpService().delete(ERM_URLS.SENTRA_INTEGRATION + `/${accountId}`);
    }
}
