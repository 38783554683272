import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { getNotificationsService, getServiceAccountsService } from 'common/interface/services';
import { Input, SelectV2, Stack } from 'common/design-system/components-v2';
import { IAccountsServiceItem } from 'common/interface/serviceAccountService';
import { I18nTranslationKey } from '../initServiceAccountsPage';

interface ICreateServiceAccountModalContentProps {
    nameInput?: string;
    setNameInput: (name: string) => void;
    selectedRoles?: string[];
    setSelectedRoles: (roles: string[]) => void;
    nameInputError?: string;
    selectedRolesError?: string;
    selectedServiceAccount?: IAccountsServiceItem | null;
}

export const ServiceAccountModalContent: FC<ICreateServiceAccountModalContentProps> = ({
    nameInput,
    setNameInput,
    selectedRoles,
    setSelectedRoles,
    nameInputError,
    selectedRolesError,
    selectedServiceAccount,
}) => {
    const { t } = useTranslation(I18nTranslationKey);
    const [rolesList, setRolesList] = useState<SelectOption[]>([]);

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const resp = await getServiceAccountsService().getServiceAccountRoles();
                const formattedRoles = resp.map((role) => ({ value: role.id.toString(), label: role.name }));
                setRolesList(formattedRoles);
            } catch (e) {
                getNotificationsService().error('', t('GENERAL.NETWORK_ERROR'));
            }
        };
        void fetchRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedServiceAccount?.name && Array.isArray(selectedServiceAccount?.roleIds)) {
            setNameInput(selectedServiceAccount.name);
            const selectedRoles = selectedServiceAccount.roleIds.map((role) => role.toString());
            setSelectedRoles(selectedRoles);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedServiceAccount, rolesList]);

    return (
        <Stack padding={[5]} spacing={5} fullWidth>
            <Input
                type={'text'}
                label={t('MODALS.CREATE_SERVICE_ACCOUNT_MODAL.NAME')}
                value={nameInput}
                onChange={(e) => setNameInput(e.target.value)}
                fullWidth
                isError={!!nameInputError}
                helperText={nameInputError}
            />
            <SelectV2
                label={t('MODALS.CREATE_SERVICE_ACCOUNT_MODAL.SELECTED_ROLES')}
                placeholder={t('MODALS.CREATE_SERVICE_ACCOUNT_MODAL.SELECT_ROLES')}
                isMulti={true}
                options={rolesList}
                onChange={(value: string[]) => setSelectedRoles(value)}
                value={selectedRoles}
                fullWidth
                clearable
                isError={!!selectedRolesError}
                helperText={selectedRolesError}
            />
        </Stack>
    );
};
