import { DrawerRegistry } from 'common/components/DrawerInfra/Drawer/DrawerRegistry';
import { IDrawerContent } from 'common/components/DrawerInfra/Drawer/Drawer.interface';
import { FINDINGS_OVERVIEW_DRAWER_ID } from '../Findings.const';
import FindingOverview from './Components/FindingOverview';
import { EventOverviewRegistry } from 'common/module_interface/events/EventOverviewRegistry';
import { isEmptyString } from 'common/utils/helpFunctions';
import { IEvent } from 'common/module_interface/events/Events';
import { i18nIntelligenceNamespace } from '../../initialize.i18n';
import i18n from 'i18next';
import { getSafeFindingSeverityInfo } from 'common/consts/FindingSeverity';
import { IconWrapper } from './FindingOverviewStyled';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IProtectedAssetDetailProvider } from 'common/module_interface/assets/ProtectedAssets';
import { InfoPaneFieldProps } from 'common/components/InfoPane/InfoPane';
import { AssetFieldsMap } from 'common/module_interface/assets/AssetsConsts';
import { FindingAssetPanel } from 'common/components/Findings/FindingAssetPanel/FindingAssetPanel';
import { Icon } from 'common/design-system/components-v2';
import { RightHeaderPanel } from './Components/Panels/RightPanel/RightHeaderPanel';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { EventOverviewTabProps, EventsDrawerRegistry } from 'common/module_interface/events/EventsDrawerRegistry';
import React from 'react';
import { FINDING_ASSET_PANEL_ID } from 'common/components/KustoEvents/KustoEvent.const';
import { getKustoEventService } from 'common/module_interface/intelligence/intelligence';
import { EventOverviewPanelsTypes } from './Types/EventOverviewPanelsTypes';
import { EventOverviewUpperPanelsTypes } from './Types/EventOverviewUpperPanelsTypes';
import { EventOverviewPanels } from 'common/module_interface/events/EventsConsts';

function initializeDrawerContent() {
    DrawerRegistry.addContentProvider({
        id: FINDINGS_OVERVIEW_DRAWER_ID,
        getDrawerContent: async ({ eventId }): Promise<IDrawerContent | undefined> => {
            const event: IKustoEvent = await getKustoEventService().getKustoEventById(eventId);
            const { findingKey, ruleName, severity } = event;
            const severityInfo = getSafeFindingSeverityInfo(severity);
            const iconColor = severityInfo.iconColors.color;
            const iconColorLightness = severityInfo.iconColors.colorLightness;
            const iconColorBg = severityInfo.bg;
            const result = findingKey
                ? {
                      title: ruleName ?? i18n.t('INT_FINDING_OVERVIEW.OVERVIEW', { ns: i18nIntelligenceNamespace }),
                      icon: (
                          <IconWrapper
                              justifyContent='center'
                              alignItems='center'
                              withBackground={true}
                              bg={iconColorBg}
                          >
                              <Icon
                                  vendorNameOrPath='security-event'
                                  color={iconColor}
                                  colorLightness={iconColorLightness}
                                  size={24}
                              />
                          </IconWrapper>
                      ),
                      component: FindingOverview,
                      componentProps: { findingKey, ruleName, finding: event },
                      rightHeaderContent: <RightHeaderPanel event={event} />,
                  }
                : undefined;
            return Promise.resolve(result);
        },
    });
}

export const initializeEventsAssetPanels = () => {
    // CDR events asset panel
    EventOverviewRegistry.addPanels(
        [
            {
                id: EventOverviewPanelsTypes.ASSET_PANEL.panelId,
                position: 20,
                content: EventOverviewPanelsTypes.ASSET_PANEL,
            },
        ],
        EventOverviewPanels.CDR_CONTENT_PANEL,
    );

    // CDR Enrichment inside Elastic events asset panel
    EventOverviewRegistry.addPanels(
        [
            {
                id: EventOverviewPanelsTypes.ASSET_PANEL.panelId,
                position: 30,
                content: EventOverviewPanelsTypes.ASSET_PANEL,
            },
        ],
        EventOverviewPanels.CDR_ELASTIC_CONTENT_PANEL,
    );

    // Elastic events asset panel
    EventsDrawerRegistry.addOverviewSections([
        {
            id: 'findingAsset',
            position: 0,
            content: {
                id: 'findingAsset',
                isRelevant: (finding: IEvent) =>
                    !isEmptyString(finding.entityDome9Id) && finding.entityDome9Id !== 'N-A',
                getValue: (finding: IEvent) => {
                    const FindingAssetPanelComponent: React.FC<EventOverviewTabProps> = () => (
                        <FindingAssetPanel
                            cloudAccountId={finding.cloudAccountId}
                            cloudAccountType={finding.cloudAccountType}
                            entityDome9Id={finding.entityDome9Id || ''}
                            entityExternalId={finding.entityExternalId}
                            entityType={finding.entityType}
                            entityName={finding.entityName}
                            entityObject={finding.entityObject}
                        />
                    );
                    return FindingAssetPanelComponent;
                },
            },
        },
    ]);

    const fieldAddins: Addin<IProtectedAssetDetailProvider<InfoPaneFieldProps>>[] = [
        {
            id: 'type',
            position: 20,
            content: AssetFieldsMap.typeField,
        },
        {
            id: 'id',
            position: 30,
            content: AssetFieldsMap.idField,
        },
        {
            id: 'organizationalUnit',
            position: 40,
            content: AssetFieldsMap.organizationalUnitField,
        },
        {
            id: 'environment',
            position: 50,
            content: AssetFieldsMap.environmentField,
        },
        {
            id: 'platform',
            position: 60,
            content: AssetFieldsMap.platformField,
        },
        {
            id: 'region',
            position: 70,
            content: AssetFieldsMap.regionField,
        },
        {
            id: 'vpc',
            position: 80,
            content: AssetFieldsMap.vpcField,
        },
        {
            id: 'tags',
            position: 90,
            content: AssetFieldsMap.tagsField,
        },
    ];
    EventOverviewRegistry.addFieldAddins(fieldAddins, FINDING_ASSET_PANEL_ID);
};

function initializeKustoEventOverview() {
    EventOverviewRegistry.addPanels(
        [
            {
                id: EventOverviewUpperPanelsTypes.SEVERITY_PANEL.panelId,
                position: 10,
                content: EventOverviewUpperPanelsTypes.SEVERITY_PANEL,
            },
            {
                id: EventOverviewUpperPanelsTypes.DATE_PANEL.panelId,
                position: 20,
                content: EventOverviewUpperPanelsTypes.DATE_PANEL,
            },
            {
                id: EventOverviewUpperPanelsTypes.ENTITY_PANEL.panelId,
                position: 30,
                content: EventOverviewUpperPanelsTypes.ENTITY_PANEL,
            },
            {
                id: EventOverviewUpperPanelsTypes.LOG_TYPE_PANEL.panelId,
                position: 40,
                content: EventOverviewUpperPanelsTypes.LOG_TYPE_PANEL,
            },
            {
                id: EventOverviewUpperPanelsTypes.ACKNOWLEDGED_PANEL.panelId,
                position: 50,
                content: EventOverviewUpperPanelsTypes.ACKNOWLEDGED_PANEL,
            },
            {
                id: EventOverviewUpperPanelsTypes.EXCLUDED_PANEL.panelId,
                position: 60,
                content: EventOverviewUpperPanelsTypes.EXCLUDED_PANEL,
            },
        ],
        EventOverviewPanels.CDR_UPPER_PANEL,
    );

    EventOverviewRegistry.addPanels(
        [
            {
                id: EventOverviewPanelsTypes.EXPLORE_LOGS_PANEL.panelId,
                position: 10,
                content: EventOverviewPanelsTypes.EXPLORE_LOGS_PANEL,
            },
        ],
        EventOverviewPanels.CDR_RIGHT_PANEL,
    );

    EventOverviewRegistry.addPanels(
        [
            {
                id: EventOverviewPanelsTypes.MITRE_PANEL.panelId,
                position: 20,
                content: EventOverviewPanelsTypes.MITRE_PANEL,
            },
            {
                id: EventOverviewPanelsTypes.DESCRIPTION_PANEL.panelId,
                position: 30,
                content: EventOverviewPanelsTypes.DESCRIPTION_PANEL,
            },
            {
                id: EventOverviewPanelsTypes.VISUALIZATION_PANEL.panelId,
                position: 40,
                content: EventOverviewPanelsTypes.VISUALIZATION_PANEL,
            },
            {
                id: EventOverviewPanelsTypes.BUNDLE_NAME_PANEL.panelId,
                position: 50,
                content: EventOverviewPanelsTypes.BUNDLE_NAME_PANEL,
            },
            {
                id: EventOverviewPanelsTypes.GSL_PANEL.panelId,
                position: 60,
                content: EventOverviewPanelsTypes.GSL_PANEL,
            },
            {
                id: EventOverviewPanelsTypes.ALERT_ID_PANEL.panelId,
                position: 70,
                content: EventOverviewPanelsTypes.ALERT_ID_PANEL,
            },
            {
                id: EventOverviewPanelsTypes.COMMENTS_PANEL.panelId,
                position: 80,
                content: EventOverviewPanelsTypes.COMMENTS_PANEL,
            },
        ],
        EventOverviewPanels.CDR_CONTENT_PANEL,
    );
}

function initializeElasticEventOverview() {
    EventOverviewRegistry.addPanels(
        [
            {
                id: EventOverviewUpperPanelsTypes.LOG_TYPE_PANEL.panelId,
                position: 40,
                content: EventOverviewUpperPanelsTypes.LOG_TYPE_PANEL,
            },
            {
                id: EventOverviewUpperPanelsTypes.ACKNOWLEDGED_PANEL.panelId,
                position: 50,
                content: EventOverviewUpperPanelsTypes.ACKNOWLEDGED_PANEL,
            },
            {
                id: EventOverviewUpperPanelsTypes.EXCLUDED_PANEL.panelId,
                position: 60,
                content: EventOverviewUpperPanelsTypes.EXCLUDED_PANEL,
            },
        ],
        EventOverviewPanels.CDR_ELASTIC_UPPER_PANEL,
    );

    EventOverviewRegistry.addPanels(
        [
            {
                id: EventOverviewPanelsTypes.MITRE_PANEL.panelId,
                position: 20,
                content: EventOverviewPanelsTypes.MITRE_PANEL,
            },
            {
                id: EventOverviewPanelsTypes.DESCRIPTION_PANEL.panelId,
                position: 40,
                content: EventOverviewPanelsTypes.DESCRIPTION_PANEL,
            },
            {
                id: EventOverviewPanelsTypes.VISUALIZATION_PANEL.panelId,
                position: 50,
                content: EventOverviewPanelsTypes.VISUALIZATION_PANEL,
            },
            {
                id: EventOverviewPanelsTypes.BUNDLE_NAME_PANEL.panelId,
                position: 60,
                content: EventOverviewPanelsTypes.BUNDLE_NAME_PANEL,
            },
            {
                id: EventOverviewPanelsTypes.GSL_PANEL.panelId,
                position: 70,
                content: EventOverviewPanelsTypes.GSL_PANEL,
            },
            {
                id: EventOverviewPanelsTypes.ALERT_ID_PANEL.panelId,
                position: 80,
                content: EventOverviewPanelsTypes.ALERT_ID_PANEL,
            },
            {
                id: EventOverviewPanelsTypes.COMMENTS_PANEL.panelId,
                position: 90,
                content: EventOverviewPanelsTypes.COMMENTS_PANEL,
            },
        ],
        EventOverviewPanels.CDR_ELASTIC_CONTENT_PANEL,
    );

    EventOverviewRegistry.addPanels(
        [
            {
                id: EventOverviewPanelsTypes.EXPLORE_LOGS_PANEL.panelId,
                position: 10,
                content: EventOverviewPanelsTypes.EXPLORE_LOGS_PANEL,
            },
        ],
        EventOverviewPanels.CDR_ELASTIC_RIGHT_PANEL,
    );
}

export function initializeFindingOverview() {
    initializeDrawerContent();
    initializeKustoEventOverview();
    initializeEventsAssetPanels();
    initializeElasticEventOverview();
}
