import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../../../../consts';
import { EditorStyled } from '../../AutoActionEditor.styled';
import { IOrganizationalUnit } from 'common/interface/data_services';
import MultiSelectorTreeDropdown from 'common/erm-components/custom/MultiSelector/MultiSelectorTreeDropdown';
import { IWhereProps } from '../../AutoActionEditor.interface';
import { getAutoActionsFilterDefinitions } from '../../AutoActionEditor.consts';
import { Spinner, Stack, Typography } from 'common/design-system/components-v2';
import {
    IFilterChangeEventInfo,
    IFilterTreeFieldDefinition,
} from 'common/erm-components/custom/FilterTree/FilterTree.interface';
import { convertToServerFilter } from 'common/erm-components/custom/FilterTree/CompoundFilter';
import { LoadingState } from 'common/interface/general';
import { isNil } from 'common/utils/helpFunctions';
import { RiskStyled } from '../../../../../RiskManagement.styled';
import i18n from 'i18next';
import { PreviewBox } from './PreviewBox/PreviewBox';
import { WhereStyled } from './AutoActionEditorWhere.styled';
import FilterTree from 'common/erm-components/custom/FilterTree';
import { useIsReadOnlyAutoActions } from '../../../useIsAutoActionReadonly';

export const AutoActionEditorWhere: React.FC<{
    exposeErrors: boolean;
    orgUnitsRoot?: IOrganizationalUnit;
    whereProps: IWhereProps;
    setWhereProps: (props: IWhereProps) => void;
}> = ({ exposeErrors, orgUnitsRoot, whereProps, setWhereProps }) => {
    const isReadOnly = useIsReadOnlyAutoActions();
    const filterDefsRef = useRef<IFilterTreeFieldDefinition[]>();
    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.IS_LOADING);
    const filterRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation(I18nRiskNamespace);

    const prepareFilterDefs = useCallback(async () => {
        if (filterDefsRef.current) {
            return;
        }
        setLoadingState(LoadingState.IS_LOADING);
        getAutoActionsFilterDefinitions()
            .then((defs: IFilterTreeFieldDefinition[]) => {
                filterDefsRef.current = defs;
                setLoadingState(LoadingState.LOADING_SUCCEEDED);
            })
            .catch((error: any) => {
                console.error(error);
                setLoadingState(LoadingState.LOADING_FAILED);
            });
    }, []);

    const onWhereChange = useCallback(
        (newProps: IWhereProps) => {
            setWhereProps(newProps);
        },
        [setWhereProps],
    );

    const onOrgUnitIdsChange = useCallback(
        (orgUnitIds: string[]) => {
            const newProps: IWhereProps = {
                ...whereProps,
                orgUnitIds,
            };
            onWhereChange(newProps);
        },
        [onWhereChange, whereProps],
    );

    const onFilterChange = useCallback(
        (filterInfo: IFilterChangeEventInfo) => {
            const hasErrors = !!filterInfo.hasErrors;
            const newProps: IWhereProps = {
                ...whereProps,
                filter: filterInfo.filter,
                validServerFilter: convertToServerFilter(filterInfo.validFilterSubset),
                hasErrors,
                errorElementRefs: filterInfo.errorRefs || [],
            };
            onWhereChange(newProps);
        },
        [onWhereChange, whereProps],
    );

    useEffect(() => {
        void prepareFilterDefs();
    }, [prepareFilterDefs]);

    return (
        <EditorStyled.PanelTopDiv>
            <EditorStyled.PanelLeftDiv>
                <EditorStyled.CategoryElementWrapper>
                    <EditorStyled.CategoryChipDiv label={t('AUTO_ACTIONS.EDITOR.WHERE.WHERE')} />
                </EditorStyled.CategoryElementWrapper>
            </EditorStyled.PanelLeftDiv>
            <EditorStyled.PanelRightDiv>
                <EditorStyled.CategoryElementWrapper>
                    <EditorStyled.LabelDiv ref={filterRef} text={t('AUTO_ACTIONS.EDITOR.WHERE.WHERE_PREFIX')} />
                </EditorStyled.CategoryElementWrapper>
                <Stack direction={'row'} spacing={3}>
                    <EditorStyled.CategoryElementWrapper>
                        <EditorStyled.LabelDiv text={t('AUTO_ACTIONS.EDITOR.WHERE.ORG_UNITS.TITLE')} />
                    </EditorStyled.CategoryElementWrapper>
                    <MultiSelectorTreeDropdown
                        organizationalUnitsRoot={orgUnitsRoot}
                        settings={{ maxHeight: '200px', minHeight: '150px' }}
                        readOnly={isReadOnly}
                        selectedIds={whereProps.orgUnitIds}
                        onSelectionChanged={onOrgUnitIdsChange}
                    />
                </Stack>
                {isNil(filterDefsRef.current) && loadingState === LoadingState.IS_LOADING && (
                    <RiskStyled.SpinnerDiv alignLeft={true} alignTop={true}>
                        <Spinner size={16} />
                    </RiskStyled.SpinnerDiv>
                )}
                {isNil(filterDefsRef.current) && loadingState === LoadingState.LOADING_FAILED && (
                    <Typography color={'alert'}>{i18n.t('COMMON.ERROR_OCCURRED')}</Typography>
                )}
                {!isNil(filterDefsRef.current) && (
                    <Stack direction={'column'}>
                        <EditorStyled.CategoryElementWrapper>
                            <EditorStyled.LabelDiv text={t('AUTO_ACTIONS.EDITOR.WHERE.FILTER.TITLE')} />
                        </EditorStyled.CategoryElementWrapper>
                        <WhereStyled.FilterTreeWrapper direction={'column'}>
                            <FilterTree
                                compoundFilter={whereProps.initialFilter}
                                filterDefinitions={filterDefsRef.current}
                                displayErrors={exposeErrors}
                                readOnly={isReadOnly}
                                onFilterChange={onFilterChange}
                            />
                        </WhereStyled.FilterTreeWrapper>
                    </Stack>
                )}
                <PreviewBox whereProps={whereProps} />
            </EditorStyled.PanelRightDiv>
        </EditorStyled.PanelTopDiv>
    );
};
