import { IRemediationResponse } from 'common/module_interface/intelligence/CloudBots/CloudBots.interface';
import { BotStatus } from 'common/module_interface/intelligence/CloudBots/CloudBots.const';

export interface ICloudBotState {
    cloudBotStatus: BotStatus;
    cloudBotRemediationData?: IRemediationResponse;
}

export interface ICloudBotsState {
    cloudBots: {
        [customizationKey: string]: ICloudBotState;
    };
}

export const CloudBotsInitialState: ICloudBotsState = {
    cloudBots: {},
};
