import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { I18nIntegrations } from 'common/module_interface/settings/integrations/consts';
import { Button, Link, Stack, Typography } from 'common/design-system/components-v2';

interface IProvideServiceAccountProps {
    toggleModal: () => void;
}

export const ProvideServiceAccount: FC<IProvideServiceAccountProps> = ({ toggleModal }) => {
    const { t } = useTranslation(I18nIntegrations);
    const internalPathServiceAccount = '/v2/users-managementservice-accounts';

    return (
        <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Button dataAid={'provide-service-account-button'} variant={'outlined'} onClick={() => toggleModal()}>
                {t('CONFIGURATIONS.SENTINEL.CREATE_SERVICE_ACCOUNT')}
            </Button>
            <Link externalUrl={internalPathServiceAccount} removeExternalIcon>
                <Typography context='info' saturation>
                    {t('CONFIGURATIONS.SENTINEL.BROWSE_EXISTING_SERVICE_ACCOUNTS')}
                </Typography>
            </Link>
        </Stack>
    );
};
