import { DrawerRegistry } from 'common/components/DrawerInfra/Drawer/DrawerRegistry';
import { DrawerActionsStyle, IDrawerContent } from 'common/components/DrawerInfra/Drawer/Drawer.interface';
import { SecurityControlViewer } from './SecurityControlViewer';
import { Icon, LevelIcon } from 'common/design-system/components-v2';
import { ISecurityControl } from '../SecurityControls.interface';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';
import { isAfToxicGraph } from '../../../RiskManagement.utils';

export const SECURITY_CONTROL_VIEWER_KEY = 'SECURITY_CONTROL_VIEWER';

function initializeDrawerContent() {
    DrawerRegistry.addContentProvider({
        id: SECURITY_CONTROL_VIEWER_KEY,
        getDrawerContent: async (securityControl: ISecurityControl): Promise<IDrawerContent | undefined> => {
            const result: IDrawerContent = {
                title: securityControl.name,
                icon: <LevelIcon iconProps={{ name: 'rule' }} />,
                component: SecurityControlViewer,
                componentProps: { securityControl },
                actions: isAfToxicGraph()
                    ? [
                          {
                              onClick: () => getErmUrlsService().goToToxicGraphPageByRuleId(securityControl.id),
                              icon: <Icon name={'issue'} />,
                              tooltip: 'Open query in Toxic exploration page',
                              label: 'Explore Query',
                              value: 'openQuery',
                          },
                      ]
                    : undefined,
            };
            return Promise.resolve(result);
        },
        options: {
            width: 'xl',
            actionsStyle: DrawerActionsStyle.BUTTONS,
        },
    });
}

export function initializeSecurityControlViewer() {
    initializeDrawerContent();
}
