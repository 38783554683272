import React from 'react';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import WorkloadRequestImageScan from 'modules/workloads/services/workload/components/WorkloadRequestImageScan';
import IframeMessageModel, { IFRAME_MESSAGE_ACTIONS } from 'common/interface/IFrame.message.model';
import { getWebAppIframeService } from 'common/interface/services';

const ImageRescanChip: React.FC<ICloudEntityData> = (entity) => {
    const {
        cloudAccountId,
        protectedAsset: { entityId },
    } = entity;

    return (
        <WorkloadRequestImageScan
            environmentId={cloudAccountId}
            imageIdOrDigest={entityId}
            successCallback={() => {
                getWebAppIframeService().emitMessage(
                    new IframeMessageModel({
                        action: IFRAME_MESSAGE_ACTIONS.RELOAD_STATE,
                    }),
                );
            }}
        />
    );
};

export default ImageRescanChip;
