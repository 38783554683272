import i18n from 'i18next';
import React from 'react';
import { insightNamespace } from '../../consts/i18n';

const AiDisclaimer: React.FC = () => {
    return (
        <div>
            <div className={'text-lg pb-7'}>{`${i18n.t('AI.DISCLAIMER.TITLE', { ns: insightNamespace })}`}</div>
            <div className={'pb-7'}>
                <div className={'mb-6'}>{`${i18n.t('AI.DISCLAIMER.LINE_1', { ns: insightNamespace })}`}</div>
                <div className={'mb-6'}>{`${i18n.t('AI.DISCLAIMER.LINE_2', { ns: insightNamespace })}`}</div>
                <div className={'mb-6'}>
                    {`${i18n.t('AI.DISCLAIMER.LINE_3', { ns: insightNamespace })}`}
                    <span className={'link'}>
                        <a
                            className={'anchor cursor-pointer link'}
                            rel='noreferrer'
                            target='_blank'
                            href={'https://www.checkpoint.com/copyright/'}
                        >{`${i18n.t('AI.DISCLAIMER.COPYRIGHT_LINK', { ns: insightNamespace })}`}</a>
                    </span>
                    {`${i18n.t('AI.DISCLAIMER.LINE_4', { ns: insightNamespace })}`}
                </div>
            </div>
        </div>
    );
};

export default AiDisclaimer;
