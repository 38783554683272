import styled from 'styled-components';
import { Typography } from 'common/design-system/components-v2';

const TitleDiv = styled(Typography)`
    font-weight: 500;
    margin-bottom: 8px;
`;

export const ImageStyled = {
    TitleDiv,
};
