import { IItemsMap } from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import { ICveIgnoreUpdate } from './CveIgnoreList.interface';
import { ICommonIgnoreItemUpdate } from '../CommonIgnoreList/CommonIgnoreList.interface';
import { getCommonIgnoreItemFromValues } from '../CommonIgnoreList/CommonIgnoreList.items';
import { getTrimmedMultiValue } from 'common/erm-components/custom/CustomForm/CustomForm.values';
import { CveIgnoreInnerNames } from './CveIgnoreList.consts';

export const getCveIgnoreItemFromValues = (itemsMap: IItemsMap): ICveIgnoreUpdate => {
    const commonIgnoreItemUpdate: ICommonIgnoreItemUpdate = getCommonIgnoreItemFromValues(itemsMap);

    return {
        ...commonIgnoreItemUpdate,
        cveIds: getTrimmedMultiValue(itemsMap[CveIgnoreInnerNames.cveIds]),
        packageNames: getTrimmedMultiValue(itemsMap[CveIgnoreInnerNames.packageNames]),
        packagePaths: getTrimmedMultiValue(itemsMap[CveIgnoreInnerNames.packagePaths]),
    };
};
