import { ICellRendererParams } from 'ag-grid-community';
import { findingModelCloudAccountTypeStringToCode } from 'common/components/Findings/Findings.interface';
import { IVendor, getVendorByElasticNumber } from 'common/consts/vendors';
import { IEvent } from 'common/module_interface/events/Events';
import React from 'react';

export const Platform: React.FC<ICellRendererParams<IEvent>> = (params) => {
    const vendor = React.useMemo<IVendor | undefined>(() => {
        const vendorCode = findingModelCloudAccountTypeStringToCode(params.value);
        return getVendorByElasticNumber(vendorCode);
    }, [params]);

    return <span data-value={vendor?.name}>{vendor?.displayName || params.value}</span>;
};
