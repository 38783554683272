import { getStoreService, ILocalStorageService } from 'common/interface/services';
import { IUser } from 'common/interface/user';

export class LocalStorageService implements ILocalStorageService {
    getFromLocalStorage(key: string, pageID?: string): any {
        const expandedKey = this.getKey(key);
        const stringData = localStorage.getItem(expandedKey);
        if (stringData === null) {
            return undefined;
        } else {
            try {
                const dataObj = JSON.parse(stringData);
                return pageID ? dataObj[pageID] : dataObj;
            } catch (err) {
                localStorage.removeItem(expandedKey);
                return undefined;
            }
        }
    }

    setToLocalStorage(key: string, pageID: string, data: any) {
        const expandedKey = this.getKey(key);
        const dataObj = this.getFromLocalStorage(key) || {};
        try {
            dataObj[pageID] = data;
            const dataStr = JSON.stringify(dataObj);
            localStorage.setItem(expandedKey, dataStr);
        } catch (err) {
            localStorage.removeItem(expandedKey);
            return;
        }
    }

    getUser() {
        const { state } = getStoreService().getReduxTools();
        return state.user.user as IUser;
    }

    getKey(key: string): string {
        const user = this.getUser();
        return `${key}.${user.accountId}.${user.name}`;
    }
}
