export enum AzureIntelligenceOnboardingType {
    nsg = 'nsg',
    centralized = 'centralized',
}

export enum AssetType {
    activityLogs = 'ActivityLogs',
    flowLogs = 'FlowLogs',
    signIns = 'SignIns',
    auditLogs = 'AuditLogs',
    storageAccounts = 'StorageAccounts',
}

export enum LogType {
    activityLogs = 'activityLogs',
    flowLogs = 'flowLogs',
    storageAccounts = 'storageAccounts',
}

export const AzureStorageTableFieldNames = {
    storageName: 'storageName',
    status: 'status',
    logsType: 'logsType',
    autoOnboard: 'autoOnboard',
    remove: 'remove',
};

export const AzureCentralizedStepsCount = 6;

export const AzureNSGStepsCount = 5;

export const AzureCreateStorageAccountLink =
    'https://docs.microsoft.com/en-us/azure/storage/common/storage-account-create?tabs=azure-portal';

export const AzureStorageAnalyticsLink =
    'https://docs.microsoft.com/en-us/azure/storage/common/storage-analytics-logging?tabs=dotnet';

export const storageIdCDRRegex =
    /^\/subscriptions\/[0-9a-fA-F-]+\/resourceGroups\/[^/]+\/providers\/Microsoft\.Storage\/storageAccounts\/[^/]+$/i;
