import { ColDef } from 'ag-grid-community';
import { Remediationflatten } from '../../../../../../services/vulnerability/vulnerability.interface';
import { severityInfo } from '../../utils';
import { ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';
import DataVolume from 'common/components/DataVolume/DataVolume';
import { Stack, Tooltip } from 'common/design-system/components-v2';

const getColumnDefs = (): ColDef<Remediationflatten>[] => [
    {
        field: 'severity',
        enableRowGroup: true,
        headerName: 'Severity',
        maxWidth: 120,
        cellRenderer: (params: ICellRendererParams) => {
            if (!params.data) {
                return params.value;
            }
            return <DataVolume dataInfo={severityInfo(params.value)} />;
        },
    },
    {
        headerName: 'Type',
        enableRowGroup: false,
        maxWidth: 150,
        valueFormatter: (params: ValueFormatterParams) => {
            if (!params.data) {
                return params.value;
            }
            return params.data.category ? params.data.category : '';
        },
    },
    {
        field: 'finding',
        headerName: 'Finding',
        maxWidth: 300,
    },
    {
        headerName: 'Remediation',
        valueGetter: (params: ValueGetterParams) => (params.data ? params.data.action : ''),
        valueFormatter: (params: ValueFormatterParams) => params.value || '',
        cellRenderer: (params: ICellRendererParams) => {
            return (
                <Stack>
                    <Tooltip content={params.value}>
                        <span>{params.value}</span>
                    </Tooltip>
                </Stack>
            );
        },
    },
];

export { getColumnDefs };
