import { Addin } from 'common/extensibility/AddinRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { FILTER_DISPAY_TYPES, FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { DEFAULT_RANGES_VALUES } from 'common/components/FilterPanel/DefaultFilters/DateFilter/DateFilter.consts';
import {
    renderAddFilter,
    renderClearAll,
    renderDefaultDateFilter,
    renderMultiSelectFilter,
    renderRecentlyUsedFilters,
    renderSavedFilters,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { LogsTableRegistry } from 'common/module_interface/intelligence/Logs/LogsTableRegistry';
import { IProtectedAssetFilterParams } from 'common/module_interface/assets/ProtectedAssets';
import i18n from 'common/services/translations/translations';
import { IDisplayMappingObject } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { TFunction } from 'i18next';
import { i18nIntelligenceNamespace } from '../../initialize.i18n';
import { eventStatus, LogFilterFields } from 'common/module_interface/intelligence/Logs/Logs.consts';
import { ensureDefaultBooleanAggregations, getYesNoDisplayMapping } from 'common/erm-components/utils/filters';
import { LogFields } from 'common/module_interface/intelligence/Logs/Logs.interface';

function getFilterDefs(): Addin<IFilterProps>[] {
    const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);

    return [
        {
            id: FILTERS_KEYS.ADD_FILTER,
            content: {
                filterProps: { key: FILTERS_KEYS.ADD_FILTER },
                renderFunction: renderAddFilter,
            },
        },
        {
            id: FILTERS_KEYS.DATE_PICKER,
            content: {
                filterProps: {
                    key: FILTERS_KEYS.DATE_PICKER,
                    defaultValue: DEFAULT_RANGES_VALUES.ONE_HOUR,
                    options: [
                        DEFAULT_RANGES_VALUES.ONE_HOUR,
                        DEFAULT_RANGES_VALUES.FOUR_HOURS,
                        DEFAULT_RANGES_VALUES.TWELVE_HOURS,
                        DEFAULT_RANGES_VALUES.ONE_DAY,
                        DEFAULT_RANGES_VALUES.WEEK,
                    ],
                    title: i18n.t('FILTER_PANEL.DATE_FILTER.TITLE'),
                    limitations: { maxDaysSelected: 60, maxDaysBack: 60 },
                },
                keyInObjectForAPI: LogFields.eventTime,
                displayTypes: [FILTER_DISPAY_TYPES.ROW],
                renderFunction: renderDefaultDateFilter,
            },
        },
        {
            id: FILTERS_KEYS.CLEAR_BUTTON,
            content: {
                filterProps: {
                    key: FILTERS_KEYS.CLEAR_BUTTON,
                },
                renderFunction: renderClearAll,
            },
        },
        {
            id: FILTERS_KEYS.SAVED_FILTERS,
            content: function (params: IProtectedAssetFilterParams & { savedFiltersComponentName: string }) {
                const filtersInitialData = params?.filtersInitialData ?? {};
                return {
                    filterProps: {
                        savedFilters: filtersInitialData?.savedFilters || [],
                        savedFiltersComponentName: params.savedFiltersComponentName,
                        selectedFilterID: params.savedFiltersComponentName,
                        key: FILTERS_KEYS.SAVED_FILTERS,
                    },
                    renderFunction: renderSavedFilters,
                };
            },
        },
        {
            id: FILTERS_KEYS.RECENTLY_USED_FILTERS,
            content: function (params: IProtectedAssetFilterParams & { recentlyUsedComponentName: string }) {
                const filtersInitialData = params?.filtersInitialData ?? {};
                return {
                    filterProps: {
                        key: FILTERS_KEYS.RECENTLY_USED_FILTERS,
                        recentlyUsedList: filtersInitialData?.recentlyUsed,
                        maxLength: 4,
                        componentName: params.recentlyUsedComponentName,
                        title: i18n.t('FILTER_PANEL.RECENTLY_USED_FILTERS'),
                    },
                    renderFunction: renderRecentlyUsedFilters,
                };
            },
        },
        {
            id: LogFilterFields.eventStatus,
            content: function (params: IProtectedAssetFilterParams) {
                const eventStatuses = Object.values(eventStatus);
                const aggregations: Aggregations = params?.aggregations ?? {};
                for (const status of eventStatuses) {
                    if (
                        !aggregations[LogFields.eventStatus]?.find((item: { value: string }) => item.value === status)
                    ) {
                        aggregations[LogFields.eventStatus]?.push({ value: status, count: 0 });
                    }
                }
                const displayMapping: IDisplayMappingObject = {};
                aggregations[LogFields.eventStatus]?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: item.value,
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations[LogFields.eventStatus],
                        key: LogFields.eventStatus,
                        title: t('LOGS_TABLE.FILTERS.STATUS.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: LogFilterFields.eventAws,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                ensureDefaultBooleanAggregations(aggregations, LogFields.eventAws);
                return {
                    filterProps: {
                        initialData: aggregations[LogFields.eventAws],
                        key: LogFields.eventAws,
                        title: t('LOGS_TABLE.FILTERS.EVENT_AWS.TITLE'),
                        displayMapping: getYesNoDisplayMapping(),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
    ];
}

export function initializeFilterDefs() {
    LogsTableRegistry.addFilterDefs(getFilterDefs());
}
