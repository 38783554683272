import { DASHBOARD_DYNAMIC_WIDGETS } from 'common/module_interface/overview/Consts';
import { DashboardWidgetTypes, IAddinWidgetsDataSource } from 'common/module_interface/overview/Interface';
import { ILogicWidgetsOptions, createWidgets } from './Utils';
import { TRAFFIC_ACTIVITY_KUBERNETES, defaultIntelligenceWidgetsTypeOptions } from './Consts';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';

export const initialize_K8_TrafficActivityWidgets = () => {
    const K8_trafficActivityOptions: ILogicWidgetsOptions = {
        action: defaultIntelligenceWidgetsTypeOptions,
        direction: defaultIntelligenceWidgetsTypeOptions,
        'dst.address': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.assetid': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.clusterid': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.controllergroups.name': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.controllergroups.type': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.image': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.ispublic': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.k8sServices': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.name': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.namespace': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.nics.id': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.nics.privateipaddress': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.nics.publicdnsname': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.nics.publicipaddress': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.nodeName': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.region': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.subtype': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.type': defaultIntelligenceWidgetsTypeOptions,
        'dst.geolocation.countryname': defaultIntelligenceWidgetsTypeOptions,
        'dst.ismalicious': defaultIntelligenceWidgetsTypeOptions,
        'dst.maliciousinfo.class': defaultIntelligenceWidgetsTypeOptions,
        'dst.maliciousinfo.confidence': defaultIntelligenceWidgetsTypeOptions,
        'dst.maliciousinfo.malwarefamily': defaultIntelligenceWidgetsTypeOptions,
        'dst.maliciousinfo.owner': defaultIntelligenceWidgetsTypeOptions,
        'dst.port': defaultIntelligenceWidgetsTypeOptions,
        eni: defaultIntelligenceWidgetsTypeOptions,
        packets: defaultIntelligenceWidgetsTypeOptions,
        protocol: defaultIntelligenceWidgetsTypeOptions,
        'src.address': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.assetid': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.clusterid': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.controllergroups.name': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.controllergroups.type': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.image': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.ispublic': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.k8sServices': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.name': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.namespace': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.nics.id': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.nics.privateipaddress': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.nics.publicdnsname': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.nics.publicipaddress': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.nodeName': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.region': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.subtype': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.type': defaultIntelligenceWidgetsTypeOptions,
        'src.geolocation.countryname': defaultIntelligenceWidgetsTypeOptions,
        'src.ismalicious': defaultIntelligenceWidgetsTypeOptions,
        'src.maliciousinfo.class': defaultIntelligenceWidgetsTypeOptions,
        'src.maliciousinfo.confidence': defaultIntelligenceWidgetsTypeOptions,
        'src.maliciousinfo.malwarefamily': defaultIntelligenceWidgetsTypeOptions,
        'src.maliciousinfo.owner': defaultIntelligenceWidgetsTypeOptions,
        'src.port': defaultIntelligenceWidgetsTypeOptions,
        starttime: defaultIntelligenceWidgetsTypeOptions,
        vpc: defaultIntelligenceWidgetsTypeOptions,
        'dst.geolocation.countrycode': [DashboardWidgetTypes.Map, DashboardWidgetTypes.MapLinks],
        'src.geolocation.countrycode': [DashboardWidgetTypes.Map, DashboardWidgetTypes.MapLinks],
    };

    const K8_TrafficActivityWidgets: IAddinWidgetsDataSource = {
        dataSourceName: TRAFFIC_ACTIVITY_KUBERNETES,
        dataField: { displayName: 'Aggregation', path: 'aggregation' },
        widgets: createWidgets(K8_trafficActivityOptions),
    };

    globalAddinContainer.addMap(DASHBOARD_DYNAMIC_WIDGETS, [K8_TrafficActivityWidgets], 'dataSourceName');
};
