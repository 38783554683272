import React, { useEffect, useRef, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { GRAPH_CLASSES, NODE_SIZE } from '../../Common/Consts/GraphProperties';
import { AssetNodeProps } from 'common/components/Graph/Models/AssetNodeProps';
import { IntelligenceNodeData } from 'common/module_interface/intelligence/intelligenceNodeData';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../initialize.i18n';
import { LayoutDirection } from 'common/components/Graph/Models/LayoutDirection';
import { useSelector } from 'react-redux';
import { getVisualizationGraphState } from '../../Common/Services/GraphState.reducer';
import NetworkTrafficNodePopoverComponent from './NetworkTrafficNodePopoverComponent';
import { Icon, Tooltip, Typography } from 'common/design-system/components-v2';
import CopiableLine from 'common/design-system/components-v2/CopiableLine';
import { preventNodeSelection } from '../../Common/Utils';
import { colors, lightPallete } from 'common/design-system/theme/colors';

const InternetNode: React.FC<AssetNodeProps> = (props: AssetNodeProps) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const { data, isConnectable } = props;
    const intelligenceAssetNodeData = data as IntelligenceNodeData;
    const customSize = NODE_SIZE;
    const [sourceHandlePosition, setSourceHandlePosition] = useState<Position>(Position.Left);
    const [targetHandlePosition, setTargetHandlePosition] = useState<Position>(Position.Right);
    const graphDirectionState: LayoutDirection | undefined = useSelector(getVisualizationGraphState);

    useEffect(() => {
        if (graphDirectionState === LayoutDirection.RightToLeft) {
            setSourceHandlePosition(Position.Right);
            setTargetHandlePosition(Position.Left);
        }
    }, [graphDirectionState]);

    const handleRef = useRef(null);

    return (
        <>
            <Handle
                type='target'
                position={sourceHandlePosition}
                style={{
                    top: '50.1%',
                    background: 'transparent',
                    width: 0,
                    height: 0,
                    border: 'none',
                }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />
            <Tooltip
                content={NetworkTrafficNodePopoverComponent(intelligenceAssetNodeData)}
                interactive={true}
                appendTo={() => document.body}
                delay={200}
                maxWidth={400}
            >
                <div className={'flex flex-col items-center'}>
                    <div
                        className={`flex flex-col items-center ${GRAPH_CLASSES.NODE}-${intelligenceAssetNodeData.classification}`}
                    >
                        <div className='flex flex-col items-center'>
                            <div
                                className={`flex flex-col items-center group rounded-full overflow-hidden p-2 ${intelligenceAssetNodeData.selected ? 'shadow-md shadow-strong' : ''} hover:shadow-md transition-shadow theme theme-dark`}
                                style={{
                                    background: colors.grey['200'],
                                    fill: '#ffffff',
                                    borderWidth: intelligenceAssetNodeData.selected ? '2px' : '0px',
                                    borderColor: '#2E3F57',
                                }}
                            >
                                <div
                                    className='bg-svg-element grid place-items-center '
                                    style={{
                                        width: `${customSize}px`,
                                        height: `${customSize}px`,
                                    }}
                                >
                                    <Icon name={'web'} size={32} customColor={lightPallete.onSurface.reverse} />
                                </div>
                            </div>
                        </div>
                        <div
                            className='absolute top-[85px] flex flex-col items-center z-100'
                            onClick={preventNodeSelection}
                        >
                            <div className=' w-[20ch] rounded-full px-5 font-medium text-center truncate'>
                                {t('INT_GRAPH.NODES.INTERNET')}
                            </div>
                            {intelligenceAssetNodeData.additionalData?.ip && (
                                <CopiableLine value={intelligenceAssetNodeData.additionalData?.ip}>
                                    <Typography variant='body' color='light'>
                                        {intelligenceAssetNodeData.additionalData?.ip}
                                    </Typography>
                                </CopiableLine>
                            )}
                        </div>
                    </div>
                </div>
            </Tooltip>
            <Handle
                ref={handleRef}
                type='source'
                position={targetHandlePosition}
                isConnectable={isConnectable}
                style={{
                    top: '49.9%',
                    background: 'transparent',
                    width: 0,
                    height: 0,
                    border: 'none',
                }}
            />
        </>
    );
};

export default InternetNode;
