import { ermTrans } from '../../../RiskManagement.utils';
import { isEmptyItemValue } from 'common/erm-components/custom/CustomForm/CustomForm.values';
import { IItem, IItemsMap } from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import { ICommonIgnoreItem, ICommonIgnoreItemPartial } from './CommonIgnoreList.interface';
import { RequiredOneOfVulnerableFields } from './CommonIgnoreList.consts';

export const checkOneOfVulnerableFields = async (item: IItem, itemsMap: IItemsMap): Promise<string | undefined> => {
    if (RequiredOneOfVulnerableFields.some((name) => !isEmptyItemValue(itemsMap[name]))) {
        return;
    }

    return ermTrans('IGNORE_LIST.COMMON.VALIDATION.AT_LEAST_ONE_VULNERABLE_COND_IS_REQUIRED');
};

export const getOtherIgnoreItemNames = async (
    fetchAllItems: () => Promise<ICommonIgnoreItem[]>,
    ignoreItem?: ICommonIgnoreItemPartial,
): Promise<string[]> => {
    const allIgnoreItems: ICommonIgnoreItem[] = await fetchAllItems();
    const otherItems: ICommonIgnoreItem[] = ignoreItem?.id
        ? allIgnoreItems.filter((item) => item.id !== ignoreItem.id)
        : allIgnoreItems;
    return otherItems.map((item) => item.name);
};
