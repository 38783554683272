import React from 'react';
import { EdgeProps } from 'reactflow';
import AccountActivityEdge from '../../AccountActivity/Components/AccountActivityEdge';
import NetworkTrafficEdge from '../../NetworkTraffic/Components/NetworkTrafficEdge';
import { EdgeData, Relationship } from '../Models/GraphModels';
import { LayoutDirection } from 'common/components/Graph/Models/LayoutDirection';
import { LogType } from 'common/module_interface/intelligence/Logs/LogSources';

export enum IntelligenceEdgeType {
    AccountActivity = 'AccountActivityEdge',
    NetworkTraffic = 'NetworkTrafficEdge',
}

export const edgeTypes: Record<IntelligenceEdgeType, React.FC<EdgeProps>> = {
    [IntelligenceEdgeType.AccountActivity]: AccountActivityEdge,
    [IntelligenceEdgeType.NetworkTraffic]: NetworkTrafficEdge,
};

export function getIntelligenceEdgeType(relationship: Relationship): IntelligenceEdgeType {
    if (relationship.type === LogType.ACCOUNT_ACTIVITY) {
        return IntelligenceEdgeType.AccountActivity;
    }

    if (relationship.type === LogType.NETWORK_ACTIVITY) {
        return IntelligenceEdgeType.NetworkTraffic;
    }

    return IntelligenceEdgeType.AccountActivity;
}

export function isEdgeAnimated(relationship: Relationship): boolean {
    if (relationship.type === LogType.ACCOUNT_ACTIVITY) {
        return !!relationship.additionalData?.accountActivity?.occurrences;
    }

    return relationship.type === LogType.NETWORK_ACTIVITY;
}

export function getIntelligenceEdgeAdditionalData(relationship: Relationship): EdgeData {
    if (relationship.type === LogType.ACCOUNT_ACTIVITY && relationship.additionalData?.accountActivity) {
        return {
            additionalData: {
                events: relationship.additionalData.accountActivity.events,
                occurrences: relationship.additionalData.accountActivity.occurrences,
            },
        };
    }

    if (relationship.type === LogType.NETWORK_ACTIVITY && relationship.additionalData?.networkActivity) {
        return {
            additionalData: {
                occurrences: relationship.additionalData.networkActivity.occurrences,
                port: relationship.additionalData.networkActivity.port,
                protocol: relationship.additionalData.networkActivity.protocol,
                time: relationship.additionalData.networkActivity.time,
                direction: relationship.additionalData.networkActivity.direction,
            },
        };
    }

    return {};
}

export function getArrowHead(type: LayoutDirection): string {
    if (type === LayoutDirection.LeftToRight) {
        return 'M2.1,15c-1,0.5-2.1-0.2-2.1-1.3V8V2.3C0,1.3,1.1,0.6,2.1,1l11.3,5.7c1.1,0.5,1.1,2.1,0,2.6L2.1,15z';
    }

    return 'M-2.1,15c1,0.5,2.1-0.2,2.1-1.3V8V2.3C0,1.3-1.1,0.6-2.1,1L-11.3,5.7c-1.1,0.5-1.1,2.1,0,2.6L-2.1,15z';
}
