export enum ActionsIds {
    RequestScan = 'request-scan',
    AddBaseImageRule = 'add-base-image-rule',
    AutoProtect = 'auto-protect',
    ProtectionMode = 'protection-mode',
}

export enum FspMode {
    PreventAndDetect = 'Block',
    DetectOnly = 'Alert',
}

export enum ProtectionMode {
    Enable = 'enable',
    Disable = 'disable',
}

export enum ActionMode {
    AutoProtect = 'AUTO_PROTECT',
    ProtectionMode = 'PROTECTION_MODE',
}
