import { GraphData } from '../../../Visualizations/Common/Models/GraphModels';
import { getEntityFindingsData } from './IntelligenceHttpService';
import { ExpandedFindingDetailsProps } from '../Models/IntelligenceRequest';
import { getIntelligenceService } from 'common/module_interface/intelligence/intelligence';
import { getIntelligenceSource } from './Utils';
import { graphDirection } from '../Models/IntelligenceResult';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { IElasticEvent } from 'common/module_interface/elasticEvents/ElasticEvent.interface';

export async function getIntelligenceData(
    expandedFindingDetailsProps: ExpandedFindingDetailsProps,
    setGraphDirection: Function,
) {
    const { finding } = expandedFindingDetailsProps;
    if (!finding) return;

    expandedFindingDetailsProps.intelligenceSource = getIntelligenceSource(finding);
    const result = await getIntelligenceService().getIntelligenceFindingGraph(finding.findingKey);
    if (!result) return;

    setGraphDirection(graphDirection[result.direction]);

    return new GraphData(result.entities, result.relationships);
}

export async function getFindingsData(
    entityId: string,
    entityName: string,
    finding: IKustoEvent,
): Promise<IElasticEvent[] | undefined> {
    try {
        if (!finding || !entityId) return;
        return await getEntityFindingsData(entityId, entityName, finding);
    } catch (error: any) {
        console.error(error);
        return undefined;
    }
}
