import { Stack, Typography } from 'common/design-system/components-v2';
import CopiableLine from 'common/design-system/components-v2/CopiableLine';

export interface NodeDetailsItem {
    label: string;
    value?: string;
    isRelevant: boolean;
}
interface NodePopoverComponentProps {
    NodePopoverDetails: NodeDetailsItem[];
}
const NodeDetailsComponent = (props: NodePopoverComponentProps) => {
    return (
        <Stack direction={'column'}>
            {props.NodePopoverDetails?.map((item) => {
                return (
                    item.value &&
                    item.isRelevant && (
                        <Stack direction={'row'} spacing={1} key={item.label}>
                            <Typography variant='bodyXs' color='strong'>
                                {item.label}:
                            </Typography>
                            <CopiableLine value={item.value}>
                                <Typography variant='bodyXs' color='light' className={'truncate max-w-[30ch] flex-1'}>
                                    {item.value}
                                </Typography>
                            </CopiableLine>
                        </Stack>
                    )
                );
            })}
        </Stack>
    );
};

export default NodeDetailsComponent;
