import { changeUrl, generateHref } from 'common/utils/http';
import React, { useMemo } from 'react';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import useRelatedEnvironments from 'modules/workloads/hooks/useRelatedEnvironments';
import { Icon, Stack, Tooltip, Typography } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';

export interface EnvironmentLinkProps {
    dome9Id: string;
}

type GetUrlParams = { kubernetesAccountId?: string; onboardedEnvironmentName?: string; environmentType?: string };
const getUrl = ({ kubernetesAccountId, onboardedEnvironmentName, environmentType }: GetUrlParams) =>
    kubernetesAccountId
        ? `/cloud-account/kubernetes/${kubernetesAccountId}`
        : `/workload/cloud-add/kubernetes?kubernetesClusterName=${onboardedEnvironmentName}&kubernetesDescription=${`${onboardedEnvironmentName}-${environmentType}`}`;

const KubernetesEnvironmentLink: React.FC<EnvironmentLinkProps> = ({ dome9Id }) => {
    const { t } = useTranslation('k8s_protected-asset-details');
    const { isLoading, data } = useRelatedEnvironments({ dome9Id });
    const url = getUrl({
        kubernetesAccountId: data?.onboardedEnvironment?.kubernetesAccountId,
        onboardedEnvironmentName: data?.managedEnvironment?.name,
        environmentType: data?.managedEnvironment?.type,
    });
    const options: IIconLinkCellProps = {
        isLink: true,
        value: data?.onboardedEnvironment?.name || t('infoPane.unsecured'),
        href: generateHref(url),
        onClick: (event: any) => {
            event.preventDefault();
            changeUrl(url);
        },
        iconProps: {
            vendorNameOrPath: data?.onboardedEnvironment ? 'kubernetes' : 'inactive',
            color: data?.onboardedEnvironment ? undefined : 'danger',
        },
    };

    const tooltipContent = useMemo(() => {
        if (data?.onboardedEnvironment || isLoading) return '';

        return [
            <Stack key='main-tooltip-stack' direction='row' spacing={2} alignItems='flex-start' padding={1}>
                <Icon key='tooltip-icon' name='kubernetes' size={24} />
                <Stack key='tooltip-stack' spacing={1}>
                    <Typography key='typo-header' variant='bodyLg'>
                        {t('infoPane.tooltip.title')}
                    </Typography>
                    <Typography key='typo-body' color='light'>
                        {t('infoPane.tooltip.body')}
                    </Typography>
                </Stack>
            </Stack>,
        ];
    }, [data?.onboardedEnvironment, isLoading, t]);

    if (isLoading) return <>{t('infoPane.loading')}</>;

    return (
        <Tooltip placement='right' content={tooltipContent}>
            <span>
                <IconLinkCellRenderer {...options} />
            </span>
        </Tooltip>
    );
};

export default KubernetesEnvironmentLink;
