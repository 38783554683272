import { GridOptions } from 'ag-grid-community';
import { CheckboxCellRenderer } from '../CellRenderers/CheckboxCellRenderer';
import { AgGridReact } from 'ag-grid-react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { I18nSettingsTranslationKey } from '../../../initialize';
import { InfoCellRenderer } from '../CellRenderers/InfoCellRenderer';
import {
    directPermissionName,
    infoColumnWidth,
    inheritedRolesColumnWidth,
    IPermissionComponentProps,
    IPermissionItem,
    PERMISSION_TYPE,
    PermissionCategorySubType,
    PermissionViewMode,
} from '../interfaces';
import { v4 as uuid } from 'uuid';
import { isPermissionItemsMatch } from '../utils';
import { getAccountService, IAccount } from 'common/interface/services';
import { RowGroupOpenedEvent } from 'ag-grid-community/dist/lib/events';
import { InheritedRolesCellRenderer } from '../CellRenderers/InheritedRolesCellRenderer';

export const AccountAccessControls: FC<IPermissionComponentProps> = ({
    accountAccessStatus,
    viewMode,
    permissions,
    permissionsChanged,
}) => {
    const { t } = useTranslation(I18nSettingsTranslationKey);

    const reviewMode = viewMode === PermissionViewMode.REVIEW;
    const [rowData, setRowData] = useState<IPermissionItem[]>([]);
    const [assumeRoles, setAssumeRoles] = useState<IAccount[]>([]);
    const [filteredData, setFilteredData] = useState<IPermissionItem[]>([]);
    const init = async () => {
        const assumeRoles = await getAccountService().getAccountAndRoles();
        setAssumeRoles(assumeRoles);
        const permissionItems = assumeRoles.map((account) => {
            return {
                type: PERMISSION_TYPE.ACCOUNT_ACCESS,
                categorySubType: PermissionCategorySubType.NETWORK_ACCOUNT_ACCESS,
                userAccount: account,
                name: account.accountName,
                path: [account.accountName],
                userRole: '*',
                enable: false,
                info: t('TOOLTIPS.DEFAULTS.NETWORK_CONTROLS'),
                id: uuid(),
            } as IPermissionItem;
        });
        const items = permissionItems.map((item) => {
            const path = item.path || [];
            return {
                id: uuid(),
                categorySubType: PermissionCategorySubType.NETWORK_ACCOUNT_ACCESS,
                type: PERMISSION_TYPE.PLACEHOLDER,
                path: [...path, '...'],
            } as IPermissionItem;
        });

        setRowData([...permissionItems, ...items]);
    };

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCellClicked = () => {
        if (permissionsChanged) {
            permissionsChanged(filteredData!);
        }
    };

    useEffect(() => {
        let _permissions = rowData;
        if (permissions?.length) {
            const _rowData = rowData.filter(
                (item) => !permissions.some((permission) => isPermissionItemsMatch(permission, item)),
            );
            _permissions = [...permissions, ..._rowData];
        }
        if (reviewMode) {
            setFilteredData(_permissions.filter((item) => item?.enable));
        } else {
            setFilteredData(_permissions);
        }
    }, [permissions, reviewMode, rowData]);

    const getBaseColumns = () => {
        const baseColumns = [];
        if (reviewMode) {
            baseColumns.push({
                field: 'inheritedRoles',
                cellRenderer: InheritedRolesCellRenderer,
                headerName: t('HEADERS.ROLES'),
                width: inheritedRolesColumnWidth,
            });
        }
        baseColumns.push({
            field: 'info',
            headerName: t('HEADERS.INFO'),
            width: infoColumnWidth,
            cellRenderer: InfoCellRenderer,
            cellRendererParams: { permissionSubType: PermissionCategorySubType.NETWORK_ACCOUNT_ACCESS },
        });
        return baseColumns;
    };
    const gridOptions: GridOptions = {
        defaultColDef: {
            suppressMenu: true,
        },
        getRowClass: (params) => {
            if (params.data && params.data.type !== PERMISSION_TYPE.PLACEHOLDER) {
                const name = params.data?.name;
                return name ? `row-${name.replaceAll(' ', '-')}` : '';
            }
        },
        getRowId: (params) => {
            return params.data?.path?.join() || '';
        },
        getRowHeight: (params) => {
            if (params.node?.data?.type === PERMISSION_TYPE.PLACEHOLDER) {
                return 0;
            }
        },
        treeData: true,
        domLayout: 'autoHeight',
        autoGroupColumnDef: {
            flex: 1,
            headerName: t('HEADERS.ACCESS_LEVEL'),
            minWidth: 280,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
                suppressCount: true,
            },
        },
        getDataPath: (data) => {
            const permissionItem = data as IPermissionItem;
            return permissionItem.path || [];
        },
        columnDefs: [
            ...getBaseColumns(),
            {
                field: 'enable',
                headerName: t('HEADERS.ENABLE'),
                cellRenderer: CheckboxCellRenderer,
                width: 80,
                cellRendererParams: { reviewMode: reviewMode || accountAccessStatus?.readonly },
            },
        ],
    };

    const OnTreeExpand = (event: RowGroupOpenedEvent) => {
        if (event.node.data && event.node.data.populated) return;
        if (event.node.data) {
            event.node.data.populated = true;
        }
        const accountName = event.node.key;
        const account = assumeRoles.find((item) => item?.accountName === accountName);
        const items = account?.roles?.map((role) => {
            const _permission = permissions?.find(
                (a) => a.userAccount?.accountId === account?.accountId && (a.userRole === role || a.userRole === '*'),
            );
            return {
                type: PERMISSION_TYPE.ACCOUNT_ACCESS,
                categorySubType: PermissionCategorySubType.NETWORK_ACCOUNT_ACCESS,
                name: role,
                userRole: role,
                enable: _permission ? true : false,
                inheritedRoles:
                    _permission?.inheritedRoles?.filter((_role: string) => _role !== directPermissionName) || [],
                userAccount: account,
                path: [accountName, role],
            } as IPermissionItem;
        }) as IPermissionItem[];

        setRowData((prevPermissions) => [
            ...prevPermissions.filter((item) => {
                return item && !(item.type === PERMISSION_TYPE.PLACEHOLDER && item.path?.[0] === accountName);
            }),
            ...items,
        ]);
    };

    return (
        <div data-aid={'access-control'}>
            {reviewMode && !filteredData?.length ? (
                ''
            ) : (
                <AgGridReact
                    onRowGroupOpened={OnTreeExpand}
                    onCellClicked={onCellClicked}
                    className={'ag-theme-alpine'}
                    rowData={filteredData}
                    gridOptions={gridOptions}
                    animateRows={true}
                />
            )}
        </div>
    );
};
