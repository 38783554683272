export enum REPORTING_ISSUE_TYPE {
    FALSE_POSITIVE = 'False positive',
    WRONG_SEVERITY = 'Wrong severity',
    REMEDIATION_ISSUE = 'Remediation issue',
    INCORRECT_INFORMATION = 'Incorrect information',
    OTHER = 'Other',
}

export const FormFields = {
    ISSUE_TYPE: 'issueType',
    ISSUE_DETAILS: 'issueDetails',
};
