import React from 'react';
import { Stack, Typography } from 'common/design-system/components-v2';
import { ICellRendererParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { EColumnGroupState, getColumnGroupState, isTrue } from 'modules/workloads/utils/aggrid';
import { Dot } from '../../components/Dot/Dot';

const BooleanStringCellRenderer = (fieldName: string, i18nKeyPrefix: string) => {
    const Component: React.FC<ICellRendererParams> = (params) => {
        const { node, value: paramValue } = params;
        const { t } = useTranslation(getK8sNamespace('vulnerability'));
        const groupState = getColumnGroupState(fieldName, node);

        if (groupState === EColumnGroupState.OTHER) {
            return null;
        }

        const value = node.groupData?.['ag-Grid-AutoColumn'] ?? paramValue;

        const renderItems: { isRelevant: () => boolean; render: () => JSX.Element }[] = [
            {
                isRelevant: () => !!value,
                render: () => <Dot isTrue={isTrue(value)} />,
            },
            {
                isRelevant: () => value && groupState === EColumnGroupState.SELF,
                render: () => <Typography>{t(`${i18nKeyPrefix}.${value}`)}</Typography>,
            },
            {
                isRelevant: () => !value && groupState === EColumnGroupState.SELF,
                render: () => <Typography>{t('VulnerabilityTable.cells.blank')}</Typography>,
            },
        ];

        const packageId = node.data?.packageId || 'package-id-unknown';
        const cveId = node.data?.id || 'cve-id-unknown';
        const rowId = `${packageId}-${cveId}`;

        return (
            <Stack direction='row' alignItems='center' justifyContent='center' fullHeight fullWidth spacing={1}>
                {renderItems
                    .filter((renderItem) => renderItem.isRelevant())
                    .map((renderItem, index) => {
                        const RenderItem = renderItem.render;
                        return <RenderItem key={`${rowId}_${fieldName}-${index}`} />;
                    })}
            </Stack>
        );
    };
    return Component;
};

export default BooleanStringCellRenderer;
