import { ShiftLeftAccount } from '../../services/shiftLeft/shiftLeftAccount.interface';

export interface ShiftLeft {
    account: ShiftLeftAccount;
}

export interface ShiftLeftState {
    shiftLeftEvnironments: {
        [shiftLeftId: string]: {
            data?: ShiftLeft;
            loading?: boolean;
            error?: boolean;
            deleted?: boolean;
        };
    };
}

export const ShiftLeftInitialState: ShiftLeftState = {
    shiftLeftEvnironments: {},
};
