import React from 'react';
import { Stack } from 'common/design-system/components-v2';
import { CveOverview } from '../../services/cveDrawerRegistry/cveDrawerRegistry.types';
import UpperPanelVul from '../../pages/cveDrawer/components/UpperPanelVul';
import DescriptionPanel from '../../pages/cveDrawer/components/DescriptionPanel';
import AttackVectorPanel from '../../pages/cveDrawer/components/AttackVectorPanel/AttackVectorPanel';
import { RemediationPanel } from '../../pages/cveDrawer/components/RemediationPanel/RemediationPanel';
import ResourcePanel from '../../pages/cveDrawer/components/ResourcePanel';
import CveReferences from '../../pages/cveDrawer/components/CveReferences/CveReferences';
import BaseImageAssetPropertiesPanel from './BaseImageAssetPropertiesPanel/BaseImageAssetPropertiesPanel';
import CveIgnore from '../../components/CveIgnore/CveIgnore';
import { getCveAndPackageById } from '../../services/vulnerability/utils';
import { GetVulnerabilityScan } from '../../services/vulnerability/vulnerability.interface';

const VulnerabilityDrawer: React.FC<{
    cveId: string;
    packageId: string;
    data: GetVulnerabilityScan.Response;
}> = (props) => {
    const { cveId, packageId, data } = props;
    const packageAndCve = getCveAndPackageById(cveId, packageId, data);
    let cveParams: CveOverview | null = null;

    if (packageAndCve) {
        cveParams = {
            cve: {
                ...packageAndCve.cve,
                isFixable: packageAndCve.cve.isFixedByPackageRemediation,
                cvssInfo: packageAndCve.cve['cvss-info'],
                vectorString: packageAndCve.cve['cvss-info']?.['vector-string'] || '',
            },
            package: {
                ...packageAndCve.package,
                isOsPackage: packageAndCve.package['is-os-package'],
            },
            accountId: '',
            cloudAccountId: '',
            affectedAsset: null,
            scannedAsset: null,
            dome9Id: '',
            cloudAccountName: '',
            platform: '',
        };
    }

    return (
        <>
            {cveParams ? (
                <Stack direction='column' spacing={5} fullWidth>
                    <UpperPanelVul overview={cveParams} />
                    <CveIgnore isExcluded={cveParams.cve.isExcluded} exclusion={cveParams.cve.exclusion} />
                    <DescriptionPanel overview={cveParams} />
                    {!!cveParams.cve.cvssInfo && <AttackVectorPanel overview={cveParams} />}
                    {!!cveParams.cve.remediation && <RemediationPanel remediation={cveParams.cve.remediation} />}
                    {Boolean(cveParams.package?.baseImages?.length) ? (
                        <BaseImageAssetPropertiesPanel cveParams={cveParams} />
                    ) : (
                        <ResourcePanel overview={cveParams} />
                    )}
                    {cveParams.cve.urls?.length > 0 && <CveReferences overview={cveParams} />}
                </Stack>
            ) : null}
        </>
    );
};

export default VulnerabilityDrawer;
