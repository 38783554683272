import { FilterConditionOperator } from 'common/erm-components/custom/FilterTree/FilterCondition';

export enum ToxicPropTypeEnum {
    string = 'string',
    number = 'number',
    boolean = 'boolean',
    date = 'date',
}

export const OR_SPECIAL_OP = '$OR';
export enum ToxicCondOpModelEnum {
    EQUALS = 'EQUALS',
    IN = 'IN',
    CONTAINS = 'CONTAINS',
    STARTS_WITH = 'STARTS_WITH',
    ENDS_WITH = 'ENDS_WITH',
}

export const toxicOpModelToUiMap: { [key in ToxicCondOpModelEnum]: FilterConditionOperator } = {
    [ToxicCondOpModelEnum.EQUALS]: FilterConditionOperator.Equals,
    [ToxicCondOpModelEnum.IN]: FilterConditionOperator.In,
    [ToxicCondOpModelEnum.CONTAINS]: FilterConditionOperator.Contains,
    [ToxicCondOpModelEnum.STARTS_WITH]: FilterConditionOperator.StartsWith,
    [ToxicCondOpModelEnum.ENDS_WITH]: FilterConditionOperator.EndsWith,
};

export const toxicOpUiToModeMap: { [key in FilterConditionOperator]?: ToxicCondOpModelEnum } = {};

Object.keys(toxicOpModelToUiMap).forEach((modelOp: string) => {
    const modelOpEnum: ToxicCondOpModelEnum = modelOp as ToxicCondOpModelEnum;
    const uiOp: FilterConditionOperator = toxicOpModelToUiMap[modelOpEnum];
    toxicOpUiToModeMap[uiOp] = modelOpEnum;
});
