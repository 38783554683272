import { NotificationType } from 'common/interface/notifications';

export enum UiKeys {
    UnsupportedForSaaS = 'UnsupportedForSaaS',
    HubSelfScanDisabled = 'HubSelfScanDisabled',
    AccountInDelayPeriod = 'AccountInDelayPeriod',
    OSNotSupported = 'OSNotSupported',
    NotRunningInstance = 'NotRunningInstance',
    SkipTagExist = 'SkipTagExist',
    AzureServerSideEncryption = 'AzureServerSideEncryption',
    AzureDiskEncryption = 'AzureDiskEncryption',
    LicensedImage = 'LicensedImage',
    TooBigVolumeSize = 'TooBigVolumeSize',
    AzureHasUnmanagedDisk = 'AzureHasUnmanagedDisk',
    FunctionAppKindNotSupported = 'FunctionAppKindNotSupported',
    FunctionAppsScanDisabled = 'FunctionAppsScanDisabled',

    VpcError = 'VpcError',
    CustomVpcError = 'CustomVpcError',
    MissingDeleteSnapshotPermission = 'MissingDeleteSnapshotPermission',
    EncryptionDataError = 'EncryptionDataError',
    ScanScopeLocked = 'ScanScopeLocked',
    InvalidAzureToken = 'InvalidAzureToken',
    AlreadyInScanState = 'AlreadyInScanState',

    ScanPrioritized = 'ScanPrioritized',
    InternalError = 'InternalError',
    BadInput = 'BadInput',
}

const supportedUiKeys = new Set(Object.keys(UiKeys));

export const getI18nKeyMessage = (statusCode: number, keyMessage: string): [string, NotificationType] => {
    if (statusCode === 200) {
        return [UiKeys.ScanPrioritized, NotificationType.SUCCESS];
    }
    if (statusCode === 500) {
        return [UiKeys.InternalError, NotificationType.ERROR];
    }
    if (statusCode === 404) {
        return [UiKeys.BadInput, NotificationType.ERROR];
    }
    const type = statusCode >= 400 ? NotificationType.ERROR : NotificationType.SUCCESS;
    return supportedUiKeys.has(keyMessage) ? [keyMessage, type] : [UiKeys.InternalError, NotificationType.ERROR];
};
