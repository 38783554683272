import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Link, Stack, Typography } from 'common/design-system/components-v2';
import { I18nTranslationKey } from '../initServiceAccountsPage';

export const FailedToCreateServiceAccountContent: FC = () => {
    const { t } = useTranslation(I18nTranslationKey);
    const internalPathServiceAccount = 'https://www.checkpoint.com/support-services/contact-support/';

    return (
        <Stack direction={'row'} spacing={3}>
            <Icon name={'error'} size={24} />
            <Stack spacing={4} padding={[1, 0]}>
                <Typography variant={'xl'}>{t('MODALS.CREATE_SERVICE_ACCOUNT_MODAL.ERROR_OCCURRED')}</Typography>
                <Typography variant={'sm'}>
                    {t('MODALS.CREATE_SERVICE_ACCOUNT_MODAL.FAILED_TO_CREATE_SERVICE_ACCOUNT')}
                </Typography>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Typography variant={'sm'}>
                        {t('MODALS.CREATE_SERVICE_ACCOUNT_MODAL.IF_THE_PROBLEM_PERSISTS')}
                    </Typography>
                    <Link externalUrl={internalPathServiceAccount} removeExternalIcon>
                        <Typography context='info' saturation>
                            {t('MODALS.CREATE_SERVICE_ACCOUNT_MODAL.CONTACT_OUR_SUPPORT')}
                        </Typography>
                    </Link>
                </Stack>
            </Stack>
        </Stack>
    );
};
