import React from 'react';
import { OnboardingConfigurationTypes } from '../../helpers/onboarding.interface';
export interface AzureWelcomeForm {
    tenantId?: string;
    subscriptionId?: string;
    managementGroupId?: string;
}

export interface AzureOnboardingState {
    isLoading?: boolean;
    welcomeForm: AzureWelcomeForm;
    name?: string;
    successText?: string;
    isManualOnboardingFlow?: boolean;
    isOrgOnboarding?: boolean;
    azureOnboardingType: AzureOnboardingType;
    azureOnboardingAccountType: AzureOnboardingAccountType;
    azureOnboardingScope: AzureOnboardingScope;
    azureOnboardingOperationMode: AzureOnboardingOperationMode;
    credentials: AzureCredentials;
    allSubscriptionIds?: string[];
    isMarkRunningCommand: boolean;
    onboardingScript?: string;
    commandCloudShell?: string;
    cloudShellUrl?: string;
    isAllowAzureChinaPrivacyPolicy?: boolean;
    isOnBoardingScriptCompleted?: boolean;
    organizationalUnitId?: string;
    isTenantIdAlreadyOnboarded?: boolean | string;
    isSubscriptionIdAlreadyOnboarded?: boolean | string;
    isManagementGroupIdAlreadyOnboarded?: boolean | string;
    activeBlades?: any;
    isAutoOnboarding: boolean;
    azureConfigurations: AzureConfiguration;
}

export interface AzureCredentials {
    clientId?: string;
    clientPassword?: string;
}

export enum AzureOnboardingType {
    single = 'azure-single',
    org = 'azure-org',
}

export enum AzureOnboardingAccountType {
    standard = 'azure',
    gov = 'azuregov',
    china = 'azurechina',
}

export enum AzureOnboardingScope {
    chinaScope = 'chinaScope',
    tenant = 'tenant',
    managementGroup = 'managementGroup',
    subscription = 'subscription',
}

export enum AzureOnboardingOperationMode {
    read = 'Read',
    manage = 'Manage',
}

export enum AzureOnboardingManagementMethod {
    undefined = 'Undefined',
    organizationOnboarding = 'OrganizationOnboarding',
    unifiedOnboarding = 'UnifiedOnboarding',
    simplifiedOnboarding = 'SimplifiedOnboarding',
    manualOnboarding = 'ManualOnboarding',
}

export interface azureCreateAccountRequest {
    name: string;
    subscriptionId: string;
    tenantId: string;
    credentials: AzureCredentials;
    operationMode: string;
    vendor: AzureOnboardingAccountType;
    onboardingManagementMethod: AzureOnboardingManagementMethod;
    OrganizationalUnitId?: string;
}

export interface azureOrgOnboardingRequest {
    organizationName?: string;
    managementGroupId: string;
    tenantId: string;
    appRegistrationName?: string;
    clientId?: string;
    clientSecret?: string;
    vendor?: any;
    useCloudGuardManagedApp: boolean;
    activeBlades: any;
    isAutoOnboarding?: boolean;
}

export interface AzureCommandCloudShellRequest {
    appName?: string;
    subscriptionId?: string;
    managementGroupIdOrTenantId?: string;
    useCloudGuardManagedApp?: boolean;
    OnboardMode?: AzureOnboardingOperationMode;
    activeBlades: AzureConfiguration;
    accountType?: AzureOnboardingAccountType;
}

export interface IInstructionTypeCard {
    onCardSelected?: (val?: any) => void;
    isSelected?: string | boolean;
    config: IInstructionTypeCardContent;
}

export interface IInstructionTypeCardContent {
    title: string;
    subtitle?: string;
    content?: string[];
    componentRender?: any;
    list?: string[];
    className?: string;
    value?: string | number | boolean;
    dataAid?: string;
    [key: string]: any;
}
export interface IInstructionsTextList {
    collapseTitle?: string;
    label: string;
    tooltip?: React.ReactNode;
    textList?: any;
    className?: string;
    render?: any;
}
export enum AzureOnboardingAWPScanType {
    saas = 'saas',
    inAccount = 'inAccount',
    inAccountHub = 'inAccountHub',
}

export interface AzureConfigurationObjectType {
    isEnabled?: boolean;
    onboardingMode?: AzureOnboardingAWPScanType | string;
    centralizedSubscriptionId?: string;
    withFunctionAppsScan?: boolean;
    accounts?: AzureCDRConfigurationAccountType[];
    withSseCmkEncryptedDisksScan?: boolean;
}

export type AzureConfiguration = {
    [key in OnboardingConfigurationTypes]?: AzureConfigurationObjectType;
};

export interface AzureCDRConfigurationAccountType {
    key?: string;
    storageId: string;
    logTypes: string[];
}

export enum CDRAssetType {
    activityLogs = 'ActivityLogs',
    signIns = 'SignIns',
    auditLogs = 'AuditLogs',
    storageAccounts = 'StorageAccounts',
}

export type LogTypesInstructionsType = { [key in CDRAssetType]: ICollapseList };

export interface ICollapseList extends IInstructionsTextList {
    collapseTitle: string;
}

export interface CDRAccountProps {
    onChange: (value: any) => void;
    onRemove?: () => void;
    initialValue?: AzureCDRConfigurationAccountType;
    index: number;
}

export interface CDRConfigSectionProps {
    onChange: (value: any) => void;
    initialValue?: AzureConfigurationObjectType;
}
