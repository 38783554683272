import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Spinner, Stack } from 'common/design-system/components-v2';
import { IEnvironment } from 'common/interface/environmentsTable';
import { ContainerRegistryType, KubernetesPlatformType } from 'modules/workloads/services/workload/workload.interface';

export type WorkloadType = KubernetesPlatformType | ContainerRegistryType;

interface IWorkloadTypeCellRendererProps extends ICellRendererParams<IEnvironment> {}

const WorkloadTypeCellRenderer = React.forwardRef<any, IWorkloadTypeCellRendererProps>((params, ref) => {
    const [type, setType] = React.useState<WorkloadType | undefined>(params.data?.['customData|workloadPlatformType']);
    const [isLoading, setIsLoading] = React.useState<boolean>(params.data?.['customData|workloadEnvironmentLoading']);

    React.useImperativeHandle(ref, () => {
        return {
            refresh(params: IWorkloadTypeCellRendererProps) {
                setType(params.data?.['customData|workloadPlatformType']);
                setIsLoading(params.data?.['customData|workloadEnvironmentLoading']);
                return true;
            },
        };
    });

    const typeText = React.useMemo(() => {
        switch (type) {
            case KubernetesPlatformType.NA:
                return 'N/A';
            default:
                return type;
        }
    }, [type]);

    if (isLoading) {
        return (
            <Stack justifyContent='center' alignItems='flex-start' fullHeight fullWidth>
                <Spinner size={16} />
            </Stack>
        );
    }

    return typeText;
});
WorkloadTypeCellRenderer.displayName = 'WorkloadTypeCellRenderer';

export default WorkloadTypeCellRenderer;
