import React, { useState, useCallback } from 'react';
import fileDownload from 'js-file-download';
import useVulnerabilityScan from 'modules/workloads/services/vulnerability/hooks/useVulnerabilityScan';
import useVulnerabilityScanMetadata from 'modules/workloads/services/vulnerability/hooks/useVulnerabilityScanMetadata';
import { Tabs, Stack } from 'common/design-system/components-v2';
import { ITabItemProps } from 'common/design-system/components-v2/Tabs/Tabs.types';
import Threats from './VulnerabilityTabs/Threats/Threats';
import Cves from './VulnerabilityTabs/Cves/Cves';
import Remediations from './VulnerabilityTabs/Remediations/Remediations';
import { restructureThreats, flattenPackageAndCve, flattenRemediation } from './utils';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import { ITableExportButton } from 'common/design-system/components-v2/Table/Table.types';
import { getHttpService, getNotificationsService } from 'common/interface/services';
import ScanStatusTag from '../components/ScanStatus';
import moment from 'moment-mini';

const TABS = [
    { label: 'CVEs', value: 'cves' },
    { label: 'Threats', value: 'threats' },
    { label: 'Secrets (EA)', value: 'secrets' },
    { label: 'Remediations Summary', value: 'remediations' },
];

const Vulnerability: React.FC<{ id: string; type: string; environmentId: string }> = ({ id, type, environmentId }) => {
    const [remediationOnly, setRemediationOnly] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<ITabItemProps>(TABS[0]);

    const { data, isLoading } = useVulnerabilityScan({
        entityId: id,
        entityType: type,
        environmentId: environmentId,
        remediationOnly: false,
    });
    const { data: scanMetadata } = useVulnerabilityScanMetadata({
        entityId: id,
        entityType: type,
        environmentId: environmentId,
    });

    const flatPackageAndCveMemo = React.useMemo(() => flattenPackageAndCve(data?.Package || []), [data?.Package]);

    const restructureThreatsMemo = React.useMemo(() => restructureThreats(data?.Malware || []), [data?.Malware]);

    const restructureSecretsMemo = React.useMemo(
        () => restructureThreats(data?.InsecureContent || []),
        [data?.InsecureContent],
    );

    const flattenRemediationMemo = React.useMemo(
        () => flattenRemediation(data?.ScanSummary['remediation-summary'] || []),
        [data?.ScanSummary],
    );

    const exportCSV = useCallback(async () => {
        const res = getHttpService().get<ArrayBuffer>('vulnerability/scan-export-csvs', {
            params: {
                environmentId: environmentId,
                entityType: type,
                entityId: id,
                ...(remediationOnly && { hasRemediation: remediationOnly }),
            },
            responseType: 'arraybuffer',
        });
        getNotificationsService().runPromise({
            promise: res,
            pending: {
                title: 'Exporting Started',
                text: 'Please wait...',
            },
            error: {
                title: 'Error Occurred',
                text: 'Failed export CSV',
            },
            autoClose: 5000,
        });
        try {
            const csvData = await res;
            fileDownload(csvData, 'scan-result-csv.zip');
        } catch (e) {
            // nothing to do here
        }
    }, [environmentId, type, id, remediationOnly]);

    const exportCveAsJSONAndCSV: Array<ITableExportButton> = React.useMemo(() => {
        return [
            {
                label: 'CSV - Download',
                onClick: () => exportCSV(),
            },
            {
                label: 'JSON - Download',
                onClick: () => {
                    const fileName = 'scan-data-vulnerabilities.json';
                    const json = JSON.stringify(data, null, 2);
                    const blob = new Blob([json], { type: 'application/json' });
                    const href = URL.createObjectURL(blob);
                    const link = document.createElement('a');

                    link.href = href;
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                },
            },
        ];
    }, [data, exportCSV]);

    const getSelectedTab = React.useCallback(
        (tab: ITabItemProps) => {
            switch (tab.value) {
                case 'threats': {
                    return (
                        <Threats
                            layers={data?.LayersDetails}
                            exportCveAsJSONAndCSV={exportCveAsJSONAndCSV}
                            threats={restructureThreatsMemo}
                        />
                    );
                }
                case 'secrets': {
                    return (
                        <Threats
                            layers={data?.LayersDetails}
                            exportCveAsJSONAndCSV={exportCveAsJSONAndCSV}
                            isThreats={false}
                            threats={restructureSecretsMemo}
                        />
                    );
                }
                case 'remediations': {
                    return (
                        <Remediations
                            exportCveAsJSONAndCSV={exportCveAsJSONAndCSV}
                            remediations={flattenRemediationMemo}
                        />
                    );
                }
                default: {
                    return (
                        <Cves
                            setRemediationOnly={setRemediationOnly}
                            exportCveAsJSONAndCSV={exportCveAsJSONAndCSV}
                            cves={flatPackageAndCveMemo}
                            layers={data?.LayersDetails}
                            cloudEntity={{ entityId: id, typeByPlatform: type, cloudAccountId: environmentId }}
                        />
                    );
                }
            }
        },
        [
            data?.LayersDetails,
            environmentId,
            exportCveAsJSONAndCSV,
            flatPackageAndCveMemo,
            flattenRemediationMemo,
            id,
            restructureSecretsMemo,
            restructureThreatsMemo,
            type,
        ],
    );

    if (isLoading)
        return (
            <Stack fullHeight>
                <CloudAnimationLoader size={'medium'} />
            </Stack>
        );

    return (
        <>
            <Stack direction={'row'} spacing={2}>
                <Tabs selectedTab={'cves'} onTabSelected={setSelectedTab} tabs={TABS} />
                <ScanStatusTag
                    scanDate={moment(scanMetadata?.scanInfo.scanDate).format('lll')}
                    scanProducer={data?.scanInfo.scanProducer}
                />
            </Stack>
            {getSelectedTab(selectedTab)}
        </>
    );
};

export default Vulnerability;
