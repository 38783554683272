import styled from 'styled-components';

const TopDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--borders-strength-border-regular, #d2d8e3);
`;

export const TopIssuesStyled = {
    TopDiv,
};

const TopTableDiv = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    padding: 2px 4px;
`;

export const TableStyled = {
    TopTableDiv,
};

const TopOccurrencesDiv = styled.div`
    display: flex;
    gap: 5px;
`;

export const OccurrencesStyled = {
    TopOccurrencesDiv,
};
