import { addMainMenuItem } from 'common/helpers/menu';
import { UserRolesTypes } from 'common/enum/UserRoles';
import { IReactPageRoute } from 'common/interface/routing';
import i18n from 'i18next';
import { i18nReportsNamespace } from './initialize.i18n';
import initializeReportsTable from './ReportsTable/ReportsTable.initialize';
import { getAppRootRegistry } from 'common/interface/services';
import { lazy } from 'react';

export default function initialize() {
    const reportsListPageRoute: IReactPageRoute = {
        condition: true,
        component: lazy(() => import('./ReportsTable/ReportsTable')),
        path: '/reports',
        exact: true,
    };

    const menuItem = {
        id: 'reports',
        position: 109,
        icon: 'report',
        label: i18n.t('NAVIGATION_MENU.REPORTS.TITLE', { ns: i18nReportsNamespace }),
        isPreview: true,
        permission: [UserRolesTypes.ALL],
        state: '/reports',
        sections: [
            {
                id: 'default',
                position: 10,
                items: [
                    {
                        id: 'templates',
                        position: 10,
                        label: i18n.t('NAVIGATION_MENU.REPORTS.TEMPLATES', { ns: i18nReportsNamespace }),
                        state: '/reports',
                        permission: [UserRolesTypes.ALL],
                    },
                ],
            },
        ],
    };

    addMainMenuItem(menuItem);

    getAppRootRegistry().addRoutes([{ id: 'ReportsList', content: reportsListPageRoute }]);

    initializeReportsTable();
}
