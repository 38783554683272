import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { Addin } from 'common/extensibility/AddinRegistry';
import { AlibabaEnvironmentAddinRegistry, AlibabaEnvironmentAddingBase } from 'common/helpers/alibabaEnvironment';
import { AlibabaEnvironmentData } from 'common/module_interface/assets/AlibabaEnvironment';
import { StatusCellRenderer_Status } from 'common/module_interface/assets/Environments';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { getAlibabaEnvNamespace } from './initialize.i18n';
import { changeUrl } from 'common/utils/http';
import { getCloudAccountsService } from 'common/interface/data_services';
import { Vendors } from 'common/consts/vendors';
import { Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import { getEventsTableWidgetService } from 'common/module_interface/events/EventsTableRegistry';
import { IChipProps } from 'common/design-system/components-v2/Chip/Chip.types';
import { CommonEventFields } from 'common/module_interface/events/EventsConsts';
import { lazy } from 'react';

export const initialAlibabaEnvironmentData = () => {
    const tabs: Addin<AlibabaEnvironmentAddingBase<Tab>>[] = [
        {
            id: 'readiness',
            position: 10,
            content: {
                id: 'readiness',
                getValue: () => ({
                    name: 'readiness',
                    label: i18next.t('alibabaPage.tabs.readiness', { ns: getAlibabaEnvNamespace('alibaba') }),
                    isReactTab: false,
                }),
            },
        },
        {
            id: 'protected assets new',
            position: 30,
            content: {
                id: 'protected assets new',
                getValue: (alibabaData: AlibabaEnvironmentData) => ({
                    name: 'protected assets new',
                    label: i18next.t('alibabaPage.tabs.protectedAssets', { ns: getAlibabaEnvNamespace('alibaba') }),
                    isReactTab: true,
                    reactTab: {
                        component: lazy(() => import('./tabs/Assets')),
                        componentProps: { cloudAccountId: alibabaData.account.id },
                    },
                }),
            },
        },
        {
            id: 'events',
            position: 40,
            content: {
                id: 'events',
                getValue: (alibabaData) => {
                    const EventsTable = getEventsTableWidgetService().getMainTable();
                    return {
                        name: 'events',
                        label: i18next.t('alibabaPage.tabs.events', { ns: getAlibabaEnvNamespace('alibaba') }),
                        isReactTab: true,
                        reactTab: {
                            component: EventsTable,
                            componentProps: {
                                tableIdPrefix: `alibaba-env_${alibabaData.account.id}`,
                                disablePadding: true,
                                defaultFilters: {
                                    fields: [{ name: 'cloudAccountId_calc', value: alibabaData.account.id }],
                                },
                                hiddenFilters: [
                                    CommonEventFields.cloudAccountId,
                                    CommonEventFields.cloudAccountType,
                                    'organizational unit',
                                    CommonEventFields.organizationalUnitId,
                                    CommonEventFields.region,
                                ],
                                hiddenColumns: [CommonEventFields.cloudAccountId, CommonEventFields.cloudAccountType],
                            },
                        },
                    };
                },
            },
        },
        {
            id: 'compliance policies',
            position: 50,
            content: {
                id: 'compliance policies',
                getValue: () => ({
                    name: 'compliance policies',
                    label: i18next.t('alibabaPage.tabs.compliancePolicies', { ns: getAlibabaEnvNamespace('alibaba') }),
                    isReactTab: false,
                }),
            },
        },
        {
            id: 'assessment history',
            position: 60,
            content: {
                id: 'assessment history',
                getValue: () => ({
                    name: 'assessment history',
                    label: i18next.t('alibabaPage.tabs.assessmentHistory', { ns: getAlibabaEnvNamespace('alibaba') }),
                    isReactTab: false,
                }),
            },
        },
    ];
    AlibabaEnvironmentAddinRegistry.addTabs(tabs);

    const detailsItems: Addin<AlibabaEnvironmentAddingBase<BlockInfoProps>>[] = [
        {
            id: 'organizationalUnit',
            position: 10,
            content: {
                id: 'organizationalUnit',
                getValue: (alibabaData) => ({
                    title: i18next.t('alibabaPage.info.organizationalUnit', { ns: getAlibabaEnvNamespace('alibaba') }),
                    info: alibabaData.account.organizationalUnitName,
                    copyable: true,
                }),
            },
        },
        {
            id: 'cloudGuardId',
            position: 30,
            content: {
                id: 'cloudGuardId',
                getValue: (alibabaData) => ({
                    title: i18next.t('alibabaPage.info.cloudGuardId', { ns: getAlibabaEnvNamespace('alibaba') }),
                    info: alibabaData.account.id,
                    copyable: true,
                }),
            },
        },
        {
            id: 'onboardingTime',
            position: 40,
            content: {
                id: 'onboardingTime',
                getValue: (alibabaData) => ({
                    title: i18next.t('alibabaPage.info.onboardingTime', { ns: getAlibabaEnvNamespace('alibaba') }),
                    info: dayjs(alibabaData.account.creationDate).format('MMM D, YYYY h:mm A Z'),
                }),
            },
        },
        {
            id: 'totalAssets',
            position: 60,
            content: {
                id: 'totalAssets',
                getValue: (alibabaData) => ({
                    title: i18next.t('alibabaPage.info.totalAssets', { ns: getAlibabaEnvNamespace('alibaba') }),
                    info: i18next.t('COMMON.PRETTY_NUMBER', { value: alibabaData.account.totalAssets }),
                }),
            },
        },
    ];
    AlibabaEnvironmentAddinRegistry.addDetailsPanelItems(detailsItems);

    const chips: Addin<AlibabaEnvironmentAddingBase<IChipProps>>[] = [
        {
            id: 'environmentStatusChip',
            position: 20,
            content: {
                id: 'environmentStatusChip',
                customLoadingLabel: i18next.t('alibabaPage.chips.status.loading', {
                    ns: getAlibabaEnvNamespace('alibaba'),
                }),
                getValue: async (alibabaData) => {
                    const syncStatusList = await getCloudAccountsService().getEnvironmentSyncStatus(true, (err) => {
                        throw err;
                    });
                    const status = syncStatusList.find(
                        (s) => s.platform === Vendors.ALIBABA && s.id === alibabaData.account.id,
                    );
                    const syncStatus = status?.hasIssues
                        ? StatusCellRenderer_Status.warning
                        : StatusCellRenderer_Status.success;
                    const tooltipI18Key =
                        syncStatus === StatusCellRenderer_Status.warning
                            ? 'alibabaPage.chips.MISSING_PERMISSIONS'
                            : 'alibabaPage.chips.VALID_PERMISSIONS';
                    const chipProps: IChipProps = {
                        label: i18next.t(`alibabaPage.chips.status.${syncStatus}`, {
                            ns: getAlibabaEnvNamespace('alibaba'),
                        }),
                        leadingIconProps: {
                            name: syncStatus === StatusCellRenderer_Status.warning ? 'warning' : 'checkCircle',
                        },
                        context: syncStatus === StatusCellRenderer_Status.warning ? 'medium' : 'low',
                        disableInteraction: true,
                        tooltip: i18next.t(tooltipI18Key, { ns: getAlibabaEnvNamespace('alibaba') }),
                    };

                    if (syncStatus === StatusCellRenderer_Status.warning) {
                        chipProps.disableInteraction = false;
                        chipProps.onClick = () => {
                            changeUrl(
                                `/cloud-account/alibaba/${alibabaData.account.id}?tabName=readiness&tabOnly=true`,
                            );
                        };
                    }
                    return chipProps;
                },
            },
        },
    ];
    AlibabaEnvironmentAddinRegistry.addChips(chips);
};
