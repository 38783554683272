import { IApplicationReducer } from 'common/interface/redux';
import gcpReducer from './reducer';
import { initialGcpEnvironmentData } from './GcpEnvironment.consts';
import { getAppRootRegistry } from 'common/interface/services';

const initializeGcpEnvRedux = () => {
    const reducers: IApplicationReducer[] = [
        {
            name: 'env_gcp',
            reducer: gcpReducer,
            isBlackList: true,
        },
    ];
    getAppRootRegistry().addReducers(reducers, 'name');
};

export const initializeGcpEnv = () => {
    initializeGcpEnvRedux();
    initialGcpEnvironmentData();
};
