import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import {
    IEnvironmentsFilter,
    PATH_ENVIRONMENTS_WIDGETS_FILTERS,
    PATH_ENVIRONMENTS_FILTERS_DEFINITIONS,
    IEnvironmentsFilterParams,
} from 'common/module_interface/assets/Environments';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { Addin } from 'common/extensibility/AddinRegistry';
import { renderAddFilter, renderTreeFilter } from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import i18n from 'i18next';
const assetsFilters: IEnvironmentsFilter[] = [
    {
        id: 'add filter',
        position: 10,
    },
    {
        id: 'organizational unit',
    },
];

const filterDefsAddins: Addin<IFilterProps>[] = [
    {
        id: 'add filter',
        content: {
            filterProps: { key: 'add-filter' },
            renderFunction: renderAddFilter,
        },
    },

    {
        id: 'organizational unit',
        content: function ({ filtersInitialData }: IEnvironmentsFilterParams) {
            return {
                filterProps: {
                    isMultiSelect: true,
                    initialData: filtersInitialData?.organizationalUnits,
                    key: 'organizationalUnitId',
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.ORGANIZATIONAL_UNITS.TITLE'),
                },
                renderFunction: renderTreeFilter,
            };
        },
    },
];

export default function initializeEnvironmentsWidgetFilters() {
    globalAddinContainer.addMap(PATH_ENVIRONMENTS_WIDGETS_FILTERS, assetsFilters);
    globalAddinContainer.add<IFilterProps>(PATH_ENVIRONMENTS_FILTERS_DEFINITIONS, filterDefsAddins);
}
