export enum TaskAttributeTypes {
    link = 'link',
    text = 'text',
}

export interface TaskAttribute {
    type: TaskAttributeTypes;
    title: string;
    value: string;
}
