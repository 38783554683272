import { FindingSeveritiesMap, getSafeFindingSeverityInfo } from 'common/consts/FindingSeverity';
import {
    Malware,
    Package,
    Category,
    RemediationSummary,
    Remediationflatten,
    Malwareflatten,
    FlatPackageAndCve,
} from 'modules/workloads/services/vulnerability/vulnerability.interface';
import { capitalizeWords } from 'common/utils/helpFunctions';
import { EBooleanString } from 'modules/workloads/utils/aggrid';

export type ColToHideFF = Array<{ field: string; hide: boolean }>;

export enum SortDirection {
    ASC = 'asc',
    DESC = 'desc',
}

export const severityInfo = (text: string) => ({
    text: getSafeFindingSeverityInfo(text).displayText,
    color: getSafeFindingSeverityInfo(text).color,
    zeroBasedIndex: getSafeFindingSeverityInfo(text).level - FindingSeveritiesMap.informational.level,
    maxLevel: 5,
});

export const getThreatTypeDisplayName = (type: string) => {
    switch (type) {
        case 'MALICIOUS_URL':
            return 'Malicious URL';
        case 'MALICIOUS_IP':
            return 'Malicious IP';
        case 'MALICIOUS_FILE':
            return 'Malicious File';
        default:
            return pascalCase(type);
    }
};

export const pascalCase = (value: string) =>
    value.replace(/\w+/g, (w) => w[0].toUpperCase() + w.slice(1).toLowerCase());

export const restructureThreats = (threats: Array<Malware>): Array<Malwareflatten> => {
    const threatsFlatten: Array<Malwareflatten> = [];
    threats.forEach((threat) => {
        if (!threat || !threat.files || !threat.files.length) {
            return;
        }

        threat.files.forEach((file: any) => {
            if (!file.contents || !file.contents.length) {
                threatsFlatten.push({
                    finding: file?.md5.length > 0 ? file.md5 : 'N/A',
                    type: getThreatTypeDisplayName(threat.type),
                    classification: threat.classification,
                    severity: pascalCase(threat.severity),
                    description: threat.description,
                    remediation: threat.remediation || '',
                    filePath: file['file-path'],
                    lines: 'N/A',
                    category: threat.category,
                    baseImages: threat.baseImages,
                    layerId: file.layerId,
                });
            }

            file.contents.forEach((content: any) => {
                threatsFlatten.push({
                    finding: content.payload,
                    type: getThreatTypeDisplayName(threat.type),
                    classification: threat.classification,
                    severity: pascalCase(threat.severity),
                    description: threat.description,
                    remediation: threat.remediation || '',
                    filePath: file['file-path'],
                    lines: content.lines.join(', '),
                    category: threat.category,
                    baseImages: threat.baseImages,
                    layerId: file.layerId,
                });
            });
        });
    });
    return threatsFlatten;
};

const addPrefixToObjectKeys = (obj: { [key: string]: string | {} }, prefix: string) => {
    const objectWithPrefix: typeof obj = {};
    for (const key in obj) {
        if (key !== 'cves' && key !== 'relatedCves') {
            objectWithPrefix[prefix + capitalizeWords(key)] = obj[key];
        }
    }
    return objectWithPrefix;
};

export const flattenPackageAndCve = (packages: Array<Package>) =>
    packages.reduce<Array<FlatPackageAndCve>>((acc, pack: any) => {
        return [...acc, ...pack.cves.map((cve: any) => ({ ...cve, ...addPrefixToObjectKeys(pack, 'package') }))];
    }, []);

export const flattenRemediation = (remediationSummary: Array<RemediationSummary>) => {
    if (!remediationSummary.length) return [];

    const remediationflatten: Array<Remediationflatten> = [];

    remediationSummary.forEach((remediationSum) => {
        remediationSum.categories.forEach((categoryRemediation: Category) => {
            categoryRemediation.remediations.forEach((remediation) => {
                remediationflatten.push({
                    category: categoryRemediation.category,
                    'file-path': remediationSum['file-path'],
                    ...remediation,
                });
            });
        });
    });
    return remediationflatten;
};

export const aggregatePackageNameVersionValues = (data: Array<FlatPackageAndCve>) => {
    const packageNameVersionOccuranceMap = data.reduce<Record<string, number>>((acc, row) => {
        const key = `${row.packageName}:${row.packageVersion}`;
        acc[key] = (acc[key] || 0) + 1;
        return acc;
    }, {});

    return Object.entries(packageNameVersionOccuranceMap).map(([value, count]) => ({ value, count }));
};

export const aggregatePackageInUseValues = (data: Array<FlatPackageAndCve>) => {
    const packageNameVersionOccuranceMap = data.reduce<Record<string, number>>((acc, row) => {
        const key = Boolean(row.packageInUse) ? EBooleanString.TRUE : 'N/A';
        acc[key] = (acc[key] || 0) + 1;
        return acc;
    }, {});

    return Object.entries(packageNameVersionOccuranceMap).map(([value, count]) => ({ value, count }));
};
