import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OciInitialState, OciState } from './oci.interface';
import { OciEnvironmentData } from 'common/module_interface/assets/OciEnvironment';

const OciSlice = createSlice({
    name: 'env_oci',
    initialState: OciInitialState,
    reducers: {
        setLoading: (state: OciState, action: PayloadAction<{ ociId: string; loading: boolean }>) => {
            const { ociId, loading } = action.payload;
            if (!state.ociEvnironments[ociId]) {
                state.ociEvnironments[ociId] = {};
            }
            state.ociEvnironments[ociId].loading = loading;
        },
        setError: (state: OciState, action: PayloadAction<{ ociId: string; error: boolean }>) => {
            const { ociId, error } = action.payload;
            if (!state.ociEvnironments[ociId]) {
                state.ociEvnironments[ociId] = {};
            }
            state.ociEvnironments[ociId].error = error;
        },
        setData: (state: OciState, action: PayloadAction<{ ociId: string; data: OciEnvironmentData }>) => {
            const { ociId, data } = action.payload;
            if (!state.ociEvnironments[ociId]) {
                state.ociEvnironments[ociId] = {};
            }
            state.ociEvnironments[ociId].data = data;
        },
        setDeleted: (state: OciState, action: PayloadAction<{ ociId: string }>) => {
            const { ociId } = action.payload;
            if (!state.ociEvnironments[ociId]) {
                state.ociEvnironments[ociId] = { deleted: true };
            }
            state.ociEvnironments[ociId].deleted = true;
        },
    },
});

export default OciSlice;
