import React, { useEffect, useState } from 'react';
import { CyeraClientIdWrapper, CyeraComponentStyled, CyeraNameWrapper } from './CyeraComponent.styled';
import { Typography, Input } from 'common/design-system/components-v2';
import GenericCancelSubmitButtonWrapper from 'common/components/SubmitButtonWrapper/SubmitButtonWrapper';
import { SUBMIT_STATUS_RESPONSE } from 'common/module_interface/settings/integrations/consts';
import { useTranslation } from 'react-i18next';
import { IGenericConfigurationProps } from 'common/module_interface/RiskManagement/integrations/cyeraintegration/CyeraIntegration.consts';
import { I18nRiskNamespace } from '../../../consts';

enum Mode {
    ADD = 'add',
    EDIT = 'edit',
}

const CyeraComponent: React.FC<IGenericConfigurationProps> = ({
    onConfigurationChangeCallBack,
    configuration,
    onConfigurationSaved,
    viewMode,
}) => {
    const { t } = useTranslation(I18nRiskNamespace);

    const [nameInputConfig, setNameInputConfig] = useState<string>(configuration?.name || '');
    const [clientIdInputConfig, setClientIdInputConfig] = useState<string>(
        configuration?.configurationObj.accountCredentials?.clientId || '',
    );
    const [secretInputConfig, setSecretInputConfig] = useState<string>('');
    const [idConfiguration, setIdConfiguration] = useState<string>(configuration?.id || '');
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [nameError, setNameError] = useState<string>('');
    const [clientIdError, setClientIdError] = useState<string>('');
    const [secretError, setSecretError] = useState<string>('');
    const [mode, setMode] = useState<string>(configuration?.name ? Mode.EDIT : Mode.ADD);
    const [responseMessageSuccess, setResponseMessageSuccess] = useState<boolean>(true);
    const discardChangesButtonHidden = Boolean(idConfiguration);
    const viewOnly = Boolean(viewMode);

    useEffect(() => {
        const changesDetected =
            nameInputConfig !== configuration?.name ||
            clientIdInputConfig !== configuration?.configurationObj.accountCredentials?.clientId;
        setIsButtonDisabled(!changesDetected);
    }, [
        nameInputConfig,
        clientIdInputConfig,
        idConfiguration,
        configuration?.name,
        secretInputConfig,
        configuration?.configurationObj.accountCredentials?.clientId,
    ]);

    const handleSecretChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSecretInputConfig(event.target.value);
    };

    const handleClientIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setClientIdInputConfig(event.target.value);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNameInputConfig(event.target.value);
    };

    const handleDiscardChanges = () => {
        setNameInputConfig(configuration?.name || '');
        setSecretInputConfig(configuration?.configurationObj.accountCredentials?.secret || '');
        setClientIdInputConfig(configuration?.configurationObj.accountCredentials?.clientId || '');
    };

    const checkValidationsErrors = () => {
        setClientIdError(
            !clientIdInputConfig && mode === Mode.ADD ? t('CYERA_INTEGRATION.CONFIGURATIONS.MISSING_CLIENT_ID') : '',
        );
        setNameError(!nameInputConfig ? t('CYERA_INTEGRATION.CONFIGURATIONS.MISSING_NAME') : '');
        setSecretError(
            !secretInputConfig && mode === Mode.ADD ? t('CYERA_INTEGRATION.CONFIGURATIONS.MISSING_SECRET') : '',
        );
        return !nameInputConfig || ((!clientIdInputConfig || !secretInputConfig) && mode === Mode.ADD);
    };

    const submitForm = async (): Promise<{ Status: SUBMIT_STATUS_RESPONSE; Message?: string }> => {
        try {
            if (checkValidationsErrors()) {
                setResponseMessageSuccess(false);
                return { Status: SUBMIT_STATUS_RESPONSE.FAIL_SAVE };
            }
            const resp =
                onConfigurationSaved &&
                (await onConfigurationSaved(
                    nameInputConfig,
                    { clientId: clientIdInputConfig, secret: secretInputConfig },
                    idConfiguration,
                ));
            resp?.id && setIdConfiguration(resp.id);
            onConfigurationChangeCallBack && onConfigurationChangeCallBack();
            setResponseMessageSuccess(true);
            setMode(Mode.EDIT);
            return { Status: SUBMIT_STATUS_RESPONSE.SUCCESS };
        } catch (error: any) {
            const errorMessage = error.message || t('CYERA_INTEGRATION.CONFIGURATIONS.SAVED_FAILED');
            setResponseMessageSuccess(false);
            return { Status: SUBMIT_STATUS_RESPONSE.FAIL_SAVE, Message: errorMessage };
        }
    };

    return (
        <CyeraComponentStyled>
            <CyeraComponentStyled>
                <CyeraNameWrapper>
                    <Typography variant='subtitleLg'>{t('CYERA_INTEGRATION.CONFIGURATIONS.NAME')}</Typography>
                    <Input
                        type='text'
                        data-aid='nameInput'
                        value={nameInputConfig}
                        onChange={handleNameChange}
                        disabled={viewOnly}
                        isError={!!nameError}
                        helperText={nameError}
                    />
                </CyeraNameWrapper>
                <CyeraClientIdWrapper>
                    <Typography variant='subtitleLg'>{t('CYERA_INTEGRATION.CONFIGURATIONS.CLIENT_ID')}</Typography>
                    <Input
                        type='text'
                        data-aid='clientIdInput'
                        value={clientIdInputConfig}
                        readOnly={mode === Mode.EDIT}
                        onChange={handleClientIdChange}
                        disabled={viewOnly}
                        isError={!!clientIdError}
                        helperText={clientIdError}
                    />
                </CyeraClientIdWrapper>
                {mode === Mode.ADD && (
                    <CyeraClientIdWrapper>
                        <Typography variant='subtitleLg'>{t('CYERA_INTEGRATION.CONFIGURATIONS.SECRET')}</Typography>
                        <Input
                            type='text'
                            data-aid='secretInput'
                            value={secretInputConfig}
                            onChange={handleSecretChange}
                            disabled={viewOnly}
                            isError={!!secretError}
                            helperText={secretError}
                        />
                    </CyeraClientIdWrapper>
                )}
            </CyeraComponentStyled>
            {GenericCancelSubmitButtonWrapper({
                handleDiscardChanges,
                discardChangesButtonHidden,
                isButtonDisabled,
                submitForm,
                viewOnly,
                responseMessageSuccess,
            })}
        </CyeraComponentStyled>
    );
};

export default CyeraComponent;
