import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const KeyValue = styled(Stack)`
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-right: 20px;
    white-space: normal;
`;

const Value = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50ch;
`;

export const LogOverviewSectionStyled = {
    KeyValue,
    Value,
};
