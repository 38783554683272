import { IProtectedAssetFilter, IProtectedAssetFilterParams } from 'common/module_interface/assets/ProtectedAssets';
import { buildPath, globalAddinContainer } from 'common/extensibility/AddinContainer';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import {
    renderAddFilter,
    renderClearAll,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter,
    renderRecentlyUsedFilters,
    renderSavedFilters,
    renderTagFilter,
    renderTreeFilter,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import {
    FILTER_BOX_DISPLAY_TYPES,
    FILTER_DISPAY_TYPES,
    FILTER_PANEL_CONSTS,
    FILTERS_KEYS,
} from 'common/components/FilterPanel/FilterPanel.consts';
import i18n from 'i18next';
import { IDisplayMappingObject } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { getVendor, getVendorDisplayName } from 'common/consts/vendors';
import { GENERAL_BOOLEAN_VALUES } from 'common/consts/GeneralConsts';
import { getRegionDisplayName } from 'common/utils/vendorUtils';
import { buildCloudAccountList, mapEnvironmentItem, mapTypeItem } from 'common/module_interface/assets/utils';
import { PATH_PROTECTED_ASSETS_WIDGETS_FILTERS } from 'common/module_interface/assets/ProtectedAssets.consts';

const PATH_PROTECTED_ASSETS_WIDGETS = buildPath('assets', 'widgets');
const PATH_PROTECTED_ASSETS_WIDGETS_FILTERS_DEFS = buildPath(PATH_PROTECTED_ASSETS_WIDGETS, 'filters');
const filters: IProtectedAssetFilter[] = [
    {
        id: 'add filter',
        position: 10,
    },
    {
        id: 'free text',
    },
    {
        id: 'organizational unit',
    },
    {
        id: 'platform',
    },
    {
        id: 'type',
    },
    {
        id: 'environment',
    },
    {
        id: 'region',
    },
    {
        id: 'network',
    },
    {
        id: 'resourceGroup',
    },
    {
        id: 'runState',
    },

    {
        id: 'publicIdAssociated',
    },
    {
        id: 'billableAsset',
    },
    {
        id: 'labels',
    },
    {
        id: 'compartment',
    },
    {
        id: 'serverlessProtectionMode',
    },
    {
        id: 'serverlessRuntimeProtection',
    },
    {
        id: 'serverlessEnable',
    },
    {
        id: 'serverlessAutoProtect',
    },
    {
        id: 'clear all',
    },
];

const widgetsFilterDefsAddins: Addin<IFilterProps>[] = [
    {
        id: 'add filter',
        content: {
            filterProps: { key: 'add-filter' },
            renderFunction: renderAddFilter,
        },
    },
    {
        id: 'free text',
        content: {
            filterProps: {
                key: FILTERS_KEYS.FREE_TEXT, //This needs to be the same as in stored filter/query params
                title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.FREE_TEXT.TITLE'),
            },
            keyInObjectForAPI: 'freeTextPhrase',
            renderFunction: renderDefaultFreeTextFilter,
            displayTypes: [FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_1, FILTER_DISPAY_TYPES.SEPARATE_FIELDS],
        },
    },
    {
        id: 'organizational unit',
        content: function ({ filtersInitialData }: IProtectedAssetFilterParams) {
            return {
                filterProps: {
                    isMultiSelect: true,
                    initialData: filtersInitialData?.organizationalUnits,
                    key: 'organizationalUnitId',
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.ORGANIZATIONAL_UNITS.TITLE'),
                },
                renderFunction: renderTreeFilter,
            };
        },
    },
    {
        id: 'platform',
        content: function ({ aggregations }: IProtectedAssetFilterParams) {
            const displayMapping: IDisplayMappingObject = {};
            aggregations['platform']?.forEach((platform: { value: string }) => {
                displayMapping[platform.value] = {
                    displayText: getVendorDisplayName(platform.value),
                    icon: getVendor(platform.value)?.icon,
                };
            });
            return {
                filterProps: {
                    initialData: aggregations['platform'],
                    key: 'platform',
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.PLATFORM.TITLE'),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'type',
        content: function ({ aggregations }: IProtectedAssetFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations['type'],
                    key: 'type',
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.TYPE.TITLE'),
                    displayMapping: mapTypeItem(aggregations?.type),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'environment',
        content: function ({ aggregations, allCloudAccounts = [] }: IProtectedAssetFilterParams) {
            const allCloudAccountsItems = aggregations['cloudAccountId']
                ? buildCloudAccountList(aggregations['cloudAccountId'], allCloudAccounts)
                : [];
            return {
                filterProps: {
                    initialData: allCloudAccountsItems,
                    key: 'cloudAccountId',
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.ENVIRONMENT.TITLE'),
                    displayMapping: mapEnvironmentItem(allCloudAccounts),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'region',
        content: function ({ aggregations }: IProtectedAssetFilterParams) {
            const displayMapping: IDisplayMappingObject = {};
            aggregations['region']?.forEach((regionData: { value: string }) => {
                displayMapping[regionData.value] = { displayText: getRegionDisplayName(regionData.value) };
            });
            return {
                filterProps: {
                    initialData: aggregations['region'],
                    key: 'region',
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.REGION.TITLE'),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'labels',
        content: function ({ aggregations }: IProtectedAssetFilterParams) {
            const initialData = [];
            for (const data of aggregations['assetLabels']) {
                if (data.value !== '') {
                    initialData.push(data);
                }
            }
            return {
                filterProps: {
                    initialData: initialData,
                    key: 'assetLabels',
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.LABELS.TITLE'),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'network',
        content: function ({ aggregations }: IProtectedAssetFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations['network'],
                    key: 'network',
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.NETWORK.TITLE'),
                    displayMapping: { '': { displayText: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.NETWORK.NONE') } },
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'resourceGroup',
        content: function ({ aggregations }: IProtectedAssetFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations['resourceGroup'],
                    key: 'resourceGroup',
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.RESOURCE_GROUP.TITLE'),
                    displayMapping: {
                        '': { displayText: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.RESOURCE_GROUP.NONE') },
                    },
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'compartment',
        content: function ({ aggregations }: IProtectedAssetFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations['compartment'],
                    key: 'compartment',
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.COMPARTMENT.TITLE'),
                    displayMapping: { '': { displayText: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.COMPARTMENT.NONE') } },
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'runState',
        content: () => {
            const initialData = [
                { value: GENERAL_BOOLEAN_VALUES.TRUE_CAPITAL_T },
                { value: GENERAL_BOOLEAN_VALUES.FALSE_CAPITAL_F },
            ];

            const displayMapping = {
                [GENERAL_BOOLEAN_VALUES.TRUE_CAPITAL_T]: {
                    displayText: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.RUN_STATE.VALUE1'),
                },
                [GENERAL_BOOLEAN_VALUES.FALSE_CAPITAL_F]: {
                    displayText: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.RUN_STATE.VALUE2'),
                },
            };

            return {
                filterProps: {
                    initialData: initialData,
                    key: 'additionalFields|IsRunning',
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.RUN_STATE.TITLE'),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'publicIdAssociated',
        content: () => {
            const initialData = [
                { value: GENERAL_BOOLEAN_VALUES.TRUE_CAPITAL_T },
                { value: GENERAL_BOOLEAN_VALUES.FALSE_CAPITAL_F },
            ];

            const displayMapping = {
                [GENERAL_BOOLEAN_VALUES.TRUE_CAPITAL_T]: {
                    displayText: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.PUBLIC_IP_ASSOCIATED.VALUE1'),
                },
                [GENERAL_BOOLEAN_VALUES.FALSE_CAPITAL_F]: {
                    displayText: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.PUBLIC_IP_ASSOCIATED.VALUE2'),
                },
            };

            return {
                filterProps: {
                    initialData: initialData,
                    key: 'additionalFields|IsPublic',
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.PUBLIC_IP_ASSOCIATED.TITLE'),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'billableAsset',
        content: () => {
            const initialData = [
                { value: GENERAL_BOOLEAN_VALUES.TRUE_CAPITAL_T },
                { value: GENERAL_BOOLEAN_VALUES.FALSE_CAPITAL_F },
            ];

            const displayMapping = {
                [GENERAL_BOOLEAN_VALUES.TRUE_CAPITAL_T]: {
                    displayText: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.BILLABLE_ASSET.VALUE1'),
                },
                [GENERAL_BOOLEAN_VALUES.FALSE_CAPITAL_F]: {
                    displayText: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.BILLABLE_ASSET.VALUE2'),
                },
            };

            return {
                filterProps: {
                    initialData: initialData,
                    key: 'additionalFields|IsBillable',
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.BILLABLE_ASSET.TITLE'),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'thirdParty',
        content: function ({ aggregations }: IProtectedAssetFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations?.thirdParty,
                    key: 'externalAdditionalFields|ThirdParty',
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.THIRD_PARTY.TITLE'),
                    displayMapping: { '': { displayText: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.THIRD_PARTY.NONE') } },
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'tag',
        content: function ({ aggregations }: IProtectedAssetFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations[FILTER_PANEL_CONSTS.ADD_FILTER_TAG],
                    key: 'tag',
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.TAGS.TITLE'),
                },
                renderFunction: renderTagFilter,
            };
        },
    },
    {
        id: 'serverlessProtectionMode',
        content: () => {
            const initialData = [{ value: GENERAL_BOOLEAN_VALUES.TRUE }, { value: GENERAL_BOOLEAN_VALUES.FALSE }];

            const displayMapping = {
                [GENERAL_BOOLEAN_VALUES.TRUE]: {
                    displayText: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_PROTECTION_MODE.VALUE1'),
                },
                [GENERAL_BOOLEAN_VALUES.FALSE]: {
                    displayText: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_PROTECTION_MODE.VALUE2'),
                },
            };

            return {
                filterProps: {
                    initialData: initialData,
                    key: 'externalAdditionalFields|WithFSPBlock',
                    title: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_PROTECTION_MODE.TITLE'),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'serverlessRuntimeProtection',
        content: () => {
            const initialData = [{ value: GENERAL_BOOLEAN_VALUES.TRUE }];

            const displayMapping = {
                [GENERAL_BOOLEAN_VALUES.TRUE]: {
                    displayText: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_RUNTIME_PROTECTION.VALUE1'),
                },
            };

            return {
                filterProps: {
                    initialData: initialData,
                    key: 'externalAdditionalFields|IncludesFSP',
                    title: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_RUNTIME_PROTECTION.TITLE'),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'serverlessEnable',
        content: () => {
            const initialData = [{ value: GENERAL_BOOLEAN_VALUES.TRUE }, { value: GENERAL_BOOLEAN_VALUES.FALSE }];

            const displayMapping = {
                [GENERAL_BOOLEAN_VALUES.TRUE]: {
                    displayText: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_ENABLE.VALUE1'),
                },
                [GENERAL_BOOLEAN_VALUES.FALSE]: {
                    displayText: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_ENABLE.VALUE2'),
                },
            };

            return {
                filterProps: {
                    initialData: initialData,
                    key: 'externalAdditionalFields|ServerlessEnable',
                    title: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_ENABLE.TITLE'),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'serverlessAutoProtect',
        content: () => {
            const initialData = [{ value: GENERAL_BOOLEAN_VALUES.TRUE }, { value: GENERAL_BOOLEAN_VALUES.FALSE }];

            const displayMapping = {
                [GENERAL_BOOLEAN_VALUES.TRUE]: {
                    displayText: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_AUTO_PROTECT.VALUE1'),
                },
                [GENERAL_BOOLEAN_VALUES.FALSE]: {
                    displayText: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_AUTO_PROTECT.VALUE2'),
                },
            };

            return {
                filterProps: {
                    initialData: initialData,
                    key: 'externalAdditionalFields|FSPStickyMode',
                    title: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_AUTO_PROTECT.TITLE'),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'saved filters',
        content: function ({ filtersInitialData }: IProtectedAssetFilterParams) {
            return {
                filterProps: {
                    savedFilters: filtersInitialData?.savedFilters || [],
                    savedFiltersComponentName: '',
                    selectedFilterID: '',
                    key: FILTERS_KEYS.SAVED_FILTERS,
                },
                renderFunction: renderSavedFilters,
            };
        },
    },
    {
        id: 'recently used',
        content: function ({ filtersInitialData }: IProtectedAssetFilterParams) {
            return {
                filterProps: {
                    key: FILTERS_KEYS.RECENTLY_USED_FILTERS,
                    recentlyUsedList: filtersInitialData?.recentlyUsed,
                    maxLength: 4,
                    componentName: '',
                    title: i18n.t('FILTER_PANEL.RECENTLY_USED_FILTERS'),
                },
                renderFunction: renderRecentlyUsedFilters,
            };
        },
    },
    {
        id: 'clear all',
        content: {
            filterProps: {
                key: FILTERS_KEYS.CLEAR_BUTTON,
            },
            renderFunction: renderClearAll,
        },
    },
];

export const ProtectedAssetsWidgetRegistry = {
    addFilterAddins: (filterAddins: Addin<IFilterProps>[]) => {
        globalAddinContainer.add<IFilterProps>(PATH_PROTECTED_ASSETS_WIDGETS_FILTERS_DEFS, filterAddins);
    },

    getFilter: (filterId: string, filterParams: IProtectedAssetFilterParams[]): IFilterProps | undefined => {
        return globalAddinContainer.getById<IFilterProps>(
            PATH_PROTECTED_ASSETS_WIDGETS_FILTERS_DEFS,
            filterId,
            filterParams,
        );
    },
};

export default function initializeProtectedAssetWidgetsFilters() {
    globalAddinContainer.addMap(PATH_PROTECTED_ASSETS_WIDGETS_FILTERS, filters);
    ProtectedAssetsWidgetRegistry.addFilterAddins(widgetsFilterDefsAddins);
}
