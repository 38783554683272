import { IProtectedAssetFilter, IProtectedAssetFilterParams } from 'common/module_interface/assets/ProtectedAssets';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { RiskManagementsWidgetRegistry } from './filters';
import { getService } from 'common/extensibility/AddinContainer';
import { IRiskManagementWidgetsService } from '../services/RiskManagementWidgetsService';

export const RISK_MANAGEMENT_WIDGETS_SERVICE_ID = 'RiskManagementWidgetsService';

export class RiskManagementWidgetsService implements IRiskManagementWidgetsService {
    getFilterDefsForWidgets(filters: IProtectedAssetFilter[], params: IProtectedAssetFilterParams): IFilterProps[] {
        const result: IFilterProps[] = [];
        filters.forEach((filter) => {
            const matchedFilterDef = RiskManagementsWidgetRegistry.getFilter(filter.id, [params]);
            if (matchedFilterDef) {
                const filterDef = { ...matchedFilterDef };
                filterDef.filterProps = { ...filterDef.filterProps, ...filter.filterProps };
                result.push(filterDef);
            }
        });
        return result;
    }
}

export function getRiskManagementService(): IRiskManagementWidgetsService {
    return getService<IRiskManagementWidgetsService>(RISK_MANAGEMENT_WIDGETS_SERVICE_ID);
}
