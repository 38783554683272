import React from 'react';
import { EventsModalOpenFrom, EventsModalProps } from 'common/module_interface/events/EventsModalsRegistry';
import { IExclusion } from 'common/interface/exclusion';
import { ExclusionRegistry } from 'common/module_interface/exclusion/exclusionRegistry';

const EventsExclusionsModal: React.FC<EventsModalProps> = ({
    closeModal,
    selectedRows,
    resetSelectedRows,
    requestNewData,
    openFrom,
}) => {
    const event = selectedRows[0];
    const sourceType = event.origin;
    const ExclusionsModal = ExclusionRegistry.getExcludeModals().find((modal) => modal.id === sourceType)?.component;

    if (!ExclusionsModal) {
        // TODO: This should not happen - should be resolved in isRelevant
        closeModal();
        return null;
    }

    const configuredExclusionObject = (): IExclusion => {
        return {
            cloudAccountIds: [event.cloudAccountId],
            cloudAccountType: event.cloudAccountType,
            comment: '',
            logicExpressions: event.entityName ? [`name like '${event.entityName}'`] : [],
            platform: event.cloudAccountType,
            rules: [
                {
                    logicHash: undefined,
                    id: event.ruleId,
                    name: event.ruleName,
                    rlmId: null,
                },
            ],
            rulesetId: event.bundleId,
            finding: event,
        };
    };
    const initConfiguredExclusion = configuredExclusionObject();

    const onSave = () => {
        if (openFrom === EventsModalOpenFrom.TABLE && resetSelectedRows) {
            resetSelectedRows();
        }
        closeModal();
        requestNewData?.();
    };

    return (
        <ExclusionsModal
            exclusion={initConfiguredExclusion}
            isOpen={true}
            onClose={closeModal}
            onSave={onSave}
            sourceType={event.origin}
        />
    );
};

export default EventsExclusionsModal;
