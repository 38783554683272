import {
    renderAddFilter,
    renderClearAll,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter,
    renderSavedFilters,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';

import { Addin } from 'common/extensibility/AddinRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import i18n from 'common/services/translations/translations';
import { I18nTranslationKey, usersTableRegistry } from './initUsersPage';
import { AggregationOption, IDataItem, IFilterParams } from '../../SimpleTableFilterPage/interfaces';
import { getRolesService, getUsersService } from 'common/interface/services';
import { IUser } from 'common/interface/user';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { filterDataForMultiSelect, filterNameDataForFreeText } from '../../SimpleTableFilterPage/commonFilters';

export const ROLE = 'role';
export const SSO = 'sso';
export const MFA = 'mfa';
export const API_KEYS = 'API key';

export const filterId = 'usersFilter';

export const dataService = {
    getAll: async (useCache: boolean): Promise<IDataItem[]> => {
        const users = await getUsersService().getUsers(useCache);
        const roles = await getRolesService().getRoles(useCache);
        for (const user of users) {
            user.rolesNames = roles.filter((role) => user.roleIds.includes(role.id)).map((role) => role.name);
        }
        return users.map((user) => {
            return {
                id: user.id,
                name: user.name,
                ssoEnabled: user.ssoEnabled,
                mfaEnforcement: !!user.mfaEnforcement?.isEnforced,
                hasApiKey: !!user.apiKeys?.length,
                rolesNames: user.rolesNames,
                permissions: user.permissions,
                lastLogin: user.lastLogin,
                isMobileDevicePaired: user.isMobileDevicePaired,
                isOwner: user.isOwner,
                isMfaEnabled: user.isMfaEnabled,
                hasApiKeyV2: user.hasApiKeyV2,
            } as IDataItem;
        });
    },

    delete(id: string): Promise<void> {
        return getUsersService().deleteUser(parseInt(id));
    },
    getById(id: string): Promise<IDataItem> {
        return Promise.resolve({ id: id } as IDataItem);
    },
    update(data: IDataItem): Promise<void> {
        return data && Promise.resolve();
    },
};

export const calcFilterData = (filtersValues: any, rawData: any) =>
    rawData.filter(
        (data: any) =>
            filterDataForMultiSelect(filtersValues[ROLE], data['rolesNames']) &&
            filterDataForMultiSelect(filtersValues[MFA], !!data['mfaEnforcement']) &&
            filterDataForMultiSelect(filtersValues[SSO], data['ssoEnabled']) &&
            filterDataForMultiSelect(filtersValues[API_KEYS], data['hasApiKey']) &&
            filterNameDataForFreeText(data, filtersValues[FILTERS_KEYS.FREE_TEXT]),
    );

interface IFilter {
    key: string;
    options: AggregationOption[];
    getAllOptionsFromUser: (role: IUser) => string[] | boolean[] | null | undefined;
}

export const getAggregations = async (filtersValues: IFiltersValues, data: any): Promise<Aggregations> => {
    const ssoFilter: IFilter = {
        key: SSO,
        options: [],
        getAllOptionsFromUser: (user: IUser) => {
            return [user.ssoEnabled];
        },
    };
    const mfaFilter: IFilter = {
        key: MFA,
        options: [],
        getAllOptionsFromUser: (user: IUser) => {
            return [!!user.mfaEnforcement];
        },
    };
    const apiFilter: IFilter = {
        key: API_KEYS,
        options: [],
        getAllOptionsFromUser: (user: IUser) => {
            return [!!user.apiKeys?.length];
        },
    };
    const roleFilter: IFilter = {
        key: ROLE,
        options: [],
        getAllOptionsFromUser: (user: IUser) => {
            return user.rolesNames;
        },
    };

    const aggregations = [ssoFilter, mfaFilter, apiFilter, roleFilter];

    for (const role of data) {
        for (const aggregation of aggregations) {
            const optionsFromRole = aggregation.getAllOptionsFromUser(role);
            optionsFromRole?.forEach((option: any) => {
                let isOptionExist = false;
                aggregation.options.forEach((filterOption: any) => {
                    if (option === filterOption.value) {
                        filterOption.count++;
                        isOptionExist = true;
                    }
                });
                !isOptionExist && aggregation.options.push({ value: option, count: 1 });
            });
        }
    }
    return {
        [SSO]: ssoFilter.options,
        [MFA]: mfaFilter.options,
        [API_KEYS]: apiFilter.options,
        [ROLE]: roleFilter.options,
    };
};

export const usersFilterIds: { id: string }[] = [
    { id: FILTERS_KEYS.ADD_FILTER },
    { id: FILTERS_KEYS.FREE_TEXT },
    { id: SSO },
    { id: MFA },
    { id: ROLE },
    { id: API_KEYS },
    { id: FILTERS_KEYS.SAVED_FILTERS },
    { id: FILTERS_KEYS.CLEAR_BUTTON },
];

const filterDefsAddins: () => Addin<IFilterProps>[] = () => [
    {
        id: FILTERS_KEYS.ADD_FILTER,
        content: {
            filterProps: { key: FILTERS_KEYS.ADD_FILTER },
            renderFunction: renderAddFilter,
        },
    },
    {
        id: FILTERS_KEYS.FREE_TEXT,
        content: {
            filterProps: {
                key: FILTERS_KEYS.FREE_TEXT, //This needs to be the same as in stored filter/query params
                title: i18n.t('FILTERS.FREE_TEXT', { ns: I18nTranslationKey }),
            },
            renderFunction: renderDefaultFreeTextFilter,
        },
    },
    {
        id: FILTERS_KEYS.SAVED_FILTERS,
        content: function ({ filtersInitialData, savedFiltersComponentName }) {
            return {
                filterProps: {
                    savedFilters: filtersInitialData?.savedFilters || [],
                    savedFiltersComponentName,
                    selectedFilterID: '',
                    key: FILTERS_KEYS.SAVED_FILTERS,
                },
                renderFunction: renderSavedFilters,
            };
        },
    },
    {
        id: FILTERS_KEYS.CLEAR_BUTTON,
        content: {
            filterProps: {
                key: FILTERS_KEYS.CLEAR_BUTTON,
            },
            renderFunction: renderClearAll,
        },
    },
    {
        id: SSO,
        content: function ({ aggregations }: IFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations[SSO],
                    key: SSO,
                    title: i18n.t('FILTERS.SSO', { ns: I18nTranslationKey }),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: MFA,
        content: function ({ aggregations }: IFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations[MFA],
                    key: MFA,
                    title: i18n.t('FILTERS.MFA', { ns: I18nTranslationKey }),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: API_KEYS,
        content: function ({ aggregations }: IFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations[API_KEYS],
                    key: API_KEYS,
                    title: i18n.t('FILTERS.API_KEYS', { ns: I18nTranslationKey }),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: ROLE,
        content: function ({ aggregations }: IFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations[ROLE],
                    key: ROLE,
                    title: i18n.t('FILTERS.ROLE', { ns: I18nTranslationKey }),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
];

export default function initializeUsersFilters() {
    usersTableRegistry.addFilterAddins(filterDefsAddins());
}
