import {
    IItemChanges,
    IItemsMap,
    ISingleDateItem,
    ISingleDateState,
} from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import { convertValueToDate, getTrimmedSingleValue } from 'common/erm-components/custom/CustomForm/CustomForm.values';
import { getExpirationDateLabel } from './CommonIgnoreList.items';
import { CommonIgnoreNames } from './CommonIgnoreList.consts';
import { isPassedDate } from 'common/erm-components/utils/dates';

export const getNewExpirationDateState = (
    item: ISingleDateItem,
    changeDetails: IItemChanges,
    itemsMap: IItemsMap,
): Promise<ISingleDateState | undefined> => {
    if (changeDetails.newItem.name !== CommonIgnoreNames.expirationDate) {
        return Promise.resolve(undefined);
    }

    const date: Date | undefined = convertValueToDate(
        getTrimmedSingleValue(itemsMap[CommonIgnoreNames.expirationDate]),
    );
    const expired = isPassedDate(date);

    return Promise.resolve({
        ...item.state,
        label: getExpirationDateLabel(expired),
        labelProps: { color: expired ? 'alert' : undefined },
    });
};
