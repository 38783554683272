import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { IIssueOrGroup, ISSUE_FIELD_NAMES } from 'modules/riskManagement/components/Issues/Issues.interface';
import { getSafeIssueSeverityInfo, IssueSeveritiesMap } from '../IssueSeverity';
import { openIssueDrawer } from '../Issues.utils';
import {
    IRuleTitleCellComponentProps,
    RuleTitleCellComponent,
} from 'common/components/ag-grid/Renderers/RuleTitleCellComponent';
import { SeverityLevelIconPropsLevel } from 'common/design-system/components-v2/LevelIcon/LevelIcon.types';

const IssueRuleTitleCellRender: React.FC<
    ICellRendererParams<IIssueOrGroup> & { customOnClick?: (ruleTitle: string, severityLevel: number) => void }
> = (params) => {
    if (!params.data) {
        return null;
    }
    const issueOrGroup: IIssueOrGroup = params.data;
    const ruleTitle: string = issueOrGroup?.ruleTitle;
    if (!ruleTitle) {
        return null;
    }
    const { customOnClick } = params;
    const componentProps: IRuleTitleCellComponentProps = {
        params,
        ruleTitle,
        ruleColId: ISSUE_FIELD_NAMES.RULE_TITLE,
        severityLevel: issueOrGroup.severity,
        drawerOpener: () => (issueOrGroup.id ? openIssueDrawer(issueOrGroup.id) : null),
        getSeverityKey: (severity?: number): SeverityLevelIconPropsLevel => {
            return severity === undefined ? IssueSeveritiesMap.unknown.key : getSafeIssueSeverityInfo(severity).key;
        },
        iconName: 'issue',
        customOnClick: customOnClick ? () => customOnClick(ruleTitle, issueOrGroup.severity) : undefined,
    };
    return <RuleTitleCellComponent {...componentProps} />;
};
export default IssueRuleTitleCellRender;
