import { DownloadSbom, GetSbom, GetSbomFormats } from './sbom.interface';
import { getHttpService } from 'common/interface/services';
import { AxiosResponse } from 'axios';

const getSbom: GetSbom.Function = async ({ entityId, entityType, environmentId }) => {
    return await getHttpService().request<AxiosResponse<GetSbom.Response>>(
        `sbom?entityId=${entityId}&entityType=${entityType}&environmentId=${environmentId}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true, cachingConfig: { useCache: true } },
        (err) => {
            throw err;
        },
    );
};

const downloadSbom: DownloadSbom.Function = async ({ environmentId, entityType, externalId, sbomFormatType }) => {
    return getHttpService().request<ArrayBuffer>(
        `sbom/export?EnvironmentId=${environmentId}&EntityType=${entityType}&ExternalId=${externalId}&SBOMVersionFormatId=${sbomFormatType}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/zip',
                Accept: 'application/zip',
            },
            responseType: 'arraybuffer',
        },
        {},
        (err) => {
            throw err;
        },
    );
};

const getSbomFormats = async () => {
    return getHttpService().request<GetSbomFormats.Response>(
        'sbom/formats',
        { method: 'GET' },
        { returnAsAxiosResponse: false, cachingConfig: { useCache: true } },
        (err) => {
            throw err;
        },
    );
};

const sbomService = {
    getSbom,
    downloadSbom,
    getSbomFormats,
};

export default sbomService;
