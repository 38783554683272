import { IRemediation } from 'common/interface/remediation';
import { IRuleset } from 'common/interface/ruleset';
import { ILicense } from 'common/interface/services';
import { IEnvironment } from 'common/interface/environmentsTable';
import { IOrganizationalUnit } from 'common/interface/data_services';

export interface RemediationState {
    remediationList: IRemediation[];
    rulesetList: IRuleset[];
    licenseList: ILicense[];
    cloudAccounts: IEnvironment[];
    organizationalUnits: IOrganizationalUnit[];
    cloudBots: any[];
}

export const RemediationInitialState: RemediationState = {
    remediationList: [],
    rulesetList: [],
    licenseList: [],
    cloudBots: [],
    cloudAccounts: [],
    organizationalUnits: [],
};
