import { ICellRendererParams } from 'ag-grid-enterprise';
import { Icon, Stack } from 'common/design-system/components-v2';
import { getExternalAdditionalFieldValue } from 'common/module_interface/assets/utils';
import React from 'react';

const AutoProtectCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const FSPStickyMode = getExternalAdditionalFieldValue(params.data, 'FSPStickyMode') === 'true';

    return (
        <Stack direction='row' alignItems='center' spacing={4}>
            {FSPStickyMode ? <Icon color={'success'} name='checkCircle' size={14}></Icon> : null}
        </Stack>
    );
};

export default AutoProtectCellRenderer;
