import { getVendorDisplayName } from 'common/consts/vendors';
import { IEnvironment } from 'common/interface/environmentsTable';
import { formatDate } from 'common/utils/helpFunctions';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';

export const MAX_ALLOWED_PERMISSIONS_CHECKS = 200;

export const exportTable = (cloudAccounts: IEnvironment[]) => {
    const mappedData = cloudAccounts.map((cloudAccount) => ({
        'Cloud Account ID': cloudAccount.externalId || 'N/A',
        'Cloud Account CloudGuard ID': cloudAccount.id,
        'Cloud Account Name': cloudAccount.name,
        Status: cloudAccount['customData|status'],
        Risk: cloudAccount['customData|riskScore'],
        Platform: getVendorDisplayName(cloudAccount.platform),
        'Onboarding Time': formatDate(cloudAccount.creationDate),
        'Organizational Unit Path':
            cloudAccount['customData|organizationalUnitPath'] || cloudAccount.organizationalUnitId,
        'Is Intelligence Enabled': cloudAccount['customData|intelligenceEnabled'] ? 'Yes' : 'No',
        'Is Iam Safety Enabled': cloudAccount['customData|iamSafety'] ? 'Yes' : 'No',
        'Is Serverless Protection Enabled': cloudAccount['customData|serverlessEnabled'] ? 'Yes' : 'No',
        'Is AWP Enabled': cloudAccount['customData|agentlessEnabled'] ? 'Yes' : 'No',
    }));

    const allRows: string[] = [Object.keys(mappedData[0]).join(',')];
    mappedData.forEach((row) => {
        allRows.push(Object.values(row).join(','));
    });
    const fileName = `Dome9_CloudAccounts_${dayjs(new Date()).format('YYYY MM DD hh:mm A').replace(/\s+/g, '_').replace(/,/g, '')}.csv`;
    const csvStr = allRows.join('\n');
    fileDownload(csvStr, fileName);
};
