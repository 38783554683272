import EmptyState from 'common/components/EmptyState';
import { NotificationType } from 'common/interface/notifications';
import { getNotificationsService } from 'common/interface/services';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useWorkloadEnvImagesScan } from 'modules/workloads/services/workload/hooks/useWorkloadEnvImagesScan';
import {
    ImageAssuraceScanEnvironment,
    ImageAssuraceScanEnvironmentScanAction,
} from 'modules/workloads/services/workload/workload.interface';
import { getScanStatus, getScanStatusDetails } from 'modules/workloads/utils';
import { useTranslation } from 'react-i18next';

const WorkloadsScanResultEmptyState = ({ entity }: { entity: ICloudEntityData }) => {
    const { t } = useTranslation(getK8sNamespace('common'));
    const { protectedAsset, cloudAccountId: environmentId } = entity;
    const scanStatus = getScanStatus(protectedAsset);
    const scanDetails = getScanStatusDetails(protectedAsset);
    const handleOnRescanEnvSuccess = (response: ImageAssuraceScanEnvironment.Response) => {
        const noneImagesToScan = response.imagesToScanCount === 0;
        const messagePrefix = noneImagesToScan ? 'emptyMessage' : 'successMessage';
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t(`workloadImageScan.envScan.${messagePrefix}.title`),
            text: t(`workloadImageScan.envScan.${messagePrefix}.body`, {
                imagesToScanCount: response.imagesToScanCount,
            }),
        });
    };
    const handleOnRescanError = (error: { message: string }) => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t(`workloadImageScan.envScan.errorMessage.title`),
            text: error.message || t(`workloadImageScan.envScan.errorMessage.body`),
        });
    };
    const { isLoading: isScanEnvRequestLoading, scanEnvImages } = useWorkloadEnvImagesScan({
        onSuccess: handleOnRescanEnvSuccess,
        onError: handleOnRescanError,
    });
    const handleOnScanClick = () => {
        return scanEnvImages({ environmentId, scanAction: ImageAssuraceScanEnvironmentScanAction.Failed });
    };
    return (
        <EmptyState
            label={
                scanStatus !== null
                    ? `${t('workloadImageScan.agentlessScan.header')} ${scanStatus}`
                    : t('noScanResults')
            }
            description={`${scanDetails ? `${scanDetails}\n` : ''}${t('workloadImageScan.envScan.errorMessage')}`}
            iconName='objectExplorer'
            iconSize={64}
            buttons={[
                {
                    color: 'brandPrimary',
                    onClick: handleOnScanClick,
                    children: t('workloadImageScan.agentlessScan.buttonText'),
                    disabled: isScanEnvRequestLoading,
                },
            ]}
        />
    );
};

export default WorkloadsScanResultEmptyState;
