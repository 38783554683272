import React, { FC, useCallback, useEffect, useState } from 'react';
import { ListHeader, CloseCardWrapper } from './configurationListManager.styled';
import { Button } from 'common/design-system/components-v2';
import OpenListCard from './ListComponents/openListCard';
import CardHeader from './ListComponents/CardHeader';
import { getConfigurationDrawerHeader } from '../Common/ConfigurationDrawerHeader';
import { DeleteConfigurationModal } from '../Common/DeleteConfigurationModal';
import { getIntegrationsService } from 'common/interface/services';
import { ITestFormatTypeKey } from 'common/module_interface/settings/integrations/consts';
import { IConfiguration } from 'common/module_interface/settings/integrations/Integrations';
import { LoadingSpinnerComponent } from '../LoadingSpinner/LoadingSpinnerComponent';
import { integrationNotificationReadOnly } from 'common/utils/userPermissionUtils';

export type ConfigurationListMode = 'list' | 'new' | 'single';
export interface IConfigurationListManagerProps {
    configurationComponent: FC<any>;
    onConfigurationChangeCallBack?: Function;
    onConfigurationSaved?: (name: string, configurationObj: any, id: string) => Promise<any>;
    onConfigurationDelete?: (configurationID: string) => Promise<any>;
    limit?: number;
    integrationTypeID: string;
    testFormatType?: ITestFormatTypeKey;
    mode?: ConfigurationListMode;
    singleConfigurationId?: string;
    SpecialMessage?: FC<{}>;
}
const ConfigurationListManager: React.FC<IConfigurationListManagerProps> = ({
    singleConfigurationId,
    mode = 'list',
    integrationTypeID,
    configurationComponent,
    onConfigurationChangeCallBack,
    limit,
    onConfigurationSaved,
    onConfigurationDelete,
    testFormatType,
    SpecialMessage,
}) => {
    const [openConfigurationIndex, setOpenConfigurationIndex] = useState(-1);
    const [configurationsList, setConfigurationsList] = useState<IConfiguration[]>();
    const [deleteModalProps, setDeleteModalProps] = useState({ isOpen: false, selectedIndex: -1 });
    const [isLoadingData, setIsLoadingData] = useState(false);
    const viewMode = integrationNotificationReadOnly();

    const addEmptyConfigurationItem = useCallback(() => {
        const newConfiguration: IConfiguration = {
            name: '',
            configurationObj: {} as any,
        };
        const newConfigurationsList = [newConfiguration];
        setConfigurationsList(newConfigurationsList);
        setOpenConfigurationIndex(0);
    }, []);

    useEffect(() => {
        const initData = async () => {
            const configurations = await getIntegrationsService().getAllConfigurationsSlim(true);
            const registeredConfigurationMap = await getIntegrationsService().getRegisteredConfigurationsData();
            setIsLoadingData(false);
            const registeredConfigurations = registeredConfigurationMap[integrationTypeID];
            const _configurationList = registeredConfigurations || configurations[integrationTypeID] || [];
            if (mode === 'single' && singleConfigurationId) {
                const _singleConfiguration = _configurationList?.find(
                    (_configuration: any) => _configuration.id === singleConfigurationId,
                );
                if (_singleConfiguration) {
                    setConfigurationsList([_singleConfiguration as IConfiguration]);
                    setOpenConfigurationIndex(0);
                }
            } else {
                setConfigurationsList(_configurationList);
                if (_configurationList.length) {
                    setOpenConfigurationIndex(-1);
                }
            }
        };
        if (mode === 'new') {
            addEmptyConfigurationItem();
        } else {
            setIsLoadingData(true);
            void initData();
        }
    }, [addEmptyConfigurationItem, integrationTypeID, mode, singleConfigurationId]);
    const closeOpenCard = () => {
        setOpenConfigurationIndex(-1);
    };

    const shouldShowAddButton = () => {
        return !limit || (limit && configurationsList && configurationsList.length < limit);
    };

    const onAddConfigurationClicked = useCallback(() => {
        const newConfigurationsList = configurationsList ? [...configurationsList] : [];
        const newConfiguration: IConfiguration = {
            name: '',
            configurationObj: {} as any,
        };
        newConfigurationsList.unshift(newConfiguration);
        setConfigurationsList(newConfigurationsList);
        setOpenConfigurationIndex(0);
    }, [configurationsList]);

    const onDeleteConfiguration = async (id: string) => {
        try {
            onConfigurationDelete && (await onConfigurationDelete(id));
            onConfigurationChangeCallBack && onConfigurationChangeCallBack();
            const newConfigurationsList = configurationsList?.filter((configuration) => configuration.id !== id);
            setConfigurationsList(newConfigurationsList);
            setDeleteModalProps({ isOpen: false, selectedIndex: -1 });
        } catch (error) {
            setDeleteModalProps({ isOpen: false, selectedIndex: -1 });
        }
    };

    const onSavedConfiguration = async (configurationName: string, configObj: any, configurationId: string) => {
        const resp =
            onConfigurationSaved && (await onConfigurationSaved(configurationName, configObj, configurationId));
        const newConfigurationsList = configurationsList ? [...configurationsList] : [];
        newConfigurationsList[openConfigurationIndex] = {
            name: configurationName,
            configurationObj: configObj,
            id: resp.id || configurationId,
        };
        setConfigurationsList(newConfigurationsList);
        return resp;
    };

    useEffect(() => {
        if (configurationsList?.length === 0) {
            addEmptyConfigurationItem();
        }
    }, [addEmptyConfigurationItem, configurationsList, onAddConfigurationClicked]);

    return (
        <div>
            {SpecialMessage && <SpecialMessage />}
            <ListHeader>
                {getConfigurationDrawerHeader()}
                {shouldShowAddButton() && (
                    <Button
                        iconProps={{ name: 'plus', size: 12 }}
                        variant='outlined'
                        color='brandPrimary'
                        dataAid='addConfiguration'
                        disabled={viewMode}
                        onClick={() => onAddConfigurationClicked()}
                    >
                        Add
                    </Button>
                )}
            </ListHeader>
            {isLoadingData ? (
                <LoadingSpinnerComponent />
            ) : (
                configurationsList?.map((configuration, index: number) => {
                    return index === openConfigurationIndex ? (
                        <OpenListCard
                            integrationTypeID={integrationTypeID}
                            key={configuration.id}
                            configuration={configuration}
                            configurationComponent={configurationComponent}
                            viewMode={viewMode}
                            configurationComponentProps={{
                                configuration: configuration,
                                onConfigurationChangeCallBack: onConfigurationChangeCallBack,
                                onConfigurationSaved: onSavedConfiguration,
                                viewMode: viewMode,
                                testFormatType: testFormatType,
                            }}
                            onHeaderClicked={() => closeOpenCard()}
                            onDeleteClicked={() =>
                                setDeleteModalProps({
                                    isOpen: true,
                                    selectedIndex: index,
                                })
                            }
                        />
                    ) : (
                        <CloseCardWrapper data-aid={'config-card'} onClick={() => setOpenConfigurationIndex(index)}>
                            <CardHeader
                                configuration={configuration}
                                viewMode={viewMode}
                                onDeleteClicked={() =>
                                    setDeleteModalProps({
                                        isOpen: true,
                                        selectedIndex: index,
                                    })
                                }
                                arrowIcon='chevronRight'
                            ></CardHeader>
                        </CloseCardWrapper>
                    );
                })
            )}
            <>
                {deleteModalProps.isOpen && (
                    <DeleteConfigurationModal
                        isOpen={deleteModalProps.isOpen}
                        configurationName={
                            (configurationsList && configurationsList[deleteModalProps.selectedIndex].name) || ''
                        }
                        onDeleteCallBack={() =>
                            onDeleteConfiguration(
                                (configurationsList && configurationsList[deleteModalProps.selectedIndex].id) || '',
                            )
                        }
                        onModalCloseCallBack={() => setDeleteModalProps({ isOpen: false, selectedIndex: -1 })}
                    />
                )}
            </>
        </div>
    );
};

export default ConfigurationListManager;
