import { Dropdown, List } from 'common/design-system/components-v2';
import { UserRolesTypes } from 'common/enum/UserRoles';
import { getUserService } from 'common/interface/services';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { environmentsTableAddNewLinks } from '../components/EnvironmentsTable/EnvironmentsTable.types';
import { getEnvsNamespace } from '../initialize.i18n';
import { Vendors } from 'common/consts/vendors';
import { IListItemProps } from 'common/design-system/components-v2/List/List.types';

interface AddEnvironmentActionProps {
    vendors?: Vendors[];
}

const AddEnvironmentAction = React.forwardRef<HTMLButtonElement, AddEnvironmentActionProps>((params, ref) => {
    const { t } = useTranslation(getEnvsNamespace('table'));

    const isAddNewDisabled = React.useMemo(() => {
        return !getUserService().hasAnyPermission([UserRolesTypes.SUPER_USER, UserRolesTypes.ONBOARDING_PERMITTED]);
    }, []);

    const listOptions = React.useMemo<IListItemProps[]>(() => {
        return environmentsTableAddNewLinks(t)
            .filter((envLink) => {
                const isRelevant = envLink.isRelevant ? envLink.isRelevant() : true;
                const isRelevantByVendor = !params.vendors || params.vendors.includes(envLink.vendor);
                return isRelevant && isRelevantByVendor;
            })
            .map((envLink) => ({
                label: envLink.label,
                labelProps: { leadingIconProps: envLink.iconProps },
                value: envLink.href,
                linkProps: { internalUrl: envLink.href },
            }));
    }, [t, params.vendors]);

    return (
        <Dropdown
            ref={ref}
            label={t('ADD_ENVIRONMENT.ADD')}
            buttonProps={{ iconProps: { name: 'plus' }, disabled: isAddNewDisabled, dataAid: 'add-new-environment' }}
            placement='bottom-start'
            maxHeight={300}
        >
            <List data-aid='add-new-environment-list' options={listOptions} />
        </Dropdown>
    );
});
AddEnvironmentAction.displayName = 'AddEnvironmentAction';

export default AddEnvironmentAction;
