import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getCloudGuardRegistrationRoute, getIsCloudInfraAvailable } from 'common/utils/RuntimeEnvironment';
import { Pages } from 'common/enum/Pages';
import { CaptchaValidationFormContentType } from 'common/components/Forms/CaptchaValidationForm.consts';
import { Spinner, Stack } from 'common/design-system/components-v2';
import CaptchaValidationForm from 'common/components/Forms/CaptchaValidationForm';
import AuthenticatedPage from './platform/main/AuthenticatedPage';

interface route {
    exact: boolean;
    path: string;
    authPage?: boolean;
    recaptchaPage?: boolean;
    recaptchaProps?: any;
    component?: React.FC;
    deprecated?: boolean;
}

const AppRouter: React.FunctionComponent = () => {
    const isCloudInfraAvailable = getIsCloudInfraAvailable();
    const registerRoute = getCloudGuardRegistrationRoute(isCloudInfraAvailable);

    const routes: route[] = React.useMemo(
        () => [
            {
                exact: true,
                path: `/not-authorized`,
                authPage: false,
                component: lazy(() => import('common/components/ErrorPages/NotAuthorizedPage/NotAuthorizedPage')),
            },
            {
                exact: true,
                path: `/${Pages.Report}`,
                authPage: true,
                component: lazy(() => import('platform/Reports/StoredReport')),
            },
            {
                exact: true,
                path: `/${Pages.BigReport}`,
                authPage: true,
                component: lazy(() => import('platform/Reports/StoredReport')),
                deprecated: true,
            },
            {
                exact: true,
                path: `/${Pages.AssessmentsExecutiveReport}`,
                authPage: true,
                component: lazy(() => import('platform/Reports/ExecutiveReport/ExecutiveReport')),
            },
            {
                exact: true,
                path: `/${Pages.Login}`,
                component: lazy(() => import('platform/account/Login/Login')),
            },
            {
                exact: true,
                path: `/${Pages.Error}`,
                component: lazy(() => import('platform/Error/ErrorPage')),
            },
            {
                exact: true,
                path: `/${Pages.ResetPassword}`,
                component: lazy(() => import('platform/account/ResetPassword/ResetPassword')),
            },
            {
                exact: true,
                path: `/${Pages.Register}/${Pages.Register__SignUp}`,
                component: lazy(() => import('platform/account/Signup/Signup')),
            },
            {
                exact: true,
                path: `/${Pages.ForgotPassword}`,
                recaptchaPage: true,
                recaptchaProps: CaptchaValidationFormContentType.FORGOT_PASSWORD,
            },
            {
                exact: true,
                path: registerRoute,
                recaptchaPage: true,
                recaptchaProps: CaptchaValidationFormContentType.REGISTER,
            },
            {
                exact: true,
                path: `/${Pages.Sso}`,
                component: lazy(() => import('platform/account/Login/Sso')),
            },
            {
                exact: true,
                path: `/${Pages.Sso}/${Pages.Sso__FailedLogin}`,
                component: lazy(() => import('./platform/account/Login/SsoFailedLogin')),
            },
            {
                exact: false,
                path: '/',
                authPage: true,
                component: lazy(() => import('./platform/main/Main')),
            },
        ],
        [registerRoute],
    );

    return (
        <Switch>
            <Redirect from={`/${Pages.Register}/${Pages.Register__Invite}`} to={`/${Pages.Register}`} />
            {routes.map((route, index) => {
                const RouteComp = route.component;
                return (
                    <Route
                        key={`AppRouterRoute-${index}`}
                        exact={route.exact}
                        path={route.path}
                        render={(routeProps) => {
                            if (route.recaptchaPage) {
                                return <CaptchaValidationForm {...routeProps} {...(route.recaptchaProps || {})} />;
                            }
                            if (!RouteComp) return null;
                            return (
                                <Suspense
                                    fallback={
                                        <Stack fullHeight fullWidth justifyContent='center' alignItems='center'>
                                            <Spinner size={64} context='info' saturation />
                                        </Stack>
                                    }
                                >
                                    {route.authPage ? (
                                        <AuthenticatedPage>{RouteComp && <RouteComp />}</AuthenticatedPage>
                                    ) : (
                                        <RouteComp />
                                    )}
                                </Suspense>
                            );
                        }}
                    />
                );
            })}
        </Switch>
    );
};

export default AppRouter;
