import React, { FC, FunctionComponentElement, useEffect } from 'react';
import { CardWrapper, DividerLine } from '../configurationListManager.styled';
import CardHeader from './CardHeader';
import { Stack } from 'common/design-system/components-v2';
import { IConfiguration } from 'common/module_interface/settings/integrations/Integrations';
import { getIntegrationsService } from 'common/interface/services';
import { LoadingSpinnerComponent } from '../../LoadingSpinner/LoadingSpinnerComponent';

interface IOpenListCardProps {
    configurationComponent: FC<any>;
    configurationComponentProps: any;
    configuration: IConfiguration;
    onDeleteClicked?: Function;
    onHeaderClicked?: () => void;
    viewMode?: boolean;
    integrationTypeID?: string;
}
const OpenListCard: React.FC<IOpenListCardProps> = ({
    integrationTypeID,
    configurationComponent,
    configurationComponentProps,
    configuration,
    viewMode,
    onDeleteClicked,
    onHeaderClicked,
}) => {
    const [displayedComponent, setDisplayedComponent] = React.useState<FunctionComponentElement<any>>();
    useEffect(() => {
        const initData = async () => {
            const integrationService = getIntegrationsService();
            const registeredConfigurations = await integrationService.getRegisteredConfigurationsData();
            if (integrationTypeID) {
                if (configuration.id) {
                    if (registeredConfigurations[integrationTypeID]) {
                        configurationComponentProps.configuration = registeredConfigurations[integrationTypeID].find(
                            (registeredConfiguration: any) => registeredConfiguration.id === configuration?.id,
                        );
                    } else {
                        const data = await integrationService.getConfigurationById(configuration.id);
                        configurationComponentProps.configuration.configurationObj = data.configuration;
                        configurationComponentProps.configuration.name = data.name;
                        configurationComponentProps.configuration.id = data.id;
                        configurationComponentProps.configuration.createdAt = data.createdAt;
                    }
                }
            }
            setDisplayedComponent(React.createElement(configurationComponent, { ...configurationComponentProps }));
        };
        void initData();
    }, [configuration.id, configurationComponent, configurationComponentProps, integrationTypeID]);

    return (
        <CardWrapper direction='row' alignItems='center' data-aid='singleConfiguration'>
            <CardHeader
                configuration={configuration}
                viewMode={viewMode}
                onClick={onHeaderClicked}
                onDeleteClicked={onDeleteClicked}
                arrowIcon='chevronDown'
            ></CardHeader>
            <DividerLine />
            {displayedComponent ? (
                <Stack padding={2} direction={'row'} fullWidth={true}>
                    {displayedComponent}
                </Stack>
            ) : (
                <LoadingSpinnerComponent />
            )}
        </CardWrapper>
    );
};

export default OpenListCard;
