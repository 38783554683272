import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const LoaderDiv = styled(Stack)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 360px;
`;

const NoteDiv = styled(Stack)`
    padding-left: 24px;
    height: 30px;
`;

const TableDiv = styled(Stack)`
    width: 100%;
    height: 100%;
    margin-bottom: 16px;
`;
export const IssueCDREventsStyled = {
    LoaderDiv,
    NoteDiv,
    TableDiv,
};
