import React from 'react';
import { Icon, Stack, Tooltip } from 'common/design-system/components-v2';
import IsFixableCellRendererStyles from './IsFixableCellRenderer.styled';
import { ICellRendererParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { isTrue, isGrouping } from 'modules/workloads/utils/aggrid';
import { aggregationByProperty } from '../../protectedAsset/page/tabs/Vulnerability/VulnerabilityTabs/Cves/ColumnDefs';

const IsFixableCellRenderer: React.FC<ICellRendererParams & { fieldName: string }> = (props) => {
    const { value, node } = props;
    const { t } = useTranslation(getK8sNamespace('vulnerability'));
    const status = t(`VulnerabilityTable.cells.fixable.${value}`);
    const areRowsGroupedByFixable = isGrouping(props?.fieldName, node);

    if (node.group && !areRowsGroupedByFixable) {
        return aggregationByProperty(
            node.allLeafChildren.map((child) => child.data),
            'isFixedByPackageRemediation',
        );
    }

    if (!value) {
        return '';
    }

    return (
        <Stack direction='row' alignItems='center' fullHeight fullWidth spacing={1}>
            <Tooltip content={areRowsGroupedByFixable ? '' : status}>
                <IsFixableCellRendererStyles.IconWrapper isFixable={isTrue(value)} padding={1}>
                    <Icon name='remedy' />
                </IsFixableCellRendererStyles.IconWrapper>
            </Tooltip>
            {areRowsGroupedByFixable ? status : null}
        </Stack>
    );
};

export default IsFixableCellRenderer;
