import styled from 'styled-components';

const SentraComponentStyled = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const SentraNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 24px 24px 30px 24px;
    gap: 8px;
`;

const SentraApiKeyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0 24px 30px 24px;
    gap: 8px;
`;

const SentraAccountNameWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-items: center;
    gap: 8px;
    margin: 0 24px 30px 24px;
    width: 100%;
`;

export { SentraComponentStyled, SentraNameWrapper, SentraApiKeyWrapper, SentraAccountNameWrapper };
