import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { IIdenticalIdentitiesTreeNode } from 'common/module_interface/identity/IdenticalRoles.interfaces';
import { identityTrans } from '../initialize.i18n';
import { PermissionsCellRenderer } from './CellRenderers/PermissionsCellRenderer';
import { IdentitiesGroupCellRenderer } from './CellRenderers/IdentitiesGroupCellRenderer';
import { IdentityIdCellRenderer } from './CellRenderers/IdentityIdCellRenderer';

export const getColumnDefs = (): ColDef<IIdenticalIdentitiesTreeNode>[] => {
    return [
        {
            field: 'id',
            headerName: identityTrans('IDENTICAL_IDENTITIES_MODAL.TABLE.COLUMNS.IDENTITY_ID.HEADER'),
            headerTooltip: identityTrans('IDENTICAL_IDENTITIES_MODAL.TABLE.COLUMNS.IDENTITY_ID.TOOLTIP'),
            minWidth: 300,
            flex: 5,
            sortable: true,
            cellRenderer: IdentityIdCellRenderer,
            suppressColumnsToolPanel: true,
        },
        {
            field: 'duplicates',
            headerName: identityTrans('IDENTICAL_IDENTITIES_MODAL.TABLE.COLUMNS.DUPLICATES.HEADER'),
            headerTooltip: identityTrans('IDENTICAL_IDENTITIES_MODAL.TABLE.COLUMNS.DUPLICATES.TOOLTIP'),
            minWidth: 100,
            flex: 1,
            sortable: true,
        },
        {
            field: 'permissions',
            headerName: identityTrans('IDENTICAL_IDENTITIES_MODAL.TABLE.COLUMNS.PERMISSIONS.HEADER'),
            headerTooltip: identityTrans('IDENTICAL_IDENTITIES_MODAL.TABLE.COLUMNS.PERMISSIONS.TOOLTIP'),
            minWidth: 100,
            flex: 1,
            cellRenderer: PermissionsCellRenderer,
        },
    ];
};

export const getAutoGroupColumnDef = (): ColDef<IIdenticalIdentitiesTreeNode> => {
    return {
        headerName: identityTrans('IDENTICAL_IDENTITIES_MODAL.TABLE.COLUMNS.IDENTITIES.HEADER'),
        headerTooltip: identityTrans('IDENTICAL_IDENTITIES_MODAL.TABLE.COLUMNS.IDENTITIES.TOOLTIP'),
        minWidth: 200,
        flex: 3,
        cellRenderer: IdentitiesGroupCellRenderer,
    };
};
