import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    AzureConfiguration,
    AzureConfigurationObjectType,
    AzureOnboardingAccountType,
    AzureOnboardingAWPScanType,
    AzureOnboardingOperationMode,
    AzureOnboardingScope,
    AzureOnboardingState,
    AzureOnboardingType,
    AzureWelcomeForm,
} from './azure.interface';
import { OnboardingConfigurationTypes } from '../../helpers/onboarding.interface';
import { RootState } from 'common/services/store/store';
import { getValidationErrors } from 'common/components/Forms/FormValidations';
import {
    azureConnectMultiTenantValidation,
    azureConnectPageValidation,
    azureConnectSubscriptionValidation,
    azureOrgOnboardingWelcomeValidationsSubscription,
    azureOnboardingWelcomeValidationsChina,
    azureOrgOnboardingWelcomeValidationsManagementGroup,
    azureOrgOnboardingWelcomeValidationsTenant,
    azurePreparePageValidation,
    azureConfigurationPageValidation,
    azureSingleOnboardingValidationsSubscription,
    azureManualOnboardingWelcomeValidationsChina,
} from './azure.validations';
import * as yup from 'yup';
import i18n from 'common/services/translations/translations';

const initialState: AzureOnboardingState = {
    welcomeForm: {},
    name: '',
    successText: i18n.t('ON_BOARDING.AZURE.ACCOUNT_ONBOARDED_SUCCESSFULLY_SUBS'),
    isManualOnboardingFlow: false,
    isOrgOnboarding: false,
    azureOnboardingType: AzureOnboardingType.single,
    azureOnboardingAccountType: AzureOnboardingAccountType.standard,
    azureOnboardingScope: AzureOnboardingScope.tenant,
    azureOnboardingOperationMode: AzureOnboardingOperationMode.read,
    credentials: {},
    isMarkRunningCommand: false,
    isLoading: false,
    onboardingScript: '',
    commandCloudShell: '',
    isAllowAzureChinaPrivacyPolicy: false,
    isOnBoardingScriptCompleted: false,
    isTenantIdAlreadyOnboarded: false,
    isSubscriptionIdAlreadyOnboarded: false,
    isManagementGroupIdAlreadyOnboarded: false,
    isAutoOnboarding: true,
    cloudShellUrl: 'https://shell.azure.com',
    organizationalUnitId: '00000000-0000-0000-0000-000000000000',
    activeBlades: {
        serverless: { isEnabled: false },
        awp: {
            isEnabled: false,
            onboardingMode: 'saas',
        },
        postureManagement: {
            onboardingMode: 'Read',
        },
    },
    azureConfigurations: {
        cspm: { isEnabled: true },
        serverless: { isEnabled: false },
        awp: {
            isEnabled: false,
            onboardingMode: AzureOnboardingAWPScanType.inAccountHub,
        },
        cdr: {
            isEnabled: true,
            accounts: [],
        },
        postureManagement: {
            onboardingMode: 'Read',
        },
    },
};

export const AzureOnboardingSlice = createSlice({
    name: 'azureOnboarding',
    initialState,
    reducers: {
        setWelcomeForm: (
            state: AzureOnboardingState,
            action: PayloadAction<{
                key: keyof AzureWelcomeForm;
                value: AzureWelcomeForm[keyof AzureWelcomeForm];
            }>,
        ) => {
            state.welcomeForm[action.payload.key] = action.payload.value;
        },
        setAzureOnboardingAccountType: (
            state: AzureOnboardingState,
            action: PayloadAction<AzureOnboardingAccountType>,
        ) => {
            state.azureOnboardingAccountType = action.payload;
        },
        setIsTenantIdAlreadyOnboarded: (state: AzureOnboardingState, action: PayloadAction<boolean | string>) => {
            state.isTenantIdAlreadyOnboarded = action.payload;
        },
        setIsSubscriptionIdAlreadyOnboarded: (state: AzureOnboardingState, action: PayloadAction<boolean | string>) => {
            state.isSubscriptionIdAlreadyOnboarded = action.payload;
        },
        setIsManagementGroupIdAlreadyOnboarded: (
            state: AzureOnboardingState,
            action: PayloadAction<boolean | string>,
        ) => {
            state.isManagementGroupIdAlreadyOnboarded = action.payload;
        },
        setIsAutoOnboarding: (state: AzureOnboardingState, action: PayloadAction<boolean>) => {
            state.isAutoOnboarding = action.payload;
        },
        setAzureOnboardingType: (state: AzureOnboardingState, action: PayloadAction<AzureOnboardingType>) => {
            state.azureOnboardingType = action.payload;
        },
        setAzureOnboardingScope: (state: AzureOnboardingState, action: PayloadAction<AzureOnboardingScope>) => {
            state.azureOnboardingScope = action.payload;
            if (state.azureOnboardingType === AzureOnboardingType.single || state.isManualOnboardingFlow) {
                state.successText = i18n.t('ON_BOARDING.AZURE.ACCOUNT_ONBOARDED_SUCCESSFULLY_SUBS');
                state.isOrgOnboarding = false;
            } else if (state.azureOnboardingScope === AzureOnboardingScope.managementGroup) {
                state.successText = i18n.t('ON_BOARDING.AZURE.ACCOUNT_ONBOARDED_SUCCESSFULLY_MG');
                state.isOrgOnboarding = true;
            } else if (state.azureOnboardingScope === AzureOnboardingScope.tenant) {
                state.successText = i18n.t('ON_BOARDING.AZURE.ACCOUNT_ONBOARDED_SUCCESSFULLY_TENANT');
                state.isOrgOnboarding = true;
            } else {
                state.successText = i18n.t('ON_BOARDING.AZURE.ACCOUNT_ONBOARDED_SUCCESSFULLY_SUBS');
                state.isOrgOnboarding = false;
            }
        },
        setAzureOnboardingName: (state: AzureOnboardingState, action: PayloadAction<string>) => {
            state.name = action.payload;
        },
        setAzureOnboardingOperationMode: (
            state: AzureOnboardingState,
            action: PayloadAction<AzureOnboardingOperationMode>,
        ) => {
            state.azureOnboardingOperationMode = action.payload;
        },
        setAzureConfigurations: (
            state: AzureOnboardingState,
            action: PayloadAction<{ key: OnboardingConfigurationTypes; value: AzureConfigurationObjectType }>,
        ) => {
            const { key, value } = action.payload;
            state.azureConfigurations = { ...state.azureConfigurations, [key]: value };
        },
        setAllSubscriptionIds: (state: AzureOnboardingState, action: PayloadAction<string[]>) => {
            state.allSubscriptionIds = action.payload;
        },
        setMarkRunningBox: (state: AzureOnboardingState, action: PayloadAction<boolean>) => {
            state.isMarkRunningCommand = action.payload;
        },
        setClientId: (state: AzureOnboardingState, action: PayloadAction<string | undefined>) => {
            state.credentials.clientId = action.payload;
        },
        setClientPassword: (state: AzureOnboardingState, action: PayloadAction<string | undefined>) => {
            state.credentials.clientPassword = action.payload;
        },
        setIsLoading: (state: AzureOnboardingState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setAzureOnboardingScript: (state: AzureOnboardingState, action: PayloadAction<string>) => {
            state.onboardingScript = action.payload;
        },
        setAzureCommandCloudShell: (state: AzureOnboardingState, action: PayloadAction<string>) => {
            state.commandCloudShell = action.payload;
        },
        setAllowAzureChinaPrivacyPolicy: (state: AzureOnboardingState, action: PayloadAction<boolean>) => {
            state.isAllowAzureChinaPrivacyPolicy = action.payload;
        },
        setIsOnBoardingScriptCompleted: (state: AzureOnboardingState, action: PayloadAction<boolean>) => {
            state.isOnBoardingScriptCompleted = action.payload;
        },
        setCloudShellUrl: (state: AzureOnboardingState, action: PayloadAction<string>) => {
            let url = 'https://shell.azure.com';
            if (action.payload === AzureOnboardingAccountType.gov) {
                url = 'https://portal.azure.us/#cloudshell/';
            } else if (action.payload === AzureOnboardingAccountType.china) {
                url = 'https://portal.azure.cn/#cloudshell/';
            }
            state.cloudShellUrl = url;
        },
        setOrganizationalUnitId: (state: AzureOnboardingState, action: PayloadAction<string>) => {
            state.organizationalUnitId = action.payload;
        },
        setAzureActiveBlades: (state: AzureOnboardingState) => {
            const { cdr, awp } = state.azureConfigurations;
            state.activeBlades = { ...state.activeBlades, awp, cdr };
        },
        setManualOnBoardingFlow: (state: AzureOnboardingState, action: PayloadAction<boolean>) => {
            state.isManualOnboardingFlow = action.payload;
        },
        clearAzureOnboardingState: () => initialState,
    },
});

export const {
    setOrganizationalUnitId,
    clearAzureOnboardingState,
    setAzureOnboardingType,
    setAzureOnboardingAccountType,
    setAzureOnboardingScope,
    setAzureOnboardingName,
    setAzureOnboardingOperationMode,
    setAzureConfigurations,
    setAllowAzureChinaPrivacyPolicy,
    setIsOnBoardingScriptCompleted,
    setAllSubscriptionIds,
    setIsTenantIdAlreadyOnboarded,
    setIsSubscriptionIdAlreadyOnboarded,
    setIsManagementGroupIdAlreadyOnboarded,
    setIsAutoOnboarding,
    setWelcomeForm,
    setMarkRunningBox,
    setClientId,
    setIsLoading,
    setClientPassword,
    setAzureOnboardingScript,
    setAzureCommandCloudShell,
    setCloudShellUrl,
    setAzureActiveBlades,
    setManualOnBoardingFlow,
} = AzureOnboardingSlice.actions;

export default AzureOnboardingSlice.reducer;

export const getOrganizationalUnitId = (state: RootState): string => state.azureOnboarding.organizationalUnitId;
export const getIsLoading = (state: RootState) => state.azureOnboarding.isLoading;
export const getSuccessText = (state: RootState) => state.azureOnboarding.successText;
export const getIsOrgOnboarding = (state: RootState) => state.azureOnboarding.isOrgOnboarding;
export const getAllowAzureChinaPrivacyPolicy = (state: RootState) =>
    state.azureOnboarding.isAllowAzureChinaPrivacyPolicy;
export const getIsOnBoardingScriptCompleted = (state: RootState) => state.azureOnboarding.isOnBoardingScriptCompleted;
export const getWelcomeForm = (state: RootState): AzureWelcomeForm => state.azureOnboarding.welcomeForm;
export const getAzureOnboardingName = (state: RootState): string => state.azureOnboarding.name;
export const getAzureOnboardingType = (state: RootState): AzureOnboardingType =>
    state.azureOnboarding.azureOnboardingType;
export const getAzureOnboardingAccountType = (state: RootState): AzureOnboardingAccountType =>
    state.azureOnboarding.azureOnboardingAccountType;
export const getAzureOnboardingScope = (state: RootState): AzureOnboardingScope =>
    state.azureOnboarding.azureOnboardingScope;
export const getAzureOnboardingOperationMode = (state: RootState): AzureOnboardingOperationMode =>
    state.azureOnboarding.azureOnboardingOperationMode;
export const getAllSubscriptionIds = (state: RootState): string[] => state.azureOnboarding.allSubscriptionIds;
export const getIsTenantIdAlreadyOnboarded = (state: RootState): boolean =>
    state.azureOnboarding.isTenantIdAlreadyOnboarded;
export const getIsSubscriptionIdAlreadyOnboarded = (state: RootState): boolean =>
    state.azureOnboarding.isSubscriptionIdAlreadyOnboarded;
export const getIsManagementGroupIdAlreadyOnboarded = (state: RootState): boolean =>
    state.azureOnboarding.isManagementGroupIdAlreadyOnboarded;
export const getIsAutoOnboarding = (state: RootState): boolean => state.azureOnboarding.isAutoOnboarding;
export const getMarkRunningBox = (state: RootState): AzureWelcomeForm => state.azureOnboarding.isMarkRunningCommand;
export const getClientId = (state: RootState): string | undefined => state.azureOnboarding.credentials.clientId;
export const getClientPassword = (state: RootState): string | undefined =>
    state.azureOnboarding.credentials.clientPassword;
export const getAzureOnboardingScript = (state: RootState): string => state.azureOnboarding.onboardingScript;
export const getAzureCommandCloudShell = (state: RootState): string => state.azureOnboarding.commandCloudShell;
export const getCloudShellUrl = (state: RootState): string => state.azureOnboarding.cloudShellUrl;
export const getAzureConfigurations = (state: RootState): AzureConfiguration =>
    state.azureOnboarding.azureConfigurations;
export const getManualOnBoardingFlow = (state: RootState): AzureConfiguration =>
    state.azureOnboarding.isManualOnboardingFlow;

export const validateAzureSingleWelcome = (state: RootState): { valid: boolean; errors?: any } => {
    switch (state.azureOnboarding.azureOnboardingAccountType) {
        case AzureOnboardingAccountType.standard: {
            try {
                yup.object().shape(azureSingleOnboardingValidationsSubscription).validateSync(
                    {
                        subscriptionId: state.azureOnboarding.welcomeForm.subscriptionId,
                    },
                    { abortEarly: false },
                );
                return { valid: true };
            } catch (error: any) {
                return { valid: false, errors: getValidationErrors(error) };
            }
        }
        case AzureOnboardingAccountType.gov: {
            try {
                yup.object().shape(azureSingleOnboardingValidationsSubscription).validateSync(
                    {
                        subscriptionId: state.azureOnboarding.welcomeForm.subscriptionId,
                    },
                    { abortEarly: false },
                );
                return { valid: true };
            } catch (error: any) {
                return { valid: false, errors: getValidationErrors(error) };
            }
        }
        case AzureOnboardingAccountType.china: {
            try {
                yup.object().shape(azureOnboardingWelcomeValidationsChina).validateSync(
                    {
                        subscriptionId: state.azureOnboarding.welcomeForm.subscriptionId,
                        isAllowAzureChinaPrivacyPolicy: state.azureOnboarding.isAllowAzureChinaPrivacyPolicy,
                    },
                    { abortEarly: false },
                );
                return { valid: true };
            } catch (error: any) {
                return { valid: false, errors: getValidationErrors(error) };
            }
        }
        default:
            return { valid: false };
    }
    return { valid: false };
};
export const validateAzureOrgWelcome = (state: RootState): { valid: boolean; errors?: any } => {
    if (state.azureOnboarding.azureOnboardingAccountType === AzureOnboardingAccountType.standard) {
        switch (state.azureOnboarding.azureOnboardingScope) {
            case AzureOnboardingScope.tenant: {
                try {
                    yup.object().shape(azureOrgOnboardingWelcomeValidationsTenant).validateSync(
                        {
                            tenantId: state.azureOnboarding.welcomeForm.tenantId,
                        },
                        { abortEarly: false },
                    );
                    return { valid: true };
                } catch (error: any) {
                    return { valid: false, errors: getValidationErrors(error) };
                }
            }
            case AzureOnboardingScope.managementGroup: {
                try {
                    yup.object().shape(azureOrgOnboardingWelcomeValidationsManagementGroup).validateSync(
                        {
                            tenantId: state.azureOnboarding.welcomeForm.tenantId,
                            managementGroupId: state.azureOnboarding.welcomeForm.managementGroupId,
                        },
                        { abortEarly: false },
                    );
                    return { valid: true };
                } catch (error: any) {
                    return { valid: false, errors: getValidationErrors(error) };
                }
            }
            case AzureOnboardingScope.subscription: {
                try {
                    yup.object().shape(azureOrgOnboardingWelcomeValidationsSubscription).validateSync(
                        {
                            tenantId: state.azureOnboarding.welcomeForm.tenantId,
                            subscriptionId: state.azureOnboarding.welcomeForm.subscriptionId,
                        },
                        { abortEarly: false },
                    );
                    return { valid: true };
                } catch (error: any) {
                    return { valid: false, errors: getValidationErrors(error) };
                }
            }
            default:
                return { valid: false };
        }
    }
    // Azure Org Gov is excluded at the moment
    else {
        // AzureOnboardingAccountType.china
        try {
            yup.object().shape(azureOnboardingWelcomeValidationsChina).validateSync(
                {
                    isAllowAzureChinaPrivacyPolicy: state.azureOnboarding.isAllowAzureChinaPrivacyPolicy,
                },
                { abortEarly: false },
            );
            return { valid: true };
        } catch (error: any) {
            return { valid: false, errors: getValidationErrors(error) };
        }
    }
};
export const validateAzureManualSingleWelcome = (state: RootState): { valid: boolean; errors?: any } => {
    switch (state.azureOnboarding.azureOnboardingAccountType) {
        case AzureOnboardingAccountType.standard: {
            return { valid: true };
        }
        case AzureOnboardingAccountType.gov: {
            return { valid: true };
        }
        case AzureOnboardingAccountType.china: {
            try {
                yup.object().shape(azureManualOnboardingWelcomeValidationsChina).validateSync(
                    {
                        isAllowAzureChinaPrivacyPolicy: state.azureOnboarding.isAllowAzureChinaPrivacyPolicy,
                    },
                    { abortEarly: false },
                );
                return { valid: true };
            } catch (error: any) {
                return { valid: false, errors: getValidationErrors(error) };
            }
        }
        default:
            return { valid: true };
    }
};

export const getAzureWelcomeFormValidations = (state: RootState): { valid: boolean; errors?: any } => {
    if (state.azureOnboarding.azureOnboardingType === AzureOnboardingType.org) {
        return validateAzureOrgWelcome(state);
    } else if (state.azureOnboarding.isManualOnboardingFlow) {
        return validateAzureManualSingleWelcome(state);
    } else {
        return validateAzureSingleWelcome(state);
    }
};

export const getAzureSingleConnectValidations = (state: RootState): { valid: boolean; errors?: any } => {
    try {
        yup.object().shape(azureConnectSubscriptionValidation).validateSync(
            {
                tenantId: state.azureOnboarding.welcomeForm.tenantId,
                clientId: state.azureOnboarding.credentials.clientId,
                clientPassword: state.azureOnboarding.credentials.clientPassword,
            },
            { abortEarly: false },
        );
        return { valid: true };
    } catch (error: any) {
        return { valid: false, errors: getValidationErrors(error) };
    }
};
export const getAzureOrgConnectValidations = (state: RootState): { valid: boolean; errors?: any } => {
    try {
        if (state.azureOnboarding.azureOnboardingScope === AzureOnboardingScope.subscription) {
            yup.object().shape(azureConnectSubscriptionValidation).validateSync(
                {
                    clientId: state.azureOnboarding.credentials.clientId,
                    clientPassword: state.azureOnboarding.credentials.clientPassword,
                },
                { abortEarly: false },
            );
            return { valid: true };
        } else {
            yup.object().shape(azureConnectMultiTenantValidation).validateSync(
                {
                    isOnBoardingScriptCompleted: state.azureOnboarding.isOnBoardingScriptCompleted,
                },
                { abortEarly: false },
            );
            return { valid: true };
        }
    } catch (error: any) {
        return { valid: false, errors: getValidationErrors(error) };
    }
};
export const getAzurePreparePageValidations = (state: RootState): { valid: boolean; errors?: any } => {
    try {
        yup.object().shape(azurePreparePageValidation).validateSync(
            {
                clientId: state.azureOnboarding.credentials.clientId,
                clientPassword: state.azureOnboarding.credentials.clientPassword,
            },
            { abortEarly: false },
        );
        return { valid: true };
    } catch (error: any) {
        return { valid: false, errors: getValidationErrors(error) };
    }
};
export const getAzureConnectPageValidations = (state: RootState): { valid: boolean; errors?: any } => {
    try {
        yup.object().shape(azureConnectPageValidation).validateSync(
            {
                tenantId: state.azureOnboarding.welcomeForm.tenantId,
                subscriptionId: state.azureOnboarding.welcomeForm.subscriptionId,
            },
            { abortEarly: false },
        );
        return { valid: true };
    } catch (error: any) {
        return { valid: false, errors: getValidationErrors(error) };
    }
};
export const getAzureOrgConfigurationFormValidations = (state: RootState): { valid: boolean; errors?: any } => {
    try {
        if (state.azureOnboarding.azureOnboardingType === AzureOnboardingType.org) {
            const configurations = { ...state.azureOnboarding.azureConfigurations };
            yup.object().shape(azureConfigurationPageValidation).validateSync(configurations, { abortEarly: false });
            return { valid: true };
        } else return { valid: true };
    } catch (error: any) {
        return { valid: false, errors: getValidationErrors(error) };
    }
};
