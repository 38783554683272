import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { Icon } from '@dome9/berries/react-components';
import { ReactComponent as Tips } from '@dome9/berries/icons/src/system-icons/tips.svg';
import { getUserService } from 'common/interface/services';
import { isNilOrEmpty } from 'common/utils/helpFunctions';
import { insightNamespace } from '../../consts/i18n';
import ResponseGenerator from '../../Suggestions/Components/ResponseGenerator';
import { getInsights } from '../Services/InsightsHttpService';
import { InsightResponse, InsightsProps } from '../Insights.intertface';
import { getChipSeverity, sortByInsightSeverity } from '../Services/InsightsHandler';
import { InsightsStyled } from './Insights.styled';
import { FindingSeverityServerEnum } from 'common/consts/FindingSeverity';
import FullPageLoader from 'common/erm-components/custom/FullSize/FullPageLoader/FullPageLoader';

const InsightsComponent: React.FC<InsightsProps> = (props: InsightsProps) => {
    const { srl, type, setShowSection } = props;
    const responseElementStyles = { heading: 'font-bold' };
    const [isLoading, setIsLoading] = useState(false);
    const [insights, setInsights] = useState<InsightResponse[]>([]);
    const [openInsights, setOpenInsights] = useState<boolean[]>([]);

    useEffect(() => {
        setInsightElements();

        async function setInsightElements() {
            if (!localStorage.getItem(`aidisclaimer.${getUserService().getUser().id}`)) return;

            setIsLoading(true);

            try {
                const elements = await getInsights(srl);
                if (isNilOrEmpty(elements) || !Array.isArray(elements)) {
                    setShowSection(false);
                    return;
                }

                elements.sort(sortByInsightSeverity);

                // Taking all critical and high insights and adding up to a total of 5 if critical and high are less than 5
                const importantCount = Math.max(
                    elements.filter((e) =>
                        [FindingSeverityServerEnum.Critical, FindingSeverityServerEnum.High].includes(e.severity),
                    ).length,
                    5,
                );
                const relevantInsights = elements.slice(0, importantCount);

                for (const e of relevantInsights) {
                    const firstMitigationLine = e.mitigation[0];
                    if (firstMitigationLine.heading) {
                        if (
                            firstMitigationLine.heading.startsWith(
                                i18n.t('INSIGHTS.REMEDIATION_TITLE', { ns: insightNamespace }),
                            )
                        )
                            continue;

                        firstMitigationLine.heading = `${i18n.t('INSIGHTS.REMEDIATION_TITLE', { ns: insightNamespace })}: ${firstMitigationLine.heading}`;
                    } else {
                        e.mitigation = [
                            { heading: `${i18n.t('INSIGHTS.REMEDIATION_TITLE', { ns: insightNamespace })}:` },
                            ...e.mitigation,
                        ];
                    }
                }

                setInsights(relevantInsights);
                setOpenInsights(relevantInsights.map(() => false));
            } catch {
                setInsights([]);
                setOpenInsights([]);
                setShowSection(false);
            } finally {
                setIsLoading(false);
            }
        }
    }, [srl, type, setShowSection]);

    return (
        <>
            {isLoading && <FullPageLoader />}
            <div className='pb-7'>
                {!!insights.length &&
                    insights.map((insight, index) => (
                        <div key={index} className={'border mb-6'}>
                            <div
                                className={'font-bold pointer flex flex-center-space-between hover:bg-hover p-6'}
                                onClick={() => {
                                    openInsights[index] = !openInsights[index];
                                    setOpenInsights([...openInsights]);
                                }}
                            >
                                <div className={'flex flex-center'}>
                                    <InsightsStyled.TipsWrapper>
                                        <Tips className={getChipSeverity(insight)} />
                                    </InsightsStyled.TipsWrapper>
                                    {`${Object.values(insight.risk[0])[0]}`}
                                </div>
                                <Icon
                                    name={'chevron-down'}
                                    size={10}
                                    className={`mr-6 ${openInsights[index] ? 'rotate-180' : ''}`}
                                />
                            </div>

                            {openInsights[index] && (
                                <div className={'pt-6 pb-8 px-8'}>
                                    <div className={'pb-6'}>
                                        <ResponseGenerator
                                            response={insight.risk.slice(1)}
                                            styleClasses={responseElementStyles}
                                        />
                                    </div>
                                    <div>
                                        <ResponseGenerator
                                            response={insight.mitigation}
                                            styleClasses={responseElementStyles}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
            </div>
        </>
    );
};

export default InsightsComponent;
