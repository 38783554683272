import { ICustomTreeNode } from 'common/erm-components/custom/CustomTree/CustomTree.interface';
import {
    IConnectionPairInfo,
    IEntityPropertiesMap,
    IEntityTypeInfo,
    IPropertyInfo,
    IQueryNodeData,
    IRelationInfo,
    IToxicGraphContainerNode,
    IToxicGraphNode,
    IToxicGraphProp,
    IToxicGraphQueryPivotTypeEnum,
    IToxicGraphRelation,
    IToxicGraphSimpleNode,
    IToxicPivotItem,
    IToxicPropsMap,
    IToxicSubGraph,
    IToxicValidationErrorInfo,
    ToxicErrorEnum,
} from './ToxicGraph.interface';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { getToxicSchema } from './ToxicGraphModel/ToxicGraphModel.schema';
import { IconProps } from 'common/design-system/components-v2/Icon/Icon.types';
import { PivotTypeEnum } from './ToxicGraphPage/LeftPanel/QueryBox/PivotType';
import { VARIES_PROP_VALUE } from './ToxicGraph.consts';
import {
    IToxicGraphNodeModel,
    IToxicGraphQueryNodeModel,
    IToxicSubGraphModel,
} from './ToxicGraphModel/ToxicGraphModel.interface';
import {
    queryModelToUiNode,
    subGraphNodeModelToSimpleNodeUi,
    subGraphRelationsModelToUi,
} from './ToxicGraphModel/ToxicGraphModel.convertors';
import { ToxicPropTypeEnum } from './ToxicGraphModel/ToxicGraphModel.consts';
import { IFilterTreeFieldDefinition } from 'common/erm-components/custom/FilterTree/FilterTree.interface';
import {
    ICompoundFilter,
    ICompoundFilterItem,
    ICompoundFilterNode,
} from 'common/erm-components/custom/FilterTree/CompoundFilter';
import { isCompoundFilterNode } from 'common/erm-components/custom/FilterTree/FilterTree.convertors';
import { FilterConditionOperator, IFilterCondition } from 'common/erm-components/custom/FilterTree/FilterCondition';
import i18n from 'i18next';
import { isBoolean } from 'common/erm-components/utils/types';
import { isArray, isObject, isString } from 'common/utils/helpFunctions';
import { getAllCustomTreeFlatNodes } from 'common/erm-components/custom/CustomTree/CustomTree.utils';

export const isGroupEntity = (entityType: string): boolean => {
    const entityTypeInfo: IEntityTypeInfo | undefined = getToxicSchema().entityTypesMap[entityType];
    return entityTypeInfo ? entityTypeInfo.isGroupEntity : false;
};

export const updateOptionalRecursively = (node: ICustomTreeNode<IQueryNodeData>, optional: boolean) => {
    node.data.optional = optional;
    if (node.children) {
        node.children.forEach((child) => updateOptionalRecursively(child, optional));
    }
    if (!optional) {
        let parent = node.parent;
        while (parent) {
            parent.data.optional = false;
            parent = parent.parent;
        }
    }
};

export const getPivotTreeNode = (
    node: ICustomTreeNode<IQueryNodeData>,
): ICustomTreeNode<IQueryNodeData> | undefined => {
    const allNodes: ICustomTreeNode<IQueryNodeData>[] = getAllCustomTreeFlatNodes(node);
    return allNodes.find((node) => node.data.pivotType === PivotTypeEnum.pivot);
};

export const isOptionalAllowed = (node: ICustomTreeNode<IQueryNodeData>): boolean => {
    if (!node.parent || node.data.pivotType === PivotTypeEnum.pivot) {
        return false;
    }

    const pivotNode: ICustomTreeNode<IQueryNodeData> | undefined = getPivotTreeNode(node);
    if (!pivotNode) {
        return true;
    }

    let pivotAncestorNode = pivotNode.parent;
    while (pivotAncestorNode) {
        if (pivotAncestorNode.id === node.id) {
            return false;
        }
        pivotAncestorNode = pivotAncestorNode.parent;
    }

    return true;
};

export const getNewQueryNodeId = (): string => {
    return `query-node-${Date.now()}`;
};

export const getConnectionPairId = (relation: string, parentType: string): string => {
    return `${parentType}-${relation}`;
};

export const getToxicNodeByPivotType = (
    node: ICustomTreeNode<IQueryNodeData>,
    pivotType: PivotTypeEnum,
): ICustomTreeNode<IQueryNodeData> | undefined => {
    if (node.data.pivotType === pivotType) {
        return node;
    }
    if (node.children) {
        for (const child of node.children) {
            const pivotNode: ICustomTreeNode<IQueryNodeData> | undefined = getToxicNodeByPivotType(child, pivotType);
            if (pivotNode) {
                return pivotNode;
            }
        }
    }
    return undefined;
};

export const getToxicPivotNode = (
    node: ICustomTreeNode<IQueryNodeData>,
): ICustomTreeNode<IQueryNodeData> | undefined => {
    return getToxicNodeByPivotType(node, PivotTypeEnum.pivot);
};

export const getToxicGroupNode = (
    node: ICustomTreeNode<IQueryNodeData>,
): ICustomTreeNode<IQueryNodeData> | undefined => {
    return getToxicNodeByPivotType(node, PivotTypeEnum.group);
};

const collectAllToxicQueryNodes = (
    node: ICustomTreeNode<IQueryNodeData>,
    allNodes: ICustomTreeNode<IQueryNodeData>[],
) => {
    allNodes.push(node);
    if (node.children) {
        node.children.forEach((child) => collectAllToxicQueryNodes(child, allNodes));
    }
};

export const getAllToxicQueryNodes = (root: ICustomTreeNode<IQueryNodeData>): ICustomTreeNode<IQueryNodeData>[] => {
    const allNodes: ICustomTreeNode<IQueryNodeData>[] = [];
    collectAllToxicQueryNodes(root, allNodes);
    return allNodes;
};

export const getToxicTypeIconProps = (entityType: string): IconProps | undefined => {
    const entityTypeInfo: IEntityTypeInfo | undefined = getToxicSchema().entityTypesMap[entityType];
    return entityTypeInfo ? entityTypeInfo.iconProps : undefined;
};

export const getToxicTypeFilterDefs = (entityType: string): IFilterTreeFieldDefinition[] => {
    const entityTypeInfo: IEntityTypeInfo | undefined = getToxicSchema().entityTypesMap[entityType];
    return entityTypeInfo ? entityTypeInfo.filterDefs : [];
};

export const getEntityTypeSelectOption = (entityType: string): SelectOption => {
    const iconProps: IconProps | undefined = getToxicTypeIconProps(entityType);
    const entityTypeInfo: IEntityTypeInfo = getToxicSchema().entityTypesMap[entityType];
    return {
        label: entityTypeInfo.name,
        value: entityType,
        labelProps: iconProps ? { leadingIconProps: iconProps } : undefined,
    };
};

export const getAllToxicEntityTypeOptions = (): SelectOption[] => {
    return Object.keys(getToxicSchema().entityTypesMap).map(getEntityTypeSelectOption);
};

export const getPairInfo = (relation: string, parentType: string): IConnectionPairInfo | undefined => {
    const pairId: string = getConnectionPairId(relation, parentType);
    return getToxicSchema().connectionPairsMap[pairId];
};

export const getRelationText = (relation: string, parentType?: string): string => {
    const relationInfo: IRelationInfo | undefined = getToxicSchema().relationsMap[relation];
    if (!relationInfo) {
        return relation;
    }

    const pairInfo: IConnectionPairInfo | undefined = parentType ? getPairInfo(relation, parentType) : undefined;
    if (!pairInfo) {
        return relationInfo.parentToChildText;
    }

    if (pairInfo.isParentToChildRelation) {
        return relationInfo.parentToChildText;
    } else {
        return relationInfo.childToParentText;
    }
};

export const getRelationSelectOption = (relation: string, parentType: string): SelectOption => {
    return { label: getRelationText(relation, parentType), value: relation };
};

export const getToxicRelationOptions = (parentType: string): SelectOption[] => {
    const entityTypeInfo: IEntityTypeInfo = getToxicSchema().entityTypesMap[parentType];
    if (!entityTypeInfo) {
        return [];
    }

    return entityTypeInfo.relevantRelations.map((relation) => getRelationSelectOption(relation, parentType));
};

export const getToxicEntityTypeOptions = (parentType: string, relation?: string): SelectOption[] => {
    if (!relation) {
        return [];
    }
    const pairInfo: IConnectionPairInfo | undefined = getPairInfo(relation, parentType);
    return pairInfo ? pairInfo.possibleChildTypes.map(getEntityTypeSelectOption) : [];
};

export const checkToxicRelationValidation = (
    parentType: string,
    relation: string,
): IToxicValidationErrorInfo | undefined => {
    const relationOptions: SelectOption[] = getToxicRelationOptions(parentType);
    if (!relationOptions.map((option) => option.value).includes(relation)) {
        return {
            errorType: ToxicErrorEnum.invalidRelation,
            description: `Relation is invalid for a parent of type "${parentType}"`,
        };
    }
    return undefined;
};

export const checkToxicEntityTypeValidation = (
    parentType: string,
    relation: string,
    entityType: string,
): IToxicValidationErrorInfo | undefined => {
    const typeOptions: SelectOption[] = getToxicEntityTypeOptions(parentType, relation);
    if (!typeOptions.map((option) => option.value).includes(entityType)) {
        return {
            errorType: ToxicErrorEnum.invalidEntityType,
            description: `Entity type is invalid for relation "${relation}" and parent of type "${parentType}"`,
        };
    }

    const relationOptions: SelectOption[] = getToxicRelationOptions(parentType);
    if (!relationOptions.map((option) => option.value).includes(relation)) {
        return {
            errorType: ToxicErrorEnum.invalidRelation,
            description: `Relation is invalid for a parent of type "${parentType}"`,
        };
    }
    return undefined;
};

export const checkToxicGraphNodeValidation = (
    node: ICustomTreeNode<IQueryNodeData>,
): IToxicValidationErrorInfo | undefined => {
    const data: IQueryNodeData = node.data;
    if (!data.entityType) {
        return {
            errorType: ToxicErrorEnum.requiredEntityType,
        };
    }

    if (!node.parent) {
        return undefined;
    }

    if (!data.relation) {
        return {
            errorType: ToxicErrorEnum.requiredRelation,
        };
    }

    const relationError: IToxicValidationErrorInfo | undefined = checkToxicRelationValidation(
        node.parent.data.entityType,
        data.relation,
    );
    if (relationError) {
        return relationError;
    }

    const entityTypeError: IToxicValidationErrorInfo | undefined = checkToxicEntityTypeValidation(
        node.parent.data.entityType,
        data.relation,
        data.entityType,
    );
    if (entityTypeError) {
        return entityTypeError;
    }

    return undefined;
};

export const hasToxicGraphErrors = (node: ICustomTreeNode<IQueryNodeData>): boolean => {
    if (node.data.errorInfo) {
        return true;
    }
    if (node.children) {
        return !!node.children.find((child) => hasToxicGraphErrors(child));
    }
    return false;
};

export const updateToxicGraphErrors = (node: ICustomTreeNode<IQueryNodeData>) => {
    node.data.errorInfo = checkToxicGraphNodeValidation(node);
    if (node.children) {
        node.children.forEach((child) => updateToxicGraphErrors(child));
    }
};

const getCommonEnvId = (containerNode: IToxicGraphContainerNode): string | undefined => {
    let commonEnvId: string | undefined = undefined;
    for (const member of containerNode.members) {
        if (commonEnvId === undefined) {
            commonEnvId = member.environmentId;
        } else {
            if (commonEnvId !== member.environmentId) {
                return VARIES_PROP_VALUE;
            }
        }
    }
    return commonEnvId;
};

export const getDisplayValueByType = (entityType: ToxicPropTypeEnum, value: any): string => {
    switch (entityType) {
        case ToxicPropTypeEnum.boolean:
            return value ? 'true' : 'false';

        case ToxicPropTypeEnum.number:
            return String(value);

        case ToxicPropTypeEnum.string:
            return value ? value : '""';

        case ToxicPropTypeEnum.date:
            return value || '(none)';

        default:
            return value ? String(value) : '""';
    }
};

export const getEntityPropInfo = (entityType: string, name: string): IPropertyInfo | undefined => {
    const entityTypeInfo: IEntityTypeInfo | undefined = getToxicSchema().entityTypesMap[entityType];
    return entityTypeInfo?.propertiesMap[name];
};

const getCommonPropsByMembers = (
    members: IToxicGraphSimpleNode[],
    useAdditionalProps?: boolean,
): IToxicGraphProp[] | undefined => {
    const commonPropsMap: IToxicPropsMap = {};
    const variesPropsMap: IToxicPropsMap = {};
    members.forEach((member) => {
        const props: IToxicGraphProp[] | undefined = useAdditionalProps ? member.additionalProps : member.props;
        if (props) {
            props.forEach((prop) => {
                const propName: string = prop.name;
                if (!variesPropsMap[propName]) {
                    const commonProp: IToxicGraphProp = commonPropsMap[propName];
                    if (!commonProp) {
                        commonPropsMap[propName] = prop;
                    } else {
                        const currValue: any = prop.value;
                        const commonValue: any = commonProp.value;
                        if (commonValue !== currValue) {
                            delete commonPropsMap[propName];
                            variesPropsMap[propName] = {
                                name: propName,
                                displayName: prop.displayName,
                                displayValue: VARIES_PROP_VALUE,
                                value: undefined,
                                isVaries: true,
                            };
                        }
                    }
                }
            });
        }
    });

    return [...Object.values(commonPropsMap), ...Object.values(variesPropsMap)];
};

const getCommonProps = (containerNode: IToxicGraphContainerNode): IToxicGraphProp[] => {
    return getCommonPropsByMembers(containerNode.members) || [];
};

const getCommonAdditionalProps = (containerNode: IToxicGraphContainerNode): IToxicGraphProp[] | undefined => {
    const relevantMembers = containerNode.members.filter((member) => !!member.additionalProps);
    if (relevantMembers.length !== containerNode.members.length) {
        return undefined;
    }

    return getCommonPropsByMembers(relevantMembers, true);
};

export const updateSubGraphGroupingInfo = (subGraph: IToxicSubGraph, expandedGroupIds: string[]) => {
    const containerNodesMap: { [key: string]: IToxicGraphContainerNode } = {};
    const membersNodesToContainerMap: { [key: string]: IToxicGraphContainerNode } = {};
    const origNodesMap: { [key: string]: IToxicGraphSimpleNode } = {};
    const containerNodes: IToxicGraphContainerNode[] = [];
    const orphanSimpleNodes: IToxicGraphSimpleNode[] = [];
    const expandedGroupIdsSet: Set<string> = new Set(expandedGroupIds);
    const isPivot: boolean = subGraph.origNodes.some((node: IToxicGraphSimpleNode) => node.isPivot);
    for (const simpleNode of subGraph.origNodes) {
        origNodesMap[simpleNode.id] = simpleNode;
        const containerGroupId = simpleNode.containerGroupId;
        if (containerGroupId && !expandedGroupIdsSet.has(containerGroupId)) {
            let containerNode: IToxicGraphContainerNode = containerNodesMap[containerGroupId];
            if (!containerNode) {
                const containerId = `${simpleNode.entityType}-container-${containerGroupId}`;
                containerNode = {
                    id: containerId,
                    entityType: simpleNode.entityType,
                    name: simpleNode.entityType,
                    iconProps: simpleNode.iconProps,
                    environmentId: simpleNode.environmentId,
                    props: [],
                    additionalProps: undefined,
                    isContainer: true,
                    groupId: containerGroupId,
                    members: [],
                    isPivot,
                    pivot: subGraph.pivot,
                };
                containerNodesMap[containerGroupId] = containerNode;
                containerNodes.push(containerNode);
            }
            membersNodesToContainerMap[simpleNode.id] = containerNode;
            containerNode.members.push(simpleNode);
        } else {
            orphanSimpleNodes.push(simpleNode);
        }
    }

    containerNodes.forEach((containerNode) => {
        const commonAdditionalProps: IToxicGraphProp[] | undefined = getCommonAdditionalProps(containerNode);
        containerNode.props = getCommonProps(containerNode).sort((a, b) => a.name.localeCompare(b.name));
        containerNode.additionalProps = commonAdditionalProps
            ? commonAdditionalProps.sort((a, b) => a.name.localeCompare(b.name))
            : undefined;
        containerNode.environmentId = getCommonEnvId(containerNode);
        containerNode.name = `${containerNode.name}(${containerNode.members.length})`;
    });

    const finalRelationsMap: { [key: string]: IToxicGraphRelation } = {};
    subGraph.origRelations.forEach((origRelation) => {
        let toId = origRelation.toId;
        let fromId = origRelation.fromId;
        const toContainerNode: IToxicGraphContainerNode = membersNodesToContainerMap[origRelation.toId];
        if (toContainerNode) {
            toId = toContainerNode.id;
        }
        const fromContainerNode: IToxicGraphContainerNode = membersNodesToContainerMap[origRelation.fromId];
        if (fromContainerNode) {
            fromId = fromContainerNode.id;
        }
        const relationSignature = `from:${fromId}-to:${toId}-relation:${origRelation.relation}`;
        if (!finalRelationsMap[relationSignature]) {
            finalRelationsMap[relationSignature] = {
                ...origRelation,
                fromId,
                toId,
            };
        }
    });

    subGraph.nodes = [...containerNodes, ...orphanSimpleNodes];
    subGraph.relations = Object.values(finalRelationsMap);
    subGraph.expandedGroupIds = expandedGroupIds;

    const selectedNode: IToxicGraphNode | undefined = subGraph.nodes.find(
        (node) => node.id === subGraph.selectedNodeId,
    );
    updateSubGraphSelectionInfo(subGraph, selectedNode);
};

export const updateSubGraphSelectionInfo = (subGraph: IToxicSubGraph, selectedNode?: IToxicGraphNode) => {
    if (!selectedNode) {
        subGraph.selectedNodeId = undefined;
        subGraph.secondarySelectedNodesIdsSet = new Set<string>();
        subGraph.secondarySelectedRelationsIdsSet = new Set<string>();
        return;
    }

    subGraph.selectedNodeId = selectedNode.id;
    const nodesMap: { [key: string]: IToxicGraphNode } = {};
    subGraph.nodes.forEach((node: IToxicGraphNode) => (nodesMap[node.id] = node));
    const outgoingRelations: IToxicGraphRelation[] = subGraph.relations.filter(
        (relation) => relation.fromId === selectedNode.id,
    );
    const targetNodes: IToxicGraphNode[] = outgoingRelations.map((relation) => nodesMap[relation.toId]);
    const incomingRelations: IToxicGraphRelation[] = subGraph.relations.filter(
        (relation) => relation.toId === selectedNode.id,
    );
    const sourceNodes: IToxicGraphNode[] = incomingRelations.map((relation) => nodesMap[relation.fromId]);
    subGraph.secondarySelectedNodesIdsSet = new Set<string>([...sourceNodes, ...targetNodes].map((node) => node.id));
    subGraph.secondarySelectedRelationsIdsSet = new Set<string>(
        [...incomingRelations, ...outgoingRelations].map((relation) => relation.id),
    );
};

const getSimpleNodesFromSubGraphModel = (
    pivot: IToxicPivotItem,
    subGraphModel: IToxicSubGraphModel,
): IToxicGraphSimpleNode[] => {
    if (!subGraphModel.evidencePath.nodes) {
        return [];
    }

    return subGraphModel.evidencePath.nodes.map((subGraphNode: IToxicGraphNodeModel) =>
        subGraphNodeModelToSimpleNodeUi(pivot, subGraphNode),
    );
};

export const subGraphModelToUi = (pivot: IToxicPivotItem, subGraphModel: IToxicSubGraphModel): IToxicSubGraph => {
    const simpleNodes: IToxicGraphSimpleNode[] = getSimpleNodesFromSubGraphModel(pivot, subGraphModel);
    const simpleRelations: IToxicGraphRelation[] = subGraphRelationsModelToUi(pivot, subGraphModel);
    const groupIdsSet: Set<string> = new Set();
    simpleNodes.forEach((node) => {
        if (node.containerGroupId) {
            groupIdsSet.add(node.containerGroupId);
        }
    });

    const subGraph: IToxicSubGraph = {
        pivot,
        nodes: simpleNodes,
        relations: simpleRelations,
        origNodes: simpleNodes,
        origRelations: simpleRelations,
        expandedGroupIds: [],
        allGroupIds: Array.from(groupIdsSet),
        selectedNodeId: undefined,
        secondarySelectedNodesIdsSet: new Set<string>(),
        secondarySelectedRelationsIdsSet: new Set<string>(),
    };
    updateSubGraphGroupingInfo(subGraph, []);
    return subGraph;
};

export const getFilterConditionText = (propsMap: IEntityPropertiesMap, cond: IFilterCondition): string | undefined => {
    const propInfo: IPropertyInfo | undefined = propsMap[cond.name];
    if (!propInfo) {
        return undefined;
    }

    if (cond.values.length === 0) {
        return undefined;
    }

    let valueText: string;
    if (propInfo.type === ToxicPropTypeEnum.boolean) {
        return isBoolean(String(cond.values[0])) ? propInfo.displayName : `not ${propInfo.displayName}`;
    }

    if (propInfo.type === ToxicPropTypeEnum.string) {
        if (cond.operator === FilterConditionOperator.In) {
            const innerList: string[] = cond.values.map((val) => `"${val}"`);
            valueText = `[${innerList.join(', ')}]`;
        } else {
            valueText = `"${cond.values[0]}"`;
        }
    } else {
        valueText = String(cond.values[0]);
    }
    if (!valueText) {
        return undefined;
    }

    const operatorText: string = i18n.t(`FILTER_CONDITION_OPERATOR.${cond.operator}`);
    return `(${propInfo.displayName} ${operatorText} ${valueText})`;
};

export const getFilterItemText = (
    propsMap: IEntityPropertiesMap,
    item: ICompoundFilterItem,
    isDeep?: boolean,
): string | undefined => {
    if (isCompoundFilterNode(item)) {
        const node: ICompoundFilterNode = item as ICompoundFilterNode;
        if (node.operands.length === 0) {
            return undefined;
        }
        const operandTexts: string[] = node.operands
            .map((operand) => getFilterItemText(propsMap, operand, true))
            .filter((text) => !!text) as string[];
        if (operandTexts.length === 0) {
            return undefined;
        }
        const logicalOpText: string = node.logicalOperator.toLowerCase();
        const fulltext = operandTexts.join(` ${logicalOpText} `);
        return isDeep ? `(${fulltext})` : fulltext;
    }

    const cond: IFilterCondition = item as IFilterCondition;
    return getFilterConditionText(propsMap, cond as IFilterCondition);
};

export const getToxicGraphFilterText = (entityType: string, filter?: ICompoundFilter): string | undefined => {
    if (!filter?.root || filter.root.operands.length === 0) {
        return undefined;
    }
    const entityTypeInfo: IEntityTypeInfo | undefined = getToxicSchema().entityTypesMap[entityType];
    if (!entityTypeInfo) {
        return undefined;
    }
    const propsMap: IEntityPropertiesMap = entityTypeInfo.propertiesMap;
    return getFilterItemText(propsMap, filter.root) || undefined;
};

export const uncapitalize = (text: string) => {
    if (text && text.length > 0) {
        return `${text.charAt(0).toLowerCase()}${text.length > 1 ? text.slice(1) : ''}`;
    }
    return text;
};

export const capitalize = (text: string) => {
    if (text && text.length > 0) {
        return `${text.charAt(0).toUpperCase()}${text.length > 1 ? text.slice(1) : ''}`;
    }
    return text;
};

export const getPropValue = <T = any>(propName: string, propsList?: IToxicGraphProp[]): any | undefined => {
    if (!propsList) {
        return undefined;
    }

    const prop = propsList.find((prop) => prop.name === propName);
    if (!prop || prop.value === undefined) {
        return undefined;
    }

    if (isString(prop.value) && prop.value === VARIES_PROP_VALUE) {
        return undefined;
    }

    return prop.value as T;
};

export const isValidQueryModel = (queryModel: IToxicGraphQueryNodeModel): boolean => {
    if (!queryModel.nodeLabel) {
        return false;
    }

    if (queryModel.type && !Object.values(IToxicGraphQueryPivotTypeEnum).includes(queryModel.type)) {
        return false;
    }

    if (queryModel.relationships && !isArray(queryModel.relationships)) {
        return false;
    }

    try {
        return !!queryModelToUiNode(queryModel);
    } catch (err) {
        return false;
    }
};

export const readQueryFromText = async (text?: string): Promise<IToxicGraphQueryNodeModel | string> => {
    if (!text) {
        return 'Failed reading query: could not find data';
    }

    try {
        const queryModel: IToxicGraphQueryNodeModel = JSON.parse(text) || undefined;

        if (!isObject(queryModel)) {
            return 'Failed reading query: could not find data';
        }

        if (!isValidQueryModel(queryModel)) {
            return 'Failed reading query: Invalid format';
        }

        return queryModel;
    } catch (err) {
        return 'Failed reading query: Invalid format';
    }
};
