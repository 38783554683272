import { Controller, useFormContext } from 'react-hook-form';
import { GroupSelection, Stack, Input } from 'common/design-system/components-v2';
import { entityGroupSelection } from '../VulnerabilityExclusionForm.const';
import ExclusionByEntity from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByEntity';
import { Entity, FormValues } from '../VulnerabilityExclusionForm.types';

const EntityNameController = () => {
    const { control, watch, getValues, resetField } = useFormContext<FormValues>();
    const {
        toggles: { entity },
    } = watch();

    return (
        <Stack spacing={4}>
            <Controller
                name={'toggles.entity'}
                control={control}
                render={({ field }) => (
                    <GroupSelection
                        defaultValue={Entity.ENTITY_NAME}
                        options={entityGroupSelection}
                        onChange={(value) => {
                            resetField('entityNames');
                            resetField('entityId');
                            field.onChange(value);
                        }}
                        value={getValues('toggles.entity')}
                        direction='row'
                    />
                )}
            />
            <Stack margin={[0, 0, 0, 4]}>
                {entity === Entity.ENTITY_NAME ? (
                    <Controller
                        name={'entityNames'}
                        control={control}
                        render={({ field }) => {
                            return (
                                <ExclusionByEntity
                                    selectedOption={field.value?.length ? field.value : undefined}
                                    filterFields={[]}
                                    isEnabled
                                    onChange={field.onChange}
                                />
                            );
                        }}
                    />
                ) : (
                    <Controller
                        name={'entityId'}
                        control={control}
                        render={({ field }) => (
                            <Input
                                fullWidth
                                value={field.value}
                                onChange={field.onChange}
                                placeholder={'Enter an entity ID'}
                            />
                        )}
                    />
                )}
            </Stack>
        </Stack>
    );
};

export default EntityNameController;
