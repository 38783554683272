import React from 'react';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { IIssue, IIssueEvidence } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { EvidencesStyled } from './Evidences.styled';
import { EvidencesRegistry } from 'common/module_interface/RiskManagement/issues/EvidencesRegistry';
import { Section } from '../../../Section/Section';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';

export interface IEvidencesProps {
    asset: IProtectedAssetViewModel;
    issue: IIssue;
    initiallyOpen: boolean;
    cloudEntity?: ICloudEntityData;
}

const Evidences: React.FC<IEvidencesProps> = ({ asset, issue, initiallyOpen, cloudEntity }) => {
    const evidences: IIssueEvidence[] = EvidencesRegistry.getItems().filter((item) => item.isRelevant(issue, asset));

    return (
        <EvidencesStyled.AccordionDiv>
            {evidences.map(({ title, component: Component, id, extraInfo: ExtraInfo }) => {
                const extraInfoElement = ExtraInfo && (
                    <ExtraInfo
                        issue={issue}
                        asset={asset}
                        evidenceInfo={cloudEntity ? { cloudEntity: cloudEntity, title: title } : undefined}
                    />
                );
                return (
                    <EvidencesStyled.Evidence key={id}>
                        <Section title={title} extraTitleComponent={extraInfoElement} initiallyOpen={initiallyOpen}>
                            <Component issue={issue} asset={asset} title={title} />
                        </Section>
                    </EvidencesStyled.Evidence>
                );
            })}
        </EvidencesStyled.AccordionDiv>
    );
};

export default Evidences;
