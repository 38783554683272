import React from 'react';
import { Stack, Typography } from 'common/design-system/components-v2';
import { CommandSnippet } from './GeneralItemSection.styled';
import { PermissionItemProps } from '../../ServerlessPermissionSection.types';

export const GeneralItemSection: React.FC<PermissionItemProps> = ({ title, data }) => {
    return (
        <Stack spacing={4}>
            <Typography variant='body500'>{title}</Typography>
            <Stack fullWidth direction='column' spacing={2}>
                <CommandSnippet text={JSON.stringify(data, null, 2)} />
            </Stack>
        </Stack>
    );
};
