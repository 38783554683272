import React from 'react';
import { GetVulnerabilityScan, Layers } from 'modules/workloads/services/vulnerability/vulnerability.interface';
import { Stack, Typography, Tooltip } from 'common/design-system/components-v2';
import CountBySeverity from 'common/design-system/components-v2/CountBySeverity';
import { Context } from 'common/design-system/theme/colors/colors.types';
import { LayersCommandWrapper } from './LayersCommand.styled';
import { changeTab } from 'common/module_interface/assets/utils';
import { InnerTabs } from 'modules/workloads/utils';

interface LayersProps {
    layers: Layers;
    vulnerability: Pick<GetVulnerabilityScan.Response, 'Package'>;
}

type CountSeverityForLayer = (
    layerId: string,
    vulnerability: LayersProps['vulnerability'],
) => Array<{ severity: Context; count: number }>;
const countSeverityForLayer: CountSeverityForLayer = (layerIdKey, vulnerability) => {
    const { Package } = vulnerability;
    const initSeverityCounts: Record<string, number> = {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
    };

    Package.forEach((pkgItem) => {
        const { layerId, cves } = pkgItem;
        if (layerId !== layerIdKey) return;
        cves.forEach((cveItem) => {
            const severity = cveItem.severity.toLowerCase();
            if (severity in initSeverityCounts) {
                initSeverityCounts[severity] += 1;
            }
        });
    });

    return Object.entries(initSeverityCounts).map(([severity, count]) => ({
        severity: severity as Context,
        count: count,
    }));
};

const sortByCreated = (a: any, b: any) => {
    const [, layerObjA] = a;
    const [, layerObjB] = b;
    return new Date(layerObjA.created).getTime() - new Date(layerObjB.created).getTime();
};

const cleanAndSortLayers = (layers: Layers) =>
    Object.entries(layers)
        .filter(([, value]) => value.created_by)
        .sort(sortByCreated);

const LayersCommand: React.FC<LayersProps> = ({ layers, vulnerability }) => (
    <LayersCommandWrapper spacing={2}>
        {cleanAndSortLayers(layers).map(([key, value], index) => {
            return (
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} key={key}>
                    <Stack style={{ maxWidth: '280px' }} direction={'row'} spacing={4}>
                        <Typography variant={'md'}>{index + 1}</Typography>
                        <Tooltip content={value.created_by}>
                            <Typography
                                onClick={() => changeTab(InnerTabs.VULNERABILITIES)}
                                elementType={'span'}
                                ellipsis
                                variant={'md'}
                            >
                                {value.created_by}
                            </Typography>
                        </Tooltip>
                    </Stack>
                    <Stack>
                        <CountBySeverity severityCounts={countSeverityForLayer(key, vulnerability)} />
                    </Stack>
                </Stack>
            );
        })}
    </LayersCommandWrapper>
);

export default LayersCommand;
