import { useEffect, useState } from 'react';
import { GridApi, RowNode } from 'ag-grid-community';
import { Checkbox } from 'common/design-system/components-v2';
import { CellRendererWrapper } from './CellRenderer.styled';
import { IPermissionItem, PERMISSION_TYPE, PermissionCategorySubType } from '../interfaces';

export const CheckboxCellRenderer = (props: {
    data: IPermissionItem;
    onClick: (checked: boolean) => void;
    api: GridApi;
    value: boolean;
    node: RowNode;
    colDef: { field: string; cellRendererParams: { reviewMode: boolean; columnName: 'view' | 'manage' | 'enable' } };
}) => {
    const [checked, setChecked] = useState(props.value || false);
    let editable = true;
    let hidden = false;
    const params = props.colDef.cellRendererParams;
    useEffect(() => {
        setChecked(props.value);
    }, [props.value]);
    if (
        props.data?.categorySubType === PermissionCategorySubType.SCOPE_CONTROLS &&
        props.data.id === PERMISSION_TYPE.ONBOARDING
    ) {
        if (params.columnName === 'view') {
            hidden = true;
        }
    }
    const manageOnlyTypes = [
        PERMISSION_TYPE.ALERTS_EXCLUSION_REMEDIATION,
        PERMISSION_TYPE.NOTIFICATIONS_AND_INTEGRATIONS,
        PERMISSION_TYPE.RULES_AND_RULESETS,
        PERMISSION_TYPE.POLICY,
        PERMISSION_TYPE.REGION,
        PERMISSION_TYPE.SECURITY_GROUP,
    ];
    if (params.reviewMode) {
        editable = false;
    } else {
        if (params.columnName === 'manage' || params.columnName === 'enable') {
            editable = !props.data?.securityGroup || props.data.securityGroup.securityGroupId > 0;
        }
        if (manageOnlyTypes.includes(props.data?.type as PERMISSION_TYPE)) {
            if (params.columnName === 'view') {
                editable = false;
            }
        }
    }

    const handleChange = (event: { target: HTMLInputElement }) => {
        const updateChildren = (node: RowNode, value: boolean, field: string) => {
            node.allLeafChildren.forEach((child: RowNode) => {
                child.setDataValue(field, value);
            });
        };
        const isChecked = event.target.checked;
        props.onClick && props.onClick(isChecked);
        setChecked(isChecked);
        props?.node?.setDataValue(props.colDef.field, isChecked); // Update grid data
        if (props.node.hasChildren()) {
            updateChildren(props.node, isChecked, props.colDef.field);
            props.api.redrawRows();
        }
    };

    return (
        <CellRendererWrapper>
            {hidden ? '' : <Checkbox readOnly={!editable} checked={checked} onChange={handleChange} />}
        </CellRendererWrapper>
    );
};
