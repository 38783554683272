import { Label, Stack } from 'common/design-system/components-v2';
import { ScanStatusTagProps } from './ScanStatusTag.types';

const ScanStatusTag = (props: ScanStatusTagProps) => {
    const { scanDate, scanProducer } = props;

    type CreateScanSource = (scanProducer: string | undefined) => { label: string };
    const createScanSource: CreateScanSource = (scanProducer) => {
        const isInspector = scanProducer === 'InspectorV2';
        return {
            label: isInspector ? 'AWS Inspector' : 'CloudGuard',
        };
    };

    return (
        <Stack style={{ backgroundColor: '#F6F7FA', borderRadius: '8px' }} padding={2} spacing={4} direction={'row'}>
            {scanProducer === 'InspectorV2' ? (
                <Label
                    text={`Scan Source: ${createScanSource(scanProducer).label}`}
                    trailIconProps={{
                        vendorNameOrPath:
                            'aws__Architecture-Service-Icons__Arch_Security-Identity-Compliance__64__Arch_Amazon-Inspector_64',
                        size: 16,
                    }}
                />
            ) : (
                <Label
                    text={`Scan Source: ${createScanSource(scanProducer).label}`}
                    trailIconProps={{ color: 'brandPrimary', name: 'cloudGuard', size: 16 }}
                />
            )}
            {scanDate && <Label text={`Scan Date: ${scanDate}`} />}
        </Stack>
    );
};

export default ScanStatusTag;
