import { ermTrans } from '../../../RiskManagement.utils';
import { isEmptyItemValue } from 'common/erm-components/custom/CustomForm/CustomForm.values';
import { IItem, IItemsMap } from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import { RequiredOneOfCveFields } from './CveIgnoreList.consts';

export const checkOneOfCveFields = async (item: IItem, itemsMap: IItemsMap): Promise<string | undefined> => {
    if (RequiredOneOfCveFields.some((name) => !isEmptyItemValue(itemsMap[name]))) {
        return;
    }

    return ermTrans('IGNORE_LIST.CVE_IGNORE_LIST.VALIDATION.AT_LEAST_ONE_CVE_COND_IS_REQUIRED');
};
