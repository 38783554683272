import { cloud_bots_actions } from './index';
import { getStoreService } from 'common/interface/services';
import { IRemediationResponse } from 'common/module_interface/intelligence/CloudBots/CloudBots.interface';
import { BotStatus } from 'common/module_interface/intelligence/CloudBots/CloudBots.const';

const addCloudBot = (customizationKey: string) => {
    const { dispatch } = getStoreService().getReduxTools();
    dispatch(cloud_bots_actions.addCloudBot({ customizationKey }));
};

const deleteCloudBot = (customizationKey: string) => {
    const { dispatch } = getStoreService().getReduxTools();
    dispatch(cloud_bots_actions.deleteCloudBot({ customizationKey }));
};

const updateCloudBotStatus = (customizationKey: string, status: BotStatus) => {
    const { dispatch } = getStoreService().getReduxTools();
    dispatch(cloud_bots_actions.setCloudBotStatus({ customizationKey, cloudBotStatus: status }));
};

const updateRemediationDetails = (customizationKey: string, remediationData?: IRemediationResponse) => {
    const { dispatch } = getStoreService().getReduxTools();
    dispatch(cloud_bots_actions.updateRemediationDetails({ customizationKey, remediationData }));
};

const CloudBotsActions = {
    addCloudBot,
    deleteCloudBot,
    updateCloudBotStatus,
    updateRemediationDetails,
};

export default CloudBotsActions;
