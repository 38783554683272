import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import i18n from 'common/services/translations/translations';
import { getExternalAdditionalFieldValue } from 'common/module_interface/assets/utils';

const RuntimeProtectionCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const getCellData = (isInsertionStateInProgress: boolean, isIncludesFSP: boolean) => {
        if (isInsertionStateInProgress) {
            return {
                text: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_RUNTIME_PROTECTION.VALUE2'),
                icon: 'clock',
            };
        }
        if (isIncludesFSP) {
            return {
                text: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_RUNTIME_PROTECTION.VALUE1'),
                icon: 'checkCircle',
            };
        }
        return { text: '' };
    };

    const insertionOptions = [
        'InProgress',
        'WaitingForScanAdd',
        'WaitingForScanRemove',
        'WaitingForInsertionAdd',
        'WaitingForInsertionRemove',
    ];
    const isIncludesFSP = getExternalAdditionalFieldValue(params.data, 'IncludesFSP') === 'true';
    const insertionState = getExternalAdditionalFieldValue(params.data, 'InsertionState');
    const isInsertionStateInProgress = insertionState && insertionOptions.includes(insertionState);
    const cellData = getCellData(Boolean(isInsertionStateInProgress), isIncludesFSP);

    const options: IIconLinkCellProps = {
        isLink: false,
        value: cellData.text,
    };

    if (cellData.icon) {
        options.iconProps = {
            vendorNameOrPath: cellData.icon,
            size: 14,
        };
    }

    return <IconLinkCellRenderer {...options} />;
};

export default RuntimeProtectionCellRenderer;
