import { IRuleset } from 'common/interface/ruleset';
import { ICloudAccount } from 'common/interface/data_services';
import { getVendor } from 'common/consts/vendors';
import { getEnvironmentDisplayNameFromCloudAccount } from 'common/components/ProtectedAssets/AssetUtils';
import { getStoreService } from 'common/interface/services';
import { RemediationModuleType } from 'common/interface/remediation';

export const mapEnvironmentItem = () => {
    const { state } = getStoreService().getReduxTools();
    const allCloudAccounts: ICloudAccount[] = state.remediation.cloudAccounts || [];
    return allCloudAccounts.reduce((prev: any, cloudAccount: any) => {
        const icon = cloudAccount?.platform ? getVendor(cloudAccount?.platform)?.icon : '';
        const displayValue = getEnvironmentDisplayNameFromCloudAccount(cloudAccount, cloudAccount.id);
        prev[cloudAccount.id] = { displayText: displayValue, icon, ...cloudAccount };
        return prev;
    }, {});
};

export const mapRulesetItem = () => {
    const { state } = getStoreService().getReduxTools();
    const allRulesetList: IRuleset[] = state.remediation.rulesetList || [];
    return allRulesetList.reduce((prev: any, ruleset: IRuleset) => {
        const displayValue = ruleset.name;
        prev[ruleset.id] = { displayText: displayValue, ...ruleset, icon: null };
        return prev;
    }, {});
};

export const getPageRemediationByModule = () => {
    const path = window.location.pathname;
    if (path.includes('magellan/remediation')) {
        return RemediationModuleType.CDR;
    } else if (path.includes('ciem/remediation')) {
        return RemediationModuleType.CIEM;
    } else {
        return RemediationModuleType.CSPM;
    }
};
