import { DrawerRegistry } from 'common/components/DrawerInfra/Drawer/DrawerRegistry';
import {
    CVE_DRAWER_ID,
    PACKAGE_DRAWER_ID,
    VULNERABILITY_DRAWER_ID,
    VULNERABILITY_EXCLUSION_DRAWER,
} from './cveDrawerRegistry.consts';
import { CveParams } from './cveDrawerRegistry.types';
import { IDrawerContent } from 'common/components/DrawerInfra/Drawer/Drawer.interface';
import CveDrawer from 'modules/workloads/pages/cveDrawer/CveDrawer';
import VulnerabilityDrawer from 'modules/workloads/Drawers/VulnerabilityDrawer/VulnerabilityDrawer';
import { LevelIcon } from 'common/design-system/components-v2';
import RightElements from '../../pages/cveDrawer/components/RightElements';
import { getCveAndPackageById } from '../vulnerability/utils';
import getActions from '../../Drawers/DrawersActions/DrawerActions';
import { TPackageDrawerParams } from 'modules/workloads/Drawers/PackageDrawer/PackageDrawer.types';
import PackageDrawer from 'modules/workloads/Drawers/PackageDrawer/PackageDrawer';
import VulnerabilityExclusionForm from '../../pages/vulnerabilityExclusion/VulnerabilityExclusionForm/VulnerabilityExclusionForm';
import { GetFunctionsProps, getFunctionsProps } from '../../pages/vulnerabilityExclusion/utils';
import { VulnerabilityExclusion, VulnerabilityExclusionType } from '../vulnerability/vulnerability.interface';
import vulnerabilityService from '../vulnerability/vulnerability.service';

function initializeDrawerContent() {
    DrawerRegistry.addContentProvider({
        id: CVE_DRAWER_ID,
        getDrawerContent: async (cveParams: CveParams): Promise<IDrawerContent | undefined> => {
            return {
                component: () => <CveDrawer cveParams={cveParams} />,
            };
        },
    });
    DrawerRegistry.addContentProvider({
        id: VULNERABILITY_DRAWER_ID,
        getDrawerContent: async ({
            cveId,
            packageId,
            cveSeverity,
            cloudEntity,
        }): Promise<IDrawerContent | undefined> => {
            const cveIdDecode = decodeURIComponent(cveId);
            const packageIdDecode = decodeURIComponent(packageId);
            const { data } = await vulnerabilityService.getVulnerabilityScan({
                entityId: cloudEntity.entityId,
                entityType: cloudEntity.typeByPlatform,
                environmentId: cloudEntity.cloudAccountId,
                remediationOnly: false,
            });

            const packageAndCve = getCveAndPackageById(cveIdDecode, packageIdDecode, data);

            const drawerContent: IDrawerContent = {
                component: VulnerabilityDrawer,
                componentProps: { cveId: cveIdDecode, packageId: packageIdDecode, data: data },
                title: cveIdDecode,
                icon: (
                    <LevelIcon
                        iconProps={{ name: 'cve' }}
                        category={'severity'}
                        level={cveSeverity?.toLowerCase() || 'unknown'}
                    />
                ),
                actions: packageAndCve
                    ? getActions({
                          cve: {
                              isExcluded: packageAndCve.cve.isExcluded,
                              id: packageAndCve.cve.id,
                              exclusion: packageAndCve.cve.exclusion,
                          },
                          exclusionInitForm: {
                              id: '',
                              type: VulnerabilityExclusionType.CVE,
                              includeInAssessment: false,
                              comment: '',
                              author: '',
                              creationDate: new Date(),
                              errorMessage: '',
                              name: '',
                              filter: {
                                  packageName: '',
                                  environmentsIds: [],
                                  cveId: packageAndCve.cve.id,
                              },
                          },
                      })
                    : [],
                rightHeaderContent: <RightElements packageAndCve={packageAndCve} />,
            };
            return Promise.resolve(drawerContent);
        },
    });

    DrawerRegistry.addContentProvider({
        id: PACKAGE_DRAWER_ID,
        getDrawerContent: async (params: TPackageDrawerParams): Promise<IDrawerContent | undefined> => {
            return {
                component: PackageDrawer,
                componentProps: { ...params, id: decodeURIComponent(params.id) },
            };
        },
    });
}

const initDrawerVulExclusion = (getFunctionsProps: GetFunctionsProps) => {
    DrawerRegistry.addContentProvider({
        id: VULNERABILITY_EXCLUSION_DRAWER,
        getDrawerContent: async (params: {
            title: string;
            initialValues: VulnerabilityExclusion;
            isEdit: boolean;
        }): Promise<IDrawerContent | undefined> => {
            const { onConfirmEdit, onConfirm } = getFunctionsProps();
            const result = {
                component: VulnerabilityExclusionForm,
                icon: <LevelIcon iconProps={{ name: 'exclude' }} />,
                title: params.title,
                componentProps: {
                    onConfirmEdit: onConfirmEdit,
                    onConfirm: onConfirm,
                    initialValues: params.initialValues,
                    isEdit: params.isEdit,
                },
            };
            return Promise.resolve(result);
        },
        options: {
            suppressCloseOnEscape: false,
        },
    });
};

export function initializeCveDrawer() {
    initializeDrawerContent();
    initDrawerVulExclusion(getFunctionsProps);
}
