import { IEvent } from 'common/module_interface/events/Events';
import { PermissionSection, PermissionSectionTypes } from './ServerlessPermissionSection.types';

export const getSectionFromRemediation = (
    sections: PermissionSection[],
    remediationActions: IEvent['remediationActions'],
) => {
    const remediationObjects = remediationActions
        .map((action) => {
            try {
                return JSON.parse(action);
            } catch {
                return null;
            }
        })
        .filter((actionObject) => !!actionObject);
    return sections.reduce<[string, string, Record<string, any>][]>((accum, { key, title, type }) => {
        const sectionData: unknown = remediationObjects.find((actionObject) => actionObject?.[key])?.[key];
        if (sectionData === undefined) {
            return accum;
        }
        let data: Record<string, any> | undefined;
        if (typeof sectionData === 'string') {
            try {
                data = JSON.parse(sectionData);
            } catch {
                data = undefined;
            }
        } else if (typeof sectionData === 'object') {
            data = sectionData || undefined;
        }
        if (data === undefined) {
            return accum;
        }
        return [...accum, [title, type, data]];
    }, []);
};

export const getServerlessPermissionRemediationSections = (bundleName: IEvent['bundleName']): PermissionSection[] => {
    switch (bundleName) {
        case 'PermissiveRole':
            return [
                { key: 'RedundantPermissions', title: 'Redundant Permissions', type: PermissionSectionTypes.issue },
                { key: 'SuggestedRole', title: 'Suggested Role', type: PermissionSectionTypes.remediation },
            ];
        case 'CredentialsUsage':
            return [{ key: 'CredentialsUsage', title: 'Credentials Usage', type: PermissionSectionTypes.remediation }];
        default:
            return [];
    }
};
