import React, { useCallback, useMemo } from 'react';
import { Stack } from 'common/design-system/components-v2';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { FILTERS_API_OBJECT_KEYS, FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import {
    renderAddFilter,
    renderClearAll,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';

export interface FilterPanelVulProps {
    data: Array<any>;
    onFilterChange: (filters: IFiltersValues) => void;
    getRefElementTextFree?: (refElementTextFree: string) => void;
    isRemediations: boolean;
}

const FilterPanelVulnerability: React.FC<FilterPanelVulProps> = ({ data, onFilterChange, isRemediations = false }) => {
    const getAggregations = useCallback(
        async (filtersValues?: IFiltersValues): Promise<Aggregations> => {
            if (filtersValues) {
                onFilterChange(filtersValues);
            }

            return data.reduce<{ [key: string]: Array<{ value: string; count: number }> }>((acc, sbomItem) => {
                Object.keys(sbomItem).forEach((key) => {
                    let value = sbomItem[key as keyof any];
                    if (typeof value === 'object') {
                        return;
                    }
                    if (typeof value === 'boolean') {
                        value = value.toString();
                    }
                    if (!acc[key]) {
                        acc[key] = [
                            {
                                value: value,
                                count: 1,
                            },
                        ];
                    } else {
                        const findItem = acc[key].find((item) => item.value === value);
                        if (findItem) {
                            findItem.count++;
                        } else {
                            acc[key].push({
                                value: value,
                                count: 1,
                            });
                        }
                    }
                });
                return acc;
            }, {});
        },
        [data, onFilterChange],
    );

    const buildFilterPropsByProperty = useCallback(
        (property: any) => {
            const groupBy: Record<string, { displayText: string }> = {};
            data.forEach((item) => {
                let key = item[property];
                if (typeof key === 'object') {
                    return;
                }
                if (typeof key === 'boolean') {
                    key = key.toString();
                }
                if (!groupBy[key]) {
                    groupBy[key] = { displayText: key };
                }
            });
            return groupBy;
        },
        [data],
    );

    const buildValueCountArray = useCallback(
        (property: any) => {
            const countMap: { [key: string]: { value: any; count: number } } = {};

            data.forEach((obj) => {
                const value = obj[property];
                if (!countMap[value]) {
                    countMap[value] = {
                        value,
                        count: 1,
                    };
                } else {
                    countMap[value].count++;
                }
            });

            return Object.keys(countMap).map((value) => ({
                value,
                count: countMap[value],
            }));
        },
        [data],
    );

    const filterPanel = useMemo(() => {
        return initFilterPanel({
            filterPanelElementsList: [
                {
                    filterProps: { key: FILTERS_KEYS.ADD_FILTER },
                    renderFunction: renderAddFilter,
                },
                {
                    filterProps: {
                        key: 'relatedIds',
                        title: 'Related IDs',
                        initialData: buildValueCountArray('relatedIds'),
                        displayMapping: buildFilterPropsByProperty('relatedIds'),
                    },
                    renderFunction: renderMultiSelectFilter,
                },
                {
                    filterProps: {
                        key: 'severity',
                        title: 'Severity',
                        initialData: buildValueCountArray('severity'),
                        displayMapping: buildFilterPropsByProperty('severity'),
                    },
                    renderFunction: renderMultiSelectFilter,
                },
                ...(!isRemediations
                    ? [
                          {
                              filterProps: {
                                  key: 'type',
                                  title: 'Type',
                                  initialData: buildValueCountArray('category'),
                                  displayMapping: buildFilterPropsByProperty('category'),
                              },
                              renderFunction: renderMultiSelectFilter,
                          },
                      ]
                    : [
                          {
                              filterProps: {
                                  key: 'category',
                                  title: 'Category',
                                  initialData: buildValueCountArray('category'),
                                  displayMapping: buildFilterPropsByProperty('category'),
                              },
                              renderFunction: renderMultiSelectFilter,
                          },
                      ]),
                {
                    filterProps: {
                        key: 'classification',
                        title: 'Classification',
                        initialData: buildValueCountArray('classification'),
                        displayMapping: buildFilterPropsByProperty('classification'),
                    },
                    renderFunction: renderMultiSelectFilter,
                },
                {
                    filterProps: {
                        key: FILTERS_KEYS.CLEAR_BUTTON,
                    },
                    renderFunction: renderClearAll,
                },
                {
                    filterProps: {
                        key: FILTERS_KEYS.FREE_TEXT,
                        title: 'Free Text',
                    },
                    keyInObjectForAPI: FILTERS_API_OBJECT_KEYS.FREE_TEXT,
                    renderFunction: renderDefaultFreeTextFilter,
                },
            ],
            getAggregations: getAggregations,
            shouldBuildObjectForAPI: false,
            filterId: 'filter-panel-vulnerability',
        });
    }, [buildFilterPropsByProperty, buildValueCountArray, getAggregations, isRemediations]);
    return <Stack margin={[6, 0, 0, 0]}>{filterPanel}</Stack>;
};

export default FilterPanelVulnerability;
