import initializeColumnDefs from './Defenitions/NotificationsColumnDefenitions';
import initializeFilters from './Defenitions/NotificationsFiltersDefenitions';
import i18n, { validLanguages } from 'common/services/translations/translations';
import { I18nNotifications } from './NotificationsPage.consts';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { NOTIFICATION_PAGE_SERVICE_ID } from 'common/interface/services';
import { NotificationPageService } from './Services/NotificationPageService';
import NotificationAddEditModal from './Components/AddEditNotificationModal/NotificationAddEditModal';

export const initializeI18nNotifications = async () => {
    return Promise.all(
        validLanguages.map(async (language) => {
            try {
                const contents = await require(`./languages/${language}/translation.json`);
                i18n.addResourceBundle(language, `${I18nNotifications}`, contents);
            } catch {
                //Nothing to do with missing translation files
            }
        }),
    );
};

const initializeModal = () => {
    globalAddinContainer.add('notificationModal', [
        {
            id: 'notificationModal',
            content: () => NotificationAddEditModal,
        },
    ]);
};

const initializeServices = () => {
    globalAddinContainer.addService(NOTIFICATION_PAGE_SERVICE_ID, new NotificationPageService());
};
export const initializeNotificationsPage = () => {
    initializeServices();
    initializeColumnDefs();
    initializeFilters();
    initializeModal();
};
