/* eslint-disable react/prop-types */

import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import SummaryWidgetsSettings from 'common/components/Widgets/SettingsComponents/SummaryWidgetsSettings';
import globalAddinContainer from 'common/extensibility/AddinContainer';
import {
    IEventsFilter,
    PATH_EVENTS_WIDGETS_FILTERS,
    getEventsService,
    PATH_RULE_AGGREGATION_FILTERS,
} from 'common/module_interface/events/Events';
import {
    DashboardWidgetTypes,
    IDashboardWidget,
    IGenericWidgetSettingsProps,
} from 'common/module_interface/overview/Interface';
import { deepCloneObject } from 'common/utils/objectUtils';
import { getEventsNamespace } from 'modules/events/initialize.i18n';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { alertsFilterPanelDataGetter, getRawSearchData } from '../WidgetsData/AlertsDataGetter';
import { renderFilterPanelForWidgets } from 'common/components/FilterPanel/DashboardFilterPanel/dashboardFilterUtils';
import { AssetAggregationCacheConfig } from 'common/components/ProtectedAssets/datasource';
import { getHttpService } from 'common/interface/services';

let aggregationsCache: Aggregations | null = null;

let debounceTimeoutId: any = null;

export function getAggregationsFunction(): (filtersValues: IFiltersValues) => Promise<Aggregations> {
    return async (filtersValues: IFiltersValues) => {
        const searchResponse = await getHttpService().post<any>({
            path: 'Compliance/Finding/searchWithCustomAggregations',
            requestObject: {
                data: {
                    filter: {
                        freeTextPhrase: '',
                        ...filtersValues,
                    },
                    pageSize: 0,
                    lowAggregationsSize: false,
                },
            },
            cachingConfig: AssetAggregationCacheConfig,
        });
        return searchResponse.aggregations;
    };
}

const AlertsWidgetsSettings: React.FC<IGenericWidgetSettingsProps> = ({
    widget,
    updateWidgetCallback,
    onFilterPanelAsyncChangeFinished,
    onFilterPanelAsyncChangeStarted,
}) => {
    const { t } = useTranslation(getEventsNamespace('translation'));
    const [filterPanelComponent, setFilterPanelComponent] = useState(<div></div>);
    const [isLoading, setIsLoading] = useState(true);
    const FILTER_PANEL_ID = 'events-widgets-filter-panel-id';
    const [limit, setLimit] = useState(widget.limit);

    const filters =
        widget.aggregation === 'ruleName'
            ? globalAddinContainer.get<IEventsFilter>(PATH_RULE_AGGREGATION_FILTERS)
            : globalAddinContainer.get<IEventsFilter>(PATH_EVENTS_WIDGETS_FILTERS);

    const handleFilterChange = (filterValues: any) => {
        widget.filterState = filterValues;
        updateWidgetCallback(widget);
    };

    const initPage = useCallback(async () => {
        if (!aggregationsCache) {
            const searchResponse = await alertsFilterPanelDataGetter({} as IDashboardWidget, getRawSearchData);
            aggregationsCache = searchResponse.aggregations;
        }
        const aggregations = aggregationsCache;
        const filterPanel =
            aggregations &&
            (await renderFilterPanelForWidgets(
                FILTER_PANEL_ID,
                filters,
                getAggregationsFunction,
                getEventsService().getFilterDefs,
                widget,
                onFilterPanelAsyncChangeFinished,
                onFilterPanelAsyncChangeStarted,
                handleFilterChange,
                aggregations,
                widget.filterState,
            ));

        filterPanel && setFilterPanelComponent(filterPanel);

        setIsLoading(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        initPage();
    }, [initPage]);

    const handleChangeLimit = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.valueAsNumber;
        setLimit(value);

        clearTimeout(debounceTimeoutId);

        debounceTimeoutId = setTimeout(() => {
            const _widgetClone = deepCloneObject(widget);
            _widgetClone.limit = value;
            updateWidgetCallback(_widgetClone);
        }, 500);
    };

    return (
        <>
            {widget.type === DashboardWidgetTypes.Summary && (
                <SummaryWidgetsSettings widget={widget} updateWidgetCallback={updateWidgetCallback} />
            )}
            {widget.type === DashboardWidgetTypes.StackedColumn && <div>{t('WIDGETS.STACKED_BY_SEVERITY')}</div>}
            <div className='mt-8'>
                {isLoading ? (
                    <span>{t('WIDGETS.LOADING_FILTERS')}</span>
                ) : (
                    <div>
                        <div className='font-semibold mb-2'>{t('WIDGETS.ITEMS_COUNT')}:</div>
                        <div className='mt-2 flex items-center mb-6'>
                            <div className='pt-4 mr-4'>
                                <input
                                    onChange={handleChangeLimit}
                                    type='range'
                                    step={5}
                                    min={5}
                                    max={50}
                                    value={limit}
                                />
                            </div>
                            <div>({limit})</div>
                        </div>
                        {filterPanelComponent}
                    </div>
                )}
            </div>
        </>
    );
};

export default AlertsWidgetsSettings;
