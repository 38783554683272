import { FC, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { I18nTranslationKey } from '../initUsersPage';
import { Button, Modal, Spinner, Stack, Typography } from 'common/design-system/components-v2';
import { getNotificationsService, getUsersService, UserPageService } from 'common/interface/services';
import { IUser } from 'common/interface/user';

interface IConnectUserToSsoModal {
    isModalOpen: boolean;
    toggleModal: () => void;
    selectedEmail?: string;
    selectedId?: number;
}

export const ConnectUserToSsoModal: FC<IConnectUserToSsoModal> = ({
    isModalOpen,
    toggleModal,
    selectedEmail,
    selectedId,
}) => {
    const { t } = useTranslation(I18nTranslationKey);
    const [selectedUser, setSelectedUser] = useState<IUser>();
    const [isLoading, setIsLoading] = useState(false);

    const init = async () => {
        const users = await getUsersService().getUsers(false);
        setSelectedUser(() => {
            return users.find((user) => user.id === selectedId);
        });
    };

    const handleClose = () => {
        toggleModal();
    };

    const errorHandling = (error: any) => {
        const errorData = error?.response?.data || '';
        errorData
            ? getNotificationsService().error(t('ACTIONS.CONNECT_USER_TO_SSO_MODAL.TOAST_HEADER'), errorData)
            : getNotificationsService().error(
                  t('ACTIONS.CONNECT_USER_TO_SSO_MODAL.TOAST_HEADER'),
                  t('ACTIONS.CONNECT_USER_TO_SSO_MODAL.TOAST_ERROR_CONTENT'),
              );
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            selectedUser && (await UserPageService().connectUserToSso(selectedUser));
            getNotificationsService().success(
                t('ACTIONS.CONNECT_USER_TO_SSO_MODAL.TOAST_HEADER'),
                t('ACTIONS.CONNECT_USER_TO_SSO_MODAL.TOAST_SUCCESS_CONTENT'),
            );
            handleClose();
        } catch (e: any) {
            errorHandling(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        void init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalOpen]);

    return (
        <Modal.ModalDialog width={'lg'} isOpen={isModalOpen}>
            <Modal.ModalHeader onClose={handleClose} title={t('ACTIONS.CONNECT_USER_TO_SSO_MODAL.MODAL_HEADER')} />
            <Modal.ModalContent>
                <Stack padding={2}>
                    <Typography variant={'lg'}>
                        <Trans>{t('ACTIONS.CONNECT_USER_TO_SSO_MODAL.MODAL_CONTENT', { email: selectedEmail })}</Trans>
                    </Typography>
                </Stack>
            </Modal.ModalContent>
            <Modal.ModalFooter>
                <Stack direction={'row'} spacing={3} justifyContent={'flex-end'} fullWidth>
                    <Button
                        dataAid={'cancel-reset-password-button'}
                        variant='text'
                        onClick={handleClose}
                        disabled={isLoading}
                    >
                        {t('GENERAL.CANCEL')}
                    </Button>
                    <Button
                        dataAid={'ok-reset-password-button'}
                        color={'brandPrimary'}
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        {' '}
                        {isLoading ? <Spinner size={12} /> : t('GENERAL.OK')}
                    </Button>
                </Stack>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    );
};
