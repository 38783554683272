import { IAsset, UrlFuncResult } from 'common/assets/common.assets';
import { toQueryString } from 'common/utils/http';
import ImageLinkRenderer from '../../CellRenderers/ImageLinkRenderer';
import EnvironmentCellRenderer from '../../CellRenderers/EnvironmentCellRenderer';
import { IColumnUsageDef } from 'common/interface/general';
import { TABLE_SELECTION_COL_ID } from 'common/utils/tableUtils';

const generateUrl = (asset: IAsset, assetUrl: UrlFuncResult): string => {
    const url = `/workload/workloads/protected-asset${asset?.isGenericObject ? '-generic' : ''}`;
    const query = {
        ...((assetUrl?.query as object) || {}),
        type: asset?.typeNumber,
    };
    const queryParams = toQueryString({ query });
    return `${url}?${queryParams}`;
};
export const TABLE_SELECTION = [
    {
        id: TABLE_SELECTION_COL_ID,
    },
];

export const assetsTableBaseColumns: Array<IColumnUsageDef & { order?: number }> = [
    {
        id: 'entity',
        order: 0,
        colDefOverride: {
            cellRendererParams: {
                generateUrl,
            },
        },
    },
    {
        id: 'ermRiskScore',
        order: 2,
        colDefOverride: {
            initialSort: 'desc',
            initialHide: true,
        },
    },
    {
        id: 'ermMisconfigurations',
        colDefOverride: {
            initialHide: true,
        },
    },
    {
        id: 'cves',
        colDefOverride: {
            initialHide: false,
        },
    },
    {
        id: 'threats',
        colDefOverride: {
            initialHide: false,
        },
    },
    {
        id: 'secrets',
        colDefOverride: {
            initialHide: false,
        },
    },
    {
        id: 'workloads',
        colDefOverride: {
            width: 100,
        },
    },
    { id: 'namespace' },
    { id: 'type' },
];

export const imagesTableBaseColumns: Array<IColumnUsageDef & { order?: number }> = [
    {
        id: 'riskScore',
        order: 0,
        colDefOverride: {
            initialSort: 'desc',
        },
    },
    { id: 'registry', order: 1 },
    {
        id: 'repository',
        order: 2,
        colDefOverride: {
            cellRenderer: ImageLinkRenderer,
        },
    },
    {
        id: 'imageTag',
        order: 3,
        colDefOverride: {
            cellRenderer: ImageLinkRenderer,
        },
    },
    { id: 'platform' },
    {
        id: 'isRunning',
        colDefOverride: {
            enableRowGroup: true,
        },
    },
    {
        id: 'cves',
        colDefOverride: {
            initialHide: false,
        },
    },
    {
        id: 'threats',
        colDefOverride: {
            initialHide: false,
        },
    },
    {
        id: 'secrets',
        colDefOverride: {
            initialHide: false,
        },
    },
    { id: 'image_scanStatus' },
    { id: 'vulnScanStatusDetails' },
    { id: 'VendorImage' },
    { id: 'BaseImage' },
    {
        id: 'imageId',
        colDefOverride: {
            cellRenderer: ImageLinkRenderer,
            initialHide: true,
        },
    },
    {
        id: 'digest',
        colDefOverride: {
            cellRenderer: ImageLinkRenderer,
            initialHide: true,
        },
    },
    { id: 'lastRunningDate', colDefOverride: { initialHide: true } },
    { id: 'cluster', colDefOverride: { initialHide: true } },
];

export const mainPageTableBaseColumns: Array<IColumnUsageDef & { order?: number }> = [
    {
        id: 'environment',
        order: 1,
        colDefOverride: {
            cellRenderer: EnvironmentCellRenderer,
            enableRowGroup: true,
        },
    },
    {
        id: 'platform',
        order: 2,
        colDefOverride: {
            initialHide: true,
        },
    },
    {
        id: 'organizationalUnit',
        order: 3,
        colDefOverride: {
            initialHide: true,
        },
    },
];

export const serverlessTableColumns: Array<IColumnUsageDef & { order?: number }> = [
    {
        id: TABLE_SELECTION_COL_ID,
        colDefOverride: {
            headerCheckboxSelection: false,
            headerCheckboxSelectionCurrentPageOnly: true,
        },
    },
    {
        id: 'entity',
        order: 0,
        colDefOverride: {
            cellRendererParams: {
                generateUrl,
            },
        },
    },
    {
        id: 'environment',
        order: 1,
        colDefOverride: {
            cellRenderer: EnvironmentCellRenderer,
        },
    },
    {
        id: 'region',
        order: 2,
    },
    {
        id: 'riskScore',
        order: 3,
    },
    {
        id: 'cves',
        order: 4,
    },
    {
        id: 'threats',
        order: 5,
    },
    {
        id: 'secrets',
        order: 6,
    },
    {
        id: 'runtimeEnvironment',
        order: 7,
    },
    {
        id: 'includesFSP',
        order: 8,
    },
    {
        id: 'fspStickyMode',
        order: 9,
    },
    {
        id: 'insertionState',
        order: 10,
    },
    {
        id: 'withFSPBlock',
        order: 11,
    },
    {
        id: 'FspVersion',
        order: 12,
    },
    {
        id: 'whiteListProgressProtego',
        order: 13,
    },
    {
        id: 'scanStatus',
        order: 14,
    },
    {
        id: 'scanStatusDetails',
        order: 15,
    },
];

export const removeColumnsBaseImage = (
    columns: Array<IColumnUsageDef & { order?: number }>,
    isBaseImageActiveFeatureOpen: boolean,
): Array<IColumnUsageDef> => {
    const baseImageColumn = ['BaseImage', 'VendorImage', 'actions'];
    if (!isBaseImageActiveFeatureOpen) {
        return columns.filter((column) => !baseImageColumn.includes(column.id));
    }
    return columns;
};

export const sortTableColumns = (columns: Array<IColumnUsageDef & { order?: number }>): Array<IColumnUsageDef> => {
    return columns
        .sort((a, b) => {
            if (typeof a.order === 'number' && typeof b.order === 'number') {
                return a.order > b.order ? 1 : -1;
            }
            if (typeof a.order === 'number') {
                return -1;
            }
            if (typeof b.order === 'number') {
                return 1;
            }
            return 0;
        })
        .map((column) => {
            delete column.order;
            return column;
        });
};
