import { IProtectedAssetFilter, IProtectedAssetFilterParams } from 'common/module_interface/assets/ProtectedAssets';
import { buildPath, globalAddinContainer } from 'common/extensibility/AddinContainer';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import {
    renderAddFilter,
    renderClearAll,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter,
    renderRecentlyUsedFilters,
    renderSavedFilters,
    renderTreeFilter,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import {
    FILTER_BOX_DISPLAY_TYPES,
    FILTER_DISPAY_TYPES,
    FILTERS_KEYS,
} from 'common/components/FilterPanel/FilterPanel.consts';
import i18n from 'i18next';
import { IDisplayMappingObject } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { getVendor, getVendorDisplayName } from 'common/consts/vendors';
import {
    CONTEXT_FIELD_FULL_NAMES,
    I18nRiskNamespace,
    IamExposureServerIdEnum,
    NetworkExposureLevelEnum,
} from '../consts';
import { mapEnvironmentItem, mapTypeItem } from 'common/module_interface/assets/utils';
import {
    RECENTLY_USED_FILTER_ID,
    SAVED_FILTERS_FILTER_ID,
} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable.filters';
import { ERM_PROTECTED_ASSET_FILTER_IDS } from '../../../common/module_interface/RiskManagement/protectedAssets/filters.consts';

const PATH_RISK_MANAGEMENT_PREFIX = buildPath('erm', 'risk management');
export const PATH_RISK_MANAGEMENT_WIDGETS = buildPath(PATH_RISK_MANAGEMENT_PREFIX, 'widgets');
const PATH_RISK_MANAGEMENT_WIDGETS_PREFIX = buildPath('erm', 'widgets');
export const PATH_RISK_MANAGEMENT_FILTERS_DEFS = buildPath(PATH_RISK_MANAGEMENT_WIDGETS_PREFIX, 'filters');
export const PATH_RISK_MANAGEMENT_FILTERS = buildPath(PATH_RISK_MANAGEMENT_WIDGETS, 'filters');

const filters: IProtectedAssetFilter[] = [
    { id: 'add filter' },
    { id: 'free text' },
    { id: 'organizational unit' },
    { id: 'platform' },
    { id: 'type' },
    { id: 'severity' },
    { id: 'environment' },
    { id: ERM_PROTECTED_ASSET_FILTER_IDS.businessPriority },
    { id: ERM_PROTECTED_ASSET_FILTER_IDS.networkExposure },
    { id: ERM_PROTECTED_ASSET_FILTER_IDS.iamExposure },
    { id: ERM_PROTECTED_ASSET_FILTER_IDS.dataSensitivity },
    { id: 'clear all' },
];

const widgetsFilterDefsAddins: Addin<IFilterProps>[] = [
    {
        id: 'add filter',
        content: {
            filterProps: { key: 'add-filter' },
            renderFunction: renderAddFilter,
        },
    },
    {
        id: 'free text',
        content: {
            filterProps: {
                key: FILTERS_KEYS.FREE_TEXT, //This needs to be the same as in stored filter/query params
                title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.FREE_TEXT.TITLE'),
            },
            keyInObjectForAPI: 'freeTextPhrase',
            renderFunction: renderDefaultFreeTextFilter,
            displayTypes: [FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_1, FILTER_DISPAY_TYPES.SEPARATE_FIELDS],
        },
    },
    {
        id: 'organizational unit',
        content: function ({ filtersInitialData }: IProtectedAssetFilterParams) {
            return {
                filterProps: {
                    initialData: filtersInitialData?.organizationalUnits,
                    key: 'organizationalUnitId',
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.ORGANIZATIONAL_UNITS.TITLE'),
                },
                renderFunction: renderTreeFilter,
            };
        },
    },
    {
        id: 'platform',
        content: function ({ aggregations }: IProtectedAssetFilterParams) {
            const displayMapping: IDisplayMappingObject = {};
            aggregations['platform']?.forEach((platform: { value: string }) => {
                displayMapping[platform.value] = {
                    displayText: getVendorDisplayName(platform.value),
                    icon: getVendor(platform.value)?.icon,
                };
            });
            return {
                filterProps: {
                    initialData: aggregations['platform'],
                    key: 'platform',
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.PLATFORM.TITLE'),
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'type',
        content: function ({ aggregations }: IProtectedAssetFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations['type'],
                    key: 'type',
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.TYPE.TITLE'),
                    displayMapping: mapTypeItem(aggregations?.type),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: 'environment',
        content: function ({ aggregations, allCloudAccounts = [] }: IProtectedAssetFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations['cloudAccountId'],
                    key: 'cloudAccountId',
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.ENVIRONMENT.TITLE'),
                    displayMapping: mapEnvironmentItem(allCloudAccounts),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: ERM_PROTECTED_ASSET_FILTER_IDS.dataSensitivity,
        content: function ({ aggregations }: IProtectedAssetFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations[CONTEXT_FIELD_FULL_NAMES.dataSensitivity],
                    key: CONTEXT_FIELD_FULL_NAMES.dataSensitivity,
                    title: i18n.t('PROTECTED_ASSETS.FILTERS.DATA_SENSITIVITY.TITLE', { ns: I18nRiskNamespace }),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: ERM_PROTECTED_ASSET_FILTER_IDS.businessPriority,
        content: function ({ aggregations }: IProtectedAssetFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations['businessPriority'],
                    key: 'businessPriority',
                    title: i18n.t('PROTECTED_ASSETS.FILTERS.BUSINESS_PRIORITY.TITLE', { ns: I18nRiskNamespace }),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: ERM_PROTECTED_ASSET_FILTER_IDS.networkExposure,
        content: function ({ aggregations }: IProtectedAssetFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations[CONTEXT_FIELD_FULL_NAMES.networkExposure]?.filter((item) => {
                        return item.value !== NetworkExposureLevelEnum.Unknown;
                    }),
                    key: CONTEXT_FIELD_FULL_NAMES.networkExposure,
                    title: i18n.t('PROTECTED_ASSETS.FILTERS.NETWORK_EXPOSURE.TITLE', { ns: I18nRiskNamespace }),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: ERM_PROTECTED_ASSET_FILTER_IDS.iamExposure,
        content: function ({ aggregations }: IProtectedAssetFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations[CONTEXT_FIELD_FULL_NAMES.iamExposure]?.filter((item) => {
                        return item.value !== IamExposureServerIdEnum.Unknown;
                    }),
                    key: CONTEXT_FIELD_FULL_NAMES.iamExposure,
                    title: i18n.t('PROTECTED_ASSETS.FILTERS.IAM_EXPOSURE.TITLE', { ns: I18nRiskNamespace }),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },

    {
        id: SAVED_FILTERS_FILTER_ID,
        content: function ({ filtersInitialData }: IProtectedAssetFilterParams) {
            return {
                filterProps: {
                    savedFilters: filtersInitialData?.savedFilters || [],
                    savedFiltersComponentName: '',
                    selectedFilterID: '',
                    key: FILTERS_KEYS.SAVED_FILTERS,
                },
                renderFunction: renderSavedFilters,
            };
        },
    },
    {
        id: RECENTLY_USED_FILTER_ID,
        content: function ({ filtersInitialData }: IProtectedAssetFilterParams) {
            return {
                filterProps: {
                    key: FILTERS_KEYS.RECENTLY_USED_FILTERS,
                    recentlyUsedList: filtersInitialData?.recentlyUsed,
                    maxLength: 4,
                    componentName: '',
                    title: i18n.t('FILTER_PANEL.RECENTLY_USED_FILTERS'),
                },
                renderFunction: renderRecentlyUsedFilters,
            };
        },
    },
    {
        id: 'clear all',
        content: {
            filterProps: {
                key: FILTERS_KEYS.CLEAR_BUTTON,
            },
            renderFunction: renderClearAll,
        },
    },
];

export const RiskManagementsWidgetRegistry = {
    addFilterAddins: (filterAddins: Addin<IFilterProps>[]) => {
        globalAddinContainer.add<IFilterProps>(PATH_RISK_MANAGEMENT_FILTERS_DEFS, filterAddins);
    },

    getFilter: (filterId: string, filterParams: IProtectedAssetFilterParams[]): IFilterProps | undefined => {
        return globalAddinContainer.getById<IFilterProps>(PATH_RISK_MANAGEMENT_FILTERS_DEFS, filterId, filterParams);
    },
};

export default function initializeRiskManagementWidgetsFilters() {
    globalAddinContainer.addMap(PATH_RISK_MANAGEMENT_FILTERS, filters);
    RiskManagementsWidgetRegistry.addFilterAddins(widgetsFilterDefsAddins);
}
