import { SortOrder } from 'common/interface/general';
import { getDescendingLevels, getLevelInfo, getLevels, IBaseLevelInfo } from 'common/consts/levels';
import { lightPallete } from 'common/design-system/theme/colors';
import { IssueSeverityEnum } from 'common/module_interface/RiskManagement/issues/Issues.interface';

export interface IIssueSeverityInfo extends IBaseLevelInfo<IssueSeverityEnum> {}

export const IssueSeveritiesMap: { [key in IssueSeverityEnum]: IIssueSeverityInfo } = {
    unknown: {
        key: IssueSeverityEnum.unknown,
        level: -1,
        displayText: 'Unknown',
        color: lightPallete.severity.unknownFg,
        bg: lightPallete.severity.unknownBg,
        context: 'default',
        iconProps: {
            name: 'severityUnknown',
        },
    },
    informational: {
        key: IssueSeverityEnum.informational,
        level: 0,
        displayText: 'Informational',
        color: lightPallete.severity.informationalFg,
        bg: lightPallete.severity.informationalBg,
        context: 'info',
        iconProps: {
            name: 'severityInfo',
        },
    },
    low: {
        key: IssueSeverityEnum.low,
        level: 2,
        displayText: 'Low',
        color: lightPallete.severity.lowFg,
        bg: lightPallete.severity.lowBg,
        context: 'low',
        iconProps: {
            name: 'severityLow',
        },
    },
    medium: {
        key: IssueSeverityEnum.medium,
        level: 3,
        displayText: 'Medium',
        color: lightPallete.severity.mediumFg,
        bg: lightPallete.severity.mediumBg,
        context: 'medium',
        iconProps: {
            name: 'severityMedium',
        },
    },
    high: {
        key: IssueSeverityEnum.high,
        level: 4,
        displayText: 'High',
        color: lightPallete.severity.highFg,
        bg: lightPallete.severity.highBg,
        context: 'high',
        iconProps: {
            name: 'severityHigh',
        },
    },
    critical: {
        key: IssueSeverityEnum.critical,
        level: 5,
        displayText: 'Critical',
        color: lightPallete.severity.criticalFg,
        bg: lightPallete.severity.criticalBg,
        context: 'critical',
        iconProps: {
            name: 'severityCritical',
        },
    },
};

const getAllSeverities = (): IIssueSeverityInfo[] => {
    return Object.values(IssueSeveritiesMap);
};

export const getIssueSeverityInfo = (key: IssueSeverityEnum | string | number): IIssueSeverityInfo | undefined => {
    return getLevelInfo<IssueSeverityEnum>(getAllSeverities(), key) as IIssueSeverityInfo | undefined;
};

export const getSafeIssueSeverityInfo = (key: IssueSeverityEnum | string | number): IIssueSeverityInfo => {
    return getIssueSeverityInfo(key) || IssueSeveritiesMap.unknown;
};

export const getIssueSeverities = (order?: SortOrder, lowestKey?: IssueSeverityEnum): IIssueSeverityInfo[] => {
    return getLevels<IssueSeverityEnum>(getAllSeverities(), order, lowestKey) as IIssueSeverityInfo[];
};

export const getRealIssueSeverities = (): IIssueSeverityInfo[] => {
    return Object.values(IssueSeveritiesMap).filter(
        (severityInfo: IIssueSeverityInfo) => severityInfo.level >= IssueSeveritiesMap.low.level,
    );
};

export const sortDecendingly = (severities: IIssueSeverityInfo[]): IIssueSeverityInfo[] => {
    return getDescendingLevels<IssueSeverityEnum>(severities) as IIssueSeverityInfo[];
};

export const convertSeverityKeysToLevels = (keys: string[]): number[] => {
    return keys.map((key) => getSafeIssueSeverityInfo(key).level);
};

export const convertSeverityLevelsToKeys = (levels: number[]): string[] => {
    return levels.map((level) => getSafeIssueSeverityInfo(level).key);
};
