import { IEventTracking } from 'common/interface/services';
import { trackEvent, trackPageView } from '../3rdParty/mixpanel/mixpanel';

export class EventTracking implements IEventTracking {
    track(eventName: string, properties?: { [params: string]: string | number | boolean | undefined }): void {
        trackEvent(eventName, properties);
    }
    pageView(): void {
        trackPageView();
    }
}
