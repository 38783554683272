import { Cve, VulnerabilityExclusion } from '../../services/vulnerability/vulnerability.interface';
import { IDrawerAction } from 'common/components/DrawerInfra/Drawer/Drawer.interface';
import vulnerabilityService from '../../services/vulnerability/vulnerability.service';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { VULNERABILITY_EXCLUSION_DRAWER } from '../../services/cveDrawerRegistry/cveDrawerRegistry.consts';
import i18n from 'common/services/translations/translations';
import { getK8sNamespace } from '../../initialize.i18n';

interface ActionProps {
    cve: Pick<Cve, 'isExcluded' | 'exclusion' | 'id'>;
    exclusionInitForm?: VulnerabilityExclusion;
}

const getActions = (actionProps: ActionProps): Array<IDrawerAction> => {
    const { cve, exclusionInitForm } = actionProps;
    return [
        {
            label: `${cve.isExcluded ? 'Edit exclude' : 'Add exclude'}`,
            value: 'exclude',
            labelProps: { leadingIconProps: { name: 'exclude' } },
            onClick: async () => {
                let exclusion: VulnerabilityExclusion | undefined = undefined;
                if (cve.isExcluded) {
                    const { data } = await vulnerabilityService.getVulnerabilityExclusions();
                    exclusion = data.find((exclusion) => exclusion.id === cve.exclusion?.id);
                    if (!exclusion) {
                        getNotificationsService().addNotification({
                            type: NotificationType.ERROR,
                            title: 'Cannot find exclusion',
                        });
                        return;
                    }
                }
                showDrawer(VULNERABILITY_EXCLUSION_DRAWER, {
                    title: i18n.t('create.createTitle', { ns: getK8sNamespace('vulnerabilityExclusion') }),
                    initialValues: cve.isExcluded ? exclusion : exclusionInitForm,
                    isEdit: cve.isExcluded,
                });
            },
        },
    ];
};

export default getActions;
