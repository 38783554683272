import React from 'react';
import { GetWorkloads } from '../workload.interface';
import WorkloadService from '../workload.service';

export interface UseWorkloadImagesPayload {
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    data?: GetWorkloads.Response;
    reloadData: () => void;
}

export type UseWorkloadsByImage = (imageId: string, digest: string) => UseWorkloadImagesPayload;

export const useWorkloadsByImage: UseWorkloadsByImage = (imageId, digest) => {
    const [{ loading, error, data }, setState] = React.useState<{
        loading: boolean;
        error: boolean;
        data?: GetWorkloads.Response;
    }>({
        loading: false,
        error: false,
    });

    const getData = async (imageId: string, digest: string) => {
        setState((current) => ({ ...current, loading: true }));
        try {
            const imagesResponse = await WorkloadService.getWorkloads({ request: { imageId, digest } });
            setState({ loading: false, error: false, data: imagesResponse.data });
        } catch (error) {
            setState({ loading: false, error: true });
        }
    };

    React.useEffect(() => {
        if (!loading && !data && !error) {
            getData(imageId, digest);
        }
    }, [imageId, digest, data, loading, error]);

    const reloadData = React.useCallback(() => getData(imageId, digest), [imageId, digest]);

    return {
        isLoading: loading && !data,
        isFetching: loading,
        isError: error,
        data,
        reloadData,
    };
};
