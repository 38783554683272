import React, { useEffect, useState } from 'react';
import {
    IProtectedAssetDetailPanelDescriptor,
    IProtectedAssetDetailPanelProvider,
} from 'common/module_interface/assets/ProtectedAssets';

import { useSelector } from 'react-redux';
import { getSelectedEntity } from './AssetDetails.reducer';
import { useLocation } from 'react-router-dom';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { changeUrl } from 'common/utils/http';
import { ProtectedAssetsPageRegistry } from 'common/module_interface/assets/ProtectedAssetsPageRegistry';

async function isRelevant(
    provider: IProtectedAssetDetailPanelProvider,
    entityData: ICloudEntityData,
): Promise<boolean> {
    if (typeof provider.isRelevant === 'boolean') {
        return await provider.isRelevant;
    }
    if (Array.isArray(provider.isRelevant)) {
        return await provider.isRelevant.includes(entityData.typeByPlatform);
    }
    return await provider.isRelevant(entityData);
}

export function useAssetDetailsPanels(): IProtectedAssetDetailPanelDescriptor[] {
    const selectedEntity: ICloudEntityData | null = useSelector(getSelectedEntity);
    const [panels, setPanels] = useState<IProtectedAssetDetailPanelDescriptor[]>([]);

    const setPanelAsync = React.useCallback(async () => {
        if (!selectedEntity) {
            setPanels([]);
            return;
        }

        const panelsData = ProtectedAssetsPageRegistry.getPanels();
        const filteredPanels: IProtectedAssetDetailPanelDescriptor[] = [];
        for (let i = 0; i < panelsData.length; i++) {
            const isRelevantResults = await isRelevant(panelsData[i], selectedEntity);
            if (isRelevantResults) {
                filteredPanels.push(panelsData[i].getValue(selectedEntity));
            }
        }
        setPanels(filteredPanels);
    }, [selectedEntity]);

    useEffect(() => {
        setPanelAsync();
    }, [setPanelAsync]);
    return panels;
}

export function useAssetDetailsSelectedPanel() {
    const [selectedPanel, setSelectedPanel] = useState<IProtectedAssetDetailPanelDescriptor | null>(null);
    const location = useLocation();
    const panels = useAssetDetailsPanels();

    useEffect(() => {
        const querystring = new URLSearchParams(location.search);
        const currentPanelName = querystring.get('tabName');
        if (currentPanelName === null && panels.length > 0) {
            querystring.set('tabName', panels[0].name);
            changeUrl(location.pathname + `?${querystring.toString()}`, true);
        }
    }, [location, panels]);

    useEffect(() => {
        const querystring = new URLSearchParams(location.search);
        const currentPanelName = querystring.get('tabName');
        if (currentPanelName === null) {
            setSelectedPanel(null);
            return;
        }
        const selectedPanel = panels.find((panel) => {
            return currentPanelName === panel.name;
        });
        if (selectedPanel) {
            setSelectedPanel(selectedPanel);
        } else {
            setSelectedPanel(null);
        }
    }, [panels, location]);
    return selectedPanel;
}
