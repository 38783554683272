import { addSection } from 'common/helpers/menu';
import { isNotChina } from 'common/utils/dataCenter';
import {
    EVENTS_URL,
    EVENTS_LEGACY_URL,
    EVENTS_URL_THREAT_AND_SECURITY,
    EVENTS_URL_POSTURE_FINDINGS,
    EVENTS_URL_CDR,
    EVENTS_TEMP_URL,
} from 'common/module_interface/events/EventsConsts';
import { getAppRootRegistry, getUserService } from 'common/interface/services';
import { lazy } from 'react';

function initializePage() {
    const eventsTableRoute = () => {
        return {
            component: lazy(() => import('./EventsTable')),
            path: [EVENTS_URL],
            exact: true,
            condition: true,
        };
    };
    const eventsTableRedirectRoute = () => {
        return {
            component: lazy(() => import('./components/RedirectToNewEventsTable')),
            path: [EVENTS_URL_THREAT_AND_SECURITY, EVENTS_URL_POSTURE_FINDINGS, EVENTS_URL_CDR, EVENTS_TEMP_URL],
            exact: true,
            condition: true,
        };
    };

    getAppRootRegistry().addRoutes([
        { id: 'Events Table Page', content: eventsTableRoute },
        { id: 'Events Table Page - Redirect route', content: eventsTableRedirectRoute },
    ]);
}

function initializeMenu() {
    addSection('events', {
        id: 'default',
        position: 10,
        items: [
            {
                id: 'all-event',
                position: 0,
                label: 'NAVIGATION_MENU.EVENTS.All_EVENTS',
                state: `${EVENTS_URL}?goToLastVisited=true`,
                urlAliases: [EVENTS_URL],
                permission: () => isNotChina(),
            },
            {
                id: 'all-events-old-view',
                position: 5,
                label: 'NAVIGATION_MENU.EVENTS.All_EVENTS',
                chip: { label: 'Old' },
                state: EVENTS_LEGACY_URL,
                permission: () => isNotChina() && getUserService().hasPermission(['events-old-view']),
            },
        ],
    });
}

export const initializeEventsTablePageAndMenu = () => {
    initializePage();
    initializeMenu();
};
