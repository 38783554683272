import React, { useCallback, useMemo } from 'react';
import FindingsTableWrapper from '../FindingsTable/FindingsTableWrapper';
import { OriginType } from 'common/module_interface/intelligence/Intelligence.const';
import { IActionUsageDef, IColumnUsageDef } from 'common/interface/general';
import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { getCdrEventsTableColumns } from './CdrEventsPage.columns';
import { getCdrEventsTableFilters } from './CdrEventsPage.filters';
import { getCdrEventsTableActions } from './CdrEventsPage.actions';
import { CDR_EVENTS_TABLE } from './CdrEventsPage.const';
import { ModalType } from '../Findings.const';
import { EventsTableTabComponentProps } from 'common/interface/events';
import { Stack } from 'common/design-system/components-v2';
import { IAdditionalFilterFieldInfo } from 'common/components/KustoEvents/KustoEvent.interface';
import { getIsArchivedFilterInfo, getOriginsFilterInfo } from '../Findings.utils';
import { convertElasticFiltersToKusto } from './CdrEvents.utils';

const CdrEventsPage: React.FC<EventsTableTabComponentProps> = ({
    archiveView,
    defaultFilters,
    hiddenFilters,
    hiddenColumns,
}) => {
    const columns: IColumnUsageDef[] = useMemo(() => {
        return getCdrEventsTableColumns(hiddenColumns);
    }, [hiddenColumns]);
    const filters: IProtectedAssetFilter[] = useMemo(() => {
        return getCdrEventsTableFilters(hiddenFilters);
    }, [hiddenFilters]);
    const actionsCreator = useCallback(
        (openDialog: (dialogType: ModalType | null) => void): IActionUsageDef[] => {
            return getCdrEventsTableActions(openDialog, archiveView);
        },
        [archiveView],
    );

    const additionalFilterFieldInfo: IAdditionalFilterFieldInfo[] = useMemo(() => {
        return [
            getOriginsFilterInfo([OriginType.Intelligence]),
            getIsArchivedFilterInfo(archiveView),
            ...convertElasticFiltersToKusto(defaultFilters),
        ];
    }, [archiveView, defaultFilters]);

    return (
        <Stack fullWidth fullHeight>
            <FindingsTableWrapper
                tableId={CDR_EVENTS_TABLE}
                filters={filters}
                columns={columns}
                actionsCreator={actionsCreator}
                isArchiveView={archiveView}
                additionalFilterFieldInfo={additionalFilterFieldInfo}
            />
        </Stack>
    );
};

export default CdrEventsPage;
