import { OutputTypeSelectorWrapper } from './OutputTypeSelectorComponent.styled';
import { FC, useCallback, useEffect, useState } from 'react';
import { RadioButton, Stack, Typography } from 'common/design-system/components-v2';
import { OutputTypes } from './interfaces';
import { useTranslation } from 'react-i18next';
import { I18nNotifications } from '../../NotificationsPage.consts';
import { ConvertDTOToOutputType, ConvertOutputTypeToDTO } from '../../Services/NotificationPageService';

export interface OutputTypeSelectorProps {
    groupName: string;
    selectedOutputType: OutputTypes | string;
    onTypeSelect: (outputType: string | undefined) => void;
}
export const OutputTypeSelectorComponent: FC<OutputTypeSelectorProps> = ({
    groupName,
    selectedOutputType,
    onTypeSelect,
}) => {
    const { t } = useTranslation(I18nNotifications);
    const [selectedType, setSelectedType] = useState(ConvertDTOToOutputType(selectedOutputType));
    const onTypeSelectHandler = useCallback(
        (outputType: OutputTypes) => {
            setSelectedType(outputType);
            onTypeSelect(ConvertOutputTypeToDTO(outputType));
        },
        [onTypeSelect],
    );

    useEffect(() => {
        if (!selectedType) {
            onTypeSelectHandler(OutputTypes.JSON_FULL_ENTITY);
        }
    }, [onTypeSelectHandler, selectedType]);

    const outputTypeItems: OutputTypes[] = [
        OutputTypes.JSON_FULL_ENTITY,
        OutputTypes.JSON_BASIC_ENTITY,
        OutputTypes.PLAIN_TEXT,
    ];
    return (
        <OutputTypeSelectorWrapper spacing={1}>
            <Typography>{t('MODALS.ADD_EDIT_MODAL.OUTPUT_TYPES_HEADER')}</Typography>
            <Stack direction={'row'} spacing={4} alignItems={'center'}>
                {outputTypeItems.map((outputType: OutputTypes) => (
                    <RadioButton
                        name={groupName}
                        checked={outputType === selectedType}
                        label={t(`MODALS.ADD_EDIT_MODAL.OUTPUT_TYPES.${outputType}`)}
                        onChange={() => onTypeSelectHandler(outputType)}
                        key={outputType}
                        //@ts-ignore
                        value={outputType.toString()}
                    ></RadioButton>
                ))}
            </Stack>
        </OutputTypeSelectorWrapper>
    );
};
