import React, { useEffect, useState } from 'react';
import {
    EmailStyled,
    EmailNameAndSearchWrapper,
    EmailBoxWrapper,
    EmailBoxFooterWrapper,
    EmailErrorWrapper,
    EmailAddWrapper,
} from './Email.styled';
import { Typography, Input, Icon, Stack, Chip } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { I18nIntegrations, SUBMIT_STATUS_RESPONSE } from 'common/module_interface/settings/integrations/consts';
import { IConfigurationContainerProps } from 'common/module_interface/settings/integrations/configurations.interface';
import GenericCancelSubmitButtonWrapper from 'common/components/SubmitButtonWrapper/SubmitButtonWrapper';
import { isEmailValid } from 'common/utils/http';
import { IConfiguration } from 'common/module_interface/settings/integrations/Integrations';

interface IGenericConfigurationProps extends IConfigurationContainerProps {
    configuration?: IGenericConfiguration;
}

export interface IGenericConfiguration extends IConfiguration {
    configurationObj: IEmailConfigurationObj;
}

export interface IEmailConfigurationObj {
    Recipients: string[];
}

const EmailComponent: React.FC<IGenericConfigurationProps> = ({
    onConfigurationChangeCallBack,
    configuration,
    onConfigurationSaved,
    viewMode,
}) => {
    const { t } = useTranslation(I18nIntegrations);

    const [nameInputConfig, setNameInputConfig] = useState<string>(configuration?.name || '');
    const [idConfiguration, setIdConfiguration] = useState<string>(configuration?.id || '');
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [searchInputConfig, SetSearchInputConfig] = useState<string>('');
    const [emailsConfig, setEmailsConfig] = useState<string[]>(configuration?.configurationObj.Recipients || []);
    const [addEmailInput, setAddEmailInput] = useState<string>('');
    const [emailCount, setEmailCount] = useState<number>(emailsConfig.length);
    const [maxEmailError, setMaxEmailError] = useState<boolean>(false);
    const [validEmailError, setValidEmailError] = useState<boolean>(false);
    const [responseMessageSuccess, setResponseMessageSuccess] = useState<boolean>(true);

    const discardChangesButtonHidden = Boolean(idConfiguration);
    const viewOnly = Boolean(viewMode);
    const emailMaxCount = 49;

    useEffect(() => {
        checkChangesMade();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameInputConfig, idConfiguration, emailsConfig]);

    const checkMissingRequiredInput = () => {
        const variableNotChanged = nameInputConfig === '';
        setIsButtonDisabled(variableNotChanged);
    };

    const checkChangesMade = () => {
        const changesDetected =
            nameInputConfig !== configuration?.name || emailsConfig !== configuration?.configurationObj.Recipients;
        setIsButtonDisabled(!changesDetected);
        checkMissingRequiredInput();
        checkEmailCountChange();
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const textInput = event.target.value;
        setNameInputConfig(textInput);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const textInput = event.target.value;
        SetSearchInputConfig(textInput);
    };

    const emailInputBlurEventHandler = () => {
        if (addEmailInput == '') {
            return;
        }
        if (isEmailValid(addEmailInput)) {
            setEmailsConfig([...emailsConfig, addEmailInput]);
            setAddEmailInput('');
            setEmailCount(emailCount + 1);
        } else {
            setValidEmailError(true);
        }
    };
    const handleRemoveEmailChange = (email: string) => {
        setEmailsConfig(emailsConfig.filter((emailItem) => emailItem !== email));
        setEmailCount(emailCount - 1);
    };

    const handleAddEmailChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const keyInput = event.key;
        const target = event.target as HTMLInputElement;
        if (keyInput === 'Enter' && addEmailInput !== '') {
            if (isEmailValid(target.value)) {
                setEmailsConfig([...emailsConfig, target.value]);
                setAddEmailInput('');
                setEmailCount(emailCount + 1);
            } else {
                setValidEmailError(true);
            }
        } else {
            setAddEmailInput(target.value);
            validEmailError && setValidEmailError(!isEmailValid(target.value));
        }
    };

    const checkEmailCountChange = () => {
        emailCount > emailMaxCount ? setMaxEmailError(true) : setMaxEmailError(false);
    };

    const handleDiscardChanges = () => {
        setNameInputConfig(configuration?.name || '');
        setEmailsConfig(configuration?.configurationObj.Recipients || []);
        SetSearchInputConfig('');
        setEmailCount(emailsConfig.length);
    };

    const submitForm = async (): Promise<{ Status: SUBMIT_STATUS_RESPONSE; Message?: string }> => {
        try {
            const resp =
                onConfigurationSaved &&
                (await onConfigurationSaved(nameInputConfig, { Recipients: emailsConfig }, idConfiguration));
            resp?.id && setIdConfiguration(resp.id);
            setResponseMessageSuccess(true);
            onConfigurationChangeCallBack && onConfigurationChangeCallBack();
            setIsButtonDisabled(true);
            return { Status: SUBMIT_STATUS_RESPONSE.SUCCESS };
        } catch (error: any) {
            setResponseMessageSuccess(false);
            return { Status: SUBMIT_STATUS_RESPONSE.FAIL_SAVE, Message: error.message || error.title };
        }
    };

    const emailInputUpdated = (e: any) => {
        setAddEmailInput(e.target.value);
        setValidEmailError(!isEmailValid(e.target.value));
    };
    const getAdornmentIcon = () => {
        if (addEmailInput === '') {
            return null;
        }
        return (
            <>
                {validEmailError ? (
                    <Icon data-aid={'validation-error-icon'} name={'remove'} customColor={'red'} />
                ) : (
                    <Icon color={'success'} name={'check'} data-aid={'validation-success-icon'} />
                )}
            </>
        );
    };
    return (
        <EmailStyled data-aid={'email-component'}>
            <EmailNameAndSearchWrapper>
                <Typography variant='subtitleLg'>{t('GENERAL.NAME')}</Typography>
                <Input
                    type='text'
                    data-aid='nameInput'
                    value={nameInputConfig}
                    placeholder={t('GENERAL.TYPE_HERE')}
                    onChange={handleNameChange}
                    autoFocus={true}
                    disabled={viewOnly}
                />
            </EmailNameAndSearchWrapper>
            <EmailNameAndSearchWrapper>
                <Typography variant='subtitleLg'>{t('CONFIGURATIONS.EMAIL.EMAILS')}</Typography>
                <Input
                    type='text'
                    value={searchInputConfig}
                    placeholder={t('CONFIGURATIONS.EMAIL.SEARCH')}
                    onChange={handleSearchChange}
                    startAdornment={<Icon name={'magnify'}></Icon>}
                    disabled={viewOnly}
                />
            </EmailNameAndSearchWrapper>
            <EmailBoxWrapper>
                {emailsConfig
                    .filter((email) => email.includes(searchInputConfig))
                    .map((email, index) => {
                        return (
                            <Stack padding={[0.5]} key={index}>
                                <Chip
                                    key={index}
                                    disabled={viewOnly}
                                    closeButton={{
                                        onClick: () => handleRemoveEmailChange(email),
                                    }}
                                    size='lg'
                                    label={email}
                                />
                            </Stack>
                        );
                    })}
                <EmailAddWrapper>
                    <Input
                        fullWidth={true}
                        onBlur={emailInputBlurEventHandler}
                        type='text'
                        data-aid='urlInput'
                        placeholder={t('CONFIGURATIONS.EMAIL.ADD_EMAIL')}
                        onChange={emailInputUpdated}
                        onKeyDown={(e) => handleAddEmailChange(e)}
                        disabled={viewOnly}
                        value={addEmailInput}
                        endAdornment={getAdornmentIcon()}
                    />
                </EmailAddWrapper>
            </EmailBoxWrapper>

            <EmailBoxFooterWrapper justifyContent={maxEmailError ? 'space-between' : 'flex-end'}>
                {maxEmailError && (
                    <EmailErrorWrapper>
                        <Stack padding={[0, 2, 0, 0]}>
                            <Icon name={'error'}></Icon>
                        </Stack>
                        <Typography elementType='h5' color={'alert'}>
                            {t('CONFIGURATIONS.EMAIL.YOU_HAVE_MORE_THAN_49_EMAILS')}
                        </Typography>
                    </EmailErrorWrapper>
                )}
                <Typography elementType='h5' color={maxEmailError ? 'alert' : 'inherit'}>
                    {emailCount}/{emailMaxCount}
                </Typography>
            </EmailBoxFooterWrapper>
            {addEmailInput && validEmailError && (
                <EmailBoxFooterWrapper>
                    <Typography data-aid={'validation-error-message'} elementType='h5' color={'alert'}>
                        {t('CONFIGURATIONS.EMAIL.THE_RECIPIENTS_FIELD_IS_NOT_A_VALID_EMAIL_ADDRESS')}
                    </Typography>
                </EmailBoxFooterWrapper>
            )}

            {GenericCancelSubmitButtonWrapper({
                handleDiscardChanges,
                discardChangesButtonHidden,
                isButtonDisabled,
                submitForm,
                viewOnly,
                responseMessageSuccess,
            })}
        </EmailStyled>
    );
};

export default EmailComponent;
