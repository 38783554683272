import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { I18nIntegrations, SentinelInputType } from 'common/module_interface/settings/integrations/consts';
import { Alert, Stack, Typography } from 'common/design-system/components-v2';
import DATA_CENTERS, { IDataCenter } from 'common/consts/data.centers';
import { ServiceAccountModal } from '../../../../ServiceAccounts/ServiceAccountsPage/ServiceAccountModal/ServiceAccountModal';
import { SentinelPrerequisites } from './SentinelPrerequisites';
import { ProvideServiceAccount } from './StepComponents/ProvideServiceAccount';
import { ProvideDataCenter } from './StepComponents/ProvideDataCenter';
import { ProvideFilterParameters } from './StepComponents/ProvideFilterParameters';
import { ProvideFilterConfiguration } from './StepComponents/ProvideFilterConfiguration';
import { stepOrderManagerProps } from 'common/module_interface/settings/integrations/Integrations';
import { StepNumber } from './Sentinel.styled';
import { colors } from 'common/design-system/theme/colors';

const SentinelComponent: React.FC = () => {
    const { t } = useTranslation(I18nIntegrations);
    const [dataCenterUrl, setDataCenterUrl] = useState<string>('');
    const [textAreaValue, setTextAreaValue] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [prerequisitesISOpen, setPrerequisitesISOpen] = useState<boolean>(false);

    const handleCopyDataCenter = () => {
        navigator.clipboard.writeText(dataCenterUrl);
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const togglePrerequisites = () => {
        setPrerequisitesISOpen(!prerequisitesISOpen);
    };

    const onFilterValueChange = (filtersValues: SentinelInputType) => {
        const formatNonEmptyKeys = (input: SentinelInputType) => {
            const nonEmptyEntries = Object.entries(input)
                .filter(
                    ([, value]) => value !== '' && value !== null && (Array.isArray(value) ? value.length > 0 : true),
                )
                .flatMap(([key, value]) =>
                    Array.isArray(value) ? value.map((item) => ({ name: key, value: item })) : [{ name: key, value }],
                );

            return nonEmptyEntries.map((entry) => JSON.stringify(entry)).join(',\n');
        };
        setTextAreaValue(formatNonEmptyKeys(filtersValues));
    };

    const stepOrderManagerProps = {
        toggleModal,
        handleCopyDataCenter,
        onFilterValueChange,
        textAreaValue,
        dataCenterUrl,
    };
    const stepOrderManager: stepOrderManagerProps[] = [
        {
            key: 'sentinel-service-account',
            Component: ProvideServiceAccount,
            title: t('CONFIGURATIONS.SENTINEL.STEP_1_TITLE'),
            subTitle: t('CONFIGURATIONS.SENTINEL.STEP_1_SUBTITLE'),
            props: stepOrderManagerProps,
        },
        {
            key: 'sentinel-data-center',
            Component: ProvideDataCenter,
            title: t('CONFIGURATIONS.SENTINEL.STEP_2_TITLE'),
            subTitle: t('CONFIGURATIONS.SENTINEL.STEP_2_SUBTITLE'),
            props: stepOrderManagerProps,
        },
        {
            key: 'sentinel-filter-params',
            Component: ProvideFilterParameters,
            title: t('CONFIGURATIONS.SENTINEL.STEP_3_TITLE'),
            subTitle: t('CONFIGURATIONS.SENTINEL.STEP_3_SUBTITLE'),
            props: stepOrderManagerProps,
        },
        {
            key: 'sentinel-filter-config',
            Component: ProvideFilterConfiguration,
            title: t('CONFIGURATIONS.SENTINEL.STEP_4_TITLE'),
            subTitle: t('CONFIGURATIONS.SENTINEL.STEP_4_SUBTITLE'),
            props: stepOrderManagerProps,
        },
    ];

    useEffect(() => {
        const hostname = window.location.hostname;
        const currentDataCenter = DATA_CENTERS.find((dataCenter: IDataCenter) => dataCenter.urls.includes(hostname));
        setDataCenterUrl(currentDataCenter?.externalApi || '');
    }, []);

    return (
        <Stack direction={'column'} data-aid={'Sentinel-component'} fullWidth>
            <Stack padding={[3]} spacing={5}>
                <SentinelPrerequisites
                    togglePrerequisites={togglePrerequisites}
                    prerequisitesISOpen={prerequisitesISOpen}
                />
                <Stack padding={[5, 0, 5, 0]}>
                    <Typography variant={'sm'} color={'strong'}>
                        {t('CONFIGURATIONS.SENTINEL.FOLLOW_THESE_STEPS')}
                    </Typography>
                </Stack>
                {stepOrderManager.map(({ key, Component, title, subTitle, props }, index) => (
                    <Stack
                        key={key}
                        data-aid={'step' + (index + 1)}
                        direction={'row'}
                        padding={[0, 0, 5, 0]}
                        spacing={4}
                        alignItems={'flex-start'}
                        fullWidth
                    >
                        <StepNumber>
                            <Typography color={'strong'} variant={'lg'}>
                                {index + 1}
                            </Typography>
                        </StepNumber>
                        <Stack spacing={3} fullWidth>
                            <Typography variant={'sm'} color={'strong'}>
                                {title}
                            </Typography>
                            <Typography variant={'sm'}>
                                <Trans
                                    i18nKey={subTitle}
                                    components={{
                                        a: (
                                            <a
                                                target={'_blank'}
                                                className={'link'}
                                                style={{ color: colors.blue['300'] }}
                                            />
                                        ),
                                    }}
                                ></Trans>
                            </Typography>
                            <Component key={key} {...props} />
                        </Stack>
                    </Stack>
                ))}
            </Stack>
            <Stack padding={[3]}>
                <Alert type={'info'}>
                    <Stack spacing={3} padding={[1, 0]}>
                        <Typography color={'strong'} variant={'sm'}>
                            {t('CONFIGURATIONS.SENTINEL.NEW_SERVICE_ACCOUNT_DETAILS.IMPORTANT')}
                        </Typography>
                        <Typography variant={'sm'}>
                            {t('CONFIGURATIONS.SENTINEL.NEW_SERVICE_ACCOUNT_DETAILS.IMPORTANT_MESSAGE')}
                        </Typography>
                    </Stack>
                </Alert>
            </Stack>
            <ServiceAccountModal
                isModalOpen={isModalOpen}
                handleOpenAction={openModal}
                handleCloseAction={closeModal}
            />
        </Stack>
    );
};

export default SentinelComponent;
