import React from 'react';
import { Card, IconButton, Input } from 'common/design-system/components-v2';
import { ICloudBotCardProps } from './CloudBotCard.types';
import { ICloudBotParameter } from 'common/module_interface/intelligence/CloudBots/CloudBots.interface';
import { CloudBotCardStyles as Styles } from './CloudBotCard.styled';

const CloudBotCard: React.FC<ICloudBotCardProps> = (params: ICloudBotCardProps) => {
    const { cloudBot, onDelete, onParameterInput } = params;
    const cloudBotParams: ICloudBotParameter[] = cloudBot.parameters
        .filter((param: ICloudBotParameter) => param.visible ?? true)
        .sort((a: ICloudBotParameter, b: ICloudBotParameter) => a.order - b.order);

    return (
        <Card
            title={cloudBot.name}
            iconProps={{ name: 'robot' }}
            rightContent={<IconButton iconProps={{ name: 'delete', size: 16 }} onClick={() => onDelete(cloudBot)} />}
        >
            {cloudBotParams.length > 0 && (
                <Styles.CloudBotParametersWrapper spacing={2} direction={'column'}>
                    {cloudBotParams.map((param: ICloudBotParameter) => (
                        <Styles.CloudBotParameter key={param.order}>
                            <Input
                                type='text'
                                label={param.name}
                                clearable={true}
                                fullWidth={true}
                                value={param.value}
                                isError={param.isError}
                                required={param.required}
                                placeholder={param.placeholder}
                                disabled={param.disabled}
                                helperText={param.isError ? param.errorText : param.helperText}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    onParameterInput(event, cloudBot, param.order)
                                }
                            />
                        </Styles.CloudBotParameter>
                    ))}
                </Styles.CloudBotParametersWrapper>
            )}
        </Card>
    );
};

export default CloudBotCard;
