import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import {
    ENVIRONMENT_POLICY_RULESETS,
    IShallowRuleset,
    IVendorRuleset,
    OU_POLICY_RULESETS,
    POLICY_ENVIRONMENT_VENDORS,
    POLICY_RULESETS,
    POLICY_VENDORS,
    Vendor,
} from 'common/interface/policy';
import { Vendors } from 'common/consts/vendors';
import { getPolicyRulesets } from 'common/components/policy/utils';
import { getAppRootRegistry, getUserService } from 'common/interface/services';
import { lazy } from 'react';

export const AF_NEW_INTEGRATIONS = 'new-integrations';

const vendors: Vendor[] = [
    {
        id: Vendors.KUBERNETES,
        name: Vendors.KUBERNETES,
        icon: 'kubernetes',
        title: 'Kubernetes',
    },
    {
        id: Vendors.CONTAINER_REGISTRY,
        name: Vendors.CONTAINER_REGISTRY,
        icon: 'containers-registry',
        title: 'Container Registry',
    },
    {
        id: Vendors.KUBERNETES_RUNTIME_ASSURANCE,
        name: Vendors.KUBERNETES_RUNTIME_ASSURANCE,
        icon: 'containers-registry',
        title: 'Kubernetes Runtime Assurance',
    },
];

export const getRulesets = async (vendor?: Vendors) => {
    const shallowRulesets = await getPolicyRulesets();
    if (vendor) {
        return shallowRulesets.filter((ruleset: IShallowRuleset) => ruleset.platform === vendor);
    } else {
        return shallowRulesets;
    }
};

const getVendorsRulesets = () => {
    const getContainerRegistryRulesets = async () => {
        const shallowRulesets = await getRulesets();
        return shallowRulesets.filter((ruleset: IShallowRuleset) => ruleset.platform === Vendors.IMAGE_ASSURANCE);
    };

    const getKubernetesRulesets = async () => {
        const shallowRulesets = await getRulesets();
        return shallowRulesets.filter(
            (ruleset: IShallowRuleset) =>
                ruleset.platform === Vendors.KUBERNETES || ruleset.platform === Vendors.IMAGE_ASSURANCE,
        );
    };
    const getKubernetesRuntimeAssuranceRulesets = async () => {
        const shallowRulesets = await getRulesets();
        return shallowRulesets.filter(
            (ruleset: IShallowRuleset) =>
                ruleset.platform === Vendors.KUBERNETES ||
                ruleset.platform === Vendors.KUBERNETES_RUNTIME_ASSURANCE ||
                ruleset.platform === Vendors.IMAGE_ASSURANCE,
        );
    };

    const vendorsRulesetsList: IVendorRuleset[] = [];
    for (const vendor of vendors) {
        switch (vendor.id) {
            case Vendors.CONTAINER_REGISTRY:
                vendorsRulesetsList.push({ vendorId: vendor.id, getRulesets: () => getContainerRegistryRulesets() });
                break;
            case Vendors.KUBERNETES:
                vendorsRulesetsList.push({ vendorId: vendor.id, getRulesets: () => getKubernetesRulesets() });
                break;
            case Vendors.KUBERNETES_RUNTIME_ASSURANCE:
                vendorsRulesetsList.push({
                    vendorId: vendor.id,
                    getRulesets: () => getKubernetesRuntimeAssuranceRulesets(),
                });
                break;
            default:
                return [];
        }
    }
    return vendorsRulesetsList;
};

const vendorRulesetsList = getVendorsRulesets();

export const initializePolicies = () => {
    const getMappedRulesetItems = (rulesetsList: IVendorRuleset[]) => {
        return [
            ...rulesetsList.map((item: IVendorRuleset) => ({
                id: item.vendorId,
                content: {
                    getRulesets: item.getRulesets,
                    vendorId: item.vendorId,
                },
            })),
        ];
    };

    const addVulnerabilityPolicyEnvsPageRoute = () => {
        return {
            condition: getUserService().hasPermission([AF_NEW_INTEGRATIONS]),
            component: lazy(() => import('./vulnerabilities/Environment/AddEnvsPolicyPage')),
            path: '/workload/policy/environments/add',
        };
    };

    const addVulnerabilityPolicyOUPageRoute = () => {
        return {
            condition: getUserService().hasPermission([AF_NEW_INTEGRATIONS]),
            component: lazy(() => import('./vulnerabilities/OU/AddOUPolicyPage')),
            path: '/workload/policy/ou/add',
        };
    };

    const editVulnerabilityPolicyPageRoute = () => {
        return {
            condition: getUserService().hasPermission([AF_NEW_INTEGRATIONS]),
            component: lazy(() => import('./vulnerabilities/EditVulnerabilitiesPolicyPage')),
            path: '/workload/policy/edit',
        };
    };

    const addAdmissionPolicyEnvsPageRoute = () => {
        return {
            condition: getUserService().hasPermission([AF_NEW_INTEGRATIONS]),
            component: lazy(() => import('./admission/Environment/AddAdmissionEnvPolicyPage')),
            path: '/runtime-assurance/policy/environments/add',
        };
    };

    const addAdmissionPolicyOUPageRoute = () => {
        return {
            condition: getUserService().hasPermission([AF_NEW_INTEGRATIONS]),
            component: lazy(() => import('./admission/OU/AddAdmissionOUPolicyPage')),
            path: '/runtime-assurance/policy/ou/add',
        };
    };

    const editAdmissionPolicyPageRoute = () => {
        return {
            condition: getUserService().hasPermission([AF_NEW_INTEGRATIONS]),
            component: lazy(() => import('./admission/EditAdmissionPolicyPage')),
            path: '/runtime-assurance/policy/edit',
        };
    };

    getAppRootRegistry().addRoutes([
        { id: 'workload-vulnerability-policy-ou', content: addVulnerabilityPolicyOUPageRoute },
        { id: 'workload-vulnerability-policy-envs', content: addVulnerabilityPolicyEnvsPageRoute },
        { id: 'workload-vulnerability-policy-edit', content: editVulnerabilityPolicyPageRoute },
        { id: 'workload-admission-policy-ou', content: addAdmissionPolicyEnvsPageRoute },
        { id: 'workload-admission-policy-envs', content: addAdmissionPolicyOUPageRoute },
        { id: 'workload-admission-policy-edit', content: editAdmissionPolicyPageRoute },
    ]);
    globalAddinContainer.add(POLICY_ENVIRONMENT_VENDORS, [
        ...vendors.map((vendor) => ({ id: vendor.id, content: vendor })),
    ]);
    globalAddinContainer.add(POLICY_VENDORS, [...vendors.map((vendor) => ({ id: vendor.id, content: vendor }))]);
    globalAddinContainer.add(POLICY_RULESETS, getMappedRulesetItems(vendorRulesetsList));
    globalAddinContainer.add(ENVIRONMENT_POLICY_RULESETS, getMappedRulesetItems(vendorRulesetsList));
    globalAddinContainer.add(OU_POLICY_RULESETS, getMappedRulesetItems(vendorRulesetsList));
};
