import i18next from 'i18next';
import { i18nPostureNamespace } from '../initialize.i18n';
import { getService } from 'common/extensibility/AddinContainer';
import { Rule } from 'common/interface/RulesManagement';
import { getCloudAccountsService, getOrganizationalUnitService, ICloudAccount } from 'common/interface/data_services';
import { RULES_MANAGEMENT_SERVICE_ID } from 'common/interface/services';
import { DashboardWidgetTypes, IDashboardWidget } from 'common/module_interface/overview/Interface';
import React, { useCallback, useEffect, useState } from 'react';
import { COMPLIANCE_TYPES, ISelectOption, widgetsTypeFieldSetsCount, LegendTypeEnum } from '../Const/const';
import RuleManageService from '../RulesManagement/services/RuleManagement.service';
import { ComplianceSelectionsSet } from './ComplianceSelectionsSet';
import { flattenTree } from 'common/utils/helpFunctions';
import { deepCloneObject } from 'common/utils/objectUtils';
import { useTranslation } from 'react-i18next';
import { ComplianceGaugeWidgetSettings } from './ComplianceGaugeWidgetSettings';

export interface Ifield {
    title: string;
    options: ISelectOption[];
    checkSelected: (index: number, optionValue: any) => boolean | undefined;
    onChange: Function;
    displayCondition?: boolean;
    id?: number;
}

let cachedResponses: any = null;

export const ComplianceWidgetsSettings: React.FC<{ widget: IDashboardWidget; updateWidgetCallback: Function }> = ({
    widget,
    updateWidgetCallback,
}) => {
    const { t } = useTranslation();
    const [widgetSelections, setWidgetSelection] = useState([] as Ifield[][]);
    const [isLoading, setIsLoading] = useState(true);
    const optionsCompliance = widget?.options?.compliance;
    const getComplianceSelectOptions = useCallback(async () => {
        const handleChangeComplianceSelections = (setIndex: number, type: string, selectedOption: ISelectOption) => {
            if (optionsCompliance) {
                const widgetClone = deepCloneObject(widget);
                const _optionsCompliance = widgetClone.options.compliance;
                switch (type) {
                    case 'type':
                        _optionsCompliance[setIndex].type = selectedOption.value;
                        _optionsCompliance[setIndex].selected = '';
                        _optionsCompliance[setIndex].rulesetId = 0;
                        break;
                    case 'ou':
                        _optionsCompliance[setIndex].selected = selectedOption.value;
                        _optionsCompliance[setIndex].rulesetId = 0;
                        break;
                    case 'ruleset':
                        _optionsCompliance[setIndex].rulesetId = selectedOption.value;
                        _optionsCompliance[setIndex].platform = selectedOption.additionalData?.cloudVendor;
                        break;
                    case 'legendType':
                        _optionsCompliance[setIndex].legendType = selectedOption.value;
                        break;
                }
                widgetClone.options.compliance = _optionsCompliance;
                updateWidgetCallback(widgetClone);
            }
        };

        const getOptions = (itemsFromServer: Rule[] | ICloudAccount[]): ISelectOption[] => {
            const itemsOptions = [] as ISelectOption[];
            for (const item of itemsFromServer) {
                itemsOptions.push({ value: item.id, label: item.name!, additionalData: item });
            }
            return itemsOptions;
        };

        const getData = async () => {
            if (cachedResponses) {
                return cachedResponses;
            } else {
                const rulesetsPromise =
                    getService<RuleManageService>(RULES_MANAGEMENT_SERVICE_ID).getRulesetsViewsFromComplianceServer();
                const organizationalUnitsPromise = getOrganizationalUnitService().getAllOrganizationalUnits();
                const EnvironmentsPromise = getCloudAccountsService().getAllCloudAccounts();
                cachedResponses = Promise.all([organizationalUnitsPromise, rulesetsPromise, EnvironmentsPromise]).then(
                    ([organizationalUnits, rulesets, environments]) => {
                        return {
                            organizationalUnits,
                            rulesets,
                            environments,
                        };
                    },
                );
                return cachedResponses;
            }
        };

        const data = await getData();
        const _organizationalUnitsTree = data.organizationalUnits;
        const treeWithManyParents: any = [];
        let shouldUseTreeWithManyParents = false;

        if (_organizationalUnitsTree[1]) {
            treeWithManyParents.push({
                item: { id: 0, name: 'Root' },
                children: _organizationalUnitsTree,
            });
            shouldUseTreeWithManyParents = true;
        }
        const _flatTree = flattenTree(
            shouldUseTreeWithManyParents ? treeWithManyParents[0] : _organizationalUnitsTree[0],
        );
        const _rootOu =
            _organizationalUnitsTree && _organizationalUnitsTree.length > 0 ? _organizationalUnitsTree[0] : null;
        const organizationalUnitsOptions = _flatTree?.map((ou) => {
            return {
                label: ou.name,
                value: ou.id,
            };
        });
        !shouldUseTreeWithManyParents &&
            _rootOu &&
            organizationalUnitsOptions.unshift({ label: _rootOu.item.name, value: _rootOu.item.id });
        const rulsetsOptions = getOptions(data.rulesets);
        const environmentsOptions = getOptions(data.environments);
        const legendTypeOptions = Object.entries(LegendTypeEnum).map(([key, value]) => ({
            label: t(`POSTURE_MANAGEMENT.WIDGETS.OPTIONS.${key}`),
            value: value,
        }));
        const emptyComplianceObj = {
            type: '',
            selected: '',
            rulesetId: 0,
            legendType: '',
        };

        const widgetSelections = [] as Ifield[][];
        const complianceTypeOptions = [
            { label: '----', value: null },
            {
                label: i18next.t('COMMON.ORGANIZATIONAL_UNIT', { ns: i18nPostureNamespace }),
                value: COMPLIANCE_TYPES.OU,
            },
            { label: i18next.t('COMMON.ENVIRONMENT', { ns: i18nPostureNamespace }), value: COMPLIANCE_TYPES.ENV },
        ];

        if (optionsCompliance?.length) {
            optionsCompliance?.forEach((selection, index) => {
                widgetSelections.push([
                    {
                        title: t('POSTURE_MANAGEMENT.WIDGETS.SELECT_TITLES.COMPLIANCE_TYPE'),
                        displayCondition: true,
                        options: complianceTypeOptions,
                        checkSelected: (setIndex: number, optionValue: any) => {
                            return selection?.type?.toLowerCase() === optionValue;
                        },
                        onChange: (setIndex: number, value: any) =>
                            handleChangeComplianceSelections(setIndex, 'type', value),
                        id: index,
                    },
                    {
                        title: t('POSTURE_MANAGEMENT.WIDGETS.SELECT_TITLES.OU_CA'),
                        displayCondition: !!selection.type,
                        options:
                            selection?.type?.toLowerCase() === COMPLIANCE_TYPES.OU
                                ? organizationalUnitsOptions
                                : environmentsOptions,
                        checkSelected: (setIndex: number, optionValue: any) => {
                            return selection.selected === optionValue;
                        },
                        onChange: (setIndex: number, value: any) =>
                            handleChangeComplianceSelections(setIndex, 'ou', value),
                        id: index,
                    },
                    {
                        title: t('POSTURE_MANAGEMENT.WIDGETS.SELECT_TITLES.RULESET'),
                        displayCondition: !!selection.type,
                        options: rulsetsOptions,
                        checkSelected: (setIndex: number, optionValue: any) => {
                            return selection.rulesetId === optionValue;
                        },
                        onChange: (setIndex: number, value: any) =>
                            handleChangeComplianceSelections(setIndex, 'ruleset', value),
                        id: index,
                    },
                    {
                        title: t('POSTURE_MANAGEMENT.WIDGETS.SELECT_TITLES.DATA_BREAKDOWN'),
                        displayCondition: !!selection.type,
                        options: legendTypeOptions,
                        checkSelected: (setIndex: number, optionValue: any) => {
                            return selection.legendType === optionValue;
                        },
                        onChange: (setIndex: number, value: any) =>
                            handleChangeComplianceSelections(setIndex, 'legendType', value),
                        id: index,
                    },
                ]);
            });
            if (widgetSelections.length < widgetsTypeFieldSetsCount[widget.type]) {
                const newComplianceObj = Array(widgetsTypeFieldSetsCount[widget.type] - widgetSelections.length).fill(
                    emptyComplianceObj,
                );
                const widgetClone = deepCloneObject(widget);
                widgetClone.options.compliance = [...widgetClone.options.compliance, ...newComplianceObj];
                updateWidgetCallback(widgetClone);
            } else if (widgetSelections.length > widgetsTypeFieldSetsCount[widget.type]) {
                const widgetClone = deepCloneObject(widget);
                widgetClone.options.compliance = widgetClone.options.compliance.slice(
                    0,
                    widgetsTypeFieldSetsCount[widget.type],
                );
                updateWidgetCallback(widgetClone);
            }
        } else {
            const newComplianceObj = Array(widgetsTypeFieldSetsCount[widget.type]).fill(emptyComplianceObj);
            const widgetClone = deepCloneObject(widget);
            widgetClone.options.compliance = newComplianceObj;
            updateWidgetCallback(widgetClone);
        }
        setWidgetSelection(widgetSelections);
        setIsLoading(false);
    }, [widget, optionsCompliance, updateWidgetCallback, t]);

    useEffect(() => {
        getComplianceSelectOptions();
    }, [getComplianceSelectOptions]);

    const clearSet = (index: number) => {
        const widgetClone = deepCloneObject(widget);
        const _optionsCompliance = widgetClone.options.compliance;
        _optionsCompliance[index].type = '';
        _optionsCompliance[index].selected = '';
        _optionsCompliance[index].rulesetId = 0;
        _optionsCompliance[index].legendType = null;
        widgetClone.options.compliance = _optionsCompliance;
        updateWidgetCallback(widgetClone);
    };

    return (
        <div className='divide-y'>
            {isLoading ? (
                <div>{t('GENERAL.LOADING')}</div>
            ) : (
                widgetSelections?.map((set, index) => {
                    return (
                        <>
                            {DashboardWidgetTypes.Gauge === widget.type && (
                                <ComplianceGaugeWidgetSettings
                                    widget={widget}
                                    updateWidgetCallback={updateWidgetCallback}
                                />
                            )}
                            <ComplianceSelectionsSet
                                key={index}
                                set={set}
                                index={index}
                                onClear={() => clearSet(index)}
                            />
                        </>
                    );
                })
            )}
        </div>
    );
};
