import { DrawerRegistry } from 'common/components/DrawerInfra/Drawer/DrawerRegistry';
import { EventDetailsDrawer } from 'modules/events/EventDrawer/EventDetailsDrawer';
import { EVENT_DETAILS_DRAWER_KEY } from 'common/module_interface/events/EventsConsts';
import { IEventDetailsDrawerParams } from 'common/module_interface/events/Events.interface';

export const initializeEventsDrawer = () => {
    DrawerRegistry.addContentProvider({
        id: EVENT_DETAILS_DRAWER_KEY.key,
        options: {
            disableSpacing: true,
            width: 'xxl',
        },
        getDrawerContent: async (drawerParams: IEventDetailsDrawerParams) => {
            return {
                component: EventDetailsDrawer,
                componentProps: { drawerParams },
            };
        },
    });
};
