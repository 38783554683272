import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import FindingGraphWrapper from '../FindingGraphWrapper';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
export const VisualizationGraphPanel: React.FC<{ event: IKustoEvent }> = ({ event }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);

    return (
        <div className={'w-full'}>
            <div className={'font-bold'}>{t('INT_FINDING_OVERVIEW.EVENTS_GRAPH')}</div>
            <FindingGraphWrapper expandedFindingDetailsProps={{ finding: event }} />
        </div>
    );
};
