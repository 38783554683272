import { toQueryString } from 'common/utils/http';
import { sendHttpRequest } from 'common/erm-components/utils/ermComponents.http';
import { CDREventInfo } from './IssueCDREvents.interface';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { IFindingGslFilter } from 'common/module_interface/intelligence/Gsl/FindingGslService.interface';
import { CompoundFilterLogicalOperator } from 'common/design-system/components-v2/FilterTree/CompoundFilter';
import { FilterConditionOperator } from 'common/design-system/components-v2/FilterTree/FilterCondition';
import { getKustoEventService } from 'common/module_interface/intelligence/intelligence';
import { CommonEventFields } from 'common/module_interface/events/EventsConsts';

export const SECURITY_EVENTS_URL = 'security-graph/issues/security-event';

export const fetchSecurityEvents = (issueId: string, assetSrl: string): Promise<CDREventInfo[]> => {
    const queryString = toQueryString({ query: { issueId: issueId, srl: assetSrl } });
    const url = `${SECURITY_EVENTS_URL}?${queryString}`;
    return sendHttpRequest<CDREventInfo[]>(url, {
        method: 'GET',
    });
};

export const fetchFindingsByKeys = async (findingsKeys: string[]): Promise<IKustoEvent[]> => {
    const gslFilter: IFindingGslFilter = {
        compoundFilter: {
            root: {
                logicalOperator: CompoundFilterLogicalOperator.AND,
                operands: [
                    {
                        name: CommonEventFields.findingKey,
                        values: findingsKeys,
                        operator: FilterConditionOperator.In,
                    },
                ],
            },
        },
    };
    return await getKustoEventService().getKustoEvents(gslFilter);
};
