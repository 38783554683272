import { IApplicationReducer } from 'common/interface/redux';
import errorReducer from './Error/ErrorPage.reducer';
import loginFormReducer from './account/Login/LoginForm.reducer';
import menuState from './main/Menus/Menus.reducer';
import breadcrumbsState from './main/Breadcrumbs/Breadcrumbs.reducer';
import switchRoleState from './main/SwitchRole/Roles/Roles.reducer';
import switchRoleComponentSlice from './user/SwitchRoleComponent.reducer';
import notificationsReducer from './main/Notifications/Notifications.reducer';
import userReducer from './user/User.reducer';
import authReducer from './auth/Auth.reducer';
import sideBarReducer from './main/SideBar/SideBar.reducer';
import { getIsCloudInfra } from 'common/utils/RuntimeEnvironment';
import {
    ACCOUNT_SERVICE_ID,
    ALERTS_SERVICE_ID,
    BREADCRUMBS_SERVICE_ID,
    COMPLIANCE_NOTIFICATION_SERVICE_ID,
    CUSTOMIZATION_SERVICE_ID,
    EVENT_TRACKING_SERVICE_ID,
    getAppRootRegistry,
    HTTP_SERVICE_API_ID,
    HTTP_SERVICE_ID,
    LOCAL_STORAGE_SERVICE_ID,
    LOGGER_SERVICE_ID,
    LogLevel,
    MENU_SERVICE_ID,
    POLICY_SERVICE_ID,
    RULESET_SERVICE_ID,
    SERVERLESS_POLICY_SERVICE_ID,
    SIDEBAR_SERVICE_ID,
    USER_SERVICE_ID,
    WEBAPP_IFRAME_SERVICE_ID,
} from 'common/interface/services';
import HttpService from './services/HttpService';
import { handleError } from './helpers/ApiErrorHandler';
import globalPersistReducer from '../Globalpersist.reducer';
import initializeMenuItems from './main/Menus/initialize';
import initializeOnboarding from './onboarding/initialize';
import { UserService } from './services/UserService';
import {
    CLOUD_ACCOUNTS_SERVICE_ID,
    ENTITY_SERVICE_ID,
    ORGANIZATIONAL_UNIT_SERVICE_ID,
} from 'common/interface/data_services';
import { OrganizationalUnitService } from './services/OrganizationalUnitService';
import { CloudAccountsService } from './services/CloudAccountsService';
import LoggerService from './services/LoggerService';
import { RulesetService } from './services/RulesetService';
import { PolicyService } from './services/PolicyService';
import { AccountService } from './services/AccountService';
import { EntityService } from './services/EntityService';
import { SideBarService } from './main/SideBar/SideBarService';
import { CustomizationService } from './services/CustomizationService';
import { MenuService } from './main/Menus/MenuService';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { BreadcrumbsService } from './services/BreadcrumbsService';
import initializeBESearch from './MainNavigationBar/GlobalSearcers/initialize';
import { setProfilerOptions } from './3rdParty/profiler/profiler';
import { isNotChina } from 'common/utils/dataCenter';
import { setBeamerOption } from './3rdParty/beamer/beamer';
import { WebappIframeService } from './services/WebappIframeService/WebappIframeService';
import { LocalStorageService } from './services/LocalStorageService';
import { ComplianceNotificationsService } from './services/ComplianceNotificationsService';
import { NotificationsService } from './services/NotificationsService';
import { POLICY_SERVICE } from 'common/interface/policy';
import { ServerlessPolicyService } from './services/ServerlessPolicyService';
import { setGoogleAnalytics } from './3rdParty/googleAnalytics/googleAnalyticsInitialize';
import { EventTracking } from './services/EventTracking';
import { setMixpanelOptions } from './3rdParty/mixpanel/mixpanel';
import { SERVICE_ACCOUNTS_SERVICE_ID } from 'common/interface/serviceAccountService';
import { ServiceAccountsService } from './services/ServiceAccountsService';
import { UsersService } from './services/UsersService';
import { RolesService } from './services/RolesService';
import { USERS_SERVICE_ID } from 'common/interface/userService';
import { ROLES_SERVICE_ID } from 'common/interface/rolesService';
import { SECURITY_GROUPS_SERVICE_ID } from 'common/interface/securityGroupsService';
import { SecurityGroupsService } from './services/SecurityGroupsService';
import { AGENTS_SERVICE_ID } from 'common/interface/agentsService';
import { AgentsService } from './services/AgentsService';
import { initializeInternalTools } from './InternalTools/SystemComponentTesting/initInternalToolsPage';

const COMPLIANCE = 'COMPLIANCE';
const SERVERLESS = 'SERVERLESS';
function initializeServices() {
    globalAddinContainer.addService(ALERTS_SERVICE_ID, new NotificationsService());
    globalAddinContainer.addService(HTTP_SERVICE_ID, new HttpService('v2', handleError));
    globalAddinContainer.addService(HTTP_SERVICE_API_ID, new HttpService('api', handleError));
    globalAddinContainer.addService(USER_SERVICE_ID, new UserService());
    globalAddinContainer.addService(ORGANIZATIONAL_UNIT_SERVICE_ID, new OrganizationalUnitService());
    globalAddinContainer.addService(CLOUD_ACCOUNTS_SERVICE_ID, new CloudAccountsService());
    globalAddinContainer.addService(LOGGER_SERVICE_ID, new LoggerService(LogLevel.Info));
    globalAddinContainer.addService(RULESET_SERVICE_ID, new RulesetService());
    globalAddinContainer.addService(POLICY_SERVICE_ID, new PolicyService());
    globalAddinContainer.addService(SERVERLESS_POLICY_SERVICE_ID, new ServerlessPolicyService());
    globalAddinContainer.addService(COMPLIANCE_NOTIFICATION_SERVICE_ID, new ComplianceNotificationsService());
    globalAddinContainer.addService(ACCOUNT_SERVICE_ID, new AccountService());
    globalAddinContainer.addService(ENTITY_SERVICE_ID, new EntityService());
    globalAddinContainer.addService(SIDEBAR_SERVICE_ID, new SideBarService());
    globalAddinContainer.addService(CUSTOMIZATION_SERVICE_ID, new CustomizationService());
    globalAddinContainer.addService(MENU_SERVICE_ID, new MenuService());
    globalAddinContainer.addService(BREADCRUMBS_SERVICE_ID, new BreadcrumbsService());
    globalAddinContainer.addService(WEBAPP_IFRAME_SERVICE_ID, new WebappIframeService());
    globalAddinContainer.addService(LOCAL_STORAGE_SERVICE_ID, new LocalStorageService());
    globalAddinContainer.add(POLICY_SERVICE, [
        { id: COMPLIANCE, content: { moduleName: COMPLIANCE, service: new PolicyService() } },
    ]);
    globalAddinContainer.add(POLICY_SERVICE, [
        { id: SERVERLESS, content: { moduleName: SERVERLESS, service: new ServerlessPolicyService() } },
    ]);
    globalAddinContainer.addService(EVENT_TRACKING_SERVICE_ID, new EventTracking());
    globalAddinContainer.addService(ROLES_SERVICE_ID, new RolesService());
    globalAddinContainer.addService(USERS_SERVICE_ID, new UsersService());
    globalAddinContainer.addService(SERVICE_ACCOUNTS_SERVICE_ID, new ServiceAccountsService());
    globalAddinContainer.addService(SECURITY_GROUPS_SERVICE_ID, new SecurityGroupsService());
    globalAddinContainer.addService(AGENTS_SERVICE_ID, new AgentsService());
}

function initializeReducers() {
    const isCloudInfra = getIsCloudInfra();
    const reducers: IApplicationReducer[] = [
        { name: 'globalPersist', reducer: globalPersistReducer, isBlackList: false },
        { name: 'error', reducer: errorReducer, isBlackList: false },
        { name: 'loginForm', reducer: loginFormReducer, isBlackList: true },
        { name: 'menuState', reducer: menuState, isBlackList: true },
        { name: 'breadcrumbsState', reducer: breadcrumbsState, isBlackList: true },
        { name: 'roleState', reducer: switchRoleState, isBlackList: false },
        { name: 'switchRoleComponentState', reducer: switchRoleComponentSlice, isBlackList: true },
        { name: 'alerts', reducer: notificationsReducer, isBlackList: true },
        { name: 'user', reducer: userReducer, isBlackList: isCloudInfra },
        { name: 'auth', reducer: authReducer, isBlackList: isCloudInfra },
        { name: 'sidebar', reducer: sideBarReducer, isBlackList: true },
    ];
    getAppRootRegistry().addReducers(reducers, 'name');
}

function initializeSubModules() {
    initializeOnboarding();
    setProfilerOptions();
    setMixpanelOptions();
    setBeamerOption();
    setGoogleAnalytics();
    initializeInternalTools();
}

export default function initialize() {
    initializeMenuItems(isNotChina());
    initializeReducers();
    initializeServices();
    initializeBESearch();
    initializeSubModules();
}
