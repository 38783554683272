import React, { useCallback, useMemo } from 'react';
import FindingsTableWrapper from '../FindingsTable/FindingsTableWrapper';
import { OriginType } from 'common/module_interface/intelligence/Intelligence.const';
import { IActionUsageDef, IColumnUsageDef } from 'common/interface/general';
import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { getCiemEventsTableColumns } from './CiemEventsPage.columns';
import { getCiemEventsTableFilters } from './CiemEventsPage.filters';
import { getCiemEventsTableActions } from './CiemEventsPage.actions';
import { CIEM_EVENTS_TABLE } from './CiemEventsPage.const';
import { ModalType } from '../Findings.const';
import { EventsTableTabComponentProps } from 'common/interface/events';
import { IStackProps } from 'common/design-system/components-v2/Stack/Stack.types';
import { Stack } from 'common/design-system/components-v2';
import { getIsArchivedFilterInfo, getOriginsFilterInfo } from '../Findings.utils';
import { IAdditionalFilterFieldInfo } from 'common/components/KustoEvents/KustoEvent.interface';

const CiemEventsPage: React.FC<EventsTableTabComponentProps> = ({ archiveView }) => {
    const columns: IColumnUsageDef[] = useMemo(() => getCiemEventsTableColumns(), []);
    const filters: IProtectedAssetFilter[] = useMemo(() => getCiemEventsTableFilters(), []);
    const actionsCreator = useCallback(
        (openDialog: (dialogType: ModalType | null) => void): IActionUsageDef[] => {
            return getCiemEventsTableActions(openDialog, archiveView);
        },
        [archiveView],
    );

    const stackProps = React.useMemo<Omit<IStackProps, 'children'>>(() => {
        // TODO: remove when old events page is removed
        if (archiveView === undefined) {
            return {
                fullHeight: true,
                fullWidth: true,
                overflow: 'auto',
                padding: 5,
            };
        }
        return {
            fullHeight: true,
            fullWidth: true,
        };
    }, [archiveView]);

    const additionalFilterFieldInfo: IAdditionalFilterFieldInfo[] = useMemo(() => {
        return [getOriginsFilterInfo([OriginType.CIEM]), getIsArchivedFilterInfo(archiveView)];
    }, [archiveView]);

    return (
        <Stack {...stackProps}>
            <FindingsTableWrapper
                tableId={CIEM_EVENTS_TABLE}
                filters={filters}
                columns={columns}
                actionsCreator={actionsCreator}
                isArchiveView={archiveView}
                additionalFilterFieldInfo={additionalFilterFieldInfo}
            />
        </Stack>
    );
};

export default CiemEventsPage;
