import { IActiveFeatureInfo } from 'common/registries/ActiveFeatureManager/ActiveFeatures';

export const CDR_TOPIC_ID = 'CDR';

export const ALL_EVENTS_TABLE_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'all-events-table',
    description: 'If enabled, all events table in React from Kusto DB will be shown under Events',
    topic: CDR_TOPIC_ID,
};

export const CDR_EXCLUSIONS_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'cdr-exclusions',
    description: 'If enabled, CDR exclusions table will be shown under Events',
    topic: CDR_TOPIC_ID,
    hidden: true,
};

export const CDR_REPORT_FORM_POC_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'cdr-report-form-poc',
    description: 'If enabled, CDR report action will be done with the new custom form infra',
    topic: CDR_TOPIC_ID,
    hidden: true,
};

export const CDR_LOGS_TABLE_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'cdr-logs-table',
    description: 'If enabled, logs table in React will be shown under Logs section in CDR',
    topic: CDR_TOPIC_ID,
};

export const CDR_ACTIVE_FEATURES: IActiveFeatureInfo[] = [
    ALL_EVENTS_TABLE_ACTIVE_FEATURE,
    CDR_EXCLUSIONS_ACTIVE_FEATURE,
    CDR_REPORT_FORM_POC_ACTIVE_FEATURE,
    CDR_LOGS_TABLE_ACTIVE_FEATURE,
];
