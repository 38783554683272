import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { I18nExclusion, modalPrefixAnalytics } from 'common/components/exclusions/helpers/exclusions.consts';
import { useTranslation } from 'react-i18next';
import { Stack, Message, GroupSelection, Typography, Command } from 'common/design-system/components-v2';
import {
    ExceptionPayloadJson,
    ExceptionPayloadsResponse,
    IExclusionModalProps,
    ServerlessFormValue,
} from 'common/components/exclusions/helpers/exclusions.interfaces';
import {
    initPayload,
    parseExceptionPayloads,
    saveExclusionRuntimeServerless,
} from 'common/components/exclusions/helpers/exclusions.workload.utils';
import { getNotificationsService } from 'common/interface/services';
import { ExclusionModuleType, IRuntimeRequestPayload } from 'common/interface/exclusion';

const ExclusionsModalServerless: FC<IExclusionModalProps> = ({ isOpen, onClose, onSave, exclusion }) => {
    const { t } = useTranslation(I18nExclusion);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [exceptionPayloadJson, setExceptionPayloadJson] = useState<ExceptionPayloadJson | undefined>();
    const [config, setConfig] = useState<ExceptionPayloadsResponse>();

    const exclusionId = exclusion?.id;
    const finding = exclusion?.finding;

    const { handleSubmit, control } = useForm<ServerlessFormValue>({
        defaultValues: {
            ruleScope: 'thisFunctionOnly',
        },
    });

    useEffect(() => {
        if (!finding) return undefined;
        const result = parseExceptionPayloads(finding);
        setExceptionPayloadJson(result.exceptionPayloads);
        setConfig(result);
    }, [finding]);

    const saveExclusion = async (data: ServerlessFormValue) => {
        setIsSaveLoading(true);
        const { ruleScope } = data;
        try {
            if (!exceptionPayloadJson || !finding) return;
            const result = initPayload(ruleScope, finding, exceptionPayloadJson);

            const data = {
                cloudAccountId: exclusion?.cloudAccountIds ? exclusion?.cloudAccountIds[0] : '',
                vendor: exclusion?.platform,
                requestPayload: result,
            };
            const saveExclusionResponse = await saveExclusionRuntimeServerless(data as IRuntimeRequestPayload);
            !!saveExclusionResponse && onSave && onSave();
            setIsSaveLoading(false);
            getNotificationsService().info(
                exclusionId
                    ? t('MODAL.TOAST.EXCLUSION_SAVE_SUCCESS.EDIT')
                    : t('MODAL.TOAST.EXCLUSION_SAVE_SUCCESS.CREATE'),
                '',
            );
        } catch (error: any) {
            getNotificationsService().error(t('MODAL.GENERAL.SAVE_ERROR'), error);
            setIsSaveLoading(false);
        }
    };

    const getFirstPayload = () => {
        return exceptionPayloadJson?.Payloads?.length ? exceptionPayloadJson.Payloads[0].toString() : '';
    };

    return (
        <Message
            id={`${modalPrefixAnalytics}-${ExclusionModuleType.Serverless}`}
            width='lg'
            onClose={onClose}
            isOpen={isOpen}
            title={t('MODAL.TOPICS.EXCLUDE_SERVERLESS.TITLE')}
            cancelBtnText={t('MODAL.FOOTER.CANCEL')}
            onCancel={onClose}
            submitBtnText={t('MODAL.FOOTER.SAVE')}
            onConfirm={handleSubmit((data) => saveExclusion(data))}
            isLoading={isSaveLoading}
        >
            <Stack spacing={3} fullWidth>
                {config?.isStringRule && (
                    <Typography>
                        {t('MODAL.TOPICS.EXCLUDE_SERVERLESS.ALWAYS_ALLOW_THIS', {
                            ruleTarget: exceptionPayloadJson?.Target,
                        })}
                    </Typography>
                )}
                {config?.isIORule && (
                    <Typography>
                        {t('MODAL.TOPICS.EXCLUDE_SERVERLESS.DISABLE_FILTERING', {
                            IORuleFeature: exceptionPayloadJson?.Payloads[0],
                        })}
                    </Typography>
                )}
                {((!config?.isIORule && exceptionPayloadJson?.Target) || config?.isStringRule) && (
                    <Command text={getFirstPayload()} />
                )}
                {config?.isSignatureRule && (
                    <Typography>{t('MODAL.TOPICS.EXCLUDE_SERVERLESS.ALWAYS_ALLOW_THIS_SIGNATURE')}</Typography>
                )}
                {config?.isMalwareEventRule && (
                    <Typography>{t('MODAL.TOPICS.EXCLUDE_SERVERLESS.ALWAYS_ALLOW_FILPATH')}</Typography>
                )}
                <Controller
                    name='ruleScope'
                    control={control}
                    render={({ field }) => (
                        <GroupSelection
                            label={t('MODAL.TOPICS.EXCLUDE_SERVERLESS.INPUTS.APPLY_TO.LABEL')}
                            options={[
                                {
                                    label:
                                        exclusion?.platform === 'aws'
                                            ? t(
                                                  'MODAL.TOPICS.EXCLUDE_SERVERLESS.INPUTS.APPLY_TO.OPTIONS.THIS_FUNCTION_ONLY',
                                              )
                                            : t(
                                                  'MODAL.TOPICS.EXCLUDE_SERVERLESS.INPUTS.APPLY_TO.OPTIONS.THIS_FUNCTION_APP_ONLY',
                                              ),
                                    value: 'thisFunctionOnly',
                                },
                                {
                                    label:
                                        exclusion?.platform === 'aws'
                                            ? t(
                                                  'MODAL.TOPICS.EXCLUDE_SERVERLESS.INPUTS.APPLY_TO.OPTIONS.ALL_FUNCTIONS_IN_THE_ACCOUNT',
                                              )
                                            : t(
                                                  'MODAL.TOPICS.EXCLUDE_SERVERLESS.INPUTS.APPLY_TO.OPTIONS.ALL_FUNCTION_APPS_IN_THE_ACCOUNT',
                                              ),
                                    value: 'allFunctionsInAccount',
                                },
                            ]}
                            {...field}
                        />
                    )}
                />
            </Stack>
        </Message>
    );
};

export default ExclusionsModalServerless;
