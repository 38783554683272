import { IconComponentProps } from 'common/design-system/components-v2/Icon/Icon.types';
import { IBaseAssetModifier } from './IBaseAssetModifier';
import { ImportanceLevelMap } from 'common/consts/ImportanceLevel';
import { DATA_SENSITIVITY_VALUES, DataSensitivityLevelEnum } from '../../../consts';
import { ermTrans } from '../../../RiskManagement.utils';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { isNil } from 'common/utils/helpFunctions';

export interface IDataSensitivityInfo extends IBaseAssetModifier {
    key: string;
    value: string;
    title: string;
    iconProps: IconComponentProps;
    bg: string;
}

export const getDataSensitivityInfosMap = (): { [key in DataSensitivityLevelEnum]: IDataSensitivityInfo } => ({
    [DataSensitivityLevelEnum.Unknown]: {
        id: DataSensitivityLevelEnum.Unknown,
        key: DataSensitivityLevelEnum.Unknown,
        value: DATA_SENSITIVITY_VALUES.UNKNOWN,
        title: ermTrans('PROTECTED_ASSETS.MODIFIERS.DATA_SENSITIVITY.UNKNOWN'),
        iconProps: { name: 'dataSensitivity', customColor: ImportanceLevelMap.unknown.fg },
        bg: ImportanceLevelMap.unknown.bg,
        level: -1,
    },
    [DataSensitivityLevelEnum.False]: {
        id: DataSensitivityLevelEnum.False,
        key: DataSensitivityLevelEnum.False,
        value: DATA_SENSITIVITY_VALUES.NOT_SENSITIVE,
        title: ermTrans('PROTECTED_ASSETS.MODIFIERS.DATA_SENSITIVITY.NOT_SENSITIVE'),
        iconProps: { name: 'dataSensitivity', customColor: ImportanceLevelMap.low.fg },
        bg: ImportanceLevelMap.low.bg,
        level: 0,
    },
    [DataSensitivityLevelEnum.True]: {
        id: DataSensitivityLevelEnum.True,
        key: DataSensitivityLevelEnum.True,
        value: DATA_SENSITIVITY_VALUES.SENSITIVE,
        title: ermTrans('PROTECTED_ASSETS.MODIFIERS.DATA_SENSITIVITY.SENSITIVE'),
        iconProps: { name: 'dataSensitivity', customColor: ImportanceLevelMap.critical.fg },
        bg: ImportanceLevelMap.critical.bg,
        level: 1,
    },
});

export const getAllDataSensitivityLevelInfos = (): IDataSensitivityInfo[] =>
    Object.values(getDataSensitivityInfosMap()).sort((a, b) => a.level - b.level);

export const getDataSensitivityLevelInfos = (): IDataSensitivityInfo[] =>
    getAllDataSensitivityLevelInfos().filter((info) => info.level >= 0);

const getUnknownDataSensitivityInfo = (): IDataSensitivityInfo =>
    getDataSensitivityInfosMap()[DataSensitivityLevelEnum.Unknown];

export const getDataSensitivityLevelInfo = (dataSensitivityValue?: string): IDataSensitivityInfo => {
    const levelInfo: IDataSensitivityInfo | undefined = isNil(dataSensitivityValue)
        ? undefined
        : getDataSensitivityLevelInfos().find((item) => item.value === dataSensitivityValue);
    return levelInfo || getUnknownDataSensitivityInfo();
};

export const getAllDataSensitivityOptions = (): SelectOption[] => {
    return getAllDataSensitivityLevelInfos().map((item) => ({
        value: item.value,
        label: item.title,
    }));
};
