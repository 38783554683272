import { ILog, ILogFieldsSection } from 'common/module_interface/intelligence/Logs/Logs.interface';
import Stack from 'common/design-system/components-v2/Stack';
import React from 'react';
import LogOverviewSection from './LogOverviewSection/LogOverviewSection';
import { getAccountActivityLogOverviewFieldsSection } from '../AccountActivity/AccountActivityLogsPage.overview';

interface LogOverviewProps {
    logInfo: ILog;
}

const LogOverview: React.FC<LogOverviewProps> = ({ logInfo }) => {
    const logInfoSections = getAccountActivityLogOverviewFieldsSection();

    return (
        <Stack direction='column' spacing={8} fullWidth={true} margin={[5, 0, 0, 0]}>
            {logInfoSections.map((section: ILogFieldsSection) => {
                return <LogOverviewSection key={section.title} panelInfo={section} logInfo={logInfo} />;
            })}
        </Stack>
    );
};

export default LogOverview;
