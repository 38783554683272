import { toast } from 'react-toastify';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification, NotificationType, PromiseNotification } from 'common/interface/notifications';
import Toast, { ToastContent } from './Toasts';

export interface NotificationsState {
    notifications: Array<Notification>;
}

const initialState: NotificationsState = {
    notifications: [],
};

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addNotification: (state: NotificationsState, action: PayloadAction<Notification>) => {
            // @ts-ignore
            toast(Toast, {
                className: action.payload.type,
                data: {
                    type: action.payload.type,
                    title: action.payload.title || '',
                    text: action.payload.text || '',
                },
                autoClose: action.payload.autoClose,
                closeOnClick: false,
                pauseOnHover: true,
            });
        },
        handlePromise: (state: NotificationsState, action: PayloadAction<PromiseNotification>) => {
            const id = toast.loading(ToastContent, {
                autoClose: action.payload.autoClose,
                className: NotificationType.INFO,
                data: {
                    type: NotificationType.PENDING,
                    title: 'Loading',
                    text: 'Please wait...',
                    ...(action.payload.pending || {}),
                },
            });
            action.payload.promise
                .then(() => {
                    toast.update(id, {
                        closeButton: true,
                        className: NotificationType.SUCCESS,
                        data: {
                            type: NotificationType.SUCCESS,
                            title: 'Completed',
                            text: 'Task is completed successfully',
                            ...(action.payload.success || {}),
                        },
                    });
                })
                .catch((error) => {
                    toast.update(id, {
                        closeButton: true,
                        className: NotificationType.ERROR,
                        data: {
                            type: NotificationType.ERROR,
                            title: 'Failed',
                            text: 'Task was failed to complete',
                            ...(action.payload.error || {}),
                            ...(action.payload.errorFormatter && { text: action.payload.errorFormatter(error) }),
                        },
                    });
                });
        },
        clearAllNotifications: () => {
            toast.dismiss();
        },
    },
});

export const { addNotification, clearAllNotifications, handlePromise } = notificationsSlice.actions;

export default notificationsSlice.reducer;
