import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const TreeNodeWrapper = styled(Stack)<{ level: number }>`
    padding-left: ${(props) => `${props.level * 24}px`};
`;

export const IdentitiesGroupCellRendererStyled = {
    TreeNodeWrapper: TreeNodeWrapper,
};
