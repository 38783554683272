import React from 'react';
import { Chip } from 'common/design-system/components-v2';
import { ScanStatusChipProps } from './ScanStatusChip.types';
import { chipTypeByStatus } from './ScanStatusChip.consts';

export const ScanStatusChip: React.FC<ScanStatusChipProps> = ({ text, tooltip }) => {
    const { color, icon } = chipTypeByStatus[text];
    return (
        <Chip
            leadingIconProps={{ name: icon }}
            color={color}
            label={'Scan Issue'}
            disableInteraction
            tooltip={tooltip}
        />
    );
};
