import { IPermissionInfo } from 'common/module_interface/identity/IdenticalRoles.interfaces';
import React from 'react';
import { PermissionsTableStyled as Styled } from './PermissionsTable.styled';
import { getColumnDefs } from './PermissionsTable.columns';
import { ClientFilterPageTable } from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable';
import { IClientFilterPageTableProps } from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPage.interface';

export interface PermissionsTableProps {
    permissions: IPermissionInfo[];
}

const PERMISSIONS_TABLE_ID = 'permissions-table';
const PermissionsTable: React.FC<PermissionsTableProps> = ({ permissions }) => {
    const pageProps: IClientFilterPageTableProps<IPermissionInfo> = {
        fetchAllItems: () => Promise.resolve(permissions),
        getTableColumnDefs: getColumnDefs,
        pageTableId: PERMISSIONS_TABLE_ID,
        tableSettings: {
            disableColumnMenu: true,
            disableFooter: true,
        },
    };

    return (
        <Styled.Wrapper>
            <ClientFilterPageTable {...pageProps} />
        </Styled.Wrapper>
    );
};

export default PermissionsTable;
