import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table, Typography } from 'common/design-system/components-v2';
import { GridApi, GridReadyEvent, IRowNode, ColumnApi } from 'ag-grid-community';
import { isFilterValuesEmpty } from '../../filterUtils';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import FilterPanelVulnerabilityCve, {
    customTransform,
} from '../../FilterPanelVulnerability/FilterPanelVulnerabilityCve';
import { getColumnDefs } from './ColumnDefs';
import ExpandCollapseAction from 'common/components/ag-grid/Actions/ExpandCollapseAction';
import { CveColumnsNames, CvesProps } from './Cves.types';
import useExpandOrCollapseAggrid from 'modules/workloads/hooks/useExpandOrCollapseAggrid';

let objectFilter: IFiltersValues = {};
const fieldsToSearchTextFree = ['id', 'description', 'packageVersion', 'packageName', 'relatedIds', 'packageLayerId'];

const Cves: React.FC<CvesProps> = ({ cves, exportCveAsJSONAndCSV, setRemediationOnly, layers, cloudEntity }) => {
    const gridRef = React.useRef<GridApi>();
    const columnApi = React.useRef<ColumnApi>();
    const [itemDisplayedRowCount, setItemDisplayedRowCount] = useState<number>(0);
    const { onColumnRowGroupChanged, onRowGroupOpened, onExpandOrCollapseAll, setExpandState, expandState } =
        useExpandOrCollapseAggrid();
    const { t } = useTranslation(getK8sNamespace('vulnerability'));

    useEffect(() => setItemDisplayedRowCount(cves.length), [cves]);

    const columnDefs = useMemo(() => getColumnDefs(cloudEntity, layers), [cloudEntity, layers]);

    const setInitExpandState = useCallback(() => {
        let maxLevel = 0;
        gridRef.current?.forEachNode((node) => {
            gridRef.current?.setRowNodeExpanded(node, node.level === 0 && node.rowIndex === 0);
            maxLevel = Math.max(maxLevel, node.level);
        });
        setExpandState((prev) => ({ ...prev, maxLevel }));
    }, [setExpandState]);

    const groupByLayers = useCallback(() => {
        if (layers) {
            columnApi.current?.setRowGroupColumns([CveColumnsNames.LayerCommand]);
            columnApi.current?.setColumnWidth('ag-Grid-AutoColumn', 250);
        } else {
            columnApi.current?.setRowGroupColumns([CveColumnsNames.PackageName]);
            columnApi.current?.setColumnVisible(CveColumnsNames.LayerId, false);
        }
    }, [layers]);

    const onGridReady = useCallback(
        (event: GridReadyEvent) => {
            gridRef.current = event.api;
            columnApi.current = event.columnApi;
            setInitExpandState();
            groupByLayers();
        },
        [groupByLayers, setInitExpandState],
    );
    const isExternalFilterPresent = useCallback(() => {
        return objectFilter && !isFilterValuesEmpty(objectFilter);
    }, []);

    const doesExternalFilterPass = useCallback((node: IRowNode) => {
        let doesExternalFilterPassValue = true;
        if (node.data) {
            Object.keys(objectFilter).forEach((key) => {
                if (!objectFilter[key] || objectFilter[key].length === 0) return;
                if (key !== 'free-text') {
                    const values: Array<string> = objectFilter[key];
                    if (customTransform[key] !== undefined) {
                        const doesFilterPassValues = values.some((value) => customTransform[key](node.data) === value);
                        doesExternalFilterPassValue = doesExternalFilterPassValue && doesFilterPassValues;
                    } else if (typeof node.data[key] === 'boolean') {
                        const doesFilterPassValues = values.some((value) => node.data[key].toString() === value);
                        doesExternalFilterPassValue = doesExternalFilterPassValue && doesFilterPassValues;
                    } else if (Array.isArray(node.data[key])) {
                        const doesFilterPassValues = values.some((value) => node.data[key].includes(value));
                        doesExternalFilterPassValue = doesExternalFilterPassValue && doesFilterPassValues;
                    } else {
                        const doesFilterPassValues = values.some((value) => node.data[key] === value);
                        doesExternalFilterPassValue = doesExternalFilterPassValue && doesFilterPassValues;
                    }
                } else {
                    const doesFilterPassFreeText = fieldsToSearchTextFree.some((field) => {
                        if (!node.data[field]) return false;
                        const searchValue = objectFilter[key];
                        if (Array.isArray(node.data[field])) {
                            return node.data[field].some((value: string) =>
                                value.toLowerCase().includes(searchValue.toLowerCase()),
                            );
                        }
                        return node.data[field].toLowerCase().includes(searchValue.toLowerCase());
                    });
                    doesExternalFilterPassValue = doesExternalFilterPassValue && doesFilterPassFreeText;
                }
            });
        }
        return doesExternalFilterPassValue;
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
        };
    }, []);

    const externalFilterChanged = useCallback(
        (newValue: IFiltersValues) => {
            objectFilter = newValue;
            gridRef.current?.onFilterChanged();
            if (newValue.isFixedByPackageRemediation[0] === 'true') {
                setRemediationOnly(true);
            } else {
                setRemediationOnly(false);
            }

            let count = 0;
            gridRef.current?.forEachNodeAfterFilter((node) => {
                !node.group && count++;
            });
            setItemDisplayedRowCount(count);
        },
        [setRemediationOnly],
    );

    const actions = [
        {
            id: 'collapse-expand',
            name: 'collapse-expand-action',
            callback: () => null,
            customComponent: () => {
                return (
                    <ExpandCollapseAction
                        groupsNo={expandState.maxLevel}
                        expanded={expandState.expandedParents}
                        onClick={(isExpanded: boolean) => {
                            isExpanded ? gridRef.current?.collapseAll() : gridRef.current?.expandAll();
                        }}
                    />
                );
            },
        },
    ];

    return (
        <>
            <FilterPanelVulnerabilityCve data={cves} onFilterChange={externalFilterChanged} />
            <Table
                tableId='cves'
                saveColumnsState
                ellipsisRowGroup
                exportButtons={exportCveAsJSONAndCSV}
                gridOptions={{
                    onColumnRowGroupChanged: onColumnRowGroupChanged,
                    onExpandOrCollapseAll: onExpandOrCollapseAll,
                    onRowGroupOpened: onRowGroupOpened,
                    rowData: cves,
                    columnDefs: columnDefs,
                    defaultColDef,
                    onGridReady,
                    doesExternalFilterPass,
                    isExternalFilterPresent,
                }}
                actions={actions}
                footer={
                    <Typography>
                        {t('VulnerabilityTable.footer', {
                            itemDisplayedRowCount: itemDisplayedRowCount,
                            totalRowCount: cves.length || 0,
                        })}
                    </Typography>
                }
            />
        </>
    );
};

export default Cves;
