import { FC } from 'react';
import { Button, Input, Stack } from 'common/design-system/components-v2';

interface IProvideDataCenterProps {
    handleCopyDataCenter: () => void;
    dataCenterUrl: string;
}

export const ProvideDataCenter: FC<IProvideDataCenterProps> = ({ handleCopyDataCenter, dataCenterUrl }) => {
    return (
        <Stack direction={'row'} spacing={2}>
            <Input type={'text'} value={dataCenterUrl} readOnly fullWidth />
            <Button
                dataAid={'provide-data-center-button'}
                iconProps={{ name: 'copy' }}
                onClick={handleCopyDataCenter}
            />
        </Stack>
    );
};
