import { ColDef, GridReadyEvent } from 'ag-grid-community';
import React, { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { GridApi } from 'ag-grid-enterprise';
import FindingSeverityCellRender from 'common/components/ag-grid/Renderers/FindingSeverityCellRender';
import '../../../Intelligence.scss';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../initialize.i18n';
import { severityComparator } from '../Services/Utils';
import { getPropertyValueFromQueryString } from '../../../Utils';
import EventTitleCellRender from 'common/components/ag-grid/Renderers/EventTitleCellRender';
import EntityNameCellRender from 'common/components/ag-grid/Renderers/EntityNameCellRender';
import i18n from 'common/services/translations/translations';
import moment from 'moment-mini';
import { getFindingsData } from '../Services/IntelligenceGraphDataHelpers';
import { PROTECTED_ASSETS_URL } from 'common/module_interface/assets/ProtectedAssets.consts';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { IEvent } from 'common/module_interface/events/Events';

const RelatedEventsTable: React.FC<{ finding: IKustoEvent | undefined }> = (props) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const { finding } = props;
    const [rowData, setRowData] = useState<IEvent[]>([]);
    const gridRef = useRef<AgGridReact>(null);
    const gridApi = useRef<GridApi>();
    const location = useLocation();
    const [entityId, setEntityId] = useState('');
    const [entityName, setEntityName] = useState('');

    useEffect(() => {
        setEntityId(getPropertyValueFromQueryString(location, 'entityId'));
        setEntityName(getPropertyValueFromQueryString(location, 'entityName'));
    }, [location]);

    const columnDefs: ColDef[] = [
        {
            field: 'createdTime',
            headerName: i18n.t('COMMON.CREATED_TIME'),
            resizable: false,
            sortable: true,
            valueFormatter: (params) => {
                if (params.value) {
                    return moment(params.value).format('lll');
                } else {
                    return '';
                }
            },
        },
        {
            field: 'severity',
            headerName: i18n.t('COMMON.SEVERITY'),
            resizable: false,
            cellRenderer: FindingSeverityCellRender,
            sortable: true,
            comparator: severityComparator,
        },
        {
            field: 'ruleName',
            headerName: i18n.t('COMMON.TITLE'),
            resizable: false,
            tooltipField: 'ruleName',
            cellRenderer: EventTitleCellRender,
        },
        {
            field: 'entityName',
            headerName: i18n.t('COMMON.ENTITY'),
            resizable: false,
            tooltipField: 'entityName',
            cellRenderer: EntityNameCellRender,
            cellRendererParams: { componentName: '', baseUrl: PROTECTED_ASSETS_URL },
        },
        {
            field: 'entityType',
            headerName: i18n.t('COMMON.ENTITY_TYPE'),
            resizable: false,
        },
    ];

    const defaultColDef = {
        resizable: false,
        suppressMenu: true,
        pinned: true,
    };

    const onGridReady = (params: GridReadyEvent) => {
        gridApi.current = params.api;
        gridApi?.current?.showLoadingOverlay();
        params.api?.sizeColumnsToFit();
    };

    const onRowDataUpdated = () => {
        const nodeId = finding?.findingKey;

        if (!nodeId || !gridApi?.current) return;
        gridApi?.current?.forEachNode((node) => {
            if (node.data.findingKey === nodeId && node.rowIndex !== null) {
                node.setSelected(true);
                gridApi?.current?.ensureIndexVisible(node.rowIndex, 'top');
            }
        });
    };

    useEffect(() => {
        if (!gridApi) return;

        void getEventsTableData();

        async function getEventsTableData(): Promise<void> {
            if (!entityId || !finding) return;
            gridApi?.current?.showLoadingOverlay();
            let entityFindings: IEvent[] | undefined;

            try {
                entityFindings = await getFindingsData(entityId, entityName, finding);
            } finally {
                if (entityFindings?.length === 0 || !entityFindings) {
                    setRowData([]);
                    gridApi?.current?.showNoRowsOverlay();
                } else {
                    setRowData(entityFindings);
                    gridApi?.current?.hideOverlay();
                }
            }
        }
    }, [gridApi, finding, entityId, entityName]);

    return (
        <>
            {entityId && (
                <div className='rounded-xl border border-strong h-full p-8 mt-8'>
                    <div className='flex flex-col'>
                        <div className='flex justify-between items-center'>
                            <div className='font-medium text-lg'>
                                {t('INT_EVENTS_TABLE.TITLE')} {entityName || entityId}
                            </div>
                        </div>
                        <div className='my-5'>{t('INT_EVENTS_TABLE.DESCRIPTION')}</div>
                    </div>

                    <div className='ag-theme-alpine h-[180px] overflow-auto min-h-[50px]'>
                        <AgGridReact
                            ref={gridRef}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            rowSelection={'single'}
                            rowData={rowData}
                            sideBar={false}
                            onGridReady={onGridReady}
                            enableBrowserTooltips={true}
                            enableCellTextSelection={true}
                            suppressContextMenu={true}
                            onRowDataUpdated={onRowDataUpdated}
                            overlayLoadingTemplate={`<span> ${t('INT_EVENTS_TABLE.LOADING')} ${entityId} ${t('INT_EVENTS_TABLE.EVENTS')} ... </span>`}
                        ></AgGridReact>
                    </div>
                </div>
            )}
        </>
    );
};

export default RelatedEventsTable;
