import React from 'react';
import { Controller, RegisterOptions, useForm } from 'react-hook-form';
import { EventsModalOpenFrom, EventsModalProps } from 'common/module_interface/events/EventsModalsRegistry';
import { useTranslation } from 'react-i18next';
import { Message, SelectV2, Spinner, Stack } from 'common/design-system/components-v2';
import { getEventsNamespace } from 'modules/events/initialize.i18n';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { useMutateEventAssign } from 'modules/events/services/complianceFindingApi/hooks/useMutateEventAssign';
import { ComplianceFindingAssign } from 'common/module_interface/events/complianceFindingApi.interface';
import { useUsers } from 'common/services/apis/ViewCommonApi/hooks/useUsers';

interface FormValues {
    user_select?: string;
}

const AssignAction: React.FC<EventsModalProps> = ({
    closeModal,
    selectedRows,
    resetSelectedRows,
    requestNewData,
    openFrom,
}) => {
    const { t } = useTranslation(getEventsNamespace('actions'));

    const { users, isLoading: isUsersLoading, isError: isUsersError } = useUsers();

    const onSuccess = (response: ComplianceFindingAssign.Response) => {
        if (response.hasErrors) {
            const failedCount = Object.keys(response.failedItems).length;
            getNotificationsService().addNotification({
                text: t('ASSIGN.NOTIFICATIONS.PARTIAL_ERROR', { failedCount, eventsCount: selectedRows.length }),
                type: NotificationType.WARNING,
            });
        } else {
            getNotificationsService().addNotification({
                text: t('ASSIGN.NOTIFICATIONS.SUCCESS', { count: selectedRows.length, user: getValues().user_select }),
                type: NotificationType.SUCCESS,
            });
        }
        if (openFrom === EventsModalOpenFrom.TABLE && resetSelectedRows) {
            resetSelectedRows();
        }
        closeModal();
        requestNewData?.();
    };

    const onError = () => {
        getNotificationsService().addNotification({
            text: t('ASSIGN.NOTIFICATIONS.ERROR', { count: selectedRows.length }),
            type: NotificationType.ERROR,
        });
    };

    const { assign, isLoading } = useMutateEventAssign({ onSuccess, onError });

    const inputValidations: { [input: string]: RegisterOptions } = React.useMemo(
        () => ({
            user_select: {
                required: t('ASSIGN.INPUTS.USER_SELECT.ERRORS.REQUIRED'),
            },
        }),
        [t],
    );

    const { handleSubmit, control, getValues } = useForm<FormValues>();

    const submitUpdate = async (data: FormValues) => {
        const { user_select } = data;
        if (!user_select) return;
        const fixedUser = user_select === 'unassigned' ? null : user_select;
        assign(selectedRows, fixedUser);
    };

    return (
        <Message
            title={t('ASSIGN.TITLE')}
            text={selectedRows.length > 1 ? t('ASSIGN.TEXT', { count: selectedRows.length }) : undefined}
            submitBtnText={t('ASSIGN.BUTTONS.SUBMIT')}
            width='lg'
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={handleSubmit((data) => submitUpdate(data))}
            isLoading={isLoading}
            dataAid='events-assign-action'
        >
            <Controller
                name='user_select'
                rules={inputValidations['user_select']}
                control={control}
                render={({ field, fieldState }) => (
                    <Stack>
                        <SelectV2
                            autoFocus
                            label={t('ASSIGN.INPUTS.USER_SELECT.LABEL')}
                            placeholder={t('ASSIGN.INPUTS.USER_SELECT.PLACEHOLDER')}
                            required
                            fullWidth
                            isMulti={false}
                            options={[
                                { value: 'unassigned', label: t('ASSIGN.INPUTS.USER_SELECT.OPTIONS.UNASSIGNED') },
                                ...users.map((user) => ({ value: user.name, label: user.name })),
                            ]}
                            isError={!!fieldState.error || isUsersError}
                            helperText={
                                fieldState.error?.message || isUsersError
                                    ? t('ASSIGN.INPUTS.USER_SELECT.ERRORS.LOADING_USERS_ERROR')
                                    : undefined
                            }
                            disabled={isUsersLoading || isUsersError}
                            endAdornment={
                                isUsersLoading ? (
                                    <Stack justifyContent='center' alignItems='center'>
                                        <Spinner size={10} />
                                    </Stack>
                                ) : undefined
                            }
                            {...field}
                        />
                    </Stack>
                )}
            />
        </Message>
    );
};

export default AssignAction;
