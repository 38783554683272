import { Stack, Typography } from 'common/design-system/components-v2';
import SimpleGenericFilterPanel from './SimpleGenericFilterPanel';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import SimpleGenericTable from './SimpleGenericTable';
import { IDataItem, IGenericPageProps } from './interfaces';
import { useTranslation } from 'react-i18next';
import { I18nSettingsTranslationKey } from '../initialize';

export const SimpleGenericPage: React.FC<IGenericPageProps> = ({
    extraActions,
    translationKey,
    calcFilteredData,
    pagination,
    filterId,
    items,
    savedFiltersComponentName,
    filterIds,
    tableRegistry,
    getAggregations,
    onItemClicked,
    onDelete,
    onAdd,
    onEdit,
    isDataLoading,
    exportButtons,
    setFilteredDataForExport,
}) => {
    const [rawData, setRawData] = useState([] as IDataItem[]);
    const [filteredData, setFilteredData] = useState<IDataItem[]>([]);
    const [isLoading, setIsLoading] = useState(isDataLoading);
    const apiRef = useRef<GridApi>();
    const { t } = useTranslation(I18nSettingsTranslationKey);

    useEffect(() => {
        setIsLoading(isDataLoading);
    }, [isDataLoading]);

    const onGridReady = useCallback((params: GridReadyEvent) => {
        apiRef.current = params.api;
    }, []);

    const init = useCallback(async () => {
        setRawData(items);
        setIsLoading(false);
    }, [items]);

    useEffect(() => {
        void init();
    }, [init]);

    const isFilterEmpty = (filtersValues: IFiltersValues) => {
        return Object.keys(filtersValues).find((filterKey) => filtersValues[filterKey] !== '') === undefined;
    };

    const onFilterChangeCallBack = React.useCallback(
        (filtersValues: IFiltersValues) => {
            if (isFilterEmpty(filtersValues)) {
                setFilteredData(rawData);
                setFilteredDataForExport && setFilteredDataForExport(rawData);
                return;
            }
            const newFilteredData = calcFilteredData(filtersValues, rawData);
            if (JSON.stringify(filteredData) !== JSON.stringify(newFilteredData)) {
                setFilteredData(newFilteredData);
                setFilteredDataForExport && setFilteredDataForExport(newFilteredData);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [rawData, filteredData],
    );

    return (
        <Stack fullHeight fullWidth padding={4}>
            <Stack fullHeight>
                <Stack spacing={5} style={{ height: '50px' }}>
                    <SimpleGenericFilterPanel
                        filterId={filterId}
                        savedFiltersComponentName={savedFiltersComponentName}
                        translationKey={translationKey}
                        tableRegistry={tableRegistry}
                        filterIds={filterIds}
                        getAggregations={getAggregations}
                        rawData={rawData}
                        tableData={filteredData}
                        isLoading={!!isLoading}
                        onFilterChange={onFilterChangeCallBack}
                    />
                </Stack>
                <Stack style={{ flexGrow: 1 }}>
                    {isLoading ? (
                        <Stack justifyContent={'center'} alignItems={'center'} fullHeight={true}>
                            <Typography variant={'bodyLg'}>{t('GENERAL.LOADING')}</Typography>
                        </Stack>
                    ) : (
                        <SimpleGenericTable
                            pagination={pagination}
                            rowSelectionMode='multiple'
                            extraActions={extraActions}
                            tableRegistry={tableRegistry}
                            translationKey={translationKey}
                            onInnerGridReady={onGridReady}
                            data={filteredData}
                            allItemsCount={rawData.length}
                            pageSize={20}
                            onDelete={onDelete}
                            onEdit={onEdit}
                            onItemClicked={onItemClicked}
                            onAdd={onAdd}
                            exportButtons={exportButtons}
                        />
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};
