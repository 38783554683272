import React, { useState } from 'react';
import {
    IDashboardWidget,
    IGenericWidgetSettingsProps,
    NavigateOption,
} from 'common/module_interface/overview/Interface';
import { useTranslation } from 'react-i18next';
import { deepCloneObject } from 'common/utils/objectUtils';

export const EnvironmentsAggregationLinks: React.FC<IGenericWidgetSettingsProps> = ({
    widget,
    updateWidgetCallback,
}) => {
    const { t } = useTranslation();

    const [widgetClone, setWidgetClone] = useState<IDashboardWidget>(widget);
    const [navigateOption, setNavigateOption] = useState<string>(
        widget?.options?.navigateOption || NavigateOption.PROTECTED_ASSET,
    );

    const checkNavigateOptionLink = (navigateTo: string) => {
        const _widgetClone = deepCloneObject(widgetClone);
        setNavigateOption(navigateTo);
        _widgetClone.options.navigateOption = navigateTo;
        setWidgetClone(_widgetClone);
        updateWidgetCallback(_widgetClone);
    };

    return (
        <div>
            <div className='font-semibold mb-2 mt-7'>{t('OVERVIEW.DASHBOARD.WIDGETS.NAVIGATION_ON_CLICK')}</div>
            <div className={'flex'}>
                <div className='mr-5 flex flex-center'>
                    <input
                        type='radio'
                        value='Builder'
                        checked={navigateOption === NavigateOption.PROTECTED_ASSET}
                        onChange={() => checkNavigateOptionLink(NavigateOption.PROTECTED_ASSET)}
                    />
                    <span className={'ml-4'}>{t(NavigateOption.PROTECTED_ASSET)}</span>
                </div>
                <div className='ml-5 flex flex-center'>
                    <input
                        type='radio'
                        value='Builder'
                        checked={navigateOption === NavigateOption.ENVIRONMENT}
                        onChange={() => checkNavigateOptionLink(NavigateOption.ENVIRONMENT)}
                    />
                    <span className={'ml-4'}>{t(NavigateOption.ENVIRONMENT)}</span>
                </div>
            </div>
        </div>
    );
};
