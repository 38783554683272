import { INotification } from 'common/interface/notificationPage';
import { INTEGRATIONS_IDS } from 'common/module_interface/settings/integrations/consts';
import {
    IAddEditNotificationsModalSection,
    INotificationsCategoryProperties,
} from './Interfaces/NotificationPageInterfaces';
import { showAlertCriticalEventsOnly } from './NotificationsUtilsFunctions';

export const I18nNotifications = 'notifications';

export const STATUS = 'status';
export const ASSOCIATIONS = 'associations';
export const TYPES = 'types';
export const defaultNotificationId = '00000000-0000-0000-0000-000000000000';

export const statusOptions = { normal: 'STATUS.NORMAL', invalid: 'STATUS.INVALID' };

export const ENABLED = 'Enabled';
export const AWS_SECURITY_HUB_DESCRIPTION_LINK =
    'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Integrations/Integrations.htm?Highlight=security%20hub#AWS_Hub';
export const CRON_EXPRESSION_DOCUMENTATION_LINK =
    'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Settings/Notifications.htm';

export const savedFiltersComponentName = 'notificationsSavedFilters';
export const notificationsTypesConfigurations = [
    {
        isTypeExist: (notification: INotification) =>
            notification.changeDetection?.awsSecurityHubIntegrationState === ENABLED,
        title: 'TYPES.AWS_SECURITY_HUB',
    },
    {
        isTypeExist: (notification: INotification) => notification.scheduledReport?.emailSendingState === ENABLED,
        title: 'TYPES.SCHEDULED_EMAIL',
    },
    {
        isTypeExist: (notification: INotification) => notification.changeDetection?.emailSendingState === ENABLED,
        title: 'TYPES.IMMEDIATE_NOTIFICATION',
    },
    { isTypeExist: (notification: INotification) => notification.alertsConsole, title: 'TYPES.ALERTS_CONSOLE' },
    {
        isTypeExist: (notification: INotification) =>
            notification.changeDetection?.emailPerFindingSendingState === ENABLED,
        title: 'TYPES.EMAIL_PER_FINDING',
    },
    {
        isTypeExist: (notification: INotification) => notification.changeDetection?.snsSendingState === ENABLED,
        title: 'TYPES.SNS',
    },
    {
        isTypeExist: (notification: INotification) =>
            notification.changeDetection?.externalTicketCreatingState === ENABLED,
        title: 'TYPES.TICKETING_SYSTEM',
    },
    {
        isTypeExist: (notification: INotification) =>
            notification.gcpSecurityCommandCenterIntegration?.state === ENABLED,
        title: 'GCP_COMMAND_CENTER',
    },
];

export const NOTIFICATIONS_CATEGORIES_IDS = {
    HTTP_ENDPOINT: 'HTTP Endpoint',
    EMAIL_REPORT: 'Email report',
    TEAMS_REPORT: 'Teams report',
    SLACK_REPORT: 'Slack report',
    TEAMS_CRITICAL_EVENTS: 'Teams Critical Events',
    SLACK_CRITICAL_EVENTS: 'Slack Critical Events',
    EACH_FINDING: 'Each finding',
    SNS: 'SNS',
    AWS_SECURITY_HUB: 'aws security hub',
    PAGER_DUTY: 'pager duty',
    AZURE_DEFENDER_FOR_CLOUD: 'Azure Defender for Cloud',
    ALERT_CONSOLE: 'Alert Console',
    GCP_SECURITY_COMMAND_CENTER: 'GCP Security Command Center',
    GENERAL_SECURITY_EVENTS_PER_OCCURRENCE: 'General Security Events Per Occurrence',
};

export const NOTIFICATION_LIST_NAME_IN_REQUEST: {
    [key: string]:
        | 'singleNotificationIntegrationSettings'
        | 'reportsIntegrationSettings'
        | 'scheduledIntegrationSettings';
} = {
    SINGLE: 'singleNotificationIntegrationSettings',
    REPORT: 'reportsIntegrationSettings',
    SCHEDULED_REPORT: 'scheduledIntegrationSettings',
};

const securityManagementCategoriesProperties: INotificationsCategoryProperties[] = [
    {
        id: NOTIFICATIONS_CATEGORIES_IDS.GCP_SECURITY_COMMAND_CENTER,
        title: 'MODALS.ADD_EDIT_MODAL.CATEGORIES.GCP_SECURITY_COMMAND_CENTER',
        type: NOTIFICATION_LIST_NAME_IN_REQUEST.SINGLE,
        integrationsIDs: [INTEGRATIONS_IDS.GCP_SECURITY_COMMAND_CENTER],
    },
    {
        id: NOTIFICATIONS_CATEGORIES_IDS.AWS_SECURITY_HUB,
        title: 'MODALS.ADD_EDIT_MODAL.CATEGORIES.AWS_SECURITY_HUB',
        description: 'MODALS.ADD_EDIT_MODAL.TOOLTIPS.CATEGORIES.AWS_SECURITY_HUB',
        descriptionLink: AWS_SECURITY_HUB_DESCRIPTION_LINK,
        type: NOTIFICATION_LIST_NAME_IN_REQUEST.SINGLE,
        integrationsIDs: [INTEGRATIONS_IDS.AWS_SECURITY_HUB],
    },
    {
        id: NOTIFICATIONS_CATEGORIES_IDS.AZURE_DEFENDER_FOR_CLOUD,
        title: 'MODALS.ADD_EDIT_MODAL.CATEGORIES.AZURE_DEFENDER_FOR_CLOUD',
        type: NOTIFICATION_LIST_NAME_IN_REQUEST.SINGLE,
        integrationsIDs: [INTEGRATIONS_IDS.AZURE_DEFENDER_FOR_CLOUD],
    },
];

const issueManagementCategoriesProperties: INotificationsCategoryProperties[] = [
    {
        id: NOTIFICATIONS_CATEGORIES_IDS.PAGER_DUTY,
        title: 'MODALS.ADD_EDIT_MODAL.CATEGORIES.PAGER_DUTY',
        type: NOTIFICATION_LIST_NAME_IN_REQUEST.SINGLE,
        integrationsIDs: [INTEGRATIONS_IDS.PAGER_DUTY],
    },
];
export const immediateNotificationsCategoriesProperties: INotificationsCategoryProperties[] = [
    {
        id: NOTIFICATIONS_CATEGORIES_IDS.EMAIL_REPORT,
        title: 'MODALS.ADD_EDIT_MODAL.CATEGORIES.EMAIL_REPORT',
        description: 'MODALS.ADD_EDIT_MODAL.TOOLTIPS.CATEGORIES.EMAIL_REPORT',
        type: NOTIFICATION_LIST_NAME_IN_REQUEST.REPORT,
        integrationsIDs: [INTEGRATIONS_IDS.EMAIL],
    },
    {
        id: NOTIFICATIONS_CATEGORIES_IDS.EACH_FINDING,
        title: 'MODALS.ADD_EDIT_MODAL.CATEGORIES.SEPARATE_MESSAGE',
        type: NOTIFICATION_LIST_NAME_IN_REQUEST.SINGLE,
        hasOutputTypeSelection: true,
        integrationsIDs: [INTEGRATIONS_IDS.EMAIL],
    },
    {
        id: NOTIFICATIONS_CATEGORIES_IDS.SNS,
        title: 'MODALS.ADD_EDIT_MODAL.CATEGORIES.SNS',
        type: NOTIFICATION_LIST_NAME_IN_REQUEST.SINGLE,
        integrationsIDs: [INTEGRATIONS_IDS.SNS],
    },
    {
        id: NOTIFICATIONS_CATEGORIES_IDS.HTTP_ENDPOINT,
        title: 'MODALS.ADD_EDIT_MODAL.CATEGORIES.HTTP_ENDPOINT',
        type: NOTIFICATION_LIST_NAME_IN_REQUEST.SINGLE,
        integrationsIDs: [
            INTEGRATIONS_IDS.GENERIC_WEBHOOK,
            INTEGRATIONS_IDS.SPLUNK,
            INTEGRATIONS_IDS.SERVICE_NOW,
            INTEGRATIONS_IDS.QRADAR,
            INTEGRATIONS_IDS.SUMO_LOGIC,
            INTEGRATIONS_IDS.JIRA,
        ],
    },
    {
        id: NOTIFICATIONS_CATEGORIES_IDS.SLACK_REPORT,
        title: 'MODALS.ADD_EDIT_MODAL.CATEGORIES.SLACK',
        type: NOTIFICATION_LIST_NAME_IN_REQUEST.REPORT,
        integrationsIDs: [INTEGRATIONS_IDS.SLACK],
    },
    {
        id: NOTIFICATIONS_CATEGORIES_IDS.TEAMS_REPORT,
        title: 'MODALS.ADD_EDIT_MODAL.CATEGORIES.TEAMS',
        type: NOTIFICATION_LIST_NAME_IN_REQUEST.REPORT,
        integrationsIDs: [INTEGRATIONS_IDS.TEAMS],
    },
    {
        id: NOTIFICATIONS_CATEGORIES_IDS.SLACK_CRITICAL_EVENTS,
        title: 'MODALS.ADD_EDIT_MODAL.CATEGORIES.SLACK_CRITICAL_EVENTS',
        type: NOTIFICATION_LIST_NAME_IN_REQUEST.SINGLE,
        integrationsIDs: [INTEGRATIONS_IDS.SLACK],
        alert: {
            condition: showAlertCriticalEventsOnly,
            title: 'GENERAL.IMPORTANT',
            message: 'MODALS.ADD_EDIT_MODAL.CRITICAL_EVENTS_ALERT',
        },
    },
    {
        id: NOTIFICATIONS_CATEGORIES_IDS.TEAMS_CRITICAL_EVENTS,
        title: 'MODALS.ADD_EDIT_MODAL.CATEGORIES.TEAMS_CRITICAL_EVENTS',
        type: NOTIFICATION_LIST_NAME_IN_REQUEST.SINGLE,
        integrationsIDs: [INTEGRATIONS_IDS.TEAMS],
        alert: {
            condition: showAlertCriticalEventsOnly,
            title: 'GENERAL.IMPORTANT',
            message: 'MODALS.ADD_EDIT_MODAL.CRITICAL_EVENTS_ALERT',
        },
    },
    {
        id: NOTIFICATIONS_CATEGORIES_IDS.GENERAL_SECURITY_EVENTS_PER_OCCURRENCE,
        title: 'MODALS.ADD_EDIT_MODAL.CATEGORIES.GENERAL_SEND_SECURITY_EVENTS',
        type: NOTIFICATION_LIST_NAME_IN_REQUEST.SINGLE,
        integrationsIDs: [],
    },
];

export const addEditNotificationsModalSections: IAddEditNotificationsModalSection[] = [
    {
        title: 'MODALS.ADD_EDIT_MODAL.SECTIONS.IMMEDIATE_NOTIFICATIONS',
        icon: 'clock',
        filter: true,
        categoryList: immediateNotificationsCategoriesProperties,
    },
    {
        title: 'MODALS.ADD_EDIT_MODAL.SECTIONS.SECURITY_MANAGEMENT',
        icon: 'web',
        categoryList: securityManagementCategoriesProperties,
    },
    {
        title: 'MODALS.ADD_EDIT_MODAL.SECTIONS.ISSUE_MANAGEMENT',
        icon: 'web',
        categoryList: issueManagementCategoriesProperties,
    },
];

export enum BUTTON_STATE {
    ACTIVE = 'active',
    DISABLED = 'disabled',
}
