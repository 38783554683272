/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { PropsValue } from 'react-select';
import { OptionType } from 'common/design-system/components-v2/Select';
import { Label, Select, Stack, Typography } from 'common/design-system/components-v2';
import { I18nRemediation } from '../../helpers/remediation.consts';
import { useTranslation } from 'react-i18next';
import RemediationApi from 'common/services/apis/Remediation/remediationApi.services';
import { IRule, RemediateByEntityOptionProps, RuleOption, SelectOption } from '../../helpers/remediation.interfaces';

const RemediateByRule: FC<RemediateByEntityOptionProps> = ({
    isEnabled,
    selectedRuleSet,
    onChange,
    selectedOption,
    formValidations,
}) => {
    const { t } = useTranslation(I18nRemediation);
    const [selectedRule, setSelectedRule] = useState<RuleOption | null>(null);
    const [rulesList, setRulesList] = useState<SelectOption[]>([]);

    useEffect(() => {
        async function fetchRules() {
            if (!selectedRuleSet) {
                handleRuleSetAbsence();
                return;
            }
            try {
                const { data } = await RemediationApi.getComplianceRuleByRuleset(selectedRuleSet.value);
                const formattedRules = formatRules(data.rules);
                updateRulesList(formattedRules);
                handlePreselectedOption(data.rules);
            } catch (error) {
                console.error('Error fetching rules:', error);
                handleFetchingError();
            }
        }

        fetchRules();
    }, [selectedRuleSet, selectedOption]);

    const formatRules = (rules: IRule[]) =>
        rules.map((rule) => ({
            label: rule.name,
            value: rule.logicHash,
        }));

    const updateRulesList = (rules: SelectOption[]) => {
        setRulesList(rules);
    };

    const handleRuleSetAbsence = () => {
        setRulesList([]);
        setSelectedRule(null);
        onChange(null);
    };

    const handlePreselectedOption = (rules: IRule[]) => {
        const selectedRule: any = rules.find((rule: IRule) => {
            return rule.logicHash === selectedOption || rule.ruleId === selectedOption;
        });
        const matchingRule = { label: selectedRule.name, value: selectedRule.logicHash } as RuleOption;
        if (matchingRule) {
            setSelectedRule(matchingRule);
            onChange(matchingRule);
        } else {
            setSelectedRule(null);
            onChange(null);
        }
    };

    const handleFetchingError = () => {
        setRulesList([]);
        setSelectedRule(null);
        onChange(null);
    };

    const onSelectedRule = (newValue: PropsValue<OptionType>) => {
        setSelectedRule(newValue as RuleOption);
        onChange(newValue as RuleOption);
    };

    return (
        // TODO: refactor to SelectV2
        <Stack spacing={1}>
            <Label text={t('REMEDIATION.MODALS.ADD_EDIT_MODAL.REMEDIATE_BY_RULE')} />
            <Select
                isDisabled={!isEnabled}
                options={rulesList}
                isMulti={false}
                value={selectedRule}
                onChange={onSelectedRule}
            />
            {formValidations && (
                <Typography color='alert' variant='bodyXs'>
                    {formValidations?.[0]?.message}
                </Typography>
            )}
        </Stack>
    );
};

export default RemediateByRule;
