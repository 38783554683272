import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { WhereStyled } from '../AutoActionEditorWhere.styled';
import { PreviewCounterCard } from './PreviewCounterCard';
import { ISSUE_FIELD_NAMES } from 'modules/riskManagement/components/Issues/Issues.interface';
import {
    getSafeIssueSeverityInfo,
    IIssueSeverityInfo,
    IssueSeveritiesMap,
} from 'modules/riskManagement/components/Issues/IssueSeverity';
import { IValueCount, LoadingState } from 'common/interface/general';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from 'modules/riskManagement/consts';
import { Label, Spinner, Typography } from 'common/design-system/components-v2';
import { ermTrans } from 'modules/riskManagement/RiskManagement.utils';
import { IconProps } from 'common/design-system/components-v2/Icon/Icon.types';
import { getOrganizationalUnitService, IOrganizationalUnit } from 'common/interface/data_services';
import { DEFAULT_ORGANIZATIONAL_UNIT_ID } from 'common/consts/DataConsts';
import { EditorInfoCard } from '../../../components/EditorInfoCard';
import { IWhereProps } from '../../../AutoActionEditor.interface';
import { IAutoActionPreviewInfo, IFieldNameCounters } from '../../../../AutoActions.interface';
import { fetchPreviewFacets } from '../../../../AutoActions.utils';
import { ICompoundFilterNode } from 'common/erm-components/custom/FilterTree/CompoundFilter';
import { AUTO_ACTION_PREVIEW_FACETS } from '../../../AutoActionEditor.consts';
import { IConditionValueOption, IFacetData } from 'common/erm-components/custom/FilterTree/FilterTree.interface';
import {
    getEntityTypeCondOptions,
    getEnvironmentCondOptions,
    getPlatformCondOptions,
} from '../../../../../../RiskManagement.options';

interface ITitleCount {
    title: string;
    count: number;
}

export interface IPreviewItemInfo {
    text: string;
    iconProps?: IconProps;
    count: number;
}

export interface IPreviewMainFieldInfo {
    title: string;
    field: string;
    itemInfosGetter: (counters: IValueCount[]) => Promise<IPreviewItemInfo[]>;
    itemsBoxTitle: string;
}

export type IValueOptionsGetter = (values: any[]) => Promise<IConditionValueOption[]>;

const getPreviewItemInfo = async (
    counters: IValueCount[],
    optionsGetter: IValueOptionsGetter,
): Promise<IPreviewItemInfo[]> => {
    const infos: IConditionValueOption[] = await optionsGetter(counters.map((counter) => counter.value));
    return counters.map((counter) => {
        const info: IConditionValueOption | undefined = infos.find((anInfo) => anInfo.value === counter.value);
        return {
            text: info?.label || counter.value,
            iconProps: info?.labelProps?.leadingIconProps,
            count: counter.count,
        };
    });
};

const getEntityTypeInfos = async (counters: IValueCount[]): Promise<IPreviewItemInfo[]> => {
    return getPreviewItemInfo(counters, (typeByPlatforms: any[]) =>
        Promise.resolve(getEntityTypeCondOptions(typeByPlatforms)),
    );
};

const getPlatformInfos = async (counters: IValueCount[]): Promise<IPreviewItemInfo[]> => {
    return getPreviewItemInfo(counters, (platformNames: any[]) =>
        Promise.resolve(getPlatformCondOptions(platformNames)),
    );
};

const getRuleInfos = async (counters: IValueCount[]): Promise<IPreviewItemInfo[]> => {
    return counters.map((counter) => {
        return {
            text: counter.value,
            iconProps: { name: 'rule' },
            count: counter.count,
        };
    });
};

const getEnvironmentInfos = async (counters: IValueCount[]): Promise<IPreviewItemInfo[]> => {
    return getPreviewItemInfo(counters, getEnvironmentCondOptions);
};

const getOrgUnitInfos = async (counters: IValueCount[]): Promise<IPreviewItemInfo[]> => {
    const flatOrgUnits: IOrganizationalUnit[] = await getOrganizationalUnitService().getAllOrganizationalUnitsFlat();
    const rootOrgUnit: IOrganizationalUnit = await getOrganizationalUnitService().getOrganizationalUnitsView();
    const allOrgUnits: IOrganizationalUnit[] = [rootOrgUnit, ...flatOrgUnits];
    return counters.map((counter) => {
        const orgUnit: IOrganizationalUnit | undefined = allOrgUnits.find((ou) => {
            if (counter.value) {
                return ou.id === counter.value;
            } else {
                return ou.id === DEFAULT_ORGANIZATIONAL_UNIT_ID;
            }
        });
        return {
            text: orgUnit?.name || counter.value || DEFAULT_ORGANIZATIONAL_UNIT_ID,
            count: counter.count,
        };
    });
};

const getMainFields = (): IPreviewMainFieldInfo[] => [
    {
        title: ermTrans('AUTO_ACTIONS.EDITOR.WHERE.PREVIEW.OUS'),
        field: ISSUE_FIELD_NAMES.ORGANIZATIONAL_UNIT_ID,
        itemsBoxTitle: ermTrans('AUTO_ACTIONS.EDITOR.WHERE.PREVIEW.TOP_OUS'),
        itemInfosGetter: getOrgUnitInfos,
    },
    {
        title: ermTrans('AUTO_ACTIONS.EDITOR.WHERE.PREVIEW.PLATFORMS'),
        field: ISSUE_FIELD_NAMES.PLATFORM,
        itemsBoxTitle: ermTrans('AUTO_ACTIONS.EDITOR.WHERE.PREVIEW.TOP_PLATFORMS'),
        itemInfosGetter: getPlatformInfos,
    },
    {
        title: ermTrans('AUTO_ACTIONS.EDITOR.WHERE.PREVIEW.ENVIRONMENTS'),
        field: ISSUE_FIELD_NAMES.ENVIRONMENT_ID,
        itemsBoxTitle: ermTrans('AUTO_ACTIONS.EDITOR.WHERE.PREVIEW.TOP_ENVIRONMENTS'),
        itemInfosGetter: getEnvironmentInfos,
    },
    {
        title: ermTrans('AUTO_ACTIONS.EDITOR.WHERE.PREVIEW.ENTITY_TYPES'),
        field: ISSUE_FIELD_NAMES.ENTITY_TYPE_BY_PLATFORM,
        itemsBoxTitle: ermTrans('AUTO_ACTIONS.EDITOR.WHERE.PREVIEW.TOP_ENTITY_TYPES'),
        itemInfosGetter: getEntityTypeInfos,
    },
    {
        title: ermTrans('AUTO_ACTIONS.EDITOR.WHERE.PREVIEW.RULES'),
        field: ISSUE_FIELD_NAMES.RULE_TITLE,
        itemsBoxTitle: ermTrans('AUTO_ACTIONS.EDITOR.WHERE.PREVIEW.TOP_RULES'),
        itemInfosGetter: getRuleInfos,
    },
];

const getFacetValueCounters = (fieldName: string, previewInfo: IAutoActionPreviewInfo): IValueCount[] => {
    const facet: IFacetData | undefined = previewInfo.facets.find((facetDetails) => facetDetails.facet === fieldName);
    return facet?.data || [];
};

const getFacetDistinctCount = (fieldName: string, previewInfo: IAutoActionPreviewInfo): number => {
    const fieldNameCounter: IFieldNameCounters | undefined = previewInfo.distinctValuesCount.find(
        (distinctValue) => distinctValue.fieldName === fieldName,
    );
    return fieldNameCounter?.count ?? 0;
};

export const PreviewBox: React.FC<{
    whereProps: IWhereProps;
}> = ({ whereProps }) => {
    const { t } = useTranslation(I18nRiskNamespace);
    const [previewInfo, setPreviewInfo] = useState<IAutoActionPreviewInfo | undefined>();
    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.IS_LOADING);

    const isStalePreview = useCallback(() => {
        return whereProps.hasErrors && !whereProps.ignoreInvalidConditions;
    }, [whereProps.hasErrors, whereProps.ignoreInvalidConditions]);

    const loadPreviewInfo = useCallback(async () => {
        if (isStalePreview()) {
            return;
        }
        setLoadingState(LoadingState.IS_LOADING);
        const serverFilter: ICompoundFilterNode | undefined = whereProps.hasErrors
            ? whereProps.validServerFilter
            : whereProps.filter?.root;
        fetchPreviewFacets(AUTO_ACTION_PREVIEW_FACETS, serverFilter, whereProps.orgUnitIds)
            .then((info: IAutoActionPreviewInfo) => {
                setPreviewInfo(info);
                setLoadingState(LoadingState.LOADING_SUCCEEDED);
            })
            .catch(() => {
                setLoadingState(LoadingState.LOADING_FAILED);
            });
    }, [isStalePreview, whereProps.filter, whereProps.hasErrors, whereProps.orgUnitIds, whereProps.validServerFilter]);

    const severityCounters: ITitleCount[] = useMemo(() => {
        if (!previewInfo) {
            return [];
        }
        const titleCounters: ITitleCount[] = [];
        const severityCounters: IValueCount[] = getFacetValueCounters(ISSUE_FIELD_NAMES.SEVERITY, previewInfo);
        for (let level = IssueSeveritiesMap.critical.level; level >= IssueSeveritiesMap.low.level; level--) {
            const severityInfo: IIssueSeverityInfo = getSafeIssueSeverityInfo(level);
            const counter: IValueCount | undefined = severityCounters.find(
                (valueCount) => Number(valueCount.value) === level,
            );
            if (counter) {
                titleCounters.push({
                    title: severityInfo.displayText,
                    count: counter.count,
                });
            } else {
                titleCounters.push({
                    title: severityInfo.displayText,
                    count: 0,
                });
            }
        }
        return titleCounters;
    }, [previewInfo]);

    useEffect(() => {
        void loadPreviewInfo();
    }, [whereProps, loadPreviewInfo]);

    return (
        <WhereStyled.PreviewTopDiv>
            <WhereStyled.PreviewTitleDiv>
                <Label text={t('AUTO_ACTIONS.EDITOR.WHERE.PREVIEW.PREVIEW_AREA_TITLE')} />
            </WhereStyled.PreviewTitleDiv>
            <EditorInfoCard
                title={t('AUTO_ACTIONS.EDITOR.WHERE.PREVIEW.PREVIEW_IMPORTANCE_TITLE')}
                text={t('AUTO_ACTIONS.EDITOR.WHERE.PREVIEW.PREVIEW_IMPORTANCE_MSG')}
            />
            {loadingState === LoadingState.LOADING_FAILED && (
                <Typography color={'alert'}>{t('AUTO_ACTIONS.EDITOR.WHERE.PREVIEW.LOAD_ERROR_OCCURRED')}</Typography>
            )}
            {loadingState === LoadingState.IS_LOADING && (
                <WhereStyled.PreviewBoxesContainer>
                    <Spinner size={24} />
                </WhereStyled.PreviewBoxesContainer>
            )}
            {loadingState === LoadingState.LOADING_SUCCEEDED && previewInfo && (
                <WhereStyled.PreviewBoxesContainer>
                    <WhereStyled.PreviewLeftDiv>
                        <PreviewCounterCard
                            title={t('AUTO_ACTIONS.EDITOR.WHERE.PREVIEW.TOTAL_ISSUES')}
                            count={previewInfo.totalCount || 0}
                            variant='h1'
                            isStale={isStalePreview()}
                        />
                    </WhereStyled.PreviewLeftDiv>
                    <WhereStyled.PreviewRightDiv>
                        <WhereStyled.PreviewRightTopDiv>
                            {severityCounters.map((severityCount) => (
                                <PreviewCounterCard
                                    title={severityCount.title}
                                    count={severityCount.count}
                                    key={severityCount.title}
                                    isStale={isStalePreview()}
                                />
                            ))}
                        </WhereStyled.PreviewRightTopDiv>
                        <WhereStyled.PreviewRightBottomDiv>
                            {getMainFields().map((fieldInfo) => (
                                <PreviewCounterCard
                                    title={fieldInfo.title}
                                    key={fieldInfo.title}
                                    count={getFacetDistinctCount(fieldInfo.field, previewInfo)}
                                    counters={getFacetValueCounters(fieldInfo.field, previewInfo)}
                                    fieldInfo={fieldInfo}
                                    isStale={isStalePreview()}
                                />
                            ))}
                        </WhereStyled.PreviewRightBottomDiv>
                    </WhereStyled.PreviewRightDiv>
                </WhereStyled.PreviewBoxesContainer>
            )}
        </WhereStyled.PreviewTopDiv>
    );
};
