import { ICellRendererParams } from 'ag-grid-community';
import { Spinner } from 'common/design-system/components-v2';
import useAllCloudAccounts from 'common/hooks/useAllCloudAccounts';
import { ICloudAccount } from 'common/interface/data_services';
import { IEvent } from 'common/module_interface/events/Events';
import React from 'react';

export const EnvironmentName: React.FC<ICellRendererParams<IEvent>> = ({ data: { cloudAccountId } = {} }) => {
    const [cloudAccount, setCloudAccount] = React.useState<ICloudAccount>();
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const { getByAccountId } = useAllCloudAccounts();

    React.useEffect(() => {
        if (!cloudAccountId) return;
        const getAndSetCloudAccount = async (cloudAccountId: string) => {
            const account = await getByAccountId(cloudAccountId);
            if (account) {
                setCloudAccount(account);
            }
            setIsLoading(false);
        };
        getAndSetCloudAccount(cloudAccountId);
    }, [cloudAccountId, getByAccountId]);

    if (!cloudAccountId) {
        return null;
    }

    if (isLoading) {
        return <Spinner size={12} />;
    }

    return <span data-value={cloudAccountId}>{cloudAccount?.name || cloudAccountId}</span>;
};
