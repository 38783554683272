export const CDR_EXCLUSIONS_SERVICE_ID = 'CdrExclusionsService';
export const CDR_EXCLUSIONS_URL = 'exclusions';

export const CDR_EXCLUSIONS_PAGE_TABLE_ID = 'CDR_EXCLUSIONS_PAGE_TABLE_ID';

export enum CdrExclusionItemNames {
    rulesetId = 'rulesetId',
    comment = 'comment',
    orgUnits = 'orgUnits',
    environments = 'environments',
    dateRange = 'dateRange',
    expirationDate = 'expirationDate',
    rules = 'rules',
    entityNames = 'entityNames',
    entityId = 'entityId',
    severities = 'severities',
    srcIpCidr = 'srcIpCidr',
    srcSavedIp = 'srcSavedIp',
    srcPort = 'srcPort',
    destIpCidr = 'destIpCidr',
    destSavedIp = 'destSavedIp',
    destPort = 'destPort',
    orgEnvRadio = 'orgEnvRadio',
    ruleRadio = 'ruleRadio',
    entityRadio = 'entityRadio',
    srcIpRadio = 'srcIpRadio',
    destIpRadio = 'destIpRadio',
    orgUnitsRadioOption = 'orgUnitsRadioOption',
    environmentsRadioOption = 'environmentsRadioOption',
    rulesRadioOption = 'rulesRadioOption',
    severitiesRadioOption = 'severitiesRadioOption',
    entityNamesRadioOption = 'entityNamesRadioOption',
    entityIdRadioOption = 'entityIdRadioOption',
    srcIpCidrRadioOption = 'srcIpCidrRadioOption',
    srcSavedIpRadioOption = 'srcSavedIpRadioOption',
    destIpCidrRadioOption = 'destIpCidrRadioOption',
    destSavedIpRadioOption = 'destSavedIpRadioOption',
}

export enum CDR_EXCLUSION_FIELD_NAMES {
    ID = 'id',
    RULESET_ID = 'rulesetId',
    RULESET_NAME = 'rulesetName',
    COMMENT = 'comment',
    RULE_IDS = 'ruleIds',
    SEVERITIES = 'severities',
    ORG_UNIT_IDS = 'orgUnitIds',
    ENV_IDS = 'envIds',
    ENTITY_NAMES = 'entityNames',
    ENTITY_ID = 'entityId',
    DATE_RANGE = 'dateRange',
    EXPIRATION_DATE = 'expirationDate',
}
