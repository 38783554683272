import { IApplicationReducer } from 'common/interface/redux';
import AzureIntelligenceOnboardingReducer from './AzureIntelligenceOnboarding.reducer';
import { getAppRootRegistry } from 'common/interface/services';
import { lazy } from 'react';

const azureIntelligenceOnboardingPageRoute = () => {
    return {
        condition: true,
        component: lazy(() => import('./AzureIntelligenceOnboarding')),
        path: '/intelligence/azure-onboarding/:accountName?/:subscription?/:type?/:cloudAccountId?',
    };
};

export const initializeAzureIntelligenceOnboardingPages = () => {
    const reducers: IApplicationReducer[] = [
        { name: 'azureIntelligenceOnboarding', reducer: AzureIntelligenceOnboardingReducer, isBlackList: true },
    ];

    getAppRootRegistry().addReducers(reducers, 'name');

    getAppRootRegistry().addRoutes([
        {
            id: 'Azure Intelligence Onboarding Page',
            content: azureIntelligenceOnboardingPageRoute,
        },
    ]);
};
