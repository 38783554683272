import { IActionUsageDef } from 'common/interface/general';
import { FindingsActions, ModalType } from '../../Findings.const';
import { UserRolesTypes } from 'common/enum/UserRoles';
import FixItAction from '../Actions/FixItAction/FixItAction';
import { CDR_REPORT_FORM_POC_ACTIVE_FEATURE } from '../../../ActiveFeatures/ActiveFeatures.consts';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { showCdrExclusionForm } from '../Modals/CdrExclusion/CdrExclusion.items';
import { cdrTrans } from '../../../Intelligence.utils';
import { isAfEnabled } from 'common/utils/debugUtils';
import { showCdrReportForm } from '../Modals/ReportIssueModal/ReportIssue.items';
import { isNil } from 'common/utils/helpFunctions';
import { IFindingModelStatus } from 'common/components/Findings/Findings.interface';
import { IEvent } from 'common/module_interface/events/Events';
import { CommonEventFields } from 'common/module_interface/events/EventsConsts';

export const createFixItAction = (
    tableId: string,
    openDialog: (dialogType: ModalType | null) => void,
): IActionUsageDef => {
    return {
        id: FindingsActions.FIX_IT,
        permission: [UserRolesTypes.SUPER_USER],
        actionDefOverride: {
            customComponent: (selectedRows: IEvent[]) => {
                return (
                    <FixItAction tableId={tableId} selectedRows={selectedRows} openDialog={openDialog}></FixItAction>
                );
            },
        },
    };
};

export const createRemediateCdrAction = (openDialog: (dialogType: ModalType | null) => void): IActionUsageDef => {
    return {
        id: FindingsActions.REMEDIATE_CDR,
        permission: [UserRolesTypes.SUPER_USER],
        actionDefOverride: {
            callback: () => {
                openDialog(ModalType.RemediateCdr);
            },
        },
    };
};

export const createRemediateCiemAction = (openDialog: (dialogType: ModalType | null) => void): IActionUsageDef => {
    return {
        id: FindingsActions.REMEDIATE_CIEM,
        permission: [UserRolesTypes.SUPER_USER],
        actionDefOverride: {
            callback: () => {
                openDialog(ModalType.RemediateCiem);
            },
        },
    };
};

export const createExcludeCdrAction = (): IActionUsageDef => {
    return {
        id: FindingsActions.EXCLUDE_CDR,
        permission: [UserRolesTypes.SUPER_USER],
        actionDefOverride: {
            callback: (selectedRows: IKustoEvent[]) => {
                if (selectedRows.length === 1) {
                    const finding: IKustoEvent = selectedRows[0];
                    showCdrExclusionForm({
                        name: finding.ruleName,
                        comment: cdrTrans('CDR_EXCLUSIONS.EDITOR.COMMENT_BY_RULE_PREFIX', { title: finding.ruleName }),
                        rulesetId: finding.bundleId ? String(finding.bundleId) : '',
                        ruleIds: [finding.ruleId],
                        envIds: [finding[CommonEventFields.cloudAccountExternalId]],
                        entityNames: finding.entityName ? [finding.entityName] : undefined,
                    });
                }
            },
        },
    };
};

export const createExcludeCiemAction = (openDialog: (dialogType: ModalType | null) => void): IActionUsageDef => {
    return {
        id: FindingsActions.EXCLUDE_CIEM,
        permission: [UserRolesTypes.SUPER_USER],
        actionDefOverride: {
            callback: () => {
                openDialog(ModalType.ExcludeCiem);
            },
        },
    };
};

export const createReportIssueAction = (openDialog: (dialogType: ModalType | null) => void): IActionUsageDef => {
    return {
        id: FindingsActions.REPORT_ISSUE,
        permission: [UserRolesTypes.SUPER_USER],
        actionDefOverride: {
            callback: (selectedRows: IKustoEvent[]) => {
                if (isAfEnabled(CDR_REPORT_FORM_POC_ACTIVE_FEATURE.key)) {
                    if (selectedRows.length > 0) {
                        showCdrReportForm(selectedRows);
                    }
                } else {
                    openDialog(ModalType.ReportIssue);
                }
            },
        },
    };
};

export const createAcknowledgeAction = (openDialog: (dialogType: ModalType | null) => void): IActionUsageDef => {
    return {
        id: FindingsActions.ACKNOWLEDGE,
        permission: [UserRolesTypes.SUPER_USER],
        actionDefOverride: {
            callback: () => {
                openDialog(ModalType.Acknowledge);
            },
            buttonProps: (selectedFindings: IKustoEvent[]) => {
                const shouldAcknowledge: boolean =
                    selectedFindings.some((finding: IKustoEvent) => !finding.acknowledged) ||
                    selectedFindings.length === 0;
                const termPrefix: string = shouldAcknowledge ? 'ACKNOWLEDGE' : 'UN_ACKNOWLEDGE';
                return {
                    iconProps: { name: 'acknowledge' },
                    label: cdrTrans(`FINDINGS_TABLE.ACTIONS.${termPrefix}.TITLE`),
                    tooltip: cdrTrans(`FINDINGS_TABLE.ACTIONS.${termPrefix}.TOOLTIP`),
                };
            },
        },
    };
};

export const createCommentAction = (openDialog: (dialogType: ModalType | null) => void): IActionUsageDef => {
    return {
        id: FindingsActions.COMMENT,
        permission: [UserRolesTypes.SUPER_USER],
        actionDefOverride: {
            callback: () => {
                openDialog(ModalType.Comment);
            },
        },
    };
};

export const createCloseAction = (openDialog: (dialogType: ModalType | null) => void): IActionUsageDef => {
    return {
        id: FindingsActions.CLOSE,
        permission: [UserRolesTypes.SUPER_USER],
        actionDefOverride: {
            callback: () => {
                openDialog(ModalType.Close);
            },
        },
    };
};

export const createChangeSeverityAction = (openDialog: (dialogType: ModalType | null) => void): IActionUsageDef => {
    return {
        id: FindingsActions.CHANGE_SEVERITY,
        permission: [UserRolesTypes.SUPER_USER],
        actionDefOverride: {
            callback: () => {
                openDialog(ModalType.ChangeSeverity);
            },
        },
    };
};

export const createAssignUserAction = (openDialog: (dialogType: ModalType | null) => void): IActionUsageDef => {
    return {
        id: FindingsActions.ASSIGN_USER,
        permission: [UserRolesTypes.SUPER_USER],
        actionDefOverride: {
            callback: () => {
                openDialog(ModalType.AssignUser);
            },
        },
    };
};

export const createArchiveAction = (
    openDialog: (dialogType: ModalType | null) => void,
    isArchiveView?: boolean,
): IActionUsageDef => {
    const termPrefix: string = isArchiveView ? 'UN_ARCHIVE' : 'ARCHIVE';
    return {
        id: FindingsActions.ARCHIVE,
        permission: [UserRolesTypes.SUPER_USER],
        actionDefOverride: {
            callback: () => {
                openDialog(ModalType.Archive);
            },
            name: cdrTrans(`FINDINGS_TABLE.ACTIONS.${termPrefix}.TITLE`),
            buttonProps: (selectedFindings: IKustoEvent[]) => {
                const shouldArchive: boolean =
                    selectedFindings.some((finding: IKustoEvent) => finding.status !== IFindingModelStatus.Archived) ||
                    selectedFindings.length === 0;
                const prefix: string = isNil(isArchiveView)
                    ? shouldArchive
                        ? 'ARCHIVE'
                        : 'UN_ARCHIVE'
                    : isArchiveView
                      ? 'UN_ARCHIVE'
                      : 'ARCHIVE';
                return {
                    iconProps: { name: 'archive' },
                    label: cdrTrans(`FINDINGS_TABLE.ACTIONS.${prefix}.TITLE`),
                    tooltip: cdrTrans(`FINDINGS_TABLE.ACTIONS.${prefix}.TOOLTIP`),
                };
            },
        },
    };
};
