import React from 'react';
import { getComplianceFindingApiService, IEvent } from 'common/module_interface/events/Events';
import { ComplianceFindingArchive } from 'common/module_interface/events/complianceFindingApi.interface';

export interface UseArchiveEventRequest {
    onSuccess?: (response: ComplianceFindingArchive.Response) => void;
    onError?: (error: string) => void;
}

export interface UseArchiveEventPayload {
    isLoading: boolean;
    isError: boolean;
    results?: ComplianceFindingArchive.Response;

    archiveEvents: (events: IEvent[]) => Promise<void>;
    reset?: () => void;
}

export type UseArchiveEventReset = (req?: UseArchiveEventRequest) => UseArchiveEventPayload;

export const useArchiveEvent: UseArchiveEventReset = (req) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isError, setIsError] = React.useState<boolean>(false);
    const [results, setResults] = React.useState<ComplianceFindingArchive.Response>();

    const archiveEvents = React.useCallback(
        async (events: IEvent[]) => {
            setIsError(false);
            setIsLoading(true);
            try {
                const response = await getComplianceFindingApiService().archive({
                    ids: events.map((event) => event.id),
                });
                setResults(response.data);
                req?.onSuccess?.(response.data);
            } catch (error: any) {
                setIsError(true);
                req?.onError?.(error?.message || 'An error occurred');
            } finally {
                setIsLoading(false);
            }
        },
        [req],
    );

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setIsError(false);
        setResults(undefined);
    }, []);

    return {
        isLoading,
        isError,
        results,

        archiveEvents,
        reset,
    };
};
