import { ActiveFeaturesRegistry } from 'common/registries/ActiveFeatureManager/ActiveFeaturesRegistry';
import { CDR_ACTIVE_FEATURES, CDR_TOPIC_ID } from './ActiveFeatures.consts';

export default function initializeActiveFeatures() {
    ActiveFeaturesRegistry.addActiveFeatureTopic({
        key: CDR_TOPIC_ID,
        title: 'CDR',
        icon: 'microscope',
    });
    ActiveFeaturesRegistry.addActiveFeatureInfos(CDR_ACTIVE_FEATURES);
}
