import { Stack } from 'common/design-system/components-v2';
import UpperPanel from './UpperPanel';
import Description from './Description';
import Remediation from './Remediation';

const RuleContent: React.FC = () => {
    return (
        <Stack spacing={4}>
            <UpperPanel />
            <Description />
            <Remediation />
        </Stack>
    );
};

export default RuleContent;
