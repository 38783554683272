import React from 'react';
import { getComplianceFindingApiService, IEvent } from 'common/module_interface/events/Events';
import { ComplianceFindingComment } from 'common/module_interface/events/complianceFindingApi.interface';

export interface UseMutateEventCommentRequest {
    onSuccess?: (response: ComplianceFindingComment.Response) => void;
    onError?: (error: string) => void;
}

export interface UseMutateEventCommentPayload {
    isLoading: boolean;
    isError: boolean;
    results?: ComplianceFindingComment.Response;

    addComment: (events: IEvent[], comment: string) => Promise<void>;
    reset?: () => void;
}

export type UseMutateEventCommentReset = (req?: UseMutateEventCommentRequest) => UseMutateEventCommentPayload;

export const useMutateEventComment: UseMutateEventCommentReset = (req) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isError, setIsError] = React.useState<boolean>(false);
    const [results, setResults] = React.useState<ComplianceFindingComment.Response>();

    const addComment = React.useCallback(
        async (events: IEvent[], comment: string) => {
            setIsError(false);
            setIsLoading(true);
            try {
                const response = await getComplianceFindingApiService().addComment({
                    ids: events.map((event) => event.id),
                    details: { text: comment },
                });
                setResults(response.data);
                req?.onSuccess?.(response.data);
            } catch (error: any) {
                setIsError(true);
                req?.onError?.(error?.message || 'An error occurred');
            } finally {
                setIsLoading(false);
            }
        },
        [req],
    );

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setIsError(false);
        setResults(undefined);
    }, []);

    return {
        isLoading,
        isError,
        results,

        addComment,
        reset,
    };
};
