import {
    getAssetTypeDisplayData,
    getCloudAccountIdDisplayData,
    getOrganizationalUnitDisplayData,
} from 'common/components/Widgets/DataGetters';
import { IAggregationSettings, IWidgetsSettings } from 'common/module_interface/overview/Interface';
import { listWidgetAdditionalFields } from 'common/components/Widgets/List/ListWidgetAdditionalFields';
import WidgetList from 'common/components/Widgets/List/List';
import WidgetPieChart from 'common/components/Widgets/Pie/PieWidget';
import WidgetColumn from 'common/components/Widgets/Column/ColumnWidget';
import {
    getNetworkDisplayData,
    getOCIDisplayData,
    getResourceGroupDisplayData,
    getRegionDisplayData,
    getPlatformDisplayData,
} from './WidgetsData/ProtectedAssetsDataGetter';

export const aggregationTypesOptions: IAggregationSettings = {
    platform: { dataId: 'platform', displayName: 'Platform', dataGetter: getPlatformDisplayData },
    region: { dataId: 'region', displayName: 'Region', dataGetter: getRegionDisplayData },
    resourceGroup: {
        dataId: 'resourceGroup',
        displayName: 'Azure Resource Group',
        dataGetter: getResourceGroupDisplayData,
    },
    network: { dataId: 'network', displayName: 'Network', dataGetter: getNetworkDisplayData },
    compartment: { dataId: 'compartment', displayName: 'Oci compartment', dataGetter: getOCIDisplayData },
    type: { dataId: 'type', displayName: 'Asset type', dataGetter: getAssetTypeDisplayData },
    cloudAccountId: { dataId: 'cloudAccountId', displayName: 'Environment', dataGetter: getCloudAccountIdDisplayData },
    organizationalUnitId: {
        dataId: 'organizationalUnitId',
        displayName: 'Organizational Unit',
        dataGetter: getOrganizationalUnitDisplayData,
    },
};

export const widgetsTypesOptions: IWidgetsSettings = {
    pie: { component: WidgetPieChart, displayName: 'Pie', key: 'pie' },
    column: { component: WidgetColumn, displayName: 'Columnm' },
    top: {
        component: WidgetList,
        displayName: 'Top',
        additionalFields: listWidgetAdditionalFields,
        dataOrder: 'asc',
        key: 'top',
    },
};
