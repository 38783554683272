import React from 'react';
import useModalFromUrl from 'common/hooks/useModalFromUrl';
import { EventsModalProps, EventsModalsRegistry } from 'common/module_interface/events/EventsModalsRegistry';
import { ErrorBoundary } from 'react-error-boundary';

const Modals: React.FC<EventsModalProps> = (props) => {
    const { modalType, setModalType, closeModal } = props;
    const { modalTypeFromUrl } = useModalFromUrl();

    React.useEffect(() => {
        if (modalTypeFromUrl && !modalType) {
            setModalType(modalTypeFromUrl);
        }
    }, [modalType, modalTypeFromUrl, setModalType]);

    const relevantModal = React.useMemo<React.ReactNode>(() => {
        const modalsFromRegistry = EventsModalsRegistry.getModals();
        const relevant = modalsFromRegistry.find(({ id }) => id === modalType);

        if (!relevant) {
            closeModal();
            return null;
        }

        return (
            <ErrorBoundary fallbackRender={() => null} onError={closeModal}>
                <relevant.modalComponent {...props} closeModal={closeModal} additionalData={relevant.additionalData} />;
            </ErrorBoundary>
        );
    }, [props, closeModal, modalType]);

    return relevantModal;
};

export default Modals;
