import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { PATH_RULE_AGGREGATION_FILTERS } from 'common/module_interface/events/Events';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';

const filters: IProtectedAssetFilter[] = [
    {
        id: 'add filter',
        position: 10,
    },
    {
        id: 'free text',
    },
    {
        id: 'date-picker',
    },
    {
        id: 'organizationalUnit',
    },
    {
        id: 'severity',
    },
    {
        id: 'cloudAccountId_calc',
    },
    {
        id: 'alertType',
    },
    {
        id: 'acknowledged',
    },
    {
        id: 'cloudAccountType',
    },
    {
        id: 'entityType',
    },
    {
        id: 'isExcluded',
    },
    {
        id: 'origin',
    },
    {
        id: 'labels',
    },
    {
        id: 'entityTypeByEnvironmentType',
    },
    {
        id: 'action',
    },
    {
        id: 'ownerUserName',
    },
    {
        id: 'ruleName',
    },
    {
        id: 'bundleName',
    },
    {
        id: 'entityNetwork',
    },
    {
        id: 'category',
    },
    {
        id: 'region',
    },
    {
        id: 'entity',
    },
    {
        id: 'clear all',
    },
];

export default function initializeRuleAggregationFilters() {
    globalAddinContainer.addMap(PATH_RULE_AGGREGATION_FILTERS, filters);
}
