import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { CVE_FIELD_NAMES } from 'modules/riskManagement/components/Issues/Issues.interface';
import { IssuesRegistry } from 'common/module_interface/RiskManagement/issues/IssuesRegistry';
import { ermTrans } from 'modules/riskManagement/RiskManagement.utils';
import { BooleanBulletCellRender } from 'common/components/ag-grid/Renderers/BooleanBulletCellRender';
import { CveIdCellRenderer } from './CveIdCellRenderer/CveIdCellRenderer';
import { IssueEPSSCellRenderer } from 'modules/riskManagement/components/Issues/cellRenderers/IssueEPSSCellRenderer';
import { IssueCVSSCellRenderer } from 'modules/riskManagement/components/Issues/cellRenderers/IssueCVSSCellRenderer';
import { ExternalLinkHeaderRenderer } from 'modules/riskManagement/components/Issues/headerRenderers/ExternalLinkHeaderRenderer';
import { IssueSeverityCellRender } from 'modules/riskManagement/renderers/IssueSeverityCellRender';
import { IGNORE_CVE_ACTION_COLUMN } from '../../../../Issues.consts';
import { IgnoreCveActionRenderer } from '../../../../cellRenderers/IgnoreCveActionRenderer';

export const initializeVulnerabilitiesTableColumns = () => {
    const columnDefs: CGColDef[] = [
        {
            colId: CVE_FIELD_NAMES.CVE_ID,
            field: CVE_FIELD_NAMES.CVE_ID,
            headerName: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.COLUMNS.ID.TITLE'),
            headerTooltip: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.COLUMNS.ID.TOOLTIP'),
            cellRenderer: CveIdCellRenderer,
        },
        {
            colId: CVE_FIELD_NAMES.SEVERITY,
            field: CVE_FIELD_NAMES.SEVERITY,
            headerName: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.COLUMNS.SEVERITY.TITLE'),
            headerTooltip: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.COLUMNS.SEVERITY.TOOLTIP'),
            cellRenderer: IssueSeverityCellRender,
        },
        {
            colId: CVE_FIELD_NAMES.CVSS_SCORE,
            field: CVE_FIELD_NAMES.CVSS_SCORE,
            headerName: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.COLUMNS.CVSS_SCORE.TITLE'),
            headerTooltip: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.COLUMNS.CVSS_SCORE.TOOLTIP'),
            cellRenderer: IssueCVSSCellRenderer,
        },
        {
            colId: CVE_FIELD_NAMES.EPSS_SCORE,
            field: CVE_FIELD_NAMES.EPSS_SCORE,
            headerTooltip: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.COLUMNS.EPSS_SCORE.TOOLTIP'),
            cellRenderer: IssueEPSSCellRenderer,
            headerComponent: ExternalLinkHeaderRenderer,
            headerComponentParams: {
                headerName: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.COLUMNS.EPSS_SCORE.TITLE'),
                tooltipText: ermTrans(
                    'ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.COLUMNS.EPSS_SCORE.TOOLTIP_CONTENT_TEXT',
                ),
                url: 'https://www.first.org/epss/',
            },
        },
        {
            colId: CVE_FIELD_NAMES.BASE_SCORE_VECTOR,
            field: CVE_FIELD_NAMES.BASE_SCORE_VECTOR,
            headerName: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.COLUMNS.ATTACK_VECTOR.TITLE'),
            headerTooltip: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.COLUMNS.ATTACK_VECTOR.TOOLTIP'),
        },
        {
            colId: CVE_FIELD_NAMES.FIXABLE,
            field: CVE_FIELD_NAMES.FIXABLE,
            headerName: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.COLUMNS.FIXABLE.TITLE'),
            headerTooltip: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.COLUMNS.FIXABLE.TOOLTIP'),
            cellRenderer: BooleanBulletCellRender,
        },
        {
            colId: CVE_FIELD_NAMES.KNOWN_EXPLOIT,
            field: CVE_FIELD_NAMES.KNOWN_EXPLOIT,
            headerName: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.COLUMNS.KNOWN_EXPLOIT.TITLE'),
            headerTooltip: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.COLUMNS.KNOWN_EXPLOIT.TOOLTIP'),
            cellRenderer: BooleanBulletCellRender,
        },
        {
            colId: IGNORE_CVE_ACTION_COLUMN,
            cellRenderer: IgnoreCveActionRenderer,
        },
    ];
    IssuesRegistry.addCvesColumnDefs(columnDefs, 'colId');
};
