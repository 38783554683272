import React from 'react';
import { Controller, RegisterOptions, useForm } from 'react-hook-form';
import { EventsModalOpenFrom, EventsModalProps } from 'common/module_interface/events/EventsModalsRegistry';
import { useTranslation } from 'react-i18next';
import { Message, SelectV2, Stack, TextArea } from 'common/design-system/components-v2';
import { getEventsNamespace } from 'modules/events/initialize.i18n';
import { LogLevel, getLoggerService, getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';

interface FormValues {
    comment: string;
    issue_type?: string;
}

const issueTypeOptions = ['FALSE_POSITIVE', 'WRONG_SEVERITY', 'REMEDIATION_ISSUE', 'INCORRENT_INFORMATION', 'OTHER'];

const ReportAnIssueAction: React.FC<EventsModalProps> = ({ closeModal, selectedRows, resetSelectedRows, openFrom }) => {
    const { t } = useTranslation(getEventsNamespace('actions'));

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const onSuccess = () => {
        getNotificationsService().addNotification({
            text: t('REPORT_AN_ISSUE.NOTIFICATIONS.SUCCESS', { count: selectedRows.length }),
            type: NotificationType.SUCCESS,
        });
        if (openFrom === EventsModalOpenFrom.TABLE && resetSelectedRows) {
            resetSelectedRows();
        }
        closeModal();
    };

    const onError = () => {
        getNotificationsService().addNotification({
            text: t('REPORT_AN_ISSUE.NOTIFICATIONS.ERROR', { count: selectedRows.length }),
            type: NotificationType.ERROR,
        });
    };

    const inputValidations: { [input: string]: RegisterOptions } = React.useMemo(
        () => ({
            comment: {
                required: t('REPORT_AN_ISSUE.INPUTS.COMMENT.ERRORS.REQUIRED'),
            },
            issue_type: {
                required: t('REPORT_AN_ISSUE.INPUTS.SEVERITY.ERRORS.REQUIRED'),
            },
        }),
        [t],
    );

    const { handleSubmit, control } = useForm<FormValues>({
        defaultValues: {
            comment: '',
            issue_type: undefined,
        },
    });

    const submitUpdate = async (data: FormValues) => {
        const { comment, issue_type } = data;
        if (!comment || !issue_type) return;
        try {
            setIsLoading(true);
            const messages = selectedRows.map((finding) =>
                JSON.stringify({
                    reportType: issue_type,
                    description: comment,
                    finding,
                }),
            );
            await getLoggerService().log(LogLevel.Info, messages.length === 1 ? messages[0] : messages, 'log', [
                'Finding Alerts Report Tracking',
            ]);
            onSuccess();
        } catch (error) {
            onError();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Message
            title={t('REPORT_AN_ISSUE.TITLE')}
            text={selectedRows.length > 1 ? t('REPORT_AN_ISSUE.TEXT', { count: selectedRows.length }) : undefined}
            submitBtnText={t('REPORT_AN_ISSUE.BUTTONS.SUBMIT')}
            width='lg'
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={handleSubmit((data) => submitUpdate(data))}
            isLoading={isLoading}
            dataAid='events-reportIssue-action'
        >
            <Controller
                name='issue_type'
                rules={inputValidations['issue_type']}
                control={control}
                render={({ field, fieldState }) => (
                    <Stack>
                        <SelectV2
                            autoFocus
                            label={t('REPORT_AN_ISSUE.INPUTS.ISSUE_TYPE.LABEL')}
                            placeholder={t('REPORT_AN_ISSUE.INPUTS.ISSUE_TYPE.PLACEHOLDER')}
                            required
                            fullWidth
                            isMulti={false}
                            options={issueTypeOptions.map((key) => ({
                                label: t(`REPORT_AN_ISSUE.INPUTS.ISSUE_TYPE.OPTIONS.${key}`),
                                value: key,
                            }))}
                            isError={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            {...field}
                        />
                    </Stack>
                )}
            />
            <Controller
                name='comment'
                rules={inputValidations['comment']}
                control={control}
                render={({ field, fieldState }) => (
                    <Stack>
                        <TextArea
                            label={t('CHANGE_SEVERITY.INPUTS.COMMENT.LABEL')}
                            placeholder={t('CHANGE_SEVERITY.INPUTS.COMMENT.PLACEHOLDER')}
                            rows={4}
                            required
                            fullWidth
                            isError={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            {...field}
                        />
                    </Stack>
                )}
            />
        </Message>
    );
};

export default ReportAnIssueAction;
