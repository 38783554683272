import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';
import { IBaseAssetModifier } from './IBaseAssetModifier';
import { BusinessPriorityLevelEnum } from '../../../consts';
import { ImportanceLevelMap } from 'common/consts/ImportanceLevel';
import { ermTrans } from '../../../RiskManagement.utils';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { isNil } from 'common/utils/helpFunctions';

export interface IBusinessPriorityInfo extends IBaseAssetModifier {
    title: string;
    iconName: AllIconsName;
    bg: string;
    fg: string;
}

export const getBusinessPriorityInfosMap = (): { [key in BusinessPriorityLevelEnum]: IBusinessPriorityInfo } => ({
    [BusinessPriorityLevelEnum.unknown]: {
        id: BusinessPriorityLevelEnum.unknown,
        title: ermTrans('RISK_MANAGEMENT.BUSINESS_PRIORITY.TYPES.UNDEFINED'),
        iconName: 'business',
        bg: ImportanceLevelMap.unknown.bg,
        fg: ImportanceLevelMap.unknown.fg,
        level: -1,
    },
    [BusinessPriorityLevelEnum.minorImportance]: {
        id: BusinessPriorityLevelEnum.minorImportance,
        title: ermTrans('RISK_MANAGEMENT.BUSINESS_PRIORITY.TYPES.LOW'),
        iconName: 'business',
        bg: ImportanceLevelMap.low.bg,
        fg: ImportanceLevelMap.low.fg,
        level: 0,
    },
    [BusinessPriorityLevelEnum.important]: {
        id: BusinessPriorityLevelEnum.important,
        title: ermTrans('RISK_MANAGEMENT.BUSINESS_PRIORITY.TYPES.MEDIUM'),
        iconName: 'business',
        bg: ImportanceLevelMap.medium.bg,
        fg: ImportanceLevelMap.medium.fg,
        level: 1,
    },
    [BusinessPriorityLevelEnum.highImportance]: {
        id: BusinessPriorityLevelEnum.highImportance,
        title: ermTrans('RISK_MANAGEMENT.BUSINESS_PRIORITY.TYPES.HIGH'),
        iconName: 'business',
        bg: ImportanceLevelMap.high.bg,
        fg: ImportanceLevelMap.high.fg,
        level: 2,
    },
    [BusinessPriorityLevelEnum.crownJewel]: {
        id: BusinessPriorityLevelEnum.crownJewel,
        title: ermTrans('RISK_MANAGEMENT.BUSINESS_PRIORITY.TYPES.CRITICAL'),
        iconName: 'assetCrownJewel',
        bg: ImportanceLevelMap.critical.bg,
        fg: ImportanceLevelMap.critical.fg,
        level: 3,
    },
});

export const getAllBusinessPriorityLevelInfos = (): IBusinessPriorityInfo[] =>
    Object.values(getBusinessPriorityInfosMap()).sort((a, b) => a.level - b.level);

export const getBusinessPriorityLevelInfos = (): IBusinessPriorityInfo[] =>
    getAllBusinessPriorityLevelInfos().filter((info) => info.level >= 0);

export const getUnknownBusinessPriorityLevelInfo = (): IBusinessPriorityInfo =>
    getBusinessPriorityInfosMap()[BusinessPriorityLevelEnum.unknown];

export function getBusinessPriorityLevelInfo(businessPriority: string | undefined): IBusinessPriorityInfo {
    const levelInfo: IBusinessPriorityInfo | undefined = isNil(businessPriority)
        ? undefined
        : getBusinessPriorityLevelInfos().find((item) => item.id === businessPriority);
    return levelInfo || getUnknownBusinessPriorityLevelInfo();
}

export const getAllBusinessPriorityOptions = (): SelectOption[] => {
    return getAllBusinessPriorityLevelInfos().map((item) => ({
        value: item.id,
        label: item.title,
        labelProps: { leadingIconProps: { name: item.iconName } },
    }));
};
