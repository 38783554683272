import React from 'react';
import RemediationPanel from 'common/components/RemediationPanel';
import { Stack, Typography } from 'common/design-system/components-v2';
import { Expandable } from '../Expandable';
import { RemediationSnippet } from './RemediationItemSection.styled';
import { PermissionItemProps } from '../../ServerlessPermissionSection.types';

export const RemediationItemSection: React.FC<PermissionItemProps> = ({ title, data }) => {
    return (
        <Stack spacing={1}>
            <Typography variant='body500'>Remediation</Typography>
            <RemediationPanel remediation=''>
                <Stack fullWidth direction='column' spacing={2}>
                    <Expandable key={title} title={title}>
                        <RemediationSnippet text={JSON.stringify(data, null, 2)}></RemediationSnippet>
                    </Expandable>
                </Stack>
            </RemediationPanel>
        </Stack>
    );
};
