import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-enterprise';
import { ColumnVisibleEvent } from 'ag-grid-community';
import { getAssessmentData, getAssessmentStatisticsForAllRulesets, getRootOU } from './AssessmentTableDataFetcher';
import i18n from 'i18next';
import { IAssessmentData, IAssessmentTable, IFilters, IGridRow, IStatistics } from './AssessmentInterfaces';
import { IDashboardWidget } from 'common/module_interface/overview/Interface';
import { changeUrl } from 'common/utils/http';
import { Button, Icon } from '@dome9/berries/react-components';
import Papa from 'papaparse';
import { exportFileFromString } from 'common/services/files/FilesExporter';
import { IExportFile } from 'common/services/files/Files.interface';
import RunAssessmentModal, { IRunAssessmentData } from './RunAssessmentModal';
import {
    getCloudAccountsService,
    getOrganizationalUnitService,
    ICloudAccount,
    IOrganizationalUnitTreeNode,
} from 'common/interface/data_services';
import { flattenTree } from 'common/utils/helpFunctions';
import AssessmentTrendModal, { IAssessmentTrendProps } from './AssessmentTrendModal';
import { Spinner } from 'common/design-system/components-v2';

const getFailedTests = (stats: IStatistics) => stats.failedTests - stats.excludedFailedTests;
const getLogicallyTested = (stats: IStatistics) => stats.logicallyTested - stats.excludedTests;
const calculatePassedPercent = (stats: IStatistics) => {
    const passedTests = getLogicallyTested(stats) - getFailedTests(stats);
    const percent = (passedTests / getLogicallyTested(stats)) * 100;
    return Number.isNaN(percent) || percent === 0 ? '-' : percent.toFixed(2) + '%';
};

const setCachedVisibleColumns = (widgetId: string, visibleRulesetColumns: string[]) => {
    localStorage.setItem(widgetId + '__visibleRulesetColumns', JSON.stringify(visibleRulesetColumns));
};
const cachedRulesetVisibleColumns = (widgetId: string) => {
    const storedData = localStorage.getItem(widgetId + '__visibleRulesetColumns');
    if (storedData) {
        return JSON.parse(storedData);
    } else {
        return [-10, -100, -11, -12, -128];
    }
};

const sideBar = {
    toolPanels: [
        {
            id: 'columns',
            labelDefault: i18n.t('GENERAL.CUSTOMIZE'),
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: false,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true,
            },
        },
    ],
    defaultToolPanel: 'columns',
};

function getRowDataWithUniqOUPaths(rowData: IGridRow[]) {
    const uniqueItems: any = {};
    rowData.forEach((item) => {
        const ouPath: string = item.ouPath?.toString() || '';
        if (!uniqueItems[ouPath]) {
            uniqueItems[ouPath] = item;
        }
    });
    return Object.values(uniqueItems);
}

const AssessmentsTableWidget: React.FC<{ widget: IDashboardWidget }> = ({ widget }) => {
    let visibleRulesetColumns = cachedRulesetVisibleColumns(widget.id);
    const thresholds = widget?.options?.thresholds;
    const filterState = widget?.filterState;
    const rowData: IGridRow[] = useMemo(() => [], []);
    const gridRef = useRef<any>(null);
    const [isRunAssessmentModalOpen, setIsRunAssessmentModalOpen] = useState<boolean>(false);
    const [isAssessmentTrendModalOpen, setIsAssessmentTrendModalOpen] = useState<boolean>(false);
    const [runAssessmentData, setRunAssessmentData] = useState<IRunAssessmentData>({ rulesetId: 0 });
    const [assessmentTrendData, setAssessmentTrendData] = useState<IAssessmentTrendProps>({
        rulesetId: 0,
        cloudAccountId: '',
        rulesetName: '',
        cloudAccountName: '',
    });
    const [cloudAccountsData, setCloudAccountsData] = useState<ICloudAccount[]>([]);
    const [flatOUs, setFlatOUs] = useState<any[]>([]);
    const [rootOU, setRootOU] = useState<{ path: string; id: string; name: string }>({ path: '', id: '', name: '' });
    const [OUTreeData, setOUTreeData] = useState<IOrganizationalUnitTreeNode[]>([]);
    const [loadingStatusMessage, setloadingStatusMessage] = useState<string>();
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [data, setData] = useState<IAssessmentData>();
    const [columnDefs, setColumnDefs] = useState<ColDef[]>([{ field: 'ouDisplayName' }]);
    const tableFilters = useRef<IFilters>();
    function buildFilters(
        filterState: { name: string; value: string | number }[],
        predefinedRulesetIds: number[] = [],
    ) {
        const filters: IFilters = {
            organizationalUnitIds: [],
            rulesetIds: [],
            cloudAccountIds: [],
            platforms: [],
        };
        const platformNamesMap = {
            get: (name: string) => {
                switch (name) {
                    case 'gcp':
                        return 'google';
                    default:
                        return name;
                }
            },
        };
        if (filterState) {
            filterState.forEach((filter) => {
                if (filter?.value) {
                    switch (filter.name) {
                        case 'organizationalUnitId':
                            filters?.organizationalUnitIds?.push(filter?.value.toString());
                            break;
                        case 'cloudAccountId':
                            filters?.cloudAccountIds?.push(filter?.value.toString());
                            break;
                        case 'account':
                            filters?.cloudAccountIds?.push(filter?.value.toString());
                            break;
                        case 'platform':
                            filters?.platforms?.push(platformNamesMap.get(filter?.value.toString()));
                            break;
                    }
                }
            });
        }
        filters.rulesetIds = [...filters.rulesetIds!, ...predefinedRulesetIds];
        return filters;
    }

    const openRunAssessmentModal = () => {
        setIsRunAssessmentModalOpen(true);
    };

    const openAssessmentTrendModal = () => {
        setIsAssessmentTrendModalOpen(true);
    };

    const closeRunAssessmentModal = () => {
        setIsRunAssessmentModalOpen(false);
    };

    const closeAssessmentTrendModal = () => {
        setIsAssessmentTrendModalOpen(false);
    };

    const loadData = async () => {
        try {
            setloadingStatusMessage(i18n.t('HTTP.LOADING_DATA'));
            tableFilters.current = buildFilters(filterState, visibleRulesetColumns);
            setData(await getAssessmentData(tableFilters.current));
            const cloudAccounts = await getCloudAccountsService().getAllCloudAccounts();
            setCloudAccountsData(cloudAccounts);
            setRootOU(await getRootOU());
            setFlatOUs(await getOrganizationalUnitService().getAllOrganizationalUnitsFlat());
            setOUTreeData(await getOrganizationalUnitService().getAllOrganizationalUnits());
            setloadingStatusMessage('');
        } catch {
            setloadingStatusMessage(i18n.t('HTTP.LOADING_DATA_ERROR'));
        }
    };

    const getAllChildrenOuIds = (ouPath: string[]) => {
        flatOUs.push(rootOU);
        flatOUs.forEach((ou) => {
            ou.pathArray = ou.path.split('.');
        });
        const flatTree = OUTreeData && flattenTree(OUTreeData[0]);

        const allChildrenOuIds = (ouId: string) => {
            const allAssociatedOus = flatTree.filter((ou) => ou.pathIds.includes(ouId));
            const selectedOu = allAssociatedOus.find((ou) => ou.id === ouId);
            const selectedOuIndexInPath = selectedOu?.pathIds.indexOf(ouId);
            const allChildrenIds = allAssociatedOus.reduce((result: string[], ou) => {
                if (!result.includes(ou.id)) {
                    result.push(ou.id);
                }
                return result;
            }, []);
            return allChildrenIds.slice(selectedOuIndexInPath);
        };

        const ouId = ouPath && flatOUs.find((ou) => ou.name === ouPath[ouPath.length - 1])?.id;
        return allChildrenOuIds(ouId);
    };

    const getFilteredCloudAccounts = (ouPath: string[], cloudAccountId?: string, rulesetVendor?: string) => {
        const allChildrenOuIds = getAllChildrenOuIds(ouPath);
        const ouId = ouPath && flatOUs.find((ou) => ou.name === ouPath[ouPath.length - 1])?.id;
        return cloudAccountId
            ? cloudAccountsData.filter(
                  (cloudAccount: ICloudAccount) =>
                      cloudAccount.id === cloudAccountId && cloudAccount.platform === rulesetVendor,
              )
            : cloudAccountsData.filter((cloudAccount: ICloudAccount) => {
                  if (rulesetVendor) {
                      return (
                          (cloudAccount.organizationalUnitId === ouId ||
                              allChildrenOuIds.includes(cloudAccount.organizationalUnitId)) &&
                          cloudAccount.platform === rulesetVendor
                      );
                  } else {
                      return (
                          cloudAccount.organizationalUnitId === ouId ||
                          allChildrenOuIds.includes(cloudAccount.organizationalUnitId)
                      );
                  }
              });
    };

    const valuesCellRenderer = useCallback(
        (props: any) => {
            let textColor = 'black';
            if (props?.value && thresholds) {
                const normalizedValue = Number(props.value.replace('%', ''));
                const threshold = thresholds.find((threshold: any) => {
                    const _thresholdMax = threshold.max || 100;
                    return normalizedValue > threshold.min && normalizedValue < _thresholdMax;
                });
                if (threshold) {
                    textColor = threshold.color;
                }
            }
            if (props?.value && props?.value.includes('%')) {
                const rulesetId = parseInt(props.column.userProvidedColDef.field);
                const rulesetVendor = data?.rulesets.find((ruleset) => ruleset.id === rulesetId)?.cloudVendor;
                const filteredCloudAccounts = getFilteredCloudAccounts(
                    props.data.ouPath,
                    props.data.cloudAccountId,
                    rulesetVendor,
                );
                const runAssessmentData = {
                    ouPath: props.data.ouPath,
                    cloudAccountId: props.data.cloudAccountId,
                    platform: props.data.cloudVendor,
                    rulesetId: rulesetId,
                    rulesetName: props.data.rulesetName
                        ? props.data.rulesetName
                        : props.column.userProvidedColDef.headerName,
                    cloudAccounts: filteredCloudAccounts,
                };

                const assessmentTrendData = {
                    rulesetId: rulesetId,
                    cloudAccountId: props.data.cloudAccountId,
                    rulesetName: props.data.rulesetName
                        ? props.data.rulesetName
                        : props.column.userProvidedColDef.headerName,
                    cloudAccountName: props.data.cloudAccountName,
                };

                return (
                    <div className={'flex'}>
                        <span style={{ color: textColor }}>{props.value}</span>
                        <Button
                            variant={'integrated'}
                            icon={'play'}
                            onClick={() => {
                                openRunAssessmentModal();
                                setRunAssessmentData(runAssessmentData);
                            }}
                        />
                        {props.data.cloudAccountId && (
                            <Button
                                variant={'integrated'}
                                icon={'timelineChart'}
                                onClick={() => {
                                    openAssessmentTrendModal();
                                    setAssessmentTrendData(assessmentTrendData);
                                }}
                            />
                        )}
                    </div>
                );
            }
            return <span style={{ color: textColor }}>{props.value}</span>;
        },
        // TODO: fix dependencies - if decide to ignore explain why
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [thresholds, cloudAccountsData, flatOUs, rootOU, OUTreeData],
    );

    useEffect(() => {
        loadData();
        // TODO: fix dependencies - if decide to ignore explain why
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thresholds]);

    useEffect(() => {
        let cloudAccounts = data?.assessmentsStatistics?.map((assessmentsStatistic: IAssessmentTable) => {
            return {
                cloudAccountId: assessmentsStatistic.cloudAccountId,
                cloudVendor: assessmentsStatistic.cloudVendor,
            };
        });

        function getOnlyOUsPaths() {
            const allOUsPaths: string[] = [];
            data?.assessmentsStatistics?.forEach((assessmentsStatistic: IAssessmentTable) => {
                let _ous = assessmentsStatistic.ouPath;
                _ous = _ous.slice(0, _ous.length - 1);
                _ous.forEach((ou: any, index: number) => {
                    const item = _ous.slice(0, index + 1)?.join('.');
                    item && allOUsPaths.push(item);
                });
            });
            return [...new Set(allOUsPaths)];
        }

        function getOURulesetPercent(assessmentsStatistics: any[], rulesetId: number, ou?: string[]) {
            let ouChildren = null;
            if (ou) {
                ouChildren = assessmentsStatistics.filter(
                    (r: any) => r.rulesetId === rulesetId && r.ouPath.join('.').includes(ou.join('.')),
                );
            } else {
                ouChildren = assessmentsStatistics.filter((r: any) => r.rulesetId === rulesetId);
            }
            const sum = ouChildren.reduce(
                (acc: any, curr: any) => {
                    acc.tested = acc.tested + getLogicallyTested(curr.statistics);
                    acc.failed = acc.failed + getFailedTests(curr.statistics);
                    return acc;
                },
                { tested: 0, failed: 0 },
            );
            const passedTests = sum.tested - sum.failed;
            const percent = (passedTests / sum.tested) * 100;
            return Number.isNaN(percent) || percent === 0 ? '-' : percent.toFixed(2) + '%';
        }

        function createOURows(ousPaths: string[]) {
            ousPaths
                .map((ou: any) => ou.split('.'))
                .forEach((ou: string[]) => {
                    const row = {
                        cloudAccountName: '',
                        ouDisplayName: ou[ou.length - 1],
                        ouPath: ou,
                    };
                    data?.rulesets.forEach((ruleset: any) => {
                        // @ts-ignore
                        row[ruleset.id] = getOURulesetPercent(data.assessmentsStatistics, ruleset.id, ou);
                    });
                    rowData.push(row);
                });
        }

        function createCloudAccountRows() {
            cloudAccounts?.forEach((cloudAccount: any) => {
                const row: IGridRow = { cloudAccountId: cloudAccount.cloudAccountId };
                data?.assessmentsStatistics.forEach((a: any) => {
                    if (
                        a.cloudAccountId === cloudAccount.cloudAccountId &&
                        a.cloudVendor === cloudAccount.cloudVendor
                    ) {
                        row[a.rulesetId] = calculatePassedPercent(a.statistics);
                        row.cloudAccountName = a.cloudAccountName;
                        row.ouDisplayName = a.ouDisplayName;
                        row.ouPath = a.ouPath;
                        row.rulesetId = a.rulesetId;
                        row.rulesetName = a.rulesetName;
                        row.cloudVendor = a.cloudVendor;
                    }
                    rowData.push(row);
                });
            });
        }

        function createColumnsFromRulesets(rulesets: any) {
            const otherRulesetsColumns = rulesets
                .filter((item: any) => !visibleRulesetColumns.includes(item.id))
                .map((ruleset: any) => {
                    return {
                        field: ruleset.id.toString(),
                        headerName: ruleset.name,
                        hide: true,
                        cellRenderer: valuesCellRenderer,
                    };
                });
            const startingRulesetsColumns = rulesets
                .filter((item: any) => visibleRulesetColumns.includes(item.id))
                .map((ruleset: any) => ({
                    field: ruleset.id.toString(),
                    headerName: ruleset.name,
                    hide: false,
                    cellRenderer: valuesCellRenderer,
                    headerComponent: () => {
                        return (
                            <div onClick={() => changeUrl('/compliance-engine/policy/' + ruleset.id)}>
                                <span>{ruleset.name}</span>
                            </div>
                        );
                    },
                }));
            setColumnDefs([...startingRulesetsColumns, ...otherRulesetsColumns]);
        }

        if (cloudAccounts) {
            cloudAccounts = [...new Set(cloudAccounts)];
            rowData.length = 0;
            const ousPaths = getOnlyOUsPaths();
            createOURows(ousPaths);
            createCloudAccountRows();
            const _rowData = getRowDataWithUniqOUPaths(rowData);
            gridRef?.current?.api?.setRowData(_rowData);
        }

        data?.rulesets && createColumnsFromRulesets(data?.rulesets);
        // TODO: fix dependencies - if decide to ignore explain why
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, rowData, valuesCellRenderer, OUTreeData]);

    const onGridReady = (params: any) => {
        const _rowData = getRowDataWithUniqOUPaths(rowData);
        params.api.setRowData(_rowData);
    };

    useEffect(() => {
        const _rowData = getRowDataWithUniqOUPaths(rowData);
        gridRef.current?.api?.setRowData(_rowData);
    }, [rowData, gridRef]);

    const getDataPath = (data: any) => {
        return data.ouPath;
    };

    const onColumnVisible = (event: ColumnVisibleEvent<any>) => {
        const visibleRulesetIds = event?.columns
            ?.filter((column) => column.isVisible())
            .map((column) => Number(column?.getColDef()?.field));
        const notVisibleRulesetIds = event?.columns
            ?.filter((column) => !column.isVisible())
            .map((column) => Number(column?.getColDef()?.field));
        if (visibleRulesetIds?.length) {
            visibleRulesetColumns = [...visibleRulesetColumns, ...visibleRulesetIds];
        } else {
            visibleRulesetColumns = visibleRulesetColumns.filter((item: any) => !notVisibleRulesetIds?.includes(item));
        }

        setCachedVisibleColumns(widget.id, visibleRulesetColumns);
        loadData();
    };

    const platformIconAndEnvironmentCellRenderer = (params: any) => {
        const iconString = params.data.cloudVendor === 'google' ? 'gcp' : params.data.cloudVendor;
        if (params.data.cloudAccountName) {
            return (
                <div className={'flex items-center'}>
                    <Icon name={iconString} size={14} />
                    <span className={'ml-5'}>{params.data.cloudAccountName}</span>
                </div>
            );
        } else {
            return (
                <div className={'flex items-center'}>
                    <span className={'ml-5'}>{params.value}</span>
                </div>
            );
        }
    };
    const defaultColDef = useMemo(() => ({ resizable: true, suppressMenu: true }), []);
    const exportFilteredResultsToCsv = async () => {
        if (tableFilters.current) {
            setIsExporting(true);
            const assessmentResults = await getAssessmentData(tableFilters.current);
            await exportDataToCsv(assessmentResults);
            setIsExporting(false);
        }
    };

    const exportDataToCsv = (assessmentResults: any) => {
        const csvData = assessmentResults.assessmentsStatistics.map((assessment: any) => {
            return {
                Date: new Date(assessment.resultDate).toLocaleString(),
                'Organizational Unit Path': assessment.ouPath.join('/'),
                'Cloud Account ID': assessment.cloudAccountId,
                'Cloud Account Name': assessment.cloudAccountName,
                Platform: assessment.cloudVendor,
                Bundle: assessment.rulesetName,
                'Success Rate': calculatePassedPercent(assessment.statistics),
                '# Tested': assessment.statistics.logicallyTested,
                '# Failed Tests': assessment.statistics.failedTests,
                '# Excluded Tests': assessment.statistics.excludedTests,
                'Triggered By': assessment.triggeredBy,
            };
        });
        const csv = Papa.unparse(csvData);
        const today = new Date();
        const dateStamp = `${today.toDateString()} ${today.getHours()} ${today.getMinutes()}`.replaceAll(' ', '_');
        const exportFile: IExportFile = {
            data: csv,
            fileName: `Dome9_filtered_dashboard_results_${dateStamp}`,
            fileExtension: 'csv',
        };
        exportFileFromString(exportFile);
    };

    const exportAllToCsv = async () => {
        setIsExporting(true);
        const assessmentResults = await getAssessmentStatisticsForAllRulesets();
        await exportDataToCsv(assessmentResults);
        setIsExporting(false);
    };

    return (
        <div className='bg-content col-span-6 flex border h-full w-full'>
            <div className='flex flex-1 flex-col'>
                {!loadingStatusMessage && (
                    <div className='flex-0 flex justify-end'>
                        {isExporting && (
                            <div className='flex flex-center mr-[10px]'>
                                <Spinner />
                                <span className='ml-[10px]'>Exporting...</span>
                            </div>
                        )}
                        <Button
                            label='Export all results'
                            variant='integrated'
                            onClick={() => exportAllToCsv()}
                        ></Button>
                        <Button
                            label='Export filtered results'
                            variant='integrated'
                            onClick={() => exportFilteredResultsToCsv()}
                        ></Button>
                    </div>
                )}
                <div className='flex flex-1'>
                    {loadingStatusMessage ? (
                        <p className='m-auto'>{loadingStatusMessage}</p>
                    ) : (
                        <AgGridReact
                            treeData={true}
                            suppressDragLeaveHidesColumns={true}
                            onColumnVisible={(e) => onColumnVisible(e)}
                            getDataPath={getDataPath}
                            rowModelType='clientSide'
                            className='ag-theme-alpine relative h-full flex-1'
                            ref={gridRef}
                            enableRangeSelection={true}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            sideBar={sideBar}
                            headerHeight={30}
                            onGridReady={onGridReady}
                            suppressContextMenu
                            groupDefaultExpanded={1}
                            autoGroupColumnDef={{
                                headerName: '',
                                cellRendererParams: {
                                    innerRenderer: platformIconAndEnvironmentCellRenderer,
                                },
                            }}
                        />
                    )}
                </div>
                <RunAssessmentModal
                    isOpen={isRunAssessmentModalOpen}
                    onCancel={closeRunAssessmentModal}
                    runAssessmentData={runAssessmentData}
                />
                <AssessmentTrendModal
                    isOpen={isAssessmentTrendModalOpen}
                    onCancel={closeAssessmentTrendModal}
                    assessmentTrendProps={assessmentTrendData}
                />
            </div>
        </div>
    );
};

export default AssessmentsTableWidget;
