import { getAppRootRegistry } from 'common/interface/services';
import { isLocalhost } from 'common/utils/RuntimeEnvironment';

const initializeBeamer = () => {
    const beamerConfig = JSON.stringify({
        product_id: 'PfncQNzG56753',
        selector: 'beamerButton',
    });

    const script1 = document.createElement('script');
    script1.innerHTML = `var beamer_config = ${beamerConfig};`;

    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.src = 'https://app.getbeamer.com/js/beamer-embed.js';
    script2.defer = true;

    document.body.appendChild(script1);
    document.body.appendChild(script2);
};

export const setBeamerOption = () => {
    if (isLocalhost) {
        return;
    }

    getAppRootRegistry().addAfterLoginEvents([
        {
            id: 'WhatsNew',
            content: async () => {
                initializeBeamer();
            },
        },
    ]);
};
