import { DirectionalLayoutModel } from 'common/components/Graph/Models/DirectionalLayoutModel';
import { LayoutDirection } from 'common/components/Graph/Models/LayoutDirection';
import { RankAssigmentAlgorithm } from 'common/components/Graph/Models/RankAssigmentAlgorithm';

export const ACCOUNT_ACTIVITY_CLASSIFICATIONS: Record<string, string> = {
    IDENTITY: 'identity',
    ISSUER: 'issuer',
    TARGET: 'target',
};

export const NETWORK_TRAFFIC_CLASSIFICATIONS: Record<string, string> = {
    INTERNAL: 'internal',
    EXTERNAL: 'external',
};

// IMPORTANT: the order of the labels is crucial, left most should be the label from the left
export const INTELLIGENCE_GRID: Record<string, any> = {
    accountActivity: {
        LABELS: ['Identity', 'Issuer', 'Target'],
        CLASSES: [
            ACCOUNT_ACTIVITY_CLASSIFICATIONS.IDENTITY,
            ACCOUNT_ACTIVITY_CLASSIFICATIONS.ISSUER,
            ACCOUNT_ACTIVITY_CLASSIFICATIONS.TARGET,
        ],
    },
    networkActivity: {
        LABELS: ['External', 'Internal'],
        CLASSES: [NETWORK_TRAFFIC_CLASSIFICATIONS.INTERNAL, NETWORK_TRAFFIC_CLASSIFICATIONS.EXTERNAL],
    },
};

export const IDENTITY_ISSUER_EVENT = {
    ASSUME_ROLE: 'AssumeRole',
};

export const GRAPH_CLASSES = {
    CONTAINER: 'intelligence-visualization-container',
    NODE: 'intelligence asset-node',
};

export const ASSET_IN_CONTEXT = {
    TOOLTIP: 'Context asset',
};

export const NODE_SIZE = 70;

export const GRID_LABELS_MARGIN = 15;

export const GRAPH_LAYOUT_MODEL: DirectionalLayoutModel = {
    layoutDirection: LayoutDirection.LeftToRight,
    nodeSeparation: 50,
    rankSeparation: 200,
    rankAssignmentAlgorithm: RankAssigmentAlgorithm.longestPath,
};

export const GRAPH_ZOOM_LIMITS = {
    min: 0.7,
    max: 1.4,
};
