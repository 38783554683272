import initializeDetailsPanelItems from './Definitions/initialize.detailsPanelItems';
import initializeChips from './Definitions/initialize.chips';
import initializeOverviewSections from './Definitions/initialize.overview';
import initializeOverviewTabs from './Definitions/initialize.tabs';

export const initializeEventDrawer = () => {
    initializeOverviewSections();
    initializeOverviewTabs();
    initializeDetailsPanelItems();
    initializeChips();
};
