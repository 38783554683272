import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import FeatureStatusRenderer from 'common/components/EnvironmentsTable/CellRenderers/FeatureStatusRenderer';
import { Vendors } from 'common/consts/vendors';
import { IEnvironment } from 'common/interface/environmentsTable';
import { FeatureStatusRenderer_Type } from 'common/module_interface/assets/Environments';
import { agentlessSupportedVendors } from 'modules/workloads/EnvironmetsDefs/initialize';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';

type TAwpStatusRendererProps = ICellRendererParams<IEnvironment>;

const AwpStatusRenderer: React.FunctionComponent<TAwpStatusRendererProps> = forwardRef<any, TAwpStatusRendererProps>(
    (props, ref) => {
        const { t } = useTranslation(getK8sNamespace('common'));
        const featRef = useRef<{ refresh: <T extends TAwpStatusRendererProps>(params: T) => boolean } | null>(null);

        useImperativeHandle(ref, () => {
            return {
                refresh(params: any) {
                    featRef.current?.refresh(params);
                    return true;
                },
            };
        });

        return (
            <FeatureStatusRenderer
                ref={featRef}
                type={FeatureStatusRenderer_Type.Agentless}
                name={t('ENVIRONMENTS_TABLE.TABLE_HEADERS.AGENTLESS.HEADER')}
                supportedVendors={agentlessSupportedVendors}
                // there is no update page for AZURE, so we don't need to check for shouldUpdateField
                shouldUpdateField={
                    props.data?.platform === Vendors.AWS ? 'customData|agentlessShouldUpdate' : undefined
                }
                {...props}
            />
        );
    },
);

AwpStatusRenderer.displayName = 'AwpStatusRenderer';

export default AwpStatusRenderer;
