import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { IssuesRegistry } from 'common/module_interface/RiskManagement/issues/IssuesRegistry';
import { ermTrans } from 'modules/riskManagement/RiskManagement.utils';
import { ACTIONS_FIELD_NAMES } from '../IAMSensitivity.interface';
import { IAMSensitivityCellRenderer } from '../../../../../cellRenderers/IAMSensitivityCellRenderer';

export const initializeIAMSensitivityTableColumns = () => {
    const columnDefs: CGColDef[] = [
        {
            colId: ACTIONS_FIELD_NAMES.ACTION,
            field: ACTIONS_FIELD_NAMES.ACTION,
            headerName: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.IAM_SENSITIVITY_TABLE.COLUMNS.ACTION.TITLE'),
            headerTooltip: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.IAM_SENSITIVITY_TABLE.COLUMNS.ACTION.TOOLTIP'),
            flex: 1,
        },
        {
            colId: ACTIONS_FIELD_NAMES.POLICY,
            field: ACTIONS_FIELD_NAMES.POLICY,
            headerName: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.IAM_SENSITIVITY_TABLE.COLUMNS.POLICY.TITLE'),
            headerTooltip: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.IAM_SENSITIVITY_TABLE.COLUMNS.POLICY.TOOLTIP'),
            flex: 3,
        },
        {
            colId: ACTIONS_FIELD_NAMES.SCOPE,
            field: ACTIONS_FIELD_NAMES.SCOPE,
            headerName: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.IAM_SENSITIVITY_TABLE.COLUMNS.RESOURCE.TITLE'),
            headerTooltip: ermTrans(
                'ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.IAM_SENSITIVITY_TABLE.COLUMNS.RESOURCE.TOOLTIP',
            ),
            flex: 3,
        },
        {
            colId: ACTIONS_FIELD_NAMES.SCORE,
            field: ACTIONS_FIELD_NAMES.SCORE,
            headerName: ermTrans(
                'ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.IAM_SENSITIVITY_TABLE.COLUMNS.IAM_SENSITIVITY.TITLE',
            ),
            headerTooltip: ermTrans(
                'ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.IAM_SENSITIVITY_TABLE.COLUMNS.IAM_SENSITIVITY.TOOLTIP',
            ),
            cellRenderer: IAMSensitivityCellRenderer,
            width: 120,
            resizable: false,
        },
    ];

    IssuesRegistry.addIamSensitivityColumnDefs(columnDefs, 'colId');
};
