import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { ermPermissionFn } from '../../RiskManagement.utils';
import { TOXIC_GRAPH_ACTIVE_FEATURE } from '../ActiveFeatures/ActiveFeatures.consts';
import {
    getToxicGraphSchema,
    TOXIC_GRAPH_SERVICE_ID,
    ToxicGraphService,
} from './ToxicGraphModel/ToxicGraphModel.service';
import { IToxicGraphSchemaModel } from './ToxicGraphModel/ToxicGraphModel.interface';
import { getAppRootRegistry } from 'common/interface/services';

let isGlobalToxicGraphSchemaReady = false;
export let globalToxicGraphSchema: IToxicGraphSchemaModel = {
    EntitySchemas: {},
    RelationshipSchemas: {},
};

const prepareToxicSchema = async () => {
    if (isGlobalToxicGraphSchemaReady) {
        return;
    }
    globalToxicGraphSchema = await getToxicGraphSchema();
    isGlobalToxicGraphSchemaReady = true;
};

export function initializeToxicGraph() {
    getAppRootRegistry().addAfterLoginEvents([
        {
            id: 'prepareToxicSchema',
            content: prepareToxicSchema,
            permission: () => ermPermissionFn(TOXIC_GRAPH_ACTIVE_FEATURE.key),
        },
    ]);

    globalAddinContainer.addService(TOXIC_GRAPH_SERVICE_ID, new ToxicGraphService());
}
