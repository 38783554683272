import { SimpleGenericPage } from '../../SimpleTableFilterPage/SimpleGenericPage';
import { I18nTranslationKey, ROLE_DRAWER, rolesTableRegistry } from './initRolesPage';
import {
    calcFilterData,
    dataService,
    filterId,
    filterIds,
    getAggregations,
    savedFiltersComponentName,
} from './RolesFiltersDefenitions';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { useDrawerHandler } from 'common/components/DrawerInfra/Drawer/UseDrawerHandler';
import { ROLES_CHANGED_HANDLER_ID } from 'common/module_interface/settings/consts';
import { IDataItem } from '../../SimpleTableFilterPage/interfaces';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ITableExportButton } from 'common/design-system/components-v2/Table/Table.types';
import { getNotificationsService, IRole } from 'common/interface/services';
import { generateAndDownloadCsv } from 'common/helpers/generateAndDownloadCsv';
import { drawerManager } from 'common/components/DrawerInfra/Drawer/DrawerManager';
import { NotificationType } from 'common/interface/notifications';
import i18n from 'common/services/translations/translations';

export const EditRole = (items: IDataItem) => {
    const editRole = i18n.t('GENERAL.EDIT_ROLE', { ns: I18nTranslationKey });
    showDrawer(
        ROLE_DRAWER.key,
        {
            roleId: items.id,
            title: editRole,
            componentProps: { roleId: items.id },
        },
        { [ROLE_DRAWER.eventTypes.roleChanged]: ROLES_CHANGED_HANDLER_ID },
    );
};

const RolesPage: React.FC = () => {
    const { t } = useTranslation(I18nTranslationKey);
    const [roles, setRoles] = useState<IDataItem[]>([]);
    const [rolesForExport, setRolesForExport] = useState<IDataItem[]>([]);

    const init = async (useCache = false) => {
        const data = await dataService.getAll(useCache);
        setRoles(data);
    };

    useEffect(() => {
        void init();
    }, []);

    const onItemDelete = async (items: IDataItem[]) => {
        try {
            const itemsIds = items.map((_item) => _item.id);
            await Promise.all(
                itemsIds.map((id) => {
                    return dataService.delete(id.toString());
                }),
            );

            setRoles((prevRoles) => prevRoles.filter((role) => !itemsIds.includes(role.id)));
            getNotificationsService().addNotification({
                type: NotificationType.SUCCESS,
                title: '',
                text: t('DRAWER.TOAST.DELETE_SUCCESS'),
            });
        } catch (e) {
            console.error('Error deleting roles', e);
        }
    };

    const handleConfigurationChanged = () => {
        drawerManager.closeTopDrawer(true);
        void init();
    };

    useDrawerHandler(ROLES_CHANGED_HANDLER_ID, handleConfigurationChanged);

    const AddRole = () => {
        showDrawer(
            ROLE_DRAWER.key,
            {
                roleId: null,
                title: t('GENERAL.ADD_ROLE'),
                componentProps: { configurations: [] },
            },
            { [ROLE_DRAWER.eventTypes.roleChanged]: ROLES_CHANGED_HANDLER_ID },
        );
    };

    const exportTable = (roles: IRole[]) => {
        const fileIdentifier = 'Roles';
        const mappedData = roles.map((role) => ({
            Id: role?.id,
            Name: role?.name,
            Description: role?.description,
            'Users Count': role?.users?.length || '0',
            'Service Account Count': role?.serviceAccounts?.length || '0',
        }));
        generateAndDownloadCsv(mappedData, fileIdentifier);
    };

    const transformToRoles = (items: IDataItem[]): IRole[] => {
        return items.map((item) => ({
            id: item?.id ? Number(item?.id) : 0,
            name: item?.name ? String(item?.name) : '',
            description: item?.description ? String(item?.description) : '',
            users: Array.isArray(item?.users) ? item.users.map((user) => String(user)) : [],
            serviceAccounts: Array.isArray(item?.serviceAccounts)
                ? item.serviceAccounts.map((account) => String(account))
                : [],
            permissions:
                typeof item.permissions === 'object' ? (item.permissions as { [key: string]: string[] }) : undefined,
        }));
    };

    const exportButtons: ITableExportButton[] = [
        {
            label: t('ACTIONS.EXPORT.EXPORT_ALL_RESULTS_TO_CSV'),
            icon: { name: 'download' },
            onClick: () => {
                exportTable(transformToRoles(roles));
            },
        },
        {
            label: t('ACTIONS.EXPORT.EXPORT_FILTERED_RESULTS_TO_CSV'),
            icon: { name: 'download' },
            onClick: () => {
                exportTable(transformToRoles(rolesForExport));
            },
        },
    ];

    return (
        <SimpleGenericPage
            items={roles}
            pagination={false}
            filterId={filterId}
            tableRegistry={rolesTableRegistry}
            savedFiltersComponentName={savedFiltersComponentName}
            translationKey={I18nTranslationKey}
            onDelete={onItemDelete}
            onAdd={AddRole}
            onEdit={EditRole}
            calcFilteredData={calcFilterData}
            getAggregations={getAggregations}
            filterIds={filterIds}
            exportButtons={exportButtons}
            setFilteredDataForExport={setRolesForExport}
        />
    );
};

export default RolesPage;
