import EmptyState from 'common/components/EmptyState';
import { NotificationType } from 'common/interface/notifications';
import { getNotificationsService } from 'common/interface/services';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useWorkloadImageScan } from 'modules/workloads/services/workload/hooks/useWorkloadImageScan';
import { getScanStatus, getScanStatusDetails } from 'modules/workloads/utils';
import { useTranslation } from 'react-i18next';

const ImageScanResultEmptyState = ({ entity }: { entity: ICloudEntityData }) => {
    const { t } = useTranslation(getK8sNamespace('common'));
    const { protectedAsset, cloudAccountId: environmentId } = entity;
    const scanStatus = getScanStatus(protectedAsset);
    const scanDetails = getScanStatusDetails(protectedAsset);
    const handleOnRescanSuccess = () => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t(`workloadImageScan.imageScan.successMessage.title`),
            text: t(`workloadImageScan.imageScan.successMessage.body`),
        });
    };
    const handleOnRescanError = (error: { message: string }) => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t(`workloadImageScan.imageScan.errorMessage.title`),
            text: error.message || t(`workloadImageScan.imageScan.errorMessage.body`),
        });
    };
    const { isLoading: isScanRequestLoading, scanImage } = useWorkloadImageScan({
        onSuccess: handleOnRescanSuccess,
        onError: handleOnRescanError,
    });
    const handleOnScanClick = () => {
        return scanImage({ environmentId, imageIdOrDigest: protectedAsset.entityId });
    };
    return (
        <EmptyState
            label={
                scanStatus !== null
                    ? `${t('workloadImageScan.agentlessScan.header')} ${scanStatus}`
                    : t('noScanResults')
            }
            description={`${scanDetails ? `${scanDetails}\n` : ''}${t('workloadImageScan.agentlessScan.nullStatus')}`}
            iconName='objectExplorer'
            iconSize={64}
            buttons={[
                {
                    color: 'brandPrimary',
                    onClick: handleOnScanClick,
                    children: t('workloadImageScan.agentlessScan.buttonText'),
                    disabled: isScanRequestLoading,
                },
            ]}
        />
    );
};

export default ImageScanResultEmptyState;
