import React, { useCallback, useEffect, useState } from 'react';
import { IIssue } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { LoadingState } from 'common/interface/general';
import i18n from 'i18next';
import { Stack, Typography } from 'common/design-system/components-v2';
import FullSizeSpinner from 'common/erm-components/custom/FullSize/FullSizeSpinner';
import { IssueCDREventsStyled } from './IssueCDREvents.styled';
import { fetchSecurityEvents, fetchFindingsByKeys } from './IssueCDREvents.datasource';
import IssueCDREventsTable from './IssueCDREventsTable';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { CDREventInfo } from './IssueCDREvents.interface';

const IssueCDREvents: React.FC<{
    issue: IIssue;
    asset: IProtectedAssetViewModel;
    title: string;
    initiallyOpen?: boolean;
}> = ({ issue, asset }) => {
    const [loadingState, setLoadingState] = useState(LoadingState.IS_LOADING);
    const [findings, setFindings] = useState<IKustoEvent[]>();
    const loadEvents = useCallback(async () => {
        if (asset.srl) {
            setLoadingState(LoadingState.IS_LOADING);
            setFindings(undefined);
            try {
                const eventsInfo: CDREventInfo[] = await fetchSecurityEvents(issue.id, asset.srl);
                if (eventsInfo && eventsInfo.length > 0) {
                    const findingsInfo: IKustoEvent[] = await fetchFindingsByKeys(
                        eventsInfo.map((event) => event.findingKey),
                    );
                    setFindings(findingsInfo || []);
                } else {
                    setFindings([]);
                }
                setLoadingState(LoadingState.LOADING_SUCCEEDED);
            } catch (e) {
                setLoadingState(LoadingState.LOADING_FAILED);
            }
        }
    }, [asset, issue]);

    useEffect(() => {
        if (issue && asset) {
            void loadEvents();
        }
    }, [issue, asset, loadEvents]);

    return (
        <Stack fullWidth>
            {loadingState === LoadingState.IS_LOADING && (
                <IssueCDREventsStyled.LoaderDiv>
                    <FullSizeSpinner />
                </IssueCDREventsStyled.LoaderDiv>
            )}
            {loadingState === LoadingState.LOADING_FAILED && (
                <IssueCDREventsStyled.NoteDiv fullWidth fullHeight>
                    <Typography color={'alert'}>{i18n.t('COMMON.ERROR_OCCURRED')}</Typography>
                </IssueCDREventsStyled.NoteDiv>
            )}
            {loadingState === LoadingState.LOADING_SUCCEEDED && findings && (
                <Stack direction={'column'} fullWidth fullHeight>
                    {findings.length > 0 ? (
                        <IssueCDREventsStyled.TableDiv>
                            <IssueCDREventsTable events={findings} />
                        </IssueCDREventsStyled.TableDiv>
                    ) : (
                        <IssueCDREventsStyled.NoteDiv>
                            <Typography>{i18n.t('COMMON.OVERVIEW.NO_DATA_TO_DISPLAY')}</Typography>
                        </IssueCDREventsStyled.NoteDiv>
                    )}
                </Stack>
            )}
        </Stack>
    );
};
export default IssueCDREvents;
