// Working only for numeric version text
// For example '1', '1.1', '1.1.1'
export const versionsCompare = (a: string, b: string) => {
    try {
        const aSplited = a.split('.').map((num) => Number(num));
        const bSplited = b.split('.').map((num) => Number(num));

        for (let i = 0; i < 3; i++) {
            const indexA = aSplited[i] || 0;
            const indexB = bSplited[i] || 0;

            if (indexA === indexB) continue;
            return indexA > indexB ? 1 : -1;
        }

        return 0;
    } catch (error) {
        return 0;
    }
};

const severityPriority: Record<string, number> = {
    critical: 0,
    high: 1,
    medium: 2,
    low: 3,
    informational: 4,
    unknown: 5,
};

export const severityComparator = (valueA: string, valueB: string): number => {
    if (!valueA || !valueB) {
        return 0;
    }
    return severityPriority[valueA.toLowerCase()] - severityPriority[valueB.toLowerCase()];
};
