import { IFieldInfo } from 'common/interface/general';
import { Pages } from 'common/enum/Pages';
import { changeUrl } from 'common/utils/http';
import { ASSET_FIELD_NAMES, ERM_CONSTS, ERM_URLS } from 'modules/riskManagement/consts';
import { ITimeFromTo } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { generateAssetPageUrl, getEventsUrl } from 'common/module_interface/assets/utils';
import { IAsset, UrlFuncResult } from 'common/assets/common.assets';
import { isRiskSupportedForAsset } from '../components/ProtectedAsset/ErmDataUtils';
import { IErmUrlsService } from 'common/module_interface/RiskManagement/Services';
import { getUrlWithFilters } from 'common/erm-components/utils/urlUtils';

export class ErmUrlsService implements IErmUrlsService {
    private getToxicGraphPageUrl() {
        return ERM_URLS.TOXIC_GRAPH;
    }

    public goToToxicGraphPageByRuleId(ruleId: string) {
        changeUrl(`${this.getToxicGraphPageUrl()}?ruleId=${ruleId}`);
    }

    public goToToxicGraphPageByIssueId(issueId: string) {
        changeUrl(`${this.getToxicGraphPageUrl()}?issueId=${issueId}`);
    }

    private getEnvironmentTableUrl(filterFields?: IFieldInfo[], freeText?: string, envType?: string) {
        const urlWithFilters = getUrlWithFilters(Pages.CloudAccountEnv, filterFields, freeText);
        return envType ? `${urlWithFilters}&envType=${envType}` : urlWithFilters;
    }

    public gotoEnvironmentTable(filterFields?: IFieldInfo[], freeText?: string, envType?: string) {
        changeUrl(this.getEnvironmentTableUrl(filterFields, freeText, envType));
    }

    public goToProtectedAssetsForType(typeByPlatform: string, filterFields?: IFieldInfo[]) {
        const fields: IFieldInfo[] = [
            ...(filterFields || []).filter((field) => field.name !== ASSET_FIELD_NAMES.type),
            {
                name: ASSET_FIELD_NAMES.type,
                value: typeByPlatform,
            },
        ];

        this.goToProtectedAssetsTable(fields);
    }

    private getProtectedAssetsTableUrl(filtersItems?: IFieldInfo[], freeText?: string) {
        return getUrlWithFilters(ERM_CONSTS.ERM_PROTECTED_ASSETS_URL, filtersItems, freeText);
    }

    public goToProtectedAssetsTable(filtersItems?: IFieldInfo[], freeText?: string) {
        changeUrl(this.getProtectedAssetsTableUrl(filtersItems, freeText));
    }

    public goToEventsTable(freeTextPhrase: string, filterFields: IFieldInfo[], creationTime?: ITimeFromTo) {
        changeUrl(getEventsUrl(freeTextPhrase, filterFields, creationTime));
    }

    private getSecurityIssuesTableUrl(filterFields?: IFieldInfo[], freeText?: string) {
        return getUrlWithFilters(ERM_URLS.ISSUES, filterFields, freeText);
    }

    public gotoSecurityIssuesTable(filterFields?: IFieldInfo[], freeText?: string) {
        changeUrl(this.getSecurityIssuesTableUrl(filterFields, freeText));
    }

    public generateAssetUrl(asset: IAsset, assetUrl: UrlFuncResult, tabName?: string, innerTabIndex?: number) {
        if (isRiskSupportedForAsset(asset.typeByPlatform)) {
            return generateAssetPageUrl(
                asset,
                assetUrl,
                ERM_CONSTS.ERM_PROTECTED_ASSETS_URL,
                ERM_CONSTS.ERM_PROTECTED_ASSETS_EXTRA_PARAMS,
                tabName,
                innerTabIndex,
            );
        } else {
            return generateAssetPageUrl(asset, assetUrl, undefined, undefined, tabName, innerTabIndex);
        }
    }
}
