import { FieldConvertorsRegistry, FieldEntityKind, IFieldConvertor } from 'common/registries/FieldConvertorsRegistry';
import { CommonEventFields } from 'common/module_interface/events/EventsConsts';
import { getVendor } from 'common/consts/vendors';
import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import i18n, { TFunction } from 'i18next';
import { FindingsTableRegistry } from 'common/components/KustoEvents/FindingsTableRegistry';
import FindingSeverityCellRender from 'common/components/ag-grid/Renderers/FindingSeverityCellRender';
import { i18nIntelligenceNamespace } from '../initialize.i18n';
import { KustoEventFields } from 'common/components/KustoEvents/KustoEvent.const';
import RuleNameCellRenderer from './SecurityEventsByRule/RuleNameCellRenderer';
import { GslCommonFields } from 'common/components/gsl/GslCommonFields';

function initializeWidgetsFieldConvertors() {
    const fieldConvertors: IFieldConvertor[] = [
        {
            sourceEntityKind: FieldEntityKind.FINDING,
            targetEntityKind: FieldEntityKind.ENVIRONMENT,
            sourceFieldName: CommonEventFields.cloudAccountType,
            targetFieldName: 'platform',
            getTargetValue: (sourceValue: string) => {
                const vendor = getVendor(sourceValue);
                return vendor?.name || '';
            },
        },
        {
            sourceEntityKind: FieldEntityKind.FINDING,
            targetEntityKind: FieldEntityKind.ENVIRONMENT,
            sourceFieldName: CommonEventFields.organizationalUnitId,
            targetFieldName: 'organizationalUnitId',
        },
        {
            sourceEntityKind: FieldEntityKind.FINDING,
            targetEntityKind: FieldEntityKind.ENVIRONMENT,
            sourceFieldName: CommonEventFields.cloudAccountId,
            targetFieldName: 'cloudAccountId',
        },
    ];
    FieldConvertorsRegistry.addFieldConvertors(fieldConvertors);
}

function initializeSecurityEventsByRuleWidgetColumns() {
    const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);
    const columnDefs: CGColDef[] = [
        {
            colId: CommonEventFields.ruleName,
            field: CommonEventFields.ruleName,
            headerName: t('CDR_DASHBOARD.WIDGETS.SECURITY_EVENTS_BY_RULE.COLUMNS.RULE'),
            cellRenderer: RuleNameCellRenderer,
        },
        {
            colId: CommonEventFields.severityLevel,
            field: CommonEventFields.severityLevel,
            headerName: t('FINDINGS_TABLE.COLUMNS.SEVERITY.HEADER'),
            cellRenderer: FindingSeverityCellRender,
        },
        {
            colId: CommonEventFields.bundleName,
            field: CommonEventFields.bundleName,
            headerName: t('CDR_DASHBOARD.WIDGETS.SECURITY_EVENTS_BY_RULE.COLUMNS.RULESET'),
        },
        {
            colId: KustoEventFields.mitreTactic,
            field: KustoEventFields.mitreTactic,
            headerName: t('CDR_DASHBOARD.WIDGETS.SECURITY_EVENTS_BY_RULE.COLUMNS.MITRE_TACTIC'),
        },
        {
            colId: GslCommonFields.count,
            field: GslCommonFields.count,
            headerName: t('CDR_DASHBOARD.WIDGETS.SECURITY_EVENTS_BY_RULE.COLUMNS.COUNT'),
        },
    ];
    FindingsTableRegistry.addEventsByRuleColumnDefs(columnDefs, 'colId');
}

export const initializeCdrDashboard = () => {
    initializeWidgetsFieldConvertors();
    initializeSecurityEventsByRuleWidgetColumns();
};
