import React from 'react';
import { Alert, Stack, Typography } from 'common/design-system/components-v2';
import { CveOverview } from '../../services/cveDrawerRegistry/cveDrawerRegistry.types';
import moment from 'moment-mini';
import { useTranslation } from 'react-i18next';

interface CveIgnoreProps {
    comment?: string;
    Date?: Date;
    isExcluded?: boolean;
    exclusion: CveOverview['cve']['exclusion'];
}

const CveIgnore: React.FC<CveIgnoreProps> = ({ isExcluded, exclusion }) => {
    const { t } = useTranslation('k8s_vulnerabilityExclusion');

    if (!isExcluded) return null;

    return (
        <Alert customIcon={{ name: 'exclude', size: 32, customColor: '#42526d' }} type={'warning'}>
            <Stack spacing={1}>
                <Typography variant={'h2'}>{t('excludedCve')}</Typography>
                <Stack margin={[4, 0, 4, 0]}>
                    <Typography variant={'body500'}>{t('table.columns.comment')}</Typography>
                    <Typography>{exclusion?.comment}</Typography>
                </Stack>
                <Stack direction={'row'} spacing={12}>
                    <Stack>
                        <Typography variant={'body500'}>{t('table.columns.creationDate')}</Typography>
                        <Typography>{moment(exclusion?.creationDate).format('lll')}</Typography>
                    </Stack>
                    <Stack>
                        <Typography variant={'body500'}>{t('table.columns.author')}</Typography>
                        <Typography>{exclusion?.author}</Typography>
                    </Stack>
                </Stack>
                {!exclusion?.includeInAssessment && (
                    <Stack margin={[3, 0]} fullWidth>
                        <hr />
                    </Stack>
                )}
                {!exclusion?.includeInAssessment && (
                    <Stack direction={'row'} spacing={1}>
                        <Typography variant={'body500'}>Note:</Typography>
                        <Typography>{t('includeInAssessmentNote')}</Typography>
                    </Stack>
                )}
            </Stack>
        </Alert>
    );
};

export default CveIgnore;
