import { EventOverviewRegistry } from 'common/module_interface/events/EventOverviewRegistry';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IProtectedAssetDetailProvider } from 'common/module_interface/assets/ProtectedAssets';
import { InfoPaneFieldProps } from 'common/components/InfoPane/InfoPane';
import { AssetFieldsMap } from 'common/module_interface/assets/AssetsConsts';

export const initializeEventAssetPanel = () => {
    const fieldAddins: Addin<IProtectedAssetDetailProvider<InfoPaneFieldProps>>[] = [
        {
            id: 'type',
            position: 20,
            content: AssetFieldsMap.typeField,
        },
        {
            id: 'id',
            position: 30,
            content: AssetFieldsMap.idField,
        },
        {
            id: 'organizationalUnit',
            position: 40,
            content: AssetFieldsMap.organizationalUnitField,
        },
        {
            id: 'environment',
            position: 50,
            content: AssetFieldsMap.environmentField,
        },
        {
            id: 'platform',
            position: 60,
            content: AssetFieldsMap.platformField,
        },
    ];
    EventOverviewRegistry.addFieldAddins(fieldAddins, 'events-asset-panel');
};
