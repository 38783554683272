import { AxiosResponse } from 'axios';
import { ManagedKubernetesType } from '../../pages/kubernetesCluster/kubernetesEnvironment/KubernetesEnvironment.types';

export enum K8sClusterVersionStatusTypes {
    Ok = 'Ok',
    Warning = 'Warning',
    Error = 'Error',
    Unknown = 'Unknown',
}

export interface KubernetesAccount {
    id: string;
    externalAccountNumber: string;
    threatIntelligenceEnabled: boolean;
    imageAssuranceEnabled: boolean;
    imageAccessRuntimeMonitorEnabled: boolean;
    runtimeProtectionEnabled: boolean;
    runtimeProtectionNetwork: boolean;
    runtimeProtectionProfiling: boolean;
    runtimeProtectionFileReputation: boolean;
    admissionControlEnabled: boolean;
    admissionControlFailOpen: boolean;
    name: string;
    creationDate: string;
    vendor: string;
    organizationalUnitId?: any;
    organizationalUnitPath: string;
    organizationalUnitName: string;
    clusterVersion?: string;
    clusterVersionStatus: K8sClusterVersionStatusTypes;
    clusterVersionStatusDescription?: string;
    description: string;
    type: ManagedKubernetesType;
}

// ~~~ CreateKubernetesAccount ~~~
export namespace CreateKubernetesAccount {
    export type Function = (
        request: CreateKubernetesAccount.Request,
    ) => Promise<AxiosResponse<CreateKubernetesAccount.Response>>;
    export interface Request {
        name: string;
        description: string;
        organizationalUnitId: string;
    }
    export interface Response extends KubernetesAccount {}
}

// ~~~ KubernetesAccountFeatureStatusChange ~~~
export namespace KubernetesAccountFeatureStatusChange {
    export type Function = (
        request: KubernetesAccountFeatureStatusChange.Request,
    ) => Promise<AxiosResponse<KubernetesAccountFeatureStatusChange.Response>>;
    export interface Request {
        id: string;
        featureType: KubernetesFeatureType;
        status: KubernetesFeatureStatus;
        magellanUrl?: string;
        accountId?: string;
    }
    export type Response = undefined;
}

// ~~~ UpdateKubernetesAccountName ~~~
export namespace UpdateKubernetesAccountName {
    export type Function = (
        request: UpdateKubernetesAccountName.Request,
    ) => Promise<AxiosResponse<UpdateKubernetesAccountName.Response>>;
    export interface Request {
        id: string;
        name: string;
    }
    export interface Response extends KubernetesAccount {}
}

// ~~~ UpdateKubernetesAccountDescription ~~~
export namespace UpdateKubernetesAccountDescription {
    export type Function = (
        request: UpdateKubernetesAccountDescription.Request,
    ) => Promise<AxiosResponse<UpdateKubernetesAccountDescription.Response>>;
    export interface Request {
        id: string;
        description: string;
    }
    export interface Response extends KubernetesAccount {}
}

// ~~~ UpdateKubernetesAccountOU ~~~
export namespace UpdateKubernetesAccountOU {
    export type Function = (
        request: UpdateKubernetesAccountOU.Request,
    ) => Promise<AxiosResponse<UpdateKubernetesAccountOU.Response>>;
    export interface Request {
        id: string;
        organizationalUnitId: string;
    }
    export interface Response extends KubernetesAccount {}
}

// ~~~ DeleteKubernetesAccount ~~~
export namespace DeleteKubernetesAccount {
    export type Function = (
        request: DeleteKubernetesAccount.Request,
    ) => Promise<AxiosResponse<DeleteKubernetesAccount.Response>>;
    export interface Request {
        id: string;
    }
    export type Response = undefined;
}

// ~~~ GetKubernetesAccount ~~~
export namespace GetKubernetesAccount {
    export type Function = (
        request: GetKubernetesAccount.Request,
        useCache?: boolean,
    ) => Promise<AxiosResponse<GetKubernetesAccount.Response>>;
    export interface Request {
        id: string;
    }
    export interface Response extends KubernetesAccount {}
}

// ~~~ GetKubernetesAccountSummary ~~~
export namespace GetKubernetesAccountSummary {
    export type Function = (
        request: GetKubernetesAccountSummary.Request,
    ) => Promise<AxiosResponse<GetKubernetesAccountSummary.Response>>;
    export interface Request {
        id: string;
    }
    export interface Response {
        id: string;
        name: string;
        numberOfNodes: number;
        numberOfPods: number;
        numberOfServices: number;
        clusterVersion: string;
        agentsStatus: string;
        agentsStatusDescription: string;
        featureStatuses: FeatureStatus[];
        description: string;
    }
}

// ~~~ GetKubernetesAgentSummary ~~~
export namespace GetKubernetesAgentSummary {
    export type Function = (
        request: GetKubernetesAgentSummary.Request,
    ) => Promise<AxiosResponse<GetKubernetesAgentSummary.Response>>;
    export interface Request {
        id: string;
    }
    export type Response = Array<KubernetesAgentSummary>;
}

export interface FeatureStatus {
    kubernetesFeature: string;
    statusOk: number;
    statusError: number;
    statusInit: number;
    statusPending: number;
}

export enum KubernetesFeatureType {
    imageAssurance = 'imageAssurance',
    imageAssurance_fileAccessMonitor = 'imageAssurance/imageAccessRuntimeMonitor',
    admissionControl = 'admissionControl',
    threatIntelligence = 'threatIntelligence',
    runtimeProtection = 'runtimeProtection',
    runtimeProtection_profiling = 'runtimeProtection/profiling',
    postureManagement = 'posture-management',
}

export enum KubernetesFeatureStatus {
    ENABLE = 'enable',
    DISABLE = 'disable',
}

export interface KubernetesAgentSummary {
    agentType: KubernetesAgentSummaryType;
    agentSummary: KubernetesAgentSummaryDetails[];
}

export enum KubernetesAgentSummaryType {
    Inventory = 'Inventory',
    AssetsManagement = 'AssetsManagement',
    FlowLogs = 'FlowLogs',
    ImageScan = 'ImageScan',
    ImageScanEngine = 'ImageScanEngine',
    ImageAccessRuntimeMonitor = 'ImageAccessRuntimeMonitor',
    AdmissionControlEnforcer = 'AdmissionControlEnforcer',
    AdmissionControlPolicy = 'AdmissionControlPolicy',
    RuntimeProtectionPolicy = 'RuntimeProtectionPolicy',
    RuntimeProtectionDaemon = 'RuntimeProtectionDaemon',
}

export interface KubernetesAgentSummaryDetails {
    version: string;
    isAgentUpToDate: boolean;
    lastCommunicationDate: string;
    status: KubernetesAgentSummaryDetailsStatus;
    description: string;
    nodeName: string;
    creationDate: string;
    agentIdentifier: string;
    components: AgentSummaryComponent[];
}

export enum KubernetesAgentSummaryDetailsStatus {
    OK = 'OK',
    PENDING = 'PENDING',
    PENDING_CLEANUP = 'PENDING_CLEANUP',
    ERROR = 'ERROR',
    WARNING = 'WARNING',
}

export interface AgentSummaryComponent {
    lastOkDate: string;
    id: string;
    name: string;
    status: string;
    description: string;
    activationDate: string;
}

// ~~~ RequestAgentStatusReportCSV ~~~
export namespace RequestAgentStatusReportCSV {
    export type Function = () => Promise<AxiosResponse<RequestAgentStatusReportCSV.Response>>;
    export type Response = string;
}
