import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'common/services/store/store';
import { LayoutDirection } from 'common/components/Graph/Models/LayoutDirection';

interface IVisualizationGraphState {
    graphDirection: LayoutDirection | undefined;
}

const initialState: IVisualizationGraphState = {
    graphDirection: LayoutDirection.LeftToRight,
};

export const visualizationGraphStateSlice = createSlice({
    name: 'visualizationGraphState',
    initialState,
    reducers: {
        setVisualizationGraphState: (
            state: IVisualizationGraphState,
            action: PayloadAction<LayoutDirection | undefined>,
        ) => {
            state.graphDirection = action.payload || initialState.graphDirection;
        },
    },
});
export const { setVisualizationGraphState } = visualizationGraphStateSlice.actions;

export const getVisualizationGraphState = (state: RootState): LayoutDirection | undefined => {
    return state.visualizationGraphState.graphDirection;
};

export default visualizationGraphStateSlice.reducer;
