import {
    commonBoxItemProps,
    commonInputItemProps,
    commonLazySelectFieldItemProps,
    commonMultiSelectFieldItemProps,
    commonOrgUnitsFieldItemProps,
    commonRadioItemProps,
    commonSingleDateFieldItemProps,
    commonTextAreaItemProps,
    FormItemType,
} from 'common/erm-components/custom/CustomForm/CustomForm.consts';
import { ermTrans } from '../../../RiskManagement.utils';
import {
    ICommonIgnoreItem,
    ICommonIgnoreItemPartial,
    ICommonIgnoreItemUpdate,
    ICommonItemsMap,
} from './CommonIgnoreList.interface';
import {
    IBoxItem,
    IBoxState,
    IItemsMap,
    IRadioItem,
} from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import {
    convertDateToValue,
    convertValueToDate,
    getSafeTrimmedSingleValue,
    getTrimmedMultiValue,
    getTrimmedSingleValue,
} from 'common/erm-components/custom/CustomForm/CustomForm.values';
import { getAllEnvironmentOptions, getEntityNamesOptions, getOrgUnitsRoot } from '../../../RiskManagement.options';
import { checkOneOfVulnerableFields, getOtherIgnoreItemNames } from './CommonIgnoreList.validations';
import { getNewExpirationDateState } from './CommonIgnoreList.options';
import { CommonIgnoreInnerNames, CommonIgnoreNames } from './CommonIgnoreList.consts';
import { isPassedDate } from 'common/erm-components/utils/dates';
import i18n from 'i18next';

const getOrgEnvRadio = async (ignoreItem?: ICommonIgnoreItemPartial): Promise<IRadioItem> => ({
    ...commonRadioItemProps,
    itemType: FormItemType.radio,
    name: CommonIgnoreInnerNames.orgEnvRadio,
    state: {},
    options: [
        {
            name: CommonIgnoreInnerNames.orgUnitsRadioOption,
            subItems: [
                {
                    ...commonOrgUnitsFieldItemProps,
                    name: CommonIgnoreInnerNames.orgUnitIds,
                    state: {
                        label: ermTrans('IGNORE_LIST.COMMON.TABLE.COLUMNS.ORG_UNIT_IDS.HEADER'),
                        orgUnitsRoot: await getOrgUnitsRoot(),
                        value: ignoreItem?.orgUnitIds,
                        tooltip: ermTrans('IGNORE_LIST.COMMON.FORM.TOOLTIP.ORG_UNITS'),
                    },
                },
            ],
        },
        {
            name: CommonIgnoreInnerNames.environmentsRadioOption,
            subItems: [
                {
                    ...commonMultiSelectFieldItemProps,
                    name: CommonIgnoreInnerNames.envIds,
                    state: {
                        label: ermTrans('IGNORE_LIST.COMMON.TABLE.COLUMNS.ENV_IDS.HEADER'),
                        options: await getAllEnvironmentOptions(),
                        value: ignoreItem?.envIds,
                        tooltip: ermTrans('IGNORE_LIST.COMMON.FORM.TOOLTIP.ENVIRONMENTS'),
                    },
                },
            ],
        },
    ],
});

const getEntityRadio = async (ignoreItem?: ICommonIgnoreItemPartial): Promise<IRadioItem> => ({
    ...commonRadioItemProps,
    itemType: FormItemType.radio,
    name: CommonIgnoreInnerNames.entityRadio,
    state: {},
    options: [
        {
            name: CommonIgnoreInnerNames.entityNamesRadioOption,
            subItems: [
                {
                    ...commonLazySelectFieldItemProps,
                    name: CommonIgnoreInnerNames.entityNames,
                    getLazyOptions: getEntityNamesOptions,
                    state: {
                        label: ermTrans('IGNORE_LIST.COMMON.TABLE.COLUMNS.ENTITY_NAMES.HEADER'),
                        value: ignoreItem?.entityNames,
                        tooltip: ermTrans('IGNORE_LIST.COMMON.FORM.TOOLTIP.ENTITY_NAMES'),
                        placeholder: ermTrans('IGNORE_LIST.COMMON.FORM.PLACEHOLDER.ENTITY_NAMES'),
                    },
                },
            ],
        },
        {
            name: CommonIgnoreInnerNames.entityIdRadioOption,
            subItems: [
                {
                    ...commonInputItemProps,
                    name: CommonIgnoreInnerNames.entityId,
                    state: {
                        label: ermTrans('IGNORE_LIST.COMMON.TABLE.COLUMNS.ENTITY_ID.HEADER'),
                        value: ignoreItem?.entityId,
                        tooltip: ermTrans('IGNORE_LIST.COMMON.FORM.TOOLTIP.ENTITY_ID'),
                    },
                },
            ],
        },
    ],
});

export const boxProps: Partial<IBoxState> = {
    direction: 'column',
    padding: [2, 2, 5, 2],
    isRequired: true,
    labelProps: { color: 'strong', padding: [0, 0, 2, 0] },
    withBackground: true,
};

const getVulBox = async (ignoreItem?: ICommonIgnoreItemPartial): Promise<IBoxItem> => ({
    ...commonBoxItemProps,
    name: CommonIgnoreNames.vulBox,
    checkValidation: checkOneOfVulnerableFields,
    state: {
        ...boxProps,
        label: ermTrans('IGNORE_LIST.COMMON.FORM.VUL_LABEL'),
        tooltip: ermTrans('IGNORE_LIST.COMMON.FORM.TOOLTIP.VUL_LABEL'),
    },
    subItems: [await getOrgEnvRadio(ignoreItem), await getEntityRadio(ignoreItem)],
});

export const getExpirationDateLabel = (expired: boolean): string => {
    return ermTrans('IGNORE_LIST.COMMON.FORM.EXPIRATION_DATE', {
        suffix: expired ? i18n.t('COMMON.EXPIRED_SUFFIX') : '',
    });
};

export const getCommonIgnoreItemsMap = async (
    fetchAllItems: () => Promise<ICommonIgnoreItem[]>,
    ignoreItem?: ICommonIgnoreItemPartial,
): Promise<ICommonItemsMap> => {
    const expired = isPassedDate(ignoreItem?.expirationDate);
    return {
        name: {
            ...commonInputItemProps,
            name: CommonIgnoreNames.name,
            state: {
                value: ignoreItem?.name,
                isRequired: true,
                charsLimit: 100,
                label: ermTrans('IGNORE_LIST.COMMON.TABLE.COLUMNS.NAME.HEADER'),
                forbiddenOtherValues: await getOtherIgnoreItemNames(fetchAllItems, ignoreItem),
            },
        },
        description: {
            ...commonTextAreaItemProps,
            name: CommonIgnoreNames.description,
            state: {
                value: ignoreItem?.description,
                charsLimit: 200,
                label: ermTrans('IGNORE_LIST.COMMON.TABLE.COLUMNS.DESCRIPTION.HEADER'),
            },
        },
        expirationDate: {
            ...commonSingleDateFieldItemProps,
            name: CommonIgnoreNames.expirationDate,
            getNewItemState: getNewExpirationDateState,
            state: {
                value: convertDateToValue(ignoreItem?.expirationDate),
                label: getExpirationDateLabel(expired),
                labelProps: { color: expired ? 'alert' : undefined },
                tooltip: ermTrans('IGNORE_LIST.COMMON.FORM.TOOLTIP.EXPIRATION_DATE'),
            },
        },
        vulBox: await getVulBox(ignoreItem),
    };
};

export const getCommonIgnoreSuccessNotification = (): string => {
    return ermTrans('IGNORE_LIST.COMMON.NOTIFICATION.SUCCESS_MESSAGE');
};

export const getCommonIgnoreFailedNotification = (_: IItemsMap, error: string): string => {
    return error || ermTrans('IGNORE_LIST.COMMON.NOTIFICATION.ERROR_MESSAGE');
};

export const getCommonIgnoreItemFromValues = (itemsMap: IItemsMap): ICommonIgnoreItemUpdate => {
    return {
        name: getSafeTrimmedSingleValue(itemsMap[CommonIgnoreNames.name]),
        description: getTrimmedSingleValue(itemsMap[CommonIgnoreNames.description]),
        expirationDate: convertValueToDate(getTrimmedSingleValue(itemsMap[CommonIgnoreNames.expirationDate])),
        entityId: getTrimmedSingleValue(itemsMap[CommonIgnoreInnerNames.entityId]),
        envIds: getTrimmedMultiValue(itemsMap[CommonIgnoreInnerNames.envIds]),
        orgUnitIds: getTrimmedMultiValue(itemsMap[CommonIgnoreInnerNames.orgUnitIds]),
        entityNames: getTrimmedMultiValue(itemsMap[CommonIgnoreInnerNames.entityNames]),
    };
};
