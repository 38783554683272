import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { LogFields } from 'common/module_interface/intelligence/Logs/Logs.interface';

export const LogsFilterTypes: { [key: string]: IProtectedAssetFilter } = {
    ADD_FILTER: {
        id: FILTERS_KEYS.ADD_FILTER,
    },
    RECENTLY_USED_FILTERS: {
        id: FILTERS_KEYS.RECENTLY_USED_FILTERS,
    },
    SAVED_FILTERS: {
        id: FILTERS_KEYS.SAVED_FILTERS,
    },
    DATE_PICKER: {
        id: FILTERS_KEYS.DATE_PICKER,
    },
    CLEAR_ALL: {
        id: FILTERS_KEYS.CLEAR_BUTTON,
    },
    STATUS: {
        id: LogFields.eventStatus,
        isField: true,
    },
    AWS_EVENT: {
        id: LogFields.eventAws,
        isField: true,
    },
};
