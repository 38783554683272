import { IProtectedAssetDetailPanelProvider } from 'common/module_interface/assets/ProtectedAssets';
import i18next from 'i18next';
import VulnerabilityWrapper from './VulnerabilityWrapper';
import AwpScanResultEmptyState from 'modules/workloads/protectedAsset/components/AwpScanResultEmptyState';
import vulnerabilityService from 'modules/workloads/services/vulnerability/vulnerability.service';
import { Addin } from 'common/extensibility/AddinRegistry';
import FailureLoadingScanResults from 'modules/workloads/protectedAsset/components/FailureLoadingScanResults';
import WorkloadService from 'modules/workloads/services/workload/workload.service';
import { AssetTypes } from 'common/assets/assets.const';
import EnableAWP from 'modules/workloads/aws/tabs/awp/components/EnableAWP/EnableAWP';
import { Vendors } from 'common/consts/vendors';
import AwpService from 'modules/workloads/services/awp/awp.service';
import { GetVulnerabilityScanMetadata } from 'modules/workloads/services/vulnerability/vulnerability.interface';
import {
    GetAwsServerlessAccountsState,
    GetWorkloadAgentessAccount,
} from 'modules/workloads/services/awp/awp.interface';
import { isScanEngineVersion2 } from '../sbom/utils';
import WorkloadsScanResultEmptyState from 'modules/workloads/protectedAsset/components/WorkloadsScanResultEmptyState';
import ImageScanResultEmptyState from 'modules/workloads/protectedAsset/components/ImageScanResultEmptyState';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { ICloudEntity } from 'common/module_interface/assets/ICloudEntity';
import LambdaEnableEmptyState from 'modules/workloads/protectedAsset/components/LambdaEnableEmptyState';

export enum VulnerabilityPageTabRender {
    NONE = 'NONE',
    V1 = 'V1',
    V2 = 'V2',
    AWP_EMPTY_STATE = 'AWP_EMPTY_STATE',
    AWP_ENABLE_STATE = 'AWP_ENABLE_STATE',
    LAMBDA_EMPTY_STATE = 'LAMBDA_EMPTY_STATE',
    LAMBDA_ENABLE_STATE = 'LAMBDA_ENABLE_STATE',
    WORKLOADS_EMPTY_STATE = 'WORKLOADS_EMPTY_STATE',
    CONTAINERS_EMPTY_STATE = 'CONTAINERS_EMPTY_STATE',
    FAILURE = 'FAILURE',
}

const awpAssetTypes = [AssetTypes.AWS_INSTANCE, AssetTypes.AZURE_VIRTUALMACHINE, AssetTypes.AZURE_FUNCTIONAPP];

const serverlessAssetTypes = [AssetTypes.AWS_LAMBDA];

const imagesAssetTypes = [
    AssetTypes.CONTAINERREGISTRY_CONTAINERREGISTRYIMAGE,
    AssetTypes.AWS_ECSIMAGE,
    AssetTypes.KUBERNETES_KUBERNETESIMAGE,
    AssetTypes.SHIFTLEFT_SHIFTLEFTIMAGE,
];

export const workloadAssetTypes = [
    AssetTypes.KUBERNETES_KUBERNETESDAEMONSET,
    AssetTypes.KUBERNETES_KUBERNETESDEPLOYMENT,
    AssetTypes.KUBERNETES_KUBERNETESPOD,
    AssetTypes.KUBERNETES_KUBERNETESREPLICASET,
    AssetTypes.KUBERNETES_KUBERNETESSTATEFULSET,
    AssetTypes.KUBERNETES_KUBERNETESCRONJOB,
];

export type VulnerabilityParams = { typeByPlatform: string; cloudAccountId: string; platform: Vendors };
export type VulnerabilityHandler = (params: VulnerabilityParams) => Promise<VulnerabilityPageTabRender>;
export type VulnerabilityDecorator = (next: VulnerabilityHandler) => VulnerabilityHandler;
export type ScanMetadataPromiseFunc = () => ReturnType<GetVulnerabilityScanMetadata.Function>;
export type GetAgentlessAccountFunc = () => ReturnType<GetWorkloadAgentessAccount.Function>;
export type GetServerlessAccountFunc = () => ReturnType<GetAwsServerlessAccountsState.Function>;

export const defaultVulnerabilityHandler: VulnerabilityHandler = () => Promise.resolve(VulnerabilityPageTabRender.NONE);

export const workloadsVulnerabilityHandler = (scanMetadataPromise: ScanMetadataPromiseFunc): VulnerabilityDecorator => {
    return (next: VulnerabilityHandler) => {
        return async (params) => {
            if (!workloadAssetTypes.includes(params.typeByPlatform)) {
                return next(params);
            }

            try {
                const {
                    data: { scanInfo },
                } = await scanMetadataPromise();
                return isScanEngineVersion2(scanInfo) ? VulnerabilityPageTabRender.V2 : VulnerabilityPageTabRender.V1;
            } catch (error: any) {
                if (error.response?.status === 404) {
                    return VulnerabilityPageTabRender.WORKLOADS_EMPTY_STATE;
                }
                return VulnerabilityPageTabRender.FAILURE;
            }
        };
    };
};

export const imagesVulnerabilityHandler = (scanMetadataPromise: ScanMetadataPromiseFunc): VulnerabilityDecorator => {
    return (next: VulnerabilityHandler) => {
        return async (params) => {
            if (!imagesAssetTypes.includes(params.typeByPlatform)) {
                return next(params);
            }

            try {
                const {
                    data: { scanInfo },
                } = await scanMetadataPromise();
                return isScanEngineVersion2(scanInfo) ? VulnerabilityPageTabRender.V2 : VulnerabilityPageTabRender.V1;
            } catch (error: any) {
                if (error.response?.status === 404) {
                    return VulnerabilityPageTabRender.CONTAINERS_EMPTY_STATE;
                }
                return VulnerabilityPageTabRender.FAILURE;
            }
        };
    };
};

export const awpVulnerabilityHandler = (
    scanMetadataPromise: ScanMetadataPromiseFunc,
    getAgentlessAccount: GetAgentlessAccountFunc,
): VulnerabilityDecorator => {
    return (next: VulnerabilityHandler) => {
        return async (params) => {
            if (!awpAssetTypes.includes(params.typeByPlatform)) {
                return next(params);
            }
            try {
                const {
                    data: { scanInfo },
                } = await scanMetadataPromise();
                return isScanEngineVersion2(scanInfo) ? VulnerabilityPageTabRender.V2 : VulnerabilityPageTabRender.V1;
            } catch (error: any) {
                if (error.response?.status !== 404) {
                    return VulnerabilityPageTabRender.FAILURE;
                }
                try {
                    await getAgentlessAccount();
                    return VulnerabilityPageTabRender.AWP_EMPTY_STATE;
                } catch (error: any) {
                    return error.response?.status === 404
                        ? VulnerabilityPageTabRender.AWP_ENABLE_STATE
                        : VulnerabilityPageTabRender.FAILURE;
                }
            }
        };
    };
};

export const lambdaVulnerabilityHandler = (
    scanMetadataPromise: ScanMetadataPromiseFunc,
    getServerlessAccount: GetServerlessAccountFunc,
): VulnerabilityDecorator => {
    return (next: VulnerabilityHandler) => {
        return async (params) => {
            if (!serverlessAssetTypes.includes(params.typeByPlatform)) {
                return next(params);
            }
            try {
                await scanMetadataPromise();
                return VulnerabilityPageTabRender.V2;
            } catch (error: any) {
                if (error.response?.status !== 404) {
                    return VulnerabilityPageTabRender.FAILURE;
                }
                try {
                    const response = await getServerlessAccount();
                    return response.data.state === 'active'
                        ? VulnerabilityPageTabRender.LAMBDA_EMPTY_STATE
                        : VulnerabilityPageTabRender.LAMBDA_ENABLE_STATE;
                } catch (error: any) {
                    return error.response?.status === 404
                        ? VulnerabilityPageTabRender.LAMBDA_ENABLE_STATE
                        : VulnerabilityPageTabRender.FAILURE;
                }
            }
        };
    };
};

export const getVulnerabilityPageTabType = (
    vulnScanMetadataPromise: () => ReturnType<GetVulnerabilityScanMetadata.Function>,
    getWorkloadAgentlessAccount: () => ReturnType<GetWorkloadAgentessAccount.Function>,
    getServerlessAccount: () => ReturnType<GetAwsServerlessAccountsState.Function>,
) => {
    const handlers = [
        lambdaVulnerabilityHandler(vulnScanMetadataPromise, getServerlessAccount),
        awpVulnerabilityHandler(vulnScanMetadataPromise, getWorkloadAgentlessAccount),
        imagesVulnerabilityHandler(vulnScanMetadataPromise),
        workloadsVulnerabilityHandler(vulnScanMetadataPromise),
    ];
    let handler = defaultVulnerabilityHandler;
    for (const h of handlers) {
        handler = h(handler);
    }
    return handler;
};

const vulnerabilitiesPageTabs: Record<
    Exclude<keyof typeof VulnerabilityPageTabRender, 'NONE'>,
    IProtectedAssetDetailPanelProvider['getValue']
> = {
    [VulnerabilityPageTabRender.V1]: () => {
        return {
            name: 'vulnerabilities',
            title: i18next.t('k8s_protected-asset-details:tabs.vulnerabilities'),
            isReact: false,
        };
    },
    [VulnerabilityPageTabRender.V2]: () => {
        return {
            name: 'vulnerabilities',
            title: i18next.t('k8s_protected-asset-details:tabs.vulnerabilities'),
            component: VulnerabilityWrapper,
            isReact: true,
        };
    },
    [VulnerabilityPageTabRender.AWP_EMPTY_STATE]: () => {
        return {
            name: 'vulnerabilities',
            title: i18next.t('k8s_protected-asset-details:tabs.vulnerabilities'),
            component: AwpScanResultEmptyState,
            isReact: true,
        };
    },
    [VulnerabilityPageTabRender.AWP_ENABLE_STATE]: () => {
        return {
            name: 'vulnerabilities',
            title: i18next.t('k8s_protected-asset-details:tabs.vulnerabilities'),
            component: ({
                entity: {
                    cloudAccountId,
                    protectedAsset: { platform },
                },
            }) => {
                return <EnableAWP cloudAccountId={cloudAccountId} platform={platform as Vendors} />;
            },
            isReact: true,
        };
    },
    [VulnerabilityPageTabRender.LAMBDA_ENABLE_STATE]: () => {
        return {
            name: 'vulnerabilities',
            title: i18next.t('k8s_protected-asset-details:tabs.vulnerabilities'),
            component: LambdaEnableEmptyState,
            isReact: true,
        };
    },
    [VulnerabilityPageTabRender.LAMBDA_EMPTY_STATE]: () => {
        return {
            name: 'vulnerabilities',
            title: i18next.t('k8s_protected-asset-details:tabs.vulnerabilities'),
            component: AwpScanResultEmptyState,
            isReact: true,
        };
    },
    [VulnerabilityPageTabRender.WORKLOADS_EMPTY_STATE]: () => {
        return {
            name: 'vulnerabilities',
            title: i18next.t('k8s_protected-asset-details:tabs.vulnerabilities'),
            component: WorkloadsScanResultEmptyState,
            isReact: true,
        };
    },
    [VulnerabilityPageTabRender.CONTAINERS_EMPTY_STATE]: () => {
        return {
            name: 'vulnerabilities',
            title: i18next.t('k8s_protected-asset-details:tabs.vulnerabilities'),
            component: ImageScanResultEmptyState,
            isReact: true,
        };
    },
    [VulnerabilityPageTabRender.FAILURE]: () => {
        return {
            name: 'vulnerabilities',
            title: i18next.t('k8s_protected-asset-details:tabs.vulnerabilities'),
            component: FailureLoadingScanResults,
            isReact: true,
        };
    },
};

const getEntityIdAndType = async (
    typeByPlatform: string,
    entityId: string,
    protectedAsset: IProtectedAssetViewModel,
    cloudEntity: ICloudEntity,
) => {
    let id = '';
    const isWorkloadType = workloadAssetTypes.some((type) => type === typeByPlatform);
    if (isWorkloadType) {
        const { data } = await WorkloadService.getImages({
            request: { dome9Id: protectedAsset.id },
            base: { cachingConfig: { useCache: true } },
        });
        if (data.length > 0) {
            id = data[0].imageId;
        }
    }
    if (typeByPlatform === AssetTypes.AWS_LAMBDA) {
        id = cloudEntity?.id || id;
    }
    const entityType = isWorkloadType ? 'KubernetesImage' : protectedAsset.type;
    return { entityId: id || entityId, entityType };
};

export const vulnerabilitiesPageTabsFactory = (
    prefix: string,
    position: number,
): Addin<IProtectedAssetDetailPanelProvider>[] => {
    return Object.entries(vulnerabilitiesPageTabs).map(([type, getValue]) => {
        return {
            id: `${prefix}-${type}`,
            position,
            content: {
                isRelevant: async ({ typeByPlatform, protectedAsset, entityId, cloudAccountId, cloudEntity }) => {
                    const { entityId: id, entityType } = await getEntityIdAndType(
                        typeByPlatform,
                        entityId,
                        protectedAsset,
                        cloudEntity,
                    );
                    const tabType = await getVulnerabilityPageTabType(
                        () =>
                            vulnerabilityService.getVulnerabilityScanMetadata({
                                entityId: id,
                                entityType,
                                environmentId: cloudAccountId,
                            }),
                        () =>
                            AwpService.getWorkloadAgentessAccount(
                                cloudAccountId,
                                protectedAsset.platform as Vendors,
                                true,
                            ),
                        () => AwpService.getAwsServerlessAccountsState(cloudAccountId),
                    )({ typeByPlatform, cloudAccountId, platform: protectedAsset.platform as Vendors });
                    return tabType === type;
                },
                getValue,
            },
        };
    });
};
