import { CIEM_ASSET_LABELS } from '../../consts';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';

export interface IThirdPartyDisplay {
    title: string;
    iconName: AllIconsName;
}

const thirdParty: IThirdPartyDisplay = {
    title: 'Third Party',
    iconName: 'thirdParty',
};

export function getThirdParty(assetLabels?: string[]): IThirdPartyDisplay | undefined {
    if (assetLabels?.includes(CIEM_ASSET_LABELS.THIRD_PARTY)) {
        return thirdParty;
    }
}
