import { IamSensitivity, IamSensitivityRoleAndTableInfo } from './IAMSensitivity.interface';
import { fetchIAMSensitivity } from '../../../../Issues.utils';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { IIssue } from 'common/module_interface/RiskManagement/issues/Issues.interface';

export const getIamSensitivityData = (
    iAmSensitivityInfo: IamSensitivity,
    rolesInfo: IProtectedAssetViewModel[],
): IamSensitivityRoleAndTableInfo[] =>
    Object.keys(iAmSensitivityInfo?.roleScopeActions).map((key, index) => ({
        id: key,
        roleAsset: rolesInfo[index],
        actions: iAmSensitivityInfo.roleScopeActions[key],
    }));

export const fetchIAMSensitivityInfo = async (
    issue: IIssue,
    asset: IProtectedAssetViewModel,
): Promise<IamSensitivityRoleAndTableInfo[]> => {
    try {
        const response: IamSensitivity = await fetchIAMSensitivity(issue, asset);
        const roleList: IProtectedAssetViewModel[] = await fetchRolesInfo(response, issue.environmentId);
        if (roleList.length === 0) {
            return [];
        }
        return getIamSensitivityData(response, roleList);
    } catch (error) {
        throw new Error('Failed to load I AM Sensitivity table');
    }
};

const fetchRolesInfo = async (
    iamSensitivityData: IamSensitivity,
    issueEnvID: string,
): Promise<IProtectedAssetViewModel[]> => {
    if (!Object.keys(iamSensitivityData?.roleScopeActions).length) {
        return [];
    }

    const fetchPromises: Promise<IProtectedAssetViewModel | null>[] = Object.keys(
        iamSensitivityData?.roleScopeActions,
    ).map((key) => getProtectedAssetsService().getProtectedAssetById(key, iamSensitivityData?.entityType, issueEnvID));
    let roleList: (IProtectedAssetViewModel | null)[] = [];
    try {
        roleList = await Promise.all(fetchPromises);
    } catch (e) {
        throw new Error('Failed to load role objects');
    }
    if (roleList.includes(null)) {
        throw new Error('Failed to load role objects');
    }
    return roleList as IProtectedAssetViewModel[];
};
