import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Icon, Stack } from 'common/design-system/components-v2';

const getStatus = (value: string | boolean | number) => {
    switch (typeof value) {
        case 'string':
            return value.toLowerCase() === 'true';
        case 'boolean':
            return value;
        case 'number':
            return value > 0;
        default:
            return false;
    }
};

const StatusCellRenderer: React.FC<ICellRendererParams> = ({ value }) => {
    const valueStatus = getStatus(value);

    return (
        <>
            {valueStatus && (
                <Stack justifyContent='center' fullHeight>
                    <Icon name={'check'}></Icon>
                </Stack>
            )}
        </>
    );
};

export default StatusCellRenderer;
