import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Dot } from './dot';

const DotCellRenderer: React.FC<ICellRendererParams> = ({ node, value }) => {
    if (node.group && value === '') {
        return 'N/A';
    }
    if (value === undefined || value === null) {
        return '';
    }
    const isValueTruthy = typeof value === 'string' ? value === 'true' : Boolean(value);
    return <>{isValueTruthy ? <Dot /> : ''}</>;
};

export default DotCellRenderer;
