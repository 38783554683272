import { IRemediationCreationByEventProps } from '../Findings.interface';
import { IRemediationCreation, RemediationModuleType } from 'common/interface/remediation';
import RemediationApi from 'common/services/apis/Remediation/remediationApi.services';

export const createCiemRemediation = async (remediationProps: IRemediationCreationByEventProps) => {
    const { event, cloudBots, comment, severities, platform, ruleLogic } = remediationProps;
    const request: IRemediationCreation = {
        rulesetId: event.bundleId,
        platform: platform,
        cloudAccountType: platform, // @@@ Rina???
        cloudAccountIds: [event.cloudAccountId],
        cloudBots: cloudBots,
        comment: comment,
        ruleName: event.ruleName,
        ruleLogicHash: event.ruleLogicHash,
        severities: severities,
        logicExpressions: [ruleLogic],
    };
    await RemediationApi.create(request, RemediationModuleType.CIEM);
};
