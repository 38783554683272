import {
    getGslService,
    ILogAdditionalParams,
    ILogsService,
    LOGS_SERVICE_ID,
} from 'common/module_interface/intelligence/intelligence';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { IGslCount, IGslFilter, IGslSort } from 'common/components/gsl/GslService.interface';
import {
    convertGslCountUiToModel,
    convertGslFilterUiToModel,
    convertGslSortUiToModel,
    convertKustoAggregationsModelToUi,
    convertKustoFieldNameListUiToModel,
    convertKustoItemListModelToUi,
} from 'common/erm-components/utils/Convertors/convertors';
import { IModelUiMappingInfo } from 'common/erm-components/utils/Convertors/convertors.interface';
import { ICountLog, ILog, LogFields } from 'common/module_interface/intelligence/Logs/Logs.interface';
import { kustoEventMappersByUiName } from 'common/components/KustoEvents/KustoEventService';
import { GslCommonFields } from 'common/components/gsl/GslCommonFields';

export enum ILogModelFields {
    eventTime = 'event_time',
    eventName = 'event_name',
    eventType = 'event_type',
    eventAws = 'event_aws',
    cloudGuardEvent = 'event_d9readonly',
    identityName = 'identity_name',
    identityId = 'identity_id',
    identityUserAgent = 'identity_useragent',
    identityRegion = 'identity_region',
    identityType = 'identity_type',
    issuerName = 'issuer_name',
    issuerId = 'issuer_id',
    issuerType = 'issuer_type',
    issuerRegion = 'issuer_region',
    targetName = 'target_name',
    targetId = 'target_id',
    targetNamespace = 'target_namespace',
    eventStatus = 'event_status',
    sourceIp = 'src_address_ip',
    sourceCountry = 'src_address_geolocation_countryname',
    sourceCountryCode = 'src_address_geolocation_countrycode',
    sourceIsMalicious = 'src_ismalicious',
    count = GslCommonFields.count,
}

interface ILogModel {
    [ILogModelFields.eventTime]: string;
    [ILogModelFields.eventName]: string;
    [ILogModelFields.eventType]: string;
    [ILogModelFields.eventAws]: string;
    [ILogModelFields.cloudGuardEvent]: string;
    [ILogModelFields.identityName]: string;
    [ILogModelFields.identityId]: string;
    [ILogModelFields.identityUserAgent]: string;
    [ILogModelFields.identityRegion]: string;
    [ILogModelFields.identityType]: string;
    [ILogModelFields.issuerName]: string;
    [ILogModelFields.issuerId]: string;
    [ILogModelFields.issuerType]: string;
    [ILogModelFields.issuerRegion]: string;
    [ILogModelFields.targetName]: string;
    [ILogModelFields.targetId]: string;
    [ILogModelFields.targetNamespace]: string;
    [ILogModelFields.eventStatus]: string;
    [ILogModelFields.sourceIp]: string;
    [ILogModelFields.sourceCountry]: string;
    [ILogModelFields.sourceCountryCode]: string;
    [ILogModelFields.sourceIsMalicious]: string;
    [ILogModelFields.count]: number;
}

type IPartialCountLogModel = Partial<ILogModel>;

interface ICountLogModel extends IPartialCountLogModel {
    [ILogModelFields.count]: number;
}

interface ILogFieldMappingInfo extends IModelUiMappingInfo {
    modelField: keyof ILogModel;
    uiField: keyof ILog;
}

const LogModelToLogUiMappers: ILogFieldMappingInfo[] = [
    { modelField: ILogModelFields.eventTime, uiField: LogFields.eventTime },
    { modelField: ILogModelFields.cloudGuardEvent, uiField: LogFields.cloudGuardEvent },
    { modelField: ILogModelFields.eventAws, uiField: LogFields.eventAws },
    { modelField: ILogModelFields.eventName, uiField: LogFields.eventName },
    { modelField: ILogModelFields.eventType, uiField: LogFields.eventType },
    { modelField: ILogModelFields.identityName, uiField: LogFields.identityName },
    { modelField: ILogModelFields.identityId, uiField: LogFields.identityId },
    { modelField: ILogModelFields.identityUserAgent, uiField: LogFields.identityUserAgent },
    { modelField: ILogModelFields.identityRegion, uiField: LogFields.identityRegion },
    { modelField: ILogModelFields.identityType, uiField: LogFields.identityType },
    { modelField: ILogModelFields.issuerName, uiField: LogFields.issuerName },
    { modelField: ILogModelFields.issuerId, uiField: LogFields.issuerId },
    { modelField: ILogModelFields.issuerType, uiField: LogFields.issuerType },
    { modelField: ILogModelFields.issuerRegion, uiField: LogFields.issuerRegion },
    { modelField: ILogModelFields.targetName, uiField: LogFields.targetName },
    { modelField: ILogModelFields.targetId, uiField: LogFields.targetId },
    { modelField: ILogModelFields.targetNamespace, uiField: LogFields.targetNamespace },
    { modelField: ILogModelFields.eventStatus, uiField: LogFields.eventStatus },
    { modelField: ILogModelFields.sourceIp, uiField: LogFields.sourceIp },
    { modelField: ILogModelFields.sourceCountry, uiField: LogFields.sourceCountry },
    { modelField: ILogModelFields.sourceCountryCode, uiField: LogFields.sourceCountryCode },
    { modelField: ILogModelFields.sourceIsMalicious, uiField: LogFields.sourceIsMalicious },
    { modelField: ILogModelFields.count, uiField: LogFields.count },
];

const logMappersByModelName: { [key: string]: ILogFieldMappingInfo } = {};
const logMappersByUiName: { [key: string]: ILogFieldMappingInfo } = {};
LogModelToLogUiMappers.forEach((mapper: ILogFieldMappingInfo) => {
    logMappersByModelName[mapper.modelField] = mapper;
    logMappersByUiName[mapper.uiField] = mapper;
});

export class LogsService implements ILogsService {
    public async getLogs(
        filter: IGslFilter,
        limit?: number,
        sortList?: IGslSort[],
        additionalParams?: ILogAdditionalParams,
    ): Promise<ILog[]> {
        const modelFilter: IGslFilter = convertGslFilterUiToModel(logMappersByUiName, filter);
        const sortListModel: IGslSort[] | undefined = convertGslSortUiToModel(logMappersByUiName, sortList);
        const serverItems: ILogModel[] = await getGslService().getItems<ILogModel, ILogAdditionalParams>({
            filter: modelFilter,
            limit,
            sort: sortListModel,
            additionalParams,
        });
        return convertKustoItemListModelToUi<ILogModel, ILog>(LogModelToLogUiMappers, serverItems);
    }

    public async getLogFacets(
        filter: IGslFilter,
        facetNames: string[],
        additionalParams?: ILogAdditionalParams,
    ): Promise<Aggregations> {
        const modelFilter: IGslFilter = convertGslFilterUiToModel(logMappersByUiName, filter);
        const modelFacets: string[] = convertKustoFieldNameListUiToModel(logMappersByUiName, facetNames);
        const modelAggregations: Aggregations = await getGslService().getAggregations(
            {
                filter: modelFilter,
                additionalParams,
            },
            modelFacets,
        );
        return convertKustoAggregationsModelToUi(logMappersByModelName, modelAggregations);
    }

    public async getLogCount(
        filter: IGslFilter,
        count: IGslCount,
        sortList?: IGslSort[],
        additionalParams?: ILogAdditionalParams,
    ): Promise<ICountLog[]> {
        const modelFilter: IGslFilter = convertGslFilterUiToModel(logMappersByUiName, filter);
        const modelCount: IGslCount = convertGslCountUiToModel(logMappersByUiName, count);
        const sortListModel: IGslSort[] | undefined = convertGslSortUiToModel(kustoEventMappersByUiName, sortList);
        const serverItems: ICountLogModel[] = await getGslService().getCount(
            {
                filter: modelFilter,
                sort: sortListModel,
                additionalParams,
            },
            modelCount,
        );
        return convertKustoItemListModelToUi<ICountLogModel, ICountLog>(LogModelToLogUiMappers, serverItems);
    }
}

export default function initializeLogsService() {
    globalAddinContainer.addService(LOGS_SERVICE_ID, new LogsService());
}
