import { IIssueExclusion, IIssueExclusionProps, IIssueExclusionsService } from './IssueExclusions.interface';
import { ISSUE_EXCLUSIONS_SERVICE_ID } from './IssueExclusions.consts';
import { getService } from 'common/extensibility/AddinContainer';
import { showIssueExclusionForm } from './IssueExclusionEditor/IssueExclusionEditor.items';

export const fetchAllIssueExclusions = async (): Promise<IIssueExclusion[]> => {
    return getIssueExclusionsService().getAllIssueExclusions();
};

export const openIssueExclusionEditor = (exclusionProps?: IIssueExclusionProps) => {
    showIssueExclusionForm(exclusionProps);
};

export function getIssueExclusionsService(): IIssueExclusionsService {
    return getService<IIssueExclusionsService>(ISSUE_EXCLUSIONS_SERVICE_ID);
}

export const createEmptyIssueExclusionUpdateProps = (): IIssueExclusionProps => ({
    name: '',
    description: '',
    ruleIds: [],
    severities: [],
    orgUnitIds: [],
    envIds: [],
    entityNames: [],
    entityId: '',
    entityTypes: [],
    expirationDate: undefined,
    tags: [],
});
