import styled from 'styled-components';

const ColumnHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-weight: 500;
`;

const IconWrapper = styled.span`
    cursor: pointer;
`;

export default {
    ColumnHeaderWrapper,
    IconWrapper,
};
