import { SimpleGenericPage } from '../../SimpleTableFilterPage/SimpleGenericPage';
import { I18nTranslationKey, USER_CHANGED_HANDLER_ID, USER_DRAWER, usersTableRegistry } from './initUsersPage';
import { ITableAction, ITableExportButton } from 'common/design-system/components-v2/Table/Table.types';
import { useTranslation } from 'react-i18next';
import { calcFilterData, dataService, filterId, getAggregations, usersFilterIds } from './UsersFiltersDefenitions';
import { useDrawerHandler } from 'common/components/DrawerInfra/Drawer/UseDrawerHandler';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { IDataItem } from 'modules/settings/SimpleTableFilterPage/interfaces';
import { useCallback, useEffect, useState } from 'react';
import { drawerManager } from 'common/components/DrawerInfra/Drawer/DrawerManager';
import { ResetPasswordModal } from './utils/ResetPasswordModal';
import { InviteUserUtil } from './utils/InviteUserUtil';
import { ConnectUserToSsoModal } from './utils/ConnectUserToSsoModal';
import { DisableMfaModal } from './utils/DisableMfaModal';
import { SetAsAccountOwnerModal } from './utils/SetAsAccountOwnerModal';
import { RevokeApiKeyV2Modal } from './utils/RevokeApiKeyV2Modal';
import { getNotificationsService, getUserService } from 'common/interface/services';
import { generateAndDownloadCsv } from 'common/helpers/generateAndDownloadCsv';
import { IUser } from 'common/interface/user';
import { formatDate } from 'common/utils/helpFunctions';
import { getDefaultUserValues } from './utils/getDefaultUserValues';
import { NotificationType } from 'common/interface/notifications';
import i18n from 'common/services/translations/translations';

interface ISelectedUser {
    name: string;
    id: number;
    isMfaEnabled: boolean;
    ssoEnabled: boolean;
    hasApiKeyV2: boolean;
    lastLogin: string;
    isMobileDevicePaired: boolean;
    isOwner: boolean;
}

export const editUser = (items: IDataItem) => {
    const editUser = i18n.t('GENERAL.EDIT_USER', { ns: I18nTranslationKey });
    const user = 'user';
    showDrawer(
        USER_DRAWER.key,
        {
            title: items?.id ? items?.name : editUser,
            componentProps: { userId: items.id },
            icon: user,
        },
        { [USER_DRAWER.eventTypes.userChanged]: USER_CHANGED_HANDLER_ID },
    );
};

const UsersPage = () => {
    const { t } = useTranslation(I18nTranslationKey);
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState<IDataItem[]>([]);
    const [usersForExport, setUsersForExport] = useState<IDataItem[]>([]);
    const [selectedUser, setSelectedUser] = useState<ISelectedUser | null>(null);
    const [modals, setModals] = useState({
        resetPassword: false,
        connectToSso: false,
        disableMfa: false,
        setAsAccountOwner: false,
        revokeApiKey: false,
    });

    const savedFiltersComponentName = 'usersSavedFilters';
    const init = async (useCache = false) => {
        setIsLoading(true);
        const data = await dataService.getAll(useCache);
        setUsers(data?.sort((a, b) => String(a.name).localeCompare(String(b.name))) || []);
        setIsLoading(false);
    };

    useEffect(() => {
        void init();
    }, []);

    const toggleModal = useCallback((key: keyof typeof modals) => {
        setModals((prev) => ({ ...prev, [key]: !prev[key] }));
    }, []);

    const handleSetSelectedUser = (selectedRows: ISelectedUser[]) => {
        setSelectedUser(() => {
            return selectedRows[0] || null;
        });
    };

    const handleAction = useCallback(
        (action: keyof typeof modals, selectedRows: ISelectedUser[]) => {
            handleSetSelectedUser(selectedRows);
            toggleModal(action);
        },
        [toggleModal],
    );

    const isCurrentUser = (selectedRows: ISelectedUser[]) => {
        const getUserInfo = () => getUserService().getUser();
        const user = getUserInfo();
        return selectedRows[0]?.id === user?.id;
    };

    const isMobileDevicePaired = (selectedRows: ISelectedUser[]) => {
        const user = users.filter((user) => user.id === selectedRows[0]?.id)[0];
        return !!user?.isMobileDevicePaired;
    };

    const isMfaEnabled = (selectedRows: ISelectedUser[]) => {
        return selectedRows[0]?.isMfaEnabled;
    };

    const isSsoEnabled = (selectedRows: ISelectedUser[]) => {
        return selectedRows[0]?.ssoEnabled;
    };

    const isOwner = (selectedRows: ISelectedUser[]) => {
        return selectedRows[0]?.isOwner;
    };

    const isSuperUser = () => {
        const isSuperUser = () => getUserService().getIsSuperUser();
        return isSuperUser();
    };

    const hasApiKeyV2 = (selectedRows: ISelectedUser[]) => {
        return selectedRows[0]?.hasApiKeyV2;
    };

    const extraActions: ITableAction[] = [
        {
            id: 'resetPassword',
            name: t('BUTTONS.RESET_PASSWORD'),
            callback: (rows) => handleAction('resetPassword', rows),
            isActionDisabled: (selectedRows) => !selectedRows?.length || isCurrentUser(selectedRows),
        },
        {
            id: 'inviteUser',
            name: t('BUTTONS.INVITE_USER'),
            callback: async (rows) => {
                await InviteUserUtil({ invitedUserId: rows[0]?.id, t });
            },
            isActionDisabled: (selectedRows) => !selectedRows?.length || isMobileDevicePaired(selectedRows),
        },
        {
            id: 'connectToSso',
            name: t('BUTTONS.CONNECT_TO_SSO'),
            callback: (rows) => handleAction('connectToSso', rows),
            isActionDisabled: (selectedRows: ISelectedUser[]) => !selectedRows?.length || isSsoEnabled(selectedRows),
        },
        {
            id: 'disableMfa',
            name: t('BUTTONS.DISABLE_MFA'),
            callback: (rows) => handleAction('disableMfa', rows),
            isActionDisabled: (selectedRows) => !selectedRows?.length || !isMfaEnabled(selectedRows) || !isSuperUser(),
        },
        {
            id: 'setAsAccountOwner',
            name: t('BUTTONS.SET_AS_ACCOUNT_OWNER'),
            callback: (rows) => handleAction('setAsAccountOwner', rows),
            isActionDisabled: (selectedRows) =>
                !selectedRows?.length || isOwner(selectedRows) || isSsoEnabled(selectedRows),
        },
        {
            id: 'revokeApiKeyV2',
            name: t('BUTTONS.REVOKE_API_KEY'),
            callback: (rows) => handleAction('revokeApiKey', rows),
            isActionDisabled: (selectedRows) => !selectedRows?.length || !hasApiKeyV2(selectedRows),
        },
    ];

    const handleConfigurationChanged = () => {
        drawerManager.closeTopDrawer(true);
        void init();
    };

    useDrawerHandler(USER_CHANGED_HANDLER_ID, handleConfigurationChanged);

    const deleteUser = async (items: IDataItem[]) => {
        try {
            const itemsIds = items.map((_item) => _item.id);
            await Promise.all(
                itemsIds.map((id) => {
                    return dataService.delete(id.toString());
                }),
            );

            setUsers((users) => users.filter((role) => !itemsIds.includes(role.id)));
            getNotificationsService().addNotification({
                type: NotificationType.SUCCESS,
                title: '',
                text: t('DRAWER.TOAST.DELETE_SUCCESS'),
            });
        } catch (e) {
            console.error('Error deleting roles', e);
        }
    };
    const addUser = () => {
        showDrawer(
            USER_DRAWER.key,
            {
                title: t('GENERAL.ADD_USER'),
                componentProps: { userId: null },
            },
            { [USER_DRAWER.eventTypes.userChanged]: USER_CHANGED_HANDLER_ID },
        );
    };

    const exportTable = (users: IUser[]) => {
        const fileIdentifier = 'Users';
        const mappedData = users.map((user) => ({
            Id: user?.id,
            User: user?.name || '',
            Roles: user?.rolesNames && Array.isArray(user?.rolesNames) ? user.rolesNames.join('; ') : '',
            SSO: user?.ssoEnabled ? 'TRUE' : 'FALSE',
            MFA: user?.isMfaEnabled ? 'TRUE' : 'FALSE',
            'API Key': user?.hasApiKey ? 'TRUE' : 'FALSE',
            'Mobile Paired': user?.isMobileDevicePaired ? 'TRUE' : 'FALSE',
            'Last Login': user?.lastLogin ? formatDate(user?.lastLogin) : 'N/A',
        }));
        generateAndDownloadCsv(mappedData, fileIdentifier);
    };

    const transformToRoles = (items: IDataItem[]): IUser[] => {
        return items.map((item) => ({
            ...getDefaultUserValues(),
            id: item?.id ? Number(item?.id) : 0,
            name: item?.name as string,
            rolesNames: item?.rolesNames as string[],
            ssoEnabled: !!item?.ssoEnabled || false,
            isMfaEnabled: !!item?.isMfaEnabled || false,
            hasApiKey: !!item?.hasApiKey || false,
            isMobileDevicePaired: !!item?.isMobileDevicePaired || false,
            lastLogin: item?.lastLogin as string,
        }));
    };

    const exportButtons: ITableExportButton[] = [
        {
            label: t('ACTIONS.EXPORT.EXPORT_ALL_RESULTS_TO_CSV'),
            icon: { name: 'download' },
            onClick: () => {
                exportTable(transformToRoles(users));
            },
        },
        {
            label: t('ACTIONS.EXPORT.EXPORT_FILTERED_RESULTS_TO_CSV'),
            icon: { name: 'download' },
            onClick: () => {
                exportTable(transformToRoles(usersForExport));
            },
        },
    ];

    return (
        <>
            <SimpleGenericPage
                isDataLoading={isLoading}
                items={users}
                pagination={false}
                extraActions={extraActions}
                filterId={filterId}
                tableRegistry={usersTableRegistry}
                savedFiltersComponentName={savedFiltersComponentName}
                translationKey={I18nTranslationKey}
                onDelete={deleteUser}
                onAdd={addUser}
                onEdit={editUser}
                calcFilteredData={calcFilterData}
                getAggregations={getAggregations}
                filterIds={usersFilterIds}
                exportButtons={exportButtons}
                setFilteredDataForExport={setUsersForExport}
            />
            <ResetPasswordModal
                isModalOpen={modals.resetPassword}
                toggleModal={() => toggleModal('resetPassword')}
                selectedEmail={selectedUser?.name}
            />
            <ConnectUserToSsoModal
                isModalOpen={modals.connectToSso}
                toggleModal={() => toggleModal('connectToSso')}
                selectedEmail={selectedUser?.name}
                selectedId={selectedUser?.id}
            />
            <DisableMfaModal
                isModalOpen={modals.disableMfa}
                toggleModal={() => toggleModal('disableMfa')}
                selectedEmail={selectedUser?.name}
                selectedId={selectedUser?.id}
            />
            <SetAsAccountOwnerModal
                isModalOpen={modals.setAsAccountOwner}
                toggleModal={() => toggleModal('setAsAccountOwner')}
                selectedEmail={selectedUser?.name}
                selectedId={selectedUser?.id}
            />
            <RevokeApiKeyV2Modal
                isModalOpen={modals.revokeApiKey}
                toggleModal={() => toggleModal('revokeApiKey')}
                selectedEmail={selectedUser?.name}
                selectedId={selectedUser?.id}
            />
        </>
    );
};

export default UsersPage;
