import { IVendor, VENDORS, getVendor } from 'common/consts/vendors';
import { ICloudAccount, IOrganizationalUnit } from 'common/interface/data_services';
import { getAssets } from 'common/assets/Assets.service';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';

export const getLatestFindings = (
    finding: any,
    aggregation: string,
    cloudAccounts: ICloudAccount[],
    organizationalUnits: IOrganizationalUnit[],
) => {
    let displayName;
    let iconClass;
    let environmentId: string;
    let filteredAccount;
    let vendorData;
    const defaultIcon: AllIconsName = 'cloud';
    switch (aggregation) {
        case 'entity':
            displayName = finding.entityDisplayName || finding.entityName;
            break;
        case 'cloudAccountId_calc':
            environmentId = finding.cloudAccountId;
            filteredAccount = cloudAccounts.filter((account: any) => account.id === environmentId);
            iconClass = getVendor(filteredAccount[0].platform)?.newIcon || defaultIcon;
            displayName = finding.entityName;
            break;
        case 'organizationalUnitId':
            displayName =
                organizationalUnits.find((ou) => ou.id === finding.organizationalUnitId)?.name ||
                finding.organizationalUnitId;
            iconClass = defaultIcon;
            break;
        case 'organizationalUnit':
            displayName = finding.ouDisplayName;
            break;
        case 'cloudAccountId':
            displayName = finding.acknowledged ? 'Yes' : 'No';
            environmentId = finding.cloudAccountId;
            filteredAccount = cloudAccounts.filter((account: any) => account.id === environmentId);
            iconClass = getVendor(filteredAccount[0].platform)?.newIcon || defaultIcon;
            break;
        case 'isExcluded':
            displayName = finding.isExcluded ? 'Yes' : 'No';
            break;
        case 'cloudAccountType':
            vendorData = VENDORS.find(
                (item) =>
                    item.elasticVendorType === finding[aggregation].toLowerCase() ||
                    item.name === finding[aggregation].toLowerCase(),
            ) as IVendor;
            iconClass = vendorData.newIcon;
            displayName = finding[aggregation];
            break;
        case 'entityTypeByEnvironmentType':
            displayName = finding.entityTypeByEnvironmentType
                .replace(/([A-Z])/g, ' $1')
                .replace(/^.*?\|/, (string: string) => string.toUpperCase())
                .replace(/(\|)/, ' ');
            break;
        default:
            displayName = finding[aggregation] || 'N/A';
    }
    return {
        displayName: displayName,
        iconClass:
            iconClass || getAssets().find((asset: any) => asset.type === finding.entityType)?.icon || defaultIcon,
    };
};

export const getAggregationFromWidgetSelection = (type: string) => {
    switch (type) {
        case 'cloudAccountId_calc':
        case 'organizationalUnitId': // OU is not supported in the API - use cloudAccountId instead as a workaround and accumulate the data
            return 'cloudAccountId';
        case 'entityTypeByEnvironmentType':
            return 'aggregationEntityTypeByEnvironmentType';
        default:
            return type;
    }
};
