import React from 'react';
import { useTheme } from 'styled-components';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';
import {
    Chip,
    ComponentOverflow,
    Stack,
    Typography,
    Button,
    Message,
    Dropdown,
} from 'common/design-system/components-v2';
import EntityHeader from 'common/design-system/components-v2/EntityHeader';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { SeverityChip } from 'common/components/SeverityChip/SeverityChip';
import { FindingSeverityEnum } from 'common/consts/FindingSeverity';
import { CveOverview } from 'modules/workloads/services/cveDrawerRegistry/cveDrawerRegistry.types';
import { changeUrl } from 'common/utils/http';
import { getLoggerService } from 'common/interface/services';
import { floatToPercentage } from 'modules/workloads/utils/floatToPercentage';
import moment from 'moment-mini';
import List from 'common/design-system/components-v2/List';
import getActions from '../../../Drawers/DrawersActions/DrawerActions';
import { VulnerabilityExclusionType } from '../../../services/vulnerability/vulnerability.interface';

const UpperPanel: React.FC<{ overview: CveOverview; isCveExplorer?: boolean }> = ({
    overview,
    isCveExplorer = false,
}) => {
    const { t } = useTranslation(getK8sNamespace('cve-explorer'));
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const {
        relatedIds,
        lastModified,
        urls,
        sourceUrl,
        source,
        id,
        severity,
        isFixable,
        knownExploit,
        knownExploitDueDate,
        epssScore,
    } = overview.cve;
    const theme = useTheme();

    const relatedCves = relatedIds.map((relatedCve) => (
        <Typography style={{ whiteSpace: 'nowrap' }} key={`related-${relatedCve}`}>
            {relatedCve}
        </Typography>
    ));

    const infoBlocks: Array<BlockInfoProps> = [
        ...(relatedIds.length > 0
            ? [
                  {
                      title: t('cveDrawer.upperPanel.related'),
                      info: <ComponentOverflow components={relatedCves} commaSeperated />,
                  },
              ]
            : []),
        ...(lastModified
            ? [{ title: t('cveDrawer.upperPanel.updated'), info: lastModified, copyable: !!lastModified }]
            : []),
        ...(urls?.length > 0 ? [{ title: t('cveDrawer.upperPanel.references'), info: urls?.length.toString() }] : []),
        ...(sourceUrl
            ? [
                  {
                      title: t('cveDrawer.upperPanel.cveSource'),
                      info: `${source}: ${id}`,
                      externalUrl: sourceUrl,
                      copyable: true,
                  },
              ]
            : []),
        ...(epssScore
            ? [{ title: t('cveDrawer.upperPanel.epssScore'), info: floatToPercentage(epssScore), copyable: true }]
            : []),
    ];

    const cveSeverity = severity.toLowerCase() as FindingSeverityEnum;
    const rightTitleElements = (
        <Stack direction='row' spacing={2}>
            {Boolean(knownExploit) && (
                <Chip
                    tooltip={
                        knownExploitDueDate &&
                        t('cveDrawer.upperPanel.knownExploitDueDate', {
                            date: moment(knownExploitDueDate).format('MMM D, YYYY'),
                        })
                    }
                    leadingIconProps={{ name: 'unsecured', size: 16 }}
                    label={t('cveDrawer.upperPanel.knownExploit')}
                    disableInteraction
                />
            )}
            {isFixable && (
                <Chip
                    leadingIconProps={{ name: 'remedy', size: 16, customColor: theme.palette.surface.blue }}
                    label={t('cveDrawer.upperPanel.fixable')}
                    customTextColor={theme.palette.surface.blue}
                    customColor={theme.palette.surface.brandLight}
                />
            )}
            <SeverityChip severity={cveSeverity} />
            <Stack direction={'row'} spacing={2} margin={[0, 0, 0, 4]}>
                {!isCveExplorer && (
                    <Button
                        onClick={() => changeUrl(`/workload/cve-search?cve=${id}`)}
                        iconProps={{ name: 'magnify' }}
                        dataAid='Find CVE'
                    >
                        {t('cveDrawer.upperPanel.findCveButton')}
                    </Button>
                )}
            </Stack>
            <Dropdown
                open={isDropdownOpen}
                buttonProps={{ iconButton: true, iconProps: { name: 'more' } }}
                onStateChange={(state) => setIsDropdownOpen(state)}
            >
                <List
                    onOptionClick={() => setIsDropdownOpen(false)}
                    options={getActions({
                        cve: {
                            exclusion: overview.cve.exclusion,
                            isExcluded: overview.cve.isExcluded,
                            id: overview.cve.id,
                        },
                        exclusionInitForm: {
                            id: '',
                            type: VulnerabilityExclusionType.CVE,
                            includeInAssessment: false,
                            comment: '',
                            author: '',
                            creationDate: new Date(),
                            errorMessage: '',
                            name: '',
                            filter: {
                                packageName: overview.package.name,
                                environmentsIds: [overview.cloudAccountId],
                                entityNames: [overview.scannedAsset?.entityName || ''],
                                cveId: id,
                            },
                        },
                    })}
                />
            </Dropdown>
        </Stack>
    );

    const onConfirm = async () => {
        await getLoggerService().warning(
            {
                title: t('cveDrawer.upperPanel.reportedFalsePositive.title'),
                cveId: id,
            },
            '',
        );
    };

    return (
        <>
            <Message
                variant={'warning'}
                isOpen={isOpen}
                width={'md'}
                onCancel={() => setIsOpen(false)}
                onClose={() => setIsOpen(false)}
                title={t('cveDrawer.upperPanel.reportedFalsePositive.messageTitle')}
                onConfirm={onConfirm}
            >
                <Typography>{t('cveDrawer.upperPanel.reportedFalsePositive.messageBody', { id: id })}</Typography>
            </Message>
            <EntityHeader
                levelIcon={{
                    iconProps: { name: 'cve' },
                    category: 'severity',
                    level: cveSeverity,
                }}
                titleRightElements={rightTitleElements}
                title={id}
                blocks={infoBlocks}
                disableShowMore
            />
        </>
    );
};

export default UpperPanel;
