export enum PermissionSectionTypes {
    remediation = 'remediation',
    issue = 'issue',
}

export interface PermissionSection {
    type: PermissionSectionTypes;
    title: string;
    key: string;
}

export interface PermissionItemProps {
    title: string;
    data: Record<string, any>;
}
