import React, { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Modal, SelectV2, Stack, Button, Label, Input, TextArea } from 'common/design-system/components-v2';
import ContainerRegisryService from '../../services/containerRegistry/containerRegistry.service';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { FormValues, ModalAddRuleBaseImageProps } from './ModalAddRuleBaseImage.types';
import { DEFAULT_VALUES, SignupSchema } from './ModalAddRuleBaseImage.consts';

const ModalAddRuleBaseImage: React.FC<ModalAddRuleBaseImageProps> = ({
    handleCloseModal,
    isModalOpen,
    onConfirm,
    initialValues,
    onConfirmEdit,
    isEditMode,
}) => {
    const [allContainerRegistryEnv, setAllContainerRegistryEnv] = useState<{ label: string; value: string }[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation(['k8s_base-image', 'k8s_common']);

    const getDefaultValueInitial = useMemo(() => {
        return initialValues || DEFAULT_VALUES;
    }, [initialValues]);

    const {
        formState: { errors },
        control,
        handleSubmit,
        reset,
    } = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: getDefaultValueInitial,
        resolver: yupResolver(SignupSchema) as any,
    });

    useEffect(() => {
        reset(initialValues);
    }, [initialValues, reset]);

    useEffect(() => {
        (async () => {
            const { data } = await ContainerRegisryService.getAllRegistryAccounts();
            setAllContainerRegistryEnv(data.map((env) => ({ label: env.name, value: env.id })));
        })();
    }, []);

    const handleConfirm: SubmitHandler<FormValues> = async (data) => {
        setIsLoading(true);
        const { id, ruleName, environments, repository, description } = data;
        try {
            if (isEditMode) {
                await onConfirmEdit?.(id, ruleName, environments, repository, description);
            } else {
                await onConfirm?.(ruleName, environments, repository, description);
            }
            getNotificationsService().addNotification({
                type: NotificationType.SUCCESS,
                title: t('createBaseImageRuleMessage.create.success'),
                text: '',
            });
            handleCloseModal();
        } catch (error) {
            getNotificationsService().addNotification({
                type: NotificationType.ERROR,
                title: t('createBaseImageRuleMessage.create.error'),
                text: '',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const onCloseModal = () => {
        handleCloseModal();
        reset(DEFAULT_VALUES);
    };

    return (
        <Modal.ModalDialog onRequestClose={handleCloseModal} isOpen={isModalOpen}>
            <Modal.ModalHeader
                title={isEditMode ? t('modal.editBaseImageTitle') : t('modal.addBaseImageRuleTitle')}
                subTitle={[t('modal.subTitleFirstRow'), t('modal.subTitleSecondRow')]}
                onClose={handleCloseModal}
            />
            <Modal.ModalContent>
                <Stack spacing={4}>
                    <Stack spacing={2}>
                        <Label required color={'strong'} text={'Name'} />
                        <Controller
                            name={'ruleName'}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    fullWidth
                                    isError={!!errors.ruleName}
                                    onChange={field.onChange}
                                    helperText={errors.ruleName?.message}
                                />
                            )}
                        />
                    </Stack>
                    <Stack spacing={2} fullWidth>
                        <Label required color={'strong'} text={'Registry Environment'} />
                        <Controller
                            name={'environments'}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <SelectV2
                                    required
                                    placeholder={'Select registry environment'}
                                    clearable
                                    isMulti={true}
                                    fullWidth
                                    value={value && value.length === 0 ? undefined : value}
                                    options={allContainerRegistryEnv}
                                    onChange={onChange}
                                    isError={!!errors.environments}
                                    helperText={errors.environments?.message}
                                />
                            )}
                        />
                    </Stack>
                    <Stack spacing={2}>
                        <Label required color={'strong'} text={'Repository'} />
                        <Controller
                            name={'repository'}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    onChange={field.onChange}
                                    tooltip={t('tooltipRepository')}
                                    isError={!!errors.repository}
                                    helperText={errors.repository?.message}
                                />
                            )}
                        />
                    </Stack>
                    <Stack spacing={2} fullWidth>
                        <Label color={'strong'} required text={'Description'} />
                        <Controller
                            name={'description'}
                            control={control}
                            render={({ field }) => (
                                <TextArea
                                    {...field}
                                    fullWidth
                                    inputStyles={{ height: '50px' }}
                                    onChange={field.onChange}
                                    isError={!!errors.description}
                                    helperText={errors.description?.message}
                                />
                            )}
                        />
                    </Stack>
                </Stack>
            </Modal.ModalContent>
            <Modal.ModalFooter>
                <Stack direction='row' justifyContent='flex-end' fullWidth>
                    <Stack direction='row' spacing={3}>
                        <Button variant='text' onClick={onCloseModal} dataAid='cancel'>
                            {t('k8s_common:cancel')}
                        </Button>
                        <Button
                            loading={isLoading}
                            color='brandPrimary'
                            onClick={handleSubmit(async (data) => handleConfirm(data))}
                            dataAid='save'
                        >
                            {t('k8s_common:save')}
                        </Button>
                    </Stack>
                </Stack>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    );
};

export default ModalAddRuleBaseImage;
