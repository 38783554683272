import styled from 'styled-components';

export const Dot = styled.div<{ isTrue: boolean }>`
    height: 6px;
    width: 6px;
    background-color: ${({ isTrue, theme }) => (isTrue ? theme.palette.onSurface.light : 'transparent')};
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.palette.onSurface.light};
    display: inline-block;
`;
