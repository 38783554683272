import { IAccountsServiceItem, IServiceAccountsService } from 'common/interface/serviceAccountService';
import { getHttpService, IServiceAccount } from 'common/interface/services';
import { CloudGuardRole, INewServiceAccountResponse } from 'common/module_interface/settings/integrations/Integrations';

export class ServiceAccountsService implements IServiceAccountsService {
    async getServiceAccounts(useCache: boolean): Promise<IServiceAccount[]> {
        return await getHttpService().get<IServiceAccount[]>({
            path: '/service-account',
            cachingConfig: {
                useCache,
            },
        });
    }
    async addServiceAccount(
        name?: string,
        roleIds?: number[],
        useCache?: boolean,
    ): Promise<INewServiceAccountResponse> {
        return new Promise((resolve, reject) => {
            getHttpService()
                .request<any>(
                    'service-account',
                    {
                        method: 'POST',
                        data: { name, roleIds },
                    },
                    { cachingConfig: { useCache: !!useCache } },
                    (error: any) => {
                        reject(error);
                    },
                )
                .then((resp) => {
                    resp && resolve(resp);
                });
        });
    }
    async deleteServiceAccounts(roleId: string): Promise<void> {
        await getHttpService().delete<IServiceAccount[]>({
            path: '/service-account/' + roleId,
        });
    }
    async editServiceAccounts(payload: IAccountsServiceItem): Promise<IAccountsServiceItem> {
        return await getHttpService().request<any>('service-account/update', { method: 'POST', data: payload });
    }
    async getServiceAccountRoles(useCache?: boolean): Promise<CloudGuardRole[]> {
        return await getHttpService().request<CloudGuardRole[]>(
            'role',
            { method: 'GET' },
            { cachingConfig: { useCache: !!useCache } },
        );
    }
}
