import React, { useState } from 'react';
import { Icon, Stack, Typography } from 'common/design-system/components-v2';
import { IntegrationsRegistry } from 'common/module_interface/settings/integrations/IntegrationsRegistry';
import { IntegrationSearchBar } from '../IntegrationSearch/IntegrationSearch';
import { useTranslation } from 'react-i18next';
import {
    CONFIGURATION_DRAWER_VIEWS,
    I18nIntegrations,
    IntegrationsDefinition,
} from 'common/module_interface/settings/integrations/consts';
import {
    ConfigurationIcon,
    DrawerBody,
    NameCellWrapper,
    StyledTypography,
    Breadcrumb,
} from './ConfigurationsStyles.styled';
import { getDrawerComponentByIntegrationID } from '../../Configurations/ConfigurationDrawerViews';

export const AddConfigurationDrawer: React.FC<any> = ({ configurations, onConfigurationChangeCallBack }) => {
    const { t } = useTranslation(I18nIntegrations);
    const [searchValue, setSearchValue] = useState('');
    const [showIntegrationList, setShowIntegrationList] = useState(true);
    const [selectedIntegration, setSelectedIntegration] = useState<any>(null);

    const integrationsDefinitions = IntegrationsRegistry.getIntegrationsDefinitions();
    integrationsDefinitions.sort((a, b) => a.title.localeCompare(b.title));
    const configurableIntegrations = integrationsDefinitions.filter((integration) => {
        return integration.configurationComponent;
    });
    const filterIntegrations = configurableIntegrations.filter((integration) => {
        return integration.title.toLowerCase().includes(searchValue.toLowerCase());
    });
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

    const onIntegrationClicked = (integrationFromList: IntegrationsDefinition) => {
        setSelectedIntegration(integrationFromList);
        setShowIntegrationList(false);
    };

    const onBackClicked = () => {
        setShowIntegrationList(true);
        setSelectedIntegration(null);
        setSearchValue('');
    };

    return (
        <Stack fullHeight={true}>
            {showIntegrationList ? (
                <Stack>
                    <IntegrationSearchBar
                        type={'text'}
                        placeholder={t('GENERAL.SEARCH')}
                        onChange={handleSearchChange}
                    />
                    <DrawerBody>
                        <Stack padding={[0, 5]} direction={'column'} spacing={3}>
                            {filterIntegrations &&
                                filterIntegrations.length &&
                                filterIntegrations.map((integration, index) => {
                                    return (
                                        <Stack
                                            data-aid={`integration-item-${integration.title.toLowerCase()}`}
                                            onClick={() => onIntegrationClicked(integration)}
                                            key={index}
                                        >
                                            <NameCellWrapper>
                                                <ConfigurationIcon>
                                                    <img src={integration.icon} alt={''} />
                                                </ConfigurationIcon>
                                                <StyledTypography>{integration.title}</StyledTypography>
                                            </NameCellWrapper>
                                        </Stack>
                                    );
                                })}
                        </Stack>
                    </DrawerBody>
                </Stack>
            ) : (
                <Stack>
                    <Stack direction={'row'} alignItems={'center'} spacing={2} padding={[0, 5]}>
                        <Breadcrumb>
                            <Typography onClick={() => onBackClicked()}>
                                {t('CONFIGURATION_PAGE.CHOOSE_TYPE')}
                            </Typography>
                        </Breadcrumb>
                        <Icon name='chevronRight' size={8} />
                        <Typography variant={'bodyLg'} color={'strong'}>
                            {selectedIntegration?.title}
                        </Typography>
                    </Stack>
                    <Stack padding={[0, 5]} direction={'column'} spacing={3}>
                        {getDrawerComponentByIntegrationID(
                            CONFIGURATION_DRAWER_VIEWS.LIST_VIEW,
                            selectedIntegration?.id,
                        )({
                            configurations: configurations,
                            onConfigurationChangeCallBack: onConfigurationChangeCallBack,
                            mode: 'new',
                        })}
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};
