import styled from 'styled-components';

export const TabsWrapper = styled('div')`
    align-self: self-start;
    width: 200px;
`;

export const SwitchWrapper = styled('div')`
    align-self: self-end;
`;
