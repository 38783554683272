import { getAppRootRegistry } from 'common/interface/services';
import { lazy } from 'react';

export const initializePolicies = () => {
    const policyPageRoute = {
        condition: true,
        component: lazy(() => import('./AddCiemPlatformPolicyPage')),
        path: '/ciem/policy/add',
    };

    const policyEnvironmentPageRoute = {
        condition: true,
        component: lazy(() => import('./AddCiemEnvironmentPolicyPage')),
        path: '/ciem/environment/add',
    };

    const policyOUPageRoute = {
        condition: true,
        component: lazy(() => import('./AddCiemOUPolicyPage')),
        path: '/ciem/ou/add',
    };

    const policyEditPageRoute = {
        condition: true,
        component: lazy(() => import('./EditCIEMPolicyPage')),
        path: '/ciem/policy/edit',
    };
    getAppRootRegistry().addRoutes([
        { id: 'ciem-vendor-policy', content: policyPageRoute },
        { id: 'ciem-environment-policy', content: policyEnvironmentPageRoute },
        { id: 'ciem-ou-policy', content: policyOUPageRoute },
        { id: 'ciem-edit-policy', content: policyEditPageRoute },
    ]);
};
