import React, { useCallback, useEffect, useState } from 'react';
import { IEvidenceInfo, IIssue } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { RiskLevelServerEnum } from 'common/module_interface/RiskManagement/RiskLevel';
import FindingSeveritySummary from '../FindingSeveritySummary/FindingSeveritySummary';
import { fetchMalwareData } from '../../../../Issues.utils';
import { IMalware } from './Malware.interface';

export const MalwareExtraInfo: React.FC<{
    issue: IIssue;
    asset: IProtectedAssetViewModel;
    evidenceInfo?: IEvidenceInfo;
}> = ({ issue, asset }) => {
    const [critical, setCritical] = useState(0);
    const [high, setHigh] = useState(0);

    const loadMalware = useCallback(async () => {
        setCritical(0);
        setHigh(0);
        try {
            const res: IMalware[] = await fetchMalwareData(issue, asset);
            setCritical(
                res.filter((item: { severity: RiskLevelServerEnum }) => item.severity === RiskLevelServerEnum.Critical)
                    .length,
            );
            setHigh(
                res.filter((item: { severity: RiskLevelServerEnum }) => item.severity === RiskLevelServerEnum.High)
                    .length,
            );
        } catch (error: any) {
            console.log(error.message);
        }
    }, [issue, asset]);

    useEffect(() => {
        if (issue && asset) {
            void loadMalware();
        }
    }, [issue, asset, loadMalware]);

    return <FindingSeveritySummary criticalCount={critical} highCount={high} />;
};

export default MalwareExtraInfo;
