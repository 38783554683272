import initializeFilters from './FilterDefinitions';
import { IReactPageRoute } from 'common/interface/routing';
import initializeColumnDefs from './ErmColumnDefinitions';
import { IAssetDetailsPageAliases } from 'common/module_interface/assets/ProtectedAssets';
import { isUrlStartsWith } from 'common/utils/http';
import { ProtectedAssetsPageRegistry } from 'common/module_interface/assets/ProtectedAssetsPageRegistry';
import { ermPermissionFn } from '../../RiskManagement.utils';
import { getAppRootRegistry } from 'common/interface/services';
import { lazy } from 'react';

export const assetTabsMetadata = {
    tabsId: 'ermProtectedAssets',
    closeAllUrl: '/risk-management/protected-assets',
};

function initializeRoutes() {
    const protectedAssetsPageRoute = (): IReactPageRoute => {
        return {
            condition: true,
            component: lazy(() => import('./ProtectedAssetsList')),
            path: ['/risk-management/protected-assets'],
            exact: true,
        };
    };

    getAppRootRegistry().addRoutes([
        {
            id: 'Risk Management Protected Assets Page',
            content: protectedAssetsPageRoute,
            permission: ermPermissionFn,
        },
    ]);

    ProtectedAssetsPageRegistry.addAliasesAddins([
        {
            id: 'erm',
            position: 10,
            content: (): IAssetDetailsPageAliases => {
                return {
                    urlAliases: [
                        '/risk-management/protected-assets/generic',
                        '/risk-management/protected-assets/details',
                    ],
                    getTabsMetadata: () => {
                        return assetTabsMetadata;
                    },
                    isRelevant: () => isUrlStartsWith('/risk-management/protected-assets/'),
                };
            },
            permission: ermPermissionFn,
        },
    ]);
}

export function initializeProtectedAssetsPage() {
    initializeFilters();
    initializeColumnDefs();
    initializeRoutes();
}
