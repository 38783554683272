import React, { ReactElement, useMemo } from 'react';
import PropertyBoxesRow from 'common/components/PropertyBox/PropertyBoxesRow';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { IEventOverviewPanelProvider } from 'common/module_interface/events/Events';
import { EventOverviewRegistry } from 'common/module_interface/events/EventOverviewRegistry';
import { EventOverviewPanels } from 'common/module_interface/events/EventsConsts';

const UpperPanel: React.FC<{ event: IKustoEvent; panelId: EventOverviewPanels }> = ({ event, panelId }) => {
    const panels: ReactElement[] = useMemo(() => {
        if (!event) return [];

        const allProviders: IEventOverviewPanelProvider[] = EventOverviewRegistry.getPanels(panelId);

        const relevantProviders: IEventOverviewPanelProvider[] = allProviders.filter(
            (panelProvider: IEventOverviewPanelProvider) =>
                panelProvider.isRelevant ? panelProvider.isRelevant(event) : false,
        );

        return relevantProviders.map((panelProvider: IEventOverviewPanelProvider) => (
            <panelProvider.panel key={panelProvider.panelId} event={event} />
        ));
    }, [event, panelId]);

    return <PropertyBoxesRow>{panels}</PropertyBoxesRow>;
};

export default UpperPanel;
