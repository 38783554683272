import WidgetColumn from 'common/components/Widgets/Column/ColumnWidget';
import LineSetWidget from 'common/components/Widgets/Line/LineSetWidget';
import WidgetList from 'common/components/Widgets/List/List';
import WidgetMapChart from 'common/components/Widgets/Map/MapWidget';
import WidgetPieChart from 'common/components/Widgets/Pie/PieWidget';
import { ICloudAccount, getCloudAccountsService } from 'common/interface/data_services';
import {
    DashboardWidgetTypes,
    IDashboardWidget,
    IWidgetDataConfiguration,
    IWidgetsSettings,
} from 'common/module_interface/overview/Interface';
import {
    EVENT_ACTIVITY_ALIBABA,
    EVENT_ACTIVITY_AWS,
    EVENT_ACTIVITY_AZURE,
    SourceOptionsByDataSourceName,
    TRAFFIC_ACTIVITY_AWS,
    TRAFFIC_ACTIVITY_AZURE,
    TRAFFIC_ACTIVITY_KUBERNETES,
} from './Consts';
import { intelligenceGenericDataConvertor, intelligenceTrendDataConvertor } from './DataConvertors';
import { logicDataGetter } from './DataGetter';
import LogicWidgetsSettings from './LogicWidgetsSettings';
import moment from 'moment-mini';
import { NotSupportedComponent } from './NotSuporttedComponent';

export interface ILogicWidgetsOptions {
    [key: string]: string[];
}

export interface IWidgetConfigParams {
    source: string;
    aggregation: string;
}

export const widgetsTypesOptions: IWidgetsSettings = {
    pie: { component: WidgetPieChart, displayName: 'Pie' },
    column: { component: WidgetColumn, displayName: 'Column' },
    top: { component: WidgetList, displayName: 'Top' },
    bottom: { component: WidgetList, displayName: 'Latest' },
    map: { component: WidgetMapChart, displayName: 'Map' },
    'map-links': { component: WidgetMapChart, displayName: 'Map Links' },
    trend: { component: LineSetWidget, displayName: 'Trend', dataConvertor: intelligenceTrendDataConvertor },
};

const NOT_SUPPORTED_TYPES = ['event.type'];
export const createWidgets = (sourceDataObj: ILogicWidgetsOptions) => {
    return Object.keys(sourceDataObj).reduce((result: IWidgetDataConfiguration[], aggregation) => {
        sourceDataObj[aggregation].forEach((type: string) => {
            if (NOT_SUPPORTED_TYPES.includes(aggregation)) {
                result.push({
                    dataId: aggregation,
                    notSupported: true,
                    type: type as DashboardWidgetTypes,
                    widgetComponent: NotSupportedComponent,
                    settingsComponent: LogicWidgetsSettings,
                });
            } else {
                result.push({
                    dataId: aggregation,
                    type: type as DashboardWidgetTypes,
                    getData: (widget: IDashboardWidget) =>
                        logicDataGetter(
                            widget,
                            widgetsTypesOptions[type].dataConvertor || intelligenceGenericDataConvertor,
                        ),
                    widgetComponent: widgetsTypesOptions[type] && widgetsTypesOptions[type].component,
                    settingsComponent: LogicWidgetsSettings,
                });
            }
        });
        return result;
    }, []);
};

export const getFilteredCloudAccountsBySource = async (widgetSource: keyof typeof SourceOptionsByDataSourceName) => {
    const trafficActivityKeyName = 'isLogicTrafficActivityOnboarded';
    const eventActivityKeyName = 'isLogicEventActivityOnboarded';

    const cloudAccountParamsByWidgetSource = {
        [TRAFFIC_ACTIVITY_AWS]: {
            platform: 'aws',
            onboardingKey: trafficActivityKeyName,
        },
        [EVENT_ACTIVITY_AWS]: {
            platform: 'aws',
            onboardingKey: eventActivityKeyName,
        },
        [TRAFFIC_ACTIVITY_AZURE]: {
            platform: 'azure',
            onboardingKey: trafficActivityKeyName,
        },
        [EVENT_ACTIVITY_AZURE]: {
            platform: 'azure',
            onboardingKey: eventActivityKeyName,
        },
        [TRAFFIC_ACTIVITY_KUBERNETES]: {
            platform: 'kubernetes',
            onboardingKey: trafficActivityKeyName,
        },
        [EVENT_ACTIVITY_ALIBABA]: {
            platform: 'alibaba',
            onboardingKey: eventActivityKeyName,
        },
    };

    const allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts(true);
    return allCloudAccounts.filter((cloudAccount) => {
        const cloudAccountParams =
            cloudAccountParamsByWidgetSource[widgetSource as keyof typeof cloudAccountParamsByWidgetSource];
        return (
            cloudAccountParams &&
            cloudAccount[cloudAccountParams.onboardingKey as keyof ICloudAccount] &&
            cloudAccount.platform === cloudAccountParams.platform
        );
    });
};

export function formatDateString(inputDateString: string) {
    const originalDate = moment.utc(inputDateString);

    const year = originalDate.format('YYYY');
    const month = originalDate.format('MM');
    const day = originalDate.format('DD');
    const hours = originalDate.format('HH');

    return `${year}-${month}-${day} ${hours}:00`;
}
