import { FC } from 'react';
import { Command } from 'common/design-system/components-v2';

interface IProvideFilterConfigurationProps {
    textAreaValue: string;
}

export const ProvideFilterConfiguration: FC<IProvideFilterConfigurationProps> = ({ textAreaValue }) => {
    return <Command text={textAreaValue ? textAreaValue : ''} maxHeight={150} />;
};
