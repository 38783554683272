import React, { useCallback, useEffect } from 'react';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { Button, InputLabel, RadioButton, Stack, Typography } from 'common/design-system/components-v2';
import ServerlessActionsModal from './ServerlessActionsModal';
import { useTranslation } from 'react-i18next';
import { BASE_TRANSLATION_KEY, getFunctionId, NOTIFICATION_TIMEOUT, showNotification } from './utils';
import { List } from './ServerlessActions.styled';
import { ActionMode, FspMode } from '../../types/actions.types';
import { PatchAutoProtectModePayload } from 'modules/workloads/services/workload/workload.interface';
import WorkloadService from 'modules/workloads/services/workload/workload.service';
import { getNotificationsService } from 'common/interface/services';
import { GridApi } from 'ag-grid-community';
import { NotificationType } from 'common/interface/notifications';
import { getExternalAdditionalFieldValue } from 'common/module_interface/assets/utils';

interface ProtectionModeActionProps {
    selectedRows: IProtectedAssetViewModel[];
    gridApi: GridApi;
}

const ProtectionModeAction: React.FC<ProtectionModeActionProps> = ({ selectedRows, gridApi }) => {
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [selectedDetectionMode, setSelectedDetectionMode] = React.useState<FspMode>(FspMode.PreventAndDetect);
    const { t } = useTranslation();

    const isDisabled =
        selectedRows.length === 0 ||
        !selectedRows.every(
            (item) => item.externalAdditionalFields?.find((f) => f.name === 'IncludesFSP')?.value === 'true',
        );

    useEffect(() => {
        const isAllPreventAndDetect = selectedRows.every(
            (row) => getExternalAdditionalFieldValue(row, 'WithFSPBlock') === 'true',
        );
        setSelectedDetectionMode(isAllPreventAndDetect ? FspMode.DetectOnly : FspMode.PreventAndDetect);
    }, [selectedRows]);

    const handleOnClick = () => {
        setIsModalOpen(true);
    };

    const handleOnCancel = () => {
        setIsModalOpen(false);
        setSelectedDetectionMode(FspMode.PreventAndDetect);
    };

    const handleOnConfirm = useCallback(async () => {
        const requestData: PatchAutoProtectModePayload = {
            functionsData: selectedRows.map((item) => ({
                functionId: getFunctionId(item),
                fspMode: selectedDetectionMode,
            })),
        };

        const accountId = selectedRows[0].cloudAccountId;
        setIsLoading(true);

        try {
            const response = await WorkloadService.patchAutoProtectMode(accountId, { request: requestData });
            const { data } = response;

            setSelectedDetectionMode(FspMode.PreventAndDetect);
            setIsModalOpen(false);
            showNotification(data, ActionMode.ProtectionMode);

            gridApi.refreshServerSide();
            gridApi.deselectAll();
        } catch (err: unknown) {
            if (err instanceof Error) {
                getNotificationsService().addNotification({
                    type: NotificationType.ERROR,
                    title: t(`${BASE_TRANSLATION_KEY}.PROTECTION_MODE.ERROR`),
                    autoClose: NOTIFICATION_TIMEOUT,
                });
            }
        } finally {
            setIsLoading(false);
        }
    }, [selectedRows, selectedDetectionMode, gridApi, t]);

    return (
        <>
            <ServerlessActionsModal
                title={t(`${BASE_TRANSLATION_KEY}.PROTECTION_MODE.MODAL_TITLE`)}
                isModalOpen={isModalOpen}
                onClose={handleOnCancel}
                onConfirm={handleOnConfirm}
                isLoading={isLoading}
            >
                <Stack spacing={3} direction='column'>
                    <Typography data-aid='modal-selected-items'>
                        {t(`${BASE_TRANSLATION_KEY}.PROTECTION_MODE.SELECTED_ITEMS`)}: {selectedRows.length}
                    </Typography>
                    <Stack direction='row' spacing={4} alignItems='center'>
                        <InputLabel text={t(`${BASE_TRANSLATION_KEY}.PROTECTION_MODE.MODE`)} />
                        <RadioButton
                            label={t(`${BASE_TRANSLATION_KEY}.PROTECTION_MODE.PREVENT_AND_DETECT`)}
                            onChange={() => setSelectedDetectionMode(FspMode.PreventAndDetect)}
                            checked={selectedDetectionMode === FspMode.PreventAndDetect}
                            dataAid='prevent-and-detect'
                        />
                        <RadioButton
                            label={t(`${BASE_TRANSLATION_KEY}.PROTECTION_MODE.DETECT_ONLY`)}
                            onChange={() => setSelectedDetectionMode(FspMode.DetectOnly)}
                            checked={selectedDetectionMode === FspMode.DetectOnly}
                            dataAid='detect-only'
                        />
                    </Stack>
                    <Stack>
                        <List>
                            <li>{t(`${BASE_TRANSLATION_KEY}.PROTECTION_MODE.INSTRUCTIONS.1`)}</li>
                            <li>{t(`${BASE_TRANSLATION_KEY}.PROTECTION_MODE.INSTRUCTIONS.2`)}</li>
                        </List>
                    </Stack>
                </Stack>
            </ServerlessActionsModal>
            <Button
                disabled={isDisabled}
                onClick={handleOnClick}
                iconProps={{ name: 'protectionSettings' }}
                variant='text'
            >
                {t(`${BASE_TRANSLATION_KEY}.PROTECTION_MODE.BUTTON`)}
            </Button>
        </>
    );
};

export default ProtectionModeAction;
