export enum ReportType {
    Compliance = 'compliance',
    CompliancePerAccount = 'compliancePerAccount',
    CompliancePerRuleSet = 'compliancePerRuleSet',
    Cdr = 'cdr',
    CdrPerAccount = 'cdrPerAccount',
    CdrPerRuleset = 'cdrPerRuleSet',
}

export enum ReportNotificationType {
    Email = 'email',
}

export enum ReportTimeRangeValue {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
}

export enum ReportTimeRangeLimit {
    Hourly = 24,
    Daily = 31,
    Weekly = 12,
    Monthly = 12,
}

export enum ReportIntervalValue {
    Daily = 1,
    Weekly,
    Monthly,
}

export const ReportFormatOptions = [{ value: 'pdf', label: 'PDF', isChecked: true }];

export const ReportSeverities = ['Critical', 'High', 'Medium', 'Low'];

export const MaxItemSelectionLimit = 50;

export enum RulesetType {
    Compliance,
    CDR,
}

export const ReportsFields = {
    REPORT_NAME: 'reportName',
    TEMPLATE: 'reportType',
    TIME_RANGE: 'timeRange',
    TARGET: 'target',
    NEXT_RUN: 'nextRun',
    LAST_RUN: 'lastRun',
    GRANULARITY: 'granularity',
};

export const ReportsFreeTextFields = {
    REPORT_NAME: 'report_name',
};

export const ReportsActions = {
    CREATE_REPORT: 'create-report',
    EDIT_REPORT: 'edit-report',
    RUN_REPORTS: 'run-reports',
    DOWNLOAD_REPORTS: 'download-reports',
    DELETE_REPORTS: 'delete-reports',
};
