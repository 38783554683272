export const ASSET_TYPES = {
    AWS_EC2_INSTANCE: 'aws|Instance',
    AWS_Lambda: 'aws|Lambda',
    AWS_ALB: 'aws|ApplicationLoadBalancer',
    AWS_EFS: 'aws|EFS',
    AWS_RDS: 'aws|RDS',
    AWS_ELB: 'aws|ELB',
    AWS_NLB: 'aws|NetworkLoadBalancer',
    AWS_GLB: 'aws|GatewayLoadBalancer',
    AWS_Redshift: 'aws|Redshift',
    Azure_Virtual_Machine: 'azure|VirtualMachine',
    Azure_Load_Balancer: 'azure|LoadBalancer',
    Azure_SQL_Server: 'azure|SQLServer',
    GCP_VM_Instance: 'GCP|VMInstance',
};

export const ADDITIONAL_FINDINGS_ASSETS = [ASSET_TYPES.AWS_EC2_INSTANCE];
export const NETWORK_SECURITY_ASSETS = [
    ASSET_TYPES.AWS_EC2_INSTANCE,
    ASSET_TYPES.AWS_ALB,
    ASSET_TYPES.AWS_EFS,
    ASSET_TYPES.AWS_ELB,
    ASSET_TYPES.AWS_GLB,
    ASSET_TYPES.AWS_Lambda,
    ASSET_TYPES.AWS_NLB,
    ASSET_TYPES.AWS_RDS,
    ASSET_TYPES.AWS_Redshift,
    ASSET_TYPES.Azure_Load_Balancer,
    ASSET_TYPES.Azure_Virtual_Machine,
    ASSET_TYPES.GCP_VM_Instance,
];
export const SQL_SERVER_ASSETS = [ASSET_TYPES.Azure_SQL_Server];
export const IAM_POLICIES = [ASSET_TYPES.AWS_Redshift, ASSET_TYPES.AWS_Lambda, ASSET_TYPES.AWS_EC2_INSTANCE];
