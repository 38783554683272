import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { MALWARE_IGNORE_FORM_DRAWER_KEY, MALWARE_IGNORE_LIST_SERVICE_ID } from './MalwareIgnoreList.consts';
import { MalwareIgnoreListService } from './MalwareIgnoreListService';
import { initializeCustomFormDrawer } from 'common/erm-components/custom/CustomForm/CustomForm.initialize';
import { getMalwareIgnoreForm } from './MalwareIgnoreList.items';

const initializeMalwareIgnoreListService = () => {
    globalAddinContainer.addService(MALWARE_IGNORE_LIST_SERVICE_ID, new MalwareIgnoreListService());
};

const initializeMalwareIgnoreDrawer = () => {
    initializeCustomFormDrawer(MALWARE_IGNORE_FORM_DRAWER_KEY, getMalwareIgnoreForm);
};

export default function initializeMalwareIgnoreList() {
    initializeMalwareIgnoreListService();
    initializeMalwareIgnoreDrawer();
}
