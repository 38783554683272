import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Typography } from 'common/design-system/components-v2';
import { getExternalAdditionalFieldValue } from 'common/module_interface/assets/utils';
import i18n from 'common/services/translations/translations';

const ProtectionModeCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const isIncludesFSP = getExternalAdditionalFieldValue(params.data, 'IncludesFSP') === 'true';
    const withFSPBlock = getExternalAdditionalFieldValue(params.data, 'WithFSPBlock') === 'true';

    if (!isIncludesFSP) {
        return null;
    }

    const cellData = i18n.t(
        `SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_PROTECTION_MODE.VALUE${isIncludesFSP && withFSPBlock ? 1 : 2}`,
    );

    return <Typography>{cellData}</Typography>;
};

export default ProtectionModeCellRenderer;
