import { Stack } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';

export const NotSupportedComponent: FC = () => {
    const { t } = useTranslation();
    return (
        <Stack fullWidth={true} alignItems={'center'} justifyContent={'center'}>
            <h4>{t('GENERAL.NOT_SUPPORTED')}</h4>
        </Stack>
    );
};
