import EmptyState from 'common/components/EmptyState';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';

const LambdaEnableEmptyState: React.FC<{ entity: ICloudEntityData }> = ({ entity: { cloudAccountId } }) => {
    const { t } = useTranslation(getK8sNamespace('common'));

    return (
        <EmptyState
            iconName='cloudFunction'
            label={t('lambdaScan.enable.label')}
            description={t('lambdaScan.enable.description')}
            links={[
                {
                    label: t('lambdaScan.enable.onboard'),
                    url: `/cloud-add/serverless/${cloudAccountId}`,
                    outsideLink: false,
                    buttonProps: {
                        variant: 'contained',
                        color: 'brandPrimary',
                    },
                },
                {
                    label: t('lambdaScan.enable.readMore'),
                    url: 'https://www.checkpoint.com/products/serverless-security/',
                    outsideLink: true,
                },
            ]}
        />
    );
};

export default LambdaEnableEmptyState;
