import React, { useEffect, useState } from 'react';
import { Dropdown, List } from 'common/design-system/components-v2';
import { IListItemProps } from 'common/design-system/components-v2/List/List.types';
import { IButtonProps } from 'common/design-system/components-v2/Button/Button.types';
import { IPopoverProps } from 'common/design-system/components-v2/Popover/Popover.types';

interface IDropDownProps {
    options: IListItemProps[];
    initialSelectedOption?: string;
    placeHolder?: string;
    onSelectionChangedCallBack?: (option: IListItemProps) => void;
    buttonProps?: IButtonProps;
    placement?: IPopoverProps['placement'];
}

const getSelectedFromList = (options: IListItemProps[], initialSelectedOption: string | undefined) => {
    return initialSelectedOption ? options.find((option) => option.value === initialSelectedOption) : undefined;
};
const DropDown: React.FC<IDropDownProps> = ({
    options,
    initialSelectedOption,
    placeHolder,
    onSelectionChangedCallBack,
}) => {
    const [selectedOption, setSelectedOption] = useState(
        getSelectedFromList(options, initialSelectedOption) || ({} as IListItemProps),
    );
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);

    const handleOptionChanged = (option: IListItemProps) => {
        setSelectedOption(option);
        setIsDropDownOpen(false);
        onSelectionChangedCallBack && onSelectionChangedCallBack(option);
    };

    useEffect(() => {
        setSelectedOption(getSelectedFromList(options, initialSelectedOption) || ({} as IListItemProps));
    }, [options, initialSelectedOption]);

    return (
        <Dropdown
            label={selectedOption.label || placeHolder}
            buttonProps={{ color: 'normal', variant: 'outlined', size: 'medium', style: { width: '60%' } }}
            maxHeight={200}
            placement='bottom-start'
            open={isDropDownOpen}
            onStateChange={setIsDropDownOpen}
        >
            <List options={options} onOptionClick={handleOptionChanged} />
        </Dropdown>
    );
};

export default DropDown;
