import { initializeColumnDefs } from './Definitions/FindingsColumnDefinitions';
import { initializeFilterDefs } from './Definitions/FindingsFilterDefinitions';
import { initializeActionsDefs } from './Definitions/FindingsActionDefinitions';
import { FieldConvertorsRegistry, FieldEntityKind, IFieldConvertor } from 'common/registries/FieldConvertorsRegistry';
import { getCloudAccountIdFilterInfo, getEntityDome9IdFilterInfo } from '../Findings.utils';
import { CommonEventFields } from 'common/module_interface/events/EventsConsts';

function initializeFilterFieldConvertors() {
    const elasticToKustoFieldConvertors: IFieldConvertor[] = [
        {
            sourceEntityKind: FieldEntityKind.ELASTIC_EVENT,
            targetEntityKind: FieldEntityKind.KUSTO_EVENT_FIELD_INFO,
            sourceFieldName: 'cloudAccountId_calc',
            getTargetValue: (value: string) => getCloudAccountIdFilterInfo(value),
        },
        {
            sourceEntityKind: FieldEntityKind.ELASTIC_EVENT,
            targetEntityKind: FieldEntityKind.KUSTO_EVENT_FIELD_INFO,
            sourceFieldName: CommonEventFields.entityDome9Id,
            getTargetValue: (value: string) => getEntityDome9IdFilterInfo(value),
        },
    ];

    FieldConvertorsRegistry.addFieldConvertors(elasticToKustoFieldConvertors);
}

export default function initializeFindingsTable() {
    initializeColumnDefs();
    initializeFilterDefs();
    initializeActionsDefs();
    initializeFilterFieldConvertors();
}
