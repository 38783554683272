import React, { useMemo } from 'react';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { WorkloadTypes } from 'modules/workloads/services/workload/workload.interface';
import WorkloadSbom from './WorkloadSbom/WorkloadSbom';
import SbomTableWithFilter from './SbomTableWithFilter/SbomTableWithFilter';
import { AssetTypes } from 'common/assets/assets.const';

const SbomTab: React.FC<{ entity: ICloudEntityData }> = (props) => {
    const {
        entity: {
            cloudAccountId,
            protectedAsset: { id: dome9Id, name },
            protectedAsset,
            typeByPlatform,
        },
    } = props;

    const isWorkloadType = useMemo(() => Object.values(WorkloadTypes).includes(typeByPlatform), [typeByPlatform]);
    const { entityId, entityType } = useMemo(
        () =>
            AssetTypes.AWS_LAMBDA !== typeByPlatform
                ? { entityId: protectedAsset.entityId, entityType: protectedAsset.type }
                : { entityId: props.entity.cloudEntity.id, entityType: protectedAsset.type },
        [props.entity.cloudEntity.id, protectedAsset.entityId, protectedAsset.type, typeByPlatform],
    );

    return (
        <>
            {isWorkloadType ? (
                <WorkloadSbom environmentId={cloudAccountId} dome9Id={dome9Id} cloudEntityData={props.entity} />
            ) : (
                <SbomTableWithFilter
                    environmentId={cloudAccountId}
                    entityId={entityId}
                    entityType={entityType}
                    cloudEntityData={props.entity}
                    entityName={name}
                />
            )}
        </>
    );
};

export default SbomTab;
