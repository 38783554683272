import { EnvironmentsTableRegistry } from 'common/module_interface/assets/EnvironmentsTableRegistry';
import { IEnvironmentsExport } from 'common/module_interface/assets/Environments';
import { Addin } from 'common/extensibility/AddinRegistry';
import i18n from 'common/services/translations/translations';
import { getK8sNamespace } from '../initialize.i18n';
import { exportKubernetesAgentsTable, exportWorkloadTable } from './utils/exportTable';
import KubernetesAccountService from '../services/kubernetesAccount/kubernetesAccount.service';
import { getNotificationsService } from 'common/interface/services';

export const initializeEnvironmentsExportsDefs = () => {
    const exportOptions: Addin<IEnvironmentsExport>[] = [
        {
            id: 'workload-export-all',
            position: 0,
            content: {
                id: 'workload-export-all',
                name: i18n.t('ENVIRONMENTS_TABLE.TABLE_EXPORTS.ALL_ENVIRONMENTS', { ns: getK8sNamespace('common') }),
                callback: (allData) => exportWorkloadTable(allData),
                isActionDisabled: (allData) => allData.length === 0,
            },
        },
        {
            id: 'workload-export-filtered',
            position: 1,
            content: {
                id: 'workload-export-filtered',
                name: i18n.t('ENVIRONMENTS_TABLE.TABLE_EXPORTS.FILTERED_ENVIRONMENTS', {
                    ns: getK8sNamespace('common'),
                }),
                callback: (allData, filteredData) => exportWorkloadTable(filteredData),
                isActionDisabled: (allData, filteredData) => filteredData.length === 0,
            },
        },
        {
            id: 'workload-export-kubernetes-agents',
            position: 1,
            content: {
                id: 'workload-export-kubernetes-agents',
                name: i18n.t('ENVIRONMENTS_TABLE.TABLE_EXPORTS.ALL_AGENTS_STATUSES', { ns: getK8sNamespace('common') }),
                callback: async () => {
                    const csvPromise = KubernetesAccountService.requestAgentStatusReportCSV();
                    getNotificationsService().runPromise({
                        promise: csvPromise,
                        pending: {
                            title: '',
                            text: i18n.t('ENVIRONMENTS_TABLE.TABLE_EXPORTS.AGENTS_NOTIFICATIONS.PENDING', {
                                ns: getK8sNamespace('common'),
                            }),
                        },
                        info: {
                            title: '',
                            text: i18n.t('ENVIRONMENTS_TABLE.TABLE_EXPORTS.AGENTS_NOTIFICATIONS.PENDING', {
                                ns: getK8sNamespace('common'),
                            }),
                        },
                        error: {
                            title: '',
                            text: i18n.t('ENVIRONMENTS_TABLE.TABLE_EXPORTS.AGENTS_NOTIFICATIONS.ERROR', {
                                ns: getK8sNamespace('common'),
                            }),
                        },
                        success: {
                            title: '',
                            text: i18n.t('ENVIRONMENTS_TABLE.TABLE_EXPORTS.AGENTS_NOTIFICATIONS.SUCCESS', {
                                ns: getK8sNamespace('common'),
                            }),
                        },
                    });
                    try {
                        const response = await csvPromise;
                        exportKubernetesAgentsTable(response.data);
                    } catch (error) {
                        /* empty */
                    }
                },
                isActionDisabled: (allData) => allData.length === 0,
            },
        },
    ];
    EnvironmentsTableRegistry.addExports(exportOptions);
};
