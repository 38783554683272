import { getDataFromServer, getGroupByFromServer } from 'common/components/ProtectedAssets/datasource';
import { HandlerFunc } from './types';
import {
    createGroupByRequest,
    createGroupRows,
    createSearchGroup,
    createSearchRequest,
    getKey,
    mutateRequest,
} from './utils';
import { SearchGroupManager } from './searchGroupManager';
import { IAffectedAsset } from '../PackageDrawer.types';
import { SearchRequest } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { EXTERNAL_ADDITIONAL_FIELDS_SOURCE } from 'common/components/ProtectedAssets/ProtectedAssetsTable.consts';

export const protectedAssetHandler =
    (
        searchGroupManager: SearchGroupManager,
        additionalGslData: Map<string, IAffectedAsset>,
        additionalFields: SearchRequest['additionalFields'] = {
            source: EXTERNAL_ADDITIONAL_FIELDS_SOURCE.THIRD_PARTY,
            filterFields: [],
            sortField: undefined,
        },
        filterEntitiesWithoutRiskScore = true,
    ): HandlerFunc =>
    (clientRequest) => {
        if (
            clientRequest.rowGroupCols.length === 0 ||
            clientRequest.rowGroupCols.length === clientRequest.groupKeys.length
        ) {
            const searchGroupKey = getKey(clientRequest);
            const searchGroup =
                searchGroupManager.get(searchGroupKey) ??
                createSearchGroup(
                    clientRequest,
                    searchGroupManager.getDefault().pageSize,
                    searchGroupManager.getDefault().filters,
                );
            searchGroupManager.add(searchGroupKey, searchGroup);
            const mainRequest = createSearchRequest(clientRequest, searchGroup);
            const requestWithAdditionalFields = mutateRequest(mainRequest, 'additionalFields', additionalFields);
            const requestWithFilterEntitiesWithoutRiskScore = mutateRequest(
                requestWithAdditionalFields,
                'filterEntitiesWithoutRiskScore',
                filterEntitiesWithoutRiskScore,
            );

            return getDataFromServer(requestWithFilterEntitiesWithoutRiskScore).then((data) => {
                searchGroup.current += data.assets.length;
                searchGroup.searchAfter = data.searchAfter ?? searchGroup.searchAfter;
                return {
                    rowData: data.assets.map((asset) => ({
                        ...asset,
                        path: additionalGslData.get(asset.id)?.path ?? '',
                        licenses: additionalGslData.get(asset.id)?.licenses ?? [],
                    })),
                    rowCount: searchGroup.current >= data.totalCount ? data.totalCount : -1,
                };
            });
        }

        const searchGroup = searchGroupManager.getDefault();
        return getGroupByFromServer(
            mutateRequest(
                createGroupByRequest(clientRequest, searchGroup),
                'filterEntitiesWithoutRiskScore',
                filterEntitiesWithoutRiskScore,
            ),
        ).then((data) => {
            const rows = createGroupRows(clientRequest, data);
            return {
                rowData: rows,
                rowCount: rows.length,
            };
        });
    };
