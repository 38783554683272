import { AxiosResponse } from 'axios';
import {
    GetPackageAffectedAssets,
    GetPackageLicenses,
} from 'modules/workloads/services/vulnerability-search/sbom-gsl/sbom-gsl.interface';
import { IAffectedAsset, TPackageInfo } from './PackageDrawer.types';
import { License } from 'modules/workloads/services/sbom/sbom.interface';

export const mergePackageInfo = (resp: GetPackageLicenses.Response): TPackageInfo => {
    const result = resp.data.reduce(
        (accum, current) => {
            accum.path.add(current.package.path);
            accum.isOsPackage.add(current.isOsPackage);
            current.licensesInfo?.forEach((licenseInfo) => accum.licenses.set(licenseInfo.value, licenseInfo));
            return {
                id: current.package.id,
                name: current.package.name,
                version: current.package.version,
                manager: current.package.managerName,
                path: accum.path,
                isOsPackage: accum.isOsPackage,
                licenses: accum.licenses,
            };
        },
        {
            id: '',
            name: '',
            manager: '',
            version: '',
            path: new Set<string>(),
            licenses: new Map<string, License>(),
            isOsPackage: new Set<boolean>(),
        },
    );
    return {
        id: result.id,
        name: result.name,
        version: result.version,
        manager: result.manager,
        path: Array.from(result.path.values()),
        isOsPackage: Array.from(result.isOsPackage.values()),
        licenses: Array.from(result.licenses.values()),
    };
};

export const mapAssetsByDome9Id = (resp: AxiosResponse<GetPackageAffectedAssets.Response>) => {
    return resp.data.data.reduce<Map<string, IAffectedAsset>>(
        (accum, { affectedAsset, package: associatedPackage, licensesInfo }) => {
            accum.set(affectedAsset.dome9Id, {
                externalId: affectedAsset.externalId,
                id: affectedAsset.dome9Id,
                path: associatedPackage.path,
                licenses: licensesInfo,
            });
            return accum;
        },
        new Map(),
    );
};

export const getFieldsFromAffectedAssetsMap = (affectedAssetsMap: Map<string, IAffectedAsset>) =>
    Array.from(affectedAssetsMap.values()).map(({ id }) => ({ name: 'id', value: id }));
