import { IColumnUsageDef } from 'common/interface/general';
import { FindingColumns } from '../../Findings.const';
import { SELECTION_GRID_COLUMN, TABLE_SELECTION_COL_ID } from 'common/utils/tableUtils';
import { KustoEventFields } from 'common/components/KustoEvents/KustoEvent.const';
import { CommonEventFields } from 'common/module_interface/events/EventsConsts';

export const FindingsColumnTypes: { [key: string]: IColumnUsageDef } = {
    SELECTION: {
        id: SELECTION_GRID_COLUMN.colId ?? TABLE_SELECTION_COL_ID,
        colDefOverride: {
            headerCheckboxSelection: false,
            pinned: false,
            lockPosition: false,
        },
    },
    RULE_NAME: {
        id: CommonEventFields.ruleName,
        colDefOverride: {
            freeTextField: true,
            minWidth: 400,
        },
    },
    SEVERITY: {
        id: CommonEventFields.severityLevel,
        colDefOverride: {
            freeTextField: true,
            minWidth: 130,
        },
    },
    CREATED_TIME: {
        id: CommonEventFields.createdTime,
        colDefOverride: {
            minWidth: 200,
        },
    },
    ENVIRONMENT: {
        id: CommonEventFields.cloudAccountId,
        colDefOverride: {
            freeTextField: true,
            minWidth: 200,
        },
    },
    DESCRIPTION: {
        id: CommonEventFields.description,
        colDefOverride: {
            minWidth: 200,
        },
    },
    RULESET_NAME: {
        id: CommonEventFields.bundleName,
        colDefOverride: {
            minWidth: 200,
        },
    },
    ENTITY_NAME: {
        id: CommonEventFields.entityName,
        colDefOverride: {
            freeTextField: true,
            minWidth: 200,
            additionalColumnsInGroupBy: [
                CommonEventFields.entityExternalId,
                CommonEventFields.entityDome9Id,
                CommonEventFields.entityTypeByEnvironmentType,
            ],
        },
    },
    ENTITY_TYPE: {
        id: CommonEventFields.entityTypeByEnvironmentType,
        colDefOverride: {
            freeTextField: true,
            minWidth: 200,
            additionalColumnsInGroupBy: [CommonEventFields.cloudAccountType],
        },
    },
    MITRE: {
        id: KustoEventFields.mitreDict,
        colDefOverride: {
            minWidth: 200,
        },
    },
    ACTION: {
        id: CommonEventFields.action,
        colDefOverride: {
            freeTextField: true,
            minWidth: 100,
        },
    },
    REMEDIATION: {
        id: CommonEventFields.remediation,
        colDefOverride: {
            freeTextField: true,
            minWidth: 200,
        },
    },
    STATUS: {
        id: KustoEventFields.statusId,
        colDefOverride: {
            minWidth: 100,
            additionalColumnsInGroupBy: [KustoEventFields.statusName],
        },
    },
    FIRST_OPENED_TIME: {
        id: CommonEventFields.lastSeenTime,
        colDefOverride: {
            minWidth: 200,
        },
    },
    ORIGIN: {
        id: CommonEventFields.origin,
        colDefOverride: {
            freeTextField: true,
            minWidth: 200,
        },
    },
    PLATFORM: {
        id: CommonEventFields.cloudAccountType,
        colDefOverride: {
            freeTextField: true,
            minWidth: 200,
        },
    },
    ASSIGNEE: {
        id: CommonEventFields.ownerUserName,
        colDefOverride: {
            freeTextField: true,
            minWidth: 100,
        },
    },
    ALERT_ID: {
        id: CommonEventFields.findingKey,
        colDefOverride: {
            freeTextField: true,
        },
    },
    LOG_TYPE: {
        id: KustoEventFields.logType,
        colDefOverride: {
            initialHide: true,
            minWidth: 200,
        },
    },
    // DO NOT use this column in the table for now, the actions are not implemented yet
    ACTIONS: {
        id: FindingColumns.ACTIONS,
        colDefOverride: {
            width: 5,
            pinned: 'right',
            lockPinned: true,
        },
    },
};
