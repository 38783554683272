import { Table } from 'common/design-system/components-v2';
import { VulnerabilitiesStyled } from './IssueVulnerabilities.styled';
import React, { useCallback, useMemo, useRef } from 'react';
import { IIssueCve } from '../../../../Issues.interface';
import { GridApi, GridOptions } from 'ag-grid-community';
import { getCvePackagesColumnDefs, getCvesColumnDefs } from './IssueVulnerabilitiesTableColumns';
import withShowMoreButton from '../../../../../../../../common/erm-components/utils/WithShowMore/WithShowMore';

export const IssueVulnerabilitiesTable: React.FC<{ topItems: IIssueCve[] }> = ({ topItems }) => {
    const apiRef = useRef<GridApi>();

    const onGridReady = useCallback((params: any) => {
        apiRef.current = params.api;
    }, []);

    const detailCellRendererParams = useCallback(() => {
        return {
            getDetailRowData: (params: any) => {
                const packages = (params.data.packages || []).sort((p1: any, p2: any) =>
                    (p1.name || '').localeCompare(p2.name || ''),
                );
                params.successCallback(packages);
            },
            detailGridOptions: {
                columnDefs: getCvePackagesColumnDefs(),
                defaultColDef: {
                    sortable: false,
                    resizable: true,
                    suppressMenu: true,
                    suppressMovable: true,
                    headerClass: 'packages-table-header',
                },
            },
            domLayout: 'autoHeight',
        };
    }, []);

    const gridOptions: GridOptions = useMemo(() => {
        return {
            columnDefs: getCvesColumnDefs(),
            defaultColDef: {
                sortable: false,
                resizable: true,
                suppressMenu: true,
                suppressMovable: true,
            },
            rowModelType: 'clientSide',
            domLayout: 'autoHeight',
            suppressDragLeaveHidesColumns: true,
            rowData: topItems,
            enableRangeSelection: false,
            suppressCellFocus: true,
            onGridReady,
            masterDetail: true,
            detailCellRendererParams,
            detailRowAutoHeight: true,
            rowClass: 'group-row-hide-overflow',
        };
    }, [detailCellRendererParams, onGridReady, topItems]);

    const TableWithButton = withShowMoreButton(Table);

    return (
        <VulnerabilitiesStyled.TopTableDiv>
            <TableWithButton gridOptions={gridOptions} disableColumnMenu={true} disableGrouping={true} />
        </VulnerabilitiesStyled.TopTableDiv>
    );
};
