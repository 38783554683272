import React from 'react';
import { Chip, Stack } from 'common/design-system/components-v2';
import { IServiceAccount } from 'common/interface/services';
import { ICellRendererParams } from 'ag-grid-community';
export const ChipsCellRenderer: React.FC<ICellRendererParams<IServiceAccount>> = ({ data }) => {
    const sortedRoleNames = React.useMemo(() => {
        if (!data?.rolesNames) return [];
        return data.rolesNames.sort();
    }, [data?.rolesNames]);
    if (!sortedRoleNames.length) {
        return null;
    }
    return (
        <Stack spacing={1} direction={'row'}>
            {sortedRoleNames.map((roleName) => (
                <Chip color={'normal'} label={roleName} key={roleName} />
            ))}
        </Stack>
    );
};
