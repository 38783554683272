import {
    ANOMALY_GRAPH_NETWORK_GSL,
    ANOMALY_REQUEST_SORT_FIELD,
    getIntelligenceFindingDataQuery,
    GRAPH_COUNT_GSL,
    GSL_FINDING_LIMIT,
    GSL_FINDING_ORDER_BY_PROPERTY,
} from '../Models/IntelligenceRequest';
import { IElasticEvent } from 'common/module_interface/elasticEvents/ElasticEvent.interface';
import { getIntelligenceService } from 'common/module_interface/intelligence/intelligence';
import moment from 'moment-mini';
import { IGslRunResponse } from 'common/module_interface/intelligence/Intelligence.interface';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { GslSource } from 'common/module_interface/intelligence/Gsl/GslService.const';
import { IEvent } from 'common/module_interface/events/Events';
import { getGslSourceByPlatformAndType, LogType } from 'common/module_interface/intelligence/Logs/LogSources';

export async function getEntityFindingsData(
    entityId: string,
    entityName: string,
    finding: IKustoEvent,
): Promise<IElasticEvent[] | undefined> {
    if (!entityId || !finding) return undefined;
    const findingCreationTime = new Date(finding.createdTime).getTime();
    const findingAccountId = finding.cloudAccountId;
    const dateNow = new Date().getTime();
    const queryForGsl = getIntelligenceFindingDataQuery(entityId, entityName, finding);
    if (!queryForGsl) return undefined;

    const data = {
        cloudAccount: [findingAccountId],
        gsl:
            `alerts where (${queryForGsl}) and origin_resolved = 'Intelligence' ` +
            `and starttime > datetime_add('Day',-30, unixtime_milliseconds_todatetime(${findingCreationTime})) ` +
            `and starttime < unixtime_milliseconds_todatetime(${dateNow}) ` +
            `and alerts where (${queryForGsl}) and origin_resolved = 'Intelligence'` +
            `and starttime > unixtime_milliseconds_todatetime(${findingCreationTime})`,
        options: {
            emptyGslTranspile: undefined,
            source: GslSource.alerts,
            multiStatementStrategy: 'union',
            limit: GSL_FINDING_LIMIT,
            order: undefined,
            orderBy: GSL_FINDING_ORDER_BY_PROPERTY,
            extraFilter: undefined,
            decorators: [],
        },
        withCredentials: true,
    };

    return await getIntelligenceService().getIntelligenceFindingQuery(data);
}

export async function getBytesOvertimeData(finding: IEvent): Promise<IGslRunResponse | undefined> {
    const findingAccountId = finding.cloudAccountExternalId;
    const dayBefore = new Date(finding.createdTime);
    dayBefore.setDate(dayBefore.getDate() - 1);
    const dateNow = new Date().getTime();
    const gslSource = finding.ruleLogic?.split(' ')?.[0];
    if (!gslSource) return undefined;

    const data = {
        cloudAccount: [findingAccountId],
        gsl: ANOMALY_GRAPH_NETWORK_GSL[gslSource],
        options: {
            emptyGslTranspile: undefined,
            source: gslSource,
            limit: -1,
            orderBy: ANOMALY_REQUEST_SORT_FIELD[gslSource],
            order: 'asc',
            extraFilter: undefined,
            decorators: [],
            start: dayBefore.getTime(),
            end: dateNow,
        },
        withCredentials: true,
    };
    return await getIntelligenceService().getGSLRunData(data);
}

export async function getGslGraphCountDataFromFinding(finding: IKustoEvent): Promise<number | undefined> {
    if (!finding.magellan) return 0;

    const platform = finding.cloudAccountType.toUpperCase();

    const logType = finding.additionalFieldsMap.networkActivity ? LogType.NETWORK_ACTIVITY : LogType.ACCOUNT_ACTIVITY;
    const gslSource = getGslSourceByPlatformAndType(platform, logType);
    if (!gslSource) return 0;

    const accountId = finding.cloudAccountExternalId;
    const start = Date.parse(moment(finding.magellan.alertWindowStartTime).add(-5, 'minutes').format());
    const end = Date.parse(moment(finding.magellan.alertWindowEndTime).add(+5, 'minutes').format());
    const filter = finding.ruleLogic + GRAPH_COUNT_GSL[logType];
    const data = {
        cloudAccount: [accountId],
        gsl: filter,
        options: {
            source: gslSource,
            start: start,
            end: end,
            limit: 1,
        },
        withCredentials: true,
    };
    try {
        return await getIntelligenceService().getGSLRunDataCount(data);
    } catch (error) {
        return 0;
    }
}
