import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { INotification } from 'common/interface/notificationPage';
import { Icon, Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

interface ILinkStyleCellRenderer extends ICellRendererParams<INotification> {}
const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.palette.surface.secondary};
`;
const LinkStyleCellRenderer: React.FC<ILinkStyleCellRenderer> = (params) => {
    if (!params.data?.name) return;
    return (
        <Stack direction={'row'} alignItems={'center'} spacing={3}>
            <IconWrapper>
                <Icon name={'bell'} size={16} />
            </IconWrapper>
            <a>{params.data?.name}</a>
        </Stack>
    );
};

export default LinkStyleCellRenderer;
