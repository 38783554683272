import {
    integrationCategory,
    IntegrationDataLoader,
    INTEGRATIONS_IDS,
    IntegrationsDefinitionWrapper,
} from 'common/module_interface/settings/integrations/consts';
import Cyera from 'assets/integrations/Icons/Cyera.svg';
import CyeraComponent from 'modules/riskManagement/components/Integrations/CyeraIntegration/CyeraComponent';
import { getCyeraIntegrationService } from 'common/module_interface/RiskManagement/Services';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IntegrationsRegistry } from 'common/module_interface/settings/integrations/IntegrationsRegistry';
import { addOrUpdateCyeraAccount } from './Cyera.utils';
import { CYERA_INTEGRATION_ID } from 'common/module_interface/RiskManagement/integrations/cyeraintegration/CyeraIntegration.consts';
import { ermPermissionFn, ermTrans } from '../../../RiskManagement.utils';

export function initializeCyeraIntegration() {
    const cyeraIntegrationObject: IntegrationsDefinitionWrapper[] = [
        {
            id: CYERA_INTEGRATION_ID,
            content: {
                id: INTEGRATIONS_IDS.CYERA,
                title: ermTrans('CYERA_INTEGRATION.TITLE'),
                icon: Cyera,
                category: integrationCategory.DSPM,
                configurationComponent: CyeraComponent,
                onDelete: (accountId: string) => getCyeraIntegrationService().deleteCyeraAccount(accountId),
                onSave: (name: string, configurationObj: any, configurationId?: string) =>
                    addOrUpdateCyeraAccount(
                        name,
                        configurationObj?.clientId,
                        configurationObj?.secret,
                        configurationId,
                    ),
            },
            permission: ermPermissionFn,
        },
    ];
    IntegrationsRegistry.addIntegrationsDefinition(cyeraIntegrationObject);
    initializeCyeraIntegrationDataFunction();
}
export function initializeCyeraIntegrationDataFunction() {
    const cyeraConfigurations: Addin<IntegrationDataLoader> = {
        id: CYERA_INTEGRATION_ID,
        content: {
            getData: getCyeraIntegrationService().getCyeraConfigurations,
        },
        permission: ermPermissionFn,
    };
    IntegrationsRegistry.addConfigurationDataLoader(cyeraConfigurations);
}
