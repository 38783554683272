import initializeColumnDefs from './Definitions/AssetsColumnDefinitions';
import initializeProtectedAssetPageColumns from './ProtectedAssetsPage/columns';
import initializeFiltersDefs from './Definitions/FilterDefinitions';
import OrganizationalUnitCellRenderer from './CellRenderers/OrganizationalUnitCellRenderer';
import EntityCellRender from 'common/components/ProtectedAssets/Renderers/EntityCellRender';
import EnvironmentCellRender from 'common/components/ProtectedAssets/Renderers/EnvironmentCellRender';
import TypeCellRenderer from '../../../common/components/ProtectedAssets/Renderers/TypeCellRenderer';
import initializeProtectedAssetPageFilters from './ProtectedAssetsPage/filters';
import { IReactPageRoute } from 'common/interface/routing';
import { initializeAssetPage, initializeSideTabs } from './ProtectedAssetsPage/initialize';
import { IApplicationReducer } from 'common/interface/redux';
import assetDetailsReducer from './ProtectedAssetsPage/AssetDetails.reducer';
import i18n from 'common/services/translations/translations';
import EventsCategoryItem from 'modules/assets/ProtectedAssets/Widgets/EventsCategoryItem';
import { OVERVIEW_PANEL_EVENTS_CATEGORIES_IDS } from 'common/module_interface/events/EventsComponentsIds';
import { isUrlStartsWith } from 'common/utils/http';
import RiskScoreCellRenderer from 'common/components/ProtectedAssets/Renderers/RiskScoreCellRenderer';
import LastRunningDateCellRenderer from 'common/components/ProtectedAssets/Renderers/LastRunningDateCellRenderer';
import { ProtectedAssetsTableRegistry } from 'common/module_interface/assets/ProtectedAssetsTableRegistry';
import { OverviewPanelRegistry } from 'common/module_interface/assets/OverviewPanelRegistry';
import { ProtectedAssetsPageRegistry } from 'common/module_interface/assets/ProtectedAssetsPageRegistry';
import { IProtectedAssetTableCellRendererAddinContent } from 'common/module_interface/assets/ProtectedAssets';
import { Addin } from 'common/extensibility/AddinRegistry';
import React, { lazy } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { getAppRootRegistry } from 'common/interface/services';

function initializeCellRenderers() {
    const cellRendererAddins: Addin<IProtectedAssetTableCellRendererAddinContent>[] = [
        {
            id: 'LastRunningDateRenderer',
            content: {
                renderer: LastRunningDateCellRenderer,
                params: {},
            },
        },
        {
            id: 'OrganizationalUnitCellRenderer',
            content: {
                renderer: OrganizationalUnitCellRenderer,
                params: {},
            },
        },
        {
            id: 'EntityCellRender',
            content: {
                renderer: EntityCellRender as React.FC<ICellRendererParams>,
                params: {},
            },
        },
        {
            id: 'EnvironmentCellRender',
            content: {
                renderer: EnvironmentCellRender,
                params: {},
            },
        },
        {
            id: 'TypeCellRenderer',
            content: {
                renderer: TypeCellRenderer,
                params: {},
            },
        },
        {
            id: 'RiskScoreCellRenderer',
            content: {
                renderer: RiskScoreCellRenderer,
                params: {},
            },
        },
    ];
    ProtectedAssetsTableRegistry.addCellRendererAddins(cellRendererAddins);
}

function initializeRoutes() {
    const protectedAssetsPageRoute = (): IReactPageRoute => {
        return {
            condition: true,
            component: lazy(() => import('./ProtectedAssetsPage/ProtectedAssetsList')),
            path: ['/protected-asset', '/protected-asset/index'],
            exact: true,
        };
    };

    const assetDetailsPageRoute = (): IReactPageRoute => {
        const path: string[] = [];
        ProtectedAssetsPageRegistry.getAliases().forEach((item) => {
            if (item) {
                path.push(...item.urlAliases);
            }
        });

        return {
            condition: true,
            component: lazy(() => import('./ProtectedAssetsPage/AssetDetails')),
            path,
            exact: true,
        };
    };

    getAppRootRegistry().addRoutes([
        {
            id: 'Assets Protected Assets Page',
            content: protectedAssetsPageRoute,
        },
        {
            id: 'Assets Asset Details Page',
            content: assetDetailsPageRoute,
        },
    ]);

    ProtectedAssetsPageRegistry.addAliasesAddins([
        {
            id: 'protectedAssets',
            position: 1000,
            content: {
                urlAliases: ['/protected-asset/generic', '/protected-asset/details'],
                getTabsMetadata: () => {
                    return { tabsId: 'assetsProtectedAssets', closeAllUrl: '/protected-asset' };
                },
                isRelevant: () => isUrlStartsWith('/protected-asset/'),
                isDefault: true,
            },
        },
    ]);
}

function initializeReducers() {
    const reducers: IApplicationReducer[] = [
        { name: 'protectedAssetDetails', reducer: assetDetailsReducer, isBlackList: true },
    ];
    getAppRootRegistry().addReducers(reducers, 'name');
}

function initializeEventsCategory() {
    const eventsItemId = 'risk data';
    OverviewPanelRegistry.addCategory(
        OVERVIEW_PANEL_EVENTS_CATEGORIES_IDS.EVENTS_CATEGORY_ID,
        20,
        () => i18n.t('EVENTS.CATEGORY.TITLE'),
        () => true,
    );
    OverviewPanelRegistry.addCategoryItem(eventsItemId, OVERVIEW_PANEL_EVENTS_CATEGORIES_IDS.EVENTS_CATEGORY_ID, 20, {
        id: eventsItemId,
        component: EventsCategoryItem,
    });
}

export default function initialize() {
    initializeColumnDefs();
    initializeProtectedAssetPageColumns();
    initializeFiltersDefs();
    initializeCellRenderers();
    initializeProtectedAssetPageFilters();
    initializeRoutes();
    initializeReducers();
    initializeAssetPage();
    initializeSideTabs();
    initializeEventsCategory();
}
