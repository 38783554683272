import { Spinner, Stack, Typography } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
export const LoadingSpinnerComponent = () => {
    const { t } = useTranslation();
    return (
        <Stack alignItems={'center'} justifyContent={'center'} padding={10} spacing={5} direction={'row'}>
            <Spinner />
            <Typography>{t('GENERAL.LOADING')}</Typography>
        </Stack>
    );
};
