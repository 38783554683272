import { Chip, Tooltip } from 'common/design-system/components-v2';
import { GridApi, RowNode } from 'ag-grid-community';
import { directPermissionName, PermissionCategorySubType } from '../interfaces';
import { CellRendererWrapper } from './CellRenderer.styled';

export interface IInfoState {
    permissionTitle: string;
    permissionType: string;
    permissionSubType: PermissionCategorySubType;
}

export const InheritedRolesCellRenderer = (props: {
    onClick: (checked: boolean) => void;
    api: GridApi;
    value: string[];
    node: RowNode;
    colDef: { cellRendererParams: { permissionSubType: PermissionCategorySubType } };
}) => {
    const roleNames = props.value;
    const uniqName = [...new Set(roleNames)];

    return (
        <CellRendererWrapper>
            <Tooltip>
                <>
                    {uniqName?.map((role, index) => {
                        return (
                            <Chip
                                key={role}
                                color={role === directPermissionName ? 'warning' : 'normal'}
                                label={role}
                            />
                        );
                    })}
                </>
            </Tooltip>
        </CellRendererWrapper>
    );
};
