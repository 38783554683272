import { AxiosResponse } from 'axios';
import { Vendors } from 'common/consts/vendors';

export enum ScanMode {
    Saas = 'saas',
    InAccount = 'inAccount',
    InAccountHub = 'inAccountHub',
    InAccountSub = 'inAccountSub',
}

export interface EnableAgentlessAwsAccountResponse {
    cloudAccountId: string;
    crossAccountRole: string;
    roleExternalId: string;
    scanMode: ScanMode;
}

export interface WorkloadAgentlessAccount {
    agentlessAccountSettings: AgentlessAccountSettings;
    missingAwpPrivateNetworkRegions: string[];
    accountIssues: AccountIssues;
    cloudAccountId: string;
    agentlessProtectionEnabled: boolean;
    scanMode: ScanMode;
    provider: Vendors;
    shouldUpdate: boolean;
    isOrgOnboarding: boolean;
    isOrgOnboardingManagement: boolean;
    isTerraform: boolean;
    centralizedCloudAccountId?: string;
}

interface AccountIssues {
    regions: { [region: string]: { issueType: string } };
    account: Account;
}

interface Account {
    issueType: string;
}

export interface AgentlessAccountSettings {
    customTags?: { [key: string]: string };
    disabledRegions?: string[];
    scanMachineIntervalInHours: number;
    maxConcurrenceScansPerRegion: number;
    skipFunctionAppsScan: boolean;
    inAccountScannerVPC: string;
    scanAWSLicensedImages?: boolean;
}

export interface CentralizedAgentlessAccountSettings {
    inAccountScannerVPC: string;
}

// ~~~ GetWorkloadAgentessAccount ~~~
export namespace GetWorkloadAgentessAccount {
    export type Function = (
        cloudAccountId: string,
        vendor: Vendors,
        useCache?: boolean,
    ) => Promise<AxiosResponse<GetWorkloadAgentessAccount.Response>>;
    export interface Response extends WorkloadAgentlessAccount {}
}

// ~~~ UpdateWorkloadAgentlessAccountSettings ~~~
export namespace UpdateWorkloadAgentlessAccountSettings {
    export type Function = (
        cloudAccountId: string,
        vendor: Vendors,
        request: UpdateWorkloadAgentlessAccountSettings.Request,
    ) => Promise<AxiosResponse<UpdateWorkloadAgentlessAccountSettings.Response>>;
    export interface Request extends Partial<AgentlessAccountSettings> {}
    export interface Response extends AgentlessAccountSettings {}
}

// ~~~ UpdateCentralizedWorkloafAgentlessAccountSettings ~~~
export namespace UpdateCentralizedWorkloafAgentlessAccountSettings {
    export type Function = (
        cloudAccountId: string,
        vendor: Vendors,
        request: UpdateWorkloadAgentlessAccountSettings.Request,
    ) => Promise<AxiosResponse<UpdateWorkloadAgentlessAccountSettings.Response>>;
    export interface Request extends CentralizedAgentlessAccountSettings {}
    export interface Response extends CentralizedAgentlessAccountSettings {}
}

// ~~~ DeleteWorkloadAgentessAccount ~~~
export namespace DeleteWorkloadAgentessAccount {
    export type Function = (
        cloudAccountId: string,
        vendor: Vendors,
        forceDelete?: boolean,
    ) => Promise<AxiosResponse<DeleteWorkloadAgentessAccount.Response>>;
    export type Response = undefined;
}

// ~~~ EnableAgentlessAwsAccount ~~~
export namespace EnableAgentlessAwsAccount {
    export type Function = (
        cloudAccountId: string,
        request: EnableAgentlessAwsAccount.Request,
    ) => Promise<AxiosResponse<EnableAgentlessAwsAccount.Response>>;
    export interface Request {
        cloudGuardAWPStackName?: string;
        crossAccountRoleName?: string;
        crossAccountRoleExternalId?: string;
        scanMode: ScanMode;
        agentlessAccountSettings?: {
            scanAWSLicensedImages?: boolean;
            inAccountScannerVPC?: string;
        };
    }
    export interface Response extends EnableAgentlessAwsAccountResponse {}
}

// ~~~ EnableAwpAzureAccount ~~~
export namespace EnableAwpAzureAccount {
    export type Function = (
        cloudAccountId: string,
        request: EnableAwpAzureAccount.Request,
    ) => Promise<AxiosResponse<EnableAwpAzureAccount.Response>>;
    export interface Request {
        scanMode: ScanMode;
        onboardingCustomizations?: OnboardingCustomizations;
        agentlessAccountSettings?: Partial<AgentlessAccountSettings>;
    }
    export type Response = undefined;
}

export interface GetAgentlessAwsOnboardingDataResponse {
    crossAccountTemplateUrl: string;
    crossAccountCloudFormationQuickCreateUrl: string;
    version: string;
}

// ~~~ GetAgentlessAwsOnboardingData ~~~
export namespace GetAgentlessAwsOnboardingData {
    export type Function = () => Promise<AxiosResponse<GetAgentlessAwsOnboardingData.Response>>;
    export interface Response extends GetAgentlessAwsOnboardingDataResponse {}
}

export interface CheckForUpdateRespsonse {
    shouldUpdate: boolean;
    updateStackCloudFormationTemplateURL: string;
    version: string;
}

// ~~~ GetAgentlessAwsOnboardingData ~~~
export namespace CheckForUpdate {
    export type Function = (id: string) => Promise<AxiosResponse<CheckForUpdate.Response>>;
    export interface Response extends CheckForUpdateRespsonse {}
}

export interface GetAzureOnboardingDataResponse {
    onboardingScriptCommand: string;
    onboardingScriptURL: string;
    version: string;
}

export enum OnboardingCustomizationsKeys {
    VMDataShareRolename = 'virtualMachineDataShareRoleName',
    VMScanOperatorRolename = 'virtualMachineScanOperatorRoleName',
    FunctionAppsOperatorRolename = 'functionAppScanOperatorRoleName',
    FunctionAppsScannerRolename = 'functionAppScannerRoleName',
    ResourceGroupName = 'resourceGroupName',
    ManagedIdentityName = 'scannerManagedIdentityName',
    ManagementResourceGroupName = 'ManagementResourceGroupName',
    CryptoCreatorRoleName = 'cryptoCreatorRoleName',
    DiskEncryptorRoleName = 'diskEncryptorRoleName',
}

export type OnboardingCustomizations = {
    [key in OnboardingCustomizationsKeys]: string;
};

// ~~~ GetAzureOnboardingData ~~~
export namespace GetAzureOnboardingData {
    export type Function = (
        cloudAccountId: string,
        request: GetAzureOnboardingData.Request,
    ) => Promise<AxiosResponse<GetAzureOnboardingData.Response>>;
    export interface Request {
        scanMode: ScanMode;
        sseCmkEncryptedDisksScan: boolean;
        centralizedId?: string;
        skipFunctionAppsScan?: boolean;
        onboardingCustomizations?: OnboardingCustomizations;
        managementGroupId?: string;
    }
    export interface Response extends GetAzureOnboardingDataResponse {}
}

// ~~~ GetAwsOnboardingData ~~~
export namespace GetAwsOnboardingData {
    export type Function = (
        cloudAccountId: string,
        request: GetAwsOnboardingData.Request,
    ) => Promise<AxiosResponse<GetAwsOnboardingData.Response>>;
    export type Request = (
        | { scanMode: ScanMode.InAccount | ScanMode.Saas }
        | { scanMode: ScanMode.InAccountHub }
        | { scanMode: ScanMode.InAccountSub; centralizedCloudAccountId: string }
    ) & {
        externalAccountId: string;
        crossAccountRoleExternalId: string;
        cloudGuardAWPStackName: string;
    };
    export type Response = {
        crossAccountTemplateUrl: string;
        crossAccountCloudFormationQuickCreateUrl: string;
        version: string;
    };
}

export interface CheckAzureAccountResponse {
    cloudAccountId: string;
    agentlessProtectionEnabled: boolean;
    scanMode: string;
    provider: string;
    shouldUpdate: boolean;
    isOrgOnboarding: boolean;
}

// ~~~ CheckAzureAccount ~~~
export namespace CheckAzureAccount {
    export type Function = (
        cloudAccountId: string,
        useCache?: boolean,
    ) => Promise<AxiosResponse<CheckAzureAccount.Response>>;
    export interface Response extends CheckAzureAccountResponse {}
}

export type Hub = {
    accountId: string;
    cloudAccountId: string;
    externalAccountNumber: string;
    tenantId: string;
};

export type GetHubsResponse = Array<Hub>;

// ~~~ GetHubs ~~~
export namespace GetHubs {
    export type Function = (
        id: string,
        platform: Vendors.AZURE | Vendors.AWS,
        organizationId?: string,
    ) => Promise<AxiosResponse<GetHubs.Response>>;
    export interface Response extends GetHubsResponse {}
}

// ~~~ EnableHubAccount ~~~
export namespace EnableHubAccount {
    export type Function = <T extends Vendors.AWS | Vendors.AZURE>(
        cloudAccountId: string,
        platform: T,
        request: Request<T>,
    ) => Promise<AxiosResponse<Response>>;

    interface AzureRequest {
        scanMode: ScanMode;
        onboardingCustomizations?: OnboardingCustomizations;
        managementGroupId?: string;
    }
    interface AwsRequest {
        crossAccountRoleName: string;
        crossAccountRoleExternalId: string;
        cloudGuardAWPStackName: string;
    }

    export type Request<T extends Vendors.AWS | Vendors.AZURE> = T extends Vendors.AWS ? AwsRequest : AzureRequest;
    export type Response = undefined;
}

// ~~~ EnableAzureSubAccount ~~~
export namespace EnableAzureSubAccount {
    export type Function = (
        cloudAccountId: string,
        request: EnableAzureSubAccount.Request,
    ) => Promise<AxiosResponse<EnableAzureSubAccount.Response>>;
    export interface Request {
        scanMode: ScanMode;
        centralizedCloudAccountId: string;
        onboardingCustomizations?: OnboardingCustomizations;
    }
    export type Response = undefined;
}

export namespace EnableAwsSubAccount {
    export type Function = (
        cloudAccountId: string,
        request: EnableAwsSubAccount.Request,
    ) => Promise<AxiosResponse<EnableAwsSubAccount.Response>>;
    export interface Request {
        crossAccountRoleName: string;
        crossAccountRoleExternalId: string;
        centralizedCloudAccountId: string;
        cloudGuardAWPStackName: string;
    }
    export type Response = undefined;
}

// ~~~ GetAwsAzureAgentlessAccountsStates ~~~
export namespace GetAwsAzureAgentlessAccountsStates {
    export type Function = (
        vendor: Vendors.AWS | Vendors.AZURE,
    ) => Promise<AxiosResponse<GetAwsAzureAgentlessAccountsStates.Response>>;
    export type Response = GetAwsAzureAgentlessAccountsStatesResponse[];
}

export interface GetAwsAzureAgentlessAccountsStatesResponse {
    cloudAccountId: string;
    agentlessProtectionEnabled: boolean;
    scanMode: string;
    provider: string;
    shouldUpdate: boolean;
    isOrgOnboarding: boolean;
    centralizedCloudAccountId?: string;
}

// ~~~ GetAwsAzureServerlessAccountsStates ~~~
export namespace GetAwsAzureServerlessAccountsStates {
    export type Function = (
        vendor: Vendors.AWS | Vendors.AZURE,
    ) => Promise<AxiosResponse<GetAwsAzureServerlessAccountsStates.Response>>;
    export type Response = GetAwsAzureServerlessAccountsStatesResponse[];
}

export namespace GetAwsServerlessAccountsState {
    export type Function = (cloudAccountId: string) => Promise<AxiosResponse<GetAwsServerlessAccountsState.Response>>;
    export type Response = GetAwsAzureServerlessAccountsStatesResponse;
}

export interface GetAwsAzureServerlessAccountsStatesResponse {
    cloudAccountId: string;
    externalAccountNumber: string;
    cloudVendor: string;
    state: GetAwsAzureServerlessAccountsStatesResponseState;
    accountId: number;
    reason: string;
    shouldUpdate: boolean;
    isOrgOnboarding: boolean;
    hasMissingPermissions: boolean;
    validatePermissionsStatus: string;
    lastValidatePermissionsTimestamp: number;
}

export enum GetAwsAzureServerlessAccountsStatesResponseState {
    Active = 'active',
    Pending = 'pending',
    PendingInProgress = 'pending_in_progress',
    Deleted = 'deleted',
}

export namespace RequestScan {
    export type Function = (
        cloudAccountId: string,
        vendor: Vendors,
        id: string,
    ) => Promise<AxiosResponse<RequestScan.Response>>;
    export type Response = string;
}

// ~~~ GetAwsAzureServerlessFunctionsGet ~~~
export namespace GetAwsAzureServerlessFunctionsGet {
    export type Function = (
        id: string,
        vendor: Vendors.AWS | Vendors.AZURE,
    ) => Promise<AxiosResponse<GetAwsAzureServerlessFunctionsGet.Response>>;
    export interface Response extends GetAwsAzureServerlessFunctionsGetResponse {}
}

export namespace GetAzureOffboarding {
    export type Function = (
        cloudAccountId: string,
        centralizedAccounts?: string,
    ) => Promise<AxiosResponse<GetAzureOffboarding.Response>>;
    export interface Response {
        offboardingScriptCommand: string;
        offboardingScriptURL: string;
        version: string;
    }
}

export interface GetAwsAzureServerlessFunctionsGetResponse {
    functionArn: string;
    region?: any;
    accountId: number;
    cloudAccountId: string;
    id: string;
    name: string;
    vpc: string;
    funcRiskLevel: string;
    funcPostureScore: number;
    includesFSP: boolean;
    isRuntimeSupported: boolean;
    fspMode: string;
    fspVersion?: any;
    scanStatus: string;
    timeout: number;
    whiteListProgressPercent: number;
    runtime: string;
    handler: string;
    fspAutoInstrumentation: boolean;
    fspAutoInstrumentationSupported: boolean;
    fspAutoInstrumentationState?: any;
    fspAutoInstrumentationProgressStep?: any;
    triggersAnalysis: any[];
    rolePermissionsAnalysis: RolePermissionsAnalysis[];
    serverlessErrors?: any;
    fspFeatures: string[];
}

interface RolePermissionsAnalysis {
    name: string;
    suggestedPolicy?: any;
    resources: Resource[];
}

interface Resource {
    status: string;
    message: string;
    resource: string;
    action: string;
    severity?: any;
}

export namespace GetAgentlessSettings {
    export type Function = () => Promise<AxiosResponse<GetAgentlessSettings.Response>>;
    export interface Response {
        scanMachineIntervalInHours: null | number;
        maxConcurrenceScansPerRegion: null | number;
        customTags: { [key: string]: string } | null;
        scanAWSLicensedImages?: boolean | null;
    }
}

export namespace UpdateAgentlessSettings {
    export type Function = (
        request: UpdateAgentlessSettings.Request,
    ) => Promise<AxiosResponse<Partial<UpdateAgentlessSettings.Response>>>;
    export interface Request {
        scanMachineIntervalInHours?: number;
        maxConcurrenceScansPerRegion?: number;
        customTags?: { [key: string]: string };
    }
    export type Response = Partial<Request>;
}

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';
