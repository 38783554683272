import { ColDef } from 'ag-grid-community';
import i18n from 'common/services/translations/translations';
import { IRole } from 'common/interface/services';
import { I18nTranslationKey, rolesTableRegistry } from './initRolesPage';
import IconLinkCellRenderer from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { ValueFormatterParams } from 'ag-grid-enterprise';
import { EditRole } from './RolesPage';

const columnDefs: () => ColDef<IRole>[] = () => [
    {
        colId: 'checkbox',
        headerCheckboxSelection: true,
        width: 40,
        maxWidth: 40,
        minWidth: 40,
        resizable: false,
        lockPosition: 'left',
        checkboxSelection: () => {
            return true;
        },
        showDisabledCheckboxes: true,
    },
    {
        colId: 'name',
        field: 'name',
        headerName: i18n.t('TABLE.HEADERS.NAME', { ns: I18nTranslationKey }),
        width: 400,
        suppressSizeToFit: true,
        sortable: true,
        cellRenderer: (params: ValueFormatterParams) =>
            IconLinkCellRenderer({ isLink: true, value: params.data?.name, onClick: () => EditRole(params.data) }),
        valueFormatter: (params) => `${params.data?.name}`,
    },
    {
        colId: 'description',
        field: 'description',
        headerName: i18n.t('TABLE.HEADERS.DESCRIPTION', { ns: I18nTranslationKey }),
        width: 200,
        suppressSizeToFit: true,
        sortable: true,
        enableRowGroup: true,
        valueGetter: (params) => params.data?.description || '',
        valueFormatter: (params) => `${params.value}` || '',
    },
    {
        colId: 'users',
        field: 'users',
        headerName: i18n.t('TABLE.HEADERS.USERS', { ns: I18nTranslationKey }),
        width: 200,
        suppressSizeToFit: true,
        sortable: true,
        enableRowGroup: true,
        valueGetter: (params) => params.data?.users?.length || '',
        valueFormatter: (params) => `${params.value}` || '',
    },
    {
        colId: 'service accounts',
        field: 'serviceAccounts',
        headerName: i18n.t('TABLE.HEADERS.SERVICE_ACCOUNTS', { ns: I18nTranslationKey }),
        width: 200,
        suppressSizeToFit: true,
        sortable: true,
        enableRowGroup: true,
        valueGetter: (params) => params.data?.serviceAccounts?.length || '',
        valueFormatter: (params) => `${params.value}` || '',
    },
];

export default function initializeRolesColumnDefs() {
    rolesTableRegistry.addColumnDefs(columnDefs());
}
