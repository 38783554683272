import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { Vendors } from 'common/consts/vendors';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import EmptyState from 'common/components/EmptyState';
import { ScanMode } from 'modules/workloads/services/awp/awp.interface';
import { useScanRequest } from 'modules/workloads/services/awp/hooks/useScanRequest/useScanRequest';
import { getNotificationsService } from 'common/interface/services';
import { AssetTypes } from 'common/assets/assets.const';
import { getScanStatus, getScanStatusDetails } from 'modules/workloads/utils';
import { ScanStatus } from '../page/widgets/Chips/ScanStatusChip.types';

const isStatusScannedOrPartial = (status: string) =>
    [ScanStatus.Partial.toString(), ScanStatus.Scanned.toString()].includes(status);

const AwpScanResultEmptyState = ({ entity }: { entity: ICloudEntityData }) => {
    const { t } = useTranslation(getK8sNamespace('common'));
    const { protectedAsset, cloudAccount } = entity;
    const status = getScanStatus(protectedAsset);
    const details = getScanStatusDetails(protectedAsset);
    const isScanModeSaaS =
        protectedAsset.externalAdditionalFields?.find(
            ({ name, source }) => name === 'ScanMode' && source === 'agentless',
        )?.value === ScanMode.Saas;
    const canRunScan = !isScanModeSaaS && entity.protectedAsset.typeByPlatform !== AssetTypes.AWS_LAMBDA;

    const { onScanRequest, isRequestPending } = useScanRequest({
        cloudAccountId: cloudAccount.id,
        cloudProvider: cloudAccount.platform as Vendors,
        entityId: protectedAsset.entityId,
        addNotificationService: getNotificationsService(),
    });

    let label = t('noScanResults');
    if (status && !isStatusScannedOrPartial(status)) {
        label = `${t('workloadImageScan.agentlessScan.header')} ${status}`;
    }

    return (
        <EmptyState
            label={label}
            description={`${details ? `${details}\n` : ''}${canRunScan ? t('workloadImageScan.agentlessScan.body') : ''}`}
            iconName='objectExplorer'
            iconSize={64}
            buttons={
                canRunScan
                    ? [
                          {
                              color: 'brandPrimary',
                              onClick: onScanRequest,
                              children: t('workloadImageScan.agentlessScan.buttonText'),
                              disabled: isRequestPending,
                          },
                      ]
                    : undefined
            }
        />
    );
};

export default AwpScanResultEmptyState;
