import { t } from 'i18next';
import { string } from 'yup';
import { EMAIL_REGEX } from 'common/utils/http';

export const ociTenancyIdRegex = new RegExp(/^ocid\d+.tenancy.oc\d+[a-z0-9A-Z.]+/);
export const ociUserRegex = new RegExp(/^ocid\d+.user.oc\d+[a-z0-9A-Z.]+/);
export const ociRegionRegex = new RegExp(/\w{2}-\w+-\d+/);

export const ociOnBoardingValidations = {
    emailAddress: string()
        .required(t('ON_BOARDING.OCI.VALIDATIONS.USER_EMAIL_ADDRESS_REQUIRED'))
        .test({
            name: 'emailAddress',
            message: t('ON_BOARDING.OCI.VALIDATIONS.USER_EMAIL_ADDRESS_NOT_VALID'),
            test: (value: any) => EMAIL_REGEX.test(value),
        }),
    homeRegion: string()
        .required(t('ON_BOARDING.OCI.VALIDATIONS.HOME_REGION_REQUIRED'))
        .test({
            name: 'homeRegion',
            message: t('ON_BOARDING.OCI.VALIDATIONS.HOME_REGION_NOT_VALID'),
            test: (value: any) => ociRegionRegex.test(value),
        }),
    tenancyId: string()
        .required(t('ON_BOARDING.OCI.VALIDATIONS.TENANCY_ID_REQUIRED'))
        .test({
            name: 'tenancyId',
            message: t('ON_BOARDING.OCI.VALIDATIONS.TENANCY_ID_NOT_VALID'),
            test: (value: any) => ociTenancyIdRegex.test(value),
        }),
    accountName: string().required(t('ON_BOARDING.OCI.VALIDATIONS.ACCOUNT_NAME_REQUIRED')),
};

export const ociAccountValidations = {
    userOcid: string()
        .required(t('ON_BOARDING.OCI.VALIDATIONS.USER_REQUIRED'))
        .test({
            name: 'userOcid',
            message: t('ON_BOARDING.OCI.VALIDATIONS.USER_NOT_VALID'),
            test: (value: any) => ociUserRegex.test(value),
        }),
};
