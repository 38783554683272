import React from 'react';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { Stack } from 'common/design-system/components-v2';
import WidgetCard from 'common/components/Dashboard/WidgetCard/WidgetCard';
import { useTranslation } from 'react-i18next';
import { useImagesByWorkload } from 'modules/workloads/services/workload/hooks/useImagesByWorkload';
import { ALink } from 'common/components/ALink';
import { A } from 'common/components/Anchor/Anchor';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import EmptyState from 'common/components/EmptyState';
import { getIconNameByEnvironmentCode } from 'common/utils/environments';

const KubernetesGeneralSectionImages: React.FC<{ entity: ICloudEntityData }> = ({ entity }) => {
    const { t } = useTranslation(getK8sNamespace('protected-asset-details'));
    const { isLoading, data } = useImagesByWorkload(entity.protectedAsset.id);

    const imagesList = React.useMemo(() => {
        return (
            data?.map((image) => {
                const platformCode = Number(image.id.split('|')[0]);
                const link = `/workload/images/generic?cloudAccountId=${entity.cloudAccountId}&assetType=${image.type}&assetId=${image.imageId}&platform=${getIconNameByEnvironmentCode(platformCode)}`;
                return { title: image.name, link, label: `${image.repository}:${image.tag}` };
            }) || []
        );
    }, [data, entity]);

    const content = React.useMemo(() => {
        switch (true) {
            case isLoading:
                return (
                    <Stack justifyContent='center' alignItems='center'>
                        <CloudAnimationLoader size={'medium'} />
                    </Stack>
                );
            case imagesList.length === 0:
                return (
                    <Stack justifyContent='center' alignItems='center' fullHeight padding={[1, 3]}>
                        <EmptyState
                            iconSize={48}
                            fontVariant='bodyLg'
                            iconName={'noResults'}
                            minHeight='unset'
                            label={t('overviewGeneral.features.images.noData')}
                        />
                    </Stack>
                );
            default:
                return (
                    <Stack spacing={2} padding={[1, 3]}>
                        {imagesList.map((image, valuesIndex) => (
                            <ALink key={`details-values-${valuesIndex}`} as={A} href={image.link} title={image.title}>
                                {image.label}
                            </ALink>
                        ))}
                    </Stack>
                );
        }
    }, [isLoading, imagesList, t]);

    return <WidgetCard classNames={{}} title={t('overviewGeneral.features.images.title')} content={content} />;
};

export default KubernetesGeneralSectionImages;
