import { getHttpService } from 'common/interface/services';
import {
    IDashboardWidget,
    IGenericWidgetDataItem,
    IGenericWidgetDataItemSet,
    IGenericWidgetDataSet,
} from 'common/module_interface/overview/Interface';
import dayjs from 'dayjs';
import { ProtectedAssetsResponse } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import {
    getCloudAccountsService,
    getOrganizationalUnitService,
    IAggregationDataItem,
    IGroupByDataItem,
    IOrganizationalUnitAllChildrens,
} from 'common/interface/data_services';
import i18n from 'i18next';
import { changeUrl } from 'common/utils/http';
import { limitDataItems } from 'common/components/Widgets/helpers';
import { IVendor, VENDORS, getVendor } from 'common/consts/vendors';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { orderBy } from 'lodash';
import { deepCloneObject } from 'common/utils/objectUtils';
import { IDateFilterOption } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { getAggregationFromWidgetSelection, getLatestFindings } from '../Utils';
import { FINDINGS_GROUPING_URL, EventType } from 'common/module_interface/events/EventsConsts';
import {
    FindingSeverityEnum,
    getFindingSeverities,
    getSafeFindingSeverityInfo,
    IFindingSeverityInfo,
} from 'common/consts/FindingSeverity';
import { IFieldInfo, SortOrder } from 'common/interface/general';
import { getEventsTableWidgetService } from 'common/module_interface/events/EventsTableRegistry';
import { getAssetByEntityTypeByEnvironmentType } from 'common/utils/filterUtils';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';
import { findingModelOriginCodeToString } from 'common/components/Findings/Findings.interface';
import { getEventsTableNamespace } from 'modules/events/EventsTable/initialize.i18n';

const defaultPageSize = 10;
const defaultIcon: AllIconsName = 'cloud';
const defaultCreationTimeFrom = '1970-01-01T00:00:00.000Z';

interface IFindingsResponse extends ProtectedAssetsResponse {
    totalFindingsCount: number;
}

interface ICreationTime {
    from: string;
    to: string;
}

interface IPropertyList {
    direction: number;
    property: any;
}

interface IFindingsFiltersRequest {
    fields: string[];
    creationTime: string | ICreationTime | null;
    freeTextPhrase: string;
}

interface IFindingsRequestDataObject {
    filter: IFindingsFiltersRequest;
    pageSize: number;
    propertiesList: IPropertyList[] | null;
    skipAggregations: boolean;
    aggregations: string[] | [];
    lowAggregationsSize: boolean;
}

const getDateFilterValues = (dateFilter: IDateFilterOption) => {
    const creationTimeTo = dayjs().toISOString();
    const timeFrame = dateFilter?.value?.time || dateFilter?.value?.count;
    if (!dateFilter) {
        return null;
    }

    switch (timeFrame) {
        case 'PT24H':
        case 24:
            return {
                creationTimeFrom: dayjs().subtract(24, 'hour').toISOString(),
                creationTimeTo: creationTimeTo,
            };
        case 'P7D':
        case 7:
            return {
                creationTimeFrom: dayjs().subtract(7, 'day').toISOString(),
                creationTimeTo: creationTimeTo,
            };
        case 'P30D':
        case 30:
            return {
                creationTimeFrom: dayjs().subtract(30, 'day').toISOString(),
                creationTimeTo: creationTimeTo,
            };
        default:
            if (dateFilter.value?.epoch?.from && dateFilter.value?.epoch?.to) {
                const roundedStartTime = dayjs(dateFilter.value?.epoch.from)
                    .set('seconds', 0)
                    .set('milliseconds', 0)
                    .toDate();
                const roundedEndTime = dayjs(dateFilter.value?.epoch.to)
                    .set('seconds', 0)
                    .set('milliseconds', 0)
                    .toDate();
                return {
                    creationTimeFrom: roundedStartTime,
                    creationTimeTo: roundedEndTime,
                };
            } else {
                return {
                    creationTimeFrom: defaultCreationTimeFrom,
                    creationTimeTo: creationTimeTo,
                };
            }
    }
};

const getDefaultFiltersFields = () => {
    return [
        {
            name: 'organizationalUnitId',
            value: '00000000-0000-0000-0000-000000000000',
        },
    ];
};

export const alertsDataGetter = async (
    widget: IDashboardWidget,
    convertData?: Function,
    aggregationType?: string,
    latest = false,
) => {
    const stackByKey = widget.options?.alertsOptions?.stackByKey;
    const getPropertiesList = () => {
        if (stackByKey) {
            return [
                {
                    direction: -1,
                    property: widget.aggregation,
                },
                {
                    direction: -1,
                    property: stackByKey,
                },
            ];
        } else {
            return null;
        }
    };
    const apiRoute = 'searchWithCustomAggregations';
    const freeTextPhrase = widget.filterState && widget.filterState.find((filter: any) => filter.name === 'free-text');
    const filterFields =
        widget.filterState &&
        widget.filterState.filter((filter: any) => {
            return filter.name !== 'free-text' && filter.name !== 'date-picker' && (filter.value || filter.value === 0);
        });
    const dateFilter = widget.filterState && widget.filterState.find((filter: any) => filter.name === 'date-picker');
    const datesFilterValues = getDateFilterValues(dateFilter);
    const defaultFiltersFields = getDefaultFiltersFields();
    const creationTime = {} as any;
    if (datesFilterValues?.creationTimeFrom && datesFilterValues.creationTimeTo) {
        creationTime.from = dayjs(datesFilterValues.creationTimeFrom);
        creationTime.from = dayjs(creationTime.from).set('seconds', 0).set('milliseconds', 0).toISOString();
        creationTime.to = dayjs(datesFilterValues.creationTimeTo);
        creationTime.to = dayjs(creationTime.to).set('seconds', 0).set('milliseconds', 0).toISOString();
    }
    const requestDataObject: IFindingsRequestDataObject = {
        filter: {
            fields: filterFields?.length > 0 ? filterFields : defaultFiltersFields,
            creationTime: datesFilterValues &&
                datesFilterValues.creationTimeFrom &&
                datesFilterValues.creationTimeTo && {
                    from: creationTime.from,
                    to: creationTime.to,
                },
            freeTextPhrase: freeTextPhrase && freeTextPhrase.value,
        },
        pageSize: widget.limit || defaultPageSize,
        propertiesList: getPropertiesList(),
        skipAggregations: false,
        aggregations: [],
        lowAggregationsSize: false,
    };

    if (aggregationType) {
        requestDataObject.aggregations = [getAggregationFromWidgetSelection(aggregationType)];
    } else {
        requestDataObject.skipAggregations = true;
    }

    const response = await getHttpService().post<IFindingsResponse>({
        path: `Compliance/Finding/${apiRoute}`,
        requestObject: {
            data: requestDataObject,
        },
        loggingConfig: { useLogging: false },
        cachingConfig: { useCache: true },
    });

    let selectedAggregation = aggregationType && response.aggregations[aggregationType];

    if (aggregationType === 'organizationalUnitId') {
        selectedAggregation = response.aggregations['cloudAccountId_calc'];
    }

    function filterOutWrongData(selectedAggregation: any) {
        //until the BE will fix the search results
        if (widget.aggregation === 'alertType') {
            return selectedAggregation.filter((item: any) => item.value >= 0);
        } else {
            return selectedAggregation;
        }
    }

    function resolveUnreadableValues(selectedAggregation: IFindingsResponse['aggregations'][string]) {
        switch (widget.aggregation) {
            case 'entityTypeByEnvironmentType':
                return selectedAggregation.map((item: { value: string; count: number }) => {
                    const asset = getAssetByEntityTypeByEnvironmentType(item.value);
                    if (asset) {
                        return {
                            displayName: asset.displayName,
                            value: item.value,
                            count: item.count,
                        };
                    }
                    return item;
                });
            default:
                return selectedAggregation;
        }
    }

    selectedAggregation = Array.isArray(selectedAggregation)
        ? resolveUnreadableValues(selectedAggregation)
        : selectedAggregation; // Why selectedAggregation is not only an array?

    selectedAggregation = filterOutWrongData(selectedAggregation);
    selectedAggregation = selectedAggregation ? selectedAggregation : [];
    if (convertData && selectedAggregation && !latest) {
        if (widget.type.toLowerCase() === 'summary') {
            return await convertData(response, widget);
        } else {
            return await convertData(selectedAggregation, widget);
        }
    } else if (convertData && latest) {
        return await convertData(response, widget, aggregationType);
    } else if (convertData) {
        return convertData(response, widget);
    }
};

export const alertsFilterPanelDataGetter = async (
    widget: IDashboardWidget,
    convertData?: Function,
    aggregationType?: string,
    latest = false,
) => {
    const stackByKey = widget.options?.alertsOptions?.stackByKey;
    const getPropertiesList = () => {
        if (stackByKey) {
            return [
                {
                    direction: -1,
                    property: widget.aggregation,
                },
                {
                    direction: -1,
                    property: stackByKey,
                },
            ];
        } else {
            return null;
        }
    };
    const apiRoute = 'searchWithCustomAggregations';
    const freeTextPhrase = widget.filterState && widget.filterState.find((filter: any) => filter.name === 'free-text');
    const filterFields =
        widget.filterState &&
        widget.filterState.filter((filter: any) => {
            return filter.name !== 'free-text' && filter.name !== 'date-picker' && (filter.value || filter.value === 0);
        });
    const dateFilter = widget.filterState && widget.filterState.find((filter: any) => filter.name === 'date-picker');
    const datesFilterValues = getDateFilterValues(dateFilter);
    const defaultFiltersFields = getDefaultFiltersFields();

    const response = await getHttpService().post<IFindingsResponse>({
        path: `Compliance/Finding/${apiRoute}`,
        requestObject: {
            data: {
                filter: {
                    fields: filterFields?.length > 0 ? filterFields : defaultFiltersFields,
                    creationTime: datesFilterValues &&
                        datesFilterValues.creationTimeFrom &&
                        datesFilterValues.creationTimeTo && {
                            from: dayjs(datesFilterValues.creationTimeFrom).toISOString(),
                            to: dayjs(datesFilterValues.creationTimeTo).toISOString(),
                        },
                    freeTextPhrase: freeTextPhrase && freeTextPhrase.value,
                },
                pageSize: widget.limit || defaultPageSize,
                propertiesList: getPropertiesList(),
                lowAggregationsSize: false,
            },
        },
        loggingConfig: { useLogging: false },
        cachingConfig: { useCache: true },
    });

    const selectedAggregation = aggregationType && response.aggregations[aggregationType];
    if (convertData && selectedAggregation && !latest) {
        return await convertData(selectedAggregation, widget);
    } else if (convertData && latest) {
        return await convertData(response, widget, aggregationType);
    } else if (convertData) {
        return convertData(response, widget);
    }
};

export const alertsStackedColumnDataGetter = async (
    aggregationName: string,
    widget: IDashboardWidget,
    convertData?: Function,
) => {
    const stackByKey = widget.options?.alertsOptions?.stackByKey || 'severity';
    const freeTextPhrase = widget.filterState && widget.filterState.find((filter: any) => filter.name === 'free-text');
    const filterFields =
        widget.filterState &&
        widget.filterState.filter((filter: any) => {
            return filter.name !== 'free-text' && filter.name !== 'date-picker' && (filter.value || filter.value === 0);
        });
    const dateFilter = widget.filterState && widget.filterState.find((filter: any) => filter.name === 'date-picker');
    const datesFilterValues = getDateFilterValues(dateFilter);
    let creationTime = null;
    if (datesFilterValues?.creationTimeFrom && datesFilterValues?.creationTimeTo) {
        creationTime = {} as any;
        creationTime.from = dayjs(datesFilterValues.creationTimeFrom);
        creationTime.from = dayjs(creationTime.from).set('seconds', 0).set('milliseconds', 0).toISOString();
        creationTime.to = dayjs(datesFilterValues.creationTimeTo);
        creationTime.to = dayjs(creationTime.to).set('seconds', 0).set('milliseconds', 0).toISOString();
    }
    const response = await getHttpService().post<IFindingsResponse>({
        path: FINDINGS_GROUPING_URL,
        requestObject: {
            data: {
                filter: {
                    fields: filterFields,
                    creationTime: creationTime,
                    freeTextPhrase: freeTextPhrase && freeTextPhrase.value,
                },
                pageSize: widget.limit || defaultPageSize,
                propertiesList: [
                    {
                        direction: -1,
                        property: aggregationName,
                    },
                    {
                        direction: -1,
                        property: stackByKey,
                    },
                ],
            },
        },
        cachingConfig: { useCache: true },
    });

    return convertData && (await convertData(response, widget));
};

export const getRawSearchData = (response: IFindingsResponse) => {
    return response;
};

const extractItemValue = (string: string | number) => {
    const parts = string.toString()?.split('|') || [];
    if (parts.length) {
        return {
            name: parts[parts.length - 1],
            value: parts[parts.length - 2],
        };
    } else return null;
};

const getUniqueFields = (fields: IFieldInfo[]) => {
    const nameValueSignaturesSet: Set<string> = new Set<string>();
    const finalFields: IFieldInfo[] = [];

    fields.forEach((field) => {
        const nameValueSignature = `${field.name}:::${JSON.stringify(field.value)}`;
        if (!nameValueSignaturesSet.has(nameValueSignature)) {
            nameValueSignaturesSet.add(nameValueSignature);
            finalFields.push(field);
        }
    });
    return finalFields;
};

export const buildAlertsWidgetLink = (widget: IDashboardWidget, item: any, isItemOverridesFilter?: boolean) => {
    const filterState: any[] = widget.filterState;
    let filterFieldsArr: IFieldInfo[] = [];
    filterState.forEach((filter: any) => {
        if (filter.name !== 'free-text' && filter.name !== 'date-picker' && (filter.value || filter.value === 0)) {
            filterFieldsArr.push({ name: filter.name, value: filter.value });
        }
    });
    const isExist = filterFieldsArr.find((item) => item.name === widget.aggregation);
    if (!isExist) {
        filterFieldsArr.push({
            name: widget.aggregation || '',
            value: widget.aggregation === item.fieldName ? item.fieldValue : item.value,
        });
    } else if (isItemOverridesFilter) {
        filterFieldsArr = filterFieldsArr.filter((item) => item.name !== widget.aggregation);
        filterFieldsArr.push({
            name: widget.aggregation || '',
            value: widget.aggregation === item.fieldName ? item.fieldValue : item.value,
        });
    }

    const nestedBuckets = item.nestedBuckets;
    if (nestedBuckets && nestedBuckets.length > 0) {
        nestedBuckets.forEach((item: any) => {
            filterFieldsArr.push({
                name: item.fieldName || '',
                value: item.fieldValue,
            });
        });
    }
    const extractedItem = item?.value ? extractItemValue(item.value) : null;

    let freeText = '';
    let dateText = '&time={"time":"All"}';
    const freeTextFilter = filterState.find((item) => item.name === 'free-text');
    const datePickerFilter = filterState.find((item) => item.name === 'date-picker');

    if (freeTextFilter && freeTextFilter.value) {
        freeText = `,"freeTextPhrase": "${freeTextFilter.value}"`;
    } else if (extractedItem && extractedItem.value) {
        freeText = `,"freeTextPhrase": "${extractedItem.value}"`;
    }
    if (widget.aggregation === 'ruleName') {
        filterFieldsArr.push({
            name: 'ruleName',
            value: item.ruleName,
        });
    }
    if (datePickerFilter && datePickerFilter.value && datePickerFilter.value.key) {
        if (datePickerFilter.value.key === 'Custom') {
            const from = Number(new Date(datePickerFilter.value.epoch.from));
            const to = Number(new Date(datePickerFilter.value.epoch.to));
            dateText = `&time={"time":{"Custom":{"from":${from},"to":${to}}}}`;
        } else {
            dateText = `&time={"time":"${datePickerFilter.value.key}"}`;
        }
    }
    const filteredFilterFieldsArr = getUniqueFields(
        filterFieldsArr.filter((item) => item.name && (item.value || item.value === 0)),
    );
    const filterFieldsStr = JSON.stringify(filteredFilterFieldsArr);
    const eventsUrl = getEventsTableWidgetService().getMainTableLink(
        EventType.ALL,
        `{"sorting":{"fieldName":"createdTime","direction":-1},"filter":{"fields":${filterFieldsStr}${freeText}}}${dateText}`,
    );
    return eventsUrl;
};

export const getActionDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const actionName = ['Detect', 'Prevent'];

    const items = selectedAggregation?.map((item) => {
        return {
            key: actionName[item.value] || i18n.t('EVENTS.NOT_AVAILABLE'),
            value: item.count,
            icon: defaultIcon,
            onclick: () => {
                changeUrl(buildAlertsWidgetLink(widget, item));
            },
        };
    });

    return {
        items: widget.limit && limitDataItems(items, widget.limit),
    };
};

export const getAlertSummaryData = (response: IFindingsResponse, widget: IDashboardWidget) => {
    const totalFindingsCount = response.totalFindingsCount;
    const alertsIcon = 'events';
    const defaultThresholds = [
        {
            color: '#000000',
            min: 0,
            max: 1,
        },
        {
            color: '#56b40a',
            min: 2,
            max: false,
        },
    ];
    const getColorByThresholds = () => {
        const thresholds = widget.options?.thresholds || defaultThresholds;

        if (thresholds && thresholds.length === 2) {
            const thresholdsLessThanNumber = thresholds[1].min;
            const thresholdsMoreThanColor = thresholds[0].color;
            const thresholdsLessThanColor = thresholds[1].color;

            if (totalFindingsCount > thresholdsLessThanNumber - 1) {
                return thresholdsLessThanColor;
            } else {
                return thresholdsMoreThanColor;
            }
        } else {
            return defaultThresholds;
        }
    };

    return {
        items: [
            {
                key: widget.description,
                value: totalFindingsCount,
                color: getColorByThresholds(),
                icon: alertsIcon,
                onclick: () => changeUrl(buildAlertsWidgetLink(widget, {})),
            },
        ],
    };
};

export const getAlertsTypeDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const items = selectedAggregation?.map((item) => {
        return {
            key:
                item.value === 0
                    ? i18n.t('EVENTS.ALERT_TYPE.THREAT_AND_SECURITY')
                    : i18n.t('EVENTS.ALERT_TYPE.POSTURE_FINDINGS'),
            value: item.count,
            icon: defaultIcon,
            onclick: () => changeUrl(buildAlertsWidgetLink(widget, item)),
        };
    });

    return {
        items: widget.limit && limitDataItems(items, widget.limit),
    };
};

export const getPlatformDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const items = selectedAggregation?.map((platform) => {
        const vendorData = VENDORS.find(
            (item) =>
                item.elasticVendorType === platform.value ||
                item.name === platform.value ||
                item.uri === platform.value,
        ) as IVendor;
        return {
            key: vendorData.displayName || vendorData.name || i18n.t('EVENTS.NOT_AVAILABLE'),
            value: platform.count,
            icon: vendorData.newIcon || defaultIcon,
            onclick: () => changeUrl(buildAlertsWidgetLink(widget, platform)),
        };
    });

    return {
        items: widget.limit && limitDataItems(items, widget.limit),
    };
};

export const getRegionDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const items = selectedAggregation?.map((item) => {
        return {
            key: item.value || i18n.t('EVENTS.GLOBAL'),
            value: item.count,
            icon: defaultIcon,
            onclick: () => changeUrl(buildAlertsWidgetLink(widget, item)),
        };
    });

    return {
        items: widget.limit && limitDataItems(items, widget.limit),
    };
};

export const getAssigneeDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const items = selectedAggregation?.map((item) => {
        return {
            key: item.value || i18n.t('EVENTS.NOT_AVAILABLE'),
            value: item.count,
            icon: defaultIcon,
            onclick: () => changeUrl(buildAlertsWidgetLink(widget, item)),
        };
    });

    return {
        items: widget.limit && limitDataItems(items, widget.limit),
    };
};

export const getCategoryDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const items = selectedAggregation?.map((item) => {
        return {
            key: item.value || i18n.t('EVENTS.NOT_AVAILABLE'),
            value: item.count,
            icon: defaultIcon,
            onclick: () => changeUrl(buildAlertsWidgetLink(widget, item)),
        };
    });

    return {
        items: widget.limit && limitDataItems(items, widget.limit),
    };
};

export const getEntityData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const entities = selectedAggregation?.map((entity) => {
        const entityAsArr = entity.value.split('|');
        const entityName = entityAsArr.length >= 4 ? `${entityAsArr[3]} (${entityAsArr[2]})` : '';
        const entityType = entityAsArr.length >= 4 ? entityAsArr[1] : '';
        const platformNumber = entityAsArr.length >= 4 ? entityAsArr[0] : '';
        const vendorData = VENDORS.find((item) => item.elasticVendorType === platformNumber);
        const typeByPlatform = `${vendorData?.name}|${entityType}`;
        const assetData = getProtectedAssetsService().getAssetByType(typeByPlatform);
        return {
            key: entityName || entity.value,
            value: entity.count,
            icon: assetData?.icon || defaultIcon,
            onclick: () => changeUrl(buildAlertsWidgetLink(widget, entity)),
        };
    });

    return {
        items: widget.limit && limitDataItems(entities, widget.limit),
    };
};

export const getEntityTypeDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const items = selectedAggregation?.map((item) => {
        return {
            key: item.displayName || item.value || i18n.t('EVENTS.NOT_AVAILABLE'),
            value: item.count,
            icon: defaultIcon,
            onclick: () => changeUrl(buildAlertsWidgetLink(widget, item)),
        };
    });

    return {
        items: widget.limit && limitDataItems(items, widget.limit),
    };
};

export const getEnvironmentDisplayData = async (
    selectedAggregation: IAggregationDataItem[],
    widget: IDashboardWidget,
) => {
    const cloudAccounts = await getCloudAccountsService().getAllCloudAccounts(true);
    const items = selectedAggregation.map((environment) => {
        const environmentAsArr = environment.value.split('|');
        const environmentId = environmentAsArr.length >= 2 ? environmentAsArr[1] : '';
        const filteredAccount = cloudAccounts.filter((account: any) => account.id === environmentId);
        const vendor = getVendor(filteredAccount[0].platform);
        return {
            key: filteredAccount[0].name || i18n.t('EVENTS.NOT_AVAILABLE'),
            value: environment.count,
            icon: vendor?.newIcon || defaultIcon,
            onclick: () => changeUrl(buildAlertsWidgetLink(widget, environment)),
        };
    });

    return {
        items: widget.limit && limitDataItems(items, widget.limit),
    };
};

// selectedAggregation is aggregation of cloudAccountId, so we need to get the OU of each cloudAccountId and aggregate by OU
export const getOrganizationalUnitDisplayData = async (
    selectedAggregation: IAggregationDataItem[],
    widget: IDashboardWidget,
) => {
    const indexedCloudAccounts = await getCloudAccountsService().getIndexedCloudAccounts();

    const flatOUs = await getOrganizationalUnitService().getAllOrganizationalUnitsFlatWithAllChildrens(true);
    const indexedOUs = flatOUs.reduce<{ [ouId: string]: IOrganizationalUnitAllChildrens }>((result, ou) => {
        result[ou.id] = ou;
        return result;
    }, {});

    const aggregatedByOU = selectedAggregation.reduce<{ [ouId: string]: IAggregationDataItem }>((result, aggItem) => {
        const cloudAccount = indexedCloudAccounts[aggItem.value.split('|')[1]];
        if (cloudAccount) {
            const ouId = cloudAccount.organizationalUnitId;
            if (result[ouId]) {
                result[ouId].count += aggItem.count;
            } else {
                result[ouId] = {
                    count: aggItem.count,
                    value: ouId,
                };
            }
        }
        return result;
    }, {});

    const items: IGenericWidgetDataItem[] = Object.values(aggregatedByOU)
        .sort((a, b) => {
            return b.count - a.count;
        })
        .map((aggItem) => {
            return {
                key: indexedOUs[aggItem.value]?.name || aggItem.value || 'N/A',
                value: aggItem.count,
                onclick: () => changeUrl(buildAlertsWidgetLink(widget, aggItem)),
            };
        });

    return {
        items: widget.limit && limitDataItems(items, widget.limit),
    };
};

export const getLabelsDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const items = selectedAggregation?.map((item) => {
        return {
            key: item.value || i18n.t('EVENTS.NOT_AVAILABLE'),
            value: item.count,
            icon: defaultIcon,
            onclick: () => changeUrl(buildAlertsWidgetLink(widget, item)),
        };
    });

    return {
        items: widget.limit && limitDataItems(items, widget.limit),
    };
};

export const getRuleDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const items = selectedAggregation?.map((item) => {
        return {
            key: item.value || i18n.t('EVENTS.NOT_AVAILABLE'),
            value: item.count,
            icon: defaultIcon,
            onclick: () => changeUrl(buildAlertsWidgetLink(widget, item)),
        };
    });

    return {
        items: widget.limit && limitDataItems(items, widget.limit),
    };
};

export const getRulesetDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const items = selectedAggregation?.map((item) => {
        return {
            key: item.value || i18n.t('EVENTS.NOT_AVAILABLE'),
            value: item.count,
            icon: defaultIcon,
            onclick: () => changeUrl(buildAlertsWidgetLink(widget, item)),
        };
    });

    return {
        items: widget.limit && limitDataItems(items, widget.limit),
    };
};

export const getSeverityDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const severityOrder = [
        FindingSeverityEnum.critical,
        FindingSeverityEnum.high,
        FindingSeverityEnum.medium,
        FindingSeverityEnum.low,
        FindingSeverityEnum.informational,
    ];
    const items = selectedAggregation?.map((item) => {
        return {
            key: item.value || i18n.t('EVENTS.NOT_AVAILABLE'),
            value: item.count,
            icon: defaultIcon,
            color: getSafeFindingSeverityInfo(item.value).color,
            onclick: () => changeUrl(buildAlertsWidgetLink(widget, item)),
        };
    });

    const sortedItems = items?.sort((a, b) => {
        return severityOrder.indexOf(a.key.toLowerCase()) - severityOrder.indexOf(b.key.toLowerCase());
    });

    return {
        items: widget.limit && limitDataItems(sortedItems, widget.limit),
    };
};

export const getSourceDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const items = selectedAggregation?.map((item) => {
        const originString = findingModelOriginCodeToString(item.value);
        return {
            key: i18n.t(`TABLE_FILTERS.ORIGIN.OPTIONS.${originString}`, {
                ns: getEventsTableNamespace('table'),
                defaultValue: item.value,
            }),
            value: item.count,
            icon: defaultIcon,
            onclick: () => changeUrl(buildAlertsWidgetLink(widget, item)),
        };
    });

    return {
        items: widget.limit && limitDataItems(items, widget.limit),
    };
};

export const getLatestAlertsData = async (response: any, widget: IDashboardWidget, aggregationType: string) => {
    const orderedByDateFindings: any[] = orderBy(response?.findings, ['createdTime'], ['asc']);
    const cloudAccounts = await getCloudAccountsService().getAllCloudAccounts(true);
    const organizationalUnits = await getOrganizationalUnitService().getAllOrganizationalUnitsFlatWithPath(true);
    const items = orderedByDateFindings?.map((item) => {
        const getLatestData = getLatestFindings(item, aggregationType, cloudAccounts, organizationalUnits);
        return {
            key: getLatestData.displayName || i18n.t('EVENTS.NOT_AVAILABLE'),
            value: dayjs(item.createdTime).format('MMMM D, YYYY'),
            icon: getLatestData.iconClass || defaultIcon,
            onclick: () => changeUrl(buildAlertsWidgetLink(widget, item)),
        };
    });

    return {
        items: widget.limit && limitDataItems(items, widget.limit),
    };
};

export const eventsStackedDataGetter = (
    response: IGroupByDataItem[],
    widget: IDashboardWidget,
): IGenericWidgetDataSet => {
    const limit = widget.limit || 10;
    const severityRanking = {
        Critical: 0,
        High: 1,
        Medium: 2,
        Low: 3,
        Informational: 4,
    };
    const nestedItemsEmptyArr = getFindingSeverities(SortOrder.descending, FindingSeverityEnum.informational).map(
        (severityInfo: IFindingSeverityInfo) => {
            return {
                key: severityInfo.serverKey,
                value: 0,
                color: severityInfo.color,
            };
        },
    );

    const getValues = (groupByItem: IGroupByDataItem) => {
        const nestedItemsArr = [...nestedItemsEmptyArr].map((nestedItem) => deepCloneObject(nestedItem));

        return groupByItem.nestedBuckets.reduce((result: IGenericWidgetDataItem[], nestedItem) => {
            const severityKey = nestedItem.fieldValue?.toLowerCase();
            const severityIndex = severityRanking[nestedItem.fieldValue as keyof typeof severityRanking];
            nestedItemsArr[severityIndex] = {
                key: nestedItem.fieldValue,
                value: nestedItem.numberOfDocuments,
                color: getSafeFindingSeverityInfo(severityKey).color,
                onclick: () => changeUrl(buildAlertsWidgetLink(widget, nestedItem)),
            };
            result = nestedItemsArr;
            return result;
        }, []);
    };

    const getKeys = (groupByItem: IGroupByDataItem) => {
        switch (groupByItem.fieldName) {
            case 'entityTypeByEnvironmentType': {
                const asset = getAssetByEntityTypeByEnvironmentType(groupByItem.fieldValue);
                return asset?.displayName || groupByItem.fieldValue;
            }
            default:
                return groupByItem.fieldValue;
        }
    };

    const items = response.reduce((result: IGenericWidgetDataItemSet[], groupByItem, index) => {
        if (index < limit) {
            result.push({
                key: getKeys(groupByItem),
                value: getValues(groupByItem),
                onclick: () => changeUrl(buildAlertsWidgetLink(widget, groupByItem, true)),
            });
        }
        return result;
    }, []);

    return {
        items: items,
        additionalInformation: response.length > limit ? { itemsCount: response.length } : {},
    };
};
