import React, { useCallback, useEffect, useState } from 'react';
import { Stack, Typography, Label, Card, Icon, Spinner } from 'common/design-system/components-v2';
import ContainersWorkloads from './containers/ContainersWorkloads';
import ContainersVulnerabilities from './containers/ContainersVulnerabilities';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import useVulnerabilityScanMetadata from 'modules/workloads/services/vulnerability/hooks/useVulnerabilityScanMetadata';
import useVulnerabilityScan from 'modules/workloads/services/vulnerability/hooks/useVulnerabilityScan';
import { getFieldByNameFromAdditionalField } from 'modules/workloads/utils';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { CardWrapper, ImageOverviewWrapper } from './ImageOverviewTab.styled';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import getFinding from 'modules/workloads/services/finding/finding.service';
import LayersCommand from './components/LayersCommand/LayersCommand';

const ImageOverview: React.FC<{ entity: ICloudEntityData }> = (props) => {
    const { t } = useTranslation(getK8sNamespace('image-overview'));
    const [isCompliant, setIsCompliant] = useState(false);
    const [isLoadingCompliant, setIsLoadingCompliant] = useState(false);
    const {
        entity: {
            cloudAccountId,
            protectedAsset: { additionalFields, entityId, type, vulnerability },
        },
    } = props;

    const { data, isLoading, error } = useVulnerabilityScanMetadata({
        entityId,
        entityType: type,
        environmentId: cloudAccountId,
    });
    const { data: vulData, isLoading: vulIsLoading } = useVulnerabilityScan({
        entityId,
        entityType: type,
        environmentId: cloudAccountId,
        remediationOnly: false,
    });

    const getScanByProducer = (scanProducer: string) => (scanProducer === 'Inspector' ? 'AWS Inspector' : 'CloudGuard');
    const scanStatusDetails =
        vulnerability?.scanStatusDetails ||
        getFieldByNameFromAdditionalField(additionalFields)('ScanStatusDetails')?.value;

    const setCompliantByFinding = useCallback(async () => {
        const data = {
            filter: {
                fields: [
                    { name: 'entityExternalId', value: entityId },
                    { name: 'cloudAccountId_calc', value: cloudAccountId },
                ],
            },
        };

        try {
            const {
                data: { findings },
            } = await getFinding(data);
            setIsLoadingCompliant(false);
            setIsCompliant(findings.length === 0);
        } catch (error) {
            setIsLoadingCompliant(false);
            setIsCompliant(false);
        }
    }, [cloudAccountId, entityId]);

    useEffect(() => {
        (async () => {
            if (error && !isLoading) {
                setIsCompliant(false);
                return;
            }
            setIsLoadingCompliant(true);
            setCompliantByFinding();
        })();
    }, [error, isLoading, setCompliantByFinding]);

    const isLayersEmpty = () => {
        if (!data?.layersDetails) return true;
        return data?.layersDetails || (data?.layersDetails && Object.keys(data?.layersDetails).length === 0);
    };

    return (
        <ImageOverviewWrapper spacing={6} direction={'row'}>
            <Stack style={{ flex: '0.4' }} fullWidth direction={'column'} spacing={6}>
                <Card title={'Vulnerabilities'}>
                    <ContainersVulnerabilities
                        isEmptyState={error}
                        entityId={entityId}
                        entityType={type}
                        environmentId={cloudAccountId}
                    />
                </Card>
                {!isLayersEmpty() ? (
                    <Card title={'Layers'}>
                        {isLoading || vulIsLoading ? (
                            <CloudAnimationLoader size={'small'} />
                        ) : data?.layersDetails && vulData ? (
                            <LayersCommand vulnerability={vulData} layers={data?.layersDetails} />
                        ) : (
                            <Stack margin={8} alignItems={'center'}>
                                <Typography variant={'bodyLg'}>No layers to display</Typography>
                            </Stack>
                        )}
                    </Card>
                ) : null}
                <Card title={t('moreInfoCard.title')}>
                    {isLoading ? (
                        <CloudAnimationLoader size={'medium'} />
                    ) : (
                        <Stack spacing={4}>
                            <Stack spacing={1}>
                                <Label text={t('moreInfoCard.imageCompliant.label')} />
                                <Stack spacing={2} direction={'row'}>
                                    {isLoadingCompliant ? (
                                        <Spinner size={16} />
                                    ) : (
                                        <>
                                            <Icon
                                                color={error ? 'normal' : isCompliant ? 'success' : 'alert'}
                                                name={error ? 'inactive' : isCompliant ? 'checkCircle' : 'error'}
                                            />
                                            {isCompliant && error ? (
                                                'N/A'
                                            ) : (
                                                <Typography variant={'body500'}>
                                                    {isCompliant
                                                        ? t('moreInfoCard.imageCompliant.compliance')
                                                        : t('moreInfoCard.imageCompliant.notCompliance')}
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                </Stack>
                            </Stack>
                            {scanStatusDetails && (
                                <Stack spacing={1}>
                                    <Label text={t('moreInfoCard.scanStatusDetails')} />
                                    <Typography variant={'body500'}> {scanStatusDetails || 'N/A'}</Typography>
                                </Stack>
                            )}
                            <Stack spacing={1}>
                                <Label text={t('moreInfoCard.scannedBy')} />
                                <Typography variant={'body500'}>
                                    {' '}
                                    {data?.scanInfo.scanProducer
                                        ? getScanByProducer(data?.scanInfo.scanProducer)
                                        : 'N/A'}
                                </Typography>
                            </Stack>
                            {data?.scannedAsset.operatingSystem && (
                                <Stack spacing={1}>
                                    <Label text={t('moreInfoCard.operatingSystem')} />
                                    <Typography variant={'body500'}>
                                        {data?.scannedAsset.operatingSystem || 'N/A'}
                                    </Typography>
                                </Stack>
                            )}
                        </Stack>
                    )}
                </Card>
            </Stack>
            <CardWrapper>
                <Card title={'Workloads'}>
                    <ContainersWorkloads imageId={''} digest={entityId} />
                </Card>
            </CardWrapper>
        </ImageOverviewWrapper>
    );
};

export default ImageOverview;
