import React from 'react';
import { Spinner } from 'common/design-system/components-v2';
import { EventAction, IEvent, SysEventType } from 'common/module_interface/events/Events';
import { EntityViewerProps, Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import { getSafeFindingSeverityInfo } from 'common/consts/FindingSeverity';
import EntityViewer from 'common/components/EntityViewer/EntityViewer';
import { LoaderWrapper } from '../helpers/Events.styled';
import Toolbar from '../EventDrawer/Components/Toolbar/Toolbar';
import { ComplianceEventsApi } from '../services/ComplianceEventsApi';
import { EventsDrawerAddingBase, EventsDrawerRegistry } from 'common/module_interface/events/EventsDrawerRegistry';
import Modals from '../Modals';
import { EventsModalOpenFrom } from 'common/module_interface/events/EventsModalsRegistry';
import { IChipProps } from 'common/design-system/components-v2/Chip/Chip.types';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { getNotificationsService, IWebappIframeServicePlacement } from 'common/interface/services';
import useEventsTable from '../EventsTable/hooks/useEventsTable';
import { getKustoEventService } from 'common/module_interface/intelligence/intelligence';
import { IEventDetailsDrawerParams } from 'common/module_interface/events/Events.interface';
import { closeDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { EVENTS_DRAWER_ID } from 'common/module_interface/events/EventsConsts';

interface EventDetailsDrawerProps {
    drawerParams: IEventDetailsDrawerParams;
    onDelete: (id: string) => void;
}

export const EventDetailsDrawer: React.FC<EventDetailsDrawerProps> = ({ drawerParams }) => {
    const { eventId, sysEventType } = drawerParams;
    const [options, setDropDownOptions] = React.useState<Array<EventAction>>([]);
    const [customOptions, setCustomOptions] = React.useState<Array<React.ReactNode>>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [event, setEvent] = React.useState<IEvent>();
    const [modalType, setModalType] = React.useState<string | null>(null);
    const [tabs, setTabs] = React.useState<Array<Tab>>([]);
    const [chips, setChips] = React.useState<Array<IChipProps>>([]);
    const [detailsPanelItems, setDetailsPanelItems] = React.useState<Array<BlockInfoProps>>([]);

    const { setDataValid } = useEventsTable();

    const fetchEvent = React.useCallback(
        async (eventId: string, useCache: boolean): Promise<IEvent> => {
            if (sysEventType === SysEventType.kusto) {
                return await getKustoEventService().getKustoEventById(eventId);
            }
            const eventsApi = new ComplianceEventsApi();
            return await eventsApi.getFindingDetails(eventId, useCache);
        },
        [sysEventType],
    );

    const getAndSetEvent = React.useCallback(
        async (eventId: string, useCache: boolean) => {
            setIsLoading(true);
            try {
                const newEvent: IEvent = await fetchEvent(eventId, useCache);
                setEvent(newEvent);
                setIsLoading(false);
            } catch (error: any) {
                setIsLoading(false);
                getNotificationsService().error(error?.message, '');
                closeDrawer();
            }
        },
        [fetchEvent],
    );

    const handleOnRequestNewData = React.useCallback(() => {
        getAndSetEvent(eventId, false);
        setDataValid(false);
    }, [eventId, getAndSetEvent, setDataValid]);

    React.useEffect(() => {
        getAndSetEvent(eventId, true);
    }, [getAndSetEvent, eventId]);

    React.useEffect(() => {
        if (!event) return;

        setTabs([]);
        setDropDownOptions([]);
        setCustomOptions([]);
        setChips([]);
        setDetailsPanelItems([]);

        const getDataFromAddins = async <T,>(
            addinsArray: Array<EventsDrawerAddingBase<T>>,
            setData: React.Dispatch<React.SetStateAction<Array<T>>>,
        ) => {
            for (let i = 0; i < addinsArray.length; i++) {
                const tab = addinsArray[i];
                const isRelevant = await Promise.resolve(tab.isRelevant ? tab.isRelevant(event) : true);
                if (!isRelevant) continue;
                const tabValue = await Promise.resolve(tab.getValue(event));
                setData((currentData) => [...currentData, tabValue]);
            }
        };

        const getAndSetTabsFromAddins = async () => {
            const tabsFromAddin = EventsDrawerRegistry.getTabs();
            getDataFromAddins(tabsFromAddin, setTabs);
        };

        const getAndSetActionsFromAddins = async () => {
            const actionsFromAddin = EventsDrawerRegistry.getActions();
            getDataFromAddins(actionsFromAddin, setDropDownOptions);
        };

        const getAndSetCustomActionsFromAddins = async () => {
            const actionsFromAddin = EventsDrawerRegistry.getCustomActions();
            getDataFromAddins(actionsFromAddin, setCustomOptions);
        };

        const getAndSetChipsFromAddins = async () => {
            const chipsFromAddin = EventsDrawerRegistry.getChips();
            getDataFromAddins(chipsFromAddin, setChips);
        };

        const getAndSetDetailsPanelItemsFromAddin = async () => {
            const detailsPanelItemsFromAddin = EventsDrawerRegistry.getDetailsPanelItems();
            getDataFromAddins(detailsPanelItemsFromAddin, setDetailsPanelItems);
        };

        getAndSetTabsFromAddins();
        getAndSetActionsFromAddins();
        getAndSetCustomActionsFromAddins();
        getAndSetChipsFromAddins();
        getAndSetDetailsPanelItemsFromAddin();
    }, [event]);

    const eventInfoProps: EntityViewerProps | undefined = React.useMemo(() => {
        if (!event) return undefined;
        const severityInfo = getSafeFindingSeverityInfo(event.severity);
        const props: EntityViewerProps = {
            title: event.ruleName || '',
            levelIcon: {
                iconProps: {
                    name: 'securityEvent',
                },
                context: severityInfo.context,
            },

            titleRightElements: (
                <Toolbar
                    entityData={event}
                    options={options}
                    customOptions={customOptions}
                    actionClick={(action) => {
                        action.callback(event);
                    }}
                />
            ),
            chips: chips,
            details: detailsPanelItems,
            tabs,
            isLoading: false,
            context: IWebappIframeServicePlacement.DRAWER,
            dataAid: 'event-details-drawer',
            ignoreUrlParams: true,
        };
        return props;
    }, [chips, tabs, event, options, customOptions, detailsPanelItems]);

    if (isLoading || !eventInfoProps || !event) {
        return (
            <LoaderWrapper>
                <Spinner size={48} context='info' saturation />
            </LoaderWrapper>
        );
    }

    const onCloseModal = () => {
        if (modalType) {
            setModalType(null);
        }
    };

    return (
        <>
            <EntityViewer {...eventInfoProps} />
            <Modals
                tableId={EVENTS_DRAWER_ID}
                openFrom={EventsModalOpenFrom.DRAWER}
                modalType={modalType}
                closeModal={() => onCloseModal()}
                setModalType={setModalType}
                selectedRows={[event]}
                requestNewData={handleOnRequestNewData}
                resetSelectedRows={() => null}
            />
        </>
    );
};
