import React, { useEffect, useState } from 'react';
import { EdgeLabelRenderer, EdgeProps, getBezierPath, useStore } from 'reactflow';
import { Popover } from '@dome9/berries/react-components';
import { A } from 'common/components/Anchor/Anchor';
import { EdgeData } from '../Models/EdgeData';
import { ExposureLevel } from '../Models/ExposureLevel';
import { useTranslation } from 'react-i18next';
import { insightNamespace } from '../../consts/i18n';
import ExposureEdgePopoverComponent from './ExposureEdgePopoverComponent';
import { fontSelector } from '../Services/fontHandler';

const ExposureEdge: React.FC<EdgeProps> = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data,
    style,
    markerEnd,
}) => {
    const { t } = useTranslation(insightNamespace);
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const edgeData = data as EdgeData;
    const [label, setLabel] = useState<string | null>(null);
    useEffect(() => {
        if (!edgeData?.securityGroupConfigurations?.length) return;
        edgeData.securityGroupConfigurations
            .sort((c1, c2) =>
                c1.portRange.from > c2.portRange.from ? 1 : c1.portRange.from < c2.portRange.from ? -1 : 0,
            )
            .sort((c1, c2) => {
                if (c2.exposureLevel === ExposureLevel.fullyPublic) return 1;
                if (
                    c2.exposureLevel === ExposureLevel.partiallyPublic &&
                    c1.exposureLevel === ExposureLevel.fullyPublic
                )
                    return -1;
                if (
                    c2.exposureLevel === ExposureLevel.private &&
                    (c1.exposureLevel === ExposureLevel.fullyPublic ||
                        c1.exposureLevel === ExposureLevel.partiallyPublic)
                )
                    return -1;
                return 0;
            });

        let l = `Port${edgeData.securityGroupConfigurations.length > 1 ? 's' : ''}: ${buildPort(0)}`;
        if (edgeData.securityGroupConfigurations.length > 1) {
            l += `, ${buildPort(1)}`;
        }
        if (edgeData.securityGroupConfigurations.length > 2) {
            l += '...';
        }

        setLabel(l);

        function buildPort(index: number): string {
            return `${edgeData.securityGroupConfigurations?.[index].portRange.from}${edgeData.securityGroupConfigurations?.[index].portRange.to ? ` - ${edgeData.securityGroupConfigurations?.[index].portRange.to}` : ''}`;
        }
    }, [edgeData?.securityGroupConfigurations]);
    const fontSize = useStore(fontSelector);

    return (
        <g style={style}>
            <defs>
                <mask id={id}>
                    <rect x='0' y='0' fill='white' height='10000' width='10000' transform='translate(-1000,-1000)' />
                    <polygon
                        className={'react-flow__edge-fat-arrow'}
                        height='10'
                        width='10'
                        transform='translate(-8,-8)'
                        points='12,14 0,14 4,8 0,2 12,2 16,8 '
                        fill='black'
                        style={{
                            offsetPath: `path('${edgePath}')`,
                        }}
                    />
                </mask>
                <linearGradient id={`gradient-${id}`} x2='1' y2='1' gradientTransform='rotate(-45)'>
                    <stop className='edge-stop--start' offset='10%'></stop>
                    <stop className='edge-stop--end' offset='60%'></stop>
                </linearGradient>
                <marker
                    className='edge-stop--arrow'
                    id={`marker-end-${id}`}
                    viewBox='0 0 40 40'
                    markerHeight={8}
                    markerWidth={8}
                    refX={11}
                    refY={8}
                >
                    <path d='M2.1,15c-1,0.5-2.1-0.2-2.1-1.3V8V2.3C0,1.3,1.1,0.6,2.1,1l11.3,5.7c1.1,0.5,1.1,2.1,0,2.6L2.1,15z' />
                </marker>
            </defs>

            <path
                id={id}
                className='react-flow__custom-edge-path'
                stroke={`url(#gradient-${id}) rgb(0,85,204)`}
                d={edgePath}
                markerEnd={markerEnd}
            />
            {label && (
                <EdgeLabelRenderer>
                    <Popover
                        content={ExposureEdgePopoverComponent(edgeData, t)}
                        interactive={true}
                        appendTo={() => document.body}
                        delay={200}
                        maxWidth={400}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                                background: `linear-gradient(45deg, ${edgeData.sourceRiskColor} 10%, ${edgeData.targetRiskColor} 60%)`,
                                padding: '0 10px',
                                borderRadius: 10,
                                fontSize: fontSize,
                                color: 'white',
                                fontWeight: 500,
                                maxWidth: 120,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {edgeData?.entity?.gotoUrl && (
                                <A href={edgeData?.entity?.gotoUrl} className={'edge-link'}>
                                    {label}
                                </A>
                            )}
                            {!edgeData?.entity?.gotoUrl && label}
                        </div>
                    </Popover>
                </EdgeLabelRenderer>
            )}
        </g>
    );
};
export default ExposureEdge;
