import {
    getHttpService,
    IHttpServiceConfig,
    IIntegrationsService,
    ISystemSnsConfiguration,
} from 'common/interface/services';
import moment from 'moment-mini';
import { IGetAllConfigurationsModel } from 'common/module_interface/settings/integrations/Integrations';
import { ITestFormatTypeKey } from 'common/module_interface/settings/integrations/consts';
import { ITenableConfiguration } from 'common/module_interface/settings/integrations/configurations.interface';
import { IntegrationsRegistry } from 'common/module_interface/settings/integrations/IntegrationsRegistry';
import { IOrganizationalUnit } from 'common/interface/data_services';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';

const GET_MULTI_INTEGRATIONS_CACHE_TAG = 'GET_MULTI_INTEGRATIONS_CACHE_TAG';
const DATA_AGE_LIMIT = 3 * 60; // After 3 minutes the cached data becomes stale
const getMultiIntegrationsCacheService = (): IHttpServiceConfig => ({
    cachingConfig: {
        useCache: true,
        tags: [GET_MULTI_INTEGRATIONS_CACHE_TAG],
        dataAgeLimit: DATA_AGE_LIMIT,
    },
});

export class IntegrationsService implements IIntegrationsService {
    private clearMultiIntegrationsCache() {
        getHttpService().clearCacheByTag(GET_MULTI_INTEGRATIONS_CACHE_TAG);
    }

    async saveTenableConfiguration(name: string, accessKey: string, secretKey: string) {
        return await getHttpService().request<any>(
            'tenableAccount',
            { method: 'POST', data: { name: name, accessKey: accessKey, secretKey: secretKey } },
            {},
            (error) => {
                return {
                    message: error?.response?.data,
                    isError: true,
                };
            },
        );
    }

    async validateTenableConfiguration(tenableExternalAccountNumber: string) {
        return await getHttpService().post<any>(`tenableAccount/${tenableExternalAccountNumber}/validate`);
    }

    async deleteTenableConfiguration(tenableExternalAccountNumber: string) {
        return await getHttpService().delete<any>('tenableAccount', {
            data: { tenableExternalAccountNumber: tenableExternalAccountNumber },
        });
    }

    async getTenableConfiguration(): Promise<ITenableConfiguration | null> {
        try {
            const tenableConfigurationFromServer = await getHttpService().request<any>(
                'tenableAccount',
                {},
                {},
                (error) => {
                    console.log(error);
                },
            );
            if (!tenableConfigurationFromServer) {
                return null;
            }
            return {
                name: tenableConfigurationFromServer.name,
                configurationObj: {
                    tenableExternalAccountNumber: tenableConfigurationFromServer.tenableExternalAccountNumber,
                    accessKey: tenableConfigurationFromServer.accessKey,
                    secretKey: tenableConfigurationFromServer.secretKey,
                    status: tenableConfigurationFromServer.status,
                    lastMatchedAssetsCount: tenableConfigurationFromServer.lastMatchedAssetsCount,
                    lastMatchedVulnerabilitiesCount: tenableConfigurationFromServer.lastMatchedVulnerabilitiesCount,
                    lastAssetSync: tenableConfigurationFromServer.lastAssetSync
                        ? moment(tenableConfigurationFromServer.lastAssetSync).format('L LT')
                        : null,
                    lastVulnerabilitySync: tenableConfigurationFromServer.lastVulnerabilitySync
                        ? moment(tenableConfigurationFromServer.lastVulnerabilitySync).format('L LT')
                        : null,
                },
            };
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async getSystemSnsConfiguration(): Promise<ISystemSnsConfiguration[] | null> {
        try {
            const snsConfigurationFromServer = await getHttpService().request<any>('settings', {}, {}, (error) => {
                console.log(error);
            });
            if (!snsConfigurationFromServer) {
                return null;
            }
            if (snsConfigurationFromServer?.snsTopicArn) {
                return [
                    {
                        name: 'snsConfiguration',
                        configurationObj: {
                            snsTopicArn: snsConfigurationFromServer?.snsTopicArn,
                        },
                    },
                ];
            } else {
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }
    async postSystemSnsConfiguration(input: string) {
        return new Promise((resolve, reject) => {
            getHttpService()
                .request<any>(
                    'settings/sns',
                    {
                        method: 'POST',
                        data: { TopicArn: input },
                    },
                    {},
                    (error) => {
                        reject(error?.response?.data || error?.message || 'server error');
                    },
                )
                .then((resp) => {
                    if (!resp) {
                        resolve('success');
                    } else {
                        reject(resp);
                    }
                });
        });
    }
    async deleteSystemSnsConfiguration() {
        return new Promise((resolve, reject) => {
            getHttpService()
                .request<any>(
                    'settings/sns',
                    {
                        method: 'DELETE',
                    },
                    {},
                    (error) => {
                        reject(error?.response?.data || error?.message || 'server error');
                    },
                )
                .then((resp) => {
                    if (!resp) {
                        resolve('success');
                    } else {
                        reject(resp);
                    }
                });
        });
    }

    async testSnsService(topicArn: string): Promise<string> {
        return new Promise((resolve, reject) => {
            getHttpService()
                .request<any>(
                    'Compliance/ContinuousComplianceNotification/SnsTest',
                    {
                        method: 'POST',
                        data: {
                            arn: topicArn,
                        },
                    },
                    {},
                    (error) => {
                        reject(error?.response?.data?.errors?.Arn[0] || 'Test failed');
                    },
                )
                .then(() => {
                    resolve('Please verify that the test message was received successfully');
                });
        });
    }

    async testGenericWebhookService(
        endpointUrl: string,
        authType: string,
        userName: string,
        password: string,
        ignoreCertificateValidation: boolean,
        testFormatType?: ITestFormatTypeKey,
    ) {
        const AuthenticationType: any = {
            Basic: 'BasicAuth',
            'No Authentication': 'noAuth',
        };
        return new Promise((resolve, reject) => {
            getHttpService()
                .request<any>(
                    'Compliance/ContinuousComplianceNotification/testWebhook',
                    {
                        method: 'POST',
                        data: {
                            url: endpointUrl,
                            advancedUrl: false,
                            payloadFormat: null,
                            formatType: testFormatType,
                            httpMethod: 'Get',
                            ignoreCertificate: ignoreCertificateValidation,
                            authMethod: AuthenticationType[authType],
                            ...(userName && { username: userName }),
                            ...(password && { password: password }),
                        },
                    },
                    {},
                    (error) => {
                        reject('HTTP Endpoint test failed. '.concat(error?.response?.data?.message || ''));
                    },
                )
                .then((resp) => {
                    if (resp) {
                        resolve('HTTP Endpoint test succeeded');
                    }
                });
        });
    }

    async getAllConfigurations(useCache?: boolean): Promise<IGetAllConfigurationsModel> {
        return new Promise((resolve, reject) => {
            getHttpService()
                .request<any>(
                    'integration',
                    {
                        method: 'GET',
                    },
                    useCache ? getMultiIntegrationsCacheService() : {},
                    (error) => {
                        reject(error?.response?.data || error?.message || 'server error');
                    },
                )
                .then((resp) => {
                    if (resp) {
                        const configurations: { [key: string]: any } = {};
                        resp.forEach((config: any) => {
                            if (!configurations[config.type]) {
                                configurations[config.type] = [];
                            }
                            configurations[config.type].push({
                                name: config.name,
                                id: config.id,
                                configurationObj: config.configuration,
                                createdAt: config.createdAt,
                            });
                        });
                        resolve(configurations);
                    } else {
                        reject(resp);
                    }
                });
        });
    }

    async getConfigurationById(configurationId: string): Promise<IGetAllConfigurationsModel> {
        return getHttpService().request<any>(`integration/${configurationId}`, {
            method: 'GET',
        });
    }

    async getAllConfigurationsSlim(useCache?: boolean): Promise<IGetAllConfigurationsModel> {
        return new Promise((resolve, reject) => {
            getHttpService()
                .request<any>(
                    'integration/slim',
                    {
                        method: 'GET',
                    },
                    useCache ? getMultiIntegrationsCacheService() : {},
                    (error) => {
                        reject(error?.response?.data || error?.message || 'server error');
                    },
                )
                .then((resp) => {
                    if (resp) {
                        const configurations: { [key: string]: any } = {};
                        resp.forEach((config: any) => {
                            if (!configurations[config.type]) {
                                configurations[config.type] = [];
                            }
                            configurations[config.type].push({
                                name: config.name,
                                id: config.id,
                                configurationObj: config.configuration,
                                createdAt: config.createdAt,
                            });
                        });
                        resolve(configurations);
                    } else {
                        reject(resp);
                    }
                });
        });
    }

    async saveConfiguration(name: string, type: string, configurationObj: any, id?: string) {
        this.clearMultiIntegrationsCache();
        return new Promise((resolve, reject) => {
            const requestObj = !id
                ? {
                      name: name,
                      type: type,
                      configuration: configurationObj,
                  }
                : {
                      name: name,
                      id: id,
                      type: type,
                      configuration: configurationObj,
                  };
            getHttpService()
                .request<any>(
                    'integration',
                    {
                        method: !id ? 'POST' : 'PUT',
                        data: requestObj,
                    },
                    {},
                    (error) => {
                        reject(error?.response?.data || error?.message || 'server error');
                    },
                )
                .then((resp) => {
                    resolve(resp);
                });
        });
    }

    async deleteConfiguration(configurationId: string) {
        this.clearMultiIntegrationsCache();
        return await getHttpService().delete<any>(`integration/${configurationId}`);
    }

    async getAzureCloudAccounts(): Promise<any> {
        return new Promise((resolve, reject) => {
            getHttpService()
                .request<any>(
                    'AzureCloudAccount',
                    {
                        method: 'GET',
                    },
                    {},
                    (error) => {
                        reject(error?.response?.data || error?.message || 'server error');
                    },
                )
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        resolve(['No Azure Cloud Accounts found']);
                    }
                });
        });
    }

    async getGcpCloudAccounts(): Promise<any> {
        return new Promise((resolve, reject) => {
            getHttpService()
                .request<any>(
                    'GoogleCloudAccount',
                    {
                        method: 'GET',
                    },
                    {},
                    (error) => {
                        reject(error?.response?.data || error?.message || 'server error');
                    },
                )
                .then((resp) => {
                    resp && resolve(resp);
                });
        });
    }

    async getSingleGcpCloudAccount(accountId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            getHttpService()
                .request<any>(
                    `GoogleCloudAccount/${accountId}/details`,
                    {
                        method: 'GET',
                    },
                    {},
                    (error) => {
                        reject(error?.response?.data || error?.message || 'server error');
                    },
                )
                .then((resp) => {
                    resp && resolve(resp);
                });
        });
    }

    async testGcpCloudSecuritySource(CloudSecuritySourceConfig: string, accountId?: string): Promise<any> {
        return new Promise((resolve, reject) => {
            getHttpService()
                .request<any>(
                    `Compliance/ContinuousComplianceNotification/cscc/test?cloudAccountId=${accountId}&sourceId=${CloudSecuritySourceConfig}`,
                    {
                        method: 'GET',
                    },
                    {},
                    (error) => {
                        reject(error?.response ? 'Source is not permitted' : 'server error');
                    },
                )
                .then((resp) => {
                    resolve(resp);
                });
        });
    }

    async getAwsCloudAccount(): Promise<any> {
        return new Promise((resolve, reject) => {
            getHttpService()
                .request<any>(
                    'cloudaccounts',
                    {
                        method: 'GET',
                    },
                    {},
                    (error) => {
                        reject(error);
                    },
                )
                .then((resp) => {
                    resp && resolve(resp);
                });
        });
    }

    async testSecurityHub(cloudAccountId: string, region: string) {
        interface RequestObj {
            includeAllAccounts?: boolean;
            cloudAccountId?: string;
            includeAllRegions?: boolean;
            region?: string;
        }
        return new Promise((resolve, reject) => {
            const requestObj: RequestObj = { includeAllAccounts: false, includeAllRegions: false };
            cloudAccountId ? (requestObj.cloudAccountId = cloudAccountId) : (requestObj.includeAllAccounts = true);
            region ? (requestObj.region = region) : (requestObj.includeAllRegions = true);
            getHttpService()
                .request<any>(
                    'Compliance/ContinuousComplianceNotification/securityHub/test',
                    {
                        method: 'POST',
                        data: requestObj,
                    },
                    {},
                    (error) => {
                        reject(error);
                    },
                )
                .then((resp) => {
                    resp && resolve(resp);
                });
        });
    }

    async awsAccountRegion(): Promise<any> {
        return new Promise((resolve, reject) => {
            getHttpService()
                .request<any>(
                    'cloudAccounts/vendors',
                    {
                        method: 'GET',
                    },
                    {},
                    (error) => {
                        reject(error);
                    },
                )
                .then((resp) => {
                    resp && resolve(resp);
                });
        });
    }

    async getInUseConfigurations(): Promise<any> {
        return new Promise((resolve, reject) => {
            getHttpService()
                .request<any>(
                    'integration/in-use',
                    {
                        method: 'GET',
                    },
                    {},
                    (error) => {
                        reject(error);
                    },
                )
                .then((resp) => {
                    resp && resolve(resp);
                });
        });
    }
    async getRegisteredConfigurationsData() {
        const allRegisteredConfigurations = IntegrationsRegistry.getConfigurationsDataLoaders();
        const registeredConfigurationsData: IGetAllConfigurationsModel = {};
        const fetchDataPromises = allRegisteredConfigurations.map(async (config: any) => {
            return config.content.getData().then((data: any) => {
                const id = config.id;
                registeredConfigurationsData[id] = data;
                return { id, data };
            });
        });
        await Promise.all(fetchDataPromises);
        return registeredConfigurationsData;
    }

    async getFiltersInitialData(useCache?: boolean): Promise<any> {
        const currentDate = new Date().toISOString();
        const payload = {
            dataSource: 'Finding',
            aggregations: [],
            filter: {
                fields: [],
                creationTime: {
                    from: '1970-01-01T08:53:43.621Z',
                    to: currentDate,
                },
                filterFields: [],
            },
            pageSize: 50,
            searchAfter: [],
            skipAggregations: true,
        };
        return await getHttpService().request<any>(
            'Compliance/Finding/searchWithCustomAggregations',
            {
                method: 'POST',
                data: payload,
            },
            { cachingConfig: { useCache: !!useCache } },
        );
    }

    async getAllFindingsAggregations(useCache?: boolean): Promise<IKustoEvent> {
        return await getHttpService().request<any>(
            'Compliance/Finding/search',
            { method: 'POST', data: {} },
            { cachingConfig: { useCache: !!useCache } },
        );
    }

    async getOrganizationalUnits(useCache?: boolean): Promise<IOrganizationalUnit> {
        return await getHttpService().request<any>(
            'organizationalUnit/view',
            { method: 'GET' },
            { cachingConfig: { useCache: !!useCache } },
        );
    }
}
