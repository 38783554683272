// @ts-nocheck
import { getRuntimeEnvironmentType, RuntimeEnvironmentType } from 'common/utils/RuntimeEnvironment';
import ReactGA from 'react-ga4';

let googleAnalyticsEnabled = false;

export const setGoogleAnalytics = () => {
    const runtimeEnvironmentType = getRuntimeEnvironmentType();
    function isProd() {
        return runtimeEnvironmentType === RuntimeEnvironmentType.PRODUCTION;
    }
    if (isProd()) {
        googleAnalyticsEnabled = true;
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js',
            });
            const f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l !== 'dataLayer' ? '&l=' + l : '';

            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-W54WH245');

        const noscriptElement = document.createElement('noscript');
        const iframeElement = document.createElement('iframe');
        iframeElement.src = 'https://www.googletagmanager.com/ns.html?id=GTM-W54WH245';
        iframeElement.height = '0';
        iframeElement.width = '0';
        iframeElement.style.display = 'none';
        iframeElement.style.visibility = 'hidden';
        noscriptElement.appendChild(iframeElement);
        document.body.appendChild(noscriptElement);
    }
};

export const addGAEvent = (category: string, action: string) => {
    if (googleAnalyticsEnabled) {
        ReactGA.event({
            category: category,
            action: action,
        });
    }
};
