import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { I18nIntegrations } from 'common/module_interface/settings/integrations/consts';
import { Icon, Stack, Typography } from 'common/design-system/components-v2';
import { PrerequisitesStyled } from './Sentinel.styled';

interface ISentinelPrerequisitesProps {
    togglePrerequisites: () => void;
    prerequisitesISOpen: boolean;
}

export const SentinelPrerequisites: FC<ISentinelPrerequisitesProps> = ({
    togglePrerequisites,
    prerequisitesISOpen,
}) => {
    const { t } = useTranslation(I18nIntegrations);

    const prerequisites = [
        {
            title: t('CONFIGURATIONS.SENTINEL.PREREQUISITES.AZURE_SUBSCRIPTION'),
            bullets: [t('CONFIGURATIONS.SENTINEL.PREREQUISITES.AZURE_SUBSCRIPTION_BULLET_1')],
        },
        {
            title: t('CONFIGURATIONS.SENTINEL.PREREQUISITES.SENTINEL_WORKSPACE_SETUP'),
            bullets: [
                t('CONFIGURATIONS.SENTINEL.PREREQUISITES.SENTINEL_WORKSPACE_SETUP_BULLET_1'),
                t('CONFIGURATIONS.SENTINEL.PREREQUISITES.SENTINEL_WORKSPACE_SETUP_BULLET_2'),
            ],
        },
        {
            title: t('CONFIGURATIONS.SENTINEL.PREREQUISITES.AZURE_SENTINEL_PERMISSIONS'),
            bullets: [
                t('CONFIGURATIONS.SENTINEL.PREREQUISITES.PERMISSIONS_BULLET_3'),
                t('CONFIGURATIONS.SENTINEL.PREREQUISITES.PERMISSIONS_BULLET_4'),
            ],
        },
        {
            title: t('CONFIGURATIONS.SENTINEL.PREREQUISITES.DATA_COLLECTION_RULES'),
            bullets: [t('CONFIGURATIONS.SENTINEL.PREREQUISITES.DATA_COLLECTION_RULES_BULLET_1')],
        },
    ];

    return (
        <Stack data-aid={'prerequisites'}>
            <PrerequisitesStyled direction={'row'} alignItems={'center'} spacing={1} onClick={togglePrerequisites}>
                <Icon name={prerequisitesISOpen ? 'chevronDown' : 'chevronRight'} />
                <Typography variant={'sm'} color={'strong'}>
                    {t('CONFIGURATIONS.SENTINEL.PREREQUISITES.TOGGLE')}
                </Typography>
            </PrerequisitesStyled>
            {prerequisitesISOpen && (
                <Stack padding={[5, 5, 0, 5]} spacing={5}>
                    {prerequisites.map((prerequisite, index) => (
                        <Stack key={index} spacing={3}>
                            <Typography variant={'sm'} color={'strong'}>
                                {prerequisite.title}
                            </Typography>
                            <Stack>
                                {prerequisite.bullets.map((bullet, index) => (
                                    <li key={index}>{bullet}</li>
                                ))}
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
            )}
        </Stack>
    );
};
