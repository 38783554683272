import { ICdrExclusionProps } from './CdrExclusion.interface';
import { CdrExclusionItemNames } from './CdrExclusion.consts';
import {
    getTrimmedMultiValue,
    getTrimmedSingleValue,
    getSafeTrimmedSingleValue,
    convertValueToDate,
} from 'common/erm-components/custom/CustomForm/CustomForm.values';
import { getRulesOptionsByRuleset } from './CdrExclusion.options';
import {
    IMultiSelectState,
    IItemChanges,
    IMultiSelectItem,
    IItemsMap,
    ISingleDateItem,
    ISingleDateState,
} from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import i18n from 'i18next';

import { isPassedDate } from 'common/erm-components/utils/dates';
import { cdrTrans } from '../../../../Intelligence.utils';

export const getExclusionFromState = (itemsMap: IItemsMap): ICdrExclusionProps => {
    return {
        rulesetId: getSafeTrimmedSingleValue(itemsMap[CdrExclusionItemNames.rulesetId]),
        comment: getTrimmedSingleValue(itemsMap[CdrExclusionItemNames.comment]),
        orgUnitIds: getTrimmedMultiValue(itemsMap[CdrExclusionItemNames.orgUnits]),
        envIds: getTrimmedMultiValue(itemsMap[CdrExclusionItemNames.environments]),
        expirationDate: convertValueToDate(getTrimmedSingleValue(itemsMap[CdrExclusionItemNames.expirationDate])),
        ruleIds: getTrimmedMultiValue(itemsMap[CdrExclusionItemNames.rules]),
        entityNames: getTrimmedMultiValue(itemsMap[CdrExclusionItemNames.entityNames]),
        entityId: getTrimmedSingleValue(itemsMap[CdrExclusionItemNames.entityId]),
        severities: getTrimmedMultiValue(itemsMap[CdrExclusionItemNames.severities]),
        srcIpCidr: getTrimmedSingleValue(itemsMap[CdrExclusionItemNames.srcIpCidr]),
        srcSavedIp: getTrimmedSingleValue(itemsMap[CdrExclusionItemNames.srcSavedIp]),
        srcPort: getTrimmedSingleValue(itemsMap[CdrExclusionItemNames.srcPort]),
        destIpCidr: getTrimmedSingleValue(itemsMap[CdrExclusionItemNames.destIpCidr]),
        destSavedIp: getTrimmedSingleValue(itemsMap[CdrExclusionItemNames.destSavedIp]),
        destPort: getTrimmedSingleValue(itemsMap[CdrExclusionItemNames.destPort]),
    };
};

export const getCdrRulesAfterChange = async (
    item: IMultiSelectItem,
    changeDetails: IItemChanges,
): Promise<IMultiSelectState | undefined> => {
    if (changeDetails.newItem.name !== CdrExclusionItemNames.rulesetId) {
        return Promise.resolve(undefined);
    }

    const rulesetId: string | undefined = getTrimmedSingleValue(changeDetails.newItem);
    return {
        ...item.state,
        options: rulesetId ? await getRulesOptionsByRuleset(rulesetId) : [],
    };
};

export const getCdrExpirationDateLabel = (expired: boolean): string => {
    return cdrTrans('CDR_EXCLUSIONS.EDITOR.EXPIRATION_DATE', {
        suffix: expired ? i18n.t('COMMON.EXPIRED_SUFFIX') : '',
    });
};

export const getNewCdrExpirationDateState = (
    item: ISingleDateItem,
    changeDetails: IItemChanges,
    itemsMap: IItemsMap,
): Promise<ISingleDateState | undefined> => {
    if (changeDetails.newItem.name !== CdrExclusionItemNames.expirationDate) {
        return Promise.resolve(undefined);
    }

    const date: Date | undefined = convertValueToDate(
        getTrimmedSingleValue(itemsMap[CdrExclusionItemNames.expirationDate]),
    );
    const expired = isPassedDate(date);

    return Promise.resolve({
        ...item.state,
        label: getCdrExpirationDateLabel(expired),
        labelProps: { color: expired ? 'alert' : undefined },
    });
};
