import styled from 'styled-components';
import { Stack, Typography } from 'common/design-system/components-v2';

const ModalSectionTitle = styled(Typography)`
    display: flex;
    gap: 5px;
    align-items: baseline;
    font-size: small;
`;

const SpinnerWrapper = styled(Stack)`
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
`;

const TableWrapper = styled.div<{ blurData: boolean }>`
    filter: ${(prop) => (prop.blurData ? 'blur' : 'none')};
    flex-grow: 1;
`;
export { ModalSectionTitle, SpinnerWrapper, TableWrapper };
