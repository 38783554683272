import { ColumnEvent, ExpandCollapseAllEvent, RowGroupOpenedEvent } from 'ag-grid-community';
import { useState } from 'react';
import { ExpandState } from '../protectedAsset/page/tabs/Vulnerability/VulnerabilityTabs/Cves/Cves.types';

const useExpandOrCollapseAggrid = () => {
    const [expandState, setExpandState] = useState<ExpandState>({ maxLevel: 0, parents: 0, expandedParents: 0 });

    const onColumnRowGroupChanged = (event: ColumnEvent) => {
        event.api.forEachNode((node) => {
            event.api.setRowNodeExpanded(node, !node.level && node.rowIndex === 0);
        });
        setExpandState((prev) => ({ ...prev, maxLevel: event.columns?.length ?? 0 }));
    };

    const onExpandOrCollapseAll = (event: ExpandCollapseAllEvent) => {
        setExpandState((prev) => ({ ...prev, expandedParents: event.source === 'expandAll' ? prev.parents : 0 }));
    };

    const onRowGroupOpened = (event: RowGroupOpenedEvent) => {
        let nodes = 0;
        let expandedNodes = 0;
        event.api.forEachNode((node) => {
            if (!node.level) {
                nodes++;
                if (node.expanded) {
                    expandedNodes++;
                }
            }
        });
        setExpandState((prev) => ({ ...prev, parents: nodes, expandedParents: expandedNodes }));
    };

    return {
        expandState,
        setExpandState,
        onColumnRowGroupChanged,
        onExpandOrCollapseAll,
        onRowGroupOpened,
    };
};

export default useExpandOrCollapseAggrid;
