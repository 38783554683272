import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const ConfigurationWrapper = styled(Stack)`
    border: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.normal}`};
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
    padding: 20px;
    margin: 10px 0px;
    width: 100%;
    height: fit-content;
    gap: 20px;
`;

export { ConfigurationWrapper };
