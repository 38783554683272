import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'common/services/store/store';
import { OciOnboardingState } from './OciOnboarding.interface';
import * as yup from 'yup';
import { getValidationErrors } from 'common/components/Forms/FormValidations';
import { ociAccountValidations, ociOnBoardingValidations } from './OciOnboarding.validations';

const initialState: OciOnboardingState = {
    name: '',
    tenancyId: '',
    homeRegion: '',
    TenantAdministratorEmailAddress: '',
    userName: '',
    groupName: '',
    policyName: '',
    userOcid: '',
    organizationalUnitId: '00000000-0000-0000-0000-000000000000',
    isLoading: false,
};

export const OcionboardingSlice = createSlice({
    name: 'ociOnboarding',
    initialState,
    reducers: {
        setAccountName: (state: OciOnboardingState, action: PayloadAction<string>) => {
            state.name = action.payload;
        },
        setTenancyId: (state: OciOnboardingState, action: PayloadAction<string>) => {
            state.tenancyId = action.payload;
        },
        setHomeRegion: (state: OciOnboardingState, action: PayloadAction<string>) => {
            state.homeRegion = action.payload;
        },
        setUserEmailAddress: (state: OciOnboardingState, action: PayloadAction<string>) => {
            state.TenantAdministratorEmailAddress = action.payload;
        },
        setUserName: (state: OciOnboardingState, action: PayloadAction<string>) => {
            state.userName = action.payload;
        },
        setGroupName: (state: OciOnboardingState, action: PayloadAction<string>) => {
            state.groupName = action.payload;
        },
        setPolicyName: (state: OciOnboardingState, action: PayloadAction<string>) => {
            state.policyName = action.payload;
        },
        setOcidUser: (state: OciOnboardingState, action: PayloadAction<string>) => {
            state.userOcid = action.payload;
        },
        setOrganizationalUnitId: (state: OciOnboardingState, action: PayloadAction<string>) => {
            state.organizationalUnitId = action.payload;
        },
        setIsLoading: (state: OciOnboardingState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        clearOciOnboardingState: () => initialState,
    },
});

export const {
    setAccountName,
    setTenancyId,
    setHomeRegion,
    setUserEmailAddress,
    setUserName,
    setGroupName,
    setPolicyName,
    setOcidUser,
    setOrganizationalUnitId,
    setIsLoading,
    clearOciOnboardingState,
} = OcionboardingSlice.actions;

export const getAccountName = (state: RootState): string => state.ociOnboarding.name;
export const getTenancyId = (state: RootState): string => state.ociOnboarding.tenancyId;
export const getHomeRegion = (state: RootState): string => state.ociOnboarding.homeRegion;
export const getUserEmailAddress = (state: RootState): string => state.ociOnboarding.TenantAdministratorEmailAddress;
export const getUserName = (state: RootState): string => state.ociOnboarding.userName;
export const getGroupName = (state: RootState): string => state.ociOnboarding.groupName;
export const getPolicyName = (state: RootState): string => state.ociOnboarding.policyName;
export const getOcidUser = (state: RootState): string => state.ociOnboarding.userOcid;
export const getOrganizationalUnitId = (state: RootState): string => state.ociOnboarding.organizationalUnitId;
export const getIsLoading = (state: RootState): string => state.ociOnboarding.isLoading;

export const getOciOnboardingValid = (state: RootState): { valid: boolean; errors?: any } => {
    try {
        yup.object().shape(ociOnBoardingValidations).validateSync(
            {
                accountName: state.ociOnboarding.name,
                tenancyId: state.ociOnboarding.tenancyId,
                homeRegion: state.ociOnboarding.homeRegion,
                emailAddress: state.ociOnboarding.TenantAdministratorEmailAddress,
            },
            { abortEarly: true },
        );
        return { valid: true };
    } catch (error: any) {
        return { valid: false, errors: getValidationErrors(error) };
    }
};

export const getOciIdValid = (state: RootState): { valid: boolean; errors?: any } => {
    try {
        yup.object()
            .shape(ociAccountValidations)
            .validateSync({ userOcid: state.ociOnboarding.userOcid }, { abortEarly: false });
        return { valid: true };
    } catch (error: any) {
        return { valid: false, errors: getValidationErrors(error) };
    }
};

export default OcionboardingSlice.reducer;
