import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import {
    AzureCloudAccountResetMissingPermissions,
    AzureCloudAccountAttachOrganizationalUnit,
} from './azureCloudAccountApi.interface';

const apiBaseUrl = 'AzureCloudAccount';

const attachOrganizationalUnit: AzureCloudAccountAttachOrganizationalUnit.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<AzureCloudAccountAttachOrganizationalUnit.Response>>(
        `${apiBaseUrl}/organizationalUnit/attach`,
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const resetMissingPermissions: AzureCloudAccountResetMissingPermissions.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<AzureCloudAccountResetMissingPermissions.Response>>(
        `${apiBaseUrl}/${request.id}/MissingPermissions/Reset`,
        { method: 'PUT', data: request },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const AzureCloudAccountApi = {
    attachOrganizationalUnit,
    resetMissingPermissions,
};

export default AzureCloudAccountApi;
