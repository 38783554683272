import { ConnectionStatus } from 'common/module_interface/intelligence/Intelligence.const';
import { AzureIntelligenceOnboardingType, AssetType } from './AzureIntelligenceOnboarding.const';

export interface IAzureIntelligenceOnboardingState {
    step: number;
    subscriptionId: string;
    cloudAccountId: string;
    ipAddress: string;
    isLoading: boolean;
    nextButtonDisabled: boolean;
    finishButtonDisabled: boolean;
    onboardingStatus: boolean;
    azureOnboardingType: AzureIntelligenceOnboardingType;
    azureIntelligenceType: AssetType;
    armTemplateDetails: IARMTemplateResponseModel | null;
    selectedLogTypes: AssetType[];
    allStorages: IStorageModel[] | null;
    nsgStorages: IAzureResourceGroupsModel[] | null;
    allSubscriptions: IReportingSubscriptionsModel[] | null;
    selectedSubscriptions: IReportingSubscriptionsModel[] | null;
    selectedStorageTab: SelectedTab;
}

export interface IWizardBody extends HTMLElement {
    updateSteps: Function;
}

export interface IDiscoverStoragesRequestModel {
    SubscriptionId: string;
    LogTypes: AssetType[];
}

export interface ILogTypesModel {
    logType: string;
    containerName: string;
    eventSubscriptionNames: string[] | null;
    logTypeStatus?: ConnectionStatus;
    hasStorageKey?: boolean;
}

export interface IAllStoragesModel {
    name: string;
    resourceGroupName: string;
    systemTopicName?: string;
    region: string;
    logTypesFound: ILogTypesModel[];
}

export interface IOnboardedStoragesModel {
    storageName: string;
    status: boolean;
    isAutoDiscoveryEnabled: boolean;
    isCentralized: boolean;
    hasStorageKey?: boolean;
    accessDenied?: boolean;
    isStorageManaged?: boolean;
    resourceGroup?: string;
    nsg?: string;
    logsType?: string;
    logTypeStatus?: ConnectionStatus;
    id?: string;
}

export interface IStorageModel {
    storageName: string;
    status: ConnectionStatus;
    isAutoDiscoveryEnabled: boolean;
    resourceGroupName: string;
    systemTopicName?: string;
    region: string;
    logTypesFound: ILogTypesModel[];
    isCentralized?: boolean;
    isSelected?: boolean;
    hasStorageKey?: boolean;
    logsType?: string[];
    id?: string;
    nsg?: string;
    storageRg?: string;
    storageExist?: boolean;
}

export interface IOnboardedStoragesResponseModel {
    storagesDetails: IOnboardedStoragesModel[];
}

export interface IRgsFromMagellanModel {
    RgAndNsg: string;
    StorageName: string;
    Status: number;
    IsStorageManaged: number;
}

export interface IAzureResourceGroupsRequestModel {
    RgsFromMagellan: IRgsFromMagellanModel[];
    SubscriptionId: string;
}

export interface IAzureResourceGroupsModel {
    resourceGroup: string;
    networkSecurityGroup: string;
    nsgLocation: string;
    nsgExternalId: string;
    flowLogExist: boolean;
    flowLogsFullStorageAddress: string;
    storageName: string;
    storageExist: boolean;
    eventTopicName: string;
    eventTopicExist: boolean;
    eventSubscriptionExist: boolean;
    containerExist: boolean;
    isConnected: boolean;
    storageLocation: string;
    storageRg: string;
    networkWatcherName: string;
    networkWatcherRg: string;
    networkWatcherLocation: string;
    isStorageManaged: number;
    isCentralized?: boolean;
}

export interface IAzureResourceGroupsResponseModel {
    nsgDetails: IAzureResourceGroupsModel[];
}

export interface IJsonData {
    resourceGroup: string;
    storageName: string;
    region: string;
    topic?: string;
    logTypes: string[];
}

interface customARMTemplateRequestModel {
    cloudAccount: string;
    appRegistrationPrincipalId?: string;
    jsonUrl: boolean;
    isCentralized: boolean;
    returnRoleAssignments?: boolean;
}

export interface INetworkTrafficARMTemplateRequestModel extends customARMTemplateRequestModel {
    jsonData: IJsonData[];
}

export interface IAccountActivityARMTemplateRequestModel extends customARMTemplateRequestModel {
    jsonData: {
        [key: string]: IJsonData[];
    };
}

export interface INsgForArmTemplate {
    networkSecurityGroup: string;
    nsgLocation: string;
    resourceGroup: string;
    storageName: string;
    storageExist: boolean;
    eventgTopicName: string;
    eventgTopicExist: boolean;
    flowLogExist: boolean;
    containerExist: boolean;
    storageLocation: string;
    storageRg: string;
    networkWatcherName: string;
    networkWatcherRg: string;
    networkWatcherLocation: string;
    isStorageManaged: number;
}

export interface INsgJsonData {
    nsgsForArmTemplate: INsgForArmTemplate[];
    rg: string;
}

export interface INsgARMTemplateRequestModel {
    returnRoleAssignments: boolean;
    cloudAccount: string;
    appRegistrationPrincipalId: string;
    jsonData: INsgJsonData[];
    jsonUrl: boolean;
}

export interface IARMTemplateResponseModel {
    roleAssignments: {
        [key: string]: {
            storageBlob: string;
            eventGrid: string;
        };
    };
    arm: string;
}

export interface IStorageDataForRMTemplate {
    StorageAccountName: string;
    LogTypes: string[];
    StorageAccountResourceGroup: string;
    StorageRoleAssignmentId?: string;
    EventGridRoleAssignmentId?: string;
}

export interface ICheckARMTemplateStatusRequest {
    StorageAccountsForSubscriptions: {
        [key: string]: IStorageDataForRMTemplate[];
    };
    StopOnFirstIncompleteConfiguration: boolean;
}

export interface ICheckARMTemplateStatus {
    subscriptionId: string;
    storageAccountName: string;
    systemTopicConfiguredProperly: boolean;
    logTypesMissingEventSubscriptions: string[];
    missingStorageRoleAssignmentId: string;
    missingEventGridRoleAssignmentId: string;
    isCompleted: boolean;
}

export interface ICheckARMTemplateStatusResponse extends Record<string, ICheckARMTemplateStatus[]> {}

export type UpdateRowsFunc = (
    allRowData: IStorageModel[],
    selectedRowData: IStorageModel[],
    isLogTypeField?: boolean,
) => void;

export interface IStorageLogTypeModel {
    storageName: string;
    logTypes: string[];
}

export interface IReportingSubscriptionsRequestModel {
    subscriptionId: string;
    storages: IStorageLogTypeModel[];
}

export interface IReportingSubscriptionsResponseModel {
    subscriptionIds: string[];
    errorsForStorageAccounts?: {
        [key: string]: {
            [key: string]: string;
        };
    };
}

export interface IReportingSubscriptionsModel {
    subscriptionId: string;
    name: string;
    status: ConnectionStatus;
}

export interface IInvalidFirewallStoragesRequestModel {
    SubscriptionId: string;
    StorageAccountName: string;
    ResourceGroup: string;
}

export interface IInvalidFirewallStoragesResponseModel {
    storageNotFound: boolean;
    storageAccountName: string;
    resourceGroup: string;
    subscriptionId: string;
}

export interface IOnboardingNetworkTrafficStorages {
    StorageName: string;
    IsAutoDiscoveryEnabled?: boolean;
}

export interface IOnboardingNetworkTrafficRequestModel {
    SubscriptionId: string;
    Storages: IOnboardingNetworkTrafficStorages[];
    ReportingSubscriptions: string[];
}

export interface IOnboardingAccountActivityStorages {
    StorageName: string;
    ResourceGroup: string;
    StorageKey: string;
    LogTypes: string[];
    IsAutoDiscoveryEnabled?: boolean;
}

export interface IOnboardingAccountActivityRequestModel {
    SubscriptionId: string;
    Storages: IOnboardingAccountActivityStorages[];
    ReportingSubscriptions: string[];
}

export interface AzureWelcomePageProps {
    onChangeType: () => void;
}

export interface StorageDetails {
    storageResourceId: string;
    LogTypes: string[];
}

export interface IAzureAddStorageModel {
    storagesDetails: StorageDetails[];
    SubscriptionId: string;
}

export interface IAzureAddStorageResponseModel {
    name: string;
    resourceGroupName: string;
    region: string;
    systemTopicName?: string;
    logTypesFound: ILogTypesModel[];
}

export enum SelectedTab {
    Manual = 'manual',
    List = 'list',
}

export interface RenderedStep {
    name: string;
    component: JSX.Element;
}

export interface WizardStep {
    title: string;
    number: number;
}
