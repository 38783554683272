import React from 'react';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { useScanResults } from './hooks/useScanResults';
import BenchmarkTab from './components/BenchmarkTab';
import EmptyState from 'common/components/EmptyState';
import { Alert, Chip, Spinner, Stack } from 'common/design-system/components-v2';
import { LoaderWrapper } from './HostConfiguration.styled';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { ScanResultsMetadata } from 'modules/workloads/services/cis/cis.interface';
import { Vendors } from 'common/consts/vendors';

const HostConfiguration: React.FC<{ entity: ICloudEntityData; metadata: ScanResultsMetadata }> = (props) => {
    const {
        entity: {
            cloudAccountId,
            asset: { platform },
            entityId,
        },
        metadata,
    } = props;
    const { benchmarkData, rowData, aggregations, setFilters, loading, error, onExportCsv } = useScanResults({
        provider: platform,
        cloudAccountId,
        queryParams: { assetId: entityId },
    });
    const { t } = useTranslation(getK8sNamespace('cis'));

    if (loading) {
        return (
            <LoaderWrapper>
                <Spinner size={48} context='info' saturation />
            </LoaderWrapper>
        );
    }

    if (error) {
        return <EmptyState iconName='error' label={t('sorrySomethingWentWrong')} />;
    }

    if (rowData.length === 0) {
        return <EmptyState iconName='info' label={t('noDataAvailable')} />;
    }

    return (
        <Stack fullWidth spacing={4}>
            <Alert withCloseButton fullWidth={false} alignIcon='center' iconSize={0}>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Chip label='Preview' color='brandPrimary' />

                    {t('hostConfTabExplanation')}
                </Stack>
            </Alert>
            <BenchmarkTab
                host={{ assetId: entityId, cloudAccountId, provider: platform as Vendors }}
                benchmarkData={benchmarkData}
                benchmarkMetadata={metadata}
                rowData={rowData}
                aggregations={aggregations}
                onExportCsv={onExportCsv}
                loading={loading}
                setFilters={setFilters}
            />
        </Stack>
    );
};

export default HostConfiguration;
