import { Icon, Tooltip } from 'common/design-system/components-v2';
import { GridApi, RowNode } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { I18nSettingsTranslationKey } from '../../../initialize';
import { PermissionCategorySubType } from '../interfaces';
import { CellRendererWrapper } from './CellRenderer.styled';

export interface IInfoState {
    permissionTitle: string;
    permissionType: string;
    permissionSubType: PermissionCategorySubType;
}
function getEnumKeyByValue(value: string): string | undefined {
    return Object.keys(PermissionCategorySubType).find(
        (key) => PermissionCategorySubType[key as keyof typeof PermissionCategorySubType] === value,
    );
}

export const InfoCellRenderer = (props: {
    onClick: (checked: boolean) => void;
    api: GridApi;
    value: string;
    node: RowNode;
    colDef: { cellRendererParams: { permissionSubType: PermissionCategorySubType } };
}) => {
    const { t } = useTranslation(I18nSettingsTranslationKey);
    const buildDefaultTooltipMessage = (permissionSubType: PermissionCategorySubType) => {
        const permissionSubTypeKey = getEnumKeyByValue(permissionSubType);
        return t(`TOOLTIPS.DEFAULTS.${permissionSubTypeKey}`);
    };

    const tooltipMessage =
        props.value || buildDefaultTooltipMessage(props.colDef?.cellRendererParams?.permissionSubType);

    return (
        <CellRendererWrapper>
            <Tooltip content={tooltipMessage}>
                <Icon name={'info'} />
            </Tooltip>
        </CellRendererWrapper>
    );
};
