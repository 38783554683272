import { ITimeRange } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { IFilterDetails } from 'common/module_interface/intelligence/Intelligence.interface';
import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { FindingsTableRegistry } from 'common/components/KustoEvents/FindingsTableRegistry';

export const dateTimeFilter = (filterValues: IFilterDetails | undefined): ITimeRange | undefined => {
    const dateFilter = filterValues?.creationTime;
    if (!dateFilter) {
        // default time frame of 30 days
        const now = new Date();
        return {
            start: now.setDate(now.getDate() - 30).toString(),
            end: new Date().getTime().toString(),
        };
    }
    return {
        start: new Date(dateFilter.from).getTime().toString(),
        end: new Date(dateFilter.to).getTime().toString(),
    };
};

export const getFilterFacetFields = (filters?: IProtectedAssetFilter[]): string[] => {
    return (
        filters
            ?.filter((filterType: IProtectedAssetFilter) => {
                const filterInfo = FindingsTableRegistry.getFilterById(filterType.id);
                const isMitreField = filterInfo?.filterProps?.isMitreField ?? false;
                return filterType.isField && !isMitreField;
            })
            .map((filterType: IProtectedAssetFilter) => filterType.id) ?? []
    );
};

export const getMitreFilterFacetFields = (filters?: IProtectedAssetFilter[]): string[] => {
    return (
        filters
            ?.filter((filterType: IProtectedAssetFilter) => {
                const filterInfo = FindingsTableRegistry.getFilterById(filterType.id);
                const isMitreField = filterInfo?.filterProps?.isMitreField ?? false;
                return filterType.isField && isMitreField;
            })
            ?.map((filterType: IProtectedAssetFilter) => filterType.id) ?? []
    );
};
