import globalAddinContainer from 'common/extensibility/AddinContainer';
import { IIntelligenceService, INTELLIGENCE_SERVICE_ID } from 'common/module_interface/intelligence/intelligence';
import { getHttpService, getStoreService } from 'common/interface/services';
import { getCloudAccountsService, ICloudAccount, IIntelligenceCloudAccount } from 'common/interface/data_services';
import {
    IAcknowledgeRequest,
    IArchiveRequest,
    IAssignUserRequest,
    IChangeSeverityRequest,
    ICloseRequest,
    IColumnMapper,
    ICommentRequest,
    IGslDataItem,
    IGslRunFacetResponse,
    IGslRunRequest,
    IGslRunResponse,
    IIntelligencePolicy,
    IntelligenceRule,
    IntelligenceRuleModel,
    IntelligenceRuleset,
    IntelligenceRulesetDetails,
    IPolicyDto,
    IUpdatePolicyDto,
} from 'common/module_interface/intelligence/Intelligence.interface';
import { isEmpty } from 'lodash';
import { clearCacheDataByTag, getCacheTag } from 'common/utils/apiCaching';
import { sendHttpRequest, sendHttpRequestNoCache } from 'common/erm-components/utils/ermComponents.http';
import { IElasticEvent, IElasticEventModel } from 'common/module_interface/elasticEvents/ElasticEvent.interface';
import { generalApiError } from 'common/utils/http';
import {
    getElasticEventFromModel,
    getElasticEventListFromModels,
} from 'common/module_interface/elasticEvents/ElasticEvent.serviceUtils';

const POLICY_SERVICE_NAME = 'POLICY';

export class IntelligenceService implements IIntelligenceService {
    async getGSLRunData<T = IGslDataItem>(data: IGslRunRequest): Promise<IGslRunResponse<T>> {
        return await sendHttpRequestNoCache<IGslRunResponse<T>>('gslws/gsl-run?action=fetch', {
            method: 'POST',
            data,
        });
    }

    async getGSLRunDataCount(data: IGslRunRequest): Promise<number> {
        const response: IGslRunResponse = await this.getGSLRunData(data);
        const columnMapper: IColumnMapper = response.cols;
        const dataItem: IGslDataItem = response.data[columnMapper['cnt']];
        return !isEmpty(dataItem) ? (dataItem[0] as number) : 0;
    }

    async getIntelligenceCloudAccounts(): Promise<IIntelligenceCloudAccount[]> {
        const state = getStoreService().state;
        const magellanUrl = state.app.magellanUrl;
        return sendHttpRequestNoCache<IIntelligenceCloudAccount[]>(`${magellanUrl}/cloudAccounts`, {
            method: 'GET',
        });
    }

    async getAllEnvironmentsOnboardedToAccountActivity(): Promise<ICloudAccount[]> {
        const allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts();
        return allCloudAccounts.filter((account) => account.isLogicEventActivityOnboarded);
    }

    async getAllEnvironmentsOnboardedToNetworkTraffic(): Promise<ICloudAccount[]> {
        const allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts();
        return allCloudAccounts.filter((account) => account.isLogicTrafficActivityOnboarded);
    }

    async getIntelligenceFindingGraph(findingKey: string): Promise<any> {
        const encodedFindingKey = encodeURIComponent(findingKey);
        const response = await sendHttpRequestNoCache<any>(`cdrws/findings/graph?finding_key=${encodedFindingKey}`, {
            method: 'GET',
        });
        return response.res;
    }

    async getIntelligenceFinding(findingKey: string): Promise<IElasticEvent | undefined> {
        const encodedFindingKey = encodeURIComponent(findingKey);
        const response = await sendHttpRequestNoCache<{ res: IElasticEventModel | undefined }>(
            `cdrws/findings/search?finding_key=${encodedFindingKey}`,
            {
                method: 'GET',
            },
        );
        return response.res ? getElasticEventFromModel(response.res) : undefined;
    }

    async getIntelligenceFindingQuery(data: IGslRunRequest): Promise<IElasticEvent[]> {
        const response = await sendHttpRequestNoCache<{ res: IElasticEventModel[] }>('cdrws/findings/query', {
            method: 'POST',
            data,
        });
        return getElasticEventListFromModels(response.res);
    }

    async getIntelligenceFacets(data: IGslRunRequest): Promise<IGslRunFacetResponse> {
        return await this.getGSLRunData<IGslRunResponse>(data);
    }

    async getIntelligenceRulesets(): Promise<IntelligenceRuleset[]> {
        const state = getStoreService().state;
        const magellanBaseUrl = state.app.magellanUrl;
        return await sendHttpRequest<IntelligenceRuleset[]>(`${magellanBaseUrl}/rulesets/all-metadata`, {
            method: 'GET',
        });
    }

    async getIntelligenceRules(rulesetId: number): Promise<IntelligenceRule[]> {
        const state = getStoreService().state;
        const magellanBaseUrl = state.app.magellanUrl;
        const rulesetDetails: IntelligenceRulesetDetails = await sendHttpRequest<IntelligenceRulesetDetails>(
            `${magellanBaseUrl}/rulesets/specific?bundleId=${rulesetId}`,
            {
                method: 'GET',
            },
        );
        const ruleModels: IntelligenceRuleModel[] = rulesetDetails?.alerts ? rulesetDetails.alerts : [];
        return ruleModels.map((model) => ({
            ...model,
            description: model.alert_explanation,
            cloudAccountId: model.cloud_account_id,
        }));
    }

    private clearMultiPoliciesCache() {
        clearCacheDataByTag(POLICY_SERVICE_NAME);
    }

    async savePolicies(payload: IPolicyDto): Promise<void> {
        this.clearMultiPoliciesCache();
        const state = getStoreService().state;
        const magellanUrl = state.app.magellanUrl;
        return sendHttpRequestNoCache<any>(magellanUrl + '/policies', {
            method: 'POST',
            data: payload,
        });
    }

    async updatePolicy(payload: IUpdatePolicyDto): Promise<void> {
        this.clearMultiPoliciesCache();
        const state = getStoreService().state;
        const magellanUrl = state.app.magellanUrl;
        return sendHttpRequestNoCache<any>(magellanUrl + '/policies', {
            method: 'PUT',
            data: payload,
        });
    }

    async getPolicies(useCache = true, errorCodeIgnoreList?: number[]): Promise<IIntelligencePolicy[]> {
        const state = getStoreService().state;
        const magellanUrl = state.app.magellanUrl;
        return getHttpService().request<IIntelligencePolicy[]>(
            `${magellanUrl}/policies/all`,
            {
                method: 'GET',
            },
            {
                cachingConfig: {
                    useCache: useCache,
                    tags: [getCacheTag(POLICY_SERVICE_NAME)],
                },
                errorCodeIgnoreList: errorCodeIgnoreList,
            },
            generalApiError,
        );
    }

    async acknowledgeFindings(request: IAcknowledgeRequest): Promise<void> {
        return await sendHttpRequestNoCache<void>('findings-update/acknowledge', {
            method: 'POST',
            data: request,
        });
    }

    async addComment(request: ICommentRequest): Promise<void> {
        return await sendHttpRequestNoCache<void>('findings-update/comment', {
            method: 'POST',
            data: request,
        });
    }

    async closeFindings(request: ICloseRequest): Promise<void> {
        return await sendHttpRequestNoCache<void>('findings-update/close', {
            method: 'POST',
            data: request,
        });
    }

    async changeSeverity(request: IChangeSeverityRequest): Promise<void> {
        return await sendHttpRequestNoCache<void>('findings-update/severity', {
            method: 'POST',
            data: request,
        });
    }

    async assignUser(request: IAssignUserRequest): Promise<void> {
        return await sendHttpRequestNoCache<void>('findings-update/assign', {
            method: 'POST',
            data: request,
        });
    }

    async archiveFindings(request: IArchiveRequest): Promise<void> {
        return await sendHttpRequestNoCache<void>('findings-update/archive', {
            method: 'POST',
            data: request,
        });
    }
}

export default function initializeIntelligenceService() {
    globalAddinContainer.addService(INTELLIGENCE_SERVICE_ID, new IntelligenceService());
}
