import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';
import { ColorsWithState } from 'common/design-system/theme/theme';

export interface ScanStatusChipProps {
    tooltip: string;
    text: ScanStatus;
}

export interface ScanStatusChipData {
    [key: string]: ScanStatusChipDataType;
}
export interface ScanStatusChipDataType {
    color: ColorsWithState;
    icon: AllIconsName;
}
export enum ScanStatus {
    'Failed' = 'Failed',
    'Not_Scanned' = 'Not Scanned',
    'Skipped' = 'Skipped',
    'Scanned' = 'Scanned',
    'Partial' = 'Partial',
    'In Progress' = 'In Progress',
}
