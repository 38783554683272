import { IEvent } from 'common/module_interface/events/Events';
import { IFindingModelOriginString } from 'common/components/Findings/Findings.interface';
import { ExclusionRegistry } from 'common/module_interface/exclusion/exclusionRegistry';
import { isServerlessExclusion } from 'common/module_interface/events/utils';

export default function initializeExclusionWorkload() {
    ExclusionRegistry.addExcludeEventEnablers([
        {
            id: IFindingModelOriginString.ContainersRuntimeProtection,
            content: {
                enabler: (event: IEvent) => {
                    return event.origin === IFindingModelOriginString.ContainersRuntimeProtection;
                },
            },
        },
    ]);

    ExclusionRegistry.addExcludeEventEnablers([
        {
            id: IFindingModelOriginString.Serverless,
            content: {
                enabler: (event: IEvent) => {
                    return isServerlessExclusion(event);
                },
            },
        },
    ]);
}
