import React, { useMemo } from 'react';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { useScanResultsMetadata } from '../hooks/useScanResultsMetadata';
import EmptyState from 'common/components/EmptyState';
import { Spinner, Stack } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import HostConfiguration from '../HostConfiguration';
import AwpScanResultEmptyState from 'modules/workloads/protectedAsset/components/AwpScanResultEmptyState';
import { Vendors } from 'common/consts/vendors';
import EnableAWP from 'modules/workloads/aws/tabs/awp/components/EnableAWP/EnableAWP';
import { useIsAwpEnabled } from '../hooks/useIsAwpEnabled';

const HostConfigurationWrapper: React.FC<{ entity: ICloudEntityData }> = (props) => {
    const {
        entity: {
            cloudAccountId,
            asset: { platform },
            entityId,
        },
    } = props;
    const {
        metadata,
        loading: metadataLoading,
        error,
    } = useScanResultsMetadata({ provider: platform, cloudAccountId, queryParams: { assetId: entityId } });
    const { t } = useTranslation(getK8sNamespace('cis'));
    const { isAwpEnabled, loading: isAwpEnabledLoading } = useIsAwpEnabled({
        platform: platform as Vendors,
        cloudAccountId,
    });

    const loading = useMemo(() => metadataLoading || isAwpEnabledLoading, [metadataLoading, isAwpEnabledLoading]);

    if (loading) {
        return (
            <Stack fullHeight fullWidth justifyContent='center' alignItems='center'>
                <Spinner size={48} context='info' saturation />
            </Stack>
        );
    }

    if (isAwpEnabled === false) {
        return <EnableAWP cloudAccountId={cloudAccountId} platform={platform as Vendors} />;
    }

    if (isAwpEnabled === null) {
        <EmptyState iconName='error' iconSize={48} label={t('sorrySomethingWentWrong')} />;
    }

    if (error) {
        return <AwpScanResultEmptyState entity={props.entity} />;
    }

    if (!metadata?.hasSuccessScan) {
        return <EmptyState iconName='noResults' iconSize={48} label={t('noResultsFound')} />;
    }

    return <HostConfiguration {...props} metadata={metadata} />;
};

export default HostConfigurationWrapper;
