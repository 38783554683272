import mixpanel from 'mixpanel-browser';
import { getAppRootRegistry, getLoggerService, getStoreService, getUserService } from 'common/interface/services';
import {
    getIsCloudInfra,
    getCurrentDataCenter,
    getRuntimeEnvironmentType,
    RuntimeEnvironmentType,
} from 'common/utils/RuntimeEnvironment';

let mixpanelInitialized = false;

export const trackEvent = (eventName: string, properties = {}) => {
    mixpanelInitialized && mixpanel.track(eventName, properties);
};

export const trackPageView = () => {
    mixpanelInitialized && mixpanel.track_pageview();
};

export const initializeMixpanel = () => {
    try {
        const prodEnvs = [RuntimeEnvironmentType.PRODUCTION, RuntimeEnvironmentType.CLOUD_INFRA_PROD];
        const isProd = prodEnvs.some((env) => env === getRuntimeEnvironmentType());
        if (isProd) {
            mixpanel.init('9e41a27cebcdf6bf8a5d1692510d01a1'); // prod
            mixpanelInitialized = true;
        } else {
            // mixpanel.init('b5075381204a49a46f80c5eee087f70f'); // staging
            // mixpanelInitialized = true;
        }
    } catch (e: any) {
        getLoggerService().error(`Could not load Mixpanel ${e.message}`);
        return;
    }
};

export const setMixpanelOptions = () => {
    if (!mixpanelInitialized) {
        return;
    }
    getAppRootRegistry().addAfterLoginEvents([
        {
            id: 'Mixpanel',
            content: async () => {
                try {
                    const user = getUserService().getUser();
                    const { state } = getStoreService().getReduxTools();
                    const account = state.user.account;
                    let company = '';
                    let name = '';
                    try {
                        company = user.email.split('@')[1].split('.')[0];
                        name = user.email.split('@')[0];
                    } catch (error) {
                        /* empty */
                    }

                    mixpanel.identify(
                        `${getCurrentDataCenter()?.dataCenter}_${user.accountId}_${user.id === -1 ? name : user.id}_${company}`,
                    );

                    // those prperties are been sent with every event
                    mixpanel.register({
                        isCloudInfra: getIsCloudInfra(),
                        isSuperUser: user.isSuperUser,
                    });

                    mixpanel.people.set({
                        accountId: user.accountId,
                        userId: user.id,
                        dataCenter: getCurrentDataCenter()?.dataCenter,
                        isSuperUser: user.isSuperUser,
                        plan: account?.plan,
                        company,
                        name,
                        isCloudInfra: getIsCloudInfra(),
                    });
                } catch (e: any) {
                    getLoggerService().error(`Could not load Mixpanel ${e.message}`);
                }
            },
        },
    ]);
};
