import { getHttpService, getStoreService } from 'common/interface/services';
import { HttpMethod } from 'common/common_types';
import { SchemaAllTypesResponse } from 'common/services/datasource/MagellanSchema/MagellanSchema.interface';
import { allVendorsLogicTypes } from './allTypes';

const getMagellanUrl = () => {
    const state = getStoreService().state;
    return state.app?.magellanUrl;
};

export const getAllTypes = async (): Promise<SchemaAllTypesResponse> => {
    const url = `${getMagellanUrl()}/schema/allTypes`;
    let types = null;
    try {
        types = await getHttpService().request<SchemaAllTypesResponse>(url, {
            method: HttpMethod.get,
        });
    } catch {
        types = allVendorsLogicTypes;
    }
    return types;
};

export const getGenericList = async (): Promise<SchemaAllTypesResponse> => {
    const url = 'genericList';
    return await getHttpService().get<SchemaAllTypesResponse>(url);
};

export const getIPList = async (): Promise<SchemaAllTypesResponse> => {
    const url = 'ipList';
    return await getHttpService().get<SchemaAllTypesResponse>(url);
};
