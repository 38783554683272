import CreatedTimeCellRender from 'common/components/ag-grid/Renderers/CreatedTimeCellRender';
import EnvironmentCellRender from 'common/components/ProtectedAssets/Renderers/EnvironmentCellRender';
import EntityTypeCellRenderer from 'common/components/ag-grid/Renderers/EntityTypeCellRenderer';
import EventTitleCellRenderer from '../CellRenderers/EventTitleCellRenderer';
import EntityNameCellRender from 'common/components/ag-grid/Renderers/EntityNameCellRender';
import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { MitreCellRender } from 'modules/Intelligence/Findings/FindingsTable/CellRenderers/MitreCellRenderer';
import { FindingsTableRegistry } from 'common/components/KustoEvents/FindingsTableRegistry';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { FindingColumns } from '../../Findings.const';
import FindingSeverityCellRender from 'common/components/ag-grid/Renderers/FindingSeverityCellRender';
import TooltipCellRenderer from '../CellRenderers/TooltipCellRenderer';
import StatusCellRenderer from '../CellRenderers/StatusCellRenderer';
import { EMPTY_STRING } from 'common/consts/GeneralConsts';
import { PROTECTED_ASSETS_URL } from 'common/module_interface/assets/ProtectedAssets.consts';
import ActionsCellRenderer from '../CellRenderers/ActionsCellRenderer';
import { SELECTION_GRID_COLUMN } from 'common/utils/tableUtils';
import { TFunction } from 'i18next';
import { KustoEventFields } from 'common/components/KustoEvents/KustoEvent.const';
import { SortDirection } from 'common/interface/general';
import AssigneeCellRenderer from '../CellRenderers/AssigneeCellRenderer';
import { CommonEventFields } from 'common/module_interface/events/EventsConsts';

function getColumnDefs(): CGColDef[] {
    const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);
    return [
        SELECTION_GRID_COLUMN,
        {
            colId: CommonEventFields.ruleName,
            field: CommonEventFields.ruleName,
            headerName: t('FINDINGS_TABLE.COLUMNS.RULE_NAME.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.RULE_NAME.TOOLTIP'),
            cellRenderer: EventTitleCellRenderer,
            enableRowGroup: true,
            sortable: true,
            suppressColumnsToolPanel: true,
            flex: 9,
        },
        {
            colId: CommonEventFields.severityLevel,
            field: CommonEventFields.severityLevel,
            headerName: t('FINDINGS_TABLE.COLUMNS.SEVERITY.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.SEVERITY.TOOLTIP'),
            cellRenderer: FindingSeverityCellRender,
            enableRowGroup: true,
            sortable: true,
            flex: 6,
            groupOrder: SortDirection.DESC,
        },
        {
            colId: CommonEventFields.createdTime,
            field: CommonEventFields.createdTime,
            headerName: t('FINDINGS_TABLE.COLUMNS.CREATED_TIME.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.CREATED_TIME.TOOLTIP'),
            cellRenderer: CreatedTimeCellRender,
            sortable: true,
            initialSort: 'desc',
            flex: 9,
        },
        {
            colId: CommonEventFields.cloudAccountId,
            field: CommonEventFields.cloudAccountId,
            headerName: t('FINDINGS_TABLE.COLUMNS.ENVIRONMENT.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.ENVIRONMENT.TOOLTIP'),
            cellRenderer: EnvironmentCellRender,
            enableRowGroup: true,
            sortable: true,
            flex: 8,
        },
        {
            colId: CommonEventFields.description,
            field: CommonEventFields.description,
            headerName: t('FINDINGS_TABLE.COLUMNS.DESCRIPTION.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.DESCRIPTION.TOOLTIP'),
            cellRenderer: TooltipCellRenderer,
            flex: 8,
        },
        {
            colId: CommonEventFields.bundleName,
            field: CommonEventFields.bundleName,
            headerName: t('FINDINGS_TABLE.COLUMNS.RULESET_NAME.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.RULESET_NAME.TOOLTIP'),
            cellRenderer: TooltipCellRenderer,
            enableRowGroup: true,
            sortable: true,
            flex: 6,
        },
        {
            colId: CommonEventFields.entityName,
            field: CommonEventFields.entityName,
            headerName: t('FINDINGS_TABLE.COLUMNS.ENTITY.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.ENTITY.TOOLTIP'),
            cellRenderer: EntityNameCellRender,
            cellRendererParams: { componentName: EMPTY_STRING, baseUrl: PROTECTED_ASSETS_URL },
            enableRowGroup: true,
            sortable: true,
            flex: 5,
        },
        {
            colId: CommonEventFields.entityTypeByEnvironmentType,
            field: CommonEventFields.entityTypeByEnvironmentType,
            headerName: t('FINDINGS_TABLE.COLUMNS.ENTITY_TYPE.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.ENTITY_TYPE.TOOLTIP'),
            cellRenderer: EntityTypeCellRenderer,
            enableRowGroup: true,
            sortable: true,
            flex: 6,
        },
        {
            colId: KustoEventFields.mitreDict,
            field: KustoEventFields.mitreDict,
            headerName: t('FINDINGS_TABLE.COLUMNS.MITRE.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.MITRE.TOOLTIP'),
            cellRenderer: MitreCellRender,
            flex: 8,
        },
        {
            colId: CommonEventFields.action,
            field: CommonEventFields.action,
            headerName: t('FINDINGS_TABLE.COLUMNS.ACTION.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.ACTION.TOOLTIP'),
            enableRowGroup: true,
            sortable: true,
            flex: 3,
        },
        {
            colId: CommonEventFields.remediation,
            field: CommonEventFields.remediation,
            headerName: t('FINDINGS_TABLE.COLUMNS.REMEDIATION.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.REMEDIATION.TOOLTIP'),
            flex: 8,
        },
        {
            colId: KustoEventFields.statusId,
            field: KustoEventFields.statusId,
            headerName: t('FINDINGS_TABLE.COLUMNS.STATUS.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.STATUS.TOOLTIP'),
            cellRenderer: StatusCellRenderer,
            enableRowGroup: true,
            sortable: true,
            flex: 4,
        },
        {
            colId: CommonEventFields.lastSeenTime,
            field: CommonEventFields.lastSeenTime,
            headerName: t('FINDINGS_TABLE.COLUMNS.FIRST_OPENED.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.FIRST_OPENED.TOOLTIP'),
            cellRenderer: CreatedTimeCellRender,
            sortable: true,
            flex: 9,
        },
        {
            colId: CommonEventFields.origin,
            field: CommonEventFields.origin,
            headerName: t('FINDINGS_TABLE.COLUMNS.ORIGIN.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.ORIGIN.TOOLTIP'),
            enableRowGroup: true,
            sortable: true,
            flex: 8,
        },
        {
            colId: CommonEventFields.cloudAccountType,
            field: CommonEventFields.cloudAccountType,
            headerName: t('FINDINGS_TABLE.COLUMNS.PLATFORM.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.PLATFORM.TOOLTIP'),
            enableRowGroup: true,
            sortable: true,
            flex: 4,
        },
        {
            colId: CommonEventFields.ownerUserName,
            field: CommonEventFields.ownerUserName,
            headerName: t('FINDINGS_TABLE.COLUMNS.ASSIGNEE.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.ASSIGNEE.TOOLTIP'),
            cellRenderer: AssigneeCellRenderer,
            enableRowGroup: true,
            sortable: true,
            flex: 3,
        },
        {
            colId: CommonEventFields.findingKey,
            field: CommonEventFields.findingKey,
            hide: true,
            suppressColumnsToolPanel: true,
        },
        {
            colId: FindingColumns.ACTIONS,
            field: '',
            headerName: '',
            cellStyle: { borderLeft: 'none' },
            cellRenderer: ActionsCellRenderer,
            resizable: false,
            sortable: false,
            flex: 1,
        },
        {
            colId: KustoEventFields.logType,
            field: KustoEventFields.logType,
            headerName: t('FINDINGS_TABLE.COLUMNS.LOG_TYPE.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.LOG_TYPE.TOOLTIP'),
            cellRenderer: TooltipCellRenderer,
            flex: 4,
            initialHide: true,
        },
    ];
}

export function initializeColumnDefs() {
    FindingsTableRegistry.addColumnDefs(getColumnDefs(), 'colId');
}
