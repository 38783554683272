import { IApplicationReducer } from 'common/interface/redux';
import awsReducer from './reducer';
import { initialAwsEnvironmentData } from './AwsEnvironment.consts';
import { Addin } from 'common/extensibility/AddinRegistry';
import { AwsEnvironmentAddingBase, AwsEnvironmentAddinRegistry } from 'common/helpers/awsEnvironment';
import {
    ActionType,
    AwsEnvironmentToolbarActions,
    AwsEnvironmentData,
} from 'common/module_interface/assets/AwsEnvironment';
import i18n from 'common/services/translations/translations';
import { Vendors } from 'common/consts/vendors';
import { getAppRootRegistry, getUserService } from 'common/interface/services';
import { changeUrl, changeUrlParam } from 'common/utils/http';

const initializeAwsEnvRedux = () => {
    const reducers: IApplicationReducer[] = [
        {
            name: 'env_aws',
            reducer: awsReducer,
            isBlackList: true,
        },
    ];
    getAppRootRegistry().addReducers(reducers, 'name');
};

const initializeAwsEnvToolbarActions = () => {
    const actions: Addin<AwsEnvironmentAddingBase<AwsEnvironmentToolbarActions>>[] = [
        {
            position: 0,
            id: 'remove',
            content: {
                id: 'remove',
                getValue: () => {
                    return {
                        type: 'button',
                        callback: () => changeUrlParam('modalType', ActionType.Remove),
                        label: i18n.t('aws-env_aws:awsPage.toolbar.remove'),
                    };
                },
            },
        },
        {
            position: 1,
            id: 'rename',
            content: {
                id: 'rename',
                isRelevant: (awsData, extraData) => extraData.permission.manage,
                getValue: () => {
                    return {
                        type: 'button',
                        callback: () => changeUrlParam('modalType', ActionType.Rename),
                        label: i18n.t('aws-env_aws:awsPage.toolbar.rename'),
                    };
                },
            },
        },
        {
            position: 2,
            id: 'add-cloud-bots',
            content: {
                id: 'add-cloud-bots',
                isRelevant: (awsData, extraData) => !extraData.cloudAccount.isCloudbotsOnboarded,
                getValue: () => {
                    return {
                        type: 'button',
                        callback: (awsAccount: AwsEnvironmentData) =>
                            changeUrl(
                                `/remediation/aws-cloudbots-onboarding?id=${awsAccount.account.id}&account=${awsAccount.account.externalAccountNumber}&vendor=${awsAccount.account.vendor}&accountName=${awsAccount.account.name}`,
                            ),
                        label: i18n.t('aws-env_aws:awsPage.toolbar.addCloudBots'),
                    };
                },
            },
        },
        {
            position: 3,
            id: 'remove-intelligence',
            content: {
                id: 'remove-intelligence',
                isRelevant: (awsData, extraData) => {
                    const isLogicOnboarded = Boolean(
                        extraData.cloudAccount.isLogicEventActivityOnboarded ||
                            extraData.cloudAccount.isLogicTrafficActivityOnboarded,
                    );
                    return (
                        isLogicOnboarded &&
                        ![Vendors.AWS_CHINA, Vendors.AWS_GOV].includes(awsData.account.vendor as Vendors)
                    );
                },
                getValue: () => {
                    return {
                        type: 'button',
                        callback: () => changeUrlParam('modalType', ActionType.RemoveIntelligence),
                        label: i18n.t('aws-env_aws:awsPage.toolbar.removeIntelligence'),
                    };
                },
            },
        },
        {
            position: 4,
            id: 'add-guard-duty',
            content: {
                id: 'add-guard-duty',
                isRelevant: (awsData, extraData) => extraData.permission.manage,
                getValue: () => {
                    return {
                        type: 'button',
                        callback: (awsAccount) =>
                            changeUrl(
                                `/magellan/magellan-aws-cft-onboarding?id=${awsAccount.account.id}&account=${awsAccount.account.externalAccountNumber}&type=${'guardduty'}&roleArn=${awsAccount.account.credentials.arn}&accountName=${awsAccount.account.name}`,
                            ),
                        label: i18n.t('aws-env_aws:awsPage.toolbar.addGuardDuty'),
                    };
                },
            },
        },
        {
            position: 5,
            id: 'remove-guard-duty',
            content: {
                id: 'remove-guard-duty',
                isRelevant: (awsData, extraData) => {
                    const isLogicGuardDutyOnboarded = Boolean(extraData.cloudAccount.isLogicGuardDutyOnboarded);
                    return (
                        isLogicGuardDutyOnboarded &&
                        ![Vendors.AWS_CHINA, Vendors.AWS_GOV].includes(awsData.account.vendor as Vendors)
                    );
                },
                getValue: () => {
                    return {
                        type: 'button',
                        callback: () => changeUrlParam('modalType', ActionType.RemoveGuardDuty),
                        label: i18n.t('aws-env_aws:awsPage.toolbar.removeGuardDuty'),
                    };
                },
            },
        },
        {
            position: 6,
            id: 'edit-credentials',
            content: {
                id: 'edit-credentials',
                isRelevant: (awsData, extraData) => extraData.permission.manage,
                getValue: () => {
                    return {
                        type: 'aws',
                        actionType: ActionType.EditCredentials,
                        label: i18n.t('aws-env_aws:awsPage.toolbar.editCredentials'),
                    };
                },
            },
        },
        {
            position: 7,
            id: 'sync-now',
            content: {
                id: 'sync-now',
                isRelevant: () => {
                    return getUserService().hasPermission(['cloud-account-sync-now']);
                },
                getValue: () => {
                    return {
                        type: 'aws',
                        actionType: ActionType.SyncNow,
                        label: i18n.t('aws-env_aws:awsPage.toolbar.syncNow'),
                    };
                },
            },
        },
        {
            position: 8,
            id: 'intelligence-separator',
            content: {
                id: 'intelligence-separator',
                isRelevant: (awsAccount) =>
                    ![Vendors.AWS_GOV, Vendors.AWS_CHINA].includes(awsAccount.account.vendor as Vendors),
                getValue: () => ({
                    type: 'seperator',
                    label: i18n.t('aws-env_aws:awsPage.toolbar.addIntelligence'),
                }),
            },
        },
        {
            position: 9,
            id: 'add-cloud-trail',
            content: {
                id: 'add-cloud-trail',
                isRelevant: (awsAccount) =>
                    ![Vendors.AWS_GOV, Vendors.AWS_CHINA].includes(awsAccount.account.vendor as Vendors),
                getValue: () => ({
                    type: 'button',
                    callback: (awsAccount: AwsEnvironmentData) => {
                        changeUrl(
                            `/magellan/magellan-aws-cft-onboarding?id=${awsAccount.account.id}&account=${awsAccount.account.externalAccountNumber}&type=${'cloudtrail'}&roleArn=${awsAccount.account.credentials.arn}&accountName=${awsAccount.account.name}`,
                        );
                    },
                    label: i18n.t('aws-env_aws:awsPage.toolbar.addCloudTrail'),
                }),
            },
        },
        {
            position: 10,
            id: 'add-flow-logs',
            content: {
                id: 'add-flow-logs',
                isRelevant: (awsAccount) =>
                    ![Vendors.AWS_GOV, Vendors.AWS_CHINA].includes(awsAccount.account.vendor as Vendors),
                getValue: () => ({
                    type: 'button',
                    callback: (awsAccount: AwsEnvironmentData) =>
                        changeUrl(
                            `/magellan/magellan-aws-cft-onboarding?id=${awsAccount.account.id}&account=${awsAccount.account.externalAccountNumber}&type=${'flowlogs'}&roleArn=${awsAccount.account.credentials.arn}&accountName=${awsAccount.account.name}`,
                        ),
                    label: i18n.t('aws-env_aws:awsPage.toolbar.addFlowLogs'),
                }),
            },
        },
    ];
    AwsEnvironmentAddinRegistry.addActions(actions);
};

export const initializeAwsEnv = () => {
    initializeAwsEnvRedux();
    initialAwsEnvironmentData();
    initializeAwsEnvToolbarActions();
};
