import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Modal, SelectV2, Stack, Typography } from 'common/design-system/components-v2';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'common/components/ConfirmationModal';
import { IFieldError } from 'common/module_interface/intelligence/CloudBots/CloudBots.interface';
import { FormFields } from './AssignUserModal.const';
import { getLoggerService, getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { getIntelligenceService } from 'common/module_interface/intelligence/intelligence';
import {
    IAssignUserRequest,
    IFindingKeysByCloudAccountId,
} from 'common/module_interface/intelligence/Intelligence.interface';
import { IAssignUserModalEditableProps, IAssignUserModalProps } from './AssignUserModal.types';
import { getFindingKeysByCloudAccountId } from '../../../Findings.utils';
import { isEmpty } from 'lodash';
import { EMPTY_STRING } from 'common/consts/GeneralConsts';
import { getCloudAccountsService } from 'common/interface/data_services';
import { IAssignUser } from 'common/interface/user';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { useFieldErrors } from '../../Hooks/useFieldErrors';

export const AssignUserModal: React.FC<IAssignUserModalProps> = (props: IAssignUserModalProps) => {
    const { findings, closeModal } = props;
    const fieldErrorsProps = useFieldErrors();
    const { addFieldErrors, clearFieldErrors, getFieldErrorText } = fieldErrorsProps;
    const { t } = useTranslation(i18nIntelligenceNamespace);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
    const [email, setEmail] = useState<string>();
    const [userOptions, setUserOptions] = useState<SelectOption[]>([]);

    const getUsersSelectOptions = useCallback(async (): Promise<SelectOption[]> => {
        const users: IAssignUser[] = await getCloudAccountsService().getAllUsers();
        return users.map((user: IAssignUser): SelectOption => {
            return {
                label: user.name,
                value: user.name,
            };
        });
    }, []);

    const init = useCallback(async () => {
        const options: SelectOption[] = await getUsersSelectOptions();
        setUserOptions(options);
    }, [getUsersSelectOptions]);

    useEffect(() => {
        init();
    }, [init]);

    const getSelectedEmail = useCallback((): string => {
        return email || EMPTY_STRING;
    }, [email]);

    const emailFieldErrorText: string | undefined = useMemo(() => {
        return getFieldErrorText(FormFields.EMAIL);
    }, [getFieldErrorText]);

    const updateEmailFieldError = (errors: IFieldError[]) => {
        const email: string = getSelectedEmail();
        if (isEmpty(email)) {
            errors.push({
                field: FormFields.EMAIL,
                label: t('ASSIGN_USER_MODAL.INPUT_FIELDS.EMAIL.ERROR'),
            });
        }
    };

    const updateFieldsErrorState = (): boolean => {
        const errors: IFieldError[] = [];
        updateEmailFieldError(errors);
        addFieldErrors(errors);
        return errors.length > 0;
    };

    const onEmailChange = (value: string | undefined) => {
        clearFieldErrors([FormFields.EMAIL]);
        setEmail(value);
    };

    const onConfirmationModalCancel = () => {
        setIsConfirmationModalOpen(false);
    };

    const onDiscardChanges = () => {
        closeModal();
    };

    const onAssignUserModalCancel = () => {
        if (hasModalPropsChanged()) {
            return setIsConfirmationModalOpen(true);
        } else {
            closeModal();
        }
    };

    const prepareAssignUserRequest = (): IAssignUserRequest => {
        const findingsKeysByCloudAccountId: IFindingKeysByCloudAccountId[] = getFindingKeysByCloudAccountId(findings);
        return { findings: findingsKeysByCloudAccountId, owner: getSelectedEmail() };
    };

    const onAssignUserButtonClick = async () => {
        const isAnyFieldError: boolean = updateFieldsErrorState();
        if (isAnyFieldError) return;

        const request: IAssignUserRequest = prepareAssignUserRequest();
        try {
            setIsLoading(true);
            await getIntelligenceService().assignUser(request);
            getNotificationsService().addNotification({
                type: NotificationType.SUCCESS,
                text: t('ASSIGN_USER_MODAL.ASSIGN_USER_SUCCESS'),
            });
            closeModal();
        } catch (message: unknown) {
            const errorTitle = t('ASSIGN_USER_MODAL.ASSIGN_USER_FAILED');
            getNotificationsService().addNotification({ type: NotificationType.ERROR, text: errorTitle });
            await getLoggerService().error(`${errorTitle} ${message as string}`);
        } finally {
            setIsLoading(false);
            props.refreshTableData();
        }
    };

    const prepareModalEditableProps = useCallback((): IAssignUserModalEditableProps => {
        return {
            email: getSelectedEmail(),
        } as IAssignUserModalEditableProps;
    }, [getSelectedEmail]);
    const initialModalEditablePropsRef: React.MutableRefObject<IAssignUserModalEditableProps> =
        useRef<IAssignUserModalEditableProps>(prepareModalEditableProps());

    const hasModalPropsChanged = useCallback(() => {
        const currModalProps: IAssignUserModalEditableProps = prepareModalEditableProps();
        return JSON.stringify(initialModalEditablePropsRef.current) !== JSON.stringify(currModalProps);
    }, [prepareModalEditableProps]);

    return (
        <Fragment>
            <Modal.ModalDialog onRequestClose={onAssignUserModalCancel} isOpen={true} width={'md'}>
                <Modal.ModalHeader title={t('ASSIGN_USER_MODAL.TITLE')} onClose={onAssignUserModalCancel} />
                <Modal.ModalContent fullHeight={true}>
                    <SelectV2
                        options={userOptions}
                        isMulti={false}
                        required={true}
                        fullWidth={true}
                        clearable={true}
                        label={t('ASSIGN_USER_MODAL.INPUT_FIELDS.EMAIL.TITLE')}
                        placeholder={t('ASSIGN_USER_MODAL.INPUT_FIELDS.EMAIL.PLACEHOLDER')}
                        isError={!!emailFieldErrorText}
                        helperText={emailFieldErrorText}
                        onChange={onEmailChange}
                    />
                </Modal.ModalContent>
                <Modal.ModalFooter>
                    <Stack direction='row' justifyContent='flex-end' fullWidth spacing={2}>
                        <Button key={'cancel-button-action'} variant='text' onClick={onAssignUserModalCancel}>
                            {i18n.t('COMMON.CANCEL')}
                        </Button>
                        <Button
                            key={'assign-user-button-action'}
                            color='brandPrimary'
                            loading={isLoading}
                            disabled={isLoading}
                            onClick={onAssignUserButtonClick}
                            dataAid='Assign user'
                        >
                            {t('ASSIGN_USER_MODAL.ACTIONS.ASSIGN_USER')}
                        </Button>
                    </Stack>
                </Modal.ModalFooter>
            </Modal.ModalDialog>
            {isConfirmationModalOpen && (
                <ConfirmationModal
                    width={'md'}
                    isOpen={isConfirmationModalOpen}
                    title={t('ACTIONS.CONFIRMATION_MODAL.TITLE')}
                    submitBtnText={i18n.t('COMMON.DISCARD')}
                    onConfirm={onDiscardChanges}
                    onClose={onConfirmationModalCancel}
                    onCancel={onConfirmationModalCancel}
                >
                    <Typography>{t('ACTIONS.CONFIRMATION_MODAL.DESCRIPTION')}</Typography>
                </ConfirmationModal>
            )}
        </Fragment>
    );
};
