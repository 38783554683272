import Select from 'common/design-system/components-v2/SelectV2';
import styled from 'styled-components';

export const List = styled.ul`
    list-style-type: disc;
    padding-left: 15px;
`;

export const StyledSelect = styled(Select)`
    width: 50%;
`;
