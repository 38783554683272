import { DrawerRegistry } from 'common/components/DrawerInfra/Drawer/DrawerRegistry';
import { ISSUE_VIEWER_KEY } from './IssueViewer.consts';
import { DrawerActionsStyle, IDrawerContent } from 'common/components/DrawerInfra/Drawer/Drawer.interface';
import { IssueViewer } from './IssueViewer';
import { initializeIssueAssetPanel } from './IssueViewerPanels/IssueAssetPanel/IssueAssetPanel.initialize';
import { fetchIssue } from '../Issues.utils';
import { IIssue, SystemLabels } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { getSafeIssueSeverityInfo } from '../IssueSeverity';
import { IssueViewerRegistry } from 'common/module_interface/RiskManagement/issues/IssueViewerRegistry';
import { IssueBasicPropertiesPanel } from './IssueViewerPanels/IssueBasicPropertiesPanel/IssueBasicPropertiesPanel';
import { IssueDescriptionPanel } from './IssueViewerPanels/IssueDescriptionPanel/IssueDescriptionPanel';
import { isEmptyString } from 'common/utils/helpFunctions';
import { IssueAssetPanel } from './IssueViewerPanels/IssueAssetPanel/IssueAssetPanel';
import { IssueEvidencePanel } from './IssueViewerPanels/IssueEvidencePanel/IssueEvidencePanel';
import { IssueRemediationPanel } from './IssueViewerPanels/IssueRemediationPanel/IssueRemediationPanel';
import { initializeVulnerabilitiesTableColumns } from './IssueViewerPanels/IssueEvidencePanel/IssueVulnerabilities/IssueVulnerabilities.initialize';
import { Chip, LevelIcon, Icon } from 'common/design-system/components-v2';
import { EvidencesRegistry } from 'common/module_interface/RiskManagement/issues/EvidencesRegistry';
import {
    ExtraInfo,
    IssueVulnerabilities,
} from './IssueViewerPanels/IssueEvidencePanel/IssueVulnerabilities/IssueVulnerabilities';
import i18n from 'i18next';
import { I18nRiskNamespace } from '../../../consts';
import { initializeIAMSensitivityTableColumns } from './IssueViewerPanels/IssueEvidencePanel/HighIAMPrivileges/IAMSensitivityTable/IAMSensitivityTable.initialize';
import HighIAMPrivileges from './IssueViewerPanels/IssueEvidencePanel/HighIAMPrivileges/HighIAMPrivileges';
import Malware from './IssueViewerPanels/IssueEvidencePanel/Malware/Malware';
import { initializeMalwareTableColumns } from './IssueViewerPanels/IssueEvidencePanel/Malware/MalwareTable/MalwareTable.initialize';
import { ermPermissionFn, ermTrans, isAfToxicGraph } from '../../../RiskManagement.utils';
import { DATA_SENSITIVITY_EVIDENCE_ACTIVE_FEATURE } from '../../ActiveFeatures/ActiveFeatures.consts';
import MalwareExtraInfo from './IssueViewerPanels/IssueEvidencePanel/Malware/MalwareExtraInfo';
import { IssueContainerImagesPanel } from './IssueViewerPanels/IssueContainerImages/IssueContainerImagesPanel';
import DataSensitivity from './IssueViewerPanels/IssueEvidencePanel/DataSensitivity/DataSensitivity';
import { excludeIssue } from '../../IssueExclusions/IssueExclusionEditor/IssueExclusionEditor.items';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';

export const ISSUE_BASIC_PROPS_PANEL_KEY = 'ISSUE_BASIC_PROPS_PANEL';
export const ISSUE_DESCRIPTION_PANEL_KEY = 'ISSUE_DESCRIPTION_PANEL';
export const ISSUE_ASSET_PANEL_KEY = 'ISSUE_ASSET_PANEL';
export const ISSUE_CONTAINER_IMAGES_LIST_KEY = 'ISSUE_CONTAINER_IMAGES_LIST_KEY';
export const ISSUE_EVIDENCE_PANEL_KEY = 'ISSUE_EVIDENCE_PANEL';
export const ISSUE_REMEDIATION_PANEL_KEY = 'ISSUE_REMEDIATION_PANEL';

const initializeIssueViewerPanels = () => {
    IssueViewerRegistry.addPanelProvider(
        {
            panelId: ISSUE_BASIC_PROPS_PANEL_KEY,
            panel: IssueBasicPropertiesPanel,
            isRelevant: () => true,
        },
        0,
    );

    IssueViewerRegistry.addPanelProvider(
        {
            panelId: ISSUE_DESCRIPTION_PANEL_KEY,
            panel: IssueDescriptionPanel,
            isRelevant: (issue: IIssue) => !isEmptyString(issue.description),
        },
        20,
    );

    IssueViewerRegistry.addPanelProvider(
        {
            panelId: ISSUE_ASSET_PANEL_KEY,
            panel: IssueAssetPanel,
            isRelevant: () => true,
        },
        40,
    );

    IssueViewerRegistry.addPanelProvider(
        {
            panelId: 'ISSUE_CONTAINER_IMAGES_LIST_KEY',
            panel: IssueContainerImagesPanel,
            isRelevant: () => true,
        },
        45,
    );

    IssueViewerRegistry.addPanelProvider(
        {
            panelId: ISSUE_EVIDENCE_PANEL_KEY,
            panel: IssueEvidencePanel,
            isRelevant: () => true,
        },
        60,
    );

    IssueViewerRegistry.addPanelProvider(
        {
            panelId: ISSUE_REMEDIATION_PANEL_KEY,
            panel: IssueRemediationPanel,
            isRelevant: (issue: IIssue) => !isEmptyString(issue.remediation),
        },
        80,
    );
};

function initializeDrawerContent() {
    DrawerRegistry.addContentProvider({
        id: ISSUE_VIEWER_KEY,
        getDrawerContent: async (issueId: string): Promise<IDrawerContent | undefined> => {
            const issue: IIssue | undefined = await fetchIssue(issueId);
            const result: IDrawerContent | undefined = issue
                ? {
                      title: issue.ruleTitle,
                      leftHeaderTrailingContent: issue.isExcluded ? (
                          <Chip
                              label={i18n.t('ISSUES.ISSUE_VIEWER.EXCLUDED', { ns: I18nRiskNamespace })}
                              color='warning'
                              leadingIconProps={{ name: 'exclude' }}
                              context='low'
                          />
                      ) : undefined,
                      icon: (
                          <LevelIcon
                              category='severity'
                              level={getSafeIssueSeverityInfo(issue.severity).key}
                              iconProps={{ name: 'issue' }}
                          />
                      ),
                      component: IssueViewer,
                      componentProps: { issue },
                      actions: issue
                          ? [
                                {
                                    onClick: () => excludeIssue(issue),
                                    icon: <Icon name={'exclude'} />,
                                    tooltip: ermTrans('ISSUES.TABLE.ACTIONS.EXCLUDE.TOOLTIP'),
                                    label: ermTrans('ISSUES.TABLE.ACTIONS.EXCLUDE.TITLE'),
                                    value: 'exclude',
                                },
                                ...(isAfToxicGraph()
                                    ? [
                                          {
                                              onClick: () => getErmUrlsService().goToToxicGraphPageByIssueId(issueId),
                                              icon: <Icon name={'issue'} />,
                                              tooltip: 'Open toxic in Toxic exploration page',
                                              label: 'Explore Toxic',
                                              value: 'openToxic',
                                          },
                                      ]
                                    : []),
                            ]
                          : [],
                  }
                : undefined;
            return Promise.resolve(result);
        },
        options: {
            width: 'xxl',
            actionsStyle: DrawerActionsStyle.BUTTONS,
        },
    });
}

function initializeEvidences() {
    EvidencesRegistry.addItem(10, {
        id: 'Vulnerabilities',
        title: i18n.t('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.VULNERABILITIES', { ns: I18nRiskNamespace }),
        isRelevant: (issue: IIssue) => issue.systemLabels.includes(SystemLabels.VULNERABILITIES),
        component: IssueVulnerabilities,
        extraInfo: ExtraInfo,
    });

    EvidencesRegistry.addItem(11, {
        id: 'Malware',
        title: i18n.t('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.MALWARE', { ns: I18nRiskNamespace }),
        isRelevant: (issue: IIssue) => issue.systemLabels.includes(SystemLabels.MALWARE) && ermPermissionFn(),
        component: Malware,
        extraInfo: MalwareExtraInfo,
    });

    EvidencesRegistry.addItem(15, {
        id: 'HighIAMPrivileges',
        title: i18n.t('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.HIGH_IAM_PRIVILEGES', { ns: I18nRiskNamespace }),
        isRelevant: (issue: IIssue) => issue.systemLabels.includes(SystemLabels.HIGH_IAM_PRIVILEGES),
        component: HighIAMPrivileges,
    });

    EvidencesRegistry.addItem(20, {
        id: 'DataSensitivity',
        title: i18n.t('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.SENSITIVE_DATA', {
            ns: I18nRiskNamespace,
        }),
        isRelevant: (issue: IIssue) =>
            issue.systemLabels.includes(SystemLabels.DATA_SENSITIVITY) &&
            ermPermissionFn(DATA_SENSITIVITY_EVIDENCE_ACTIVE_FEATURE.key),
        component: DataSensitivity,
    });
}

export function initializeIssueViewer() {
    initializeIssueViewerPanels();
    initializeIssueAssetPanel();
    initializeDrawerContent();
    initializeVulnerabilitiesTableColumns();
    initializeIAMSensitivityTableColumns();
    initializeMalwareTableColumns();
    initializeEvidences();
}
