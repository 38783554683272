import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { Icon, Stack, Tooltip, Typography } from 'common/design-system/components-v2';
import Link from 'common/erm-components/basic/Link/Link';
import { ermTrans } from '../../../../../../../RiskManagement.utils';
import { CVEStyled } from './CveIdCellRenderer.styled';

const createCVEUrl = (cveId: string): string => {
    if (cveId.startsWith('GHSA')) {
        return `https://github.com/advisories/${cveId}`;
    }
    if (cveId.startsWith('CVE')) {
        return `https://nvd.nist.gov/vuln/detail/${cveId}`;
    }
    if (cveId.startsWith('ALAS')) {
        return `https://alas.aws.amazon.com/${cveId}.html`;
    }
    return '';
};

const isLegalCVEId = (cveId: string) => cveId.startsWith('GHSA') || cveId.startsWith('CVE') || cveId.startsWith('ALAS');

export const CveIdCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const options: IIconLinkCellProps = {
        isLink: false,
        value: params.data.id,
        levelIconProps: {
            category: 'severity',
            iconProps: { name: 'cve' },
            size: 'md',
            level: params.data.severity,
        },
    };

    return (
        <Stack direction='row' spacing={2}>
            <IconLinkCellRenderer {...options} />
            <Tooltip
                content={
                    <Stack>
                        <Typography>
                            {`${params.data.description}. ${
                                isLegalCVEId(params.data.id)
                                    ? ermTrans(
                                          'ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.COLUMNS.ID.TOOLTIP_CONTENT_TEXT',
                                      )
                                    : ''
                            }`}
                        </Typography>
                    </Stack>
                }
            >
                {isLegalCVEId(params.data.id) ? (
                    <Link externalUrl={createCVEUrl(params.data.id)} leadingIconProps={{ name: 'info', size: 12 }} />
                ) : (
                    <CVEStyled.Info>
                        <Icon name={'info'} size={12} />
                    </CVEStyled.Info>
                )}
            </Tooltip>
        </Stack>
    );
};
