import { Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import { Addin } from 'common/extensibility/AddinRegistry';
import { AwsEnvironmentAddinRegistry, AwsEnvironmentAddingBase } from 'common/helpers/awsEnvironment';

export const initialAwsEnvironmentTabs = () => {
    const tabs: Addin<AwsEnvironmentAddingBase<Tab>>[] = [
        {
            id: 'magellan',
            position: 30,
            content: {
                id: 'magellan',
                isRelevant: (awsData, extraData) => {
                    const isLogicOnboarded =
                        extraData.cloudAccount.isLogicEventActivityOnboarded ||
                        extraData.cloudAccount.isLogicTrafficActivityOnboarded;
                    return !!isLogicOnboarded;
                },
                getValue: () => ({
                    name: 'magellan',
                    label: 'Intelligence',
                    isReactTab: false,
                }),
            },
        },
    ];
    AwsEnvironmentAddinRegistry.addTabs(tabs);
};
