import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'common/services/store/store';
import i18n from 'common/services/translations/translations';
import { getStoreService } from 'common/interface/services';
import { Pages } from 'common/enum/Pages';
import { changeUrl } from 'common/utils/http';

interface ErrorState {
    title: string;
    text: string;
}

const initialState: ErrorState = {
    title: '',
    text: i18n.t('GENERAL.ERROR'),
};

export const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        setErrorTitle: (state: ErrorState, action: PayloadAction<string>) => {
            state.title = action.payload;
        },
        setErrorText: (state: ErrorState, action: PayloadAction<string>) => {
            state.text = action.payload;
        },
        clearError: () => {
            return initialState;
        },
    },
});
export const { setErrorTitle, setErrorText, clearError } = errorSlice.actions;

export const getErrorTitle = (state: RootState): string => state.error.title;
export const getErrorText = (state: RootState): string => state.error.text;
export const getErrorGoHome = (state: RootState): boolean => state.error.goHome || false;

export const navigateToNewError = (error: ErrorState) => () => {
    const { dispatch } = getStoreService().getReduxTools();
    try {
        dispatch(clearError());
        dispatch(setErrorTitle(error.title));
        dispatch(setErrorText(error.text));
        changeUrl(`/${Pages.Error}`, true);
    } catch (error) {
        console.log('error in reducer is: ', error);
    }
};

export default errorSlice.reducer;
