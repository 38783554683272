import { Alert, Chip, Stack } from 'common/design-system/components-v2';
import { EventOverviewTabProps } from 'common/module_interface/events/EventsDrawerRegistry';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';

const PreviewBanner: React.FC<EventOverviewTabProps> = ({ finding }) => {
    const { t } = useTranslation(getK8sNamespace('events'));
    return (
        <Alert iconSize={0}>
            <Stack direction='row' spacing={2} alignItems='center'>
                <Chip label={t('PREVIEW.LABEL')} color='brandPrimary' disableInteraction />
                {t(`PREVIEW.${finding.origin}`, { defaultValue: 'This is a preview of the upcoming feature' })}
            </Stack>
        </Alert>
    );
};

export default PreviewBanner;
