import { getHttpService, IComplianceNotificationsService } from 'common/interface/services';
import { IComplianceNotification } from 'common/interface/complianceNotification';

export class ComplianceNotificationsService implements IComplianceNotificationsService {
    async getNotifications(useCache = true): Promise<IComplianceNotification[]> {
        try {
            return await getHttpService().get<IComplianceNotification[]>({
                path: '/Compliance/ContinuousComplianceNotification',
                cachingConfig: { useCache },
            });
        } catch {
            return [];
        }
    }
}
