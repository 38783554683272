import { useCallback, useMemo, useRef, useState, useEffect, FC } from 'react';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Table } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import ConfirmationModal from 'common/components/ConfirmationModal';
import { CellClickedEvent, RowSelectedEvent } from 'ag-grid-community/dist/lib/events';
import { IDataItem, IGenericTableProps, IModalConfigurations } from './interfaces';

const SimpleGenericTable: FC<IGenericTableProps> = ({
    extraActions = [],
    translationKey,
    tableRegistry,
    onInnerGridReady,
    pageSize,
    pagination,
    data,
    allItemsCount,
    onDelete,
    onAdd,
    onEdit,
    rowSelectionMode = 'multiple',
    onItemClicked,
    exportButtons,
}) => {
    const { t } = useTranslation(translationKey);
    const apiRef = useRef<GridApi>();
    const [selectedRows, setSelectedRows] = useState<IDataItem[]>([]);
    const [modalConfigurations, setModalConfigurations] = useState({} as IModalConfigurations);

    useEffect(() => {
        if (apiRef.current) {
            apiRef.current.setRowData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, apiRef.current]);

    const columnDefs = useMemo<ColDef[]>(() => {
        return tableRegistry.getColumnDefs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCellClicked = (event: CellClickedEvent) => {
        onItemClicked && onItemClicked(event.data);
    };

    const onSelectionChanged = (event: RowSelectedEvent) => {
        const selectedItems: IDataItem[] = event.api.getSelectedRows();
        setSelectedRows(selectedItems);
    };

    const onModalClose = () => {
        setModalConfigurations({ ...modalConfigurations, isOpen: false });
    };

    const openDeleteModal = () => {
        const newModalConfigurations = {} as IModalConfigurations;
        newModalConfigurations.title =
            selectedRows.length > 1
                ? t('MODALS.DELETE.MULTIPLE_DELETION_TITLE')
                : t('MODALS.DELETE.SINGLE_DELETION_TITLE');
        newModalConfigurations.text = t('MODALS.DELETE.CONFIRMATION_TEXT', {
            name: selectedRows.map((item) => item.name).join(','),
        });
        newModalConfigurations.onClose = onModalClose;
        newModalConfigurations.onConfirm = () => {
            onDelete && onDelete(selectedRows);
            onModalClose();
        };
        newModalConfigurations.isOpen = true;
        setModalConfigurations(newModalConfigurations);
    };

    const defaultActions = useMemo<ITableAction[]>(() => {
        return [
            {
                id: 'add',
                name: t('BUTTONS.ADD', { ns: translationKey }),
                buttonProps: {
                    iconProps: { name: 'plus' },
                },
                callback: () => onAdd && onAdd(),
                isActionDisabled: () => false,
            },
            {
                id: 'edit',
                name: t('BUTTONS.EDIT', { ns: translationKey }),
                buttonProps: {
                    iconProps: { name: 'edit' },
                },
                callback: () => onEdit && onEdit(selectedRows[0]),
                isActionDisabled: () => selectedRows.length !== 1,
            },
            {
                id: 'delete',
                name: t('BUTTONS.DELETE', { ns: translationKey }),
                buttonProps: {
                    iconProps: { name: 'delete' },
                },
                callback: () => openDeleteModal(),
                isActionDisabled: () => selectedRows.length === 0,
            },
            ...extraActions,
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows]);

    const onGridReady = useCallback(
        (params: GridReadyEvent) => {
            apiRef.current = params.api;
            apiRef.current.setRowData(data);
            onInnerGridReady(params);
        },
        [data, onInnerGridReady],
    );

    const gridOptions: GridOptions = {
        pagination: pagination,
        columnDefs,
        rowSelection: rowSelectionMode,
        onGridReady,
        getRowId: (params) => params.data.id!,
        onCellClicked: handleCellClicked,
        onRowSelected: onSelectionChanged,
        isRowSelectable: () => {
            return true;
        },
    };

    return (
        <>
            <Table
                pageSize={pageSize}
                disableGrouping={true}
                gridOptions={gridOptions}
                actions={defaultActions}
                exportButtons={exportButtons}
                footer={t('TABLE.FOOTER', {
                    currentCount: data.length.toLocaleString(),
                    totalCount: allItemsCount.toLocaleString(),
                })}
            />
            {modalConfigurations.isOpen && (
                <ConfirmationModal
                    isOpen={modalConfigurations.isOpen}
                    onConfirm={modalConfigurations.onConfirm}
                    title={modalConfigurations.title}
                    text={modalConfigurations.text}
                    onClose={modalConfigurations.onClose}
                    onCancel={modalConfigurations.onClose}
                />
            )}
        </>
    );
};

export default SimpleGenericTable;
