import { getHttpService, IAgent } from 'common/interface/services';
import { IAgentsService } from 'common/interface/agentsService';

export class AgentsService implements IAgentsService {
    async getAgents(useCache = true): Promise<IAgent[]> {
        return await getHttpService().get<IAgent[]>({
            path: '/agent',
            cachingConfig: {
                useCache,
            },
        });
    }
}
