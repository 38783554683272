import i18n from 'common/services/translations/translations';
import KubernetesGeneralSection from './widgets/KubernetesGeneralSection/KubernetesGeneralSection';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { OverviewPanelRegistry } from 'common/module_interface/assets/OverviewPanelRegistry';
import { AssetTypes } from 'common/assets/assets.const';

const relevantTypesData = [
    { id: 'asset-overview-general-KubernetesPod', type: AssetTypes.KUBERNETES_KUBERNETESPOD },
    { id: 'asset-overview-general-KubernetesDaemonSet', type: AssetTypes.KUBERNETES_KUBERNETESDAEMONSET },
    { id: 'asset-overview-general-KubernetesDeployment', type: AssetTypes.KUBERNETES_KUBERNETESDEPLOYMENT },
    { id: 'asset-overview-general-KubernetesReplicaSet', type: AssetTypes.KUBERNETES_KUBERNETESREPLICASET },
    { id: 'asset-overview-general-KubernetesNode', type: AssetTypes.KUBERNETES_KUBERNETESNODE },
];

export function initializeAssetDetailsOverview() {
    relevantTypesData.forEach(({ id, type }) => {
        OverviewPanelRegistry.addCategory(
            id,
            30,
            () => i18n.t(`${getK8sNamespace('protected-asset-details')}:overviewGeneral.title`),
            (entity) => entity.protectedAsset.typeByPlatform === type,
        );
        OverviewPanelRegistry.addCategoryItem(id, id, 30, {
            id,
            component: KubernetesGeneralSection,
        });
    });
}
