import { ICustomTreeNode } from 'common/erm-components/custom/CustomTree/CustomTree.interface';
import {
    IConnectionPairInfo,
    IEntityTypeInfo,
    IPropertyInfo,
    IQueryNodeData,
    IToxicGraphProp,
    IToxicGraphQueryPivotTypeEnum,
    IToxicGraphRelation,
    IToxicGraphSimpleNode,
    IToxicPivotItem,
} from '../ToxicGraph.interface';
import {
    IToxicFilterConditionPartModel,
    IToxicFilterConditionValue,
    IToxicFilterItemModelInfo,
    IToxicFilterItemPartModel,
    IToxicFilterOperandsMapModel,
    IToxicGraphNodeModel,
    IToxicGraphQueryWithEnvModel,
    IToxicGraphQueryNodeModel,
    IToxicGraphQueryRelationModel,
    IToxicGraphRelationModel,
    IToxicPropsMapModel,
    IToxicSrlAdditionalDataModel,
    IToxicSrlItemModel,
    IToxicSubGraphModel,
} from './ToxicGraphModel.interface';
import { PivotTypeEnum } from '../ToxicGraphPage/LeftPanel/QueryBox/PivotType';
import { getToxicSchema } from './ToxicGraphModel.schema';
import {
    getDisplayValueByType,
    getEntityPropInfo,
    getPairInfo,
    getToxicGraphFilterText,
    getToxicTypeIconProps,
} from '../ToxicGraph.utils';
import {
    OR_SPECIAL_OP,
    ToxicCondOpModelEnum,
    toxicOpModelToUiMap,
    toxicOpUiToModeMap,
    ToxicPropTypeEnum,
} from './ToxicGraphModel.consts';
import {
    FilterConditionOperator,
    IFilterCondition,
    IFilterConditionValue,
} from 'common/erm-components/custom/FilterTree/FilterCondition';
import { isBoolean } from 'common/erm-components/utils/types';
import {
    CompoundFilterLogicalOperator,
    ICompoundFilter,
    ICompoundFilterItem,
    ICompoundFilterNode,
} from 'common/erm-components/custom/FilterTree/CompoundFilter';
import { isCompoundFilterNode } from 'common/erm-components/custom/FilterTree/FilterTree.convertors';
import { enrichCustomTreeNode } from 'common/erm-components/custom/CustomTree/CustomTree.utils';

export const condOpModelToUi = (modelOp: ToxicCondOpModelEnum): FilterConditionOperator => {
    return toxicOpModelToUiMap[modelOp] || FilterConditionOperator.Equals;
};

export const condOpUiToModel = (uiOp: FilterConditionOperator): ToxicCondOpModelEnum => {
    return toxicOpUiToModeMap[uiOp] || ToxicCondOpModelEnum.EQUALS;
};

export const queryUiNodeToRelationshipModel = (
    node: ICustomTreeNode<IQueryNodeData>,
): IToxicGraphQueryRelationModel | undefined => {
    let reverse = false;
    if (!node.data.relation || !node.parent) {
        return undefined;
    }

    const pairInfo: IConnectionPairInfo | undefined = getPairInfo(node.data.relation, node.parent.data.entityType);
    reverse = pairInfo ? !pairInfo.isParentToChildRelation : false;
    return {
        label: node.data.relation,
        optional: node.data.optional,
        to: queryUiNodeToModel(node),
        reverse,
    };
};
export const queryUiNodeToRelationshipsModel = (
    node: ICustomTreeNode<IQueryNodeData>,
): IToxicGraphQueryRelationModel[] | undefined => {
    if (!node.children) {
        return undefined;
    }

    const relations: (IToxicGraphQueryRelationModel | undefined)[] = node.children.map((child) =>
        queryUiNodeToRelationshipModel(child),
    );
    const resultRelations: IToxicGraphQueryRelationModel[] = [];
    relations.forEach((relation) => {
        if (relation) {
            resultRelations.push(relation);
        }
    });
    return resultRelations;
};
export const convertPivotEnumUiToModel = (pivotType: PivotTypeEnum): IToxicGraphQueryPivotTypeEnum | undefined => {
    switch (pivotType) {
        case PivotTypeEnum.group:
            return IToxicGraphQueryPivotTypeEnum.group;

        case PivotTypeEnum.pivot:
            return IToxicGraphQueryPivotTypeEnum.pivot;

        default:
            return undefined;
    }
};

export const convertPivotEnumModelToUi = (pivotType: IToxicGraphQueryPivotTypeEnum | undefined): PivotTypeEnum => {
    switch (pivotType) {
        case IToxicGraphQueryPivotTypeEnum.group:
            return PivotTypeEnum.group;

        case IToxicGraphQueryPivotTypeEnum.pivot:
            return PivotTypeEnum.pivot;

        default:
            return PivotTypeEnum.none;
    }
};

export const srlModelItemToUi = (srlModelItem: IToxicSrlItemModel, requestId: string): IToxicPivotItem => {
    const entityTypeInfo: IEntityTypeInfo = getToxicSchema().entityTypesMap[srlModelItem.entityLabel];

    return {
        ...srlModelItem,
        iconProps: entityTypeInfo?.iconProps,
        displayName: srlModelItem.additionalData?.name || srlModelItem.srl,
        requestId,
    };
};
export const queryUiNodeToModel = (node: ICustomTreeNode<IQueryNodeData>): IToxicGraphQueryNodeModel => {
    return {
        nodeLabel: node.data.entityType,
        type: convertPivotEnumUiToModel(node.data.pivotType),
        relationships: queryUiNodeToRelationshipsModel(node),
        filter: toxicFilterUiToModel(node.data.entityType, node.data.compoundFilter),
    };
};

export const queryUiToModel = (
    root: ICustomTreeNode<IQueryNodeData>,
    environmentId?: string,
): IToxicGraphQueryWithEnvModel => {
    return {
        environmentId,
        query: queryUiNodeToModel(root),
    };
};

export const subGraphRelationModelToUi = (
    pivot: IToxicPivotItem,
    subGraphRelation: IToxicGraphRelationModel,
): IToxicGraphRelation => {
    const displayName =
        getToxicSchema().relationsMap[subGraphRelation.label]?.parentToChildText || subGraphRelation.label;
    return {
        id: `fromId:${subGraphRelation.fromId}-toId:${subGraphRelation.toId}-relation:${subGraphRelation.label}`,
        fromId: subGraphRelation.fromId,
        toId: subGraphRelation.toId,
        relation: subGraphRelation.label,
        displayName,
        pivot,
    };
};
export const subGraphRelationsModelToUi = (
    pivot: IToxicPivotItem,
    subGraphModel: IToxicSubGraphModel,
): IToxicGraphRelation[] => {
    if (!subGraphModel.evidencePath.relationships) {
        return [];
    }
    return subGraphModel.evidencePath.relationships.map((subGraphRelation: IToxicGraphRelationModel) =>
        subGraphRelationModelToUi(pivot, subGraphRelation),
    );
};

export const nodePropsModelToUi = (entityType: string, nodePropsMapModel: IToxicPropsMapModel): IToxicGraphProp[] => {
    return Object.keys(nodePropsMapModel).map((name: string) => {
        const propInfo: IPropertyInfo | undefined = getEntityPropInfo(entityType, name);
        const value = nodePropsMapModel[name];
        return {
            name,
            displayValue: propInfo
                ? getDisplayValueByType(propInfo.type as ToxicPropTypeEnum, value)
                : value
                  ? String(value)
                  : '""',
            displayName: propInfo ? propInfo.displayName : name,
            value,
        };
    });
};

export const nodeAdditionalPropsModelToUi = (additionalData: IToxicSrlAdditionalDataModel): IToxicGraphProp[] => {
    return Object.keys(additionalData).map((name: string) => {
        const value = additionalData[name as keyof IToxicSrlAdditionalDataModel];
        return {
            name,
            displayName: name,
            value,
            displayValue: value ? String(value) : '""',
        };
    });
};

export const subGraphNodeModelToSimpleNodeUi = (
    pivot: IToxicPivotItem,
    modelNode: IToxicGraphNodeModel,
): IToxicGraphSimpleNode => {
    const isPivot: boolean = pivot.srl === modelNode.srl;
    const additionalData: IToxicSrlAdditionalDataModel | undefined = modelNode.additionalData;
    return {
        id: modelNode.srl,
        entityType: modelNode.label,
        name: additionalData?.name || modelNode.label,
        environmentId: modelNode.environmentId || undefined,
        props: nodePropsModelToUi(modelNode.label, modelNode.properties).sort((a, b) => a.name.localeCompare(b.name)),
        additionalProps: additionalData
            ? nodeAdditionalPropsModelToUi(additionalData).sort((a, b) => a.name.localeCompare(b.name))
            : undefined,
        isContainer: false,
        iconProps: getToxicTypeIconProps(modelNode.label),
        containerGroupId: modelNode.groupId,
        isPivot,
        pivot,
        modelNode,
    };
};

export const toxicFilterOperandUiToModel = (
    entityTypeInfo: IEntityTypeInfo,
    item: ICompoundFilterItem,
): IToxicFilterItemModelInfo | undefined => {
    if (isCompoundFilterNode(item)) {
        const node: ICompoundFilterNode = item as ICompoundFilterNode;
        return {
            key: OR_SPECIAL_OP,
            part: toxicFilterOperandsUiToModel(entityTypeInfo, node.operands),
        };
    }

    const cond: IFilterCondition = item as IFilterCondition;
    if (cond.values.length === 0) {
        return undefined;
    }

    const propInfo: IPropertyInfo | undefined = entityTypeInfo.propertiesMap[cond.name];
    if (!propInfo) {
        return undefined;
    }

    let value: IToxicFilterConditionValue;
    if (propInfo.type === ToxicPropTypeEnum.boolean) {
        value = isBoolean(String(cond.values[0]));
    } else if (propInfo.type === ToxicPropTypeEnum.string) {
        if (cond.operator === FilterConditionOperator.In) {
            value = cond.values.map((val) => String(val));
        } else {
            value = String(cond.values[0]);
        }
    } else {
        return undefined;
    }

    const opModel: ToxicCondOpModelEnum = condOpUiToModel(cond.operator);
    return {
        key: cond.name,
        part: { [opModel]: value },
    };
};

export const toxicFilterOperandsUiToModel = (
    entityTypeInfo: IEntityTypeInfo,
    operands: ICompoundFilterItem[],
): IToxicFilterOperandsMapModel => {
    if (operands.length === 0) {
        return {};
    }

    const rawItemInfos: (IToxicFilterItemModelInfo | undefined)[] = operands.map((item: ICompoundFilterItem) =>
        toxicFilterOperandUiToModel(entityTypeInfo, item),
    );
    const cleanItemInfos: IToxicFilterItemModelInfo[] = rawItemInfos.filter(
        (item: IToxicFilterItemModelInfo | undefined) => !!item,
    ) as IToxicFilterItemModelInfo[];
    const filterModel: IToxicFilterOperandsMapModel = {};
    cleanItemInfos.forEach((itemInfo: IToxicFilterItemModelInfo) => {
        if (itemInfo.key === OR_SPECIAL_OP) {
            const partKeys: string[] = Object.keys(itemInfo.part);
            filterModel[itemInfo.key] = partKeys.map((partKey) => {
                const partEntry: IToxicFilterItemPartModel = (itemInfo.part as IToxicFilterOperandsMapModel)[
                    partKey
                ] as IToxicFilterItemPartModel;
                return { [partKey]: partEntry };
            });
        } else {
            filterModel[itemInfo.key] = itemInfo.part;
        }
    });
    return filterModel;
};

export const toxicFilterUiToModel = (
    entityType: string,
    compoundFilter?: ICompoundFilter,
): IToxicFilterOperandsMapModel | undefined => {
    if (!compoundFilter?.root || compoundFilter.root.operands.length === 0) {
        return undefined;
    }

    const entityTypeInfo: IEntityTypeInfo | undefined = getToxicSchema().entityTypesMap[entityType];
    if (!entityTypeInfo) {
        return undefined;
    }

    const filterModel: IToxicFilterOperandsMapModel = toxicFilterOperandsUiToModel(
        entityTypeInfo,
        compoundFilter.root.operands,
    );
    if (Object.keys(filterModel).length === 0) {
        return undefined;
    }

    return filterModel;
};

export const toxicFilterOperandModelToUi = (
    key: string,
    itemPart: IToxicFilterItemPartModel,
): ICompoundFilterItem | undefined => {
    if (key === OR_SPECIAL_OP) {
        const operandsModel: IToxicFilterOperandsMapModel[] = itemPart as IToxicFilterOperandsMapModel[];
        return {
            logicalOperator: CompoundFilterLogicalOperator.OR,
            operands: operandsModel
                .map((operandModel: IToxicFilterOperandsMapModel) => {
                    if (Object.keys(operandModel).length !== 1) {
                        return undefined;
                    }
                    const key = Object.keys(operandModel)[0];
                    const item: IToxicFilterItemPartModel = operandModel[key];
                    return toxicFilterOperandModelToUi(key, item);
                })
                .filter((item: ICompoundFilterItem | undefined) => !!item) as ICompoundFilterItem[],
        };
    }

    const condPart: IToxicFilterConditionPartModel = itemPart as IToxicFilterConditionPartModel;
    const ops: ToxicCondOpModelEnum[] = Object.keys(condPart) as ToxicCondOpModelEnum[];
    if (ops.length === 0) {
        return undefined;
    }

    const modelOp: ToxicCondOpModelEnum = ops[0];
    const uiOp = condOpModelToUi(modelOp);
    if (!uiOp) {
        return undefined;
    }

    const value: IToxicFilterConditionValue = condPart[modelOp];
    const values: IFilterConditionValue[] = Array.isArray(value) ? value : [value];
    return {
        name: key,
        values,
        operator: uiOp,
    };
};

export const toxicFilterOperandsModelToUi = (operandsMapModel: IToxicFilterOperandsMapModel): ICompoundFilterItem[] => {
    if (Object.keys(operandsMapModel).length === 0) {
        return [];
    }

    const keys: string[] = Object.keys(operandsMapModel);
    const rawOperands: (ICompoundFilterItem | undefined)[] = keys.map((key: string) =>
        toxicFilterOperandModelToUi(key, operandsMapModel[key]),
    );
    return rawOperands.filter((item: ICompoundFilterItem | undefined) => !!item) as ICompoundFilterItem[];
};

export const toxicFilterModelToUi = (filterModel?: IToxicFilterOperandsMapModel): ICompoundFilter | undefined => {
    if (!filterModel) {
        return undefined;
    }

    const topOperands: ICompoundFilterItem[] = toxicFilterOperandsModelToUi(filterModel);
    if (topOperands.length === 0) {
        return undefined;
    }

    return {
        root: {
            logicalOperator: CompoundFilterLogicalOperator.AND,
            operands: topOperands,
        },
    };
};

let nodeIcCounter = 0;
const getNodeId = (): string => `toxic-node-${nodeIcCounter++}`;
export const queryNodeModelToUiNode = (
    nodeModel: IToxicGraphQueryNodeModel,
    relation?: string,
    optional?: boolean,
): ICustomTreeNode<IQueryNodeData> => {
    const compoundFilter: ICompoundFilter | undefined = toxicFilterModelToUi(nodeModel.filter);
    const filterText: string | undefined = getToxicGraphFilterText(nodeModel.nodeLabel, compoundFilter);
    const data: IQueryNodeData = {
        id: getNodeId(),
        entityType: nodeModel.nodeLabel,
        pivotType: convertPivotEnumModelToUi(nodeModel.type),
        compoundFilter,
        filterText,
        relation,
        optional,
    };
    return {
        id: data.id,
        data,
        children: nodeModel.relationships?.map((relationModel: IToxicGraphQueryRelationModel) =>
            queryNodeModelToUiNode(relationModel.to, relationModel.label, relationModel.optional),
        ),
    };
};

export const queryModelToUiNode = (
    nodeModel: IToxicGraphQueryNodeModel,
    relation?: string,
    optional?: boolean,
): ICustomTreeNode<IQueryNodeData> => {
    const root: ICustomTreeNode<IQueryNodeData> = queryNodeModelToUiNode(nodeModel, relation, optional);
    return enrichCustomTreeNode(root);
};
