import React, { useEffect, useState } from 'react';
import {
    SlackStyled,
    SlackNameWrapper,
    SlackEndpointUrlWrapper,
    SlackEndpointUrlInputWrapper,
    SlackAlertWrapper,
} from './Slack.styled';
import { Typography, Input, Alert, Stack } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import {
    I18nIntegrations,
    SUBMIT_STATUS_RESPONSE,
    URL_ENDPOINT_PROTOCOL,
} from 'common/module_interface/settings/integrations/consts';
import { IConfigurationContainerProps } from 'common/module_interface/settings/integrations/configurations.interface';
import CancelSubmitButtonWrapper from 'common/components/SubmitButtonWrapper/SubmitButtonWrapper';
import { IConfiguration } from 'common/module_interface/settings/integrations/Integrations';
import { isUrlValid } from 'common/utils/http';

interface IGenericConfigurationProps extends IConfigurationContainerProps {
    configuration?: IGenericConfiguration;
}

export interface IGenericConfiguration extends IConfiguration {
    configurationObj: ITeamsConfigurationObj;
}

export interface ITeamsConfigurationObj {
    Url: string;
    type: string;
}

const SlackComponent: React.FC<IGenericConfigurationProps> = ({
    onConfigurationChangeCallBack,
    configuration,
    onConfigurationSaved,
    viewMode,
}) => {
    const { t } = useTranslation(I18nIntegrations);
    const urlFromConfig = configuration?.configurationObj?.Url?.replace(/^https?:\/\//, '') || '';

    const [nameInputConfig, setNameInputConfig] = useState<string>(configuration?.name || '');
    const [endpointUrlInputConfig, SetEndpointUrlInputConfig] = useState<string>(urlFromConfig);
    const [idConfiguration, setIdConfiguration] = useState<string>(configuration?.id || '');
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [responseMessageSuccess, setResponseMessageSuccess] = useState<boolean>(true);

    const discardChangesButtonHidden = Boolean(idConfiguration);
    const viewOnly = Boolean(viewMode);

    useEffect(() => {
        checkChangesMade();
        checkValidUrl();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameInputConfig, endpointUrlInputConfig, idConfiguration]);

    const checkMissingRequiredInput = () => {
        const variableNotChanged = nameInputConfig === '' || endpointUrlInputConfig === '';
        setIsButtonDisabled(variableNotChanged);
    };

    const checkChangesMade = () => {
        const changesDetected = nameInputConfig !== configuration?.name || endpointUrlInputConfig !== urlFromConfig;
        setIsButtonDisabled(!changesDetected);
        checkMissingRequiredInput();
    };

    const checkValidUrl = () => {
        const buttonEnabled = isUrlValid(endpointUrlInputConfig);
        setIsButtonDisabled(!buttonEnabled);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const textInput = event.target.value;
        setNameInputConfig(textInput);
    };

    const handleEndpointUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const textInput = event.target.value;
        const domain = textInput.replace(/^https?:\/\//, '');
        SetEndpointUrlInputConfig(domain);
    };

    const handleDiscardChanges = () => {
        setNameInputConfig(configuration?.name || '');
        SetEndpointUrlInputConfig(urlFromConfig);
    };

    const submitForm = async (): Promise<{ Status: SUBMIT_STATUS_RESPONSE; Message?: string }> => {
        try {
            const resp =
                onConfigurationSaved &&
                (await onConfigurationSaved(
                    nameInputConfig,
                    { Url: URL_ENDPOINT_PROTOCOL + endpointUrlInputConfig },
                    idConfiguration,
                ));
            resp?.id && setIdConfiguration(resp.id);
            setResponseMessageSuccess(true);
            onConfigurationChangeCallBack && onConfigurationChangeCallBack();
            setIsButtonDisabled(true);
            return { Status: SUBMIT_STATUS_RESPONSE.SUCCESS };
        } catch (error: any) {
            setResponseMessageSuccess(false);
            return { Status: SUBMIT_STATUS_RESPONSE.FAIL_SAVE, Message: error.message || error.title };
        }
    };

    return (
        <SlackStyled data-aid={'slack-component'}>
            <SlackAlertWrapper>
                <Alert type={'info'}>
                    <Stack spacing={1}>
                        <Typography variant='body500'>{t('GENERAL.IMPORTANT')}</Typography>
                        <Typography variant='bodyLg'>{t('CONFIGURATIONS.SLACK.SLACK_RATE_LIMIT_MSG')}</Typography>
                    </Stack>
                </Alert>
            </SlackAlertWrapper>
            <SlackNameWrapper>
                <Typography variant='subtitleLg'>{t('GENERAL.NAME')}</Typography>
                <Input
                    type='text'
                    data-aid='nameInput'
                    value={nameInputConfig}
                    placeholder={t('GENERAL.TYPE_HERE')}
                    onChange={handleNameChange}
                    autoFocus={true}
                    disabled={viewOnly}
                />
            </SlackNameWrapper>
            <SlackEndpointUrlWrapper style={{ alignItems: 'flex-end' }}>
                <SlackEndpointUrlInputWrapper>
                    <Typography variant='subtitleLg'>{t('CONFIGURATIONS.SLACK.SLACK_WEBHOOK_URL')}</Typography>
                    <Input
                        type='text'
                        data-aid='urlInput'
                        value={endpointUrlInputConfig}
                        fullWidth={true}
                        onChange={handleEndpointUrlChange}
                        startAdornment={<div>{URL_ENDPOINT_PROTOCOL}</div>}
                        disabled={viewOnly}
                    />
                </SlackEndpointUrlInputWrapper>
            </SlackEndpointUrlWrapper>
            {CancelSubmitButtonWrapper({
                handleDiscardChanges,
                discardChangesButtonHidden,
                isButtonDisabled,
                submitForm,
                viewOnly,
                responseMessageSuccess,
            })}
        </SlackStyled>
    );
};

export default SlackComponent;
