import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const TableWrapper = styled(Stack)`
    width: 500px;
    height: 280px;
`;

export const PermissionsTableStyled = {
    Wrapper: TableWrapper,
};
