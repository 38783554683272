import { IProtectedAssetFilterParams } from 'common/module_interface/assets/ProtectedAssets';
import i18n from 'i18next';
import { renderMultiSelectFilter } from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { ProtectedAssetsTableRegistry } from 'common/module_interface/assets/ProtectedAssetsTableRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { ASSET_FIELD_NAMES, CONTEXT_FIELD_FULL_NAMES } from '../../../consts';
import { Addin } from 'common/extensibility/AddinRegistry';
import { FieldConvertorsRegistry, FieldEntityKind, IFieldConvertor } from 'common/registries/FieldConvertorsRegistry';

import {
    getBusinessPriorityFilterProps,
    getDataSensitivityFilterProps,
    getIamExposureFilterProps,
    getNetworkExposureFilterProps,
} from 'modules/riskManagement/RiskManagement.filter';

export function initializeRiskDashboard() {
    const filterDefsAddins: Addin<IFilterProps>[] = [
        {
            id: 'networkExposure',
            content: function ({ aggregations }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        key: CONTEXT_FIELD_FULL_NAMES.networkExposure,
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.NETWORK_EXPOSURE.TITLE'),
                        ...getNetworkExposureFilterProps(aggregations, CONTEXT_FIELD_FULL_NAMES.networkExposure),
                    },
                    renderFunction: renderMultiSelectFilter,
                } as IFilterProps;
            },
        },
        {
            id: 'iamExposure',
            content: function ({ aggregations }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        key: CONTEXT_FIELD_FULL_NAMES.iamExposure,
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.IAM_EXPOSURE.TITLE'),
                        ...getIamExposureFilterProps(aggregations, CONTEXT_FIELD_FULL_NAMES.iamExposure),
                    },
                    renderFunction: renderMultiSelectFilter,
                } as IFilterProps;
            },
        },
        {
            id: 'dataSensitivity',
            content: function ({ aggregations }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        key: CONTEXT_FIELD_FULL_NAMES.dataSensitivity,
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.DATA_SENSITIVITY.TITLE'),
                        ...getDataSensitivityFilterProps(aggregations, CONTEXT_FIELD_FULL_NAMES.dataSensitivity),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: ASSET_FIELD_NAMES.businessPriority,
            content: function ({ aggregations }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        key: ASSET_FIELD_NAMES.businessPriority,
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.BUSINESS_PRIORITY.TITLE'),
                        ...getBusinessPriorityFilterProps(aggregations, ASSET_FIELD_NAMES.businessPriority),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
    ];

    ProtectedAssetsTableRegistry.addFilterAddins(filterDefsAddins);
    initializeWidgetsFieldConvertors();
}

function initializeWidgetsFieldConvertors() {
    const fieldConvertors: IFieldConvertor[] = [
        {
            sourceEntityKind: FieldEntityKind.ASSET,
            targetEntityKind: FieldEntityKind.WAF,
            sourceFieldName: 'cloudAccountId',
            targetFieldName: 'environment',
        },
    ];
    FieldConvertorsRegistry.addFieldConvertors(fieldConvertors);
}
