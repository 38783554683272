import React from 'react';
import { useTranslation } from 'react-i18next';
import { RegistryNameInputProps } from './RegistryNameInput.interface';
import { Input } from 'common/design-system/components-v2';

const RegistryNameInput: React.FC<RegistryNameInputProps> = (props) => {
    const { setRegistryName, registryName } = props;
    const { t } = useTranslation('k8s_registry');

    return (
        <div className='input-container'>
            <Input
                label={t('ON_BOARDING.ENTER_REGISTRY_NAME')}
                required
                value={registryName.value || ''}
                isError={registryName.isDirty && registryName.isError}
                helperText={registryName.isDirty && registryName.isError ? registryName.errorMessage : ''}
                onChange={(e) => setRegistryName(e.target.value)}
                clearable
                fullWidth
            />
        </div>
    );
};

export default RegistryNameInput;
