import styled from 'styled-components';
import Stack from 'common/design-system/components-v2/Stack';
import { Icon } from 'common/design-system/components-v2';

export const IssueAlertContainer = styled(Stack)`
    background: ${({ theme }) => theme.palette.severity.highBg};
    padding: 12px;
    gap: 16px;
    border-radius: 10px;
`;

export const AlertIcon = styled(Icon)`
    padding: 2px;
    font-weight: bold;
    border: 1px solid ${({ theme }) => theme.palette.severity.criticalFg};
    border-radius: 50%;
`;
