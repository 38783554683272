import { DrawerRegistry } from 'common/components/DrawerInfra/Drawer/DrawerRegistry';
import { IDrawerContent } from 'common/components/DrawerInfra/Drawer/Drawer.interface';
import { LevelIcon } from 'common/design-system/components-v2';
import { SimilarityGroupViewer } from './SimilarityGroupViewer';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import i18n from 'i18next';

export const SIMILAR_ASSETS_DRAWER_KEY = 'SIMILAR_ASSETS_DRAWER_KEY';

export interface ISimilarityGroupViewerDrawerProps {
    similarityGroupId: string;
    memberAsset: IProtectedAssetViewModel;
    assetEntityName: string;
}

export const initializeSimilarityGroupViewerDrawer = () => {
    DrawerRegistry.addContentProvider({
        id: SIMILAR_ASSETS_DRAWER_KEY,
        getDrawerContent: (drawerProps: ISimilarityGroupViewerDrawerProps): Promise<IDrawerContent> => {
            const { assetEntityName, similarityGroupId, memberAsset } = drawerProps;
            const result = {
                title: `${i18n.t('COMMON.PROTECTED_ASSETS_TABLE.SIMILARITY_TITLE_PREFIX')} ${assetEntityName}`,
                icon: <LevelIcon iconProps={{ name: 'presetRowGroup' }} size={'xl'} />,
                component: SimilarityGroupViewer,
                componentProps: { similarityGroupId, memberAsset },
            };
            return Promise.resolve(result);
        },
        options: {
            width: 'xxl',
        },
    });
};
