import initializeColumnDefs from './Definitions/AssetsColumnDefinitions';
import initializeFilters from './Definitions/FilterDefinitions';
import initializeCustomDataDefs from './Definitions/CustomData';
import initializeTabsDefs from './Definitions/TabsDefinitions';
import initializeActionsDefs from './Definitions/ActionsDefinitions';
import initializeExportsDefs from './Definitions/ExportsDefinitions';

export const initializeEnvironments = () => {
    initializeColumnDefs();
    initializeFilters();
    initializeCustomDataDefs();
    initializeTabsDefs();
    initializeActionsDefs();
    initializeExportsDefs();
};
