import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { I18nTranslationKey, RulesetsTableRegistry } from '../InitRulsetsPage';
import {
    renderAddFilter,
    renderClearAll,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter,
    renderSavedFilters,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { Addin } from 'common/extensibility/AddinRegistry';
import i18n from 'i18next';

import { filterNameDataForFreeText } from 'common/SimpleTableFilterPage/SimpleFilterPanel/simpleFilterPanelFunctions';
import { ILocalFilter } from 'common/SimpleTableFilterPage/SimpleFilterPanel/SimpleFilterPanel.interfaces';
import { IExtendRuleset } from 'common/components/rulesetsPage/DefaultRulesetsDataService';

export const RULESETS_FILTER_KEYS = {
    HAS_POLICY: 'has-policy',
    PLATFORM: 'platform',
    TYPE: 'type',
};

export const RULESETS_TYPES = {
    CUSTOM_MANAGED: 'custom',
    CLOUDGAURD_MANAGED: 'cloudgaurd',
};

const rulesetsTypeDisplayObject = () => {
    return {
        [RULESETS_TYPES.CUSTOM_MANAGED]: {
            displayText: i18n.t('FILTERS.OPTIONS.CUSTOM_MANAGED', { ns: I18nTranslationKey }),
        },
        [RULESETS_TYPES.CLOUDGAURD_MANAGED]: {
            displayText: i18n.t('FILTERS.OPTIONS.CLOUD_GUARD_MANAGED', { ns: I18nTranslationKey }),
        },
    };
};

export const rulesetsLocalFilters: ILocalFilter<IExtendRuleset>[] = [
    {
        key: FILTERS_KEYS.FREE_TEXT,
        filteringFunction: filterNameDataForFreeText,
        shouldShowDataItem: (filterValue: string | boolean | number, ruleset: IExtendRuleset) => {
            return ruleset?.name?.toLowerCase().includes(filterValue.toString().toLowerCase());
        },
    },
    {
        key: RULESETS_FILTER_KEYS.HAS_POLICY,
        options: [],
        getOptionsFromDataItem: (ruleset: IExtendRuleset) => {
            return ruleset.numberOfPolicies > 0;
        },
        shouldShowDataItem: (filterValue: string | boolean | number, ruleset: IExtendRuleset) => {
            return filterValue ? ruleset.numberOfPolicies > 0 : ruleset.numberOfPolicies === 0;
        },
    },
    {
        key: RULESETS_FILTER_KEYS.PLATFORM,
        options: [],
        getOptionsFromDataItem: (ruleset: IExtendRuleset) => {
            return ruleset.cloudVendor;
        },
        shouldShowDataItem: (filterValue: string | boolean | number, ruleset: IExtendRuleset) => {
            return filterValue === ruleset.cloudVendor;
        },
    },
    {
        key: RULESETS_FILTER_KEYS.TYPE,
        options: [],
        getOptionsFromDataItem: (ruleset: IExtendRuleset) => {
            return ruleset.id < 0 ? RULESETS_TYPES.CLOUDGAURD_MANAGED : RULESETS_TYPES.CUSTOM_MANAGED;
        },
        shouldShowDataItem: (filterValue: string | boolean | number, ruleset: IExtendRuleset) => {
            return filterValue === RULESETS_TYPES.CUSTOM_MANAGED ? ruleset.id > 0 : ruleset.id < 0;
        },
    },
];

export const rulesetsPageFilterIds = [
    {
        id: FILTERS_KEYS.ADD_FILTER,
    },
    { id: RULESETS_FILTER_KEYS.HAS_POLICY },
    { id: RULESETS_FILTER_KEYS.PLATFORM },
    { id: RULESETS_FILTER_KEYS.TYPE },
    { id: FILTERS_KEYS.FREE_TEXT },
    { id: FILTERS_KEYS.CLEAR_BUTTON },
    { id: FILTERS_KEYS.SAVED_FILTERS },
];

export const filterDefsAddins: () => Addin<IFilterProps>[] = () => [
    {
        id: FILTERS_KEYS.ADD_FILTER,
        content: {
            filterProps: { key: FILTERS_KEYS.ADD_FILTER },
            renderFunction: renderAddFilter,
        },
    },
    {
        id: FILTERS_KEYS.FREE_TEXT,
        content: {
            filterProps: {
                key: FILTERS_KEYS.FREE_TEXT, //This needs to be the same as in stored filter/query params
                title: i18n.t('FILTERS.FREE_TEXT', { ns: I18nTranslationKey }),
            },
            renderFunction: renderDefaultFreeTextFilter,
        },
    },
    {
        id: RULESETS_FILTER_KEYS.HAS_POLICY,
        content: function ({ aggregations }: any) {
            return {
                filterProps: {
                    initialData: aggregations[RULESETS_FILTER_KEYS.HAS_POLICY],
                    key: RULESETS_FILTER_KEYS.HAS_POLICY,
                    title: i18n.t('FILTERS.HAS_POLICY', { ns: I18nTranslationKey }),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: RULESETS_FILTER_KEYS.PLATFORM,
        content: function ({ aggregations }: any) {
            return {
                filterProps: {
                    initialData: aggregations[RULESETS_FILTER_KEYS.PLATFORM],
                    key: RULESETS_FILTER_KEYS.PLATFORM,
                    title: i18n.t('FILTERS.PLATFORM', { ns: I18nTranslationKey }),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: RULESETS_FILTER_KEYS.TYPE,
        content: function ({ aggregations }: any) {
            return {
                filterProps: {
                    initialData: aggregations[RULESETS_FILTER_KEYS.TYPE],
                    key: RULESETS_FILTER_KEYS.TYPE,
                    title: i18n.t('FILTERS.TYPE', { ns: I18nTranslationKey }),
                    displayMapping: rulesetsTypeDisplayObject(),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: FILTERS_KEYS.SAVED_FILTERS,
        content: function ({ filtersInitialData, savedFiltersComponentName }) {
            return {
                filterProps: {
                    savedFilters: filtersInitialData?.savedFilters || [],
                    savedFiltersComponentName,
                    selectedFilterID: '',
                    key: FILTERS_KEYS.SAVED_FILTERS,
                },
                renderFunction: renderSavedFilters,
            };
        },
    },
    {
        id: FILTERS_KEYS.CLEAR_BUTTON,
        content: {
            filterProps: {
                key: FILTERS_KEYS.CLEAR_BUTTON,
            },
            renderFunction: renderClearAll,
        },
    },
];

export default function initializeRulesFilters() {
    RulesetsTableRegistry.addFilterAddins(filterDefsAddins());
}
