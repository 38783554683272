import { Vendors } from 'common/consts/vendors';
import { isChina } from 'common/utils/dataCenter';
import { initializeEnvironmentsTabsDefs } from './initialize.tabDefs';
import { initializeEnvironmentsRows } from './initialize.customRowDefs';
import { initializeEnvironmentsTableData } from './initialize.customDataDefs';
import { initializeEnvironmentsFilters } from './initialize.filterDefs';
import { initializeEnvironmentsColumnDefs } from './initialize.columnDefs';
import { initializeEnvironmentsActionsDefs } from './initialize.actionsDefs';
import { initializeEnvironmentsExportsDefs } from './initialize.exportsDefs';

export const agentlessSupportedVendors = !isChina() ? [Vendors.AWS, Vendors.AZURE] : [Vendors.AWS];
export const serverlessSupportedVendors = [Vendors.AWS];

export const initializeEnvironmetsDefs = () => {
    initializeEnvironmentsColumnDefs();
    initializeEnvironmentsFilters();
    initializeEnvironmentsTableData();
    initializeEnvironmentsRows();
    initializeEnvironmentsTabsDefs();
    initializeEnvironmentsActionsDefs();
    initializeEnvironmentsExportsDefs();
};
