import React, { useCallback, useEffect, useState } from 'react';
import { IIssue } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { LoadingState } from 'common/interface/general';
import i18n from 'i18next';
import { Stack, Typography } from 'common/design-system/components-v2';
import { ermTrans } from '../../../../../../RiskManagement.utils';
import FullSizeSpinner from 'common/erm-components/custom/FullSize/FullSizeSpinner';
import { DataSensitivityStyled } from './DataSensitivity.styled';
import { fetchDataSensitivity } from '../../../../Issues.utils';
import { DataSensitivityInfo } from './DataSensitivity.interface';
import DataSensitivityTable from './DataSensitivityTable';

const DataSensitivity: React.FC<{ issue: IIssue; title: string; initiallyOpen?: boolean }> = ({ issue }) => {
    const [loadingState, setLoadingState] = useState(LoadingState.IS_LOADING);
    const [data, setData] = useState<DataSensitivityInfo[]>();

    const loadDataSensitivity = useCallback(async () => {
        setLoadingState(LoadingState.IS_LOADING);
        setData(undefined);
        try {
            setData(await fetchDataSensitivity(issue));
            setLoadingState(LoadingState.LOADING_SUCCEEDED);
        } catch (e) {
            setLoadingState(LoadingState.LOADING_FAILED);
        }
    }, [issue]);

    useEffect(() => {
        if (issue) {
            void loadDataSensitivity();
        }
    }, [issue, loadDataSensitivity]);

    return (
        <Stack fullWidth>
            {loadingState === LoadingState.IS_LOADING && (
                <DataSensitivityStyled.LoaderDiv>
                    <FullSizeSpinner />
                </DataSensitivityStyled.LoaderDiv>
            )}
            {loadingState === LoadingState.LOADING_FAILED && (
                <DataSensitivityStyled.NoteDiv fullWidth fullHeight>
                    <Typography color={'alert'}>{i18n.t('COMMON.ERROR_OCCURRED')}</Typography>
                </DataSensitivityStyled.NoteDiv>
            )}
            {loadingState === LoadingState.LOADING_SUCCEEDED && data && (
                <Stack direction={'column'} fullWidth fullHeight>
                    {data.length > 0 ? (
                        <DataSensitivityStyled.TableDiv>
                            <DataSensitivityTable assets={data} />
                        </DataSensitivityStyled.TableDiv>
                    ) : (
                        <DataSensitivityStyled.NoteDiv>
                            <Typography>
                                {ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.IAM_SENSITIVITY_TABLE.NO_DATA')}
                            </Typography>
                        </DataSensitivityStyled.NoteDiv>
                    )}
                </Stack>
            )}
        </Stack>
    );
};
export default DataSensitivity;
