import { ICellRendererParams } from 'ag-grid-enterprise';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { changeUrl, generateHref } from 'common/utils/http';
import React, { SyntheticEvent } from 'react';

const ImageLinkRenderer: React.FC<ICellRendererParams<IProtectedAssetViewModel>> = (params) => {
    const { data, value, colDef } = params;

    const linkOptions = React.useMemo<IIconLinkCellProps>(() => {
        const url = `/workload/images/generic?cloudAccountId=${data?.cloudAccountId}&assetType=${data?.type}&assetId=${data?.entityId}&platform=${data?.platform}`;

        return {
            isLink: true,
            value: value,
            href: generateHref(url),
            onClick: (event: SyntheticEvent) => {
                event.preventDefault();
                changeUrl(url);
            },
            levelIconProps:
                colDef?.colId === 'repository'
                    ? {
                          iconProps: {
                              name: 'containerImage',
                          },
                          size: 'md',
                      }
                    : undefined,
        };
    }, [data, value, colDef?.colId]);

    if (data?.isGrouped) return value;

    return <IconLinkCellRenderer {...linkOptions} />;
};

export default ImageLinkRenderer;
