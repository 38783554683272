import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import { FINDINGS_SEARCH_URL } from 'common/module_interface/events/EventsConsts';
import { Finding } from './finding.interface';

const getFinding: Finding.Function = async (data) => {
    return await getHttpService().request<AxiosResponse<Finding.Response>>(
        FINDINGS_SEARCH_URL,
        { method: 'POST', data },
        { returnAsAxiosResponse: true, cachingConfig: { useCache: true } },
        (err) => {
            throw err;
        },
    );
};

export default getFinding;
