import React from 'react';
import { EventOverviewTabProps } from 'common/module_interface/events/EventsDrawerRegistry';
import { formatDate } from 'common/utils/helpFunctions';
import { Stack, Typography } from 'common/design-system/components-v2';

const EventsComments: React.FC<EventOverviewTabProps> = ({ finding: { comments } }) => {
    if (!comments || !comments.length) {
        return null;
    }

    return (
        <Stack spacing={2}>
            <Typography type='key'>Comments ({comments.length})</Typography>
            <Stack spacing={4} padding={[0, 2]}>
                {comments.map((comment, index) => (
                    <Stack key={index} spacing={1}>
                        <Stack direction='row' spacing={3}>
                            <Typography type='key'>{comment.userName}</Typography>
                            <Typography>{formatDate(comment.timestamp)}</Typography>
                        </Stack>
                        <Typography>{comment.text}</Typography>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
};

export default EventsComments;
