import { IBasicRole } from 'common/interface/rolesService';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';

export const basicRole: IBasicRole = {
    name: '',
    description: '',
    users: [],
    serviceAccounts: [],
    permissions: {
        access: [],
        manage: [],
        view: [],
        create: [],
        crossAccountAccess: [],
        rulesets: [],
        notifications: [],
        policies: [],
        alertActions: [],
        onBoarding: [],
    },
};

export interface ISelectLastAction {
    option: SelectOption | null;
    event: 'added' | 'removed' | 'clearAll';
}
