import { IColumnUsageDef } from 'common/interface/general';
import { ColDef } from 'ag-grid-enterprise';
import { mergeColumnDefs } from 'common/utils/tableUtils';
import { CVE_FIELD_NAMES, CVE_PACKAGE_FIELD_NAMES } from '../../../../Issues.interface';
import { IssuesRegistry } from 'common/module_interface/RiskManagement/issues/IssuesRegistry';
import { PackageVersionCellRenderer } from './PackageVersionCellRenderer';
import { ermTrans } from 'modules/riskManagement/RiskManagement.utils';
import { CveIdCellRenderer } from './CveIdCellRenderer/CveIdCellRenderer';
import { IGNORE_CVE_ACTION_COLUMN } from '../../../../Issues.consts';

const getColumnDefs = (): IColumnUsageDef[] => {
    return [
        {
            id: CVE_FIELD_NAMES.CVE_ID,
            colDefOverride: {
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: {
                    suppressCount: true,
                    innerRenderer: CveIdCellRenderer,
                },
                minWidth: 300,
                flex: 3,
            },
        },
        {
            id: CVE_FIELD_NAMES.SEVERITY,
            colDefOverride: {
                minWidth: 100,
                maxWidth: 100,
                resizeable: false,
            },
        },
        {
            id: CVE_FIELD_NAMES.CVSS_SCORE,
            colDefOverride: {
                minWidth: 120,
                maxWidth: 120,
                resizeable: false,
            },
        },
        {
            id: CVE_FIELD_NAMES.EPSS_SCORE,
            colDefOverride: {
                minWidth: 120,
                maxWidth: 120,
                resizeable: false,
            },
        },
        {
            id: CVE_FIELD_NAMES.BASE_SCORE_VECTOR,
            colDefOverride: {
                flex: 10,
            },
        },
        {
            id: CVE_FIELD_NAMES.FIXABLE,
            colDefOverride: {
                minWidth: 85,
                maxWidth: 85,
                resizeable: false,
            },
        },
        {
            id: CVE_FIELD_NAMES.KNOWN_EXPLOIT,
            colDefOverride: {
                minWidth: 120,
                maxWidth: 120,
                resizeable: false,
            },
        },
        {
            id: IGNORE_CVE_ACTION_COLUMN,
            colDefOverride: {
                minWidth: 120,
                maxWidth: 120,
                resizeable: false,
            },
        },
    ];
};

export const getCvesColumnDefs = (): ColDef[] => {
    return mergeColumnDefs(getColumnDefs(), IssuesRegistry.getCvesColumnDefs());
};

export const getCvePackagesColumnDefs = (): ColDef[] => {
    return [
        {
            field: CVE_PACKAGE_FIELD_NAMES.NAME,
            headerName: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.PACKAGES_COLUMNS.PACKAGE'),
            cellRenderer: PackageVersionCellRenderer,
            flex: 2,
        },
        {
            field: CVE_PACKAGE_FIELD_NAMES.UPGRADE_TO,
            headerName: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.PACKAGES_COLUMNS.UPGRADE_TO'),
            flex: 1,
        },
        {
            field: CVE_PACKAGE_FIELD_NAMES.FILE_PATH,
            headerName: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.CVES_TABLE.PACKAGES_COLUMNS.FILE_PATH'),
            flex: 1,
        },
    ];
};
