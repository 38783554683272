import React from 'react';
import i18n from 'common/services/translations/translations';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import EventsWidget from './EventsWidget';
import { FilterField, FindingsAlertType } from 'common/components/Findings/Findings.interface';
import { DEFAULT_ORGANIZATIONAL_UNIT_ID } from 'common/consts/DataConsts';
import { FindingSeverityServerEnum } from 'common/consts/FindingSeverity';
import { EventsTableFilters, getEventsTableWidgetService } from 'common/module_interface/events/EventsTableRegistry';
import { EventType } from 'common/module_interface/events/EventsConsts';
import useReactRouterQuery from 'common/hooks/useReactRouterQuery';
import { ASSETS_TAB_NAMES } from 'common/module_interface/assets/ProtectedAssets';

const EventsCategoryItem: React.FC<{ entity: ICloudEntityData }> = ({ entity }) => {
    const { changeParams } = useReactRouterQuery();

    if (!entity.cloudEntity) {
        const loading = i18n.t('GENERAL.LOADING');
        return <div>{loading}</div>;
    }
    const filterFields: FilterField[] = [
        {
            name: 'organizationalUnitId',
            value: DEFAULT_ORGANIZATIONAL_UNIT_ID,
        },
        {
            name: 'cloudAccountId_calc',
            value: entity.cloudAccountId,
        },
        {
            name: 'acknowledged',
            value: 'false',
        },
        {
            name: 'cloudAccountType',
            isHidden: true,
        },
        {
            name: 'entityType',
            isHidden: true,
        },
        {
            name: 'labels',
            isHidden: true,
        },
        {
            name: 'isExcluded',
            value: 'false',
        },
        {
            name: 'entityDome9Id',
            value: entity.protectedAsset.id,
        },
        {
            name: 'severity',
            value: FindingSeverityServerEnum.High,
        },
        {
            name: 'severity',
            value: FindingSeverityServerEnum.Critical,
        },
    ];

    const changeTabFilterFields: EventsTableFilters['fields'] = [
        {
            name: 'severity',
            value: [FindingSeverityServerEnum.High, FindingSeverityServerEnum.Critical],
        },
        {
            name: 'isExcluded',
            value: ['false'],
        },
    ];

    const onFindingClick = () => {
        const newParams = getEventsTableWidgetService().getParamsStringForEventsTab(
            ASSETS_TAB_NAMES.EVENTS_TAB_NAME,
            EventType.POSTURE_FINDINGS,
            { fields: changeTabFilterFields },
        );
        changeParams(newParams);
    };

    const onSecurityClick = () => {
        const newParams = getEventsTableWidgetService().getParamsStringForEventsTab(
            ASSETS_TAB_NAMES.EVENTS_TAB_NAME,
            EventType.ALL,
            {
                fields: [
                    ...changeTabFilterFields,
                    { name: 'alertType', value: [FindingsAlertType.SECURITY.toString()] },
                ],
            },
        );
        changeParams(newParams);
    };

    return (
        <div className='grid grid-cols-4 sm:gap-5 xl:gap-8'>
            <EventsWidget
                title={i18n.t('EVENTS.ITEMS.POSTURE_FINDINGS.TITLE')}
                icon={'find'}
                onClick={onFindingClick}
                alertType={FindingsAlertType.FINDINGS}
                filterFields={filterFields}
            />
            <EventsWidget
                title={i18n.t('EVENTS.ITEMS.SECURITY_EVENTS.TITLE')}
                icon={'securityEvent'}
                onClick={onSecurityClick}
                alertType={FindingsAlertType.SECURITY}
                filterFields={filterFields}
            />
        </div>
    );
};

export default EventsCategoryItem;
