import React from 'react';
import { cdrTrans } from '../../../../Intelligence.utils';
import { getCdrExclusionFormProps } from './CdrExclusion.items';
import { IExclusionModalProps } from 'common/components/exclusions/helpers/exclusions.interfaces';
import { IFullCustomFormProps } from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import { CustomFormDialog } from 'common/erm-components/custom/CustomForm/components/CustomFormDialog/CustomFormDialog';
import { useEventHandler } from 'common/erm-components/utils/EventBus/useEventHandler';
import { CDR_EXCLUSIONS_PAGE_TABLE_ID } from './CdrExclusion.consts';
import { IEvent } from 'common/module_interface/events/Events';

const CdrElasticExclusion: React.FC<IExclusionModalProps> = ({ onClose, onSave, exclusion }) => {
    useEventHandler(CDR_EXCLUSIONS_PAGE_TABLE_ID, () => onSave());

    if (!exclusion) return null;

    const finding: IEvent | undefined = exclusion.finding;

    if (!finding) return null;

    const cdrExclusion = {
        name: finding.ruleName,
        comment: cdrTrans('CDR_EXCLUSIONS.EDITOR.COMMENT_BY_RULE_PREFIX', { title: finding.ruleName }),
        rulesetId: finding.bundleId ? String(finding.bundleId) : '',
        ruleIds: [finding.ruleId],
        envIds: [finding.cloudAccountExternalId],
        entityNames: finding.entityName ? [finding.entityName] : undefined,
    };

    const fullFormProps: IFullCustomFormProps = {
        formProps: getCdrExclusionFormProps(cdrExclusion),
        data: cdrExclusion,
        onClose: onClose,
    };

    return CustomFormDialog({ ...fullFormProps });
};

export default CdrElasticExclusion;
