import React from 'react';
import { Controller, RegisterOptions, useForm } from 'react-hook-form';
import { EventsModalOpenFrom, EventsModalProps } from 'common/module_interface/events/EventsModalsRegistry';
import { useTranslation } from 'react-i18next';
import { Message, Stack, TextArea } from 'common/design-system/components-v2';
import { getEventsNamespace } from 'modules/events/initialize.i18n';
import { ComplianceFindingComment } from 'common/module_interface/events/complianceFindingApi.interface';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { useMutateEventAcknowledge } from 'modules/events/services/complianceFindingApi/hooks/useMutateEventAcknowledge';

interface FormValues {
    comment: string;
}
const commentMaxLength = 200;

const AcknowledgeAction: React.FC<EventsModalProps> = ({
    closeModal,
    selectedRows,
    resetSelectedRows,
    requestNewData,
    openFrom,
}) => {
    const { t } = useTranslation(getEventsNamespace('actions'));

    const acknowledged = React.useMemo(() => selectedRows.every((row) => !row.acknowledged), [selectedRows]);
    const termPrefix = acknowledged ? 'ACKNOWLEDGE' : 'UNACKNOWLEDGE';

    const onSuccess = (response: ComplianceFindingComment.Response) => {
        if (response.hasErrors) {
            const failedCount = Object.keys(response.failedItems).length;
            getNotificationsService().addNotification({
                text: t(`${termPrefix}.NOTIFICATIONS.PARTIAL_ERROR`, { failedCount, eventsCount: selectedRows.length }),
                type: NotificationType.WARNING,
            });
        } else {
            getNotificationsService().addNotification({
                text: t(`${termPrefix}.NOTIFICATIONS.SUCCESS`, { count: selectedRows.length }),
                type: NotificationType.SUCCESS,
            });
        }
        if (openFrom === EventsModalOpenFrom.TABLE && resetSelectedRows) {
            resetSelectedRows();
        }
        closeModal();
        requestNewData?.();
    };

    const onError = () => {
        getNotificationsService().addNotification({
            text: t(`${termPrefix}.NOTIFICATIONS.ERROR`, { count: selectedRows.length }),
            type: NotificationType.ERROR,
        });
    };

    const { acknowledge, isLoading } = useMutateEventAcknowledge({ onSuccess, onError });

    const inputValidations: { [input: string]: RegisterOptions } = React.useMemo(
        () => ({
            comment: {
                required: t(`${termPrefix}.INPUTS.COMMENT.ERRORS.REQUIRED`),
                maxLength: {
                    value: commentMaxLength,
                    message: t(`${termPrefix}.INPUTS.COMMENT.ERRORS.MAX_LENGTH`, { value: commentMaxLength }),
                },
            },
        }),
        [t, termPrefix],
    );

    const { handleSubmit, control } = useForm<FormValues>({
        defaultValues: {
            comment: '',
        },
    });

    const submitUpdate = async (data: FormValues) => {
        const { comment } = data;
        if (comment) {
            acknowledge(selectedRows, { comment, acknowledged });
        }
    };

    return (
        <Message
            title={t(`${termPrefix}.TITLE`)}
            text={selectedRows.length > 1 ? t(`${termPrefix}.TEXT`, { count: selectedRows.length }) : undefined}
            submitBtnText={t(`${termPrefix}.BUTTONS.SUBMIT`)}
            width='lg'
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={handleSubmit((data) => submitUpdate(data))}
            isLoading={isLoading}
            dataAid={`events-${termPrefix.toLowerCase()}-action`}
        >
            <Controller
                name='comment'
                rules={inputValidations['comment']}
                control={control}
                render={({ field, fieldState }) => (
                    <Stack>
                        <TextArea
                            label={t(`${termPrefix}.INPUTS.COMMENT.LABEL`)}
                            placeholder={t(`${termPrefix}.INPUTS.COMMENT.PLACEHOLDER`)}
                            rows={4}
                            required
                            fullWidth
                            isError={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            maxLength={commentMaxLength}
                            autoFocus
                            {...field}
                        />
                    </Stack>
                )}
            />
        </Message>
    );
};

export default AcknowledgeAction;
