import { IPermissionObject, IUser } from 'common/interface/user';
import { getHttpService } from 'common/interface/services';

export const getUserFromServer = async (): Promise<IUser> => {
    return await getHttpService().get<IUser>({
        path: 'user/me',
        cachingConfig: { useCache: false },
        errorCodeIgnoreList: [404],
    });
};

export const getUserPermissionsFromServer = async (): Promise<IPermissionObject> => {
    return await getHttpService().get<IPermissionObject>(
        `webPermission/GetNavMenuPermissions?origin=${window.location.host}`,
    );
};
