import { OptionType } from 'common/design-system/components-v2/Select';
import { RemediationModuleType, IRemediation } from 'common/interface/remediation';

export interface RulesetOption extends OptionType {
    platform: string;
}

export interface RuleOption extends OptionType {
    ruleLogicHash: string;
}
export interface IRemediationModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: () => void;
    remediation?: IRemediation;
    sourceType?: RemediationModuleType;
}
export interface RemediateByEntityOptionProps {
    isEnabled: boolean;
    selectedRuleSet: RulesetOption | null;
    selectedOption?: string | null;
    formValidations?: { message: string; type: string }[];
    onChange: (value: OptionType | RuleOption | string | null) => void;
}
export interface RemediateBySeverityOptionProps {
    isEnabled: boolean;
    selectedRuleSet: RulesetOption | null;
    selectedOption?: string[] | null;
    formValidations?: { message: string; type: string }[];
    onChange: (value: string[] | null) => void;
}
export interface AddCloudBotsOptionProps {
    isEnabled: boolean;
    isCiem?: boolean;
    isEditMode?: boolean;
    selectedRuleSet: any;
    maxCloudBots?: number;
    formValidations?: { message: string; type: string }[];
    selectedOption?: string[] | null;
    onChange: (value: any) => void;
}

export enum EntityTypesEnum {
    byName = 'byName',
    byID = 'byID',
}

export interface IRule {
    category: string;
    cloudbots: any;
    complianceTag: string;
    controlTitle: string;
    description: string;
    domain: string;
    isDefault: boolean;
    labels: string[];
    logic: string;
    logicHash: string;
    name: string;
    priority: string;
    remediation: string;
    rlmId: any;
    ruleId: string;
    severity: string;
}
export interface SelectOption {
    label: string;
    value: string;
}
// SHOULD BE MOVED TO COMMON
export interface Aggregations {
    [key: string]: { value: any; count: number }[];
}
