import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const TableWrapper = styled(Stack)`
    height: 276px;
`;
const FooterActionsWrapper = styled(Stack)``;

export const CloudBotStatusModalStyles = {
    TableWrapper,
    FooterActionsWrapper,
};
