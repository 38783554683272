import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import {
    CONFIGURATION_DRAWER,
    HOW_TO_CONFIGURE_URLS,
    INTEGRATION_DRAWER,
    integrationCategory,
    INTEGRATIONS_IDS,
    IntegrationsDefinitionWrapper,
    TEST_FORMAT_TYPE,
} from 'common/module_interface/settings/integrations/consts';
import { getIsCloudInfra } from 'common/utils/RuntimeEnvironment';
import { generateHref } from 'common/utils/http';
import { DrawerRegistry } from 'common/components/DrawerInfra/Drawer/DrawerRegistry';
import { IDrawerContent, IDrawerHandlersIdMap } from 'common/components/DrawerInfra/Drawer/Drawer.interface';
import SnsSystemComponent from './Configurations/Componenets/SnsAudit/SnsSystemComponent';
import GenericWebhookComponent from './Configurations/Componenets/GenericWebhook/GenericWebhookComponent';
import { getDrawerComponentByIntegrationID } from './Configurations/ConfigurationDrawerViews';
import { getIntegrationsService, INTEGRATIONS_SERVICE_ID, getAppRootRegistry } from 'common/interface/services';
import { IntegrationsService } from './Services/IntegrationsService';
import { IApplicationReducer } from 'common/interface/redux';
import IntegrationsReducer from './Integrations.reducer';
import TeamsComponent, { TeamsSpecialMessage } from './Configurations/Componenets/Teams/TeamsComponent';
import { TenableConfigurationComponent } from './Configurations/Componenets/Tenable/TenableConfigurationComponent';
import { DrawerBus } from 'common/components/DrawerInfra/Drawer/DrawerBus';
import SlackComponent from './Configurations/Componenets/Slack/SlackComponent';
import EmailComponent from './Configurations/Componenets/Email/EmailComponent';
import QRadarComponent from './Configurations/Componenets/Qradar/QradarComponent';
import SnsComponent from './Configurations/Componenets/SNS/SnsComponent';
import AzureDefenderForCloudComponent from './Configurations/Componenets/AzureDefenderForCloud/AzureDefenderForCloudComponent';
import PagerDutyComponent from './Configurations/Componenets/PagerDuty/PagerDutyComponent';
import JiraComponent from './Configurations/Componenets/Jira/JiraComponent';
import GcpSecurityCommandCenterComponent from './Configurations/Componenets/GcpSecurityCommandCenter/GcpSecurityCommandCenterComponent';
import AwsSecurityHubComponent from './Configurations/Componenets/AwsSecurityHub/AwsSecurityHubComponent';
import { IntegrationsRegistry } from 'common/module_interface/settings/integrations/IntegrationsRegistry';
import { integrationLogos } from './Components/Logos/integrationLogos';
import { AddConfigurationDrawer } from './Components/ConfigurationPage/AddConfigurationDrawer';
import SentinelComponent from './Configurations/Componenets/Sentinel/SentinelComponent';
import initializeSentinelFilters from './Configurations/Componenets/Sentinel/SentinelFilterDefinitions';
import Link from 'common/design-system/components-v2/Link';

const initializeIntegrationDrawer = () => {
    DrawerRegistry.addContentProvider({
        id: INTEGRATION_DRAWER.key,
        getDrawerContent: (drawerContent: any, handlersIdMap?: IDrawerHandlersIdMap): Promise<IDrawerContent> => {
            const onConfigurationChangeCallBack = () => {
                DrawerBus.sendEvent(handlersIdMap![INTEGRATION_DRAWER.eventTypes.configurationChanged]);
            };

            const result = {
                title: drawerContent.title,
                icon: <img src={drawerContent.icon} alt={''} width={32} height={32} style={{ objectFit: 'contain' }} />,
                component: getDrawerComponentByIntegrationID(drawerContent.view, drawerContent.integrationID),
                componentProps: {
                    ...drawerContent.componentProps,
                    onConfigurationChangeCallBack: onConfigurationChangeCallBack,
                },
                rightHeaderContent: <Link externalUrl={drawerContent.howToConfigureLink}>How to Configure</Link>,
            };
            //@ts-ignore
            return Promise.resolve(result);
        },
        options: {
            hasHeaderLineSeparator: true,
            width: 'lg',
        },
    });
};

const initializeConfigurationDrawer = () => {
    DrawerRegistry.addContentProvider({
        id: CONFIGURATION_DRAWER.key,
        getDrawerContent: (drawerContent: any, handlersIdMap?: IDrawerHandlersIdMap): Promise<IDrawerContent> => {
            const onConfigurationChangeCallBack = () => {
                DrawerBus.sendEvent(handlersIdMap![CONFIGURATION_DRAWER.eventTypes.configurationChanged]);
            };
            const result = {
                title: drawerContent.title,
                component: AddConfigurationDrawer,
                componentProps: {
                    ...drawerContent.componentProps,
                    onConfigurationChangeCallBack: onConfigurationChangeCallBack,
                },
            };
            //@ts-ignore
            return Promise.resolve(result);
        },
        options: {
            hasHeaderLineSeparator: true,
            width: 'lg',
        },
    });
};

function initializeIntegrationsService() {
    globalAddinContainer.addService(INTEGRATIONS_SERVICE_ID, new IntegrationsService());
}

function initializeIntegrationsReducer() {
    const reducers: IApplicationReducer[] = [
        { name: 'integrationsSlice', reducer: IntegrationsReducer, isBlackList: true },
    ];
    getAppRootRegistry().addReducers(reducers, 'name');
}

export function initializeIntegrations() {
    initializeIntegrationDrawer();
    initializeIntegrationsService();
    initializeIntegrationsReducer();
    initializeConfigurationDrawer();
    initializeSentinelFilters();

    let integrationsObj: IntegrationsDefinitionWrapper[] = [
        {
            id: 'splunk',
            content: {
                id: INTEGRATIONS_IDS.SPLUNK,
                title: 'Splunk',
                icon: integrationLogos.SPLUNK,
                category: integrationCategory.EVENTS_AND_LOGGING,
                testFormatType: TEST_FORMAT_TYPE.SPLUNK_BASIC,
                howToConfigureLink: HOW_TO_CONFIGURE_URLS.SPLUNK,
                configurationComponent: GenericWebhookComponent,
                onDelete: (configurationId: string) => getIntegrationsService().deleteConfiguration(configurationId),
                onSave: (name: string, configurationObj: any, configurationId?: string) =>
                    getIntegrationsService().saveConfiguration(
                        name,
                        INTEGRATIONS_IDS.SPLUNK,
                        configurationObj,
                        configurationId,
                    ),
            },
        },
        {
            id: 'qradar',
            content: {
                id: INTEGRATIONS_IDS.QRADAR,
                title: 'Qradar',
                icon: integrationLogos.QRADAR,
                category: integrationCategory.EVENTS_AND_LOGGING,
                testFormatType: TEST_FORMAT_TYPE.QRADAR,
                configurationComponent: QRadarComponent,
                howToConfigureLink: HOW_TO_CONFIGURE_URLS.QRADAR,
                onDelete: (configurationId: string) => getIntegrationsService().deleteConfiguration(configurationId),
                onSave: (name: string, configurationObj: any, configurationId?: string) =>
                    getIntegrationsService().saveConfiguration(
                        name,
                        INTEGRATIONS_IDS.QRADAR,
                        configurationObj,
                        configurationId,
                    ),
            },
        },
        {
            id: 'sumoLogic',
            content: {
                id: INTEGRATIONS_IDS.SUMO_LOGIC,
                title: 'Sumo Logic',
                icon: integrationLogos.SUMO_LOGIC,
                category: integrationCategory.EVENTS_AND_LOGGING,
                testFormatType: TEST_FORMAT_TYPE.JSON_FIRST_LEVEL_ENTITY,
                howToConfigureLink: HOW_TO_CONFIGURE_URLS.SUMO_LOGIC,
                configurationComponent: GenericWebhookComponent,
                onDelete: (configurationId: string) => getIntegrationsService().deleteConfiguration(configurationId),
                onSave: (name: string, configurationObj: any, configurationId?: string) =>
                    getIntegrationsService().saveConfiguration(
                        name,
                        INTEGRATIONS_IDS.SUMO_LOGIC,
                        configurationObj,
                        configurationId,
                    ),
            },
        },
        {
            id: 'serviceNow',
            content: {
                id: INTEGRATIONS_IDS.SERVICE_NOW,
                title: 'Service Now',
                icon: integrationLogos.SERVICE_NOW,
                category: integrationCategory.TICKETING_SYSTEM,
                testFormatType: TEST_FORMAT_TYPE.SERVICE_NOW,
                howToConfigureLink: HOW_TO_CONFIGURE_URLS.SERVICE_NOW,
                configurationComponent: GenericWebhookComponent,
                onDelete: (configurationId: string) => getIntegrationsService().deleteConfiguration(configurationId),
                onSave: (name: string, configurationObj: any, configurationId?: string) =>
                    getIntegrationsService().saveConfiguration(
                        name,
                        INTEGRATIONS_IDS.SERVICE_NOW,
                        configurationObj,
                        configurationId,
                    ),
            },
        },
        {
            id: 'Jira',
            content: {
                id: INTEGRATIONS_IDS.JIRA,
                title: 'Jira',
                icon: integrationLogos.JIRA,
                hasIntegrationPayload: true,
                category: integrationCategory.TICKETING_SYSTEM,
                testFormatType: TEST_FORMAT_TYPE.JIRA,
                howToConfigureLink: HOW_TO_CONFIGURE_URLS.JIRA,
                configurationComponent: JiraComponent,
                onDelete: (configurationId: string) => getIntegrationsService().deleteConfiguration(configurationId),
                onSave: (name: string, configurationObj: any, configurationId?: string) =>
                    getIntegrationsService().saveConfiguration(
                        name,
                        INTEGRATIONS_IDS.JIRA,
                        configurationObj,
                        configurationId,
                    ),
            },
        },
        {
            id: 'pagerDuty',
            content: {
                id: INTEGRATIONS_IDS.PAGER_DUTY,
                title: 'Pager Duty',
                icon: integrationLogos.PAGER_DUTY,
                category: integrationCategory.TICKETING_SYSTEM,
                howToConfigureLink: HOW_TO_CONFIGURE_URLS.PAGER_DUTY,
                configurationComponent: PagerDutyComponent,
                onDelete: (configurationId: string) => getIntegrationsService().deleteConfiguration(configurationId),
                onSave: (name: string, configurationObj: any, configurationId?: string) =>
                    getIntegrationsService().saveConfiguration(
                        name,
                        INTEGRATIONS_IDS.PAGER_DUTY,
                        configurationObj,
                        configurationId,
                    ),
            },
        },
        {
            id: 'genericWebhook',
            content: {
                id: INTEGRATIONS_IDS.GENERIC_WEBHOOK,
                title: 'Generic Webhook',
                icon: integrationLogos.WEBHOOK,
                category: integrationCategory.COLLABORATIONS_AND_MESSAGING,
                testFormatType: TEST_FORMAT_TYPE.JSON_WITH_FULL_ENTITY,
                configurationComponent: GenericWebhookComponent,
                onDelete: (configurationId: string) => getIntegrationsService().deleteConfiguration(configurationId),
                onSave: (name: string, configurationObj: any, configurationId?: string) =>
                    getIntegrationsService().saveConfiguration(
                        name,
                        INTEGRATIONS_IDS.GENERIC_WEBHOOK,
                        configurationObj,
                        configurationId,
                    ),
            },
        },
        {
            id: 'amazonInspector',
            content: {
                title: 'Amazon Inspector',
                icon: integrationLogos.AMAZON_INSPECTOR,
                category: integrationCategory.CLOUD_SERVICES,
                configurationUrl: getIsCloudInfra()
                    ? 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=Risk_Calculation'
                    : 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=Risk_Calculation',
            },
        },
        {
            id: 'oneLogin',
            content: {
                title: 'OneLogin',
                icon: integrationLogos.ONELOGIN,
                category: integrationCategory.SSO_CI,
                configurationUrl: 'https://portal.checkpoint.com/dashboard/settings/identity-access',
            },
        },
        {
            id: 'pingIdentity',
            content: {
                title: 'pingIdentity',
                icon: integrationLogos.PING_IDENTITY,
                category: integrationCategory.SSO_CI,
                configurationUrl: 'https://portal.checkpoint.com/dashboard/settings/identity-access',
            },
        },
        {
            id: 'okta',
            content: {
                title: 'Okta',
                icon: integrationLogos.OKTA,
                category: integrationCategory.SSO_CI,
                configurationUrl: 'https://portal.checkpoint.com/dashboard/settings/identity-access',
            },
        },
        {
            id: 'genericSamlServer',
            content: {
                title: 'Generic Saml',
                icon: integrationLogos.GENERIC_SAML_SERVER,
                category: integrationCategory.SSO_CI,
                configurationUrl: 'https://portal.checkpoint.com/dashboard/settings/identity-access',
            },
        },
        {
            id: 'googleWorkspace',
            content: {
                title: 'Google Workspace',
                icon: integrationLogos.GOOGLE_WORKSPACE,
                category: integrationCategory.SSO_CI,
                configurationUrl: 'https://portal.checkpoint.com/dashboard/settings/identity-access',
            },
        },
        {
            id: 'due',
            content: {
                title: 'Duo',
                icon: integrationLogos.DUO,
                category: integrationCategory.SSO_CI,
                configurationUrl: 'https://portal.checkpoint.com/dashboard/settings/identity-access',
            },
        },
        {
            id: 'adfs',
            content: {
                title: 'ADFS',
                icon: integrationLogos.ADFS,
                category: integrationCategory.SSO_CI,
                configurationUrl: 'https://portal.checkpoint.com/dashboard/settings/identity-access',
            },
        },
        {
            id: 'microsoftEntraId',
            content: {
                title: 'Microsoft Entra Id',
                icon: integrationLogos.MICROSOFT_ENTRA_ID,
                category: integrationCategory.SSO_CI,
                configurationUrl: 'https://portal.checkpoint.com/dashboard/settings/identity-access',
            },
        },
        // {
        //     id: 'centrify',
        //     content: {
        //         title: 'Centrify',
        //         icon: integrationLogos.CENTRIFY,
        //         category: integrationCategory.SSO_CG,
        //         configurationUrl: generateHref('settings/authentication'),
        //     }
        // },
        // {
        //     id: 'jumpCloud',
        //     content: {
        //         title: 'Jump Cloud',
        //         icon: integrationLogos.JUMP_CLOUD,
        //         category: integrationCategory.SSO_CG,
        //         configurationUrl: generateHref('settings/authentication'),
        //     }
        // },
        {
            id: 'genericSSO',
            content: {
                title: 'Generic SSO',
                icon: integrationLogos.GENERIC_SAML_SERVER,
                category: integrationCategory.SSO_CG,
                configurationUrl: generateHref('settings/authentication'),
            },
        },
        {
            id: 'sns',
            content: {
                id: INTEGRATIONS_IDS.SNS,
                title: 'SNS',
                icon: integrationLogos.SNS,
                hasOutputTypeSelection: true,
                howToConfigureLink: HOW_TO_CONFIGURE_URLS.SNS,
                category: integrationCategory.COLLABORATIONS_AND_MESSAGING,
                configurationComponent: SnsComponent,
                onDelete: (configurationId: string) => getIntegrationsService().deleteConfiguration(configurationId),
                onSave: (name: string, configurationObj: any, configurationId?: string) =>
                    getIntegrationsService().saveConfiguration(
                        name,
                        INTEGRATIONS_IDS.SNS,
                        configurationObj,
                        configurationId,
                    ),
            },
        },
        {
            id: 'teams',
            content: {
                id: INTEGRATIONS_IDS.TEAMS,
                title: 'Teams',
                icon: integrationLogos.TEAMS,
                category: integrationCategory.COLLABORATIONS_AND_MESSAGING,
                howToConfigureLink: HOW_TO_CONFIGURE_URLS.TEAMS,
                configurationComponent: TeamsComponent,
                onDelete: (configurationId: string) => getIntegrationsService().deleteConfiguration(configurationId),
                onSave: (name: string, configurationObj: any, configurationId?: string) =>
                    getIntegrationsService().saveConfiguration(
                        name,
                        INTEGRATIONS_IDS.TEAMS,
                        configurationObj,
                        configurationId,
                    ),
                SpecialMessage: TeamsSpecialMessage,
            },
        },
        {
            id: 'slack',
            content: {
                id: INTEGRATIONS_IDS.SLACK,
                title: 'Slack',
                icon: integrationLogos.SLACK,
                category: integrationCategory.COLLABORATIONS_AND_MESSAGING,
                howToConfigureLink: HOW_TO_CONFIGURE_URLS.SLACK,
                configurationComponent: SlackComponent,
                onDelete: (configurationId: string) => getIntegrationsService().deleteConfiguration(configurationId),
                onSave: (name: string, configurationObj: any, configurationId?: string) =>
                    getIntegrationsService().saveConfiguration(
                        name,
                        INTEGRATIONS_IDS.SLACK,
                        configurationObj,
                        configurationId,
                    ),
            },
        },
        {
            id: 'email',
            content: {
                id: INTEGRATIONS_IDS.EMAIL,
                title: 'Email',
                icon: integrationLogos.EMAIL,
                category: integrationCategory.COLLABORATIONS_AND_MESSAGING,
                configurationComponent: EmailComponent,
                onDelete: (configurationId: string) => getIntegrationsService().deleteConfiguration(configurationId),
                onSave: (name: string, configurationObj: any, configurationId?: string) =>
                    getIntegrationsService().saveConfiguration(
                        name,
                        INTEGRATIONS_IDS.EMAIL,
                        configurationObj,
                        configurationId,
                    ),
            },
        },
        {
            id: 'amazonMacie',
            content: {
                title: 'Amazon Macie',
                icon: integrationLogos.AMAZON_MACIE,
                category: integrationCategory.DSPM,
                configurationUrl: getIsCloudInfra()
                    ? 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=macie_purview'
                    : 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=macie_purview',
            },
        },
        {
            id: 'aws',
            content: {
                title: 'AWS',
                icon: integrationLogos.AWS,
                category: integrationCategory.PLATFORMS_CLOUD_PROVIDERS,
                configurationUrl: generateHref('aws'),
            },
        },
        {
            id: 'azure',
            content: {
                title: 'Azure',
                icon: integrationLogos.AZURE,
                category: integrationCategory.PLATFORMS_CLOUD_PROVIDERS,
                configurationUrl: generateHref('azure-onboarding'),
            },
        },
        {
            id: 'gcp',
            content: {
                title: 'GCP',
                icon: integrationLogos.GCP,
                category: integrationCategory.PLATFORMS_CLOUD_PROVIDERS,
                configurationUrl: generateHref('gcp-onboarding'),
            },
        },
        {
            id: 'alibaba',
            content: {
                title: 'Alibaba',
                icon: integrationLogos.Alibaba,
                category: integrationCategory.PLATFORMS_CLOUD_PROVIDERS,
                configurationUrl: generateHref('cloud-add/alibaba'),
            },
        },
        {
            id: 'oci',
            content: {
                title: 'OCI',
                icon: integrationLogos.OCI,
                category: integrationCategory.PLATFORMS_CLOUD_PROVIDERS,
                configurationUrl: generateHref('oci-onboarding'),
            },
        },
        {
            id: 'Kubernetes',
            content: {
                title: 'Kubernetes',
                icon: integrationLogos.KUBERNETES,
                category: integrationCategory.PLATFORMS_CLOUD_PROVIDERS,
                configurationUrl: generateHref('workload/cloud-add/kubernetes'),
            },
        },
        {
            id: 'containerRegistry',
            content: {
                title: 'Container Registry',
                icon: integrationLogos.CONTAINER_REGISTRY,
                category: integrationCategory.PLATFORMS_CLOUD_PROVIDERS,
                configurationUrl: generateHref('workload/cloud-add/kubernetes'),
            },
        },
        {
            id: 'shiftLeft',
            content: {
                title: 'ShiftLeft',
                icon: integrationLogos.SHIFTLEFT,
                category: integrationCategory.PLATFORMS_CLOUD_PROVIDERS,
                configurationUrl: generateHref('cloud-add/shift-left'),
            },
        },
        {
            id: 'microsoftPurview',
            content: {
                title: 'Microsoft Purview',
                icon: integrationLogos.MICROSOFT_PURVIEW,
                category: integrationCategory.DSPM,
                configurationUrl: getIsCloudInfra()
                    ? 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=macie_purview'
                    : 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=macie_purview',
            },
        },
        {
            id: 'amazonGuardDuty',
            content: {
                title: 'Amazon GuardDuty',
                icon: integrationLogos.AMAZON_GUARD_DUTY,
                category: integrationCategory.CLOUD_SERVICES,
                configurationUrl: getIsCloudInfra()
                    ? 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=guardduty'
                    : 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=guardduty',
            },
        },
        {
            id: 'GCPEventArc',
            content: {
                title: 'GCP EventArc',
                icon: integrationLogos.GCP_EVENT_ARC,
                category: integrationCategory.CLOUD_SERVICES,
                configurationUrl: getIsCloudInfra()
                    ? 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=eventarc'
                    : 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=eventarc',
            },
        },
        {
            id: 'awsSecurityHub',
            content: {
                id: INTEGRATIONS_IDS.AWS_SECURITY_HUB,
                title: 'Aws Security Hub',
                icon: integrationLogos.AWS_SECURITY_HUB,
                category: integrationCategory.CLOUD_SERVICES,
                howToConfigureLink: HOW_TO_CONFIGURE_URLS.AWS_SECURITY_HUB,
                configurationComponent: AwsSecurityHubComponent,
                onDelete: (configurationId: string) => getIntegrationsService().deleteConfiguration(configurationId),
                onSave: (name: string, configurationObj: any, configurationId?: string) =>
                    getIntegrationsService().saveConfiguration(
                        name,
                        INTEGRATIONS_IDS.AWS_SECURITY_HUB,
                        configurationObj,
                        configurationId,
                    ),
            },
        },
        {
            id: 'azureDefenderForCloud',
            content: {
                id: INTEGRATIONS_IDS.AZURE_DEFENDER_FOR_CLOUD,
                title: 'Azure Defender For Cloud',
                icon: integrationLogos.AZURE_DEFENDER,
                category: integrationCategory.CLOUD_SERVICES,
                howToConfigureLink: HOW_TO_CONFIGURE_URLS.AZURE_DEFENDER_FOR_CLOUD,
                configurationComponent: AzureDefenderForCloudComponent,
                onDelete: (configurationId: string) => getIntegrationsService().deleteConfiguration(configurationId),
                onSave: (name: string, configurationObj: any, configurationId?: string) =>
                    getIntegrationsService().saveConfiguration(
                        name,
                        INTEGRATIONS_IDS.AZURE_DEFENDER_FOR_CLOUD,
                        configurationObj,
                        configurationId,
                    ),
            },
        },
        // {
        //     id: 'AzureEventHub',
        //     content: {
        //         title: 'Azure EventHub',
        //         icon: AZURE_EVENT_HUB,
        //         category: integrationCategory.CLOUD_SERVICES,
        //         configurationUrl: ''
        //     }
        //
        // },
        {
            id: 'gcpSecurityCommandCenter',
            content: {
                id: INTEGRATIONS_IDS.GCP_SECURITY_COMMAND_CENTER,
                title: 'GCP Security Command Center',
                icon: integrationLogos.GCP_SECURITY_COMMAND_CENTER,
                category: integrationCategory.CLOUD_SERVICES,
                howToConfigureLink: HOW_TO_CONFIGURE_URLS.GCP_SECURITY_COMMAND_CENTER,
                configurationComponent: GcpSecurityCommandCenterComponent,
                onDelete: (configurationId: string) => getIntegrationsService().deleteConfiguration(configurationId),
                onSave: (name: string, configurationObj: any, configurationId?: string) =>
                    getIntegrationsService().saveConfiguration(
                        name,
                        INTEGRATIONS_IDS.GCP_SECURITY_COMMAND_CENTER,
                        configurationObj,
                        configurationId,
                    ),
            },
        },
        {
            id: 'tenable',
            content: {
                id: INTEGRATIONS_IDS.TENABLE,
                title: 'Tenable',
                icon: integrationLogos.TENABLE,
                category: integrationCategory.VULNERABILITY_SECURITY_SCANNER,
                configurationComponent: TenableConfigurationComponent,
                howToConfigureLink: HOW_TO_CONFIGURE_URLS.TENABLE,
            },
        },
        {
            id: 'torq',
            content: {
                title: 'Torq',
                icon: integrationLogos.TORQ,
                category: integrationCategory.THIRD_PARTY,
                configurationUrl: 'https://learn.torq.io/docs/check-point-cloudguard',
            },
        },
        {
            id: 'seemplicity',
            content: {
                title: 'Seemplicity',
                icon: integrationLogos.SEAMPLICITY,
                category: integrationCategory.THIRD_PARTY,
                configurationUrl: 'https://seemplicity.io/integrations/',
            },
        },
        {
            id: 'devOcean',
            content: {
                title: 'devOcean',
                icon: integrationLogos.DEV_OCEAN,
                category: integrationCategory.THIRD_PARTY,
                configurationUrl: 'https://www.devocean.security/integrations',
            },
        },
        {
            id: 'tamnoon',
            content: {
                title: 'Tamnoon',
                icon: integrationLogos.TAMNOON,
                category: integrationCategory.THIRD_PARTY,
                configurationUrl: 'https://tamnoon.io/integrations/',
            },
        },
        {
            id: 'axonius',
            content: {
                title: 'Axonius',
                icon: integrationLogos.AXONIUS,
                category: integrationCategory.THIRD_PARTY,
                configurationUrl: 'https://docs.axonius.com/docs/dome9',
            },
        },
        {
            id: 'snsAudit',
            content: {
                id: INTEGRATIONS_IDS.SNS_AUDIT,
                title: 'SNS Audit',
                icon: integrationLogos.SNS,
                category: integrationCategory.SYSTEM_AUDIT,
                configurationComponent: SnsSystemComponent,
            },
        },
        {
            id: 'microsoftSentinel',
            content: {
                id: INTEGRATIONS_IDS.MICROSOFT_SENTINEL,
                title: 'Microsoft Sentinel',
                icon: integrationLogos.MICROSOFT_SENTINEL,
                category: integrationCategory.EVENTS_AND_LOGGING,
                configurationComponent: SentinelComponent,
            },
        },
    ];
    ///hiding third party integrations (CNAPP-9231)
    integrationsObj = integrationsObj.filter((item) => item.content.category !== integrationCategory.THIRD_PARTY);
    if (getIsCloudInfra()) {
        integrationsObj = integrationsObj.filter((item) => item.content.category !== integrationCategory.SSO_CG);
    } else {
        integrationsObj = integrationsObj.filter((item) => item.content.category !== integrationCategory.SSO_CI);
    }
    IntegrationsRegistry.addIntegrationsDefinition(integrationsObj);
}
