import { DrawerRegistry } from 'common/components/DrawerInfra/Drawer/DrawerRegistry';
import { AUTO_ACTION_EDITOR_DRAWER_EVENT_HANDLER_ID, AUTO_ACTION_EDITOR_KEY } from './AutoActionEditor.consts';
import { IDrawerContent } from 'common/components/DrawerInfra/Drawer/Drawer.interface';
import { AutoActionEditor } from './AutoActionEditor';
import { LevelIcon } from 'common/design-system/components-v2';
import { IAutoAction, IAutoActionEditorParams, IAutoActionProps } from '../AutoActions.interface';
import { fetchAllAutoActions } from '../AutoActions.utils';
import { ermTrans } from '../../../RiskManagement.utils';
import { DrawerBus } from 'common/components/DrawerInfra/Drawer/DrawerBus';

function initializeDrawerContent() {
    DrawerRegistry.addContentProvider({
        id: AUTO_ACTION_EDITOR_KEY,
        getDrawerContent: async (params: IAutoActionEditorParams): Promise<IDrawerContent | undefined> => {
            const onAutoActionsChange = () => {
                DrawerBus.sendEvent(params.onAutoActionsChangedHandlerId);
            };

            const allAutoActions: IAutoAction[] = await fetchAllAutoActions();
            const autoActionProps: IAutoActionProps | undefined = params.autoActionProps;
            const result: IDrawerContent = {
                title: autoActionProps?.id ? autoActionProps.name : ermTrans('AUTO_ACTIONS.EDITOR.ADD_AUTO_ACTION'),
                icon: <LevelIcon iconProps={{ name: 'automatedAction' }} />,
                component: AutoActionEditor,
                componentProps: { autoActionProps, allAutoActions, onAutoActionsChange },
            };
            return Promise.resolve(result);
        },
        options: {
            width: 'xl',
            suppressCloseOnEscape: true,
            onCloseHandlerId: AUTO_ACTION_EDITOR_DRAWER_EVENT_HANDLER_ID,
        },
    });
}

export function initializeAutoActionEditor() {
    initializeDrawerContent();
}
