import React from 'react';
import { Chip, Tooltip } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from '../../../../initialize.i18n';

const GroupImage: React.FC<{ isBaseImage: boolean; isVendorImage: boolean }> = ({ isBaseImage, isVendorImage }) => {
    const { t } = useTranslation(getK8sNamespace('protected-asset-details'));
    const tooltipBaseImage = 'This image configured as base image';
    const tooltipVendorImage = 'This image configured as vendor image';
    return (
        <>
            {isBaseImage && (
                <Tooltip content={tooltipBaseImage} placement={'top'}>
                    <Chip disableInteraction label={t('chips.baseImage')} />
                </Tooltip>
            )}

            {isVendorImage && (
                <Tooltip content={tooltipVendorImage} placement={'top'}>
                    <Chip disableInteraction label={t('chips.vendorImage')} />
                </Tooltip>
            )}
        </>
    );
};

export default GroupImage;
