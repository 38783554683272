import { Vendors } from 'common/consts/vendors';
import { useScanResults } from 'modules/workloads/protectedAsset/page/tabs/HostConfiguration/hooks/useScanResults';

export interface AwpHostConfigRuleDrawerProps {
    assetId: string;
    ruleId: string;
    cloudAccountId: string;
    provider: Vendors;
}

export enum EResult {
    LOADING = 'loading',
    ERROR = 'error',
    SUCCESS = 'success',
}

export type TResultLoading = {
    status: EResult.LOADING;
};

export type TResultError = {
    status: EResult.ERROR;
    error: string;
};

type TUseScanResults = ReturnType<typeof useScanResults>;
type TResultData = Pick<
    Exclude<TUseScanResults['benchmarkData'], undefined>,
    'benchmarkId' | 'benchmarkTitle' | 'benchmarkVersion' | 'scanDate'
> & { rule: TUseScanResults['rowData'][0] } & AwpHostConfigRuleDrawerProps;

export type TResultSuccess = {
    status: EResult.SUCCESS;
    data: TResultData;
};

export type TResult = TResultLoading | TResultError | TResultSuccess;
