import { Tooltip, Typography } from 'common/design-system/components-v2';
import React from 'react';

const ImageScanInfo: React.FC<{ text: string; tooltip?: string; date?: string }> = ({ text, tooltip, date }) => {
    const buildContent = () => {
        if (!tooltip && !date) return;
        if (tooltip && !date) return tooltip;
        if (date && !tooltip) return date;
        return `${tooltip}\n${date}`;
    };
    return (
        <Tooltip content={buildContent()} placement='bottom'>
            <Typography>{text}</Typography>
        </Tooltip>
    );
};

export default ImageScanInfo;
