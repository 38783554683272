import { Select } from '@dome9/berries/react-components';
import { AssetAggregationCacheConfig, Datasource } from 'common/components/ProtectedAssets/datasource';
import globalAddinContainer from 'common/extensibility/AddinContainer';
import React, { useEffect, useState } from 'react';
import { EXTERNAL_ADDITIONAL_FIELDS_SOURCE } from 'common/components/ProtectedAssets/ProtectedAssetsTable.consts';
import SummaryWidgetsSettings from 'common/components/Widgets/SettingsComponents/SummaryWidgetsSettings';
import { ISelectOption } from 'common/interface/general';
import { getProtectedAssetsService, IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { DashboardWidgetTypes } from 'common/module_interface/overview/Interface';
import { useTranslation } from 'react-i18next';
import { widgetsTypesOptions } from '../ProtectedAssetsWidgetsSettings';
import { defaultOUFilter } from '../WidgetsData/ProtectedAssetsDataGetter';
import { EnvironmentsAggregationLinks } from '../EnvironmentsAggregationLinks';
import { ASSETS_SEARCH_URL } from 'common/module_interface/assets/AssetsConsts';
import { renderFilterPanelForWidgets } from 'common/components/FilterPanel/DashboardFilterPanel/dashboardFilterUtils';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { SingleValue } from 'react-select';
import { PATH_PROTECTED_ASSETS_WIDGETS_FILTERS } from 'common/module_interface/assets/ProtectedAssets.consts';
import { getAssets } from 'common/assets/Assets.service';

export const ProtectedAssetsSettings: React.FC<any> = ({
    widget,
    updateWidgetCallback,
    onFilterPanelAsyncChangeFinished,
    onFilterPanelAsyncChangeStarted,
}) => {
    const includedEntityTypes = getAssets().map((asset) => asset.typeByPlatform);

    const defaultDatasourceConfig: any = {
        pageSize: 0,
        filter: {
            fields: defaultOUFilter,
            freeTextPhrase: '',
            includedEntityTypes: includedEntityTypes,
        },
        groupByUrl: '',
        searchUrl: ASSETS_SEARCH_URL,
        externalAdditionalFields: {
            source: EXTERNAL_ADDITIONAL_FIELDS_SOURCE.THIRD_PARTY,
        },
    };
    const [filterPanelComponent, setFilterPanelComponent] = useState(<div></div>);
    const [isLoading, setIsLoading] = useState(true);
    const FILTER_PANEL_ID = 'events-widgets-filter-panel-id';
    const { t } = useTranslation();

    function getAggregationsFunction(
        defaultDatasourceConfig: any,
        includedEntityTypes: string[],
    ): (filtersValues: IFiltersValues) => Promise<Aggregations> {
        return async (filtersValues: IFiltersValues) => {
            const tempDatasource = new Datasource({
                ...defaultDatasourceConfig,
                ...{
                    filters: {
                        ...filtersValues,
                        includedEntityTypes,
                    },
                    cachingConfig: AssetAggregationCacheConfig,
                },
            });
            const searchResponse = await tempDatasource.getAdHokDataFromServer();
            return searchResponse.aggregations;
        };
    }

    const handleFilterChange = React.useCallback(
        (filterValues: any) => {
            if (JSON.stringify(widget.filterState || '') === JSON.stringify(filterValues || '')) return;
            widget.filterState = filterValues;
            updateWidgetCallback(widget);
        },
        [widget, updateWidgetCallback],
    );

    useEffect(() => {
        const filters = globalAddinContainer.get<IProtectedAssetFilter>(PATH_PROTECTED_ASSETS_WIDGETS_FILTERS);
        async function initPage() {
            const tempDatasource = new Datasource({ ...defaultDatasourceConfig });
            const initialData = await tempDatasource.getAdHokDataFromServer();
            const filterPanel =
                initialData &&
                (await renderFilterPanelForWidgets(
                    FILTER_PANEL_ID,
                    filters,
                    getAggregationsFunction,
                    getProtectedAssetsService().getFilterDefsForWidgets,
                    widget,
                    onFilterPanelAsyncChangeFinished,
                    onFilterPanelAsyncChangeStarted,
                    handleFilterChange,
                    initialData.aggregations,
                    widget.filterState,
                ));

            filterPanel && setFilterPanelComponent(filterPanel);

            setIsLoading(false);
        }

        initPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {widget.type === DashboardWidgetTypes.Summary && (
                <SummaryWidgetsSettings widget={widget} updateWidgetCallback={updateWidgetCallback} />
            )}
            {widget.type === DashboardWidgetTypes.Top &&
                widgetsTypesOptions['top'].additionalFields!({ widget, updateWidgetCallback })}
            {widget.aggregation === 'cloudAccountId' && (
                <EnvironmentsAggregationLinks widget={widget} updateWidgetCallback={updateWidgetCallback} />
            )}
            <div className='mt-8'>
                {isLoading ? <span>{t('GENERAL.LOADING_FILTERS')}</span> : <div>{filterPanelComponent}</div>}
            </div>
        </>
    );
};

export const AssetsTopWidgetsSettings: React.FC<any> = ({ widget, updateWidgetCallback }) => {
    const widgetTypeOptions = [
        { label: '5', value: 5 },
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '50', value: 50 },
    ];
    const selectedLimit = { value: widget.limit || 10 };
    const { t } = useTranslation();
    const handleChangeWidgetType = (option: any) => {
        widget.limit = option.value;
        updateWidgetCallback(widget);
    };
    return (
        <div>
            <div className='font-semibold mb-2'>Items Count</div>
            <Select
                required={false}
                value={widgetTypeOptions?.filter((option) => option.value === selectedLimit.value) as any} // TODO: fix this
                onChange={(option: SingleValue<ISelectOption>) => handleChangeWidgetType(option)}
                closeMenuOnSelect={true}
                placeholder={t('GENERAL.LOADING')}
                isSearchable={true}
                options={widgetTypeOptions}
            />
            {ProtectedAssetsSettings({ widget, updateWidgetCallback })}
        </div>
    );
};
