import styled from 'styled-components';

const SnsStyled = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const SnsNameWrapper = styled.div`
    width: 50%;
    margin: 24px;
`;

const SnsEndpointUrlWrapper = styled.div`
    display: flex;
    margin: 0 24px;
    align-items: flex-end;
`;

const SnsEndpointUrlInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export { SnsStyled, SnsNameWrapper, SnsEndpointUrlWrapper, SnsEndpointUrlInputWrapper };
