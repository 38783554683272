import { GcpEnvironmentData } from 'common/module_interface/assets/GcpEnvironment';

export interface GcpState {
    gcpEvnironments: {
        [gcpId: string]: {
            data?: GcpEnvironmentData;
            loading?: boolean;
            error?: boolean;
            deleted?: boolean;
        };
    };
}

export const GcpInitialState: GcpState = {
    gcpEvnironments: {},
};
