import React, { useState } from 'react';
import ImagesOnWorkload from '../ImagesOnWorkload/ImagesOnWorkload';
import { Image } from 'modules/workloads/services/workload/workload.interface';
import SbomTableWithFilter from '../SbomTableWithFilter/SbomTableWithFilter';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { useImagesByWorkload } from '../../../../../services/workload/hooks/useImagesByWorkload';
import EmptyStateWrapper from '../../EmptyStateWrapper/EmptyStateWrapper';

const WorkloadSbom: React.FC<{ dome9Id: string; environmentId: string; cloudEntityData: ICloudEntityData }> = ({
    dome9Id,
    environmentId,
    cloudEntityData,
}) => {
    const [selectedImage, setSelectedImage] = useState<Image | null>(null);
    const { data, isError, isLoading } = useImagesByWorkload(dome9Id);

    if (((data && data.length === 0) || isError) && !isLoading) return <EmptyStateWrapper />;

    return (
        <>
            {data && (
                <ImagesOnWorkload data={data} onClickSelectImage={setSelectedImage} selectedImage={selectedImage} />
            )}
            {selectedImage !== null && (
                <SbomTableWithFilter
                    entityName={selectedImage?.name || ''}
                    cloudEntityData={cloudEntityData}
                    entityId={selectedImage?.imageId || ''}
                    entityType={selectedImage?.type || ''}
                    environmentId={environmentId}
                />
            )}
        </>
    );
};

export default WorkloadSbom;
