import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-community';
import { HandlerFunc } from './types';
import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';

export class DatasourceEngine implements IServerSideDatasource {
    private handler: HandlerFunc;

    constructor(handler: HandlerFunc) {
        this.handler = handler;
    }

    getRows(params: IServerSideGetRowsParams) {
        const columns: CGColDef[] = params.columnApi.getColumns()?.map((column) => column.getColDef()) ?? [];
        this.handler({ ...params.request, columnsDefs: columns })
            .then((data) => {
                if (data.rowData.length === 0) {
                    params.api.showNoRowsOverlay();
                } else {
                    params.api.hideOverlay();
                }
                params.success(data);
            })
            .catch((error) => {
                console.error('Failed loading server side request', error);
                params.fail();
            });
    }
}
