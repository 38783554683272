import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClusterSelectorProps } from './ScanningEnvironmentSelector.interface';
import { ALink } from 'common/components/ALink';
import { ScanningEnvironmentType } from '../../../../../utils/RegistryOptions/RegistryOptions';
import { A } from 'common/components/Anchor/Anchor';
import { SelectV2 as Select } from 'common/design-system/components-v2';

const ScanningEnvironmentSelector: React.FC<ClusterSelectorProps> = ({
    selectedCluster,
    selectedClusterError,
    scanningEnvironments,
    setSelectedScanningEnvironment,
    scanningEnvironmentTypeIcon,
    scanningEnvironmentType,
    isLoading,
}) => {
    const { t } = useTranslation('k8s_registry');

    const options = scanningEnvironments.map((environment) => ({
        label: `${environment.name} (${environment.id})`,
        value: environment.id,
        iconProps: { vendorNameOrPath: scanningEnvironmentTypeIcon },
    }));

    return (
        <div className='input-container'>
            <Select
                label={t('ON_BOARDING.CHOOSE_ENVIRONMENT_TO_HOST_THE_SCANNER')}
                required
                fullWidth
                isMulti={false}
                options={options}
                disabled={isLoading}
                onChange={(value) => setSelectedScanningEnvironment(value)}
                isError={selectedClusterError}
                value={options.find((option) => option.value === selectedCluster?.id)?.value}
                placeholder={t(
                    `ON_BOARDING.${isLoading ? 'selectScanningEnvironmentLoading' : 'selectScanningEnvironmentPlaceHolder'}`,
                )}
            />
            {scanningEnvironmentType === ScanningEnvironmentType.Kubernetes && (
                <ALink>
                    <A href='/workload/cloud-add/kubernetes'>{t('ON_BOARDING.onBoardANewKubernetesCluster')}</A>
                </ALink>
            )}
        </div>
    );
};

export default ScanningEnvironmentSelector;
