import { IconComponentProps } from 'common/design-system/components-v2/Icon/Icon.types';
import { CONTEXT_FIELD_FULL_NAMES } from '../../consts';
import { IFilterFieldsProps } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';

export enum WAFDistributionProtection {
    NO_WAF = 'withoutWafRiskDistribution',
    CURRENT = 'currentRiskDistribution',
    WITH_WAF = 'withWafRiskDistribution',
}

export enum WafProtectionEnum {
    PREVENT = 'Prevent',
    DETECT = 'Detect',
    DISABLED = 'Disabled',
    NONE = 'None',
}

interface IWafProtectionInfo {
    id: string;
    title: string;
    iconProps?: IconComponentProps;
    bg?: string;
    useChip?: boolean;
    isProtected: boolean;
}

export const WafProtectionMap: { [key in WafProtectionEnum]: IWafProtectionInfo } = {
    None: {
        id: WafProtectionEnum.NONE,
        title: 'None',
        isProtected: false,
    },
    Disabled: {
        id: WafProtectionEnum.DISABLED,
        title: 'Disabled',
        useChip: true,
        isProtected: false,
    },
    Detect: {
        id: WafProtectionEnum.DETECT,
        title: 'Detect',
        iconProps: { name: 'shieldWithCircle', size: 16 },
        useChip: true,
        isProtected: true,
    },
    Prevent: {
        id: WafProtectionEnum.PREVENT,
        title: 'Prevent',
        iconProps: { name: 'shieldChecked', size: 16 },
        bg: '#54698B',
        useChip: true,
        isProtected: true,
    },
};

const getAllWAFProtectionInfos = (): IWafProtectionInfo[] => {
    return Object.values(WafProtectionMap);
};

export const getSafeWafProtectionInfo = (wafProtection?: WafProtectionEnum | string): IWafProtectionInfo => {
    if (!wafProtection) return WafProtectionMap.None;
    return WafProtectionMap[wafProtection as WafProtectionEnum] || WafProtectionMap.None;
};

export const getWAFProtectionFilters = (): IFilterFieldsProps[] => {
    return getAllWAFProtectionInfos()
        .filter((wafProtectionInfo) => wafProtectionInfo.isProtected)
        .map((wafProtectionInfo) => {
            return { name: CONTEXT_FIELD_FULL_NAMES.wafProtection, value: wafProtectionInfo.id };
        });
};
