import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RegistryURIFieldProps } from './RegistryURIField.interface';
import RegistryURIInput from './RegistryURIInput';
import RegistryURISelector from './RegistryURISelector';
import { RegistryType } from 'modules/workloads/utils/RegistryOptions/RegistryOptions';
import { Input } from 'common/design-system/components-v2';

const registryURIFormats = {
    ACR: '<b><i>acrName</b></i>.azurecr.io',
    ECR: '<b><i>aws_account_id</b></i>.dkr.ecr.<b><i>region</b></i>.amazonaws.com',
    GCR: null,
    GAR: null,
    Artifactory: 'the base URL (e.g. "MyArtifactory.jfrog.com")',
    Harbor: null,
    Nexus: 'resolvable hostname or the IP of your Nexus instance (e.g. myNexus.nexus.com, 172.2.2.2:8080)',
    GitHub: 'ghcr.io',
    Quay: 'quay.io/<b><i>organization</b></i>',
    DockerHub: 'hub.docker.com/<b><i>organization</b></i>',
};

const RegistryURIField: React.FC<RegistryURIFieldProps> = (props) => {
    const { registryType, registryUrl, onChange } = props;
    // const { t } = useTranslation();
    const { t } = useTranslation();
    const registryURITooltip = `${t('ON_BOARDING.REGISTRY.REGISTRY_URI_TOOLTIP')} ${registryURIFormats[registryType]}`;
    const registryURITooltipContent = registryURIFormats[registryType] ? (
        <div dangerouslySetInnerHTML={{ __html: registryURITooltip }}></div>
    ) : undefined;
    const registryURIField = useMemo(() => {
        switch (registryType) {
            case RegistryType.ACR:
            case RegistryType.ECR:
            case RegistryType.GAR:
            case RegistryType.JFrogArtifactory:
            case RegistryType.Harbor:
            case RegistryType.Nexus:
            case RegistryType.Quay:
            case RegistryType.DockerHub:
                return (
                    <RegistryURIInput
                        registryType={registryType}
                        registryUrl={registryUrl}
                        onChange={onChange}
                        tooltipContent={registryURITooltipContent}
                    />
                );
            case RegistryType.GCR:
                return <RegistryURISelector onChange={onChange} registryUrl={registryUrl} />;
            case RegistryType.GitHub:
                return (
                    <Input
                        label={t('k8s_registry:ON_BOARDING.registryConfigFormLabels.registryUri')}
                        value='ghcr.io'
                        readOnly
                        fullWidth
                    />
                );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChange, registryType, registryUrl]);

    return <div className='input-container'>{registryURIField}</div>;
};

export default RegistryURIField;
