import { BillingProviders, IUser } from 'common/interface/user';

export const getDefaultUserValues = (): IUser => ({
    id: 0,
    name: '',
    email: '',
    rolesNames: [],
    ssoEnabled: false,
    isMfaEnabled: false,
    hasApiKey: false,
    isMobileDevicePaired: false,
    lastLogin: '',
    isSuperUser: false,
    accountId: 0,
    calculatedPermissions: {},
    canSwitchRole: false,
    account: {
        plan: '',
        billingProvider: BillingProviders.Dome9,
        crossAccountIdentifier: false,
    },
    hasApiKeyV1: false,
    hasApiKeyV2: false,
    iamSafe: { cloudAccounts: [] },
    isAuditor: false,
    isLocked: false,
    isOwner: false,
    isSuspended: false,
    permissions: {},
    apiKeys: [],
    mfaEnforcement: { isEnforced: false },
    roleIds: [],
});
