import { DrawerRegistry } from 'common/components/DrawerInfra/Drawer/DrawerRegistry';
import { DRAWER_ID } from './AwpHostConfigRuleDrawer.consts';
import { LevelIcon } from 'common/design-system/components-v2';
import { AwpHostConfigRuleDrawerProps } from './AwpHostConfigRuleDrawer.types';
import RuleContent from './components/RuleContent';
import { RuleProvider } from './context/Rule/RuleProvider';

export function initializeAwpHostConfigRuleDrawer() {
    DrawerRegistry.addContentProvider({
        id: DRAWER_ID,
        getDrawerContent: async ({ ruleId, assetId, cloudAccountId, provider }: AwpHostConfigRuleDrawerProps) => {
            return {
                title: ruleId,
                icon: <LevelIcon iconProps={{ name: 'rule' }} category='severity' level='unknown' />,
                component: () => (
                    <RuleProvider assetId={assetId} cloudAccountId={cloudAccountId} provider={provider} ruleId={ruleId}>
                        <RuleContent />
                    </RuleProvider>
                ),
            };
        },
    });
}
