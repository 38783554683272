import { Fragment, FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import { initialLogin } from '../auth/Auth.actions';
let loginStarted = false;
const AuthenticatedPage: FunctionComponent<PropsWithChildren<any>> = (props: PropsWithChildren<any>) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        if (loginStarted) return;
        loginStarted = true;
        initialLogin().then((value) => {
            if (value) setIsLoggedIn(value);
        });
    }, []);

    return <Fragment>{isLoggedIn ? props.children : <></>}</Fragment>;
};

export default AuthenticatedPage;
