import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Icon, Markdown, Tooltip } from 'common/design-system/components-v2';
import { IEvent } from 'common/module_interface/events/Events';

const RemediationCellRenderer: React.FC<ICellRendererParams<IEvent>> = (params) => {
    const { remediation } = params.data || {};

    if (!remediation) return null;

    return (
        <Tooltip content={<Markdown markdown={remediation} />} interactive placement='auto'>
            <Icon name='remedy' color='brandPrimary' />
        </Tooltip>
    );
};

export default RemediationCellRenderer;
