import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../../../../consts';
import { AttrStyled } from './AutoActionEditorAttr.styled';
import { EditorStyled } from '../../AutoActionEditor.styled';
import Input from 'common/design-system/components-v2/Input';
import { Stack, TextArea } from 'common/design-system/components-v2';
import { IAttrProps } from '../../AutoActionEditor.interface';
import { IAutoAction } from '../../../AutoActions.interface';
import { getNameErrorMsg } from '../../AutoActionEditor.utils';
import { errorPropsHasMessages } from '../../../AutoActions.utils';
import { useIsReadOnlyAutoActions } from '../../../useIsAutoActionReadonly';

interface IAttrErrors {
    nameErrorMsg?: string;
}

export const AutoActionEditorAttr: React.FC<{
    exposeErrors: boolean;
    onSave: () => void;
    attrProps: IAttrProps;
    setAttrProps: (props: IAttrProps) => void;
    otherAutoActions: IAutoAction[];
}> = ({ exposeErrors, onSave, attrProps, setAttrProps, otherAutoActions }) => {
    const isReadOnly = useIsReadOnlyAutoActions();
    const errorsRef = useRef<IAttrErrors>({});
    const nameRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation(I18nRiskNamespace);

    const onNameKeyUp = useCallback(
        (event: any) => {
            if (event?.key === 'Enter') {
                onSave();
            }
        },
        [onSave],
    );

    const updateErrors = useCallback(
        (theAttrProps: IAttrProps) => {
            errorsRef.current.nameErrorMsg = getNameErrorMsg(theAttrProps.name, otherAutoActions);
            attrProps.errorElementRefs = errorsRef.current.nameErrorMsg && nameRef.current ? [nameRef.current] : [];
            theAttrProps.hasErrors = errorPropsHasMessages(errorsRef.current);
        },
        [attrProps, otherAutoActions],
    );

    const onNameChange = useCallback(
        (name: string) => {
            const newProps = {
                ...attrProps,
                name,
            };
            updateErrors(newProps);
            setAttrProps(newProps);
        },
        [attrProps, setAttrProps, updateErrors],
    );

    const onDescriptionChange = useCallback(
        (description: string) => {
            const newProps = {
                ...attrProps,
                description,
            };
            updateErrors(newProps);
            setAttrProps(newProps);
        },
        [attrProps, setAttrProps, updateErrors],
    );

    useEffect(() => {
        updateErrors(attrProps);
    }, [updateErrors, attrProps]);

    return (
        <AttrStyled.TopDiv spacing={5}>
            <EditorStyled.AreaDiv spacing={1}>
                <EditorStyled.LabelDiv required text={t('AUTO_ACTIONS.EDITOR.ATTR.NAME')} />
                <Input
                    value={attrProps.name}
                    onChange={(e) => onNameChange(e.target.value)}
                    onKeyUp={(event) => onNameKeyUp(event)}
                    placeholder={isReadOnly ? undefined : t('AUTO_ACTIONS.EDITOR.ATTR.NAME_PLACEHOLDER')}
                    isError={!!(exposeErrors && errorsRef.current.nameErrorMsg)}
                    helperText={
                        exposeErrors && errorsRef.current.nameErrorMsg ? errorsRef.current.nameErrorMsg : undefined
                    }
                    readOnly={isReadOnly}
                    ref={nameRef}
                />
            </EditorStyled.AreaDiv>
            <Stack spacing={1}>
                <EditorStyled.LabelDiv text={t('AUTO_ACTIONS.EDITOR.ATTR.DESCRIPTION')} />
                <TextArea
                    onChange={(e) => onDescriptionChange(e.target.value)}
                    placeholder={isReadOnly ? undefined : t('AUTO_ACTIONS.EDITOR.ATTR.DESCRIPTION_PLACEHOLDER')}
                    readOnly={isReadOnly}
                    value={attrProps.description}
                />
            </Stack>
        </AttrStyled.TopDiv>
    );
};
