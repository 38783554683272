import { DashboardWidgetTypes, IDashboard } from 'common/module_interface/overview/Interface';

import { v4 } from 'uuid';
import { ICloudAccount } from 'common/interface/data_services';
import { LOCAL_AWS_DEFAULT_DASHBOARD } from '../Consts';
import { DEFAULT_ORGANIZATIONAL_UNIT_ID } from 'common/consts/DataConsts';

export default function initializeAWSDefaultDashboard(allCloudAccounts: ICloudAccount[]) {
    const mappedCloudAccountsByExternalId = allCloudAccounts.map((cloudAccount) => cloudAccount.externalId);

    const awsDefaultDashboard: IDashboard = {
        id: LOCAL_AWS_DEFAULT_DASHBOARD,
        name: LOCAL_AWS_DEFAULT_DASHBOARD,
        isLocalDashboard: true,
        sections: [
            {
                isExpanded: true,
                id: 'inventory',
                title: 'INVENTORY',
                dashboardId: LOCAL_AWS_DEFAULT_DASHBOARD,
                position: 1,
                widgets: [
                    {
                        id: v4(),
                        sectionId: 'inventory',
                        dashboardId: LOCAL_AWS_DEFAULT_DASHBOARD,
                        type: DashboardWidgetTypes.Summary,
                        title: 'Inventory',
                        limit: 10,
                        description: 'Total Protected Assets',
                        hideOnNoData: false,
                        aggregation: null,
                        dataSourceName: 'Protected Assets',
                        gslFilter: '',
                        cloudAccountId: null,
                        timeFrame: 1,
                        filterState: [
                            {
                                name: 'organizationalUnitId',
                                value: DEFAULT_ORGANIZATIONAL_UNIT_ID,
                            },
                            {
                                name: 'platform',
                                value: 'aws',
                            },
                        ],
                        options: {
                            id: v4(),
                            sizes: {
                                height: 1,
                                width: 1,
                            },
                            navigateOption: 'Protected Assets',
                            binSize: '10m',
                            thresholds: [],
                        },
                    },
                    {
                        id: v4(),
                        sectionId: 'inventory',
                        dashboardId: LOCAL_AWS_DEFAULT_DASHBOARD,
                        type: DashboardWidgetTypes.Top,
                        title: 'Protected assets by Environment',
                        limit: 10,
                        description: 'Total Protected Assets',
                        hideOnNoData: false,
                        aggregation: 'cloudAccountId',
                        dataSourceName: 'Protected Assets',
                        gslFilter: '',
                        cloudAccountId: null,
                        timeFrame: 1,
                        filterState: [
                            {
                                name: 'organizationalUnitId',
                                value: DEFAULT_ORGANIZATIONAL_UNIT_ID,
                            },
                            {
                                name: 'platform',
                                value: 'aws',
                            },
                        ],
                        options: {
                            id: v4(),
                            sizes: {
                                height: 1,
                                width: 2,
                            },
                            navigateOption: 'Protected Assets',
                            binSize: '10m',
                        },
                    },
                    {
                        id: v4(),
                        sectionId: 'inventory',
                        dashboardId: LOCAL_AWS_DEFAULT_DASHBOARD,
                        type: DashboardWidgetTypes.Top,
                        title: 'Top Protected Assets by type',
                        limit: 50,
                        description: '',
                        hideOnNoData: false,
                        aggregation: 'type',
                        dataSourceName: 'Protected Assets',
                        gslFilter: '',
                        cloudAccountId: null,
                        timeFrame: 1,
                        filterState: [
                            {
                                name: 'organizationalUnitId',
                                value: DEFAULT_ORGANIZATIONAL_UNIT_ID,
                            },
                            {
                                name: 'platform',
                                value: 'aws',
                            },
                        ],
                        options: {
                            id: v4(),
                            sizes: {
                                height: 1,
                                width: 2,
                            },
                            navigateOption: 'Protected Assets',
                            binSize: '10m',
                        },
                    },
                    {
                        id: v4(),
                        sectionId: 'inventory',
                        dashboardId: LOCAL_AWS_DEFAULT_DASHBOARD,
                        type: DashboardWidgetTypes.Pie,
                        title: 'Protected assets by region',
                        limit: 5,
                        description: 'Total Protected Assets',
                        hideOnNoData: false,
                        aggregation: 'region',
                        dataSourceName: 'Protected Assets',
                        gslFilter: '',
                        cloudAccountId: null,
                        timeFrame: 1,
                        filterState: [
                            {
                                name: 'organizationalUnitId',
                                value: DEFAULT_ORGANIZATIONAL_UNIT_ID,
                            },
                            {
                                name: 'platform',
                                value: 'aws',
                            },
                        ],
                        options: {
                            id: v4(),
                            sizes: {
                                height: 1,
                                width: 2,
                            },
                            navigateOption: 'Protected Assets',
                            binSize: '10m',
                        },
                    },
                ],
            },
            {
                isExpanded: true,
                id: 'postureManagement',
                title: 'POSTURE MANAGEMENT',
                dashboardId: LOCAL_AWS_DEFAULT_DASHBOARD,
                position: 2,
                widgets: [
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: LOCAL_AWS_DEFAULT_DASHBOARD,
                        type: DashboardWidgetTypes.Top,
                        title: 'Assets with most high severity alerts',
                        limit: 50,
                        description: '',
                        hideOnNoData: false,
                        aggregation: 'entity',
                        dataSourceName: 'Alerts',
                        gslFilter: '',
                        cloudAccountId: null,
                        timeFrame: 1,
                        filterState: [
                            {
                                name: 'organizationalUnitId',
                                value: DEFAULT_ORGANIZATIONAL_UNIT_ID,
                            },
                            {
                                name: 'severity',
                                value: 'High',
                            },
                            {
                                name: 'severity',
                                value: 'Critical',
                            },
                            {
                                name: 'cloudAccountType',
                                value: 1,
                            },
                            {
                                name: 'acknowledged',
                                value: 'false',
                            },
                            {
                                name: 'isExcluded',
                                value: 'false',
                            },
                        ],
                        options: {
                            id: v4(),
                            sizes: {
                                height: 2,
                                width: 2,
                            },
                            navigateOption: 'Alerts',
                            binSize: '10m',
                        },
                    },
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: LOCAL_AWS_DEFAULT_DASHBOARD,
                        type: DashboardWidgetTypes.Latest,
                        title: 'Latest high and critical alerts',
                        limit: 50,
                        description: '',
                        hideOnNoData: false,
                        aggregation: 'ruleName',
                        dataSourceName: 'Alerts',
                        gslFilter: '',
                        cloudAccountId: null,
                        timeFrame: 1,
                        filterState: [
                            {
                                name: 'organizationalUnitId',
                                value: DEFAULT_ORGANIZATIONAL_UNIT_ID,
                            },
                            {
                                name: 'severity',
                                value: 'High',
                            },
                            {
                                name: 'severity',
                                value: 'Critical',
                            },
                            {
                                name: 'cloudAccountType',
                                value: 1,
                            },
                            {
                                name: 'acknowledged',
                                value: 'false',
                            },
                            {
                                name: 'isExcluded',
                                value: 'false',
                            },
                        ],
                        options: {
                            id: v4(),
                            sizes: {
                                height: 2,
                                width: 2,
                            },
                            navigateOption: 'Alerts',
                            binSize: '10m',
                        },
                    },
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: LOCAL_AWS_DEFAULT_DASHBOARD,
                        type: DashboardWidgetTypes.Top,
                        title: 'Environments with high severity alerts',
                        limit: 10,
                        description: '',
                        hideOnNoData: false,
                        aggregation: 'cloudAccountId_calc',
                        dataSourceName: 'Alerts',
                        gslFilter: '',
                        cloudAccountId: null,
                        timeFrame: 1,
                        filterState: [
                            {
                                name: 'organizationalUnitId',
                                value: DEFAULT_ORGANIZATIONAL_UNIT_ID,
                            },
                            {
                                name: 'severity',
                                value: 'High',
                            },
                            {
                                name: 'severity',
                                value: 'Critical',
                            },
                            {
                                name: 'cloudAccountType',
                                value: 1,
                            },
                            {
                                name: 'acknowledged',
                                value: 'false',
                            },
                            {
                                name: 'isExcluded',
                                value: 'false',
                            },
                        ],
                        options: {
                            id: v4(),
                            sizes: {
                                height: 2,
                                width: 2,
                            },
                            navigateOption: 'Alerts',
                            binSize: '10m',
                        },
                    },
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: LOCAL_AWS_DEFAULT_DASHBOARD,
                        type: DashboardWidgetTypes.Top,
                        title: 'External findings',
                        limit: 10,
                        description: '',
                        hideOnNoData: false,
                        aggregation: 'ruleName',
                        dataSourceName: 'Alerts',
                        gslFilter: '',
                        cloudAccountId: null,
                        timeFrame: 1,
                        filterState: [
                            {
                                name: 'organizationalUnitId',
                                value: DEFAULT_ORGANIZATIONAL_UNIT_ID,
                            },
                            {
                                name: 'cloudAccountType',
                                value: 1,
                            },
                            {
                                name: 'origin',
                                value: '102',
                            },
                        ],
                        options: {
                            id: v4(),
                            sizes: {
                                height: 1,
                                width: 2,
                            },
                            navigateOption: 'Alerts',
                            binSize: '10m',
                        },
                    },
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: LOCAL_AWS_DEFAULT_DASHBOARD,
                        type: DashboardWidgetTypes.Top,
                        title: 'Compute Alerts',
                        limit: 50,
                        description: '',
                        hideOnNoData: false,
                        aggregation: 'entity',
                        dataSourceName: 'Alerts',
                        gslFilter: '',
                        cloudAccountId: null,
                        timeFrame: 1,
                        filterState: [
                            {
                                name: 'organizationalUnitId',
                                value: DEFAULT_ORGANIZATIONAL_UNIT_ID,
                            },
                            {
                                name: 'severity',
                                value: 'High',
                            },
                            {
                                name: 'severity',
                                value: 'Critical',
                            },
                            {
                                name: 'entityTypeByEnvironmentType',
                                value: '10|VMInstance',
                            },
                            {
                                name: 'entityTypeByEnvironmentType',
                                value: '1|instance',
                            },
                            {
                                name: 'entityTypeByEnvironmentType',
                                value: '1|Instance',
                            },
                            {
                                name: 'cloudAccountType',
                                value: 1,
                            },
                            {
                                name: 'acknowledged',
                                value: 'false',
                            },
                            {
                                name: 'isExcluded',
                                value: 'false',
                            },
                        ],
                        options: {
                            id: v4(),
                            sizes: {
                                height: 1,
                                width: 2,
                            },
                            navigateOption: 'Alerts',
                            binSize: '10m',
                        },
                    },
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: LOCAL_AWS_DEFAULT_DASHBOARD,
                        type: DashboardWidgetTypes.Top,
                        title: 'Storage with high severity alerts',
                        limit: 50,
                        description: 'High risks on storage',
                        hideOnNoData: false,
                        aggregation: 'entity',
                        dataSourceName: 'Alerts',
                        gslFilter: '',
                        cloudAccountId: null,
                        timeFrame: 1,
                        filterState: [
                            {
                                name: 'organizationalUnitId',
                                value: DEFAULT_ORGANIZATIONAL_UNIT_ID,
                            },
                            {
                                name: 'severity',
                                value: 'High',
                            },
                            {
                                name: 'severity',
                                value: 'Critical',
                            },
                            {
                                name: 'entityTypeByEnvironmentType',
                                value: '1|S3Bucket',
                            },
                            {
                                name: 'entityTypeByEnvironmentType',
                                value: '7|StorageAccount',
                            },
                            {
                                name: 'entityTypeByEnvironmentType',
                                value: '1|S3',
                            },
                            {
                                name: 'entityTypeByEnvironmentType',
                                value: '10|StorageBucket',
                            },
                            {
                                name: 'cloudAccountType',
                                value: 1,
                            },
                            {
                                name: 'acknowledged',
                                value: 'false',
                            },
                            {
                                name: 'isExcluded',
                                value: 'false',
                            },
                        ],
                        options: {
                            id: v4(),
                            sizes: {
                                height: 1,
                                width: 2,
                            },
                            navigateOption: 'Alerts',
                            binSize: '10m',
                        },
                    },
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: LOCAL_AWS_DEFAULT_DASHBOARD,
                        type: DashboardWidgetTypes.Top,
                        title: 'Entities with encryption and key management alerts',
                        limit: 50,
                        description: '',
                        hideOnNoData: false,
                        aggregation: 'entity',
                        dataSourceName: 'Alerts',
                        gslFilter: '',
                        cloudAccountId: null,
                        timeFrame: 1,
                        filterState: [
                            {
                                name: 'organizationalUnitId',
                                value: DEFAULT_ORGANIZATIONAL_UNIT_ID,
                            },
                            {
                                name: 'severity',
                                value: 'High',
                            },
                            {
                                name: 'severity',
                                value: 'Critical',
                            },
                            {
                                name: 'cloudAccountType',
                                value: 1,
                            },
                            {
                                name: 'acknowledged',
                                value: 'false',
                            },
                            {
                                name: 'isExcluded',
                                value: 'false',
                            },
                            {
                                name: 'free-text',
                                value: 'Encryption and Key Management',
                            },
                        ],
                        options: {
                            id: v4(),
                            sizes: {
                                height: 1,
                                width: 2,
                            },
                            navigateOption: 'Alerts',
                            binSize: '10m',
                        },
                    },
                ],
            },
            {
                isExpanded: true,
                id: 'intelligenceAndThreatHunting',
                title: 'INTELLIGENCE AND THREAT HUNTING',
                dashboardId: LOCAL_AWS_DEFAULT_DASHBOARD,
                position: 3,
                widgets: [
                    {
                        id: v4(),
                        sectionId: 'intelligenceAndThreatHunting',
                        dashboardId: LOCAL_AWS_DEFAULT_DASHBOARD,
                        type: DashboardWidgetTypes.Top,
                        title: 'Latest high and critical events',
                        limit: 50,
                        description: '',
                        hideOnNoData: false,
                        aggregation: 'event.name',
                        dataSourceName: 'Event Activity (AWS)',
                        gslFilter: '',
                        cloudAccountId: '__ALL_ACCOUNTS__',
                        timeFrame: 1,
                        filterState: [],
                        options: {
                            id: v4(),
                            sizes: {
                                height: 2,
                                width: 2,
                            },
                            navigateOption: 'Event Activity (AWS)',
                            binSize: '10m',
                        },
                    },
                    {
                        id: v4(),
                        sectionId: 'intelligenceAndThreatHunting',
                        dashboardId: LOCAL_AWS_DEFAULT_DASHBOARD,
                        type: DashboardWidgetTypes.Top,
                        title: 'Assets with most events in the last day',
                        limit: 50,
                        description: '',
                        hideOnNoData: false,
                        aggregation: 'identity.name',
                        dataSourceName: 'Event Activity (AWS)',
                        gslFilter: '',
                        cloudAccountId: '__ALL_ACCOUNTS__',
                        timeFrame: 24,
                        filterState: [],
                        options: {
                            id: v4(),
                            sizes: {
                                height: 2,
                                width: 2,
                            },
                            navigateOption: 'Event Activity (AWS)',
                            binSize: '10m',
                        },
                    },
                    {
                        id: v4(),
                        sectionId: 'intelligenceAndThreatHunting',
                        dashboardId: LOCAL_AWS_DEFAULT_DASHBOARD,
                        type: DashboardWidgetTypes.Trend,
                        title: 'Alerts Trendline',
                        limit: 5,
                        description: '',
                        hideOnNoData: true,
                        aggregation: null,
                        dataSourceName: 'Alerts',
                        gslFilter: '',
                        cloudAccountId: null,
                        timeFrame: 1,
                        filterState: [],
                        options: {
                            alertTrendOptions: [
                                {
                                    type: 'Organizational Unit',
                                    title: 'Medium',
                                    selected: mappedCloudAccountsByExternalId,
                                    selectedName: DEFAULT_ORGANIZATIONAL_UNIT_ID,
                                    widgetConfigFilterState: [
                                        {
                                            name: 'severity',
                                            value: 'Medium',
                                        },
                                        {
                                            name: 'origin',
                                            value: 1,
                                        },
                                    ],
                                },
                                {
                                    type: 'Organizational Unit',
                                    title: 'High',
                                    selected: mappedCloudAccountsByExternalId,
                                    selectedName: DEFAULT_ORGANIZATIONAL_UNIT_ID,
                                    widgetConfigFilterState: [
                                        {
                                            name: 'severity',
                                            value: 'High',
                                        },
                                        {
                                            name: 'origin',
                                            value: 1,
                                        },
                                    ],
                                },
                                {
                                    type: 'Organizational Unit',
                                    title: 'Critical',
                                    selected: mappedCloudAccountsByExternalId,
                                    selectedName: DEFAULT_ORGANIZATIONAL_UNIT_ID,
                                    widgetConfigFilterState: [
                                        {
                                            name: 'severity',
                                            value: 'Critical',
                                        },
                                        {
                                            name: 'origin',
                                            value: 1,
                                        },
                                    ],
                                },
                            ],
                            navigateOption: 'Alerts',
                            binSize: '10m',
                            id: v4(),
                            sizes: {
                                height: 2,
                                width: 2,
                            },
                        },
                    },
                    {
                        id: v4(),
                        sectionId: 'intelligenceAndThreatHunting',
                        dashboardId: LOCAL_AWS_DEFAULT_DASHBOARD,
                        type: DashboardWidgetTypes.Trend,
                        title: 'traffic trend by account for the last 24h',
                        limit: 5,
                        description: '',
                        hideOnNoData: false,
                        aggregation: 'sumBytes',
                        dataSourceName: 'Traffic Activity (AWS)',
                        gslFilter: '',
                        cloudAccountId: '__ALL_ACCOUNTS__',
                        timeFrame: 24,
                        filterState: [],
                        options: {
                            id: v4(),
                            sizes: {
                                height: 2,
                                width: 4,
                            },
                            navigateOption: 'Traffic Activity (AWS)',
                            binSize: '10m',
                        },
                    },
                ],
            },
        ],
    };

    return awsDefaultDashboard;
}
