import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    I18nEmailNotifications,
    EmailNotificationsSubject,
    componentsList,
    SECTION_VALUES,
    IConditionalCheckbox,
} from '../consts';
import { Checkbox, Stack } from 'common/design-system/components-v2';
import {
    IEmailNotifications,
    ICompliance,
    IIamSafe,
} from 'common/module_interface/settings/emailNotifications/EmailPageInterfaces';

interface IComponentProps {
    emailNotifications?: IEmailNotifications;
    onChange: (
        key: keyof IEmailNotifications,
        value: boolean,
        emailNotificationsKey?: keyof IEmailNotifications,
    ) => void;
    removeSelectForDependentCheckbox: (
        data: { key: keyof IEmailNotifications; value: boolean; emailNotificationsKey?: string }[],
    ) => void;
    subjectList: EmailNotificationsSubject[];
    id: string;
}

const TopicComponent: FC<IComponentProps> = ({
    emailNotifications,
    subjectList,
    id,
    onChange,
    removeSelectForDependentCheckbox,
}) => {
    const { t } = useTranslation(I18nEmailNotifications);
    const [visibleSubjects, setVisibleSubjects] = useState<EmailNotificationsSubject[]>([]);

    const isDisabled = (conditionalCheckbox?: IConditionalCheckbox): boolean => {
        if (!conditionalCheckbox) return false;
        const { parent, emailNotificationsKey } = conditionalCheckbox;
        if (emailNotificationsKey) {
            const emailNotificationsNestedObj = emailNotifications?.[
                emailNotificationsKey as keyof IEmailNotifications
            ] as Record<string, boolean> | undefined;
            return !emailNotificationsNestedObj?.[parent as keyof typeof emailNotificationsNestedObj];
        } else {
            return !emailNotifications?.[parent as keyof IEmailNotifications];
        }
    };

    const getCheckedStatus = (id: string, key: string): boolean => {
        switch (id) {
            case SECTION_VALUES.CSPM:
                return !!emailNotifications?.compliance?.[key as keyof ICompliance];
            case SECTION_VALUES.IDENTITY:
                return !!emailNotifications?.iamSafe?.[key as keyof IIamSafe];
            default:
                return !!emailNotifications?.[key as keyof IEmailNotifications];
        }
    };

    const handleCheckboxChange = (id: string, key: keyof IEmailNotifications, value: boolean) => {
        const compliance = 'compliance';
        const iAmSafe = 'iamSafe';
        switch (id) {
            case SECTION_VALUES.CSPM:
                onChange(key, value, compliance);
                break;
            case SECTION_VALUES.IDENTITY:
                onChange(key, value, iAmSafe);
                break;
            default:
                onChange(key, value);
                break;
        }
    };

    const dynamicAccessLeases = emailNotifications?.[componentsList.leaseEvent as keyof IEmailNotifications];
    useEffect(() => {
        !dynamicAccessLeases &&
            removeSelectForDependentCheckbox([
                { key: componentsList.accessNotificationsOthers as keyof IEmailNotifications, value: false },
            ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dynamicAccessLeases]);

    const AdministrationNotificationsOthers =
        emailNotifications?.[componentsList.usersNotificationsOthers as keyof IEmailNotifications];
    useEffect(() => {
        !AdministrationNotificationsOthers &&
            removeSelectForDependentCheckbox([
                { key: componentsList.userCreatedOrDeleted as keyof IEmailNotifications, value: false },
                { key: componentsList.userForgotPassword as keyof IEmailNotifications, value: false },
            ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AdministrationNotificationsOthers]);

    const AuthorizationWindows = emailNotifications?.iamSafe?.[componentsList.iamSafe.leaseEvent as keyof IIamSafe];
    useEffect(() => {
        const iAmSafe = 'iamSafe';
        !AuthorizationWindows &&
            removeSelectForDependentCheckbox([
                {
                    key: componentsList.iamSafe.leaseNotificationsOthers as keyof IEmailNotifications,
                    value: false,
                    emailNotificationsKey: iAmSafe,
                },
            ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AuthorizationWindows]);

    useEffect(() => {
        const updateVisibleSubjects = async () => {
            const visible = await Promise.all(
                subjectList.map(async (subject) => {
                    if (subject.hidden) {
                        const isHidden = await subject.hidden();
                        return isHidden ? null : subject;
                    }
                    return subject;
                }),
            );
            setVisibleSubjects(visible.filter((subject): subject is EmailNotificationsSubject => subject !== null));
        };
        void updateVisibleSubjects();
    }, [subjectList]);

    return (
        <Stack padding={2} flexWrap>
            {visibleSubjects.map(({ key, label, conditionalCheckbox, disabled }) => (
                <Stack key={key} padding={conditionalCheckbox ? [0, 4] : []}>
                    <Checkbox
                        label={t(label)}
                        checked={getCheckedStatus(
                            id,
                            key as keyof ICompliance | keyof IIamSafe | keyof IEmailNotifications,
                        )}
                        onChange={(e) => handleCheckboxChange(id, key as keyof IEmailNotifications, e.target.checked)}
                        disabled={isDisabled(conditionalCheckbox) || disabled?.()}
                    />
                </Stack>
            ))}
        </Stack>
    );
};
export default TopicComponent;
