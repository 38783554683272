import { initializeColumnDefs } from '../Definitions/LogsColumnDefinitions';
import { initializeLogOverview } from '../LogOverview/LogOverview.initialize';
import { initializeTabs } from './AccountActivityLogsPage.tabs';
import { initializeFilterDefs } from '../Definitions/LogsFilterDefinitions';

export function initializeAccountActivityLogTable() {
    initializeColumnDefs();
    initializeLogOverview();
    initializeFilterDefs();
    initializeTabs();
}
