import { ermTrans } from '../../../RiskManagement.utils';
import { IIssueExclusion, IIssueExclusionProps } from '../IssueExclusions.interface';
import { fetchAllIssueExclusions } from '../IssueExclusions.utils';
import { getSafeTrimmedMultiValue, isEmptyItemValue } from 'common/erm-components/custom/CustomForm/CustomForm.values';
import { IItemsMap } from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import { IssueExclusionItemNames } from './IssueExclusionEditor.consts';
import { IOrganizationalUnit } from 'common/interface/data_services';

export const RequiredConditionFieldNames: IssueExclusionItemNames[] = [
    IssueExclusionItemNames.severities,
    IssueExclusionItemNames.rules,
    IssueExclusionItemNames.entityTypes,
    IssueExclusionItemNames.environments,
    IssueExclusionItemNames.orgUnits,
    IssueExclusionItemNames.entityNames,
    IssueExclusionItemNames.entityId,
    IssueExclusionItemNames.expirationDate,
    IssueExclusionItemNames.tags,
];

export const isRootOrgUnitSelected = (itemsMap: IItemsMap, orgUnitsRoot: IOrganizationalUnit): boolean => {
    const selectedOrgIds: string[] = getSafeTrimmedMultiValue(itemsMap[IssueExclusionItemNames.orgUnits]);
    return selectedOrgIds.length > 0 && selectedOrgIds[0] === orgUnitsRoot.id;
};

export const checkConditionsValidation = async (
    itemsMap: IItemsMap,
    orgUnitsRoot: IOrganizationalUnit,
): Promise<string | undefined> => {
    const activeFieldNames: string[] = RequiredConditionFieldNames.filter((name) => !isEmptyItemValue(itemsMap[name]));
    if (activeFieldNames.length === 0) {
        return ermTrans('ISSUE_EXCLUSIONS.EDITOR.CONDITIONS_ERROR');
    }

    if (
        activeFieldNames.length === 1 &&
        activeFieldNames[0] === IssueExclusionItemNames.orgUnits &&
        isRootOrgUnitSelected(itemsMap, orgUnitsRoot)
    ) {
        return ermTrans('ISSUE_EXCLUSIONS.EDITOR.CONDITIONS_ERROR');
    }

    return undefined;
};

export const getOtherExclusionNames = async (issueExclusion?: IIssueExclusionProps): Promise<string[]> => {
    const allExclusions: IIssueExclusion[] = await fetchAllIssueExclusions();
    const otherExclusions: IIssueExclusion[] = issueExclusion?.id
        ? allExclusions.filter((exclusion) => exclusion.id !== issueExclusion.id)
        : allExclusions;
    return otherExclusions.map((exclusion) => exclusion.name);
};
