import {
    ManagedKubernetesAccountDetails,
    OnBoardedKubernetesAccountDetails,
} from '../services/workload/workload.interface';
import WorkloadService from '../services/workload/workload.service';
import useOnInit from './useOnInit';
import { useState } from 'react';

type ManagedEnvironment = ManagedKubernetesAccountDetails;
type OnboardedEnvironment = OnBoardedKubernetesAccountDetails;

type RelatedEnvironment = {
    managedEnvironment: ManagedEnvironment | null;
    onboardedEnvironment: OnboardedEnvironment | null;
};

export interface UseEnvironmentPayload {
    isLoading: boolean;
    data?: RelatedEnvironment;
}

export interface UseEnvironmentProps {
    cloudAccountId?: string;
    dome9Id?: string;
}

export type UseEnvironment = ({ cloudAccountId, dome9Id }: UseEnvironmentProps) => UseEnvironmentPayload;
const useRelatedEnvironments: UseEnvironment = ({ cloudAccountId, dome9Id }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<RelatedEnvironment>();

    const getRelatedEnvironment = async ({ cloudAccountId, dome9Id }: UseEnvironmentProps) => {
        const res = await WorkloadService.getWorkloadEnvironment();
        const environments = res.data.kubernetesAccounts;
        return environments.find(
            (environment) =>
                environment.managedKubernetesAccountDetails?.dome9EntityId === dome9Id ||
                environment.onBoardedKubernetesAccountDetails?.id === cloudAccountId,
        );
    };

    useOnInit(() => {
        const getData = async () => {
            const relatedEnvironment = await getRelatedEnvironment({ cloudAccountId, dome9Id });

            if (relatedEnvironment) {
                setData({
                    managedEnvironment: relatedEnvironment?.managedKubernetesAccountDetails,
                    onboardedEnvironment: relatedEnvironment?.onBoardedKubernetesAccountDetails,
                });
            }

            setLoading(false);
        };

        getData();
    });

    return {
        isLoading: loading,
        data,
    };
};

export default useRelatedEnvironments;
