import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import { CloudAccountIamSafeStatsGet } from './cloudAccounts.interface';

const cloudAccountIamSafeStatsGet: CloudAccountIamSafeStatsGet.Function = async () => {
    return await getHttpService().request<AxiosResponse<CloudAccountIamSafeStatsGet.Response>>(
        'cloudaccounts/iam-safe-stats',
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const CloudAccountsService = {
    cloudAccountIamSafeStatsGet,
};

export default CloudAccountsService;
