import { Button, Modal, Stack } from 'common/design-system/components-v2';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BASE_TRANSLATION_KEY } from './utils';

interface IServerlessActionsModalProps {
    isModalOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    isLoading?: boolean;
    isError?: boolean;
    children: React.ReactNode;
}

const ServerlessActionsModal: React.FC<IServerlessActionsModalProps> = ({
    isModalOpen,
    onClose,
    onConfirm,
    title,
    isLoading = false,
    isError = false,
    children,
}) => {
    const { t } = useTranslation();

    const handleCloseModal = (): void => {
        onClose();
    };

    return (
        <Modal.ModalDialog isOpen={isModalOpen} width='lg' onAfterClose={onClose} dataAid='serverless-actions-modal'>
            <Modal.ModalHeader onClose={handleCloseModal} title={title} />
            <Modal.ModalContent>{children}</Modal.ModalContent>
            <Modal.ModalFooter>
                <Stack direction='row' spacing={1} alignItems='center' fullWidth justifyContent='flex-end'>
                    <Button variant='text' onClick={handleCloseModal} disabled={isLoading} dataAid='cancel'>
                        {t(`${BASE_TRANSLATION_KEY}.CANCEL`)}
                    </Button>
                    <Button
                        variant='contained'
                        color='brandPrimary'
                        onClick={onConfirm}
                        loading={isLoading}
                        disabled={isLoading || isError}
                        dataAid='apply'
                    >
                        {t(`${BASE_TRANSLATION_KEY}.APPLY`)}
                    </Button>
                </Stack>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    );
};

export default ServerlessActionsModal;
