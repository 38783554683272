import CreatedTimeCellRender from 'common/components/ag-grid/Renderers/CreatedTimeCellRender';
import EventStatusCellRender from '../Components/EventStatusCellRender';
import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { TFunction } from 'i18next';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from '../../initialize.i18n';
import { LogsTableRegistry } from 'common/module_interface/intelligence/Logs/LogsTableRegistry';
import { LogFields } from 'common/module_interface/intelligence/Logs/Logs.interface';
import CountryCellRender from '../Components/CountryCellRender';

// all columns definitions for the logs table -> not specific to any platform
export function getLogsColumnDefinitions(): CGColDef[] {
    const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);
    return [
        {
            colId: LogFields.eventTime,
            field: LogFields.eventTime,
            headerName: t('LOGS_TABLE.COLUMNS.EVENT_TIME.HEADER'),
            cellRenderer: CreatedTimeCellRender,
            sortable: true,
            initialSort: 'desc',
            flex: 6,
        },
        {
            colId: LogFields.eventName,
            field: LogFields.eventName,
            headerName: t('LOGS_TABLE.COLUMNS.EVENT_NAME.HEADER'),
            sortable: true,
            flex: 7,
        },
        {
            colId: LogFields.identityName,
            field: LogFields.identityName,
            headerName: t('LOGS_TABLE.COLUMNS.IDENTITY_NAME.HEADER'),
            sortable: true,
            flex: 6,
        },
        {
            colId: LogFields.identityUserAgent,
            field: LogFields.identityUserAgent,
            headerName: t('LOGS_TABLE.COLUMNS.IDENTITY_USERAGENT.HEADER'),
            flex: 5,
        },
        {
            colId: LogFields.issuerName,
            field: LogFields.issuerName,
            headerName: t('LOGS_TABLE.COLUMNS.ISSUER_NAME.HEADER'),
            sortable: true,
            flex: 6,
        },
        {
            colId: LogFields.targetName,
            field: LogFields.targetName,
            headerName: t('LOGS_TABLE.COLUMNS.TARGET_NAME.HEADER'),
            sortable: true,
            flex: 6,
        },
        {
            colId: LogFields.targetNamespace,
            field: LogFields.targetNamespace,
            headerName: t('LOGS_TABLE.COLUMNS.TARGET_NAMESPACE.HEADER'),
            flex: 6,
        },
        {
            colId: LogFields.eventStatus,
            field: LogFields.eventStatus,
            headerName: t('LOGS_TABLE.COLUMNS.EVENT_STATUS.HEADER'),
            cellRenderer: EventStatusCellRender,
            sortable: true,
            flex: 1,
        },
        {
            colId: LogFields.sourceIp,
            field: LogFields.sourceIp,
            headerName: t('LOGS_TABLE.COLUMNS.SOURCE_IP.HEADER'),
            flex: 6,
        },
        {
            colId: LogFields.sourceCountry,
            field: LogFields.sourceCountry,
            headerName: t('LOGS_TABLE.COLUMNS.SOURCE_COUNTRY.HEADER'),
            cellRenderer: CountryCellRender,
            sortable: true,
            flex: 4,
        },
    ];
}

export function initializeColumnDefs() {
    LogsTableRegistry.addColumnDefs(getLogsColumnDefinitions(), 'colId');
}
