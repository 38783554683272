import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { Addin } from 'common/extensibility/AddinRegistry';
import { GcpEnvironmentAddinRegistry, GcpEnvironmentAddingBase } from 'common/helpers/gcpEnvironment';
import { GcpEnvironmentData } from 'common/module_interface/assets/GcpEnvironment';
import { StatusCellRenderer_Status } from 'common/module_interface/assets/Environments';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { getGcpEnvNamespace } from './initialize.i18n';
import { changeUrl } from 'common/utils/http';
import { getCloudAccountsService } from 'common/interface/data_services';
import { Vendors } from 'common/consts/vendors';
import { Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import { getEventsTableWidgetService } from 'common/module_interface/events/EventsTableRegistry';
import { IChipProps } from 'common/design-system/components-v2/Chip/Chip.types';
import { CommonEventFields } from 'common/module_interface/events/EventsConsts';
import { lazy } from 'react';

export const initialGcpEnvironmentData = () => {
    const tabs: Addin<GcpEnvironmentAddingBase<Tab>>[] = [
        {
            id: 'readiness',
            position: 10,
            content: {
                id: 'readiness',
                getValue: () => ({
                    name: 'readiness',
                    label: i18next.t('gcpPage.tabs.readiness', { ns: getGcpEnvNamespace('gcp') }),
                    isReactTab: false,
                }),
            },
        },
        {
            id: 'network',
            position: 20,
            content: {
                id: 'network',
                getValue: () => ({
                    name: 'Network',
                    label: i18next.t('gcpPage.tabs.network', { ns: getGcpEnvNamespace('gcp') }),
                    isReactTab: false,
                }),
            },
        },
        {
            id: 'protected assets new',
            position: 30,
            content: {
                id: 'protected assets new',
                getValue: (gcpData: GcpEnvironmentData) => ({
                    name: 'protected assets new',
                    label: i18next.t('gcpPage.tabs.protectedAssets', { ns: getGcpEnvNamespace('gcp') }),
                    isReactTab: true,
                    reactTab: {
                        component: lazy(() => import('./tabs/Assets')),
                        componentProps: { cloudAccountId: gcpData.account.id },
                    },
                }),
            },
        },
        {
            id: 'events',
            position: 40,
            content: {
                id: 'events',
                getValue: (gcpData) => {
                    const EventsTable = getEventsTableWidgetService().getMainTable();
                    return {
                        name: 'events',
                        label: i18next.t('gcpPage.tabs.events', { ns: getGcpEnvNamespace('gcp') }),
                        isReactTab: true,
                        reactTab: {
                            component: EventsTable,
                            componentProps: {
                                tableIdPrefix: `gcp-env_${gcpData.account.id}`,
                                disablePadding: true,
                                defaultFilters: {
                                    fields: [{ name: 'cloudAccountId_calc', value: gcpData.account.id }],
                                },
                                hiddenFilters: [
                                    CommonEventFields.cloudAccountId,
                                    CommonEventFields.cloudAccountType,
                                    'organizational unit',
                                    CommonEventFields.organizationalUnitId,
                                    CommonEventFields.region,
                                ],
                                hiddenColumns: [CommonEventFields.cloudAccountId, CommonEventFields.cloudAccountType],
                            },
                        },
                    };
                },
            },
        },
        {
            id: 'compliance policies',
            position: 50,
            content: {
                id: 'compliance policies',
                getValue: () => ({
                    name: 'compliance policies',
                    label: i18next.t('gcpPage.tabs.compliancePolicies', { ns: getGcpEnvNamespace('gcp') }),
                    isReactTab: false,
                }),
            },
        },
        {
            id: 'assessment history',
            position: 60,
            content: {
                id: 'assessment history',
                getValue: () => ({
                    name: 'assessment history',
                    label: i18next.t('gcpPage.tabs.assessmentHistory', { ns: getGcpEnvNamespace('gcp') }),
                    isReactTab: false,
                }),
            },
        },
        {
            id: 'serverless',
            position: 70,
            content: {
                id: 'serverless',
                getValue: () => ({
                    name: 'serverless',
                    label: i18next.t('gcpPage.tabs.serverless', { ns: getGcpEnvNamespace('gcp') }),
                    isReactTab: false,
                }),
                isRelevant: (gcpData: GcpEnvironmentData) => gcpData.account.isServerlessActive,
            },
        },
    ];
    GcpEnvironmentAddinRegistry.addTabs(tabs);

    const detailsItems: Addin<GcpEnvironmentAddingBase<BlockInfoProps>>[] = [
        {
            id: 'organizationalUnit',
            position: 10,
            content: {
                id: 'organizationalUnit',
                getValue: (gcpData) => ({
                    title: i18next.t('gcpPage.info.organizationalUnit', { ns: getGcpEnvNamespace('gcp') }),
                    info: gcpData.account.organizationalUnitName,
                    copyable: true,
                }),
            },
        },
        {
            id: 'cloudGuardId',
            position: 30,
            content: {
                id: 'cloudGuardId',
                getValue: (gcpData) => ({
                    title: i18next.t('gcpPage.info.cloudGuardId', { ns: getGcpEnvNamespace('gcp') }),
                    info: gcpData.account.id,
                    copyable: true,
                }),
            },
        },
        {
            id: 'onboardingTime',
            position: 40,
            content: {
                id: 'onboardingTime',
                getValue: (gcpData) => ({
                    title: i18next.t('gcpPage.info.onboardingTime', { ns: getGcpEnvNamespace('gcp') }),
                    info: dayjs(gcpData.account.creationDate).format('MMM D, YYYY h:mm A Z'),
                }),
            },
        },
        {
            id: 'totalAssets',
            position: 60,
            content: {
                id: 'totalAssets',
                getValue: (gcpData) => ({
                    title: i18next.t('gcpPage.info.totalAssets', { ns: getGcpEnvNamespace('gcp') }),
                    info: i18next.t('COMMON.PRETTY_NUMBER', { value: gcpData.account.totalAssets }),
                }),
            },
        },
    ];
    GcpEnvironmentAddinRegistry.addDetailsPanelItems(detailsItems);

    const chips: Addin<GcpEnvironmentAddingBase<IChipProps>>[] = [
        {
            id: 'environmentStatusChip',
            position: 20,
            content: {
                id: 'environmentStatusChip',
                customLoadingLabel: i18next.t('gcpPage.chips.status.loading', { ns: getGcpEnvNamespace('gcp') }),
                getValue: async (gcpData) => {
                    const syncStatusList = await getCloudAccountsService().getEnvironmentSyncStatus(true, (err) => {
                        throw err;
                    });
                    const status = syncStatusList.find(
                        (s) => s.platform === Vendors.GOOGLE && s.id === gcpData.account.id,
                    );
                    const syncStatus = status?.hasIssues
                        ? StatusCellRenderer_Status.warning
                        : StatusCellRenderer_Status.success;
                    const tooltipI18Key =
                        syncStatus === StatusCellRenderer_Status.warning
                            ? 'gcpPage.chips.MISSING_PERMISSIONS'
                            : 'gcpPage.chips.VALID_PERMISSIONS';
                    const chipProps: IChipProps = {
                        label: i18next.t(`gcpPage.chips.status.${syncStatus}`, { ns: getGcpEnvNamespace('gcp') }),
                        leadingIconProps: {
                            name: syncStatus === StatusCellRenderer_Status.warning ? 'warning' : 'checkCircle',
                        },
                        context: syncStatus === StatusCellRenderer_Status.warning ? 'medium' : 'low',
                        disableInteraction: true,
                        tooltip: i18next.t(tooltipI18Key, { ns: getGcpEnvNamespace('gcp') }),
                    };

                    if (syncStatus === StatusCellRenderer_Status.warning) {
                        chipProps.disableInteraction = false;
                        chipProps.onClick = () => {
                            changeUrl(`/cloud-account/gcp/${gcpData.account.id}?tabName=readiness&tabOnly=true`);
                        };
                    }
                    return chipProps;
                },
            },
        },
    ];
    GcpEnvironmentAddinRegistry.addChips(chips);
};
