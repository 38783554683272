import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { DRAWER_ID } from 'modules/workloads/Drawers/AwpHostConfigRuleDrawer/AwpHostConfigRuleDrawer.consts';
import { AwpHostConfigRuleDrawerProps } from 'modules/workloads/Drawers/AwpHostConfigRuleDrawer/AwpHostConfigRuleDrawer.types';

export const openRuleDrawer = (params: AwpHostConfigRuleDrawerProps) => () => {
    showDrawer(DRAWER_ID, {
        assetId: params.assetId,
        ruleId: params.ruleId,
        provider: params.provider,
        cloudAccountId: params.cloudAccountId,
    });
};
