import styled from 'styled-components';

const AwsSecurityHubStyled = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    .marginLeftSpace {
        margin-left: 10px;
    }
`;

const AwsSecurityHubNameWrapper = styled.div`
    width: 50%;
    margin: 24px;
`;

const AwsSecurityHubAccountSelectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 0 24px 24px 24px;

    .marginLeft {
        margin-left: 50px;
    }
`;

const AwsSecurityHubSettingsAreInvalidInSomeRegions = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 24px;

    .warningMessage {
        background: #ffe5e5;
        border-radius: 5px;
    }
`;

const AwsSecurityHubModalFooter = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 24px;
`;

const ToolTipWrapper = styled.div`
    margin-left: 5px;
`;

export {
    AwsSecurityHubStyled,
    AwsSecurityHubNameWrapper,
    AwsSecurityHubAccountSelectionWrapper,
    AwsSecurityHubSettingsAreInvalidInSomeRegions,
    AwsSecurityHubModalFooter,
    ToolTipWrapper,
};
