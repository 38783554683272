import { FC, useEffect, useState } from 'react';
import { Spinner, Stack } from 'common/design-system/components-v2';
import { getIntegrationsService } from 'common/interface/services';
import { initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { IntegrationsRegistry } from 'common/module_interface/settings/integrations/IntegrationsRegistry';
import {
    FILTER_PANEL_LIST_OBJECT,
    SENTINEL_FILTER_PANEL_ID,
} from 'common/module_interface/settings/integrations/consts';
import { getCloudAccountsService } from 'common/interface/data_services';

interface ISentinelFilterProps {
    onFilterValueChange: (filtersValues: any) => void;
}
const sentinelSourceFilterKeys = [1, 2, 105, 106];
export const SentinelFilterPanel: FC<ISentinelFilterProps> = ({ onFilterValueChange }) => {
    const [filterElement, setFilterElement] = useState(<div></div>);
    const [isLoading, setIsLoading] = useState(true);
    const [aggregations, setAggregations] = useState<any>({});
    const onFilterEvent = (_action: string, filtersValues: IFiltersValues) => {
        onFilterValueChange(filtersValues);
    };

    function buildSourceFilterList() {
        return sentinelSourceFilterKeys.map((key: number) => {
            return {
                value: key,
                count: 0,
            };
        });
    }

    const getAggregations = async () => {
        const findings = await getIntegrationsService().getAllFindingsAggregations(true);
        findings.aggregations.organizationalUnit = await getIntegrationsService().getOrganizationalUnits(true);
        findings.aggregations.allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts(true);
        findings.aggregations.origin = buildSourceFilterList();
        return findings?.aggregations;
    };

    useEffect(() => {
        async function renderFilterPanel() {
            const filterPanelElementsList = IntegrationsRegistry.getFilterDefs(FILTER_PANEL_LIST_OBJECT, {
                aggregations: aggregations,
                filtersInitialData: {
                    organizationalUnits: aggregations.organizationalUnit,
                    allCloudAccounts: aggregations.allCloudAccounts,
                },
            });

            const filterPanel = initFilterPanel({
                filterPanelElementsList,
                getAggregations: getAggregations,
                filterId: SENTINEL_FILTER_PANEL_ID,
                onFilterChangeCallBack: onFilterEvent,
                initialValues: { origin: sentinelSourceFilterKeys },
            });
            setFilterElement(filterPanel);
            setIsLoading(false);
        }
        void renderFilterPanel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aggregations]);

    useEffect(() => {
        const init = async () => {
            const resp = await getAggregations();
            setAggregations(resp);
        };
        void init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Stack>{isLoading ? <Spinner /> : filterElement}</Stack>;
};
