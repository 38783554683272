import React, { useState } from 'react';
import ImagesOnWorkload from '../../sbom/ImagesOnWorkload/ImagesOnWorkload';
import { Image } from 'modules/workloads/services/workload/workload.interface';
import Vulnerability from '../Vulnerability';
import { useImagesByWorkload } from '../../../../../services/workload/hooks/useImagesByWorkload';
import EmptyStateWrapper from '../../EmptyStateWrapper/EmptyStateWrapper';

interface VulnerabilityWorkloadsProps {
    dome9Id: string;
    environmentId: string;
}

const VulnerabilityWorkloads: React.FC<VulnerabilityWorkloadsProps> = ({ dome9Id, environmentId }) => {
    const [selectedImage, setSelectedImage] = useState<Image | null>(null);
    const { data, isError, isLoading } = useImagesByWorkload(dome9Id);

    if ((data?.length === 0 || isError) && !isLoading) return <EmptyStateWrapper />;

    return (
        <>
            {data && (
                <ImagesOnWorkload data={data} onClickSelectImage={setSelectedImage} selectedImage={selectedImage} />
            )}
            {selectedImage !== null && (
                <Vulnerability type={'KubernetesImage'} id={selectedImage?.imageId} environmentId={environmentId} />
            )}
        </>
    );
};

export default VulnerabilityWorkloads;
