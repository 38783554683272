import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';

export const PackageVersionCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const options: IIconLinkCellProps = {
        isLink: false,
        value: params.value,
        levelIconProps: {
            iconProps: { name: 'package' },
            size: 'md',
        },
    };
    return <IconLinkCellRenderer {...options} />;
};
