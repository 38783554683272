import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { ScanResultsMetadata } from 'modules/workloads/services/cis/cis.interface';
import cisService from 'modules/workloads/services/cis/cis.service';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface QueryParams {
    assetId: string;
}

interface UseScanResultsMetadataProps {
    provider: string;
    cloudAccountId: string;
    queryParams: QueryParams;
}

interface UseScanResultsMetadataReturn {
    metadata?: ScanResultsMetadata;
    loading: boolean;
    error: string | null;
}

export const useScanResultsMetadata = ({
    provider,
    cloudAccountId,
    queryParams,
}: UseScanResultsMetadataProps): UseScanResultsMetadataReturn => {
    const { t } = useTranslation(getK8sNamespace('cis'));
    const [metadata, setMetadata] = useState<ScanResultsMetadata | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const res = await cisService.getScanResultsMetadata({ provider, cloudAccountId, queryParams });
                setMetadata(res.data);
            } catch (e: any) {
                console.error(e);
                setError(e.message || t('sorrySomethingWentWrong'));
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        metadata,
        loading,
        error,
    };
};
