import { ISSUE_EXCLUSIONS_SERVICE_ID } from './IssueExclusions.consts';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { IssueExclusionsService } from '../../services/IssueExclusionsService';
import { initializeIssueExclusionEditor } from './IssueExclusionEditor/IssueExclusionEditor.initialize';

const initializeIssueExclusionService = () => {
    globalAddinContainer.addService(ISSUE_EXCLUSIONS_SERVICE_ID, new IssueExclusionsService());
};

export default function initializeIssueExclusion() {
    initializeIssueExclusionEditor();
    initializeIssueExclusionService();
}
