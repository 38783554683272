import React, { useRef, useState } from 'react';
import { Handle, Position, useStore } from 'reactflow';
import { useTranslation } from 'react-i18next';
import { Icon, Popover, Tooltip } from '@dome9/berries/react-components';
import { A } from 'common/components/Anchor/Anchor';
import { ExposureNodeData } from 'common/module_interface/insight/ExposureNodeData';
import { AssetNodeProps } from 'common/components/Graph/Models/AssetNodeProps';
import { getExposureColor } from 'common/components/Graph/Services/ColorGetter';
import ExposurePopoverComponent from 'common/erm-components/custom/ExposureGraph/ExposurePopoverComponent';
import { insightNamespace } from '../../consts/i18n';
import { InsightPopoverRegistry } from 'common/module_interface/insight/InsightPopoverRegistry';
import { fontSelector } from '../Services/fontHandler';

const ExposureAssetNode: React.FC<AssetNodeProps> = (props: AssetNodeProps) => {
    const { t } = useTranslation(insightNamespace);
    const fontSize = useStore(fontSelector);

    const { data, isConnectable } = props;

    const [nwBadge] = useState(InsightPopoverRegistry.getBadges().find((item) => item.position === 'nw'));
    const [neBadge] = useState(InsightPopoverRegistry.getBadges().find((item) => item.position === 'ne'));

    const exposureNodeData = data as ExposureNodeData;
    const customSize = 86;
    const handleRef = useRef(null);
    const foregroundCssClass =
        exposureNodeData.platform === 'aws' || exposureNodeData.label === 'CG WAF' ? 'white-foreground' : '';
    return (
        <>
            <Handle
                type='target'
                position={Position.Left}
                style={{
                    top: '50.1%',
                    background: 'transparent',
                    width: 0,
                    height: 0,
                    border: 'none',
                }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />

            <div className={'flex flex-col items-center asset-node'}>
                {exposureNodeData?.isInContext && (
                    <Tooltip content={t('NODE.YOU_ARE_HERE')}>
                        <div className={'absolute top-[-40px] flex flex-col items-center'}>
                            <Icon name={'location'} size={32} />
                        </div>
                    </Tooltip>
                )}
                <Popover
                    content={ExposurePopoverComponent(exposureNodeData)}
                    interactive={true}
                    appendTo={() => document.body}
                    delay={200}
                    maxWidth={400}
                >
                    <div className='flex flex-col items-center'>
                        <div
                            className=' flex flex-col items-center group rounded-full overflow-hidden p-2 hover:shadow-md transition-shadow theme theme-dark'
                            style={{
                                background: getExposureColor(exposureNodeData?.riskScore),
                                fill: '#ffffff',
                                borderColor: 'rgba(255,255,255,.2)',
                            }}
                        >
                            {nwBadge && (
                                <div className={'absolute -left-6'}>
                                    {<nwBadge.component data={exposureNodeData} />}
                                </div>
                            )}

                            {neBadge && (
                                <div className={'absolute -right-3'}>
                                    {<neBadge.component data={exposureNodeData} />}
                                </div>
                            )}

                            {exposureNodeData.riskScore !== undefined && exposureNodeData.riskScore !== null && (
                                <div className='absolute text-strong bottom-2 font-bold drop-shadow-sm'>
                                    {exposureNodeData.riskScore}
                                </div>
                            )}
                            <div
                                className='bg-svg-element grid place-items-center '
                                style={{
                                    width: `${customSize}px`,
                                    height: `${customSize}px`,
                                }}
                            >
                                {exposureNodeData.headerIcon && (
                                    <div className={`node-icon-container ${foregroundCssClass}`}>
                                        <exposureNodeData.headerIcon alt='' title={''} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Popover>
                <div className='absolute top-[95px] flex flex-col items-center '>
                    {exposureNodeData.gotoUrl && !exposureNodeData.isInContext && (
                        <div
                            className={'link w-[18ch] rounded-full px-5 text-center truncate'}
                            style={{ fontSize: fontSize }}
                        >
                            <A href={exposureNodeData.gotoUrl} className={'link'}>
                                {exposureNodeData.name || exposureNodeData.externalId}
                            </A>
                        </div>
                    )}
                    {(exposureNodeData.isInContext || !exposureNodeData.gotoUrl) && (
                        <div
                            className={'w-[18ch] rounded-full px-5 text-center truncate'}
                            style={{ fontSize: fontSize }}
                        >
                            {exposureNodeData.name || exposureNodeData.externalId}
                        </div>
                    )}
                    {exposureNodeData.label && exposureNodeData.label !== exposureNodeData.name && (
                        <div
                            className={'text-weak w-[18ch] rounded-full px-5 text-center truncate'}
                            style={{ fontSize: fontSize }}
                        >
                            {exposureNodeData.label}
                        </div>
                    )}
                </div>
            </div>
            <Handle
                ref={handleRef}
                type='source'
                position={Position.Right}
                isConnectable={isConnectable}
                style={{
                    top: '50%',
                    background: 'transparent',
                    width: 0,
                    height: 0,
                    border: 'none',
                }}
            />
        </>
    );
};

export default ExposureAssetNode;
