import React, { useEffect, useState } from 'react';
import { Button, Modal } from '@dome9/berries/react-components';
import {
    AssetType,
    EVENT_ACTIVITY_ALIBABA,
    EVENT_ACTIVITY_AWS,
    EVENT_ACTIVITY_AZURE,
    TRAFFIC_ACTIVITY_AWS,
    TRAFFIC_ACTIVITY_AZURE,
    TRAFFIC_ACTIVITY_KUBERNETES,
} from './Consts';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../initialize.i18n';
import { GSLBuilderComponent } from '../Gsl/gslBuilder/GSLBuilderComponent';

export const GSLBuilderModal: React.FC<{
    predefinedRuleText: string;
    onConfirm: (val: string) => void;
    onCancel: () => void;
    isOpen: boolean;
    assetType: any;
}> = ({ predefinedRuleText, onCancel, onConfirm, assetType, isOpen }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);

    const [rule, setRule] = useState<string>(predefinedRuleText);
    const [GSLAssetType, setGSLAssetType] = useState<AssetType>('flowlogs');
    function updateSentenceCallback(ruleSentence: string) {
        setRule(ruleSentence);
    }
    useEffect(() => {
        switch (assetType) {
            case TRAFFIC_ACTIVITY_AWS:
                setGSLAssetType('flowlogs');
                break;
            case EVENT_ACTIVITY_AWS:
                setGSLAssetType('cloudtrail');
                break;
            case TRAFFIC_ACTIVITY_AZURE:
                setGSLAssetType('azureflowlogs');
                break;
            case EVENT_ACTIVITY_AZURE:
                setGSLAssetType('azurecloudtrail');
                break;
            case EVENT_ACTIVITY_ALIBABA:
                setGSLAssetType('alibabaactiontrail');
                break;
            case TRAFFIC_ACTIVITY_KUBERNETES:
                setGSLAssetType('flowlogskubernets');
                break;
            default:
                setGSLAssetType('flowlogs');
                break;
        }
    }, [assetType]);
    return (
        <Modal.ModalDialog isOpen={isOpen} width={'xl'}>
            <Modal.ModalHeader title={t('WIDGETS.GSL_BUILDER.TITLE')} onClose={onCancel} />
            <Modal.ModalContent>
                <GSLBuilderComponent
                    predefinedRuleText={predefinedRuleText}
                    updateSentence={updateSentenceCallback}
                    assetType={GSLAssetType}
                />
            </Modal.ModalContent>
            <Modal.ModalFooter>
                <div className='modal__footer-actions'>
                    <Button onClick={() => onCancel()} dataAid='Cancel'>
                        {t('WIDGETS.GSL_BUILDER.CANCEL')}
                    </Button>
                    <Button onClick={() => onConfirm(rule)} color='primary' dataAid='Save'>
                        {t('WIDGETS.GSL_BUILDER.SAVE')}
                    </Button>
                </div>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    );
};

export default GSLBuilderModal;
