import { IconComponentProps } from 'common/design-system/components-v2/Icon/Icon.types';
import { IBaseAssetModifier } from './IBaseAssetModifier';
import { ImportanceLevelMap } from 'common/consts/ImportanceLevel';
import { NetworkExposureLevelEnum, NetworkExposureServerIdEnum } from '../../../consts';
import { ermTrans } from '../../../RiskManagement.utils';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { isNil } from 'common/utils/helpFunctions';

export interface INetworkExposureLevelInfo extends IBaseAssetModifier {
    serverIds: string[];
    title: string;
    iconProps: IconComponentProps;
    bg: string;
}

export const getNetworkExposureInfosMap = (): { [key in NetworkExposureLevelEnum]: INetworkExposureLevelInfo } => ({
    [NetworkExposureLevelEnum.Unknown]: {
        id: NetworkExposureLevelEnum.Unknown,
        serverIds: [NetworkExposureServerIdEnum.Unknown],
        title: ermTrans('PROTECTED_ASSETS.MODIFIERS.NETWORK_EXPOSURE.UNKNOWN'),
        iconProps: { name: 'web', customColor: ImportanceLevelMap.unknown.fg },
        bg: ImportanceLevelMap.unknown.bg,
        level: -1,
    },
    [NetworkExposureLevelEnum.Private]: {
        id: NetworkExposureLevelEnum.Private,
        serverIds: [NetworkExposureServerIdEnum.Private],
        title: ermTrans('PROTECTED_ASSETS.MODIFIERS.NETWORK_EXPOSURE.PRIVATE'),
        iconProps: { name: 'private', customColor: ImportanceLevelMap.low.fg },
        bg: ImportanceLevelMap.low.bg,
        level: 0,
    },
    [NetworkExposureLevelEnum.Partial]: {
        id: NetworkExposureLevelEnum.Partial,
        serverIds: [NetworkExposureServerIdEnum.PartiallyPublic, NetworkExposureServerIdEnum.Partial],
        title: ermTrans('PROTECTED_ASSETS.MODIFIERS.NETWORK_EXPOSURE.PARTIALLY_PUBLIC'),
        iconProps: { name: 'semiPublic', customColor: ImportanceLevelMap.medium.fg },
        bg: ImportanceLevelMap.medium.bg,
        level: 1,
    },
    [NetworkExposureLevelEnum.Public]: {
        id: NetworkExposureLevelEnum.Public,
        serverIds: [NetworkExposureServerIdEnum.Public],
        title: ermTrans('PROTECTED_ASSETS.MODIFIERS.NETWORK_EXPOSURE.PUBLIC'),
        iconProps: { name: 'web', customColor: ImportanceLevelMap.critical.fg },
        bg: ImportanceLevelMap.critical.bg,
        level: 2,
    },
});

export const getAllNetworkExposureLevelInfos = (): INetworkExposureLevelInfo[] =>
    Object.values(getNetworkExposureInfosMap()).sort((a, b) => a.level - b.level);

export const getNetworkExposureLevelInfos = (): INetworkExposureLevelInfo[] =>
    getAllNetworkExposureLevelInfos().filter((info) => info.level >= 0);

const getUnknownNetworkExposureLevelInfo = (): INetworkExposureLevelInfo =>
    getNetworkExposureInfosMap()[NetworkExposureLevelEnum.Unknown];

export function getNetworkExposureLevelInfo(networkExposure: string | undefined): INetworkExposureLevelInfo {
    const levelInfo: INetworkExposureLevelInfo | undefined = isNil(networkExposure)
        ? undefined
        : getNetworkExposureLevelInfos().find((item) => item.serverIds.includes(networkExposure));
    return levelInfo || getUnknownNetworkExposureLevelInfo();
}

export const getAllNetworkExposureOptions = (): SelectOption[] => {
    return getAllNetworkExposureLevelInfos().map((item) => ({
        value: item.id,
        label: item.title,
    }));
};
