import React from 'react';
import { CardHeaderWrapper } from '../configurationListManager.styled';
import Typography from 'common/design-system/components-v2/Typography';
import { Button } from 'common/design-system/components-v2';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';
import { IConfiguration } from 'common/module_interface/settings/integrations/Integrations';

interface ICardHeaderProps {
    configuration: IConfiguration;
    arrowIcon: AllIconsName;
    onDeleteClicked?: Function;
    onClick?: Function;
    viewMode?: boolean;
}
const CardHeader: React.FC<ICardHeaderProps> = ({ configuration, onClick, arrowIcon, viewMode, onDeleteClicked }) => {
    return (
        <CardHeaderWrapper padding={2} onClick={() => onClick && onClick()}>
            <div>
                <Typography variant='bodyLg'>
                    <Button iconProps={{ name: arrowIcon, size: 10 }} variant='text' size='small' />
                    {configuration && configuration.name}
                </Typography>
            </div>
            {configuration?.id && (
                <Button
                    iconProps={{ name: 'delete' }}
                    variant='text'
                    disabled={viewMode}
                    dataAid='deleteConfiguration'
                    onClick={(event) => {
                        event.stopPropagation();
                        onDeleteClicked && onDeleteClicked();
                    }}
                />
            )}
        </CardHeaderWrapper>
    );
};

export default CardHeader;
