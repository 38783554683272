import { ermTrans } from './RiskManagement.utils';
import { lightPallete } from 'common/design-system/theme/colors';

export enum DataAssetsCategory {
    DATABASE = 'Database',
    DATALAKE = 'DataLake',
    STORAGE = 'Storage',
}

export interface IDataAssetCategoryInfo {
    id: DataAssetsCategory;
    getTitle: () => string;
    position: number;
    iconName: string;
    assetTypes: string[];
    value: number;
}

export interface IDataAssetCategoryCounter {
    categoryInfo: IDataAssetCategoryInfo;
    count: number;
}

export type IDataCategoriesMapType<T> = { [key in DataAssetsCategory]: T };

export const DataAssetCategoriesMap: IDataCategoriesMapType<IDataAssetCategoryInfo> = {
    [DataAssetsCategory.DATABASE]: {
        id: DataAssetsCategory.DATABASE,
        getTitle: () => ermTrans('DATA_SECURITY_DASHBOARD.DATA_CATEGORIES.DATABASE'),
        position: 0,
        assetTypes: [],
        iconName: 'database',
        value: 0,
    },
    [DataAssetsCategory.DATALAKE]: {
        id: DataAssetsCategory.DATALAKE,
        getTitle: () => ermTrans('DATA_SECURITY_DASHBOARD.DATA_CATEGORIES.DATALAKE'),
        position: 1,
        assetTypes: [],
        iconName: 'datalake',
        value: 0,
    },
    [DataAssetsCategory.STORAGE]: {
        id: DataAssetsCategory.STORAGE,
        getTitle: () => ermTrans('DATA_SECURITY_DASHBOARD.DATA_CATEGORIES.STORAGE'),
        position: 2,
        assetTypes: [],
        iconName: 'storage',
        value: 0,
    },
};

export const getAllSortedCategories = (): IDataAssetCategoryInfo[] =>
    Object.values(DataAssetCategoriesMap).sort((a, b) => (a.position < b.position ? 0 : 1));

export const getAllCategoriesSortedMap = () =>
    Object.fromEntries(
        Object.entries(DataAssetCategoriesMap)
            .sort((a, b) => a[1].position - b[1].position)
            .map(([key, { value }]) => [key, value]),
    );

export const getCategoryIdByType = (typeByPlatform: string): DataAssetsCategory | undefined =>
    Object.values(DataAssetCategoriesMap).find((category) => category.assetTypes.includes(typeByPlatform))?.id;

export const getCategoryColor = (categoryInfo: IDataAssetCategoryInfo): string =>
    lightPallete.graph.list[categoryInfo.position];
