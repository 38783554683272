import { LicenseManager } from 'ag-grid-enterprise';
import './platform/auth/StoreCloudInfraToken';
import { createRoot } from 'react-dom/client';
import { applyPolyfills, defineCustomElements } from '@dome9/components/loader';
import 'common/services/translations/translations';
import './index.scss';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import globalAddinContainer from './common/extensibility/AddinContainer';
import { initialize, initializeI18n } from './initialize';
import { getLoggerService, getStoreService } from 'common/interface/services';
import { Router } from 'react-router-dom';
import history from 'common/utils/history';
import ToastStyle from 'platform/main/Notifications/Toast.styled';
import { toastOption } from 'platform/main/Notifications/Toasts';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/design-system/theme/theme';
import { getGlobalPersistor } from 'common/services/store/store';
import { initializeMixpanel } from 'platform/3rdParty/mixpanel/mixpanel';
import { Suspense } from 'react';
import { Spinner, Stack } from 'common/design-system/components-v2';
import { ErrorBoundary } from '@sentry/react';
import EmptyState from 'common/components/EmptyState';

LicenseManager.setLicenseKey(
    'CompanyName=Check Point Software Technologies Ltd.,LicensedApplication=CloudGuard,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=10,LicensedProductionInstancesCount=1,AssetReference=AG-030639,SupportServicesEnd=9_September_2023_[v2]_MTY5NDIxNDAwMDAwMA==c754169b518b09572970722206d12267',
);
await initializeI18n();
initializeMixpanel();
initialize();
globalAddinContainer.start();
const storeService = getStoreService();
const store = storeService.store;
const persistor = getGlobalPersistor();

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
                <ThemeProvider theme={theme('light')}>
                    <ErrorBoundary
                        fallback={
                            <EmptyState
                                iconName='cloudGuard'
                                label='Something went wrong'
                                description='We were unable to load the content. Please try again later.'
                                links={[
                                    {
                                        label: 'Contact Support',
                                        url: 'https://support.checkpoint.com',
                                        outsideLink: true,
                                    },
                                ]}
                            />
                        }
                        onError={(error) => {
                            getLoggerService().error(error);
                        }}
                    >
                        <Suspense
                            fallback={
                                <Stack fullHeight fullWidth justifyContent='center' alignItems='center'>
                                    <Spinner size={64} context='info' saturation />
                                </Stack>
                            }
                        >
                            <App />
                            <ToastStyle.ToastProvider {...(toastOption as any)} />
                        </Suspense>
                    </ErrorBoundary>
                </ThemeProvider>
            </Router>
        </PersistGate>
    </Provider>,
);

applyPolyfills().then(() => {
    defineCustomElements();
});
