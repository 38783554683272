import React, { ReactElement, useMemo } from 'react';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { Stack } from 'common/design-system/components-v2';
import { IEvent, IEventOverviewPanelProvider } from 'common/module_interface/events/Events';
import { EventOverviewRegistry } from 'common/module_interface/events/EventOverviewRegistry';
import { EventOverviewPanels } from 'common/module_interface/events/EventsConsts';

export const RightHeaderPanel: React.FC<{ event: IEvent }> = ({ event }) => {
    const panels: ReactElement[] = useMemo(() => {
        if (!event) return [];

        const allProviders: IEventOverviewPanelProvider[] = EventOverviewRegistry.getPanels(
            EventOverviewPanels.CDR_RIGHT_PANEL,
        );

        const relevantProviders: IEventOverviewPanelProvider[] = allProviders.filter(
            (panelProvider: IEventOverviewPanelProvider) =>
                panelProvider.isRelevant ? panelProvider.isRelevant(event as IKustoEvent) : false,
        );

        return relevantProviders.map((panelProvider: IEventOverviewPanelProvider) => (
            <panelProvider.panel key={panelProvider.panelId} event={event as IKustoEvent} />
        ));
    }, [event]);

    return <Stack spacing={2}>{panels}</Stack>;
};
