import { Location } from 'common/utils/history';
import { changeUrl } from 'common/utils/http';

export function getPropertyValueFromQueryString(location: Location<unknown>, propertyName: string): string {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(propertyName) || '';
}

export function setPropertyValueInQueryString(propertyName: string, value: string): void {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set(propertyName, value);
    changeUrl(window.location.pathname + `?${queryParams.toString()}`);
}
