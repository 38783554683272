import { getStoreService, ISideBarService } from 'common/interface/services';
import { setIsVisible, setLoadedComponentId } from './SideBar.reducer';

export class SideBarService implements ISideBarService {
    setVisibility(isVisible: boolean): void {
        getStoreService().dispatch(setIsVisible(isVisible));
    }

    loadComponent(componentId: string): void {
        getStoreService().dispatch(setLoadedComponentId(componentId));
    }
}
