import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'common/services/store/store';
import { ICloudBotsState } from './cloudBots.interface';

const cloudBotsReducer = (state: RootState) => state.cloudBots as ICloudBotsState;

const getCloudBotsState = createSelector(
    [cloudBotsReducer, (_state: RootState, customizationKey: string) => customizationKey],
    (state, customizationKey) => state.cloudBots[customizationKey],
);

export const CloudBotsSelector = {
    getCloudBotsState,
};
