import {
    renderAddFilter,
    renderClearAll,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter,
    renderSavedFilters,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import i18n from 'common/services/translations/translations';
import { I18nTranslationKey, rolesTableRegistry } from './initRolesPage';
import { AggregationOption, IDataItem, IFilterParams } from 'modules/settings/SimpleTableFilterPage/interfaces';
import { getRolesService, IRole } from 'common/interface/services';
import { filterNameDataForFreeText } from '../../SimpleTableFilterPage/commonFilters';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { filterHasItemsForMultiSelect } from './common';
import { IBasicRole } from 'common/interface/rolesService';
import { IUser } from 'common/interface/user';

export const HAS_USERS = 'hasUsers';
export const HAS_SERVICE_ACCOUNTS = 'hasServiceAccounts';

export interface IFilter {
    key: string;
    options: AggregationOption[];
    getAllOptionsFromRole: (role: IRole) => string[] | boolean[] | null | undefined;
}

export const filterIds: { id: string }[] = [
    { id: FILTERS_KEYS.ADD_FILTER },
    { id: FILTERS_KEYS.FREE_TEXT },
    { id: HAS_USERS },
    { id: HAS_SERVICE_ACCOUNTS },
    { id: FILTERS_KEYS.SAVED_FILTERS },
    { id: FILTERS_KEYS.CLEAR_BUTTON },
];
export const savedFiltersComponentName = 'rolesSavedFilters';
export const filterId = 'rolesFilter';

export const dataService = {
    getAll: async (useCache: boolean): Promise<IDataItem[]> => {
        const roles = await getRolesService().getRoles(useCache);
        const convertToDataItem = (roles: IRole[]) => {
            return roles.map((role) => {
                return {
                    id: role.id,
                    name: role.name,
                    users: role.users,
                    description: role.description,
                    serviceAccounts: role.serviceAccounts,
                } as IDataItem;
            });
        };
        return convertToDataItem(roles);
    },
    delete: async (roleId: string): Promise<void> => {
        return await getRolesService().deleteRole(roleId);
    },
    addRole: async (role: IBasicRole): Promise<IRole> => {
        return await getRolesService().addRole(role);
    },
    getUsers: async (useCache: boolean): Promise<IUser[]> => {
        return await getRolesService().getUsers(useCache);
    },
    updateUser: async (user: IUser): Promise<IUser> => {
        return await getRolesService().updateUser(user);
    },
    updateRole: async (role: IRole): Promise<IRole> => {
        return await getRolesService().updateRole(role);
    },
};

export const calcFilterData = (filtersValues: IFiltersValues, rawData: IRole[]) =>
    rawData.filter(
        (data: any) =>
            filterNameDataForFreeText(data, filtersValues[FILTERS_KEYS.FREE_TEXT]) &&
            filterHasItemsForMultiSelect(filtersValues[HAS_USERS], data['users']) &&
            filterHasItemsForMultiSelect(filtersValues[HAS_SERVICE_ACCOUNTS], data['serviceAccounts']),
    );

export const getAggregations = async (filtersValues: IFiltersValues, data: any): Promise<Aggregations> => {
    const usersFilter: IFilter = {
        key: HAS_USERS,
        options: [],
        getAllOptionsFromRole: (role: IRole) => {
            return [role.users?.length > 0];
        },
    };
    const serviceAccountsFilter: IFilter = {
        key: HAS_SERVICE_ACCOUNTS,
        options: [],
        getAllOptionsFromRole: (role: IRole) => {
            return [role.serviceAccounts?.length > 0];
        },
    };

    const aggregations = [usersFilter, serviceAccountsFilter];

    for (const role of data) {
        for (const aggregation of aggregations) {
            const optionsFromRole = aggregation.getAllOptionsFromRole(role);
            optionsFromRole?.forEach((option) => {
                let isOptionExist = false;
                aggregation.options.forEach((filterOption) => {
                    if (option === filterOption.value) {
                        filterOption.count++;
                        isOptionExist = true;
                    }
                });
                !isOptionExist && aggregation.options.push({ value: option, count: 1 });
            });
        }
    }
    return { [HAS_USERS]: usersFilter.options, [HAS_SERVICE_ACCOUNTS]: serviceAccountsFilter.options };
};

const filterDefsAddins: () => Addin<IFilterProps>[] = () => [
    {
        id: FILTERS_KEYS.ADD_FILTER,
        content: {
            filterProps: { key: FILTERS_KEYS.ADD_FILTER },
            renderFunction: renderAddFilter,
        },
    },
    {
        id: FILTERS_KEYS.FREE_TEXT,
        content: {
            filterProps: {
                key: FILTERS_KEYS.FREE_TEXT, //This needs to be the same as in stored filter/query params
                title: i18n.t('FILTERS.FREE_TEXT', { ns: I18nTranslationKey }),
            },
            renderFunction: renderDefaultFreeTextFilter,
        },
    },
    {
        id: FILTERS_KEYS.SAVED_FILTERS,
        content: function ({ filtersInitialData, savedFiltersComponentName }) {
            return {
                filterProps: {
                    savedFilters: filtersInitialData?.savedFilters || [],
                    savedFiltersComponentName,
                    selectedFilterID: '',
                    key: FILTERS_KEYS.SAVED_FILTERS,
                },
                renderFunction: renderSavedFilters,
            };
        },
    },
    {
        id: FILTERS_KEYS.CLEAR_BUTTON,
        content: {
            filterProps: {
                key: FILTERS_KEYS.CLEAR_BUTTON,
            },
            renderFunction: renderClearAll,
        },
    },
    {
        id: HAS_USERS,
        content: function ({ aggregations }: IFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations[HAS_USERS],
                    key: HAS_USERS,
                    title: i18n.t('FILTERS.HAS_USERS', { ns: I18nTranslationKey }),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
    {
        id: HAS_SERVICE_ACCOUNTS,
        content: function ({ aggregations }: IFilterParams) {
            return {
                filterProps: {
                    initialData: aggregations[HAS_SERVICE_ACCOUNTS],
                    key: HAS_SERVICE_ACCOUNTS,
                    title: i18n.t('FILTERS.HAS_SERVICE_ACCOUNTS', { ns: I18nTranslationKey }),
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
    },
];

export default function initializeRolesFilters() {
    rolesTableRegistry.addFilterAddins(filterDefsAddins());
}
