import { OciEnvironmentData } from 'common/module_interface/assets/OciEnvironment';

export interface OciState {
    ociEvnironments: {
        [ociId: string]: {
            data?: OciEnvironmentData;
            loading?: boolean;
            error?: boolean;
            deleted?: boolean;
        };
    };
}

export const OciInitialState: OciState = {
    ociEvnironments: {},
};
