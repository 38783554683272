import { IColumnUsageDef } from 'common/interface/general';
import { ColDef } from 'ag-grid-enterprise';
import { IssuesRegistry } from 'common/module_interface/RiskManagement/issues/IssuesRegistry';
import { IIssueOrGroup, ISSUE_FIELD_NAMES } from 'modules/riskManagement/components/Issues/Issues.interface';
import { mergeColumnDefs, TABLE_SELECTION_COL_ID } from 'common/utils/tableUtils';

const getColumnDefs = (onClickRuleTitle: (issueOrGroup: IIssueOrGroup) => void): IColumnUsageDef[] => {
    return [
        {
            id: TABLE_SELECTION_COL_ID,
        },
        {
            id: ISSUE_FIELD_NAMES.RULE_TITLE,
            colDefOverride: {
                width: 450,
                minWidth: 200,
                cellRendererParams: {
                    onClick: onClickRuleTitle,
                },
            },
        },
        {
            id: ISSUE_FIELD_NAMES.SEVERITY,
            colDefOverride: {
                minWidth: 80,
                flex: 3,
            },
        },
        {
            id: ISSUE_FIELD_NAMES.CREATED_TIME,
            colDefOverride: {
                minWidth: 120,
                flex: 4,
            },
        },
        {
            id: ISSUE_FIELD_NAMES.ENTITY_NAME,
            colDefOverride: {
                minWidth: 130,
                flex: 6,
            },
        },
        {
            id: ISSUE_FIELD_NAMES.ENTITY_TYPE_BY_PLATFORM,
            colDefOverride: {
                minWidth: 90,
                flex: 3,
            },
        },
        {
            id: ISSUE_FIELD_NAMES.STATUS,
        },
        {
            id: ISSUE_FIELD_NAMES.IS_EXCLUDED,
            colDefOverride: {
                hide: true,
            },
        },
        {
            id: ISSUE_FIELD_NAMES.ENVIRONMENT_ID,
            colDefOverride: {
                minWidth: 120,
                flex: 5,
            },
        },
        {
            id: ISSUE_FIELD_NAMES.PLATFORM,
            colDefOverride: {
                hide: true,
                minWidth: 80,
                flex: 3,
            },
        },
        {
            id: ISSUE_FIELD_NAMES.RULE_ID,
            colDefOverride: {
                minWidth: 140,
                flex: 4,
            },
        },
        {
            id: ISSUE_FIELD_NAMES.ID,
            colDefOverride: {
                minWidth: 80,
                flex: 3,
            },
        },
        {
            id: ISSUE_FIELD_NAMES.ORGANIZATIONAL_UNIT_ID,
            colDefOverride: {
                hide: true,
                minWidth: 100,
                flex: 5,
            },
        },
    ];
};

export const getIssuesPageColumnDefs = (onClickRuleTitle: (issueOrGroup: IIssueOrGroup) => void): ColDef[] => {
    return mergeColumnDefs(getColumnDefs(onClickRuleTitle), IssuesRegistry.getIssuesColumnDefs());
};
