import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getFilterParamsFromURL, initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { getCustomizationService, getStoreService } from 'common/interface/services';
import { getCloudAccountsService, getOrganizationalUnitService } from 'common/interface/data_services';
import { IFiltersInitialData, IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { FindingsFilterPanelDiv } from './FindingsFilterPanel.styled';
import { getSavedFilterDefs } from 'common/utils/filterUtils';
import { FindingsTableRegistry } from 'common/components/KustoEvents/FindingsTableRegistry';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import FilterPanelPlaceholder from 'common/components/FilterPanel/FilterPanelPlaceholder/FilterPanelPlaceholder';
import { GenericObject } from 'common/interface/general';
import { removeEmptyValues } from 'common/erm-components/utils/filters';
import { getStoredFilterValuesById } from 'common/components/FilterPanel/filterUtils';
import { FindingStatusCode, KustoEventFields } from 'common/components/KustoEvents/KustoEvent.const';
import { isEmpty, isUndefined } from 'lodash';
import { setFiltersValues } from 'common/components/FilterPanel/FilterPanel.reducer';

interface FindingsFilterPanelProps {
    filters: IProtectedAssetFilter[];
    disableFilterPanel?: boolean;
    getAggregations: (filtersValues: IFiltersValues) => Promise<Aggregations>;
    getInitialAggregations: () => Promise<Aggregations>;
    filterId: string;
    isArchiveView?: boolean;
    isLoading: boolean;
    onChangeLoadingState: (isLoading: boolean) => void;
}

export const FindingsFilterPanel: React.FC<FindingsFilterPanelProps> = ({
    filters,
    disableFilterPanel,
    getAggregations,
    getInitialAggregations,
    filterId,
    isArchiveView,
    isLoading,
    onChangeLoadingState,
}) => {
    const [filterElement, setFilterElement] = useState(<div></div>);

    const initialFilterValues: IFiltersValues = useMemo(() => {
        const dispatch = getStoreService().dispatch;
        const defaultFilterValues: IFiltersValues = { [KustoEventFields.statusId]: [FindingStatusCode.OPEN] };

        const queryParams = removeEmptyValues(getFilterParamsFromURL());
        if (queryParams && Object.keys(queryParams).length > 0) {
            const result = { ...queryParams };
            if (!Object.keys(queryParams).includes(KustoEventFields.statusId)) {
                result.status = [FindingStatusCode.OPEN];
            }
            dispatch(setFiltersValues({ filtersValues: result, filterId }));
            return result;
        }

        const storedFilterValues: GenericObject<any> = removeEmptyValues(getStoredFilterValuesById(filterId)) || {};

        let filterValues: IFiltersValues = defaultFilterValues;
        if (!isEmpty(storedFilterValues)) {
            if (!isUndefined(isArchiveView)) {
                storedFilterValues[KustoEventFields.isArchived] = [isArchiveView];
            }
            filterValues = storedFilterValues;
        }
        dispatch(setFiltersValues({ filtersValues: filterValues, filterId }));

        return filterValues;
    }, [filterId, isArchiveView]);

    const getFiltersInitialData = useCallback(async () => {
        const recentlyUsedComponentName: string = filters?.find(
            (filterType) => filterType.id === FILTERS_KEYS.RECENTLY_USED_FILTERS,
        )?.filterProps?.componentName;
        const recentlyUsedPromise = getCustomizationService().getCustomization(recentlyUsedComponentName);
        const savedFiltersComponentName: string = filters?.find(
            (filterType) => filterType.id === FILTERS_KEYS.SAVED_FILTERS,
        )?.filterProps?.savedFiltersComponentName;
        const savedFiltersPromise = getCustomizationService().getCustomization(savedFiltersComponentName);

        const organizationalUnitsPromise = getOrganizationalUnitService().getOrganizationalUnitsView();
        return Promise.all([recentlyUsedPromise, savedFiltersPromise, organizationalUnitsPromise]).then(
            ([recentlyUsed, savedFilters, organizationalUnits]) => {
                return {
                    savedFilters,
                    recentlyUsed,
                    organizationalUnits,
                };
            },
        );
    }, [filters]);

    const renderFilterPanel = useCallback(
        async (aggregations: Aggregations) => {
            const filtersInitialData: IFiltersInitialData = await getFiltersInitialData();
            const allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts();
            const filterPanelElementsList = getSavedFilterDefs(
                filters,
                {
                    aggregations,
                    filtersInitialData,
                    allCloudAccounts,
                },
                FindingsTableRegistry.getFilterById,
            );
            const filterPanel = initFilterPanel({
                filterPanelElementsList,
                getAggregations,
                shouldBuildObjectForAPI: true,
                filterId: filterId,
                initialValues: initialFilterValues,
            });
            onChangeLoadingState(false);
            setFilterElement(filterPanel);
        },
        [filterId, filters, getAggregations, getFiltersInitialData, initialFilterValues, onChangeLoadingState],
    );

    useEffect(() => {
        if (isLoading) {
            getInitialAggregations()
                .then((aggregations: Aggregations) => {
                    void renderFilterPanel(aggregations);
                })
                .catch(() => {
                    void renderFilterPanel({});
                });
        }
    }, [getInitialAggregations, isLoading, renderFilterPanel]);

    return (
        <>
            <FindingsFilterPanelDiv isVisible={!disableFilterPanel}>
                {isLoading && <FilterPanelPlaceholder />}
                {!isLoading && filterElement}
            </FindingsFilterPanelDiv>
        </>
    );
};
