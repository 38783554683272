import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
    I18nExclusion,
    EXCLUSION_SELECTED_TYPE,
    ENTITY_FILTER_BY_KEY,
    modalPrefixAnalytics,
} from 'common/components/exclusions/helpers/exclusions.consts';
import { useTranslation } from 'react-i18next';
import { Stack, Message, GroupSelection } from 'common/design-system/components-v2';
import {
    IExclusionConfig,
    IExclusionModalProps,
    IExclusionValidation,
    IState,
    ITags,
} from 'common/components/exclusions/helpers/exclusions.interfaces';
import { exclusionCspmValidation, exclusionSave } from 'common/components/exclusions/helpers/exclusions.utils';
import ExclusionByRuleset from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByRuleset';
import ExclusionComment from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionComment';
import ExclusionByOrganizationalUnit from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByOrganizationalUnit';
import ExclusionByRegion from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByRegion';
import ExclusionByRule from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByRule';
import ExclusionByAccountNumber from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByAccountNumber';
import ExclusionByEnvironment from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByEnvironment';
import ExclusionByFindingType from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByFindingType';
import ExclusionBySeverities from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionBySeverities';
import ExclusionByTags from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByTags';
import ExclusionByEntity from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByEntity';
import ExclusionByDate from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByDate';
import ExclusionWarning from 'common/components/exclusions/helpers/exclusionWarning';
import validateWithYup from 'common/helpers/validateWithYup';
import { getNotificationsService } from 'common/interface/services';
import { ExclusionModuleType } from 'common/interface/exclusion';
import { IRuleset } from 'common/interface/ruleset';

const ExclusionsModalVulnerabilities: FC<IExclusionModalProps> = ({
    isOpen,
    onClose,
    onSave,
    exclusion,
    filterType,
}) => {
    const { t } = useTranslation(I18nExclusion);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [isSaveClicked, setIsSaveClicked] = useState(false);
    const initialState: IState = useMemo(() => {
        return {
            selectedRuleset: undefined,
            selectedRulesetDetails: null,
            selectedComment: '',
            ouEnvironmentRadioButton: '',
            selectedOrganizationalUnit: [],
            selectedEnvironment: [],
            selectedRegion: [],
            selectedDateRange: undefined,
            selectedRule: [],
            selectedAccountNumber: '',
            selectedTags: [],
            selectedSeverities: [],
            entityLogic: null,
            findingTypeLogic: null,
            exclusionId: '',
        };
    }, []);

    const [state, setState] = useState<IState>(initialState);

    useEffect(() => {
        if (!exclusion) return;
        setState((prevState) => ({
            ...prevState,
            selectedComment: exclusion.comment ?? '',
            selectedDateRange: exclusion.dateRange ?? undefined,
            selectedAccountNumber: exclusion.cloudAccountId ?? '',
            ouEnvironmentRadioButton: exclusion.cloudAccountIds
                ? EXCLUSION_SELECTED_TYPE.ENVIRONMENT
                : EXCLUSION_SELECTED_TYPE.ORGANIZATIONAL_UNIT,
            selectedOrganizationalUnit: exclusion.organizationalUnitIds ?? [],
            selectedEnvironment: exclusion.cloudAccountIds ?? [],
            selectedRegion: exclusion.regions ?? [],
            selectedSeverities: exclusion?.severities ?? [],
            exclusionId: exclusion?.id ?? '',
            findingTypeLogic: exclusion?.logicExpressions ?? [],
        }));
    }, [exclusion]);

    const configuredExclusionObject = (): IExclusionValidation => {
        const {
            selectedRuleset,
            selectedComment,
            selectedRegion,
            selectedSeverities,
            entityLogic,
            selectedAccountNumber,
            selectedTags,
            selectedRule,
        } = state;
        return {
            ruleset: selectedRuleset,
            comment: selectedComment,
            requiredAdditionalInfo:
                !!selectedRegion.length ||
                !!selectedSeverities.length ||
                !!entityLogic ||
                !!selectedAccountNumber ||
                !!selectedTags.length ||
                !!selectedRule.length,
        };
    };
    const requestPayload = configuredExclusionObject();
    const formValidations = validateWithYup({
        yupValidationObject: exclusionCspmValidation(t),
        payload: { ...requestPayload },
    });

    const saveExclusion = async () => {
        setIsSaveLoading(true);
        setIsSaveClicked(true);
        try {
            if (!formValidations.valid) {
                setIsSaveLoading(false);
                return;
            }
            onSave && onSave();
            await exclusionSave(state as IExclusionConfig);
            setIsSaveLoading(false);
            getNotificationsService().info(
                exclusion?.id
                    ? `${t('MODAL.TOAST.EXCLUSION_SAVE_SUCCESS.EDIT')} ${t('MODAL.TOAST.EXCLUSION_SAVE_SUCCESS.EDIT_DISCLAIMER')}`
                    : `${t('MODAL.TOAST.EXCLUSION_SAVE_SUCCESS.CREATE')} ${t('MODAL.TOAST.EXCLUSION_SAVE_SUCCESS.CREATE_DISCLAIMER')}`,
                '',
            );
            onClose();
        } catch (error: any) {
            getNotificationsService().error(t('MODAL.GENERAL.SAVE_ERROR'), error);
            setIsSaveLoading(false);
        }
    };

    const handleFilterFields = () => {
        const { ouEnvironmentRadioButton, selectedOrganizationalUnit, selectedEnvironment } = state;
        switch (ouEnvironmentRadioButton) {
            case EXCLUSION_SELECTED_TYPE.ORGANIZATIONAL_UNIT:
                return selectedOrganizationalUnit.map((id) => ({
                    name: ENTITY_FILTER_BY_KEY.ORGANIZATIONAL_UNIT,
                    value: id,
                }));
            case EXCLUSION_SELECTED_TYPE.ENVIRONMENT:
                return selectedEnvironment.map((id) => ({ name: ENTITY_FILTER_BY_KEY.ENVIRONMENT, value: id }));
            default:
                return null;
        }
    };

    const updateState = (key: keyof IState, value: any) => {
        setState((prevState) => ({ ...prevState, [key]: value }));
    };

    const onChangeSeverities = useCallback((value: string[]) => {
        updateState('selectedSeverities', value);
    }, []);
    const onChangeRuleset = useCallback((value: string) => {
        updateState('selectedRuleset', value);
    }, []);
    const onChangeTags = useCallback((value: ITags[]) => {
        updateState('selectedTags', value);
    }, []);

    const onChangeRulesetDetails = useCallback((value: IRuleset | null) => {
        updateState('selectedRulesetDetails', value);
    }, []);
    const onChangeAccountNumber = useCallback((value: string) => {
        updateState('selectedAccountNumber', value);
    }, []);
    const onChangeEntityLogic = useCallback((value: string | null) => {
        updateState('entityLogic', value);
    }, []);
    const onHandleGsl = useCallback((value: string | null) => {
        return `scannedAsset.entityName like '${value}'`;
    }, []);

    const {
        selectedRuleset,
        selectedComment,
        ouEnvironmentRadioButton,
        selectedOrganizationalUnit,
        selectedEnvironment,
        selectedRulesetDetails,
        selectedRegion,
        selectedDateRange,
        selectedSeverities,
        selectedRule,
        selectedTags,
    } = state;
    return (
        <Message
            id={`${modalPrefixAnalytics}-${ExclusionModuleType.ImageAssurance}`}
            width='lg'
            onClose={onClose}
            isOpen={isOpen}
            title={exclusion?.id ? t('MODAL.HEADER.EDIT') : t('MODAL.HEADER.CREATE')}
            cancelBtnText={t('MODAL.FOOTER.CANCEL')}
            onCancel={onClose}
            submitBtnText={t('MODAL.FOOTER.SAVE')}
            onConfirm={saveExclusion}
            isLoading={isSaveLoading}
        >
            <Stack spacing={4} fullWidth>
                {isSaveClicked && !formValidations.valid && <ExclusionWarning />}
                <ExclusionByRuleset
                    formValidations={isSaveClicked ? formValidations.errors?.ruleset : undefined}
                    selectedOption={selectedRuleset}
                    initialSelectedOption={exclusion?.rulesetId}
                    isSaveClicked={isSaveClicked}
                    onChange={onChangeRuleset}
                    onRulesetDetailsChange={onChangeRulesetDetails}
                    onClose={onClose}
                    filterType={filterType}
                    tooltip={t('MODAL.TOOLTIP.RULE_SET')}
                />
                <ExclusionComment
                    formValidations={isSaveClicked ? formValidations.errors?.comment : undefined}
                    isEnabled={!!selectedRuleset}
                    selectedOption={selectedComment}
                    onChange={(value) => updateState('selectedComment', value)}
                    isSaveClicked={isSaveClicked}
                    tooltip={t('MODAL.TOOLTIP.COMMENT')}
                />
                <Stack spacing={1}>
                    <GroupSelection
                        direction='row'
                        label={t('MODAL.TOPICS.ORGANIZATIONAL_UNIT_OR_ENVIRONMENT.TITLE')}
                        labelProps={{ tooltip: t('MODAL.TOOLTIP.ENV_OI') }}
                        value={ouEnvironmentRadioButton}
                        onChange={(value: string) => updateState('ouEnvironmentRadioButton', value)}
                        options={[
                            {
                                dataAid: 'ou-radio-button',
                                label: t('MODAL.TOPICS.ORGANIZATIONAL_UNIT.TITLE'),
                                name: EXCLUSION_SELECTED_TYPE.ORGANIZATIONAL_UNIT,
                                value: EXCLUSION_SELECTED_TYPE.ORGANIZATIONAL_UNIT,
                            },
                            {
                                dataAid: 'enviroment-radio-button',
                                label: t('MODAL.TOPICS.ENVIRONMENT.TITLE'),
                                name: EXCLUSION_SELECTED_TYPE.ENVIRONMENT,
                                value: EXCLUSION_SELECTED_TYPE.ENVIRONMENT,
                            },
                        ]}
                    />
                    {ouEnvironmentRadioButton === EXCLUSION_SELECTED_TYPE.ORGANIZATIONAL_UNIT && (
                        <ExclusionByOrganizationalUnit
                            isEnabled={!!selectedRuleset}
                            selectedRuleset={selectedRuleset}
                            selectedOption={selectedOrganizationalUnit}
                            initialSelectedOption={exclusion?.organizationalUnitIds}
                            onChange={(value) => updateState('selectedOrganizationalUnit', value)}
                            onClose={onClose}
                        />
                    )}
                    {ouEnvironmentRadioButton === EXCLUSION_SELECTED_TYPE.ENVIRONMENT && (
                        <ExclusionByEnvironment
                            isEnabled={!!selectedRuleset}
                            selectedRuleset={selectedRuleset}
                            selectedOption={selectedEnvironment}
                            initialSelectedOption={exclusion?.cloudAccountIds}
                            selectedRulesetDetails={selectedRulesetDetails}
                            onChange={(value) => updateState('selectedEnvironment', value)}
                            onClose={onClose}
                        />
                    )}
                </Stack>
                <ExclusionByRegion
                    isEnabled={!!selectedRuleset}
                    selectedRuleset={selectedRuleset}
                    selectedOption={selectedRegion}
                    initialSelectedOption={exclusion?.regions}
                    selectedRulesetDetails={selectedRulesetDetails}
                    onChange={(value) => updateState('selectedRegion', value)}
                    onClose={onClose}
                    tooltip={t('MODAL.TOOLTIP.REGIONS')}
                />
                <ExclusionByDate
                    selectedOption={selectedDateRange}
                    onChange={(value) => updateState('selectedDateRange', value)}
                    tooltip={t('MODAL.TOOLTIP.DATE_RANGE')}
                />
                <ExclusionByRule
                    isEnabled={!!selectedRuleset && !selectedSeverities.length}
                    selectedRuleset={selectedRuleset}
                    selectedRulesetDetails={selectedRulesetDetails}
                    selectedOption={selectedRule}
                    initialSelectedOption={exclusion?.rules}
                    onChange={(value) => updateState('selectedRule', value)}
                    onClose={onClose}
                    tooltip={t('MODAL.TOOLTIP.RULE')}
                />
                <ExclusionByFindingType
                    initialSelectedOption={exclusion?.logicExpressions}
                    onChange={(value) => updateState('findingTypeLogic', value)}
                    tooltip={t('MODAL.TOOLTIP.FINDING_TYPE')}
                />
                <ExclusionByEntity
                    formValidations={isSaveClicked ? formValidations.errors?.logicExpressions : undefined}
                    filterFields={handleFilterFields()}
                    isEnabled={!!selectedRuleset}
                    selectedRuleset={selectedRuleset}
                    selectedOption={exclusion?.logicExpressions}
                    onChange={onChangeEntityLogic}
                    handleGsl={onHandleGsl}
                    tooltip={t('MODAL.TOOLTIP.ENTITY_NAME')}
                />
                <ExclusionByAccountNumber
                    isEnabled={!!selectedRuleset}
                    selectedRuleset={selectedRuleset}
                    selectedOption={exclusion?.logicExpressions}
                    onChange={onChangeAccountNumber}
                    tooltip={t('MODAL.TOOLTIP.ACCOUNT_NUMBER')}
                />
                <ExclusionByTags
                    isEnabled={!!selectedRuleset}
                    selectedOption={selectedTags}
                    initialSelectedOption={exclusion?.logicExpressions}
                    onChange={onChangeTags}
                    tooltip={t('MODAL.TOOLTIP.TAGS')}
                />
                <ExclusionBySeverities
                    isEnabled={!!selectedRuleset && !selectedRule.length}
                    selectedRuleset={selectedRuleset}
                    selectedOption={selectedSeverities}
                    initialSelectedOption={selectedSeverities}
                    onChange={onChangeSeverities}
                    tooltip={t('MODAL.TOOLTIP.ALERTS_SEVERITY')}
                />
            </Stack>
        </Message>
    );
};

export default ExclusionsModalVulnerabilities;
