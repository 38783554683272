/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { GroupSelection, Input, Stack } from 'common/design-system/components-v2';
import { entityTypes, I18nRemediation } from '../../helpers/remediation.consts';
import { useTranslation } from 'react-i18next';
import { EntityTypesEnum, RemediateByEntityOptionProps } from '../../helpers/remediation.interfaces';
export const RemediateByEntityOption: FC<RemediateByEntityOptionProps> = ({
    isEnabled,
    selectedRuleSet,
    onChange,
    selectedOption,
    formValidations,
}) => {
    const { t } = useTranslation(I18nRemediation);
    const gslText = { name: 'name like', id: 'id like' };
    const [entityType, setEntityType] = useState<EntityTypesEnum>(
        selectedOption && selectedOption.includes(gslText.id) ? EntityTypesEnum.byID : EntityTypesEnum.byName,
    );
    const extractInitialText = (option: string | null): string => {
        if (!option) return '';
        const match = option.match(/(name|id) like '([^']+)'/);
        return match ? match[2] : '';
    };
    const [text, setText] = useState<string>(() => extractInitialText(selectedOption || ''));

    useEffect(() => {
        onChange(text ? translateTextToGSL(text) : null);
    }, [selectedRuleSet, selectedOption]);
    const translateTextToGSL = (text: string): string => {
        return entityType === EntityTypesEnum.byName ? `${gslText.name} '${text}'` : `${gslText.id} '${text}'`;
    };
    const onTextChange = (string: string) => {
        setText(string);
        if (!string || string === '') {
            onChange(null);
        } else {
            const result = translateTextToGSL(string);
            onChange(result);
        }
    };
    const onEntityTypeChange = (newValue: EntityTypesEnum) => {
        setEntityType(newValue);
        onTextChange(text);
    };
    return (
        <Stack>
            <Stack>
                <GroupSelection
                    direction='row'
                    label={t('REMEDIATION.MODALS.ADD_EDIT_MODAL.REMEDIATE_BY_ENTITY')}
                    onChange={(value) => onEntityTypeChange(value as EntityTypesEnum)}
                    value={entityType}
                    options={entityTypes(t)}
                />
                <Input
                    disabled={!isEnabled}
                    data-aid='remediation-name-input'
                    label={
                        entityType === EntityTypesEnum.byName
                            ? t('REMEDIATION.MODALS.ADD_EDIT_MODAL.ENTITY_NAME')
                            : t('REMEDIATION.MODALS.ADD_EDIT_MODAL.ENTITY_ID')
                    }
                    value={text}
                    onChange={(event) => onTextChange(event.target.value)}
                    fullWidth
                    clearable
                    isError={!!formValidations}
                    helperText={formValidations?.[0]?.message}
                />
            </Stack>
        </Stack>
    );
};
export default RemediateByEntityOption;
