import { IApplicationReducer } from 'common/interface/redux';
import registryReducer from './reducers/registry';
import clusterReducer from './reducers/cluster';
import exampleReducer from './reducers/exampleReduxWithHooks';
import clusterReducerNew from './reducers/cluster-new';
import registriesReducer from './reducers/registries';
import shiftLeftReducer from './reducers/shiftLeft';
import { getAppRootRegistry, getStoreService } from 'common/interface/services';
import { ExampleState } from './reducers/exampleReduxWithHooks/example.interface';
import { ClusterState } from './reducers/cluster/cluster.interface';
import { RegistryState } from './reducers/registry/registry.interface';
import { ClusterState as ClusterStateNew } from './reducers/cluster-new/cluster.interface';
import { RegistriesState } from './reducers/registries/registries.interface';
import { ShiftLeftState } from './reducers/shiftLeft/shiftLeft.interface';

export const initializeK8sRedux = () => {
    const reducers: IApplicationReducer[] = [
        {
            name: 'registry',
            reducer: registryReducer,
            isBlackList: true,
        },
        {
            name: 'cluster',
            reducer: clusterReducer,
            isBlackList: true,
        },
        {
            name: 'example',
            reducer: exampleReducer,
            isBlackList: true,
        },
        {
            name: 'k8s_cluster',
            reducer: clusterReducerNew,
            isBlackList: true,
        },
        {
            name: 'k8s_registry',
            reducer: registriesReducer,
            isBlackList: true,
        },
        {
            name: 'k8s_shiftLeft',
            reducer: shiftLeftReducer,
            isBlackList: true,
        },
    ];
    getAppRootRegistry().addReducers(reducers, 'name');
};

interface UserState {
    cloudAccount: {
        d9AwsAccountNumber: string;
    };
}

export interface K8SRootStore {
    registry: RegistryState;
    cluster: ClusterState;
    example: ExampleState;
    k8s_cluster: ClusterStateNew;
    k8s_registry: RegistriesState;
    k8s_shiftLeft: ShiftLeftState;
    user: UserState;
}
interface K8SStoreService {
    getReduxTools(): {
        dispatch: (action: any) => void;
        state: K8SRootStore;
    };
}

type K8SStore = () => K8SStoreService;
export const K8sStoreService: K8SStore = getStoreService;
