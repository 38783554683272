import { AssetTypes } from 'common/assets/assets.const';
import { Vendors } from 'common/consts/vendors';
import { ScanMode } from 'modules/workloads/services/awp/awp.interface';

const assets = new Set([AssetTypes.AWS_INSTANCE, AssetTypes.AZURE_VIRTUALMACHINE, AssetTypes.AZURE_FUNCTIONAPP]);

export const isAwpAsset = (assetType: string) => {
    return assets.has(assetType);
};

export const isScanLicensedImagesEnabled = (scanMode: ScanMode, platform: Vendors) => {
    return [Vendors.AWS].includes(platform) && [ScanMode.InAccount].includes(scanMode);
};
