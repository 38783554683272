export const MALWARE_IGNORE_LIST_SERVICE_ID = 'MALWARE_IGNORE_LIST_SERVICE_ID';
export const MALWARE_IGNORE_LIST_PAGE_ID = 'MALWARE_IGNORE_LIST_PAGE_ID';
export const MALWARE_IGNORE_RESOURCE_URL = 'security-graph/ignore-malwares';
export const MALWARE_IGNORE_FORM_DRAWER_KEY = 'MALWARE_IGNORE_FORM_DRAWER_KEY';

export enum MALWARE_IGNORE_LIST_ITEM_FIELDS {
    MALWARE_IDS = 'malwareIds',
}

export enum MALWARE_IGNORE_LIST_MODEL_FIELDS {
    MALWARE_IDS = 'targetExternalId',
}

export enum MalwareIgnoreNames {
    malwareBox = 'malwareBox',
}

export enum MalwareIgnoreInnerNames {
    malwareIds = 'malwareIds',
}
