import {
    VulnerabilityExclusion,
    VulnerabilityExclusionType,
} from 'modules/workloads/services/vulnerability/vulnerability.interface';
import * as yup from 'yup';
import { DateRange } from 'react-day-picker';

export interface VulnerabilityExclusionFormProps {
    handleCloseModal: () => void;
    isEdit: boolean;
    onConfirm: (
        type: VulnerabilityExclusionType,
        comment: string,
        includeInAssessment: boolean,
        name: string,
        filters: VulnerabilityExclusion['filter'],
    ) => Promise<void>;
    onConfirmEdit: (
        id: string,
        name: string,
        comment: string,
        type: VulnerabilityExclusionType,
        includeInAssessment: boolean,
        filters: VulnerabilityExclusion['filter'],
    ) => Promise<void>;
    initialValues?: VulnerabilityExclusion;
}

export enum Entity {
    ENTITY_NAME = 'entityName',
    ENTITY_ID = 'entityId',
}

export enum Environment {
    ENVIRONMENTS_IDS = 'environments',
    ORGANIZATIONAL_UNITS = 'organizationalUnits',
}

export const DEFAULT_VALUES = {
    name: '',
    cveId: '',
    runAssessment: false,
    comment: '',
    maliciousCategory: '',
    filePath: '',
    toggles: {
        vulnerabilityType: VulnerabilityExclusionType.CVE,
        entity: Entity.ENTITY_NAME,
        envType: Environment.ENVIRONMENTS_IDS,
    },
    environmentsIds: [],
    organizationalUnitsIds: [],
    packageName: '',
    packageVersion: '',
    entityNames: [],
    entityId: '',
    date: {
        from: undefined,
        to: undefined,
    },
};

export type FormValues = {
    name: string;
    cveId: string;
    runAssessment: boolean;
    comment: string;
    maliciousCategory?: string;
    filePath?: string;
    toggles: {
        vulnerabilityType: VulnerabilityExclusionType;
        entity: Entity;
        envType: Environment;
    };
    environmentsIds?: string[];
    organizationalUnitsIds?: string[];
    packageName?: string;
    packageVersion?: string;
    entityNames?: string[];
    entityId?: string;
    date: DateRange;
};

export const SignupSchema = yup.object().shape({
    name: yup.string().trim().required('Name is required'),
    filePath: yup.string().when('toggles.vulnerabilityType', {
        is: (value: VulnerabilityExclusionType) => value === VulnerabilityExclusionType.THREAT,
        then: () => yup.string().trim().required('File Path is required'),
        otherwise: () => yup.string(),
    }),
    packageName: yup.string().when('packageVersion', {
        is: (value: string) => value?.length > 0,
        then: () => yup.string().trim().required('Package Name is required'),
        otherwise: () => yup.string().notRequired(),
    }),
    cveId: yup.string().when('toggles.vulnerabilityType', {
        is: (value: VulnerabilityExclusionType) => value === VulnerabilityExclusionType.CVE,
        then: () => yup.string().trim().required('CVE Id is required'),
        otherwise: () => yup.string(),
    }),
    comment: yup.string().trim().required('Comment is required'),
});
