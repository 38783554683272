import React from 'react';
import { Typography } from 'common/design-system/components-v2';
import { FixableBoxContext } from './FixableBox.styled';

const FixableBox: React.FC<{ onClick?: () => void; totalFixable: number; title?: string }> = ({
    totalFixable,
    title,
    onClick,
}) => (
    <FixableBoxContext
        isClickable={!!onClick}
        count={totalFixable}
        justifyContent={'center'}
        context={totalFixable ? 'important' : 'default'}
    >
        {title && (
            <Typography variant={'body500'} context={'important'}>
                {title}
            </Typography>
        )}
        <Typography variant={'body500'} onClick={onClick} context={totalFixable ? 'important' : 'default'}>
            {totalFixable}
        </Typography>
    </FixableBoxContext>
);

export default FixableBox;
