import { Environment } from './VulnerabilityExclusionForm.types';
import { VulnerabilityExclusionType } from 'modules/workloads/services/vulnerability/vulnerability.interface';

const envGroupSelection = [
    {
        label: 'Environments',
        value: Environment.ENVIRONMENTS_IDS,
        name: 'environments',
    },
    {
        label: 'Organizational Units',
        value: Environment.ORGANIZATIONAL_UNITS,
        name: 'organizationalUnits',
    },
];

const typeGroupSelection = [
    {
        label: 'CVE',
        value: VulnerabilityExclusionType.CVE,
        name: 'cve',
    },
    {
        label: 'Threat',
        value: VulnerabilityExclusionType.THREAT,
        name: 'threat',
    },
    {
        label: 'Secret',
        value: VulnerabilityExclusionType.SECRET,
        name: 'secret',
    },
];

const entityGroupSelection = [
    {
        label: 'Entity Name',
        value: 'entityName',
        name: 'entityName',
    },
    {
        label: 'Entity Id',
        value: 'entityId',
        name: 'entityId',
    },
];

const categoryOptions = [
    { label: 'MaliciousFile', value: 'maliciousFile' },
    { label: 'MaliciousUrl', value: 'maliciousUrl' },
    { label: 'MaliciousIP', value: 'maliciousIP' },
];

enum ExclusionFormFields {
    NAME = 'name',
    CveId = 'cveId',
    RUN_ASSESSMENT = 'runAssessment',
    ENVIRONMENT_IDS = 'environmentsIds',
    ORGANIZATIONAL_UNITS_IDS = 'organizationalUnitsIds',
    COMMENT = 'comment',
    PACKAGE_NAME = 'packageName',
    PACKAGE_VERSION = 'packageVersion',
    ENTITY_NAME = 'entityName',
    ENTITY_ID = 'entityId',
    THREAT = 'threat',
}

export { envGroupSelection, typeGroupSelection, entityGroupSelection, ExclusionFormFields, categoryOptions };
