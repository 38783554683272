import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { FindingSeveritiesMap, getSafeFindingSeverityInfo } from 'common/consts/FindingSeverity';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { openKustoEventDrawer } from '../../Findings.utils';
import { CommonEventFields } from 'common/module_interface/events/EventsConsts';
import { SeverityLevelIconPropsLevel } from 'common/design-system/components-v2/LevelIcon/LevelIcon.types';
import {
    IRuleTitleCellComponentProps,
    RuleTitleCellComponent,
} from 'common/components/ag-grid/Renderers/RuleTitleCellComponent';

const EventTitleCellRenderer: React.FC<
    ICellRendererParams<IKustoEvent> & { onClick?: (finding: IKustoEvent) => void; isGroupColumn: boolean }
> = (params) => {
    if (!params.data) {
        return null;
    }
    const finding: IKustoEvent = params.data;
    const ruleTitle: string = finding.ruleName;
    if (!ruleTitle) {
        return null;
    }
    const componentProps: IRuleTitleCellComponentProps = {
        params,
        ruleTitle,
        ruleColId: CommonEventFields.ruleName,
        severityLevel: finding.severityLevel,
        drawerOpener: () => openKustoEventDrawer(finding),
        getSeverityKey: (severity?: number): SeverityLevelIconPropsLevel => {
            return severity === undefined ? FindingSeveritiesMap.unknown.key : getSafeFindingSeverityInfo(severity).key;
        },
        iconName: 'securityEvent',
    };
    return <RuleTitleCellComponent {...componentProps} />;
};

export default EventTitleCellRenderer;
