import {
    IComplianceResponse,
    IComplianceStatistics,
    IComplianceTrendResponse,
    WidgetSeverityResults,
} from 'common/components/Compliance/Compliance.interface';
import { getHttpService, RULES_MANAGEMENT_SERVICE_ID } from 'common/interface/services';
import {
    DashboardWidgetTypes,
    IDashboardWidget,
    IDashboardWidgetComplianceObject,
    IGenericWidgetData,
} from 'common/module_interface/overview/Interface';
import dayjs from 'dayjs';
import i18next, { t } from 'i18next';
import moment from 'moment-mini';
import { COMPLIANCE_TYPES, LegendTypeEnum, SeverityEnum, widgetsTypeFieldSetsCount } from '../Const/const';
import { i18nPostureNamespace } from '../initialize.i18n';
import { changeUrl } from 'common/utils/http';
import { getCloudAccountsService, getOrganizationalUnitService } from 'common/interface/data_services';
import { getService } from 'common/extensibility/AddinContainer';
import RuleManageService from '../RulesManagement/services/RuleManagement.service';

export const complianceDataGetter = async (widget: IDashboardWidget, convertData?: Function) => {
    const promises = [] as Promise<any>[];
    if (widget.options.compliance?.length === widgetsTypeFieldSetsCount[widget.type]) {
        widget.options?.compliance &&
            widget.options?.compliance.forEach((item) => {
                const complianceType =
                    widget.options?.compliance &&
                    widget.options?.compliance[0].type &&
                    widget.options.compliance[0].type?.toLowerCase();
                if (item.rulesetId !== 0 && item.selected !== '') {
                    let apiConfig = null;
                    switch (complianceType) {
                        case COMPLIANCE_TYPES.OU: {
                            apiConfig = {
                                path: 'AssessmentHistoryV2/OrganizationalUnitsLastAssessmentStatistics',
                                data: {
                                    bundleIds: [item.rulesetId],
                                    organizationalUnitIds: [item.selected],
                                },
                            };
                            break;
                        }
                        case COMPLIANCE_TYPES.ENV: {
                            apiConfig = {
                                path: 'AssessmentHistoryV2/LastAssessmentResults/view',
                                data: {
                                    cloudAccountBundleFilters: [
                                        {
                                            bundleIds: [item.rulesetId],
                                            cloudAccountIds: [item.selected],
                                            cloudAccountType: item.platform,
                                        },
                                    ],
                                },
                            };
                            break;
                        }
                    }

                    const response = getHttpService().post<IComplianceResponse>({
                        path: apiConfig?.path,
                        requestObject: { data: apiConfig?.data },
                        loggingConfig: { useLogging: false },
                        cachingConfig: { useCache: true },
                    });
                    promises.push(response);
                }
            });
    }

    const response = await Promise.all(promises)
        .catch(function () {
            return [];
        })
        .then((results) => {
            return results;
        });
    if (widget.type === DashboardWidgetTypes.Gauge) {
        return convertData && (await convertData(response[0], widget));
    }

    return convertData && (await convertData(response.length > 0 ? response[0] : [], widget));
};

export const buildComplianceGaugeWidgetLink = (widget: IDashboardWidget, id?: number) => {
    const OU = 'organizational unit';
    const complianceData: any = widget.options.compliance?.length && widget.options?.compliance[0];
    if (complianceData.type?.toLowerCase() === OU) {
        const queryObject = {
            pageNumber: 1,
            sorting: {
                fieldName: 'createdTime',
                direction: -1,
            },
            filterFields: [
                {
                    name: 'bundleId',
                    value: complianceData.rulesetId,
                },
            ],
            creationTime: {
                from: moment().subtract(24, 'hour').toISOString(),
                to: moment().toISOString(),
            },
            HideCount: true,
        };
        const time = {
            time: '24 Hrs',
        };

        const queryObjectString = JSON.stringify(queryObject);
        const timeString = JSON.stringify(time);

        return changeUrl(`/compliance-engine/history?query=${queryObjectString}&time=${timeString}`);
    }
    if (id) {
        return changeUrl(`/compliance-engine/result/${id}`);
    }
};

export const getComplianceGaugeResults = (
    response: IComplianceResponse[],
    widget: IDashboardWidget,
): IGenericWidgetData => {
    if (response.length === 0) {
        return {
            items: [],
        };
    }
    const complianceData: IDashboardWidgetComplianceObject =
        (widget.options?.compliance?.length && widget.options.compliance[0]) ||
        ({} as IDashboardWidgetComplianceObject);

    const severities: WidgetSeverityResults = {
        passed: { critical: 0, high: 0, medium: 0, low: 0, informational: 0 },
        failed: { critical: 0, high: 0, medium: 0, low: 0, informational: 0 },
    };

    let aggregatedLogicallyTested = 0;
    let aggregatedFailedTest = 0;

    function processStats(statistics: IComplianceStatistics) {
        const { excludedTests = 0, excludedFailedTests = 0 } = statistics;
        aggregatedFailedTest += statistics.failedTests - excludedFailedTests;
        aggregatedLogicallyTested += statistics.logicallyTested - excludedTests;
        Object.values(SeverityEnum).forEach((severity) => {
            if (statistics.totalRelevantTestsBySeverity && statistics.totalRelevantTestsBySeverity[severity]) {
                severities.passed[severity] +=
                    (statistics.totalRelevantTestsBySeverity[severity] || 0) -
                    (statistics?.failedTestsBySeverity?.[severity] || 0);
            }
            if (statistics.failedTestsBySeverity && statistics.failedTestsBySeverity[severity]) {
                severities.failed[severity] += statistics.failedTestsBySeverity[severity] || 0;
            }
        });
    }

    response.forEach((item) => {
        if (item.statistics) {
            processStats(item.statistics);
        } else if (item.stats) {
            processStats(item.stats);
        }
    });

    const checkLegendIsHidden = (severity: SeverityEnum, type: LegendTypeEnum) => {
        if (!complianceData?.legendType) return true;
        return (!severities.failed[severity] && !severities.passed[severity]) || complianceData?.legendType !== type;
    };

    const calculatePercentage = (value: number, total: number) => {
        if (total === 0) return '';
        const percentage = (value / total) * 100;
        return `${percentage.toFixed(1)}%`;
    };
    const result = {
        items: [
            // For this array always the first item will be the numerator and the second item will be the denominator.
            {
                key: i18next.t('WIDGETS.PASSED_TESTS', { ns: i18nPostureNamespace }),
                value: aggregatedLogicallyTested - aggregatedFailedTest,
                onclick: () => buildComplianceGaugeWidgetLink(widget, response[0]?.id),
                isHidden: complianceData?.legendType
                    ? complianceData?.legendType === LegendTypeEnum.BY_SEVERITY
                    : false,
            },
            {
                key: i18next.t('WIDGETS.TOTAL_TESTED', { ns: i18nPostureNamespace }),
                value: aggregatedLogicallyTested,
                onclick: () => buildComplianceGaugeWidgetLink(widget, response[0]?.id),
                isHidden: complianceData?.legendType
                    ? complianceData?.legendType === LegendTypeEnum.BY_SEVERITY
                    : false,
            },
            {
                key: i18next.t('WIDGETS.CRITICAL', { ns: i18nPostureNamespace }),
                value: `${severities.failed.critical}/${severities.passed.critical}`,
                subtitle: `${calculatePercentage(severities.failed.critical, severities.failed.critical + severities.passed.critical)}`,
                tooltip: i18next.t('WIDGETS.WIDGET_TOOLTIP', { ns: i18nPostureNamespace }),
                onclick: () => buildComplianceGaugeWidgetLink(widget, response[0]?.id),
                isHidden: checkLegendIsHidden(SeverityEnum.critical, LegendTypeEnum.BY_SEVERITY),
            },
            {
                key: i18next.t('WIDGETS.HIGH', { ns: i18nPostureNamespace }),
                value: `${severities.failed.high}/${severities.passed.high}`,
                tooltip: i18next.t('WIDGETS.WIDGET_TOOLTIP', { ns: i18nPostureNamespace }),
                subtitle: `${calculatePercentage(severities.failed.high, severities.failed.high + severities.passed.high)}`,
                onclick: () => buildComplianceGaugeWidgetLink(widget, response[0]?.id),
                isHidden: checkLegendIsHidden(SeverityEnum.high, LegendTypeEnum.BY_SEVERITY),
            },
            {
                key: i18next.t('WIDGETS.MEDIUM', { ns: i18nPostureNamespace }),
                value: `${severities.failed.medium}/${severities.passed.medium}`,
                tooltip: i18next.t('WIDGETS.WIDGET_TOOLTIP', { ns: i18nPostureNamespace }),
                subtitle: `${calculatePercentage(severities.failed.medium, severities.failed.medium + severities.passed.medium)}`,
                onclick: () => buildComplianceGaugeWidgetLink(widget, response[0]?.id),
                isHidden: checkLegendIsHidden(SeverityEnum.medium, LegendTypeEnum.BY_SEVERITY),
            },
            {
                key: i18next.t('WIDGETS.LOW', { ns: i18nPostureNamespace }),
                value: `${severities.failed.low}/${severities.passed.low}`,
                tooltip: i18next.t('WIDGETS.WIDGET_TOOLTIP', { ns: i18nPostureNamespace }),
                subtitle: `${calculatePercentage(severities.failed.low, severities.failed.low + severities.passed.low)}`,
                onclick: () => buildComplianceGaugeWidgetLink(widget, response[0]?.id),
                isHidden: checkLegendIsHidden(SeverityEnum.low, LegendTypeEnum.BY_SEVERITY),
            },
            {
                key: i18next.t('WIDGETS.INFORMATIONAL', { ns: i18nPostureNamespace }),
                value: `${severities.failed.informational}/${severities.passed.informational}`,
                tooltip: i18next.t('WIDGETS.WIDGET_TOOLTIP', { ns: i18nPostureNamespace }),
                subtitle: `${calculatePercentage(severities.failed.informational, severities.failed.informational + severities.passed.informational)}`,
                onclick: () => buildComplianceGaugeWidgetLink(widget, response[0]?.id),
                isHidden: checkLegendIsHidden(SeverityEnum.informational, LegendTypeEnum.BY_SEVERITY),
            },
        ],
        options: {
            threshold: widget.options.thresholds,
        },
    };
    return result;
};

export const complianceTrendDataGetter = async (widget: IDashboardWidget, convertData?: Function) => {
    const promises = [] as Promise<any>[];
    if (widget.options.compliance?.length) {
        widget.options?.compliance &&
            widget.options?.compliance.forEach((item) => {
                if (!!item.rulesetId && !!item.selected) {
                    const creationTimeFrom = dayjs().startOf('day').add(-30, 'days');
                    const creationTimeTo = dayjs().set('seconds', 0).set('milliseconds', 0).startOf('day');
                    let response = {} as Promise<any>;
                    const complianceType = item.type && item.type.toLowerCase();
                    switch (complianceType) {
                        case COMPLIANCE_TYPES.OU: {
                            const params = `rulesetId=${item.rulesetId}&organizationalUnitId=${item.selected}&from=${creationTimeFrom}&to=${creationTimeTo}`;
                            response = getHttpService().get({
                                path: `AssessmentHistoryV2/assessmentTrendOrganizationalUnit?${params}`,
                                cachingConfig: { useCache: true },
                            });
                            break;
                        }
                        case COMPLIANCE_TYPES.ENV: {
                            const params = `bundleId=${item.rulesetId}&cloudAccountId=${item.selected}&from=${creationTimeFrom}&to=${creationTimeTo}`;
                            response = getHttpService().get({
                                path: `AssessmentHistoryV2/assessmentTrendV2?${params}`,
                                cachingConfig: { useCache: true },
                            });
                            break;
                        }
                    }
                    promises.push(response);
                }
            });
    }

    const response = await Promise.allSettled(promises)
        .catch(function () {
            return [];
        })
        .then((results) => {
            return results;
        });

    const filteredResults = response.filter((result) => result.status === 'fulfilled');

    return convertData && (await convertData(filteredResults, widget));
};

export const setTrendDataItems = async (responses: IComplianceTrendResponse[][], widget?: IDashboardWidget) => {
    const cloudAccounts = await getCloudAccountsService().getAllCloudAccounts(true);
    const organizationalUnits = await getOrganizationalUnitService().getAllOrganizationalUnitsFlat();
    const rulesets =
        await getService<RuleManageService>(RULES_MANAGEMENT_SERVICE_ID).getRulesetsViewsFromComplianceServer();

    const tooltip = {
        formatter: function (this: any) {
            return `${this.x} - ${this.y}%`;
        },
    };
    const trendData: { key: string; value: any }[] = [];
    responses.forEach((response: IComplianceTrendResponse[], index: number) => {
        const res = response as any;
        const values = res.value;
        const singleTrendData: any = [];
        if (!values || (values && values.length === 0)) {
            return {
                items: [],
            };
        }
        values.forEach((result: IComplianceTrendResponse) => {
            const percentageOfTests = parseFloat(((result.passedTests / result.totalTests) * 100).toFixed(2));
            singleTrendData.push({
                key: dayjs(result.assessmentDate).format('YYYYMMDD'),
                value: percentageOfTests,
                // onclick: () => changeUrl(`/compliance-engine//history?query=%7B"pageNumber":2,"sorting":%7B"fieldName":"createdTime","direction":-1%7D,"filterFields":%5B%7B"name":"cloudAccountId","value":"${result.cloudAccountId}"name":"bundleId","value":${result.rulesetId},"creationTime":%7B"from":"2023-08-26T23:00:00","to":"2023-08-27T22:59:59"%7D,"HideCount":true%7D&time=%7B"time":%7B"Custom":%7B"from":${result.assessmentDate},"to":${result.assessmentDate}}}`)
            });
        });
        let legendLabel = null;
        const currentEnv = widget?.options?.compliance ? widget.options.compliance[index] : null;
        if (currentEnv) {
            const organizationalUnit = organizationalUnits.find((item) => item.id === currentEnv.selected);
            const environment = cloudAccounts.find((item) => item.id === currentEnv.selected);
            const ruleset = rulesets.find((item) => item.id === currentEnv.rulesetId);
            if (environment || ruleset) {
                if (environment) {
                    legendLabel = `${environment?.name || ''}${environment && ruleset ? '-' : ''}${ruleset?.name || ''}`;
                } else if (organizationalUnit) {
                    legendLabel = `${organizationalUnit?.name || ''}${organizationalUnit && ruleset ? '-' : ''}${ruleset?.name || ''}`;
                } else {
                    legendLabel = ruleset?.name || '';
                }
            }
        }

        trendData.push({ key: legendLabel || String(index), value: singleTrendData });
    });
    const allDates = trendData
        .reduce((result: number[], item) => {
            item.value.forEach((value: any) => {
                if (!result.includes(value.key)) {
                    result.push(value.key);
                }
            });
            return result;
        }, [])
        .sort((a, b) => a - b);

    const trendDataAddMissingValues: any = trendData.map((item) => {
        return {
            key: item.key,
            value: allDates.map((date) => {
                const found = item.value.find((value: any) => value.key === date);
                const dateFormatted = dayjs(date).format('D MMM');
                if (found) {
                    return { key: dateFormatted, value: found.value };
                } else {
                    return { key: dateFormatted, value: null };
                }
            }),
        };
    });

    return { items: trendDataAddMissingValues, options: { tooltip: tooltip } };
};

const getResultByTime = (data: IComplianceTrendResponse[], type: 'day' | 'week' | 'month') => {
    switch (type) {
        case 'day': {
            const lastDay = data[data.length - 1];
            const lastDayPassedRate = lastDay.passedTests / lastDay.totalTests;
            const lastDayMinusOne = data[data.length - 2];
            const lastDayMinusOnePassedRate = lastDayMinusOne.passedTests / lastDayMinusOne.totalTests;
            const diff = Number(((lastDayPassedRate - lastDayMinusOnePassedRate) * 100).toFixed(2));
            return {
                diff,
                title: getTitleByType(type),
            };
        }
        case 'week': {
            const lastDay = data[data.length - 1];
            const lastDayPassedRate = lastDay.passedTests / lastDay.totalTests;
            const lastDayMinusWeek = data[data.length - 8];
            const lastDayMinusWeekPassedRate = lastDayMinusWeek.passedTests / lastDayMinusWeek.totalTests;
            const diff = Number(((lastDayPassedRate - lastDayMinusWeekPassedRate) * 100).toFixed(2));
            return {
                diff,
                title: getTitleByType(type),
            };
        }
        case 'month': {
            const lastDay = data[data.length - 1];
            const lastDayPassedRate = lastDay.passedTests / lastDay.totalTests;
            const lastDayMinusMonth = data[0];
            const lastDayMinusMonthPassedRate = lastDayMinusMonth.passedTests / lastDayMinusMonth.totalTests;
            const diff = Number(((lastDayPassedRate - lastDayMinusMonthPassedRate) * 100).toFixed(2));
            return {
                diff,
                title: getTitleByType(type),
            };
        }
    }
};

function getTitleByType(type: 'day' | 'week' | 'month') {
    switch (type) {
        case 'day':
            return t('OVERVIEW.DASHBOARD.WIDGETS.TREND_LINE.DAY');
            break;
        case 'week':
            return t('OVERVIEW.DASHBOARD.WIDGETS.TREND_LINE.WEEK');
            break;
        case 'month':
            return t('OVERVIEW.DASHBOARD.WIDGETS.TREND_LINE.MONTH');
            break;
    }
}

export const generateTrendSummaryData = (response: IComplianceTrendResponse[][]): IGenericWidgetData => {
    const res = response as any;
    const data = res[0] ? res[0].value : [];
    if (data.length > 0) {
        return {
            items: data.length > 0 ? [{ key: 'data', value: 1 }] : [],
            options: {
                result: { denominator: data[data.length - 1].passedTests, numerator: data[data.length - 1].totalTests },
                section1: getResultByTime(data, 'day'),
                section2: getResultByTime(data, 'week'),
                section3: getResultByTime(data, 'month'),
            },
        };
    } else return { items: [] };
};

export const generateTrendlineChangeSummaryData = async (
    response: IComplianceTrendResponse[][],
    widget: IDashboardWidget,
): Promise<IGenericWidgetData> => {
    // result should be sorted from new to old
    const trendChangeSummaryData = generateTrendSummaryData(response);
    const trendData = await setTrendDataItems(response, widget);

    return {
        items: trendData.items,
        options: { trendOptions: trendData.options, changeSummaryOptions: trendChangeSummaryData.options },
    };
};
