import React from 'react';
import { getComplianceFindingApiService, IEvent } from 'common/module_interface/events/Events';
import { ComplianceFindingAssign } from 'common/module_interface/events/complianceFindingApi.interface';

export interface UseMutateEventAssignRequest {
    onSuccess?: (response: ComplianceFindingAssign.Response) => void;
    onError?: (error: string) => void;
}

export interface UseMutateEventAssignPayload {
    isLoading: boolean;
    isError: boolean;
    results?: ComplianceFindingAssign.Response;

    assign: (events: IEvent[], assigneeUserName: string | null) => Promise<void>;
    reset?: () => void;
}

export type UseMutateEventAssignReset = (req?: UseMutateEventAssignRequest) => UseMutateEventAssignPayload;

export const useMutateEventAssign: UseMutateEventAssignReset = (req) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isError, setIsError] = React.useState<boolean>(false);
    const [results, setResults] = React.useState<ComplianceFindingAssign.Response>();

    const assign = React.useCallback(
        async (events: IEvent[], assigneeUserName: string | null) => {
            setIsError(false);
            setIsLoading(true);
            try {
                const response = await getComplianceFindingApiService().assign({
                    ids: events.map((event) => event.id),
                    details: { assigneeUserName },
                });
                setResults(response.data);
                req?.onSuccess?.(response.data);
            } catch (error: any) {
                setIsError(true);
                req?.onError?.(error?.message || 'An error occurred');
            } finally {
                setIsLoading(false);
            }
        },
        [req],
    );

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setIsError(false);
        setResults(undefined);
    }, []);

    return {
        isLoading,
        isError,
        results,

        assign,
        reset,
    };
};
