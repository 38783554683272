import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Dot } from '../../components/Dot/Dot';
import { aggregationByProperty } from '../../protectedAsset/page/tabs/Vulnerability/VulnerabilityTabs/Cves/ColumnDefs';

const DotCellRenderer: React.FC<ICellRendererParams> = ({ node, value }) => {
    if (node.group) {
        return aggregationByProperty(node?.allLeafChildren?.map((child) => child.data) ?? [], 'isExcluded');
    }
    if (value === '') {
        return 'N/A';
    }
    if (value === undefined || value === null) {
        return '';
    }
    const isValueTruthy = typeof value === 'string' ? value === 'true' : Boolean(value);
    return <Dot isTrue={isValueTruthy} />;
};

export default DotCellRenderer;
