import {
    ISSUE_EXCLUSION_COND_NAMES,
    ISSUE_EXCLUSIONS_RESOURCE_URL,
} from '../components/IssueExclusions/IssueExclusions.consts';
import { getHttpService } from 'common/interface/services';
import { generalApiError } from 'common/utils/http';
import { clearCacheDataByTag, getCacheTag } from 'common/utils/apiCaching';
import {
    IIssueExclusion,
    IIssueExclusionProps,
    IIssueExclusionsService,
} from '../components/IssueExclusions/IssueExclusions.interface';
import { ISelectOptionsMap } from '../RiskManagement.interface';
import { ICompoundFilterNode } from 'common/erm-components/custom/FilterTree/CompoundFilter';
import { FilterConditionOperator, IFilterCondition } from 'common/erm-components/custom/FilterTree/FilterCondition';
import { Tag } from 'common/components/Tags/Tags.types';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';

import { sendHttpRequest } from 'common/erm-components/utils/ermComponents.http';
import { IdResponse } from 'common/erm-components/utils/ermComponents.interface';
import {
    addConditionIfExists,
    getConditionsMap,
    getConditionValueAsSingle,
    getConditionValues,
    IConditionsMap,
} from '../RiskManagement.conditions';
import {
    createSelectOption,
    getAllEntityTypeOptions,
    getAllEnvironmentOptions,
    getAllOrgUnitOptions,
    getAllRulesOptions,
    getSelectOptionsMap,
} from '../RiskManagement.options';
import { getCleanCompoundFilter } from 'common/erm-components/utils/filters';
import { getCleanOrgUnitIds } from 'common/erm-components/utils/orgUnits';

const ISSUE_EXCLUSION_SERVICE_NAME = 'ISSUE_EXCLUSION';

const getIssueExclusionUrl = (issueExclusionId: string) => `${ISSUE_EXCLUSIONS_RESOURCE_URL}/${issueExclusionId}`;

interface IIssueExclusionUpdateModel {
    name: string;
    description?: string;
    filter?: ICompoundFilterNode;
    organizationalUnitIdsFilter?: string[];
}

interface IIssueExclusionModel extends IIssueExclusionUpdateModel {
    _id: string;
}

const getExpirationDateFromTimestamps = (timestamps: string[]): Date | undefined => {
    return timestamps.length === 2 ? new Date(timestamps[1]) : undefined;
};

const getTimestampsFromExpirationDate = (expirationDate?: Date): string[] | undefined => {
    if (expirationDate) {
        const now = new Date();
        const from: Date = expirationDate.getTime() < now.getTime() ? new Date(expirationDate.getTime() - 100) : now;
        return [from.toISOString(), expirationDate.toISOString()];
    }
};

type ITagsMap = { [key: string]: string };
const getTagsFromTagObjects = (tagObjects: ITagsMap[]): Tag[] => {
    const tags: Tag[] = [];
    tagObjects.forEach((tagObject: ITagsMap) => {
        const props: string[] = Object.keys(tagObject);
        if (props.length === 1) {
            const key = props[0];
            tags.push({
                key,
                value: tagObject[key],
            });
        }
    });
    return tags;
};

const getTagObjectsFromTags = (tags: Tag[]): ITagsMap[] => {
    return tags.map((tag: Tag) => ({ [tag.key]: tag.value }));
};

const createExclusionUpdateModel = (updateProps: IIssueExclusionProps): IIssueExclusionUpdateModel => {
    const conditions: IFilterCondition[] = [];
    addConditionIfExists(conditions, ISSUE_EXCLUSION_COND_NAMES.RULE_ID, updateProps.ruleIds);
    addConditionIfExists(conditions, ISSUE_EXCLUSION_COND_NAMES.SEVERITY, updateProps.severities);
    addConditionIfExists(conditions, ISSUE_EXCLUSION_COND_NAMES.ENV_ID, updateProps.envIds);
    addConditionIfExists(
        conditions,
        ISSUE_EXCLUSION_COND_NAMES.ENTITY_NAME,
        updateProps.entityNames,
        FilterConditionOperator.LikeAny,
    );
    addConditionIfExists(
        conditions,
        ISSUE_EXCLUSION_COND_NAMES.ENTITY_EXTERNAL_ID,
        updateProps.entityId ? [updateProps.entityId] : undefined,
        FilterConditionOperator.Equals,
    );
    addConditionIfExists(conditions, ISSUE_EXCLUSION_COND_NAMES.ENTITY_TYPE_BY_PLATFORM, updateProps.entityTypes);
    addConditionIfExists(
        conditions,
        ISSUE_EXCLUSION_COND_NAMES.TIMESTAMP,
        getTimestampsFromExpirationDate(updateProps.expirationDate),
        FilterConditionOperator.Between,
    );
    addConditionIfExists(
        conditions,
        ISSUE_EXCLUSION_COND_NAMES.ENTITY_TAGS,
        getTagObjectsFromTags(updateProps.tags),
        FilterConditionOperator.HasAny,
    );

    const filter: ICompoundFilterNode | undefined = getCleanCompoundFilter(conditions);
    return {
        filter,
        name: updateProps.name,
        description: updateProps.description.trim() || undefined,
        organizationalUnitIdsFilter: getCleanOrgUnitIds(updateProps.orgUnitIds),
    };
};

const createExclusionFromModel = (
    issueExclusionModel: IIssueExclusionModel,
    allOrgUnitOptions: ISelectOptionsMap,
    allEnvironmentOptionsMap: ISelectOptionsMap,
    allRuleOptionsMap: ISelectOptionsMap,
    allEntityTypeOptionsMap: ISelectOptionsMap,
): IIssueExclusion => {
    const { filter } = issueExclusionModel;
    const condMap: IConditionsMap = getConditionsMap(filter);
    const orgUnitIds: string[] = issueExclusionModel.organizationalUnitIdsFilter || [];
    const orgUnitOptions: SelectOption[] = orgUnitIds.map((id) => allOrgUnitOptions[id] || createSelectOption(id));
    const ruleIds: string[] = getConditionValues<string>(ISSUE_EXCLUSION_COND_NAMES.RULE_ID, condMap) || [];
    const ruleOptions: SelectOption[] = ruleIds.map((id) => allRuleOptionsMap[id] || createSelectOption(id));
    const envIds: string[] = getConditionValues<string>(ISSUE_EXCLUSION_COND_NAMES.ENV_ID, condMap) || [];
    const envOptions: SelectOption[] = envIds.map((id) => allEnvironmentOptionsMap[id] || createSelectOption(id));
    const entityTypes: string[] =
        getConditionValues<string>(ISSUE_EXCLUSION_COND_NAMES.ENTITY_TYPE_BY_PLATFORM, condMap) || [];
    const entityTypeOptions: SelectOption[] = entityTypes.map(
        (id) => allEntityTypeOptionsMap[id] || createSelectOption(id),
    );
    return {
        name: issueExclusionModel.name,
        description: issueExclusionModel.description ?? '',
        id: issueExclusionModel._id,
        ruleIds,
        ruleOptions,
        severities: getConditionValues<number>(ISSUE_EXCLUSION_COND_NAMES.SEVERITY, condMap) || [],
        envIds,
        envOptions,
        entityNames: getConditionValues<string>(ISSUE_EXCLUSION_COND_NAMES.ENTITY_NAME, condMap) || [],
        entityId: getConditionValueAsSingle<string>(ISSUE_EXCLUSION_COND_NAMES.ENTITY_EXTERNAL_ID, condMap) || '',
        entityTypes,
        entityTypeOptions,
        expirationDate: getExpirationDateFromTimestamps(
            getConditionValues<string>(ISSUE_EXCLUSION_COND_NAMES.TIMESTAMP, condMap) || [],
        ),
        tags: getTagsFromTagObjects(
            getConditionValues<ITagsMap>(ISSUE_EXCLUSION_COND_NAMES.ENTITY_TAGS, condMap) || [],
        ),
        orgUnitIds,
        orgUnitOptions,
    };
};

const createExclusionsFromModel = async (issueExclusionModels: IIssueExclusionModel[]): Promise<IIssueExclusion[]> => {
    const allOrgUnitOptionsMap: ISelectOptionsMap = getSelectOptionsMap(await getAllOrgUnitOptions());
    const allEnvironmentOptionsMap: ISelectOptionsMap = getSelectOptionsMap(await getAllEnvironmentOptions());
    const allRuleOptionsMap: ISelectOptionsMap = getSelectOptionsMap(await getAllRulesOptions());
    const allEntityTypeOptionsMap: ISelectOptionsMap = getSelectOptionsMap(await getAllEntityTypeOptions());
    return issueExclusionModels.map((model) =>
        createExclusionFromModel(
            model,
            allOrgUnitOptionsMap,
            allEnvironmentOptionsMap,
            allRuleOptionsMap,
            allEntityTypeOptionsMap,
        ),
    );
};

export class IssueExclusionsService implements IIssueExclusionsService {
    private clearMultiActionsCache() {
        clearCacheDataByTag(ISSUE_EXCLUSION_SERVICE_NAME);
    }
    private clearSpecificActionCache(issueExclusionId: string) {
        clearCacheDataByTag(ISSUE_EXCLUSION_SERVICE_NAME, issueExclusionId);
    }
    public async getAllIssueExclusions(): Promise<IIssueExclusion[]> {
        const exclusionModels: IIssueExclusionModel[] = await sendHttpRequest<IIssueExclusionModel[]>(
            ISSUE_EXCLUSIONS_RESOURCE_URL,
            { method: 'GET' },
            undefined,
            [getCacheTag(ISSUE_EXCLUSION_SERVICE_NAME)],
        );
        return createExclusionsFromModel(exclusionModels);
    }

    public async updateIssueExclusion(issueExclusionId: string, updateProps: IIssueExclusionProps): Promise<string> {
        const updateModel: IIssueExclusionUpdateModel = createExclusionUpdateModel(updateProps);
        this.clearMultiActionsCache();
        this.clearSpecificActionCache(issueExclusionId);
        return getHttpService().request<string>(
            getIssueExclusionUrl(issueExclusionId),
            {
                method: 'PUT',
                data: updateModel,
            },
            undefined,
            generalApiError,
        );
    }

    public async createIssueExclusion(updateProps: IIssueExclusionProps): Promise<string> {
        const updateModel: IIssueExclusionUpdateModel = createExclusionUpdateModel(updateProps);
        this.clearMultiActionsCache();
        const response: IdResponse = await getHttpService().request<IdResponse>(
            ISSUE_EXCLUSIONS_RESOURCE_URL,
            {
                method: 'POST',
                data: updateModel,
            },
            undefined,
            generalApiError,
        );
        return response.id;
    }

    public async deleteIssueExclusion(issueExclusionId: string, multiTagAlreadyCleared?: boolean): Promise<string> {
        if (!multiTagAlreadyCleared) {
            this.clearMultiActionsCache();
        }
        this.clearSpecificActionCache(issueExclusionId);
        return getHttpService().request<string>(
            getIssueExclusionUrl(issueExclusionId),
            {
                method: 'DELETE',
            },
            undefined,
            generalApiError,
        );
    }

    public async deleteIssueExclusions(
        issueExclusionIds: string[],
        throwExceptionUponFailures?: boolean,
    ): Promise<string[]> {
        this.clearMultiActionsCache();
        const promises = issueExclusionIds.map((issueExclusionId) => {
            return this.deleteIssueExclusion(issueExclusionId, true);
        });
        if (throwExceptionUponFailures) {
            return Promise.all(promises).then(() => []);
        } else {
            const failedIds: string[] = [];
            return Promise.allSettled(promises).then((results: PromiseSettledResult<any>[]) => {
                results.forEach((result: PromiseSettledResult<any>, index) => {
                    if (result.status === 'rejected') {
                        failedIds.push(issueExclusionIds[index]);
                    }
                });
                return failedIds;
            });
        }
    }
}
