import {
    ICommonIgnoreItem,
    ICommonIgnoreItemModel,
    ICommonIgnoreItemModelUpdate,
    ICommonIgnoreItemUpdate,
} from './CommonIgnoreList.interface';
import {
    addConditionIfExists,
    addDateConditionIfExists,
    addSingleValueConditionIfExists,
    getConditionsMap,
    getConditionValueAsDate,
    getConditionValueAsSingle,
    getConditionValues,
    IConditionsMap,
} from '../../../RiskManagement.conditions';
import { FilterConditionOperator, IFilterCondition } from 'common/erm-components/custom/FilterTree/FilterCondition';
import { IGNORE_LIST_MODEL_FIELDS } from './CommonIgnoreList.consts';

export const getCommonIgnoreItemFromModel = (model: ICommonIgnoreItemModel): ICommonIgnoreItem => {
    const { filter, organizationalUnitIdsFilter } = model;
    const condMap: IConditionsMap = getConditionsMap(filter);
    return {
        id: model._id,
        name: model.name,
        description: model.description,
        expirationDate: getConditionValueAsDate(IGNORE_LIST_MODEL_FIELDS.TIMESTAMP, condMap),
        entityId: getConditionValueAsSingle(IGNORE_LIST_MODEL_FIELDS.SOURCE_EXTERNAL_ID, condMap),
        envIds: getConditionValues(IGNORE_LIST_MODEL_FIELDS.SOURCE_ENVIRONMENT_ID, condMap),
        orgUnitIds: organizationalUnitIdsFilter,
        entityNames: getConditionValues(IGNORE_LIST_MODEL_FIELDS.SOURCE_NAME, condMap),
    };
};

export const getIgnoreItemsFromModels = (models: ICommonIgnoreItemModel[]): ICommonIgnoreItem[] => {
    return models.map((model) => getCommonIgnoreItemFromModel(model));
};

export const getCommonIgnoreItemFilterConditions = (ignoreItemUpdate: ICommonIgnoreItemUpdate): IFilterCondition[] => {
    const conditions: IFilterCondition[] = [];
    addDateConditionIfExists(
        conditions,
        IGNORE_LIST_MODEL_FIELDS.TIMESTAMP,
        ignoreItemUpdate.expirationDate,
        FilterConditionOperator.LT,
    );
    addSingleValueConditionIfExists(conditions, IGNORE_LIST_MODEL_FIELDS.SOURCE_EXTERNAL_ID, ignoreItemUpdate.entityId);
    addConditionIfExists(conditions, IGNORE_LIST_MODEL_FIELDS.SOURCE_ENVIRONMENT_ID, ignoreItemUpdate.envIds);
    addConditionIfExists(
        conditions,
        IGNORE_LIST_MODEL_FIELDS.SOURCE_NAME,
        ignoreItemUpdate.entityNames,
        FilterConditionOperator.LikeAny,
    );
    return conditions;
};

export const getCommonIgnoreItemModelUpdateFromItem = (
    ignoreItemUpdate: ICommonIgnoreItemUpdate,
): ICommonIgnoreItemModelUpdate => {
    return {
        name: ignoreItemUpdate.name,
        description: ignoreItemUpdate.description,
        organizationalUnitIdsFilter: ignoreItemUpdate.orgUnitIds,
    };
};
