import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { LogsFilterTypes } from '../Types/LogsFilterTypes';

export const getAccountActivityBaseFilters = (): IProtectedAssetFilter[] => [
    LogsFilterTypes.ADD_FILTER,
    LogsFilterTypes.DATE_PICKER,
    LogsFilterTypes.RECENTLY_USED_FILTERS,
    LogsFilterTypes.SAVED_FILTERS,
    LogsFilterTypes.CLEAR_ALL,
];
