import axios, { AxiosError, AxiosRequestConfig, Method } from 'axios';
import dayjs from 'dayjs';
import { refreshToken } from 'platform/auth/Auth.actions';
import {
    getEventTracking,
    getStoreService,
    getWebAppIframeService,
    IHttpService,
    IHttpServiceConfig,
} from 'common/interface/services';
import ApiCaching from '../../common/utils/apiCaching';
import IframeMessageModel, { IFRAME_MESSAGE_ACTIONS } from 'common/interface/IFrame.message.model';
import { getCsrf, getReturnUrl } from 'common/utils/http';
import { getRouteFromPath, runRequest } from 'platform/services/httpServiceUtils';
import { addGAEvent } from '../3rdParty/googleAnalytics/googleAnalyticsInitialize';
import { Pages } from 'common/enum/Pages';
import { saveReturnUrlToLocalStorage } from 'platform/user/User.actions';

const API_HEADERS = {
    'Content-Type': 'application/json',
    'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
    'Cache-Control': 'no-cache',
    reactHeader: 'x',
    Pragma: 'no-cache',
};

class HttpService implements IHttpService {
    refreshTokenPromise?: Promise<any>;
    defaultErrorHandler: <T>(error: AxiosError<T>, errorCodeIgnoreList?: number[]) => void;
    apiPrefix: string;
    apiCaching: ApiCaching;

    constructor(apiPrefix: string, errorHandler: <T>(error: AxiosError<T>) => void) {
        this.defaultErrorHandler = errorHandler;
        this.apiPrefix = apiPrefix;
        this.apiCaching = new ApiCaching();
    }

    public async checkApiConnectivity(retryCount = 0): Promise<boolean> {
        try {
            const res = await this.request(
                'SystemHealth',
                { method: 'GET' },
                { publicMode: true },
                (error) => error.code,
            );
            if (res === 'ERR_NETWORK') {
                if (retryCount < 5) {
                    return new Promise((resolve) => {
                        setTimeout(
                            () => {
                                getEventTracking().track('API Connectivity Error', { retryCount });
                                console.warn(`Retrying to connect to the API, count: ${retryCount + 1}`);
                                resolve(this.checkApiConnectivity(retryCount + 1));
                            },
                            500 * (retryCount + 1),
                        );
                    });
                }
                getEventTracking().track('Failed to connect to the API');
                console.error('Failed to connect to the API');
                let returnUrl = getReturnUrl();
                if (decodeURIComponent(returnUrl!) === `/${Pages.Login}`) returnUrl = '';
                saveReturnUrlToLocalStorage(returnUrl);
                window.location.href = '/v2/network-error-page/index.html'; // Wont work in local mode
                return false;
            }
            return true;
        } catch (err) {
            return true;
        }
    }

    private async method<T>(
        method: Method,
        path: string | IHttpServiceConfig,
        requestObject?: AxiosRequestConfig,
        serviceConfig?: IHttpServiceConfig,
        customHandleError?: (error: AxiosError<T>) => T,
    ): Promise<T> {
        if (typeof path !== 'string') {
            serviceConfig = path as IHttpServiceConfig;
            path = serviceConfig.path as string;
            requestObject = serviceConfig.requestObject;
            if (path === undefined) {
                throw new Error(
                    `The path is mandatory when making a request with a service config object ${serviceConfig}`,
                );
            }
        }

        return this.request(path, { ...(requestObject ?? {}), method }, serviceConfig, customHandleError);
    }

    public async post<T>(
        path: string | IHttpServiceConfig,
        requestObject?: AxiosRequestConfig,
        serviceConfig?: IHttpServiceConfig,
        customHandleError?: (error: AxiosError<T>) => T,
    ) {
        if (typeof path !== 'string') {
            serviceConfig = path as IHttpServiceConfig;
            path = serviceConfig.path as string;
            requestObject = serviceConfig.requestObject;
            if (path === undefined) {
                throw new Error(
                    `The path is mandatory when making a request with a service config object ${serviceConfig}`,
                );
            }
        }
        return this.request<T>(
            path,
            { ...(requestObject ?? { data: {} }), method: 'POST' },
            serviceConfig,
            customHandleError,
        );
    }

    public async delete<T>(
        path: string | IHttpServiceConfig,
        requestObject?: AxiosRequestConfig,
        serviceConfig?: IHttpServiceConfig,
        customHandleError?: (error: AxiosError<T>) => T,
    ): Promise<T> {
        return this.method<T>('DELETE', path, requestObject, serviceConfig, customHandleError);
    }

    public async put<T>(
        path: string | IHttpServiceConfig,
        requestObject?: AxiosRequestConfig,
        serviceConfig?: IHttpServiceConfig,
        customHandleError?: (error: AxiosError<T>) => T,
    ): Promise<T> {
        return this.method<T>('PUT', path, requestObject, serviceConfig, customHandleError);
    }

    public async get<T>(
        path: string | IHttpServiceConfig,
        requestObject?: AxiosRequestConfig,
        serviceConfig?: IHttpServiceConfig,
        customHandleError?: (error: AxiosError<T>) => T,
    ): Promise<T> {
        return this.method<T>('GET', path, requestObject, serviceConfig, customHandleError);
    }

    public async request<T, P = any>(
        path: string,
        request: AxiosRequestConfig<P> = {},
        serviceConfig: IHttpServiceConfig = {},
        customHandleError?: (error: AxiosError<T>) => T,
    ): Promise<T> {
        const { errorCodeIgnoreList, publicMode, returnAsAxiosResponse, cachingConfig } = serviceConfig;
        const state = getStoreService().state;
        const headers: AxiosRequestConfig['headers'] = this.buildHeaders(path, request);
        if (!publicMode) {
            headers.csrf = getCsrf();
            const tokenValid =
                state.auth.tokenLocalExpirationTime &&
                dayjs(new Date(state.auth.tokenLocalExpirationTime as string))
                    .subtract(90, 'seconds')
                    .isAfter(dayjs());
            if (headers.csrf && !tokenValid) {
                if (this.refreshTokenPromise) {
                    await this.refreshTokenPromise;
                } else {
                    console.log(`== Refreshing token , from request ${path} `);
                    this.refreshTokenPromise = refreshToken();
                    await this.refreshTokenPromise;
                    this.refreshTokenPromise = undefined;
                }
            }
        }

        const route = getRouteFromPath(path, this.apiPrefix, request.baseURL);
        const method = request.method?.toUpperCase() ?? 'GET';
        const promiseCreator = () => {
            return axios(route, { withCredentials: true, headers, ...request });
        };

        try {
            const response = await runRequest<T, P>(
                this.apiCaching,
                route,
                method,
                promiseCreator,
                request.data,
                cachingConfig,
            );
            addGAEvent('API', path);
            return returnAsAxiosResponse ? (response as any) : response.data;
        } catch (error: any) {
            if (customHandleError) {
                return customHandleError(error);
            } else {
                this.defaultErrorHandler(error, errorCodeIgnoreList);
                throw error;
            }
        }
    }

    private buildHeaders(path: string, requestObject: AxiosRequestConfig) {
        const headers = requestObject.headers ?? Object.assign({}, API_HEADERS);
        if (path.startsWith('http')) {
            delete headers.reactHeader;
        }
        return headers;
    }

    public async getIsSessionAlive(): Promise<boolean> {
        try {
            await this.request('settings', {}, {}, (error) => {
                const errorCode = error?.response?.status || -1;
                if (errorCode === 401 || errorCode === 403) {
                    throw new Error('Authentication error while testing if the session is alive');
                }
            });
            return true;
        } catch (err) {
            return false;
        }
    }

    public clearCacheByTag(tag: string, method?: string, skipSendToWebapp?: boolean) {
        this.apiCaching.clearCacheByTag(tag, method);
        if (!skipSendToWebapp) {
            getWebAppIframeService().emitMessage(
                new IframeMessageModel({ action: IFRAME_MESSAGE_ACTIONS.CLEAR_CACHE, data: { tag } }),
            );
        }
    }

    public clearCacheByPath(path: string, method = 'GET', baseUrl?: string) {
        const route = getRouteFromPath(path, this.apiPrefix, baseUrl);
        this.apiCaching.clearCacheByRoute(route, method);
    }

    public clearEntireCache() {
        this.apiCaching.clearEntireCache();
    }

    public getCacheSize(): number {
        return this.apiCaching.getCacheSize();
    }
}

export default HttpService;
