import styled from 'styled-components';

const SubPanelsListTopDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    padding: 10px 0;
`;

const SubPanelTopDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
`;

const HeaderDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    width: 100%;
`;

export const EvidenceStyled = {
    SubPanelsListTopDiv,
    SubPanelTopDiv,
    HeaderDiv,
};
