import { useCallback, useState, useEffect } from 'react';
import { GetSbomFormats } from '../sbom.interface';
import { getNotificationsService } from 'common/interface/services';
import sbomService from '../sbom.service';
import fileDownload from 'js-file-download';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';

interface UseExportSbomParams {
    entityName: string;
    entityId: string;
    entityType: string;
    environmentId: string;
    isScanEngineVersion2: boolean;
}

export const useExportSbom = ({
    entityName,
    entityId,
    entityType,
    environmentId,
    isScanEngineVersion2,
}: UseExportSbomParams) => {
    const { t } = useTranslation(getK8sNamespace('sbom'));
    const [sbomFormats, setSbomFormats] = useState<GetSbomFormats.Response['formats']>([]);
    const [isExporting, setIsExporting] = useState(false);

    const exportSbom = useCallback(
        async (format: string) => {
            setIsExporting(true);
            const downloadProcess = sbomService.downloadSbom({
                environmentId: environmentId,
                entityType: entityType,
                externalId: entityId,
                sbomFormatType: format,
            });
            getNotificationsService().runPromise({
                promise: downloadProcess,
                pending: {
                    title: t('sbomExport.notification.pending.title'),
                    text: t('sbomExport.notification.pending.body'),
                },
                error: {
                    title: t('sbomExport.notification.error.title'),
                },
                success: {
                    title: t('sbomExport.notification.success.title'),
                    text: t('sbomExport.notification.success.body'),
                },
                errorFormatter: (error) => {
                    return `${t('sbomExport.notification.error.body')}${error.message ? `: ${error.message}` : ''}`;
                },
                autoClose: 5000,
            });
            try {
                const response = await downloadProcess;
                const sanitizedEntityName = entityName
                    .replace(/[^a-zA-Z0-9/:]/g, '')
                    .replaceAll('/', '_')
                    .replaceAll(':', '-');
                fileDownload(response, `sbom_${format.toLowerCase()}_${sanitizedEntityName}.zip`);
            } catch (err) {
                // nothing to do here
            } finally {
                setIsExporting(false);
            }
        },
        [environmentId, entityType, entityId, entityName, t],
    );

    useEffect(() => {
        if (!isScanEngineVersion2) {
            return;
        }
        sbomService
            .getSbomFormats()
            .then((response) => {
                setSbomFormats(response.formats);
            })
            .catch(() => {
                console.debug('Failed to get sbom formats');
                setSbomFormats([]);
            });
    }, [exportSbom, isScanEngineVersion2]);

    return {
        isExporting,
        sbomFormats,
        exportSbom,
    };
};
