import { getHttpService, IEmailNotificationPageService } from 'common/interface/services';
import { IEmailNotifications } from 'common/module_interface/settings/emailNotifications/EmailPageInterfaces';

export class EmailNotificationService implements IEmailNotificationPageService {
    async getSettingsList(useCache = true) {
        return await getHttpService().get<any>({
            path: 'settings',
            cachingConfig: { useCache },
        });
    }

    async getSelectedAccountsList(useCache = true) {
        return await getHttpService().get<any>({
            path: 'settings/email-notification-filters/cloudAccounts',
            cachingConfig: { useCache },
        });
    }

    async updateEmailNotifications(emailNotificationsSettings: IEmailNotifications, useCache?: boolean) {
        return await getHttpService().request<any>(
            'settings/email-notifications',
            { method: 'PUT', data: emailNotificationsSettings },
            { cachingConfig: { useCache: !!useCache } },
        );
    }

    async updateSelectedAccountsList(cloudAccounts: { cloudAccounts: string[] }, useCache?: boolean) {
        return await getHttpService().request<any>(
            'settings/email-notification-filters/cloudAccounts',
            { method: 'PUT', data: cloudAccounts },
            { cachingConfig: { useCache: !!useCache } },
        );
    }

    async selectAllCloudAccounts(useCache?: boolean) {
        return await getHttpService().request<any>(
            'settings/email-notification-filters/cloudAccounts',
            { method: 'DELETE' },
            { cachingConfig: { useCache: !!useCache } },
        );
    }
}
