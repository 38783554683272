import React from 'react';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { ALink } from 'common/components/ALink';
import { A } from 'common/components/Anchor/Anchor';
import { getHttpService } from 'common/interface/services';
import {
    ProtectedAssetsResponse,
    SearchRequest,
} from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { ASSETS_SEARCH_URL } from 'common/module_interface/assets/AssetsConsts';
import { Typography } from 'common/design-system/components-v2';
import { AssetTypes } from 'common/assets/assets.const';

const KubernetesGeneralSectionNamespace: React.FC<{ entity: ICloudEntityData }> = ({ entity }) => {
    const [link, setLink] = React.useState<string | undefined>(undefined);

    const namespaceLabel = React.useMemo(() => entity.cloudEntity.namespace || '', [entity]);

    React.useEffect(() => {
        const getLink = async () => {
            const requestObject: SearchRequest = {
                filter: {
                    fields: [
                        { name: 'cloudAccountId', value: entity.cloudAccountId },
                        { name: 'type', value: AssetTypes.KUBERNETES_KUBERNETESNAMESPACE },
                    ],
                    freeTextPhrase: entity.cloudEntity.namespace,
                },
                pageSize: 1,
            };
            const searchResult = await getHttpService().request<ProtectedAssetsResponse>(ASSETS_SEARCH_URL, {
                data: requestObject,
                method: 'POST',
            });
            if (searchResult.totalCount === 1) {
                const { cloudAccountId, type, entityId, platform } = searchResult.assets[0];
                setLink(
                    `/workload/workloads/protected-asset-generic?cloudAccountId=${cloudAccountId}&assetType=${type}&assetId=${entityId}&platform=${platform}`,
                );
            }
        };
        getLink();
    }, [entity]);

    if (!link) return <Typography variant='bodyLg'>{namespaceLabel}</Typography>;

    return (
        <Typography variant='bodyLg'>
            <ALink title={namespaceLabel} as={A} href={link}>
                {namespaceLabel}
            </ALink>
        </Typography>
    );
};

export default KubernetesGeneralSectionNamespace;
