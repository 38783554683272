import React, { useEffect, useState } from 'react';
import { JiraStyled, AdvanceUrlWrapper } from './Jira.styled';
import { Stack, Checkbox, Icon, Tooltip } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { AUTHENTICATION_TYPE, I18nIntegrations } from 'common/module_interface/settings/integrations/consts';
import { IConfigurationContainerProps } from 'common/module_interface/settings/integrations/configurations.interface';
import GenericWebhookComponent from '../GenericWebhook/GenericWebhookComponent';
import { IConfiguration } from 'common/module_interface/settings/integrations/Integrations';

interface IGenericConfigurationProps extends IConfigurationContainerProps {
    configuration?: IGenericConfiguration;
}

export interface IGenericConfiguration extends IConfiguration {
    configurationObj: IJiraConfigurationObj;
}

export interface IJiraConfigurationObj {
    Url: string;
    AuthType: string;
    Username: string;
    Password: string;
    IgnoreCertificate: boolean;
    AdvancedUrl: boolean;
    MethodType?: string;
    availableAuthTypes?: string[];
}

const JiraComponent: React.FC<IGenericConfigurationProps> = ({
    onConfigurationChangeCallBack,
    configuration,
    onConfigurationSaved,
    viewMode,
    testFormatType,
}) => {
    const { t } = useTranslation(I18nIntegrations);

    const [advanceUrl, SetAdvanceUrl] = useState<boolean>(configuration?.configurationObj.AdvancedUrl || false);
    const [childChangesDetected, SetChildChangesDetected] = useState<boolean>(false);

    const childConfigurationObject = {
        AdvancedUrl: advanceUrl,
    };
    if (configuration?.configurationObj) {
        configuration.configurationObj.AuthType = AUTHENTICATION_TYPE.BasicAuth;
        configuration.configurationObj.availableAuthTypes = [AUTHENTICATION_TYPE.BASIC];
    }
    const checkChangesMade = () => {
        const changesDetected = advanceUrl !== configuration?.configurationObj.AdvancedUrl;
        SetChildChangesDetected(changesDetected);
    };

    const handleAdvanceUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        checkChangesMade();
        const textInput = event.target.checked;
        SetAdvanceUrl(textInput);
    };

    const childrenHandleDiscardChanges = () => {
        SetAdvanceUrl(configuration?.configurationObj.AdvancedUrl || false);
    };

    useEffect(() => {
        checkChangesMade();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advanceUrl]);

    const children = (
        <AdvanceUrlWrapper>
            <Stack margin={[0, 2, 0, 0]}>
                <Checkbox
                    checked={advanceUrl}
                    labelProps={{ color: 'strong' }}
                    label={t('CONFIGURATIONS.JIRA.ADVANCE_URL')}
                    onChange={handleAdvanceUrlChange}
                />
            </Stack>
            <Tooltip
                content={
                    <Stack spacing={2}>
                        <div>{t('CONFIGURATIONS.JIRA.ADVANCE_URL_TOOLTIP')}</div>
                    </Stack>
                }
                placement='bottom'
            >
                <Icon color='normal' name='info' size={12} />
            </Tooltip>
        </AdvanceUrlWrapper>
    );

    const propsForGenericWebhookComponent = {
        onConfigurationChangeCallBack,
        configuration,
        onConfigurationSaved,
        viewMode,
        children,
        childConfigurationObject,
        childrenHandleDiscardChanges,
        childChangesDetected,
        testFormatType,
    };

    return (
        <JiraStyled data-aid={'Jira-component'}>
            <GenericWebhookComponent {...propsForGenericWebhookComponent} />
        </JiraStyled>
    );
};

export default JiraComponent;
