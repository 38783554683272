// ~~~ GetAllRegistryAccounts ~~~
import { AxiosResponse } from 'axios';

// Vulnerability Scan
export namespace GetVulnerabilityScan {
    export type Function = (
        request: GetVulnerabilityScan.Request,
    ) => Promise<AxiosResponse<GetVulnerabilityScan.Response>>;
    export type Response = {
        InsecureContent: Array<InsecureContent>;
        Malware: Array<Malware>;
        Package: Array<Package>;
        SBOMPackage: [];
        ScanSummary: ScanSummary;
        scanInfo: ScanInfo;
        scannedAsset: ScannedAsset;
        LayersDetails: Layers;
    };
    export type Request = {
        entityId: string;
        entityType: string;
        environmentId: string;
        remediationOnly: boolean;
    };
}

export interface InsecureContent {
    cgType: string;
    category: string;
    classification: string;
    customUniqueKeyHash: string;
    description: string;
    files: Array<{ contents: Array<any>; 'file-path': string; md5: string; layerId: string | null }>;
    id: string;
    name: string;
    remediation: string;
    severity: string;
    type: string;
}

export interface ScanSummary {
    category: string;
    cgType: string;
    description: string | null;
    id: string;
    remediation: string;
    'remediation-summary': Array<RemediationSummary>;
}

export type FlatPackageAndCve = Cve & {
    [K in keyof Package as `package${Capitalize<K>}`]: Package[K];
};

interface Remediation {
    finding: string;
    severity: string;
    action: string;
    lines: number[];
}

export interface Category {
    category: string;
    remediations: Remediation[];
}

export interface RemediationSummary {
    'file-path': string;
    categories: Category[];
}

interface Layer {
    id: string;
    created: string;
    created_by?: string;
    empty_layer?: boolean;
    author?: string;
    command: string;
}

export interface Layers {
    [key: string]: Layer;
}

export interface Package {
    category: string;
    cgType: string;
    layerId: string;
    customUniqueKeyHash: string;
    cves: Array<Cve>;
    description: string;
    id: string;
    'is-os-package': boolean;
    isFixable: boolean;
    name: string;
    packageManager: {
        name: string;
        path: string;
    };
    knownExploit?: boolean | null;
    knownExploitDateAdded?: Date | null;
    knownExploitDueDate?: Date | null;
    isCveIgnored?: boolean;
    parents: Array<any>;
    relatedCves: Array<Cve>;
    remediation: string;
    severity: string;
    type: string;
    version: string;
    inUse: boolean;
    baseImages: Array<{ name: string; externalId: string; groups: Array<string> }>;
}

export interface CveExclusion {
    id: string;
    comment: string;
    includeInAssessment: boolean;
    author: string;
    creationDate: Date;
}

export interface Cve {
    'cvss-info': CvssInfo | null;
    description: string;
    epssScore?: number | null;
    knownExploit?: boolean | null;
    knownExploitDateAdded?: Date | null;
    knownExploitDueDate?: Date | null;
    id: string;
    isFixedByPackageRemediation: boolean;
    lastModified: null | any;
    relatedIds: string[];
    remediation: string;
    severity: string;
    source: string;
    sourceUrl: string;
    urls: string[];
    isExcluded?: boolean;
    exclusion?: CveExclusion;
}

interface CvssInfo {
    'base-score': number;
    'exploitability-score': number;
    'impact-score': number;
    metrics: null;
    source: string;
    'vector-string': string;
    version: string;
}

export namespace GetVulnerabilityScanMetadata {
    export type Function = (
        request: GetVulnerabilityScanMetadata.Request,
    ) => Promise<AxiosResponse<GetVulnerabilityScanMetadata.Response>>;
    export type Response = {
        scanInfo: ScanInfo;
        layersDetails?: Layers;
        scannedAsset: ScannedAsset;
        vulnerabilityStats: {};
    };
    export type Request = {
        entityId: string;
        entityType: string;
        environmentId: string;
    };
}

export interface UseVulnerabilityScanState {
    isLoading: boolean;
    error: boolean;
    data?: GetVulnerabilityScan.Response;
}

// Vulnerability Scan Metadata
export interface UseVulnerabilityScanMetadataState {
    isLoading: boolean;
    data?: GetVulnerabilityScanMetadata.Response;
    error: boolean;
}

type ScanInfo = {
    scanProducer: string;
    scanDate: Date;
};

type ScannedAsset = {
    additionalFields: { [key: string]: string; value: string };
    entityType: string;
    environmentId: string;
    operatingSystem: string;
    externalId: string;
    platform: string;
};

export interface Malware {
    id?: string;
    severity: string;
    type: string;
    category: string;
    classification: string;
    name?: string;
    description: string;
    remediation: string;
    files?: File[];
    cgType?: string;
    customUniqueKeyHash?: string;
    baseImages?: Array<{ externalId: string; groups: Array<string>; name: string }>;
}

interface File {
    'file-path': string;
    layerId: string | null;
    contents: Content[];
}

interface Content {
    payload: string;
    'payload-sha256': string;
    lines: number[];
}

// flatten objects
export interface Remediationflatten {
    category: string;
    'file-path': string;
    finding: string;
    severity: string;
    action: string;
    lines: number[];
}

export interface Malwareflatten {
    layerId: string | null;
    finding: string;
    type: string;
    classification: string;
    severity: string;
    description: string;
    remediation: string;
    filePath: string;
    lines: string;
    category: string;
    baseImages?: Array<{ externalId: string; groups: Array<string>; name: string }>;
}

// ------ VulnerabilityExclusions ------

export enum VulnerabilityExclusionType {
    CVE = 'CVE',
    THREAT = 'Threat',
    SECRET = 'Secret',
}

export interface VulnerabilityExclusion {
    id: string;
    type: VulnerabilityExclusionType;
    includeInAssessment: boolean;
    comment: string;
    author: string;
    creationDate: Date;
    errorMessage: string;
    name: string;
    filter: {
        cveId: string;
        packageName?: string;
        packageVersion?: string;
        filePath?: string;
        maliciousCategory?: string;
        entityNames?: string[];
        entityId?: string;
        environmentsIds?: string[];
        organizationalUnitsIds?: string[];
        startDate?: Date;
        endDate?: Date;
    };
}

export namespace GetVulnerabilityExclusions {
    export type Function = () => Promise<AxiosResponse<GetVulnerabilityExclusions.Response>>;
    export type Response = VulnerabilityExclusion[];
}

export namespace CreateVulnerabilityExclusions {
    export type Function = () => Promise<AxiosResponse<CreateVulnerabilityExclusions.Response>>;
    export type Request = Array<
        Pick<VulnerabilityExclusion, 'type' | 'comment' | 'filter' | 'includeInAssessment' | 'name'>
    >;
    export interface Response extends Request {
        errorMessage: string;
    }
}

export namespace EditVulnerabilityExclusions {
    export type Function = () => Promise<AxiosResponse<EditVulnerabilityExclusions.Response>>;
    export type Request = Array<
        Pick<VulnerabilityExclusion, 'id' | 'comment' | 'includeInAssessment' | 'filter' | 'type' | 'name'>
    >;
    export type Response = VulnerabilityExclusion[];
}

export namespace DeleteVulnerabilityExclusions {
    export type Function = () => Promise<AxiosResponse<DeleteVulnerabilityExclusions.Response>>;
    export type Request = Array<string>;
    export type Response = Array<Pick<VulnerabilityExclusion, 'id' | 'errorMessage'>>;
}
