import { ILogTablePlatformTab } from 'common/module_interface/intelligence/Logs/Logs.interface';
import { getVendorDisplayName, getVendorIcon, Vendors } from 'common/consts/vendors';
import { LogsTableRegistry } from 'common/module_interface/intelligence/Logs/LogsTableRegistry';
import { LogsColumnTypes as ColumnTypes } from '../Types/LogsColumnTypes';
import { getAccountActivityBaseFilters } from './AccountActivityLogsPage.filters';
import { getAccountActivityBaseColumns } from './AccountActivityLogsPage.columns';
import { LogsFilterTypes } from '../Types/LogsFilterTypes';
import { GslSource } from 'common/module_interface/intelligence/Gsl/GslService.const';

export function initializeTabs() {
    const baseFilters = getAccountActivityBaseFilters();
    const baseColumns = getAccountActivityBaseColumns();
    const tabs: ILogTablePlatformTab[] = [
        {
            id: `${GslSource.cloudtrail}_tab`,
            platform: Vendors.AWS,
            label: getVendorDisplayName(Vendors.AWS),
            icon: getVendorIcon(Vendors.AWS),
            columns: [
                ...baseColumns,
                ColumnTypes.IDENTITY_USERAGENT,
                ColumnTypes.EVENT_STATUS,
                ColumnTypes.SOURCE_IP,
                ColumnTypes.SOURCE_COUNTRY,
            ],
            filters: [...baseFilters, LogsFilterTypes.STATUS, LogsFilterTypes.AWS_EVENT],
        },
        {
            id: `${GslSource.azurect}_tab`,
            platform: Vendors.AZURE,
            label: getVendorDisplayName(Vendors.AZURE),
            icon: getVendorIcon(Vendors.AZURE),
            columns: [
                ...baseColumns,
                ColumnTypes.IDENTITY_USERAGENT,
                ColumnTypes.EVENT_STATUS,
                ColumnTypes.SOURCE_IP,
                ColumnTypes.SOURCE_COUNTRY,
            ],
            filters: [...baseFilters, LogsFilterTypes.STATUS],
        },
        {
            id: `${GslSource.gcpct}_tab`,
            platform: Vendors.GOOGLE,
            label: getVendorDisplayName(Vendors.GOOGLE),
            icon: getVendorIcon(Vendors.GOOGLE),
            columns: [
                ...baseColumns,
                ColumnTypes.IDENTITY_USERAGENT,
                ColumnTypes.EVENT_STATUS,
                ColumnTypes.SOURCE_IP,
                ColumnTypes.SOURCE_COUNTRY,
            ],
            filters: [...baseFilters, LogsFilterTypes.STATUS],
        },
        {
            id: `${GslSource.kubernetescloudtrail}_tab`,
            platform: Vendors.KUBERNETES,
            label: getVendorDisplayName(Vendors.KUBERNETES),
            icon: getVendorIcon(Vendors.KUBERNETES),
            columns: [...baseColumns, ColumnTypes.TARGET_NAMESPACE],
            filters: [...baseFilters],
        },
    ];
    LogsTableRegistry.addTabs(tabs);
}
