import AWS from '../../../../../assets/integrations/Icons/Logo_aws.svg';
import AZURE from '../../../../../assets/integrations/Icons/Logo_azure.svg';
import GCP from '../../../../../assets/integrations/Icons/Logo_gcp.svg';
import Alibaba from '../../../../../assets/integrations/Icons/Logo_alibaba.svg';
import OCI from '../../../../../assets/integrations/Icons/Logo_oci.svg';
import KUBERNETES from '../../../../../assets/integrations/Icons/Logo_kubernetes.svg';
import CONTAINER_REGISTRY from '../../../../../assets/integrations/Icons/Logo_container_registry.svg';
import AMAZON_MACIE from '../../../../../assets/integrations/Icons/Amazon_macie.svg';
import AMAZON_INSPECTOR from '../../../../../assets/integrations/Icons/Amazon_inspector.svg';
import AMAZON_GUARD_DUTY from '../../../../../assets/integrations/Icons/Amazon_guard_duty.svg';
import GCP_EVENT_ARC from '../../../../../assets/integrations/Icons/GCP_eventarc.svg';
import MICROSOFT_PURVIEW from 'assets/integrations/Icons/Microsoft_purview.svg';
import ONELOGIN from '../../../../../assets/integrations/Icons/OneLogin.svg';
import PING_IDENTITY from '../../../../../assets/integrations/Icons/Ping_identity.svg';
import SNS from '../../../../../assets/integrations/Icons/SNS.svg';
import WEBHOOK from '../../../../../assets/integrations/Icons/Generic_webhook.svg';
import TENABLE from '../../../../../assets/integrations/Icons/Tenable.svg';
import OKTA from '../../../../../assets/integrations/Icons/Okta.svg';
import GENERIC_SAML_SERVER from '../../../../../assets/integrations/Icons/Generic_SAML_server.svg';
import GOOGLE_WORKSPACE from '../../../../../assets/integrations/Icons/Google_workspace.svg';
import DUO from 'assets/integrations/Icons/Duo.svg';
import MICROSOFT_ENTRA_ID from '../../../../../assets/integrations/Icons/Microsoft_entra_ID.svg';
import ADFS from '../../../../../assets/integrations/Icons/ADFs.svg';
import CENTRIFY from '../../../../../assets/integrations/Icons/Centrify.svg';
import JUMP_CLOUD from '../../../../../assets/integrations/Icons/Jumpcloud.svg';
import TORQ from '../../../../../assets/integrations/Icons/Torq.svg';
import SEAMPLICITY from '../../../../../assets/integrations/Icons/Seemplicity.svg';
import DEV_OCEAN from '../../../../../assets/integrations/Icons/DevOcean.svg';
import TAMNOON from '../../../../../assets/integrations/Icons/Tamnoon.svg';
import AXONIUS from '../../../../../assets/integrations/Icons/Axonius.svg';
import TEAMS from '../../../../../assets/integrations/Icons/Teams.svg';
import SLACK from '../../../../../assets/integrations/Icons/Slack.svg';
import EMAIL from '../../../../../assets/integrations/Icons/Email.svg';
import SPLUNK from '../../../../../assets/integrations/Icons/Splunk.svg';
import SERVICE_NOW from '../../../../../assets/integrations/Icons/Service_now.svg';
import QRADAR from '../../../../../assets/integrations/Icons/Qradar.svg';
import JIRA from '../../../../../assets/integrations/Icons/Jira.svg';
import SUMO_LOGIC from '../../../../../assets/integrations/Icons/Sumo_logic.svg';
import PAGER_DUTY from '../../../../../assets/integrations/Icons/Pager_duty.svg';
import SHIFTLEFT from '../../../../../assets/integrations/Icons/shiftleft.svg';
import GCP_SECURITY_COMMAND_CENTER from '../../../../../assets/integrations/Icons/GCP_security_command_center.svg';
import AZURE_DEFENDER from '../../../../../assets/integrations/Icons/Azure_defender_for_cloud.svg';
import AWS_SECURITY_HUB from '../../../../../assets/integrations/Icons/AWS_security_hub.svg';
import MICROSOFT_SENTINEL from '../../../../../assets/integrations/Icons/Microsoft_Sentinel.svg';

export const integrationLogos = {
    AWS,
    AZURE,
    GCP,
    Alibaba,
    OCI,
    KUBERNETES,
    CONTAINER_REGISTRY,
    AMAZON_MACIE,
    AMAZON_INSPECTOR,
    AMAZON_GUARD_DUTY,
    GCP_EVENT_ARC,
    MICROSOFT_PURVIEW,
    ONELOGIN,
    PING_IDENTITY,
    SNS,
    WEBHOOK,
    TENABLE,
    OKTA,
    GENERIC_SAML_SERVER,
    GOOGLE_WORKSPACE,
    DUO,
    MICROSOFT_ENTRA_ID,
    ADFS,
    CENTRIFY,
    JUMP_CLOUD,
    TORQ,
    SEAMPLICITY,
    DEV_OCEAN,
    TAMNOON,
    AXONIUS,
    TEAMS,
    SLACK,
    EMAIL,
    SPLUNK,
    SERVICE_NOW,
    QRADAR,
    JIRA,
    SUMO_LOGIC,
    PAGER_DUTY,
    SHIFTLEFT,
    GCP_SECURITY_COMMAND_CENTER,
    AZURE_DEFENDER,
    AWS_SECURITY_HUB,
    MICROSOFT_SENTINEL,
};
