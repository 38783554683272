import { ICellRendererParams } from 'ag-grid-community';
import Licenses from 'modules/workloads/components/Licenses/Licenses';
import { transformToChips } from 'modules/workloads/components/Licenses/Licenses.utils';

const LicensesChipsCellRenderer = (data: ICellRendererParams) => {
    const { licenses } = data.data;
    if (!licenses) return null;
    return <Licenses licenses={licenses} transformer={transformToChips} />;
};

export default LicensesChipsCellRenderer;
