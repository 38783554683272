import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import SimpleText from 'common/components/ProtectedAssets/Renderers/SimpleText';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../initialize.i18n';
import { FindingsColumnTypes } from '../Types/FindingsColumnTypes';

const AssigneeCellRenderer: React.FC<ICellRendererParams<IKustoEvent>> = (params) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    if (
        !params.value &&
        params.node.group &&
        params.node.rowGroupColumn?.getColId() !== FindingsColumnTypes.ASSIGNEE.id
    ) {
        return null;
    }

    const assignee: string = params.value || t('FINDINGS_TABLE.CELL_RENDERERS.ASSIGNEE.UNASSIGNED');
    return <SimpleText value={assignee} />;
};

export default AssigneeCellRenderer;
