import { IQueryNodeData, IToxicPivotItem, IToxicSubGraph } from '../ToxicGraph.interface';
import { sendHttpRequest } from 'common/erm-components/utils/ermComponents.http';
import { subGraphModelToUi } from '../ToxicGraph.utils';
import {
    IToxicGraphQueryNodeModel,
    IToxicGraphQueryWithEnvModel,
    IToxicGraphRunQueryResponseModel,
    IToxicGraphSchemaModel,
    IToxicSubGraphModel,
} from './ToxicGraphModel.interface';
import { queryModelToUiNode, srlModelItemToUi } from './ToxicGraphModel.convertors';
import { ICustomTreeNode } from 'common/erm-components/custom/CustomTree/CustomTree.interface';
import { getService } from 'common/extensibility/AddinContainer';

const GET_TOXIC_GRAPH_SCHEMA_URL = 'security-graph/entity-schema';
const RUN_TOXIC_QUERY_URL = 'security-graph/query/sync';
const GET_TOXIC_QUERY_BY_RULE_URL = 'security-graph/rules/query';
const GET_TOXIC_PIVOT_SUB_GRAPH_URL = 'security-graph/query/evidence-path/sync';
export const TOXIC_GRAPH_SERVICE_ID = 'TOXIC_GRAPH_SERVICE_ID';

const getPivotSubGraphUrl = (pivot: IToxicPivotItem): string => {
    const srl: string = encodeURIComponent(pivot.srl);
    return `${GET_TOXIC_PIVOT_SUB_GRAPH_URL}/${pivot.requestId}/${srl}`;
};

export const getToxicGraphSchema = async (): Promise<IToxicGraphSchemaModel> => {
    return await sendHttpRequest<IToxicGraphSchemaModel>(GET_TOXIC_GRAPH_SCHEMA_URL, {
        method: 'GET',
    });
};

export interface IToxicGraphService {
    runToxicQuery: (queryRequest: IToxicGraphQueryWithEnvModel) => Promise<IToxicPivotItem[]>;
    getToxicSubGraph(pivot: IToxicPivotItem): Promise<IToxicSubGraph>;
    getToxicRootNodeByRuleId(ruleId: string): Promise<ICustomTreeNode<IQueryNodeData> | undefined>;
}

export class ToxicGraphService implements IToxicGraphService {
    async runToxicQuery(queryRequest: IToxicGraphQueryWithEnvModel): Promise<IToxicPivotItem[]> {
        const response: IToxicGraphRunQueryResponseModel = await sendHttpRequest<IToxicGraphRunQueryResponseModel>(
            RUN_TOXIC_QUERY_URL,
            {
                data: queryRequest,
                method: 'POST',
            },
        );

        if (response.error) {
            throw new Error(response.error || 'No results found');
        }

        if (!response.srlResults || response.srlResults.length === 0) {
            return [];
        }

        const pivots: IToxicPivotItem[] = response.srlResults.map((modelItem) =>
            srlModelItemToUi(modelItem, response.queryRunRequestId),
        );
        return pivots.sort((a, b) => a.displayName.localeCompare(b.displayName));
    }

    async getToxicSubGraph(pivot: IToxicPivotItem): Promise<IToxicSubGraph> {
        const subGraphModel: IToxicSubGraphModel = await sendHttpRequest<IToxicSubGraphModel>(
            getPivotSubGraphUrl(pivot),
            {
                method: 'GET',
            },
        );

        return subGraphModelToUi(pivot, subGraphModel);
    }

    async getToxicRootNodeByRuleId(ruleId: string): Promise<ICustomTreeNode<IQueryNodeData> | undefined> {
        const url = `${GET_TOXIC_QUERY_BY_RULE_URL}/${ruleId}`;
        const queryModel: IToxicGraphQueryNodeModel = await sendHttpRequest<IToxicGraphQueryNodeModel>(url, {
            method: 'GET',
        });

        const newRoot: ICustomTreeNode<IQueryNodeData> = queryModelToUiNode(queryModel);
        return Promise.resolve(newRoot);
    }
}

export function getToxicGraphService(): IToxicGraphService {
    return getService<IToxicGraphService>(TOXIC_GRAPH_SERVICE_ID);
}
