import { getTrimmedSingleValue } from 'common/erm-components/custom/CustomForm/CustomForm.values';
import { cdrTrans } from '../../../../Intelligence.utils';
import { CommonRegExp } from 'common/erm-components/utils/ermComponents.consts';
import { IItem, IItemsMap } from 'common/erm-components/custom/CustomForm/CustomForm.interface';

const MAX_PORT = 65535;
export const checkPortRange = async (item: IItem, itemsMap: IItemsMap): Promise<string | undefined> => {
    const value = getTrimmedSingleValue(itemsMap[item.name]);
    if (!value) {
        return;
    }

    const parts: string[] | null = value.match('^([0-9]+)[ ]*-[ ]*([0-9]+)$');
    if (!parts || parts.length < 3) {
        return cdrTrans('CDR_EXCLUSIONS.VALIDATION.INVALID_PORT_RANGE_FORMAT');
    }

    const from = Number(parts[1]);
    const to = Number(parts[2]);
    if (isNaN(from) || isNaN(to)) {
        return cdrTrans('CDR_EXCLUSIONS.VALIDATION.INVALID_PORT_RANGE_FORMAT');
    }

    if (from < 1 || from > MAX_PORT || to < 1 || to > MAX_PORT || from > to) {
        return cdrTrans('CDR_EXCLUSIONS.VALIDATION.INVALID_PORT_RANGE_VALUES');
    }
};

export const checkIpCidr = async (item: IItem, itemsMap: IItemsMap): Promise<string | undefined> => {
    const value: string | undefined = getTrimmedSingleValue(itemsMap[item.name]);
    if (!value) {
        return;
    }

    if (!CommonRegExp.IP.test(value) && !CommonRegExp.CIDR.test(value) && !CommonRegExp.CIDRV6.test(value)) {
        return cdrTrans('CDR_EXCLUSIONS.VALIDATION.INVALID_IP_CIDR_FORMAT');
    }
};
