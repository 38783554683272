import appReducer from './App.reducer';
import { IApplicationReducer } from 'common/interface/redux';

import initializeCommon from './common';
import initializeK8s from './modules/workloads';
import initializeRiskManagement from './modules/riskManagement';
import initializePostureManagement from './modules/postureManagement';
import initializeIdentity from './modules/Identity';
import initializeNetworkSecurity from './modules/NetworkSecurity';
import initializeIntelligence from './modules/Intelligence';
import initializeSettings, { initializeI18nSettings } from './modules/settings';
import initializeAssets from './modules/assets';
import initializeSpectral from './modules/spectral';
import initializeApp from './platform';
import initializeInsight from './modules/Insight';
import initializePolicy from './modules/policy';
import initializeOverview from './modules/overview';
import initializeServices from './common/services/initialize';
import initializeShiftLeft from './modules/shiftLeft/initialize';
import initializeEvents from './modules/events/initialize';
import initializeReporting from './modules/reporting/initialize';
import initializeRemediationModals from './modules/remediation/initialize';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { isNotChina } from 'common/utils/dataCenter';
import { initializeComponentsI18n } from 'common/design-system/initialize.i18n';
import { initializeI18nIntelligence } from './modules/Intelligence/initialize.i18n';
import { initializeI18nEvents } from './modules/events/initialize.i18n';
import { initializeI18nOverview } from './modules/overview/initialize.i18n';
import { initializeI18nPosture } from './modules/postureManagement/initialize.i18n';
import { initializeI18nReports } from './modules/reporting/initialize.i18n';
import { initializeI18nRiskManagement } from './modules/riskManagement/initialize.i18n';
import { initializeI18nIntegrations } from './modules/settings/Integrations/initialize.i18n';
import { initializeK8sI18n } from './modules/workloads/initialize.i18n';
import { initializeI18nNotifications } from './modules/settings/NotificationsPage/initialize';
import { initializeEnvironmentsTableI18n } from 'modules/assets/Environments/EnvironmentsTable/initialize.i18n';
import { initializeI18Identity } from './modules/Identity/initialize.i18n';
import { initializeI18nPolicy } from './modules/policy/initialize.i18n';
import { initializeAwsEnvI18n } from 'modules/assets/Environments/AwsEnvironment/initialize.i18n';
import { initializeAzureEnvI18n } from 'modules/assets/Environments/AzureEnvironment/initialize.i18n';
import { initializeGcpEnvI18n } from 'modules/assets/Environments/GcpEnvironment/initialize.i18n';
import { initializeAlibabaEnvI18n } from 'modules/assets/Environments/AlibabaEnvironment/initialize.i18n';
import { initializeInsightI18n } from './modules/Insight/initialize.i18n';
import { initializeEventsTableI18n } from 'modules/events/EventsTable/initialize.i18n';
import { initializeOciEnvI18n } from 'modules/assets/Environments/OciEnvironment/initialize.i18n';
import { initializeOusPageI18n } from 'modules/assets/Ous/initialize.i18n';
import { initializeI18nExclusion } from './modules/exclusion/initialize.i18n';
import initializeExclusion from './modules/exclusion/initialize';
import { initializeI18nRoles } from 'modules/settings/Roles/RolesPage/initRolesPage';
import { initializeI18nUsers } from 'modules/settings/Users/UsersPage/initUsersPage';
import { initializeI18nServiceAccounts } from 'modules/settings/ServiceAccounts/ServiceAccountsPage/initServiceAccountsPage';
import { initializeI18nRulesets } from './modules/postureManagement/Rulesets/InitRulsetsPage';
import { APP_ROOT_REGISTRY, getAppRootRegistry } from 'common/interface/services';
import { AppRootRegistry } from 'platform/services/AppRootRegistry/AppRootRegistry';
import { ActiveFeaturesRegistry } from 'common/registries/ActiveFeatureManager/ActiveFeaturesRegistry';
import { IActiveFeatureInfo } from 'common/registries/ActiveFeatureManager/ActiveFeatures';

let isInitialized = false;

function initializeReducers() {
    const reducers: IApplicationReducer[] = [{ name: 'app', reducer: appReducer, isBlackList: true }];
    getAppRootRegistry().addReducers(reducers, 'name');
}

function initializeModules() {
    initializeOverview();
    initializeRiskManagement();
    initializeSettings();
    initializePolicy();
    initializeExclusion();
    initializePostureManagement();
    initializeIdentity();
    initializeReporting();
    initializeAssets();
    initializeEvents();
    initializeApp();
    initializeIntelligence();
    initializeK8s();
    initializeNetworkSecurity();
    initializeInsight();
    initializeRemediationModals();
    if (isNotChina()) {
        initializeSpectral();
        initializeShiftLeft();
    }
}

function initializePlatformActiveFeatures() {
    ActiveFeaturesRegistry.addActiveFeatureTopic({
        key: 'platform',
        title: 'PLATFORM',
        icon: 'settings',
    });
    const japaneseActiveFeatureInfo: IActiveFeatureInfo[] = [
        {
            key: 'japanese',
            description: 'Bug fix for Japanese - enabling the user to view the app in Japanese language in portal',
            topic: 'platform',
        },
    ];
    ActiveFeaturesRegistry.addActiveFeatureInfos(japaneseActiveFeatureInfo);
}

export function initialize() {
    if (isInitialized) {
        return;
    }
    globalAddinContainer.addService(APP_ROOT_REGISTRY, new AppRootRegistry());
    initializeReducers();
    initializeCommon();
    initializeModules();
    initializeServices();
    isInitialized = true;
    initializePlatformActiveFeatures();
}

export async function initializeI18n() {
    await Promise.all([
        initializeI18nExclusion(),
        initializeI18nEvents(),
        initializeI18nIntelligence(),
        initializeI18nOverview(),
        initializeI18nPosture(),
        initializeI18nReports(),
        initializeComponentsI18n(),
        initializeI18nRiskManagement(),
        initializeI18nIntegrations(),
        initializeI18nNotifications(),
        initializeI18nSettings(),
        initializeI18nRoles(),
        initializeI18nRulesets(),
        initializeI18nUsers(),
        initializeI18nServiceAccounts(),
        initializeK8sI18n(),
        initializeEnvironmentsTableI18n(),
        initializeI18Identity(),
        initializeI18nPolicy(),
        initializeAwsEnvI18n(),
        initializeAzureEnvI18n(),
        initializeGcpEnvI18n(),
        initializeAlibabaEnvI18n(),
        initializeInsightI18n(),
        initializeEventsTableI18n(),
        initializeOciEnvI18n(),
        initializeOusPageI18n(),
    ]);
}
