import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { ERM_PROTECTED_ASSET_FILTER_IDS } from 'common/module_interface/RiskManagement/protectedAssets/filters.consts';

export const assetsTableBaseFilters: Array<IProtectedAssetFilter> = [
    { id: ERM_PROTECTED_ASSET_FILTER_IDS.riskScore },
    { id: ERM_PROTECTED_ASSET_FILTER_IDS.cves },
    { id: 'type' },
    { id: 'tag' },
];

export const imagesTableBaseFilters: Array<IProtectedAssetFilter> = [
    { id: 'tag' },
    { id: 'VendorImage' },
    { id: 'BaseImage' },
    { id: 'ImageId' },
    { id: 'scanStatus' },
];

export const mainPageTableBaseFilters: Array<IProtectedAssetFilter> = [
    { id: 'organizational unit' },
    { id: 'platform' },
    { id: 'environment' },
    { id: 'region' },
    { id: 'network' },
];
