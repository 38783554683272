import {
    renderAddFilter,
    renderClearAll,
    renderMultiSelectFilter,
    renderSavedFilters,
    renderRecentlyUsedFilters,
    renderDefaultFreeTextFilter,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { IDisplayMappingObject } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { Aggregations } from 'modules/workloads/services/vulnerability-search/cve-gsl/cve-gsl.interface';
import { ICustomzationResponse } from 'common/interface/services';
import { capitalizeWords } from 'common/utils/helpFunctions';

export interface IFilterDefs {
    aggregations: Aggregations;
    filtersInitialData: { savedFilters: ICustomzationResponse<any>; recentlyUsed: ICustomzationResponse<any> };
    savedFiltersComponentName: string;
    recentlyUsedComponentName: string;
}

const addFilterDef = () => ({
    filterProps: { key: 'add-filter' },
    renderFunction: renderAddFilter,
});

const statusFilterDef = (aggregations: Aggregations) => {
    const displayMapping: IDisplayMappingObject = {};
    aggregations['status']?.forEach((status: { value: string; count: number }) => {
        displayMapping[status.value] = {
            displayText: capitalizeWords(status.value) || '',
        };
    });

    return {
        filterProps: {
            initialData: aggregations['status'],
            key: 'status',
            title: 'Status',
            displayMapping,
        },
        renderFunction: renderMultiSelectFilter,
    };
};

// const severityFilterDef = (aggregations: Aggregations) => { // re-enable this when the severity field is available in the API
//     const displayMapping: IDisplayMappingObject = {};
//     aggregations['severity']?.forEach((severtiy: { value: string, count: number }) => {
//         displayMapping[severtiy.value] = {
//             displayText: capitalizeWords(severtiy.value) || '',
//         };
//     });

//     return {
//         filterProps: {
//             initialData: aggregations['severity'],
//             key: 'severity',
//             title: 'Severity',
//             displayMapping,
//         },
//         renderFunction: renderMultiSelectFilter,
//     };
// };

const savedFiltersFilterDef = ({ filtersInitialData, savedFiltersComponentName }: Partial<IFilterDefs>) => ({
    filterProps: {
        savedFilters: filtersInitialData?.savedFilters || [],
        savedFiltersComponentName,
        selectedFilterID: '',
        key: FILTERS_KEYS.SAVED_FILTERS,
    },
    renderFunction: renderSavedFilters,
});

const recentlyUsedFiltersFilterDef = ({ filtersInitialData, recentlyUsedComponentName }: Partial<IFilterDefs>) => ({
    filterProps: {
        key: FILTERS_KEYS.RECENTLY_USED_FILTERS,
        recentlyUsedList: filtersInitialData?.recentlyUsed,
        maxLength: 4,
        componentName: recentlyUsedComponentName,
        title: 'Recently Used Filters',
    },
    renderFunction: renderRecentlyUsedFilters,
});

const clearFiltersButtonDef = () => ({
    filterProps: {
        key: FILTERS_KEYS.CLEAR_BUTTON,
    },
    renderFunction: renderClearAll,
});

const freeTextFilterDef = () => ({
    filterProps: {
        key: FILTERS_KEYS.FREE_TEXT,
        title: 'Free Text',
    },
    keyInObjectForAPI: 'freeTextPhrase',
    renderFunction: renderDefaultFreeTextFilter,
});

export const filterDefs = ({
    aggregations,
    filtersInitialData,
    recentlyUsedComponentName,
    savedFiltersComponentName,
}: IFilterDefs): Array<() => IFilterProps> => {
    return [
        addFilterDef,
        () => statusFilterDef(aggregations),
        () => savedFiltersFilterDef({ filtersInitialData, savedFiltersComponentName }),
        () => recentlyUsedFiltersFilterDef({ filtersInitialData, recentlyUsedComponentName }),
        // () => severityFilterDef(aggregations),
        () => freeTextFilterDef(),
        clearFiltersButtonDef,
    ];
};
