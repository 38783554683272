import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Input, Modal } from 'common/design-system/components-v2';
import { EMPTY_STRING } from 'common/consts/GeneralConsts';
import { EndPointType } from 'common/module_interface/intelligence/CloudBots/CloudBots.const';
import {
    ICloudBot,
    ICloudBotParameter,
    ICloudBotsData,
    IFieldError,
    IFindingKey,
    IRemediationRequest,
    IRemediationResponse,
} from 'common/module_interface/intelligence/CloudBots/CloudBots.interface';
import {
    getEndPointType,
    getFixItCustomizationKey,
} from 'common/module_interface/intelligence/CloudBots/CloudBots.utils';
import { getCloudBotsService } from 'common/module_interface/intelligence/CloudBots/CloudBots.service';
import { ModalType } from 'modules/Intelligence/Findings/Findings.const';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { getLoggerService, getNotificationsService } from 'common/interface/services';
import { FixItModalStyles as Styles } from './FixItModal.styled';
import CloudBotsActions from '../../../CloudBots/Reducers/cloudBots.actions';
import { isEmpty, isNull } from 'lodash';
import { FormFields, NO_BOT_TRIGGER_ERR } from './FixItModal.const';
import { IFixItModalProps } from './FixItModal.types';
import { useTranslation } from 'react-i18next';
import { CloudBotsInput } from '../../../CloudBots/Components/CloudBotsInput';
import { ICloudBotsInputRef } from '../../../CloudBots/Components/CloudBotsInput.types';
import { getVendor, IVendor, Vendors } from 'common/consts/vendors';
import { useFieldErrors } from '../../Hooks/useFieldErrors';
import { IEvent } from 'common/module_interface/events/Events';

export const FixItModal: React.FC<IFixItModalProps> = (props: IFixItModalProps) => {
    const { tableId, selectedRows, setModalType, closeModal } = props;
    const fixItCustomizationKey: string = useMemo(() => getFixItCustomizationKey(tableId), [tableId]);
    const fieldErrorsProps = useFieldErrors();
    const { addFieldErrors, isFieldError, getFieldErrorText, clearFieldErrors } = fieldErrorsProps;
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const cloudBotsInputRef: React.RefObject<ICloudBotsInputRef> = useRef<ICloudBotsInputRef>(null);
    const endPointRef: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
    const [cloudAccountId, setCloudAccountId] = useState<string>(EMPTY_STRING);
    const [botExecuting, setBotExecuting] = useState<boolean>(false);
    const [botExecutionResponse, setBotExecutionResponse] = useState<IRemediationResponse | null>(null);
    const [isCancelled, setIsCancelled] = useState<boolean>(false);
    const firstFinding: IEvent | undefined = useMemo(() => {
        return isEmpty(selectedRows) ? undefined : selectedRows[0];
    }, [selectedRows]);
    const [endpointUrl, setEndpointUrl] = useState<string>(EMPTY_STRING);
    const [platform, setPlatform] = useState<Vendors>();

    useEffect(() => {
        if (!firstFinding) return;
        if (firstFinding.cloudAccountType) {
            const vendor: IVendor | null = getVendor(firstFinding.cloudAccountType);
            if (!vendor) return;
            setPlatform(vendor.name);
        }
        if (firstFinding.cloudAccountId) {
            setCloudAccountId(firstFinding.cloudAccountId);
        }
    }, [firstFinding]);

    const endPointLabel: string = useMemo(() => {
        const endPointType: EndPointType = getEndPointType(firstFinding);
        switch (endPointType) {
            case EndPointType.SNS:
                return t('CLOUD_BOTS.SNS_ENDPOINT');
            case EndPointType.HTTP:
                return t('CLOUD_BOTS.HTTP_ENDPOINT');
        }
    }, [firstFinding, t]);

    const updateFieldsErrorState = () => {
        const errors: IFieldError[] = [];
        if (!getEndpoint()) {
            errors.push({
                field: FormFields.ARN_ENDPOINT,
                label: t('CLOUD_BOTS.ERRORS.ENDPOINT_REQUIRED'),
            });
        }
        addFieldErrors(errors);
        return errors.length > 0;
    };

    const handleEndPointChange = () => {
        clearFieldErrors([FormFields.ARN_ENDPOINT]);
    };

    const getFindingsKeys = (): IFindingKey[] => {
        return (
            selectedRows.map((row: IEvent): IFindingKey => {
                return { key: row.findingKey };
            }) ?? []
        );
    };

    const getEndpoint = (): string => {
        return endPointRef.current?.value?.trim() ?? EMPTY_STRING;
    };

    const generateCloudBotsData = (selectedCloudBots: ICloudBot[] = []): ICloudBotsData[] => {
        return selectedCloudBots.map((bot: ICloudBot): ICloudBotsData => {
            return {
                name: bot.id,
                params: bot.parameters.map((param: ICloudBotParameter) => param.value ?? EMPTY_STRING),
            };
        });
    };

    const executeRemediation = async (): Promise<IRemediationResponse> => {
        const findingsKeys: IFindingKey[] = getFindingsKeys();
        const selectedCloudBots: ICloudBot[] = cloudBotsInputRef.current?.getSelectedCloudBots() ?? [];
        const cloudBots: ICloudBotsData[] = generateCloudBotsData(selectedCloudBots);
        const triggerEndpoint: string = getEndpoint();

        const request: IRemediationRequest = {
            findings: findingsKeys,
            cloudbots: cloudBots,
            cloudAccount: cloudAccountId,
            triggerEndpoint: triggerEndpoint,
        };
        return await getCloudBotsService().executeRemediation(request);
    };

    const handleError = async (message: string) => {
        setBotExecuting(false);
        const errorTitle = t('CLOUD_BOTS.NOTIFICATIONS.ERROR.REMEDIATION_EXECUTION_TITLE');
        const errorText = t('CLOUD_BOTS.NOTIFICATIONS.ERROR.REMEDIATION_EXECUTION_TEXT');
        getNotificationsService().error(errorTitle, errorText);
        await getLoggerService().error(`${errorTitle}: ${message}`);
    };

    const handleExecuteButtonClick = async () => {
        const isValidCloudBots: boolean = cloudBotsInputRef.current?.isValidCloudBots() ?? false;
        const isAnyFieldError: boolean = updateFieldsErrorState();
        if (!isValidCloudBots || isAnyFieldError) return;

        if (getEndpoint()) {
            // save endpoint into localStorage
            getCloudBotsService().saveEndpoint(cloudAccountId, getEndpoint());
        }
        try {
            setBotExecuting(true);
            const response: IRemediationResponse = await executeRemediation();
            if (isEmpty(response.workItemsTriggerStatuses)) {
                await handleError(NO_BOT_TRIGGER_ERR);
            } else {
                setBotExecutionResponse(response);
            }
        } catch (error: any) {
            await handleError(error.message);
        }
    };

    useEffect(() => {
        const storeDetailsAndCloseModal = async () => {
            if (!isCancelled && !isNull(botExecutionResponse)) {
                await getCloudBotsService().setRemediationDetails(fixItCustomizationKey, {
                    triggerTime: Date.now(),
                    remediationData: botExecutionResponse,
                });
                closeModal();
                // add current executing cloudBot into redux store
                CloudBotsActions.addCloudBot(fixItCustomizationKey);
                setModalType(ModalType.BotStatusCheck);
                setBotExecuting(false);
            } else if (isCancelled && isNull(botExecutionResponse)) {
                closeModal();
            }
        };
        storeDetailsAndCloseModal();
    }, [closeModal, isCancelled, botExecutionResponse, setModalType, fixItCustomizationKey]);

    const handleCancelButtonClick = () => {
        setIsCancelled(true);
    };

    useEffect(() => {
        if (!cloudAccountId || !platform) return;
        const getEndpoint = async () => {
            const endpoint = (await getCloudBotsService().getEndpoint(cloudAccountId, platform)) || EMPTY_STRING;
            setEndpointUrl(endpoint);
        };
        getEndpoint();
    }, [cloudAccountId, platform]);

    return (
        <Modal.ModalDialog onRequestClose={handleCancelButtonClick} isOpen={true} width={'lg'}>
            <Modal.ModalHeader
                title={t('CLOUD_BOTS.TITLE')}
                onClose={handleCancelButtonClick}
                subTitle={t('CLOUD_BOTS.SUBTITLE')}
            />
            <Modal.ModalContent>
                <Styles.FormFieldsWrapper spacing={6}>
                    <CloudBotsInput
                        maxCloudBotsAllowed={5}
                        fieldErrorsProps={fieldErrorsProps}
                        selectedRows={selectedRows}
                        ref={cloudBotsInputRef}
                    />
                    <Styles.EndpointWrapper fullWidth>
                        <Input
                            ref={endPointRef}
                            label={endPointLabel}
                            value={endpointUrl}
                            required={true}
                            clearable={true}
                            isError={isFieldError(FormFields.ARN_ENDPOINT)}
                            helperText={getFieldErrorText(FormFields.ARN_ENDPOINT)}
                            onChange={handleEndPointChange}
                            placeholder={t('CLOUD_BOTS.TYPE_HERE')}
                            fullWidth
                        />
                    </Styles.EndpointWrapper>
                </Styles.FormFieldsWrapper>
            </Modal.ModalContent>
            <Modal.ModalFooter>
                <Styles.FooterActionsWrapper direction='row' justifyContent='flex-end' fullWidth spacing={2}>
                    <Button key={'cancel-button-action'} variant='text' onClick={handleCancelButtonClick}>
                        {i18n.t('COMMON.CANCEL')}
                    </Button>
                    <Button
                        key={'execute-button-action'}
                        color='brandPrimary'
                        onClick={handleExecuteButtonClick}
                        loading={botExecuting}
                        disabled={botExecuting}
                    >
                        {i18n.t('COMMON.EXECUTE')}
                    </Button>
                </Styles.FooterActionsWrapper>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    );
};
