import { IBaseUserModel, IUser } from 'common/interface/user';
import { getHttpService } from 'common/interface/services';
import { IUsersService } from 'common/interface/userService';

export class UsersService implements IUsersService {
    async getUsers(useCache = true): Promise<IUser[]> {
        return await getHttpService().get<IUser[]>({
            path: '/user',
            cachingConfig: {
                useCache,
            },
        });
    }

    async addUser(user: IBaseUserModel): Promise<IUser> {
        return await getHttpService().post('/user', { data: user });
    }

    async deleteUser(userId: number): Promise<void> {
        return await getHttpService().delete(`/user/${userId}`);
    }

    async editUser(user: IUser): Promise<IUser> {
        return await getHttpService().put(`/user/${user.id}`, { data: user });
    }
}
