import { InfoPanelActionProps } from 'common/components/InfoPane/InfoPane';
import { Vendors } from 'common/consts/vendors';
import { IProtectedAssetDetailProvider } from 'common/module_interface/assets/ProtectedAssets';
import { isAwpAsset } from 'modules/workloads/pages/awp/awp.utils';
import AwpService from 'modules/workloads/services/awp/awp.service';
import { Addin } from 'common/extensibility/AddinRegistry';
import { awpScanChipProviders, getProviderType } from './AwpRescanChip.utils';

export const awpScanChipAddinActionFactory = (
    id: string,
    position: number,
): Addin<IProtectedAssetDetailProvider<InfoPanelActionProps>>[] => {
    return Object.entries(awpScanChipProviders).map(([type, getValue]) => {
        return {
            id: `${id} - ${type}`,
            position,
            content: {
                isRelevant: async (entity, isSummaryView) => {
                    if (isSummaryView || !isAwpAsset(entity.typeByPlatform)) return false;
                    try {
                        const {
                            data: { agentlessProtectionEnabled, scanMode },
                        } = await AwpService.getWorkloadAgentessAccount(
                            entity.cloudAccountId,
                            entity.protectedAsset.platform as Vendors,
                        );
                        return getProviderType(agentlessProtectionEnabled, scanMode) === type;
                    } catch (error: any) {
                        return error?.response?.status === 404 ? getProviderType(false, undefined) === type : false;
                    }
                },
                getValue,
            },
        };
    });
};
