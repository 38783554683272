import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const TopDiv = styled(Stack)`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const SepDiv = styled(Stack)`
    display: flex;
    width: 100%;
    height: 20px;
    position: relative;
`;

const SepLine = styled.div`
    display: flex;
    height: 1px;
    border-bottom: 1px solid #d2d9e4;
    position: absolute;
    top: 20px;
    left: -24px;
    right: -24px;
`;

export const BaseStyled = {
    TopDiv,
    SepDiv,
    SepLine,
};
