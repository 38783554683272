import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import { GetAzureAccountDetails, GetAzureOffboarding } from './azure-cloud-account.interface';
import { Vendors } from 'common/consts/vendors';

const getAzureAccountDetails: GetAzureAccountDetails.Function = async (cloudAccountId) => {
    return await getHttpService().request<AxiosResponse<GetAzureAccountDetails.Response>>(
        `AzureCloudAccount/${cloudAccountId}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true, cachingConfig: { useCache: false } },
        (err) => {
            throw err;
        },
    );
};
const getAzureOffboarding: GetAzureOffboarding.Function = async (cloudAccountId, centralizedAccounts) => {
    return await getHttpService().request<AxiosResponse<GetAzureOffboarding.Response>>(
        `workload/agentless/${Vendors.AZURE}/accounts/${cloudAccountId}/offboarding${centralizedAccounts ? `?centralizedAccounts=${centralizedAccounts}` : ''}`,
        { method: 'GET', data: { id: cloudAccountId } },
        { returnAsAxiosResponse: true, cachingConfig: { useCache: true } },
        (e) => {
            throw e;
        },
    );
};

const AzureCloudAccountService = {
    getAzureAccountDetails,
    getAzureOffboarding,
};

export default AzureCloudAccountService;
