import React from 'react';
import { Stack, Table } from 'common/design-system/components-v2';
import { RulesTableProps } from './RulesTable.types';
import EmptyState from 'common/components/EmptyState';
import { GridApi, ColumnApi, GridReadyEvent, GridOptions } from 'ag-grid-community';
import { rulesTableColDefs } from '../definitions/ColumnDefinitions';
import RulesTableFilterPanel from './RulesTableFilterPanel/RuleTableFilterPanel';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { ITableProps } from 'common/design-system/components-v2/Table/Table.types';

const RulesTable: React.FunctionComponent<RulesTableProps> = ({
    host,
    rowData,
    aggregations,
    isLoading,
    onFilterChange,
    onExportCsv,
}) => {
    const { t } = useTranslation(getK8sNamespace('cis'));
    const apiRef = React.useRef<GridApi>();
    const columnApiRef = React.useRef<ColumnApi>();

    React.useEffect(() => {
        if (apiRef.current) {
            if (isLoading) {
                apiRef.current.showLoadingOverlay();
            } else {
                apiRef.current.hideOverlay();
            }
        }
    }, [isLoading]);

    React.useEffect(() => {
        if (apiRef.current && rowData) {
            apiRef.current.setRowData([]);
            apiRef.current.setRowData(rowData);
        }
    }, [rowData]);

    const onGridReady = React.useCallback(
        (params: GridReadyEvent) => {
            apiRef.current = params.api;
            columnApiRef.current = params.columnApi;
            if (rowData) {
                apiRef.current.setRowData([]);
                apiRef.current.setRowData(rowData);
            }
            if (isLoading) {
                apiRef.current.showLoadingOverlay();
            } else {
                apiRef.current.hideOverlay();
            }
        },
        [rowData, isLoading],
    );

    const gridOptions: GridOptions = React.useMemo(
        () => ({
            columnDefs: rulesTableColDefs(host),
            noRowsOverlayComponent: () => (
                <EmptyState
                    iconSize={32}
                    iconName='magnify'
                    description={'No Results Found'}
                    label='No Results Found'
                />
            ),
            onGridReady,
        }),
        [onGridReady, host],
    );

    const exportButtons: ITableProps['exportButtons'] = React.useMemo(() => {
        return [{ onClick: onExportCsv, label: t('export.export.label'), tooltip: t('export.export.tooltip') }];
    }, [t, onExportCsv]);

    return (
        <div style={{ height: '800px', width: '100%' }}>
            <Stack spacing={4} fullHeight>
                <RulesTableFilterPanel aggregations={aggregations || {}} onFilterChange={onFilterChange} />
                <Table tableId='rules-table' saveColumnsState exportButtons={exportButtons} gridOptions={gridOptions} />
            </Stack>
        </div>
    );
};

export default RulesTable;
