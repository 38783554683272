import React from 'react';
import CloudAccountsApi from 'modules/assets/Environments/services/cloudAccountsApi/cloudAccountsApi.service';
import AzureCloudAccountApi from 'modules/assets/Environments/services/azureCloudAccountApi/azureCloudAccountApi.service';
import GoogleCloudAccountApi from 'modules/assets/Environments/services/googleCloudAccountApi/googleCloudAccountApi.service';
import OciCloudAccountsApi from 'modules/assets/Environments/services/ociCloudAccountApi/ociCloudAccountApi.service';
import { IEnvironment } from 'common/interface/environmentsTable';
import { Vendors } from 'common/consts/vendors';

export interface UseResetPermissionsResults {
    isLoading: boolean;
    isSuccess?: boolean;
    error?: string;
}

export interface UseMissingPermissionsResetReq {
    onFinish?: (response?: { successAmount: number; failedAmount: number }) => void;
}

export interface UseMissingPermissionsResetPayload {
    isLoading: boolean;
    results: { [id: string]: UseResetPermissionsResults };
    resetPermissions: (cloudAccounts: IEnvironment[]) => Promise<void>;
    reset?: () => void;
}

export type UseMissingPermissionsReset = (req?: UseMissingPermissionsResetReq) => UseMissingPermissionsResetPayload;

export const useMissingPermissionsReset: UseMissingPermissionsReset = (req) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [results, setResults] = React.useState<{ [id: string]: UseResetPermissionsResults }>({});

    const resetPermissions = React.useCallback(
        async (cloudAccounts: IEnvironment[]) => {
            setIsLoading(true);
            const allResults = await Promise.allSettled(
                cloudAccounts.map((cloudAccount) => {
                    setResults((prev) => ({ ...prev, [cloudAccount.id]: { isLoading: true } }));
                    let request = CloudAccountsApi.resetMissingPermissions;
                    switch (cloudAccount.platform) {
                        case Vendors.AZURE: {
                            request = AzureCloudAccountApi.resetMissingPermissions;
                            break;
                        }
                        case Vendors.GOOGLE: {
                            request = GoogleCloudAccountApi.resetMissingPermissions;
                            break;
                        }
                        case Vendors.OCI: {
                            request = OciCloudAccountsApi.resetMissingPermissions;
                            break;
                        }
                    }
                    return request({ id: cloudAccount.id })
                        .then((result) => {
                            setResults((prev) => ({
                                ...prev,
                                [cloudAccount.id]: { isLoading: false, isSuccess: true },
                            }));
                            return result;
                        })
                        .catch((error) => {
                            setResults((prev) => ({
                                ...prev,
                                [cloudAccount.id]: { isLoading: false, isSuccess: false, error: error.message },
                            }));
                            return error;
                        });
                }),
            );
            req?.onFinish?.({
                successAmount: allResults.filter((result) => result.status === 'fulfilled').length,
                failedAmount: allResults.filter((result) => result.status === 'rejected').length,
            });
            setIsLoading(false);
        },
        [req],
    );

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setResults({});
    }, []);

    return {
        isLoading,
        results,
        resetPermissions,
        reset,
    };
};
