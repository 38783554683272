import React from 'react';
import Stack from 'common/design-system/components-v2/Stack';
import { FindingSeveritiesMap } from 'common/consts/FindingSeverity';
import LevelCounterIndicator from 'common/erm-components/custom/LevelCounterIndicator/LevelCounterIndicator';

export const FindingSeveritySummary: React.FC<{ criticalCount: number; highCount: number }> = ({
    criticalCount,
    highCount,
}) => (
    <Stack direction={'row'} spacing={2}>
        {criticalCount > 0 && (
            <LevelCounterIndicator
                bg={FindingSeveritiesMap.critical.color}
                count={criticalCount}
                title={FindingSeveritiesMap.critical.displayText}
            />
        )}
        {highCount > 0 && (
            <LevelCounterIndicator
                bg={FindingSeveritiesMap.high.color}
                count={highCount}
                title={FindingSeveritiesMap.high.displayText}
            />
        )}
    </Stack>
);

export default FindingSeveritySummary;
