import React, { useCallback, useMemo } from 'react';
import { changeUrl } from 'common/utils/http';
import { useLocation } from 'react-router-dom';
import { useAssetDetailsPanels } from './AssetDetailsPanelsHooks';
import { removeAngularTabProps } from 'common/module_interface/assets/utils';
import { getAssetInfoFromLocation } from 'common/components/ProtectedAssets/AssetUtils';
import '../../Assets.scss';
import { logEvent } from 'common/utils/logUtils';

interface ListItem {
    title: string;
    isSelected?: boolean;
    name: string;
}

const List: React.FC<{ items: ListItem[]; onSelect: (item: ListItem) => void; classNames?: string }> = (props) => {
    return (
        <ul className={`list list--hoverable ${props.classNames}`}>
            {props.items.map((item: ListItem, index: number) => (
                <li
                    key={index}
                    role='option'
                    aria-selected={item.isSelected}
                    tabIndex={-1}
                    className={`group list__item flex !pr-10 py-2 !pl-8 ${item.isSelected ? 'selected-tab' : ''}`}
                    onClick={() => props.onSelect(item)}
                    data-aid={item.title.replace(/\s+/g, '-').toLowerCase()}
                >
                    <div className='flex-1'>{item.title}</div>
                </li>
            ))}
        </ul>
    );
};

const AssetDetailsPanelsList: React.FC = () => {
    const location = useLocation();
    const panels = useAssetDetailsPanels();
    const onSelect = useCallback(
        (item: ListItem) => {
            const querystring = new URLSearchParams(location.search);
            removeAngularTabProps(querystring);
            querystring.set('tabName', item.name);
            querystring.set('tabOnly', 'true');
            const assetInfo = getAssetInfoFromLocation(location);
            logEvent('assetTabSelected', {
                tabName: item.name,
                assetId: assetInfo.id,
                cloudAccountId: assetInfo.cloudAccountId,
                assetType: assetInfo.type,
                platform: assetInfo.platform,
            });
            changeUrl(location.pathname + `?${querystring.toString()}`, true);
        },
        [location],
    );

    const listItems: ListItem[] = useMemo(() => {
        const querystring = new URLSearchParams(location.search);
        const currentPanelName = querystring.get('tabName');
        return panels.map((panel) => {
            return {
                title: panel.title,
                isSelected: currentPanelName !== null && currentPanelName === panel.name,
                name: panel.name,
            };
        });
    }, [panels, location]);

    return (
        <div className={'flex-0 flex flex-col border-r pt-8 p-6'} data-aid='asset-details-panels-list'>
            <List items={listItems} onSelect={onSelect}></List>
        </div>
    );
};

export default AssetDetailsPanelsList;
