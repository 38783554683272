import { getHttpService } from 'common/interface/services';
import { SERVICES_TAGS } from '../../services/services.consts';
import vulnerabilityService from '../../services/vulnerability/vulnerability.service';
import { VulnerabilityExclusionFormProps } from './VulnerabilityExclusionForm/VulnerabilityExclusionForm.types';
import { globalEventBus } from 'common/erm-components/utils/EventBus/eventBus';
import { TABLE_ID_VULNERABILITY_EXCLUSION } from './VulnerabilityExclusionTable.const';

const onConfirm: VulnerabilityExclusionFormProps['onConfirm'] = async (
    type,
    comment,
    includeInAssessment,
    name,
    filters,
) => {
    getHttpService().clearCacheByTag(SERVICES_TAGS.VULNERABILITY_EXCLUSIONS);
    await vulnerabilityService.createVulnerabilityExclusions([
        {
            type: type,
            comment: comment,
            includeInAssessment: includeInAssessment,
            name: name,
            filter: filters,
        },
    ]);
    globalEventBus.sendEvent(TABLE_ID_VULNERABILITY_EXCLUSION);
};

const onConfirmEdit: VulnerabilityExclusionFormProps['onConfirmEdit'] = async (
    id,
    name,
    comment,
    type,
    includeInAssessment,
    filters,
) => {
    getHttpService().clearCacheByTag(SERVICES_TAGS.VULNERABILITY_EXCLUSIONS);
    await vulnerabilityService.editVulnerabilityExclusions([
        {
            name,
            id,
            comment,
            includeInAssessment,
            type: type,
            filter: filters,
        },
    ]);
    globalEventBus.sendEvent(TABLE_ID_VULNERABILITY_EXCLUSION);
};

export type GetFunctionsProps = () => {
    onConfirm: VulnerabilityExclusionFormProps['onConfirm'];
    onConfirmEdit: VulnerabilityExclusionFormProps['onConfirmEdit'];
};
export const getFunctionsProps: GetFunctionsProps = () => {
    return {
        onConfirm: onConfirm,
        onConfirmEdit: onConfirmEdit,
    };
};
