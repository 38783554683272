import { IActiveFeatureInfo } from 'common/registries/ActiveFeatureManager/ActiveFeatures';

export const ERM_TOPIC_ID = 'ERM';

export const ERM_EXPERIMENTS_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'erm-experiments',
    description:
        'If enabled, it allows developer led customer facing features which were not yet approved by the product team',
    topic: ERM_TOPIC_ID,
};
export const DATA_SECURITY_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'data-security',
    description: 'If enabled, Data Security menu item will be available in menu',
    topic: ERM_TOPIC_ID,
};

export const SIMILAR_ASSETS_FEATURE: IActiveFeatureInfo = {
    key: 'similar-assets',
    description: 'If enabled, the Protected Assets table will allow grouping by Similarity',
    topic: ERM_TOPIC_ID,
};

export const DATA_SENSITIVITY_EVIDENCE_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'erm-sensitive-evidence',
    description:
        'If enabled, Data Sensitivity section with list of assets that have Classifications shown in evidence path.',
    topic: ERM_TOPIC_ID,
};

export const TOXIC_GRAPH_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'toxic-graph',
    description: 'If enabled, Toxic Graph page will be displayed under Risk Management / Toxic Combinations',
    topic: ERM_TOPIC_ID,
};

export const CGNS_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'erm-cgns-integration',
    description: 'If enabled, CGNA integration will be shown',
    topic: ERM_TOPIC_ID,
};

export const ERM_ACTIVE_FEATURES: IActiveFeatureInfo[] = [
    ERM_EXPERIMENTS_ACTIVE_FEATURE,
    CGNS_ACTIVE_FEATURE,
    DATA_SECURITY_ACTIVE_FEATURE,
    SIMILAR_ASSETS_FEATURE,
    TOXIC_GRAPH_ACTIVE_FEATURE,
    DATA_SENSITIVITY_EVIDENCE_ACTIVE_FEATURE,
];
