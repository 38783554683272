import { sendLogger } from './logger';
import {
    IProtectedAssetAdditionalField,
    IProtectedAssetViewModel,
} from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { getAdditionalFieldValue } from 'common/utils/ColumnDisplayValueGetters';
import { getExternalAdditionalFieldValue } from 'common/module_interface/assets/utils';
import { AssetTypes } from 'common/assets/assets.const';
import { StatusWarning, StatusError, StatusSuccess } from 'common/design-system/icons/SvgComponents/system-icons';
import moment from 'moment-mini';

const STATUSES = {
    OK: StatusSuccess,
    PENDING: StatusWarning,
    WARNING: StatusWarning,
    ERROR: StatusError,
    default: StatusWarning,
};

// @ts-ignore
export const getIconByStatus = (status: string) => STATUSES[status] || STATUSES['default'];
export const isCheckpointUser = (mail: string | undefined) => mail?.split('@')[1] === 'checkpoint.com';
export const isCustomerPlan = (plan: string) =>
    ['Enterprise', 'FREE', 'Enterprise_Trial', 'MSP'].some((planItem) => planItem === plan);

type GetFieldByNameFromAdditionalField = (
    additionalFields: Array<IProtectedAssetAdditionalField>,
) => (fieldName: string) => IProtectedAssetAdditionalField | undefined;
const getFieldByNameFromAdditionalField: GetFieldByNameFromAdditionalField = (additionalFields) => (fieldName) =>
    additionalFields.find((field) => field.name === fieldName);

export const isImageType = (typeByPlatform: string) =>
    [
        AssetTypes.CONTAINERREGISTRY_CONTAINERREGISTRYIMAGE,
        AssetTypes.KUBERNETES_KUBERNETESIMAGE,
        AssetTypes.AWS_ECSIMAGE,
        AssetTypes.SHIFTLEFT_SHIFTLEFTIMAGE,
    ].some((type) => type === typeByPlatform);

export const getScanStatus = (data: IProtectedAssetViewModel) => {
    return (
        data?.vulnerability?.scanStatus ||
        getAdditionalFieldValue(data, 'ScanStatus') ||
        getExternalAdditionalFieldValue(data, 'ScanStatus')
    );
};

export const getScanStatusDetails = (data: IProtectedAssetViewModel) => {
    return (
        data?.vulnerability?.scanStatusDetails ||
        getAdditionalFieldValue(data, 'ScanStatusDetails') ||
        getExternalAdditionalFieldValue(data, 'ScanStatusDetails') ||
        ''
    );
};

export const getScanStatusData = (protectedAsset: IProtectedAssetViewModel) => {
    const scanStatus = getScanStatus(protectedAsset);
    const tooltip = getScanStatusDetails(protectedAsset);
    const value = getExternalAdditionalFieldValue(protectedAsset, 'LastScanDate');
    const date = value && moment(value).isValid() ? moment(value).format('MM/DD/YYYY hh:mm:ss A') : '';
    return { scanStatus, tooltip, date };
};

export const paddingBackslash = (text: string) => text.replace(/\\/g, '\\\\');

const removeShaFromImage = (imageId?: string | null) => {
    const split = imageId?.split(':');
    if (split && split.length > 1) {
        return split[1];
    }
    return '';
};

export enum InnerTabs {
    VULNERABILITIES = 'vulnerabilities',
}

export { sendLogger, getFieldByNameFromAdditionalField, removeShaFromImage };
