import { FC, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { I18nTranslationKey } from '../initUsersPage';
import { Button, Modal, Spinner, Stack, Typography } from 'common/design-system/components-v2';
import { getNotificationsService, UserPageService } from 'common/interface/services';

interface IResetPasswordModalProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    selectedEmail?: string;
    selectedId?: number;
}

export const RevokeApiKeyV2Modal: FC<IResetPasswordModalProps> = ({
    isModalOpen,
    toggleModal,
    selectedEmail,
    selectedId,
}) => {
    const { t } = useTranslation(I18nTranslationKey);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        toggleModal();
    };

    const showErrorNotification = (error: any) => {
        const errorData = error?.response?.data || error.message || '';
        const errorHeader = t('ACTIONS.Revoke_API_KEY_MODAL.TOAST_ERROR_HEADER');
        const errorContent = errorData || t('ACTIONS.Revoke_API_KEY_MODAL.TOAST_ERROR_CONTENT');
        getNotificationsService().error(errorHeader, errorContent);
    };

    const handleSubmit = async () => {
        if (!selectedId) {
            showErrorNotification(new Error('No user selected'));
            return;
        }
        setIsLoading(true);
        const payload = { userId: selectedId };
        try {
            await UserPageService().revokeApiKey(payload);
            getNotificationsService().success(
                t('ACTIONS.Revoke_API_KEY_MODAL.TOAST_SUCCESS_HEADER'),
                t('ACTIONS.Revoke_API_KEY_MODAL.TOAST_SUCCESS_CONTENT'),
            );
            handleClose();
        } catch (e: any) {
            showErrorNotification(e);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal.ModalDialog width={'lg'} isOpen={isModalOpen}>
            <Modal.ModalHeader onClose={handleClose} title={t('ACTIONS.Revoke_API_KEY_MODAL.MODAL_HEADER')} />
            <Modal.ModalContent>
                <Stack padding={2}>
                    <Typography variant={'lg'}>
                        <Trans>{t('ACTIONS.Revoke_API_KEY_MODAL.MODAL_CONTENT', { email: selectedEmail })}</Trans>
                    </Typography>
                </Stack>
            </Modal.ModalContent>
            <Modal.ModalFooter>
                <Stack direction={'row'} spacing={3} justifyContent={'flex-end'} fullWidth>
                    <Button
                        dataAid={'cancel-reset-password-button'}
                        variant='text'
                        onClick={handleClose}
                        disabled={isLoading}
                    >
                        {t('GENERAL.CANCEL')}
                    </Button>
                    <Button
                        dataAid={'ok-reset-password-button'}
                        color={'brandPrimary'}
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        {' '}
                        {isLoading ? <Spinner size={12} /> : t('GENERAL.OK')}
                    </Button>
                </Stack>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    );
};
