import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ColumnApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import { getIssuesPageColumnDefs } from './IssuesTableColumns';
import { DefaultTextCellRender } from 'common/components/ag-grid/Renderers/DefaultTextCellRender';
import { TableStyled } from './IssuesTable.styled';
import { IssuesTableDatasource } from './IssuesTableDatasource';
import { IIssue, IServerInputFilterDetails } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { fixPinnedColumns, getGroupedCols, loadColumnsState, saveColumnsState } from 'common/utils/tableUtils';
import { IIssueOrGroup, IIssuesTableData, IIssueTableDataCounters, IIssueTableDataState } from '../../Issues.interface';
import { ITableExportButton } from 'common/design-system/components-v2/Table/Table.types';
import { I18nRiskNamespace } from '../../../../consts';
import i18n from 'i18next';
import { getNotificationsService } from 'common/interface/services';
import { IssuesRegistry } from 'common/module_interface/RiskManagement/issues/IssuesRegistry';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import { escapeCSVValue, isString } from 'common/utils/helpFunctions';
import { CGColDef, ICsvExportConfig } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import Table from 'common/design-system/components-v2/Table';
import { isToxicGrouping, openIssueDrawer } from '../../Issues.utils';
import { useTranslation } from 'react-i18next';
import { LoadingState } from 'common/interface/general';
import { ermTrans } from '../../../../RiskManagement.utils';
import { IsServerSideGroupOpenByDefaultParams } from 'ag-grid-community/dist/lib/interfaces/iCallbackParams';
import { FIRST_TOXIC_FIELD_NAME } from '../../Issues.consts';
import { getIssuesTableActions } from './IssuesTableActions';
import { default as IssueRuleTitleCellRender } from '../../cellRenderers/IssueRuleTitleCellRender';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';

const COLUMNS_STATE_STORAGE_KEY = 'ISSUES_TABLE_COLUMNS_STATE';

function getCSVExportDefs(columnDefs: CGColDef[]): ICsvExportConfig[] {
    return columnDefs
        .filter((value) => value.csvExport !== undefined)
        .map((value) => value.csvExport!)
        .sort((first, second) => {
            return first.order - second.order;
        });
}

async function getIssuesAsCSV(columnDefs: CGColDef[], tableData: IIssuesTableData) {
    const exportDefs = getCSVExportDefs(columnDefs);

    const exportedRows: string[] = [];
    const titleRow: string[] = [];
    exportDefs.forEach((exportDef) => {
        if (exportDef) {
            titleRow.push(exportDef.title);
        }
    });
    exportedRows.push(titleRow.join(','));
    for (const issue of tableData.issuesOrGroups) {
        const rowValues: string[] = [];
        for (const exportDef of exportDefs) {
            const calculatedValue = await exportDef.calculateValue(issue);
            const value: string = escapeCSVValue(String(calculatedValue ?? ''));
            rowValues.push(value);
        }
        exportedRows.push(rowValues.join(','));
    }

    return exportedRows.join('\n');
}

export const IssuesTable: React.FC<{
    filterValues?: IServerInputFilterDetails;
    columnStateStorageKey?: string;
}> = ({ filterValues, columnStateStorageKey = COLUMNS_STATE_STORAGE_KEY }) => {
    const gridApiRef = useRef<GridApi<IIssue>>();
    const columnApiRef = useRef<ColumnApi>();
    const [dataSource, setDataSource] = useState<IssuesTableDatasource | undefined>();
    const [footer, setFooter] = useState<string | undefined>(ermTrans('GENERAL.LOADING'));
    const { t } = useTranslation(I18nRiskNamespace);

    const onDataStateChange = useCallback(
        (dataState?: IIssueTableDataState) => {
            gridApiRef.current?.hideOverlay();
            if (!dataState) {
                setFooter(undefined);
            } else if (isString(dataState)) {
                switch (dataState as LoadingState) {
                    case LoadingState.IS_LOADING:
                        setFooter(t('GENERAL.LOADING'));
                        break;

                    case LoadingState.LOADING_FAILED:
                        setFooter(t('GENERAL.FAILED_LOADING_DATA'));
                        break;

                    default:
                        setFooter(undefined);
                }
            } else {
                const dataCounters: IIssueTableDataCounters = dataState as IIssueTableDataCounters;
                if (dataCounters.itemCount === 0) {
                    setTimeout(() => {
                        //This is a temp solution, a ticket was opened on ag-grid. This timeout should not be needed here.
                        gridApiRef.current?.showNoRowsOverlay();
                    });
                }

                if (dataCounters.groupCount !== undefined) {
                    setFooter(
                        t('ISSUES.TABLE.GROUPING_FOOTER_TEXT', {
                            groupCount: dataCounters.groupCount,
                            itemCount: dataCounters.itemCount,
                            totalCount: dataCounters.totalCount,
                        }),
                    );
                } else {
                    setFooter(
                        t('ISSUES.TABLE.FOOTER_TEXT', {
                            itemCount: dataCounters.itemCount,
                            totalCount: dataCounters.totalCount,
                        }),
                    );
                }
            }
        },
        [t],
    );

    const onGridReady = useCallback(
        (params: GridReadyEvent<IIssue>) => {
            gridApiRef.current = params.api;
            columnApiRef.current = params.columnApi;
            setDataSource(
                new IssuesTableDatasource({
                    gridApi: params.api,
                    gridColApi: params.columnApi,
                    onDataStateChange,
                }),
            );
            loadColumnsState(params.columnApi, columnStateStorageKey);
        },
        [columnStateStorageKey, onDataStateChange],
    );

    const getChildCount = useCallback((data: any) => {
        return data ? data.childCount : undefined;
    }, []);

    const isTableGrouped = useCallback(() => {
        if (!columnApiRef.current) {
            return false;
        }
        const colDefs: ColDef[] = getGroupedCols(columnApiRef.current);
        return colDefs.length > 0;
    }, []);

    const onColumnStateChanged = useCallback(() => {
        if (!columnApiRef.current) {
            return;
        }
        saveColumnsState(columnApiRef.current, columnStateStorageKey);
        fixPinnedColumns(columnApiRef.current);
    }, [columnStateStorageKey]);

    const onClickRuleTitle = useCallback((issueOrGroup: IIssueOrGroup) => {
        if (issueOrGroup.id) {
            openIssueDrawer(issueOrGroup.id);
        }
    }, []);

    const isServerSideGroupOpenByDefault = useCallback((params: IsServerSideGroupOpenByDefaultParams): boolean => {
        if (!columnApiRef.current) {
            return false;
        }
        if (isToxicGrouping(columnApiRef.current)) {
            return params.rowNode.field === FIRST_TOXIC_FIELD_NAME;
        }
        return false;
    }, []);

    const gridOptions: GridOptions = useMemo(() => {
        return {
            columnDefs: getIssuesPageColumnDefs(onClickRuleTitle),
            rowSelection: 'multiple',
            onGridReady,
            defaultColDef: { cellRenderer: DefaultTextCellRender },
            rowModelType: 'serverSide',
            getChildCount,
            autoGroupColumnDef: {
                cellRendererSelector: (params) => ({
                    component: params.node.group ? 'agGroupCellRenderer' : IssueRuleTitleCellRender,
                }),
                pinned: 'left',
                lockPinned: true,
                width: 450,
                minWidth: 200,
            },
            onSortChanged: onColumnStateChanged,
            onColumnResized: onColumnStateChanged,
            onColumnMoved: onColumnStateChanged,
            onColumnVisible: onColumnStateChanged,
            onGridColumnsChanged: onColumnStateChanged,
            overlayNoRowsTemplate: ermTrans('ISSUES.TABLE.NO_ROWS'),
            enableRangeSelection: false,
            suppressCellFocus: true,
            isServerSideGroupOpenByDefault,
        };
    }, [getChildCount, isServerSideGroupOpenByDefault, onClickRuleTitle, onColumnStateChanged, onGridReady]);

    useEffect(() => {
        if (dataSource) {
            gridApiRef.current!.setServerSideDatasource(dataSource);
        }
    }, [dataSource]);

    useEffect(() => {
        if (filterValues && dataSource) {
            void dataSource.setFilterFields(filterValues);
        }
    }, [dataSource, filterValues]);

    const exportButtons = useMemo<ITableExportButton[]>(() => {
        return [
            {
                label: i18n.t('ISSUES.TABLE.ACTIONS.EXPORT.CAPTION', { ns: I18nRiskNamespace }),
                onClick: () => {
                    if (!dataSource) {
                        getNotificationsService().error(
                            i18n.t('ISSUES.TABLE.ACTIONS.EXPORT.ERROR_TITLE', { ns: I18nRiskNamespace }),
                            i18n.t('ISSUES.TABLE.ACTIONS.EXPORT.ERROR_MESSAGE', { ns: I18nRiskNamespace }),
                        );
                        return;
                    }
                    dataSource.getExportData().then(async (tableData) => {
                        const columnDefs = IssuesRegistry.getIssuesColumnDefs();
                        const dataToExport = await getIssuesAsCSV(columnDefs, tableData);
                        const fileName = `CloudGuard_Security_Issues_${dayjs().format('YYYY_MM_DD_hh:mm_A')}.csv`;
                        fileDownload(dataToExport, fileName, undefined, '\ufeff'); //UTF8 BOM
                    });
                },
            },
        ];
    }, [dataSource]);

    return (
        <TableStyled.TopDiv hideSortIndicators={isTableGrouped()}>
            <Table
                exportButtons={exportButtons}
                gridOptions={gridOptions}
                footer={footer}
                actions={getIssuesTableActions()}
            />
        </TableStyled.TopDiv>
    );
};
