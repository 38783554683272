import { Components } from 'react-markdown';
import styled from 'styled-components';
import SmartCodeSnippet from './components/SmartCodeSnippet/SmartCodeSnippet';

const Ul = styled.ul`
    list-style: disc;
`;

const Li = styled.li`
    align-items: baseline;
    margin-left: ${({ theme }) => `${theme.spacing(4)}${theme.units}`};
`;

const Paragraph = styled.p`
    line-height: 2;
`;

const Strong = styled.strong`
    font-weight: 800;
`;

export const customizeComponents: Components = {
    ul: Ul,
    li: Li,
    strong: Strong,
    p: Paragraph,
    code: ({ children, node }) => <SmartCodeSnippet node={node}>{children}</SmartCodeSnippet>,
};
