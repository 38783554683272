import React, { useEffect } from 'react';
import ImageCard from 'modules/workloads/components/ImageCard/ImageCard';
import { Stack } from 'common/design-system/components-v2';
import { Image } from 'modules/workloads/services/workload/workload.interface';

interface ImagesOnWorkloadProps {
    onClickSelectImage: (image: Image) => void;
    selectedImage: Image | null;
    data: Image[];
}

const ImagesOnWorkload: React.FC<ImagesOnWorkloadProps> = ({ onClickSelectImage, selectedImage, data }) => {
    useEffect(() => {
        if (data?.length > 0) {
            onClickSelectImage(data[0]);
        }
    }, [data, onClickSelectImage]);

    const sortImageByRiskScore = (a: Image, b: Image) => {
        if (!a.riskScore || !b.riskScore) return 0;
        if (a.riskScore > b.riskScore) return -1;
        if (a.riskScore < b.riskScore) return 1;
        return 0;
    };

    return (
        <div>
            <Stack overflow={'auto'} direction={'row'} padding={[0, 0, 4, 0]} spacing={3}>
                {data
                    ?.sort(sortImageByRiskScore)
                    .map((image) => (
                        <ImageCard
                            key={image.imageId}
                            {...image}
                            onClickSelectImage={onClickSelectImage}
                            isSelectedImage={selectedImage?.imageId === image.imageId}
                        />
                    ))}
            </Stack>
        </div>
    );
};

export default ImagesOnWorkload;
