import React, { useCallback } from 'react';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import { IAsset } from 'common/assets/common.assets';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { getVendorDisplayName } from 'common/consts/vendors';
import { LevelIconProps } from 'common/design-system/components-v2/LevelIcon/LevelIcon.types';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import EntityCard from 'common/design-system/components-v2/EntityCard';

interface EntityCardProps {
    finding: IKustoEvent;
}

export const FindingAssetCard: React.FC<EntityCardProps> = ({ finding }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);

    const getBlocks = useCallback((): BlockInfoProps[] => {
        const asset: IAsset | null = getProtectedAssetsService().getAssetByType(finding.entityType);
        const platformName: string = getVendorDisplayName(finding.cloudAccountType);
        return [
            {
                title: t('CARD_PROPS.TYPE'),
                info: asset?.displayName,
                copyable: true,
            },
            {
                title: t('CARD_PROPS.ID'),
                info: finding.entityExternalId,
                copyable: true,
            },
            {
                title: t('CARD_PROPS.ENVIRONMENT'),
                info: finding.cloudAccountName,
                copyable: true,
            },
            {
                title: t('CARD_PROPS.REGION'),
                info: finding.region,
                copyable: true,
            },
            {
                title: t('CARD_PROPS.PLATFORM'),
                info: platformName,
                copyable: true,
            },
        ];
    }, [
        finding.cloudAccountName,
        finding.cloudAccountType,
        finding.entityExternalId,
        finding.region,
        finding.entityType,
        t,
    ]);

    const getLevelIcon = useCallback((): LevelIconProps => {
        const asset: IAsset | null = getProtectedAssetsService().getAssetByType(finding.entityType);
        return {
            iconProps: {
                vendorNameOrPath: asset?.icon || 'cloudGuard',
            },
        };
    }, [finding.entityType]);

    return (
        <EntityCard
            cardTitle={t('ASSET_CARD.TITLE')}
            title={finding.entityName}
            levelIcon={getLevelIcon()}
            blocks={getBlocks()}
        />
    );
};
