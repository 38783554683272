import { getAppRootRegistry, getUserService } from 'common/interface/services';
import { lazy } from 'react';

const workLoadWelcomeRoute = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: lazy(() => import('./pages/welcomePage')),
        path: ['/workload/welcome-page', '/workload-protection/welcome-page'] as any,
    };
};

const onboardingRoute = () => {
    return {
        condition: true,
        component: lazy(() => import('./pages/registry/RegistryOnboarding/RegistryOnboarding')),
        path: '/workload/registry-onboarding',
    };
};

const scanningRoute = () => {
    return {
        condition: true,
        component: lazy(() => import('./pages/registry/registryPage/RegistryPage')),
        path: [
            '/workload/environments/containerregistry/:registryId',
            '/cloud-account/containerregistry/:registryId',
        ] as any,
    };
};

const workloadRoute = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: lazy(() => import('./pages/settings/Settings')),
        path: '/settings/workload',
    };
};

const dashboardRoute = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: lazy(() => import('./pages/dashboard/Dashboard')),
        path: ['/workload/dashboard', '/workload-protection/dashboard'] as any,
    };
};

const kubernetesEnvironmentRoute = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: lazy(() => import('./pages/kubernetesCluster/kubernetesEnvironment/KubernetesEnvironment')),
        path: ['/workload/environments/kubernetes/:id', '/cloud-account/kubernetes/:id'] as any,
    };
};

const shiftleftEnvironmentRoute = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: lazy(() => import('./pages/shiftLeft/shiftLeftEnvironment/ShiftLeftEnvironment')),
        path: ['/workload/environments/shiftleft/:id', '/cloud-account/shiftleft/:id'] as any,
    };
};

const KubernetesOnboardingRoute = () => {
    return {
        condition: true,
        component: lazy(() => import('./pages/kubernetesCluster/kubernetesOnboarding')),
        path: '/workload/cloud-add/kubernetes',
        exact: true,
    };
};

const ShiftleftOnboardingRoute = () => {
    return {
        condition: true,
        component: lazy(() => import('./pages/shiftLeft/ShiftleftOnboarding/ShiftleftOnboarding')),
        path: '/workload/cloud-add/shiftleft',
    };
};

const KubernetesOnboardingFirstTimeRoute = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: lazy(
            () => import('./pages/kubernetesCluster/kubernetesOnboardingFirstTime/KubernetesOnboardingFirstTime'),
        ),
        path: '/workload/cloud-add/kubernetes/first-time',
    };
};

const EnableAwp = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: lazy(() => import('./pages/awp/awpOnboarding/awpOnboardingAws/awpOnboardingAws')),
        path: ['/cloud-add/agentless/aws/:cloudAccountId'] as any,
        exact: true,
    };
};

const EnableAwpAzure = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: lazy(() => import('./pages/awp/awpOnboarding/awpOnboardingAzure/awpOnboardingAzure')),
        path: ['/cloud-add/agentless/azure/:cloudAccountId'] as any,
        exact: true,
    };
};

const UpdateAwp = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: lazy(() => import('./pages/awp/awpUpdate/awpUpdate')),
        path: ['/cloud-update/agentless/:cloudAccountId'] as any,
        exact: true,
    };
};

const CveSearchPage = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: lazy(() => import('./pages/cveSearch')),
        path: ['/workload/cve-search'],
    };
};

const BaseImageRulesPage = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: lazy(() => import('./pages/baseImageRules/BaseImageRules')),
        path: ['/workload/base-image-rule'],
    };
};

const VulnerabilityExclusionRoute = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: lazy(() => import('./pages/vulnerabilityExclusion/VulnerabilityExclusionTable')),
        path: ['/workload/vulnerability-exclusion'],
    };
};

const VulnerabilityFindingsRoute = () => {
    return {
        condition: getUserService().hasPermission(['workloads_vlm_findings']),
        component: lazy(() => import('./pages/vulnerabilityFindings/VulnerabilityFindings')),
        path: ['/workload/vulnerability'],
        exact: false,
    };
};

const VulnerabilitySearchPage = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: lazy(() => import('./pages/vulnerabilitySearch/VulnerabilitySearch')),
        path: ['/workload/vulnerability-search/'],
        exact: false,
    };
};

const ComplianceExclusionsPage = () => {
    return {
        condition: getUserService().hasPermission(['workload-compliance-exclusions']),
        component: lazy(() => import('./pages/complianceExclusions/ComplianceExclusions')),
        path: ['/workload/exclusion'],
        exact: false,
    };
};

export const initializeK8sPages = () => {
    getAppRootRegistry().addRoutes([
        { id: 'K8s Welcome', content: workLoadWelcomeRoute },
        { id: 'K8s Registry Onboarding', content: onboardingRoute },
        { id: 'K8s Registry Scanning', content: scanningRoute },
        { id: 'K8s Workload', content: workloadRoute },
        { id: 'K8s Dashboard', content: dashboardRoute },
        { id: 'K8s Kubernetes Environment', content: kubernetesEnvironmentRoute },
        { id: 'K8s Shifleft Environment', content: shiftleftEnvironmentRoute },
        { id: 'K8s Onboarding Cluster', content: KubernetesOnboardingRoute },
        { id: 'K8s Onboarding Cluster First Time', content: KubernetesOnboardingFirstTimeRoute },
        { id: 'Awp Onboarding', content: EnableAwp },
        { id: 'Awp Onboarding Azure', content: EnableAwpAzure },
        { id: 'Awp Update', content: UpdateAwp },
        { id: 'k8s shiftleft Onboarding', content: ShiftleftOnboardingRoute },
        { id: 'cve-search', content: CveSearchPage },
        { id: 'vulnerability-exclusion', content: VulnerabilityExclusionRoute },
        { id: 'base-image-rule', content: BaseImageRulesPage },
        { id: 'vulnerability-search', content: VulnerabilitySearchPage },
        { id: 'compliance-exclusions', content: ComplianceExclusionsPage },
        { id: 'vulnerability-findings-new', content: VulnerabilityFindingsRoute },
    ]);
};
