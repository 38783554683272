import { FC, useState } from 'react';
import {
    I18nExclusion,
    EXCLUSION_SELECTED_TYPE,
    ENTITY_FILTER_BY_KEY,
    modalPrefixAnalytics,
} from 'common/components/exclusions/helpers/exclusions.consts';
import { IDateRange } from 'common/design-system/components-v2/DatePicker/DatePicker.types';
import { useTranslation } from 'react-i18next';
import { Message, Stack, GroupSelection } from 'common/design-system/components-v2';
import {
    IExclusionConfig,
    IExclusionModalProps,
    IExclusionValidation,
    ITags,
} from 'common/components/exclusions/helpers/exclusions.interfaces';
import { ExclusionModuleType } from 'common/interface/exclusion';
import { IRuleset } from 'common/interface/ruleset';
import { exclusionCspmValidation, exclusionSave } from 'common/components/exclusions/helpers/exclusions.utils';
import ExclusionByRuleset from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByRuleset';
import ExclusionComment from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionComment';
import ExclusionByOrganizationalUnit from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByOrganizationalUnit';
import ExclusionByRegion from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByRegion';
import ExclusionByRule from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByRule';
import ExclusionByAccountNumber from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByAccountNumber';
import ExclusionByEnvironment from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByEnvironment';
import ExclusionBySeverities from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionBySeverities';
import ExclusionByTags from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByTags';
import ExclusionByEntity from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByEntity';
import ExclusionByDate from 'common/components/exclusions/Components/ExclusionsInputs/ExclusionByDate';
import ExclusionWarning from 'common/components/exclusions/helpers/exclusionWarning';
import validateWithYup from 'common/helpers/validateWithYup';
import { getNotificationsService } from 'common/interface/services';

const ExclusionsModalCspm: FC<IExclusionModalProps> = ({ isOpen, onClose, onSave, exclusion }) => {
    const { t } = useTranslation(I18nExclusion);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [isSaveClicked, setIsSaveClicked] = useState(false);
    const [selectedRuleset, setSelectedRuleset] = useState<string>();
    const [selectedRulesetDetails, setSelectedRulesetDetails] = useState<IRuleset | null>();
    const [selectedComment, setSelectedComment] = useState<string>(exclusion?.comment || '');
    const [ouEnvironmentRadioButton, setOuEnvironmentRadioButton] = useState<string>(
        exclusion?.cloudAccountIds ? EXCLUSION_SELECTED_TYPE.ENVIRONMENT : EXCLUSION_SELECTED_TYPE.ORGANIZATIONAL_UNIT,
    );
    const [selectedOrganizationalUnit, setSelectedOrganizationalUnit] = useState<string[]>([]);
    const [selectedEnvironment, setSelectedEnvironment] = useState<string[]>([]);
    const [selectedRegion, setSelectedRegion] = useState<string[]>([]);
    const [selectedDateRange, setSelectedDateRange] = useState<IDateRange | undefined>(
        exclusion?.dateRange || undefined,
    );
    const [selectedRule, setSelectedRule] = useState<string[]>([]);
    const [selectedAccountNumber, setSelectedAccountNumber] = useState<string>(exclusion?.cloudAccountId || '');
    const [selectedTags, setSelectedTags] = useState<ITags[]>([]);
    const [selectedSeverities, setSelectedSeverities] = useState<string[]>([]);
    const [entityLogic, setEntityLogic] = useState<string | null>(null);

    const exclusionId = exclusion?.id;
    const exclusionConfig = {
        selectedComment,
        ouEnvironmentRadioButton,
        selectedOrganizationalUnit,
        selectedEnvironment,
        entityLogic,
        selectedDateRange,
        selectedRegion,
        selectedRule,
        selectedAccountNumber,
        selectedTags,
        selectedSeverities,
        exclusionId,
        selectedRulesetDetails,
    };

    const configuredExclusionObject = (): IExclusionValidation => {
        return {
            ruleset: selectedRuleset,
            comment: selectedComment,
            requiredAdditionalInfo:
                !!selectedRegion.length ||
                !!selectedSeverities.length ||
                !!entityLogic ||
                !!selectedAccountNumber ||
                !!selectedTags.length ||
                !!selectedRule.length,
        };
    };
    const requestPayload = configuredExclusionObject();
    const formValidations = validateWithYup({
        yupValidationObject: exclusionCspmValidation(t),
        payload: { ...requestPayload },
    });

    const saveExclusion = async () => {
        setIsSaveLoading(true);
        setIsSaveClicked(true);
        try {
            if (!formValidations.valid) {
                setIsSaveLoading(false);
                return;
            }
            const saveExclusionResponse = await exclusionSave(exclusionConfig as IExclusionConfig);
            !!saveExclusionResponse && onSave && onSave();
            setIsSaveLoading(false);
            getNotificationsService().info(
                exclusionId
                    ? `${t('MODAL.TOAST.EXCLUSION_SAVE_SUCCESS.EDIT')} ${t('MODAL.TOAST.EXCLUSION_SAVE_SUCCESS.EDIT_DISCLAIMER')}`
                    : `${t('MODAL.TOAST.EXCLUSION_SAVE_SUCCESS.CREATE')} ${t('MODAL.TOAST.EXCLUSION_SAVE_SUCCESS.CREATE_DISCLAIMER')}`,
                '',
            );
        } catch (error: any) {
            getNotificationsService().error(t('MODAL.GENERAL.SAVE_ERROR'), error);
            setIsSaveLoading(false);
        }
    };

    const handleFilterFields = () => {
        switch (ouEnvironmentRadioButton) {
            case EXCLUSION_SELECTED_TYPE.ORGANIZATIONAL_UNIT:
                return selectedOrganizationalUnit.map((id) => ({
                    name: ENTITY_FILTER_BY_KEY.ORGANIZATIONAL_UNIT,
                    value: id,
                }));
            case EXCLUSION_SELECTED_TYPE.ENVIRONMENT:
                return selectedEnvironment.map((id) => ({ name: ENTITY_FILTER_BY_KEY.ENVIRONMENT, value: id }));
            default:
                return null;
        }
    };

    const onRulesetChange = (value: string) => {
        setSelectedRuleset(value);
    };
    const onCommentChange = (value: string) => {
        setSelectedComment(value);
    };
    const onRulesetDetailsChange = (value: IRuleset | null) => {
        setSelectedRulesetDetails(value);
    };
    const handleOuEnvironmentRadioButtonChange = (value: string) => {
        setOuEnvironmentRadioButton(value);
    };
    const handleOrganizationalUnitChange = (value: string[]) => {
        setSelectedOrganizationalUnit(value);
    };
    const handleEnvironmentChange = (value: string[]) => {
        setSelectedEnvironment(value);
    };
    const onRegionChange = (value: string[]) => {
        setSelectedRegion(value);
    };

    const onDateRangeChange = (value?: IDateRange) => {
        setSelectedDateRange(value);
    };

    const onRuleChange = (value: string[]) => {
        setSelectedRule(value);
    };

    const onAccountNumberChange = (value: string) => {
        setSelectedAccountNumber(value);
    };
    const onTagsChange = (value: ITags[]) => {
        setSelectedTags(value);
    };
    const onSeverityChange = (value: string[]) => {
        setSelectedSeverities(value);
    };
    const onEntityLogicChange = (value: string | null) => {
        setEntityLogic(value);
    };

    return (
        <Message
            id={`${modalPrefixAnalytics}-${ExclusionModuleType.CSPM}`}
            width='lg'
            onClose={onClose}
            isOpen={isOpen}
            title={exclusion?.id ? t('MODAL.HEADER.EDIT') : t('MODAL.HEADER.CREATE')}
            cancelBtnText={t('MODAL.FOOTER.CANCEL')}
            onCancel={onClose}
            submitBtnText={t('MODAL.FOOTER.SAVE')}
            onConfirm={saveExclusion}
            isLoading={isSaveLoading}
        >
            <Stack spacing={3} fullWidth>
                {isSaveClicked && !formValidations.valid && <ExclusionWarning />}
                <ExclusionByRuleset
                    formValidations={isSaveClicked ? formValidations.errors?.ruleset : undefined}
                    selectedOption={selectedRuleset}
                    initialSelectedOption={exclusion?.rulesetId}
                    isSaveClicked={isSaveClicked}
                    onChange={(value) => onRulesetChange(value)}
                    onRulesetDetailsChange={(value) => onRulesetDetailsChange(value)}
                    onClose={onClose}
                />
                <ExclusionComment
                    formValidations={isSaveClicked ? formValidations.errors?.comment : undefined}
                    isEnabled={!!selectedRuleset}
                    selectedOption={selectedComment}
                    onChange={(value) => onCommentChange(value)}
                    isSaveClicked={isSaveClicked}
                />
                <Stack spacing={1}>
                    <GroupSelection
                        direction='row'
                        value={ouEnvironmentRadioButton}
                        onChange={handleOuEnvironmentRadioButtonChange}
                        options={[
                            {
                                dataAid: 'ou-radio-button',
                                label: t('MODAL.TOPICS.ORGANIZATIONAL_UNIT.TITLE'),
                                name: EXCLUSION_SELECTED_TYPE.ORGANIZATIONAL_UNIT,
                                value: EXCLUSION_SELECTED_TYPE.ORGANIZATIONAL_UNIT,
                            },
                            {
                                dataAid: 'enviroment-radio-button',
                                label: t('MODAL.TOPICS.ENVIRONMENT.TITLE'),
                                name: EXCLUSION_SELECTED_TYPE.ENVIRONMENT,
                                value: EXCLUSION_SELECTED_TYPE.ENVIRONMENT,
                            },
                        ]}
                    />
                    {ouEnvironmentRadioButton === EXCLUSION_SELECTED_TYPE.ORGANIZATIONAL_UNIT && (
                        <ExclusionByOrganizationalUnit
                            isEnabled={!!selectedRuleset}
                            selectedRuleset={selectedRuleset}
                            selectedOption={selectedOrganizationalUnit}
                            initialSelectedOption={exclusion?.organizationalUnitIds}
                            onChange={(value) => handleOrganizationalUnitChange(value)}
                            onClose={onClose}
                        />
                    )}
                    {ouEnvironmentRadioButton === EXCLUSION_SELECTED_TYPE.ENVIRONMENT && (
                        <ExclusionByEnvironment
                            isEnabled={!!selectedRuleset}
                            selectedRuleset={selectedRuleset}
                            selectedOption={selectedEnvironment}
                            initialSelectedOption={exclusion?.cloudAccountIds}
                            selectedRulesetDetails={selectedRulesetDetails}
                            onChange={(value) => handleEnvironmentChange(value)}
                            onClose={onClose}
                        />
                    )}
                </Stack>
                <ExclusionByRegion
                    isEnabled={!!selectedRuleset}
                    selectedRuleset={selectedRuleset}
                    selectedOption={selectedRegion}
                    initialSelectedOption={exclusion?.regions}
                    selectedRulesetDetails={selectedRulesetDetails}
                    onChange={(value) => onRegionChange(value)}
                    onClose={onClose}
                />
                <ExclusionByDate selectedOption={selectedDateRange} onChange={(value) => onDateRangeChange(value)} />
                <ExclusionByRule
                    isEnabled={!!selectedRuleset && !selectedSeverities.length}
                    selectedRuleset={selectedRuleset}
                    selectedRulesetDetails={selectedRulesetDetails}
                    selectedOption={selectedRule}
                    initialSelectedOption={exclusion?.rules}
                    onChange={(value) => onRuleChange(value)}
                    onClose={onClose}
                />
                <ExclusionByEntity
                    formValidations={isSaveClicked ? formValidations.errors?.logicExpressions : undefined}
                    filterFields={handleFilterFields()}
                    isEnabled={!!selectedRuleset}
                    selectedRuleset={selectedRuleset}
                    selectedOption={exclusion?.logicExpressions}
                    onChange={onEntityLogicChange}
                    isSelectionGroup
                />
                <ExclusionByAccountNumber
                    isEnabled={!!selectedRuleset}
                    selectedRuleset={selectedRuleset}
                    selectedOption={exclusion?.logicExpressions}
                    onChange={(value) => onAccountNumberChange(value)}
                />
                <ExclusionByTags
                    isEnabled={!!selectedRuleset}
                    selectedOption={selectedTags}
                    initialSelectedOption={exclusion?.logicExpressions}
                    onChange={(value) => onTagsChange(value)}
                />
                <ExclusionBySeverities
                    isEnabled={!!selectedRuleset && !selectedRule.length}
                    selectedRuleset={selectedRuleset}
                    selectedOption={selectedSeverities}
                    initialSelectedOption={exclusion?.severities}
                    onChange={(value) => onSeverityChange(value)}
                />
            </Stack>
        </Message>
    );
};

export default ExclusionsModalCspm;
