import React, { Suspense } from 'react';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { getEventsTableWidgetService } from 'common/module_interface/events/EventsTableRegistry';
import { CommonEventFields } from 'common/module_interface/events/EventsConsts';
import { Spinner, Stack } from 'common/design-system/components-v2';

const EventsPanel: React.FC<{ entity: ICloudEntityData }> = ({ entity }) => {
    const EventsTable = getEventsTableWidgetService().getMainTable();
    return (
        <Suspense
            fallback={
                <Stack fullHeight fullWidth justifyContent='center' alignItems='center'>
                    <Spinner size={64} context='info' saturation />
                </Stack>
            }
        >
            <EventsTable
                tableIdPrefix={`asset_${entity.protectedAsset.id}`}
                disablePadding
                defaultFilters={{
                    fields: [
                        { name: 'cloudAccountId_calc', value: entity.cloudAccount.id },
                        { name: 'entityDome9Id', value: entity.protectedAsset.id },
                    ],
                }}
                hiddenFilters={[
                    CommonEventFields.cloudAccountId,
                    CommonEventFields.cloudAccountType,
                    'organizational unit',
                    CommonEventFields.organizationalUnitId,
                    CommonEventFields.entityType,
                    CommonEventFields.entityTypeByEnvironmentType,
                    CommonEventFields.region,
                ]}
                hiddenColumns={[
                    CommonEventFields.cloudAccountId,
                    CommonEventFields.cloudAccountType,
                    CommonEventFields.entityName,
                    CommonEventFields.entityType,
                    CommonEventFields.entityTypeByEnvironmentType,
                ]}
            />
        </Suspense>
    );
};

export default EventsPanel;
