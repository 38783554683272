import i18n from 'i18next';
import React, { useState } from 'react';
import { Button, Modal } from '@dome9/berries/react-components';
import AiDisclaimer from '../../AiDisclaimer/Components/AiDisclaimer';
import { getUserService } from 'common/interface/services';
import { insightNamespace } from '../../consts/i18n';
import { Checkbox } from 'common/design-system/components-v2';

interface InsightsDisclaimerModalProps {
    setApproved(approved: boolean): void;

    isOpen: boolean;
    closeModal: () => void;
}

export const InsightsDisclaimerModal: React.FC<InsightsDisclaimerModalProps> = (
    props: InsightsDisclaimerModalProps,
) => {
    const { setApproved, closeModal, isOpen } = props;
    const [understood, setUnderstood] = useState(false);

    return (
        <Modal.ModalDialog isOpen={isOpen} onRequestClose={closeModal} width={'xl'}>
            <Modal.ModalHeader title={'Turn on AI Generated Insights'} onClose={closeModal} />
            <Modal.ModalContent>
                <AiDisclaimer />
                <Checkbox
                    label={i18n.t('INSIGHTS.DISCLAIMER.MODAL.APPROVE', { ns: insightNamespace })}
                    onChange={(event) => setUnderstood(event?.target?.checked)}
                />
            </Modal.ModalContent>
            <Modal.ModalFooter>
                <div className='modal__footer-actions'>
                    <span className={'border'}>
                        <Button
                            className='btn--integrated'
                            onClick={() => {
                                closeModal();
                            }}
                        >
                            {i18n.t('INSIGHTS.DISCLAIMER.MODAL.CANCEL', { ns: insightNamespace })}
                        </Button>
                    </span>

                    <Button
                        color={'primary'}
                        disabled={!understood}
                        onClick={() => {
                            localStorage.setItem(`aidisclaimer.${getUserService().getUser().id}`, 'true');
                            setApproved(true);
                            closeModal();
                        }}
                    >
                        {i18n.t('INSIGHTS.DISCLAIMER.MODAL.OK', { ns: insightNamespace })}
                    </Button>
                </div>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    );
};
