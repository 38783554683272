import { Button, Chip, EntityHeader, Stack } from 'common/design-system/components-v2';
import React from 'react';
import { PanelBlock, RightElement, UpperPanelProps } from './UpperPanel.types';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';
import { transformToChips } from 'modules/workloads/components/Licenses/Licenses.utils';
import Licenses from 'modules/workloads/components/Licenses/Licenses';
import { changeUrl } from 'common/utils/http';

const UpperPanel: React.FC<UpperPanelProps> = ({
    version,
    manager,
    licenses,
    name,
    path,
    isOsPackage,
    showExplorePackageButton,
}) => {
    const { t } = useTranslation(getK8sNamespace('cve-explorer'));
    const blocks: PanelBlock[] = [
        {
            isRelevant: Boolean(manager),
            title: t('cveDrawer.packagePanel.manager'),
            info: manager,
            copyable: true,
        },
        {
            isRelevant: Boolean(version),
            title: t('cveDrawer.packagePanel.version'),
            info: version,
            copyable: true,
        },
        {
            isRelevant: Boolean(path.length) && path.some((value) => Boolean(value)),
            title: t('cveDrawer.packagePanel.path'),
            info: path.filter((pathValue) => Boolean(pathValue)).join(','),
            copyable: true,
        },
        {
            isRelevant: Boolean(licenses.length),
            title: t('cveDrawer.packagePanel.licenses'),
            info: <Licenses licenses={licenses} transformer={transformToChips} />,
            copyable: false,
        },
    ];
    const infoBlocks = blocks.reduce<BlockInfoProps[]>((accum, current) => {
        if (current?.isRelevant ?? true) {
            return [
                ...accum,
                {
                    title: current.title,
                    info: current.info,
                    copyable: current.copyable,
                },
            ];
        }
        return accum;
    }, []);

    const rightElements: RightElement[] = [
        {
            value: <Chip key={'isOsPackage'} label={t('cveDrawer.packagePanel.operatingSystem')} disableInteraction />,
            isRelevant: isOsPackage.every((isOs) => isOs),
        },
        {
            isRelevant: showExplorePackageButton,
            value: (
                <Stack direction={'row'} spacing={2} margin={[0, 0, 0, 4]}>
                    <Button
                        onClick={() => changeUrl(`/workload/vulnerability-search/packages?packageName=${name}`)}
                        iconProps={{ name: 'magnify' }}
                        dataAid='find package'
                    >
                        {t('cveDrawer.packagePanel.findPackageButton')}
                    </Button>
                </Stack>
            ),
        },
    ];
    const rightTitleElements = (
        <Stack direction='row' spacing={2}>
            {rightElements.filter((element) => element.isRelevant).map((element) => element.value)}
        </Stack>
    );

    return (
        <EntityHeader
            blocksMaxWidth={500}
            levelIcon={{
                iconProps: { name: 'package' },
            }}
            title={name}
            blocks={infoBlocks}
            titleRightElements={rightTitleElements}
            disableShowMore
        />
    );
};

export default UpperPanel;
