import { renderGslFilter } from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { Addin } from 'common/extensibility/AddinRegistry';
import i18n from 'i18next';
import { INTELLIGENCE_TABLE_FILTERS } from 'common/module_interface/intelligence/intelligence';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';

/**
 * common filter definitions for Intelligence tables
 */
const filterDefsAddIns: Addin<IFilterProps>[] = [
    {
        id: 'gsl',
        content: {
            filterProps: {
                key: FILTERS_KEYS.GSL,
                title: i18n.t('FILTER_PANEL.GSL_FILTER.TITLE'),
            },
            keyInObjectForAPI: 'gsl',
            renderFunction: renderGslFilter,
        },
    },
];

export default function initializeIntelligenceFilters() {
    globalAddinContainer.add<IFilterProps>(INTELLIGENCE_TABLE_FILTERS, filterDefsAddIns);
}
