import { DASHBOARD_DYNAMIC_WIDGETS } from 'common/module_interface/overview/Consts';
import { DashboardWidgetTypes, IAddinWidgetsDataSource } from 'common/module_interface/overview/Interface';
import { ILogicWidgetsOptions, createWidgets } from './Utils';
import { EVENT_ACTIVITY_AWS, defaultIntelligenceWidgetsTypeOptions } from './Consts';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';

export const initialize_AWS_EventActivityWidgets = () => {
    const defaultOptions = [...defaultIntelligenceWidgetsTypeOptions, DashboardWidgetTypes.Trend];
    const eventActivityAWSoptions: ILogicWidgetsOptions = {
        'event.aws': defaultOptions,
        'event.d9readonly': defaultOptions,
        'event.error.message': defaultOptions,
        'event.name': defaultOptions,
        'event.status': defaultOptions,
        'event.time': defaultOptions,
        'event.type': defaultOptions,
        'identity.account.id': defaultOptions,
        'identity.assetid': defaultOptions,
        'identity.awsservice': defaultOptions,
        'identity.id': defaultOptions,
        'identity.image': defaultOptions,
        'identity.name': defaultOptions,
        'identity.region': defaultOptions,
        'identity.type': defaultOptions,
        'identity.useragent': defaultOptions,
        'identity.vpc': defaultOptions,
        'issuer.id': defaultOptions,
        'issuer.mfa': defaultOptions,
        'issuer.name': defaultOptions,
        'issuer.region': defaultOptions,
        'issuer.sts.token': defaultOptions,
        'issuer.token': defaultOptions,
        'issuer.type': defaultOptions,
        'request.parameters': defaultOptions,
        'response.parameters': defaultOptions,
        'src.address.geolocation.countryname': defaultOptions,
        'src.address.ip': defaultOptions,
        'src.address.maliciousinfo.class': defaultOptions,
        'src.address.maliciousinfo.confidence': defaultOptions,
        'src.address.maliciousinfo.malwarefamily': defaultOptions,
        'src.address.maliciousinfo.owner': defaultOptions,
        'src.assetid': defaultOptions,
        'src.image': defaultOptions,
        'src.region': defaultOptions,
        'src.type': defaultOptions,
        'src.vpc': defaultOptions,
        'target.account.id': defaultOptions,
        'target.arn': defaultOptions,
        'target.assetid': defaultOptions,
        'target.hasnewthirdparties': defaultOptions,
        'target.id': defaultOptions,
        'target.image': defaultOptions,
        'target.name': defaultOptions,
        'target.newthirdparties': defaultOptions,
        'target.region': defaultOptions,
        'target.type': defaultOptions,
        'target.vpc': defaultOptions,
        'src.address.geolocation.countrycode': [DashboardWidgetTypes.Map],
    };

    const AWS_EventActivityWidgets: IAddinWidgetsDataSource = {
        dataSourceName: EVENT_ACTIVITY_AWS,
        dataField: { displayName: 'Aggregation', path: 'aggregation' },
        widgets: createWidgets(eventActivityAWSoptions),
    };

    globalAddinContainer.addMap(DASHBOARD_DYNAMIC_WIDGETS, [AWS_EventActivityWidgets], 'dataSourceName');
};
