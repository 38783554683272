import i18n, { TFunction } from 'i18next';
import { FindingsTableRegistry } from 'common/components/KustoEvents/FindingsTableRegistry';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import { FindingsActions } from '../../Findings.const';
import { i18nIntelligenceNamespace } from '../../../initialize.i18n';
import { isRemediationSupported } from 'common/module_interface/intelligence/Remediation/Remediation.utils';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';

import { isAnyClosedFinding } from 'common/module_interface/events/Events';

function getActionsDefs(): ITableAction<IKustoEvent>[] {
    const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);
    return [
        {
            id: FindingsActions.FIX_IT,
            name: t('FINDINGS_TABLE.ACTIONS.FIX_IT.TITLE'),
            callback: () => null,
        },
        {
            id: FindingsActions.REMEDIATE_CDR,
            name: t('FINDINGS_TABLE.ACTIONS.REMEDIATE.TITLE'),
            buttonProps: {
                iconProps: { name: 'remedy' },
                tooltip: t('FINDINGS_TABLE.ACTIONS.REMEDIATE.TOOLTIP'),
            },
            isActionDisabled: (selectedRows: IKustoEvent[]) => {
                if (selectedRows.length !== 1 || isAnyClosedFinding(selectedRows)) return true;
                const finding: IKustoEvent = selectedRows[0];
                return !isRemediationSupported(finding);
            },
            callback: () => null,
        },
        {
            id: FindingsActions.REMEDIATE_CIEM,
            name: t('FINDINGS_TABLE.ACTIONS.REMEDIATE.TITLE'),
            buttonProps: {
                iconProps: { name: 'remedy' },
                tooltip: t('FINDINGS_TABLE.ACTIONS.REMEDIATE.TOOLTIP'),
            },
            isActionDisabled: (selectedRows: IKustoEvent[]) => {
                if (selectedRows.length !== 1) return true;
                const finding: IKustoEvent = selectedRows[0];
                return !isRemediationSupported(finding);
            },
            callback: () => null,
        },
        {
            id: FindingsActions.EXCLUDE_CDR,
            name: t('FINDINGS_TABLE.ACTIONS.EXCLUDE.TITLE'),
            buttonProps: {
                iconProps: { name: 'exclude' },
                tooltip: t('FINDINGS_TABLE.ACTIONS.EXCLUDE.TOOLTIP'),
            },
            isActionDisabled: (selectedFindings: IKustoEvent[]) => {
                return selectedFindings.length !== 1 || isAnyClosedFinding(selectedFindings);
            },
            callback: () => null,
        },
        {
            id: FindingsActions.EXCLUDE_CIEM,
            name: t('FINDINGS_TABLE.ACTIONS.EXCLUDE.TITLE'),
            buttonProps: {
                iconProps: { name: 'exclude' },
                tooltip: t('FINDINGS_TABLE.ACTIONS.EXCLUDE.TOOLTIP'),
            },
            isActionDisabled: (selectedFindings: IKustoEvent[]) => {
                return selectedFindings.length !== 1;
            },
            callback: () => null,
        },
        {
            id: FindingsActions.REPORT_ISSUE,
            name: t('FINDINGS_TABLE.ACTIONS.REPORT_ISSUE.TITLE'),
            buttonProps: {
                iconProps: { name: 'warning' },
                tooltip: t('FINDINGS_TABLE.ACTIONS.REPORT_ISSUE.TOOLTIP'),
            },
            isActionDisabled: (selectedRows: IKustoEvent[]) => {
                return selectedRows.length === 0 || isAnyClosedFinding(selectedRows);
            },
            callback: () => null,
        },
        {
            id: FindingsActions.ACKNOWLEDGE,
            name: t('FINDINGS_TABLE.ACTIONS.ACKNOWLEDGE.TITLE'),
            buttonProps: {
                iconProps: { name: 'acknowledge' },
                tooltip: t('FINDINGS_TABLE.ACTIONS.ACKNOWLEDGE.TOOLTIP'),
            },
            isActionDisabled: (selectedRows: IKustoEvent[]) => {
                return selectedRows.length === 0 || isAnyClosedFinding(selectedRows);
            },
            callback: () => null,
        },
        {
            id: FindingsActions.COMMENT,
            name: t('FINDINGS_TABLE.ACTIONS.COMMENT.TITLE'),
            buttonProps: {
                iconProps: { name: 'comments' },
                tooltip: t('FINDINGS_TABLE.ACTIONS.COMMENT.TOOLTIP'),
            },
            isActionDisabled: (selectedRows: IKustoEvent[]) => {
                return selectedRows.length === 0 || isAnyClosedFinding(selectedRows);
            },
            callback: () => null,
        },
        {
            id: FindingsActions.CLOSE,
            name: t('FINDINGS_TABLE.ACTIONS.CLOSE.TITLE'),
            buttonProps: {
                iconProps: { name: 'remove' },
                tooltip: t('FINDINGS_TABLE.ACTIONS.CLOSE.TOOLTIP'),
            },
            isActionDisabled: (selectedRows: IKustoEvent[]) => {
                return selectedRows.length === 0 || isAnyClosedFinding(selectedRows);
            },
            callback: () => null,
        },
        {
            id: FindingsActions.CHANGE_SEVERITY,
            name: t('FINDINGS_TABLE.ACTIONS.CHANGE_SEVERITY.TITLE'),
            buttonProps: {
                iconProps: { name: 'severityUnknown' },
                tooltip: t('FINDINGS_TABLE.ACTIONS.CHANGE_SEVERITY.TOOLTIP'),
            },
            isActionDisabled: (selectedRows: IKustoEvent[]) => {
                return selectedRows.length === 0 || isAnyClosedFinding(selectedRows);
            },
            callback: () => null,
        },
        {
            id: FindingsActions.ASSIGN_USER,
            name: t('FINDINGS_TABLE.ACTIONS.ASSIGN_USER.TITLE'),
            buttonProps: {
                iconProps: { name: 'assign' },
                tooltip: t('FINDINGS_TABLE.ACTIONS.ASSIGN_USER.TOOLTIP'),
            },
            isActionDisabled: (selectedRows: IKustoEvent[]) => {
                return selectedRows.length === 0 || isAnyClosedFinding(selectedRows);
            },
            callback: () => null,
        },
        {
            id: FindingsActions.ARCHIVE,
            name: t('FINDINGS_TABLE.ACTIONS.ARCHIVE.TITLE'),
            buttonProps: {
                iconProps: { name: 'archive' },
                tooltip: t('FINDINGS_TABLE.ACTIONS.ARCHIVE.TOOLTIP'),
            },
            isActionDisabled: (selectedRows: IKustoEvent[]) => {
                return selectedRows.length === 0 || isAnyClosedFinding(selectedRows);
            },
            callback: () => null,
        },
    ];
}

export function initializeActionsDefs() {
    FindingsTableRegistry.addActions(getActionsDefs(), 'id');
}
