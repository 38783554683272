import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import {
    IMultiSelectFilter,
    IMultiSelectFilterSortInfo,
} from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import {
    getDisplayMappingFromOptions,
    getFilterValuesInitialDataWithOptions,
} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable.filters';
import { isNil } from 'common/utils/helpFunctions';
import i18n from 'i18next';
import { FindingSeverityServerEnum } from 'common/consts/FindingSeverity';
import { IssueSeverityEnum } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { getSafeIssueSeverityInfo } from './components/Issues/IssueSeverity';
import {
    getAllBusinessPriorityOptions,
    getBusinessPriorityLevelInfo,
} from './components/ProtectedAsset/AssetModifiers/BusinessPriority';
import {
    getAllDataSensitivityOptions,
    getDataSensitivityLevelInfo,
} from './components/ProtectedAsset/AssetModifiers/DataSensitivity';
import {
    getAllIamExposureOptions,
    getIamExposureLevelInfo,
} from './components/ProtectedAsset/AssetModifiers/IamExposure';
import {
    getAllNetworkExposureOptions,
    getNetworkExposureLevelInfo,
} from './components/ProtectedAsset/AssetModifiers/NetworkExposure';

export const getIssueSeverityFilterSortInfo = (): IMultiSelectFilterSortInfo => ({
    comparer: (option1: any, option2: any) => {
        const val1: number = isNil(option1?.value) ? 0 : getSafeIssueSeverityInfo(option1.value).level;
        const val2: number = isNil(option2?.value) ? 0 : getSafeIssueSeverityInfo(option2.value).level;
        return val2 - val1;
    },
    label: i18n.t('ERM_COMPONENTS.FILTERS.CUSTOM_SORT.SORT_BY_SEVERITY'),
});

export const getBusinessPriorityFilterSortInfo = (): IMultiSelectFilterSortInfo => ({
    comparer: (option1: any, option2: any) => {
        const val1: number = isNil(option1?.value) ? 0 : getBusinessPriorityLevelInfo(option1.value)?.level || 0;
        const val2: number = isNil(option2?.value) ? 0 : getBusinessPriorityLevelInfo(option2.value)?.level || 0;
        return val2 - val1;
    },
    label: i18n.t('ERM_COMPONENTS.FILTERS.CUSTOM_SORT.SORT_BY_BUSINESS_PRIORITY'),
});

export const getBusinessPriorityFilterProps = (
    aggregations: Aggregations,
    field: string,
): Partial<IMultiSelectFilter> => {
    const options: SelectOption[] = getAllBusinessPriorityOptions();
    return {
        initialData: getFilterValuesInitialDataWithOptions(aggregations, field, options),
        displayMapping: getDisplayMappingFromOptions(options),
        customSortInfo: getBusinessPriorityFilterSortInfo(),
    };
};

export const getDataSensitivityFilterSortInfo = (): IMultiSelectFilterSortInfo => ({
    comparer: (option1: any, option2: any) => {
        const val1: number = isNil(option1?.value) ? 0 : getDataSensitivityLevelInfo(option1.value)?.level || 0;
        const val2: number = isNil(option2?.value) ? 0 : getDataSensitivityLevelInfo(option2.value)?.level || 0;
        return val2 - val1;
    },
    label: i18n.t('ERM_COMPONENTS.FILTERS.CUSTOM_SORT.SORT_BY_DATA_SENSITIVITY'),
});

export const getDataSensitivityFilterProps = (
    aggregations: Aggregations,
    field: string,
): Partial<IMultiSelectFilter> => {
    const options: SelectOption[] = getAllDataSensitivityOptions();
    return {
        initialData: getFilterValuesInitialDataWithOptions(aggregations, field, options),
        displayMapping: getDisplayMappingFromOptions(options),
        customSortInfo: getDataSensitivityFilterSortInfo(),
    };
};

export const getIamExposureFilterSortInfo = (): IMultiSelectFilterSortInfo => ({
    comparer: (option1: any, option2: any) => {
        const val1: number = isNil(option1?.value) ? 0 : getIamExposureLevelInfo(option1.value)?.level || 0;
        const val2: number = isNil(option2?.value) ? 0 : getIamExposureLevelInfo(option2.value)?.level || 0;
        return val2 - val1;
    },
    label: i18n.t('ERM_COMPONENTS.FILTERS.CUSTOM_SORT.SORT_BY_IAM_EXPOSURE'),
});

export const getIamExposureFilterProps = (aggregations: Aggregations, field: string): Partial<IMultiSelectFilter> => {
    const options: SelectOption[] = getAllIamExposureOptions();
    return {
        initialData: getFilterValuesInitialDataWithOptions(aggregations, field, options),
        displayMapping: getDisplayMappingFromOptions(options),
        customSortInfo: getIamExposureFilterSortInfo(),
    };
};

export const getNetworkExposureFilterSortInfo = (): IMultiSelectFilterSortInfo => ({
    comparer: (option1: any, option2: any) => {
        const val1: number = isNil(option1?.value) ? 0 : getNetworkExposureLevelInfo(option1.value)?.level || 0;
        const val2: number = isNil(option2?.value) ? 0 : getNetworkExposureLevelInfo(option2.value)?.level || 0;
        return val2 - val1;
    },
    label: i18n.t('ERM_COMPONENTS.FILTERS.CUSTOM_SORT.SORT_BY_NETWORK_EXPOSURE'),
});

export const getNetworkExposureFilterProps = (
    aggregations: Aggregations,
    field: string,
): Partial<IMultiSelectFilter> => {
    const options: SelectOption[] = getAllNetworkExposureOptions();
    return {
        initialData: getFilterValuesInitialDataWithOptions(aggregations, field, options),
        displayMapping: getDisplayMappingFromOptions(options),
        customSortInfo: getNetworkExposureFilterSortInfo(),
    };
};

export const findingsSeverityStyleInitialData = [
    { value: FindingSeverityServerEnum.Low },
    { value: FindingSeverityServerEnum.Medium },
    { value: FindingSeverityServerEnum.High },
    { value: FindingSeverityServerEnum.Critical },
];

export const issueSeverityStyleInitialData = [
    { value: IssueSeverityEnum.low },
    { value: IssueSeverityEnum.medium },
    { value: IssueSeverityEnum.high },
    { value: IssueSeverityEnum.critical },
];
