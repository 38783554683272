import React, { useEffect, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import i18n from 'common/services/translations/translations';
import { IIssueOrGroup, ISSUE_FIELD_NAMES } from '../Issues.interface';
import { getOrgUnitFullPath } from 'common/components/ProtectedAssets/AssetUtils';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import { isGroupedByField } from '../Issues.utils';

export const IssueOrgUnitCellRenderer: React.FC<ICellRendererParams<IIssueOrGroup>> = (params) => {
    const [fullName, setFullName] = useState<string>(i18n.t('GENERAL.LOADING'));
    const issueOrGroup: IIssueOrGroup | undefined = params.data;

    useEffect(() => {
        async function updateFullName() {
            const issue: IIssueOrGroup | undefined = params.data;
            const ouFullName = await getOrgUnitFullPath(issue?.organizationalUnitId);
            setFullName(ouFullName);
        }
        if (
            !issueOrGroup ||
            (issueOrGroup.isGrouped && !isGroupedByField(issueOrGroup, ISSUE_FIELD_NAMES.ORGANIZATIONAL_UNIT_ID))
        ) {
            setFullName('');
        } else {
            void updateFullName();
        }
    }, [issueOrGroup, params.data]);

    if (fullName) {
        const options: IIconLinkCellProps = {
            isLink: false,
            value: fullName,
        };
        CellToClipboardHandler(params, fullName);
        return <IconLinkCellRenderer {...options} />;
    } else {
        return '';
    }
};
