import { useContext } from 'react';
import { RuleContext } from './RuleContext';

export const useRuleContext = () => {
    const context = useContext(RuleContext);
    if (!context) {
        throw new Error('useRuleContext must be used within the RuleProvider');
    }
    return context;
};
