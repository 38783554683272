import { IHeaderParams } from 'ag-grid-community';
import React from 'react';
import ImageScanStatusHeaderRendererStyled from './ImageScanStatusHeaderRenderer.styled';
import { getCheckpointUrl } from 'common/components/Forms/urls';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Stack } from 'common/design-system/components-v2';

const ImageScanStatusHeaderRenderer: React.FC<IHeaderParams> = (props: IHeaderParams) => {
    const { t } = useTranslation('k8s_common');
    const { displayName } = props;
    const scanStatusDocsUrl = getCheckpointUrl('SCAN_STATUS_DOCS');

    const onClickSort = () => {
        props.progressSort();
    };

    const onSeeDocsClicked = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    return (
        <ImageScanStatusHeaderRendererStyled.ColumnHeaderWrapper onClick={onClickSort}>
            <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
                {displayName}
                {props.column.isSorting() && (
                    <span
                        className={`ag-icon ag-icon-${props.column.isSortAscending() ? 'asc' : 'desc'}`}
                        role='presentation'
                    ></span>
                )}
            </Stack>
            <Tooltip content={t('seeDocumentation')}>
                <Stack alignItems='center'>
                    <a onClick={onSeeDocsClicked} href={scanStatusDocsUrl} target='_blank' rel='noreferrer'>
                        <IconButton iconProps={{ name: 'book', size: 12 }} />
                    </a>
                </Stack>
            </Tooltip>
        </ImageScanStatusHeaderRendererStyled.ColumnHeaderWrapper>
    );
};

export default ImageScanStatusHeaderRenderer;
