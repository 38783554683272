import React from 'react';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import FilterPanelPlaceholder from 'common/components/ProtectedAssets/DynamicProtectedAssetsTable/components/FilterPanelPlaceholder';
import globalAddinContainer from 'common/extensibility/AddinContainer';
import { getCloudAccountsService, getOrganizationalUnitService } from 'common/interface/data_services';
import { getHttpService } from 'common/interface/services';
import { getProtectedAssetsService, IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { PATH_PROTECTED_ASSETS_PAGE_TABLE_FILTERS } from 'common/module_interface/assets/ProtectedAssets.consts';
import { ASSETS_SEARCH_URL } from 'common/module_interface/assets/AssetsConsts';
import { ProtectedAssetsResponse } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { AffectedAssetsTableFilterPanelProps } from './AffectedAssetsFilterPanel.types';
import { Filters } from 'modules/workloads/services/vulnerability-search/common/types';
import { RELEVANT_ASSETS_FILTERS_IDS } from './AffectedAssetsFilterPanel.consts';

const AffectedAssetsTableFilterPanel: React.FC<AffectedAssetsTableFilterPanelProps> = ({
    onFilterChange,
    aggregations,
    coreFilterFields,
}) => {
    const protectedAssetsFilters = React.useMemo(
        () => globalAddinContainer.get<IProtectedAssetFilter>(PATH_PROTECTED_ASSETS_PAGE_TABLE_FILTERS),
        [],
    );
    const [filterPanel, setFilterPanel] = React.useState(<FilterPanelPlaceholder />);

    const getAggregations = React.useCallback(
        async (filtersValues?: Filters): Promise<Aggregations> => {
            if (filtersValues) {
                onFilterChange(filtersValues);
            }
            const response = await getHttpService().post<ProtectedAssetsResponse>(ASSETS_SEARCH_URL, {
                data: {
                    filter: {
                        fields: [...coreFilterFields, ...(filtersValues?.fields ?? [])],
                        freeTextPhrase: filtersValues?.freeTextPhrase ?? '',
                    },
                    pageSize: 0,
                    searchAfter: [],
                },
            });
            return response.aggregations;
        },
        [coreFilterFields, onFilterChange],
    );

    React.useEffect(() => {
        const initial = async () => {
            const getFiltersInitialData = async () => {
                const organizationalUnitsPromise = getOrganizationalUnitService().getOrganizationalUnitsView();
                const allCloudAccountsPromise = getCloudAccountsService().getAllCloudAccounts();
                return Promise.all([organizationalUnitsPromise, allCloudAccountsPromise]).then(
                    ([organizationalUnits, allCloudAccounts]) => {
                        return {
                            organizationalUnits,
                            allCloudAccounts,
                        };
                    },
                );
            };
            const filterPanelElementsList = getProtectedAssetsService().getFilterDefs(
                protectedAssetsFilters.filter(({ id }) => RELEVANT_ASSETS_FILTERS_IDS.includes(id)),
                {
                    aggregations,
                    filtersInitialData: await getFiltersInitialData(),
                },
            );
            setFilterPanel(
                initFilterPanel({
                    filterPanelElementsList,
                    getAggregations,
                    shouldBuildObjectForAPI: true,
                    shouldUseQueryParams: false,
                }),
            );
        };
        initial();
    }, [aggregations, getAggregations, protectedAssetsFilters]);
    return filterPanel;
};

export default AffectedAssetsTableFilterPanel;
