import { Command } from 'common/design-system/components-v2';
import styled from 'styled-components';

export const RemediationSnippet = styled(Command)`
    white-space: break-spaces;
    background-color: #f0f7ff;
    margin-left: 30px;
    max-height: 390px;
    overflow: scroll;
`;
