import { ILog, ILogFieldInfo, ILogFieldsSection } from 'common/module_interface/intelligence/Logs/Logs.interface';
import React from 'react';
import Stack from 'common/design-system/components-v2/Stack';
import { Card, CopiableLine, Typography } from 'common/design-system/components-v2';
import { LogOverviewSectionStyled } from './LogOverviewSection.styled';

const LogOverviewSection: React.FC<{ panelInfo: ILogFieldsSection; logInfo: ILog }> = ({ panelInfo, logInfo }) => {
    return (
        <Card title={panelInfo.title}>
            <Stack direction={'column'} spacing={1}>
                {panelInfo.fields.map((item: ILogFieldInfo) => {
                    const value: string = logInfo[item.field as keyof ILog] as string;
                    return (
                        value && (
                            <LogOverviewSectionStyled.KeyValue key={item.field}>
                                <Typography variant={'body500'} color={'light'}>
                                    {item.label}:{' '}
                                </Typography>
                                {value && (
                                    <CopiableLine value={value}>
                                        <LogOverviewSectionStyled.Value>
                                            {item.decorator ? item.decorator(value) : value}
                                        </LogOverviewSectionStyled.Value>
                                    </CopiableLine>
                                )}
                            </LogOverviewSectionStyled.KeyValue>
                        )
                    );
                })}
            </Stack>
        </Card>
    );
};

export default LogOverviewSection;
