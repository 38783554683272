import React, { useMemo } from 'react';
import WidgetColumn from './WidgetColumn';
import FindingSeverityList, { FindingSeverityListItem } from './FindingSeverityList';
import LevelIndicator from './LevelIndicator';
import {
    BusinessPriorityLevelEnum,
    DataSensitivityLevelEnum,
    I18nRiskNamespace,
    IamExposureLevelEnum,
    IamSensitivityLevelEnum,
    NetworkExposureLevelEnum,
    ORIGIN_TYPES,
    RULESET_NAMES,
    VULNERABILITIES_INNER_TAB_INDEX,
} from '../../../consts';
import i18n from 'i18next';
import {
    isDataSensitivityRelevant,
    isIamExposureRelevant,
    isIamSensitivityRelevant,
    isNetworkExposureRelevant,
    isRunningStatusRelevant,
} from '../ErmDataUtils';
import { useTranslation } from 'react-i18next';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { changeTab } from 'common/module_interface/assets/utils';
import { ASSETS_TAB_NAMES } from 'common/module_interface/assets/ProtectedAssets';
import { AssetTypes } from 'common/assets/assets.const';
import { getCustomRulesets, getRulesetVendorName } from '../../Rulesets/Ruleset.actions';
import { FilterField } from 'common/components/Findings/Findings.interface';
import {
    FindingSeverityEnum,
    getSafeFindingSeverityInfo,
    IFindingSeverityCounters,
} from 'common/consts/FindingSeverity';

import { getIamExposureLevelInfo } from '../AssetModifiers/IamExposure';
import { getBusinessPriorityLevelInfo } from '../AssetModifiers/BusinessPriority';
import { getNetworkExposureLevelInfo } from '../AssetModifiers/NetworkExposure';
import { getDataSensitivityLevelInfo } from '../AssetModifiers/DataSensitivity';
import { getIamSensitivityLevelInfo } from '../AssetModifiers/IamSensitivity';
import { EventsTableFilters, getEventsTableWidgetService } from 'common/module_interface/events/EventsTableRegistry';
import { EventType } from 'common/module_interface/events/EventsConsts';
import { changeUrlParams } from 'common/utils/http';

const RUNNING_STATUS_VALUES = [
    { key: 'False', value: 'ASSET_DETAILS.RISK_DATA.CONTEXT.RUNNING_STATUS.STOPPED' },
    { key: 'True', value: 'ASSET_DETAILS.RISK_DATA.CONTEXT.RUNNING_STATUS.RUNNING' },
];

enum SeverityItemType {
    CVES = 'CVEs',
    THREATS = 'Threats',
    SECRETS = 'Secrets',
    MISCONFIGURATIONS = 'Misconfigurations',
}

function getSeverityListItem(
    title: string,
    data: IFindingSeverityCounters,
    type: SeverityItemType,
): FindingSeverityListItem {
    return {
        title,
        type,
        lowCount: data.low,
        mediumCount: data.medium,
        highCount: data.high,
        criticalCount: data.critical,
    };
}

const RiskData: React.FC<{ asset: IProtectedAssetViewModel }> = ({ asset }) => {
    const { t } = useTranslation(I18nRiskNamespace);
    const severities = useMemo<FindingSeverityListItem[]>(() => {
        const result = [];

        if (asset.vulnerability?.stats) {
            asset.vulnerability?.stats.cve &&
                result.push(
                    getSeverityListItem(
                        t('ASSET_DETAILS.RISK_DATA.FINDINGS.CVES'),
                        asset.vulnerability?.stats.cve,
                        SeverityItemType.CVES,
                    ),
                );
            asset.vulnerability?.stats.threat &&
                result.push(
                    getSeverityListItem(
                        t('ASSET_DETAILS.RISK_DATA.FINDINGS.THREATS'),
                        asset.vulnerability?.stats.threat,
                        SeverityItemType.THREATS,
                    ),
                );
            asset.vulnerability?.stats.secret &&
                result.push(
                    getSeverityListItem(
                        t('ASSET_DETAILS.RISK_DATA.FINDINGS.SECRETS'),
                        asset.vulnerability?.stats.secret,
                        SeverityItemType.SECRETS,
                    ),
                );
        } else {
            // fallback to old data structure
            asset.cvEs &&
                result.push(
                    getSeverityListItem(t('ASSET_DETAILS.RISK_DATA.FINDINGS.CVES'), asset.cvEs, SeverityItemType.CVES),
                );
            asset.malwarePerSeverity &&
                result.push(
                    getSeverityListItem(
                        t('ASSET_DETAILS.RISK_DATA.FINDINGS.THREATS'),
                        asset.malwarePerSeverity,
                        SeverityItemType.THREATS,
                    ),
                );
            asset.secretsPerSeverity &&
                result.push(
                    getSeverityListItem(
                        t('ASSET_DETAILS.RISK_DATA.FINDINGS.SECRETS'),
                        asset.secretsPerSeverity,
                        SeverityItemType.SECRETS,
                    ),
                );
        }

        asset.postureFindings &&
            result.push(
                getSeverityListItem(
                    t('ASSET_DETAILS.RISK_DATA.FINDINGS.MISCONFIGURATIONS'),
                    asset.postureFindings,
                    SeverityItemType.MISCONFIGURATIONS,
                ),
            );
        return result;
    }, [asset, t]);

    const currentNetworkExposure = isNetworkExposureRelevant(asset.typeByPlatform)
        ? getNetworkExposureLevelInfo(asset.context?.networkExposure)
        : null;
    const currentIamExposure = isIamExposureRelevant(asset.typeByPlatform)
        ? getIamExposureLevelInfo(asset.context?.iamExposure)
        : null;
    const runningStatus = isRunningStatusRelevant(asset.typeByPlatform)
        ? asset.additionalFields.find((additionalField: { name: string; value: string }) => {
              return additionalField.name === 'IsRunning';
          })
        : null;

    let runningStatusValue = '';
    let runningStatusIndex = -1;
    if (runningStatus) {
        runningStatusIndex = RUNNING_STATUS_VALUES.findIndex((item) => item.key === runningStatus.value);
        if (runningStatusIndex >= 0) {
            runningStatusValue = i18n.t(RUNNING_STATUS_VALUES[runningStatusIndex].value, { ns: I18nRiskNamespace });
        }
    }

    const currentBusinessPriority = getBusinessPriorityLevelInfo(asset.businessPriority);

    let currentIamSensitivityLevel = -1;
    let currentIamSensitivityTitle: string | undefined = undefined;
    if (isIamSensitivityRelevant(asset.typeByPlatform)) {
        const iamSensitivityInfo = getIamSensitivityLevelInfo(asset.context?.iamSensitivity);
        currentIamSensitivityLevel = iamSensitivityInfo.level;
        currentIamSensitivityTitle = iamSensitivityInfo.title;
    }

    let dataSensitivityTitle: string | undefined = undefined;
    let dataSensitivityLevel = -1;
    if (isDataSensitivityRelevant(asset.typeByPlatform)) {
        const dataSensitivityInfo = getDataSensitivityLevelInfo(asset.context?.dataSensitivity);
        dataSensitivityLevel = dataSensitivityInfo.level;
        dataSensitivityTitle =
            dataSensitivityInfo.id === DataSensitivityLevelEnum.Unknown ? undefined : dataSensitivityInfo.title;
    }

    const changeTabToVulnerabilities = (innerTabIndex: number, severityType?: FindingSeverityEnum) => {
        const filterFields = [];
        if (severityType) {
            filterFields.push({
                name: 'severity',
                value: getSafeFindingSeverityInfo(severityType).serverKey,
            });
        }
        changeTab(ASSETS_TAB_NAMES.VULNERABILITIES_TAB_NAME, filterFields, innerTabIndex);
    };

    const changeTabToFindings = (
        severityType?: FindingSeverityEnum,
        ruleset?: string,
        origin?: number,
        otherFilterFields: FilterField[] = [],
    ) => {
        const filterFields = [...otherFilterFields];
        if (ruleset) {
            filterFields.push({
                name: 'bundleName',
                value: ruleset,
            });
        }
        if (origin !== undefined) {
            filterFields.push({
                name: 'origin',
                value: origin,
            });
        }
        if (severityType) {
            filterFields.push({
                name: 'severity',
                value: getSafeFindingSeverityInfo(severityType).serverKey,
            });
        }

        const params = getEventsTableWidgetService().getParamsStringForEventsTab(
            ASSETS_TAB_NAMES.EVENTS_TAB_NAME,
            EventType.POSTURE_FINDINGS,
            {
                fields: filterFields.reduce<EventsTableFilters['fields']>((acc, field) => {
                    if (!field.value) return acc;
                    acc?.push({ name: field.name, value: [field.value] });
                    return acc;
                }, []),
            },
        );
        changeUrlParams(params.map((param) => ({ name: param.paramKey, value: param.newValue })));
    };

    const handleSeverityClick = (severityItem: FindingSeverityListItem, severityType?: FindingSeverityEnum) => {
        const isLambda = asset.typeByPlatform === AssetTypes.AWS_LAMBDA;
        if (isLambda) {
            switch (severityItem.type) {
                case SeverityItemType.CVES:
                    changeTabToFindings(
                        severityType,
                        RULESET_NAMES.VULNERABLE_DEPENDENCY,
                        ORIGIN_TYPES.SERVERLESS_RUNTIME_PROTECTION,
                    );
                    return;

                case SeverityItemType.THREATS:
                case SeverityItemType.SECRETS:
                    changeTabToFindings(
                        severityType,
                        RULESET_NAMES.CREDENTIALS_USAGE,
                        ORIGIN_TYPES.SERVERLESS_RUNTIME_PROTECTION,
                    );
                    return;

                default:
                    break;
            }
        }

        switch (severityItem.type) {
            case SeverityItemType.CVES:
                changeTabToVulnerabilities(VULNERABILITIES_INNER_TAB_INDEX.CVES, severityType);
                return;

            case SeverityItemType.THREATS:
                changeTabToVulnerabilities(VULNERABILITIES_INNER_TAB_INDEX.THREATS, severityType);
                return;

            case SeverityItemType.SECRETS:
                changeTabToVulnerabilities(VULNERABILITIES_INNER_TAB_INDEX.SECRETS, severityType);
                return;

            case SeverityItemType.MISCONFIGURATIONS:
                getCustomRulesets().then((customRulesets) => {
                    const assetVendor = getRulesetVendorName(asset.platform);
                    const ruleset = customRulesets.find(
                        (ruleset) => getRulesetVendorName(ruleset.platform) === assetVendor,
                    );
                    changeTabToFindings(severityType, ruleset?.rulesetName, ORIGIN_TYPES.COMPLIANCE_ENGINE, [
                        {
                            name: 'isExcluded',
                            value: 'false',
                        },
                    ]);
                });
                return;

            default:
                break;
        }
    };

    const getOnClickSeverityLine = (severityItem: FindingSeverityListItem) => {
        if (
            severityItem.lowCount + severityItem.mediumCount + severityItem.highCount + severityItem.criticalCount ===
            0
        ) {
            return null;
        }
        return () => handleSeverityClick(severityItem);
    };

    const getOnClickSeverityCounter = (
        severityItem: FindingSeverityListItem,
        severityType: FindingSeverityEnum,
        count: number,
    ) => {
        if (count === 0) {
            return null;
        }
        return () => handleSeverityClick(severityItem, severityType);
    };

    return (
        <div className={'bg-content flex flex-1 flex-col '}>
            <div
                className={
                    'grid grid-cols-16 gap-9 md:gap-10 xl:gap-9  xxl:gap-11 md:px-9 xl:px-9 xl:pb-10 md:pb-10 xl:pt-8 px-8 pb-8 md:pt-8 pt-6 container'
                }
            >
                <div className={'col-span-16 lg:col-span-6  xxl:col-span-8'}>
                    <WidgetColumn title={t('ASSET_DETAILS.RISK_DATA.FINDINGS.TITLE')}></WidgetColumn>
                    <FindingSeverityList
                        severities={severities}
                        getOnClickSeverityLine={getOnClickSeverityLine}
                        getOnClickSeverityCounter={getOnClickSeverityCounter}
                    />
                </div>
                <div className='col-span-8 lg:col-span-5  xxl:col-span-4 flex flex-col'>
                    <WidgetColumn title={t('ASSET_DETAILS.RISK_DATA.CONTEXT.TITLE')}></WidgetColumn>
                    {currentNetworkExposure && (
                        <LevelIndicator
                            title={t('ASSET_DETAILS.RISK_DATA.CONTEXT.NETWORK_EXPOSURE')}
                            value={currentNetworkExposure.title}
                            totalBars={Object.values(NetworkExposureLevelEnum).length - 2}
                            currentBarIndex={currentNetworkExposure.level - 1}
                            isDisabled={currentNetworkExposure.level === -1}
                        />
                    )}
                    {currentIamExposure && (
                        <LevelIndicator
                            title={t('ASSET_DETAILS.RISK_DATA.CONTEXT.IAM_EXPOSURE')}
                            value={currentIamExposure.title}
                            totalBars={Object.values(IamExposureLevelEnum).length - 2}
                            currentBarIndex={currentIamExposure.level - 1}
                            isDisabled={currentIamExposure.level === -1}
                        />
                    )}
                    {runningStatus && (
                        <LevelIndicator
                            title={t('ASSET_DETAILS.RISK_DATA.CONTEXT.RUNNING_STATUS.TITLE')}
                            value={runningStatusValue}
                            totalBars={RUNNING_STATUS_VALUES.length - 1}
                            currentBarIndex={runningStatusIndex - 1}
                            className={'mt-8'}
                        />
                    )}
                    {!currentNetworkExposure && !currentIamExposure && !runningStatus ? (
                        <div className='text-center text-weakest flex-1 border grid place-items-center'>
                            <div>{t('GENERAL.NOT_SUPPORTED')}</div>
                        </div>
                    ) : null}
                </div>
                <div className={'col-span-8 lg:col-span-5  xxl:col-span-4'}>
                    <WidgetColumn title={t('ASSET_DETAILS.RISK_DATA.IMPACT.TITLE')}></WidgetColumn>
                    <LevelIndicator
                        title={t('ASSET_DETAILS.RISK_DATA.IMPACT.BUSINESS_PRIORITY')}
                        value={currentBusinessPriority.title}
                        totalBars={Object.values(BusinessPriorityLevelEnum).length - 2}
                        currentBarIndex={currentBusinessPriority.level - 1}
                        isDisabled={currentBusinessPriority.level === -1}
                    />
                    {currentIamSensitivityTitle && (
                        <LevelIndicator
                            title={t('ASSET_DETAILS.RISK_DATA.IMPACT.IAM_SENSITIVITY')}
                            value={currentIamSensitivityTitle}
                            totalBars={Object.values(IamSensitivityLevelEnum).length - 2}
                            currentBarIndex={currentIamSensitivityLevel - 1}
                            isDisabled={currentIamSensitivityLevel === -1}
                            className={'mt-8'}
                        />
                    )}
                    {dataSensitivityTitle && (
                        <LevelIndicator
                            title={t('ASSET_DETAILS.RISK_DATA.CONTEXT.DATA_SENSITIVITY')}
                            value={dataSensitivityTitle}
                            totalBars={Object.values(DataSensitivityLevelEnum).length - 2}
                            currentBarIndex={dataSensitivityLevel - 1}
                            className={'mt-8'}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default RiskData;
