import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { isGroupEntity } from '../../../ToxicGraph.utils';
import { ICustomTreeNode } from 'common/erm-components/custom/CustomTree/CustomTree.interface';
import { IQueryNodeData } from '../../../ToxicGraph.interface';

export enum PivotTypeEnum {
    pivot = 'pivot',
    group = 'group',
    none = 'none',
}

export interface IPivotTypeInfo {
    key: PivotTypeEnum;
    title: string;
    sortValue: number;
}

let sortValue = 0;
export const PivotTypeMap: { [key in PivotTypeEnum]: IPivotTypeInfo } = {
    none: {
        key: PivotTypeEnum.none,
        title: 'Normal',
        sortValue: sortValue++,
    },
    group: {
        key: PivotTypeEnum.group,
        title: 'Group',
        sortValue: sortValue++,
    },
    pivot: {
        key: PivotTypeEnum.pivot,
        title: 'Pivot',
        sortValue: sortValue++,
    },
};

export const getDescendingPivotTypes = (): IPivotTypeInfo[] => {
    return Object.values(PivotTypeMap).sort((s1: IPivotTypeInfo, s2: IPivotTypeInfo) => s2.sortValue - s1.sortValue);
};

export const getPivotTypeOption = (pivotType: PivotTypeEnum): SelectOption => {
    const pivotTypeInfo: IPivotTypeInfo = PivotTypeMap[pivotType];
    if (!pivotTypeInfo) {
        return {
            value: pivotType,
            label: pivotType,
        };
    }
    return {
        value: pivotTypeInfo.key,
        label: pivotTypeInfo.title,
    };
};

export const getPivotTypeOptions = (node: ICustomTreeNode<IQueryNodeData>): SelectOption[] => {
    if (node.data.pivotType === PivotTypeEnum.pivot) {
        return [getPivotTypeOption(PivotTypeEnum.pivot)];
    }
    const isGroup: boolean = isGroupEntity(node.data.entityType);
    return getDescendingPivotTypes()
        .filter((pivotTypeInfo: IPivotTypeInfo) => (isGroup ? true : pivotTypeInfo.key !== PivotTypeEnum.group))
        .map((pivotTypeInfo: IPivotTypeInfo) => ({
            value: pivotTypeInfo.key,
            label: pivotTypeInfo.title,
        }));
};
