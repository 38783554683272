import { IActionUsageDef } from 'common/interface/general';
import {
    createAcknowledgeAction,
    createAssignUserAction,
    createChangeSeverityAction,
    createCloseAction,
    createCommentAction,
    createExcludeCdrAction,
    createArchiveAction,
    createFixItAction,
    createRemediateCdrAction,
    createReportIssueAction,
} from '../FindingsTable/Types/FindingsActionsTypes';
import { CDR_EVENTS_TABLE } from './CdrEventsPage.const';
import { ModalType } from '../Findings.const';

export const getCdrEventsTableActions = (
    openDialog: (dialogType: ModalType | null) => void,
    isArchiveView: boolean,
): IActionUsageDef[] => {
    if (isArchiveView) {
        return [createArchiveAction(openDialog, true), createCloseAction(openDialog)];
    }
    return [
        createFixItAction(CDR_EVENTS_TABLE, openDialog),
        createRemediateCdrAction(openDialog),
        createReportIssueAction(openDialog),
        createAcknowledgeAction(openDialog),
        createCommentAction(openDialog),
        createChangeSeverityAction(openDialog),
        createArchiveAction(openDialog),
        createCloseAction(openDialog),
        createAssignUserAction(openDialog),
        createExcludeCdrAction(),
    ];
};
