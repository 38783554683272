import { GslQueryFilters } from './gsl-run/gsl-run.interface';
import GslQuery from './GslQuery';

type ReducedFilters = {
    [filterName: string]: Array<string>;
};

const reduceFilters = (filters: GslQueryFilters): ReducedFilters => {
    return filters.reduce((acc: ReducedFilters, filter) => {
        if (!acc[filter.name]) {
            acc[filter.name] = [];
        }
        acc[filter.name].push(filter.value);
        return acc;
    }, {});
};

export const addFiltersToGslQuery = ({ gslQuery, filters }: { gslQuery: GslQuery; filters: GslQueryFilters }) => {
    const reducedFilters = reduceFilters(filters);

    Object.entries(reducedFilters).forEach(([filterName, filterValues]) => {
        const queryConditions: string[] = [];
        filterValues.forEach((filterValue) => {
            let queryPart = '';

            if (filterName === 'scan.producer') {
                if (filterValue === 'Inspector') {
                    queryPart = "scan.producer = 'Inspector'";
                } else {
                    queryPart = "scan.producer != 'Inspector'";
                }
            } else {
                queryPart = `${filterName} = '${filterValue}'`;
            }

            queryConditions.push(queryPart);
        });

        if (queryConditions.length > 0) {
            gslQuery.bulkOr(queryConditions);
        }
    });
};
