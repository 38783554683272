import { Button, Modal } from 'common/design-system/components-v2';
import Typography from 'common/design-system/components-v2/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18nIntegrations } from 'common/module_interface/settings/integrations/consts';

interface IDeleteConfigurationModalProps {
    isOpen: boolean;
    configurationName: string;
    onDeleteCallBack: Function;
    onModalCloseCallBack: Function;
}
export const DeleteConfigurationModal: React.FC<IDeleteConfigurationModalProps> = ({
    isOpen,
    configurationName,
    onDeleteCallBack,
    onModalCloseCallBack,
}) => {
    const { t } = useTranslation(I18nIntegrations);

    return (
        <Modal.ModalDialog width={'lg'} isOpen={isOpen}>
            <Modal.ModalHeader
                title={t('CONFIGURATIONS.DELETE_MODAL.TITLE')}
                hasSeparator={false}
                onClose={() => onModalCloseCallBack()}
            />
            <Modal.ModalContent>
                <Typography variant='bodyLg'>
                    {t('CONFIGURATIONS.DELETE_MODAL.CONTENT', { configurationName: configurationName })}
                </Typography>
            </Modal.ModalContent>
            <Modal.ModalFooter hasSeparator={false}>
                <Button
                    size='medium'
                    onClick={() => {
                        onModalCloseCallBack();
                    }}
                    dataAid='Cancel'
                >
                    {t('GENERAL.CANCEL')}
                </Button>
                <Button
                    variant='contained'
                    color='alert'
                    size='medium'
                    dataAid='removeConfigurationModal'
                    onClick={() => {
                        onDeleteCallBack();
                    }}
                >
                    {t('GENERAL.REMOVE')}
                </Button>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    );
};
