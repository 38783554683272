import React from 'react';
import moment from 'moment-mini';
import { Button, Chip, Stack } from 'common/design-system/components-v2';
import { SeverityChip } from 'common/components/SeverityChip/SeverityChip';
import { changeUrl } from 'common/utils/http';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from '../../../initialize.i18n';
import { FindingSeverityEnum } from 'common/consts/FindingSeverity';
import { Cve, Package } from '../../../services/vulnerability/vulnerability.interface';

interface RightElementsProps {
    isCveExplorer?: boolean;
    packageAndCve: { package: Package; cve: Cve } | undefined;
}
const RightElements: React.FC<RightElementsProps> = ({ isCveExplorer, packageAndCve }) => {
    const { t } = useTranslation(getK8sNamespace('cve-explorer'));
    const theme = useTheme();

    if (!packageAndCve) return null;

    return (
        <Stack direction='row' spacing={2}>
            {Boolean(packageAndCve?.cve.knownExploit) && (
                <Chip
                    tooltip={
                        packageAndCve?.cve.knownExploitDueDate &&
                        t('cveDrawer.upperPanel.knownExploitDueDate', {
                            date: moment(packageAndCve?.cve.knownExploitDueDate).format('lll'),
                        })
                    }
                    leadingIconProps={{ name: 'unsecured', size: 16 }}
                    label={t('cveDrawer.upperPanel.knownExploit')}
                    disableInteraction
                />
            )}
            {packageAndCve?.cve.isFixedByPackageRemediation && (
                <Chip
                    leadingIconProps={{ name: 'remedy', size: 16, customColor: theme.palette.surface.blue }}
                    label={t('cveDrawer.upperPanel.fixable')}
                    customTextColor={theme.palette.surface.blue}
                    customColor={theme.palette.surface.brandLight}
                />
            )}
            <SeverityChip severity={packageAndCve?.cve.severity.toLowerCase() as FindingSeverityEnum} />
            {!isCveExplorer && (
                <Stack direction={'row'} spacing={2} margin={[0, 0, 0, 4]}>
                    <Button
                        onClick={() => changeUrl(`/workload/cve-search?cve=${packageAndCve?.cve.id}`)}
                        iconProps={{ name: 'magnify' }}
                        dataAid='Find CVE'
                    >
                        {t('cveDrawer.upperPanel.findCveButton')}
                    </Button>
                </Stack>
            )}
        </Stack>
    );
};

export default RightElements;
