import { Icon, Stack, Table } from 'common/design-system/components-v2';
import React, { useCallback, useMemo, useRef } from 'react';
import { GridApi, GridOptions } from 'ag-grid-community';
import { IamSensitivityTableInfo } from '../IAMSensitivity.interface';
import { IssuesRegistry } from 'common/module_interface/RiskManagement/issues/IssuesRegistry';
import { IAMSensTableStyled } from './IAMSensitivityTable.styled';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import EntityLink from 'common/components/ProtectedAssets/Renderers/EntityLink';
import { IconProps } from 'common/design-system/components-v2/Icon/Icon.types';
import { getAssetUrl } from '../../../../../Issues.utils';
import withShowMoreButton from '../../../../../../../../../common/erm-components/utils/WithShowMore/WithShowMore';

const IAMSensitivityTable: React.FC<IamSensitivityTableInfo> = ({ actions, roleAsset }) => {
    const iAsset = useMemo(() => getProtectedAssetsService().getAssetByType(roleAsset.type), [roleAsset]);
    const iconProps: IconProps | undefined = iAsset?.icon ? { vendorNameOrPath: iAsset.icon, size: 24 } : undefined;

    const apiRef = useRef<GridApi>();

    const onGridReady = useCallback((params: any) => {
        apiRef.current = params.api;
    }, []);

    const gridOptions: GridOptions = useMemo(() => {
        return {
            columnDefs: IssuesRegistry.getIamSensitivityColumnDefs(),
            defaultColDef: {
                sortable: false,
                resizable: true,
                suppressMenu: true,
                suppressMovable: true,
            },
            rowModelType: 'clientSide',
            domLayout: 'autoHeight',
            suppressDragLeaveHidesColumns: true,
            rowData: actions,
            enableRangeSelection: false,
            suppressCellFocus: true,
            onGridReady,
            masterDetail: true,
            detailRowAutoHeight: true,
        };
    }, [actions, onGridReady]);

    const TableWithButton = withShowMoreButton(Table);

    return (
        <IAMSensTableStyled.TopDiv>
            <Stack direction='row' spacing={2} alignItems='center'>
                {iconProps && (
                    <IAMSensTableStyled.IconDiv>
                        <Icon {...iconProps} />
                    </IAMSensTableStyled.IconDiv>
                )}
                <IAMSensTableStyled.LinkWrapper>
                    <EntityLink entityName={roleAsset?.name} url={getAssetUrl(roleAsset) || 'none'} />
                </IAMSensTableStyled.LinkWrapper>
            </Stack>
            <TableWithButton gridOptions={gridOptions} disableColumnMenu={true} disableGrouping={true} />
        </IAMSensTableStyled.TopDiv>
    );
};

export default IAMSensitivityTable;
