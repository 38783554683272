import { getService, globalAddinContainer } from 'common/extensibility/AddinContainer';
import { Vendors } from 'common/consts/vendors';
import { getAppRootRegistry, IRulesetService, RULESET_SERVICE_ID } from 'common/interface/services';
import {
    ENVIRONMENT_POLICY_RULESETS,
    IShallowRuleset,
    IVendorRuleset,
    OU_POLICY_RULESETS,
    POLICY_ENVIRONMENT_VENDORS,
    POLICY_RULESETS,
    POLICY_VENDORS,
    Vendor,
} from 'common/interface/policy';
import { lazy } from 'react';

export const CSPM = 'CSPM';
export const getRulesets = async (vendor?: Vendors) => {
    let rulesets = await getService<IRulesetService>(RULESET_SERVICE_ID).getAllRulesets();
    rulesets = rulesets.map((ruleset) => {
        return {
            ...ruleset,
            cloudVendor: ruleset.cloudVendor === 'google' ? 'gcp' : ruleset.cloudVendor,
            availableVersions: [...ruleset.availableVersions, { version: 'Latest' }],
        };
    });

    const shallowRulesets: IShallowRuleset[] = rulesets.map((ruleset) => {
        return {
            id: ruleset.id.toString(),
            name: ruleset.name,
            icon: ruleset.cloudVendor,
            description: ruleset.description,
            availableVersions: ruleset.availableVersions,
            platform: ruleset.cloudVendor,
        };
    });
    if (vendor) {
        return shallowRulesets.filter((ruleset: IShallowRuleset) => ruleset.platform === vendor);
    } else {
        return shallowRulesets;
    }
};

export const getEnvironmentRulesetsByVendor = async (vendor?: Vendors) => {
    let rulesets = await getService<IRulesetService>(RULESET_SERVICE_ID).getAllRulesets();
    rulesets = rulesets.map((ruleset) => {
        return {
            ...ruleset,
            cloudVendor: ruleset.cloudVendor === 'google' ? 'gcp' : ruleset.cloudVendor,
            availableVersions: [...ruleset.availableVersions, { version: 'Latest' }],
        };
    });

    const shallowRulesets: IShallowRuleset[] = rulesets.map((ruleset) => {
        return {
            id: ruleset.id.toString(),
            name: ruleset.name,
            icon: ruleset.cloudVendor,
            description: ruleset.description,
            availableVersions: ruleset.availableVersions,
            platform: ruleset.cloudVendor,
        };
    });
    if (vendor) {
        if (vendor === Vendors.AWS) {
            return shallowRulesets.filter(
                (ruleset: IShallowRuleset) =>
                    ruleset.platform === vendor || ruleset.platform === Vendors.IMAGE_ASSURANCE,
            );
        } else {
            return shallowRulesets.filter((ruleset: IShallowRuleset) => ruleset.platform === vendor);
        }
    } else {
        return shallowRulesets;
    }
};

export default function initialize() {
    const policyPageRoute = {
        condition: true,
        component: lazy(() => import('./PlatformPolicy/AddPolicyPage')),
        path: '/policy/add',
    };
    const policyEnvironmentsPageRoute = {
        condition: true,
        component: lazy(() => import('./EnvironmentPolicy/AddEnvironmentPolicyPage')),
        path: '/policy/environments/add',
    };
    const policyOUPageRoute = {
        condition: true,
        component: lazy(() => import('./OUPolicy/AddOUPolicyPage')),
        path: '/policy/ou/add',
    };

    const policyEditPageRoute = {
        condition: true,
        component: lazy(() => import('./EditPolicy/EditPolicyPage')),
        path: '/policy/edit',
    };

    const addEditServerlessPolicyPagePageRoute = {
        condition: true,
        component: lazy(() => import('./PlatformPolicy/AddServerlessPolicyPage')),
        path: '/serverless/policy/edit',
    };

    const vendors: Vendor[] = [
        {
            id: Vendors.AWS,
            name: Vendors.AWS,
            icon: 'aws',
            title: 'Amazon Web Services',
        },
        {
            id: Vendors.AZURE,
            name: Vendors.AZURE,
            icon: 'azure',
            title: 'Microsoft Azure',
        },
        {
            id: Vendors.GCP,
            name: Vendors.GCP,
            icon: 'gcp',
            title: 'Google Cloud Platform',
        },
        {
            id: Vendors.ALIBABA,
            name: Vendors.ALIBABA,
            icon: 'alibaba',
            title: 'Alibaba',
        },
        {
            id: Vendors.OCI,
            name: Vendors.OCI,
            icon: 'oracle',
            title: 'Oracle Cloud Platform',
        },
    ];

    const getVendorsRulesets = () => {
        const vendorsRulesetsList: IVendorRuleset[] = [];
        for (const vendor of vendors) {
            vendorsRulesetsList.push({ vendorId: vendor.id, getRulesets: () => getRulesets(vendor.id) });
        }
        return vendorsRulesetsList;
    };
    const getEnvironmentRulesets = () => {
        const vendorsRulesetsList: IVendorRuleset[] = [];
        for (const vendor of vendors) {
            vendorsRulesetsList.push({
                vendorId: vendor.id,
                getRulesets: () => getEnvironmentRulesetsByVendor(vendor.id),
            });
        }
        return vendorsRulesetsList;
    };
    const vendorRulesetsList = getVendorsRulesets();
    const environmentRulesetsList = getEnvironmentRulesets();
    const getMappedRulesetItems = (rulesetsList: IVendorRuleset[]) => {
        return [
            ...rulesetsList.map((item: IVendorRuleset) => ({
                id: item.vendorId,
                content: {
                    getRulesets: item.getRulesets,
                    vendorId: item.vendorId,
                },
            })),
        ];
    };

    globalAddinContainer.add(POLICY_ENVIRONMENT_VENDORS, [
        ...vendors.map((vendor) => ({ id: vendor.id, content: vendor })),
    ]);
    globalAddinContainer.add(POLICY_VENDORS, [...vendors.map((vendor) => ({ id: vendor.id, content: vendor }))]);

    globalAddinContainer.add(POLICY_RULESETS, getMappedRulesetItems(vendorRulesetsList));

    globalAddinContainer.add(ENVIRONMENT_POLICY_RULESETS, getMappedRulesetItems(environmentRulesetsList));

    globalAddinContainer.add(OU_POLICY_RULESETS, getMappedRulesetItems(environmentRulesetsList));

    getAppRootRegistry().addRoutes([
        { id: 'Policy', content: policyPageRoute },
        { id: 'PolicyOU', content: policyOUPageRoute },
        { id: 'PolicyEnvironments', content: policyEnvironmentsPageRoute },
        { id: 'PolicyEdit', content: policyEditPageRoute },
        { id: 'addEditServerlessPolicyPagePageRoute', content: addEditServerlessPolicyPagePageRoute },
    ]);
}
