import React, { useCallback, useEffect, useState } from 'react';
import { IIssue } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { LoadingState } from 'common/interface/general';
import i18n from 'i18next';
import { Stack, Typography } from 'common/design-system/components-v2';
import { ermTrans } from '../../../../../../RiskManagement.utils';
import FullSizeSpinner from 'common/erm-components/custom/FullSize/FullSizeSpinner';
import { MalwareStyled } from './Malware.styled';
import { IMalware } from './Malware.interface';
import MalwareTable from './MalwareTable/MalwareTable';
import { fetchMalwareData } from '../../../../Issues.utils';

const Malware: React.FC<{ issue: IIssue; asset: IProtectedAssetViewModel; title: string; initiallyOpen?: boolean }> = ({
    issue,
    asset,
}) => {
    const [loadingState, setLoadingState] = useState(LoadingState.IS_LOADING);
    const [malwareItems, setMalwareItems] = useState<IMalware[]>();

    const loadMalware = useCallback(async () => {
        setLoadingState(LoadingState.IS_LOADING);
        setMalwareItems(undefined);
        try {
            const malwareData: IMalware[] = await fetchMalwareData(issue, asset);
            setMalwareItems(malwareData);
            setLoadingState(LoadingState.LOADING_SUCCEEDED);
        } catch (e) {
            setLoadingState(LoadingState.LOADING_FAILED);
        }
    }, [issue, asset]);

    useEffect(() => {
        if (issue && asset) {
            void loadMalware();
        }
    }, [issue, asset, loadMalware]);

    return (
        <Stack fullWidth>
            {loadingState === LoadingState.IS_LOADING && (
                <MalwareStyled.LoaderDiv>
                    <FullSizeSpinner />
                </MalwareStyled.LoaderDiv>
            )}
            {loadingState === LoadingState.LOADING_FAILED && (
                <MalwareStyled.NoteDiv fullWidth fullHeight>
                    <Typography color={'alert'}>{i18n.t('COMMON.ERROR_OCCURRED')}</Typography>
                </MalwareStyled.NoteDiv>
            )}
            {loadingState === LoadingState.LOADING_SUCCEEDED && malwareItems && (
                <Stack direction={'column'} fullWidth fullHeight>
                    {malwareItems.length > 0 ? (
                        <MalwareStyled.TableDiv>
                            <MalwareTable malwareItems={malwareItems} />
                        </MalwareStyled.TableDiv>
                    ) : (
                        <MalwareStyled.NoteDiv>
                            <Typography>
                                {ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.MALWARE_TABLE.NO_DATA')}
                            </Typography>
                        </MalwareStyled.NoteDiv>
                    )}
                </Stack>
            )}
        </Stack>
    );
};
export default Malware;
