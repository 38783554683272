import styled, { css } from 'styled-components';
import { Stack } from 'common/design-system/components-v2';
import { addAlpha } from 'common/design-system/theme/colors';

interface IconWrapperProps {
    withBackground?: boolean;
    bg?: string;
}
export const IconWrapper = styled(Stack)<IconWrapperProps>`
    ${({ withBackground, bg, theme }) =>
        withBackground &&
        css`
            background: ${bg || addAlpha(theme.palette.surface.strong, 0.75)};
            padding: ${`${theme.spacing(2)}${theme.units}`};
            border-radius: 8px;
            flex-shrink: 0;
        `};
`;
